import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  createThreadRequest,
  endThreadRequest, getThreadRequest, getThreadsRequest, updateThreadNameRequest,
} from '../../../store/actions/chatBot';
import { ReactComponent as ChatBotIcon } from '../../../assets/icons/chat_bot.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import Menu from '../Menu/Menu';
import Loader from '../Loader/Loader';

const topics = [
  {
    title: 'Order',
    subtitle: 'Purchase order',
    path: '/orders/purchase',
    orderType: 'purchase',
  },
  {
    title: 'Order',
    subtitle: 'Sales order',
    path: '/orders/sale',
    orderType: 'sale',
  },
];

const menuOptions = [
  {
    label: (
      <div className="chat_menu_option">
        <EditIcon className="edit" />

        <p>Rename chat</p>
      </div>
    ),
    value: 'default',
  },
  {
    label: (
      <div className="chat_menu_option">
        <DeleteIcon className="delete" />

        <p>Delete chat</p>
      </div>
    ),
    value: 'delete',
  },
];

const Topics = () => {
  const dispatch = useDispatch();
  const {
    threadsLists, threadsListsLoading, totalPages, page,
  } = useSelector((store) => store.chatBot.threads);

  const [deletingThreadId, setDeletingThreadId] = useState('');
  const [changingThread, setChangingThread] = useState({});

  const getThread = (threadId) => {
    dispatch(getThreadRequest({ threadId }));
  };

  const onMenuSelect = async (thread, value) => {
    if (value === 'delete') {
      // setDeletingThreadId(thread.threadId);
      await dispatch(endThreadRequest(thread.threadId));

      // setDeletingThreadId('');
    } else {
      setChangingThread(thread);
    }
  };

  const changeThreadName = (threadName) => {
    setChangingThread((prev) => ({ ...prev, threadName }));
  };

  const saveThreadName = () => {
    dispatch(updateThreadNameRequest(changingThread));
    setChangingThread({});
  };

  const scroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;

    if (!threadsListsLoading && page !== totalPages && Math.floor(offsetHeight + scrollTop + 10) >= scrollHeight) {
      dispatch(getThreadsRequest({ page: page + 1 }));
    }
  };

  return (
    <>
      <div className="chat__assistant__header">
        <h3 className="chat__assistant__header__title">eSwap chat assistant</h3>
      </div>

      <div className="chat__assistant__topics_wrapper" onScroll={scroll}>
        <ChatBotIcon className="chat__assistant__icon" />

        <div className="chat__assistant__main__types__block">
          {topics.map(({
            title, subtitle, path, orderType,
          }) => (
            <div
              key={path}
              className="chat__assistant__main__type"
              tabIndex={0}
              role="button"
              onClick={() => dispatch(createThreadRequest({ orderType }))}
            >
              <div className="chat__assistant__main__type__label">{title}</div>
              <div className="chat__assistant__main__type__value">{subtitle}</div>
            </div>
          ))}
        </div>

        <div className="chat__assistant__main__history__block">
          {!threadsLists.length && threadsListsLoading
            ? <Loader color="white" size={36} />

            : (
              <>
                {threadsLists.map((t) => (
                  <div
                    key={t.id}
                    className={classNames(
                      'chat__assistant__main__history__block__item',
                      { disabled: t.id === changingThread.id },
                    )}
                    onClick={() => t.id !== changingThread.id && getThread(t.threadId)}
                  >
                    {t.id === changingThread.id
                      ? (
                        <input
                          autoFocus
                          value={changingThread.threadName}
                          onChange={({ target: { value } }) => changeThreadName(value)}
                          onBlur={saveThreadName}
                        />
                      )

                      : <span>{t.threadName}</span>}

                    <Menu
                      options={menuOptions}
                      onChange={(menuOption) => onMenuSelect(t, menuOption)}
                      loading={deletingThreadId === t.threadId}
                      zIndex={1000}
                      loaderColor="#FFFFFF"
                    />
                  </div>
                ))}

                {threadsListsLoading
                  && <Loader className="chat__assistant__main__history__block__loading" color="white" size={20} />}
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default Topics;
