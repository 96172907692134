import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Button from '../../_common/Form/Button';
import {
  createCustomerRequest,
  getSingleCustomerRequest, setCustomerFormData, setCustomerFormErrors,
  updateCustomerRequest,
} from '../../../store/actions/customers';
import Loader from '../../_common/Loader/Loader';
import { getCustomerCustomFieldsRequest } from '../../../store/actions/settings';
import { saveDraftReorderProduct } from '../../../store/actions/products';


const tabs = [
  {
    tab: 'general',
    title: 'General',
    errorKeys: ['email', 'image', 'type', 'firstName', 'lastName', 'company', 'phone', 'workPhone'],
  },
  {
    tab: 'otherDetails',
    title: 'Other details',
    errorKeys: ['defaultCurrencyCode', 'defaultPaymentMethodId', 'defaultPaymentTermId',
      'defaultPriceListId', 'defaultWarehouseId', 'defaultTaxId'],
  },
  { tab: 'addresses', title: 'Addresses', errorKeys: ['shippingAddresses', 'billingAddress'] },
  { tab: 'contactPerson', title: 'Contact person', errorKeys: ['contacts'] },
  { tab: 'customFields', title: 'Custom fields', errorKeys: ['customFields'] },
];

function CustomerFormWrapper(props) {
  const { tab, onTabChange, children } = props;

  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [searchParams] = useSearchParams();

  const customerFormData = useSelector((state) => state.customers.customerFormData);
  const errors = useSelector((state) => state.customers.errors);
  const customFields = useSelector((state) => state.settings.customFields);
  const draftReorder = useSelector((state) => state.products.draftSavedReorder);

  const filteredTabs = useMemo(() => {
    if (customerFormData.type === 'individual') {
      return tabs.filter((t) => t.tab !== 'contactPerson');
    }
    return tabs;
  }, [customerFormData.type]);

  useEffect(() => {
    (async () => {
      let customer = {};
      if (customerId) {
        const { payload: { data } } = await dispatch(getSingleCustomerRequest(customerId));
        customer = data.customer;
      }
      const { payload } = await dispatch(getCustomerCustomFieldsRequest());
      const fields = {};
      customer?.customFields?.forEach((c) => {
        fields[c.name] = {
          name: c.name,
          value: c.value,
        };
      });
      payload?.customerCustomFields?.forEach((c) => {
        if (!fields[c.label] && c.defaultValue) {
          fields[c.label] = {
            name: c.label,
            value: c.defaultValue,
          };
        }
      });
      dispatch(setCustomerFormData({ key: 'customFields', value: fields }));
      setLoadingData(false);
    })();
  }, [customerId]);

  const handleSave = useCallback(async () => {
    setLoading(true);
    const data = { ...customerFormData };
    if (data.contacts) {
      data.contacts = data.contacts.filter((c) => _.compact(Object.values(c)).length > 1);
    }

    if (data.customFields) {
      const manFields = customFields.filter((c) => c.isMandatory);
      const notFilled = manFields.filter((m) => !data.customFields[m.label]);

      if (!_.isEmpty(notFilled)) {
        const e = { customFields: {} };
        notFilled.forEach((n) => {
          e.customFields[n.label] = n.helpText || 'Field is required';
        });
        dispatch(setCustomerFormErrors(e));
        setLoading(false);
        return;
      }
      data.customFields = _.map(data.customFields, (val) => val);
    }
    if (customerId) {
      const { payload } = await dispatch(updateCustomerRequest({
        ...data,
        id: customerId,
      }));
      if (payload.data.customer) {
        toast.success('Successfully updated!');
        navigate('/crm/customers');
      }
    } else {
      const { payload } = await dispatch(createCustomerRequest(data));
      if (payload.data.customer) {
        toast.success('Successfully created!');

        if (searchParams.get('back')) {
          if (searchParams.get('back').includes('/stocks/reorders')) {
            dispatch(saveDraftReorderProduct({ ...draftReorder, vendorId: payload.data.customer }));

            navigate(searchParams.get('back'));
          } else {
            navigate(`${searchParams.get('back')}?customerId=${payload.data.customer.id}`);
          }
        } else {
          navigate('/crm/customers');
        }
      }
    }

    setLoading(false);
  }, [customerFormData, customerId, searchParams, customFields]);

  return (
    <div className="customer_form_wrapper">
      <div className="top">
        <p>{customerId ? 'Update contact' : 'New contact'}</p>
        <div className="actions">
          <Button
            btnType="cancel"
            roundBorder
            onClick={() => navigate(searchParams.get('back') ? searchParams.get('back') : '/crm/customers')}
          >
            Cancel
          </Button>
          <Button roundBorder onClick={handleSave} loading={loading}>Save changes</Button>
        </div>
      </div>
      <div className="tabs">
        {filteredTabs.map((t) => (
          <div
            className={classNames('tab', { active: t.tab === tab })}
            key={t.tab}
            onClick={() => onTabChange(t.tab)}
            role="button"
            tabIndex={-1}
          >
            {t.title}
            {Object.keys(errors).some((e) => t.errorKeys.includes(e)) ? <span className="red_dot" /> : null}
          </div>
        ))}
      </div>
      {!loadingData ? children : <Loader />}
    </div>
  );
}

export default CustomerFormWrapper;

CustomerFormWrapper.propTypes = {
  tab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
