import React, { useCallback } from 'react';
import Select, { components } from 'react-select';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as ClearIcon } from '../../../assets/icons/cancel.svg';

function MultiSelect(props) {
  const {
    label, addNew, onAddNewClick, ...p
  } = props;

  const Option = (pr) => (
    <components.Option
      {...pr}
    >
      <div className="custom_option" ref={pr.innerRef} {...pr.innerProps}>
        <span style={{ background: pr.data.color }} />
        <p>{pr.label}</p>
      </div>

    </components.Option>
  );

  const Menu = (pr) => (
    <components.Menu
      {...pr}
    >
      {pr.children}
      {addNew ? (
        <div
          data-scope={typeof addNew === 'boolean' ? undefined : addNew}
          onClick={onAddNewClick}
          className="add_new_button"
        >
          <span className="plus">+</span>
          <span>Add new</span>
        </div>
      ) : null}
    </components.Menu>
  );

  const MultiValue = (pr) => (
    <components.Option
      {...pr}
    >
      <div className="custom_option" ref={pr.innerRef} {...pr.innerProps}>
        <span style={{ background: pr.data.color }} />
        <p>{pr.data.name}</p>
        <ClearIcon {...pr.removeProps} />

      </div>

    </components.Option>
  );

  const ClearIndicator = useCallback(({ innerProps: { ref: clearIndicatorRef, ...restInnerProps } }) => (
    <ClearIcon
      {...restInnerProps}
      ref={clearIndicatorRef}
      className="clear_indicator_wrapper"
    />
  ), []);

  const DropdownIndicator = useCallback((prop) => {
    const { menuIsOpen } = prop.selectProps;

    return (
      <div className="dropdown_indicator_wrapper">
        <ArrowIcon className={`select__arrow ${menuIsOpen ? 'select__arrow_up' : ''}`} />
      </div>
    );
  }, []);

  return (
    <div className="select__wrapper round_border multi_select">
      {label ? <label className="label">{label}</label> : ''}
      <Select
        isMulti
        components={{
          Option, MultiValue, DropdownIndicator, ClearIndicator, Menu,
        }}
        classNamePrefix="direct"
        {...p}
      />
    </div>
  );
}

export default MultiSelect;
