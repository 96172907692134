import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import Button from '../../../_common/Form/Button';
import Loader from '../../../_common/Loader/Loader';

function AdminPaidAmountChart({ paidAmount, loading, interval }) {
  const chart = useMemo(() => ({
    series: [{
      name: 'series1',
      data: paidAmount?.invoices?.map((d) => d.total) || [],
    }],
    options: {
      chart: {
        height: 350,
        type: 'area',

        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      xaxis: {
        // type: 'datetime',
        categories: paidAmount?.invoices?.map((d) => moment(d.time, 'YYYY/MM/DD')
          .format('MMM')) || [], // interval === 'day' ? 'MMM D, YYYY',
      },
      colors: ['#F90'],
      fill: {
        opacity: 0,
        type: 'solid',
      },
    },
  }), [paidAmount]);

  return (
    <div className="admin_paid_amount_chart">
      {loading ? <Loader className="admin_chart_wrapper_loader" /> : (
        <>
          <div className="admin_chart_header">
            <div className="admin_chart_header_left">
              <h3>Amount they have paid</h3>

              <Button href="#" btnType="transparent" color="#1472FF" title="View all" />
            </div>

            <div className="admin_chart_header_count">{paidAmount.total}</div>
          </div>

          <Chart
            options={chart.options}
            series={chart.series}
            // width="94%"
            type="area"
            height={425}
          />
        </>
      )}
    </div>
  );
}

export default AdminPaidAmountChart;
