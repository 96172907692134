import React, { useCallback } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/cancel.svg';
import { orderStatuses } from '../../../../helpers/staticData';

const StepThree = ({
  data, changeData, orderStatusList, setOrderStatusList, errors, setStatusErrors, fromMyApp,
}) => {
  const changeStatuses = useCallback((name, value, path) => {
    changeData((prev) => ({
      ...prev,
      orderStatusMapping: {
        ...prev.orderStatusMapping,
        [name]: path ? { ...(prev.orderStatusMapping?.[name] || {}), [path]: value } : value,
      },
    }));

    if (path) {
      setStatusErrors((prev) => ({ ...prev, [name]: { ...prev[name], [path]: false } }));
    }
  }, []);

  const addNewLine = useCallback(() => {
    setOrderStatusList((prev) => [...prev, {
      [+new Date()]: {
        name: '',
        value: '',
      },
    }]);
  }, []);

  const deleteLine = useCallback((key) => {
    setOrderStatusList((prev) => prev.filter((p) => !p?.[key]));

    changeData((prev) => {
      delete prev.orderStatusMapping?.[key];

      return {
        ...prev,
        orderStatusMapping: {
          ...prev.orderStatusMapping,
        },
      };
    });

    setStatusErrors((prev) => {
      delete prev[key];

      return prev;
    });
  }, []);

  return (
    <div className={classNames('integration_step_two_wrapper', { my_app: fromMyApp })}>
      <div>
        {fromMyApp && <h2>Configure Statuses sync between eSwap and your app</h2>}

        {orderStatusList.map((list) => (
          list.name
            ? (
              <div
                key={list.name}
                className="integration_step_two_fields_wrapper"
              >
                <div className="integration_step_two_fields_left_block">
                  <p className="integration_step_two_fields_left_block_title">{_.startCase(list.name)}</p>
                </div>

                <div className="integration_step_two_fields_right_block">
                  <div className="integration_step_two_fields_right_block_select">
                    <Select
                      value={data[list.name]}
                      onChange={(value) => changeStatuses(list.name, value)}
                      options={orderStatuses}
                      roundBorder
                      menuPosition="fixed"
                      size="small"
                    />
                  </div>
                </div>
              </div>
            )

            : (
              _.map(list, (fields, key) => (
                <div key={key} className="integration_step_two_fields_wrapper status_fields">
                  <div className="integration_step_two_fields_left_block">
                    <Input
                      value={data[key]?.name}
                      error={errors[key]?.name}
                      onChangeText={(value) => changeStatuses(key, value, 'name')}
                      size="small"
                      roundBorder
                    />
                  </div>

                  <div className="integration_step_two_fields_right_block">
                    <Select
                      value={data[key]?.value}
                      options={orderStatuses}
                      error={errors[key]?.value}
                      wrapperClassName="integration_step_two_fields_right_block_field"
                      onChange={(value) => changeStatuses(key, value, 'value')}
                      roundBorder
                      menuPosition="fixed"
                      size="small"
                    />

                    <ClearIcon
                      className="integration_step_two_fields_right_block_field_clear_btn"
                      onClick={() => deleteLine(key)}
                    />
                  </div>
                </div>
              )))
        ))}

        <Button
          addBtn
          btnType="transparent"
          color="#1472FF"
          onClick={addNewLine}
          className="add_line_btn"
        >
          Add new line
        </Button>
      </div>
    </div>
  );
};

export default StepThree;
