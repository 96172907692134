import React, { useEffect, useRef } from 'react';

const HtmlIframe = ({ html }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      iframe.srcdoc = `${html}<style>body{zoom: 25%; overflow: hidden}</style>`;
    }
  }, [html]);

  return (
    <iframe
      ref={iframeRef}
      style={{
        width: '100%', height: '100%', border: 'none', pointerEvents: 'none',
      }}
      title="HTML Content"
    />
  );
};

export default HtmlIframe;
