import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import Wrapper from '../../Layout/Wrapper';
import {
  bulkGenerateBarcodeRequest,
  changeProductTableAttribute,
  changeProductTableAttributeRequest,
  getPriseListAndAddTableAttribute,
  getProductsRequest,
  getProductsTableAttributes,
  productTableEditableEvent,
} from '../../../store/actions/products';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import Button from '../../_common/Form/Button';
import Filters from './Filters';
import Loader from '../../_common/Loader/Loader';
import Pagination from '../../_common/Pagination/Pagination';
import DynamicTable from '../../_common/DynamicTable/DynamicTable';
import { shopIcons } from '../../../assets/shops';
import noImage from '../../../assets/icons/default.svg';
import Formatter from '../../../helpers/Formatter';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_with_circle.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive_with_cirle.svg';
import { ReactComponent as EditProductsIcon } from '../../../assets/icons/edit_product.svg';
import { ReactComponent as EditPricingIcon } from '../../../assets/icons/edit_pricing.svg';
import { ReactComponent as PrintLabelsIcon } from '../../../assets/icons/print_labels.svg';
import { ReactComponent as BarcodeIcon } from '../../../assets/icons/barcode.svg';
import { ReactComponent as ShopIcon } from '../../../assets/icons/filters/shop.svg';
import { ReactComponent as WarehouseIcon } from '../../../assets/icons/filters/warehouse.svg';
import { ReactComponent as StockIcon } from '../../../assets/icons/filters/stock.svg';
import { ReactComponent as BrandIcon } from '../../../assets/icons/filters/brand.svg';
import { ReactComponent as CategoryIcon } from '../../../assets/icons/filters/category.svg';
import { ReactComponent as PriceIcon } from '../../../assets/icons/filters/price.svg';
import { ReactComponent as ProductTypeIcon } from '../../../assets/icons/filters/productType.svg';
// import { ReactComponent as UpcValidatorIcon } from '../../../assets/icons/ups_validator.svg';
import { ReactComponent as ReorderRulesIcon } from '../../../assets/icons/reorder_rules.svg';
import { ReactComponent as BalkArchiveIcon } from '../../../assets/icons/archive.svg';
import { ReactComponent as OpenChildIcon } from '../../../assets/icons/go_back.svg';
import { ReactComponent as UnArchiveIcon } from '../../../assets/icons/un_archive.svg';
import { ReactComponent as RestoreArchiveIcon } from '../../../assets/icons/restore_archive.svg';
import Api from '../../../Api';
import Utils from '../../../helpers/Utils';
import ImportModal from '../ImportCSVFile/ImportModal';
import ExportFileModal from '../ExportFile/ExportFileModal';
import ArchiveModal from '../../_common/Modal/ArchiveModal';
import WarningMessageModal from './BulkActionsModal/WarningMessageModal';
import BulkModal from './BulkModal';
import UpdateModal from '../../_common/Modal/UpdateModal';
import { barCodeFields } from './CreateComponents/ProductBarcode';
import TitleBar from '../../Layout/TitleBar';

const balkActions = [
  {
    label: (
      <>
        <EditProductsIcon />

        <p>Edit products</p>
      </>
    ),
    value: 'bulkEdit',
  },
  {
    label: (
      <>
        <EditPricingIcon />

        <p>Edit pricing</p>
      </>
    ),
    value: 'editPricing',
  },
  {
    label: (
      <>
        <PrintLabelsIcon />

        <p>Print labels</p>
      </>
    ),
    value: 'printLabels',
  },
  {
    label: (
      <>
        <BarcodeIcon />

        <p>Generate barcode</p>
      </>
    ),
    value: 'generateBarcode',
  },
  {
    label: (
      <>
        <ReorderRulesIcon />

        <p>Reorder rules</p>
      </>
    ),
    value: 'reorderRules',
  },
  // {
  //   label: (
  //     <>
  //       <UpcValidatorIcon />
  //
  //       <p>Upc validator</p>
  //     </>
  //   ),
  //   value: 'upcValidator',
  // },
  {
    label: (
      <>
        <BalkArchiveIcon />

        <p>Archive</p>
      </>
    ),
    value: 'archive',
  },
];

const defaultFilterData = [
  { label: 'Product type', valuePath: 'productType' },
  { label: 'Shop', valuePath: 'shopId' },
  { label: 'Stocks', valuePath: 'totalStock' },
  { label: 'Warehouses', valuePath: 'warehouseId' },
  { label: 'Brands', valuePath: 'brandId' },
  { label: 'Category', valuePath: 'categoryId' },
  { label: 'Price lists', valuePath: 'priceListId' },
];

const productFilterOptions = {
  productType: {
    label: 'Product type',
    path: 'productType',
    valuePath: 'productType',
    icon: <ProductTypeIcon />,
    staticData: [
      {
        label: 'Service',
        id: 'service',
      },
      {
        label: 'Product',
        id: 'product',
      },
      {
        label: 'Virtual',
        id: 'virtual',
      },
    ],
  },
  totalStock: {
    label: 'Stocks',
    path: 'totalStock',
    valuePath: 'totalStock',
    icon: <StockIcon />,
    staticData: [
      {
        label: 'Out of stock',
        id: ',0',
      },
      {
        label: 'Low stock',
        id: '1,5',
      },
    ],
  },
  shopId: {
    label: 'Shop',
    path: 'shop',
    valuePath: 'shopId',
    icon: <ShopIcon />,
    localSearch: true,
  },
  warehouseId: {
    icon: <WarehouseIcon />,
    label: 'Warehouses',
    path: 'warehouses',
    valuePath: 'warehouseId',
    localSearch: true,
  },
  brandId: {
    icon: <BrandIcon />,
    label: 'Brands',
    path: 'brand',
    valuePath: 'brandId',
  },
  categoryId: {
    icon: <CategoryIcon />,
    label: 'Category',
    path: 'category',
    valuePath: 'categoryId',
  },
  priceListId: {
    icon: <PriceIcon />,
    label: 'Price list',
    path: 'price-list',
    valuePath: 'priceListId',
  },
};

const titleFilter = [
  {
    label: 'All Items',
    path: 'parent,simple,bundle',
  },
  {
    label: 'Bundles',
    path: 'bundle',
  },
  {
    label: 'Variants',
    path: 'variant',
  },
  {
    label: 'Simples',
    path: 'simple',
  },
];

const defaultFiltersParams = withDefault(ArrayParam, []);

const Products = ({ pageName = '' }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectRef = useRef(null);
  const timeOutRef = useRef(null);
  const firstLoad = useRef(true);

  const dispatch = useDispatch();
  const productList = useSelector((state) => state.products.productList);
  const productTotalPage = useSelector((state) => state.products.productTotalPage);
  const columns = useSelector((state) => state.products.columns);
  const defaultAttributes = useSelector((state) => state.products.defaultAttributes);
  const priseList = useSelector((state) => state.products.priseList);
  const tableEditable = useSelector((state) => state.products.tableEditable);

  const [variantBundleChildData, setVariantBundleChildData] = useState({});
  const [loading, setLoading] = useState(true);
  const [childLoading, setChildLoading] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [archiveModal, setArchiveModal] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [openVariantBundleChild, setOpenVariantBundleChild] = useState([]);
  const [bulkProductsModal, setBulkProductsModal] = useState({});
  const [warningsModal, setWarningsModal] = useState([]);
  const [bulkGenerateBarcode, setBulkGenerateBarcode] = useState([]);

  const priceListCols = useMemo(() => {
    const data = {};
    priseList.forEach((p) => {
      data[`priceList_${p.id}`] = ({ item }) => (
        Formatter.price(item.prices.find((d) => p.id === d.priceListId)?.price, p.currencyCode) ?? '-'
      );
    });
    return data;
  }, [priseList]);

  const [queryData, setQuery] = useQueryParams({
    type: withDefault(StringParam, pageName === 'variants' ? 'variant' : 'parent,simple,bundle'),
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    priceListId: defaultFiltersParams,
    categoryId: defaultFiltersParams,
    brandId: defaultFiltersParams,
    status: defaultFiltersParams,
    productType: defaultFiltersParams,
    totalStock: defaultFiltersParams,
    warehouseId: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const { type } = queryData;

  const isArchive = pageName === 'archive';

  useEffect(() => {
    firstLoad.current = true;
  }, [pathname]);

  useEffect(() => {
    if (!titleFilter.find((d) => d.path === queryData.type)) {
      setQuery((prev) => ({ ...prev, type: 'bundle' }));
    }
  }, [type]);

  const getProductRequest = async () => {
    setLoading(true);
    clearTimeout(timeOutRef.current);

    if (firstLoad.current) {
      await dispatch(getProductsTableAttributes());
      await dispatch(getPriseListAndAddTableAttribute());
    }

    timeOutRef.current = setTimeout(async () => {
      await dispatch(getProductsRequest({
        ...Utils.arrayToString({
          ...queryData,
          shopId: queryData.shopId.length ? queryData.shopId : [0],
          totalStock: queryData.totalStock.length === 2 ? ',5' : (queryData.totalStock.join(',') || null),
        }),
        includes: 'stock,warehouse,priceList,prices,relations',
        limit: 20,
        isArchive: pageName === 'archive',
      }));

      setCheckedItems([]);
      firstLoad.current = false;
      setLoading(false);
    }, 400);
  };

  useEffect(() => {
    (async () => {
      await getProductRequest();
    })();

    return () => dispatch(productTableEditableEvent(false));
  }, [queryData, pathname]);

  useClickAway(selectRef, () => {
    setOpenSelect(false);
  });

  const changeTableAttribute = (attributes) => {
    dispatch(changeProductTableAttribute(attributes));
  };

  const handleArchive = useCallback(async () => {
    setArchiveLoading(true);

    try {
      if (pageName === 'archive') {
        await Api.restoreProduct(archiveModal.join(','));
      } else {
        await Api.archiveProduct(archiveModal.join(','));
      }

      if ((productList.length === 1 || productList.length === archiveModal.length) && +queryData.page > 1) {
        setQuery((prev) => ({ ...prev, page: +prev.page - 1 }));
      } else {
        await getProductRequest();
      }

      toast.success(`Products ${pageName === 'archive' ? 'restore' : 'archive'} successfully`);

      setArchiveModal([]);
    } catch (e) {
      toast.error('Something went wrong.Please try again.');
    }

    setArchiveLoading(false);
  }, [archiveModal, queryData.page, productList.length]);

  const saveChangedTableAttribute = async () => {
    try {
      setSaveLoading(true);
      await dispatch(changeProductTableAttributeRequest({ productsTableAttributes: columns }));

      dispatch(productTableEditableEvent(false));
      setSaveLoading(false);
    } catch (e) {
      toast.error('Something wrong place try again !');
    }
  };

  const loadOptions = async (filter, { search, page }) => {
    if (filter.staticData) {
      return { data: filter.staticData };
    }

    let data;
    let totalPages;

    if (filter.path === 'warehouses') {
      const { data: { warehouses: list } } = await Api.getWarehouses();

      data = list.map((warehouse) => ({ id: warehouse.id, label: warehouse.title }));
    }

    if (filter.path === 'shop') {
      const { data: { integrations } } = await Api.getIntegrations({ category: 'shop' });

      data = [{ id: '0', label: 'eSwap' }, ...integrations.map((s) => ({ id: s.id, label: s.name }))];
    }

    if (filter.path === 'price-list') {
      const pricesListData = await Api.getPriceList(Utils.deleteEmptyKeys({
        page: search ? 1 : page,
        s: search,
        includeIds: page === 1 ? queryData[filter.valuePath].join(',') : '',
      }));

      data = pricesListData.data.priceLists.map((s) => ({ id: s.id, label: s.name }));

      totalPages = pricesListData.data.totalPages;
    }

    if (filter.path === 'category') {
      const categoriesData = await Api.getCategories(Utils.deleteEmptyKeys({
        page: search ? 1 : page,
        s: search,
        includeIds: page === 1 ? queryData[filter.valuePath].join(',') : '',
      }));

      data = categoriesData.data.categories.map((s) => ({ id: s.id, label: s.name }));

      totalPages = categoriesData.data.totalPages;
    }

    if (filter.path === 'brand') {
      const brandesData = await Api.getBrands(Utils.deleteEmptyKeys({
        page: search ? 1 : page,
        s: search,
        includeIds: page === 1 ? queryData[filter.valuePath].join(',') : '',
      }));

      data = brandesData.data.brands.map((s) => ({ id: s.id, label: s.name }));

      totalPages = brandesData.data.totalPages;
    }

    return { data, totalPages };
  };

  const openVariantOrBundle = async (e, id) => {
    e.stopPropagation();

    setOpenVariantBundleChild((prev) => (prev.includes(id)
      ? prev.filter(((p) => p !== id))
      : [...prev, id]));

    if (!variantBundleChildData[id]) {
      setChildLoading(id);
      const { data: { product } } = await Api.getSingleProduct(id);

      setVariantBundleChildData((prev) => ({
        ...prev,
        [id]: product.type === 'parent'
          ? product.variants
          : product.bundles,
      }));

      setChildLoading('');
    }
  };

  const balkActionsClick = (path) => {
    if (path === 'archive') {
      setArchiveModal(checkedItems);
    } else if (path === 'generateBarcode') {
      setBulkGenerateBarcode(checkedItems);
    } else {
      setBulkProductsModal({ path, productLists: productList.filter((p) => checkedItems.includes(p.id)) });
    }
  };

  const createBarcode = async ({ format }) => {
    await dispatch(bulkGenerateBarcodeRequest({ productIds: bulkGenerateBarcode, barCodeType: format }));

    setBulkGenerateBarcode([]);
  };

  return (
    <Wrapper hideBackBtn className={classNames('products_list_wrapper', { no_archive: !isArchive })}>
      {isArchive ? (
        <TitleBar title={_.capitalize(pageName)} />
      ) : (
        <TitleBar
          backButton={false}
          actions={titleFilter}
          isActionActive={(d) => d.path === type}
          onActionClick={(d) => setQuery((prev) => ({ ...prev, type: d.path, page: 1 }))}
        >
          <Button
            title="Add new"
            addBtn
            href="/products/add"
            data-scope="WRITE_PRODUCTS"
          />
        </TitleBar>
      )}

      <div className="products_wrapper">
        {firstLoad.current
          ? <Loader />
          : (
            <>
              <Filters
                queryData={queryData}
                setQuery={setQuery}
                showActionButtons={!!checkedItems.length}
                balkActions={pageName === 'archive' ? [{
                  label: (
                    <>
                      <RestoreArchiveIcon />

                      <p>Restore products</p>
                    </>
                  ),
                  value: 'archive',
                }] : balkActions}
                balkActionsClick={balkActionsClick}
                tableEditable={tableEditable}
                defaultFilterData={defaultFilterData}
                onImportClick={pageName === 'archive' ? null : () => setOpenImportModal(true)}
                onExportClick={pageName === 'archive' ? null : () => setOpenExportModal(true)}
                filterOptions={productFilterOptions}
                loadOptions={loadOptions}
                onEditableTableClick={() => dispatch(productTableEditableEvent(true))}
              />

              <DynamicTable
                onChange={changeTableAttribute}
                loading={loading}
                onSelect={setCheckedItems}
                data={productList}
                checkedItems={checkedItems}
                editable={tableEditable}
                checkbox
                keyExtractor={(item) => item.id}
                columns={columns}
                callDragDisabled={(call) => ['item'].includes(call.key)}
                renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
                renderColumns={{
                  item: ({ item }) => (
                    <div
                      className="product_item_wrapper"
                      role="button"
                      tabIndex="0"
                      onClick={() => navigate(item.type === 'variant'
                        ? `/products/${item.parentId}?productVariantId=${item.id}`
                        : `/products/${item.id}`)}
                      data-test-id={item.id}
                    >
                      {((item.type === 'bundle' || item.type === 'parent') && item?.totalStock?.totalItems > 0) && (
                        <div
                          role="button"
                          tabIndex="0"
                          onClick={(e) => openVariantOrBundle(e, item.id)}
                          className={classNames(
                            'product_open_item_child',
                            { active: openVariantBundleChild.includes(item.id) },
                          )}
                        >
                          <OpenChildIcon />
                        </div>
                      )}

                      <div className="product_item_img">
                        <img
                          src={item.images[0]?.src || item.images[0]?.medium || noImage}
                          alt="img"
                        />
                      </div>

                      <div className="product_item_title">
                        {(item.type === 'bundle' || item.type === 'parent')
                          && (
                            <div className={classNames(
                              'product_item_type',
                              { parent: item.type === 'parent' },
                            )}
                            >
                              {item.type === 'parent'
                                ? `Variant ${item?.totalStock?.totalItems || 0}`
                                : `Bundles ${item?.totalStock?.totalItems || 0}`}
                            </div>
                          )}

                        <p>{item.title}</p>
                      </div>
                    </div>
                  ),

                  shopType: ({ item }) => {
                    const icons = [shopIcons[item.shopType] || shopIcons.eswap,
                      ...item.relations.map((r) => shopIcons[r.shopType] || shopIcons.eswap)];

                    return (
                      <div className="products_shop_link_icons_wrapper">
                        {icons.slice(0, icons.length > 4 ? 3 : 4).map((Icon, index) => (
                          <div className="products_shop_icon_wrapper" key={index} style={{ left: index * 15 }}>
                            <Icon />
                          </div>
                        ))}

                        {icons.length > 4 && (
                          <div className="products_shop_icon_wrapper" style={{ left: 3 * 15 }}>
                            {`+${icons.length - 4}`}
                          </div>
                        )}
                      </div>

                    );
                  },
                  sku: ({ item }) => item.sku || '-',
                  category: ({ item }) => item.categories.map((c) => c.name).join(', ') || '-',
                  totalStock: ({ item }) => item.totalStock?.stock || '0',
                  available: ({ item }) => item.totalStock?.available || '0',
                  createdAt: ({ item }) => moment(item.createdAt).format('MM/DD/YY'),

                  ...priceListCols,
                  actions: ({ item }) => (
                    <div className="products_actions_wrapper">
                      <div
                        role="button"
                        tabIndex="0"
                        data-scope="WRITE_PRODUCTS"
                        onClick={() => navigate(item.type === 'variant'
                          ? `/products/${item.parentId}?productVariantId=${item.id}`
                          : `/products/${item.id}`)}
                      >
                        <EditIcon />
                      </div>

                      <div
                        role="button"
                        tabIndex="0"
                        onClick={() => setArchiveModal([item.id])}
                        data-scope="DELETE_PRODUCTS"
                      >
                        {pageName === 'archive' ? <UnArchiveIcon /> : <ArchiveIcon />}
                      </div>
                    </div>
                  ),
                }}
                openChildData={openVariantBundleChild}
                renderChildColumns={{
                  item: ({ item }) => (
                    <div
                      className="product_item_wrapper"
                      role="button"
                      tabIndex="0"
                      onClick={() => navigate(item.type === 'variant'
                        ? `/products/${item.parentId}?productVariantId=${item.id}`
                        : `/products/${item.id}`)}
                    >
                      <div className="product_item_img">
                        <img
                          src={item.images[0]?.src || item.images[0]?.medium || noImage}
                          alt="img"
                        />
                      </div>

                      <div className="product_item_title">
                        <p>{item.title}</p>
                      </div>
                    </div>
                  ),
                  shopType: ({ item }) => {
                    const icons = [shopIcons[item.shopType] || shopIcons.eswap,
                      ...item.relations.map((r) => shopIcons[r.shopType] || shopIcons.eswap)];

                    return (
                      <div className="products_shop_link_icons_wrapper">
                        {icons.slice(0, icons.length > 4 ? 3 : 4).map((Icon, index) => (
                          <div className="products_shop_icon_wrapper" key={index} style={{ left: index * 15 }}>
                            <Icon />
                          </div>
                        ))}

                        {icons.length > 4 && (
                          <div className="products_shop_icon_wrapper" style={{ left: 3 * 15 }}>
                            {`+${icons.length - 4}`}
                          </div>
                        )}
                      </div>

                    );
                  },
                  sku: ({ item }) => item.sku || '-',
                  category: ({ item }) => item.categories.map((c) => c.name).join(', ') || '-',
                  totalStock: ({ item }) => item.totalStock?.stock || '0',
                  available: ({ item }) => item.totalStock?.available || '0',
                  createdAt: ({ item }) => moment(item.createdAt).format('MM/DD/YY'),

                  ...priceListCols,
                  actions: ({ item }) => (
                    <div className="products_actions_wrapper">
                      <div
                        role="button"
                        tabIndex="0"
                        onClick={() => navigate(item.type === 'variant'
                          ? `/products/${item.parentId}?productVariantId=${item.id}`
                          : `/products/${item.id}`)}
                      >
                        <EditIcon />
                      </div>
                    </div>
                  ),
                }}
                childData={variantBundleChildData}
                childLoading={childLoading}
              />

              {+productTotalPage > 1 && (
                <div className="products_pagination">
                  <Pagination
                    page={queryData.page}
                    totalPages={+productTotalPage}
                  />
                </div>
              )}

            </>
          )}
      </div>

      {tableEditable
        && (
          <div className="products_wrapper_table_changed_save">
            <Button
              className="table_changed_save_button"
              size="small"
              title="Cancel"
              onClick={() => dispatch(productTableEditableEvent(false))}
              roundBorder
              btnType="cancel"
            />

            <Button
              className="table_changed_save_button"
              size="small"
              title="Save changes"
              onClick={saveChangedTableAttribute}
              roundBorder
              color="#1472FF"
              loading={saveLoading}
            />
          </div>
        )}

      {!_.isEmpty(bulkProductsModal) && (
        <BulkModal
          bulkProductsModal={bulkProductsModal}
          setBulkProductsModal={setBulkProductsModal}
          setWarningsModal={setWarningsModal}
          className="bulk_reorder_rules_modal_wrapper"
          priceListCols={priceListCols}
        />
      )}

      {!!bulkGenerateBarcode.length && (
        <UpdateModal
          id="barcode_modal"
          isOpen={!!bulkGenerateBarcode.length}
          onClose={() => setBulkGenerateBarcode([])}
          fields={[barCodeFields[0]]}
          onSave={createBarcode}
          zIndex={1000}
        />
      )}

      <WarningMessageModal
        warningProducts={warningsModal}
        onClose={() => setWarningsModal([])}
        onClick={() => {}}
      />

      <ArchiveModal
        isOpen={!!archiveModal.length}
        text="products"
        onArchive={handleArchive}
        loading={archiveLoading}
        onClose={() => setArchiveModal([])}
        restore={pageName === 'archive'}
      />

      <ImportModal
        isOpen={openImportModal}
        onClose={() => setOpenImportModal(false)}
        type="products"
      />

      <ExportFileModal
        isOpen={openExportModal}
        onClose={() => setOpenExportModal(false)}
        selectedItemsId={checkedItems}
        name="productsExport"
      />
    </Wrapper>
  );
};

export default Products;
