import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Api from '../../../Api';
import Table from '../../_common/Tables/Table';
import Loader from '../../_common/Loader/Loader';
import Input from '../../_common/Form/Input';
import Pagination from '../../_common/Pagination/Pagination';
import Select from '../../_common/Form/Select';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as UnlockIcon } from '../../../assets/icons/unlock.svg';
import { ReactComponent as DataIcon } from '../../../assets/icons/data.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filters.svg';
import Utils from '../../../helpers/Utils';
import Button from '../../_common/Form/Button';
import Checkbox from '../../_common/Form/Checkbox';
import Account from '../../../helpers/Account';
import { getAccountRequest, getCurrentSubscription } from '../../../store/actions/users';
import { destroySocket, socketInit } from '../../../store/actions/socket';
import { getThreadsRequest, resetChatBot } from '../../../store/actions/chatBot';
import { getSetupRequest } from '../../../store/actions/notifications';

const tableHeader = [
  {
    title: 'Email',
    path: 'email',
  },
  {
    title: 'Plan',
    path: 'plan',
  },
  {
    title: 'Status',
    path: 'status',
  },
  {
    title: 'Orders',
    path: 'orders',
  },
  {
    title: 'Channels',
    path: 'channels',
  },
  {
    title: 'Child users',
    path: 'parentId',
  },
  {
    title: 'Reached the limits',
    path: 'reachedTheLimits',
  },
  {
    title: 'Utm source',
    path: 'utmSource',
  },
  {
    title: 'Created At',
    path: 'createdAt',
    isSelectable: true,
  },
  {
    title: 'Last login',
    path: 'lastLoginAt',
  },
];

const titleFilter = [
  {
    label: 'Users - all',
    path: '',
    text: 'All',
  },
  {
    label: 'Users - admin',
    path: 'true',
    text: 'Admin',
  },
  {
    label: 'Users - user',
    path: 'false',
    text: 'User',
  },
];

const menuOptions = [
  {
    label: <div className="menu_option_single">
      <UnlockIcon />
      <p>Sign in</p>
    </div>,
    value: 'signIn',
  },
  {
    label: <div className="menu_option_single">
      <DataIcon />
      <p>User all data</p>
    </div>,
    value: 'userAllData',
  },
  {
    label: <div className="menu_option_single">
      <DeleteIcon className="menu_option_single_delete_icon" />
      <p className="menu_option_single_delete_text">Delete user</p>
    </div>,
    value: 'deleteUser',
  },
];

const parentFields = [
  {
    path: 'childUser',
    label: 'All',
    value: '',
  },
  {
    path: 'childUser',
    label: 'Parent',
    value: 'false',
  },
  {
    path: 'childUser',
    label: 'Child',
    value: 'true',
  },
];

const limitFields = [
  {
    path: 'reachedLimit',
    label: 'All',
    value: '',
  },
  {
    path: 'reachedLimit',
    label: 'Yes',
    value: 'true',
  },
  {
    path: 'reachedLimit',
    label: 'No',
    value: 'false',
  },
];

const statusFields = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Restricted',
    value: 'restricted',
  },
  {
    label: 'Deleted',
    value: 'deleted',
  },
];

function AdminUsers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstLoad = useRef(true);
  const selectRef = useRef(null);
  const filtersRef = useRef(null);
  const timeoutRef = useRef(null);

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    isAdmin: withDefault(StringParam, ''),
    plan: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    reachedLimit: withDefault(StringParam, ''),
    childUser: withDefault(StringParam, ''),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const { isAdmin } = queryData;

  const [tableLoading, setTableLoading] = useState(true);
  const [openSelect, setOpenSelect] = useState(false);
  const [filtersIsOpen, setFiltersOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      setTableLoading(true);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (firstLoad.current) {
        const { data } = await Api.getPlanListForAdmin({ paginated: false });

        setPlans([{ publicName: 'All', id: '' }, ...data.plans]);
      }

      timeoutRef.current = setTimeout(async () => {
        const requestData = { ...Utils.deleteEmptyKeys({ ...queryData, ...sorting }) };

        const isAdminFields = queryData.isAdmin ?? JSON.parse(queryData.isAdmin);
        const childUserFields = queryData.childUser ?? JSON.parse(queryData.childUser);
        const reachedLimitFields = queryData.reachedLimit ?? JSON.parse(queryData.reachedLimit);

        requestData.isAdmin = isAdminFields;
        requestData.childUser = childUserFields;
        requestData.reachedLimit = reachedLimitFields;

        await getUsers(requestData);

        setTableLoading(false);
        firstLoad.current = false;
      }, 300);
    })();
  }, [queryData, sorting]);

  const getUsers = useCallback(async (filterData) => {
    const { data } = await Api.getAdminUsers({
      ...Utils.deleteEmptyKeys(filterData),
    });

    setTotalPages(data.totalPages);
    setUsers(data.users);
  }, []);

  useClickAway(selectRef, () => {
    setOpenSelect(false);
  });

  useClickAway(filtersRef, () => {
    setFiltersOpen(false);
  });

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, [sorting]);

  const onMenuSelectClick = useCallback(async (path, id) => {
    if (path === 'userAllData') {
      navigate(`/users/edit/${id}`);
    } else if (path === 'signIn') {
      const foundUser = users.find((u) => +u.id === +id);

      Account.setUserId(id);
      Account.setOwnerId(+foundUser.parentId || id);

      dispatch(resetChatBot());
      dispatch(destroySocket());

      await Promise.all([dispatch(getAccountRequest(id)), dispatch(getCurrentSubscription())]);
      await Promise.all([dispatch(getSetupRequest()), dispatch(getThreadsRequest())]);

      dispatch(socketInit());
      navigate('/dashboard');
    } else {

    }
  }, [users]);

  const onQueryDataChange = useCallback((path, value) => {
    setFiltersOpen(false);

    setQuery((prev) => ({
      ...prev,
      [path]: value,
    }));
  }, []);

  return (
    <div className="admin_users">
      <div className="admin_users_filters">
        <div
          role="button"
          tabIndex="0"
          onClick={() => setOpenSelect((prev) => !prev)}
          className={classNames('products_header_select_wrapper', { active: openSelect })}
          ref={selectRef}
        >
          <p className="products_header_title">
            {`${titleFilter.find((d) => d.path === isAdmin)?.label}`}
          </p>

          <ArrowIcon />

          {openSelect && (
          <div className="products_header_select_block">
            {titleFilter.map((d) => (
              <div
                key={d.path}
                role="button"
                tabIndex={0}
                onClick={() => onQueryDataChange('isAdmin', d.path)}
                className={classNames('', { active: d.path === isAdmin })}
              >
                {d.text}
              </div>
            ))}
          </div>
          )}
        </div>

        <div className="admin_users_filters_bottom">
          <div className="admin_users_search_field">
            <Input
              placeholder="Search title"
              roundBorder
              size="small"
              search
              value={queryData.s}
              onChangeText={(value) => setQuery((prev) => ({
                ...prev,
                s: value,
              }))}
            />
          </div>

          <div className="admin_users_filters_btn_wrapper" ref={filtersRef}>
            <Button
              onClick={() => setFiltersOpen((prev) => !prev)}
              roundBorder
              title="Filters"
              iconLeft={<FilterIcon />}
              btnType="transparent"
              className="admin_users_filters_btn"
            />

            {filtersIsOpen && (
              <div className="filters_open_menu">
                <div className="admin_users_selects_block">
                  <Select
                    size="small"
                    roundBorder
                    options={plans}
                    onChange={(value) => onQueryDataChange('plan', value)}
                    value={queryData.plan}
                    valuePath="id"
                    labelPath="publicName"
                    placeholder="Plans"
                    label="Subscription plans"
                  />

                  <Select
                    size="small"
                    roundBorder
                    options={statusFields}
                    value={queryData.status}
                    onChange={(value) => onQueryDataChange('status', value)}
                    label="Status"
                  />

                  <div className="admin_users_selects_radio_block">
                    <p>Users type</p>

                    <div className="admin_users_selects_radio_all">
                      {parentFields.map(({ path, value, label }) => (
                        <Checkbox
                          onChange={() => onQueryDataChange(path, value)}
                          checked={queryData[path] === value}
                          radio
                          label={label}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="admin_users_selects_radio_block">
                    <p>Reached limit</p>

                    <div className="admin_users_selects_radio_all">
                      {limitFields.map(({ path, value, label }) => (
                        <Checkbox
                          onChange={() => onQueryDataChange(path, value)}
                          checked={queryData[path] === value}
                          radio
                          label={label}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="admin_users_table">
        {firstLoad.current ? <Loader /> : (
          <>
            <Table
              data={users}
              header={tableHeader}
              sortBy={sorting.sortBy}
              sort={sorting.sort}
              onSortBy={onSortBy}
              loading={tableLoading}
              menuOptions={menuOptions}
              onMenuSelect={(id, path) => onMenuSelectClick(path, id)}
            />

            <Pagination onChangePage={(p) => setQuery((prev) => ({ ...prev, page: p }))} totalPages={totalPages} />
          </>
        )}
      </div>
    </div>
  );
}

export default AdminUsers;
