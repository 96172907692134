import React, { useState } from 'react';
import { currencies } from 'currency-formatter';
import _ from 'lodash';
import moment from 'moment/moment';
import classNames from 'classnames';
import Select from '../../../_common/Form/Select';
import Datepicker from '../../../_common/Form/Datepicker';
import Switcher from '../../../_common/Form/Switcher';
import WarningModal from '../../../_common/Modal/WarningModal';

const settings = {
  general: {
    currency: {
      title: 'Currency',
      fields: [
        {
          label: 'Set default currency',
          path: 'currencyCode',
          type: 'select',
          options: currencies,
          labelPath: 'code',
          valuePath: 'code',
        },
      ],
    },
    syncSettings: {
      title: 'Synchronization settings',
      fields: [
        // {
        //   label: 'Sync products stocks from shop to eSwap',
        //   path: 'configs.receiveProductsStocks',
        //   type: 'toggle',
        // },
        // {
        //   label: 'Sync products prices from shop to eSwap',
        //   path: 'configs.receiveProductsPrices',
        //   type: 'toggle',
        // },
        // {
        //   label: 'Sync products categories from shop to eSwap',
        //   path: 'configs.receiveProductsCategories',
        //   type: 'toggle',
        // },
        {
          label: 'Products stock sync options',
          path: 'configs.syncProductsStocks',
          type: 'select',
          options: [
            { label: 'from eSwap to shop', value: '0' },
            { label: 'from shop to eSwap', value: '1' },
          ],
          className: 'products_sync_option',
        },
        {
          label: 'Products price sync options',
          path: 'configs.syncProductsPrices',
          type: 'select',
          options: [
            { label: 'from eSwap to shop', value: '0' },
            { label: 'from shop to eSwap', value: '1' },
          ],
          className: 'products_sync_option',
        },
        // {
        //   label: 'Sync products categories from eSwap to shop',
        //   path: 'configs.syncProductsCategories',
        //   type: 'toggle',
        // },
      ],
    },
    import: {
      title: 'Import',
      fields: [
        {
          label: 'Import orders from',
          description: 'This setting will work for the first orders import. For the other imports eSwap will grab the orders starting from the last order date.',
          path: 'orderImportStartDate',
          type: 'datePicker',
        },
      ],
    },
    // copyList: { // ???
    //   title: '',
    //   fields: [
    //     {
    //       label: 'List copy products in eSwap shop',
    //       description: 'All new incoming products from shop will be automatically listed to eSwap and linked.',
    //       path: 'configs.copy',
    //       type: 'toggle',
    //     },
    //   ],
    // },
    // quantity: {
    //   title: '',
    //   fields: [
    //     {
    //       label: 'Quantity control',
    //       description: 'Allow negative stock',
    //       path: 'configs.allowNegativeStocks',
    //       type: 'toggle',
    //     },
    //   ],
    // },
    enableZeroStock: { // ???
      title: '',
      fields: [
        {
          label: 'Enable/Disable zero stock',
          description: 'To set product stock to 0, this setting need to be enabled. \n'
          + 'Otherwise, when eSwap will try to update the product stock to 0, you might encounter this error - "The quantity must be a.svg valid number greater than 0."',
          path: 'configs.enableZeroStock',
          type: 'toggle',
        },
      ],
    },
  },
  // email: {
  //   quantity: {
  //     title: 'Manage which emails and notifications you receive',
  //     fields: [
  //       {
  //         label: 'Purchase order',
  //         path: 'configs.salesOrderEmail',
  //         type: 'toggle',
  //       },
  //       {
  //         label: 'Sales order',
  //         path: 'configs.stockTransferEmail',
  //         type: 'toggle',
  //       },
  //       {
  //         label: 'Stock transfer',
  //         path: 'configs.stockTransferEmail',
  //         type: 'toggle',
  //       },
  //     ],
  //   },
  // },

  accounting: {
    currency: {
      title: 'Currency',
      fields: [
        {
          label: 'Set default currency',
          path: 'currencyCode',
          type: 'select',
          options: currencies,
          labelPath: 'code',
          valuePath: 'code',
        },
      ],
    },
    addItems: {
      title: '',
      fields: [
        {
          label: 'Add items to accounting',
          description: '',
          path: 'addItemToAccounting',
          type: 'toggle',
        },
      ],
    },
  },
};

const General = ({
  data, changeData, isSyncedIntegration, settingsPath = 'general',
}) => {
  const [modalInfo, setModalInfo] = useState({});

  const selectSettings = (path, value) => {
    if (isSyncedIntegration && path === 'configs.syncProductsStocks' && value === '1') {
      setModalInfo({ path, value });
    } else {
      changeData((prev) => {
        const newData = { ...prev };
        _.set(newData, path, value);

        return newData;
      });
    }
  };

  const saveOption = () => {
    const { path, value } = modalInfo;
    changeData((prev) => ({ ...prev, [path]: value }));

    setModalInfo({});
  };

  return (
    <div className="integration_general_settings">
      {_.map(settings[settingsPath], ({ fields, title }, key) => (
        <div key={key} className="integration_general_settings_wrapper">
          <h2 className="integration_general_settings_title">{title}</h2>

          <div className="integration_general_settings_fields_block">
            {fields.map(({
              label, description, path, type, options, labelPath, valuePath, className = '',
            }) => (
              <div key={path} className="integration_general_settings_fields_wrapper">
                <div className="integration_general_settings_fields_title">
                  <h3>
                    {label}
                  </h3>

                  {description && (
                    <p>
                      {description}
                    </p>
                  )}
                </div>

                <div className="integration_general_settings_fields">
                  {type === 'datePicker'
                    ? (
                      <Datepicker
                        onChange={(date) => selectSettings(path, moment(date).format('YYYY-MM-DD'))}
                        value={_.get(data, path) ? moment(_.get(data, path)).format('MM.DD.YYYY') : undefined}
                      />
                    )

                    : type === 'select'
                      ? (
                        <Select
                          value={_.get(data, path, '0')}
                          onChange={(value) => selectSettings(path, value)}
                          options={options}
                          valuePath={valuePath}
                          labelPath={labelPath}
                          roundBorder
                          size="small"
                          isSearchable
                          lightBlue
                          wrapperClassName={classNames('integration_general_settings_fields_select', className)}
                        />
                      )

                      : (
                        <Switcher
                          checked={!!+_.get(data, path)}
                          onChange={(check) => selectSettings(path, check ? '1' : '0')}
                        />
                      )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <WarningModal
        isOpen={!_.isEmpty(modalInfo)}
        onClose={() => setModalInfo({})}
        onSaveClick={saveOption}
        text="You already have a sales channel where synchronization from the shop to eSwap is enabled. If you enable
        this on a second shop, it may cause stock issues. To avoid this, we recommend using this feature on only one
        sales channel."
        btnText="Ok"
      />
    </div>
  );
};

export default General;
