import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { ReactComponent as ProductsIcon } from '../../assets/icons/products.svg';
import { ReactComponent as SalesOrdersIcon } from '../../assets/icons/sales_orders.svg';
import { ReactComponent as PurchaseOrdersIcon } from '../../assets/icons/purchase_orders.svg';
import { ReactComponent as CustomersIcon } from '../../assets/icons/customers.svg';
import { ReactComponent as CarriersIcon } from '../../assets/icons/carriers.svg';
import { ReactComponent as AutomationIcon } from '../../assets/icons/automation.svg';
import { ReactComponent as PlansIcon } from '../../assets/icons/plans.svg';
import { ReactComponent as LogsIcon } from '../../assets/icons/logs.svg';
import { ReactComponent as AFCIcon } from '../../assets/icons/afc.svg';
import { ReactComponent as AppsIcon } from '../../assets/icons/apps.svg';
import { ReactComponent as MyAppsIcon } from '../../assets/icons/my_apps.svg';
import { ReactComponent as InventoryIcon } from '../../assets/icons/inventory.svg';
import { ReactComponent as OrdersIcon } from '../../assets/icons/orders.svg';
import { ReactComponent as CRMIcon } from '../../assets/icons/crm.svg';
import { ReactComponent as StocksIcon } from '../../assets/icons/stocks.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/reports.svg';
import Account from '../../helpers/Account';

export const nav = {
  user: [
    {
      label: 'Dashboard',
      path: '/dashboard',
      icon: <DashboardIcon />,
    },
    {
      label: 'Products',
      path: '/products',
      icon: <ProductsIcon />,
      scope: 'READ_PRODUCTS',
    },
    {
      label: 'Sales Orders',
      path: '/orders/sales-order',
      icon: <SalesOrdersIcon />,
      scope: 'READ_ORDERS',
    },
    {
      label: 'Purchase Orders',
      path: '/stocks/purchase-orders',
      icon: <PurchaseOrdersIcon />,
      scope: 'READ_PURCHASE_ORDERS',
    },
    {
      label: 'Customers',
      path: '/crm/customers',
      icon: <CustomersIcon />,
      scope: 'READ_CUSTOMERS',
    },
    {
      label: 'Automation',
      path: '/automation',
      icon: <AutomationIcon />,
    },
    {
      label: 'My apps',
      path: '/my-apps',
      icon: <MyAppsIcon />,
      scope: 'READ_INTEGRATIONS',
    },
    {
      label: 'Inventory',
      path: '/inventory/categories',
      icon: <InventoryIcon />,
      mobile: true,
    },
    {
      label: 'Orders',
      path: '/orders/sales-order',
      icon: <OrdersIcon />,
      mobile: true,
      scope: 'READ_ORDERS',
    },
    {
      label: 'CRM',
      path: '/crm/customers',
      icon: <CRMIcon />,
      mobile: true,
      scope: 'READ_CUSTOMERS',
    },
    {
      label: 'Stocks',
      path: '/stocks/purchase-orders',
      icon: <StocksIcon />,
      mobile: true,
      scope: 'READ_PURCHASE_ORDERS',
    },
    {
      label: 'Settings',
      path: '/settings',
      icon: <SettingsIcon />,
      mobile: true,
    },
    {
      label: 'Reports',
      path: '/reports/overview',
      icon: <ReportsIcon />,
      mobile: true,
    },
  ],

  admin: [
    {
      label: 'Dashboard',
      path: '/dashboard',
      icon: <DashboardIcon />,
    },
    {
      label: 'Users',
      path: '/users',
      icon: <CustomersIcon />,
    },
    // {
    //   label: 'CrmRoles',
    //   path: '/roles',
    //   icon: <RolesIcon />,
    // },
    {
      label: 'Carriers',
      path: '/carriers',
      icon: <CarriersIcon />,
    },
    {
      label: 'Customers',
      path: '/crm/users',
      icon: <CRMIcon />,
      mobile: true,
    },
    {
      label: 'Plans',
      path: '/plans',
      icon: <PlansIcon />,
    },
    {
      label: 'Logs',
      path: '/logs',
      icon: <LogsIcon />,
    },
    {
      label: 'AFC',
      path: '/amazon-centers',
      icon: <AFCIcon />,
    },
  ],
};

const LayoutMenu = () => {
  const { pathname } = useLocation();
  const { isAdmin = false } = Account.get();

  const menu = nav[isAdmin ? 'admin' : 'user'];
  return (
    <aside className="auth_layout_aside">
      <NavLink to="/" className="auth_layout_aside_logo">
        <LogoIcon />
      </NavLink>

      <nav className="auth_layout_aside_nav">
        <div className="auth_layout_aside_nav_menu">
          {menu.map(({
            path, label, icon, mobile, scope,
          }) => (
            !mobile && (
              <NavLink
                className="auth_layout_aside_nav_menu_link"
                to={path}
                key={path}
                data-scope={scope}
              >
                <div className="auth_layout_aside_nav_menu_link_icon">
                  {icon}
                </div>

                <div className="auth_layout_aside_nav_menu_link_label">{label}</div>
              </NavLink>
            )
          ))}
        </div>

        {!isAdmin && (
          <div data-scope="WRITE_INTEGRATIONS" className="auth_layout_aside_nav_app">
            <NavLink
              className={classNames('auth_layout_aside_nav_menu_link', {
                active: !pathname.includes('my-apps') && pathname.includes('apps'),
              })}
              to="/apps/list/all"
            >
              <div className="auth_layout_aside_nav_menu_link_icon">
                <AppsIcon />
              </div>

              <div className="auth_layout_aside_nav_menu_link_label">Apps</div>
            </NavLink>
          </div>
        )}
      </nav>
    </aside>
  );
};

LayoutMenu.propTypes = {};

export default LayoutMenu;
