import React, { useCallback, useEffect, useState } from 'react';
import { currencies, unformat } from 'currency-formatter';
import moment from 'moment/moment';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import Button from '../../components/_common/Form/Button';
import Input from '../../components/_common/Form/Input';
import Select from '../../components/_common/Form/Select';
import Datepicker from '../../components/_common/Form/Datepicker';
import OrderProducts from '../../components/pages/Orders/OrderProducts';
import OrderProductsList from '../../components/pages/Orders/OrderProductsList';
import OrderCustomer from '../../components/pages/Orders/OrderCustomer';
import Loader from '../../components/_common/Loader/Loader';
import PrintDocumentModal from '../../components/_common/Modal/PrintDocumentModal';
import Api from '../../Api';
import PaymentsTermForm from '../../components/pages/Settings/General/PaymentsTermForm';
import PriceListForm from '../../components/pages/Inventory/PriceLists/PriceListForm';
import { ReactComponent as PrintIcon } from '../../assets/icons/remote_printing.svg';
import { ReactComponent as QuotationIcon } from '../../assets/icons/quotation.svg';
import {
  createOrderRequest,
  generateOrderNumberRequest, getOrderTagsRequest, getSingleOrderRequest,
  updateOrderRequest,
} from '../../store/actions/orders';
import {
  getCompaniesRequest,
  getSettingsRequest,
  getTaxTypesRequest,
  getWarehousesRequest,
} from '../../store/actions/settings';
import { getPriceListsRequest } from '../../store/actions/priceLists';
import { getCountriesRequest } from '../../store/actions/app';
import { getProductsRequest } from '../../store/actions/products';
import { getSingleCustomerRequest, removeCustomerData } from '../../store/actions/customers';
import { getUsersListRequest } from '../../store/actions/users';
import TagFormModal from '../../components/pages/Settings/Tags/TagFormModal';
import MultiSelect from '../../components/_common/Form/MultiSelect';
import CreateCompanyModal from '../../components/pages/Settings/Company/CreateCompanyModal';
import { getWarehouseList } from '../../store/actions/stockTransfer';

const taxes = [
  { label: 'Inclusive', value: 'inclusive' },
  { label: 'Exclusive', value: 'exclusive' },
];

function PurchaseOrderForm() {
  const [loadingData, setLoadingData] = React.useState(false);
  const [form, setForm] = useState({
    orderProducts: [],
    products: [],
    type: 'purchase',
    status: 'pending',
    taxType: 'inclusive',
    sourceCreatedAt: moment().format('YYYY-MM-DD'),
    paymentTerm: {},
    landedCosts: [],
    tags: [],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState('');
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [createPaymentTermModal, setCreatePaymentTermModal] = useState(false);
  const [createPriceListModal, setCreatePriceListModal] = useState(false);
  const [createTagModal, setCreateTagModal] = useState(false);
  const [createCompanyModal, setCreateCompanyModal] = useState(false);

  const { orderId, quotation } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const priceLists = useSelector((state) => state.priceLists.priceLists);
  const orderNumber = useSelector((state) => state.orders.orderNumber);
  const customer = useSelector((state) => state.customers.customer);
  const {
    paymentTerms, currencyCode, warehouses, companies,
  } = useSelector((state) => state.settings.generalList);
  const taxTypes = useSelector((state) => state.settings.taxTypes.taxes);
  const profile = useSelector((state) => state.users.profile);
  const users = useSelector((state) => state.users.usersList);
  const orderTags = useSelector((state) => state.orders.orderTags);
  const countries = useSelector((state) => state.app.countries);

  useMount(() => dispatch(removeCustomerData()));

  useEffect(() => {
    if (orderId) {
      (async () => {
        setLoadingData(true);
        const { payload: { data } } = await dispatch(getSingleOrderRequest(orderId));
        const order = _.cloneDeep(data.order);
        if (order) {
          let ids = order.orderProducts.map((o) => o.productId);

          let orderProducts = order.orderProducts.map((o) => ({
            id: o.id,
            productId: o.productId,
            price: o.price || 0,
            discountPercentage: o.discountPercentage || 0,
            qty: o.qty || 1,
            taxPercentage: o.taxPercentage || 0,
            taxId: o.taxId || 0,
            warehouseId: o.warehouseId,
            type: o.type,
            title: o.title,
          }));

          if (searchParams.get('productId')) {
            ids = [...ids, searchParams.get('productId')];
          }

          const { payload: { data: { products } } } = await dispatch(getProductsRequest({
            ids,
            limit: ids.length,
            includes: 'stock,warehouse',
          }));

          if (searchParams.get('productId')) {
            const prod = products.find((p) => p.id === searchParams.get('productId'));
            orderProducts = [...orderProducts, {
              productId: prod.id,
              price: prod.prices.find((pr) => pr.priceListId === order)?.price || prod.prices?.[0]?.price || 0,
              qty: 1,
              warehouseId: prod.warehouses.find((w) => w.isDefault)?.id,
            }];
          }

          const customerId = searchParams.get('customerId') ? searchParams.get('customerId') : order.customerId;
          let addresses = {};
          if (customerId) {
            const { payload } = await dispatch(getSingleCustomerRequest(customerId));
            addresses = {
              shippingAddressId: searchParams.get('customerId')
                ? payload.data.customer.shippingAddresses[0]?.id : order.shippingAddressId,
              billingAddressId: searchParams.get('customerId')
                ? payload.data.customer.billingAddress?.id : order.billingAddressId,
            };
          }

          setForm({
            ...order,
            landedCosts: order.landedCosts ? order.landedCosts.map((l) => ({
              title: l.title,
              price: l.price,
              splitMethod: l.splitMethod,
              taxPercentage: l.taxPercentage,
              id: l.id,
            })) : undefined,
            customerId,
            ...addresses,
            products: _.cloneDeep(products).map((p) => {
              p.warehouses = warehouses.map((w) => {
                const warehouse = p.totalStock.stocks.find((s) => s.warehouseId === w.id);

                if (!warehouse) {
                  return null;
                }
                return {
                  ...warehouse,
                  ...w,
                };
              }).filter((d) => d);

              return p;
            }) || [],
            orderProducts,
          });
        }

        setLoadingData(false);
      })();
    }
  }, [orderId, searchParams, warehouses]);

  useEffect(() => {
    (async () => {
      dispatch(getTaxTypesRequest({}));
      dispatch(getPriceListsRequest());
      dispatch(getCountriesRequest());
      dispatch(getWarehousesRequest());
      dispatch(getOrderTagsRequest());
      dispatch(getCompaniesRequest());
      dispatch(getWarehouseList());
      dispatch(generateOrderNumberRequest({ prefix: 'PO-' }));
      dispatch(getSettingsRequest(['paymentTerms', 'currencyCode']));
    })();
  }, []);

  useEffect(() => {
    if (!orderId) {
      handleChange('number', orderNumber);
    }
  }, [orderNumber, orderId]);

  useEffect(() => {
    const termId = paymentTerms?.find((p) => p.isDefault)?.id;
    if (!form.paymentTerm?.id) {
      handleChange('paymentTerm.id', termId);
    }
  }, [paymentTerms, form.paymentTerm]);

  useEffect(() => {
    if (!form.currencyCode) {
      handleChange('currencyCode', currencyCode);
    }
  }, [currencyCode, form.currencyCode]);

  useEffect(() => {
    const companyId = companies.find((p) => p.isDefault)?.id;
    if (!form.companyId) {
      handleChange('companyId', companyId);
    }
  }, [companies, form.companyId]);

  useEffect(() => {
    handleChange('assignedUserId', profile.id);
  }, [profile]);

  useEffect(() => {
    const priceListId = priceLists.filter((p) => p.type === 'buy').find((p) => p.isDefault)?.id;

    if (!form.priceListId) {
      handleChange('priceListId', priceListId);
    }
  }, [priceLists, form.priceListId]);

  useEffect(() => {
    const warehouseId = warehouses.find((p) => p.isDefault)?.id;

    if (!form.warehouseId) {
      handleChange('warehouseId', warehouseId);
    }
  }, [warehouses, form.warehouseId]);

  const handleChange = useCallback((key, value) => {
    if (key) {
      _.set(form, key, value);
      _.unset(errors, key);
    } else {
      Object.assign(form, value);
      Object.keys(value).forEach((o) => {
        delete errors[o];
      });
    }
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSave = useCallback(async (status, redirect, state) => {
    setLoading(status);
    let datum = {};

    const sAddress = customer.shippingAddresses?.find((c) => c.id === form.shippingAddressId) || {};

    const d = {
      companyId: form.companyId,
      customerId: form.customerId,
      shippingAddressId: form.shippingAddressId,
      shippingAddress: {
        address1: sAddress.address1,
        address2: sAddress.address2,
        city: sAddress.city,
        countryCode: sAddress.countryCode,
        firstName: sAddress.firstName,
        lastName: sAddress.lastName,
        id: sAddress.id,
        phone: sAddress.phone,
        postalCode: sAddress.postalCode,
        region: sAddress.region,
        title: sAddress.title,
      },
      billingAddress: {
        address1: customer.billingAddress?.address1,
        address2: customer.billingAddress?.address2,
        city: customer.billingAddress?.city,
        countryCode: customer.billingAddress?.countryCode,
        firstName: customer.billingAddress?.firstName,
        lastName: customer.billingAddress?.lastName,
        id: customer.billingAddress?.id,
        phone: customer.billingAddress?.phone,
        postalCode: customer.billingAddress?.postalCode,
        region: customer.billingAddress?.region,
        title: customer.billingAddress?.title,
      },
      priceListId: form.priceListId,
      status,
      type: form.type,
      taxType: form.taxType,
      sourceCreatedAt: form.sourceCreatedAt,
      paymentTerm: { id: form.paymentTerm.id },
      totalShippingPrice: +unformat(form.totalShippingPrice, { code: form.currencyCode }) || 0,
      number: form.number,
      currencyCode: form.currencyCode,
      notes: form.notes,
      landedCosts: form.landedCosts,
      tags: form.tags.map((t) => ({ id: t.id })),
      orderProducts: form.orderProducts.map((o) => ({
        productId: o.productId,
        id: o.id,
        price: o.price || 0,
        discountPercentage: o.discountPercentage || 0,
        qty: o.qty || 1,
        taxPercentage: o.taxPercentage || 0,
        warehouseId: o.warehouseId,
        type: o.type,
        title: o.title,
        taxId: o.taxId,
      })),
    };

    if (orderId) {
      const { payload: { data } } = await dispatch(updateOrderRequest({ ...d, id: orderId }));
      datum = data;
    } else {
      const { payload: { data } } = await dispatch(createOrderRequest(d));
      datum = data;
    }

    if (datum.status === 'error' && !_.isEmpty(datum.errors)) {
      setErrors(datum.errors);
      setLoading('');
      return 'error';
    }
    if (datum.status === 'error') {
      toast.error(datum.message);
      setLoading('');
    } else {
      if (!redirect) {
        navigate(`/stocks/purchase-orders/preview/${datum.order.id}`, { replace: true });
      } else {
        await navigate(`/stocks/purchase-orders/edit/${datum.order.id}`, { replace: true });
        navigate(`${redirect}?back=/stocks/purchase-orders/edit/${datum.order.id}&isVendor=1`, { state });
      }
      toast.success(orderId ? 'Successfully updated' : 'Successfully created');
      setLoading('');
    }
  }, [form, orderId, customer, location]);

  const savePaymentTerm = useCallback(async (d) => {
    dispatch(getSettingsRequest(['paymentTerms']));
    handleChange('paymentTerm.id', _.last(d).id);
  }, [handleChange]);

  const savePriceList = useCallback(async (d) => {
    await dispatch(getPriceListsRequest());
    handleChange('priceListId', d.id);
  }, [handleChange]);

  const handleAddOrderProducts = useCallback((items) => {
    const tax = taxTypes.find((t) => t.type === 'purchase' || t.code === 'PURCHASES') || {};
    const orderProducts = items.map((o) => ({
      ...o,
      taxId: tax.id,
      taxPercentage: tax.rate,
    }));
    handleChange('orderProducts', [...form.orderProducts, ...orderProducts]);
  }, [form, taxTypes]);

  const handleAddProducts = useCallback((items) => {
    handleChange('products', [...items, ...form.products]);
  }, [form]);

  const handleAddNewWarehouse = useCallback(() => {
    const res = handleSave(orderId ? form.status : 'draft', '/stocks/warehouses/add');
    if (res === 'error') {
      toast.error('Can not navigate until all required fields is filled');
    }
  }, [form, orderId]);

  const getCreateButtonTitle = useCallback(() => {
    if (form.id && form.status !== 'draft') {
      return 'Update order';
    }
    if (form.id && form.status === 'draft') {
      return 'Approve order';
    }
    return 'Create Order';
  }, [form.id, form.status]);

  const handleGetUsers = useCallback(async (s) => {
    const { payload } = await dispatch(getUsersListRequest({ s }));
    return payload.users;
  }, []);

  const saveTag = useCallback(async (d) => {
    await dispatch(getOrderTagsRequest());
    handleChange('tags', [...form.tags, d]);
  }, [handleChange, form.tags]);

  const handleChangePriceList = useCallback((val) => {
    const oProducts = form.orderProducts.map((p) => ({
      ...p,
      price: form.products.find((pr) => pr.id === p.productId)?.prices
          ?.find((pr) => pr.priceListId === val)?.price
        || form.products.find((pr) => pr.id === p.productId)?.prices?.[0]?.price
        || 0,
    }));
    const data = {
      priceListId: val,
      orderProducts: oProducts,
    };
    handleChange(null, data);
  }, [form]);

  const handleGetPriceLists = useCallback(async (s) => {
    const { payload } = await dispatch(getPriceListsRequest({ s }));
    return payload.data.priceLists.filter((p) => p.type === 'buy');
  }, []);

  const handleGetCompanies = useCallback(async (s) => {
    const { payload } = await dispatch(getCompaniesRequest({ s }));
    return payload.companies;
  }, []);

  const saveCompany = useCallback(async (d) => {
    await dispatch(getCompaniesRequest());
    handleChange('companyId', d.id);
  }, [handleChange]);

  return (
    <div className="sales_order_form">
      {!loadingData ? (
        <>
          <div className="top">
            <div className="left">
              <p className="title">{orderId ? `Purchase Order# ${form.id}` : 'New purchase order'}</p>
              <div>
                {orderId ? (
                  <Button
                    btnType="transparent"
                    className="print"
                    iconLeft={<PrintIcon />}
                    onClick={() => setShowPrintModal(true)}
                  >
                    Print order
                  </Button>
                ) : null}
                {quotation || location.pathname.includes('create') || form.status === 'draft' ? (
                  <Button
                    iconLeft={<QuotationIcon />}
                    btnType="transparent"
                    className="print quotation"
                    onClick={() => handleSave('draft')}
                    loading={loading === 'draft'}
                  >
                    Save as
                    quotation
                  </Button>
                ) : null}
              </div>
            </div>
            <div className="right">
              <div className="actions">
                <Button btnType="cancel" roundBorder onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  roundBorder
                  onClick={() => handleSave('pending')}
                  loading={loading && loading !== 'draft'}
                >
                  {getCreateButtonTitle()}
                </Button>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="left">
              <div className="form">
                <div className="row">
                  <Input
                    label="Purchase Order#"
                    roundBorder
                    value={form.number}
                    onChange={(ev) => handleChange('number', ev.target.value)}
                    error={errors.number}
                  />
                  <div className="small">
                    <Datepicker
                      label="Create date"
                      onChange={(date) => handleChange('sourceCreatedAt', moment(date).format('YYYY-MM-DD'))}
                      value={form.sourceCreatedAt ? moment(form.sourceCreatedAt).toDate() : undefined}
                      error={errors.sourceCreatedAt}
                    />
                    <Datepicker
                      label="Expected Arrival"
                      onChange={(date) => handleChange('expectedReceiveAt', moment(date).format('YYYY-MM-DD'))}
                      value={form.expectedReceiveAt ? moment(form.expectedReceiveAt).format('MM.DD.YYYY') : undefined}
                      error={errors.expectedReceiveAt}
                    />
                  </div>
                  <div className="small">
                    <Select
                      label="Company"
                      placeholder="Select company"
                      roundBorder
                      isAsync
                      getFullOption
                      loadOptions={handleGetCompanies}
                      defaultOptions
                      getOptionLabel={(o) => o.name}
                      getOptionValue={(o) => o.id}
                      error={errors.companyId}
                      value={companies.find((c) => c.id === form.companyId)}
                      onChange={(val) => handleChange('companyId', val.id)}
                      addNew="WRITE_COMPANIES"
                      onAddNewClick={() => setCreateCompanyModal(true)}
                    />
                    <Select
                      label="Payment terms"
                      roundBorder
                      placeholder="Select"
                      options={paymentTerms}
                      valuePath="id"
                      labelPath="title"
                      error={errors.paymentTerm?.id}
                      onChange={(val) => handleChange('paymentTerm.id', val)}
                      value={form.paymentTerm.id}
                      defaultValue={(o) => o.isDefault}
                      addNew
                      onAddNewClick={() => setCreatePaymentTermModal(true)}
                    />
                  </div>
                </div>
                <div className="row">
                  <Select
                    label="Warehouse"
                    roundBorder
                    placeholder="Select"
                    options={warehouses}
                    valuePath="id"
                    labelPath="title"
                    onChange={(val) => handleChange('warehouseId', val)}
                    value={form.warehouseId}
                    addNew="WRITE_WAREHOUSES"
                    onAddNewClick={handleAddNewWarehouse}
                  />
                  <Select
                    label="Currency"
                    roundBorder
                    options={currencies}
                    placeholder="Select"
                    valuePath="code"
                    labelPath="code"
                    error={errors.currencyCode}
                    onChange={(val) => handleChange('currencyCode', val)}
                    value={form.currencyCode}
                    isSearchable
                  />

                  <Select
                    label="Price list"
                    roundBorder
                    placeholder="Select"
                    defaultOptions
                    valuePath="id"
                    labelPath="name"
                    loadOptions={handleGetPriceLists}
                    error={errors.priceListId}
                    onChange={handleChangePriceList}
                    value={priceLists.find((p) => p.id === form.priceListId)}
                    addNew="WRITE_PRICE_LISTS"
                    isAsync
                    onAddNewClick={() => setCreatePriceListModal(true)}
                  />

                </div>
                <div className="row" style={{ alignItems: 'flex-start' }}>
                  <Select
                    label="Assign to"
                    roundBorder
                    placeholder="Select"
                    isAsync
                    defaultOptions
                    loadOptions={handleGetUsers}
                    valuePath="id"
                    getOptionLabel={(o) => `${o.firstName || ''} ${o.lastName || ''}`}
                    error={errors.assignedUserId}
                    onChange={(val) => handleChange('assignedUserId', val)}
                    value={users.find((u) => u.id === form.assignedUserId)}
                  />
                  <Select
                    label="Taxes are"
                    roundBorder
                    placeholder="Select"
                    options={taxes}
                    error={errors.taxType}
                    onChange={(val) => handleChange('taxType', val)}
                    value={form.taxType}
                  />
                  <MultiSelect
                    label="Tags"
                    placeholder="Select tags"
                    options={orderTags}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.id}
                    value={orderTags.filter((o) => form.tags.some((t) => t.id === o.id))}
                    onChange={(val) => handleChange('tags', val)}
                    addNew="WRITE_TAGS"
                    onAddNewClick={() => setCreateTagModal(true)}
                  />
                </div>
              </div>
              <OrderProducts
                onAddOrderProducts={handleAddOrderProducts}
                onAddProducts={handleAddProducts}
                priceListId={form.priceListId}
                saveOrder={handleSave}
                status={form.status}
                shopId={form.shopId || '0'}
              />
              <OrderProductsList
                form={form}
                onChange={handleChange}
                errors={errors}
                saveOrder={handleSave}
              />
            </div>
            <div className="right">
              <OrderCustomer
                onChange={handleChange}
                shippingAddressId={form.shippingAddressId}
                customerId={form.customerId}
                status={form.status}
                errors={errors}
                onSave={handleSave}
                label="Vendor"
                type="vendor"
              />
            </div>
          </div>
        </>
      ) : null}
      {loadingData ? <Loader /> : null}
      {showPrintModal ? (
        <PrintDocumentModal
          isOpen={showPrintModal}
          onClose={() => setShowPrintModal(false)}
          type="orders/sales-quotation"
          onRequest={async (templateId) => {
            const { data } = await Api.viewOrderQuotation(orderId, 'download', { templateId });
            return data;
          }}
          onRemotePrint={() =>{}}

        />
      ) : null}

      {createPaymentTermModal ? (
        <PaymentsTermForm
          isOpen={createPaymentTermModal}
          onClose={() => setCreatePaymentTermModal(false)}
          onSave={savePaymentTerm}
        />
      ) : null}
      {createPriceListModal ? (
        <PriceListForm
          isOpen={createPriceListModal}
          onClose={() => setCreatePriceListModal(false)}
          onSave={savePriceList}
          type="buy"
        />
      ) : null}
      {createTagModal ? (
        <TagFormModal
          isOpen={createTagModal}
          onClose={() => setCreateTagModal(false)}
          onSave={saveTag}
        />
      ) : null}
      {createCompanyModal ? (
        <CreateCompanyModal
          isOpen={createCompanyModal}
          data={{}}
          countries={countries}
          onClose={() => setCreateCompanyModal(false)}
          onSave={saveCompany}
        />
      ) : null}
    </div>
  );
}

export default PurchaseOrderForm;
