import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useClickAway } from 'react-use';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import Wrapper from '../../../../Layout/Wrapper';
import Api from '../../../../../Api';
import Input from '../../../../_common/Form/Input';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Select from '../../../../_common/Form/Select';
import Loader from '../../../../_common/Loader/Loader';
import Table from '../../../../_common/Tables/Table';
import Pagination from '../../../../_common/Pagination/Pagination';
import ScheduleModal from '../ScheduleModal';
import Button from '../../../../_common/Form/Button';
import { ReactComponent as FiltersIcon } from '../../../../../assets/icons/filters.svg';
import { ReactComponent as ExportIcon } from '../../../../../assets/icons/export.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/remote_printing.svg';

const tableHeader = [
  {
    title: 'PO number',
    path: 'number',
  },
  {
    title: 'Amount',
    path: 'amount',
  },
  {
    title: 'Status',
    path: 'status',
  },
  {
    title: 'Qty ordered',
    path: 'orderedQty',
  },
  {
    title: 'Qty received',
    path: 'receivedQty',
  },
  {
    title: 'Vendor',
    path: 'vendor',
  },
  {
    title: 'Landed cost',
    path: 'landedCost',
  },
  {
    title: 'Total amount',
    path: 'totalPrice',
  },
];

const statusOptions = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'shipped',
    label: 'Shipped',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'partial_shipped',
    label: 'Partial shipped',
  },
  {
    value: 'delivered',
    label: 'Delivered',
  },
  {
    value: 'partial_delivered',
    label: 'Partial delivered',
  },
  {
    value: 'canceled',
    label: 'Canceled',
  },
];

function PurchaseHistoryReport() {
  const navigate = useNavigate();
  const firstLoad = useRef(true);
  const filterMenuRef = useRef(null);
  const searchTimeout = useRef(null);

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    // sortBy: withDefault(StringParam, 'createdAt'),
    // sort: withDefault(StringParam, 'desc'),
    customerId: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    startDate: withDefault(StringParam, moment().subtract(1, 'month').format('MM-DD-YYYY')),
    endDate: withDefault(StringParam, moment().format('MM-DD-YYYY')),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [totalPages, setTotalPages] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [filterMenu, setFilterMenu] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({ id: '', firstName: 'All', lastName: '' });

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    (async () => {
      setTimeout(async () => {
        setTableLoading(true);

        await settingStateFromQuery();

        if (firstLoad.current) {
          const customers = await Api.getCustomers({ isVendor: true });

          setCustomerOptions(customers.data.customers);
        }

        try {
          await getPurchaseHistoryReport({
            allQuery: queryData,
          });
        } catch (e) {
          navigate('/404');
        }

        setTableLoading(false);
        firstLoad.current = false;
      }, 500);
    })();
  }, [queryData]);

  const settingStateFromQuery = useCallback(async () => {
    let singleCustomer = { firstName: 'All', lastName: '', id: '' };

    if (queryData.customerId) {
      const foundCustomer = customerOptions.find((c) => c.id === queryData.customerId);

      if (foundCustomer) {
        singleCustomer = foundCustomer;
      } else {
        try {
          const customer = await Api.getSingleCustomer(queryData.customerId);

          singleCustomer = customer.data.customer;
        } catch (e) {
          console.log(e);
        }
      }
    }

    setSelectedCustomer(singleCustomer);
    setSelectedStatus(queryData.status);
  }, [queryData]);

  useClickAway(filterMenuRef, async () => {
    if (filterMenu) {
      setFilterMenu(false);

      await settingStateFromQuery();
    }
  });

  const getPurchaseHistoryReport = useCallback(async ({ allQuery }) => {
    const requestData = { ...allQuery };

    !requestData.customerId ? delete requestData.customerId : requestData.customerId = +requestData.customerId;

    const { data } = await Api.getPurchaseHistoryReport(requestData);

    setTotalPages(data.totalPages);
    setReportData(data.orders);
  }, []);

  const loadMoreCustomer = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getCustomers({ s: inputValue, isVendor: true });

    callback(data.customers);
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setQuery((prev) => ({
      ...prev,
      sort: newSorting.sort,
      sortBy: newSorting.sortBy,
    }));
  }, [queryData.sort, queryData.sortBy]);

  const onSchedule = useCallback(() => {
    setScheduleIsOpen(true);
  }, []);

  const onExport = useCallback(async () => {
    try {
      await Api.exportPurchaseHistoryReport({
        by: {
          startDate: queryData.startDate,
          endDate: queryData.endDate,
          s: queryData.s,
        },
        fields: ['orderedQty', 'receivedQty', 'status', 'vendor', 'amount', 'number'],
        fileType: 'csv',
      });

      toast.success('Successfully exported. You will receive an email.');
    } catch (e) {
      toast.error('Something went wrong. Please try later.');
    }
  }, [queryData]);

  const onPrint = () => {
    window.print();
  };

  const onRunReport = useCallback(() => {
    setQuery((prev) => ({
      ...prev,
      status: selectedStatus,
      customerId: selectedCustomer.id,
      page: 1,
    }));

    setFilterMenu(false);
  }, [selectedStatus, selectedCustomer]);

  return (
    <Wrapper
      title="Purchse order history report"
      onBtnClick={onSchedule}
      buttonTitle="Schedule report"
      btnType="light_blue"
    >
      <div className="inventory_report">
        <div className="inventory_report_filters">
          <div className="inventory_report_filter_search">
            <Input
              value={queryData.s}
              placeholder="Search"
              size="small"
              roundBorder
              search
              onChangeText={(val) => setQuery((prev) => ({
                ...prev,
                s: val,
                page: 1,
              }))}
              data-test-id="filter_search"
            />
          </div>

          <div className="inventory_report_filter_right">
            <Button
              onClick={onPrint}
              size="small"
              roundBorder
              iconLeft={<PrintIcon />}
              title="Print"
              btnType="transparent"
              className="print_button"
            />

            {!!reportData.length && (
              <Button
                onClick={onExport}
                size="small"
                roundBorder
                iconLeft={<ExportIcon />}
                title="Export"
                btnType="transparent"
                className="export_button"
              />
            )}

            <RangeDatePicker
              currentDate={{
                startDate: queryData.startDate,
                endDate: queryData.endDate,
              }}
              onChangeDate={(value) => setQuery((prev) => ({
                ...prev,
                startDate: value[0],
                endDate: (value[0] > value[1] || !value[1]) ? value[0] : value[1],
                page: 1,
              }))}
              calendarClassName="inventory_report_filters_menu_calendar"
              popperPlacement="bottom-start"
            />

            <div className="inventory_report_filter_btn_wrapper" ref={filterMenuRef}>
              <Button
                title="Filters"
                size="small"
                roundBorder
                iconLeft={<FiltersIcon />}
                btnType="transparent"
                className="inventory_report_filter_btn"
                onClick={() => setFilterMenu((prev) => !prev)}
              />

              {filterMenu && (
                <div className="inventory_report_filters_menu">
                  <div className="inventory_report_filters_checkboxes">
                    <div className="inventory_report_filters_select_wrp">
                      <Select
                        defaultOptions={[{
                          firstName: 'All',
                          lastName: '',
                          id: '',
                        }, ...customerOptions]}
                        onChange={(value) => setSelectedCustomer(value)}
                        value={selectedCustomer}
                        size="small"
                        roundBorder
                        labelPath="title"
                        label="Vendor"
                        valuePath="id"
                        getFullOption
                        loadOptions={(inputValue, callback) => loadMoreCustomer(inputValue, callback)}
                        isAsync
                        getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
                      />
                    </div>

                    <div className="inventory_report_filters_select_wrp">
                      <Select
                        options={statusOptions}
                        onChange={(value) => setSelectedStatus(value)}
                        value={selectedStatus}
                        size="small"
                        roundBorder
                        label="Status"
                      />
                    </div>
                  </div>

                  <div className="inventory_report_filters_menu_buttons">
                    <Button
                      onClick={() => onRunReport()}
                      className="inventory_report_filters_menu_run_btn_single"
                      title="Run report"
                      size="small"
                      roundBorder
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {firstLoad.current ? <Loader /> : (
          <div className="inventory_report_table">
            <Table
              data={reportData}
              header={tableHeader}
              deleteModalText="user invitation"
              sortBy={queryData.sortBy}
              sort={queryData.sort}
              onSortBy={onSortBy}
              loading={tableLoading}
              hideDeleteButtonFields={['active', 'restricted']}
            />
          </div>
        )}

        <div className="inventory_report_pagination">
          <Pagination
            totalPages={totalPages}
            onChangePage={(p) => setQuery((prev) => ({ ...prev, page: p }))}
          />
        </div>

        <ScheduleModal isOpen={scheduleIsOpen} onClose={() => setScheduleIsOpen(false)} />
      </div>
    </Wrapper>
  );
}

export default PurchaseHistoryReport;
