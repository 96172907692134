import React, { useCallback } from 'react';
import _ from 'lodash';
import Switcher from '../../../../_common/Form/Switcher';
import Input from '../../../../_common/Form/Input';

const B2BGeneral = ({
  data, changeData, errors, setErrors,
}) => {
  const onChange = useCallback((path, value) => {
    changeData((prev) => {
      const newData = { ...prev };
      _.set(newData, path, value);

      return newData;
    });

    setErrors((prev) => {
      const newErrors = { ...prev };

      if (path === 'settings.storeName' || path === 'name') {
        const key = path.replace(/^\w+\./, '');
        delete newErrors[key];
      }

      return newErrors;
    });
  }, []);

  return (
    <div className="integration_step_two_wrapper my_app b2b_general">
      <div>
        <h2>
          Store URL:

          <div>{data.settings.storeUrl.replace(/^https?:\/\//, '')}</div>
        </h2>

        <div
          className="integration_step_two_fields_wrapper"
        >
          <div className="integration_step_two_fields_left_block">
            <p className="integration_step_two_fields_left_block_title">App name</p>
          </div>

          <div className="integration_step_two_fields_right_block">
            <div className="integration_step_two_fields_right_block_select">
              <Input
                size="small"
                roundBorder
                onChangeText={(val) => onChange('name', val)}
                value={data.name}
                error={errors.name}
              />
            </div>
          </div>
        </div>

        <div
          className="integration_step_two_fields_wrapper"
        >
          <div className="integration_step_two_fields_left_block">
            <p className="integration_step_two_fields_left_block_title">Store name</p>
          </div>

          <div className="integration_step_two_fields_right_block">
            <div className="integration_step_two_fields_right_block_select">
              <Input
                size="small"
                roundBorder
                onChangeText={(val) => onChange('settings.storeName', val)}
                value={data.settings.storeName}
                error={errors.storeName}
              />
            </div>
          </div>
        </div>

        <div
          className="integration_step_two_fields_wrapper"
        >
          <div className="integration_step_two_fields_left_block">
            <p className="integration_step_two_fields_left_block_title">
              <Switcher
                label="Password"
                checked={!!+data.settings.passwordStatus}
                onChange={(check) => onChange('settings.passwordStatus', check ? '1' : '0')}
              />
            </p>
          </div>

          <div className="integration_step_two_fields_right_block">
            <div className="integration_step_two_fields_right_block_select">
              <Input
                size="small"
                roundBorder
                password
                // placeholder="Enter password"
                onChangeText={(val) => onChange('settings.password', val)}
                value={data.settings.password}
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

B2BGeneral.propTypes = {};

export default B2BGeneral;
