import React from 'react';
import _ from 'lodash';
import moment from 'moment/moment';
import { findCurrency } from 'currency-formatter';
import Input from './Form/Input';
import Datepicker from './Form/Datepicker';
import Timepicker from './Form/Timepicker';
import Select from './Form/Select';

function CustomField(props) {
  const {
    type, onChange, value, label, mask, currencyCode, phoneSymbol, options, id,error
  } = props;

  if (type === 'number') {
    return (
      <Input
        value={value}
        type="number"
        onChange={(ev) => onChange(ev.target.value)}
        label={label}
        roundBorder
        error={error}
      />
    );
  }
  if (type === 'text') {
    return (
      <Input
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        label={label}
        roundBorder
        error={error}
      />
    );
  }
  if (type === 'phone') {
    return (
      <Input
        value={value}
        mask={mask}
        onChange={(ev) => onChange(ev.target.value)}
        label={label}
        roundBorder
        symbol={phoneSymbol}
        error={error}
      />
    );
  }
  if (type === 'currency') {
    return (
      <Input
        value={value}
        symbol={findCurrency(currencyCode)?.symbol}
        onChange={(ev) => onChange(ev.target.value)}
        label={label}
        type="number"
        roundBorder
        error={error}
      />
    );
  }
  if (type === 'select') {
    return (
      <Select
        value={value}
        options={_.uniq(options).map((o) => ({ label: o, value: o }))}
        onChange={(val) => onChange(val)}
        label={label}
        roundBorder
        error={error}
      />
    );
  }
  // if (type === 'attachment') {
  //   return (
  //     <div className="attachment">
  //       <Input
  //         label={label}
  //         accept="image/png, image/gif, image/jpeg, image/webp"
  //         type="file"
  //         roundBorder
  //         id={`file-upload_${id}`}
  //         placeholder={value?.name}
  //         onChange={(ev) => {
  //           onChange(ev.target.files[0]);
  //         }}
  //       />
  //       <label htmlFor={`file-upload_${id}`} className="custom-file-upload">
  //         {value?.name ? value?.name : 'Choose a.svg file'}
  //       </label>
  //     </div>
  //   );
  // }
  if (type === 'date') {
    return (
      <Datepicker
        value={value ? moment(value, 'YYYY-MM-DD').toDate() : undefined}
        label={label}
        error={error}
        onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
      />
    );
  }
  if (type === 'dateTime') {
    return (
      <div className="dates">
        <Datepicker
          label={label}
          onChange={(date) => onChange(moment(date).toISOString())}
          value={value ? moment(value).toDate() : undefined}
          error={error}
        />
        <Timepicker
          label=" "
          value={value ? moment(value).format('hh:mm') : undefined}
          onChange={(time) => {
            if (time) {
              const [h, m] = time.split(':');
              const date = moment(value).set({ hours: h, minutes: m });
              onChange(moment(date).toISOString());
            }
          }}
        />
      </div>
    );
  }
  return null;
}

export default CustomField;
