import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Input from './Form/Input';
import Select from './Form/Select';
import Datepicker from './Form/Datepicker';
import Timepicker from './Form/Timepicker';
import Utils from '../../helpers/Utils';

function ProductCustomField({
  type, onChange, value, label, options, error,
}) {
  const inputFields = ['number', 'text', 'phone', 'currency'];
  const validationTypes = {
    phone: (e) => Utils.onBeforeInput(e, 'number'),
    number: (e) => Utils.onBeforeInput(e, 'number'),
    currency: (e) => Utils.onBeforeInput(e, 'currency'),
  };

  if (inputFields.includes(type)) {
    return (
      <Input
        value={value}
        type={type === 'text' ? 'text' : 'number'}
        onChange={(ev) => onChange(ev.target.value)}
        label={label}
        roundBorder
        error={error}
        size="small"
        onBeforeInput={validationTypes[type] || null}
        symbol={type === 'currency' ? '$' : type === 'phone' ? '+' : ''}
      />
    );
  }

  if (type === 'select') {
    return (
      <Select
        value={value}
        options={_.uniq(options).map((o) => ({ label: o, value: o }))}
        onChange={(val) => onChange(val)}
        label={label}
        roundBorder
        error={error}
        size="small"
      />
    );
  }

  if (type === 'date') {
    return (
      <Datepicker
        value={value ? moment(value, 'YYYY-MM-DD').toDate() : undefined}
        label={label}
        onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
        error={error}
      />
    );
  }

  if (type === 'dateTime') {
    return (
      <div className="dates">
        <Datepicker
          label={label}
          value={value ? moment(value).toDate() : undefined}
          onChange={(date) => onChange(moment(date).toISOString())}
          error={error}
        />

        <Timepicker
          label=" "
          value={value ? moment(value).format('hh:mm') : undefined}
          onChange={(time) => {
            if (time) {
              const [h, m] = time.split(':');
              const date = moment(value).set({ hours: h, minutes: m });
              onChange(moment(date).toISOString());
            }
          }}
        />
      </div>
    );
  }

  return null;
}

ProductCustomField.propTypes = {
  // header: PropTypes.array.isRequired,
};

ProductCustomField.defaultProps = {
  // checkedItems: [],
};
export default ProductCustomField;
