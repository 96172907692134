import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Loader from '../components/_common/Loader/Loader';
import Wrapper from '../components/Layout/Wrapper';
import Input from '../components/_common/Form/Input';
import Utils from '../helpers/Utils';
import Button from '../components/_common/Form/Button';

import { ReactComponent as ImageIcon } from '../assets/icons/add_image.svg';
import { ReactComponent as ClearIcon } from '../assets/icons/cancel.svg';
import UpdateModal from '../components/_common/Modal/UpdateModal';
import Api from '../Api';
import { getAccountRequest } from '../store/actions/users';

const fields = [
  {
    label: 'First name',
    path: 'firstName',
    placeholder: 'Enter your first name',
    required: true,
  },
  {
    label: 'Last name',
    path: 'lastName',
    placeholder: 'Enter your last name',
    required: true,
  },
  {
    label: 'Email',
    path: 'email',
    disabled: true,
  },
];

const modalFields = [
  {
    title: 'Old password',
    path: 'currentPassword',
    placeholder: 'Enter your old password',
    type: 'password',
    required: true,
  },
  {
    title: 'New password',
    path: 'newPassword',
    placeholder: 'Enter your new password',
    type: 'password',
    required: true,
  },
  {
    title: 'Confirm new password',
    path: 'confirmPassword',
    placeholder: 'Enter confirm password',
    type: 'password',
    required: true,
  },
];

const imageAllowFormats = '.png, .jpg, .jpeg, .webp';

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const { profile, status } = useSelector((state) => state.users);

  const [profileData, setProfileData] = useState({});
  const [errors, setErrors] = useState({});

  const [saveLoading, saveLoadingToggle] = useState(false);

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (status === 'ok') {
      setProfileData(profile);
    }
  }, [status]);

  const changeProfileData = useCallback((path, value) => {
    setProfileData((prev) => ({ ...prev, [path]: value }));

    setErrors((prev) => ({ ...prev, [path]: '' }));
  }, []);

  const uploadLogo = useCallback((e) => {
    Utils.onUploadFile(e, (file) => {
      setProfileData((prev) => ({ ...prev, image: file }));
    }, imageAllowFormats);
  }, []);

  const submit = useCallback(async (e) => {
    e?.preventDefault();

    let hasError = false;

    fields.forEach(({ path, required }) => {
      if (required && !profileData[path].trim()) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));

        hasError = true;
      }
    });

    if (hasError) {
      toast.error('Please correct these fields');
    } else {
      saveLoadingToggle(true);

      try {
        const { data: { user } } = await Api.updateAccount(profileData);
        setProfileData(user);
        toast.success('UpdateProfile successfully updated');
        dispatch(getAccountRequest());
      } catch (err) {
        setErrors(err.response.data.errors);
        toast.error('Please correct these fields');
      }

      saveLoadingToggle(false);
    }
  }, [profileData]);

  const closeModal = useCallback(() => {
    setModalData({});
  }, []);

  const savePassword = useCallback(async ({ currentPassword, newPassword, confirmPassword }) => {
    if (newPassword !== confirmPassword) {
      const err = {};

      _.set(err, 'response.data.errors', { newPassword: 'Passwords are not the same' });
      throw err;
    }

    await Api.updateAccountPassword({ currentPassword, newPassword });
    toast.success('Password successfully updated');
    closeModal();
  }, []);

  return (
    <Wrapper
      title="UpdateProfile"
      onBtnClick={() => submit()}
      btnLoading={saveLoading}
      buttonTitle="Save changes"
      className="profile_wrapper"
    >
      {_.isEmpty(profileData)
        ? <Loader />

        : (
          <div className="update_profile_wrapper">
            <div className="upload_btn_wrapper">
              <div className="upload_btn_avatar_wrapper">
                {!profileData.image
                  ? (
                    <div className="default_avatar_wrapper">
                      <ImageIcon />
                    </div>
                  )

                  : (
                    <div className="avatar_wrapper">
                      <img
                        src={profileData.image?._preview || profileData.image}
                        alt="Avatar"
                        className="avatar"
                      />

                      <ClearIcon onClick={() => changeProfileData('image', '')} />
                    </div>
                  )}
              </div>

              <Button
                btnType="upload"
                size="small"
                reverseColor
                onFileUpload={uploadLogo}
                fileAccept={imageAllowFormats}
              >
                Upload avatar
              </Button>
            </div>

            <form onSubmit={submit}>
              {fields.map(({
                label, path, placeholder, disabled,
              }) => (
                <Input
                  key={label}
                  label={label}
                  placeholder={placeholder}
                  value={profileData[path]}
                  error={errors[path]}
                  onChangeText={(value) => !disabled && changeProfileData(path, value)}
                  size="small"
                  roundBorder
                  disabled={disabled}
                />
              ))}

              <div className="change_password_btn_wrapper">
                <Button
                  roundBorder
                  size="small"
                  btnType="transparent"
                  color="#1472FF"
                  onClick={() => setModalData({ currentPassword: '', newPassword: '', id: 1 })}
                >
                  Change password
                </Button>
              </div>
            </form>
          </div>
        )}

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalFields}
        onClose={closeModal}
        onSave={savePassword}
        singleData={modalData}
        fullTitle="Change password"
      />
    </Wrapper>
  );
};

export default UpdateProfile;
