import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import Input from '../../../_common/Form/Input';
import { deletePackageRequest, getPackagesRequest } from '../../../../store/actions/settings';
import PackageDimensionsFormModal from './PackageDimensionsFormModal';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import DeleteModal from '../../../_common/Modal/DeleteModal';

const tableColumns = [
  {
    key: 'packageId',
    isEnabled: true,
  }, {
    key: 'name',
    isEnabled: true,
  }, {
    key: 'packageLength',
    isEnabled: true,
  }, {
    key: 'packageHeight',
    isEnabled: true,
  }, {
    key: 'packageWidth',
    isEnabled: true,
  },
];

function Packages() {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [s, setS] = useState('');

  const dispatch = useDispatch();

  const packages = useSelector((state) => state.settings.packages);

  const filteredPackages = useMemo(() => packages.filter((p) => p.title.includes(s)), [packages, s]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getPackagesRequest());
      setLoading(false);
    })();
  }, []);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload } = await dispatch(deletePackageRequest(deletingId));
    if (payload.status === 'error') {
      toast.error(payload.message);
    } else {
      await dispatch(getPackagesRequest());
      setDeletingId(null);

      toast.success('Package successfully deleted');
    }
    setLoadingDelete(false);
  }, [deletingId]);

  return (
    <Wrapper
      title="Packages"
      onBtnClick={() => setModal(true)}
      addBtn
      buttonTitle="New packages"
    >
      <div className="shipping_types">
        <Input
          onChange={(ev) => setS(ev.target.value)}
          search
          placeholder="Search name"
          roundBorder
          size="small"
          value={s}
        />
        <DynamicTable
          loading={loading}
          data={filteredPackages}
          editable={false}
          checkbox={false}
          keyExtractor={(item) => item.id}
          columns={tableColumns}
          callDragDisabled={(call) => ['item'].includes(call.key)}
          renderColumnHeader={(col) => _.startCase(col.key)}
          renderColumns={{
            packageId: ({ item }) => (
              <div>
                {item.id}
              </div>
            ),
            name: ({ item }) => (
              <div>
                {item.title}
              </div>
            ),
            packageLength: ({ item }) => (
              <div>
                {item.dimensions[0]}
              </div>
            ),
            packageHeight: ({ item }) => (
              <div>
                {item.dimensions[1]}
              </div>
            ),
            packageWidth: ({ item }) => (
              <div>
                {item.dimensions[2]}
              </div>
            ),
            actions: ({ item }) => (
              <div className="products_actions_wrapper">
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setModal(item.id)}
                >
                  <EditIcon />
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setDeletingId(item.id)}
                  className="archive_icon"
                >
                  <DeleteIcon />
                </div>

              </div>
            ),
          }}
        />

      </div>
      <DeleteModal
        isOpen={!!deletingId}
        onDelete={handleDelete}
        onClose={() => setDeletingId(null)}
        loading={loadingDelete}
        text="package"
      />

      <PackageDimensionsFormModal isOpen={!!modal} onClose={() => setModal(false)} id={modal} />
    </Wrapper>
  );
}

export default Packages;
