import React, {
  useCallback, useRef, useState,
} from 'react';
import _ from 'lodash';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway, useDeepCompareEffect } from 'react-use';
import { toast } from 'react-toastify';
import { format } from 'currency-formatter';
import fileDownload from 'js-file-download';
import Loader from '../../../_common/Loader/Loader';
import Pagination from '../../../_common/Pagination/Pagination';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import {
  deleteCreditNoteRequest,
  getCreditNotesRequest,
  refundCreditNoteRequest,
} from '../../../../store/actions/creditNotes';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import Api from '../../../../Api';
import Utils from '../../../../helpers/Utils';
import ApplyToInvoiceModal from './ApplyToInvoiceModal';

function CreditNotesTable() {
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [applyModal, setApplyModal] = useState(null);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams({});
  const dropdownRef = useRef();

  const creditNotes = useSelector((state) => state.creditNotes.creditNotes);
  const totalPages = useSelector((state) => state.creditNotes.totalPages);

  useDeepCompareEffect(() => {
    handleGetCreditNotes();
  }, [searchParams]);

  useClickAway(dropdownRef, () => {
    setDropdown(null);
  });

  const handleGetCreditNotes = useCallback(async () => {
    setLoading(true);
    await dispatch(getCreditNotesRequest({ ...Object.fromEntries(searchParams), includes: 'customer' }));
    setLoading(false);
  }, [searchParams]);

  const handleRefund = useCallback(async (id) => {
    setLoadingRefund(true);
    const { payload: { data } } = await dispatch(refundCreditNoteRequest(id));
    if (data.creditNote) {
      toast.success('Credit note successfully refunded.');
      await handleGetCreditNotes();
      setDropdown(null);
    } else {
      toast.error(data.message);
    }
    setLoadingRefund(false);
  }, []);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload: { data } } = await dispatch(deleteCreditNoteRequest(deletingId));
    if (data.status !== 'error') {
      toast.success('Successfully deleted.');
      await handleGetCreditNotes();
      setDeletingId(null);
    } else {
      toast.error(data.message);
    }
    setLoadingDelete(false);
  }, [deletingId]);

  const handleGetAmount = useCallback(() => {
    const note = creditNotes.find((c) => c.id === applyModal);
    if (note) {
      return +note.credit - (+note.refunded + +note.applied);
    }
    return null;
  }, [creditNotes, applyModal]);

  if (!creditNotes) {
    return null;
  }

  return (
    <>
      <div className="table_wrapper sale_orders_table credit_notes_table">
        {!(loading && _.isEmpty(creditNotes)) ? (
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Credit Note#</th>
                <th className="table_thead_tr_th">Customer</th>
                <th className="table_thead_tr_th">Credit</th>
                <th className="table_thead_tr_th">Status</th>
                <th className="table_thead_tr_th">Date</th>
                <th />
              </tr>
            </thead>

            <tbody className="table_tbody">
              {creditNotes.map((o) => (
                <tr
                  className="table_tbody_tr"
                  key={o.id}
                >
                  <td className="table_tbody_tr_td link">
                    <Link to={`/orders/credit-notes/preview/${o.id}`}>
                      {o.number}
                    </Link>
                  </td>
                  <td
                    className="table_tbody_tr_td"
                  >
                    {`${o.customer.firstName || '-'} ${o.customer.lastName || '-'}`}
                  </td>
                  <td className="table_tbody_tr_td link">
                    {format(o.credit, { code: o.currencyCode })}
                  </td>
                  <td
                    className={`table_tbody_tr_td status ${Utils.getCreditNoteStatus(o?.credit, o?.refunded, o?.applied)}`}
                  >
                    <div>
                      <p className="status_dot" />
                      <span>
                        {_.startCase(Utils.getCreditNoteStatus(o?.credit, o?.refunded, o?.applied))}
                      </span>
                    </div>
                  </td>
                  <td
                    className="table_tbody_tr_td"
                  >
                    {o.sourceCreatedAt ? moment(o.sourceCreatedAt).format('YYYY.MM.DD hh:mm:ss') : '-'}
                  </td>
                  <td className="table_tbody_tr_td">
                    <div className="actions">
                      <div onClick={() => setDropdown(o.id)}>
                        <DotsIcon />
                        {dropdown === o.id ? (
                          <ul className="dropdown" ref={dropdownRef} onClick={() => setDropdown(null)}>
                            <li onClick={async () => {
                              setLoadingDownload(true);
                              try {
                                const { data } = await Api.downloadCreditNoteDocument(o.id);
                                fileDownload(data, 'document.pdf');
                                setLoadingDownload(false);
                              } catch (e) {
                                console.warn(e.response);
                              }
                            }}
                            >
                              Download document
                              {loadingDownload ? (
                                <div className="loader_wrapper" style={{ top: 8 }}>
                                  <span style={{ width: 15, height: 15 }} />
                                </div>
                              ) : null}
                            </li>
                            {+o.refunded < +o.credit ? (
                              <li onClick={() => setApplyModal(o.id)} data-scope="WRITE_CREDIT_NOTES">
                                Apply to invoice
                              </li>
                            ) : null}
                            {Utils.canRefundCreditNote(o) ? (
                              <li onClick={() => handleRefund(o.id)} data-scope="WRITE_CREDIT_NOTES">
                                Refund
                                {loadingRefund ? (
                                  <div className="loader_wrapper" style={{ top: 8 }}>
                                    <span style={{ width: 15, height: 15 }} />
                                  </div>
                                ) : null}
                              </li>
                            ) : null}
                            <li data-scope="DELETE_CREDIT_NOTES" className="delete" onClick={() => setDeletingId(o.id)}>
                              Delete
                            </li>
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {loading && _.isEmpty(creditNotes) ? <Loader size={30} /> : null}
        {!loading && _.isEmpty(creditNotes) ? <div className="no_data">No credit notes yet</div> : null}
      </div>
      <DeleteModal
        isOpen={!!deletingId}
        onDelete={handleDelete}
        onClose={() => setDeletingId(null)}
        loading={loadingDelete}
        text="credit note"
      />
      <Pagination totalPages={totalPages} />
      {applyModal ? (
        <ApplyToInvoiceModal
          id={applyModal}
          isOpen={!!applyModal}
          onClose={() => setApplyModal(null)}
          onGetData={handleGetCreditNotes}
          amount={handleGetAmount()}
        />
      ) : null}
    </>
  );
}

export default CreditNotesTable;
