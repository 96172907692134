import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as MenuMobileRightIcon } from '../../assets/icons/menuMobileRight.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow__right.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import Account from '../../helpers/Account';

const defaultMenu = {
  label: 'Dashboard',
  icon: <DashboardIcon />,
  subMenu: [{
    label: 'Dashboard',
    path: '/',
  },
  // {
  //   label: 'Settings',
  //   path: '/settings',
  // }
  ],
};

const MobileMenuItem = ({ menu }) => {
  const { isAdmin = false } = Account.get();
  const { pathname } = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    document.body.style.removeProperty('overflow');
    document.body.ontouchmove = () => true;
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.ontouchmove = () => false;
    } else {
      closeMenu();
    }
  }, [menuOpen]);

  const {
    label: title, icon, subMenu, isAdminRoute,
  } = menu.find(({ rootPath }) => pathname.startsWith(rootPath)) || defaultMenu;

  return (
    <>
      <MenuMobileRightIcon
        className="MenuMobileRightIcon"
        role="button"
        aria-label="Menu Button"
        onClick={() => setMenuOpen((prev) => !prev)}
      />

      <div className={classNames('menu__mobile__right', { menu__open: menuOpen })}>
        <div
          aria-label="Layer Menu"
          className="menu__mobile__right__layer"
          onClick={() => setMenuOpen((prev) => !prev)}
        />

        <div className="menu__mobile__right__nav">
          <div className="menu__mobile__right__nav__top">
            {icon}

            {title}
          </div>

          <CloseIcon
            className="CloseIcon"
            role="button"
            aria-label="Menu Button"
            onClick={() => setMenuOpen((prev) => !prev)}
          />

          <div className="menu__mobile__right__nav__block">
            {subMenu.map(({ path, label }) => (
              <NavLink
                key={path}
                to={path}
                className="menu__right__item"
                onClick={() => setMenuOpen(false)}
              >
                <div className="menu__right__name">{label}</div>

                <ArrowRight />
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenuItem;
