import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { format, unformat } from 'currency-formatter';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import noImg from '../../../assets/icons/no_img.svg';
import Select from '../../_common/Form/Select';
import Input from '../../_common/Form/Input';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/close_red.svg';
import {
  calculateOrderPriceRequest,
} from '../../../store/actions/orders';
import LandedCoast from './purchaseOrders/LandedCoast';
import Button from '../../_common/Form/Button';

export const idKey = Symbol('id');

function OrderProductsList(props) {
  const {
    onChange, errors, form: {
      orderProducts, currencyCode, totalShippingPrice = 0,
      taxType, products, notes = [], status, type, landedCosts,
    }, saveOrder,
  } = props;

  const [total, setTotal] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { orderId } = useParams();

  const taxTypes = useSelector((state) => state.settings.taxTypes.taxes);
  const order = useSelector((state) => state.orders.order);

  const taxTypesFiltered = useMemo(() => {
    let taxes = taxTypes;
    if (type === 'sale') {
      taxes = taxes.filter((tax) => tax.type === 'sale' || tax.code === 'SALES');
    } else {
      taxes = taxes.filter((tax) => tax.type === 'purchase' || tax.code === 'PURCHASES');
    }
    const notExistingTaxes = order.orderProducts ? order.orderProducts.filter((t) => !t.taxId).map((t) => ({
      rate: t.taxPercentage,
      title: type === 'sale' ? 'Sales tax' : 'Purchase tax',
    })) : [];
    taxes = [...taxes, ...notExistingTaxes];

    return taxes;
  }, [taxTypes, type, order]);

  useEffect(() => {
    onChange('totalShippingPrice', format(totalShippingPrice, { code: currencyCode }));
  }, [currencyCode]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const d = {
        orderProducts: orderProducts.map((p) => ({
          price: p.price || 0,
          discountPercentage: p.discountPercentage || 0,
          qty: p.qty || 1,
          taxPercentage: p.taxPercentage || 0,
        })),
        taxType,
        totalShippingPrice: unformat(totalShippingPrice, {}),
        landedCosts,
      };
      const { payload } = await dispatch(calculateOrderPriceRequest(d));
      if (payload) {
        setTotal(payload.data);
      }
      setLoading(false);
    })();
  }, [orderProducts, taxType, unformat(totalShippingPrice, {}), landedCosts]);

  const handleNavigate = async (id) => {
    const res = await saveOrder(orderId ? status : 'draft', `/products/${id}`);
    if (res === 'error') {
      toast.error('Can not navigate until all required fields is filled');
    }
  };

  const handleAddProducts = useCallback(() => {
    const tax = taxTypesFiltered?.[0] || {};

    onChange('orderProducts', [...orderProducts, {
      [idKey]: _.uniqueId('temp'),
      type: 'custom',
      taxId: tax.id,
      taxPercentage: tax.rate,
      price: 0,
    }]);
    onChange('products', [...products, { [idKey]: _.uniqueId('temp'), type: 'custom' }]);
  }, [orderProducts, taxTypesFiltered]);

  let customLabel = true;

  return (
    <div className="table_wrapper order_products_list">
      <Helmet>
        <body className="select_with_portal" />
      </Helmet>
      <table className="table">
        <thead className="table_thead">
          <tr className="table_thead_tr">
            <th className="table_thead_tr_th">Product</th>
            <th className="table_thead_tr_th">Warehouse</th>
            <th className="table_thead_tr_th">Quantity</th>
            <th className="table_thead_tr_th">Available</th>
            <th className="table_thead_tr_th">Price</th>
            <th className="table_thead_tr_th">Discount</th>
            <th className="table_thead_tr_th">Tax</th>
            <th className="table_thead_tr_th">Amount</th>
            <th />
          </tr>
        </thead>
        <tbody className="table_tbody">
          {orderProducts.map((op, i) => {
            const product = products.find((p) => p.id === op.productId) || {};
            if (op.type !== 'custom') {
              return (
                <tr
                  className="table_tbody_tr"
                  key={op[idKey] || op.id}
                  data-attr={op[idKey] || op.id}
                >
                  <td className="table_tbody_tr_td">
                    <div className="info">
                      <img src={product.images?.[0]?.src || product.images?.[0]?.medium || noImg} alt="" />
                      <span
                        onClick={() => handleNavigate(product.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        {product.title || op.title}
                      </span>
                    </div>
                  </td>
                  <td className="table_tbody_tr_td select">
                    <Select
                      menuPortalTarget={document.body}
                      options={product.warehouses}
                      labelPath="title"
                      valuePath="id"
                      value={_.get(op, 'warehouseId')}
                      onChange={(v) => {
                        _.set(orderProducts, `[${i}].warehouseId`, v);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      error={
                      !!_.get(errors, `orderProducts[${i}].warehouseId`)
                        || !!_.get(errors, `orderProducts[${i}].productId`)
}
                    />
                  </td>
                  <td className="table_tbody_tr_td">
                    <Input
                      onChange={(ev) => {
                        _.set(orderProducts, `[${i}].qty`, ev.target.value);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      value={_.get(op, 'qty', 1)}
                      type="number"
                      min={0}
                    />
                  </td>
                  <td className="table_tbody_tr_td">
                    {
                    product.warehouses
                      ?.find((w) => w.id === _.get(op, 'warehouseId'))?.available
                    || 0
                  }
                  </td>
                  <td
                    className="table_tbody_tr_td price"
                  >
                    <Input
                      onChange={(ev) => {
                        _.set(orderProducts, `[${i}].price`, ev.target.value);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      value={_.get(op, 'price', '')}
                      type="number"
                    />
                  </td>

                  <td className="table_tbody_tr_td discount">
                    <Input
                      onChange={(ev) => {
                        _.set(orderProducts, `[${i}].discountPercentage`, ev.target.value);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      value={_.get(op, 'discountPercentage', '')}
                      type="number"
                      min={0}
                      symbol="%"
                    />
                  </td>
                  <td className="table_tbody_tr_td select">
                    <Select
                      options={taxTypesFiltered}
                      valuePath="id"
                      getOptionLabel={(o) => `${o.title} (${o.rate}%)`}
                      menuPortalTarget={document.body}
                      getFullOption
                      onChange={(val) => {
                        _.set(orderProducts, `[${i}].taxId`, val?.id);
                        _.set(orderProducts, `[${i}].taxPercentage`, val?.rate || 0);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      value={op?.taxId}
                    />
                  </td>
                  <td
                    className="table_tbody_tr_td"
                  >
                    {format(_.get(total, `orderProducts[${i}].totalPrice`, 0), { code: currencyCode })}
                  </td>
                  <td className="table_tbody_tr_td action" style={{ pointerEvents: !loading ? 'auto' : 'none' }}>
                    <RemoveIcon onClick={() => {
                      const data = {
                        orderProducts: orderProducts.filter((pr, index) => index !== i),
                      };
                      onChange(null, data);
                    }}
                    />
                  </td>
                </tr>
              );
            }
            return null;
          })}
          {orderProducts.map((op, i) => {
            if (op.type === 'custom') {
              const label = customLabel ? 'Custom lines' : '';
              customLabel = false;
              return (
                <tr
                  className={`table_tbody_tr ${label ? 'custom_label' : ''}`}
                  key={op[idKey] || op.id}
                >
                  <td className="table_tbody_tr_td title_input">
                    <div style={{ minWidth: 183 }}>
                      <Input
                        label={label}
                        value={_.get(op, 'title')}
                        onChange={(ev) => {
                          _.set(orderProducts, `[${i}].title`, ev.target.value);
                          onChange('orderProducts', [...orderProducts]);
                        }}
                        error={!!_.get(errors, `orderProducts[${i}].title`)}
                      />
                    </div>
                  </td>
                  <td className="table_tbody_tr_td select" />
                  <td className="table_tbody_tr_td">
                    <Input
                      onChange={(ev) => {
                        _.set(orderProducts, `[${i}].qty`, ev.target.value);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      value={_.get(op, 'qty', 1)}
                      type="number"
                      min={0}
                    />
                  </td>
                  <td className="table_tbody_tr_td" />
                  <td
                    className="table_tbody_tr_td price"
                  >
                    <Input
                      onChange={(ev) => {
                        _.set(orderProducts, `[${i}].price`, ev.target.value);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      value={_.get(op, 'price', '')}
                      type="number"
                    />
                  </td>

                  <td className="table_tbody_tr_td discount">
                    <Input
                      onChange={(ev) => {
                        _.set(orderProducts, `[${i}].discountPercentage`, ev.target.value);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      value={_.get(op, 'discountPercentage', '')}
                      type="number"
                      min={0}
                      symbol="%"
                    />
                  </td>
                  <td className="table_tbody_tr_td select">
                    <Select
                      options={taxTypesFiltered}
                      valuePath="id"
                      getOptionLabel={(o) => `${o.title} (${o.rate}%)`}
                      menuPortalTarget={document.body}
                      getFullOption
                      onChange={(val) => {
                        _.set(orderProducts, `[${i}].taxId`, val?.id);
                        _.set(orderProducts, `[${i}].taxPercentage`, val?.rate || 0);
                        onChange('orderProducts', [...orderProducts]);
                      }}
                      value={op?.taxId}
                    />
                  </td>
                  <td
                    className="table_tbody_tr_td"
                  >
                    {format(_.get(total, `orderProducts[${i}].totalPrice`, 0), { code: currencyCode })}
                  </td>
                  <td className="table_tbody_tr_td action" style={{ pointerEvents: !loading ? 'auto' : 'none' }}>
                    <RemoveIcon onClick={() => {
                      const data = {
                        orderProducts: orderProducts.filter((pr, index) => index !== i),
                      };
                      onChange(null, data);
                    }}
                    />
                  </td>
                </tr>
              );
            }
            return null;
          })}

        </tbody>
      </table>
      {type === 'sale' ? (
        <div className="add_new_button_container">
          <Button btnType="transparent" addBtn onClick={handleAddProducts}>New row</Button>
        </div>
      ) : null}
      {type === 'purchase' ? (
        <LandedCoast
          onChange={onChange}
          landedCosts={landedCosts}
          currencyCode={currencyCode}
          total={total}
          errors={errors}
        />
      ) : null}

      <div className="resume">
        <div className="notes">
          <Input
            textarea
            label="Notes"
            placeholder="Enter any notes to be displayed in your transaction"
            onChange={(ev) => {
              onChange('notes[0].value', ev.target.value);
              onChange('notes[0].name', 'Note');
            }}
            value={notes[0]?.value}
          />

        </div>
        <div className="total">
          <div className="item">
            <p>Total Items</p>
            <p>{orderProducts.length}</p>
          </div>
          <div className="item">
            <p>Tax</p>
            <p>{format(total?.totalTax, { code: currencyCode })}</p>
          </div>
          <div className="item">
            <p>Shipping charges</p>
            <Input
              roundBorder
              onBlur={() => onChange('totalShippingPrice', format(totalShippingPrice, { code: currencyCode }))}
              onFocus={() => onChange('totalShippingPrice', unformat(totalShippingPrice, { code: currencyCode }))}
              onChange={(ev) => onChange('totalShippingPrice', ev.target.value)}
              value={totalShippingPrice}
            />
          </div>
          <div className="item" style={{ fontWeight: 600 }}>
            <p>Subtotal</p>
            <p>{format(total?.subtotalPrice, { code: currencyCode })}</p>
          </div>
          {type === 'purchase' ? (
            <div className="item" style={{ fontWeight: 600 }}>
              <p>Landed costs</p>
              <p>{format(total?.totalLandedCosts, { code: currencyCode })}</p>
            </div>
          ) : null}
          <div className="item" style={{ fontWeight: 700 }}>
            <p>Total</p>
            <p>{format(total?.totalPrice, { code: currencyCode })}</p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default OrderProductsList;
