import React, {
  useCallback, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../_common/Pagination/Pagination';
import { titleFilter } from '../Batches';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrow.svg';
import noImage from '../../../../../assets/icons/default.svg';
import { getBatchesList, singleBatchData } from '../../../../../store/actions/batches';
import Loader from '../../../../_common/Loader/Loader';

const SingleBatchesLeft = ({ changeTabs }) => {
  const { batchesId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectRef = useRef();

  const batchLists = useSelector((state) => state.batches.batchLists);
  const batchTotalPage = useSelector((state) => state.batches.batchTotalPage);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const changePage = useCallback(async (currentPage) => {
    setLoading(true);
    setPage(currentPage);

    const { payload } = await dispatch(getBatchesList({
      page: currentPage,
    }));

    if (page === 1 && !payload.batches.length) {
      navigate('/inventory/batches');
    }
    setLoading(false);
  }, []);

  const onBatchClick = (item) => {
    changeTabs('Sale orders');
    dispatch(singleBatchData(item));
    navigate(`/inventory/batches/single/${item.id}`);
  };

  return (
    <div className="single_batches_left_wrapper">
      <div
        className="batches_header_select_wrapper"
        ref={selectRef}
      >
        <p className="batches_header_title">
          All batches
        </p>
      </div>

      <div className="single_batches_left_body_wrapper">
        <div className={classNames('single_batches_left_body_item_wrapper', { loading })}>
          {batchLists.map((b) => (
            <div
              key={b.id}
              role="button"
              tabIndex="0"
              className={classNames('single_batches_left_body_item', {
                active: +b.id === +batchesId,
              })}
              onClick={() => onBatchClick(b)}
            >
              <div className="batches_table_number">
                {b.batchNumber}
              </div>

              <div className="product_item_wrapper">
                <div className="product_item_img">
                  <img
                    src={b.product.images[0]?.src || b.product.images[0]?.small || noImage}
                    alt="img"
                  />
                </div>

                <div className="product_item_title">
                  <p>{b.product.title}</p>
                </div>
              </div>
            </div>
          ))}

          {loading && <Loader />}
        </div>

        {+batchTotalPage > 1 && (
        <div className="batches_pagination">
          <Pagination
            page={page}
            totalPages={+batchTotalPage}
            showNumber={1}
            onChangePage={changePage}
          />
        </div>
        )}
      </div>

    </div>
  );
};

export default SingleBatchesLeft;
