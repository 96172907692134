import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Datepicker from '../../../_common/Form/Datepicker';
import noImg from '../../../../assets/icons/no_img.svg';
import {
  acceptOrderReturnRequest,
  orderReturnsListRequest,
} from '../../../../store/actions/orderReturns';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import { getProductsRequest } from '../../../../store/actions/products';

function ReceiveForm() {
  const [form, setForm] = useState({
    acceptedProducts: [],
    sourceCreatedAt: moment().format('YYYY-MM-DD'),
    note: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { returnId } = useParams();

  const orderReturnsList = useSelector((state) => state.orderReturns.orderReturnsList);

  const singleReturn = useMemo(() => orderReturnsList.find((o) => o.id === returnId) || {}, [orderReturnsList, returnId]);

  useEffect(() => {
    if (!singleReturn.orderReturnProducts) {
      return;
    }
    const acceptedProducts = singleReturn.orderReturnProducts.map((p) => ({
      returnProductId: p.id,
      qty: 0,
    }));
    handleChange('acceptedProducts', acceptedProducts);
  }, [singleReturn.orderReturnProducts]);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getProductsRequest(
        { ids: singleReturn.orderReturnProducts?.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [singleReturn]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(acceptOrderReturnRequest({ ...form, id: returnId }));
    if (data.status === 'error') {
      setErrors(data.errors);
    } else {
      await dispatch(orderReturnsListRequest({ ...Object.fromEntries(searchParams), includes: 'customer,order' }));
      toast.success('Sale return successfully created.');
      navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
    }
    setLoading(false);
  }, [form, returnId]);

  return (
    <div className="sales_return_form package_form">
      <form onSubmit={handleSubmit}>
        <div className="top">
          <div className="info">
            <div
              className="arrow"
              onClick={() => navigate(`${location.pathname}${location.search}`, { state: {}, replace: true })}
            >
              <ArrowIcon />
            </div>
            <div>
              <p className="title">New Return Receipt</p>
              <p className="number">{singleReturn.number}</p>
            </div>
          </div>
          <div className="actions">
            <Button
              btnType="cancel"
              roundBorder
              onClick={() => navigate(`${location.pathname}${location.search}`, {
                state: {},
                replace: true,
              })}
            >
              Cancel
            </Button>
            <Button roundBorder type="submit" loading={loading}>
              Receive
            </Button>
          </div>
        </div>
        <div className="row">
          <Datepicker
            label="Date"
            onChange={(date) => handleChange('sourceCreatedAt', moment(date).format('YYYY-MM-DD'))}
            value={form.sourceCreatedAt ? moment(form.sourceCreatedAt).toDate() : undefined}
          />
        </div>
        <div className="table_wrapper">
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Item</th>
                <th className="table_thead_tr_th ordered">Shipped</th>
                <th className="table_thead_tr_th packed">Returned</th>
                <th className="table_thead_tr_th">Receivable qty</th>
              </tr>
            </thead>
            <tbody className="table_tbody">
              {singleReturn?.orderReturnProducts?.map((p, i) => {
                const product = products?.find((f) => f.id === p.productId) || {};
                return (
                  <tr
                    className="table_tbody_tr"
                    key={p.id}
                  >
                    <td className="table_tbody_tr_td product_title">
                      <div className="info" style={{ alignItems: 'center' }}>
                        {p.type !== 'custom' ? (
                          <img
                            style={{ height: 30, width: 30 }}
                            src={product.images?.[0]?.src
                              || product.images?.[0]?.medium
                              || noImg}
                            alt=""
                          />
                        ) : null}
                        {p.type !== 'custom'
                          ? (
                            <Link
                              to={product.type === 'variant'
                                ? `/products/${product.parentId}?productVariantId=${product.id}`
                                : `/products/${product.id}`}
                              target="_blank"
                            >
                              {p.title}
                            </Link>
                          )
                          : <span>{p.title}</span>}
                      </div>
                    </td>
                    <td className="table_tbody_tr_td">
                      {p.qty || 0}
                    </td>
                    <td className="table_tbody_tr_td">
                      {p.receivedQty || 0}
                    </td>
                    <td className="table_tbody_tr_td qty_input">
                      <Input
                        onChange={(ev) => handleChange(`acceptedProducts[${i}]`, {
                          returnProductId: p.id,
                          qty: ev.target.value,
                        })}
                        value={form.acceptedProducts[i]?.qty}
                        type="number"
                        error={_.get(errors, `acceptedProducts[${i}].receivedQty`, '')}
                        readOnly={p.qty - p.receivedQty <= 0}
                        max={p.qty - (p.receivedQty || 0)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="note">
            <Input
              textarea
              roundBorder
              label="Notes"
              value={form.note}
              onChange={(ev) => handleChange('note', ev.target.value)}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ReceiveForm;
