import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const generateOrderNumberRequest = createAsyncThunk(
  'orders/generateOrderNumberRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.generateOrderNumber(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createOrderRequest = createAsyncThunk(
  'orders/createOrderRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createOrder(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getOrdersRequest = createAsyncThunk(
  'orders/getOrdersRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getOrders(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getSingleOrderRequest = createAsyncThunk(
  'orders/getSingleOrderRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSingleOrder(payload);
      await thunkAPI.dispatch(getOrderCommentsAndHistoryRequest(data.order.id));
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const updateOrderRequest = createAsyncThunk(
  'orders/updateOrderRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, ...d } = payload;
      const { data } = await Api.updateOrder(id, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const calculateOrderPriceRequest = createAsyncThunk(
  'orders/calculateOrderPriceRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.calculateOrderPrice(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const viewOrderQuotationRequest = createAsyncThunk(
  'orders/viewOrderQuotationRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, type, ...params } = payload;
      const { data } = await Api.viewOrderQuotation(id, type, params);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getOrderCommentsAndHistoryRequest = createAsyncThunk(
  'orders/getOrderCommentsAndHistoryRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getOrderCommentsAndHistory(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createOrderCommentsAndHistoryRequest = createAsyncThunk(
  'orders/createOrderCommentsAndHistoryRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createOrderCommentsAndHistory(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const deleteOrderCommentsAndHistoryRequest = createAsyncThunk(
  'orders/deleteOrderCommentsAndHistoryRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteOrderCommentsAndHistory(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const recordPaymentRequest = createAsyncThunk(
  'orders/recordPaymentRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, ...d } = payload;
      const { data } = await Api.recordPayment(id, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getSalesOrdersTableAttributes = createAsyncThunk(
  'orders/getSalesOrdersTableAttributes',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['ordersTableAttributes']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const archiveOrderRequest = createAsyncThunk(
  'orders/archiveOrderRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.archiveOrder(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const unArchiveOrderRequest = createAsyncThunk(
  'orders/unArchiveOrderRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.unArchiveOrder(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const generatePaymentNumberRequest = createAsyncThunk(
  'orders/generatePaymentNumberRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.generatePaymentNumber(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const assignOrderToUserRequest = createAsyncThunk(
  'orders/assignOrderToUserRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.assignOrderToUser(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getOrderTagsRequest = createAsyncThunk(
  'orders/getOrderTagsRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getOrdersTags(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createOrderTagRequest = createAsyncThunk(
  'orders/createOrderTagRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createOrderTag(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const bulkPayOrderRequest = createAsyncThunk(
  'bulk/pay/order/request',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.bulkPayOrders(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const sendOrderToRequest = createAsyncThunk(
  'orders/sendOrderToRequest',
  async (payload, thunkAPI) => {
    try {
      const { integration, ...p } = payload;
      const { data } = await Api.sendOrderTo(integration, p);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const duplicateOrderRequest = createAsyncThunk(
  'orders/duplicateOrderRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.duplicateOrder(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const salesOrdersTableEditableEvent = createAction(
  'orders/salesOrdersTableEditableEvent',
);

export const changeSalesOrdersTableAttributes = createAction(
  'orders/changeSalesOrdersTableAttributes',
);
