import React, {
  forwardRef, useCallback, useId,
} from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components as comps } from 'react-select';
import AsyncSelect from 'react-select/async';

import CreatableSelect from 'react-select/creatable';
import { AsyncPaginate } from 'react-select-async-paginate';
import classNames from 'classnames';
import Loader from '../Loader/Loader';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as ClearIcon } from '../../../assets/icons/cancel.svg';
import UserPermissions from '../../../helpers/UserPermissions';

const defaultIsOptionDisabled = (option) => !UserPermissions.hasAccess(option.scope);

const Select = forwardRef(({
  options, value, onChange, placeholder, label, isDisabled, isAsync, isSearchable, isCreatable, isClearable, labelPath,
  valuePath, wrapperClassName, error, isAsyncPaginate, defaultValue, getFullOption, roundBorder, fieldset, size,
  loading, lightBlue, isMulti, components = {}, addNew, onAddNewClick, dataTestId,
  isOptionDisabled = defaultIsOptionDisabled, ...p
}, ref) => {
  const id = useId();

  const DropdownIndicator = useCallback((prop) => {
    const { isLoading, menuIsOpen } = prop.selectProps;

    return (
      <div className="dropdown_indicator_wrapper">
        {isLoading || loading
          ? <Loader size={12} borderWidth={2} color="rgba(17,28,56,0.5)" />
          : <ArrowIcon className={`select__arrow ${menuIsOpen ? 'select__arrow_up' : ''}`} />}
      </div>
    );
  }, [loading]);

  const ClearIndicator = useCallback(({ innerProps: { ref: clearIndicatorRef, ...restInnerProps } }) => (
    <ClearIcon
      {...restInnerProps}
      ref={clearIndicatorRef}
      className="clear_indicator_wrapper"
    />
  ), []);

  const Menu = (pr) => (
    <comps.Menu
      {...pr}
    >
      {pr.children}
      {addNew ? (
        <div
          onClick={onAddNewClick}
          className="add_new_button"
          data-scope={typeof addNew === 'boolean' ? undefined : addNew}
        >
          <span className="plus">+</span>
          <span>Add new</span>
        </div>
      ) : null}
    </comps.Menu>
  );

  return (
    <div
      className={classNames('select__wrapper', wrapperClassName, {
        error,
        round_border: roundBorder,
        fieldset,
        lightBlue,
        [`size_${size}`]: !!size,
        multiple: isMulti,
      })}
      data-test-id={dataTestId}
    >
      {label && <label className="label">{label}</label>}

      {isAsyncPaginate ? (
        <AsyncPaginate
          id={`select_${id}`}
          selectRef={ref}
          classNamePrefix="direct"
          components={{
            ClearIndicator, DropdownIndicator, Menu, ...components,
          }}
          value={options.find((o) => o[valuePath] === value) || null}
          options={options}
          onChange={(o) => onChange(getFullOption ? o : (o?.[valuePath] || ''))}
          placeholder={placeholder}
          isDisabled={isDisabled}
          getOptionValue={(option) => option[valuePath]}
          getOptionLabel={(option) => option[labelPath]}
          defaultValue={defaultValue}
          isClearable={isClearable}
          isOptionDisabled={isOptionDisabled}
          {...p}
        />
      )
        : isAsync ? (
          <AsyncSelect
            id={`select_${id}`}
            ref={ref}
            classNamePrefix="direct"
            components={{
              ClearIndicator, DropdownIndicator, Menu, ...components,
            }}
            onChange={(o) => onChange(getFullOption ? o : (o?.[valuePath] || ''))}
            placeholder={placeholder}
            isDisabled={isDisabled}
            isClearable={isClearable}
            value={value}
            isMulti={isMulti}
            getOptionValue={(option) => option[valuePath]}
            getOptionLabel={(option) => option[labelPath]}
            isOptionDisabled={isOptionDisabled}
            {...p}
          />
        )
          : isCreatable ? (
            <CreatableSelect
              id={`select_${id}`}
              ref={ref}
              value={value}
              classNamePrefix="direct"
              components={{
                ClearIndicator, DropdownIndicator, Menu, ...components,
              }}
              options={options}
              onChange={(o) => onChange(getFullOption ? o : (o?.[valuePath] || ''))}
              placeholder={placeholder}
              isDisabled={isDisabled}
              getOptionValue={(option) => option[valuePath]}
              getOptionLabel={(option) => option[labelPath]}
              isClearable={isClearable}
              isMulti={isMulti}
              isOptionDisabled={isOptionDisabled}
              {...p}
            />
          )
            : (
              <ReactSelect
                id={`select_${id}`}
                ref={ref}
                classNamePrefix="direct"
                components={{
                  ClearIndicator, DropdownIndicator, Menu, ...components,
                }}
                value={isMulti
                  ? value.map((v) => options.find((o) => (o[valuePath] === v || o[valuePath] === v[valuePath])))
                  : (options.find((o) => (o?.[valuePath] === value || o?.[valuePath] === value?.[valuePath])) || null)}
                options={options}
                onChange={(o) => onChange(isMulti
                  ? o.map((opt) => opt[valuePath])
                  : getFullOption ? o : (o?.[valuePath] || ''))}
                placeholder={placeholder}
                isDisabled={isDisabled}
                getOptionValue={(option) => option[valuePath]}
                getOptionLabel={(option) => option[labelPath]}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isMulti={isMulti}
                isOptionDisabled={isOptionDisabled}
                {...p}
              />
            )}

      {!!error && typeof error === 'string' && <p className="error_text">{error}</p>}
    </div>
  );
});

export default Select;
Select.propTypes = {
  options: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))]),
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isMulti: PropTypes.bool,
  isAsync: PropTypes.bool,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isAsyncPaginate: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  valuePath: PropTypes.string,
  labelPath: PropTypes.string,
  roundBorder: PropTypes.bool,
  fieldset: PropTypes.bool,
  lightBlue: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default']),
  addNew: PropTypes.bool,
  onAddNewClick: PropTypes.func,
  dataTestId: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  value: '',
  placeholder: '',
  label: '',
  wrapperClassName: '',
  isMulti: false,
  isAsync: false,
  isCreatable: false,
  isClearable: false,
  isDisabled: false,
  isSearchable: false,
  isAsyncPaginate: false,
  error: '',
  valuePath: 'value',
  labelPath: 'label',
  roundBorder: false,
  fieldset: false,
  lightBlue: false,
  loading: false,
  size: 'default',
  addNew: false,
  onAddNewClick: null,
  dataTestId: '',
};
