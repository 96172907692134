import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../_common/Form/Input';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';
import { ReactComponent as AddIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { addressIdKey } from '../../../../store/reducers/customers';
import { setCustomerFormData } from '../../../../store/actions/customers';
import { getRegionsRequest } from '../../../../store/actions/app';

function Addresses() {
  const containerRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { shippingAddresses, billingAddress } = useSelector((state) => state.customers.customerFormData);
  const errors = useSelector((state) => state.customers.errors);
  const countries = useSelector((state) => state.app.countries);
  const regions = useSelector((state) => state.app.regions);

  const handleAddAddress = useCallback(async () => {
    await dispatch(setCustomerFormData({
      key: 'shippingAddresses',
      value: [...shippingAddresses, { [addressIdKey]: _.uniqueId() }],
    }));
    const { bottom, height } = containerRef.current.getBoundingClientRect();
    containerRef.current.scrollTo({
      top: bottom + height,
      left: 0,
      behavior: 'smooth',
    });
  }, [shippingAddresses, containerRef.current]);

  const handleCountryChange = useCallback(async (key, value) => {
    handleChange(`${key}.countryCode`, value);
    handleChange(`${key}.region`, '');
    setLoading(true);
    await dispatch(getRegionsRequest({ country: value }));
    setLoading(false);
  }, []);

  const handleChange = useCallback((key, value) => {
    dispatch(setCustomerFormData({ key, value }));
  }, []);

  const handleRemoveAddress = useCallback((id) => {
    dispatch(setCustomerFormData({
      key: 'shippingAddresses',
      value: shippingAddresses.filter((c) => c[addressIdKey] !== id),
    }));
  }, [shippingAddresses]);

  const handleCopyAddress = useCallback(() => {
    const data = {
      title: billingAddress.title,
      firstName: billingAddress.firstName,
      lastName: billingAddress.lastName,
      countryCode: billingAddress.countryCode,
      region: billingAddress.region,
      city: billingAddress.city,
      postalCode: billingAddress.postalCode,
      address1: billingAddress.address1,
      address2: billingAddress.address2,
      phone: billingAddress.phone,
    };
    handleChange('shippingAddresses[0]', data);
  }, [billingAddress]);

  return (
    <div className="addresses" ref={containerRef}>
      <div className="addressItem">
        <p className="title">Billing address</p>
        <div className="row">
          <Input
            roundBorder
            label="Title"
            placeholder="Title"
            onChange={(ev) => handleChange('billingAddress.title', ev.target.value)}
            value={_.get(billingAddress, 'title', '')}
            error={_.get(errors, 'billingAddress.title')}
          />
          <Input
            roundBorder
            label="First Name"
            placeholder="First Name"
            onChange={(ev) => handleChange('billingAddress.firstName', ev.target.value)}
            value={_.get(billingAddress, 'firstName', '')}
            error={_.get(errors, 'billingAddress.firstName')}
          />
          <Input
            roundBorder
            label="Last Name"
            placeholder="Last Name"
            onChange={(ev) => handleChange('billingAddress.lastName', ev.target.value)}
            value={_.get(billingAddress, 'lastName', '')}
            error={_.get(errors, 'billingAddress.lastName')}
          />
        </div>
        <div className="row">
          <Select
            options={countries}
            roundBorder
            isSearchable
            label="Country/region"
            placeholder="Country"
            onChange={(val) => handleCountryChange('billingAddress', val)}
            value={_.get(billingAddress, 'countryCode', '')}
            valuePath="code"
            labelPath="name"
            error={_.get(errors, 'billingAddress.countryCode')}
          />
          <Select
            options={regions[billingAddress.countryCode]}
            roundBorder
            label="State"
            placeholder="State"
            loading={loading}
            onChange={(value) => handleChange('billingAddress.region', value)}
            value={regions[billingAddress.countryCode]?.find((c) => c.value === billingAddress.region)}
            error={_.get(errors, 'billingAddress.region')}
            isCreatable
          />
          <Input
            roundBorder
            label="City"
            placeholder="City"
            onChange={(ev) => handleChange('billingAddress.city', ev.target.value)}
            value={_.get(billingAddress, 'city', '')}
            error={_.get(errors, 'billingAddress.city ')}
          />
        </div>
        <div className="row">
          <Input
            roundBorder
            label="Zip"
            placeholder="Zip"
            onChange={(ev) => handleChange('billingAddress.postalCode', ev.target.value)}
            value={_.get(billingAddress, 'postalCode', '')}
            error={_.get(errors, 'billingAddress.postalCode')}
          />
          <Input
            roundBorder
            label="Address 1"
            placeholder="Address 1"
            onChange={(ev) => handleChange('billingAddress.address1', ev.target.value)}
            value={_.get(billingAddress, 'address1', '')}
            error={_.get(errors, 'billingAddress.address1')}
          />
          <Input
            roundBorder
            label="Address 2"
            placeholder="Address 2"
            onChange={(ev) => handleChange('billingAddress.address2', ev.target.value)}
            value={_.get(billingAddress, 'address2', '')}
            error={_.get(errors, 'billingAddress.address2')}
          />
        </div>
        <div className="row">
          <Input
            roundBorder
            label="Mobile phone"
            symbol="+"
            onChange={(ev) => handleChange('billingAddress.phone', ev.target.value ? `+${ev.target.value}` : undefined)}
            value={_.get(billingAddress, 'phone', '').replace('+', '')}
            error={_.get(errors, 'billingAddress.phone')}
          />
        </div>
      </div>
      <div>

        {shippingAddresses.map((a, i) => (
          <div className={`item ${i > 0 ? 'addressItemShipping' : ''}`} key={a[addressIdKey]}>
            <div className="top">
              <p className="title">
                <span className="address_type">Shipping address</span>
                <span>{shippingAddresses.length > 1 ? ` ${i + 1}` : ''}</span>
                {i === 0 ? (
                  <span className="copy_address" onClick={handleCopyAddress}>
                    <CopyIcon />
                    Copy from Billing address
                  </span>
                ) : null}
              </p>
              {i === 0 ? (
                <Button btnType="transparent" iconLeft={<AddIcon />} onClick={handleAddAddress}>
                  New shipping
                  address
                </Button>
              ) : null}
              {i > 0 ? (
                <p className="removeIcon" onClick={() => handleRemoveAddress(a[addressIdKey])}>
                  <RemoveIcon />
                </p>
              ) : null}
            </div>
            <div>
              <div className="row">
                <Input
                  roundBorder
                  label="Title"
                  placeholder="Title"
                  onChange={(ev) => handleChange(`shippingAddresses[${i}].title`, ev.target.value)}
                  value={_.get(shippingAddresses, `[${i}].title`, '')}
                  error={_.get(errors, `shippingAddresses[${i}].title`)}
                />
                <Input
                  roundBorder
                  label="First Name"
                  placeholder="First Name"
                  onChange={(ev) => handleChange(`shippingAddresses[${i}].firstName`, ev.target.value)}
                  value={_.get(shippingAddresses, `[${i}].firstName`, '')}
                  error={_.get(errors, `shippingAddresses[${i}].firstName`)}
                />
                <Input
                  roundBorder
                  label="Last Name"
                  placeholder="Last Name"
                  onChange={(ev) => handleChange(`shippingAddresses[${i}].lastName`, ev.target.value)}
                  value={_.get(shippingAddresses, `[${i}].lastName`, '')}
                  error={_.get(errors, `shippingAddresses[${i}].lastName`)}
                />
              </div>
              <div className="row">
                <Select
                  options={countries}
                  isSearchable
                  roundBorder
                  label="Country/region"
                  placeholder="Country"
                  onChange={(val) => handleCountryChange(`shippingAddresses[${i}]`, val)}
                  value={_.get(shippingAddresses, `[${i}].countryCode`, '')}
                  valuePath="code"
                  labelPath="name"
                  error={_.get(errors, `shippingAddresses[${i}].countryCode`)}
                />
                <Select
                  options={regions[shippingAddresses[i].countryCode]}
                  isSearchable
                  roundBorder
                  label="State"
                  placeholder="State"
                  onChange={(value) => handleChange(`shippingAddresses[${i}].region`, value)}
                  value={regions[shippingAddresses[i].countryCode]?.find((c) => c.value === shippingAddresses[i].region)}
                  error={_.get(errors, `shippingAddresses[${i}].region`)}
                  loading={loading}
                  isCreatable
                />
                <Input
                  roundBorder
                  label="City"
                  placeholder="City"
                  onChange={(ev) => handleChange(`shippingAddresses[${i}].city`, ev.target.value)}
                  value={_.get(shippingAddresses, `[${i}].city`, '')}
                  error={_.get(errors, `shippingAddresses[${i}].city`)}
                />
              </div>
              <div className="row">
                <Input
                  roundBorder
                  label="Zip"
                  placeholder="Zip"
                  onChange={(ev) => handleChange(`shippingAddresses[${i}].postalCode`, ev.target.value)}
                  value={_.get(shippingAddresses, `[${i}].postalCode`, '')}
                  error={_.get(errors, `shippingAddresses[${i}].postalCode`)}
                />
                <Input
                  roundBorder
                  label="Address 1"
                  placeholder="Address 1"
                  onChange={(ev) => handleChange(`shippingAddresses[${i}].address1`, ev.target.value)}
                  value={_.get(shippingAddresses, `[${i}].address1`, '')}
                  error={_.get(errors, `shippingAddresses[${i}].address1`)}
                />
                <Input
                  roundBorder
                  label="Address 2"
                  placeholder="Address 2"
                  onChange={(ev) => handleChange(`shippingAddresses[${i}].address2`, ev.target.value)}
                  value={_.get(shippingAddresses, `[${i}].address2`, '')}
                  error={_.get(errors, `shippingAddresses[${i}].address2`)}
                />
              </div>
              <div className="row">
                <Input
                  roundBorder
                  label="Mobile phone"
                  symbol="+"
                  onChange={(ev) => handleChange(`shippingAddresses[${i}].phone`, ev.target.value ? `+${ev.target.value}` : undefined)}
                  value={_.get(shippingAddresses, `[${i}].phone`, '').replace('+', '')}
                  error={_.get(errors, `shippingAddresses[${i}].phone`)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Addresses;
