import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import Wrapper from '../../../Layout/Wrapper';
import Tabs from '../../../_common/Tabs/Tabs';
import EditPrice from './EditPrice';
import PriceToProducts from './PriceToProducts';

function EditPriceLists() {
  const { priceId, editType } = useParams();

  const priceTabs = useMemo(() => ([
    {
      name: 'Edit',
      href: `/inventory/price-lists/${priceId}/edit`,
      component: <EditPrice />,
    },
    {
      name: 'Price list products',
      href: `/inventory/price-lists/${priceId}/price-list-products`,
      component: <PriceToProducts />,
      scope: 'READ_PRODUCTS',
    },
  ]), [priceId, editType]);

  if (!priceTabs.find((t) => t.href.includes(editType))) {
    return <Navigate to="/404" />;
  }

  return (
    <Wrapper title="Edit price lists">
      <Tabs tabs={priceTabs} />
    </Wrapper>
  );
}

export default EditPriceLists;
