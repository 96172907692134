import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { format } from 'currency-formatter';
import _ from 'lodash';
import { getCreditNotesRequest } from '../../../../store/actions/creditNotes';
import Utils from '../../../../helpers/Utils';

function CreditNotesList() {
  const dispatch = useDispatch();
  const { noteId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams({});
  const ref = useRef();

  const creditNotes = useSelector((state) => state.creditNotes.creditNotes);

  useEffect(() => {
    dispatch(getCreditNotesRequest({ ...Object.fromEntries(searchParams), includes: 'customer' }));
  }, [searchParams]);

  return (
    <div className="orders_list">
      <div className="top">
        <p className="title">Credit notes</p>
      </div>
      <div className="list_container">
        <table className="list">
          <tbody>
            {creditNotes?.map((cn) => (
              <tr
                key={cn.id}
                className={noteId === cn.id ? 'active' : ''}
                ref={ref}
                onClick={() => navigate(`/orders/credit-notes/preview/${cn.id}${location.search}`, { replace: true })}
              >
                <td>
                  <div>
                    <p className="name">{`${cn.customer.firstName || '-'} ${cn.customer.lastName || '-'}`}</p>
                    <p className="number">{cn.number}</p>
                  </div>
                </td>
                <td
                  className="info"
                >
                  <div>
                    <p className="price">{format(cn.credit, { code: cn.currencyCode })}</p>
                    <p className={`status ${Utils.getCreditNoteStatus(
                      cn?.credit,
                      cn?.refunded,
                      cn?.applied,
                    )}`}
                    >
                      <span className="status_dot" />
                      {_.startCase(Utils.getCreditNoteStatus(
                        cn?.credit,
                        cn?.refunded,
                        cn?.applied,
                      ))}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CreditNotesList;
