import React, { useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Checkbox from '../../../_common/Form/Checkbox';
import WarningModal from '../../../_common/Modal/WarningModal';
import Api from '../../../../Api';

const ProductSettings = ({ changeSettings, settings }) => {
  const { productId } = useParams();
  const [openInfoModal, setOpenInfoModal] = useState('');
  const [loading, setLoading] = useState(false);

  const changeSettingsBatchesOrSerialTracking = (path) => {
    if (settings) {
      setOpenInfoModal(path || 'none');
    } else {
      changeSettings(path);
    }
  };

  const onModalSave = async () => {
    if (productId !== 'add') {
      setLoading(true);

      try {
        await Api.deleteBatchesByProduct(productId);
      } catch (e) {
        toast.error(e.response.data.message);
        setLoading(false);
        return;
      }
      setLoading(false);
    }

    setOpenInfoModal('');
    changeSettings(openInfoModal === 'none' ? '' : openInfoModal);
  };

  return (
    <div className="create_product_setting_wrapper">
      <h2>Settings</h2>

      <Checkbox
        onChange={() => changeSettingsBatchesOrSerialTracking('')}
        checked={settings === ''}
        className={classNames('create_product_setting_radio', { active: settings === '' })}
        radio
        label="None"
      />

      <Checkbox
        onChange={() => changeSettingsBatchesOrSerialTracking('batchNumber')}
        checked={settings === 'batchNumber'}
        className={classNames('create_product_setting_radio', { active: settings === 'batchNumber' })}
        radio
        label="Batch tracking"
      />

      <Checkbox
        onChange={() => changeSettingsBatchesOrSerialTracking('serialNumber')}
        checked={settings === 'serialNumber'}
        className={classNames('create_product_setting_radio', { active: settings === 'serialNumber' })}
        radio
        label="Serial numbers tracking"
      />

      <Checkbox
        onChange={() => {}}
        checked={false}
        className="create_product_setting_radio"
        label="Don’t allow negative stock"
      />

      <WarningModal
        isOpen={!!openInfoModal}
        btnText="Ok"
        onClose={() => setOpenInfoModal('')}
        text="Are you sure you want to change the tracking method? All previous changes will be permanently deleted"
        onSaveClick={onModalSave}
        loading={loading}
      />
    </div>
  );
};

ProductSettings.propTypes = {
  changeSettings: PropTypes.func,
  settings: PropTypes.string,
};

ProductSettings.defaultProps = {
  changeSettings: () => {},
  settings: '',
};

export default ProductSettings;
