import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../_common/Form/Button';

function SubscriptionCard({
  children, title, onClick, btnType, btnTitle, planTitle, className, reverseButton, onSecondButtonClick, secondBtnTitle,
  secondBtnType,
}) {
  return (
    <div className={`billing_card_wrp ${className}`}>
      <div className="billing_card_top">
        <p className="billing_card_title">
          {title}

          <span>{planTitle}</span>
        </p>

        <div className="billing_card_btns">
          {onSecondButtonClick && (
            <Button
              size="small"
              onClick={onSecondButtonClick}
              className="billing_card_button"
              roundBorder
              color="#1472FF"
              reverseColor={reverseButton}
              title={secondBtnTitle}
              btnType={secondBtnType}
            />
          )}

          {onClick && (
            <Button
              size="small"
              onClick={onClick}
              className="billing_card_button"
              roundBorder
              btnType={btnType}
              color="#1472FF"
              reverseColor={reverseButton}
            >
              {btnTitle}
            </Button>
          )}
        </div>
      </div>

      <div className="billing_card_bottom">
        {children}
      </div>
    </div>
  );
}

SubscriptionCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  secondBtnTitle: PropTypes.string,
  className: PropTypes.string,
  planTitle: PropTypes.string,
  onClick: PropTypes.func,
  onSecondButtonClick: PropTypes.func,
  reverseButton: PropTypes.bool,
  btnType: PropTypes.string,
  secondBtnType: PropTypes.string,
};

SubscriptionCard.defaultProps = {
  title: 'Settings',
  secondBtnTitle: '',
  planTitle: '',
  className: '',
  onClick: undefined,
  onSecondButtonClick: undefined,
  reverseButton: false,
  btnType: 'primary',
  secondBtnType: 'transparent',
};

export default SubscriptionCard;
