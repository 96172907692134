import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import Wrapper from '../../Layout/Wrapper';
import Loader from '../../_common/Loader/Loader';
import Api from '../../../Api';
import Input from '../../_common/Form/Input';
import Table from '../../_common/Tables/Table';
import Pagination from '../../_common/Pagination/Pagination';
import Button from '../../_common/Form/Button';
import { ReactComponent as NotFoundIcon } from '../../../assets/icons/no_match.svg';

function Reorders() {
  const navigate = useNavigate();
  const firstLoad = useRef(true);

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [tableLoading, setTableLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [reorders, setReorders] = useState([]);

  useEffect(() => {
    (async () => {
      await getReorderRules();

      firstLoad.current = false;
    })();
  }, [queryData]);

  const tableHeader = useMemo(() => (
    [
      {
        title: 'Reorder#',
        path: 'number',
        navigate: (id) => navigate(`/stocks/reorders/${id}`),
      },
      {
        title: 'Title',
        path: 'title',
      },
      {
        title: 'Warehouse',
        path: 'warehouseName',
      },
      {
        title: 'Vendor',
        path: 'customerName',
      },
      {
        title: 'Reorder Qty',
        path: 'reorderQty',
      },
      {
        title: 'Minimum stock',
        path: 'minStock',
      },
      {
        title: 'Company',
        path: 'companyName',
      },
      {
        title: 'Currency',
        path: 'currencyCode',
      },
      {
        title: 'Created at',
        path: 'createdAt',
      },
      {
        title: 'Last updated',
        path: 'updatedAt',
      },
    ]
  ), []);

  const getReorderRules = useCallback(async () => {
    setTableLoading(true);

    const { data } = await Api.getReorders({ ...queryData, limit: 2 });

    setTotalPages(data.totalPages);

    const customersIds = [];

    data.reorders.forEach((r) => {
      customersIds.push(r.vendorId);
    });

    const [warehouses, customers, companies] = await Promise.all([
      Api.getWarehouses(),
      Api.getCustomers({ includeIds: customersIds.join(',') || undefined, limit: 1 }),
      Api.getCompanies(),
    ]);

    setReorders(data.reorders.map((r) => {
      const newData = { ...r };

      const foundCustomer = customers.data.customers.find((c) => +c.id === +r.vendorId);

      newData.warehouseName = warehouses.data.warehouses.find((w) => +w.id === +r.warehouseId).title;
      newData.customerName = `${foundCustomer.firstName} ${foundCustomer.lastName}`;
      newData.companyName = companies.data.companies.find((c) => +c.id === +r.companyId).name;

      return newData;
    }));

    setDeleteLoading(false);
    setTableLoading(false);
  }, [queryData]);

  const onReorderDelete = useCallback(async (id) => {
    setDeleteLoading(true);

    await Api.deleteReorder(id);
    setQuery((prev) => ({
      ...prev,
      page: prev.page > 1
        ? reorders.length === 1 ? prev.page - 1
          : prev.page
        : 1,
    }));

    if ((queryData.page > 1 && reorders.length > 1) || queryData.page === 1) {
      await getReorderRules();
    }
  }, [reorders]);

  return (
    <Wrapper
      title="Reorder rules"
      onBtnClick={!!reorders.length && !queryData.s ? () => { navigate('/stocks/reorders/add'); } : null}
      addBtn
      buttonTitle="Add new"
    >
      <div className="reorders">
        {firstLoad.current && tableLoading ? <Loader /> : (
          <div style={{ height: '100%' }}>
            <div className="reorders_top">
              <div className="reorder_filter_search">
                <Input
                  value={queryData.s}
                  placeholder="Search"
                  size="small"
                  roundBorder
                  search
                  onChangeText={(val) => setQuery((prev) => ({
                    ...prev,
                    s: val,
                  }))}
                  data-test-id="filter_search"
                />
              </div>
            </div>

            <div className="reorders_table">
              <Table
                data={reorders}
                header={tableHeader}
                onDeleteClick={(id) => onReorderDelete(id)}
                deleteLoading={deleteLoading}
                loading={tableLoading}
                deleteModalText="reorder rule"
                notFound={(
                  <>
                    <NotFoundIcon />

                    <h1>Roles not found</h1>

                    <Button
                      title="Add new"
                      href="/stocks/reorders/add"
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />

              <Pagination
                totalPages={totalPages}
                currentPage={queryData.page}
                onChangePage={(p) => setQuery((prev) => ({ ...prev, page: p }))}
              />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default Reorders;
