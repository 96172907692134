import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import Checkbox from '../../../_common/Form/Checkbox';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_with_circle.svg';
import Chip from '../../../_common/Chip/Chip';
import MultipleTagInput from '../../../_common/Form/MultipleTagInput';

function ProductOptions({
  onVariantOrBundleCheck,
  hasOption,
  setProductData,
  variants = [],
  setProductHasOption,
  warehouses,
  priceData,
  dimensions,
  dimensionsUnit,
  weight,
  weightUnit,
  optionData,
  setOptionData,
  customFields,
  setProductErrors,
}) {
  const isInitialized = useRef(false);
  const [show, setShow] = useState(true);
  const [errors, setErrors] = useState({});
  const [createdOptions, setCreatedOptions] = useState({});

  useEffect(() => {
    if (!isInitialized.current && variants.length) {
      const settingData = {};

      variants.forEach((v) => {
        v.attributes.forEach((atr) => {
          const title = atr.title ? atr.title : atr.name;

          if (settingData[title]) {
            settingData[title] = _.uniq([...settingData[title], atr.value]);
          } else {
            settingData[title] = [atr.value];
          }
        });
      });

      isInitialized.current = true;
      setCreatedOptions(settingData);

      if (!_.isEmpty(settingData)) setProductHasOption(true);
    }
  }, [variants]);

  const onOptionChange = useCallback((path, value, id) => {
    setOptionData((prev) => prev.map((line) => (line.id === id ? {
      ...line,
      [path]: value,
    } : line)));

    setErrors((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [path]: '',
      },
    }));
  }, []);

  const addNewOptionClick = useCallback(() => {
    setOptionData((prev) => [...prev, {
      name: '',
      value: '',
      id: +new Date(),
    }]);
  }, []);

  const deleteLine = useCallback((id) => {
    setOptionData((prev) => prev.filter((o) => +o.id !== +id));
    setErrors((prev) => ({
      ...prev,
      [id]: {
        name: '',
        value: '',
      },
    }));
  }, []);

  const createOption = useCallback((optData) => {
    const settingData = {};

    const optNames = Object.keys(createdOptions);
    const newOptNames = optData.map((o) => o.name);

    const allOpts = _.uniq([...optNames, ...newOptNames]);

    if (allOpts.length > 3) {
      toast.error('Max allowed options count is 3.');

      return;
    }

    optData.forEach((o) => {
      settingData[o.name] = o.value;
    });

    setCreatedOptions((prev) => {
      const newOptions = { ...prev };

      _.forEach(settingData, (value, key) => {
        newOptions[key] = prev[key] ? _.uniq([...prev[key], ...value]) : value;
      });

      return newOptions;
    });

    setErrors({});
  }, [createdOptions]);

  const deleteOption = useCallback((key, value) => {
    setCreatedOptions((prev) => {
      if (prev[key].length === 1) {
        const newOptions = { ...prev };
        delete newOptions[key];

        return newOptions;
      }
      return {
        ...prev,
        [key]: prev[key].filter((o) => o !== value),
      };
    });
  }, []);

  const onAddOption = useCallback(() => {
    let hasError = false;

    _.forEach(optionData, (val) => {
      if (!val.name) {
        setErrors((prev) => ({
          ...prev,
          [val.id]: {
            ...prev[val.id],
            name: 'Please enter option name',
          },
        }));

        hasError = true;
      }

      if (!val.value.length) {
        setErrors((prev) => ({
          ...prev,
          [val.id]: {
            ...prev[val.id],
            value: 'Please enter value',
          },
        }));

        hasError = true;
      }
    });

    if (!hasError) {
      createOption(optionData);

      setOptionData([]);
    }
  }, [optionData, errors]);

  const onGenerateVariant = () => {
    const options = _.map(createdOptions, (value) => value);
    const keys = Object.keys(createdOptions);
    const variantCount = options.reduce((count, currentValue) => count * currentValue.length, 1);

    if (variantCount > 100) {
      toast.error('Max variant generation quantity is 100.');

      return;
    }

    const results = generateAllProductVariations(options);

    const resultVariants = results.map((r) => r.map((val, index) => (
      {
        title: keys[index],
        value: val,
      }
    )));

    const generateVariantId = (v) => (
      _.orderBy(v.attributes, 'title')
        .map((d) => `${d.title}:${d.value}`)
        .join(',')
    );

    const newVariants = resultVariants.map((attributes) => ({
      title: attributes.map((a) => a.value).join('/'),
      attributes,
    }));

    const deleteVariants = _.differenceBy(variants, newVariants, generateVariantId);
    const createVariants = _.differenceBy(newVariants, variants, generateVariantId)
      .map((c) => ({
        ...c,
        id: generateVariantId(c),
        price: '',
        stocks: [],
        sku: generateSKU(),
        quantity: '',
        // allQuantity: '',
        priceData,
        images: [],
        customFields,
        dimensions: dimensions || [],
        weight: weight || '',
        weightUnit: weightUnit || 'oz',
        dimensionsUnit: dimensionsUnit || 'cm',
        variantStockWarehouses: warehouses,
      }));

    const updateVariants = variants.filter(
      (v) => !deleteVariants.some((d) => generateVariantId(d) === generateVariantId(v)),
    );

    updateVariants.push(...createVariants);

    setProductData((prev) => ({
      ...prev,
      variants: updateVariants,
    }));
    setProductErrors({});
  };

  const generateAllProductVariations = (options) => {
    const result = [];

    const generateProductVariations = (currentIndex, currentVariation) => {
      if (currentIndex === options.length) {
        result.push(currentVariation);
        return;
      }

      options[currentIndex].forEach((option) => {
        const newVariation = [...currentVariation, option];
        generateProductVariations(currentIndex + 1, newVariation);
      });
    };

    generateProductVariations(0, []);
    return result;
  };

  const generateSKU = () => {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';

    let sku = '';

    // Generate 4 random letters
    for (let i = 0; i < 4; i++) {
      sku += letters.charAt(Math.floor(Math.random() * letters.length));
    }

    // Generate 4 random numbers
    for (let i = 0; i < 4; i++) {
      sku += digits.charAt(Math.floor(Math.random() * digits.length));
    }

    return sku;
  };

  return (
    <div className="create_product_options">
      <div className={`create_product_title_block ${!show ? 'not_show' : ''}`}>
        <ArrowIcon onClick={() => setShow((prev) => !prev)} />

        <h2 className="create_product_title_block_title">Options</h2>
      </div>

      {show && (
        <>
          <div className="create_product_options_block">
            <Checkbox
              checked={hasOption}
              onChange={(checked) => onVariantOrBundleCheck('options', checked)}
              label="This product has option"
              className="create_product_options_checkbox"
            />
          </div>

          {hasOption && (
            <div className="create_product_options_wrp">
              {!_.isEmpty(createdOptions) && (
                _.map(createdOptions, (value, key) => (
                  <div className="create_product_options_variants" key={key}>
                    <h3 className="create_product_options_variants_title">{key}</h3>

                    <div className="create_product_options_variants_chips">
                      {value.map((text) => (
                        <Chip
                          onDelete={() => deleteOption(key, text)}
                          text={text}
                          key={text}
                        />
                      ))}
                    </div>
                  </div>
                ))
              )}

              {!!optionData.length && (
                <div className="create_product_options_inputs_single">
                  {optionData.map((opt) => (
                    <div className="create_product_options_inputs" key={opt.id}>
                      <div className="create_product_options_input_wrp">
                        <Input
                          value={opt.name}
                          onChangeText={(value) => onOptionChange('name', value, opt.id)}
                          size="small"
                          roundBorder
                          label="Option name"
                          error={errors?.[opt.id]?.name}
                        />
                      </div>

                      <div className="create_product_options_input_wrp">
                        <MultipleTagInput
                          value={opt.value}
                          onChange={(value) => onOptionChange('value', value, opt.id)}
                          size="small"
                          roundBorder
                          label={(
                            <span>
                              Option value

                              <span
                                style={{
                                  fontSize: 12,
                                  color: '#717A8A',
                                  fontWeight: 400,
                                  paddingLeft: 6,
                                }}
                              >
                                (separate values using an enter)
                              </span>
                            </span>
                          )}
                          error={errors?.[opt.id]?.value}
                        />
                      </div>

                      {!!optionData.length
                        && (
                          <DeleteIcon
                            className="create_product_options_delete_icon"
                            onClick={() => deleteLine(opt.id)}
                          />
                        )}
                    </div>
                  ))}

                  <Button
                    onClick={() => onAddOption()}
                    size="small"
                    roundBorder
                    reverseColor
                    color="#1472FF"
                    title="Done"
                    className="create_product_options_single_done_btn"
                  />
                </div>
              )}

              <Button
                onClick={addNewOptionClick}
                title="New option"
                className="create_product_options_new_button"
                roundBorder
                size="small"
                btnType="transparent"
                addBtn
                color="rgba(20, 114, 255, 1)"
              />

              {!_.isEmpty(createdOptions) && (
                <Button
                  onClick={onGenerateVariant}
                  title="Generate variants"
                  className="create_product_options_generate_btn"
                  roundBorder
                  size="small"
                  color="rgba(20, 114, 255, 1)"
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

ProductOptions.propTypes = {
  variants: PropTypes.array,
  warehouses: PropTypes.array,
  onVariantOrBundleCheck: PropTypes.func,
  hasOption: PropTypes.bool,
  setProductData: PropTypes.func,
  setProductHasOption: PropTypes.func,
  priceData: PropTypes.array,
  dimensions: PropTypes.array,
  dimensionsUnit: PropTypes.string,
  weight: PropTypes.string,
  weightUnit: PropTypes.string,
  optionData: PropTypes.array,
  setOptionData: PropTypes.func,
  setProductErrors: PropTypes.func,
  customFields: PropTypes.array,
};

ProductOptions.defaultProps = {
  variants: [],
  warehouses: [],
  onVariantOrBundleCheck: () => {},
  hasOption: false,
  setProductData: () => {},
  setProductHasOption: () => {},
  priceData: [],
  dimensions: [],
  dimensionsUnit: 'cm',
  weight: '',
  weightUnit: 'oz',
  optionData: [],
  setOptionData: () => {},
  setProductErrors: () => {},
  customFields: [],
};

export default ProductOptions;
