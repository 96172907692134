import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { format } from 'currency-formatter';
import { useParams } from 'react-router-dom';
import Modal from '../../../_common/Modal/Modal';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import Checkbox from '../../../_common/Form/Checkbox';
import Loader from '../../../_common/Loader/Loader';
import { serviceIcons } from '../../../../assets/icons/shippingServices';
import Utils from '../../../../helpers/Utils';
import { getOrderRatesByDimensionsRequest } from "../../../../store/actions/packages";
import { toast } from "react-toastify";

const weightUnits = [
  { label: 'oz', value: 'oz' },
  { label: 'lb', value: 'lb' },
  { label: 'g', value: 'g' },
  { label: 'kg', value: 'kg' },
];

function RatesModal(props) {
  const {
    isOpen, onClose, formPackage, orderPackage, onUpdatePackage, warehouse,
  } = props;
  const [form, setForm] = useState({ dimensions: [] });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);

  const dispatch = useDispatch();
  const { orderId, packageId } = useParams();

  const shippingRatesByWeight = useSelector((state) => state.packages.shippingRatesByWeight);
  const shippingRates = useSelector((state) => state.packages.shippingRates);

  useEffect(() => {
    const d = {
      dimensions: formPackage.dimensions || orderPackage.dimensions,
      weightUnit: formPackage.weightUnit || orderPackage.weightUnit,
      weight: formPackage.weight || orderPackage.weight,
      dimensionsUnit: formPackage.dimensionsUnit || orderPackage.dimensionsUnit,
    };
    setForm({ ...d });
  }, [formPackage, orderId, packageId, orderPackage]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    setForm(_.cloneDeep(form));
  }, [form]);

  const handleGetRates = useCallback(async () => {
    setLoading(true);
    const { payload } = await dispatch(getOrderRatesByDimensionsRequest({
      dimensions: form.dimensions,
      orderId,
      packageId,
      weightUnit: form.weightUnit,
      weight: form.weight,
      dimensionsUnit: form.dimensionsUnit,
    }));
    if (payload.data.status === 'error') {
      setErrors(payload.data.errors || {});
      if (_.isEmpty(payload.data.errors)) {
        toast.error(payload.data.message);
      }
    }
    setLoading(false);
  }, [form, orderId, packageId]);

  const handleConfigureLabels = useCallback(async () => {
    setConfigureLoading(true);
    await onUpdatePackage(form, true);
    setConfigureLoading(false);
    onClose();
  }, [form]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="rates_modal">
      <div className="left">
        <p className="title">Rate browser</p>
        <div className="from">
          <p>Ship from</p>
          <p className="country">
            {' '}
            {warehouse
              ? Utils.formatAddress(
                [warehouse.address,
                  warehouse.city,
                  warehouse.region.code,
                  warehouse.postalCode,
                  warehouse.country.code],
              )
              : 'US'}
          </p>
        </div>
        <Select
          roundBorder
          label="Service"
          options={shippingRates}
          getOptionLabel={(o) => `${o.provider} ${o.serviceName}`}
          getFullOption
          valuePath="rateId"
          onChange={async (val) => {
            handleChange('rateId', val.rateId);
          }}
          value={form.rateId}
        />
        <div className="row small">
          <Input
            roundBorder
            label={`Length (${form.dimensionsUnit})`}
            value={form.dimensions[0]}
            onChange={(ev) => handleChange('dimensions[0]', ev.target.value)}
            type="number"
            error={errors.dimensions?.[0]}
          />
          <Input
            roundBorder
            label={`Width (${form.dimensionsUnit})`}
            value={form.dimensions[1]}
            onChange={(ev) => handleChange('dimensions[1]', ev.target.value)}
            type="number"
            error={errors.dimensions?.[1]}

          />
          <Input
            roundBorder
            label={`Height (${form.dimensionsUnit})`}
            value={form.dimensions[2]}
            onChange={(ev) => handleChange('dimensions[2]', ev.target.value)}
            type="number"
            error={errors.dimensions?.[2]}
          />

        </div>
        <div className="row">
          <Input
            roundBorder
            label="Weight"
            value={form.weight}
            onChange={(ev) => handleChange('weight', ev.target.value)}
            type="number"
            error={errors.weight}
          />
          <Select
            roundBorder
            label="Measure units"
            value={form.weightUnit}
            options={weightUnits}
            onChange={(val) => handleChange('weightUnit', val)}
            menuPlacement="top"
          />
        </div>
        <div className="actions">
          <Button roundBorder btnType="light_blue" onClick={handleGetRates} loading={loading}>Browse rates</Button>
        </div>

      </div>
      <div className="right">
        <div>
          {_.map(_.groupBy(shippingRatesByWeight, 'provider'), (v, k) => {
            const Icon = serviceIcons[k.toLowerCase()];
            return (
              <div className="rates" key={k}>
                <p className="title">
                  {Icon ? <Icon className="icon" /> : null}
                  <span>{k}</span>
                </p>
                {v.map((p) => (
                  <div key={p.rateId}>
                    <Checkbox
                      radio
                      label={(
                        <div>
                          <p className="service">{`${p.provider} ${p.serviceName}`}</p>
                          <p className="estimate">{`${p.estimatedDays} business days`}</p>
                        </div>
                      )}
                      onChange={() => {
                        handleChange('carrier', p.provider);
                        handleChange('service', p.serviceName);
                        handleChange('rateId', p.rateId);
                      }}
                      checked={form.rateId === p.rateId}
                    />
                    <div className="rate">{format(p.amount, { code: p.currency })}</div>
                  </div>
                ))}
              </div>
            );
          })}

          {!dataLoaded ? <Loader /> : null}
          {_.isEmpty(shippingRatesByWeight) && dataLoaded ? <div className="no_data"> No rates found</div> : null}

          <div className="actions">
            <Button btnType="cancel" roundBorder onClick={onClose}>Cancel</Button>
            <Button roundBorder onClick={handleConfigureLabels} loading={configureLoading} disabled={!form.service}>
              Configure
              label
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RatesModal;
