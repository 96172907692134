import React, { memo, useId } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DraggableIcon } from '../../../assets/icons/drag_and_drop.svg';

const Switcher = memo(({
  className, label, onChange, checked, disabled, labelMarginRight, draggable,
}) => {
  const uniqId = useId();

  return (
    <div className={`toggle_wrapper ${className}`}>
      <input
        id={`toggle_id_${uniqId}`}
        type="checkbox"
        className="toggle_input"
        checked={checked}
        onChange={({ target }) => onChange(target.checked)}
        disabled={disabled}
      />

      <label className="toggle_label" htmlFor={`toggle_id_${uniqId}`}>
        {draggable && <DraggableIcon />}

        {label && <span style={{ marginRight: labelMarginRight }} className="toggle_text">{label}</span>}

        <div className="toggle_back">
          <span className="toggle_ellipse" />
        </div>
      </label>
    </div>
  );
});

Switcher.propTypes = {
  checked: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  labelMarginRight: PropTypes.number,
  disabled: PropTypes.bool,
  draggable: PropTypes.bool,
};

Switcher.defaultProps = {
  className: '',
  label: '',
  labelMarginRight: 10,
  checked: false,
  disabled: false,
  draggable: false,
};

export default Switcher;
