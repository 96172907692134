import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { currencies } from 'currency-formatter';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Switcher from '../../../_common/Form/Switcher';
import Button from '../../../_common/Form/Button';
import Modal from '../../../_common/Modal/Modal';
import { getCompaniesRequest } from '../../../../store/actions/settings';
import { createPriceListRequest } from '../../../../store/actions/priceLists';

const types = [{ value: 'sale', label: 'Sale' }, { value: 'buy', label: 'Buy' }];

function PriceListForm(props) {
  const { isOpen, onClose, onSave, type = 'sale' } = props;
  const [form, setForm] = useState({
    isDefault: false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { currencyCode, companies } = useSelector((state) => state.settings.generalList);

  useEffect(() => {
    const companyId = companies.find((p) => p.isDefault)?.id;
    if (!form.companyId) {
      handleChange('companyId', companyId);
    }
  }, [companies, form.companyId]);

  useEffect(() => {
    if (!form.currencyCode) {
      handleChange('currencyCode', currencyCode);
    }
  }, [currencyCode, form.currencyCode]);

  useEffect(() => {
    if (!form.type) {
      handleChange('type', type);
    }
  }, [type, form.type]);

  const handleGetCompanies = useCallback(async (s) => {
    const { payload } = await dispatch(getCompaniesRequest({ s }));
    return payload.companies;
  }, []);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setErrors({ ...errors });
    setForm({ ...form });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload } = await dispatch(createPriceListRequest(form));
    if (payload.data.status === 'error') {
      setErrors({ ...payload.data.errors });
    } else {
      onSave(payload.data.priceList);
      onClose();
    }
    setLoading(false);
  }, [form]);
  return (
    <Modal
      wrapperClassName="update_modal_amazon_app"
      className="general_settings_update_modal_wrapper"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} className="fields_block">
        <h3 className="fields_block_title">New price list</h3>
        <div className="update_modal_single_input">

          <Input
            value={form.name}
            onChange={(ev) => handleChange('name', ev.target.value)}
            label="Name"
            placeholder="Name"
            error={errors?.name}
            wrapperClassName="update_modal_input_wrp"
            roundBorder
            size="small"
          />
        </div>
        <div className="update_modal_single_input">
          <Select
            label="Type"
            placeholder="Select type"
            roundBorder
            options={types}
            error={errors.type}
            value={form.type}
            onChange={(val) => handleChange('type', val)}
            wrapperClassName="update_modal_input_wrp"
            size="small"
          />

        </div>
        <div className="update_modal_single_input">
          <Select
            label="Company"
            placeholder="Select company"
            roundBorder
            isAsync
            getFullOption
            loadOptions={handleGetCompanies}
            defaultOptions={companies}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            error={errors.companyId}
            value={companies.find((c) => c.id === form.companyId)}
            onChange={(val) => handleChange('companyId', val.id)}
            wrapperClassName="update_modal_input_wrp"
            size="small"
          />

        </div>
        <div className="update_modal_single_input">
          <Select
            label="Currency"
            roundBorder
            options={currencies}
            placeholder="Select"
            valuePath="code"
            labelPath="code"
            error={errors.currencyCode}
            onChange={(val) => handleChange('currencyCode', val)}
            value={form.currencyCode}
            isSearchable
            wrapperClassName="update_modal_input_wrp"
            size="small"
          />
        </div>
        <Switcher
          onChange={(checked) => handleChange('isDefault', checked)}
          label="Set as default"
          className="update_modal_swithcher"
          checked={form.isDefault}
        />

        <div className="update_modal_buttons_wrp">
          <Button
            className="update_modal_cancel_button"
            roundBorder
            size="small"
            btnType="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            className="update_modal_save_button"
            roundBorder
            size="small"
            type="submit"
            loading={loading}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default PriceListForm;
