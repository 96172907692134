import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../_common/Form/Button';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import WarningModal from '../../../_common/Modal/WarningModal';
import ProductSettings from './ProductSettings';

const barcodeOptions = [
  { value: 'upc', label: 'UPC', example: '123456789012' },
  { value: 'ean13', label: 'EAN13', example: '121212121212' },
  { value: 'ean8', label: 'EAN8', example: '12121212' },
  { value: 'std25', label: 'Standard 2 of 5' },
  { value: 'int25', label: 'Interleaved 2 of 5' },
  { value: 'code11', label: 'Code 11', example: '123456789' },
  { value: 'code39', label: 'Code 39', example: 'A123B456C' },
  { value: 'code93', label: 'Code 93', example: 'A123456B' },
  { value: 'code128', label: 'Code 128', example: '15121613' },
  { value: 'codabar', label: 'Codabar', example: 'A123456B' },
  { value: 'msi', label: 'MSI', example: '1234567890' },
  { value: 'datamatrix', label: 'Data Matrix', example: '9876543210' },
  { value: 'gtin', label: 'GTIN', example: '' },
  { value: 'isbn', label: 'ISBN', example: '' },
];

export const barCodeFields = [
  {
    title: 'Choose type',
    path: 'format',
    type: 'select',
    required: true,
    menuPortalTarget: true,
    options: barcodeOptions,
  },
  {
    title: 'Numbers',
    path: 'numbers',
    required: true,
    dynamicPlaceholderKey: 'format',
  },
];

function ProductBarcode({
  createdBarcodeData, barcode, onGenerateBarcode, barcodeModal, setBarcodeModal, error,
}) {
  const placeholdersFields = {
    upc: '123456789012',
    ean13: '121212121212',
    ean8: '12121212',
    std25: '121212121212',
    int25: '121212121212',
    code11: '123456789',
    code39: 'A123B456C',
    code93: 'A123456B',
    code128: '15121613',
    codabar: 'A123456B',
    msi: '1234567890',
    datamatrix: '9876543210',
    gtin: '121212121212',
    isbn: '121212121212',
  };

  const newFields = useMemo(() => barCodeFields.map((f) => (f.path === 'numbers'
    ? { ...f, placeholder: barcodeOptions.find((b) => barcode?.format === b?.value)?.example }
    : f)), [barcode]);

  return (
    <div className={`create_product_barcode ${error ? 'error' : ''}`}>
      <div className="create_product_barcode_top">
        <h3 className="create_product_barcode_top_title">Barcode</h3>

        <Button
          onClick={() => setBarcodeModal(true)}
          size="small"
          roundBorder
          title="Generate barcode"
          btnType="transparent"
          color="rgba(20, 114, 255, 1)"
        />
      </div>

      <div
        className="create_product_barcode_svg_block"
        style={{ height: createdBarcodeData.format ? '140px' : 0, marginTop: '10px' }}
      >
        {/* <svg id="barcode" className="barcode_svg"/> */}

        <canvas id="barcode" />

        {/* {!hasSvg && barcode.numbers && <p className="barcode_text">{barcode.numbers}</p>} */}
      </div>

      {!!error && <p className="error_text">{`${error} !`}</p>}

      <UpdateModal
        isOpen={barcodeModal}
        onClose={() => setBarcodeModal(false)}
        fields={newFields}
        onSave={onGenerateBarcode}
        singleData={barcode}
        dynamicPlaceholder={placeholdersFields}
      />
    </div>
  );
}

ProductSettings.propTypes = {
  createdBarcodeData: PropTypes.object,
  barcode: PropTypes.object,
  onGenerateBarcode: PropTypes.func,
  setBarcodeModal: PropTypes.func,
  barcodeModal: PropTypes.bool,
  error: PropTypes.string,
};

WarningModal.defaultProps = {
  createdBarcodeData: {},
  barcode: {},
  onGenerateBarcode: () => {},
  setBarcodeModal: () => {},
  barcodeModal: false,
  error: '',
};

export default ProductBarcode;
