import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import noImage from '../../../../assets/icons/default.svg';
import { shopIcons } from '../../../../assets/shops';
import Pagination from '../../../_common/Pagination/Pagination';
import StatusField from '../../../_common/StatusField/StatusField';
import Formatter from '../../../../helpers/Formatter';
import Button from '../../../_common/Form/Button';
import { ReactComponent as ImportIcon } from '../../../../assets/icons/import.svg';

const columns = [
  {
    key: 'number', isEnabled: true,
  },
  {
    key: 'shopType', isEnabled: true,
  },
  {
    key: 'customer', isEnabled: true,
  },
  {
    key: 'shippingAddress', isEnabled: true,
  },
  {
    key: 'billingAddress', isEnabled: true,
  },
  {
    key: 'status', isEnabled: true,
  },
  {
    key: 'paymentStatus', isEnabled: true,
  },
  {
    key: 'createdAt', isEnabled: true,
  },
  {
    key: 'shippedAt', isEnabled: true,
  },
  {
    key: 'totalPrice', isEnabled: true,
  },
];

const defaultAttributes = {
  number: 'Order ID',
  shopType: 'Shop',
  customer: 'Customer',
  shippingAddress: 'Shipping address',
  billingAddress: 'Billing address',
  status: 'Status',
  paymentStatus: 'Payment status',
  createdAt: 'Created at',
  shippedAt: 'Shipped at',
  totalPrice: 'Total',
};

const Orders = ({ openExpiredModal }) => {
  const { integrationId } = useParams();
  const navigate = useNavigate();

  const myApp = useSelector((state) => state.app.myApp);

  const firstLoad = useRef(true);

  const [loading, setLoading] = React.useState(false);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({ totalPage: 1, currentPage: 1 });
  const { totalPage, currentPage } = pagination;

  const [importLoading, setImportLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await Api.getOrders({
        limit: 20,
        page: currentPage,
        shopId: integrationId,
        includes: 'customer,packages',
      });

      setOrders(data.orders);
      setPagination((prev) => ({ ...prev, totalPage: data.totalPages }));
      firstLoad.current = false;
      setLoading(false);
    })();
  }, [currentPage]);

  const importOrders = async () => {
    setImportLoading(true);

    if (myApp.isExpired) {
      openExpiredModal();
    } else {
      try {
        const { data: { message } } = await Api.integrationImport({
          shop: myApp.type,
          type: 'orders',
          integrationId,
        });

        toast.success(message);
      } catch (e) {
        console.warn(e);
        toast.error(e.response.data?.message || 'Something went wrong');
      }
    }

    setImportLoading(false);
  };

  return (
    <div className="my_app_logs">
      {myApp.type !== 'b2b' && (
        <Button
          className="my_app_import_btn"
          size="small"
          btnType="cancel"
          roundBorder
          reverseColor
          iconLeft={<ImportIcon />}
          loading={importLoading}
          onClick={importOrders}
        >
          Import orders
        </Button>
      )}

      {firstLoad.current
        ? (
          <Loader />
        )
        : (
          <div className="my_app_logs_table_wrapper">
            <DynamicTable
              loading={loading}
              data={orders}
              keyExtractor={(item) => item.id}
              columns={columns}
              renderColumnHeader={(col) => (defaultAttributes[col.key] || col.key)}
              renderColumns={{
                number: ({ item }) => (
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => navigate(`/orders/sales-order/preview/${item.id}`)}
                    className="my_app_logs_table_order_number"
                  >
                    {item.number || '-'}
                  </div>
                ),

                shopType: ({ item }) => {
                  const Icon = shopIcons[item.shopType] || shopIcons.eswap;

                  return (
                    Icon ? (
                      <div className="orders_shop_icon_wrapper">
                        <Icon />
                      </div>
                    )
                      : '-'
                  );
                },

                customer: ({ item }) => <p>{`${item.customer?.firstName} ${item.customer?.lastName}`}</p>,
                shippingAddress: ({ item }) => (item.shippingAddress
                  ? (
                    <p>
                      {`${item.shippingAddress.address1} ${item.shippingAddress.city} ${item.shippingAddress.address2}`}
                    </p>
                  )
                  : '-'),
                billingAddress: ({ item }) => (item.billingAddress
                  ? (
                    <p>
                      {`${item.billingAddress.address1} ${item.billingAddress.city} ${item.billingAddress.address2}`}
                    </p>
                  )
                  : '-'),
                shippedAt: ({ item }) => (item.packages[0]?.shippedAt
                  ? moment(item.packages[0].shippedAt).format('DD MMM YYYY')
                  : '-'),
                status: ({ item }) => <StatusField status={item.status} />,
                paymentStatus: ({ item }) => <StatusField status={item.paymentStatus} />,
                totalPrice: ({ item }) => Formatter.price(item.totalPrice, item.currencyCode),
                createdAt: ({ item }) => moment(item.createdAt).format('DD MMM YYYY'),
              }}
            />

            {+totalPage > 1 && (
              <div className="my_app_logs_pagination">
                <Pagination
                  onChangePage={(page) => setPagination((prev) => ({ ...prev, currentPage: page }))}
                  page={currentPage}
                  totalPages={+totalPage}
                />
              </div>
            )}
          </div>

        )}
    </div>
  );
};

export default Orders;
