import React, {
  useCallback, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import Api from '../../../../Api';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Tabs from '../../../_common/Tabs/Tabs';
import noImage from '../../../../assets/icons/default.svg';
import Utils from '../../../../helpers/Utils';
import Loader from '../../../_common/Loader/Loader';
import Pagination from '../../../_common/Pagination/Pagination';
import LinkedTable from './LinkedTable';
import { ReactComponent as NotFoundIcon } from '../../../../assets/icons/notFound/import_not_found.svg';
import useWindowSize from '../../../../helpers/hooks/useWindowSize';

const types = {
  linked: {
    includes: 'relations',
    path: 'relations',
  },
  unlinked: {
    includes: 'relationSuggestions',
    path: 'relationSuggestions',
  },
};

const defaultShopId = '0';

const Listings = () => {
  const { type } = useParams();

  const [filters, setFilters] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: withDefault(StringParam, defaultShopId),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const { isMobile: isTablet } = useWindowSize(1030);

  const firstLoad = useRef(true);
  const timeout = useRef();

  const [products, setProducts] = useState({
    list: [],
    totalPages: 1,
  });

  const { list, totalPages } = products;

  const [shops, setShops] = useState([]);
  const [activeProduct, setActiveProduct] = useState({});

  const [loading, loadingToggle] = useState(true);

  useLayoutEffect(() => {
    loadingToggle(true);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      if (firstLoad.current) {
        const { data: { integrations } } = await Api.getIntegrations({ category: 'shop' });

        setShops([{ id: '0', label: 'eSwap' }, ...integrations.map((s) => ({ id: s.id, label: s.name }))]);
      }

      const { data } = await Api.getProducts({
        // limit: 20,
        ...filters,
        includes: types[type].includes,
        isLinked: type === 'linked',
      });

      setProducts({
        list: data.products,
        totalPages: data.totalPages,
      });

      setActiveProduct({
        id: data.products?.[0]?.id,
        products: data.products?.[0]?.[types[type].path],
      });

      loadingToggle(false);
      firstLoad.current = false;
    }, 500);
  }, [filters, type]);

  const changeFilters = useCallback((path, value) => {
    setFilters((prev) => {
      const resetPaths = ['shopId', 's'];
      const newFilters = { ...prev, [path]: value };

      if (resetPaths.includes(path)) {
        if (path === 'shopId') {
          newFilters.s = '';
        }

        newFilters.page = 1;
      }

      return newFilters;
    });
  }, []);

  const selectProduct = useCallback((product) => {
    setActiveProduct({
      id: product.id,
      products: product[types[type].path],
    });
  }, [type]);

  const updateProduct = useCallback(async (product, unlink) => {
    const currentProduct = list.find((l) => l.id === activeProduct.id);
    currentProduct.relations = currentProduct.relations.map((r) => ({ id: r.id }));

    await Api.updateProductRelations({
      productId: currentProduct.id,
      relationId: currentProduct.relationId || '',
      relations: unlink
        ? currentProduct.relations.filter((r) => r.id !== product.id)
        : [...currentProduct.relations, { id: product.id }],
    });

    setProducts((prev) => ({
      ...prev,
      list: prev.list.map((l) => {
        let relations = [...l.relations, product];

        if (unlink) {
          relations = l.relations.filter((r) => r.id !== product.id);
        }

        return l.id === activeProduct.id ? { ...l, relations } : l;
      }),
    }));

    setActiveProduct((prev) => {
      let productsLists = [...prev.products, product];

      if (unlink) {
        productsLists = prev.products.filter((p) => p.id !== product.id);
      }

      return { ...prev, products: productsLists };
    });

    toast.success(`Product successfully ${unlink ? 'unlinked' : 'linked'}`);
  }, [activeProduct, list, type]);

  const overviewTabs = useMemo(() => [
    {
      name: 'Linked',
      href: '/inventory/listings/linked',
      query: filters.shopId !== defaultShopId && `?shopId=${filters.shopId}`,
    },
    {
      name: 'Unlinked',
      href: '/inventory/listings/unlinked',
      query: filters.shopId !== defaultShopId && `?shopId=${filters.shopId}`,
    },
  ], [filters.shopId]);

  return (
    <div className="listings_page">
      {firstLoad.current
        ? <Loader />

        : (
          <>
            <div className="listings_page_left_block">
              <div className="listings_page_left_block_title">
                <h1>Listings</h1>

                <Select
                  options={shops}
                  value={filters.shopId}
                  valuePath="id"
                  onChange={(option) => changeFilters('shopId', option)}
                  roundBorder
                  size="small"
                />
              </div>

              <div className="listings_page_left_block_products">
                <Tabs tabs={overviewTabs} />

                <Input
                  placeholder="Search by item or SKU"
                  roundBorder
                  size="small"
                  search
                  value={filters.s}
                  onChangeText={(value) => changeFilters('s', value)}
                  wrapperClassName="product_search_field"
                />
                <div className="listings_page_left_block_products_list_wrapper">
                  {loading && <Loader />}

                  <div className="listings_page_left_block_products_list">
                    {list.length
                      ? list.map((l) => (
                        <div
                          key={l.id}
                          className={classNames(
                            'listings_page_left_block_product',
                            { active: activeProduct.id === l.id },
                          )}
                          onClick={() => selectProduct(l)}
                        >
                          <img src={l.images?.[0]?.src || noImage} alt="img" />

                          <div
                            className="listings_page_left_block_product_info"
                          >
                            <p className="listings_page_left_block_product_title">{l.title}</p>

                            <div className="listings_page_left_block_product_shop_logo_wrapper">
                              <div className="listings_page_left_block_product_shop_logo">
                                {Utils.getAppIcon(l, true)}
                              </div>

                              <p className="listings_page_left_block_product_sku">{`SKU: ${l.sku || '-'}`}</p>
                            </div>
                          </div>
                        </div>
                      ))

                      : (
                        <div className="data_not_found">
                          <NotFoundIcon />

                          <h1>Products not found</h1>
                        </div>
                      )}

                    <Pagination
                      onChangePage={(p) => changeFilters('page', p)}
                      totalPages={totalPages}
                      showNumber={isTablet ? 1 : 3}
                    />
                  </div>
                </div>
              </div>
            </div>

            <LinkedTable
              data={activeProduct}
              shopId={filters.shopId}
              updateProduct={updateProduct}
              productsFilterLoading={loading}
            />
          </>
        )}
    </div>
  );
};

Listings.propTypes = {};

export default Listings;
