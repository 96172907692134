import React, { memo, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow.svg';
import useWindowSize from '../../../helpers/hooks/useWindowSize';

const Pagination = memo(({
  currentPage, totalPages, showNumber = 6, onChangePage, ...rest
}) => {
  const { isMobile } = useWindowSize();
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));

  const changePage = useCallback((p) => {
    if (onChangePage) {
      onChangePage(p);
    } else {
      setPage(p);
    }
  }, [onChangePage]);

  if (totalPages <= 1) {
    return null;
  }

  return (
    totalPages > 1 && (
      <ReactPaginate
        previousLabel={<Arrow className="pagination_arrow_icon_left" />}
        nextLabel={<Arrow className="pagination_arrow_icon_right" />}
        pageCount={totalPages}
        forcePage={+(currentPage ?? page) - 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={isMobile ? 3 : showNumber}
        onPageChange={(e) => changePage(e.selected + 1)}
        containerClassName="pagination justifyContentCenter"
        pageClassName="page_item"
        pageLinkClassName="page_link"
        previousClassName="page_item"
        previousLinkClassName="page_link"
        nextClassName="page_item"
        nextLinkClassName="page_link"
        breakClassName="page_item"
        breakLinkClassName="page_link"
        activeClassName="active"
        disabledClassName="disabled"
        {...rest}
      />
    )
  );
});

export default Pagination;
