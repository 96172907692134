import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_with_circle.svg';
import noImage from '../../../../assets/icons/default.svg';
import Input from '../../../_common/Form/Input';
import Utils from '../../../../helpers/Utils';

const ProductTable = ({
  products, sourceWarehouseId, destinationWarehouseId, setRequestData,
  actions, disable, columns, defaultAttributes, loading,
}) => {
  const navigate = useNavigate();
  const [destinationStockId, setDestinationStockId] = useState(destinationWarehouseId);

  useEffect(() => {
    setDestinationStockId(destinationWarehouseId);
  }, [destinationWarehouseId]);

  const changeQuantity = (item, value) => {
    const maxCount = +stockCount(sourceWarehouseId, item) + (+item.currentQtyCover || 0);

    if (!actions) {
      setRequestData((prev) => prev.map((product) => (product.productId === item.productId
        ? ({ ...product, currentQtyCover: value })
        : product)));
    } else {
      setRequestData((prev) => ({
        ...prev,
        products: prev.products.map((product) => (product.productId === item.productId
          ? ({ ...product, quantity: +value > +maxCount ? maxCount : value })
          : product)),
      }));
    }
  };

  const deleteProduct = (productId) => {
    setRequestData((prev) => ({
      ...prev,
      products: prev.products.filter((product) => product.productId !== productId),
    }));
  };

  const stockCount = (warehouseId, item) => item.stocks.filter((i) => i.warehouseId === warehouseId)?.[0]?.available || 0;

  return (
    <div className="new_stock_transfer_table_wrapper">
      <DynamicTable
        data={products}
        keyExtractor={(item) => item.productId || item.id}
        columns={columns}
        loading={loading}
        renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
        renderColumns={{
          item: ({ item }) => (
            <div className="product_item_wrapper">
              <div className="product_item_img">
                <img
                  src={item.images[0]?.src || item.images[0]?.medium || noImage}
                  alt="img"
                />
              </div>

              <div className="product_item_title">
                <p>{item.title}</p>
              </div>
            </div>
          ),

          sourceWarehouse: ({ item }) => stockCount(sourceWarehouseId, item),

          destinationWarehouse: ({ item }) => stockCount(destinationStockId, item),

          number: ({ item }) => (
            <div
              role="button"
              tabIndex="0"
              onClick={() => navigate(`/stocks/stock-transfer/received-history/${item.id}`)}
              className="new_stock_transfer_table_number"
            >
              {item.number}
            </div>
          ),

          receivedQty: ({ item }) => item.receivedQty,

          currentQty: ({ item }) => item.currentQty,

          quantity: ({ item }) => (
            setRequestData ? (
              <Input
                value={!actions ? item.currentQtyCover : item.quantity}
                onChange={(e) => changeQuantity(item, e.target.value)}
                onBeforeInput={Utils.typingNumber}
                disabled={disable
                  || (actions && item.quantity === 0 && +stockCount(sourceWarehouseId, item) === 0)
                  || (!actions && item.currentQtyCover === 0)}
              />
            ) : item.quantity
          ),
          sourceStock: ({ item }) => item.sourceStock,
          destStock: ({ item }) => item.destStock,
          receiveDate: ({ item }) => moment(item.receiveDate).format('MM/DD/YYYY'),

          createdAt: ({ item }) => moment(item.createdAt).format('MM/DD/YYYY'),

          actions: ({ item }) => (actions ? (
            <div className="products_actions_wrapper">
              <div
                role="button"
                tabIndex="0"
                onClick={() => deleteProduct(item.productId)}
              >
                <DeleteIcon />
              </div>
            </div>
          ) : null),
        }}
      />
    </div>

  );
};

ProductTable.propTypes = {
  products: PropTypes.array.isRequired,
  sourceWarehouseId: PropTypes.string,
  destinationWarehouseId: PropTypes.string,
  columns: PropTypes.array.isRequired,
  defaultAttributes: PropTypes.object.isRequired,
  setRequestData: PropTypes.func,
  actions: PropTypes.bool,
  disable: PropTypes.bool,
  loading: PropTypes.bool,
};

ProductTable.defaultProps = {
  actions: false,
  disable: false,
  loading: false,
  destinationWarehouseId: '',
  sourceWarehouseId: '',
  setRequestData: null,
};

export default ProductTable;
