import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { format } from 'currency-formatter';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import Modal from '../../../_common/Modal/Modal';
import Select from '../../../_common/Form/Select';
import noImg from '../../../../assets/icons/no_img.svg';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import { getCompaniesRequest, getSettingsRequest } from '../../../../store/actions/settings';
import {
  calculateOrderPriceRequest,
  createOrderRequest,
  getSingleOrderRequest,
} from '../../../../store/actions/orders';
import { getCustomersRequest, getSingleCustomerRequest, removeCustomerData } from '../../../../store/actions/customers';
import Utils from '../../../../helpers/Utils';
import { getPriceListsRequest } from '../../../../store/actions/priceLists';

function BackorderFormModal(props) {
  const { isOpen, onClose, products } = props;

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const [total, setTotal] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const dispatch = useDispatch();
  const { orderId } = useParams();

  const { currencyCode } = useSelector((state) => state.settings.generalList);
  const customer = useSelector((state) => state.customers.customer);
  const customers = useSelector((state) => state.customers.customers);
  const order = useSelector((state) => state.orders.order);

  useEffect(() => {
    const orderProducts = products.map((p) => ({
      productId: p.id,
      price: p.prices?.[0]?.price || 0,
      discountPercentage: 0,
      qty: 1,
      taxPercentage: 0,
      warehouseId: order.orderProducts.find((o) => o.productId === p.id)?.warehouseId,
    }));
    handleChange('orderProducts', orderProducts);
  }, [products, order]);

  useEffect(() => {
    (async () => {
      const d = {
        orderProducts: form.orderProducts.map((p) => ({
          price: p.price || 0,
          discountPercentage: p.discountPercentage || 0,
          qty: p.qty || 1,
          taxPercentage: p.taxPercentage || 0,
        })),
        totalShippingPrice: 0,
        taxType: 'inclusive',
      };
      const { payload } = await dispatch(calculateOrderPriceRequest(d));
      if (payload) {
        setTotal(payload.data);
      }
    })();
  }, [form.orderProducts]);

  useEffect(() => {
    (async () => {
      setLoadingData(true);
      const [
        { payload: { currencyCode: code, paymentTerms } },
        { payload: { data: { priceLists } } },
        { payload: { companies } },

      ] = await Promise.all([
        dispatch(getSettingsRequest(['currencyCode', 'paymentTerms'])),
        dispatch(getPriceListsRequest()),
        dispatch(getCompaniesRequest()),
      ]);

      const data = {
        currencyCode: code,
        paymentTerm: { id: paymentTerms?.find((p) => p.isDefault)?.id },
        priceListId: priceLists.find((p) => p.isDefault)?.id,
        companyId: companies.find((p) => p.isDefault)?.id,
      };

      handleChange(null, data);

      setLoadingData(false);
    })();
    return () => dispatch(removeCustomerData());
  }, []);

  const handleChange = useCallback((key, value) => {
    if (key) {
      _.set(form, key, value);
      _.unset(errors, key);
    } else {
      Object.assign(form, value);
      Object.keys(value).forEach((o) => {
        delete errors[o];
      });
    }
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleGetCustomers = useCallback(async (s) => {
    const { payload } = await dispatch(getCustomersRequest({
      s,
      isVendor: true,
    }));
    return payload.data.customers;
  }, []);

  const handleGetLabel = useCallback((o) => {
    if (o.firstName) {
      return `${o.firstName} ${o.lastName}`;
    }
    if (o.email) {
      return o.email;
    }
    if (o.phone) {
      return o.phone;
    }
    return o.id;
  }, []);

  const handleChangeCustomer = useCallback(async (val) => {
    const { payload } = await dispatch(getSingleCustomerRequest(val.id));
    handleChange('customerId', val.id);
    handleChange('shippingAddressId', payload.data.customer.shippingAddresses?.[0]?.id);
  }, []);

  const handleSave = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);

    const sAddress = customer.shippingAddresses?.find((c) => c.id === form.shippingAddressId) || {};

    const d = {
      customerId: form.customerId,
      shippingAddressId: form.shippingAddressId,
      companyId: form.companyId,
      shippingAddress: {
        address1: sAddress.address1,
        address2: sAddress.address2,
        city: sAddress.city,
        countryCode: sAddress.countryCode,
        firstName: sAddress.firstName,
        lastName: sAddress.lastName,
        id: sAddress.id,
        phone: sAddress.phone,
        postalCode: sAddress.postalCode,
        region: sAddress.region,
        title: sAddress.title,
      },
      billingAddress: {
        address1: customer.billingAddress?.address1,
        address2: customer.billingAddress?.address2,
        city: customer.billingAddress?.city,
        countryCode: customer.billingAddress?.countryCode,
        firstName: customer.billingAddress?.firstName,
        lastName: customer.billingAddress?.lastName,
        id: customer.billingAddress?.id,
        phone: customer.billingAddress?.phone,
        postalCode: customer.billingAddress?.postalCode,
        region: customer.billingAddress?.region,
        title: customer.billingAddress?.title,
      },
      status: 'pending',
      type: 'purchase',
      taxType: 'inclusive',
      totalShippingPrice: 0,
      landedCosts: [],
      priceListId: form.priceListId,
      currencyCode: form.currencyCode,
      paymentTerm: { id: form.paymentTerm.id },
      sourceCreatedAt: moment().format('YYYY-MM-DD'),
      associationId: orderId,
      orderProducts: form.orderProducts.map((o) => ({
        productId: o.productId,
        id: o.id,
        price: o.price || 0,
        discountPercentage: o.discountPercentage || 0,
        qty: o.qty || 1,
        taxPercentage: o.taxPercentage || 0,
        warehouseId: o.warehouseId,
      })),
    };
    const { payload: { data } } = await dispatch(createOrderRequest(d));
    if (data.status === 'error' && !_.isEmpty(data.errors)) {
      setErrors(data.errors);
    } else if (data.status === 'error') {
      toast.error(data.message);
    } else {
      onClose();
      toast.success('Backorder successfully created!');
      await dispatch(getSingleOrderRequest(orderId));
    }
    setLoading(false);
  }, [form, orderId, customer]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="backorder_form_modal"
      overflowY={false}
    >
      <p className="title">Create purchase order</p>
      <form onSubmit={handleSave}>
        <div className="vendor_select">
          <Select
            label="Vendor"
            placeholder="Select vendor"
            roundBorder
            isAsync
            loadOptions={handleGetCustomers}
            defaultOptions
            valuePath="id"
            getFullOption
            getOptionLabel={handleGetLabel}
            onChange={handleChangeCustomer}
            value={customers.find((c) => c.id === form.customerId)}
            error={errors.customerId || errors.shippingAddressId || errors.billingAddressId}
          />
          <div className="addresses">
            {form.customerId && customer.shippingAddresses?.[0] ? (
              <div className="address">
                <p className="address_name">Shipping address</p>
                <p className="address_title">{customer.shippingAddresses[0].title}</p>
                <p
                  className="text"
                >
                  {`${customer.shippingAddresses[0].firstName || ''} ${customer.shippingAddresses[0].lastName || ''}`}
                </p>
                <p className="text">
                  {Utils.formatAddress([customer.shippingAddresses[0].address1,
                    customer.shippingAddresses[0].city,
                    customer.shippingAddresses[0].region,
                    customer.shippingAddresses[0].postalCode,
                    customer.shippingAddresses[0].countryCode])}
                </p>
              </div>
            ) : null}
            {form.customerId && customer.billingAddress ? (
              <div className="address">
                <p className="address_name">Billing address</p>
                <p className="address_title">{customer.billingAddress.title}</p>
                <p
                  className="text"
                >
                  {`${customer.billingAddress.firstName || ''} ${customer.billingAddress.lastName || ''}`}
                </p>
                <p className="text">
                  {Utils.formatAddress([customer.billingAddress.address1,
                    customer.billingAddress.city,
                    customer.billingAddress.region,
                    customer.billingAddress.postalCode,
                    customer.billingAddress.countryCode])}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <div className="products_list table_wrapper">
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Product</th>
                <th className="table_thead_tr_th">Warehouse</th>
                <th className="table_thead_tr_th">Available</th>
                <th className="table_thead_tr_th">Incoming</th>
                <th className="table_thead_tr_th">Cost price</th>
                <th className="table_thead_tr_th">Qty</th>
                <th className="table_thead_tr_th">Subtotal</th>
              </tr>
            </thead>
            <tbody className="table_tbody">
              {products?.map((p, i) => (
                <tr
                  className="table_tbody_tr"
                  key={p.id}
                >

                  <td className="table_tbody_tr_td">
                    <div className="info" style={{ alignItems: 'center' }}>
                      {p.type !== 'custom' ? (
                        <img
                          style={{ height: 30, width: 30 }}
                          src={p?.images?.[0]?.src
                          || p?.images?.[0]?.medium
                          || noImg}
                          alt=""
                        />
                      ) : null}
                      {p.type !== 'custom'
                        ? (
                          <Link
                            to={p?.type === 'variant'
                              ? `/products/${p.parentId}?productVariantId=${p.id}`
                              : `/products/${p.id}`}
                            target="_blank"
                          >
                            {p.title}
                          </Link>
                        )
                        : <span>{p.title}</span>}
                    </div>
                  </td>
                  <td className="table_tbody_tr_td select">
                    <Select
                      menuPortalTarget={document.body}
                      options={p.warehouses}
                      labelPath="title"
                      valuePath="id"
                      value={_.get(form.orderProducts, `[${i}].warehouseId`)}
                      onChange={(v) => {
                        _.set(form.orderProducts, `[${i}].warehouseId`, v);
                        handleChange('orderProducts', [...form.orderProducts]);
                      }}
                      error={!!_.get(errors, `orderProducts[${i}].warehouseId`)}
                    />
                  </td>
                  <td className="table_tbody_tr_td ">
                    {p.stocks.find((s) => s.warehouseId === _.get(form.orderProducts, `[${i}].warehouseId`))?.available}
                  </td>
                  <td className="table_tbody_tr_td">
                    {p.stocks.find((s) => s.warehouseId === _.get(form.orderProducts, `[${i}].warehouseId`))?.incoming}
                  </td>
                  <td className="table_tbody_tr_td">
                    <Input
                      onChange={(ev) => {
                        _.set(form.orderProducts, `[${i}].price`, ev.target.value);
                        handleChange('orderProducts', [...form.orderProducts]);
                      }}
                      value={_.get(form.orderProducts, `[${i}].price`, '')}
                      type="number"
                      symbol="$"
                    />
                  </td>
                  <td
                    className="table_tbody_tr_td "
                  >
                    <Input
                      onChange={(ev) => {
                        _.set(form.orderProducts, `[${i}].qty`, ev.target.value);
                        handleChange('orderProducts', [...form.orderProducts]);
                      }}
                      value={_.get(form.orderProducts, `[${i}].qty`, '')}
                      type="number"
                    />
                  </td>

                  <td className="table_tbody_tr_td ">
                    {format(_.get(total.orderProducts, `[${i}].totalPrice`), { code: currencyCode })}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="information">
          <p>These items have insufficient stock to fulfil this order.</p>
          <p>
            Quantities have been automatically calculated for what should be backordered, these are editable if
            required.
          </p>
        </div>
        <div className="actions">
          <Button btnType="cancel" roundBorder onClick={onClose}>Cancel</Button>
          <Button roundBorder type="submit" loading={loading}>Create PO</Button>
        </div>
      </form>
    </Modal>
  );
}

export default BackorderFormModal;
