import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getAppsRequest = createAsyncThunk(
  'notifications/get-apps',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getIntegrations({ category: 'shop' });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const socketImport = createAction('notifications/socket/app-import');

export const getNotificationsRequest = createAsyncThunk(
  'notifications/get-notifications',
  async (payload, thunkAPI) => {
    let level = 'info,warn,debug';

    if (payload === 'Errors') {
      level = 'error';
    }

    try {
      const { data } = await Api.getAllLogs({ level });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getSetupRequest = createAsyncThunk(
  'notifications/get-setup',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSetupInfo();
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
