import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NumberParam, StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import Loader from '../../../_common/Loader/Loader';
import Api from '../../../../Api';
import Table from '../../../_common/Tables/Table';
import Pagination from '../../../_common/Pagination/Pagination';

const tableHeader = [
  {
    path: 'name',
    title: 'Plan name',
    isSelectable: true,
  },
  {
    path: 'publicName',
    title: 'Plan public name',
  },
  {
    path: 'createdAt',
    title: 'Created at',
    isSelectable: true,
  },
  {
    path: 'status',
    title: 'Status',
  },
];

function Plans() {
  const navigate = useNavigate();
  const firstLoad = useRef(true);

  const [plans, setPlans] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const [page, setPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 1),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  useEffect(() => {
    (async () => {
      setTableLoading(true);

      const { data } = await Api.getPlanListForAdmin({
        paginated: true,
        page,
        sort: sorting.sort,
        sortBy: sorting.sortBy,
      });

      setPlans(data.plans);
      setTotalPages(data.totalPages);

      setTableLoading(false);
      firstLoad.current = false;
    })();
  }, [page, sorting.sort, sorting.sortBy]);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, [sorting]);

  const onDelete = useCallback(async (id) => {
    setDeleteLoading(true);

    try {
      await Api.deletePlan(id);

      setPlans((prev) => prev.filter((p) => p.id !== id));
    } catch (e) {
      toast.error(e.response.data.message);
    }

    setDeleteLoading(false);
  }, []);

  return (
    <Wrapper
      title="Plans"
      onBtnClick={() => navigate('/plans/add')}
      buttonTitle="New plan"
      addBtn
    >
      {firstLoad.current ? <Loader /> : (
        <div className="plans_wrapper">
          <div className="plans_table_wrapper">
            <Table
              data={plans}
              header={tableHeader}
              sortBy={sorting.sortBy}
              sort={sorting.sort}
              onSortBy={onSortBy}
              onDeleteClick={onDelete}
              deleteModalText="plan"
              onEditClick={(id) => navigate(`/plans/${id}`)}
              loading={tableLoading}
              deleteLoading={deleteLoading}
              disableWhenStatusFalse
            />
          </div>

          <Pagination totalPages={totalPages} onChangePage={() => {}} page={page} />
        </div>
      )}
    </Wrapper>
  );
}

export default Plans;

// const [s, setSearch] = useQueryParam(
//   's',
//   withDefault(StringParam, ''),
//   { updateType: 'replaceIn', removeDefaultsFromUrl: true },
// );
