import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from '../../../_common/Modal/Modal';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';

const SetCategoriesModal = memo(({
  item, categoriesList, changeProduct, loadMoreOptions, setOpenCategoriesModal,
}) => {
  const [selectMenuWrapper, setSelectMenuWrapper] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(item)) {
      setSelectMenuWrapper(document.querySelector('#categories_modal'));
    }
  }, [item]);

  const onChangeCategories = (val) => {
    setOpenCategoriesModal((prev) => ({ ...prev, categories: val }));
  };

  const onClose = () => {
    setOpenCategoriesModal({});
  };

  const onSave = () => {
    changeProduct(item.categories, item.id, 'categories');

    onClose();
  };

  return (
    <Modal
      className="warning_title_modal"
      isOpen={!_.isEmpty(item)}
      onClose={onClose}
      id="categories_modal"
    >

      <h2>Select categories</h2>

      <Select
        isAsync
        wrapperClassName="warning_title_modal_select"
        value={item.categories}
        onChange={(val) => onChangeCategories(val)}
        defaultOptions={categoriesList}
        loadOptions={(value) => loadMoreOptions(value, 'category')}
        getFullOption
        valuePath="id"
        labelPath="name"
        isMulti
        menuPosition="fixed"
        menuPortalTarget={selectMenuWrapper}
        getOptionLabel={(option) => {
          if (option?.parents?.length) {
            const parents = option.parents.map((p) => p.name).reverse().join(' / ');

            return `${option.name} / ${parents}`;
          }

          return option.name;
        }}
      />

      <div className="bulk_edit_modal_buttons_wrapper">
        <Button
          className="bulk_edit_modal_button"
          size="small"
          title="Cancel"
          onClick={onClose}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="bulk_edit_modal_button"
          size="small"
          title="Save"
          onClick={onSave}
          roundBorder
          color="#1472FF"
        />
      </div>

    </Modal>
  );
});

SetCategoriesModal.propTypes = {
  item: PropTypes.object.isRequired,
  categoriesList: PropTypes.array.isRequired,
  changeProduct: PropTypes.func.isRequired,
  loadMoreOptions: PropTypes.func.isRequired,
  setOpenCategoriesModal: PropTypes.func.isRequired,
};

export default SetCategoriesModal;
