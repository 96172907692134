import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as FTPIcon } from '../../../../assets/icons/apps/ftp.svg';
import FtpImg1 from '../../../../assets/images/apps/ftp/ftp_1.png';
import FtpImg2 from '../../../../assets/images/apps/ftp/ftp_2.png';
import FtpImg3 from '../../../../assets/images/apps/ftp/ftp_3.png';
import FtpImg4 from '../../../../assets/images/apps/ftp/ftp_4.png';
import FtpImg5 from '../../../../assets/images/apps/ftp/ftp_5.png';
import FtpImg6 from '../../../../assets/images/apps/ftp/ftp_6.png';
import FtpImg7 from '../../../../assets/images/apps/ftp/ftp_7.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { appInstallFields } from '../../../../helpers/staticData';

function FTPInstall() {
  const { app } = useParams();
  const navigate = useNavigate();
  const [appName, appType] = app.split('-');

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const { data: { integration: { id } } } = await Api.createIntegration(
        appName,
        {
          integrationName: 'ftp',
          host: data.host,
          port: data.port,
          user: data.user,
          password: data.password,
          filePath: data.filePath,
          type: appType,
        },
      );

      navigate(`/apps/integration/${id}`);
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="FTP"
      shopIcon={<FTPIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and "
        + 'process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p className="title">
        Installation Instructions for Connecting FTP to eSwap:
      </p>

      <ul>
        <li>Navigate to the Apps section in eSwap, particularly under &quot;FTP,&quot; and select &quot;FTP.&quot;</li>
        <li>Click on &quot;Install app.&quot;</li>
      </ul>

      <p>
        <img
          src={FtpImg1}
          alt="info image"
        />
      </p>

      <p>
        Click on &quot;Install app.&quot;
      </p>

      <p>
        <img
          src={FtpImg2}
          alt="info image"
        />
      </p>

      <ul>
        <li>Enter your FTP host address.</li>
        <li>Enter the port number associated with your FTP server.</li>
        <li>Enter your FTP username.</li>
        <li>Provide the file path where your data is located on the FTP server.</li>
        <li>Click &quot;Install app&quot; to proceed.</li>
      </ul>

      <p>
        <img
          src={FtpImg3}
          alt="info image"
        />
      </p>

      <ul>
        <li>Upon installation, follow these configuration steps:</li>
        <li>Configure FTP order settings according to your requirements.</li>
      </ul>

      <p>
        <img
          src={FtpImg4}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Configure FTP order statuses to align with existing lines or create new ones if
          necessary.
        </li>
        <li>Decide whether to import data immediately or at a later time.</li>
      </ul>

      <p>
        <img
          src={FtpImg5}
          alt="info image"
        />
      </p>

      <p>The import process will begin based on your selections.</p>
      <p>
        <img
          src={FtpImg6}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Once the configuration steps are completed, click &quot;Done Installation&quot; to finalize the
          setup.
        </li>
        <li>The FTP app will now be accessible from your apps page within eSwap.</li>
      </ul>

      <p>
        <img
          src={FtpImg7}
          alt="info image"
        />
      </p>

      <p>
        By following these steps, you will successfully connect your FTP account to eSwap, enabling seamless
        data synchronization and management between the two platforms.
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={appInstallFields.ftp}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default FTPInstall;
