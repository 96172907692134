import React from 'react';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import { ReactComponent as UspsIcon } from '../../../../assets/icons/apps/usps.svg';
import { ReactComponent as UpsIcon } from '../../../../assets/icons/apps/ups.svg';
import { ReactComponent as FedexIcon } from '../../../../assets/icons/apps/fedex.svg';
import Checkbox from '../../../_common/Form/Checkbox';

function RateBrowser() {
  return (
    <div className="rate_browser_data">
      <div className="rate_browser_left">
        <h3 className="rate_browser_title">Rate browser</h3>

        <div className="rate_browser_form">
          <h4 className="ship__from">Ship from</h4>
          <p className="country_title">US</p>

          <div className="country_postal_code">
            <Select
              label="Country"
              // options={currencies}
              roundBorder
              // valuePath="code"
              // labelPath="code"
              // value={generalList.currencyCode}
              // onChange={(code) => changeGeneralSettings('currencyCode', code)}
              size="small"
            />

            <Input
              label="Postal code"
              // value={value}
              // onChangeText={(val) => onChange(val)}
              size="small"
              roundBorder
            />
          </div>

          <Select
            label="Service"
            // options={currencies}
            roundBorder
            // valuePath="code"
            // labelPath="code"
            // value={generalList.currencyCode}
            // onChange={(code) => changeGeneralSettings('currencyCode', code)}
            size="small"
          />

          <Select
            label="Package"
            // options={currencies}
            roundBorder
            // valuePath="code"
            // labelPath="code"
            // value={generalList.currencyCode}
            // onChange={(code) => changeGeneralSettings('currencyCode', code)}
            size="small"
          />
          <div className="inputs_block">
            <Input
              label="Length (in)"
              // value={value}
              // onChangeText={(val) => onChange(val)}
              size="small"
              roundBorder
            />

            <Input
              label="Width (in)"
              // value={value}
              // onChangeText={(val) => onChange(val)}
              size="small"
              roundBorder
            />

            <Input
              label="Height (in)"
              // value={value}
              // onChangeText={(val) => onChange(val)}
              size="small"
              roundBorder
            />

          </div>

          <div className="country_postal_code">
            <Input
              label="Weight"
              // value={value}
              // onChangeText={(val) => onChange(val)}
              size="small"
              roundBorder
            />

            <Select
              label="Measure units"
              // options={currencies}
              roundBorder
              // valuePath="code"
              // labelPath="code"
              // value={generalList.currencyCode}
              // onChange={(code) => changeGeneralSettings('currencyCode', code)}
              size="small"
            />
          </div>

          <Select
            label="Confirmation"
            // options={currencies}
            roundBorder
            // valuePath="code"
            // labelPath="code"
            // value={generalList.currencyCode}
            // onChange={(code) => changeGeneralSettings('currencyCode', code)}
            size="small"
          />

          <Select
            label="Service class"
            // options={currencies}
            roundBorder
            // valuePath="code"
            // labelPath="code"
            // value={generalList.currencyCode}
            // onChange={(code) => changeGeneralSettings('currencyCode', code)}
            size="small"
          />

          <div className="saveBrowse">
            <Button
              title="Browse rates"
              size="small"
              btnType="light_blue"
              // loading={loading}
              roundBorder
              // onClick={openPresets}
            />
          </div>

        </div>
      </div>

      <div className="rate_browser_right">
        <p>Rates 3 of 3 available</p>

        <div className="usps_data">
          <div className="usps_package">
            <UspsIcon />
            <p>USPS first class package</p>
          </div>

          <div className="usps_item">
            <div className="usps_item_wrap">
              <div className="usps_check">
                <Checkbox
                  // key={value}
                  // label={label}
                  // onChange={() => onChangeInstallType(value)}
                  // checked={value === installType}
                  radio
                />

                <div>
                  <div className="usps_title">USPS first class package</div>
                  <div className="usps_desc">2 business days</div>
                </div>
              </div>

              <div className="usps_title">$9.18</div>

            </div>

            <div className="usps_item_wrap">
              <div className="usps_check">
                <Checkbox
                  // key={value}
                  // label={label}
                  // onChange={() => onChangeInstallType(value)}
                  // checked={value === installType}
                  radio
                />

                <div>
                  <div className="usps_title">USPS first class package</div>
                  <div className="usps_desc">2 business days</div>
                </div>
              </div>

              <div className="usps_title">$9.18</div>

            </div>

            <div className="usps_item_wrap">
              <div className="usps_check">
                <Checkbox
                  // key={value}
                  // label={label}
                  // onChange={() => onChangeInstallType(value)}
                  // checked={value === installType}
                  radio
                />

                <div>
                  <div className="usps_title">USPS first class package</div>
                  <div className="usps_desc">2 business days</div>
                </div>
              </div>

              <div className="usps_title">$9.18</div>

            </div>

            <div className="usps_item_wrap">
              <div className="usps_check">
                <Checkbox
                  // key={value}
                  // label={label}
                  // onChange={() => onChangeInstallType(value)}
                  // checked={value === installType}
                  radio
                />

                <div>
                  <div className="usps_title">USPS first class package</div>
                  <div className="usps_desc">2 business days</div>
                </div>
              </div>

              <div className="usps_title">$9.18</div>

            </div>

          </div>
        </div>

        <div className="fedex_row">
          <FedexIcon />
          <p>FedEx packages</p>
        </div>

        <div className="fedex_row">
          <UpsIcon />
          <p>UPS</p>
        </div>

        <div className="browser_buttons_block">
          <Button
            btnType="cancel"
            roundBorder
            size="small"
          >
            Cancel
          </Button>

          <Button
            roundBorder
            size="small"
            // onClick={() => setWarningModal(true)}
          >
            Configure label
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RateBrowser;
