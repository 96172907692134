import React, { useMemo } from 'react';
import SelectedCard from './SelectedCard';
import Input from '../../../_common/Form/Input';
import { ReactComponent as QuickbooksIcon } from '../../../../assets/icons/apps/quickbooks.svg';
import { ReactComponent as XeroIcon } from '../../../../assets/icons/apps/xero.svg';

const StepFour = ({ data, changeData }) => {
  const changeAccountingData = (type) => {
    changeData((prev) => {
      const foundData = prev.accounting.includes(type);

      if (foundData) {
        return { ...prev, accounting: prev.accounting.filter((s) => s !== type) };
      }

      return { ...prev, accounting: [...prev.accounting, type] };
    });
  };

  const changeOtherValue = (value) => {
    changeData((prev) => ({ ...prev, accountingOtherValue: value }));

    changeData((prev) => {
      if (!value.trim()) {
        return { ...prev, accounting: prev.accounting.filter((s) => s !== 'other') };
      }

      if (!prev.accounting.includes('other')) {
        return { ...prev, accounting: [...prev.accounting, 'other'] };
      }

      return prev;
    });
  };

  const info = useMemo(() => [
    {
      type: 'quickbooks',
      content: (
        <div className="selected_card_icon_wrapper">
          <p className="selected_card_top_description">Quickbooks</p>

          <QuickbooksIcon className="selected_card_icon" />
        </div>
      ),
    },
    {
      type: 'xero',
      content: (
        <div className="selected_card_icon_wrapper">
          <p className="selected_card_top_description">Xero</p>

          <XeroIcon className="selected_card_icon" />
        </div>
      ),
    },
    {
      type: 'other',
      content: (
        <div>
          <p className="selected_card_top_description">Other</p>

          <Input
            value={data.accountingOtherValue}
            onChangeText={(value) => changeOtherValue(value)}
            size="small"
            roundBorder
          />
        </div>
      ),
    },
  ], [data]);

  return (
    <div className="onboarding_page_steps_content step_four">
      {info.map(({ type, content }) => (
        <SelectedCard
          key={type}
          onChange={() => type !== 'other' && changeAccountingData(type)}
          checked={data.accounting.includes(type)}
          hideCheckbox={type === 'other'}
        >
          {content}
        </SelectedCard>
      ))}
    </div>
  );
};

export default StepFour;
