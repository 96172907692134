import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../../../Api';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';
import demoImg from '../../../assets/images/onboarding_demo_page.png';
import StepsLine from '../../_common/Steps/StepsLine';
import StepWrapper from './Steps/StepWrapper';
import StepTwo from './Steps/StepTwo';
import StepThree from './Steps/StepThree';
import Account from '../../../helpers/Account';
import StepFour from './Steps/StepFour';

const steps = [
  { step: 1 }, { step: 2 }, { step: 4 },
];

const Onboarding = () => {
  const navigate = useNavigate();
  const profile = Account.get();

  const [data, setData] = useState({
    sale: [],
    saleOtherValue: '',
    places: [],
    placesOtherValue: '',
    accounting: [],
    accountingOtherValue: '',
  });
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const token = localStorage.getItem('onboardingToken');

    if (!token) {
      navigate('/', { replace: true });
    }
  }, []);

  const changeStep = (step) => {
    if (step > currentStep) {
      setCurrentStep(step);
    }
  };

  const onNextBtnClick = () => {
    setCurrentStep((prev) => {
      if (prev === 4) {
        const sendingData = {};

        const isSaleOtherFieldSelected = data.sale.includes('other');
        const isPlacesOtherFieldSelected = data.sale.includes('other');
        const isAccountingOtherFieldSelected = data.sale.includes('other');

        sendingData.sale = [...data.sale.filter((s) => s !== 'other')];
        sendingData.places = [...data.places.filter((s) => s !== 'other')];
        sendingData.accounting = [...data.accounting.filter((s) => s !== 'other')];

        if (isSaleOtherFieldSelected && data.saleOtherValue.trim()) {
          sendingData.sale.push(`other ${data.saleOtherValue.trim()}`);
        }

        if (isPlacesOtherFieldSelected && data.placesOtherValue.trim()) {
          sendingData.places.push(`other ${data.placesOtherValue.trim()}`);
        }

        if (isAccountingOtherFieldSelected && data.accountingOtherValue.trim()) {
          sendingData.accounting.push(`other ${data.accountingOtherValue.trim()}`);
        }

        sendingData.sale = sendingData.sale.join(',');
        sendingData.places = sendingData.places.join(',');
        sendingData.accounting = sendingData.accounting.join(',');

        const token = localStorage.getItem('onboardingToken');

        Account.setToken(token);

        navigate('/setup', { replace: true });

        Api.createAccountTarget(sendingData);

        localStorage.removeItem('onboardingToken');

        return prev;
      } if (prev === 2 && !data.sale.includes('sale')) {
        return prev + 2;
      }

      return prev + 1;
    });
  };

  const stepsInfo = useMemo(() => [{
    step: 1,
    component: (
      <StepWrapper
        title="Welcome to eSwap!"
        subtitle="Thank you for choosing eSwap and starting your free 14-day trial! With eSwap it's simple to manage
  what you sell and where you sell it, all from one place. First, let's take a look at how our software works for you!"
        onNextBtnClick={onNextBtnClick}
      />
    ),
  },
  {
    step: 2,
    component: (
      <StepWrapper
        title={`Welcome ${profile.firstName} ${profile.lastName}`}
        subtitle="We have created your eSwap account"
        description="To set up this account for your business, first tell us how you sell your products. Select all that
      applies."
        onNextBtnClick={onNextBtnClick}
      >
        <StepTwo
          changeData={setData}
          data={data}
        />
      </StepWrapper>
    ),
  },
  {
    step: 3,
    component: (
      <StepWrapper
        title={`Welcome ${profile.firstName} ${profile.lastName}`}
        subtitle="We have created your eSwap account"
        description="This is the fastest and esiest way to get started with eSwap as we can import all your product
        information, images and orders automatocally."
        onNextBtnClick={onNextBtnClick}
        nextStepBtnTitle="Done"
      >
        <StepThree
          changeData={setData}
          data={data}
        />
      </StepWrapper>
    ),
  },
  {
    step: 4,
    component: (
      <StepWrapper
        title={`Welcome ${profile.firstName} ${profile.lastName}`}
        subtitle="We have created your eSwap account"
        description="Integrating with an accounting platform saves you time by automatically pulling in your orders and
      payments. Select a platform you currently use, if any."
        onNextBtnClick={onNextBtnClick}
        nextStepBtnTitle="Done"
      >
        <StepFour
          changeData={setData}
          data={data}
        />
      </StepWrapper>
    ),
  },
  ], [data]);

  return (
    <div className="onboarding_page">
      <div className="onboarding_page_main">
        <div className="onboarding_page_main_left_block">
          <div className="onboarding_page_logo_wrapper">
            <LogoIcon />
          </div>

          <div className="onboarding_page_main_left_block_steps">
            <StepsLine
              currentStep={currentStep}
              steps={steps}
              setStep={changeStep}
            />

            {stepsInfo.find(({ step }) => step === currentStep)?.component}
          </div>
        </div>

        <div className="onboarding_page_main_right_block">
          <img src={demoImg} alt="Demo image" />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
