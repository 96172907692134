import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Input from '../../_common/Form/Input';
import Button from '../../_common/Form/Button';
import Utils from '../../../helpers/Utils';
import TermsAndPrivacyText from './TermsAndPrivacyText';

const StepOne = ({
  fields, data, onChange, changeStep, errors, setErrors,
}) => {
  const submit = useCallback((e) => {
    e.preventDefault();

    let hasError = fields.some(({ path }) => errors[path]);

    fields.forEach(({ path }) => {
      if (!data[path].trim()) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));
        hasError = true;
      } else if (path === 'email' && !Utils.isEmail(data[path])) {
        setErrors((prev) => ({ ...prev, [path]: 'Enter valid email' }));
        hasError = true;
      }
    });

    if (hasError) {
      toast.error('Please correct these fields');
    } else {
      changeStep(2);
    }
  }, [data]);

  return (
    <form onSubmit={submit}>
      {fields.map(({
        type, label, path, placeholder,
      }) => (
        <div key={label}>
          <Input
            label={label}
            placeholder={placeholder}
            password={type === 'password'}
            value={data[path]}
            error={errors[path]}
            onChangeText={(value) => onChange(path, value)}
            size="small"
            roundBorder
          />
        </div>
      ))}

      <Button
        roundBorder
        size="small"
        type="submit"
        className="sign_up_btn_continue"
      >
        Continue
      </Button>
    </form>
  );
};

StepOne.propTypes = {
  fields: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default StepOne;
