import React, { useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({
  checked, indeterminate, label, onChange, disabled, className, labelMarginLeft, error, color, radio, plus,
}) => {
  const id = useId();

  return (
    <div
      style={{
        '--checkbox_color': color,
      }}
      className={classNames('checkbox_wrapper', className, { error, radio })}
    >
      <input
        id={`checkbox_id_${id}`}
        type="checkbox"
        className={classNames('checkbox_input', { indeterminate, plus, radio })}
        checked={checked}
        onChange={({ target }) => onChange(target.checked)}
        disabled={disabled}
      />

      <label className="checkbox_label" htmlFor={`checkbox_id_${id}`}>
        <span className="checkbox_box" />

        {label && <span style={{ paddingLeft: labelMarginLeft }} className="checkbox_text">{label}</span>}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  labelMarginLeft: PropTypes.number,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: '',
  label: '',
  color: '#1472FF',
  labelMarginLeft: 6,
  checked: false,
  onChange: () => {},
  disabled: false,
};

export default Checkbox;
