import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import SaleOrdersFilters from '../../components/pages/Orders/saleOrders/SaleOrdersFilters';
import SaleOrdersTable from '../../components/pages/Orders/saleOrders/SaleOrdersTable';
import Wrapper from '../../components/Layout/Wrapper';
import { getOrdersRequest } from '../../store/actions/orders';
import Loader from '../../components/_common/Loader/Loader';

function Quotations() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const firstLoaded = useRef(true);
  const [searchParams] = useSearchParams({});
  const [checkedOrders, setCheckedOrders] = useState([]);

  const location = useLocation();

  const type = useMemo(() => (location.pathname.includes('/stocks') ? 'purchase' : 'sale'), [location]);

  useDeepCompareEffect(() => {
    handleGetOrders();
  }, [searchParams, type]);

  const handleGetOrders = useCallback(async () => {
    setLoading(true);
    await dispatch(getOrdersRequest({
      status: 'draft',
      ...Object.fromEntries(searchParams),
      includes: 'customer',
      isArchive: false,
      type,
    }));
    firstLoaded.current = false;
    setLoading(false);
  }, [searchParams, type]);

  return (
    <Wrapper
      title="Quotations"
      className="customers_wrapper sales_orders_wrapper"
    >
      {!firstLoaded.current ? (
        <div className="customersList">
          <SaleOrdersFilters reorderCols={false} filterExclude={['status']} type={type} />
          <SaleOrdersTable
            setCheckedOrders={setCheckedOrders}
            checkedOrders={checkedOrders}
            loading={loading}
            onGetOrders={handleGetOrders}
            preview={false}
            path={type === 'sale' ? '/orders/sales-order' : '/stocks/purchase-orders'}
          />
        </div>
      ) : <Loader />}
    </Wrapper>
  );
}

export default Quotations;
