import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import plusImg from '../../../../assets/icons/image-plus.svg';
import Checkbox from '../../../_common/Form/Checkbox';
import { setCustomerFormData } from '../../../../store/actions/customers';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/close.svg';

function General() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const customerFormData = useSelector((state) => state.customers.customerFormData);
  const errors = useSelector((state) => state.customers.errors);

  useEffect(() => {
    if (searchParams.get('isVendor')) {
      dispatch(setCustomerFormData({ key: 'isVendor', value: true }));
      dispatch(setCustomerFormData({ key: 'isCustomer', value: false }));
    }
  }, []);

  return (
    <div className="general">
      <div className="file_upload">
        <div className="image">
          <img src={customerFormData.image?.uri || customerFormData.image || plusImg} alt="" />
          {customerFormData.image
            ? (
              <p className="delete" onClick={() => dispatch(setCustomerFormData({ key: 'image', value: undefined }))}>
                <DeleteIcon />
              </p>
            ) : null}
        </div>
        <Button
          btnType="upload"
          size="small"
          reverseColor
          fileAccept="image/png, image/gif, image/jpeg, image/webp"
          onFileUpload={(ev) => {
            const file = ev.target.files[0];
            try {
              file.uri = URL.createObjectURL(file);
            } catch (e) {
              console.log(e);
            }
            dispatch(setCustomerFormData({ key: 'image', value: file }));
          }}
        >
          Upload logo
        </Button>

      </div>
      <p className="title">Customer overview</p>
      <div className="overview">
        <div className="type">
          <Checkbox
            label="Individual"
            radio
            onChange={(checked) => {
              dispatch(setCustomerFormData({ key: 'type', value: checked ? 'individual' : '' }));
            }}
            checked={customerFormData.type === 'individual'}
          />
          <Checkbox
            checked={customerFormData.type === 'company'}
            label="Business"
            radio
            onChange={(checked) => {
              dispatch(setCustomerFormData({ key: 'type', value: checked ? 'company' : '' }));
            }}
          />
        </div>
        <div className="type">
          <span>Type:</span>
          <Checkbox
            onChange={(value) => {
              dispatch(setCustomerFormData({ key: 'isVendor', value }));
            }}
            checked={customerFormData.isVendor}
            label="Vendor"
          />
          <Checkbox
            onChange={(value) => {
              dispatch(setCustomerFormData({ key: 'isCustomer', value }));
            }}
            checked={customerFormData.isCustomer}
            label="Customer"
          />
        </div>
      </div>
      <p className="title">Personal information</p>
      <div className={`row ${customerFormData.type}`}>
        {customerFormData.type === 'individual' ? (
          <Input
            roundBorder
            label="First name"
            placeholder="First name"
            onChange={(ev) => {
              dispatch(setCustomerFormData({ key: 'firstName', value: ev.target.value }));
              dispatch(setCustomerFormData({ key: 'billingAddress.firstName', value: ev.target.value }));
            }}
            value={customerFormData.firstName}
            error={errors.firstName}
          />
        ) : null}
        {customerFormData.type === 'individual' ? (
          <Input
            roundBorder
            label="Last name"
            placeholder="Last name"
            onChange={(ev) => {
              dispatch(setCustomerFormData({ key: 'lastName', value: ev.target.value }));
              dispatch(setCustomerFormData({ key: 'billingAddress.lastName', value: ev.target.value }));
            }}
            value={customerFormData.lastName}
            error={errors.lastName}
          />
        ) : null}
        <Input
          roundBorder
          label="Company name"
          placeholder="Company name"
          onChange={(ev) => {
            dispatch(setCustomerFormData({ key: 'company', value: ev.target.value }));
          }}
          value={customerFormData.company}
        />
        <Input
          roundBorder
          label="Email"
          placeholder="Email"
          onChange={(ev) => {
            dispatch(setCustomerFormData({ key: 'email', value: ev.target.value }));
          }}
          value={customerFormData.email}
          error={errors.email}
        />
        <Input
          roundBorder
          label="Mobile phone"
          symbol="+"
          onChange={(ev) => {
            dispatch(setCustomerFormData(
              { key: 'phone', value: ev.target.value ? `+${ev.target.value}` : undefined },
            ));
          }}
          value={customerFormData.phone?.replace('+', '')}
          error={errors.phone}
          type="number"
        />
        <Input
          roundBorder
          label="Work phone"
          symbol="+"
          onChange={(ev) => {
            dispatch(setCustomerFormData(
              { key: 'workPhone', value: ev.target.value ? `+${ev.target.value}` : undefined },
            ));
          }}
          value={customerFormData.workPhone?.replace('+', '')}
          error={errors.workPhone}
          type="number"
        />
      </div>

    </div>
  );
}

export default General;
