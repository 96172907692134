import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { ReactComponent as GoBackIcon } from '../../assets/icons/go_back.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';

function TitleBar(props) {
  const {
    title, backButton = true, children, actions, isActionActive, onActionClick,
  } = props;
  const actionsRef = useRef();
  const navigate = useNavigate();
  const [isActionOpen, setIsActionOpen] = useState(false);

  useClickAway(actionsRef, () => {
    setIsActionOpen(false);
  });

  const handleActionClick = useCallback((d) => {
    setIsActionOpen(false);
    onActionClick(d);
  }, [onActionClick]);

  return (
    <div className="main_title_wrapper">
      <div className="title">
        {backButton ? <GoBackIcon className="back_button" onClick={() => navigate(-1)} /> : null}
        {title ? <h1>{title}</h1> : null}
        {actions?.length ? (
          <div className={`actions_wrapper ${isActionOpen ? 'open' : ''}`} ref={actionsRef}>
            <h1 onClick={() => setIsActionOpen(!isActionOpen)}>
              {`${actions.find(isActionActive)?.label}`}
            </h1>
            <ArrowIcon onClick={() => setIsActionOpen(!isActionOpen)} />
            {isActionOpen ? (
              <div className="dropdown">
                {actions.map((d) => (
                  <button
                    type="button"
                    key={d.label}
                    className={isActionActive(d) ? 'active' : undefined}
                    onClick={() => handleActionClick(d)}
                  >
                    {d.label}
                  </button>
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="buttons">
        {children}
      </div>
    </div>
  );
}

export default TitleBar;
