import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import Button from '../../../_common/Form/Button';
import Utils from '../../../../helpers/Utils';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_red.svg';
import Modal from '../../../_common/Modal/Modal';
import DeleteModal from '../../../_common/Modal/DeleteModal';

const imageAllowFormats = '.png, .jpg, .jpeg, .webp';

function ProductImages({ setProductData, images }) {
  const [show, setShow] = React.useState(true);
  const [openedImageURL, setOpenedImageURL] = useState('');
  const [deleteModal, setDeleteModal] = useState('');

  const onUploadFile = useCallback((e) => {
    Utils.onUploadFile(e, (file) => {
      setProductData((prev) => ({ ...prev, images: [...prev.images, { src: file }] }));
    }, imageAllowFormats);
  }, []);

  const onDeleteIconClick = useCallback((e, index) => {
    e.stopPropagation();
    setDeleteModal(index);
  }, []);

  const onImageDelete = useCallback(() => {
    setProductData((prev) => ({
      ...prev,
      images: prev.images.filter((val, i) => +deleteModal !== +i),
    }));

    setDeleteModal('');
  }, [deleteModal]);

  return (
    <div className="create_product_images">
      <div className={`create_product_title_block ${!show ? 'not_show' : ''}`}>
        <ArrowIcon onClick={() => setShow((prev) => !prev)} />

        <h2 className="create_product_title_block_title">Media</h2>
      </div>

      {show && (
        <div className="create_product_images_block">
          <div className="create_product_added_images">
            <div className="create_product_images_block_upload">
              <Button
                btnType="upload"
                onFileUpload={onUploadFile}
                title="Upload image"
                reverseColor
                className="create_product_images_input"
                fileAccept={imageAllowFormats}
                multipleUpload
              />
            </div>

            {images.map((img, index) => (
              <div
                className="create_product_added_img_wrp"
                key={img?.src?._preview || img?.src}
                onClick={() => setOpenedImageURL(img?.src?._preview || img?.src || img?.medium)}
              >
                <img
                  src={img?.src?._preview || img?.src || img?.medium}
                  alt="product image"
                  className="create_product_added_img"
                />

                <DeleteIcon
                  className="img_delete_icon"
                  onClick={(e) => onDeleteIconClick(e, index)}
                />
              </div>
            ))}
          </div>

          <Modal
            isOpen={!!openedImageURL}
            onClose={() => setOpenedImageURL('')}
            className="product_variant_img_view_modal"
            zIndex={103}
          >
            <div>
              <img
                src={openedImageURL}
                alt="Variant image"
                className="product_variant_img_view_modal_image"
              />
            </div>
          </Modal>

          <DeleteModal
            isOpen={deleteModal !== ''}
            onDelete={onImageDelete}
            onClose={() => setDeleteModal('')}
            text="image"
          />
        </div>
      )}
    </div>
  );
}

ProductImages.propTypes = {
  setProductData: PropTypes.func.isRequired,
  images: PropTypes.array,
};

ProductImages.defaultProps = {
  images: [],
};

export default ProductImages;
