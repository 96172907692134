import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useClickAway } from 'react-use';
import { toast } from 'react-toastify';
import _ from 'lodash';
import FilterButton from '../../../_common/Form/FilterButton';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/remote_printing.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import { declineReturnRequest, deleteReturnRequest } from '../../../../store/actions/orderReturns';
import Loader from '../../../_common/Loader/Loader';
import Utils from '../../../../helpers/Utils';

function ReturnPreviewHeader() {
  const [dropdown, setDropdown] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { returnId } = useParams();
  const dispatch = useDispatch();

  const dropdownRef = useRef();

  useClickAway(dropdownRef, () => {
    setDropdown(false);
  });

  const orderReturnsList = useSelector((state) => state.orderReturns.orderReturnsList);

  const singleReturn = useMemo(() => orderReturnsList.find((o) => o.id === returnId) || {}, [orderReturnsList, returnId]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload: { data } } = await dispatch(deleteReturnRequest(returnId));
    if (data.status === 'error') {
      toast.error(data.message);
    } else {
      toast.success('Successfully deleted.');
      navigate(`/orders/returns${location.search}`);
    }
    setLoadingDelete(false);
  }, [returnId, location]);

  const handleDecline = useCallback(async () => {
    setLoadingDelete(true);
    const { payload: { data } } = await dispatch(declineReturnRequest(returnId));
    if (data.status === 'error') {
      toast.error(data.message);
    } else {
      toast.success('Successfully declined.');
      navigate(`/orders/returns${location.search}`);
    }
    setLoadingDelete(false);
  }, [returnId, location]);

  const handleGetReceiveStatus = useCallback((orderReturnProducts) => {
    const receivedQty = _.sumBy(orderReturnProducts, 'receivedQty');
    const qty = _.sumBy(orderReturnProducts, 'qty');
    if (qty - receivedQty === 0) {
      return 'received';
    }
    if (receivedQty) {
      return 'partially_received';
    }
    return 'not_received';
  }, []);

  return (
    <div className="preview_header">
      <div className="info">
        <div className="row">
          <p className="number">{singleReturn.number}</p>
          <div className="statuses">
            <p className={singleReturn.status}>
              <span className="status_dot" />
              {Utils.formatStatus(singleReturn.status)}
            </p>
            <p className={handleGetReceiveStatus(singleReturn.orderReturnProducts)}>
              <span className="status_dot" />
              {Utils.formatStatus(handleGetReceiveStatus(singleReturn.orderReturnProducts))}
            </p>
          </div>
        </div>
        <div className="row">
          <p className="created_at">
            <span>{moment(singleReturn.sourceCreatedAt || singleReturn.createdAt).format('MM/DD/YYYY')}</span>
          </p>
          <p className="price_list">
            <span className="label">Reason:</span>
            <span className="value">{singleReturn.reason || '-'}</span>
          </p>
        </div>
      </div>
      <div className="actions">
        <FilterButton
          className="filter_button"
          onClick={() => {
          }}
        >
          <PrintIcon />
          <span>Print</span>
        </FilterButton>
        <FilterButton
          className="filter_button"
          onClick={() => navigate(`${location.pathname}${location.search}`, {
            state: { receive: true },
            replace: true,
          })}
        >
          <span>Receive</span>
        </FilterButton>
        <FilterButton className="filter_button dots" onClick={() => setDropdown(true)}>
          <DotsIcon />
        </FilterButton>
        {dropdown ? (
          <ul className="dropdown" ref={dropdownRef}>
            <li onClick={handleDecline}>Mark as Declined</li>
            <li className="delete" onClick={handleDelete}>
              Delete
              {loadingDelete ? <Loader size={10} /> : null}
            </li>
          </ul>
        ) : null}
        <RemoveIcon className="remove_icon" onClick={() => navigate(`/orders/returns${location.search}`)} />
      </div>
    </div>
  );
}

export default ReturnPreviewHeader;
