import React, { memo, useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TagsInput } from 'react-tag-input-component';

const MultipleTagInput = memo(({
  value, onChange, label, placeholder, className, roundBorder, error, size, fieldset, onBeforeInput,
}) => {
  const id = useId();

  return (
    <div className={classNames('multiple_input_wrapper', className, {
      error,
      round_border: roundBorder,
      fieldset,
      [`size_${size}`]: !!size,
    })}
    >
      {label && <label htmlFor={id} className="label">{label}</label>}

      <TagsInput
        value={value}
        onChange={onChange}
        placeHolder={placeholder}
        beforeAddValidate={onBeforeInput}
      />

      {!!error && typeof error === 'string' && <p className="error_text">{error}</p>}
    </div>
  );
});

MultipleTagInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default']),
  roundBorder: PropTypes.bool,
  fieldset: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  onBeforeInput: PropTypes.func,
};

MultipleTagInput.defaultProps = {
  className: '',
  value: [],
  label: '',
  placeholder: '',
  size: 'default',
  roundBorder: false,
  fieldset: false,
  error: '',
  onChange: () => {},
  onBeforeInput: undefined,
};

export default MultipleTagInput;
