import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Switcher from '../../_common/Form/Switcher';
import Input from '../../_common/Form/Input';
import Button from '../../_common/Form/Button';
import Checkbox from '../../_common/Form/Checkbox';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import Loader from '../../_common/Loader/Loader';
import {
  createExportTemplate,
  getExportPriceList, getExportTemplateCustomFieldList,
  getExportWarehouseList,
  templateFieldsAction, updateExportTemplate,
} from '../../../store/actions/fileExport';
import Utils from '../../../helpers/Utils';

const fieldsData = {
  productsExport: [
    {
      label: 'All',
      path: 'all',
    },
    {
      label: 'Title',
      path: 'title',
    },
    {
      label: 'SKU',
      path: 'sku',
    },
    {
      label: 'Product description',
      path: 'description',
    },
    {
      label: 'Type',
      path: 'type',
    },
    {
      label: 'Warehouse',
      path: 'warehouseIds',
      childFields: [],
    },
    {
      label: 'Price lists',
      path: 'priceListIds',
      childFields: [],
    },
    {
      label: 'Variants',
      path: 'variants',
    },
    {
      label: 'MAC price',
      path: 'macPrice',
    },
    {
      label: 'Barcode',
      path: 'barcode',
    },
    {
      label: 'Product tags',
      path: 'productTags',
      childFields: [
        {
          label: 'Name',
          path: 'productTags.name',
        },
        {
          label: 'Color',
          path: 'productTags.color',
        },
      ],
    },
    {
      label: 'UPC',
      path: 'upc',
    },
    {
      label: 'Brand',
      path: 'brand',
    },
    {
      label: 'Dimensions',
      path: 'dimensions',
      childFields: [
        {
          label: 'Weight',
          path: 'dimensions.weight',
        },
        {
          label: 'Weight unit',
          path: 'dimensions.weightUnit',
        },
        {
          label: 'Dimensions unit',
          path: 'dimensions.dimensionsUnit',
        },
        {
          label: 'Dimensions',
          path: 'dimensions.dimensions',
        },
      ],
    },
    {
      label: 'Category',
      path: 'category',
      childFields: [
        {
          label: 'Name',
          path: 'category.name',
        },
        {
          label: 'Description',
          path: 'category.description',
        },
      ],
    },
    {
      label: 'Shop',
      path: 'shop',
    },
    {
      label: 'Vendor',
      path: 'vendor',
      childFields: [
        {
          label: 'Company',
          path: 'vendor.company',
        },
        {
          label: 'Email',
          path: 'vendor.email',
        },
        {
          label: 'First name',
          path: 'vendor.firstName',
        },
        {
          label: 'Last name',
          path: 'vendor.lastName',
        },
        {
          label: 'Phone',
          path: 'vendor.phone',
        },
        {
          label: 'Billing address',
          path: 'vendor.billingAddress',
        },
        {
          label: 'Shipping address',
          path: 'vendor.shippingAddresses',
        },
      ],
    },
  ],
  ordersExport: [
    {
      label: 'All',
      path: 'all',
    },
    {
      label: 'Order ID',
      path: 'orderId',
    },
    {
      label: 'Total price',
      path: 'totalPrice',
    },
    {
      label: 'Subtotal price',
      path: 'subtotalPrice',
    },
    {
      label: 'Total tax',
      path: 'totalTax',
    },
    {
      label: 'Fulfillment status',
      path: 'status',
    },
    {
      label: 'Payment status',
      path: 'paymentStatus',
    },
    {
      label: 'Currency',
      path: 'currencyCode',
    },
    {
      label: 'Customer',
      path: 'customer',
    },
    {
      label: 'Product',
      path: 'product',
    },
    {
      label: 'Warehouse',
      path: 'warehouse',
    },
    {
      label: 'PriceList',
      path: 'priceList',
    },
    {
      label: 'Shop',
      path: 'shopType',
    },
    {
      label: 'Carrier',
      path: 'carrier',
    },
    {
      label: 'Delivery method',
      path: 'deliveryMethod',
    },
    {
      label: 'Shipping rate',
      path: 'shippingRate',
    },
    {
      label: 'Shipping date',
      path: 'shippedDate',
    },
    {
      label: 'Package type',
      path: 'packageType',
    },
    {
      label: 'Package size',
      path: 'packageSize',
    },
    {
      label: 'Package weight',
      path: 'packageWeight',
    },
    {
      label: 'Package weight unit',
      path: 'packageWeightUnit',
    },
    {
      label: 'Label url',
      path: 'labelUrl',
    },
    {
      label: 'Payment term',
      path: 'paymentTerm',
    },
    {
      label: 'Created At',
      path: 'createdAt',
    },
    {
      label: 'Shipping address',
      path: 'shippingAddress',
    },
    {
      label: 'Billing address',
      path: 'billingAddress',
    },
  ],
  customersExport: [
    {
      label: 'All',
      path: 'all',
    },
    {
      label: 'First name',
      path: 'firstName',
    },
    {
      label: 'Last name',
      path: 'lastName',
    },
    {
      label: 'Email',
      path: 'email',
    },
    {
      label: 'Phone',
      path: 'phone',
    },
    {
      label: 'Type',
      path: 'type',
    },
    {
      label: 'Company',
      path: 'company',
    },
    {
      label: 'Logo',
      path: 'logo',
    },
    {
      label: 'Tax type',
      path: 'taxType',
    },
    {
      label: 'Website',
      path: 'website',
    },
    {
      label: 'Job position',
      path: 'jobPosition',
    },
    {
      label: 'Price list',
      path: 'priceList',
    },
    {
      label: 'Payment term',
      path: 'paymentTerm',
    },
    {
      label: 'Billing address',
      path: 'billingAddress',
    },
    {
      label: 'Shipping address',
      path: 'shippingAddress',
    },
  ],
};

const ExportTemplate = ({
  createOrUpdateTemplate, setCreateOrUpdateTemplate, requestSaveTemplate, setRequestSaveTemplate,
  name, setRequestExport,
}) => {
  const defaultDataRef = useRef(requestSaveTemplate);

  const dispatch = useDispatch();
  const templateFields = useSelector((state) => state.fileExport.templateFields);
  const warehouses = useSelector((state) => state.fileExport.warehouses);
  const priceLists = useSelector((state) => state.fileExport.priceLists);
  const customFields = useSelector((state) => state.fileExport.customFields);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [savedTemplate, setSavedTemplate] = useState(false);
  const [childFieldsPath, setChildFieldsPath] = useState([]);
  const [error, setError] = useState('');

  const splitFieldsIds = (path) => requestSaveTemplate.fields.filter((f) => f.includes(path)).map((f) => {
    const [, id] = f.split('.');

    return id;
  });

  useMemo(() => {
    if (createOrUpdateTemplate === 'add' || createOrUpdateTemplate !== 'custom' || requestSaveTemplate.title) {
      setSavedTemplate(true);
    }

    if (!templateFields?.length) {
      dispatch(templateFieldsAction(fieldsData[name]));
    }
  }, []);

  const allTemplateCount = useMemo(() => templateFields.reduce((total, item) => {
    if (item.childFields) return total + item.childFields.length;

    return total + 1;
  }, 0) - 1, [templateFields]);

  const openChildFields = (path) => {
    setChildFieldsPath((prev) => (prev.includes(path)
      ? prev.filter((p) => p !== path)
      : [...prev, path]));
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if ((!priceLists.length || !warehouses.length) && name === 'productsExport') {
        await Promise.all([dispatch(getExportWarehouseList()),
          dispatch(getExportPriceList())]);
      }

      if (!customFields.length) {
        await dispatch(getExportTemplateCustomFieldList(
          { name: `${name.replace('sExport', '')}CustomFields` },
        ));
      }

      setLoading(false);
    })();
  }, []);

  const changeCheckbox = (path, field) => {
    if (path === 'all') {
      setRequestSaveTemplate((prev) => ({
        ...prev,
        fields: requestSaveTemplate.fields.length === allTemplateCount
          ? []
          : _.drop(templateFields.map((p) => {
            if (p.childFields?.length) {
              return p.childFields.map((c) => c.path);
            }

            return p.path;
          }).flat(1)),
      }));

      return;
    }

    const fullChildData = [...requestSaveTemplate.fields, path].filter((f) => f.startsWith(`${field.path}.`));

    if (path === field?.path && field.childFields) {
      setRequestSaveTemplate((prev) => ({
        ...prev,
        fields: fullChildData.length === field.childFields.length
          ? _.difference(prev.fields, [...field.childFields.map((f) => f.path)])
          : _.uniq([...prev.fields, ...field.childFields.map((f) => f.path)]),
      }));
    } else {
      setRequestSaveTemplate((prev) => ({
        ...prev,
        fields: prev.fields.includes(path)
          ? prev.fields.filter((p) => p !== path)
          : [...prev.fields, path],
      }));
    }
  };

  const changeInput = (value) => {
    setError('');
    setRequestSaveTemplate((prev) => ({ ...prev, title: value }));
  };

  const onCancel = () => {
    setCreateOrUpdateTemplate('');
    setRequestSaveTemplate(createOrUpdateTemplate !== 'custom' ? { fields: [] } : defaultDataRef.current);
  };

  const saveClick = async () => {
    let hasErrors = false;
    if (savedTemplate && !requestSaveTemplate.title) {
      hasErrors = true;
      setError('This field is required!');
    }

    if (!requestSaveTemplate.fields.length) {
      hasErrors = true;
      toast.error('Please check  fields!');
    }
    if (!hasErrors) {
      const requestData = Utils.deleteEmptyKeys({
        id: '',
        warehouseIds: splitFieldsIds('warehouseIds'),
        priceListIds: splitFieldsIds('priceListIds'),
        fields: requestSaveTemplate.fields.filter((f) => !(f.includes('warehouseIds') || f.includes('priceListIds'))),
      });

      let exportDate;

      if (savedTemplate) {
        setSaveLoading(true);

        if (createOrUpdateTemplate !== 'add' && createOrUpdateTemplate !== 'custom') {
          await dispatch(updateExportTemplate({
            [name]: {
              id: createOrUpdateTemplate,
              title: requestSaveTemplate.title,
              ...requestData,
            },
          }));

          exportDate = {
            id: createOrUpdateTemplate,
            ...requestData,
          };
        } else {
          const { payload } = await dispatch(createExportTemplate({
            [name]: {
              title: requestSaveTemplate.title,
              ...requestData,
            },
          }));

          exportDate = {
            id: _.last(payload[name]).id,
            ...requestData,
          };
        }

        setRequestSaveTemplate({ fields: [] });
      } else {
        exportDate = requestData;

        setRequestSaveTemplate((prev) => ({
          ...prev,
          ...requestData,
        }));
      }

      setRequestExport((prev) => ({
        fileType: prev.fileType,
        by: { ...prev.by },
        ...exportDate,
      }));

      setCreateOrUpdateTemplate('');
      setSaveLoading(false);
    }
  };

  const changeSwitch = (check) => {
    if (!check) {
      setRequestSaveTemplate((prev) => ({ ...prev, title: '' }));
    }
    setSavedTemplate(check);
  };

  return (
    <div className="export_modal_template_wrapper">
      <h1>Edit Export Template</h1>

      <Switcher
        onChange={changeSwitch}
        checked={savedTemplate}
        label="Save in templates"
        className="export_modal_template_switch"
      />

      {savedTemplate && (
      <Input
        label="Template name"
        type="text"
        onChange={(e) => changeInput(e.target.value)}
        value={requestSaveTemplate.title}
        error={error}
        size="small"
        roundBorder
        wrapperClassName="export_modal_template_input"
      />
      )}

      {loading && <Loader />}

      <div className="export_modal_template_body_wrapper">
        <div className={classNames('export_modal_template_checkbox_wrapper', { withInput: savedTemplate })}>
          {templateFields.map((field) => {
            const checkedChildFieldCount = requestSaveTemplate.fields
              .filter((f) => f.startsWith(`${field.path}.`)).length;

            return (
              <div className="export_modal_template_checkbox" key={field.path}>
                <div className="export_modal_template_child_count">
                  <Checkbox
                    onChange={() => changeCheckbox(field.path, field)}
                    label={field.label}
                    checked={field.path === 'all'
                      ? requestSaveTemplate.fields.length === allTemplateCount
                      : requestSaveTemplate.fields.includes(field.path)
                          || (checkedChildFieldCount === field?.childFields?.length
                        && !!field?.childFields?.length && !loading)}
                    indeterminate={checkedChildFieldCount}
                  />

                  {field.childFields && (
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => openChildFields(field.path)}
                    className="export_modal_template_checkbox_child"
                  >
                    {!!checkedChildFieldCount
                          && (
                            <p className="export_selected_count">
                              {checkedChildFieldCount}
                            </p>
                          )}

                    <div
                      className={classNames(
                        'export_modal_template_checkbox_child_arrow',
                        { active: childFieldsPath.includes(field.path) },
                      )}
                    >
                      <ArrowIcon />
                    </div>
                  </div>
                  )}
                </div>

                {childFieldsPath.includes(field.path) && field.childFields.map((childField) => (
                  <div className="export_modal_template_checkbox_child_wrapper" key={childField.path}>
                    <Checkbox
                      onChange={() => changeCheckbox(childField.path, field)}
                      label={childField.label}
                      checked={requestSaveTemplate.fields.includes(childField.path)}
                    />
                  </div>
                ))}
              </div>
            );
          })}

        </div>

        <div className="export_modal_template_buttons_wrapper">
          <Button
            className="export_modal_template_button"
            size="small"
            title="Cancel"
            onClick={onCancel}
            roundBorder
            btnType="cancel"
          />

          <Button
            className="export_modal_template_button"
            size="small"
            title="Save"
            onClick={saveClick}
            roundBorder
            color="#1472FF"
            loading={saveLoading}
          />
        </div>
      </div>
    </div>
  );
};

ExportTemplate.propTypes = {
  createOrUpdateTemplate: PropTypes.string.isRequired,
  setCreateOrUpdateTemplate: PropTypes.func.isRequired,
  requestSaveTemplate: PropTypes.object.isRequired,
  setRequestSaveTemplate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setRequestExport: PropTypes.func.isRequired,
};

export default ExportTemplate;
