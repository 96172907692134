import { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Api from '../../../../Api';
import { getSetupRequest } from '../../../../store/actions/notifications';

const GmailIntegration = () => {
  const { app } = useParams();

  const navigate = useNavigate();

  const [{ code }] = useQueryParams({ code: StringParam });

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!code) {
        navigate('/404', { replace: true });
      } else {
        try {
          await Api.createIntegration(app, {
            integrationName: 'Gmail',
            code,
            type: 'gmail',
            redirectUrl: `${window.location.origin}/integration-verify/${app}`,
          });

          await dispatch(getSetupRequest());

          navigate('/my-apps', { replace: true });
        } catch (err) {
          toast.error(err.response.data.message);
          navigate('/apps/list/email', { replace: true });
        }
      }
    })();
  }, []);

  return null;
};

export default GmailIntegration;
