import React, { Children } from 'react';
import { Routes } from 'react-router-dom';
import UserPermissions from '../../helpers/UserPermissions';

function filerRoutes(children) {
  return Children.map(children, (child) => {
    const { scope } = child.props;
    if (!UserPermissions.hasAccess(scope)) {
      return React.cloneElement(child, { element: <div>TODO</div> });
    }
    return React.cloneElement(child, { children: filerRoutes(child.props.children) });
  });
}

function RoutesWithPermissions(props) {
  const children = filerRoutes(props.children);
  return Reflect.construct(Routes, [{ children }], RoutesWithPermissions);
}

Object.setPrototypeOf(RoutesWithPermissions.prototype, Routes.prototype);
Object.setPrototypeOf(RoutesWithPermissions, Routes);

export default RoutesWithPermissions;
