import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { currencies } from 'currency-formatter';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useClickAway } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../../Layout/Wrapper';
import Input from '../../_common/Form/Input';
import Select from '../../_common/Form/Select';
import Button from '../../_common/Form/Button';
import Api from '../../../Api';
import Loader from '../../_common/Loader/Loader';
import Checkbox from '../../_common/Form/Checkbox';
import UpdateModal from '../../_common/Modal/UpdateModal';
import CreateCompanyModal from '../Settings/Company/CreateCompanyModal';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import DefaultImage from '../../../assets/icons/default.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/close_red.svg';
import { saveDraftReorderProduct } from '../../../store/actions/products';
import Utils from '../../../helpers/Utils';

const companyDefaultData = {
  name: '',
  logo: '',
  email: '',
  phone: '',
  fax: '',
  address: '',
  size: '',
  industry: '',
  registerNumber: '',
  vatNumber: '',
  countryCode: '',
  regionId: '',
  city: '',
  postalCode: '',
  isDefault: false,
};

function CreateReorder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { reorderId } = useParams();
  const firstLoad = useRef(false);
  const searchRef = useRef(null);
  const searchTimeout = useRef();
  const [searchParams] = useSearchParams();

  const draftReorder = useSelector((state) => state.products.draftSavedReorder);

  const [selectOptions, setSelectOptions] = useState({});
  const [countries, setCountries] = useState([]);
  const [openedModal, setOpenedModal] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [reorderData, setReorderData] = useState({
    title: '',
    number: '',
    warehouseId: '',
    vendorId: '',
    companyId: '',
    currencyCode: '',
    priceListId: '',
    action: 'draft_purchase',
  });
  const [errors, setErrors] = useState({});

  const zIndexForSelect = 10;

  useEffect(() => {
    (async () => {
      let singleWarehouse;
      let singleCompany;
      let singleCustomer;
      let singlePrice;

      let singleReorder;

      if (reorderId !== 'add') {
        // try {
        const { data } = await Api.getSingleReorder(reorderId);

        singleReorder = data.reorder;

        [singleWarehouse, singleCompany, singleCustomer, singlePrice] = await Promise.all([
          Api.getWarehouse(singleReorder.warehouseId),
          Api.getSingleCompany(singleReorder.companyId),
          Api.getSingleCustomer(singleReorder.vendorId),
          Api.getPriceListSingle(singleReorder.priceListId),
        ]);

        setReorderData({
          title: singleReorder.title,
          currencyCode: singleReorder.currencyCode,
          warehouseId: singleWarehouse.data.warehouse,
          companyId: singleCompany.data.company,
          vendorId: singleCustomer.data.customer,
          priceListId: singlePrice.data.priceList,
          number: singleReorder.number,
        });

        const productIds = _.uniqBy(singleReorder.reorderProducts.map((p) => (p.parentId || p.productId)));

        const product = await Api.getProducts({ ids: productIds.join(','), includes: 'variant' });
        const prods = product.data.products;

        const allProducts = prods.map((p) => {
          if (p.variants.length) {
            const foundVariants = singleReorder.reorderProducts.filter((pr) => +pr.parentId === +p.id);

            return {
              ...p,
              variants: p.variants.map((v) => {
                const foundVariant = foundVariants.find(
                  (reorderProd) => +reorderProd.productId === +v.id,
                );

                return {
                  ...v,
                  minStock: foundVariant.minStock,
                  reorderQty: foundVariant.reorderQty,
                  price: foundVariant.price,
                };
              }),
            };
          }

          const foundProduct = singleReorder.reorderProducts.find((pr) => +pr.productId === +p.id);

          return {
            ...p,
            minStock: foundProduct.minStock,
            reorderQty: foundProduct.reorderQty,
            price: foundProduct.price,
          };
        });

        setSelectedProducts(allProducts);
        // } catch (e) {
        //   navigate('/404');
        // }
      } else if (location.search.includes('?back=/products/')) {
        const productId = location.search.replace('?back=/products/', '');

        const product = await Api.getSingleProduct(productId);

        if (_.isEmpty(draftReorder)) {
          onSearchOrViewClick(product.data.product);
        }
      }

      if (!firstLoad.current) {
        const [
          prices,
          warehouse,
          customer,
          company,
          country,
        ] = await Promise.all([
          Api.getPriceLists(),
          Api.getWarehouses(),
          Api.getCustomers({ isVendor: true }),
          Api.getCompanies(),
          Api.getCountries(),
        ]);

        setSelectOptions({
          priceLists: singlePrice
            ? _.uniqBy([...prices.data.priceLists, singlePrice.data.priceList], 'id')
            : prices.data.priceLists,
          warehouses: singleWarehouse
            ? _.uniqBy([...warehouse.data.warehouses, singleWarehouse.data.warehouse], 'id')
            : warehouse.data.warehouses,
          customers: singleCustomer
            ? _.uniqBy([...customer.data.customers, singleCustomer.data.customer], 'id')
            : customer.data.customers,
          companies: singleCompany
            ? _.uniqBy([...company.data.companies, singleCompany.data.company], 'id')
            : company.data.companies,
        });
        setCountries(country.data.countries);

        const { data } = await Api.generateReorderNumber();

        if (!_.isEmpty(draftReorder)) {
          const selected = { ...draftReorder, number: singleReorder?.number || draftReorder.number || data?.number };

          delete selected.selectedProducts;

          setReorderData(selected);
          setSelectedProducts(draftReorder.selectedProducts);
        } else {
          setReorderData((prev) => ({ ...prev, number: singleReorder?.number || data?.number }));
        }
      }

      dispatch(saveDraftReorderProduct({}));
      firstLoad.current = true;
    })();
  }, []);

  useEffect(() => {
    if (searchValue) {
      searchTimeout.current = setTimeout(async () => {
        const { data } = await Api.getProducts({ s: searchValue, type: 'parent,simple', includes: 'variant' });

        setSearchResult(data.products);

        setSearchLoading(false);
      }, 500);
    } else {
      setSearchLoading(false);
    }
  }, [searchValue]);

  const selectFields = [
    {
      path: 'warehouseId',
      label: 'Warehouse',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: selectOptions.warehouses,
      getFullOption: true,
      getOptionLabel: (opt) => (opt.title),
      dataTestId: 'warehouse_select',
    },
    {
      path: 'vendorId',
      label: 'Vendor',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: selectOptions.customers,
      getFullOption: true,
      getOptionLabel: (opt) => (`${opt.firstName} ${opt.lastName}`),
      dataTestId: 'vendor_select',
    },
    {
      path: 'companyId',
      label: 'Company',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: selectOptions.companies,
      getFullOption: true,
      getOptionLabel: (opt) => (opt.name),
      createFields: [
        {
          title: 'Name',
          path: 'name',
          required: true,
          isSelectable: true,
        },
        {
          title: 'Type',
          path: 'type',
          type: 'select',
          required: true,
          options: [{
            value: 'sale',
            label: 'Sale',
          }, {
            value: 'buy',
            label: 'Buy',
          }],
        },
        {
          title: 'Company',
          path: 'company',
          type: 'select',
          options: selectOptions.companies,
          labelPath: 'name',
          valuePath: 'id',
          required: true,
          menuPortalTarget: true,
        },
        {
          title: 'Count',
          path: 'productsCount',
        },
        {
          title: 'Currency',
          path: 'currencyCode',
          type: 'select',
          options: currencies,
          required: true,
          valuePath: 'code',
          labelPath: 'code',
          menuPortalTarget: true,
          isSearchable: true,
        },
      ],
      loadMoreOptions: (inputValue, callback) => loadMoreData('companies', inputValue, callback),
      dataTestId: 'company_select',
    },
    {
      path: 'currencyCode',
      label: 'Currency',
      options: currencies,
      valuePath: 'code',
      isAsync: false,
      isSearchable: true,
      getOptionLabel: (opt) => (opt.code),
      dataTestId: 'currency_select',
    },
    {
      path: 'priceListId',
      label: 'Price list',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: selectOptions.priceLists,
      getFullOption: true,
      getOptionLabel: (opt) => (opt.name),
      createFields: [
        {
          title: 'Name',
          path: 'name',
          required: true,
          isSelectable: true,
        },
        {
          title: 'Type',
          path: 'type',
          type: 'select',
          required: true,
          options: [{
            value: 'sale',
            label: 'Sale',
          }, {
            value: 'buy',
            label: 'Buy',
          }],
        },
        {
          title: 'Company',
          path: 'company',
          type: 'select',
          options: selectOptions.companies,
          labelPath: 'name',
          valuePath: 'id',
          required: true,
          menuPortalTarget: true,
        },
        {
          title: 'Count',
          path: 'productsCount',
        },
        {
          title: 'Currency',
          path: 'currencyCode',
          type: 'select',
          options: currencies,
          required: true,
          valuePath: 'code',
          labelPath: 'code',
          menuPortalTarget: true,
          isSearchable: true,
        },
      ],
      loadMoreOptions: (inputValue, callback) => loadMoreData('priceLists', inputValue, callback),
      dataTestId: 'price_select',
    },
  ];

  const loadMoreData = useCallback(async (path, inputValue, callback) => {
    const requestPath = path === 'warehouseId' ? 'warehouses'
      : path === 'vendorId' ? 'customers' : path === 'companyId' ? 'companies' : 'priceLists';

    const requests = {
      warehouses: Api.getWarehouses,
      customers: Api.getCustomers,
      companies: Api.getCompanies,
      priceLists: Api.getPriceLists,
    };

    const requestData = { s: inputValue };
    if (requestPath === 'customers') requestData.isVendor = true;

    const { data } = await requests[requestPath](requestData);

    callback(data[requestPath]);
  }, []);

  const onCreateSelectButtonClick = useCallback(async (path) => {
    if (path === 'priceListId') {
      setOpenedModal(() => selectFields.find((field) => field.path === path));
    } else if (path === 'companyId') {
      setCompanyData(companyDefaultData);
    } else if (path === 'warehouseId') {
      dispatch(saveDraftReorderProduct({ ...reorderData, selectedProducts }));

      navigate(`/stocks/warehouses/add?backUrl=${location.pathname}${location.search}`);
    } else if (path === 'vendorId') {
      dispatch(saveDraftReorderProduct({ ...reorderData, selectedProducts }));

      navigate(`/crm/customers/create?isVendor=1&back=${location.pathname}${location.search}`);
    }
  }, [selectFields]);

  const onCreatePrice = useCallback(async (newData) => {
    const sendingData = {
      name: newData.name,
      type: newData.type,
      companyId: newData.company,
      productsCount: newData.productsCount,
      currencyCode: newData.currencyCode,
      isDefault: newData.isDefault || false,
    };

    const newPrice = await Api.createPrice(sendingData);

    const { data } = await Api.getPriceLists();

    setSelectOptions((prev) => ({
      ...prev,
      priceLists: data.priceLists,
    }));

    setReorderData((prev) => ({
      ...prev,
      priceListId: newPrice.data.priceList,
    }));

    toast.success('Price has been successfully created');
    setOpenedModal(null);
  }, []);

  const saveCompany = useCallback(async (newCompany) => {
    const { data } = await Api.getCompanies();

    setSelectOptions((prev) => ({
      ...prev,
      companies: data.companies,
    }));

    setReorderData((prev) => ({
      ...prev,
      companyId: data.companies.find((c) => c.id === newCompany.id),
    }));

    setCompanyData({});
  }, []);

  const visibleSearchResult = useCallback((res) => {
    const src = res.images?.[0]?.src ? res.images?.[0]?.src : res.images?.[0];

    return (
      <div className="search_result_single_returned">
        <img src={src || DefaultImage} alt="img" className="search_result_single_img" />

        <p>{res?.title || 'a'}</p>
      </div>
    );
  }, []);

  const resetSearch = useCallback(() => {
    setSearchOpen(false);
    setSearchResult([]);
    setSearchValue('');
  }, [searchValue, searchResult, isSearchOpen]);

  const changeSearchValue = (val) => {
    setSearchLoading(true);

    if (val) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
      setSearchResult([]);
    }

    clearTimeout(searchTimeout.current);
    setSearchValue(val);
  };

  useClickAway(searchRef, () => {
    setSearchOpen(false);
  });

  const onSearchOrViewClick = useCallback((product) => {
    let newProduct = {
      ...product,
      variants: product?.variants?.length ? product.variants.map((v) => (
        {
          ...v,
          minStock: '',
          reorderQty: '',
          price: '',
          reorderId: _.uniqueId('reorder'),
        }
      )) : [],
    };

    if (!newProduct?.variants?.length) {
      newProduct = {
        ...newProduct,
        minStock: '',
        reorderQty: '',
        price: '',
        reorderId: _.uniqueId('reorder'),
      };
    }

    setSelectedProducts((prev) => [...prev, newProduct]);

    resetSearch();
  }, []);

  const onReorderDataChange = useCallback((value, path) => {
    setReorderData((prev) => ({
      ...prev,
      [path]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, []);

  const onReorderProductChange = useCallback((value, path, productId, variantId = '') => {
    setSelectedProducts((prev) => prev.map((p) => {
      if (!variantId) {
        if (+p.id === +productId) {
          return {
            ...p,
            [path]: value,
          };
        }

        return p;
      }

      return {
        ...p,
        variants: p.variants.map((v) => (+v.id === +variantId ? { ...v, [path]: value } : v)),
      };
    }));

    setErrors((prev) => {
      if (variantId) {
        return {
          ...prev,
          [productId]: {
            ...prev?.[productId],
            [variantId]: {
              ...prev?.[productId]?.[variantId],
              [path]: '',
            },
          },
        };
      }

      return {
        ...prev,
        [productId]: {
          ...prev[productId],
          [path]: '',
        },
      };
    });
  }, []);

  const onDeleteProduct = useCallback((productId, errorReorderIds) => {
    setSelectedProducts((prev) => prev.filter((p) => +p.id !== +productId));

    setErrors((prev) => {
      if (prev.reorderProducts) {
        let newReorderProductsError;

        if (Array.isArray(errorReorderIds)) {
          newReorderProductsError = [...prev.reorderProducts];

          errorReorderIds.forEach((errId) => {
            newReorderProductsError = newReorderProductsError.filter((err) => err.reorderId !== errId);
          });
        } else {
          newReorderProductsError = prev.reorderProducts.filter((v) => v.reorderId !== errorReorderIds);
        }

        return {
          ...prev,
          reorderProducts: newReorderProductsError,
        };
      }

      return prev;
    });
  }, []);

  const onSaveReorder = useCallback(async () => {
    let newError = {};

    if (!selectedProducts.length) {
      toast.error('You must choose at least 1 product to create reorder rule');
      return;
    }

    selectedProducts.forEach((p) => {
      const paths = ['minStock', 'reorderQty', 'price'];

      if (p.variants.length) {
        p.variants.forEach((v) => {
          if (paths.some((path) => v[path].toString().trim())) {
            paths.forEach((path) => {
              if (!v[path].toString().trim()) {
                newError = {
                  ...newError,
                  [p.id]: {
                    ...newError?.[p.id],
                    [v.id]: {
                      ...newError?.[p.id]?.[v.id],
                      [path]: 'Field is required',
                    },
                  },
                };
              }
            });
          }
        });
      } else if (paths.some((path) => p[path].toString().trim())) {
        paths.forEach((path) => {
          if (!p[path].toString().trim()) {
            newError = { ...newError, [p.id]: { ...newError?.[p.id], [path]: 'Field is required' } };
          }
        });
      }
    });

    setErrors(newError);
    let sendingProducts;

    if (_.isEmpty(newError)) {
      const formatedProducts = selectedProducts.flatMap((p) => (p.variants.length ? p.variants : p));

      try {
        sendingProducts = formatedProducts.map((p) => ({
          productId: +p.id,
          minStock: +p.minStock,
          reorderQty: +p.reorderQty,
          price: +p.price,
        }));

        const sendingData = {
          title: reorderData.title,
          warehouseId: reorderData.warehouseId.id,
          vendorId: reorderData.vendorId.id,
          companyId: reorderData.companyId.id,
          currencyCode: reorderData.currencyCode,
          priceListId: reorderData.priceListId.id,
          number: reorderData.number,
          action: 'notify',
          reorderProducts: sendingProducts,
        };

        if (reorderId === 'add') {
          await Api.createReorder(sendingData);
        } else {
          await Api.updateReorder(reorderId, sendingData);
        }

        toast.success(`Successfully ${reorderId ? 'updated' : 'created'} reorder rule`);

        if (searchParams.get('back')) {
          navigate(searchParams.get('back'));
        } else {
          navigate('/stocks/reorders');
        }
      } catch (e) {
        const err = e.response.data.errors;

        let newErrors = {};

        if (err.reorderProducts) {
          newErrors.reorderProducts = err.reorderProducts.map((error, i) => ({
            ...error,
            reorderId: formatedProducts[i].reorderId,
          }));
        } else {
          newErrors = {
            title: err.title,
            warehouseId: err.warehouseId,
            vendorId: err.vendorId,
            companyId: err.companyId,
            currencyCode: err.currencyCode,
            priceListId: err.priceListId,
          };
        }

        setErrors((prev) => ({
          ...prev,
          ...newErrors,
        }));

        toast.error(e.response.data.message);
      }
    }
  }, [selectedProducts, reorderData]);

  return (
    <Wrapper
      title={`${reorderId === 'add' ? 'Create' : 'Update'} reorder rule`}
      onBtnClick={onSaveReorder}
      buttonTitle={reorderId === 'add' ? 'Create' : 'Update'}
      onCancelBtnClick={() => navigate(-1)}
    >
      {!firstLoad.current ? <Loader /> : (
        <div className="reorder_create">
          <div className="reorder_top">
            <div className="reorder_select_single">
              <Input
                key="title"
                value={reorderData.title}
                label="Title"
                onChangeText={(value) => onReorderDataChange(value, 'title')}
                size="small"
                roundBorder
                error={errors?.title}
              />
            </div>

            <div className="reorder_select_single">
              <Input
                key="number"
                value={reorderData.number}
                label="Reorder number"
                onChangeText={(value) => onReorderDataChange(value, 'number')}
                size="small"
                roundBorder
                error={errors?.number}
              />
            </div>

            {selectFields.map(({
              path,
              label,
              valuePath,
              isAsync,
              defaultOptions,
              options,
              getFullOption,
              getOptionLabel,
              dataTestId,
            }, index) => (
              <div className="reorder_select_single" style={{ zIndex: zIndexForSelect - index }} key={path}>
                <Select
                  value={reorderData[path]}
                  options={options}
                  label={label}
                  valuePath={valuePath}
                  isAsync={isAsync}
                  defaultOptions={defaultOptions}
                  size="small"
                  roundBorder
                  loadOptions={(inputValue, callback) => loadMoreData(path, inputValue, callback)}
                  onChange={(value) => onReorderDataChange(value, path)}
                  getFullOption={getFullOption}
                  getOptionLabel={getOptionLabel}
                  key={path}
                  error={errors?.[path]}
                  isSearchable={!isAsync}
                  dataTestId={dataTestId}
                />

                {path !== 'currencyCode' && (
                <Button
                  onClick={() => onCreateSelectButtonClick(path)}
                  size="small"
                  roundBorder
                  btnType="transparent"
                  color="#1472FF"
                  addBtn
                  title={`Add ${path === 'priceListId' ? 'price' : label.toLocaleLowerCase()}`}
                />
                )}
              </div>
            ))}

            <div className="reorder_select_single">
              <Checkbox
                onChange={(checked) => onReorderDataChange(checked ? 'purchase' : 'draft_purchase', 'action')}
                label="Automatically create a purchase order without confirmation"
                checked={reorderData.action === 'purchase'}
                className="reorder_checkbox_single"
              />
            </div>
          </div>

          <div className="reorder_bottom">
            <div className="create_product_bundles_block">
              <div className="create_product_bundles_search" ref={searchRef}>
                <div className="create_product_bundles_search_top">
                  <label className="global_search_field_input">
                    <input
                      type="text"
                      placeholder="Search products..."
                      value={searchValue}
                      onChange={({ target }) => changeSearchValue(target.value)}
                    />

                    {!searchLoading ? <SearchIcon /> : <Loader size={18} className="search_loader" />}
                  </label>

                  {isSearchOpen && (
                    <div
                      className="global_search_results_wrp"
                      style={{ top: (searchLoading || !searchResult.length) ? '-50px' : '-210px' }}
                    >
                      {searchLoading
                        ? <p style={{ textAlign: 'center' }}>Searching...</p>
                        : searchResult.length ? searchResult.map((r) => (
                          <div
                            className={`search_result_single 
                              ${selectedProducts.find((p) => +p.id === +r.id) ? 'disable' : ''}`}
                            onClick={() => !selectedProducts.find((p) => +p.id === +r.id)
                                && onSearchOrViewClick(r)}
                            key={r.id}
                          >
                            <SearchIcon />

                            {visibleSearchResult(r)}
                          </div>
                        ))
                          : <p style={{ textAlign: 'center' }}>No result</p>}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!!selectedProducts?.length && (
            <div className="mobile__overflow">
              <div className="reorder_bottom_header">
                <div />
                <p>Minimum stock</p>
                <p>Reorder qty</p>
                <p>Cost price</p>
              </div>

              {selectedProducts.map((p) => (
                <div className="reorder_single_prod" key={p.id}>
                  <div className="reorder_single_prod_simple">
                    <div className="reorder_single_prod_simple_left">
                      <img
                        src={p?.images?.[0]?.src || DefaultImage}
                        alt="img"
                        className="reorder_single_prod_simple_left_img"
                      />

                      <div className="reorder_single_prod_simple_left_name_block">
                        {!!p.variants.length && (
                        <p className="reorder_single_prod_simple_left_variants">
                          {`Variants ${p.variants.length}`}
                        </p>
                        )}

                        <p className="reorder_single_prod_simple_left_name">{p.title}</p>
                      </div>

                      <p className="reorder_error_text">
                        {errors?.reorderProducts?.find((err) => err.reorderId === p.reorderId)?.productId}
                      </p>
                    </div>

                    {!p.variants.length && (
                    <div className="reorder_single_prod_right">
                      <div className="reorder_single_prod_right_input_wrp">
                        <Input
                          value={p.minStock}
                          onChangeText={(val) => onReorderProductChange(val, 'minStock', p.id)}
                          size="small"
                          placeholder="0"
                          error={errors?.[p.id]?.minStock}
                          onBeforeInput={(e) => Utils.onBeforeInput(e, 'positiveNumber')}
                        />
                      </div>

                      <div className="reorder_single_prod_right_input_wrp">
                        <Input
                          value={p.reorderQty}
                          onChangeText={(val) => onReorderProductChange(val, 'reorderQty', p.id)}
                          size="small"
                          placeholder="0"
                          error={errors?.[p.id]?.reorderQty}
                          onBeforeInput={(e) => Utils.onBeforeInput(e, 'positiveNumber')}
                        />
                      </div>

                      <div className="reorder_single_prod_right_input_wrp">
                        <Input
                          value={p.price}
                          onChangeText={(val) => onReorderProductChange(val, 'price', p.id)}
                          size="small"
                          placeholder="0"
                          error={errors?.[p.id]?.price}
                          onBeforeInput={(e) => Utils.onBeforeInput(e, 'positiveNumber')}
                        />
                      </div>
                    </div>
                    )}

                    <DeleteIcon
                      className="reorder_delete_icon"
                      onClick={() => onDeleteProduct(p.id, p.variants.length
                        ? p.variants.map((v) => v.reorderId) : p.reorderId)}
                    />
                  </div>

                  {!!p.variants.length && (
                  <div className="reorder_single_prod_variant_all">
                    {p.variants.map((v) => (
                      <div className="reorder_single_variant" key={v.id}>
                        <div className="line_for_variant" />

                        <div className="reorder_single_variant_left">
                          <img
                            src={v?.images?.[0]?.src || DefaultImage}
                            alt="img"
                            className="reorder_single_variant_left_img"
                          />

                          <p className="reorder_single_variant_left_name">{v.title}</p>
                        </div>

                        <div className="reorder_single_variant_right">
                          <div className="reorder_single_prod_right_input_wrp">
                            <Input
                                    value={v?.minStock}
                                    onChangeText={(val) => onReorderProductChange(val, 'minStock', p.id, v.id)}
                                    size="small"
                                    placeholder="0"
                                    error={errors?.[p.id]?.[v.id]?.minStock}
                                    onBeforeInput={(e) => Utils.onBeforeInput(e, 'positiveNumber')}
                                  />
                          </div>

                          <div className="reorder_single_prod_right_input_wrp">
                            <Input
                                    value={v?.reorderQty}
                                    onChangeText={(val) => onReorderProductChange(val, 'reorderQty', p.id, v.id)}
                                    size="small"
                                    placeholder="0"
                                    error={errors?.[p.id]?.[v.id]?.reorderQty}
                                    onBeforeInput={(e) => Utils.onBeforeInput(e, 'positiveNumber')}
                                  />
                          </div>

                          <div className="reorder_single_prod_right_input_wrp">
                            <Input
                                    value={v?.price}
                                    onChangeText={(val) => onReorderProductChange(val, 'price', p.id, v.id)}
                                    size="small"
                                    placeholder="0"
                                    error={errors?.[p.id]?.[v.id]?.price}
                                    onBeforeInput={(e) => Utils.onBeforeInput(e, 'positiveNumber')}
                                  />
                          </div>
                        </div>

                        <p className="reorder_error_text">
                          {errors?.reorderProducts?.find((err) => err.reorderId === v.reorderId)?.productId}
                        </p>
                      </div>
                    ))}
                  </div>
                  )}
                </div>
              ))}
            </div>
            )}

          </div>

          {openedModal?.path === 'priceListId' && (
          <UpdateModal
            fields={openedModal.createFields}
            isOpen={openedModal.path === 'priceListId'}
            onSave={onCreatePrice}
            text="price"
            onClose={() => setOpenedModal(null)}
            withDefaultSwitcher
            loadMoreOptions={openedModal.loadMoreOptions}
          />
          )}

          <CreateCompanyModal
            isOpen={!_.isEmpty(companyData)}
            data={companyData}
            countries={countries}
            onClose={() => setCompanyData({})}
            onSave={saveCompany}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default CreateReorder;
