import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { format, unformat } from 'currency-formatter';
import { toast } from 'react-toastify';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Datepicker from '../../../_common/Form/Datepicker';
import Select from '../../../_common/Form/Select';
import { calculateOrderPriceRequest } from '../../../../store/actions/orders';
import { getSettingsRequest } from '../../../../store/actions/settings';
import noImg from '../../../../assets/icons/no_img.svg';
import { getProductsRequest } from '../../../../store/actions/products';
import {
  createInvoiceRequest,
  generateInvoiceNumberRequest,
  getOrderInvoicesRequest,
} from '../../../../store/actions/invoices';

function InvoiceForm() {
  const [form, setForm] = useState({
    sourceCreatedAt: moment().format('YYYY-MM-DD'),
    paymentTerm: {},
    orderInvoiceProducts: [],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState({});

  const dispatch = useDispatch();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const order = useSelector((state) => state.orders.order);
  const { paymentTerms } = useSelector((state) => state.settings.generalList);
  const invoicedCount = useSelector((state) => state.invoices.invoicedCount);

  useEffect(() => {
    (async () => {
      dispatch(getSettingsRequest(['paymentTerms']));
      const { payload } = await dispatch(generateInvoiceNumberRequest(
        { prefix: order.type === 'purchase' ? 'BL-' : undefined },
      ));
      handleChange('number', payload.data.number);
    })();
  }, []);

  useEffect(() => {
    handleChange('shippingPrice', format(order.totalShippingPrice, { code: order.currencyCode }));
  }, [order]);

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(order)) {
        const d = {
          orderProducts: order.orderProducts?.map((p) => ({
            price: p.price || 0,
            discountPercentage: p.discountPercentage || 0,
            qty: p.qty || 1,
            taxPercentage: p.taxPercentage || 0,
          })),
          taxType: order.taxType,
          totalShippingPrice: unformat(form.shippingPrice, {}),
          landedCosts: order.landedCosts ? order.landedCosts.map((l) => ({
            title: l.title,
            price: l.price,
            splitMethod: l.splitMethod,
            taxPercentage: l.taxPercentage,
            id: l.id,
          })) : undefined,
        };
        const { payload } = await dispatch(calculateOrderPriceRequest(d));
        if (payload) {
          setTotal(payload.data);
        }
      }
    })();
  }, [order, unformat(form.shippingPrice, {})]);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getProductsRequest(
        { ids: order.orderProducts?.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [order]);

  useEffect(() => {
    if (_.isEmpty(order.orderProducts)) {
      return;
    }
    const orderInvoiceProducts = order.orderProducts.map((p) => ({
      orderProductId: p.id,
      qty: p.qty - invoicedCount[p.id] >= 0 ? p.qty - invoicedCount[p.id] : p.qty,
    }));
    handleChange('orderInvoiceProducts', orderInvoiceProducts);
  }, [invoicedCount, order.orderProducts]);

  useEffect(() => {
    const termId = order.paymentTerm?.id ? order.paymentTerm.id : paymentTerms?.find((p) => p.isDefault)?.id;
    const term = paymentTerms?.find((p) => p.id === termId) || {};
    const dueDate = getDueDate(term);
    handleChange('paymentTerm.id', termId);
    handleChange('dueDate', dueDate);
  }, [order, paymentTerms]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(createInvoiceRequest({
      number: form.number,
      orderInvoiceProducts: form.orderInvoiceProducts,
      paymentTerm: form.paymentTerm,
      shippingPrice: unformat(form.shippingPrice, {}),
      orderId,
    }));
    if (data.status === 'error' && !_.isEmpty(data.errors)) {
      setErrors(data.errors);
    } else if (data.status === 'error' && _.isEmpty(data.errors)) {
      toast.error(data.message);
    } else {
      await dispatch(getOrderInvoicesRequest(orderId));
      toast.success(`${order.type === 'purchase' ? 'Bill' : 'Invoice'} successfully created.`);
      navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
    }
    setLoading(false);
  }, [form, orderId, order]);

  const getDueDate = useCallback((term, date) => (term.startFrom === 'invoice_date'
    ? moment(date).add(term.dueInDays, 'days').format('YYYY-MM-DD')
    : moment().endOf('month').add(term.dueInDays, 'days').format('YYYY-MM-DD')), []);

  return (
    <div className="invoice_form package_form">
      <form onSubmit={handleSubmit}>
        <div className="top">
          <p className="title">{`New ${order.type === 'purchase' ? 'bill' : 'invoice'}`}</p>
          <div className="actions">
            <Button
              btnType="cancel"
              roundBorder
              onClick={() => navigate(`${location.pathname}${location.search}`, { state: {}, replace: true })}
            >
              Cancel
            </Button>
            <Button roundBorder type="submit" loading={loading}>
              {`Create ${order.type === 'purchase' ? 'bill' : 'invoice'} `}
            </Button>
          </div>
        </div>
        <div className="form">
          <div className="row">
            <Input
              label="Customer"
              roundBorder
              value={`${order.customer?.firstName || '-'} ${order.customer?.lastName || '-'}`}
              readOnly
            />
            <Input
              label={order.type === 'purchase' ? 'Bill#' : 'Invoice#'}
              roundBorder
              value={form.number}
              onChange={(ev) => handleChange('number', ev.target.value)}
            />
            <Input
              label="Order Number"
              roundBorder
              value={order.number}
              readOnly
            />

          </div>
          <div className="row">
            <div className="small">
              <Datepicker
                label={`${order.type === 'purchase' ? 'Bill' : 'Invoice'} Date`}
                onChange={(date) => {
                  const dueDate = getDueDate(paymentTerms.find((p) => p.id === form.paymentTerm.id), date);

                  handleChange('sourceCreatedAt', moment(date).format('YYYY-MM-DD'));
                  handleChange('dueDate', dueDate);
                }}
                value={form.sourceCreatedAt ? moment(form.sourceCreatedAt).toDate() : undefined}
              />
              <Datepicker
                label="Due date"
                value={form.dueDate ? moment(form.dueDate).format('MM.DD.YYYY') : undefined}
                disabled
              />
            </div>
            <Select
              label="Terms"
              options={paymentTerms}
              roundBorder
              labelPath="title"
              valuePath="id"
              getFullOption
              onChange={(val) => {
                const dueDate = getDueDate(val);
                handleChange('paymentTerm.id', val.id);
                handleChange('dueDate', dueDate);
              }}
              value={form.paymentTerm?.id}
              error={errors.paymentTerm?.id}
            />
          </div>
        </div>

        <div className="preview_products_list">
          <div className="products_list table_wrapper">
            <table className="table">
              <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Product</th>
                <th className="table_thead_tr_th">Ordered</th>
                <th className="table_thead_tr_th">{order.type === 'purchase' ? 'Billed' : 'Invoiced'}</th>
                <th className="table_thead_tr_th">Rate</th>
                <th className="table_thead_tr_th">Discount</th>
                <th className="table_thead_tr_th">Tax</th>
                <th className="table_thead_tr_th">Landed cost</th>
                <th className="table_thead_tr_th">Qty</th>
                <th className="table_thead_tr_th">Amount</th>
                <th />
              </tr>
              </thead>
              <tbody className="table_tbody">
              {order?.orderProducts?.map((p, i) => {
                const product = products?.find((f) => f.id === p.productId) || {};
                return (
                  <tr
                    className="table_tbody_tr"
                    key={p.id}
                    data-product-id={p.id}
                  >
                    <td className="table_tbody_tr_td">
                      <div className="info" style={{ alignItems: 'center' }}>
                        {p.type !== 'custom' ? (

                          <img
                            style={{ height: 30, width: 30 }}
                            src={product.images?.[0]?.src
                              || product.images?.[0]?.medium
                              || noImg}
                            alt=""
                          />
                        ) : null}
                        {p.type !== 'custom'
                          ? (
                            <Link
                              to={product.type === 'variant'
                                ? `/products/${product.parentId}?productVariantId=${product.id}`
                                : `/products/${product.id}`}
                              target="_blank"
                            >
                              {p.title}
                            </Link>
                          )
                          : <span>{p.title}</span>}
                      </div>
                    </td>
                    <td className="table_tbody_tr_td ">
                      {p.qty}
                    </td>
                    <td className="table_tbody_tr_td">
                      {invoicedCount[p.productId] || 0}
                    </td>
                    <td className="table_tbody_tr_td">{format(p.price, { code: order.currencyCode })}</td>
                    <td
                      className="table_tbody_tr_td "
                    >
                      {`${p.discountPercentage || 0}%`}
                    </td>

                    <td className="table_tbody_tr_td ">
                      {`${p.taxPercentage || 0}%`}
                    </td>
                    <td className="table_tbody_tr_td ">
                      {format(p.landedCost, { code: order.currencyCode })}
                    </td>
                    <td className="table_tbody_tr_td input">
                      <Input
                        onChange={(ev) => handleChange(`orderInvoiceProducts[${i}]`, {
                          orderProductId: p.id,
                          qty: ev.target.value,
                        })}
                        value={form.orderInvoiceProducts[i]?.qty}
                        type="number"
                        readOnly={p.qty - invoicedCount[p.id] <= 0}
                        max={p.qty - (invoicedCount[p.id] || 0)}
                        error={_.get(errors, `orderInvoiceProducts[${i}].qty`, '')}
                      />
                    </td>
                    <td className="table_tbody_tr_td">{format(p.totalPrice, { code: order.currencyCode })}</td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
          <div className="total_container">
            <div>
              <Input
                textarea
                label="Customer notes"
                placeholder="Enter any notes to be displayed in your transaction"
                onChange={(ev) => handleChange('note', ev.target.value)}
                value={form.note}
              />
            </div>
            <div className="total">
              <div className="item">
                <p>Total Items</p>
                <p>{order?.orderProducts?.length || 0}</p>
              </div>
              <div className="item">
                <p>Total Units</p>
                <p>{_.sumBy(order.orderProducts, 'qty')}</p>
              </div>
              <div className="item">
                <p>Tax</p>
                <p>{format(order.totalTax, { code: order.currencyCode })}</p>
              </div>
              {order.type === 'purchase' ? (
                <div className="item">
                  <p>Landed loas</p>
                  <p>{format(order.totalLandedCosts, { code: order.currencyCode })}</p>
                </div>
              ) : null}
              <div className="item shipping_charges">
                <p>Shipping charges</p>
                <Input
                  roundBorder
                  onBlur={() => handleChange(
                    'shippingPrice',
                    format(form.shippingPrice, { code: order.currencyCode }),
                  )}
                  onFocus={() => handleChange(
                    'shippingPrice',
                    unformat(form.shippingPrice, { code: order.currencyCode }),
                  )}
                  onChange={(ev) => handleChange('shippingPrice', ev.target.value)}
                  value={form.shippingPrice}
                />
              </div>
              <div className="item">
                <p className="subtotal_value">Subtotal</p>
                <p>{format(total?.subtotalPrice, { code: order.currencyCode })}</p>
              </div>
              <div className="item">
                <p className="total_value">Total</p>
                <p>{format(total?.totalPrice, { code: order.currencyCode })}</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default InvoiceForm;
