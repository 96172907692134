import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';

const ShortMenu = ({ menu, selectMenu }) => {
  const { pathname } = useLocation();

  const ref = useRef(null);
  const [isOpen, openingToggle] = useState(false);

  const [openSubMenu, setOpeningSubMenu] = useState({});

  useClickAway(ref, () => {
    openingToggle(false);
  });

  const selectSubMenu = (path) => {
    selectMenu(path);
    openingToggle(false);
  };

  useEffect(() => {
    if (!isOpen && openSubMenu.label) {
      setOpeningSubMenu({});
    }
  }, [isOpen]);

  return (
    <div ref={ref} className="short_menu">
      <div
        onClick={() => openingToggle((prev) => !prev)}
        className={classNames('short_menu_title', { open: isOpen })}
      >
        <MenuIcon />

        Menu
      </div>

      {isOpen && (
        <>
          <div className="short_menu_options">
            {menu.map(({
              label, icon, rootPath, subMenu,
            }) => (
              <div
                key={label}
                onClick={() => setOpeningSubMenu({ label, menu: subMenu })}
                className={classNames('short_menu_option_wrapper', {
                  active: pathname.includes(rootPath),
                  open: openSubMenu.label === label,
                })}
              >
                <div className="short_menu_option">
                  <div>
                    {icon}

                    {label}
                  </div>

                  <ArrowIcon className="short_menu_option_arrow" />
                </div>
              </div>
            ))}

            <NavLink to="/settings" className="short_menu_settings_wrapper" onClick={() => openingToggle(false)}>
              <SettingsIcon />

              Settings
            </NavLink>
          </div>

          {openSubMenu.label && (
            <div className="short_menu_sub_menu">
              {openSubMenu.menu.map((s) => (
                <div
                  key={s.path}
                  onClick={() => selectSubMenu(s.path)}
                  className={classNames('short_menu_sub_menu_option', { active: s.path === pathname })}
                >
                  {s.label}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

ShortMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string,
    icon: PropTypes.element,
    rootPath: PropTypes.string,
    subMenu: PropTypes.array,
    isAdminRoute: PropTypes.bool,
  })).isRequired,
  selectMenu: PropTypes.func.isRequired,
};

export default ShortMenu;
