import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as BonanzaIcon } from '../../../../assets/icons/apps/bonanza.svg';
import BonanzaImg1 from '../../../../assets/images/apps/bonanza/bonanza_1.png';
import BonanzaImg2 from '../../../../assets/images/apps/bonanza/bonanza_2.png';
import BonanzaImg3 from '../../../../assets/images/apps/bonanza/bonanza_3.png';
import BonanzaImg4 from '../../../../assets/images/apps/bonanza/bonanza_4.png';
import BonanzaImg5 from '../../../../assets/images/apps/bonanza/bonanza_5.png';
import BonanzaImg6 from '../../../../assets/images/apps/bonanza/bonanza_6.png';
import BonanzaImg7 from '../../../../assets/images/apps/bonanza/bonanza_7.png';
import BonanzaImg8 from '../../../../assets/images/apps/bonanza/bonanza_8.png';
import BonanzaImg9 from '../../../../assets/images/apps/bonanza/bonanza_9.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';

const fields = [
  {
    title: 'Your app name',
    placeholder: 'Enter your app name',
    path: 'integrationName',
    required: true,
  },
];

function BonanzaInstall() {
  const { app } = useParams();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const {
        data: {
          params: { authenticationURL, hardExpirationTime, authToken },
        },
      } = await Api.installApp(
        app,
        {
          integrationName: data.integrationName,
          redirectUrl: `${window.location.origin}/integration-verify/${app}`,
        },
      );

      localStorage.setItem(`${app}IntegrationInfo`, JSON.stringify({
        integrationName: data.integrationName,
        authenticationURL,
        hardExpirationTime,
        authToken,
      }));

      window.location.href = authenticationURL;
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Bonanza"
      shopIcon={<BonanzaIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and"
        + ' process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p className="title">
        Installation Instructions for Connecting Bonanza to eSwap:
      </p>

      <ul>
        <li>
          {' '}
          Navigate to the Apps section in eSwap, particularly under &quot;Marketplaces,&quot; and select
          &quot;Bonanza.&quot;
        </li>
        <li>Click on &quot;Install app.&quot;</li>
      </ul>

      <p>
        <img
          src={BonanzaImg1}
          alt="info image"
        />
      </p>

      <p>
        Click on &quot;Install app.&quot;
      </p>

      <p>
        <img
          src={BonanzaImg2}
          alt="info image"
        />
      </p>

      <ul>
        <li> Enter a desired name for your Bonanza shop.</li>
        <li> Click &quot;Install app&quot; to proceed.</li>
      </ul>

      <p>
        <img
          src={BonanzaImg3}
          alt="info image"
        />
      </p>

      <ul>
        <li>You will be redirected to the Bonanza website.</li>
        <li>Fill in your username and password to log in to your Bonanza account.</li>
      </ul>

      <p>
        <img
          src={BonanzaImg4}
          alt="info image"
        />
      </p>

      <ul>
        <li>After logging in, Bonanza will congratulate you on the successful sign-up.</li>
        <li>Click &quot;Return to eSwap Global Inc.&quot; to continue the integration process.</li>
      </ul>

      <p>
        <img
          src={BonanzaImg5}
          alt="info image"
        />
      </p>

      <p>
        Upon installation, follow these configuration steps:
      </p>

      <p>
        <img
          src={BonanzaImg6}
          alt="info image"
        />
      </p>

      <ul>
        <li>Upon installation, follow these configuration steps:</li>
        <li>
          Choose a warehouse location from existing options or create a new one if
          necessary.
        </li>
        <li>Sync your price list with Bonanza or create a new one as required.</li>
        <li>
          Configure Bonanza order statuses to align with existing lines or create new ones
          if necessary.
        </li>
        <li>Decide whether to import data immediately or at a later time.</li>
        <li>The import process will begin based on your selections.</li>
      </ul>

      <p>
        <img
          src={BonanzaImg7}
          alt="info image"
        />
      </p>

      <p>
        <img
          src={BonanzaImg8}
          alt="info image"
        />
      </p>

      <ul>
        <li>Once the configuration steps are completed, click &quot;Done Installation&quot; to finalize the</li>
        <li>The Bonanza app will now be accessible from your apps page within eSwap.</li>
      </ul>

      <p>
        <img
          src={BonanzaImg9}
          alt="info image"
        />
      </p>

      <p>
        By following these steps, you will successfully connect your Bonanza account to eSwap, enabling
        seamless data synchronization and management between the two platforms.
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={fields}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default BonanzaInstall;
