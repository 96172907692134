import React, { useCallback, useEffect, useState } from 'react';
import {
  Outlet, useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import bwipjs from 'bwip-js';
import CreateProductVariant from './CreateProductVariant';
import Loader from '../../_common/Loader/Loader';
import Button from '../../_common/Form/Button';
import Input from '../../_common/Form/Input';
import ProductImages from './CreateComponents/ProductImages';
import ProductPrices from './CreateComponents/ProductPrices';
import Api from '../../../Api';
import ProductDimensions from './CreateComponents/ProductDimensions';
import ProductInventory from './CreateComponents/ProductInventory';
import ProductOptions from './CreateComponents/ProductOptions';
import ProductVariants from './CreateComponents/ProductVariants';
import ProductBundles from './CreateComponents/ProductBundles';
import ProductVendor from './CreateComponents/ProductVendor';
import ProductTags from './CreateComponents/ProductTags';
import ProductBarcode from './CreateComponents/ProductBarcode';
import WarningModal from '../../_common/Modal/WarningModal';
import 'react-quill/dist/quill.snow.css';
import { getSetupRequest } from '../../../store/actions/notifications';
import LinkedProducts from './CreateComponents/LinkedProducts';
import ProductBatchTracking from './CreateComponents/ProductBatchTracking';
import ProductSettings from './CreateComponents/ProductSettings';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import ProductCustomFields from './CreateComponents/ProductCustomFields';
import Utils from '../../../helpers/Utils';
// import ProductReorder from './CreateComponents/ProductReorder';

export const defaultReorders = {
  title: '',
  minStock: '',
  reorderQty: '',
  vendorId: '',
  warehouseId: '',
  companyId: '',
  currencyCode: '',
  priceListId: '',
  price: '',
  action: 'purchase', // 'notify', 'draft_purchase', 'purchase'
};
const batchIndexKey = Symbol.for('batchIndexKey');

const CreateProduct = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const dispatch = useDispatch();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { hasProducts } = useSelector((state) => state.notifications.setup);

  const [barCode, setBarcode] = useState({
    format: '',
    numbers: '',
  });
  const [barcodeModal, setBarcodeModal] = useState(false);
  const [createdBarcodeData, setCreatedBarcodeData] = useState({
    format: '',
    numbers: '',
  });

  const [variantId, setVariantId] = useState('');
  const [nextVariantIndex, setNextVariantIndex] = useState(null);
  const [isVariantDataChanged, setIsVariantDataChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [productHasOption, setProductHasOption] = useState(false);
  const [productHasBundle, setProductHasBundle] = useState(false);
  // const [productHasReorder, setProductHasReorder] = useState(false);
  const [productReorders, setProductReorders] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const [checkModal, setCheckModal] = useState('');
  const [saveVariantModal, setSaveVariantModal] = useState(false);
  const [productSelectOptions, setProductSelectOptions] = useState({});
  // const [countries, setCountries] = useState([]);
  const [selectedWarehouseForVariants, setSelectedWarehouseForVariants] = useState('0');
  const [selectedPriceForVariants, setSelectedPriceForVariants] = useState('0');
  const [errors, setErrors] = useState({});
  const [settings, setSettings] = useState('');
  const [batchNumberLoading, setButchNumberLoading] = useState(false);
  const [productData, setProductData] = useState({
    sku: '',
    title: '',
    description: '',
    shopType: 'eswap',
    barCode: '',
    barCodeType: '',
    productType: 'product',
    status: 'active',
    sourceCreatedAt: new Date(), // moment().format('MM/DD/YYYY')
    unitType: '',
    weight: '',
    weightUnit: '',
    dimensionsUnit: '',
    dimensions: [],
    images: [],
    variants: [],
    bundles: [
      // {
      //   productId: 55,
      //   quantity: 1,
      //   warehouseId: 1,
      // },
    ],
    customFields: [],
    vendorId: '',
    tags: [],
    prices: [
      // {
      //   priceListId: 3,
      //   price: 123,
      // },
    ],
    stocks: [
      // {
      //   warehouseId: 14,
      //   available: 5,
      // },
    ],
    attributes: [ // in variants
      // {
      //   name: 'col',
      //   value: 'red',
      // },
      // {
      //   name: 'size',
      //   value: 'Xl',
      // },
    ],

    stockWarehouses: [],
    priceData: [],
    // variantStockWarehouses: [],
    relations: [],
    categories: [],

    batches: [
      // {
      // batchNumber:'11111' //serialNumber
      //   "qty": 10,
      //   "expirationDateFrom": "2022-01-01",
      //   "expirationDateTo": "2022-01-02"
      // },
      // {
      //   "qty": 5,
      //   "expirationDateFrom": "2022-01-05",
      //   "expirationDateTo": "2022-01-09"
      // }
    ],
  });
  const [newBatchNumber, setNewBatchNumber] = useState('');

  const {
    priceLists, customers, categories, tags, warehouses, brands, // companies
  } = productSelectOptions;

  useEffect(() => {
    if (location.state?._title) {
      setProductData((prev) => ({
        ...prev,
        title: location.state._title,
      }));
    }
  }, [location.state]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let singleWarehouse;
      let singleCompany;
      // let singleBrand;
      let singleCustomer;
      // let singleCustomerReorder;
      let singlePrice;

      const [
        prices,
        warehouse,
        customer,
        category,
        tag,
        company,
        // country,
        brand,
        customFields,
      ] = await Promise.all([
        Api.getPriceLists(),
        Api.getWarehouses(),
        Api.getCustomers({ isVendor: true }),
        Api.getCategories({ includes: 'parents' }),
        Api.getProductsTags(),
        Api.getCompanies(),
        // Api.getCountries(),
        Api.getBrands(),
        Api.getSettings(['productCustomFields']),
      ]);

      const defaultCustomFields = customFields.data?.productCustomFields
        ? customFields.data.productCustomFields.filter((c) => c.displayInPortal)
          .map((f) => ({ ...f, value: '', name: '' })) : [];

      if (productId !== 'add') {
        let singleProduct;

        try {
          singleProduct = await Api.getSingleProduct(productId);

          const reorderProduct = await Api.getProductReorderRule(productId);

          setProductReorders(reorderProduct.data.reorders);
        } catch (e) {
          navigate('/404');
          return;
        }

        const prod = singleProduct.data.product;

        if (prod.vendorId || searchParams.get('customerId')) {
          singleCustomer = await Api.getSingleCustomer((searchParams.get('customerId') || prod.vendorId));
        }

        if (prod.variants.length) {
          changeHasOption(true);
        }

        if (prod.bundles.length) {
          setProductHasBundle(true);
        }

        if (prod.barCode) {
          setTimeout(() => {
            onGenerateBarcode({ format: prod.barCodeType, numbers: prod.barCode });
          }, 300);
        }
        //
        // if(Array.isArray(prod.categories)) {
        //   if (prod.categories.length) {}
        // }

        // if (!_.isEmpty(prod.reorder)) {
        //   try {
        //     [singleWarehouse, singleCompany, singleCustomerReorder, singlePrice] = await Promise.all([
        //       Api.getWarehouse(prod.reorder.warehouseId),
        //       Api.getSingleCompany(prod.reorder.companyId),
        //       Api.getSingleCustomer(prod.reorder.vendorId),
        //       Api.getPriceListSingle(prod.reorder.priceListId),
        //     ]);
        //   } catch (e) {
        //     console.log(e);
        //   }
        //   setProductHasReorder(true);
        // }

        const stockWarehouses = warehouse.data.warehouses.map((w) => {
          const foundStock = prod.totalStock?.stocks?.find((st) => w.id === st.warehouseId);

          return foundStock ? {
            ...w,
            stockId: foundStock.id,
            stock: foundStock.stock || '',
            available: foundStock.available || '',
            allocated: foundStock.allocated || '',
            incoming: foundStock.incoming || '',
          } : w;
        });

        const priceData = prices.data.priceLists.map((pList) => {
          const foundPrice = prod.prices.find((p) => p.priceListId === pList.id);

          return foundPrice ? {
            ...pList,
            productPriceId: foundPrice.id,
            price: foundPrice.price,
          } : pList;
        });

        const newVariants = prod.variants.map((vrt) => {
          const variantStockWarehouses = [];

          warehouse.data.warehouses.forEach((w) => {
            const foundVariantStock = vrt.stocks.find((st) => w.id === st.warehouseId);

            variantStockWarehouses.push(foundVariantStock ? {
              ...w,
              variantStockId: foundVariantStock.id,
              available: foundVariantStock.available || '',
              stock: foundVariantStock.stock || '',
              allocated: foundVariantStock.allocated || '',
              incoming: foundVariantStock.incoming || '',
              // bundleWarehouseId: w.id,
            } : w);
          });

          let allQuantity = 0;
          let allAllocated = 0;
          let allIncoming = 0;

          variantStockWarehouses.forEach((st) => {
            if (st.available) allQuantity += +st.available;
            if (st.allocated) allAllocated += +st.allocated;
            if (st.incoming) allIncoming += +st.incoming;
          });

          // const newCustomFields = [];
          //
          // vrt.customFields.forEach((f) => {
          //   const foundField = customFields.data.productCustomFields.find((c) => c.label === f.name);
          // });

          return {
            ...vrt,
            priceData: prices.data.priceLists.map((pList) => {
              const foundPrice = vrt.prices.find((p) => p.priceListId === pList.id);

              return foundPrice ? {
                ...pList,
                productPriceId: foundPrice.id,
                price: foundPrice.price,
              } : pList;
            }),
            customFields: vrt.customFields?.length
              ? vrt.customFields.map((f) => {
                const foundField = customFields.data.productCustomFields.find((c) => c.label === f.name);

                return { ...foundField, value: f.value };
              })
              : defaultCustomFields,
            variantStockWarehouses,
            quantity: allQuantity || '',
            stock: allQuantity || '',
            allocated: allAllocated || '',
            incoming: allIncoming || '',
          };
        });

        const newCustomFields = prod.customFields?.length
          ? prod.customFields.map((f) => {
            const foundField = customFields?.data?.productCustomFields?.find((c) => c.label === f.name);

            return { ...foundField, value: f.value };
          })
          : defaultCustomFields;

        const newBundles = prod.bundles.map((b) => {
          let bundleAvailable = 0;

          b.totalStock?.stocks?.forEach((bundleStock) => {
            bundleAvailable += +bundleStock.available;
          });

          return {
            ...b,
            bundleProductId: b.bundle.productId,
            bundleQuantity: b.bundle.quantity,
            bundleProductStocks: b.stocks,
            bundleAvailable,
            // b.stocks.find(
            //   (st) => st.warehouseId.toString() === b.bundle.warehouseId.toString(),
            // )?.available
          };
        });
        const batchData = !prod.variants.length
          ? prod.batches
          : prod.variants.filter((v) => v.batches.length >= 1)
            .map((b) => b.batches.map((a) => ({ ...a, variant: b.id }))).flat(1)
            .map((b, i) => ({ ...b, [batchIndexKey]: i }));

        const settingsData = batchData.length
          ? _.get(batchData, '[0].batchNumber')
            ? 'batchNumber'
            : 'serialNumber'
          : '';

        if (settingsData === 'batchNumber') {
          const { data } = await Api.generateBatchNumber();
          setNewBatchNumber(data.batchNumber);
        }

        setSettings(settingsData);

        // const newReorder = !_.isEmpty(prod.reorder) ? {
        //   ...prod.reorder,
        //   vendorId: singleCustomerReorder.data.customer,
        //   companyId: singleCompany.data.company,
        //   warehouseId: singleWarehouse.data.warehouse,
        //   priceListId: singlePrice.data.priceList,
        // } : defaultReorders;

        const settingProduct = {
          sku: prod.sku,
          title: prod.title,
          description: prod.description,
          shopType: prod.shopType,
          barCode: prod.barCode,
          barCodeType: prod.barCodeType,
          status: prod.status,
          sourceCreatedAt: prod.sourceCreatedAt || new Date(), // moment().format('MM/DD/YYYY')
          unitType: prod.unitType,
          weight: prod.weight,
          weightUnit: prod.weightUnit,
          dimensionsUnit: prod.dimensionsUnit,
          dimensions: prod.dimensions,
          images: prod.images,
          tags: prod.tags.map((t) => t.id),
          brandId: prod.brand,
          categories: prod.categories, // (Array.isArray(prod.categories)&&prod.categories.length)?prod.categories[0] :
          prices: prod.prices,
          stocks: prod.stocks,
          attributes: prod.attributes,
          vendorId: singleCustomer?.data?.customer || '',
          customFields: newCustomFields || [],
          productType: prod.productType,
          variants: newVariants.map((pr) => (
            {
              ...pr,
              disabledKeys: [],
              name: `${pr.attributes.map((atr) => atr?.value).join('/')}`,
              price: pr?.prices?.[0]?.price,
              // stock: pr?.stocks?.[0]?.stock,
              // quantity: pr?.stocks?.[0]?.stock || '', jamanakavor tox mna
              // allQuantity: pr?.stocks?.[0]?.stock || '', jamanakavor tox mna
            }
          )),
          bundles: newBundles,
          priceData,
          stockWarehouses,
          // reorder: newReorder,
          shopId: prod.shopId,
          relations: prod.relations,
          batches: batchData,
        };

        setProductData(settingProduct);

        if (searchParams.get('productVariantId')) {
          const productVariantId = searchParams.get('productVariantId');
          const foundVariant = prod.variants.find((v) => v.id.toString() === productVariantId.toString());

          if (foundVariant) setVariantId(productVariantId);

          setSearchParams({}, { replace: true });
        }
      } else {
        const defaultUnits = await Api.getSettings(['weightUnit', 'sizeUnit']);

        setProductData((prev) => ({
          ...prev,
          customFields: defaultCustomFields,
          priceData: prices.data.priceLists,
          stockWarehouses: warehouse.data.warehouses,
          weightUnit: defaultUnits.data.weightUnit,
          dimensionsUnit: defaultUnits.data.sizeUnit,
          shopType: 'eswap',
          unitType: 'pcs',
        }));
      }

      if (warehouse.data.warehouses.length === 1) {
        setSelectedWarehouseForVariants(warehouse.data.warehouses[0].id);
      }

      setSelectedPriceForVariants(prices.data.priceLists[0].id);
      setProductSelectOptions({
        categories: category.data.categories,
        tags: tag.data.tags,
        brands: brand.data.brands,
        priceLists: singlePrice
          ? _.uniqBy([...prices.data.priceLists, singlePrice.data.priceList], 'id')
          : prices.data.priceLists,
        warehouses: singleWarehouse
          ? _.uniqBy([...warehouse.data.warehouses, singleWarehouse.data.warehouse], 'id')
          : warehouse.data.warehouses,
        customers: singleCustomer
          ? _.uniqBy([...customer.data.customers, singleCustomer.data.customer], 'id')
          : customer.data.customers,
        companies: singleCompany
          ? _.uniqBy([...company.data.companies, singleCompany.data.company], 'id')
          : company.data.companies,
      });
      // setCountries(country.data.countries);

      if (searchParams.get('customerId')) {
        setSearchParams({}, { replace: false });
      }

      // setProductSelectOptions((prev) => (
      //   { ...prev, warehouses: warehouse.data.warehouses.map((w) => ({ ...w, bundleWarehouseId: w.id })) }));

      setLoading(false);
    })();
  }, [location.pathname]);

  const onDataChange = useCallback((path, value, id, tableLinePath) => {
    setErrors((err) => {
      _.remove(err, path);
      return _.cloneDeep(err);
    });

    setProductData((prev) => {
      if (path === 'priceLists') {
        return ({
          ...prev,
          priceData: prev.priceData.map((pr) => (+pr.id === +id ? { ...pr, price: value } : pr)),
        });
      }

      if (typeof path === 'number' && !variantId) {
        return ({
          ...prev,
          dimensions: prev.dimensions.length
            ? prev.dimensions.map((d, i) => (+i === +path ? value : d))
            : Array.from({ length: 3 }, (d, i) => (+i === +path ? value : '')),
        });
      }

      if (typeof path === 'number' && variantId) {
        return ({
          ...prev,
          variants: prev.variants.map((v) => {
            if (v.id.toString() === variantId.toString()) {
              return {
                ...v,
                dimensions: v.dimensions.length
                  ? v.dimensions.map((d, i) => (+i === +path ? value : d))
                  : Array.from({ length: 3 }, (d, i) => (+i === +path ? value : '')),
              };
            }

            return v;
          }),
        });
      }

      if ((path === 'dimensionsUnit' || path === 'weight' || path === 'weightUnit') && variantId) {
        return ({
          ...prev,
          variants: prev.variants.map((v) => (v.id.toString() === variantId.toString()
            ? ({ ...v, [path]: value }) : v)),
        });
      }

      if (path === 'stocks') {
        const foundStock = prev.stockWarehouses.find((st) => +st.id === +id);

        return {
          ...prev,
          stockWarehouses: foundStock
            ? prev.stockWarehouses.map((st) => (st.id.toString() === id.toString() ? { ...st, available: value } : st))
            : [...prev.stockWarehouses, { id, available: value, warehouseId: id }],
        };
      }

      if (path === 'variants') {
        return {
          ...prev,
          variants: prev.variants.map((v) => (
            v.id.toString() === id.toString()
              ? {
                ...v,
                [tableLinePath]: value,
                variantStockWarehouses: tableLinePath === 'quantity' ? v.variantStockWarehouses.map(
                  (st) => (+st.id === +selectedWarehouseForVariants ? { ...st, available: value } : st),
                ) : v.variantStockWarehouses,
                priceData: tableLinePath === 'price' ? v.priceData.map((p) => {
                  if (p.id.toString() === selectedPriceForVariants.toString()) {
                    return {
                      ...p,
                      price: value,
                    };
                  }

                  return p;
                }) : v.priceData,
              }
              : v)),
        };
      }

      if (path === 'bundles') {
        return {
          ...prev,
          bundles: prev.bundles.map((b) => (b.id.toString() === id.toString()
            ? {
              ...b,
              [tableLinePath]: value,
              // bundleAvailable: tableLinePath === 'bundleWarehouseId'
              // ? foundBundleStock?.available : b.bundleAvailable,
            } : b)),
        };
      }

      if (path === 'customField') {
        return {
          ...prev,
          customFields: prev.customFields.map((v) => (v.label === id ? { ...v, value } : v)),
        };
      }

      if (path === 'variantCustomField') {
        return {
          ...prev,
          variants: prev.variants.map((v) => {
            if (v.id.toString() === variantId.toString()) {
              return {
                ...v,
                customFields: v.customFields.map((f) => (f.label === id ? { ...f, value } : f)),
              };
            }

            return v;
          }),
        };
      }

      if (path === 'variantStocks') {
        return {
          ...prev,
          variants: prev.variants.map((v) => {
            if (v.id.toString() === variantId.toString()) {
              const foundStock = v.variantStockWarehouses.find((st) => st.id.toString() === id.toString());

              let newWarehouseStocks;

              if (foundStock) {
                newWarehouseStocks = v.variantStockWarehouses.map((st) => (st.id.toString() === id.toString()
                  ? { ...st, available: value } : st));
              } else {
                newWarehouseStocks = [...v.variantStockWarehouses, { id, available: value, warehouseId: id }];
              }

              return {
                ...v,
                variantStockWarehouses: newWarehouseStocks,
                quantity: selectedWarehouseForVariants === '0' ? v.variantStockWarehouses.reduce((result, st) => {
                  if (st.id.toString() === id.toString()) {
                    return +value + (+result);
                  }

                  if (st.available) {
                    return +st.available + (+result);
                  }

                  return result;
                }, 0)
                  : selectedWarehouseForVariants.toString() === id.toString()
                    ? value
                    : v.variantStockWarehouses.find(
                      (vt) => vt.id.toString() === selectedWarehouseForVariants,
                    ).available,
              };
            }

            return v;
          }),
        };
      }

      if (path === 'selectForVariant') {
        setSelectedWarehouseForVariants(value);

        if (value === '0') {
          return {
            ...prev,
            variants: prev.variants.map((v) => {
              let allQuantity = 0;
              let allAllocated = 0;
              let allIncoming = 0;

              v.variantStockWarehouses.forEach((st) => {
                if (st.available) allQuantity += +st.available;
                if (st.allocated) allAllocated += +st.allocated;
                if (st.incoming) allIncoming += +st.incoming;
              });

              return {
                ...v,
                quantity: allQuantity || '',
                stock: allQuantity || '',
                allocated: allAllocated || '',
                incoming: allIncoming || '',
              };
            }),
          };
        }

        return {
          ...prev,
          variants: prev.variants.map((v) => {
            const foundStocks = v.variantStockWarehouses.find((st) => st.id.toString() === value.toString());

            return {
              ...v,
              quantity: foundStocks.available || '',
              allocated: foundStocks.allocated || '',
              incoming: foundStocks.incoming || '',
              stock: foundStocks.available || '',
            };
          }),
        };
      }

      // if (path === 'reorder') {
      //   return {
      //     ...prev,
      //     reorder: {
      //       ...prev.reorder,
      //       [id]: id === 'action'
      //         ? value ? 'purchase' : 'draft'
      //         : value,
      //     },
      //   };
      // }

      if (path === 'variantSku') {
        return {
          ...prev,
          variants: prev.variants.map((v) => {
            if (v.id.toString() === variantId.toString()) {
              return {
                ...v,
                sku: value,
              };
            }

            return v;
          }),
        };
      }

      if (path === 'variantPrice') {
        return {
          ...prev,
          variants: prev.variants.map((v) => {
            if (v.id.toString() === variantId.toString()) {
              // const foundPrice = v.priceData.find((p) => p.id.toString() === id.toString());

              return {
                ...v,
                priceData: v.priceData.map((p) => (p.id.toString() === id.toString() ? { ...p, price: value } : p)),
                price: selectedPriceForVariants === id ? value : v.price,
              };
            }

            return v;
          }),
        };
      }

      if (path === 'variantImage') {
        return {
          ...prev,
          variants: prev.variants.map((v) => {
            if (v.id.toString() === variantId.toString()) {
              // const foundPrice = v.priceData.find((p) => p.id.toString() === id.toString());

              return {
                ...v,
                images: value === 'delete' ? [] : [{ src: value }],
              };
            }

            return v;
          }),
          images: prev.images.length ? value === 'delete'
            ? [...prev.images] : [...prev.images, { src: value }]
            : [{ src: value }],
        };
      }

      if (path === 'variantImageUpdate') {
        return {
          ...prev,
          variants: prev.variants.map((v) => {
            if (v.id.toString() === variantId.toString()) {
              const selectedImage = prev.images.filter((img) => {
                if (img.src?._preview) {
                  return img?.src?._preview === value?.src?._preview;
                }

                return img?.src === value?.src;
              });

              return {
                ...v,
                images: selectedImage,
              };
            }

            return v;
          }),
        };
      }

      if (path === 'deleteVariant') {
        if (errors.variants?.length) {
          setErrors((err) => {
            const index = prev.variants.findIndex((v) => v.id.toString() === value.toString());

            return {
              ...err,
              variants: err.variants.filter((v, i) => i !== index),
            };
          });
        }

        return {
          ...prev,
          variants: prev.variants.filter((v) => v.id.toString() !== value.toString()),
        };
      }

      if (path === 'variantTitle') {
        return {
          ...prev,
          variants: prev.variants.map((v) => (v.id.toString() === variantId.toString() ? { ...v, title: value } : v)),
        };
      }

      if (path === 'relations' && variantId) {
        return {
          ...prev,
          variants: prev.variants.map((v) => (v.id.toString() === variantId.toString()
            ? { ...v, relations: value } : v)),
        };
      }

      return {
        ...prev,
        [path]: value,
      };
    });

    if (variantId) {
      setIsVariantDataChanged(true);
    }

    setErrors((prev) => {
      if (typeof path === 'number') {
        if (variantId) {
          const foundVariantIndex = productData.variants?.findIndex((v) => v.id.toString() === variantId?.toString());

          return ({
            ...prev,
            variants: prev?.variants?.map((v, i) => {
              if (i === foundVariantIndex && v?.dimensions?.length) {
                let newError = { ...v };

                const newLineError = v.dimensions.map((val, ind) => (ind === path ? null : val));

                if (newLineError.every((item) => item === null || item === '')) {
                  delete newError.dimensions;

                  if (_.isEmpty(newError)) newError = null;
                } else {
                  newError = { ...newError, dimensions: newLineError };
                }

                return newError;
              }

              return v;
            }),
          });
        }

        return ({
          ...prev,
          dimensions: prev?.dimensions?.map((d, i) => (path === i ? null : d)),
        });
      }

      if (path === 'variantTitle') {
        const foundVariantIndex = productData.variants?.findIndex((v) => v.id.toString() === variantId?.toString());

        return ({
          ...prev,
          variants: prev?.variants?.map((v, i) => {
            let newError = { ...v };

            if (i === foundVariantIndex) {
              delete newError.title;

              if (_.isEmpty(newError)) newError = null;

              return newError;
            }

            return v;
          }),
        });
      }

      if (path === 'customField') {
        return {
          ...prev,
          customFields: { ...prev.customFields, [_.camelCase(id)]: '' },
        };
      }

      if (path === 'variantCustomField') {
        const foundVariantIndex = productData.variants?.findIndex((v) => v.id.toString() === variantId?.toString());

        return {
          ...prev,
          variants: prev?.variants?.map((v, i) => {
            if (i === foundVariantIndex && v) {
              const variantError = { ...v };
              const newCustomFields = { ...v.customFields, [_.camelCase(id)]: '' };

              const customFieldHasError = !!Object.values(newCustomFields).filter((val) => val).length;

              if (customFieldHasError) {
                return {
                  ...v,
                  customFields: newCustomFields,
                };
              }

              delete variantError.customFields;
              return _.isEmpty(variantError) ? null : variantError;
            }

            return v;
          }),
        };
      }

      // if (path === 'reorder') {
      //   let allErrors = { ...prev };
      //   const reorderErrors = { ...prev.reorder, [id]: '' };
      //
      //   const hasError = !!_.compact(Object.values(reorderErrors)).length;
      //
      //   if (!hasError) {
      //     delete allErrors.reorder;
      //   } else {
      //     allErrors = { ...allErrors, reorder: reorderErrors };
      //   }
      //
      //   return allErrors;
      // }

      const allErrors = { ...prev };
      delete allErrors[path];

      return allErrors;
    });
  }, [productSelectOptions, variantId, selectedWarehouseForVariants, selectedPriceForVariants, isVariantDataChanged]);

  const onVariantsHeaderChange = useCallback((path, value) => {
    if (path === 'price') {
      setSelectedPriceForVariants(value);

      setProductData((prev) => ({
        ...prev,
        variants: prev.variants.map((v) => ({
          ...v,
          price: v.priceData.find((p) => p.id.toString() === value.toString()).price || '',
          // priceData: v.priceData.map((p) => (p.id.toString() === value.toString() ? { ...p, price: value } : p)),
        })),
      }));

      return;
    }

    if (path === 'quantity') {
      setSelectedWarehouseForVariants(value);
    }
  }, [productData]);

  const changeHasOption = useCallback((value = undefined) => {
    setProductHasOption((prev) => (value !== undefined ? value : !prev));
  }, []);

  const onVariantOrBundleCheck = useCallback((type, isChecked) => {
    if (type === 'options') {
      if (productHasBundle || (!productHasBundle && productData.bundles.length)) {
        setCheckModal(type);
      } else {
        setProductHasOption(isChecked);

        if (!productData.attributes.length) {
          setOptionData(() => [{
            name: '',
            value: '',
            id: +new Date(),
          }]);
        }
      }
    } else if (productHasOption || (!productHasOption && productData.variants.length)) {
      setCheckModal(type);
    } else {
      setProductHasBundle(isChecked);
      setProductData((prev) => ({
        ...prev,
        bundles: isChecked ? prev.bundles : [],
        stockWarehouses: prev.stockWarehouses.map((st) => ({
          ...st,
          available: prev.bundles.length ? '' : st.available,
        })),
      }));
    }
  }, [productHasOption, productHasBundle, productData]);

  const onGenerateBarcode = useCallback((barcodeData) => {
    const barCodeMapping = {
      upc: 'upca',
      int: 'interleaved2of5',
      codabar: 'rationalizedCodabar',
      isbn: 'ean13',
      gtin: 'ean13',
      std25: 'ean13',
      int25: 'ean13',
    };

    try {
      bwipjs.toCanvas('barcode', {
        bcid: barCodeMapping[barcodeData.format] || barcodeData.format, // Barcode type
        text: barcodeData.numbers, // Text to encode
        scale: 3, // 3x scaling factor
        height: 10, // Bar height, in millimeters
        includetext: true, // Show human-readable text
        textxalign: 'center', // Always good to set this
      });
      // JsBarcode('#barcode', barcodeData.numbers, { format: barcodeData.format });

      setBarcode({ format: barcodeData.format, numbers: barcodeData.numbers });
      setCreatedBarcodeData({ format: barcodeData.format, numbers: barcodeData.numbers });
      setErrors((prev) => ({ ...prev, barCode: '' }));
    } catch (e) {
      // document.querySelector(`#${barcodeId}`).innerHTML = '';

      // setBarcode({ format: barcodeData.format, numbers: barcodeData.numbers });
      // setCreatedBarcodeData({ format: '', numbers: '' });

      throw {
        response: {
          data: {
            errors: {},
            message: typeof e === 'string'
              ? e : `${barcodeData.numbers} is not valid input for ${barcodeData.format}.`,
          },
        },
      };
    }

    setBarcodeModal(false);
  }, [barCode]);

  const onSaveCheckModal = useCallback(() => {
    if (checkModal === 'options') {
      setProductData((prev) => ({
        ...prev,
        bundles: [],
      }));

      setProductHasOption(true);
      setProductHasBundle(false);
    } else {
      setProductData((prev) => ({
        ...prev,
        variants: [],
      }));

      setProductHasOption(false);
      setProductHasBundle(true);
    }

    setCheckModal('');
  }, [productData, checkModal]);

  const onCreateProduct = useCallback(async (isClickedFromBundle = false, data = productData) => {
    setCreateLoading(true);
    let haseError = false;

    if (data.customFields.length) {
      const parentError = {};
      const variantErrors = [];

      data.customFields.forEach((f) => {
        if (f.isMandatory && !f.value.trim() && !f.defaultValue.trim()) {
          parentError[_.camelCase(f.label)] = 'Field is required';

          haseError = true;
        }
      });

      console.log(data.variants, 'VVVVVVVVVVVVVVVV');

      data.variants.forEach((v) => {
        const singleVariantError = {};

        v.customFields.forEach((f) => {
          if (f.isMandatory && !f.value.trim() && !f.defaultValue.trim()) {
            singleVariantError[_.camelCase(f.label)] = 'Field is required';

            haseError = true;
          }
        });

        variantErrors.push(_.isEmpty(singleVariantError) ? null : singleVariantError);
      });

      setErrors((prev) => ({
        ...prev,
        customFields: _.isEmpty(parentError) ? null : parentError,
        variants: (prev.variants !== null && prev?.variants?.length)
          ? prev.variants.map((err, i) => {
            if (variantErrors[i]) {
              return { ...err, customFields: variantErrors[i] };
            }

            return err;
          })
          : variantErrors.map((err) => (!err ? null : { customFields: err })),
      }));
    }

    const sendingStocks = !productHasOption
      ? data.stockWarehouses.filter((st) => !!st.available).map((
        (st) => ({ warehouseId: +st.id, available: st.available || st.stock }))) : [];

    // if (!data.title.trim()) {
    //   haseError = true;
    //   setErrors((prev) => ({ ...prev, title: 'Field is required' }));
    // }

    if (data.batches.length) {
      setErrors((prev) => ({ ...prev, batches: [] }));

      _.forEach(_.cloneDeep(data.batches), (batch, i) => {
        setErrors((prev) => ({
          ...prev,
          batches: [...(prev.batches || []), null],
        }));

        // batch.variant = batch.variant || '';

        delete batch[settings === 'batchNumber' ? 'serialNumber' : 'batchNumber'];

        _.forEach(batch, (val, key) => {
          const batchValue = val.toString().trim();
          if (key === 'serialNumber' && !batchValue) {
            setErrors((prev) => ({
              ...prev,
              batches: prev.batches.map((b, index) => {
                if (index === i) {
                  return { ...(b || {}), [key]: 'Field is required' };
                }

                return b;
              }),
            }));

            haseError = true;
          } else if (!batchValue && settings === 'batchNumber') {
            setErrors((prev) => ({
              ...prev,
              batches: prev.batches.map((b, index) => {
                if (index === i) {
                  return { ...(b || {}), [key]: 'Field is required' };
                }

                return b;
              }),
            }));

            haseError = true;
          }
        });
      });
    }

    if (haseError) {
      toast.error('Invalid fields found, please correct.');

      setCreateLoading(false);
      // throw new Error('Invalid fields found, please correct.');
      return { status: 'error' };
    }

    const sendingData = {
      ...data,
      barCode: barCode.numbers,
      barCodeType: barCode.format,
      vendorId: typeof data.vendorId === 'object' ? (data.vendorId?.id || '') : data.vendorId,
      weight: data.weight,
      weightUnit: data.weightUnit,
      dimensions: data.dimensions,
      dimensionsUnit: data.dimensionsUnit,
      categories: data.categories.map((c) => ({ id: c.id })),
      sourceCreatedAt: data.sourceCreatedAt,
      tags: data.tags.map((t) => ({ id: +t })),
      prices: data.priceData.filter((p) => !!p.price).map((p) => ({ priceListId: p.id, price: p.price })),
      stocks: sendingStocks,
      brandId: typeof data.brandId === 'object' ? (data.brandId?.id || undefined) : data.brandId,
      // reorder: productHasReorder ? {
      //   ...data.reorder,
      //   companyId: +data.reorder.companyId.id,
      //   priceListId: +data.reorder.priceListId.id,
      //   vendorId: +data.reorder.vendorId.id,
      //   warehouseId: +data.reorder.warehouseId.id,
      // } : {},
      bundles: !productHasBundle ? [] : data.bundles.map((b) => ({
        bundle: {
          productId: +b.bundle.productId,
          quantity: +b.bundleQuantity || 1,
        },
      })),
      customFields: data.customFields.map(
        (f) => ({ name: f.label, value: f.value || f.defaultValue || '' }),
      ),
      variants: !productHasOption ? [] : data.variants.map((v) => {
        const sendingVariant = {
          ...v,
          batches: data.batches.filter((b) => b.variant === v.id).map((b) => ({
            [settings]: b[settings],
            qty: b.qty,
            id: b.id || undefined,
            expirationDateFrom: b.expirationDateFrom || undefined,
            expirationDateTo: b.expirationDateTo || undefined,
            [batchIndexKey]: b[batchIndexKey],
          })),
        };

        sendingVariant.prices = sendingVariant.priceData.filter((p) => !!p.price).map((p) => ({
          priceListId: p.id,
          price: p.price,
        }));

        sendingVariant.stocks = sendingVariant.variantStockWarehouses.filter((s) => !!s.available)
          .map((s) => ({ warehouseId: +s.id, available: s.available }));
        sendingVariant.attributes = v.attributes.map((attr) => ({ name: attr.title || attr.name, value: attr.value }));
        sendingVariant.customFields = v.customFields.map(
          (f) => ({ name: f.label, value: f.value || f.defaultValue || '' }),
        );

        if (productId !== 'add') sendingVariant.relations = v.relations?.map((r) => ({ id: r.id }));

        // delete sendingVariant.allQuantity;
        delete sendingVariant.priceData;
        delete sendingVariant.name;
        delete sendingVariant.price;
        delete sendingVariant.quantity;
        delete sendingVariant.variantStockWarehouses;
        delete sendingVariant.createdAt;
        delete sendingVariant.isDemo;
        delete sendingVariant.isListed;
        delete sendingVariant.ownerId;
        delete sendingVariant.parentId;
        delete sendingVariant.shopId;
        delete sendingVariant.shopType;
        delete sendingVariant.sourceId;
        delete sendingVariant.sourceUrl;
        delete sendingVariant.type;
        delete sendingVariant.updatedAt;
        delete sendingVariant.stock;
        delete sendingVariant.isArchive;
        delete sendingVariant.totalStock;
        delete sendingVariant.allocated;
        delete sendingVariant.incoming;

        return sendingVariant;
      }),
      relations: data.relations.map((r) => ({ id: r.id })),
      batches: data.variants.length ? [] : data.batches.map((b) => ({
        [settings]: b[settings],
        qty: b.qty,
        id: b.id || undefined,
        expirationDateFrom: b.expirationDateFrom || undefined,
        expirationDateTo: b.expirationDateTo || undefined,
      })),
    };

    if (!sendingData.sku) delete sendingData.sku;
    if (!sendingData.barCode) delete sendingData.barCode;
    if (!sendingData.barCodeType) delete sendingData.barCodeType;

    // delete sendingData.reorder.productId;
    // delete sendingData.reorder.ownerId;
    delete sendingData.priceData;
    delete sendingData.stockWarehouses;
    delete sendingData.variantStockWarehouses;
    delete sendingData.createdAt;
    delete sendingData.shopId;
    delete sendingData.shopType;
    delete sendingData.brand;

    let foundVariant;

    let product;

    try {
      product = productId === 'add'
        ? await Api.createProduct(sendingData)
        : await Api.updateProduct(sendingData, productId);
    } catch (e) {
      const err = e.response.data.errors;

      const batchError = err?.variants?.map((variant, index) => {
        const batchIndex = sendingData.variants[index].batches.map((b) => b[batchIndexKey]);

        return (variant?.batches || []).map((b, i) => ({ ...b, position: batchIndex[i] }));
      }).flat(1);

      if (batchError?.length) {
        setErrors((prev) => ({
          ...prev,
          batches: data.batches.map((b, i) => batchError.find((er) => er.position === i) || null),
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          ...err,
        }));
      }

      toast.error(e.response.data?.message || 'Invalid fields found, please correct.');
      setCreateLoading(false);
      setSaveVariantModal(false);
      return e.response.data;
    }

    if (!hasProducts) {
      await dispatch(getSetupRequest());
    }

    if (variantId) {
      foundVariant = product.data.product.variants[nextVariantIndex === null
        ? data.variants.findIndex((v) => v.id.toString() === variantId.toString())
        : nextVariantIndex];

      // if (isVariantDataChanged) {
      setProductData((prev) => ({
        ...prev,
        variants: prev.variants.map((v, index) => ({
          ...v,
          id: product.data.product.variants[index].id,
        })),
      }));

      setVariantId(foundVariant.id);
      setNextVariantIndex(null);
      setIsVariantDataChanged(false);
      // }

      toast.success(`Successfully ${productId === 'add' ? 'created' : 'updated'}`);
      if (!isClickedFromBundle) {
        if (productId === 'add') navigate(`/products/${product.data.product.id}`, { replace: true });
      }
    } else if (searchParams.get('back')) {
      navigate(`${searchParams.get('back')}?productId=${product.data.product.id}`);
    } else if (!isClickedFromBundle) {
      navigate('/products');
    }

    setCreateLoading(false);

    // if (product.data.product.variants.find((v) => v.id.toString() === variantId.toString())) {
    //   setVariantId('');
    // }   LINKEDIC CLICKI JAMANAK ERROR VARIANTID -i het kapvac

    setErrors({});
    return product.data.product;
  }, [productData, barCode, productId, isVariantDataChanged, variantId, nextVariantIndex,
    searchParams.get('back'), errors]); // productHasReorder

  const addBatch = async (path, newPath, lastBatchNumber) => {
    let batchNumber = '';

    if (path === 'batchNumber' && newPath) {
      setButchNumberLoading(true);
      try {
        const { data } = await Api.generateBatchNumber();
        batchNumber = data.batchNumber;
      } catch (e) {
        toast.error('Something wrong');
        setButchNumberLoading(false);

        setSettings('');
        return;
      }

      setButchNumberLoading(false);
    } else if (path === 'batchNumber') {
      const newBatchNumberArr = lastBatchNumber.split('-');
      if (productId !== 'add' && +newBatchNumberArr[1] + 1 <= +newBatchNumber.split('-')[1]) {
        batchNumber = newBatchNumber;
      } else {
        batchNumber = `${newBatchNumberArr[0]}-${String(+newBatchNumberArr[1] + 1).padStart(8, 0)}`;
      }
    }

    setProductData((prev) => ({
      ...prev,
      batches: newPath
        ? [{
          [path]: batchNumber,
          qty: 1,
          ...(
            productData.variants.length ? {
              variant: productData.variants[0].id,
            } : {}
          ),
          expirationDateFrom: '',
          expirationDateTo: '',
          [batchIndexKey]: 0,
        }]
        : [
          ...prev.batches,
          {
            [path]: batchNumber,
            qty: 1,
            ...(
              productData.variants.length ? {
                variant: productData.variants[0].id,
              } : {}
            ),
            expirationDateFrom: '',
            expirationDateTo: '',
            [batchIndexKey]: prev.batches.length,
          }],
    }));
  };

  const changeSettings = async (path) => {
    setSettings(path);

    if (path) {
      await addBatch(path, true);
    } else {
      setProductData((prev) => ({
        ...prev,
        batches: [],
      }));
    }
  };

  const onAddReorder = useCallback(async () => {
    const savedProduct = await onCreateProduct(true);

    if (savedProduct && savedProduct?.status !== 'error') {
      const productUrl = +savedProduct.parentId
        ? `/products/${savedProduct.parentId}` // ?productVariantId=${savedProduct.id
        : `/products/${savedProduct.id}`;

      navigate(productUrl, { replace: true });

      navigate(`/stocks/reorders/add?back=${productUrl}`);
    }
  }, [onCreateProduct]);

  return (
    <>
      <div id="nested_navigation">
        <Outlet />
      </div>

      <div className="product_create">
        {createLoading && <Loader className="product_create_loading" />}

        {loading ? <Loader /> : (
          variantId ? (
            <CreateProductVariant
              setVariantId={setVariantId}
              variantId={variantId}
              productData={productData}
              variants={productData.variants}
              onDataChange={onDataChange}
              isVariantDataChanged={isVariantDataChanged}
              parentProductImages={productData.images}
              onUpdateVariant={onCreateProduct}
              setNextVariantIndex={setNextVariantIndex}
              nextVariantIndex={nextVariantIndex}
              errors={errors}
              setSaveVariantModal={setSaveVariantModal}
              saveVariantModal={saveVariantModal}
            />
          ) : (
            <div className="product_create_wrapper">
              <div className="product_create_header">
                <div className="product_header_left">
                  <ArrowIcon onClick={() => navigate(-1)} className="product_header_left_back" />

                  <h2 className="product_create_header_title">
                    {productId === 'add' ? 'Add new product' : 'Update product'}
                  </h2>

                  {Utils.getAppIcon(productData, true)}
                </div>

                <div className="product_create_header_btns">
                  <Button
                    onClick={() => navigate(searchParams.get('back') ? searchParams.get('back') : -1)}
                    btnType="cancel"
                    title="Cancel"
                    size="small"
                    roundBorder
                  />

                  <Button
                    onClick={() => onCreateProduct()}
                    title={`${productId !== 'add' ? 'Update' : 'Create'} product`}
                    size="small"
                    roundBorder
                    className="create_product_button"
                  />
                </div>
              </div>

              <div className="product_create_content">
                <div className="product_create_left">
                  <div className="product_create_name_block">
                    <div className="product_create_name_block_top">
                      <div className="product_create_name_block_top_input">
                        <Input
                          value={productData.title}
                          onChangeText={(value) => onDataChange('title', value)}
                          label="Title"
                          roundBorder
                          size="small"
                          error={errors.title}
                        />
                      </div>

                      <div className="product_create_name_block_top_input">
                        <Input
                          value={productData.sku}
                          onChangeText={(value) => onDataChange('sku', value)}
                          label="SKU"
                          roundBorder
                          size="small"
                          error={errors.sku}
                        />
                      </div>
                    </div>

                    <div className="product_create_name_block_textarea">
                      <ReactQuill
                        theme="snow"
                        value={productData.description}
                        onChange={(value) => onDataChange('description', value)}
                      />
                      {/* <Input */}
                      {/*   value={productData.description} */}
                      {/*   onChangeText={(value) => onDataChange('description', value)} */}
                      {/*   textarea */}
                      {/*   roundBorder */}
                      {/*   size="small" */}
                      {/*   label="Description" */}
                      {/* /> */}
                    </div>
                  </div>

                  <ProductImages
                    setProductData={setProductData}
                    images={productData.images}
                  />

                  <ProductPrices
                    onDataChange={onDataChange}
                    priceLists={priceLists}
                    priceData={productData.priceData}
                    disabled={productHasOption}
                  />

                  <ProductDimensions
                    onDataChange={onDataChange}
                    dimensions={productData.dimensions}
                    dimensionsUnit={productData.dimensionsUnit}
                    weightUnit={productData.weightUnit}
                    weight={productData.weight}
                    errors={errors}
                  />

                  <ProductInventory
                    stockWarehouses={productData.stockWarehouses}
                    onDataChange={onDataChange}
                    disabled={productHasOption || productHasBundle}
                  />

                  <ProductOptions
                    onVariantOrBundleCheck={onVariantOrBundleCheck}
                    hasOption={productHasOption}
                    setProductData={setProductData}
                    variants={productData.variants}
                    setProductHasOption={setProductHasOption}
                    warehouses={warehouses}
                    priceData={productData.priceData}
                    dimensions={productData.dimensions}
                    dimensionsUnit={productData.dimensionsUnit}
                    weight={productData.weight}
                    weightUnit={productData.weightUnit}
                    optionData={optionData}
                    setOptionData={setOptionData}
                    attributes={productData.attributes}
                    customFields={productData.customFields}
                    setProductErrors={setErrors}
                  />

                  {productHasOption && (
                    <ProductVariants
                      onDataChange={onDataChange}
                      variants={productData.variants}
                      setVariantId={setVariantId}
                      variantId={variantId}
                      warehouses={warehouses}
                      selectedWarehouse={selectedWarehouseForVariants}
                      selectedPrice={selectedPriceForVariants}
                      productHasBundle={productHasBundle}
                      priceLists={priceLists}
                      onHeaderChange={onVariantsHeaderChange}
                      productHasOption={productHasOption}
                      errors={errors}
                    />
                  )}

                  <ProductBundles
                    onDataChange={onDataChange}
                    bundles={productData.bundles}
                    warehouses={warehouses}
                    setProductData={setProductData}
                    productHasBundle={productHasBundle}
                    onVariantOrBundleCheck={onVariantOrBundleCheck}
                    onCreateProduct={onCreateProduct}
                    productData={productData}
                  />

                  <ProductCustomFields
                    customFields={productData.customFields}
                    errors={errors}
                    onDataChange={onDataChange}
                  />

                  {settings && (
                    <ProductBatchTracking
                      settings={settings}
                      setProductData={setProductData}
                      batches={productData.batches}
                      addBatch={addBatch}
                      changeSettings={changeSettings}
                      errors={errors.batches}
                      setErrors={setErrors}
                      loading={batchNumberLoading}
                      variants={productData.variants}
                    />
                  )}
                </div>

                <div className="product_create_right">
                  <ProductVendor
                    onDataChange={onDataChange}
                    customers={customers}
                    categories={categories}
                    brands={brands}
                    setCategories={(cat) => setProductSelectOptions((prev) => ({
                      ...prev,
                      categories: cat,
                    }))}
                    setBrands={(br) => setProductSelectOptions((prev) => ({
                      ...prev,
                      brands: br,
                    }))}
                    productData={productData}
                    createProduct={onCreateProduct}
                  />

                  <ProductTags
                    tags={tags}
                    tagValue={productData.tags}
                    setProductData={setProductData}
                    setTags={(tag) => setProductSelectOptions((prev) => ({
                      ...prev,
                      tags: tag,
                    }))}
                  />

                  <ProductBarcode
                    onGenerateBarcode={onGenerateBarcode}
                    barCode={barCode}
                    createdBarcodeData={createdBarcodeData}
                    setBarcodeModal={setBarcodeModal}
                    barcodeModal={barcodeModal}
                    error={errors.barCode}
                  />

                  <div className="create_product_reorder_block_new">
                    <div className="create_product_reorder_block_new_title_block">
                      <h3>Reorder rule</h3>

                      {!productReorders.length && (
                        <Button
                          onClick={onAddReorder}
                          size="small"
                          roundBorder
                          title="Create reorder rule"
                          btnType="transparent"
                          color="rgba(20, 114, 255, 1)"
                        />
                      )}
                    </div>

                    {!!productReorders.length && (
                      <div className="create_product_reorder_name">
                        <span>Reorder number: </span>

                        <p
                          onClick={() => {
                            navigate(`/stocks/reorders/${productReorders[0].id}?back=/products/${productId}`);
                          }}
                        >
                          {`#${productReorders[0].number}`}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* <ProductReorder */}
                  {/*  warehouses={warehouses} */}
                  {/*  companies={companies} */}
                  {/*  customers={customers} */}
                  {/*  priceLists={priceLists} */}
                  {/*  onDataChange={onDataChange} */}
                  {/*  reorderData={productData.reorder} */}
                  {/*  setProductHasReorder={setProductHasReorder} */}
                  {/*  productHasReorder={productHasReorder} */}
                  {/*  setProductSelectOptions={setProductSelectOptions} */}
                  {/*  countries={countries} */}
                  {/*  createProduct={onCreateProduct} */}
                  {/*  errors={errors?.reorder} */}
                  {/* /> */}

                  {productId !== 'add' && (
                    <LinkedProducts
                      data={productData}
                      onDataChange={onDataChange}
                      updateProduct={onCreateProduct}
                    />
                  )}

                  <ProductSettings
                    changeSettings={changeSettings}
                    settings={settings}
                  />
                </div>
              </div>

              <WarningModal
                isOpen={!!checkModal}
                onClose={() => setCheckModal('')}
                onSaveClick={onSaveCheckModal}
                text={`If you proceed, all changes connected with 
                ${checkModal === 'options' ? 'bundle products' : 'product variants'} will be lost. Do you want to 
                continue?`}
                btnText="OK"
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default CreateProduct;
