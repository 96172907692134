import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import Api from '../../Api';
import { getRegionsRequest } from './app';

export const getCustomersRequest = createAsyncThunk(
  'customers/getCustomersRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getCustomers(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createCustomerRequest = createAsyncThunk(
  'customers/createCustomerRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createCustomer(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getCustomersTableAttributes = createAsyncThunk(
  'get/customers/table.attributes',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['customersTableAttributes']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const customersTableEditableEvent = createAction('customers/table/editable/event');

export const changeCustomersTableAttribute = createAction('change/customers/table/attributes');

export const updateCustomerRequest = createAsyncThunk(
  'customers/updateCustomerRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, ...d } = payload;
      const { data } = await Api.updateCustomer(id, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getSingleCustomerRequest = createAsyncThunk(
  'customers/getSingleCustomerRequest',
  async (payload, thunkAPI) => {
    try {
      const {
        data,
        data: { customer: { shippingAddresses = [], billingAddress = {} } },
      } = await Api.getSingleCustomer(payload);
      let codes = shippingAddresses.map((s) => ({ country: s.countryCode, region: s.region }));
      codes = [...codes, {
        country: billingAddress?.countryCode,
        region: billingAddress?.region,
      }].filter((c) => c.country);
      await Promise.all(codes.map((c) => thunkAPI.dispatch(getRegionsRequest(c))));
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const deleteCustomerRequest = createAsyncThunk(
  'customers/deleteCustomerRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteCustomer(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const updateCustomerAddressRequest = createAsyncThunk(
  'customers/updateCustomerAddressRequest',
  async (payload, thunkAPI) => {
    try {
      const { customerId, id, ...d } = payload;
      const { data } = await Api.updateCustomerAddress(customerId, id, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createCustomerAddressRequest = createAsyncThunk(
  'customers/updateCustomerAddressRequest',
  async (payload, thunkAPI) => {
    try {
      const { customerId, ...d } = payload;
      const { data } = await Api.createCustomerAddress(customerId, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const setCustomerFormData = createAction('customers/setCustomerFormData');
export const unsetCustomerFormData = createAction('customers/unsetCustomerFormData');
export const setCustomerFormErrors = createAction('customers/setCustomerFormErrors');
export const removeCustomerFormData = createAction('customers/removeCustomerFormData');
export const removeCustomerData = createAction('customers/removeCustomerData');
