import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { useClickAway } from 'react-use';
import { ReactComponent as MenuMobileLeftIcon } from '../../assets/icons/menuMobileLeft.svg';
import { ReactComponent as AppsIcon } from '../../assets/icons/apps.svg';
import Account from '../../helpers/Account';
import { nav } from './LayoutMenu';

const MobileMenuCategory = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const { isAdmin = false } = Account.get();
  const ref = useRef();

  useClickAway(ref, () => {
    setMenuOpen(false);
  });

  const closeMenu = () => {
    document.body.style.removeProperty('overflow');
    document.body.ontouchmove = () => true;
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.ontouchmove = () => false;
    } else {
      closeMenu();
    }
  }, [menuOpen]);

  const menu = nav[isAdmin ? 'admin' : 'user'];

  return (
    <div ref={ref}>
      <MenuMobileLeftIcon
        className="MenuMobileLeftIcon"
        role="button"
        aria-label="Menu Button"
        onClick={() => setMenuOpen((prev) => !prev)}
      />

      <div
        className={classNames('menu__mobile__left', { menu__open: menuOpen })}
      >
        {menu.map(({ path, label, icon }) => (
          <NavLink
            key={path}
            to={path}
            className="menu__left__item"
            onClick={() => setMenuOpen(false)}
          >
            <div className="menu__left__icon__wrapper">
              {icon}
            </div>

            <div className="menu__left__name">{label}</div>
          </NavLink>
        ))}

        {!isAdmin && (
          <NavLink
            className={classNames('menu__left__item', {
              active: !pathname.includes('my-apps') && pathname.includes('apps'),
            })}
            to="/apps/list/all"
            onClick={() => setMenuOpen(false)}
          >
            <div className="menu__left__icon__wrapper">
              <AppsIcon />
            </div>

            <div className="menu__left__name">Apps</div>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default MobileMenuCategory;
