import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Wrapper from '../../Layout/Wrapper';
import Input from '../../_common/Form/Input';
import Select from '../../_common/Form/Select';
import Datepicker from '../../_common/Form/Datepicker';
import SearchProduct from './StockTransferComponent/SearchProduct';
import { getWarehouseList } from '../../../store/actions/stockTransfer';
import { getProductsRequest } from '../../../store/actions/products';
import ProductTable from './StockTransferComponent/ProductTable';
import Api from '../../../Api';
import Utils from '../../../helpers/Utils';
import Loader from '../../_common/Loader/Loader';
import WarningModal from '../../_common/Modal/WarningModal';
import Button from '../../_common/Form/Button';

const columns = [
  {
    key: 'item', isEnabled: true,
  },
  {
    key: 'sourceWarehouse', isEnabled: true,
  },
  {
    key: 'destinationWarehouse', isEnabled: true,
  },
  {
    key: 'quantity', isEnabled: true,
  },
];

const defaultAttributes = {
  item: 'Item',
  sourceWarehouse: 'Source stock',
  destinationWarehouse: 'Destination stock',
  quantity: 'Transfer qty',
};

const CreateOrEditStockTransfer = () => {
  const navigate = useNavigate();
  const { stockTransferId } = useParams();

  const dispatch = useDispatch();
  const warehouseList = useSelector((store) => store.stockTransfer.warehouseList);

  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [requestData, setRequestData] = useState({
    number: '',
    sourceCreatedAt: new Date().toISOString(),
    sourceWarehouseId: '',
    destinationWarehouseId: '',
    products: [],
    note: '',
  });
  const [error, setError] = useState('');

  const {
    number, sourceWarehouseId, destinationWarehouseId,
    products, sourceCreatedAt, note,
  } = requestData;

  useEffect(() => {
    (async () => {
      dispatch(getWarehouseList());

      if (stockTransferId) {
        if (_.isEmpty(products)) {
          setLoading(true);
          try {
            const { data: { stockTransfer } } = await Api.getSingleStockTransfer(stockTransferId);

            setRequestData({
              number: stockTransfer.number,
              sourceCreatedAt: stockTransfer.sourceCreatedAt,
              sourceWarehouseId: stockTransfer.sourceWarehouseId,
              destinationWarehouseId: stockTransfer.destinationWarehouseId,
              products: stockTransfer.products,
              note: stockTransfer.note,
            });

            dispatch(getProductsRequest({
              includes: 'stock,warehouse',
              warehouseId: stockTransfer.sourceWarehouseId,
              totalStock: `1,,${stockTransfer.sourceWarehouseId}`,

            }));
          } catch (e) {
            console.log(e.error);
          }

          setLoading(false);
        }
      } else if (warehouseList[0]?.id) {
        dispatch(getProductsRequest({
          includes: 'stock,warehouse',
          warehouseId: warehouseList[0]?.id,
          totalStock: `1,,${warehouseList[0]?.id}`,
        }));

        setRequestData((prev) => ({
          ...prev,
          sourceWarehouseId: warehouseList[0]?.id,
          destinationWarehouseId: warehouseList[1]?.id || '',
        }));
      }
    })();
  }, [warehouseList]);

  const handleChange = (path, value) => {
    if (path === 'sourceWarehouseId') {
      if (value === sourceWarehouseId) {
        return;
      }

      if (stockTransferId && products.length) {
        setOpenModal(value);
      } else {
        dispatch(getProductsRequest({
          includes: 'stock,warehouse',
          warehouseId: value,
          totalStock: `1,,${value}`,

        }));
        setRequestData((prev) => ({ ...prev, [path]: value, products: [] }));
      }
    } else {
      if (path === 'destinationWarehouseId') setError('');
      setRequestData((prev) => ({ ...prev, [path]: path === 'sourceCreatedAt' ? value.toISOString() : value }));
    }
  };

  const saveChange = async () => {
    if (!requestData.destinationWarehouseId) {
      toast.error('Please select Location to');

      setError('This field is required');
      return;
    }

    if (!products.length) {
      toast.error('Please select product');
    } else {
      setBtnLoading(true);
      try {
        const request = Utils.deleteEmptyKeys({
          ...requestData,
          products: products.map(({ productId, quantity }) => ({ productId, quantity })),
        });

        if (stockTransferId) {
          await Api.singleStockTransferUpdate(stockTransferId, request);
        } else {
          await Api.createStockTransfer(request);
        }

        navigate('/stocks/stock-transfer');
      } catch (e) {
        toast.error(e.response?.data?.message || 'Something went wrong');
      }

      setBtnLoading(false);
    }
  };

  const onSaveModal = useCallback(() => {
    dispatch(getProductsRequest({
      includes: 'stock,warehouse',
      warehouseId: openModal,
      totalStock: `1,,${openModal}`,
    }));

    setRequestData((prev) => ({ ...prev, sourceWarehouseId: openModal, products: [] }));

    setOpenModal('');
  }, [openModal]);

  return (
    <Wrapper
      className="new_stock_transfer_wrapper"
      buttonTitle="Save changes"
      cancelButtonTitle="Cancel"
      title={`${stockTransferId ? 'Edit' : 'New'} stock transfer`}
      onBtnClick={saveChange}
      onCancelBtnClick={() => navigate(-1)}
      btnLoading={btnLoading}
      hideBackBtn
    >

      {loading ? <Loader /> : (
        <>
          <div className="new_stock_transfer_top_wrapper">
            <div>
              <Input
                label="Stock transfer#"
                roundBorder
                value={number}
                onChange={(ev) => handleChange('number', ev.target.value)}
                error=""
                wrapperClassName="new_stock_transfer_top_item"
              />

              <Datepicker
                label="Date"
                onChange={(date) => handleChange('sourceCreatedAt', date)}
                value={sourceCreatedAt ? moment(sourceCreatedAt).format('MM.DD.YYYY') : ''}
                error=""
                wrapperClassName="new_stock_transfer_top_item"
              />
            </div>

            <div>

              <Select
                label="Location from"
                wrapperClassName="new_stock_transfer_top_item"
                options={warehouseList.filter((p) => p.id !== destinationWarehouseId)}
                roundBorder
                getOptionLabel={(o) => o.title}
                getOptionValue={(o) => o.id}
                value={sourceWarehouseId}
                onChange={(val) => handleChange('sourceWarehouseId', val)}
                valuePath="id"
                isSearchable
              />

              <div className="new_stock_transfer_top_item_wrapper">
                <Select
                  label="Location to"
                  wrapperClassName="new_stock_transfer_top_item"
                  options={warehouseList.filter((p) => p.id !== sourceWarehouseId)}
                  roundBorder
                  getOptionLabel={(o) => o.title}
                  getOptionValue={(o) => o.id}
                  value={destinationWarehouseId}
                  onChange={(val) => handleChange('destinationWarehouseId', val)}
                  isSearchable
                  error={error}
                  valuePath="id"
                />

                <Button
                  onClick={() => navigate('/stocks/warehouses/add?backUrl=/stocks/stock-transfer/create')}
                  size="small"
                  roundBorder
                  className="new_stock_transfer_top_item_add_button"
                  btnType="transparent"
                  color="#1472FF"
                  addBtn
                  title="Add werehouse"
                />
              </div>
            </div>
          </div>

          <SearchProduct
            warehouseId={sourceWarehouseId}
            setRequestData={setRequestData}
            products={products}
          />

          <ProductTable
            products={products}
            sourceWarehouseId={sourceWarehouseId}
            destinationWarehouseId={destinationWarehouseId}
            setRequestData={setRequestData}
            actions
            columns={columns}
            defaultAttributes={defaultAttributes}
          />

          <Input
            label="Note"
            value={note}
            onChange={(ev) => handleChange('note', ev.target.value)}
            textarea
            placeholder="Enter any notes to be displayed in your transaction"
            wrapperClassName="new_stock_transfer_note"
          />

        </>
      )}

      <WarningModal
        isOpen={!!openModal}
        onClose={() => setOpenModal('')}
        onSaveClick={onSaveModal}
        text="If you change the source warehouse, all products in this transfer will be removed."
        btnText="OK"
      />
    </Wrapper>
  );
};

export default CreateOrEditStockTransfer;
