import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../assets/icons/logo.svg';
import Loader from '../components/_common/Loader/Loader';
import AmazonIntegration from '../components/pages/Apps/AppIntegration/AmazonIntegration';
import Account from '../helpers/Account';
import WooCommerceIntegration from '../components/pages/Apps/AppIntegration/WooCommerceIntegration';
import ShopifyIntegration from '../components/pages/Apps/AppIntegration/ShopifyIntegration';
import EqwidIntegration from '../components/pages/Apps/AppIntegration/EqwidIntegration';
import BigCommerceIntegration from '../components/pages/Apps/AppIntegration/BigCommerceIntegration';
import EbayIntegration from '../components/pages/Apps/AppIntegration/EbayIntegration';
import BonanzaIntegration from '../components/pages/Apps/AppIntegration/BonanzaIntegration';
import VendIntegration from '../components/pages/Apps/AppIntegration/VendIntegration';
import QuickbooksIntegration from '../components/pages/Apps/AppIntegration/QuickbooksIntegration';
import GmailIntegration from '../components/pages/Apps/AppIntegration/GmailIntegration';
import XeroIntegration from '../components/pages/Apps/AppIntegration/XeroIntegration';
import MailchimpIntegration from '../components/pages/Apps/AppIntegration/MailchimpIntegration';
import StripeIntegration from '../components/pages/Apps/AppIntegration/StripeIntegration';
import ShipBobIntegration from '../components/pages/Apps/AppIntegration/ShipBobIntegration';

const components = {
  ebay: <EbayIntegration />,
  amazon: <AmazonIntegration />,
  woocommerce: <WooCommerceIntegration />,
  shopify: <ShopifyIntegration />,
  ecwid: <EqwidIntegration />,
  bigcommerce: <BigCommerceIntegration />,
  bonanza: <BonanzaIntegration />,
  vend: <VendIntegration />,
  quickbooks: <QuickbooksIntegration />,
  xero: <XeroIntegration />,
  mailchimp: <MailchimpIntegration />,
  messaging: <GmailIntegration />,
  stripe: <StripeIntegration />,
  shipbob: <ShipBobIntegration />,
};

const AppIntegrationVerify = () => {
  const isAuthorized = Account.getToken();

  const navigate = useNavigate();
  const { app } = useParams();

  useLayoutEffect(() => {
    if (!isAuthorized) {
      Account.logout();
    } else if (!components[app]) {
      navigate('/');
    }
  }, []);

  return (
    <div className="logout_layout">
      {components[app]}

      <div className="logout_layout_logo_wrapper">
        <LogoIcon />
      </div>

      <Loader />
    </div>
  );
};

AppIntegrationVerify.propTypes = {};

export default AppIntegrationVerify;
