import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getOrderInvoicesRequest = createAsyncThunk(
  'invoices/getOrderInvoicesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getOrderInvoices(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const generateInvoiceNumberRequest = createAsyncThunk(
  'invoices/generateInvoiceNumberRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.generateInvoiceNumber(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createInvoiceRequest = createAsyncThunk(
  'invoices/createInvoiceRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createInvoice(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const deleteInvoiceRequest = createAsyncThunk(
  'invoices/deleteInvoiceRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteInvoice(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getOrdersInvoicesRequest = createAsyncThunk(
  'invoices/getOrdersInvoicesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getOrdersInvoices(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const cancelInvoiceRequest = createAsyncThunk(
  'invoices/cancelInvoiceRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.cancelInvoice(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const cancelPaymentsRequest = createAsyncThunk(
  'invoices/cancelInvoiceRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.cancelPayments(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const invoicesTableEditableEvent = createAction(
  'invoices/invoicesTableEditableEvent',
);

export const changeInvoicesTableAttributes = createAction(
  'invoices/changeInvoicesTableAttributes',
);

export const getInvoicesTableAttributesRequest = createAsyncThunk(
  'invoices/getInvoicesTableAttributesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['orderInvoiceTableAttributes']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const generateInvoiceRequest = createAsyncThunk(
  'invoices/generateInvoiceRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.generateInvoice(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const sendInvoiceToAccountingRequest = createAsyncThunk(
  'invoices/sendInvoiceToAccountingRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.sendInvoiceToAccounting(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getSingleInvoiceRequest = createAsyncThunk(
  'invoices/getSingleInvoiceRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSingleInvoice(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const removeSingleInvoiceData = createAction('invoices/removeSingleInvoiceData');
