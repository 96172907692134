import React, { useMemo } from 'react';
import SelectedCard from './SelectedCard';
import Input from '../../../_common/Form/Input';
import { ReactComponent as AmazonIcon } from '../../../../assets/icons/apps/amazon.svg';
import { ReactComponent as EbayIcon } from '../../../../assets/icons/apps/ebay.svg';
import { ReactComponent as ShopifyIcon } from '../../../../assets/icons/apps/shopify.svg';
import { ReactComponent as WoocommerceIcon } from '../../../../assets/icons/apps/woocommerce.svg';

const StepThree = ({ data, changeData }) => {
  const changeAccountingData = (type) => {
    changeData((prev) => {
      const foundData = prev.places.includes(type);

      if (foundData) {
        return { ...prev, places: prev.places.filter((s) => s !== type) };
      }

      return { ...prev, places: [...prev.places, type] };
    });
  };

  const changeOtherValue = (value) => {
    changeData((prev) => ({ ...prev, placesOtherValue: value }));

    changeData((prev) => {
      if (!value.trim()) {
        return { ...prev, places: prev.places.filter((s) => s !== 'other') };
      }

      if (!prev.places.includes('other')) {
        return { ...prev, places: [...prev.places, 'other'] };
      }

      return prev;
    });
  };

  const info = useMemo(() => [
    {
      type: 'amazon',
      content: (
        <div className="selected_card_icon_wrapper">
          <p className="selected_card_top_description">Amazon</p>

          <AmazonIcon className="selected_card_icon" />
        </div>
      ),
    },
    {
      type: 'ebay',
      content: (
        <div className="selected_card_icon_wrapper">
          <p className="selected_card_top_description">Ebay</p>

          <EbayIcon className="selected_card_icon" />
        </div>
      ),
    },
    {
      type: 'shopify',
      content: (
        <div className="selected_card_icon_wrapper">
          <p className="selected_card_top_description">Shopify</p>

          <ShopifyIcon className="selected_card_icon" />
        </div>
      ),
    },
    {
      type: 'woocommerce',
      content: (
        <div className="selected_card_icon_wrapper">
          <p className="selected_card_top_description">Woocommerce</p>

          <WoocommerceIcon className="selected_card_icon" />
        </div>
      ),
    },
    {
      type: 'other',
      content: (
        <div>
          <p className="selected_card_top_description">Other</p>

          <Input
            value={data.placesOtherValue}
            onChangeText={(value) => changeOtherValue(value)}
            size="small"
            roundBorder
          />
        </div>
      ),
    },
  ], [data]);

  return (
    <div className="onboarding_page_steps_content step_three">
      {info.map(({ type, content }) => (
        <SelectedCard
          key={type}
          onChange={() => type !== 'other' && changeAccountingData(type)}
          checked={data.places.includes(type)}
          hideCheckbox={type === 'other'}
        >
          {content}
        </SelectedCard>
      ))}
    </div>
  );
};

export default StepThree;
