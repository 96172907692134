import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Table from '../../_common/Tables/Table';
import Loader from '../../_common/Loader/Loader';
import Api from '../../../Api';
// import Button from '../../_common/Form/Button';

const tableHeader = [
  {
    title: 'Invoice number',
    path: 'number',
  },
  {
    title: 'Plan',
    path: 'planName',
  },
  {
    title: 'Amount Paid',
    path: 'amountPaid',
  },
  {
    title: 'Created at',
    path: 'createdAt',
  },
  {
    title: 'Coupon',
    path: 'discount',
  },
  {
    title: 'Tax',
    path: 'tax',
  },
  {
    title: 'Status',
    path: 'status',
  },
];

function ProfileInvoices() {
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await Api.getCurrentSubscriptionInvoices({ userId });

      setInvoices(data.invoices.map((inv) => ({ ...inv, planName: _.upperFirst(inv.planName) })));
      setLoading(false);
    })();
  }, []);

  const onDownload = (id) => {
    window.open(invoices.find((i) => i.id === id).invoicePdf);
  };

  return (
    <div className="profile_invoices">
      {loading ? <Loader /> : (
        <div>
          <Table
            data={invoices}
            header={tableHeader}
            onDownloadClick={onDownload}
          />

          {/* {!!invoices.length && ( */}
          {/*   <div className="view_all_btn_wrapper"> */}
          {/*     <Button */}
          {/*       btnType="transparent" */}
          {/*       color="#1472FF" */}
          {/*       title="View all invoices" */}
          {/*     /> */}
          {/*   </div> */}
          {/* )} */}
        </div>
      )}
    </div>
  );
}

export default ProfileInvoices;
