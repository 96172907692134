import React from 'react';
import classNames from 'classnames';
import Checkbox from '../../../_common/Form/Checkbox';

const SelectedCard = ({ checked, onChange, children, hideCheckbox }) => (
  <div className={classNames('selected_card_wrapper', { checked, hideCheckbox })}>
    <Checkbox
      onChange={onChange}
      checked={checked}
    />

    {children}
  </div>
);

export default SelectedCard;
