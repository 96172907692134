import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import { ReactComponent as TableEditIcon } from '../../../../assets/icons/table_edit.svg';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import noImage from '../../../../assets/icons/default.svg';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Utils from '../../../../helpers/Utils';
import Select from '../../../_common/Form/Select';
import Api from '../../../../Api';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import { barCodeFields } from '../CreateComponents/ProductBarcode';
import { bulkEditProductRequest } from '../../../../store/actions/products';
import SetCategoriesModal from './SetCategoriesModal';

const columns = [
  {
    key: 'item', isEnabled: true,
  },
  {
    key: 'sku', isEnabled: true,
  },
  {
    key: 'brand', isEnabled: true,
  },
  {
    key: 'category', isEnabled: true,
  },
  {
    key: 'barcode', isEnabled: true,
  },
];

const BulkEdit = ({
  productItems, priceListCols, setBulkEdit,
}) => {
  const timeOutRef = useRef();

  const dispatch = useDispatch();
  const priceListsEnabledColumns = useSelector((store) => store.products.priceListsEnabledColumns);
  const priseList = useSelector((store) => store.products.priseList);

  const [selectMenuWrapper, setSelectMenuWrapper] = useState();
  const [brandsList, setBrandsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [barCode, setBarcode] = useState({
    format: '',
    numbers: '',
  });
  const [barcodeModal, setBarcodeModal] = useState('');
  const [openCategoriesModal, setOpenCategoriesModal] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const defaultAttributes = useMemo(() => _.defaults(
    {
      item: 'Item',
      sku: 'SKU',
      brand: 'Brand',
      category: 'Category',
      barcode: 'Barcode',
    },
    ...priceListsEnabledColumns.map((p) => (
      { [p.key]: priseList.find((prise) => (prise.id === p.key.split('_')[1]))?.name })),
  ), [priceListsEnabledColumns, productItems]);

  useEffect(() => {
    if (productItems.length) {
      setSelectMenuWrapper(document.querySelector('#modal'));
    }
  }, [productItems]);

  useEffect(() => {
    (async () => {
      const [{ data: { brands } },
        { data: { categories } }] = await Promise.all([await Api.getBrands({
        page: 1,
        limit: 100,
      }),
      await Api.getCategories({
        page: 1,
        limit: 100,
      })]);

      setBrandsList(brands);
      setCategoriesList(categories);
    })();
  }, []);

  const loadMoreOptions = async (value, path) => {
    clearTimeout(timeOutRef.current);

    return (await new Promise((resolve) => {
      timeOutRef.current = setTimeout(async () => {
        if (path === 'brand') {
          const { data: { brands } } = await Api.getBrands(Utils.deleteEmptyKeys({
            page: 1,
            s: value.trim(),
          }));

          resolve(brands);
        } else {
          const { data: { categories } } = await Api.getCategories(Utils.deleteEmptyKeys({
            page: 1,
            s: value.trim(),

          }));
          resolve(categories);
        }
      }, 500);
    }));
  };

  const onGenerateBarcode = useCallback((barcodeData) => {
    setErrors(((prev) => ({ ...prev, [barcodeModal]: { ...prev?.[barcodeModal], barCode: '' } })));

    setBulkEdit((prev) => ({
      ...prev,
      productLists: prev.productLists.map((p) => (p.id === barcodeModal ? {
        ...p,
        barCode: barcodeData.numbers,
        barCodeType: barcodeData.format,
      } : p)),
    }));

    setBarcodeModal('');
  }, [barcodeModal]);

  const openGenerateBarcode = (item) => {
    setBarcodeModal(item.id);

    setBarcode({
      id: item.barCode ? item.id : null,
      format: item.barCodeType,
      numbers: item.barCode,
    });
  };

  const changeProduct = (value, id, path) => {
    setErrors(((prev) => ({ ...prev, [id]: { ...prev?.[id], [path]: '' } })));

    setBulkEdit((prev) => ({
      ...prev,
      productLists: prev.productLists.map((p) => (p.id === id ? {
        ...p,
        [path]: value,
      } : p)),
    }));
  };

  const saveClick = async () => {
    setLoading(true);

    const requestData = productItems.map((b) => ({
      id: b.id,
      title: b.title,
      sku: b.sku,
      brandId: b.brandId,
      categories: b.categories.map((c) => ({ id: c.id, name: c.name })),
      prices: b.prices.map((c) => ({ priceListId: c.priceListId, price: c.price, discountPrice: c.discountPrice })),
      barCode: b.barCode,
      barCodeType: b.barCodeType,
    }));

    const { payload } = await dispatch(bulkEditProductRequest(requestData));

    if (payload.status === 200) {
      closeModal();
    } else {
      _.forEach(payload.data.errors.update, (error, index) => {
        if (error) {
          setErrors((prev) => ({
            ...prev,
            [_.get(productItems, index).id]: { ...error },
          }
          ));
        }
      });
    }

    setLoading(false);
  };

  const closeModal = () => {
    setBulkEdit({});
  };

  return (
    <div
      className="bulk_edit_modal_wrapper"
    >
      <h2>Items bulk edit</h2>

      {/* <div className="bulk_edit_modal_table_edit"> */}
      {/*  <FilterButton */}
      {/*    className="filter_button dots" */}
      {/*    onClick={() => { */}
      {/*    }} */}
      {/*  > */}
      {/*    <TableEditIcon /> */}
      {/*  </FilterButton> */}
      {/* </div> */}

      <div className="bulk_edit_modal_table">
        <DynamicTable
          // onChange={(a.svg)=>{
          //   console.log(a.svg)}}
          hideOrShow={false}
          loading={false}
          data={productItems}
          keyExtractor={(item) => item.id}
          columns={[...columns, ...priceListsEnabledColumns]}
          renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
          renderColumns={{
            item: ({ item }) => (
              <div className="bulk_edit_modal_item_wrapper">
                <div className="bulk_edit_modal_item_img">
                  <img
                    src={item.images[0]?.src || item.images[0]?.medium || noImage}
                    alt="img"
                  />
                </div>

                <Input
                  wrapperClassName="bulk_edit_modal_input_select"
                  value={item.title}
                  onChange={(e) => changeProduct(e.target.value, item.id, 'title')}
                  error={errors?.[item.id]?.title}
                />
              </div>
            ),

            sku: ({ item }) => (
              <Input
                wrapperClassName="bulk_edit_modal_input_select"
                value={item.sku}
                onChange={(e) => changeProduct(e.target.value, item.id, 'sku')}
                error={errors?.[item.id]?.sku}
              />
            ),

            brand: ({ item }) => (
              <Select
                wrapperClassName="bulk_edit_modal_input_select"
                getOptionLabel={(o) => o.name}
                getOptionValue={(o) => o.id}
                value={brandsList.find((b) => b.id === item.brandId)}
                defaultOptions={brandsList}
                isAsync
                loadOptions={(value) => loadMoreOptions(value, 'brand')}
                onChange={(val) => changeProduct(val, item.id, 'brandId')}
                isSearchable
                valuePath="id"
                menuPosition="fixed"
                menuPortalTarget={selectMenuWrapper}
                error={errors?.[item.id]?.brandId}
              />
            ),

            category: ({ item }) => (
              <div className={classNames('bulk_edit_modal_category_wrapper', {
                error: errors?.[item.id]?.category,
              })}
              >
                <p>{`${item.categories.length} selected`}</p>

                <p onClick={() => setOpenCategoriesModal(item)}>See all</p>

                {errors?.[item.id]?.category
                  && <p className="bulk_edit_modal_category_error_text">{errors?.[item.id]?.category}</p>}
              </div>
            ),

            ...priceListCols,

            barcode: ({ item }) => (
              <div
                role="button"
                tabIndex="0"
                onClick={() => openGenerateBarcode(item)}
                className={classNames('bulk_edit_modal_barcode', {
                  generate: item.barCode,
                })}
              >
                {item.barCode || 'Generate'}

                {errors?.[item.id]?.barCode && (
                  <p>
                    {errors?.[item.id]?.barCode}
                  </p>
                )}
              </div>
            ),
          }}
        />

      </div>

      <div className="bulk_edit_modal_buttons_wrapper">
        <Button
          className="bulk_edit_modal_button"
          size="small"
          title="Cancel"
          onClick={closeModal}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="bulk_edit_modal_button"
          size="small"
          title="Save"
          onClick={saveClick}
          roundBorder
          color="#1472FF"
          loading={loading}
        />
      </div>

      {!!barcodeModal && (
      <UpdateModal
        id="barcode_modal"
        isOpen={!!barcodeModal}
        onClose={() => setBarcodeModal('')}
        fields={barCodeFields}
        onSave={onGenerateBarcode}
        className="barcode_generate_modal"
        singleData={barCode}
        zIndex={10000}
      />
      )}

      <SetCategoriesModal
        categoriesList={categoriesList}
        setOpenCategoriesModal={setOpenCategoriesModal}
        changeProduct={changeProduct}
        item={openCategoriesModal}
        loadMoreOptions={loadMoreOptions}
      />
    </div>
  );
};

BulkEdit.propTypes = {
  productItems: PropTypes.array,
  priceListCols: PropTypes.object,
  setBulkEdit: PropTypes.func,
};
BulkEdit.defaultProp = {
  productItems: [],
  priceListCols: {},
  setBulkEdit: () => {},
};
export default BulkEdit;
