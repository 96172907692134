import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../Api';
import Loader from '../components/_common/Loader/Loader';

function ShopifyAuth() {
  const [query] = useSearchParams();

  useEffect(() => {
    request().catch(console.error);
  }, [query]);

  const request = useCallback(async () => {
    try {
      const requestData = Object.fromEntries([...query]);
      requestData.redirectUrl = `${window.location.origin}/shopify/auth`;
      const { data } = await Api.getShopifyRedirectUrl(requestData);
      window.location.href = data.redirectUrl;
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    }
  }, [query]);
  return (
    <div>
      <Loader />
    </div>
  );
}

export default ShopifyAuth;
