import { createReducer } from '@reduxjs/toolkit';
import {
  getAccountRequest,
  getCurrentSubscription,
  getPermissions,
  getRolesListRequest,
  getUsersListRequest,
  loginGoogleOauth,
  loginRequest,
} from '../actions/users';
import Account from '../../helpers/Account';

const initialState = {
  profile: Account.get(),
  status: '',
  token: Account.getToken(),
  currentSubscription: {},
  usersList: [],
  roles: [],
  permissions: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginRequest.fulfilled, (state, { payload: { user, token }, meta: { arg: { remember } } }) => {
      state.profile = user;
      state.token = token;

      Account.set(user);
      Account.setToken(token, 'token', remember);
    })
    .addCase(loginGoogleOauth.fulfilled, (state, { payload: { user, token } }) => {
      Account.set(user);
      Account.setToken(token, 'token', false);

      state.profile = user;
      state.token = token;
    })
    .addCase(getAccountRequest.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(getAccountRequest.fulfilled, (state, { payload: { user } }) => {
      Account.set(user);

      state.profile = user;
      state.status = 'ok';
    })
    .addCase(getCurrentSubscription.fulfilled, (state, { payload: { data } }) => {
      state.currentSubscription = data;
    })
    .addCase(getUsersListRequest.fulfilled, (state, { payload }) => {
      state.usersList = payload.users;
    })
    .addCase(getRolesListRequest.fulfilled, (state, { payload }) => {
      state.roles = payload.roles;
    })
    .addCase(getPermissions.fulfilled, (state, { payload }) => {
      state.permissions = payload.scopes;
    });
});

export default reducer;
