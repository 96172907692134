import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getSettingsRequest = createAsyncThunk(
  'settings/get',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updateSettingsRequest = createAsyncThunk(
  'settings/put',
  async (payload) => {
    const { data } = await Api.updateSettings(payload);
    return data;
  },
);

export const getWarehousesRequest = createAsyncThunk(
  'warehouses/get',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getWarehouses(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const setDefaultWarehouseRequest = createAsyncThunk(
  'set-default-warehouse/put',
  async (payload) => {
    const { data } = await Api.setDefaultWarehouse(payload);
    return data;
  },
);

export const getCompaniesRequest = createAsyncThunk(
  'companies/get',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getCompanies(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const setDefaultCompanyRequest = createAsyncThunk(
  'set-default-company/put',
  async (payload) => {
    const { data } = await Api.setDefaultCompany(payload);
    return data;
  },
);

export const getTaxTypesRequest = createAsyncThunk(
  'tax-types/get',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getTaxTypes();

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updateTaxTypesRequest = createAsyncThunk(
  'tax-types/put',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.updateTaxTypes(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const deleteTaxTypesRequest = createAsyncThunk(
  'tax-types/delete',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteTaxTypes(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getPaymentMethodsRequest = createAsyncThunk(
  'payment-methods/get',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['paymentMethods']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const createPaymentMethodRequest = createAsyncThunk(
  'create-methods/post',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createSettingsList({
        paymentMethods: {
          title: payload.title,
          isDefault: payload.isDefault,
        },
      });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updatePaymentMethodRequest = createAsyncThunk(
  'payment-methods/put',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.updateSettingsList({
        paymentMethods: {
          id: payload.id,
          title: payload.title,
          isDefault: payload.isDefault,
        },
      });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const deletePaymentMethodRequest = createAsyncThunk(
  'payment-methods/delete',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteSettingsList({ paymentMethods: payload });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getPaymentTermsRequest = createAsyncThunk(
  'payment-terms/get',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['paymentTerms']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const createPaymentTermRequest = createAsyncThunk(
  'payment-terms/post',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createSettingsList({
        paymentTerms: {
          title: payload.title,
          isDefault: payload.isDefault,
          dueInDays: payload.dueInDays,
          startFrom: payload.startFrom,
        },
      });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updatePaymentTermRequest = createAsyncThunk(
  'payment-terms/put',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.updateSettingsList({
        paymentTerms: {
          id: payload.id,
          title: payload.title,
          isDefault: payload.isDefault,
          dueInDays: payload.dueInDays,
          startFrom: payload.startFrom,
        },
      });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const deletePaymentTermRequest = createAsyncThunk(
  'payment-terms/delete',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteSettingsList({ paymentTerms: payload });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getPackagesRequest = createAsyncThunk(
  'settings/getPackagesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['packageDimensions']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const createPackagesRequest = createAsyncThunk(
  'settings/updatePackagesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createSettingsList({ packageDimensions: payload });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const updatePackagesRequest = createAsyncThunk(
  'settings/updatePackagesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.updateSettingsList({ packageDimensions: payload });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const deletePackageRequest = createAsyncThunk(
  'settings/deletePackageRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteSettingsList({ packageDimensions: payload });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getCustomerCustomFieldsRequest = createAsyncThunk(
  'settings/getCustomerCustomFieldsRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['customerCustomFields']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
