import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from './Modal';
import Button from '../Form/Button';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

const DeleteModal = memo(({
  isOpen, onClose, deleteType, onDelete, zIndex, className, text, loading, icon, btnTitle,
}) => (
  <Modal
    className={classNames('delete_modal_wrapper', className)}
    isOpen={isOpen}
    onClose={() => !loading && onClose()}
    zIndex={zIndex}
  >
    <div className="delete_modal_delete_icon_wrapper">
      {icon}
    </div>

    <h2>
      {`${deleteType} ${text}`}
    </h2>

    <div className="delete_modal_delete_text">
      <p>{`Are you sure you want to ${deleteType} this ${text}?`}</p>

      <p>This action cannot be undone.</p>
    </div>

    <div className="delete_modal_btns_wrapper">
      <Button
        size="small"
        roundBorder
        btnType="cancel"
        reverseColor
        onClick={onClose}
      >
        Cancel
      </Button>

      <Button
        size="small"
        roundBorder
        btnType="red"
        loading={loading}
        onClick={onDelete}
      >
        {btnTitle || deleteType}
      </Button>
    </div>
  </Modal>
));

DeleteModal.propTypes = {
  deleteType: PropTypes.oneOf(['delete', 'uninstall', 'cancel']),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
  zIndex: PropTypes.number,
  loading: PropTypes.bool,
  icon: PropTypes.object,
  btnTitle: PropTypes.string,
};

DeleteModal.defaultProps = {
  className: '',
  deleteType: 'delete',
  text: '',
  zIndex: 150,
  onClose: null,
  loading: false,
  onDelete: () => {
  },
  icon: <DeleteIcon />,
  btnTitle: undefined,
};

export default DeleteModal;
