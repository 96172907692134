import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import Input from '../../../_common/Form/Input';
import Utils from '../../../../helpers/Utils';

function ProductPrices({
  onDataChange, priceData = [], disabled, isVariant,
}) {
  const [show, setShow] = useState(true);

  return (
    <div className="create_product_prices">
      <div className={`create_product_title_block ${!show ? 'not_show' : ''}`}>
        {!isVariant && <ArrowIcon onClick={() => setShow((prev) => !prev)} />}

        <h2 className="create_product_title_block_title">Pricing</h2>
      </div>

      {show && (
        <div className="create_product_prices_block">
            {priceData.map((p) => (
              <div className="create_product_prices_input" key={p.id}>
                <Input
                  onChangeText={(value) => onDataChange(isVariant ? 'variantPrice' : 'priceLists', value, p.id)}
                  value={p.price}
                  size="small"
                  roundBorder
                  label={p.name}
                  symbol="$"
                  disabled={disabled}
                  onBeforeInput={(e) => Utils.typingFloatNumber(e, null, 2)}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

ProductPrices.propTypes = {
  onDataChange: PropTypes.func.isRequired,
  priceData: PropTypes.array,
  isVariant: PropTypes.bool,
  disabled: PropTypes.bool,
};

ProductPrices.defaultProps = {
  isVariant: false,
  disabled: false,
  priceData: [],
};

export default ProductPrices;
