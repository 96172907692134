import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../../Api';
import Loader from '../../_common/Loader/Loader';
import Table from '../../_common/Tables/Table';
import Button from '../../_common/Form/Button';

function ProfileChannels() {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [integrations, setIntegrations] = useState([]);

  const tableHeader = useMemo(() => [
    {
      title: 'Title',
      path: 'name',
      navigate: (id) => navigate(`/my-apps/${id}/dashboard`),
    },
    {
      title: 'Product count',
      path: 'planName',
    },
    {
      title: 'Created at',
      path: 'createdAt',
    },
  ], []);

  useEffect(() => {
    (async () => {
      const { data } = await Api.getIntegrations({ userId });

      setIntegrations(data.integrations);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="profile_channels">
      {loading ? <Loader /> : (
        <div>
          <Table
            data={integrations}
            header={tableHeader}
          />

          {!!integrations.length && (
            <div className="view_all_btn_wrapper">
              <Button
                btnType="transparent"
                color="#1472FF"
                title="View all channels"
                onClick={() => { navigate('/my-apps'); }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProfileChannels;
