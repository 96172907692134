import React, { useEffect, useState } from 'react';
import { format } from 'currency-formatter';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import noImg from '../../../../assets/icons/no_img.svg';
import { getProductsRequest } from '../../../../store/actions/products';
import CommentsAndHistory from './CommentsAndHistory';

function ProductsList() {
  const [products, setProducts] = useState([]);

  const dispatch = useDispatch();

  const order = useSelector((state) => state.orders.order);

  useEffect(() => {
    (async () => {
      const ids = order.orderProducts?.map((p) => p.productId);
      if (!_.isEmpty(ids)) {
        const { payload } = await dispatch(getProductsRequest(
          {
            ids: order.orderProducts?.map((p) => p.productId),
            includes: 'incomingStock,prices,warehouse,stock',
            calcStock: true,
          },
        ));
        setProducts(payload.data.products);
      }
    })();
  }, [order]);

  return (
    <>
      <div className="preview_products_list">
        <p className="title">{`Items(${order?.orderProducts?.length || 0})`}</p>
        <div className="products_list table_wrapper">
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Product</th>
                <th className="table_thead_tr_th">Quantity</th>
                <th className="table_thead_tr_th">Price</th>
                <th className="table_thead_tr_th">Discount</th>
                <th className="table_thead_tr_th">Tax</th>
                {order.type === 'purchase' ? <th className="table_thead_tr_th">Landed cost</th> : null}
                <th className="table_thead_tr_th">Amount</th>
                <th />
              </tr>
            </thead>
            <tbody className="table_tbody">
              {order?.orderProducts?.map((p) => {
                const product = products?.find((f) => f.id === p.productId) ||{};
                return (
                  <tr
                    className="table_tbody_tr"
                    key={p.id}
                  >

                    <td className="table_tbody_tr_td">
                      <div className="info" style={{ alignItems: 'center' }}>
                        {p.type !== 'custom' ? (
                          <img
                            style={{ height: 30, width: 30 }}
                            src={product.images?.[0]?.src
                            || product.images?.[0]?.medium
                            || noImg}
                            alt=""
                          />
                        ) : null}
                        {p.type !== 'custom'
                          ? (
                            <Link
                              data-scope="READ_PRODUCTS"
                              to={product.type === 'variant'
                                ? `/products/${product.parentId}?productVariantId=${product.id}`
                                : `/products/${product.id}`}
                              target="_blank"
                            >
                              {p.title}
                            </Link>
                          )
                          : <span>{p.title}</span>}
                      </div>
                    </td>
                    <td className="table_tbody_tr_td">
                      {p.qty}
                    </td>
                    <td className="table_tbody_tr_td">{format(p.price, { code: order.currencyCode })}</td>
                    <td
                      className="table_tbody_tr_td "
                    >
                      {`${p.discountPercentage || 0}%`}
                    </td>

                    <td className="table_tbody_tr_td ">
                      {`${p.taxPercentage || 0}%`}
                    </td>
                    {order.type === 'purchase'
                      ? <td className="table_tbody_tr_td">{format(p.landedCost, { code: order.currencyCode })}</td> : null}
                    <td className="table_tbody_tr_td">{format(p.totalPrice, { code: order.currencyCode })}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="total_container">
          <div className="total">
            <div className="item">
              <p>Total Items</p>
              <p>{order?.orderProducts?.length || 0}</p>
            </div>
            <div className="item">
              <p>Total Units</p>
              <p>{_.sumBy(order?.orderProducts, 'qty') || 0}</p>
            </div>
            <div className="item">
              <p>Tax</p>
              <p>{format(order.totalTax, { code: order.currencyCode })}</p>
            </div>
            <div className="item">
              <p>Shipping charges</p>
              <p>{format(order.totalShippingPrice, { code: order.currencyCode })}</p>
            </div>
            {order.type === 'purchase' ? (
              <div className="item">
                <p>Landed costs</p>
                <p>{format(order.totalLandedCosts, { code: order.currencyCode })}</p>
              </div>
            ) : null}
            <div className="item">
              <p className="subtotal_value">Subtotal</p>
              <p>{format(order.subtotalPrice, { code: order.currencyCode })}</p>
            </div>
            <div className="item">
              <p className="total_value">Total</p>
              <p>{format(order.totalPrice, { code: order.currencyCode })}</p>
            </div>
          </div>
        </div>
      </div>
      <CommentsAndHistory />
    </>
  );
}

export default ProductsList;
