import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NumberParam, StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Table from '../components/_common/Tables/Table';
import Wrapper from '../components/Layout/Wrapper';
import Api from '../Api';
import Pagination from '../components/_common/Pagination/Pagination';
import Loader from '../components/_common/Loader/Loader';
import Button from '../components/_common/Form/Button';
import { ReactComponent as NotFoundIcon } from '../assets/icons/notFound/automation_not_found.svg';

const Automation = () => {
  const navigate = useNavigate();
  const firstLoad = useRef(true);

  const [page, setPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 1),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const { sort, sortBy } = sorting;

  const [automationData, setAutomationData] = useState({
    list: [],
    totalPages: 1,
  });

  const { list, totalPages } = automationData;

  const [loading, loadingToggle] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    (async () => {
      loadingToggle(true);

      await getListRequest();

      loadingToggle(false);
      firstLoad.current = false;
    })();
  }, [sorting]);

  const getListRequest = async (newFilter = {}) => {
    const { data } = await Api.getAutomationList({
      page,
      ...sorting,
      ...newFilter,
    });

    setAutomationData({
      list: data.automations.map((a) => ({
        ...a,
        event: _.upperFirst(a.event),
        status: a.isActive ? 'active' : 'inactive',
      })),
      totalPages: +data.totalPages,
    });
  };

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, [sorting]);

  const changePage = useCallback(async (currentPage) => {
    loadingToggle(true);

    await getListRequest({ page: currentPage });

    setPage(currentPage);
    loadingToggle(false);
  }, [sorting]);

  const onDelete = useCallback(async (id) => {
    setDeleteLoading(true);

    await Api.deleteAutomation(id);

    if (+totalPages > 1 && list.length === 1) {
      await changePage(page - 1);
    } else {
      await getListRequest();
    }

    toast.success('Workflow successfully deleted');

    setDeleteLoading(false);
  }, [sorting, page, totalPages, list]);

  const tableHeader = useMemo(() => [
    {
      title: 'Name',
      path: 'name',
      navigate: (id) => navigate(`/automation/${id}`),
      isSelectable: true,
    },
    {
      title: 'Event',
      path: 'event',
      isSelectable: true,
    },
    {
      title: 'Created at',
      path: 'createdAt',
      isSelectable: true,
    },
    {
      title: 'Status',
      path: 'status',
    },
  ], []);

  return (
    <Wrapper
      onBtnClick={() => navigate('/automation/add')}
      addBtn
      title="Automation"
      buttonTitle="Create workflow"
    >
      {firstLoad.current
        ? <Loader />

        : (
          <div className="automation_table_wrapper">
            <Table
              data={list}
              header={tableHeader}
              onSortBy={onSortBy}
              sortBy={sortBy}
              sort={sort}
              onEditClick={(id) => navigate(`/automation/${id}`)}
              onDeleteClick={onDelete}
              deleteModalText="workflow"
              deleteLoading={deleteLoading}
              loading={loading}
              notFound={(
                <>
                  <NotFoundIcon />

                  <h1>You don't have automation yet</h1>

                  <Button
                    className="global_btn_save"
                    size="small"
                    title="Create workflow"
                    addBtn
                    reverseColor
                    href="/automation/add"
                    roundBorder
                  />
                </>
              )}
            />

            <Pagination onChangePage={(p) => changePage(p)} totalPages={totalPages} />
          </div>
        )}
    </Wrapper>
  );
};

export default Automation;
