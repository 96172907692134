import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';

const HeaderSelect = ({
  title, options, value, onChange, labelPath, valuePath, defaultValue,
}) => {
  const ref = useRef(null);
  const [isOpen, openingToggle] = useState(false);

  useClickAway(ref, () => {
    openingToggle(false);
  });

  const selectOption = (option) => {
    onChange(option);
    openingToggle(false);
  };

  // src={shopIcons[c.shopType || 'eswap']}

  return (
    <div className="header_select" ref={ref}>
      <div
        className={classNames('header_select_current_value', { open: isOpen })}
        onClick={() => openingToggle((prev) => !prev)}
      >
        <span>{title || options.find((t) => t[valuePath] === value)?.[labelPath] || defaultValue}</span>

        <ArrowIcon className="header_select_arrow" />
      </div>

      {isOpen && (
        <div className="header_select_options">
          {options.map((t) => (
            <div
              key={t[valuePath]}
              onClick={() => selectOption(t[valuePath])}
              className={classNames('header_select_option', { active: t[valuePath] === value })}
            >
              {t[labelPath]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

HeaderSelect.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelPath: PropTypes.string,
  valuePath: PropTypes.string,
  defaultValue: PropTypes.string,
};

HeaderSelect.defaultProps = {
  title: '',
  labelPath: 'label',
  valuePath: 'value',
  defaultValue: '',
};
export default HeaderSelect;
