import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Button from '../../../_common/Form/Button';
import { ReactComponent as AddIcon } from '../../../../assets/icons/plus.svg';
import CustomField from '../../../_common/CustomField';
import { setCustomerFormData, unsetCustomerFormData } from '../../../../store/actions/customers';

function CustomFields() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerFormData = useSelector((state) => state.customers.customerFormData);
  const errors = useSelector((state) => state.customers.errors);
  const currencyCode = useSelector((state) => state.settings.generalList.currencyCode);
  const customFields = useSelector((state) => state.settings.customFields);

  const handleChange = useCallback((key, label, value) => {
    if (value) {
      dispatch(setCustomerFormData({ key, value: { name: label, value } }));
    } else {
      dispatch(unsetCustomerFormData({ key }));
    }
  }, []);

  return (
    <div className="customFields">
      <p className="title">Custom Fields</p>
      <div className="custom_fields">
        {customFields.map((c, i) => (
          <CustomField
            key={c.id}
            type={c.type}
            label={c.label}
            currencyCode={customerFormData.defaultCurrencyCode || currencyCode}
            onChange={(val) => handleChange(`customFields[${c.label}]`, c.label, val)}
            value={customerFormData.customFields?.[c.label]?.value}
            phoneSymbol="+"
            id={c.id}
            error={_.get(errors.customFields, c.label) || _.get(errors.customFields, `${i}.value`)}
            {...c}
          />
        ))}
      </div>
      <div className="note">
        <span>Note:</span>
        <p>
          You can add additional fields for your customers and have these show up on your customer create/edit
          page by going to Settings ➡ Custom fields and click on new custom filed button.
        </p>
      </div>
      <Button
        iconLeft={<AddIcon />}
        btnType="transparent"
        onClick={() => navigate(`/settings/custom-fields/add?type=customer&back=${window.location.pathname}?from=customFields`)}
      >
        Add custom field
      </Button>
    </div>
  );
}

export default CustomFields;
