import app from './app';
import users from './users';
import orders from './orders';
import batches from './batches';
import packages from './packages';
import invoices from './invoices';
import products from './products';
import settings from './settings';
import dashboard from './dasboard';
import customers from './customers';
import templates from './templates';
import fileImport from './fileImport';
import fileExport from './fileExport';
import priceLists from './priceLists';
import creditNotes from './creditNotes';
import orderReturns from './orderReturns';
import stockTransfer from './stockTransfer';
import notifications from './notifications';
import chatBot from './chatBot';

const reducers = {
  app,
  users,
  orders,
  batches,
  products,
  packages,
  invoices,
  settings,
  templates,
  dashboard,
  customers,
  fileImport,
  fileExport,
  priceLists,
  creditNotes,
  orderReturns,
  stockTransfer,
  notifications,
  chatBot,
};

export default reducers;
