import React, {
  Fragment, useCallback, useEffect, useRef, useState,
} from 'react';
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import _ from 'lodash';
import { useClickAway } from 'react-use';
import DashboardStatusCounts from './DashboardOrders/DashboardStatusCounts';
import DashboardProducts from './DashboardOrders/DashboardProducts';
import DashboardBySalesChannels from './DashboardOrders/DashboardBySalesChannels';
import DashboardPurchaseOrders from './DashboardOrders/DashboardPurchaseOrders';
import DashboardTopSellingProducts from './DashboardOrders/DashboardTopSellingProducts';
import DashboardSalesOrdersSummary from './DashboardOrders/DashboardSalesOrdersSummary';
import DashboardItemsAtLowQuantity from './DashboardOrders/DashboardItemsAtLowQuantity';
import DashboardItemsOutOfStock from './DashboardOrders/DashboardItemsOutOfStock';
import Loader from '../../_common/Loader/Loader';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import Button from '../../_common/Form/Button';
import Switcher from '../../_common/Form/Switcher';
import RangeDatePicker from '../../_common/Form/RangePickerRange';
import {
  getDashboardLayoutRequest, getDashboardProductsCountRequest, getDashboardShopsRequest, getDashboardStatusCountRequest,
  updateDashboardLayoutRequest,
} from '../../../store/actions/dashboard';

let grid;

const gridConfigs = {
  cellHeight: 180,
  float: true,
};

const gridItemsRef = {};

function UserDashboard() {
  const dispatch = useDispatch();
  const dropDownRef = useRef();
  const isInitialized = useRef(false);

  const dashboardLayout = useSelector((store) => store.dashboard.dashboardLayout);
  const currentSubscription = useSelector((store) => store.users.currentSubscription || {});

  const trialStartDate = currentSubscription?.subscription?.trialStartDate;
  const trialEndDate = currentSubscription?.subscription?.trialEndDate;
  const trialStart = moment(trialStartDate);
  const trialEnd = moment(trialEndDate);
  const durationInDays = trialEnd.diff(trialStart, 'days');

  const [firstLoad, setFirstLoad] = useState(true);
  const [saveLayoutLoading, setSaveLayoutLoading] = useState(false);
  const [showSavedButtons, setShowSavedButtons] = useState(false);
  const [dropDownMenu, setDropDownMenu] = useState(false);

  const defaultGrid = useRef([]);
  const [visibleCharts, setVisibleCharts] = useState([]);

  const [latestSalesData, setLatestSalesData] = useQueryParams({
    byChannelSearch: withDefault(StringParam, ''),
    channel: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [searchValues, setSearchValues] = useQueryParams({
    stockSearch: withDefault(StringParam, ''),
    quantitySearch: withDefault(StringParam, ''),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [date, setDate] = useQueryParams({
    startDate: withDefault(StringParam, moment().startOf('week').format('YYYY-MM-DD')),
    endDate: withDefault(StringParam, moment().format('YYYY-MM-DD')),
  }, { updateType: 'replaceIn' });

  const { startDate, endDate } = date;

  useClickAway(dropDownRef, () => {
    setDropDownMenu(false);
  });

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getDashboardLayoutRequest());
      await dispatch(getDashboardShopsRequest({ category: 'shop' }));
      await dispatch(getDashboardStatusCountRequest({ startDate, endDate }));
      await dispatch(getDashboardProductsCountRequest({ startDate, endDate }));

      const dashboardSetting = window.innerWidth < 768 ? payload.data.mobileDashboardSettings
        : window.innerWidth < 1170 ? payload.data.tabletDashboardSettings : payload.data.dashboardSettings;

      const formattingLayout = dashboardSetting.filter((item) => item.show);

      setVisibleCharts(formattingLayout);

      defaultGrid.current = formattingLayout;
      setFirstLoad(false);
    })();
  }, []);

  useEffect(() => {
    if (dashboardLayout.length && !isInitialized.current) {
      grid = GridStack.init(gridConfigs);

      isInitialized.current = true;

      gridMoveAndResizeToggle(false);
    }
  }, [dashboardLayout]);

  useEffect(() => {
    if (!firstLoad && !saveLayoutLoading) {
      reRenderGrid();
    }
  }, [visibleCharts]);

  const gridMoveAndResizeToggle = useCallback((toggle = true) => {
    if (!firstLoad) {
      setShowSavedButtons(toggle);
    }

    setVisibleCharts(toggle ? dashboardLayout : defaultGrid.current);

    grid.enableMove(toggle);
    grid.enableResize(toggle);
  }, [dashboardLayout, firstLoad]);

  const reRenderGrid = useCallback(() => {
    grid.removeAll(false);

    const refs = { ...gridItemsRef };

    _.forEach(visibleCharts.filter((item) => item.show), (item) => {
      grid.addWidget(
        refs[item.key],
        { ...item.value.position },
      );
    });
  }, [visibleCharts]);

  const onSaveLayout = useCallback(async () => {
    setSaveLayoutLoading(true);
    const savedGrid = grid.save();

    const updatedData = savedGrid.map((item) => ({
      key: item.id,
      show: !!visibleCharts.find((i) => i.key === item.id),
      value: {
        position: {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item?.h || '',
        },
      },
    }));

    const deletedCharts = visibleCharts.filter((c) => !c.show).map((chart) => ({
      ...chart,
      value: {
        position: { ...chart.value.position, x: '', y: '' },
      },
    }));

    const settingType = window.innerWidth < 768 ? 'mobileDashboardSettings'
      : window.innerWidth < 1170 ? 'tabletDashboardSettings' : 'dashboardSettings';

    defaultGrid.current = updatedData;

    await dispatch(updateDashboardLayoutRequest({
      data: [...deletedCharts, ...updatedData],
      settingType,
    }));

    gridMoveAndResizeToggle(false);

    setShowSavedButtons(false);
    setSaveLayoutLoading(false);
  }, [visibleCharts]);

  const onAddOrRemoveGrid = useCallback((key, checked) => {
    grid.addWidget(gridItemsRef[key]); // const { nodes } = grid.engine;

    setVisibleCharts((prev) => prev.map((item) => (item.key === key ? { ...item, show: checked } : item)));
  }, [visibleCharts]);

  return (
    <div className="dashboard">
      {firstLoad && <Loader className="dashboard_loading" />}

      {trialStartDate && (
        <div className="dashboard_top_info">
          <ClockIcon />

          <p style={{ color: '#1472FF' }}>{`${durationInDays} days left for your trial.`}</p>

          <p> Book a time with one of our collegues before your trial ends.</p>

          <Button
            size="small"
            roundBorder
            title="Upgrade"
            className="dashboard_top_info_btn"
          />

          <Button
            size="small"
            roundBorder
            title="Book a time"
            className="dashboard_top_info_btn"
          />
        </div>
      )}

      <div className="dashboard_top_filters">
        <h3>Sales activity</h3>

        <div className="dashboard_top_filters_right">
          {showSavedButtons && (
            <div className="dashboard_top_filters_save_btns">
              <Button
                title="Cancel"
                btnType="cancel"
                onClick={() => !saveLayoutLoading && gridMoveAndResizeToggle(false)}
                size="small"
                roundBorder
              />
              <Button
                title="Save changes"
                onClick={onSaveLayout}
                size="small"
                roundBorder
                loading={saveLayoutLoading}
                className="dashboard_top_filters_save_btn"
              />
            </div>
          )}

          {!showSavedButtons && (
            <Button
              btnType="light_blue"
              size="small"
              roundBorder
              title="Edit layout"
              className="dashboard_edit_btn"
              onClick={() => gridMoveAndResizeToggle(true)}
            />
          )}

          {showSavedButtons && (
            <div className="shops_drop_down" ref={dropDownRef}>
              <div
                onClick={() => setDropDownMenu((prev) => !prev)}
                role="button"
                tabIndex={0}
                className="shops_drop_down_select"
              >
                <ArrowIcon style={{
                  transform: dropDownMenu ? 'rotate(180deg)' : '',
                  transition: 'all 0.4s',
                }}
                />
              </div>

              {dropDownMenu && (
                <div className="shops_drop_down_menu">
                  {visibleCharts.map((l) => (
                    <div key={l.key || l.id} className="shops_drop_down_menu_single">
                      <Switcher
                        onChange={(checked) => onAddOrRemoveGrid(l.key, checked)}
                        label={_.startCase(l.key)}
                        checked={visibleCharts.find((item) => item.key === l.key).show}
                        className="shops_drop_down_menu_single_switcher"
                      />
                    </div>
                  ))}

                  {/* <div className="shops_drop_down_menu_btns"> */}
                  {/*   <Button */}
                  {/*     title="Cancel" */}
                  {/*     btnType="cancel" */}
                  {/*     onClick={() => { */}
                  {/*     }} */}
                  {/*     size="small" */}
                  {/*     roundBorder */}
                  {/*     className="cancel_btn" */}
                  {/*   /> */}
                  {/*   <Button */}
                  {/*     title="Save changes" */}
                  {/*     onClick={() => { */}
                  {/*     }} */}
                  {/*     size="small" */}
                  {/*     roundBorder */}
                  {/*     className="save_btn" */}
                  {/*   /> */}
                  {/* </div> */}
                </div>
              )}
            </div>

          )}

          {!showSavedButtons
            && (
              <div className="dashboard_react_calendar">
                <RangeDatePicker
                  currentDate={date}
                  onChangeDate={(dates) => {
                    setDate({
                      startDate: dates[0],
                      endDate: (dates[0] > dates[1] || !dates[1]) ? dates[0] : dates[1],
                    });
                  }}
                />
              </div>
            )}
        </div>
      </div>

      <div className="grid-stack">
        {visibleCharts.map((l) => {
          const orderKeys = ['toBeFulfilled', 'toBeInvoiced', 'assignedToMe', 'completed'];
          const productsKeys = ['allProducts', 'outOfStockProducts', 'archivedProducts', 'quantityOnHand'];

          return (
            <Fragment key={l?.key}>
              {orderKeys.includes(l.key) && (
                <DashboardStatusCounts
                  data={l}
                  innerRef={gridItemsRef}
                  disable={showSavedButtons}
                  onDelete={() => onAddOrRemoveGrid(l.key, false)}
                  loading={firstLoad}
                />
              )}

              {productsKeys.includes(l.key) && (
                <DashboardProducts
                  data={l}
                  innerRef={gridItemsRef}
                  disable={showSavedButtons}
                  onDelete={() => onAddOrRemoveGrid(l.key, false)}
                  loading={firstLoad}
                />
              )}

              {l.key === 'bySalesChannels' && (
                <DashboardBySalesChannels
                  innerRef={gridItemsRef}
                  data={l}
                  searchData={latestSalesData}
                  setSearchData={setLatestSalesData}
                  disable={showSavedButtons}
                  onDelete={() => onAddOrRemoveGrid('bySalesChannels', false)}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}

              {l.key === 'purchaseOrders' && (
                <DashboardPurchaseOrders
                  data={l}
                  innerRef={gridItemsRef}
                  disable={showSavedButtons}
                  onDelete={() => onAddOrRemoveGrid('purchaseOrders', false)}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}

              {l.key === 'topSellingProducts' && (
                <DashboardTopSellingProducts
                  data={l}
                  innerRef={gridItemsRef}
                  disable={showSavedButtons}
                  onDelete={() => onAddOrRemoveGrid('topSellingProducts', false)}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}

              {l.key === 'salesOrderSummary' && (
                <DashboardSalesOrdersSummary
                  data={l}
                  innerRef={gridItemsRef}
                  disable={showSavedButtons}
                  onDelete={() => onAddOrRemoveGrid('salesOrderSummary', false)}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}

              {l.key === 'itemsAtLowQuantities' && (
                <DashboardItemsAtLowQuantity
                  data={l}
                  innerRef={gridItemsRef}
                  setSearchValues={setSearchValues}
                  searchValue={searchValues.quantitySearch}
                  disable={showSavedButtons}
                  onDelete={() => onAddOrRemoveGrid('itemsAtLowQuantities', false)}
                  startDate={startDate}
                  endDate={endDate}
                  // date={{ startDate: startDate || null, endDate: endDate || null }}
                />
              )}

              {l.key === 'outOfStock' && (
                <DashboardItemsOutOfStock
                  data={l}
                  innerRef={gridItemsRef}
                  setSearchValues={setSearchValues}
                  searchValue={searchValues.stockSearch}
                  disable={showSavedButtons}
                  onDelete={() => onAddOrRemoveGrid('outOfStock', false)}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default UserDashboard;
