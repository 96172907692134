import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../_common/Loader/Loader';
import { ReactComponent as DragIcon } from '../../../../assets/icons/dashboard_drag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/dashboard_close.svg';
import { getDashboardTopProductsRequest } from '../../../../store/actions/dashboard';
import noImage from '../../../../assets/icons/default.svg';

function DashboardTopSellingProducts({
  data, disable, innerRef, onDelete, startDate, endDate,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const topSellingProducts = useSelector((store) => store.dashboard.topSellingProducts);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await dispatch(getDashboardTopProductsRequest({ startDate, endDate }));

      setLoading(false);
    })();
  }, [startDate, endDate]);

  return (
    <div
      ref={(ref) => {
        innerRef[data?.key] = ref || innerRef[data?.key];
      }}
      className="grid-stack-item"
      gs-max-w="12"
      gs-min-w="3"
      gs-max-h="3"
      gs-min-h="2"
      gs-id={data?.key}
      gs-w={data?.value?.position?.w}
      gs-h={data?.value?.position?.h}
      gs-x={data?.value?.position?.x}
      gs-y={data?.value?.position?.y}
      style={{ display: data?.show === false ? 'none' : 'block' }}
    >
      <div className="grid-stack-item-content">
        <div className="chart_wrapper" style={{ cursor: disable ? 'grab' : 'default' }}>
          {loading ? <Loader /> : (
            <div className="dashboard_top_selling_products">
              <h3 className="dashboard_top_selling_products_title">Top selling products</h3>

              {disable && (
                <div className="drag_icons">
                  <DragIcon />

                  <CloseIcon onClick={onDelete} />
                </div>
              )}
              <div className="dashboard_top_selling_products_block">
                {!topSellingProducts.length
                  ? <div className="dashboard_top_selling_products_no_data">No Data Found</div>
                  : topSellingProducts.map((prod) => (
                    <div className="dashboard_top_selling_products_single" key={prod.productId}>
                      <div
                        className="dashboard_top_selling_products_single_left"
                        onClick={() => {
                          navigate(`${+prod.parentId
                            ? `/products/${prod.parentId}?productVariantId=${prod.productId}`
                            : `/products/${prod.productId}`}`);
                        }}
                      >
                        <img className="dashboard_top_selling_products_single_img" src={prod?.image || noImage} />

                        <p className="dashboard_top_selling_products_single_left_name">
                          {prod.title}
                        </p>
                      </div>

                      <p className="dashboard_top_selling_products_single_price">{`$${prod.price}`}</p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardTopSellingProducts;
