import { ReactComponent as VisaLogoSvg } from '../assets/icons/cards/visa_logo.svg';
import { ReactComponent as MastercardLogoSvg } from '../assets/icons/cards/mastercard_logo.svg';
import { ReactComponent as DiscoverLogoSvg } from '../assets/icons/cards/discover_logo.svg';
import { ReactComponent as JCBLogoSvg } from '../assets/icons/cards/jcb_logo.svg';
import { ReactComponent as AMEXLogoSvg } from '../assets/icons/cards/amex_logo.svg';
import { ReactComponent as UnionPayLogoSvg } from '../assets/icons/cards/union_pay_logo.svg';

export const companySizes = [
  { value: '1-5' }, { value: '6-10' }, { value: '11-20' }, { value: '21-50' }, { value: '50+' },
];

export const companyIndustries = [
  {
    title: 'Agricultural and Gardening Products',
  },
  {
    title: 'Animals and Pets',
  },
  {
    title: 'Appliances, Interior Design, Furnishings',
  },
  {
    title: 'Art and Crafts',
  },
  {
    title: 'Audio, Print, and Media',
  },
  {
    title: 'Beer, Wine and Spirits',
  },
  {
    title: 'Diversified Products',
  },
  {
    title: 'Electronics and IT',
  },
  {
    title: 'Energy, Industrial, Construction',
  },
  {
    title: 'Fashion, Clothing, Accessories/Jewellery',
  },
  {
    title: 'Fitness, Sports, and Outdoor',
  },
  {
    title: 'Food and Beverage',
  },
  {
    title: 'Health, Wellness, Cosmetics',
  },
  {
    title: 'Manufacturing and Machinery',
  },
  {
    title: 'Medical and Pharmaceuticals',
  },
  {
    title: 'Toys and Games',
  },
  {
    title: 'Transport Vehicles & Parts',
  },
  {
    title: 'Other',
  },
];

export const cardTypes = [
  {
    type: 'Visa',
    icon: <VisaLogoSvg />,
  },
  {
    type: 'Mastercard',
    icon: <MastercardLogoSvg />,
  },
  {
    type: 'Discover',
    icon: <DiscoverLogoSvg />,
  },
  {
    type: 'JCB',
    icon: <JCBLogoSvg />,
  },
  {
    type: 'Amex',
    icon: <AMEXLogoSvg />,
  },
  {
    type: 'UnionPay',
    icon: <UnionPayLogoSvg />,
  },
];

export const amazonStaticData = [
  {
    countryCode: 'US',
    country: 'United States of America',
    region: 'amazon-us',
  },
  {
    countryCode: 'CA',
    country: 'Canada',
    region: 'amazon-us',
  },
  {
    countryCode: 'MX',
    country: 'Mexico',
    region: 'amazon-us',
  },
  {
    countryCode: 'BR',
    country: 'Brazil',
    region: 'amazon-us',
  },
  {
    countryCode: 'UK',
    country: 'United Kingdom',
    region: 'amazon-eu',
  },
  {
    countryCode: 'ES',
    country: 'Spain',
    region: 'amazon-eu',
  },
  {
    countryCode: 'FR',
    country: 'France',
    region: 'amazon-eu',
  },
  {
    countryCode: 'BE',
    country: 'Belgium',
    region: 'amazon-eu',
  },
  {
    countryCode: 'NL',
    country: 'Netherlands',
    region: 'amazon-eu',
  },
  {
    countryCode: 'DE',
    country: 'Germany',
    region: 'amazon-eu',
  },
  {
    countryCode: 'IT',
    country: 'Italy',
    region: 'amazon-eu',
  },
  {
    countryCode: 'SE',
    country: 'Sweden',
    region: 'amazon-eu',
  },
  {
    countryCode: 'ZA',
    country: 'South Africa',
    region: 'amazon-eu',
  },
  {
    countryCode: 'PL',
    country: 'Poland',
    region: 'amazon-eu',
  },
  {
    countryCode: 'EG',
    country: 'Egypt',
    region: 'amazon-eu',
  },
  {
    countryCode: 'TR',
    country: 'Turkey',
    region: 'amazon-eu',
  },
  {
    countryCode: 'SA',
    country: 'Saudi Arabia',
    region: 'amazon-eu',
  },
  {
    countryCode: 'AE',
    country: 'United Arab Emirates',
    region: 'amazon-eu',
  },
  {
    countryCode: 'IN',
    country: 'India',
    region: 'amazon-eu',
  },
  {
    countryCode: 'AU',
    country: 'Australia',
    region: 'amazon-au',
  },
  {
    countryCode: 'SG',
    country: 'Singapore',
    region: 'amazon-au',
  },
  {
    countryCode: 'JP',
    country: 'Japan',
    region: 'amazon-au',
  },
];

export const orderStatuses = [
  {
    label: 'Shipped',
    value: 'shipped',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Partially shipped',
    value: 'partially_shipped',
  },
  {
    label: 'Delivered',
    value: 'delivered',
  },
  {
    label: 'Partial delivered',
    value: 'partial_delivered',
  },
  {
    label: 'Cancelled',
    value: 'canceled',
  },
];

// paymentStatus: 'paid', 'partial_paid', 'unpaid', 'refunded', 'partial_refunded', 'canceled',

export const paymentStatuses = [
  {
    label: 'Paid',
    value: 'paid',
  },
  {
    label: 'Partial paid',
    value: 'partial_paid',
  },
  {
    label: 'Unpaid',
    value: 'unpaid',
  },
  {
    label: 'Refunded',
    value: 'refunded',
  },
  {
    label: 'Partial refunded',
    value: 'partial_refunded',
  },
  {
    label: 'Cancelled',
    value: 'canceled',
  },
];

export const fileMappingOption = {
  orders: {
    requiredFields: ['sourceId', 'product.title', 'product.sourceId'],
    errorMessage: '"Order id, Product title, Product Id" are required, please select these fields',
    options: [
      {
        label: 'Order id*',
        value: 'sourceId',
        required: true,
      },
      {
        label: 'Product title*',
        value: 'product.title',
        required: true,
      },
      {
        label: 'Product Id*',
        value: 'product.sourceId',
        required: true,
      },
      {
        label: 'Order type',
        value: 'type',
      },
      {
        label: 'Currency',
        value: 'currency',
      },
      {
        label: 'Customer address 1',
        value: 'customer.address1',
      },
      {
        label: 'Customer address 2',
        value: 'customer.address2',
      },
      {
        label: 'Customer city',
        value: 'customer.city',
      },
      {
        label: 'Customer company',
        value: 'customer.company',
      },
      {
        label: 'Customer country code',
        value: 'customer.countryCode',
      },
      {
        label: 'Customer created at',
        value: 'customer.createdAt',
      },
      {
        label: 'Customer email',
        value: 'customer.email',
      },
      {
        label: 'Customer first name',
        value: 'customer.firstName',
      },
      {
        label: 'Customer id',
        value: 'customer.sourceId',
      },
      {
        label: 'Customer last name',
        value: 'customer.lastName',
      },
      {
        label: 'Customer middle name',
        value: 'customer.middleName',
      },
      {
        label: 'Customer phone',
        value: 'customer.phone',
      },
      {
        label: 'Customer postal code',
        value: 'customer.postalCode',
      },
      {
        label: 'Customer region',
        value: 'customer.region',
      },
      {
        label: 'Customer source created at',
        value: 'customer.sourceCreatedAt',
      },
      {
        label: 'Customer source updated at',
        value: 'customer.sourceUpdatedAt',
      },
      {
        label: 'Customer updated at',
        value: 'customer.updatedAt',
      },
      {
        label: 'Note',
        value: 'notes[0].value',
      },
      {
        label: 'Payed at',
        value: 'payedAt',
      },
      {
        label: 'Payment method',
        value: 'paymentMethod',
      },
      {
        label: 'Payment status',
        value: 'sourcePaymentStatus',
      },
      {
        label: 'Product barcode',
        value: 'product.barcode',
      },
      {
        label: 'Product brand',
        value: 'product.brand',
      },
      {
        label: 'Product type',
        value: 'product.type',
      },
      {
        label: 'Product categories',
        value: 'product.categories',
      },
      {
        label: 'Product created at',
        value: 'product.createdAt',
      },
      {
        label: 'Product description',
        value: 'product.description',
      },
      {
        label: 'Product dimensions height',
        value: 'product.productDimensions.dimensions[1]',
      },
      {
        label: 'Product dimensions length',
        value: 'product.productDimensions.dimensions[2]',
      },
      {
        label: 'Product dimensions unit',
        value: 'product.productDimensions.dimensionsUnit',
      },
      {
        label: 'Product dimensions weight',
        value: 'product.productDimensions.weight',
      },
      {
        label: 'Product dimensions weight unit',
        value: 'product.productDimensions.weightUnit',
      },
      {
        label: 'Product dimensions width',
        value: 'product.productDimensions.dimensions[0]',
      },
      {
        label: 'Product from shop',
        value: 'product.fromShop',
      },
      {
        label: 'Product images',
        value: 'product.images[0].src',
      },
      {
        label: 'Product package dimensions Height',
        value: 'product.packageDimensions.dimensions[1]',
      },
      {
        label: 'Product package dimensions Unit',
        value: 'product.packageDimensions.dimensionsUnit',
      },
      {
        label: 'Product package dimensions length',
        value: 'product.packageDimensions.dimensions[2]',
      },
      {
        label: 'Product package dimensions weight',
        value: 'product.packageDimensions.weight',
      },
      {
        label: 'Product package dimensions weight unit',
        value: 'product.packageDimensions.weightUnit',
      },
      {
        label: 'Product package dimensions width',
        value: 'product.packageDimensions.dimensions[0]',
      },
      {
        label: 'Product parent id',
        value: 'product.parentId',
      },
      {
        label: 'Product price',
        value: 'product.price',
      },
      {
        label: 'Product quantity',
        value: 'product.count',
      },
      {
        label: 'Product shipping tax',
        value: 'product.shippingTax',
      },
      {
        label: 'Product short description',
        value: 'product.shortDescription',
      },
      {
        label: 'Product sku',
        value: 'product.sku',
      },
      {
        label: 'Product source url',
        value: 'product.sourceUrl',
      },
      {
        label: 'Product status',
        value: 'product.status',
      },
      {
        label: 'Product subtotal price',
        value: 'product.subtotalPrice',
      },
      {
        label: 'Product tax',
        value: 'product.tax',
      },
      {
        label: 'Product tax percent',
        value: 'product.taxPercent',
      },
      {
        label: 'Product total discount',
        value: 'product.totalDiscount',
      },
      {
        label: 'Product total price',
        value: 'product.totalPrice',
      },
      {
        label: 'Product total shipping price',
        value: 'product.totalShippingPrice',
      },
      {
        label: 'Product updated at',
        value: 'product.updatedAt',
      },
      {
        label: 'Product url',
        value: 'product.stock',
      },
      {
        label: 'Refund reason',
        value: 'refundReason',
      },
      {
        label: 'Shipping tax',
        value: 'shippingTax',
      },
      {
        label: 'Status',
        value: 'sourceStatus',
      },
      {
        label: 'Subtotal price',
        value: 'subtotalPrice',
      },
      {
        label: 'Tax percent',
        value: 'taxPercent',
      },
      {
        label: 'Tax type',
        value: 'taxType',
      },
      {
        label: 'Total',
        value: 'total',
      },
      {
        label: 'Total discount',
        value: 'totalDiscount',
      },
      {
        label: 'Total fees',
        value: 'totalFees',
      },
      {
        label: 'Total refund',
        value: 'totalRefund',
      },
      {
        label: 'Total shipping price',
        value: 'totalShippingPrice',
      },
      {
        label: 'Total tax',
        value: 'totalTax',
      },
      {
        label: 'Created at',
        value: 'createdAt',
      },
      {
        label: 'Updated at',
        value: 'updatedAt',
      },
    ],
  },
  products: {
    requiredFields: ['title', 'sourceId', 'type'],
    errorMessage: '"Title, Product Id, Type" are required, please select these fields',
    options: [
      {
        label: 'Title*',
        value: 'title',
        required: true,
      },
      {
        label: 'Product Id*',
        value: 'sourceId',
        required: true,
      },
      {
        label: 'Type*',
        value: 'type',
        required: true,
      },
      {
        label: 'Tax',
        value: 'tax',
      },
      {
        label: 'SKU',
        value: 'sku',
      },
      {
        label: 'Stock',
        value: 'stock',
      },
      {
        label: 'Price',
        value: 'price',
      },
      {
        label: 'Price',
        value: 'price',
      },
      {
        label: 'Brand',
        value: 'brand',
      },
      {
        label: 'Status',
        value: 'status',
      },
      {
        label: 'Quantity',
        value: 'count',
      },
      {
        label: 'Barcode',
        value: 'barcode',
      },
      {
        label: 'Parent id',
        value: 'parentId',
      },
      {
        label: 'From shop',
        value: 'fromShop',
      },
      {
        label: 'Created At',
        value: 'createdAt',
      },
      {
        label: 'Source url',
        value: 'sourceUrl',
      },
      {
        label: 'Updated At',
        value: 'updatedAt',
      },
      {
        label: 'images',
        value: 'images[0].src',
      },
      {
        label: 'Categories',
        value: 'categories',
      },
      {
        label: 'Total price',
        value: 'totalPrice',
      },
      {
        label: 'Tax percent',
        value: 'taxPercent',
      },
      {
        label: 'Description',
        value: 'description',
      },
      {
        label: 'Shipping tax',
        value: 'shippingTax',
      },
      {
        label: 'Total discount',
        value: 'totalDiscount',
      },
      {
        label: 'Subtotal price',
        value: 'subtotalPrice',
      },
      {
        label: 'Short description',
        value: 'shortDescription',
      },
      {
        label: 'Total shipping price',
        value: 'totalShippingPrice',
      },
      {
        label: 'Package dimensions weight',
        value: 'packageDimensions.weight',
      },
      {
        label: 'Package dimensions weight unit',
        value: 'packageDimensions.weightUnit',
      },
      {
        label: 'Package dimensions dimensions unit',
        value: 'packageDimensions.dimensionsUnit',
      },
      {
        label: 'Package dimensions dimensions width',
        value: 'packageDimensions.dimensions[0]',
      },
      {
        label: 'Package dimensions dimensions height',
        value: 'packageDimensions.dimensions[1]',
      },
      {
        label: 'Package dimensions dimensions length',
        value: 'packageDimensions.dimensions[2]',
      },
      {
        label: 'Product dimensions weight',
        value: 'productDimensions.weight',
      },
      {
        label: 'Product dimensions weight unit',
        value: 'productDimensions.weightUnit',
      },
      {
        label: 'Product dimensions dimensions unit',
        value: 'productDimensions.dimensionsUnit',
      },
      {
        label: 'Product dimensions width',
        value: 'productDimensions.dimensions[0]',
      },
      {
        label: 'Product dimensions height',
        value: 'productDimensions.dimensions[1]',
      },
      {
        label: 'Product dimensions length',
        value: 'productDimensions.dimensions[2]',
      },
    ],
  },
  customers: {
    requiredFields: ['firstName', 'lastName', 'email'],
    errorMessage: '"First name, Last name, Email" are required, please select these fields',
    options: [
      {
        label: 'First name*',
        value: 'firstName',
        required: true,
      },
      {
        label: 'Last name*',
        value: 'lastName',
        required: true,
      },
      {
        label: 'Email*',
        value: 'email',
        required: true,
      },
      {
        label: 'Address 1',
        value: 'address1',
      },
      {
        label: 'Address 2',
        value: 'address2',
      },
      {
        label: 'City',
        value: 'city',
      },
      {
        label: 'Company',
        value: 'company',
      },
      {
        label: 'Country code',
        value: 'countryCode',
      },
      {
        label: 'Created at',
        value: 'createdAt',
      },
      {
        label: 'Customer id',
        value: 'sourceId',
      },
      {
        label: 'Phone',
        value: 'phone',
      },
      {
        label: 'Postal code',
        value: 'postalCode',
      },
      {
        label: 'Region',
        value: 'region',
      },
      {
        label: 'Source created at',
        value: 'sourceCreatedAt',
      },
      {
        label: 'Source updated at',
        value: 'sourceUpdatedAt',
      },
      {
        label: 'Updated at',
        value: 'updatedAt',
      },
    ],
  },
};

export const appInstallFields = {
  bigcommerce: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your app access token',
      placeholder: 'Enter your app access token, e.g. 6fe2as9h89xca9bliadhc0izpi44896',
      path: 'accessToken',
      required: true,
    },
    {
      title: 'Your app hash',
      placeholder: 'Enter your app hash, e.g. 2e67tawf7d',
      path: 'storeHash',
      required: true,
    },
    {
      title: 'Your app client Id',
      placeholder: 'Enter your app client Id, e.g. 6a5ldyxc02mlk17qq0s4wkmb6woqmt0',
      path: 'clientId',
      required: true,
    },
  ],
  shopifyOAuth: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    // {
    //   title: 'Your shop URL (optional)',
    //   placeholder: 'Enter Your shop URL, e.g. https://demo.myshopify.com',
    //   path: 'url',
    // },
  ],
  shopifyManual: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your app URL',
      placeholder: 'Enter your app URL, e.g. https://demo.myshopify.com',
      path: 'url',
      required: true,
    },
    {
      title: 'Your app access token',
      placeholder: 'Enter your app access token, e.g. shpat_07f83aefb4fbt9855a1951d3ddd00089',
      path: 'accessToken',
      required: true,
    },
    {
      title: 'Your app API key',
      placeholder: 'Enter your app API key, e.g. 5ad0792e0b6de96cpo7b5ba609bc8c35',
      path: 'clientId',
      required: true,
    },
    {
      title: 'Your app API secret key',
      placeholder: 'Enter your app API secret key, e.g. 5ff5eaf781540b09f77857673ccc89c1',
      path: 'clientSecret',
      required: true,
    },

  ],
  odoo: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your app URL',
      placeholder: 'Enter Your app URL, e.g https://demo.odoo.com',
      path: 'url',
      required: true,
    },
    {
      title: 'Your app DB',
      placeholder: 'Enter your app DB',
      path: 'db',
      required: true,
    },
    {
      title: 'Your name',
      placeholder: 'Enter your name, e.g. demo@mail.com',
      path: 'username',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
  ],
  'canada-post': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your account id',
      placeholder: 'Enter your account id',
      path: 'accountId',
      required: true,
    },
    {
      title: 'Your first name',
      placeholder: 'Enter your first name',
      path: 'firstName',
      required: true,
    },
    {
      title: 'Your last name',
      placeholder: 'Enter your last name',
      path: 'lastName',
      required: true,
    },
    {
      title: 'Your email',
      placeholder: 'Enter your email',
      path: 'email',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
    {
      title: 'Your company name',
      placeholder: 'Enter your company name',
      path: 'company',
      required: true,
    },
    {
      title: 'Your customer number',
      placeholder: 'Enter your customer number',
      path: 'customerNumber',
      required: true,
    },
    {
      title: 'Your contract id',
      placeholder: 'Enter your contract id',
      path: 'contractId',
      required: true,
    },
    // {
    //   title: 'Your payment method',
    //   placeholder: 'Enter your payment method',
    //   path: 'paymentMethod',
    //   required: true,
    // },
  ],
  'dhl-express': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your account id',
      placeholder: 'Enter your account id',
      path: 'accountId',
      required: true,
    },
    {
      title: 'Your first name',
      placeholder: 'Enter your first name',
      path: 'firstName',
      required: true,
    },
    {
      title: 'Your last name',
      placeholder: 'Enter your last name',
      path: 'lastName',
      required: true,
    },
    {
      title: 'Your email',
      placeholder: 'Enter your email',
      path: 'email',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
    {
      title: 'Your company name',
      placeholder: 'Enter your company name',
      path: 'company',
      required: true,
    },
    {
      title: 'Your site id',
      placeholder: 'Enter your site id',
      path: 'siteId',
      required: true,
    },
    {
      title: 'Your country',
      placeholder: 'Enter your country',
      path: 'country',
      type: 'select',
      valuePath: 'code',
      labelPath: 'name',
      menuPortalTarget: true,
      required: true,
    },
  ],
  'dpd-uk': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your account id',
      placeholder: 'Enter your account id',
      path: 'accountId',
      required: true,
    },
    {
      title: 'Your first name',
      placeholder: 'Enter your first name',
      path: 'firstName',
      required: true,
    },
    {
      title: 'Your last name',
      placeholder: 'Enter your last name',
      path: 'lastName',
      required: true,
    },
    {
      title: 'Your email',
      placeholder: 'Enter your email',
      path: 'email',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
    {
      title: 'Your company name',
      placeholder: 'Enter your company name',
      path: 'company',
      required: true,
    },
    {
      title: 'Your user name',
      placeholder: 'Enter your user name',
      path: 'username',
      required: true,
    },
  ],
  fedex: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your account id',
      placeholder: 'Enter your account id',
      path: 'accountId',
      required: true,
    },
    {
      title: 'Your first name',
      placeholder: 'Enter your first name',
      path: 'firstName',
      required: true,
    },
    {
      title: 'Your last name',
      placeholder: 'Enter your last name',
      path: 'lastName',
      required: true,
    },
    {
      title: 'Your email',
      placeholder: 'Enter your email',
      path: 'email',
      required: true,
    },
    {
      title: 'Your phone',
      placeholder: 'Enter your phone',
      path: 'phone',
      validation: 'phone',
      required: true,
    },
    {
      title: 'Your country',
      placeholder: 'Enter your country',
      path: 'country',
      type: 'select',
      valuePath: 'code',
      labelPath: 'name',
      menuPortalTarget: true,
      required: true,
    },
    {
      title: 'Your state',
      placeholder: 'Enter your state',
      path: 'state',
      type: 'select',
      valuePath: 'code',
      labelPath: 'name',
      menuPortalTarget: true,
      required: true,
    },
    {
      title: 'Your city',
      placeholder: 'Enter your city',
      path: 'city',
      required: true,
    },
    {
      title: 'Your address',
      placeholder: 'Enter your address',
      path: 'address1',
      required: true,
    },
    {
      title: 'Your zip',
      placeholder: 'Enter your zip',
      path: 'zip',
      required: true,
    },
    {
      title: 'Your company name',
      placeholder: 'Enter your company name',
      path: 'company',
      required: true,
    },
  ],
  shipstation: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your account API Key',
      placeholder: 'Enter your account API Key, e.g. 66bcdf7c26bf4acf920ed720834a6907',
      path: 'apiKey',
      required: true,
    },
    {
      title: 'Your account API Secret',
      placeholder: 'Enter your account API Secret, e.g. 5768e517de8442dba7618799805decde',
      path: 'apiSecret',
      required: true,
    },
    {
      title: 'Your account Webhook Key',
      placeholder: 'Enter your account Webhook Key',
      path: 'webhookKey',
    },
  ],
  purolator: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your account id',
      placeholder: 'Enter your account id',
      path: 'accountId',
      required: true,
    },
    {
      title: 'Your first name',
      placeholder: 'Enter your first name',
      path: 'firstName',
      required: true,
    },
    {
      title: 'Your last name',
      placeholder: 'Enter your last name',
      path: 'lastName',
      required: true,
    },
    {
      title: 'Your email',
      placeholder: 'Enter your email',
      path: 'email',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
    {
      title: 'Your company name',
      placeholder: 'Enter your company name',
      path: 'company',
      required: true,
    },
    {
      title: 'Your user name',
      placeholder: 'Enter your user name',
      path: 'username',
      required: true,
    },
  ],
  ups: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your account id',
      placeholder: 'Enter your account id',
      path: 'accountId',
      required: true,
    },
    {
      title: 'Your account number',
      placeholder: 'Enter your account number',
      path: 'accountNumber',
      required: true,
    },
    {
      title: 'Your company name',
      placeholder: 'Enter your company name',
      path: 'company',
      required: true,
    },
    {
      title: 'Your first name',
      placeholder: 'Enter your first name',
      path: 'firstName',
      required: true,
    },
    {
      title: 'Your last name',
      placeholder: 'Enter your last name',
      path: 'lastName',
      required: true,
    },
    {
      title: 'Your email',
      placeholder: 'Enter your email',
      path: 'email',
      required: true,
    },
    {
      title: 'Your phone',
      placeholder: 'Enter your phone',
      path: 'phone',
      validation: 'phone',
      required: true,
    },
    {
      title: 'Your country',
      placeholder: 'Enter your country',
      path: 'country',
      type: 'select',
      valuePath: 'code',
      labelPath: 'name',
      menuPortalTarget: true,
      required: true,
    },
    {
      title: 'Your state',
      placeholder: 'Enter your state',
      path: 'state',
      type: 'select',
      valuePath: 'code',
      labelPath: 'name',
      menuPortalTarget: true,
      required: true,
    },
    {
      title: 'Your city',
      placeholder: 'Enter your city',
      path: 'city',
      required: true,
    },
    {
      title: 'Your address',
      placeholder: 'Enter your address',
      path: 'address1',
      required: true,
    },
    {
      title: 'Your zip',
      placeholder: 'Enter your zip',
      path: 'zip',
      required: true,
    },
  ],
  usps: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your account id',
      placeholder: 'Enter your account id',
      path: 'accountId',
      required: true,
    },
    {
      title: 'Your account number',
      placeholder: 'Enter your account number',
      path: 'accountNumber',
      required: true,
    },
    {
      title: 'Your company name',
      placeholder: 'Enter your company name',
      path: 'company',
      required: true,
    },
    {
      title: 'Your first name',
      placeholder: 'Enter your first name',
      path: 'firstName',
      required: true,
    },
    {
      title: 'Your last name',
      placeholder: 'Enter your last name',
      path: 'lastName',
      required: true,
    },
    {
      title: 'Your email',
      placeholder: 'Enter your email',
      path: 'email',
      required: true,
    },
    {
      title: 'Your phone',
      placeholder: 'Enter your phone',
      path: 'phone',
      validation: 'phone',
      required: true,
    },
    {
      title: 'Your country',
      placeholder: 'Enter your country',
      path: 'country',
      type: 'select',
      valuePath: 'code',
      labelPath: 'name',
      menuPortalTarget: true,
      required: true,
    },
    {
      title: 'Your state',
      placeholder: 'Enter your state',
      path: 'state',
      type: 'select',
      valuePath: 'code',
      labelPath: 'name',
      menuPortalTarget: true,
      required: true,
    },
    {
      title: 'Your city',
      placeholder: 'Enter your city',
      path: 'city',
      required: true,
    },
    {
      title: 'Your address',
      placeholder: 'Enter your address',
      path: 'address1',
      required: true,
    },
    {
      title: 'Your zip',
      placeholder: 'Enter your zip',
      path: 'zip',
      required: true,
    },
  ],
  wayfair: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your app client id',
      placeholder: 'Enter your app client id, e.g. iYTjgrdfFdHosYmorN5jZ5w',
      path: 'clientId',
      required: true,
    },
    {
      title: 'Your app client secret',
      placeholder: 'Enter your app client secret, e.g. KwIdl9Zt014Rd-HVFqms9S6UpPJyek0-rA...',
      path: 'clientSecret',
      required: true,
    },
  ],
  ftp: [
    {
      title: 'Your host',
      placeholder: 'Enter your host',
      path: 'host',
      required: true,
    },
    {
      title: 'Your port',
      placeholder: 'Enter your port',
      path: 'port',
      required: true,
    },
    {
      title: 'Your user',
      placeholder: 'Enter your user',
      path: 'user',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
    {
      title: 'Your file path',
      placeholder: 'Enter your file path',
      path: 'filePath',
      required: true,
    },
  ],
  'b-2-b': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your store domain',
      placeholder: 'Enter your store domain',
      path: 'storeUrl',
      urlPreview: '.eswap.ch',
      required: true,
    },
    {
      title: 'Your store name',
      placeholder: 'Enter your store name',
      path: 'storeName',
      required: true,
    },
    {
      title: 'Your store password (optional)',
      placeholder: 'Enter your store password',
      path: 'password',
      type: 'password',
      // required: true,
    },
  ],

  // cartrover start
  'cartrover-etsy': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
  ],
  'cartrover-houzz': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
    {
      title: 'Your server id',
      placeholder: 'Enter your server id',
      path: 'server_id',
      required: true,
    },
    {
      title: 'Your user key',
      placeholder: 'Enter your user key',
      path: 'user_key',
      required: true,
    },
    {
      title: 'Your account password',
      placeholder: 'Enter your account password',
      path: 'password',
      required: true,
    },
  ],
  'cartrover-magento2': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
    {
      title: 'Your server id',
      placeholder: 'Enter your server id',
      path: 'server_id',
      required: true,
    },
  ],
  'cartrover-walmart': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
    {
      title: 'Your user key',
      placeholder: 'Enter your user key',
      path: 'user_key',
      required: true,
    },
    {
      title: 'Your account password',
      placeholder: 'Enter your account password',
      path: 'password',
      required: true,
    },
  ],
  'cartrover-wayfair': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
    {
      title: 'Your user key',
      placeholder: 'Enter your user key',
      path: 'user_key',
      required: true,
    },
    {
      title: 'Your account password',
      placeholder: 'Enter your account password',
      path: 'password',
      required: true,
    },
  ],
  'cartrover-wish': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
  ],
  'cartrover-extensiv': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
    {
      title: 'Your Server Id',
      placeholder: 'Enter your Server Id',
      path: 'server_id',
      required: true,
    },
    {
      title: 'Your User Key',
      placeholder: 'Enter your User Key',
      path: 'user_key',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
  ],
  'cartrover-extensiv-order': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
  ],
  'google-shopping': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
  ],
  'open-cart': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
    {
      title: 'Your Server Id',
      placeholder: 'Enter your Server Id',
      path: 'server_id',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
  ],
  'presta-shop': [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your API user',
      placeholder: 'Enter your API user',
      path: 'apiUser',
      required: true,
    },
    {
      title: 'Your API key',
      placeholder: 'Enter your API key',
      path: 'apiKey',
      required: true,
    },
    {
      title: 'Your Server Id',
      placeholder: 'Enter your Server Id',
      path: 'server_id',
      required: true,
    },
    {
      title: 'Your password',
      placeholder: 'Enter your password',
      path: 'password',
      required: true,
    },
  ],
  // cartrover end
};
