import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '../../../_common/Modal/Modal';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import { getCountriesRequest, getRegionsRequest } from '../../../../store/actions/app';
import { getSingleOrderRequest, updateOrderRequest } from '../../../../store/actions/orders';

function EditOrderAddress(props) {
  const { isOpen, onClose, onVerify } = props;
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const countries = useSelector((state) => state.app.countries);
  const regions = useSelector((state) => state.app.regions);
  const order = useSelector((state) => state.orders.order);

  useEffect(() => {
    dispatch(getCountriesRequest());
  }, []);

  useEffect(() => {
    dispatch(getRegionsRequest(order.shippingAddress.countryCode));
    setForm(order.shippingAddress);
  }, [order]);


  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleCountryChange = useCallback(async (value) => {
    handleChange('countryCode', value);
    handleChange('region', '');
    setLoading(true);
    await dispatch(getRegionsRequest(value));
    setLoading(false);
  }, [handleChange]);


  const handleSave = useCallback(async () => {
    const data = {
      companyId: order.companyId,
      customerId: order.customerId,
      shippingAddress: {
        countryCode: form.countryCode,
        region: form.region,
        city: form.city,
        title: form.title,
        firstName: form.firstName,
        lastName: form.lastName,
        address1: form.address1,
        address2: form.address2,
        phone: form.phone,
        postalCode: form.postalCode,
      },
      priceListId: order.priceListId,
      status: order.status,
      type: order.type,
      taxType: order.taxType,
      sourceCreatedAt: order.sourceCreatedAt,
      shippingAddressId: order.shippingAddressId,
      paymentTerm: { id: order.paymentTerm.id },
      totalShippingPrice: +order.totalShippingPrice || 0,
      number: order.number,
      currencyCode: order.currencyCode,
      id: order.id,
      orderProducts: order.orderProducts.map((o) => ({
        productId: o.productId,
        id: o.id,
        price: o.price || 0,
        discountPercentage: o.discountPercentage || 0,
        qty: o.qty || 1,
        taxPercentage: o.taxPercentage || 0,
        warehouseId: o.warehouseId,
      })),
    };
    setLoading(true);
    const { payload } = await dispatch(updateOrderRequest(data));
    if (payload.data.status !== 'error') {
      toast.success('Address updated successfully.');
      await dispatch(getSingleOrderRequest(order.id));
      await onVerify(payload.data.order.shippingAddress)
      onClose();
    } else {
      setErrors(payload.data.errors.shippingAddress || {});
    }
    setLoading(false);
  }, [form, order]);

  return (
    <Modal isOpen={isOpen} className="edit_address_modal" onClose={onClose}>
      <div>
        <p className="title">Shipping Address</p>
        <div className="row">

          <Input
            roundBorder
            label="First Name"
            placeholder="First Name"
            onChange={(ev) => handleChange('firstName', ev.target.value)}
            value={_.get(form, 'firstName', '')}
            error={errors.firstName}
          />
          <Input
            roundBorder
            label="Last Name"
            placeholder="Last Name"
            onChange={(ev) => handleChange('lastName', ev.target.value)}
            value={_.get(form, 'lastName', '')}
            error={errors.lastName}
          />
        </div>
        <div className="row">
          <Select
            options={countries}
            roundBorder
            isSearchable
            label="Country/region"
            placeholder="Country"
            onChange={handleCountryChange}
            value={_.get(form, 'countryCode', '')}
            valuePath="code"
            labelPath="name"
            error={errors.countryCode}
          />
          <Select
            options={regions[form.countryCode] || []}
            roundBorder
            label="State"
            placeholder="State"
            loading={loading}
            onChange={(value) => handleChange('region', value)}
            value={regions[form.countryCode]?.find((c) => c.value === form.region)}
            error={errors.region}
            isCreatable
          />
        </div>
        <div className="row">
          <Input
            roundBorder
            label="City"
            placeholder="City"
            onChange={(ev) => handleChange('city', ev.target.value)}
            value={_.get(form, 'city', '')}
            error={errors.city}
          />
          <Input
            roundBorder
            label="Title"
            placeholder="Title"
            onChange={(ev) => handleChange('title', ev.target.value)}
            value={_.get(form, 'title', '')}
            error={errors.title}
          />
        </div>

        <div className="row">
          <Input
            roundBorder
            label="Address 1"
            placeholder="Address 1"
            onChange={(ev) => handleChange('address1', ev.target.value)}
            value={_.get(form, 'address1', '')}
            error={errors.address1}
          />
          <Input
            roundBorder
            label="Address 2"
            placeholder="Address 2"
            onChange={(ev) => handleChange('address2', ev.target.value)}
            value={_.get(form, 'address2', '')}
            error={errors.address2}
          />
        </div>
        <div className="row">
          <Input
            roundBorder
            label="Mobile phone"
            onChange={(ev) => handleChange('phone', ev.target.value)}
            value={_.get(form, 'phone', '')}
            error={errors.phone}
          />
          <Input
            roundBorder
            label="Zip"
            placeholder="Zip"
            onChange={(ev) => handleChange('postalCode', ev.target.value)}
            value={_.get(form, 'postalCode', '')}
            error={errors.postalCode}
          />
        </div>
      </div>
      <div className="actions">
        <Button roundBorder btnType="cancel" onClick={onClose}>Cancel</Button>
        <Button roundBorder onClick={handleSave} loading={loading}>Save changes</Button>
      </div>
    </Modal>
  );
}

export default EditOrderAddress;
