import React from 'react';
import Account from '../../../helpers/Account';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';

function Dashboard() {
  const userIsAdmin = Account.get()?.isAdmin;

  return (
    userIsAdmin ? <AdminDashboard /> : <UserDashboard />
  );
}

export default Dashboard;
