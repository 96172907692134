import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const { REACT_APP_GOOGLE_RECAPTCHA_KEY } = process.env;

function ReCaptcha2({
  onChange,
  size,
  onExpired,
  error
}) {
  useEffect(() => {
    window.handleRecaptchaLoad = () => {
      window.grecaptcha.render('recaptcha2', {
        sitekey: REACT_APP_GOOGLE_RECAPTCHA_KEY,
        callback: onChange,
        'expired-callback': () => {
          window.grecaptcha.reset();
          onExpired();
        },
        size,
      });
    };

    if (window.grecaptcha) {
      window.handleRecaptchaLoad();
    }

    return () => {
      onExpired();

      window.grecaptcha = undefined;
    };
  }, []);

  return (
    <div className="re_captcha" data-theme="light">
      <div id="recaptcha2" />
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js?onload=handleRecaptchaLoad&render=explicit&hl=en"
          async
          defer
        />
      </Helmet>
      {error ? <p className="error_text">{error}</p> : null}
    </div>
  );
}

export default ReCaptcha2;
