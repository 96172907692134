import React, { useEffect, useRef } from 'react';
import {
  Navigate, Route, useLocation,
} from 'react-router-dom';
import Login from './pages/Login';
import Layout from './components/Layout/Layout';
import Signup from './pages/Signup';
import Settings from './pages/Settings';
import GeneralSettingsWrapper from './components/pages/Settings/General/GeneralSettingsWrapper';
import ResetPassword from './pages/ResetPassword';
import Automation from './pages/Automation';
import Warehouses from './pages/Warehouses';
import CreateOrEditWarehouse from './components/pages/Warehouses/CreateOrEditWarehouse';
import Subscription from './components/pages/Settings/PlansBillings/Subscription';
import PricingPlans from './components/pages/Settings/PlansBillings/PricingPlans';
import UpdatePlan from './components/pages/Settings/PlansBillings/UpdatePlan';
import Companies from './components/pages/Settings/Company/Companies';
import Customers from './pages/customers/Customers';
import CustomersForm from './pages/customers/CustomersForm';
import WarehouseBinLocations from './components/pages/Warehouses/WarehouseBinLocations';
import WarehouseProducts from './components/pages/Warehouses/WarehouseProducts';
import ChangePaymentMethod from './components/pages/Settings/PlansBillings/ChangePaymentMethod';
import UpdateProfile from './pages/UpdateProfile';
import SalesOrders from './pages/orders/SalesOrders';
import SalesOrderForm from './pages/orders/SalesOrderForm';
import AppsWrapper from './components/pages/Apps/AppList/AppsWrapper';
import AppInstallWrapper from './components/pages/Apps/AppList/AppInstallWrapper';
import IntegrationStepsWrapper from './components/pages/Apps/AppSteps/IntegrationStepsWrapper';
import AppIntegrationVerify from './pages/AppIntegrationVerify';
import CrmRoles from './components/pages/CRM/CrmRoles';
import CreateRole from './components/pages/CRM/CreateRole';
import CrmUsers from './components/pages/CRM/CrmUsers';
import Plans from './components/pages/Settings/PlansBillings/Plans';
import CreatePlan from './components/pages/Settings/PlansBillings/CreatePlan';
import InvitationVerify from './components/pages/CRM/InvitationVerify';
import EmailTemplate from './components/pages/Settings/EmailTemplates/EmailTemplate';
import EmailTemplates from './components/pages/Settings/EmailTemplates/EmailTemplates';
import Setup from './pages/Setup';
import CreateOrEditAutomation from './pages/CreateOrEditAutomation';
import ShippingTypes from './components/pages/Settings/ShippingTypes/ShippingTypes';
import Currency from './components/pages/Settings/Currency/Currency';
import Tags from './components/pages/Settings/Tags/Tags';
import Brands from './components/pages/Settings/Brands/Brands';
import ProductTypes from './components/pages/Settings/ProductTypes/ProductTypes';
import AdjustmentReasons from './components/pages/Settings/AdjustmentReasons/AdjustmentReasons';
import Packages from './components/pages/Settings/Packages/Packages';
import PriceLists from './components/pages/Inventory/PriceLists/PriceLists';
import EditPriceLists from './components/pages/Inventory/PriceLists/EditPriceLists';
import NotFound from './pages/NotFound';
import SalesOrderPreview from './pages/orders/SalesOrderPreview';
import Profile from './pages/Profile';
import Products from './components/pages/Products/Products';
import MyApps from './components/pages/MyApps/MyApps';
import Categories from './components/pages/Inventory/Categories/Categories';
import Listings from './components/pages/Inventory/Listings/Listings';
import Batches from './components/pages/Inventory/Batches/Batches';
import StockControl from './components/pages/Inventory/StockControl/StockControl';
import ComingSoon from './components/_common/ComingSoon/ComingSoon';
import SalesOrderReturns from './pages/orders/SalesOrderReturns';
import ReturnPreview from './pages/orders/ReturnPreview';
import Overview from './components/pages/Reports/Overview/Overview';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import BuyShippingLabel from './pages/orders/BuyShippingLabel';
import CreatePassword from './pages/CreatePassword';
import MyApp from './components/pages/MyApps/MyApp';
import StockTransfer from './components/pages/StockTransfer/StockTransfer';
import CreateOrEditStockTransfer from './components/pages/StockTransfer/CreateOrEditStockTransfer';
import ReceiveStockTransfer from './components/pages/StockTransfer/ReceiveStockTransfer';
import CreateProduct from './components/pages/Products/CreateProduct';
import Dashboard from './components/pages/Dashboard/Dashboard';
import AdminUsers from './components/pages/AdminUsers/AdminUsers';
import EditUserSubscription from './components/pages/AdminUsers/EditUserSubscription';
import ReceiveStockHistory from './components/pages/StockTransfer/ReceiveStockHistory';
import SingleBatches from './components/pages/Inventory/Batches/SingleBatches';
import EditBatches from './components/pages/Inventory/Batches/EditBatches';
import CreditNotes from './components/pages/Orders/creditNotes/CreditNotes';
import CreditNotesCreate from './components/pages/Orders/creditNotes/CreditNotesCreate';
import ArchivedOrders from './pages/orders/ArchivedOrders';
import Reporting from './components/pages/Reports/Reporting/Reporting';
import InventoryReport from './components/pages/Reports/Reporting/ProductReports/InventoryReport';
import Onboarding from './components/pages/Onboarding/Onboarding';
import StripeLayout from './components/Layout/StripeLayout';
import ImportCsvFile from './components/pages/ImportCSVFile/ImportCSVFile';
import Quotations from './pages/orders/Quotations';
import Invoices from './pages/orders/Invoices';
import InvoicePreview from './pages/orders/InvoicePreview';
import CustomFields from './components/pages/Settings/CustomFields/CustomFields';
import InventoryDetailsReport from './components/pages/Reports/Reporting/ProductReports/InventoryDetailsReport';
import InventoryStockOnHandReport from './components/pages/Reports/Reporting/ProductReports/InventoryStockOnHandReport';
import SalesHistoryReport from './components/pages/Reports/Reporting/SalesReports/SalesHistoryReport';
import SalesByCustomerReport from './components/pages/Reports/Reporting/SalesReports/SalesByCustomerReport';
import SalesByProductReport from './components/pages/Reports/Reporting/SalesReports/SalesByProductReport';
import Shipments from './pages/orders/Shipments';
import CreateOrEditCustomFields from './components/pages/Settings/CustomFields/CreateOrEditCustomFields';
import PurchaseOrders from './pages/orders/PurchaseOrders';
import PurchaseOrderForm from './pages/orders/PurchaseOrderForm';
import InventoryLocationReport from './components/pages/Reports/Reporting/ProductReports/InventoryLocationReport';
import SalesInvoiceHistoryReport from './components/pages/Reports/Reporting/SalesReports/SalesInvoiceHistoryReport';
import SalesShippedItemsReport from './components/pages/Reports/Reporting/SalesReports/SalesShippedItemsReport';
import ReturnsHistoryReport from './components/pages/Reports/Reporting/ReturnReports/ReturnsHistoryReport';
import ReturnsByProductReport from './components/pages/Reports/Reporting/ReturnReports/ReturnsByProductReport';
import PurchaseByProductReport from './components/pages/Reports/Reporting/PurchaseReports/PurchaseByProductReport';
import PurchaseHistoryReport from './components/pages/Reports/Reporting/PurchaseReports/PurchaseHistoryReport';
import PurchaseByCustomerReport from './components/pages/Reports/Reporting/PurchaseReports/PurchaseByCustomerReport';
import PurchasesOrderPreview from './pages/orders/PurchasesOrderPreview';
import ProductStockUpdatingReport from './components/pages/Reports/Reporting/ProductReports/ProductStockUpdatingReport';
import ReturnsByCountryReport from './components/pages/Reports/Reporting/ReturnReports/ReturnsByCountryReport';
import Bills from './pages/orders/Bills';
import PurchaseReorderReport from './components/pages/Reports/Reporting/PurchaseReports/PurchaseReorderReport';
import PurchaseReceiveHistoryReport
  from './components/pages/Reports/Reporting/PurchaseReports/PurchaseReceiveHistoryReport';
import InventoryIncomingStockReport
  from './components/pages/Reports/Reporting/ProductReports/InventoryIncomingStockReport';
import Receives from './pages/orders/Receives';
import ReturnsByCustomer from './components/pages/Reports/Reporting/ReturnReports/ReturnsByCustomer';
import SalesByTimePeriodReport from './components/pages/Reports/Reporting/SalesReports/SalesByTimePeriodReport';
import ReceivePreview from './pages/orders/ReceivePreview';
import SalesPackageHistoryReport from './components/pages/Reports/Reporting/SalesReports/SalesPackageHistoryReport';
import Reorders from './components/pages/Reorders/Reorders';
import CreateReorder from './components/pages/Reorders/CreateReorder';
import ShopifyInstall from './pages/ShopifyInstall';
import ShopifyAuth from './pages/ShopifyAuth';
import RemotePrinting from './components/pages/Settings/RemotePrinting/RemotePrinting';
import Logs from './components/pages/Logs/Logs';
import UserPermissions from './helpers/UserPermissions';
import RoutesWithPermissions from './components/_common/RoutesWithPermissions';
import CreditNotePreview from './pages/orders/CreditNotePreview';

const Navigations = () => {
  const { pathname } = useLocation();
  const prevPath = useRef('');

  useEffect(() => {
    UserPermissions.subscribe();
    return () => {
      UserPermissions.unsubscribe();
    };
  }, []);

  useEffect(() => {
    prevPath.current = pathname;
  }, [pathname]);

  return (
    <RoutesWithPermissions>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/create-password" element={<CreatePassword />} />
      <Route path="/crm/invitation/verify" element={<InvitationVerify />} />
      <Route path="/shopify" element={<ShopifyInstall />} />
      <Route path="/shopify/auth" element={<ShopifyAuth />} />

      <Route path="/integration-verify/:app" element={<AppIntegrationVerify />} />

      <Route path="/" element={<Layout prevPath={prevPath.current} />}>
        <Route index element={<Navigate to="/dashboard" />} />

        {/* Admin routes start */}
        <Route path="roles">
          <Route index element={<PrivateRoute showToAdmin><CrmRoles /></PrivateRoute>} />

          <Route path=":roleId" element={<PrivateRoute showToAdmin><CreateRole /></PrivateRoute>} />
        </Route>

        <Route path="users">
          <Route index element={<PrivateRoute showToAdmin><AdminUsers /></PrivateRoute>} />

          <Route path="edit/:userId" element={<Profile />} />
          <Route path="edit/:userId/subscription" element={<EditUserSubscription />} />
        </Route>

        <Route path="plans">
          <Route index element={<PrivateRoute showToAdmin><Plans /></PrivateRoute>} />

          <Route path=":planId" element={<PrivateRoute showToAdmin><CreatePlan /></PrivateRoute>} />
        </Route>
        {/* Admin routes end */}

        <Route path="profile">
          <Route index element={<Profile />} />

          <Route path="update" element={<UpdateProfile />} />
        </Route>

        <Route path="dashboard" element={<Dashboard />} />

        <Route path="import-csv-file/:type" element={<ImportCsvFile />} />

        <Route path="settings">
          <Route index element={<Settings />} />

          <Route path="general">
            <Route index element={<Navigate to="/404" replace />} />

            <Route path=":type" element={<GeneralSettingsWrapper />} />
          </Route>

          <Route path="plans-billings" element={(<StripeLayout />)}>
            <Route index element={<Navigate to="/404" replace />} />

            <Route path="subscription" element={<Subscription />} />
            <Route path="pricing-plans" element={<PricingPlans />} />
            <Route path="update-plan/:planId" element={<UpdatePlan />} />
            <Route path="change-payment-method" element={<ChangePaymentMethod />} />
            {/* <Route path="plan" element={<GeneralSettingsWrapper />} /> */}
          </Route>

          <Route path="companies">
            <Route scope="READ_COMPANIES" index element={<Companies />} />
          </Route>

          <Route path="shipping-types" element={<ShippingTypes />} />

          <Route path="currency" element={<Currency />} />

          <Route scope="READ_TAGS" path="orders-tags" element={<Tags />} />
          <Route scope="READ_TAGS" path="products-tags" element={<Tags type="products" />} />

          <Route scope="READ_BRANDS" path="brands" element={<Brands />} />

          <Route path="product-types" element={<ProductTypes />} />

          <Route path="adjustment-reasons" element={<AdjustmentReasons />} />

          <Route path="remote-printing" element={<RemotePrinting />} />

          <Route path="custom-fields">
            <Route index element={<CustomFields />} />

            <Route path=":fieldType" element={<CreateOrEditCustomFields />} />
            <Route path=":fieldType/:fieldId" element={<CreateOrEditCustomFields />} />
          </Route>

          <Route path="packages" element={<Packages />} />

          <Route path="email-templates">
            <Route index element={<EmailTemplates />} />

            <Route path=":templateId" element={<EmailTemplate />} />
          </Route>
        </Route>

        <Route path="stocks">
          <Route index element={<Navigate to="/404" replace />} />

          <Route scope="READ_WAREHOUSES" path="warehouses">
            <Route index element={<Warehouses />} />

            <Route path=":warehouseId">
              <Route scope="WRITE_PRODUCTS" index element={<CreateOrEditWarehouse />} />

              <Route
                scope="READ_WAREHOUSES_BIN_LOCATIONS"
                path="bin-locations"
                element={<WarehouseBinLocations />}
              />
              <Route scope="READ_PRODUCTS" path="products" element={<WarehouseProducts />} />
            </Route>
          </Route>

          <Route scope="READ_PURCHASE_ORDERS" path="purchase-orders">
            <Route index element={<PurchaseOrders />} />
            <Route path="create" element={<PurchaseOrderForm />} />
            <Route path="edit/:orderId" element={<PurchaseOrderForm />} />
            <Route path="preview/:orderId" element={<PurchasesOrderPreview />} />
          </Route>
          <Route scope="READ_ORDER_INVOICES" path="bills" element={<Bills />} />
          <Route scope="READ_ORDER_INVOICES" path="bills/preview/:invoiceId" element={<InvoicePreview />} />
          <Route scope="READ_PURCHASE_ORDERS" path="quotations" element={<Quotations />} />

          <Route path="stock-transfer">
            <Route index element={<StockTransfer />} />
            <Route path="create" element={<CreateOrEditStockTransfer />} />
            <Route path="receive/:stockTransferId" element={<ReceiveStockTransfer />} />
            <Route path="received-history/:receiveId" element={<ReceiveStockHistory />} />
            <Route path="edit/:stockTransferId" element={<CreateOrEditStockTransfer />} />
          </Route>

          <Route path="receives" element={<Receives />} />
          <Route path="receives/preview/:receiveId" element={<ReceivePreview />} />

          <Route path="reorders">
            <Route index element={<Reorders />} />
            <Route path=":reorderId" element={<CreateReorder />} />
          </Route>
        </Route>

        <Route path="automation">
          <Route index element={<Automation />} />
          <Route path=":automationId" element={<CreateOrEditAutomation />} />
        </Route>

        <Route scope="READ_INTEGRATIONS" path="apps">
          <Route path="list/:type" element={<AppsWrapper />} />
          <Route path="integration/:integrationId" element={<IntegrationStepsWrapper />} />
          <Route path="cartrover/:app" element={<AppInstallWrapper type="cartrover" />} />
          <Route path=":app" element={<AppInstallWrapper />} />
        </Route>

        <Route scope="READ_INTEGRATIONS" path="my-apps">
          <Route index element={<MyApps />} />
          <Route path=":integrationId/:type" element={<MyApp />} />
        </Route>

        <Route path="crm">
          <Route path="roles">
            <Route index element={<CrmRoles />} />
            <Route path=":roleId" element={<CreateRole />} />
          </Route>

          <Route path="admin-roles">
            <Route index element={<CrmRoles admin />} />

            <Route path=":roleId" element={<CreateRole admin />} />
          </Route>

          <Route path="users" element={<CrmUsers />} />

          <Route path="customers">
            <Route scope="READ_CUSTOMERS" index element={<Customers />} />
            <Route scope="WRITE_CUSTOMERS" path="create" element={<CustomersForm />} />
            <Route scope="WRITE_CUSTOMERS" path="edit/:customerId" element={<CustomersForm />} />
          </Route>
        </Route>

        <Route path="inventory">
          <Route scope="READ_PRICE_LISTS" path="price-lists">
            <Route index element={<PriceLists />} />

            <Route scope="WRITE_PRICE_LISTS" path=":priceId">
              <Route path=":editType" element={<EditPriceLists />} />
            </Route>
          </Route>

          <Route scope="READ_PRODUCTS" path="variants" element={<Products pageName="variants" />} />

          <Route scope="READ_PRODUCT_CATEGORIES" path="categories" element={<Categories />} />

          <Route scope="READ_PRODUCT_LISTINGS" path="listings">
            <Route index element={<Navigate to="/404" replace />} />

            <Route path=":type" element={<Listings />} />
          </Route>

          <Route path="stock-control" element={<StockControl />} />
          <Route scope="READ_PRODUCTS" path="archive" element={<Products pageName="archive" />} />

          <Route scope="READ_PRODUCTS" path="batches">
            <Route index element={<Batches />} />
            <Route path="single/:batchesId" element={<SingleBatches />} />
            <Route scope="WRITE_PRODUCTS" path="edit/:batchesId" element={<EditBatches />} />
          </Route>
        </Route>

        <Route path="orders">
          <Route scope="READ_ORDERS" path="sales-order" element={<SalesOrders />} />
          <Route scope="WRITE_ORDERS" path="sales-order/create" element={<SalesOrderForm />} />
          <Route scope="WRITE_ORDERS" path="sales-order/edit/:orderId" element={<SalesOrderForm />}>
            <Route scope="READ_PRODUCTS" path="products/:productId" element={<CreateProduct />} />
          </Route>
          <Route scope="READ_ORDERS" path="sales-order/preview/:orderId" element={<SalesOrderPreview />} />
          <Route
            scope="WRITE_ORDERS"
            path="sales-order/preview/buy-label/:orderId/:packageId"
            element={<BuyShippingLabel />}
          />
          <Route scope="READ_ORDER_INVOICES" path="invoices" element={<Invoices />} />
          <Route scope="READ_ORDER_INVOICES" path="invoices/preview/:invoiceId" element={<InvoicePreview />} />
          <Route scope="READ_ORDERS" path="quotations" element={<Quotations />} />
          <Route scope="READ_ORDERS" path="shipments" element={<Shipments />} />
          <Route scope="READ_ORDERS" path="returns" element={<SalesOrderReturns />} />
          <Route scope="READ_CREDIT_NOTES" path="credit-notes" element={<CreditNotes />} />
          <Route scope="WRITE_CREDIT_NOTES" path="credit-notes/create" element={<CreditNotesCreate />} />
          <Route scope="WRITE_CREDIT_NOTES" path="credit-notes/preview/:noteId" element={<CreditNotePreview />} />
          <Route scope="READ_ORDER_RETURNS" path="returns/preview/:returnId" element={<ReturnPreview />} />
          <Route scope="READ_ORDERS" path="archive" element={<ArchivedOrders />} />
          <Route scope="WRITE_ORDERS" path="sales-order/edit/:orderId/:quotation" element={<SalesOrderForm />} />
        </Route>

        <Route path="setup" element={<Setup />} />

        <Route path="logs" element={<Logs />} />

        <Route scope="READ_PRODUCTS" path="products">
          <Route index element={<Products />} />
          <Route path=":productId" element={<CreateProduct />} />
        </Route>

        {/* element={<CustomersForm />} */}

        <Route path="reports">
          <Route path="overview" element={<Overview />} />

          <Route path="reporting">
            <Route index element={<Reporting />} />
            <Route path="inventories" element={<InventoryReport />} />
            <Route path="details" element={<InventoryDetailsReport />} />
            <Route path="stock-on-hand" element={<InventoryStockOnHandReport />} />
            <Route path="location" element={<InventoryLocationReport />} />
            <Route path="stock-updating" element={<ProductStockUpdatingReport />} />
            <Route path="incoming" element={<InventoryIncomingStockReport />} />

            <Route path="sales-history" element={<SalesHistoryReport />} />
            <Route path="sales-package-history" element={<SalesPackageHistoryReport />} />
            <Route path="sales-by-product" element={<SalesByProductReport />} />
            <Route path="sales-by-customer" element={<SalesByCustomerReport />} />
            <Route path="sales-invoice-history" element={<SalesInvoiceHistoryReport />} />
            <Route path="sales-shipped-items" element={<SalesShippedItemsReport />} />
            <Route path="sales-by-time" element={<SalesByTimePeriodReport />} />

            <Route path="purchase-by-product" element={<PurchaseByProductReport />} />
            <Route path="purchase-history" element={<PurchaseHistoryReport />} />
            <Route path="purchase-by-customer" element={<PurchaseByCustomerReport />} />
            <Route path="purchase-reorder" element={<PurchaseReorderReport />} />
            <Route path="purchase-receive-history" element={<PurchaseReceiveHistoryReport />} />

            <Route path="returns-history" element={<ReturnsHistoryReport />} />
            <Route path="returns-by-product" element={<ReturnsByProductReport />} />
            <Route path="returns-by-country" element={<ReturnsByCountryReport />} />
            <Route path="returns-by-customer" element={<ReturnsByCustomer />} />
          </Route>

          <Route path="saved-reports" element={<ComingSoon title="Saved reports" />} />
          <Route path="scheduled" element={<ComingSoon title="Scheduled" />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </RoutesWithPermissions>
  );
};

export default Navigations;
