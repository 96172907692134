import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Modal from '../../../../_common/Modal/Modal';
import DynamicTable from '../../../../_common/DynamicTable/DynamicTable';
import noImage from '../../../../../assets/icons/default.svg';
import Datepicker from '../../../../_common/Form/Datepicker';
import Input from '../../../../_common/Form/Input';
import Utils from '../../../../../helpers/Utils';
import Button from '../../../../_common/Form/Button';
import { updateBatchRequest } from '../../../../../store/actions/batches';

const columns = [
  { key: 'number', isEnabled: true },
  { key: 'item', isEnabled: true },
  { key: 'manufacturedDate', isEnabled: true },
  { key: 'expirationDate', isEnabled: true },
  { key: 'quantity', isEnabled: true },
];

const defaultAttributes = {
  number: 'Batch#',
  item: 'Item',
  manufacturedDate: 'Manufactured date',
  expirationDate: 'Expiration date',
  quantity: 'Quantity',
};

const EditBatchModal = memo(({
  singleBatch, setSingleBatch,
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [dateOpenPortal, setDateOpenPortal] = useState();

  useEffect(() => {
    if (!_.isEmpty(singleBatch)) {
      setDateOpenPortal(document.querySelector('#modal'));
    }
  }, [singleBatch]);

  const onClose = () => {
    if (!loading) {
      setSingleBatch({});
    } else {
      toast.info('Please wait while finish request!');
    }
  };

  const onChangeData = (path, value) => {
    setErrors((prev) => ({ ...prev, [path]: '' }));

    setSingleBatch((prev) => ({ ...prev, [path]: value }));
  };

  const onSave = async () => {
    setLoading(true);
    const { payload } = await dispatch(updateBatchRequest(singleBatch));

    if (payload?.data?.status !== 'error') {
      onClose();
      toast.success('Your changes have been successfully saved.');
    } else {
      setErrors(payload.data.errors?.batch || {});
      toast.error('Something went wrong!');
    }

    setLoading(false);
  };

  return (
    <Modal
      className="batch_edit_modal_wrapper"
      isOpen={!_.isEmpty(singleBatch)}
      onClose={onClose}
      zIndex={9998}
    >

      <h2>Edit batch</h2>

      <div className="batches_edit_wrapper">
        <DynamicTable
          data={[singleBatch]}
          keyExtractor={(item) => item.batchNumber}
          columns={columns}
          loading={false}
          renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
          renderColumns={{
            number: ({ item }) => (
              <div className="batches_table_number">
                {item.batchNumber}
              </div>
            ),

            item: ({ item }) => (
              <div className="product_item_wrapper">
                <div className="product_item_img">
                  <img
                    src={item.product.images[0]?.src || item.product.images[0]?.small || noImage}
                    alt="img"
                  />
                </div>

                <div className="product_item_title">
                  <p>{item.product.title}</p>
                </div>
              </div>
            ),

            manufacturedDate: ({ item }) => (
              <div className="batches_date_wrapper">
                <Datepicker
                  onChange={(date) => onChangeData('expirationDateFrom', date)}
                  value={item.expirationDateFrom ? moment(item.expirationDateFrom).format('MM/DD/YYYY') : ''}
                  error={errors.expirationDateFrom}
                  portalPosition="top"
                  wrapperClassName="batches_date"
                  portalContainer={dateOpenPortal}
                />
              </div>

            ),

            expirationDate: ({ item }) => (
              <div className="batches_date_wrapper">
                <Datepicker
                  portalContainer={dateOpenPortal}
                  onChange={(date) => onChangeData('expirationDateTo', date)}
                  value={item.expirationDateTo ? moment(item.expirationDateTo).format('MM/DD/YYYY') : ''}
                  error={errors.expirationDateTo}
                  portalPosition="top"
                  wrapperClassName="batches_date"
                />
              </div>
            ),

            quantity: ({ item }) => (
              <Input
                wrapperClassName="batches_input"
                value={item.qty || ''}
                error={errors.qty}
                onChange={(e) => (onChangeData('qty', e.target.value))}
                onBeforeInput={Utils.typingNumber}
              />
            ),
          }}
        />
      </div>

      <div className="batch_edit_modal_buttons_wrapper">
        <Button
          className="batch_edit_modal_button"
          size="small"
          title="Cancel"
          onClick={onClose}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="batch_edit_modal_button"
          size="small"
          title="Save"
          onClick={onSave}
          roundBorder
          color="#1472FF"
          loading={loading}
        />
      </div>

    </Modal>
  );
});

export default EditBatchModal;
