import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as WooCommerceIcon } from '../../../../assets/icons/apps/woocommerce.svg';
import WoocommerceImg1 from '../../../../assets/images/apps/woocommerce/woocommerce_1.png';
import WoocommerceImg2 from '../../../../assets/images/apps/woocommerce/woocommerce_2.png';
import WoocommerceImg3 from '../../../../assets/images/apps/woocommerce/woocommerce_3.png';
import WoocommerceImg4 from '../../../../assets/images/apps/woocommerce/woocommerce_4.png';
import WoocommerceImg5 from '../../../../assets/images/apps/woocommerce/woocommerce_5.png';
import WoocommerceImg6 from '../../../../assets/images/apps/woocommerce/woocommerce_6.png';
import WoocommerceImg7 from '../../../../assets/images/apps/woocommerce/woocommerce_7.png';
import WoocommerceImg8 from '../../../../assets/images/apps/woocommerce/woocommerce_8.png';
import WoocommerceImg9 from '../../../../assets/images/apps/woocommerce/woocommerce_9.png';
import WoocommerceImg10 from '../../../../assets/images/apps/woocommerce/woocommerce_10.png';
import WoocommerceImg11 from '../../../../assets/images/apps/woocommerce/woocommerce_11.png';
import WoocommerceImg12 from '../../../../assets/images/apps/woocommerce/woocommerce_12.png';
import WoocommerceImg13 from '../../../../assets/images/apps/woocommerce/woocommerce_13.png';
import WoocommerceImg14 from '../../../../assets/images/apps/woocommerce/woocommerce_14.png';
import WoocommerceImg15 from '../../../../assets/images/apps/woocommerce/woocommerce_15.png';
import WoocommerceImg16 from '../../../../assets/images/apps/woocommerce/woocommerce_16.png';
import WoocommerceImg17 from '../../../../assets/images/apps/woocommerce/woocommerce_17.png';
import WoocommerceImg18 from '../../../../assets/images/apps/woocommerce/woocommerce_18.png';
import WoocommerceImg19 from '../../../../assets/images/apps/woocommerce/woocommerce_19.png';
import WoocommerceImg20 from '../../../../assets/images/apps/woocommerce/woocommerce_20.png';
import WoocommerceImg21 from '../../../../assets/images/apps/woocommerce/woocommerce_21.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import formatter from '../../../../helpers/Formatter';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';

const fields = [
  {
    title: 'Your app name',
    placeholder: 'Enter your app name',
    path: 'integrationName',
    required: true,
  },
  {
    title: 'Your app URL',
    placeholder: 'Enter Your app URL, e.g. https://demo.com',
    path: 'url',
    required: true,
  },
];

function WooCommerceInstall() {
  const { app } = useParams();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const response = await Api.installApp(
        app,
        {
          integrationName: data.integrationName,
          url: formatter.url(data.url),
          redirectUrl: `${window.location.origin}/integration-verify/${app}`,
        },
      );

      window.location.href = response.data.params.redirectUrl;
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Woocommerce"
      shopIcon={<WooCommerceIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and"
        + ' process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p>In eSwap you can connect your WooCommerce account with 2 ways.</p>

      <ul>
        <li>
          Navigate to the Apps section in eSwap, particularly under &quot;Ecommerce,&quot; and select
          &quot;WooCommerce.&quot;
        </li>
        <li>Click on &quot;Install app.&quot;</li>
      </ul>

      <p>
        <img
          src={WoocommerceImg12}
          alt="info image"
        />
      </p>

      <p>Click on &quot;Install app.&quot;</p>

      <p>
        <img
          src={WoocommerceImg13}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Enter a desired name for your WooCommerce shop.
        </li>
        <li>Enroll your shop URL.</li>
        <li>Click &quot;Install app&quot; to proceed.</li>
      </ul>

      <p>
        <img
          src={WoocommerceImg14}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          You will be redirected to the WooCommerce website.
        </li>
        <li>Fill in your username and password to log in to your WooCommerce account.</li>
      </ul>

      <p>
        <img
          src={WoocommerceImg15}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          WooCommerce will prompt you to approve starting the integration process.
        </li>
        <li>Click &quot;Approve&quot; to initiate the integration.</li>
      </ul>

      <p>
        <img
          src={WoocommerceImg16}
          alt="info image"
        />
      </p>

      <p className="title">Upon installation, follow these configuration steps:</p>

      <p>
        Choose a warehouse location from existing options or create a new one if
        necessary.
      </p>

      <p>
        <img
          src={WoocommerceImg17}
          alt="info image"
        />
      </p>

      <p>Sync your price list with WooCommerce or create a new one as required.</p>
      <p>
        <img
          src={WoocommerceImg18}
          alt="info image"
        />
      </p>

      <p>
        Configure WooCommerce order statuses to align with existing lines or create
        new ones if necessary.
      </p>

      <p>
        <img
          src={WoocommerceImg19}
          alt="info image"
        />
      </p>

      <ul>
        <li>Decide whether to import data immediately or at a later time.</li>
        <li>The import process will begin based on your selections.</li>
      </ul>

      <p>
        <img
          src={WoocommerceImg20}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Once the configuration steps are completed, click &quot;Done Installation&quot; to finalize the
          setup.
        </li>
        <li>The WooCommerce app will now be accessible from your apps page within eSwap.</li>
      </ul>

      <p>
        <img
          src={WoocommerceImg21}
          alt="info image"
        />
      </p>

      <p>
        By following these steps, you will successfully connect your WooCommerce account to eSwap, enabling
        seamless data synchronization and management between the two platforms.
      </p>

      <br />
      <br />
      <br />
      <br />

      <ul>
        <li>
          With consumer key and consumer secret keys, which you need to generate in
          your WooCommerce store.
        </li>

        <li>Without keys</li>
      </ul>

      <p className="title">
        1. Log in to your WooCommerce store admin panel, go to WooCommerce -&gt;
        Settings -&gt; Advanced -&gt; Rest API -&gt; Add Key.
      </p>

      <p>
        <img
          src={WoocommerceImg1}
          alt="info image"
        />
      </p>

      <p>
        After clicking on the Add key button you’ll be redirected to another page
        where you need to fill key details and select permission type. To fully use
        eSwap -&gt; WooCommerce integration you need to select Read/Write access.
      </p>

      <p>
        <img
          src={WoocommerceImg2}
          alt="info image"
        />
      </p>

      <p>
        After tapping on the

        <strong> Generate API key</strong>
        {' '}
        button you will see
        generated Consumer key and secret which you need to copy and past in eSwap.
      </p>

      <p>
        <img
          src={WoocommerceImg3}
          alt="info image"
        />
      </p>

      <p>
        Now go back to your eSwap account, tap on Available apps -&gt; Sales
        channels -&gt; WooCommerce and click on the
        {' '}
        <strong>Install app</strong>
        {' '}
        button.
      </p>

      <ul>
        <li>Now fill your shop name(e.g. WooCommerce)</li>
        <li>If your shop has SSL certificate choose https:// if not Http://</li>
        <li>Copy and paste Your app URL(e.g. mytestshop.com)</li>
        <li>
          Enable checkbox
          {' '}
          <strong>Install with existing keys</strong>
        </li>
        <li>Paste consumer key in API ket input</li>
        <li>Paste Consumer Secret in Password/API secret input</li>
        <li>Click install app</li>
      </ul>

      <p>
        <img
          src={WoocommerceImg4}
          alt="info image"
        />
      </p>

      <p className="title">
        2. Go to your eSwap account, tap on Available apps -&gt; Sales channels
        -&gt; WooCommerce, and click on the
        {' '}
        <strong>Install app</strong>
        {' '}
        button.
      </p>

      <ul>
        <li>Fill your shop name</li>
        <li>Set https:// and past Your app URL</li>
        <li>Click Install app</li>
        <li>
          You’ll be redirected to your WooCommerce store where you need to approve
          eSwap’s connection to your WooCommerce store.
        </li>
      </ul>

      <p>
        <img
          src={WoocommerceImg5}
          alt="info image"
        />
      </p>

      <p>
        Click
        {' '}
        <strong>Approve.</strong>
      </p>

      <p>
        After connecting your WooCommerce store with one of these ways you need to
        proceed with the next few steps to complete the installation.
      </p>

      <h2>STEP 1</h2>

      <p>
        Configure WooCommerce stock locations. Here you need to choose
        warehouse in eSwap where all of your WooCommerce stock levels will be
        synced.
      </p>

      <p>You can also create a new warehouse, for your WooCommerce store.</p>

      <p>
        <img
          src={WoocommerceImg6}
          alt="info image"
        />
      </p>

      <h2>STEP 2</h2>

      <p>
        Sync your WooCommerce prices in eSwap’s price lists or create a new one for
        each price.
      </p>

      <p>
        <img
          src={WoocommerceImg7}
          alt="info image"
        />
      </p>

      <h2>STEP 3</h2>

      <p>
        On this step, you need to choose the default warehouse where all of your
        WooCommerce orders will be shipped and synced from and from which price list
        incoming orders will be synced.
      </p>

      <p>
        <img
          src={WoocommerceImg8}
          alt="info image"
        />
      </p>

      <h2>STEP 4</h2>

      <p>
        On this step, we ask you to import all your data and automatically sync
        matching products or you can manually import them later from My Apps -&gt;
        WooCommerce page.
      </p>

      <p>
        <img
          src={WoocommerceImg9}
          alt="info image"
        />
      </p>

      <p>
        That’s’ all. On the top, you can see a progress bar that will show what we
        are syncing. You can do other processes until your WooCommerce channel will
        be fully integrated. We’ll email you and notify you in the notification
        section as well.
      </p>

      <p>
        <img
          src={WoocommerceImg10}
          alt="info image"
        />
      </p>

      <p>
        After installation, you can see how many products, orders, and customers are
        imported to eSwap.
      </p>

      <p>
        <img
          src={WoocommerceImg11}
          alt="info image"
        />
      </p>

      <p>That’s all now your installation is complete!</p>

      <UpdateModal
        isOpen={installModal}
        fields={fields}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default WooCommerceInstall;
