import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  NumberParam, StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import Wrapper from '../../../Layout/Wrapper';
import Loader from '../../../_common/Loader/Loader';
import Table from '../../../_common/Tables/Table';
import Api from '../../../../Api';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Pagination from '../../../_common/Pagination/Pagination';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import { ReactComponent as CategoriesNotFoundIcon } from '../../../../assets/icons/no_match.svg';

import TitleBar from '../../../Layout/TitleBar';

const defaultCategoryData = {
  name: '',
  parentId: '',
  description: '',
};

const fields = [
  {
    title: 'Category name',
    path: 'name',
    required: true,
  },
  {
    title: 'Parent category',
    path: 'parentId',
    type: 'select',
    menuPortalTarget: true,
    valuePath: 'id',
    labelPath: 'name',
    isAsync: true,
    defaultOptions: [],
    getFullOption: true,
    getOptionLabel: (option) => {
      if (option?.parents?.length) {
        const parents = option.parents.map((p) => p.name).reverse().join(' / ');

        return `${parents} ${option.name ? ` / ${option.name}` : ''}`;
      }

      return option.name;
    },
  },
  {
    title: 'Description',
    path: 'description',
  },
];

function Categories() {
  const firstLoad = useRef(true);
  const timeout = useRef();

  const [page, setPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 1),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [search, setSearch] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const [tableLoading, setTableLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [updateCategoryId, setUpdateCategoryId] = useState('');
  const [categoryData, setCategoryData] = useState(defaultCategoryData);
  const [categories, setCategories] = useState([]);

  const updateFields = useMemo(
    () => fields.map((field) => (field.path !== 'parentId' ? field
        : {
          ...field,
          defaultOptions: categories,
        }
    )),
    [categories.length, categoryData],
  );

  useEffect(() => {
    clearTimeout(timeout.current);
    setTableLoading(true);

    timeout.current = setTimeout(async () => {
      await getCategories(page, sorting.sort, sorting.sortBy, search);

      setTableLoading(false);
      firstLoad.current = false;
    }, 500);
  }, [page, sorting, search]);

  const getCategories = useCallback(async (newPage, sort, sortBy, s) => {
    const { data } = await Api.getCategories({
      page: newPage,
      includes: 'parents',
      sort,
      sortBy,
      s,
    });

    setTotalPages(data.totalPages);
    setCategories(data.categories);
    setDeleteLoading(false);
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, [sorting]);

  const closeModal = useCallback(() => {
    setUpdateCategoryId('');
    setCategoryData(defaultCategoryData);
  }, []);

  const loadCategories = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getCategories({ s: inputValue, includes: 'parents' });

    callback(data.categories);
  }, []);

  const updateCategory = useCallback((id) => {
    setUpdateCategoryId(id);
    const currentCategory = categories.find((c) => c.id === id);

    setCategoryData({
      ...currentCategory,
      parentId: currentCategory.parents?.[0]?.name
        ? {
          name: '',
          id: currentCategory.parents[0].id,
          parents: currentCategory.parents,
        } : null,
    });
  }, [categories]);

  const deleteCategory = useCallback(async (id) => {
    setDeleteLoading(true);

    await Api.deleteCategory(id);

    if (totalPages !== 1 && categories.length === 1) {
      setPage((prev) => prev - 1);
    } else {
      await getCategories(page, sorting.sort, sorting.sortBy);
    }
  }, [categories, page, sorting]);

  const onSaveCategory = useCallback(async (newData) => {
    if (updateCategoryId === 'add') {
      await Api.createCategory({ ...newData, parentId: newData?.parentId?.id || null });
    } else {
      const sendingData = {
        name: newData.name,
        parentId: newData.parentId?.id || null,
        description: newData.description,
      };

      await Api.updateCategory(newData.id, sendingData);
    }

    const { data } = await Api.getCategories({
      page: page || 1,
      sort: sorting.sort,
      sortBy: sorting.sortBy,
      includes: 'parents',
      s: search,
    });

    setTotalPages(data.totalPages);
    setCategories(data.categories);

    setUpdateCategoryId('');
    setCategoryData(defaultCategoryData);
  }, [categoryData, updateCategoryId, updateCategory]);

  const tableHeader = useMemo(() => [
    {
      title: 'Category name',
      navigate: updateCategory,
      path: 'name',
      // isSelectable: true,
    },
    {
      title: 'Parent',
      path: 'parents',
    },
  ], [updateCategory]);

  return (
    <Wrapper hideBackBtn>
      <TitleBar title="Categories">
        <Button
          title="Create category"
          addBtn
          data-scope="WRITE_PRODUCT_CATEGORIES"
          onClick={() => setUpdateCategoryId('add')}
        />
      </TitleBar>
      <div className="inventory_categories">
        {firstLoad.current ? <Loader /> : (
          <>
            <Input
              placeholder="Search name or address"
              roundBorder
              size="small"
              search
              value={search}
              onChangeText={(value) => setSearch(value)}
              wrapperClassName="company_search_field"
            />

            <div className="inventory_table_wrapper">
              <Table
                data={categories}
                header={tableHeader}
                deleteModalText="category"
                onDeleteClick={(id) => deleteCategory(id)}
                deleteLoading={deleteLoading}
                onEditClick={(id) => updateCategory(id)}
                loading={tableLoading}
                sortBy={sorting.sortBy}
                sort={sorting.sort}
                onSortBy={onSortBy}
                editScope="WRITE_PRODUCT_CATEGORIES"
                deleteScope="WRITE_PRODUCT_CATEGORIES"
                notFound={(
                  <>
                    <CategoriesNotFoundIcon />

                    <h1>Categories not found</h1>

                    <Button
                      title="Create category"
                      onClick={() => setUpdateCategoryId('add')}
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />

              <Pagination onChangePage={(p) => setPage(p)} totalPages={totalPages} />
            </div>
          </>
        )}

        <UpdateModal
          isOpen={!!updateCategoryId}
          onClose={closeModal}
          fields={updateFields}
          onSave={onSaveCategory}
          text="category"
          singleData={categoryData}
          loadMoreOptions={loadCategories}
        />
      </div>
    </Wrapper>
  );
}

export default Categories;
