import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CustomerFormWrapper from '../../components/pages/Customers/CustomerFormWrapper';
import General from '../../components/pages/Customers/formTabs/General';
import Details from '../../components/pages/Customers/formTabs/Details';
import Addresses from '../../components/pages/Customers/formTabs/Addresses';
import { getCountriesRequest } from '../../store/actions/app';
import ContactPerson from '../../components/pages/Customers/formTabs/ContactPerson';
import { removeCustomerFormData } from '../../store/actions/customers';
import CustomFields from '../../components/pages/Customers/formTabs/CustomFields';
import { getSettingsRequest, getTaxTypesRequest, getWarehousesRequest } from '../../store/actions/settings';
import { getPriceListsRequest } from '../../store/actions/priceLists';

function CustomersForm() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'general' });

  useEffect(() => {
    dispatch(getCountriesRequest());
    dispatch(getSettingsRequest(['paymentMethods', 'paymentTerms', 'currencyCode']));
    dispatch(getWarehousesRequest({}));
    dispatch(getTaxTypesRequest({}));
    dispatch(getPriceListsRequest());
  }, []);

  useMemo(() => {
    if (searchParams.get('from') !== 'customFields') {
      dispatch(removeCustomerFormData());
    }
  }, []);

  useEffect(() => {
    if (searchParams.get('from')) {
      searchParams.set('tab', 'customFields');
      setSearchParams(searchParams);
    }
  }, []);

  const handleChangeTab = useCallback((t) => {
    searchParams.set('tab', t);
    setSearchParams(searchParams);
  }, [searchParams]);

  const handleGetTab = useCallback(() => ({
    general: <General />,
    otherDetails: <Details />,
    addresses: <Addresses />,
    contactPerson: <ContactPerson />,
    customFields: <CustomFields />,
  })[searchParams.get('tab')], [searchParams]);

  return (
    <CustomerFormWrapper onTabChange={handleChangeTab} tab={searchParams.get('tab')}>
      {handleGetTab()}
    </CustomerFormWrapper>
  );
}

export default CustomersForm;
