import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import Api from '../../Api';

export const getProductsRequest = createAsyncThunk(
  'products/getProductsRequest',
  async (payload, thunkAPI) => {
    try {
      const { calcStock, ...p } = payload;
      if (!_.isEmpty(p.ids)) {
        p.ids = _.compact(p.ids).join(',');
        p.ids = p.ids || undefined;
      }
      const { data } = await Api.getProducts(p);
      return { data, calcStock };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const clearProductsList = createAction('clear/products/list');

export const getProductsTableAttributes = createAsyncThunk(
  'get/product/table.attributes',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['productsTableAttributes']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getPriseListAndAddTableAttribute = createAsyncThunk(
  'get/prise/list/add/table/attribute',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getPriceList({ page: 1, limit: 100 });

      return data.priceLists;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const changeProductTableAttributeRequest = createAsyncThunk(
  'change/product/table/attributes/request',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.updateSettings(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const changeProductTableAttribute = createAction('change/product/table/attributes');

export const productTableEditableEvent = createAction('product/table/editable/event');

export const bulkEditProductRequest = createAsyncThunk('bulk/edit/product/request', async (payload, thunkAPI) => {
  try {
    return await Api.bulkProductUpdate(
      {
        update: payload.map((p) => ({ ...p, categories: p.categories.map((c) => ({ id: c.id })) })),
      },
    );
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const bulkGenerateBarcodeRequest = createAsyncThunk(
  'bulk/generate/Barcode',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.generateProductBarcode(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const saveDraftReorderProduct = createAction('draft/reorder/product');

export const getProductBatchesRequest = createAsyncThunk(
  'products/getProductBatchesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getProductBatches(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
