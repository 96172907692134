import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import Wrapper from '../../../../Layout/Wrapper';
import Api from '../../../../../Api';
import Input from '../../../../_common/Form/Input';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Select from '../../../../_common/Form/Select';
import Loader from '../../../../_common/Loader/Loader';
import Table from '../../../../_common/Tables/Table';
import Pagination from '../../../../_common/Pagination/Pagination';
import ScheduleModal from '../ScheduleModal';
import { ReactComponent as ExportIcon } from '../../../../../assets/icons/export.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/remote_printing.svg';
import Button from '../../../../_common/Form/Button';

const tableHeader = [
  {
    title: 'Product',
    path: 'productName',
  },
  {
    title: 'SKU',
    path: 'sku',
  },
  {
    title: 'Description',
    path: 'description',
  },
  {
    title: 'Qty sold',
    path: 'qtySold',
  },
  {
    title: 'Total value',
    path: 'totalValue',
  },
];

function SalesShippedItemsReport() {
  const firstLoad = useRef(true);
  const searchTimeout = useRef(null);

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    // sortBy: withDefault(StringParam, 'createdAt'),
    // sort: withDefault(StringParam, 'desc'),
    shopId: withDefault(StringParam, ''),
    startDate: withDefault(StringParam, moment().subtract(1, 'month').format('MM-DD-YYYY')),
    endDate: withDefault(StringParam, moment().format('MM-DD-YYYY')),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [totalPages, setTotalPages] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [shopOptions, setShopOptions] = useState([]);
  const [selectedShop, setSelectedShop] = useState({ id: '', name: 'All shops' });

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    (async () => {
      setTimeout(async () => {
        setTableLoading(true);

        await settingStateFromQuery();

        if (firstLoad.current) {
          const shops = await Api.getIntegrations();

          setShopOptions([{ id: '', name: 'All shops' }, { id: '0', name: 'eSwap' }, ...shops.data.integrations]);
        }

        try {
          await getShippedItems({ allQuery: queryData });
        } catch (e) {
          // navigate('/404');
        }

        setTableLoading(false);
        firstLoad.current = false;
      }, 500);
    })();
  }, [queryData]);

  const settingStateFromQuery = useCallback(async () => {
    let singleShop = { name: 'All shops', id: '' };

    if (queryData.shopId) {
      if (queryData.shopId === '0') {
        singleShop = { name: 'eSwap', id: '0' };
      } else {
        const foundShop = shopOptions.find((sh) => sh.id === queryData.shopId);

        if (foundShop) {
          singleShop = foundShop;
        } else {
          try {
            const shop = await Api.getIntegration(queryData.shopId);

            singleShop = shop.data.integration;
          } catch (e) {
            console.log(e);
          }
        }
      }
    }

    setSelectedShop(singleShop);
  }, [queryData]);

  const getShippedItems = useCallback(async ({ allQuery }) => {
    let requestData = { ...allQuery };

    if (!requestData.shopId) {
      delete requestData.shopId;
    } else {
      requestData = {
        ...requestData,
        shopId: [+requestData.shopId],
      };
    }

    const { data } = await Api.getShippedItemsReport(requestData);

    setTotalPages(data.totalPages);
    setReportData(data.items);
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setQuery((prev) => ({
      ...prev,
      sort: newSorting.sort,
      sortBy: newSorting.sortBy,
    }));
  }, [queryData.sort, queryData.sortBy]);

  const onSchedule = useCallback(() => {
    setScheduleIsOpen(true);
  }, []);

  const onShopChange = useCallback((value) => {
    setSelectedShop(value);

    setQuery((prev) => ({ ...prev, shopId: value.id, page: 1 }));
  }, []);

  const onExport = useCallback(async () => {
    try {
      await Api.exportShippedItemsReport({
        by: {
          startDate: queryData.startDate,
          endDate: queryData.endDate,
          s: queryData.s,
          shopId: selectedShop.id ? [selectedShop.id] : [],
        },
        fields: ['totalValue', 'sku', 'qtySold', 'description', 'productName'],
        fileType: 'csv',
      });

      toast.success('Successfully exported. You will receive an email.');
    } catch (e) {
      toast.error('Something went wrong. Please try later.');
    }
  }, [queryData]);

  const onPrint = () => {
    window.print();
  };

  return (
    <Wrapper
      title="Sales shipped items report"
      onBtnClick={onSchedule}
      buttonTitle="Schedule report"
      btnType="light_blue"
    >
      {tableLoading && firstLoad.current ? <Loader /> : (
        <div className="inventory_report">
          <div className="inventory_report_filters">
            <div className="inventory_report_filter_search">
              <Input
                value={queryData.s}
                placeholder="Search"
                size="small"
                roundBorder
                search
                onChangeText={(val) => setQuery((prev) => ({
                  ...prev,
                  s: val,
                  page: 1,
                }))}
                data-test-id="filter_search"
              />
            </div>

            <div className="inventory_report_filter_right">
              <Button
                onClick={onPrint}
                size="small"
                roundBorder
                iconLeft={<PrintIcon />}
                title="Print"
                btnType="transparent"
                className="print_button"
              />

              {!!reportData.length && (
                <Button
                  onClick={onExport}
                  size="small"
                  roundBorder
                  iconLeft={<ExportIcon />}
                  title="Export"
                  btnType="transparent"
                  className="export_button"
                />
              )}

              <RangeDatePicker
                currentDate={{
                  startDate: queryData.startDate,
                  endDate: queryData.endDate,
                }}
                onChangeDate={(value) => setQuery((prev) => ({
                  ...prev,
                  startDate: value[0],
                  endDate: (value[0] > value[1] || !value[1]) ? value[0] : value[1],
                  page: 1,
                }))}
                calendarClassName="inventory_report_filters_menu_calendar"
                popperPlacement="bottom-start"
              />

              <div className="inventory_report_filter_select">
                <Select
                  value={selectedShop}
                  valuePath="id"
                  options={shopOptions}
                  getFullOption
                  getOptionLabel={(options) => (`${options.name}`)}
                  size="small"
                  roundBorder
                  placeholder="Shops"
                  onChange={(value) => onShopChange(value)}
                  isSearchable
                />
              </div>
            </div>
          </div>

          {firstLoad.current ? <Loader /> : (
            <div className="inventory_report_table">
              <Table
                data={reportData}
                header={tableHeader}
                deleteModalText="user invitation"
                sortBy={queryData.sortBy}
                sort={queryData.sort}
                onSortBy={onSortBy}
                loading={tableLoading}
                hideDeleteButtonFields={['active', 'restricted']}
              />
            </div>
          )}

          <div className="inventory_report_pagination">
            <Pagination
              totalPages={totalPages}
              onChangePage={(p) => setQuery((prev) => ({
                ...prev,
                page: p,
              }))}
            />
          </div>

          <ScheduleModal isOpen={scheduleIsOpen} onClose={() => setScheduleIsOpen(false)} />
        </div>
      )}
    </Wrapper>
  );
}

export default SalesShippedItemsReport;
