import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Checkbox from '../../../_common/Form/Checkbox';
import { cardTypes } from '../../../../helpers/staticData';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import Button from '../../../_common/Form/Button';
// import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';

function PlasticCard({ data, setDeletingId, setCards }) {
  const onDefaultChange = () => {
    setCards((prev) => prev.map((c) => ({ ...c, isDefault: c.id === data.id })));
  };

  return (
    <div className="plastic_card_wrapper">
      <div className="plastic_card_left_block">
        <Checkbox
          onChange={onDefaultChange}
          radio
          className="plastic_card_left_block_radio"
          checked={data.isDefault}
        />

        <div className="plastic_card_left_card_info">
          <div className="plastic_card_left_card_icon">
            {cardTypes.find((c) => c.type.toLowerCase() === data.card.brand.toLowerCase())?.icon}
          </div>

          <div>
            <p className="plastic_card_left_card_name">
              {`${_.upperFirst(data.card.brand)} ${data.card.last4}`}
            </p>

            <p className="plastic_card_left_card_expiry">
              {`
                Expiry on 
                ${data.card.expMonth < 10
                ? `0${data.card.expMonth}` : data.card.expMonth}
                /
                ${data.card.expYear.toString().slice(-2)}
              `}
            </p>
          </div>
        </div>
      </div>

      <div className="plastic_card_right_block">
        {data.isDefault && (
        <div className="plastic_card_right_block_default">
          Default
        </div>
        )}

        <div className="plastic_card_right_buttons_block">
          {/* <Button btnType="transparent" onClick={() => {}}> */}
          {/*  <EditIcon /> */}
          {/* </Button> */}

          <Button btnType="transparent" onClick={() => setDeletingId(data.id)}>
            <DeleteIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

PlasticCard.propTypes = {
  card: PropTypes.object,
  setCards: PropTypes.func,
};

PlasticCard.defaultProps = {
  card: {},
  setCards: () => {},
};
export default PlasticCard;
