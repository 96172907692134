import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SoonIcon } from '../../../assets/icons/cooming_soon.svg';
import Button from '../Form/Button';
import Wrapper from '../../Layout/Wrapper';

function ComingSoon({ title }) {
  return (
    <Wrapper title={title}>
      <div className="coming_soon">
        <SoonIcon />

        <p>This feature is coming soon</p>

        <Button
          href="/dashboard"
          title="Go to dashboard"
          size="small"
          roundBorder
          className="soon_btn"
        />
      </div>
    </Wrapper>
  );
}

ComingSoon.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ComingSoon;
