import React, {
  Fragment, useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as RoundsIcon } from '../assets/icons/rounds.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/cancel.svg';
import Select from '../components/_common/Form/Select';
import Wrapper from '../components/Layout/Wrapper';
import Button from '../components/_common/Form/Button';
import Input from '../components/_common/Form/Input';
import { orderStatuses, paymentStatuses } from '../helpers/staticData';
import MultipleTagInput from '../components/_common/Form/MultipleTagInput';
import Utils from '../helpers/Utils';
import TabButton from '../components/_common/Tabs/TabButton';
import Api from '../Api';
import UpdateModal from '../components/_common/Modal/UpdateModal';
import Loader from '../components/_common/Loader/Loader';
import Switcher from '../components/_common/Form/Switcher';

const statuses = [
  {
    title: 'Inactive',
    value: 'inactive',
  }, {
    title: 'Active',
    value: 'active',
  },
];

const conditionOptions = [
  { label: 'Equals any of', value: '$in', multiple: true },
  { label: 'Does not equal any of', value: '$nin', multiple: true },
  { label: 'Contains all of', value: '$all', multiple: true },
  { label: 'Not contains all of', value: '$not', multiple: false },
  { label: 'Equals', value: '$eq', multiple: false },
  { label: 'Does not equal', value: '$ne', multiple: false },
  {
    label: 'Is less than', value: '$lt', multiple: false, validation: 'number',
  },
  {
    label: 'Is less than or equal to', value: '$lte', multiple: false, validation: 'number',
  },
  {
    label: 'Is greater than', value: '$gt', multiple: false, validation: 'number',
  },
  {
    label: 'Is greater than or equal to', value: '$gte', multiple: false, validation: 'number',
  },
];

const getConditions = (necessaryConditions = []) => (
  conditionOptions.filter(({ value }) => necessaryConditions.includes(value))
);

const emailTemplate = [
  { label: 'Invoice', value: 'invoice' },
  { label: 'Order Confirmation', value: 'orderConfirmation' },
  { label: 'Order Notification', value: 'orderNotification' },
  { label: 'Sales quotation', value: 'salesQuotation' },
];

const events = [
  { label: 'Sales order: Created', value: 'create' },
  { label: 'Sales order: Updated', value: 'update' },
];

// data: [{ warehouseId: 1, qty: { lte: 0 } }],
const staticQueryData = [
  {
    query: {
      $and: [
        {
          orderProduct: {
            $in: [
              '4253',
              '4416',
            ],
          },
        },
        // {
        //   'orderProducts.stock': {
        //     warehousesId: { $eq: 222 },
        //     qty: { $lte: 0 },
        //   },
        // },
      ],
    },
    conditions: [
      {
        type: 'sendEmail',
        data: {
          emailTemplate: 'orderConfirmation',
        },
      },
    ],
  },
];

const id = Symbol('key');

const queryToData = (JSONQuery) => {
  const query = JSON.parse(JSONQuery);

  let selectedProducts = [];
  let selectedMembers = [];
  let selectedRegions = [];

  const generateAndData = (andQuery) => {
    const dataObj = { [id]: _.uniqueId('id_') };

    _.forEach(andQuery, (object, key) => {
      dataObj.trigger = key;

      const [andConditions, value] = Object.entries(object)[0];
      dataObj.conditions = andConditions;

      dataObj.value = value;

      if (key === 'shippingAddress.region') {
        selectedRegions = [{ id: dataObj[id], region: Array.isArray(value) ? value[0] : value }, ...selectedRegions];
      }

      if (key === 'orderProducts.productId') {
        selectedProducts = [...(Array.isArray(value) ? value : [value]), ...selectedProducts];
      }
    });

    return dataObj;
  };

  const data = query.map((q) => ({
    [id]: _.uniqueId('id_'),
    $and: q.query.$and.map((a) => generateAndData(a)),
    conditions: q.conditions.map((c) => {
      if (c.type === 'assignToMember') {
        selectedMembers = [c.data.userId, ...selectedMembers];
      }

      return { ...c, [id]: _.uniqueId('id_') };
    }),
  }));

  return {
    selectedProducts: selectedProducts.length ? _.uniq(selectedProducts).join(',') : null,
    selectedMembers: selectedMembers.length ? _.uniq(selectedMembers).join(',') : null,
    selectedRegions,
    data,
  };
};

const dataToQuery = (data) => (data.map((datum) => ({
  query: { $and: datum.$and.map((d) => ({ [d.trigger]: { [d.conditions]: d.value } })) },
  conditions: datum.conditions,
})));

const defaultData = [
  {
    [id]: _.uniqueId('id_'),
    $and: [
      {
        [id]: _.uniqueId('id_'),
        trigger: '',
        conditions: '',
        value: '',
      },
    ],
    conditions: [
      {
        [id]: _.uniqueId('id_'),
        type: '',
        data: {},
      },
    ],
  },
];

const NULL = {
  valueOf() {
    return null;
  },
  toJSON() {
    return null;
  },
};

const modalInfo = {
  addTagsToOrder: {
    text: 'tag',
    valuePath: 'name',
    fields: [
      {
        title: 'Tag name',
        path: 'name',
        placeholder: 'Enter Tag name',
        required: true,
      },
      {
        title: 'Tag color',
        path: 'color',
        placeholder: 'Enter Tag',
        type: 'colorPicker',
        required: true,
      },
    ],
  },
};

function CreateOrEditAutomation() {
  const { automationId } = useParams();
  const navigate = useNavigate();

  const [workflowData, setWorkflowData] = useState({ name: '', event: '', isActive: true });
  const [workflowQuery, setWorkflowQuery] = useState(defaultData);

  const [errors, setErrors] = useState({});
  const [queryErrors, setQueryErrors] = useState([]);

  const [triggerOptions, setTriggerOptions] = useState({});

  const [triggerCountryCodesForRegion, setTriggerCountryCodesForRegion] = useState([]);
  const [regionLoadingIds, setRegionLoadingIds] = useState([]);

  const [loading, loadingToggle] = useState(true);
  const [saveLoading, saveLoadingToggle] = useState(false);

  const triggers = useMemo(() => [
    {
      label: 'Allocation Warehouses',
      value: 'orderProducts.warehouseId',
      conditions: getConditions(['$in', '$all', '$not']),
      data: triggerOptions.warehouses,
      labelPath: 'title',
      valuePath: 'id',
    },
    {
      label: 'Channel',
      value: 'shopId',
      conditions: getConditions(['$eq', '$ne', '$in', '$nin']),
      data: [{ name: 'Not channel', id: NULL }, ...(triggerOptions.integrations || [])],
      labelPath: 'name',
      valuePath: 'id',
    },
    {
      label: 'Destination Country',
      value: 'shippingAddress.countryCode',
      conditions: getConditions(['$eq', '$ne', '$in', '$nin']),
      data: triggerOptions.countries,
      labelPath: 'name',
      valuePath: 'code',
    },
    {
      label: 'Destination State',
      value: 'shippingAddress.region',
      conditions: getConditions(['$eq', '$ne', '$in', '$nin']),
      data: triggerOptions.regions || [],
      labelPath: 'name',
      valuePath: 'name',
    },
    {
      label: 'Destination Postcode',
      value: 'shippingAddress.postalCode',
      conditions: getConditions(['$eq', '$ne']),
    },
    {
      label: 'Order Has Insufficient Stock',
      value: 'orderProducts.unavailableStocks.warehouseId', // todo
      conditions: getConditions(['$eq']),
      data: [{ warehouseId: 1, qty: { lte: 0 } }],
      // data: dataWarehouses                                ??????????
    },
    {
      label: 'Order Number',
      value: 'number',
      conditions: getConditions(['$eq', '$ne']),
    },
    {
      label: 'Order Status',
      value: 'status',
      conditions: getConditions(['$eq', '$ne', '$in', '$nin']),
      data: orderStatuses,
    },
    {
      label: 'Payment Status',
      value: 'paymentStatus',
      conditions: getConditions(['$eq', '$ne', '$in', '$nin']),
      data: paymentStatuses,
    },
    {
      label: 'Order Total',
      value: 'totalPrice',
      conditions: getConditions(['$eq', '$ne', '$lt', '$lte', '$gt', '$gte']),
    },
    {
      label: 'Order Tag',
      value: 'tags.id',
      conditions: getConditions(['$eq', '$ne', '$in', '$all', '$not']),
      data: triggerOptions.tags,
      labelPath: 'name',
      valuePath: 'id',
    },
    {
      label: 'Total Number Of Items',
      value: 'orderProducts.$size',
      conditions: getConditions(['$eq', '$ne', '$lt', '$lte', '$gt', '$gte']),
    },
    {
      label: 'Order Product',
      value: 'orderProducts.productId',
      conditions: getConditions(['$eq', '$in']),
      data: triggerOptions.products,
      defaultData: triggerOptions.defaultProducts,
      loadOptions: async (value, callback) => {
        const { data } = await Api.getProducts({ s: value });

        setTriggerOptions((prev) => ({ ...prev, products: [...prev.products, ...data.products] }));
        callback(data.products);
      },
      isAsync: true,
      labelPath: 'title',
      valuePath: 'id',
    },
    {
      label: 'Product Count',
      value: 'productsQty',
      conditions: getConditions(['$eq', '$lt', '$lte', '$gt', '$gte']),
      validation: 'number',
    },
  ], [triggerOptions]);

  const conditions = useMemo(() => [
    {
      label: 'Add Notes To Order',
      value: 'addNotesToOrder',
      fields: [
        {
          label: 'Note Value',
          path: 'value',
          type: 'textarea',
        },
      ],
    },
    {
      label: 'Add Tags To Order',
      value: 'addTagsToOrder',
      fields: [
        {
          label: 'Order Tags',
          path: 'tags',
          data: triggerOptions.tags,
          type: 'creatableSearchableMultiselect',
          labelPath: 'name',
          valuePath: 'id',
          createOptionLabel: 'Order tag',
          defaultValue: [],
        },
      ],
    },
    {
      label: 'Assign To Member',
      value: 'assignToMember',
      fields: [
        {
          label: 'Members',
          path: 'userId',
          data: triggerOptions.members,
          defaultData: triggerOptions.defaultMembers,
          loadOptions: async (value, callback) => {
            const { data: { users } } = await Api.getUsersList({ s: value });

            setTriggerOptions((prev) => ({ ...prev, members: [...prev.members, ...users] }));
            callback(users);
          },
          labelPath: 'fullName',
          valuePath: 'id',
          type: 'selectAsyncSearchable',
        },
      ],
    },
    {
      label: 'Assign To Warehouse',
      value: 'assignToWarehouse',
      fields: [
        {
          label: 'Warehouses',
          path: 'warehouseId',
          data: triggerOptions.warehouses,
          labelPath: 'title',
          valuePath: 'id',
          type: 'selectSearchable',
        },
      ],
    },
    {
      label: 'Carrier',
      value: 'carrier',
      fields: [
        {
          label: 'Carriers',
          path: 'carrier',
          data: triggerOptions.shippingIntegrations,
          type: 'selectSearchable',
          labelPath: 'name',
          valuePath: 'id',
        },
      ],
    },
    {
      label: 'Finalize The Order',
      value: 'finalizeTheOrder',
      fields: [],
    },
    {
      label: 'Create Package',
      value: 'createPackage',
      fields: [],
    },
    {
      label: 'Invoice Order',
      value: 'generateInvoice',
      fields: [],
    },
    {
      label: 'Remove Tags',
      value: 'removeTags',
      fields: [
        {
          label: 'Tags',
          path: 'tags',
          data: triggerOptions.tags,
          type: 'selectSearchable',
          labelPath: 'name',
          valuePath: 'id',
        },
      ],
    },
    // {
    //   label: 'Send Email',
    //   value: 'sendEmail',
    //   fields: [
    //     {
    //       label: 'Email Template',
    //       path: 'emailTemplate',
    //       data: emailTemplate,
    //       type: 'select',
    //     },
    //   ],
    // },
    // {
    //   label: 'Send Email To Warehouse',
    //   value: 'sendEmailToWarehouse',
    //   fields: [
    //     {
    //       label: 'Email Template',
    //       path: 'templateId',
    //       data: emailTemplate,
    //       type: 'select',
    //     },
    //     {
    //       label: 'Send to',
    //       path: 'to',
    //       data: [{ label: 'Admin Warehouse', value: 'adminWarehouse' }],
    //       type: 'multiselect',
    //       defaultValue: [],
    //     },
    //     {
    //       label: 'Attach additional files to email',
    //       path: 'attachFiles',
    //       data: [{ label: 'Packing Slip', value: 'packingSlip' }, { label: 'Shipping Label', value: 'shippingLabel' }],
    //       type: 'multiselect',
    //       defaultValue: [],
    //     },
    //     {
    //       label: (
    //         <span>
    //           Emails
    //
    //           <span
    //             style={{
    //               fontSize: 12, color: '#717A8A', fontWeight: 400, paddingLeft: 6,
    //             }}
    //           >
    //             (separate emails using a.svg enter)
    //           </span>
    //         </span>),
    //       path: 'emails',
    //       validation: 'email',
    //       type: 'multiInput',
    //       defaultValue: [],
    //     },
    //   ],
    // },
    // {
    //   label: 'Send Email To Product Vendors',
    //   value: 'sendEmailProductVendors',
    //   fields: [
    //     {
    //       label: 'Email Template',
    //       path: 'emailTemplate',
    //       data: emailTemplate,
    //       type: 'select',
    //     },
    //   ],
    // },
  ], [triggerOptions]);

  useEffect(() => {
    (async () => {
      let defaultProducts;
      let defaultMembers;

      try {
        if (automationId !== 'add') {
          const {
            data: {
              automation: {
                query, name, isActive, event,
              },
            },
          } = await Api.getAutomation(automationId);

          setWorkflowData({ name, event, isActive});

          const {
            data, selectedProducts, selectedMembers, selectedRegions,
          } = queryToData(query);

          defaultProducts = selectedProducts;
          defaultMembers = selectedMembers;

          const { data: { regions } } = await Api.getRegionsByName(selectedRegions.map((r) => r.region).join(','));

          selectedRegions.forEach((s) => {
            setTriggerCountry(s.id, regions.find((r) => r.name === s.region).countryCode);
          });

          setWorkflowQuery(data);
        }
      } catch (e) {
        navigate('/404');
      }

      const [
        { data: { warehouses } },
        { data: { integrations } },
        { data: { countries } },
        { data: { tags } },
        { data: { products } },
        { data: { users: members } },
        { data: { integrations: shippingIntegrations } },
      ] = await Promise.all([
        Api.getWarehouses(), Api.getIntegrations(), Api.getCountries(), Api.getOrdersTags(),
        Api.getProducts({ includeIds: defaultProducts }),
        Api.getUsersList({ includeIds: defaultMembers }),
        Api.getIntegrations({ category: 'shipping' }),
      ]);

      setTriggerOptions({
        warehouses,
        integrations,
        countries,
        tags,
        products,
        defaultProducts: products,
        members,
        defaultMembers: members,
        shippingIntegrations,
      });

      loadingToggle(false);
    })();
  }, []);

  const setTriggerCountry = async (itemId, code) => {
    setTriggerCountryCodesForRegion((prev) => {
      const foundItem = prev.find((item) => item.id === itemId);
      if (foundItem) {
        foundItem.code = code;
        return prev;
      }

      return [...prev, { id: itemId, code }];
    });

    setRegionLoadingIds((prev) => [...prev, itemId]);

    const { data: { regions } } = await Api.getRegions(code);

    setTriggerCountryCodesForRegion((prev) => prev.map((p) => (p.id === itemId ? { ...p, regions } : p)));

    setRegionLoadingIds((prev) => prev.filter((p) => p !== itemId));
  };

  const selectCountryCodesForRegion = useCallback((code, parentId, parentPath, itemId, path, isMulti) => {
    changeQuery(parentId, parentPath, itemId, path, isMulti ? [] : '');

    setTriggerCountry(itemId, code);
  }, []);

  const changeData = useCallback((path, value) => {
    setWorkflowData((prev) => ({ ...prev, [path]: value }));
    setErrors((prev) => ({ ...prev, [path]: false }));
  }, []);

  const [modalData, setModalData] = useState({});

  const changeQuery = useCallback((parentId, parentPath, itemId, path, value, newOption = null) => {
    if (newOption) {
      const valuePath = modalInfo[newOption?.type]?.valuePath;

      setModalData({
        ...newOption, [valuePath]: newOption.label, parentId, parentPath, itemId, path, prevData: value,
      });
    }

    setQueryErrors((prev) => prev.map((p) => {
      if (p.id === parentId && p?.[parentPath]?.[itemId]) {
        return {
          ...p,
          [parentPath]: {
            ...p[parentPath],
            [itemId]: p[parentPath][itemId].filter((e) => e !== path.replace('data.', '')),
          },
        };
      }

      return p;
    }));

    setWorkflowQuery((prevState) => prevState.map((prev) => (prev[id] === parentId
      ? {
        ...prev,
        [parentPath]: prev[parentPath].map((p) => {
          if (p[id] === itemId) {
            const newData = { ...p };

            _.set(newData, path, value);

            if (path === 'trigger') {
              if (triggerCountryCodesForRegion.find((t) => t.id === itemId)) {
                setTriggerCountryCodesForRegion((d) => d.filter((t) => t.id !== itemId));
              }

              newData.conditions = '';
              newData.value = '';
            }

            if (path === 'conditions') {
              const { multiple } = conditionOptions.find((t) => t.value === value);

              newData.value = multiple ? [] : '';
            }

            if (path === 'type') {
              const conditionsFields = conditions.find((t) => t.value === value)?.fields;
              delete newData.data;

              conditionsFields.forEach((f) => {
                _.set(newData, `data.${f.path}`, f?.defaultValue || '');
              });
            }

            return newData;
          }

          return p;
        }),
      }
      : prev)));
  }, [triggerCountryCodesForRegion]);

  const deleteLine = useCallback((parentId, key = '', itemId = null) => {
    setWorkflowQuery((prev) => {
      if (key) {
        return prev.map((p) => {
          if (p[id] === parentId) {
            return { ...p, [key]: p[key].filter((c) => c[id] !== itemId) };
          }

          return p;
        });
      }

      return prev.filter((p) => p[id] !== parentId);
    });

    setQueryErrors((prev) => {
      if (key) {
        return prev.map((p) => {
          if (p.id === parentId && p?.[key]?.[itemId]) {
            return {
              ...p,
              [key]: {
                ...p[key],
                [itemId]: [],
              },
            };
          }

          return p;
        });
      }

      return prev.filter((p) => p.id !== parentId);
    });
  }, []);

  const addNewLine = useCallback((parentIndex, key) => {
    const caseIf = {
      [id]: _.uniqueId('id_'),
      trigger: '',
      conditions: '',
      value: '',
    };

    const caseThen = {
      [id]: _.uniqueId('id_'),
      type: '',
      data: {},
    };

    setWorkflowQuery((prev) => {
      if (key === 'elseIf') {
        return [...prev, { [id]: _.uniqueId('id_'), $and: [caseIf], conditions: [caseThen] }];
      }

      return prev?.map((p, index) => {
        const addingFields = key === '$and' ? caseIf : caseThen;

        if (index === parentIndex) {
          return { ...p, [key]: [...p[key], addingFields] };
        }

        return p;
      });
    });
  }, []);

  const onBeforeMultiInput = useCallback((value, validation) => {
    if (validation === 'email' && !Utils.isEmail(value)) {
      toast.error('Please enter valid email');
      return false;
    }

    return true;
  }, []);

  const saveQuery = useCallback(async () => {
    let hasError = false;

    _.forEach(workflowData, (value, key) => {
      if (typeof value === 'string' && !value.trim()) {
        setErrors((prev) => ({ ...prev, [key]: true }));

        hasError = true;
      }
    });

    setQueryErrors(workflowQuery.map((d) => {
      const err = {
        id: d[id],
        $and: {},
        conditions: {},
      };

      d.$and.forEach((a) => {
        _.forEach(a, (value, key) => {
          if (typeof value === 'number' ? !value
            : typeof value === 'string' ? !value.trim() : _.isEmpty(value)) {
            err.$and[a[id]] = [...(err.$and[a[id]] || []), key];

            hasError = true;
          }
        });
      });

      d.conditions.forEach((c) => {
        if (!c.type) {
          err.conditions[c[id]] = [...(err.conditions[c[id]] || []), 'type'];
          hasError = true;
        }

        _.forEach(c.data, (value, key) => {
          if (typeof value === 'number' ? !value
            : typeof value === 'string' ? !value.trim() : _.isEmpty(value)) {
            err.conditions[c[id]] = [...(err.conditions[c[id]] || []), key];
            hasError = true;
          }
        });
      });

      return err;
    }));

    if (hasError) {
      toast.error('Please fill these fields');
    } else {
      const sendingData = {
        name: workflowData.name,
        event: workflowData.event,
        isActive: workflowData.isActive,
        query: dataToQuery(workflowQuery),
        type: 'sale',
      };

      saveLoadingToggle(true);

      try {
        if (automationId === 'add') {
          await Api.createAutomation(sendingData);
          navigate('/automation');
        } else {
          sendingData.id = automationId;
          await Api.updateAutomation(sendingData);
        }

        toast.success(`Automation successfully ${automationId !== 'add' ? 'updated' : 'created'}`);
      } catch (err) {
        toast.error(err.response.data.message);
      }

      saveLoadingToggle(false);
    }
  }, [workflowData, workflowQuery]);

  const saveModalInfo = useCallback(async (newData) => {
    if (newData.type === 'addTagsToOrder') {
      const {
        parentId, parentPath, itemId, path, prevData, name, color,
      } = newData;

      const { data: { tag } } = await Api.createOrderTag({ name, color });

      setTriggerOptions((prev) => ({ ...prev, tags: [...prev.tags, tag] }));

      changeQuery(parentId, parentPath, itemId, path, [...prevData, tag.id]);
    }

    toast.success(`${_.upperFirst(modalInfo[newData.type].text)} successfully saved`);
    setModalData({});
  }, [modalData]);

  // console.log(workflowQuery, 'data');
  return (
    <Wrapper
      title="Create workflow"
      cancelButtonHref="/automation"
      className="create_automation_page "
      onBtnClick={saveQuery}
      btnLoading={saveLoading}
    >
      {loading
        ? <Loader />

        : (
          <div className="create_automation_page_wrapper">
            <div className="workflow_name_wrapper">
              <div>
                <Input
                  label="Workflow name"
                  placeholder="Enter name"
                  value={workflowData.name}
                  onChangeText={(value) => changeData('name', value)}
                  error={errors.name}
                  roundBorder
                  size="small"
                />

                <Select
                  placeholder="Select event"
                  options={events}
                  label="Event"
                  value={workflowData.event}
                  onChange={(value) => changeData('event', value)}
                  error={errors.event}
                  roundBorder
                  size="small"
                />

                <Switcher
                  label="Status"
                  className="create_automation__swithcher"
                  checked={workflowData.isActive}
                  onChange={(check) => changeData('isActive', check)}
                />
              </div>
            </div>

            <div className="workflow_main_content">
              {workflowQuery.map((list, parentIndex) => (
                <div className="workflow_main_content_fields_block" key={list[id]}>
                  {/* <div className="rounds_wrapper"> */}
                  {/*   {workflowQuery.length > 1 */}
                  {/*     ? ( */}
                  {/*       <button */}
                  {/*         type="button" */}
                  {/*         className="delete_block_btn" */}
                  {/*         onClick={() => deleteLine(list[id])} */}
                  {/*       > */}
                  {/*         <CloseIcon /> */}
                  {/*       </button> */}
                  {/*     ) */}

                  {/*     : ( */}
                  {/*       <RoundsIcon className="round_icon" /> */}
                  {/*     )} */}

                  {/*   <RoundsIcon className="round_icon" /> */}
                  {/* </div> */}

                  {_.map(list, (items, key) => (
                    <div
                      key={key}
                      className="workflow_main_content_fields_wrapper"
                    >
                      {items?.map((item, index) => {
                        const currentTrigger = triggers.find((t) => t.value === item?.trigger);
                        const currentTriggerCondition = conditionOptions.find((t) => t.value === item?.conditions);
                        const currentCondition = conditions.find((t) => t.value === item?.type);

                        const triggerError = queryErrors.find((e) => e.id === list[id]);
                        const triggerValidation = currentTrigger?.validation || currentTriggerCondition?.validation;

                        return (
                          <Fragment key={item[id]}>
                            {key === '$and'
                              ? (
                                <div className="workflow_main_content_fields_if_block">
                                  <div className="workflow_main_content_fields_block_content">
                                    <div className="title">{index ? 'and' : 'if'}</div>

                                    <div className="workflow_main_content_fields">
                                      <Select
                                        options={triggers}
                                        wrapperClassName="workflow_field"
                                        label="Select trigger"
                                        onChange={(value) => {
                                          changeQuery(list[id], key, item[id], 'trigger', value);
                                        }}
                                        error={triggerError?.$and?.[item[id]]?.includes('trigger')}
                                        value={item.trigger}
                                        isSearchable
                                        menuPosition="fixed"
                                        roundBorder
                                        size="small"
                                      />

                                      <Select
                                        options={currentTrigger?.conditions || []}
                                        label="Select condition"
                                        wrapperClassName="workflow_field"
                                        value={item.conditions}
                                        isDisabled={!currentTrigger}
                                        onChange={(value) => {
                                          changeQuery(list[id], key, item[id], 'conditions', value);
                                        }}
                                        error={triggerError?.$and?.[item[id]]?.includes('conditions')}
                                        menuPosition="fixed"
                                        roundBorder
                                        size="small"
                                      />

                                      {currentTrigger?.data
                                        ? (
                                          <div className={classNames('workflow_field', {
                                            region_field: item.trigger === 'shippingAddress.region',
                                          })}
                                          >
                                            {item.trigger === 'shippingAddress.region' && (
                                            <Select
                                              options={triggerOptions.countries}
                                              label="Select"
                                              value={triggerCountryCodesForRegion.find((c) => c.id === item[id])?.code}
                                              onChange={(value) => {
                                                selectCountryCodesForRegion(value, list[id], key, item[id], 'value', currentTriggerCondition?.multiple);
                                              }}
                                              labelPath="name"
                                              valuePath="code"
                                              isDisabled={!item.conditions}
                                              error={triggerError?.$and?.[item[id]]?.includes('value')}
                                              menuPosition="fixed"
                                              roundBorder
                                              isSearchable
                                              size="small"
                                            />
                                            )}

                                            {currentTrigger.isAsync
                                              ? (
                                                <Select
                                                  defaultOptions={currentTrigger.defaultData}
                                                  label="Select"
                                                  value={
                                                  _.isArray(item.value)
                                                    ? item.value.map((v) => (
                                                      currentTrigger.data
                                                        .find((o) => o[currentTrigger.valuePath] === v)))

                                                    : (currentTrigger.data.find((o) => (
                                                      o[currentTrigger.valuePath] === item.value)) || null)
                                                }
                                                  onChange={(option) => {
                                                    const value = _.isArray(option)
                                                      ? option.map((o) => o.value || o[currentTrigger.valuePath])
                                                      : option.value || option[currentTrigger.valuePath];

                                                    changeQuery(list[id], key, item[id], 'value', value);
                                                  }}
                                                  labelPath={currentTrigger.labelPath}
                                                  valuePath={currentTrigger.valuePath}
                                                  isDisabled={!item.conditions}
                                                  error={triggerError?.$and?.[item[id]]?.includes('value')}
                                                  isMulti={currentTriggerCondition?.multiple}
                                                  isSearchable
                                                  isAsync
                                                  loadOptions={currentTrigger.loadOptions}
                                                  getFullOption
                                                  menuPosition="fixed"
                                                  roundBorder
                                                  size="small"
                                                />
                                              )

                                              : (
                                                <Select
                                                  options={item.trigger === 'shippingAddress.region'
                                                    ? (triggerCountryCodesForRegion
                                                      .find((c) => c.id === item[id])?.regions || [])

                                                    : currentTrigger.data}
                                                  label={item.trigger === 'shippingAddress.region' ? '' : 'Select'}
                                                  value={item.value}
                                                  onChange={(value) => {
                                                    changeQuery(list[id], key, item[id], 'value', value);
                                                  }}
                                                  loading={!!regionLoadingIds.find((itemId) => itemId === item[id])}
                                                  labelPath={currentTrigger.labelPath}
                                                  valuePath={currentTrigger.valuePath}
                                                  isDisabled={(item.trigger === 'shippingAddress.region'
                                                          && !!regionLoadingIds.find((itemId) => itemId === item[id]))
                                                    || !item.conditions}
                                                  error={triggerError?.$and?.[item[id]]?.includes('value')}
                                                  isMulti={currentTriggerCondition?.multiple}
                                                  menuPosition="fixed"
                                                  roundBorder
                                                  size="small"
                                                />
                                              )}
                                          </div>
                                        )
                                        : (
                                          currentTriggerCondition?.multiple
                                            ? (
                                              <MultipleTagInput
                                                label={(
                                                  <span>
                                                    Enter value

                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        color: '#717A8A',
                                                        fontWeight: 400,
                                                        paddingLeft: 6,
                                                      }}
                                                    >
                                                      (separate values using a enter)
                                                    </span>
                                                  </span>
                                              )}
                                                value={item.value}
                                                onChange={(value) => {
                                                  changeQuery(list[id], key, item[id], 'value', value);
                                                }}
                                                error={triggerError?.$and?.[item[id]]?.includes('value')}
                                                size="small"
                                                roundBorder
                                              />
                                            )

                                            : (
                                              <Input
                                                label="Enter value"
                                                value={item.value}
                                                onChangeText={(value) => {
                                                  changeQuery(list[id], key, item[id], 'value', value);
                                                }}
                                                onBeforeInput={(e) => {
                                                  Utils.onBeforeInput(e, triggerValidation);
                                                }}
                                                error={triggerError?.$and?.[item[id]]?.includes('value')}
                                                disabled={!item.conditions}
                                                roundBorder
                                                size="small"
                                                wrapperClassName="workflow_field"
                                              />
                                            )
                                        )}
                                    </div>
                                  </div>

                                  {items.length > 1 && (
                                  <div className="delete_btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() => deleteLine(list[id], key, item[id])}
                                    >
                                      <CloseIcon />
                                    </button>
                                  </div>
                                  )}
                                </div>
                              )

                              : (
                                <div className="workflow_main_content_fields_then_block">
                                  <div className="workflow_main_content_fields_block_content">
                                    <div className="title">{index ? 'and' : 'then'}</div>

                                    <div className="workflow_main_content_fields">
                                      <Select
                                        options={conditions}
                                        wrapperClassName="workflow_field"
                                        label="Select trigger"
                                        value={item.type}
                                        onChange={(value) => changeQuery(list[id], key, item[id], 'type', value)}
                                        error={triggerError?.conditions?.[item[id]]?.includes('type')}
                                        menuPosition="fixed"
                                        roundBorder
                                        size="small"
                                      />

                                      {currentCondition?.fields.map((f) => (
                                        f.type.toLowerCase()
                                          .includes('async')
                                          ? (
                                            <Select
                                              key={f.label}
                                              label={f.label}
                                              wrapperClassName="workflow_field"
                                              valuePath={f.valuePath}
                                              getOptionLabel={(o) => (f.labelPath === 'fullName'
                                                ? `${o.firstName} ${o.lastName}`
                                                : (o.label || o[f.labelPath]))}
                                              defaultOptions={f.defaultData}
                                              value={
                                              _.isArray(item.data[f.path])
                                                ? item.data[f.path].map((v) => (
                                                  f.data.find((o) => o[f.valuePath] === v)))

                                                : (f.data.find((o) => (
                                                  o[f.valuePath] === item.data[f.path])) || null)
                                            }
                                              onChange={(option) => {
                                                const value = _.isArray(option)
                                                  ? option.map((o) => o.value || o[f.valuePath])
                                                  : option.value || option[f.valuePath];

                                                changeQuery(list[id], key, item[id], `data.${f.path}`, value);
                                              }}
                                              loadOptions={f.loadOptions}
                                              getFullOption
                                              isAsync
                                              isSearchable
                                              error={triggerError?.conditions?.[item[id]]?.includes(f.path)}
                                              isMulti={f.type.toLowerCase()
                                                .includes('multiselect')}
                                              roundBorder
                                              menuPosition="fixed"
                                              size="small"
                                            />
                                          )
                                          : f.type.toLowerCase()
                                            .includes('creatable')
                                            ? (
                                              <Select
                                                key={f.label}
                                                label={f.label}
                                                options={f.data}
                                                wrapperClassName="workflow_field"
                                                value={
                                                _.isArray(item.data[f.path])
                                                  ? item.data[f.path].map((v) => (
                                                    f.data.find((o) => o[f.valuePath || 'value'] === v)))

                                                  : (currentTrigger.data.find((o) => (
                                                    o[f.valuePath || 'value'] === item.data[f.path])) || null)
                                              }
                                                getFullOption={f.type.toLowerCase()
                                                  .includes('multiselect')
                                                || f.getFullOption}
                                                formatCreateLabel={(value) => `Create ${f.createOptionLabel} "${value}"`}
                                                onChange={(option) => {
                                                  let value = option;
                                                  let newOption = option.__isNew__ ? option : null;

                                                  if (f.type.toLowerCase()
                                                    .includes('multiselect')) {
                                                    value = option.filter((o) => !o.__isNew__)
                                                      .map((o) => o[f.valuePath || 'value']);

                                                    newOption = option.find((o) => o.__isNew__) || null;
                                                  }

                                                  if (newOption) {
                                                    newOption = {
                                                      ...newOption,
                                                      type: item.type,
                                                    };
                                                  }

                                                  changeQuery(list[id], key, item[id], `data.${f.path}`, value, newOption);
                                                }}
                                                valuePath={f.valuePath}
                                                getOptionLabel={(o) => o.label || o[f.labelPath]}
                                                error={triggerError?.conditions?.[item[id]]?.includes(f.path)}
                                                isMulti={f.type.toLowerCase()
                                                  .includes('multiselect')}
                                                isSearchable={f.type.toLowerCase()
                                                  .includes('searchable')}
                                                isCreatable
                                                roundBorder
                                                menuPosition="fixed"
                                                size="small"
                                              />
                                            )

                                            : f.type.toLowerCase().includes('select')
                                              ? (
                                                <Select
                                                  key={f.label}
                                                  wrapperClassName="workflow_field"
                                                  label={f.label}
                                                  value={item.data[f.path]}
                                                  options={f.data}
                                                  getFullOption={f.getFullOption}
                                                  valuePath={f.valuePath}
                                                  labelPath={f.labelPath}
                                                  onChange={(value) => {
                                                    changeQuery(list[id], key, item[id], `data.${f.path}`, value);
                                                  }}
                                                  error={triggerError?.conditions?.[item[id]]?.includes(f.path)}
                                                  isMulti={f.type.toLowerCase()
                                                    .includes('multiselect')}
                                                  isSearchable={f.type.toLowerCase()
                                                    .includes('searchable')}
                                                  roundBorder
                                                  menuPosition="fixed"
                                                  size="small"
                                                />
                                              )

                                              : f.type === 'multiInput'
                                                ? (
                                                  <MultipleTagInput
                                                    key={f.label}
                                                    label={f.label}
                                                    value={item.data[f.path]}
                                                    onChange={(value) => {
                                                      changeQuery(list[id], key, item[id], `data.${f.path}`, value);
                                                    }}
                                                    onBeforeInput={(value) => onBeforeMultiInput(value, f.validation)}
                                                    error={triggerError?.conditions?.[item[id]]?.includes(f.path)}
                                                    size="small"
                                                    roundBorder
                                                  />
                                                )

                                                : (
                                                  <Input
                                                    key={f.label}
                                                    label={f.label}
                                                    value={item.data[f.path]}
                                                    textarea={f.type === 'textarea'}
                                                    onChangeText={(value) => {
                                                      changeQuery(list[id], key, item[id], `data.${f.path}`, value);
                                                    }}
                                                    error={triggerError?.conditions?.[item[id]]?.includes(f.path)}
                                                    roundBorder
                                                    size="small"
                                                    wrapperClassName="workflow_field"
                                                  />
                                                )
                                      ))}
                                    </div>
                                  </div>

                                  {items.length > 1 && (
                                  <div className="delete_btn_wrapper">
                                    <button
                                      type="button"
                                      onClick={() => deleteLine(list[id], key, item[id])}
                                    >
                                      <CloseIcon />
                                    </button>
                                  </div>
                                  )}
                                </div>
                              )}

                            {items.length - 1 === index && (
                            <Button
                              type="button"
                              addBtn
                              roundBorder
                              onClick={() => addNewLine(parentIndex, key)}
                              title="And"
                              className="and_btn"
                              color="#1472FF"
                              btnType="transparent"
                              size="small"
                            />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  ))}

                  {workflowQuery.length > 1 && (
                    <div className="workflow_main_content_fields_block_delete_btn_wrapper">
                      <Button
                        type="button"
                        size="small"
                        onClick={() => deleteLine(list[id])}
                        title="Delete workflow"
                        color="#EB0000"
                        btnType="transparent"
                      />
                    </div>
                  )}
                </div>
              ))}

              <div className="workflow_main_content_else_btn_wrapper">
                <Button
                  type="button"
                  addBtn
                  size="small"
                  roundBorder
                  onClick={() => addNewLine(null, 'elseIf')}
                  title="Else if"
                  className="else_btn"
                  btnType="transparent"
                  color="#1472FF"
                />
              </div>
            </div>
          </div>
        )}

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalInfo[modalData?.type]?.fields || []}
        onClose={() => setModalData({})}
        onSave={saveModalInfo}
        singleData={modalData}
        text={modalInfo[modalData?.type]?.text}
      />
    </Wrapper>
  );
}

export default CreateOrEditAutomation;
