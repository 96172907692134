import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  ArrayParam,
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../Api';
import Wrapper from '../../../Layout/Wrapper';
import Loader from '../../../_common/Loader/Loader';
import Table from '../../../_common/Tables/Table';
import Pagination from '../../../_common/Pagination/Pagination';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import WarningModal from '../../../_common/Modal/WarningModal';
import Filters from '../../Products/Filters';
import Utils from '../../../../helpers/Utils';
import Button from '../../../_common/Form/Button';
import { ReactComponent as ControlNotFoundIcon } from '../../../../assets/icons/no_match.svg';

const fields = [
  {
    title: 'Reason title',
    placeholder: 'Enter new reason title',
    path: 'reason',
    required: true,
  },
];
const defaultFiltersParams = withDefault(ArrayParam, []);

const productFilterOptions = {
  type: {
    label: 'Product type',
    path: 'type',
    valuePath: 'type',
    staticData: [
      {
        label: 'Variants',
        id: 'variant',
      },
      {
        label: 'Simples',
        id: 'simple',
      },
    ],
  },
  totalStock: {
    label: 'Stocks',
    path: 'totalStock',
    valuePath: 'totalStock',
    staticData: [
      {
        label: 'Out',
        id: ',0',
      },
      {
        label: 'Low',
        id: '1,5',
      },
    ],
  },
  shopId: {
    label: 'Shop',
    path: 'shop',
    valuePath: 'shopId',
    localSearch: true,
  },
  warehouseId: {
    label: 'Warehouses',
    path: 'warehouses',
    valuePath: 'warehouseId',
    localSearch: true,
  },
  brandId: {
    label: 'Brands',
    path: 'brand',
    valuePath: 'brandId',
  },
  categoryId: {
    label: 'Category',
    path: 'category',
    valuePath: 'categoryId',
  },
};

const defaultFilterData = [
  { label: 'Product type', valuePath: 'type' },
  { label: 'Stocks', valuePath: 'totalStock' },
  { label: 'Brands', valuePath: 'brandId' },
  { label: 'Shop', valuePath: 'shopId' },
  { label: 'Warehouses', valuePath: 'warehouseId' },
  { label: 'Category', valuePath: 'categoryId' },
];

const loadOptions = async (query, filter, { search, page }) => {
  if (filter.staticData) {
    return { data: filter.staticData };
  }

  let data;
  let totalPages;

  if (filter.path === 'warehouses') {
    const { data: { warehouses: warehouseList } } = await Api.getWarehouses();

    data = warehouseList.map((warehouse) => ({ id: warehouse.id, label: warehouse.title }));
  }

  if (filter.path === 'shop') {
    const { data: { integrations } } = await Api.getIntegrations({ category: 'shop' });

    data = [{ id: '0', label: 'eSwap' }, ...integrations.map((s) => ({ id: s.id, label: s.name }))];
  }

  if (filter.path === 'category') {
    const categoriesData = await Api.getCategories(Utils.deleteEmptyKeys({
      page: search ? 1 : page,
      s: search,
      includeIds: page === 1 ? query[filter.valuePath].join(',') : '',
    }));

    data = categoriesData.data.categories.map((s) => ({ id: s.id, label: s.name }));

    totalPages = search ? 1 : categoriesData.data.totalPages;
  }

  if (filter.path === 'brand') {
    const brandesData = await Api.getBrands(Utils.deleteEmptyKeys({
      page: search ? 1 : page,
      s: search,
      includeIds: page === 1 ? query[filter.valuePath].join(',') : '',
    }));

    data = brandesData.data.brands.map((s) => ({ id: s.id, label: s.name }));

    totalPages = search ? 1 : brandesData.data.totalPages;
  }

  return { data, totalPages };
};

const StockControl = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    categoryId: defaultFiltersParams,
    brandId: defaultFiltersParams,
    totalStock: defaultFiltersParams,
    type: defaultFiltersParams,
    warehouseId: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  // const [sorting, setSorting] = useQueryParams({
  //   sortBy: withDefault(StringParam, 'createdAt'),
  //   sort: withDefault(StringParam, 'desc'),
  // }, { updateType: 'replaceIn' });

  const firstLoad = useRef(false);
  const timeout = useRef();

  const [stockData, setStockData] = useState({
    list: [],
    totalPages: 1,
  });

  const { list, totalPages } = stockData;

  const [warehouses, setWarehouses] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [unknownReasons, setUnknownReasons] = useState([]);

  const [changedQty, setChangingQty] = useState([]);

  const [reasonModalInfo, setReasonModalInfo] = useState({});
  const [maximumQtyModal, maximumQtyModalToggle] = useState(false);

  const [loading, loadingToggle] = useState(true);
  const [saveLoading, saveLoadingToggle] = useState('');

  const productNavigate = (id) => {
    const product = list.find((l) => l.id === id);

    navigate(product.type === 'variant'
      ? `/products/${product.parentId}?productVariantId=${product.id}`
      : `/products/${product.id}`);
  };

  const tableHeader = useMemo(() => [
    {
      title: 'Item',
      path: 'productItem',
      navigate: productNavigate,
    },
    {
      title: 'SKU',
      path: 'sku',
    },
    {
      title: 'Shop',
      path: 'shopType',
    },
    {
      title: 'Stock',
      path: 'quantity',
    },
    {
      title: 'Warehouse',
      path: 'warehouseId',
      fieldType: 'select',
      options: warehouses,
      valuePath: 'id',
      labelPath: 'title',
    },
    {
      title: 'Quantity',
      path: 'quantity',
      fieldType: 'input',
      placeholder: '0',
      validation: 'positiveNumber',
      maxLength: 9,
    },
    {
      title: 'Reason',
      path: 'reason',
      fieldType: 'select',
      addButtonText: 'New reason',
      options: _.uniqBy([...reasons.map((r) => ({ label: r, value: r })), ...unknownReasons], 'value'),
    },
  ], [warehouses, reasons, unknownReasons, list]);

  const getProducts = async (reason, firstWarehouse = warehouses[0]?.id, changedQtyData = []) => {
    const { data } = await Api.getProducts({
      // limit: 20,
      includes: 'stock,warehouse',
      ...filters,
      type: filters.type.join(',') || 'simple,variant',
      warehouseId: filters.warehouseId.join(',') || null,
      brandId: filters.brandId.join(',') || null,
      shopId: filters.shopId.join(',') || null,
      categoryId: filters.categoryId.join(',') || null,
      totalStock: filters.totalStock.length === 2 ? ',5' : (filters.totalStock.join(',') || null),
    });

    setUnknownReasons(_.uniqBy(data.products.map((p) => p.stocks.map((s) => (
      { label: s.lastHistory?.reason, value: s.lastHistory?.reason, disabled: true }
    ))).flat(1).filter((r) => r.value), 'value'));

    setStockData({
      list: data.products.map((p) => {
        const foundChangedQty = changedQtyData.find((q) => q.productId === p.id);

        let { stocks } = p;

        if (foundChangedQty) {
          const { warehouses: stockLists, productId } = foundChangedQty;
          const qtyList = stockLists.map((s) => ({ ...s, productId, stock: s.quantity }));

          stocks = _.uniqBy([...qtyList, ...stocks], 'warehouseId');
        }

        return {
          ...p,
          warehouseId: stocks?.[0]?.warehouseId || firstWarehouse || null,
          quantity: stocks?.[0]?.stock || '',
          reason: stocks?.[0]?.lastHistory?.reason || reason,
          stocks,
        };
      }),
      totalPages: data.totalPages,
    });
  };

  useEffect(() => {
    loadingToggle(true);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      let [firstReason] = reasons;
      let [firstWarehouse] = warehouses;

      if (!firstLoad.current) {
        const { data: { warehouses: warehouseLists } } = await Api.getWarehouses();
        const { data: { stockReasons } } = await Api.getSettings(['stockReasons']);

        setWarehouses(warehouseLists);
        setReasons(stockReasons);

        [firstReason] = stockReasons;
        [firstWarehouse] = warehouseLists;
      }

      await getProducts(firstReason, firstWarehouse.id, changedQty);

      loadingToggle(false);
      firstLoad.current = true;
    }, 500);
  }, [filters]);

  const saveReason = useCallback(async (data) => {
    const newReasons = [...reasons, data.reason];

    await Api.updateSettings({ stockReasons: newReasons });
    setReasons(newReasons);
    changeData(reasonModalInfo.id, reasonModalInfo.path, data.reason);
    setReasonModalInfo({});
  }, [reasonModalInfo, reasons]);

  const changeData = useCallback((id, path, value) => {
    const changedQtyArray = changedQty.map((q) => q.warehouses.map((w) => ({ ...w, productId: q.productId }))).flat(1);
    const foundWarehouseId = list.find((q) => q.id === id)?.warehouseId;
    const foundChangingItem = changedQtyArray
      .find((arr) => arr.productId === id && arr.warehouseId === foundWarehouseId);

    if (changedQtyArray.length === 100 && !foundChangingItem) {
      maximumQtyModalToggle(true);
    } else if (value.addNew) {
      setReasonModalInfo({ id, path });
    } else {
      setStockData((prev) => {
        const newData = { ...prev };

        if (path === 'warehouseId') {
          newData.list = newData.list.map((l) => (l.id === id
            ? {
              ...l,
              warehouseId: value,
              quantity: l.stocks.find((s) => s.warehouseId === value)?.stock || '',
              reason: l.stocks.find((s) => s.warehouseId === value)?.lastHistory?.reason || 'Correction',
            }

            : l));
        } else {
          newData.list = newData.list.map((l) => {
            if (l.id === id) {
              setChangingQty((prevQty) => {
                const newQtyData = { warehouseId: l.warehouseId, quantity: l.quantity, reason: l.reason };

                // if (!l.warehouses.find((w) => w.id === l.warehouseId)) {
                //   newQtyData.new = true;
                // }

                newQtyData[path] = value;

                if (prevQty.find((q) => q.productId === id)) {
                  return prevQty.map((q) => (q.productId === id
                    ? {
                      ...q,
                      warehouses: q.warehouses.find((w) => w.warehouseId === l.warehouseId)
                        ? q.warehouses.map((w) => (w.warehouseId === l.warehouseId ? newQtyData : w))

                        : [...q.warehouses, newQtyData],
                    } : q));
                }

                return [...prevQty, { productId: id, warehouses: [newQtyData] }];
              });

              const newStockData = {
                productId: id, warehouseId: l.warehouseId, stock: l.quantity, reason: l.reason,
              };

              newStockData[path === 'reason' ? 'reason' : 'stock'] = value;

              return {
                ...l,
                [path]: value,
                stocks: l.stocks.find((s) => s.warehouseId === l.warehouseId)
                  ? l.stocks.map((s) => (s.warehouseId === l.warehouseId
                    ? {
                      ...s,
                      [path === 'reason' ? 'reason' : 'stock']: value,
                    }
                    : s))

                  : [...l.stocks, newStockData],
              };
            }

            return l;
          });
        }

        return newData;
      });
    }
  }, [changedQty, list]);

  const onSaveQty = useCallback(async (fromModal) => {
    if (changedQty.length) {
      saveLoadingToggle(fromModal ? 'modal' : 'default');

      const sendingData = changedQty.map((q) => ({
        ...q,
        warehouses: q.warehouses.map((w) => ({ ...w, quantity: w.quantity || 0 })),
      }));

      setChangingQty([]);
      await Api.getCreateStockControl(sendingData);

      saveLoadingToggle('');
      maximumQtyModalToggle(false);
    }

    toast.success('Successfully updated');
  }, [changedQty]);

  const onCancelQty = useCallback(async () => {
    loadingToggle(true);
    setChangingQty([]);

    await getProducts(reasons[0]);

    loadingToggle(false);
  }, [reasons]);

  return (
    <Wrapper
      title="Stock Control"
      onBtnClick={firstLoad.current ? () => onSaveQty(false) : null}
      btnLoading={saveLoading === 'default'}
      onCancelBtnClick={firstLoad.current ? onCancelQty : null}
      className="stock_control_page"
      buttonTitle="Save"
      cancelButtonTitle="Cancel"
    >
      {!firstLoad.current
        ? <Loader />

        : (
          <>
            <div className="logs_search_field_wrapper">
              <Filters
                queryData={filters}
                setQuery={setFilters}
                defaultFilterData={defaultFilterData}
                filterOptions={productFilterOptions}
                loadOptions={(filter, searchFilters) => loadOptions(filters, filter, searchFilters)}
              />
            </div>

            <div className="tags_table_wrapper stock_control">
              <Table
                data={list}
                header={tableHeader}
                // sortBy={sortBy}
                // sort={sort}
                // onSortBy={onSortBy}
                loading={loading}
                onChange={changeData}
                notFound={(
                  <>
                    <ControlNotFoundIcon />

                    <h1>Stock control not found</h1>

                    <Button
                      title="Create product"
                      href="/products/add"
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />

              <Pagination
                onChangePage={(p) => setFilters((prev) => ({ ...prev, page: p }))}
                totalPages={totalPages}
              />
            </div>
          </>
        )}

      <UpdateModal
        isOpen={!_.isEmpty(reasonModalInfo)}
        fields={fields}
        onSave={saveReason}
        onClose={() => setReasonModalInfo({})}
        fullTitle="New reason"
        btnText="Save"
      />

      <WarningModal
        isOpen={maximumQtyModal}
        onClose={() => maximumQtyModalToggle(false)}
        onSaveClick={() => onSaveQty(true)}
        loading={saveLoading === 'modal'}
        text="Maximum update qty is 100, please save to continue!"
        btnText="Save"
      />
    </Wrapper>
  );
};

StockControl.propTypes = {

};

export default StockControl;
