import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import Table from '../../../_common/Tables/Table';
import Select from '../../../_common/Form/Select';

function ProductVariants({
  onDataChange, variants, setVariantId, warehouses, selectedWarehouse, productHasBundle, priceLists, selectedPrice,
  onHeaderChange, productHasOption, errors,
}) {
  const tableHeader = [
    {
      title: 'Variant',
      path: 'productItem',
      navigate: (id) => setVariantId(id),
    },
    {
      title: 'Price',
      path: 'price',
      fieldType: 'input',
      validation: 'number',
      headerType: 'select',
      valuePath: 'id',
      labelPath: 'name',
      value: '',
      options: [],
    },
    {
      title: 'Quantity',
      path: 'quantity',
      fieldType: 'input',
      validation: 'number',
    },
    {
      title: 'SKU',
      path: 'sku',
      fieldType: 'input',
    },
    {
      title: 'Stock',
      path: 'stock',
    },
    {
      title: 'Allocated',
      path: 'allocated',
    },
    {
      title: 'Incoming',
      path: 'incoming',
    },
  ];

  const [variantTable, setVariantTable] = useState(tableHeader);
  const [show, setShow] = useState(true);

  useEffect(() => {
    setVariantTable((prev) => prev.map((h) => {
      if (h.path === 'price') {
        return {
          ...h,
          options: priceLists,
          value: selectedPrice,
        };
      }

      return h;
    }));
  }, [priceLists, selectedPrice]);

  const allVariants = useMemo(() => variants.map(
    (v) => ({
      ...v,
      disabledKeys: selectedWarehouse === '0' ? ['quantity'] : [],
      tooltip: selectedWarehouse === '0' ? { quantity: 'Update for individual variants only' } : {},
    }),
  ), [selectedWarehouse, variants]);

  useEffect(() => {
    setShow(true);
  }, [variants]);

  useEffect(() => {
    if (productHasBundle) setShow(false);
  }, [productHasBundle]);

  return (
    <div className={`create_product_variants ${productHasBundle ? 'disable' : ''}`}>
      <div className={`create_product_title_block ${!show ? 'not_show' : ''} ${productHasBundle ? 'disable' : ''}`}>
        <ArrowIcon onClick={() => !productHasBundle && setShow((prev) => !prev)} />

        <h2 className="create_product_title_block_title">Variants</h2>
      </div>

      {!productHasBundle && show && productHasOption && (
        <>
          <div className="create_product_variants_table">
            <Table
              data={allVariants}
              header={variantTable}
              onChange={(id, path, value) => onDataChange('variants', value, id, path)}
              onEditClick={(id) => setVariantId(id)}
              onHeaderChange={(path, value) => onHeaderChange(path, value)}
              onDeleteClick={(id) => onDataChange('deleteVariant', id)}
              lineErrorData={errors.variants}
            />
          </div>

          <Select
            options={warehouses.length > 1 ? [{ title: 'All locations', id: '0' }, ...warehouses] : warehouses}
            value={selectedWarehouse}
            onChange={(value) => onDataChange('selectForVariant', value)}
            labelPath="title"
            valuePath="id"
            wrapperClassName="create_product_variant_select"
            roundBorder
            size="small"
          />
        </>
      )}
    </div>
  );
}

ProductVariants.propTypes = {
  variants: PropTypes.array,
  warehouses: PropTypes.array,
  setVariantId: PropTypes.func,
  onDataChange: PropTypes.func,
  onHeaderChange: PropTypes.func,
  productHasBundle: PropTypes.bool,
  productHasOption: PropTypes.bool,
  selectedWarehouse: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceLists: PropTypes.array,
  errors: PropTypes.object,
};

ProductVariants.defaultProps = {
  variants: [],
  warehouses: [],
  setVariantId: () => {},
  onDataChange: () => {},
  onHeaderChange: () => {},
  selectedWarehouse: '0',
  selectedPrice: '0',
  productHasBundle: false,
  productHasOption: false,
  priceLists: [],
  errors: {},
};

export default ProductVariants;
