import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import Pagination from '../../../_common/Pagination/Pagination';

const columns = [
  {
    key: 'level', isEnabled: true,
  },
  {
    key: 'message', isEnabled: true,
  },
  {
    key: 'createdAt', isEnabled: true,
  },
];

const defaultAttributes = {
  level: 'Type',
  message: 'Status',
  createdAt: 'Time',
};

const Logs = () => {
  const { integrationId } = useParams();

  const firstLoad = useRef(true);
  const [loading, setLoading] = useState(false);
  const [logger, setLogger] = useState([]);
  const [pagination, setPagination] = useState({ totalPage: 1, currentPage: 1 });
  const { totalPage, currentPage } = pagination;

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await Api.getAllLogs({
        limit: 10,
        page: currentPage,
        integrationId,
      });

      setLogger(data.logs || []);
      setPagination((prev) => ({ ...prev, totalPage: data.totalPages }));
      firstLoad.current = false;
      setLoading(false);
    })();
  }, [currentPage]);

  return (
    <div className="my_app_logs">

      {firstLoad.current
        ? <Loader />
        : (
          <div className={classNames('my_app_logs_table_wrapper', {
            log: true,
          })}
          >
            <DynamicTable
              loading={loading}
              data={logger}
              keyExtractor={(item) => item._id}
              columns={columns}
              renderColumnHeader={(col) => (defaultAttributes[col.key] || col.key)}
              renderColumns={{
                level: ({ item }) => (
                  <div className={classNames('info', {
                    error: item.level === 'error',
                    warning: item.level === 'warning',
                  })}
                  >
                    {_.capitalize(item.level)}
                  </div>
                ),
                message: ({ item }) => item.message || '-',
                createdAt: ({ item }) => moment(item.createdAt).format('YYYY-DD-MM hh:mm:ss'),
              }}
            />

            {+totalPage > 1 && (
              <div className="my_app_logs_pagination">
                <Pagination
                  onChangePage={(page) => setPagination((prev) => ({ ...prev, currentPage: page }))}
                  page={currentPage}
                  totalPages={+totalPage}
                />
              </div>
            )}
          </div>

        )}
    </div>
  );
};

export default Logs;
