import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { components } from 'react-select';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Select from '../../_common/Form/Select';
import Checkbox from '../../_common/Form/Checkbox';
import Button from '../../_common/Form/Button';
import Api from '../../../Api';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';

const ExportConfig = ({
  selectedItemsLength, exportType, onChangeExportType, closeModal, setCreateOrUpdateTemplate, setRequestSaveTemplate,
  requestSaveTemplate, requestExport, setRequestExport, name,
}) => {
  const templateListLoading = useSelector((state) => state.fileExport.templateListLoading);
  const templateList = useSelector((state) => state.fileExport.templateList);
  const [exportTypeOptions, setExportTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    setExportTypeOptions([]);
  }, [exportType]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (exportType === 'byWarehouse') {
        const { data: { warehouses: list } } = await Api.getWarehouses();
        setExportTypeOptions(list.map((warehouse) => ({ id: warehouse.id, label: warehouse.title })));
      } else if (exportType === 'byShop') {
        const { data: { integrations } } = await Api.getIntegrations({ category: 'shop' });
        setExportTypeOptions(integrations.map((s) => ({ id: s.id, label: s.name })));
      }
      setLoading(false);
    })();
  }, [exportType]);

  useEffect(() => {
    if (!requestSaveTemplate.fields.length && !requestExport.id && !_.isEmpty(templateList)) {
      const fieldData = _.cloneDeep(templateList[0]);

      delete fieldData._id;
      delete fieldData.title;

      setRequestExport((prev) => ({
        ...prev,
        ...fieldData,
      }));
    }
  }, [requestSaveTemplate]);

  const Menu = (props) => (
    <components.Menu
      {...props}
    >
      {props.children}

      <div
        role="button"
        tabIndex="0"
        onClick={() => setCreateOrUpdateTemplate('add')}
        className="export_modal_select_add"
      >
        + Add new
      </div>
    </components.Menu>
  );

  const SingleValue = ({
    children,
    ...props
  }) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
  );
  const joinFields = (path, object) => object[path].map((p) => `${path}.${p}`);

  const editExportTemplate = (e, field) => {
    e.preventDefault();
    e.stopPropagation();
    setCreateOrUpdateTemplate(field.id);

    const fieldData = _.cloneDeep(field);

    delete fieldData._id;
    delete fieldData.id;

    setRequestSaveTemplate(() => {
      let fields = [];

      _.forEach(fieldData, ((r, key) => {
        if (key !== 'fields' && key !== 'title') {
          fields = [...fields, ...joinFields(key, fieldData)];
        }
      }));

      return ({
        ...fieldData,
        fields: [...fieldData.fields, ...fields],
      });
    });
  };

  const changeBySelect = (option, path) => {
    setRequestExport((prev) => ({
      ...prev,
      by: {
        ...prev.by,
        [path]: [option.id],
      },
    }));
  };

  const templateSelect = (option) => {
    const fieldData = _.cloneDeep(option);

    delete fieldData._id;
    delete fieldData.title;

    setRequestExport((prev) => ({
      ...prev,
      ...fieldData,
    }));
  };

  const customTemplateClick = () => {
    setCreateOrUpdateTemplate('custom');

    if (requestSaveTemplate.fields.length) {
      setRequestSaveTemplate((prev) => {
        let fields = [];

        _.forEach(requestSaveTemplate, ((r, key) => {
          if (key !== 'fields' && key !== 'title') {
            fields = [...fields, ...joinFields(key, requestSaveTemplate)];
          }
        }));

        return ({
          ...prev,
          fields: [...prev.fields, ...fields],
        });
      });
    }
  };

  const changeFileType = (type) => {
    setRequestExport((prev) => ({ ...prev, fileType: type }));
  };

  const onExportClick = async () => {
    setExportLoading(true);

    try {
      const requestData = _.cloneDeep(requestExport);

      delete requestData.id;

      const data = await Api.exportFile(name, requestData);

      if (data.toast) {
        toast.success('We have successfully started the export process. '
         + 'You will receive the exported file at your email address shortly.');
      }

      closeModal();
    } catch (e) {
      toast.error('Something went wrong. Please try again!');
    }

    setExportLoading(false);
  };

  return (
    <div className="export_config_wrapper">
      <h1>{`Export ${name.replace('Export', '')}`}</h1>

      <Checkbox
        onChange={() => onChangeExportType('all')}
        label="All items"
        radio
        checked={exportType === 'all'}
        className="export_modal_radio"
      />

      <div className="export_filter_select_wrapper">
        <Checkbox
          onChange={() => onChangeExportType('byWarehouse')}
          label="By warehouse"
          radio
          checked={exportType === 'byWarehouse'}
          className="export_modal_radio"
        />

        {exportType === 'byWarehouse'
          && (
          <Select
            isSearchable
            options={exportTypeOptions}
            value={requestExport.by.warehouseId[0]}
            onChange={(option) => changeBySelect(option, 'warehouseId')}
            size="small"
            roundBorder
            loading={loading}
            valuePath="id"
            getFullOption
            placeholder="Select warehouse"
            menuPosition="fixed"
            menuPortalTarget={document.querySelector('#modal')}
          />
          )}
      </div>

      <div className="export_filter_select_wrapper">
        <Checkbox
          onChange={() => onChangeExportType('byShop')}
          label="By shop"
          radio
          checked={exportType === 'byShop'}
          className="export_modal_radio"
        />

        {exportType === 'byShop' && (
        <Select
          isSearchable
          options={exportTypeOptions}
          value={requestExport.by.shopId[0]}
          onChange={(option) => changeBySelect(option, 'shopId')}
          loading={loading}
          roundBorder
          getFullOption
          size="small"
          placeholder="Select shop"
          valuePath="id"
          menuPosition="fixed"
          menuPortalTarget={document.querySelector('#modal')}
        />
        )}
      </div>

      <div className="export_selected_count_wrapper">
        <Checkbox
          onChange={() => onChangeExportType('selected')}
          label="Selected"
          radio
          disabled={!selectedItemsLength}
          checked={exportType === 'selected'}
        />

        {!!selectedItemsLength
          && <p className="export_selected_count">{selectedItemsLength}</p>}
      </div>

      <h2>Export As</h2>

      <Checkbox
        onChange={() => changeFileType('csv')}
        label="CSV"
        radio
        checked={requestExport.fileType === 'csv'}
        className="export_modal_radio"
      />

      <Checkbox
        onChange={() => changeFileType('xlsx')}
        label="XLSX (Microsoft Excel)"
        radio
        checked={requestExport.fileType === 'xlsx'}
        className="export_modal_radio"
      />

      <Select
        label="Fields in Export File"
        options={templateList}
        value={requestExport.id}
        onChange={templateSelect}
        roundBorder
        size="small"
        isDisabled={!requestExport.id && !!requestSaveTemplate.fields.length}
        placeholder="Select template"
        menuPlacement="top"
        components={{ Menu, SingleValue }}
        loading={templateListLoading}
        styles={{
          singleValue: (base) => ({
            ...base,
            svg: {
              display: 'none',
            },
          }),
        }}
        valuePath="id"
        getFullOption
        getOptionLabel={(s) => (
          <div className="export_modal_template_select_items">
            <p>{s.title}</p>

            <div
              role="button"
              tabIndex="0"
              onClick={(e) => editExportTemplate(e, s)}
            >
              <EditIcon />
            </div>
          </div>
        )}
      />

      <div
        role="button"
        tabIndex="0"
        className="export_custom_field_wrapper"
        onClick={customTemplateClick}
      >
        {requestSaveTemplate.fields.length
          ? <p>Custom template</p>
          : <p>Create custom fields for export</p>}

        {!!requestSaveTemplate.fields.length && (
        <div
          role="button"
          tabIndex="0"
          onClick={(e) => {
            e.stopPropagation();
            setRequestSaveTemplate({ fields: [] });
          }}
        >
          <CancelIcon />
        </div>
        )}
      </div>

      <div className="import_modal_buttons_wrapper">
        <Button
          size="small"
          roundBorder
          btnType="cancel"
          reverseColor
          onClick={closeModal}
          title="Cancel"
          className="import_modal_button"
        />

        <Button
          size="small"
          roundBorder
          title="Export"
          onClick={onExportClick}
          className="import_modal_button"
          loading={exportLoading}
        />
      </div>
    </div>
  );
};

ExportConfig.propTypes = {
  name: PropTypes.string.isRequired,
  selectedItemsLength: PropTypes.number.isRequired,
  exportType: PropTypes.string.isRequired,
  onChangeExportType: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  setCreateOrUpdateTemplate: PropTypes.func.isRequired,
  setRequestSaveTemplate: PropTypes.func.isRequired,
  requestSaveTemplate: PropTypes.object.isRequired,
  requestExport: PropTypes.object.isRequired,
  setRequestExport: PropTypes.func.isRequired,
};
export default ExportConfig;
