import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircleProgress from '../_common/Progress/CircleProgress';
import SearchField from './SearchField';
import HeaderSelect from './HeaderSelect';
import ShortMenu from './ShortMenu';
import Notifications from './Notifications';
import SyncNotifications from './SyncNotifications';
import ProfileMenu from './ProfileMenu';
import useWindowSize from '../../helpers/hooks/useWindowSize';

import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as InventoryIcon } from '../../assets/icons/inventory.svg';
import { ReactComponent as OrdersIcon } from '../../assets/icons/orders.svg';
import { ReactComponent as CRMIcon } from '../../assets/icons/crm.svg';
import { ReactComponent as StocksIcon } from '../../assets/icons/stocks.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as ReportsIcon } from '../../assets/icons/reports.svg';
import Account from '../../helpers/Account';
import { getAppsRequest, getSetupRequest } from '../../store/actions/notifications';
import MobileMenuCategory from './MobileMenuCategory';
import MobileMenuItem from './MobileMenuItem';
import { socketInit } from '../../store/actions/socket';

export const inventory = [
  {
    label: 'Categories',
    path: '/inventory/categories',
  },
  {
    label: 'Listings',
    path: '/inventory/listings/linked',
  },
  {
    label: 'Variants',
    path: '/inventory/variants',
  },
  {
    label: 'Batches',
    path: '/inventory/batches',
  },
  {
    label: 'Stock Control',
    path: '/inventory/stock-control',
  },
  {
    label: 'Price Lists',
    path: '/inventory/price-lists',
  },
  {
    label: 'Archive',
    path: '/inventory/archive',
  },
];

const orders = [
  {
    label: 'Sales Orders',
    path: '/orders/sales-order',
  },
  {
    label: 'Invoices',
    path: '/orders/invoices',
  },
  {
    label: 'Quotations',
    path: '/orders/quotations',
  },
  {
    label: 'Shipments',
    path: '/orders/shipments',
  },
  {
    label: 'Returns',
    path: '/orders/returns',
  },
  {
    label: 'Credit Notes',
    path: '/orders/credit-notes',
  },
  {
    label: 'Archive',
    path: '/orders/archive',
  },
];

const CRM = [
  {
    label: 'Customers',
    path: '/crm/customers',
  },
  {
    label: 'Users',
    path: '/crm/users',
  },
  {
    label: 'Roles',
    path: '/crm/roles',
  },
];

const stocks = [
  {
    label: 'Purchase Orders',
    path: '/stocks/purchase-orders',
  },
  {
    label: 'Bills',
    path: '/stocks/bills',
  },
  {
    label: 'Quotations',
    path: '/stocks/quotations',
  },
  {
    label: 'Reorders',
    path: '/stocks/reorders',
  },
  {
    label: 'Warehouses',
    path: '/stocks/warehouses',
  },
  {
    label: 'Stock Transfer',
    path: '/stocks/stock-transfer',
  },
  {
    label: 'Receives',
    path: '/stocks/receives',
  },
];

const reports = [
  {
    label: 'Overview',
    path: '/reports/overview',
  },
  {
    label: 'Reporting',
    path: '/reports/reporting',
  },
  {
    label: 'Saved Reports',
    path: '/reports/saved-Reports',
  },
  {
    label: 'Scheduled',
    path: '/reports/scheduled',
  },
];

const settings = [
  {
    label: 'General',
    path: '/settings/general',
  },
  {
    label: 'Plans & Billing',
    path: '/settings/plans-billing',
  },
  {
    label: 'Shipping',
    path: '/settings/shipping',
  },
  {
    label: 'Packages',
    path: '/settings/packages',
  },
  {
    label: 'Shipping',
    path: '/settings/shipping-types',
  },
  {
    label: 'Company',
    path: '/settings/company',
  },
  {
    label: 'Currency',
    path: '/settings/currency',
  },
  {
    label: 'Email Templates',
    path: '/settings/email-templates',
  },
  {
    label: 'Tags',
    path: '/settings/tags',
  },
  {
    label: 'Product Types',
    path: '/settings/product-types',
  },
  {
    label: 'Brands',
    path: '/settings/brands',
  },
  {
    label: 'Adjustment Reason',
    path: '/settings/adjustment-reason',
  },
  {
    label: 'API Access',
    path: '/settings/api-access',
  },
  {
    label: 'Remote Printing',
    path: '/settings/remote-printing',
  },
];

const Header = () => {
  const { isAdmin = false } = Account.get();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isMobile } = useWindowSize(767);
  const { isMobile: isTablet } = useWindowSize(1399);

  const dispatch = useDispatch();
  const { percent } = useSelector((state) => state.notifications.setup);

  const selectMenu = (url) => {
    navigate(url);
  };

  const menu = useMemo(() => (
    [
      {
        label: 'Inventory',
        icon: <InventoryIcon />,
        rootPath: '/inventory',
        subMenu: inventory,
        isAdminRoute: false,
      },
      {
        label: 'Orders',
        icon: <OrdersIcon />,
        rootPath: '/orders',
        subMenu: orders,
        isAdminRoute: false,
      },
      {
        label: 'CRM',
        icon: <CRMIcon />,
        rootPath: '/crm',
        subMenu: isAdmin ? [
          {
            label: 'Users',
            path: '/crm/users',
          },
          {
            label: 'Default roles',
            path: '/crm/roles',
          },
          {
            label: 'Admin roles',
            path: '/crm/admin-roles',
          }] : [
          {
            label: 'Customers',
            path: '/crm/customers',
          },
          {
            label: 'Users',
            path: '/crm/users',
          },
          {
            label: 'Roles',
            path: '/crm/roles',
          },
        ],
        isAdminRoute: true,
      },
      {
        label: 'Stocks',
        icon: <StocksIcon />,
        rootPath: '/stocks',
        subMenu: stocks,
        isAdminRoute: false,
      },
      {
        label: 'Reports',
        icon: <ReportsIcon />,
        rootPath: '/reports',
        subMenu: reports,
        isAdminRoute: false,
      },
      // {
      //   label: 'Settings',
      //   icon: <SettingsIcon />,
      //   rootPath: '/settings',
      //   subMenu: settings,
      // },
    ]
  ), [isAdmin]);

  useEffect(() => {
    (async () => {
      dispatch(getSetupRequest());
      await dispatch(getAppsRequest());
      dispatch(socketInit());
    })();
  }, [isAdmin]);

  return (
    <header className={classNames({ admin_header: isAdmin })}>
      {isMobile && (
        <>
          <MobileMenuCategory />

          <NavLink to="/" className="mobile__logo">
            <LogoIcon />
          </NavLink>
        </>
      )}

      <div className={`auth_layout_content_header_left_block ${isAdmin ? 'is_admin' : ''}`}>
        {menu.map(({
          label, icon, rootPath, subMenu, isAdminRoute,
        }) => (
          <div
            key={label}
            className={classNames(
              'auth_layout_content_header_left_block_select_wrapper',
              { active: pathname.includes(rootPath) },
            )}
          >
            {(!isAdmin || (isAdmin && isAdminRoute)) && (
              <HeaderSelect
                options={subMenu}
                onChange={selectMenu}
                title={(
                  <div className="auth_layout_content_header_left_block_menu_title">
                    {icon}

                    {label}
                  </div>
                )}
                value={pathname}
                valuePath="path"
              />
            )}
          </div>
        ))}

        {!isAdmin && (
          <NavLink to="/settings" className="auth_layout_content_header_left_block_menu_link">
            <SettingsIcon />

            Settings
          </NavLink>
        )}
      </div>
      <div className="auth_layout_content_header_right_block">
        {!isAdmin && (
          <>
            {!isMobile && isTablet && <ShortMenu menu={menu} selectMenu={selectMenu} />}

            <NavLink to="/setup">
              <CircleProgress
                percent={percent}
                size={43}
                borderWidth={4}
                fontSize={13}
              />
            </NavLink>

            {!isMobile && <SearchField />}

            <SyncNotifications />

            <Notifications />
          </>
        )}

        <ProfileMenu />
      </div>

      {isMobile && <MobileMenuItem menu={menu} />}
    </header>
  );
};

Header.propTypes = {

};

export default Header;
