import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Modal from '../../../../_common/Modal/Modal';
import Select from '../../../../_common/Form/Select';
import Input from '../../../../_common/Form/Input';
import Utils from '../../../../../helpers/Utils';
import Button from '../../../../_common/Form/Button';

const SmtpCreateModal = memo(({
  fields, singleData, onSave, isOpen, onClose, zIndex, onClick,
}) => {
  const [currentFields, setCurrentFields] = useState([]);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [saveLoading, saveLoadingToggle] = useState(false);

  useEffect(() => {
    if (fields.length) setCurrentFields(fields);
  }, [fields]);

  useEffect(() => {
    if (isOpen) {
      setErrors({});
      setData(singleData);
    }
  }, [isOpen]);

  const onChange = useCallback((path, value) => {
    setData((prev) => ({ ...prev, [path]: value }));

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, [data]);

  const saveData = useCallback(async (e) => {
    e?.preventDefault();
    let hasError = false;

    fields.forEach(({ path, required }) => {
      if (required && !data?.[path]?.toString().trim()) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));
        hasError = true;
      } else if (path.toLowerCase().includes('email') && !Utils.isEmail(data[path])) {
        setErrors((prev) => ({ ...prev, [path]: 'Enter valid email' }));
        hasError = true;
      } else if (path.toLowerCase().includes('url') && !Utils.isUrl(data[path])) {
        setErrors((prev) => ({ ...prev, [path]: 'Enter valid url' }));
        hasError = true;
      }
    });

    if (!hasError) {
      try {
        if (e) {
          saveLoadingToggle(true);
          await onSave(data);
        } else {
          await onClick(data);
        }
      } catch (err) {
        setErrors(err?.response?.data?.errors);

        toast.error(err?.response?.data?.message || 'Please correct these fields');
      }

      saveLoadingToggle(false);
    } else {
      toast.error('Please correct these fields');
    }
  }, [data]);

  return (
    <Modal
      wrapperClassName="update_modal_amazon_app"
      className="general_settings_update_modal_wrapper"
      isOpen={isOpen}
      onClose={() => !saveLoading && onClose()}
      zIndex={zIndex}
    >
      <form onSubmit={saveData} className="fields_block">
        <h3 className="fields_block_title">Install SMTP</h3>

        {currentFields.map(({
          placeholder, title, path, type, options, validation, valuePath, labelPath, menuPortalTarget, getFullOption,
          disabled, loading, isAsync, defaultOptions, required, ...rest
        }) => (
          <div className="update_modal_single_input" key={path}>
            {
              type === 'select'
                ? (
                  <Select
                    onChange={(d) => onChange(path, d)}
                    value={data[path]}
                    label={title}
                    placeholder={placeholder || title}
                    options={options}
                    valuePath={valuePath}
                    labelPath={labelPath}
                    error={errors?.[path]}
                    loading={loading}
                    wrapperClassName="update_modal_input_wrp"
                    roundBorder
                    getFullOption={getFullOption}
                    size="small"
                    menuPosition={menuPortalTarget ? 'fixed' : null}
                    menuPortalTarget={menuPortalTarget ? document.querySelector('#modal') : null}
                    isAsync={isAsync}
                    defaultOptions={defaultOptions}
                    {...rest}
                  />
                )
                : (
                  <Input
                    value={data[path]}
                    onChangeText={(value) => onChange(path, value)}
                    label={title}
                    password={type === 'password'}
                    placeholder={placeholder || title}
                    error={errors?.[path]}
                    wrapperClassName="update_modal_input_wrp"
                    roundBorder
                    size="small"
                    symbol={path === 'rate' ? '%' : ''}
                    onBeforeInput={(e) => Utils.onBeforeInput(e, validation)}
                  />
                )
            }

            {type === 'password'
              && <input type="text" autoComplete="new-password" style={{ display: 'none' }} />}
          </div>
        ))}

        <Button
          className="update_modal_button"
          roundBorder
          size="small"
          btnType="light_blue"
          type="button"
          onClick={() => saveData()}
        >
          Test connection
        </Button>

        <div className="update_modal_buttons_wrp">
          <Button
            className="update_modal_cancel_button"
            roundBorder
            size="small"
            btnType="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            className="update_modal_save_button"
            roundBorder
            size="small"
            type="submit"
            loading={saveLoading}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
});

SmtpCreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  zIndex: PropTypes.number,
  singleData: PropTypes.object,
  onClick: PropTypes.func,
};

SmtpCreateModal.defaultProps = {
  zIndex: 150,
  onClose: null,
  onSave: () => {},
  singleData: {},
  onClick: undefined,
};
export default SmtpCreateModal;
