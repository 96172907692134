import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  NumberParam, useQueryParams,
} from 'use-query-params';
import { ReactComponent as GoBackIcon } from '../../../assets/icons/go_back.svg';
import { ReactComponent as PrintIcon } from '../../../assets/icons/remote_printing.svg';
import Api from '../../../Api';
import FilterButton from '../../_common/Form/FilterButton';
import Loader from '../../_common/Loader/Loader';
import ProductTable from './StockTransferComponent/ProductTable';
import Pagination from '../../_common/Pagination/Pagination';

const columns = [
  {
    key: 'item', isEnabled: true,
  },
  {
    key: 'sourceStock', isEnabled: true,
  },
  {
    key: 'destStock', isEnabled: true,
  },
  {
    key: 'quantity', isEnabled: true,
  },
  {
    key: 'receivedQty', isEnabled: true,
  },
  {
    key: 'createdAt', isEnabled: true,
  },
];

const defaultAttributes = {
  item: 'Item',
  sourceStock: 'Source stock',
  destStock: 'Destination stock',
  quantity: 'Transfer qty',
  receivedQty: 'Received qty',
  createdAt: 'Date',
};

const ReceiveStockHistory = () => {
  const { receiveId } = useParams();
  const navigate = useNavigate();
  const firstLoading = useRef(true);

  const [{ page }] = useQueryParams({ page: NumberParam });

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [number, setNumber] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await Api.getSingleReceiveStockTransfer({ id: receiveId, page });

        setProducts(data.products);
        setTotalPage(data.totalPages);
        setNumber(data.number);

        setLoading(false);
        firstLoading.current = false;
      } catch (e) {
        console.error(e);
      }
    })();
  }, [page]);
  // console.log(params);
  return (
    firstLoading.current ? <div className="stock_transfer_received_history_loader"><Loader /></div>
      : (
        <div className="stock_transfer_received_history_wrapper">
          <div className="stock_transfer_received_history_header">
            <div className="stock_transfer_received_history_header_title">
              <GoBackIcon className="back_button" onClick={() => navigate(-1)} />

              <h2>{`Receive transfer ${number}`}</h2>
            </div>

            <FilterButton
              onClick={() => {
              }}
              style={{ marginRight: 10 }}
            >
              <PrintIcon />

              Print
            </FilterButton>
          </div>

          <div className="stock_transfer_received_history_body">
            <ProductTable
              products={products}
              columns={columns}
              defaultAttributes={defaultAttributes}
              loading={loading}
            />

            {+totalPage > 1 && (
              <div className="stock_transfer_pagination">
                <Pagination
                  page={page}
                  totalPages={+totalPage}
                />
              </div>
            )}
          </div>
        </div>
      )
  );
};

export default ReceiveStockHistory;
