import React, {
  useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  changeCustomersTableAttribute,
  deleteCustomerRequest,
} from '../../../store/actions/customers';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_with_circle.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_with_circle.svg';
import noImage from '../../../assets/icons/default.svg';
import Pagination from '../../_common/Pagination/Pagination';
import DeleteModal from '../../_common/Modal/DeleteModal';
import shopIcons from '../../../assets/shops';
import Loader from '../../_common/Loader/Loader';
import DynamicTable from '../../_common/DynamicTable/DynamicTable';
import ImportModal from '../ImportCSVFile/ImportModal';
import ExportFileModal from '../ExportFile/ExportFileModal';

const defaultAttributes = {
  shopType: 'Shop',
  image: 'Image',
  firstName: 'First name',
  lastName: 'Last name',
  phone: 'Phone',
  isCustomer: 'Customer',
  type: 'Type',
  jobPosition: 'Job position',
  website: 'Website link',
  orders: 'Orders',
  createdAt: 'Created at',
};

const CustomersTable = (props) => {
  const {
    onGetCustomers, openImportModal, openExportModal,
    setOpenExportModal, setOpenImportModal, loadingData, firstLoadingRef,
  } = props;
  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkedCustomers, setCheckedCustomers] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customers = useSelector((state) => state.customers.customers);
  const columns = useSelector((state) => state.customers.columns);
  const totalPages = useSelector((state) => state.customers.totalPages);
  const tableEditable = useSelector((state) => state.customers.tableEditable);

  const handleDeleteCustomer = useCallback(async () => {
    setLoading(true);
    const { payload: { data } } = await dispatch(deleteCustomerRequest(customerId));
    if (data.status !== 'error') {
      toast.success('Successfully deleted.');
      await onGetCustomers();
    } else {
      toast.error(data.message);
    }
    setLoading(false);
    setCustomerId(null);
  }, [customerId]);

  const changeTableAttribute = (attributes) => {
    dispatch(changeCustomersTableAttribute(attributes));
  };

  return (
    firstLoadingRef.current ? <div><Loader size={30} /></div> : (
      <>
        <div className="table_wrapper">
          <DynamicTable
            onChange={changeTableAttribute}
            loading={loadingData}
            onSelect={setCheckedCustomers}
            data={customers}
            editable={tableEditable}
            checkbox
            keyExtractor={(item) => item.id}
            columns={columns}
            callDragDisabled={(call) => ['phone', 'lastName', 'firstName'].includes(call.key)}
            renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
            renderColumns={{
              shopType: ({ item }) => (
                <div className="products_shop_icon_wrapper">
                  <img
                    src={shopIcons[item.shopType] || shopIcons.eswap}
                    alt=""
                    style={{
                      width: 34,
                      height: 34,
                      borderRadius: '50%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ),
              image: ({ item }) => (
                <div className="products_shop_icon_wrapper">
                  <img
                    src={item.image || noImage}
                    alt="img"
                    style={{
                      width: 34,
                      height: 34,
                      borderRadius: '50%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ),
              firstName: ({ item }) => (
                <div
                  className="customers_first_name"
                  onClick={() => navigate(`/crm/customers/edit/${item.id}`)}
                  role="button"
                  tabIndex="0"
                >
                  {item.firstName || '-'}
                </div>
              ),
              lastName: ({ item }) => item.lastName || '-',
              phone: ({ item }) => item.phone || '-',
              isCustomer: ({ item }) => (item.isCustomer ? 'Customer' : 'Vendor'),
              type: ({ item }) => _.upperFirst(item.type),
              jobPosition: ({ item }) => item.jobPosition || '-',
              website: ({ item }) => item.website || '-',
              orders: ({ item }) => item.orders || '-',
              createdAt: ({ item }) => moment(item.createdAt).format('YYYY.MM.DD hh:mm:ss'),
              actions: ({ item }) => (
                <div className="customers_actions_wrapper">
                  <div
                    role="button"
                    tabIndex="0"
                    data-scope="WRITE_CUSTOMERS"
                    onClick={() => navigate(`/crm/customers/edit/${item.id}`)}
                  >
                    <EditIcon />
                  </div>
                  <div
                    role="button"
                    tabIndex="0"
                    data-scope="DELETE_CUSTOMERS"
                    onClick={() => setCustomerId(item.id)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              ),
            }}
          />
        </div>

        <Pagination totalPages={totalPages} />

        <DeleteModal
          isOpen={!!customerId}
          text="customer"
          onDelete={handleDeleteCustomer}
          loading={loading}
          onClose={() => setCustomerId(null)}
        />

        <ImportModal
          isOpen={openImportModal}
          onClose={() => setOpenImportModal(false)}
          type="customers"
        />

        <ExportFileModal
          isOpen={openExportModal}
          onClose={() => setOpenExportModal(false)}
          selectedItemsId={checkedCustomers}
          name="customersExport"
        />
      </>
    )

  );
};

export default CustomersTable;
