import React from 'react';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useDispatch, useSelector } from 'react-redux';
import Filters from '../../Products/Filters';
import Api from '../../../../Api';
import { invoicesTableEditableEvent } from '../../../../store/actions/invoices';

const defaultFilterData = [
  { label: 'Status', valuePath: 'status' },
  { label: 'Order', valuePath: 'orderId' },
];

const filterOptions = {
  status: {
    label: 'Status',
    path: 'status',
    valuePath: 'status',
    staticData: [
      {
        label: 'Paid',
        id: 'paid',
      },
      {
        label: 'Unpaid',
        id: 'unpaid',
      },
      {
        label: 'Partial paid',
        id: 'partial_paid',
      },
      {
        label: 'Refunded',
        id: 'refunded',
      },
      {
        label: 'Partial refunded',
        id: 'partial_refunded',
      },
      {
        label: 'Canceled',
        id: 'canceled',
      },
    ],
  },
  orderId: {
    label: 'Orders',
    path: 'orders',
    valuePath: 'orderId',
  },
};

const defaultFiltersParams = withDefault(ArrayParam, []);

function InvoiceFilters() {
  const dispatch = useDispatch();
  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    orderId: defaultFiltersParams,
    status: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const tableEditable = useSelector((state) => state.invoices.tableEditable);

  const loadOptions = async (filter) => {
    if (filter.staticData) {
      return { data: filter.staticData };
    }

    let data;
    let totalPages;

    if (filter.path === 'orders') {
      const { data: { orders: list, totalPages: p } } = await Api.getOrders();
      totalPages = p;

      data = list.map((order) => ({ id: order.id, label: order.number }));
    }
    return { data, totalPages };
  };

  return (
    <Filters
      queryData={queryData}
      setQuery={setQuery}
      balkActionsClick={(s) => {
        console.log(s);
      }}
      tableEditable={tableEditable}
      defaultFilterData={defaultFilterData}
      onPrintClick={() => {
      }}
      filterOptions={filterOptions}
      loadOptions={loadOptions}
      onEditableTableClick={() => dispatch(invoicesTableEditableEvent(true))}
    />
  );
}

export default InvoiceFilters;
