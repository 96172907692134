import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';
import Button from '../components/_common/Form/Button';
import LogoutLayout from '../components/Layout/LogoutLayout';
import GoogleButton from '../components/_common/Form/GoogleButton';
import StepOne from '../components/pages/Signup/StepOne';
import StepTwo from '../components/pages/Signup/StepTwo';
import Api from '../Api';
import Formatter from '../helpers/Formatter';
import Account from '../helpers/Account';

const stepOneFields = [
  {
    label: 'First name',
    path: 'firstName',
    placeholder: 'Enter your first name',
    type: 'input',
  },
  {
    label: 'Last name',
    path: 'lastName',
    placeholder: 'Enter your last name',
    type: 'input',
  },
  {
    label: 'Email',
    path: 'email',
    placeholder: 'Enter your email',
    type: 'input',
  },
  {
    label: 'Password',
    path: 'password',
    placeholder: 'Enter your password',
    type: 'password',
  },
];

const addressFieldsFormat = {
  administrative_area_level_1: {
    path: 'company.regionId',
    textType: 'short_name',
  },
  country: {
    path: 'company.countryCode',
    textType: 'short_name',
  },
  locality: {
    path: 'company.city',
    textType: 'long_name',
  },
  postal_code: {
    path: 'company.postalCode',
    textType: 'short_name',
  },
};

const Signup = () => {
  const navigate = useNavigate();

  const [queryParams] = useQueryParams({
    integrationHash: '',
  });
  const [step, setStep] = useState(1);
  const [data, setData] = useState(Account.getAuthData());

  const [recaptchaToken, setReCaptchaToken] = useState('');
  const [fromGoogleOauth, setFromGoogleOauth] = useState(false);

  const [errors, setErrors] = useState({});

  const [countries, setCountries] = useState([]);
  const [statesLoading, statesLoadingToggle] = useState(false);
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (data.company.countryCode) {
      (async () => {
        statesLoadingToggle(true);
        const payload = await Api.getRegions(data.company.countryCode);

        if (Number.isNaN(data.company.regionId)) {
          const regionId = payload.data.regions.find((p) => p.code === data.company.regionId)?.id || '';

          setData((prev) => ({
            ...prev,
            company: { ...prev.company, regionId },
          }));
        }

        setStates(payload.data.regions);
        statesLoadingToggle(false);
      })();
    }
  }, [data.company.countryCode]);

  useEffect(() => {
    (async () => {
      const payload = await Api.getCountries();

      setCountries(payload.data.countries);
    })();
  }, []);

  const changeData = useCallback((path, value) => {
    setData((prev) => {
      const newData = { ...prev };
      _.set(newData, path, value);

      if (path === 'company.countryCode') {
        _.set(newData, 'company.regionId', '');
      }
      return newData;
    });

    setErrors((prev) => {
      const newErrors = { ...prev };
      _.unset(newErrors, path);

      return newErrors;
    });
  }, []);

  const addressClick = useCallback((addressInfo) => {
    _.forEach(addressInfo.address_components, (val) => {
      const info = addressFieldsFormat[val.types[0]];

      if (info?.path) {
        setData((prev) => {
          const newData = { ...prev };

          _.set(newData, info.path, val[info.textType]);

          return newData;
        });

        if (val[info.textType]) {
          setErrors((prev) => {
            const newErrors = { ...prev };
            _.unset(newErrors, info?.path);

            return newErrors;
          });
        }
      }
    });
  }, []);

  const changeStep = useCallback((s) => {
    setStep(s);
  }, []);

  const registration = useCallback(async () => {
    try {
      const sendingData = _.cloneDeep(data);
      sendingData.company.phone = Formatter.phoneNumber(sendingData.company.phone);
      sendingData.captcha = recaptchaToken;
      sendingData.integrationHash = queryParams.integrationHash;

      const { data: { token, user } } = await Api.register(sendingData);

      localStorage.setItem('onboardingToken', token);
      Account.set(user);

      navigate('/onboarding');
      Account.removeAuthData();
      toast.success('Successfully registered');
    } catch (e) {
      setErrors(e.response.data.errors);
      toast.error('Please correct these fields');

      if (stepOneFields.some(({ path }) => e.response.data.errors[path])) {
        setStep(1);
      }
    }
  }, [data, recaptchaToken, queryParams]);

  const onReCaptchaChange = (e) => {
    setErrors((prev) => ({
      ...prev,
      recaptchaToken: '',
    }));

    setReCaptchaToken(e);
  };

  const onReCaptchaExpire = () => {
    setReCaptchaToken('');
  };

  return (
    <LogoutLayout title="Create your free account">
      <div className="login_page">
        {step === 1
          ? (
            <StepOne
              data={data}
              onChange={changeData}
              changeStep={changeStep}
              errors={errors}
              setErrors={setErrors}
              fields={stepOneFields}
            />
          )

          : (
            <StepTwo
              data={data}
              onChange={changeData}
              changeStep={changeStep}
              errors={errors}
              setErrors={setErrors}
              countries={countries}
              registration={registration}
              statesLoading={statesLoading}
              states={states}
              onReCaptchaChange={onReCaptchaChange}
              onReCaptchaExpire={onReCaptchaExpire}
              addressClick={addressClick}
              fromGoogleOauth={fromGoogleOauth}
            />
          )}

        {!queryParams.integrationHash ? (
          <>
            <div className="login_page_or"><p>or</p></div>

            {step === 1 && (
              <GoogleButton
                title="Sign Up with Google"
                signup
                setData={setData}
                setStep={setStep}
                setFromGoogleOauth={setFromGoogleOauth}
              />
            )}

            <div className="login_page_login_btn_wrapper">
              Already have an account?

              <Button
                roundBorder
                color="#1472FF"
                href="/login"
                btnType="transparent"
                size="small"
              >
                Log in
              </Button>
            </div>
          </>
        ) : null}

      </div>
    </LogoutLayout>
  );
};

export default Signup;
