import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Wrapper from '../../../Layout/Wrapper';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Checkbox from '../../../_common/Form/Checkbox';
import Utils from '../../../../helpers/Utils';
import { ReactComponent as DraggableIcon } from '../../../../assets/icons/drag_and_drop.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../../../assets/icons/plus.svg';
import Button from '../../../_common/Form/Button';
import Timepicker from '../../../_common/Form/Timepicker';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';

const types = [
  {
    label: 'Order',
    value: 'orderCustomFields',
  },
  {
    label: 'Product',
    value: 'productCustomFields',
  },
  {
    label: 'Customer',
    value: 'customerCustomFields',
  },
];

// const dateOptions = [
//   { value: 'MM DD YYYY' },
//   { value: 'MMM D, YYYY' },
//   { value: 'MMMM D, YYYY' },
//   { value: 'DD MM YYYY' },
//   { value: 'D MMMM YYYY' },
//   { value: 'D MMM YYYY' },
//   { value: 'Do MMMM YYYY' },
// ];
//
// const timeOptions = [
//   { value: 'HH:mm' },
//   { value: 'hh:mm a.svg' },
//   { value: 'hh:mm A' },
// ];

const dataTypes = [
  {
    label: 'Text',
    value: 'text',
    defaultValueType: 'input',
    preview: 'input',
  },
  {
    label: 'Number',
    value: 'number',
    defaultValueType: 'input',
    validation: 'number',
    preview: 'input',
  },
  // {
  //   label: 'Checkbox',
  //   value: 'checkbox',
  //   defaultValueType: 'checkbox',
  // },
  {
    label: 'Phone',
    value: 'phone',
    defaultValueType: 'input',
    validation: 'number',
    symbol: '+',
    preview: 'input',
  },
  {
    label: 'Date',
    value: 'date',
    preview: 'date',
  },
  {
    label: 'Date & time',
    value: 'dateTime',
    preview: 'dateTime',
  },
  {
    label: 'Amount',
    value: 'currency',
    defaultValueType: 'input',
    validation: 'currency',
    symbol: '$',
    preview: 'input',
  },
  {
    label: 'Dropdown',
    value: 'select',
    defaultValueType: 'select',
    format: 'dropdown',
    preview: 'select',
  },
  // {
  //   label: 'Attachment',
  //   value: 'attachment',
  //   format: 'attachment',
  // },
];

const radioOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const radioFields = {
  isMandatory: {
    title: 'Is Mandatory',
    options: radioOptions,
  },
  displayInPortal: {
    title: 'Display in portal',
    options: radioOptions,
  },
};

const attachmentOptions = [
  { label: 'Image', value: 'image' },
  { label: 'PDF', value: 'pdf' },
  { label: 'Document', value: 'document' },
];

const fields = [
  {
    label: 'Type',
    path: 'customFieldType',
    field: 'select',
    options: types,
  },
  {
    label: 'Label Name',
    path: 'label',
    field: 'input',
  },
  {
    label: 'Data type',
    path: 'type',
    field: 'select',
    options: dataTypes,
  },
  {
    label: 'Help text',
    path: 'helpText',
    field: 'input',
  },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

const defaultFieldsData = {
  customFieldType: 'orderCustomFields',
  label: '',
  type: 'text',
  defaultValue: '',
  helpText: '',
  isMandatory: true,
  displayInPortal: true,
  options: [],
};

const CreateOrEditCustomFields = () => {
  const { fieldType, fieldId } = useParams();
  const navigate = useNavigate();

  const inputRefs = useRef({});
  const defaultData = useRef(defaultFieldsData);

  const [data, setData] = useState(defaultFieldsData);

  const [errors, setErrors] = useState({});

  const [loading, loadingToggle] = useState(true);
  const [saveLoading, saveLoadingToggle] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('type')) {
      changeData('customFieldType', 'customerCustomFields');
    }
  }, [searchParams.get('type')]);

  useEffect(() => {
    (async () => {
      if (fieldType !== 'add') {
        const payload = await Api.getSettingsList({ [fieldType]: fieldId });

        defaultData.current = { customFieldType: fieldType, ...payload.data[fieldType] };
        setData(defaultData.current);
      }

      loadingToggle(false);
    })();
  }, []);

  const changeData = useCallback((path, value) => {
    const resetDefaultValuePaths = ['type', 'options', 'attachment'];

    setData((prev) => {
      const newData = { ...prev };

      if (path === 'attachment') {
        newData.options = newData.options.includes(value)
          ? newData.options.filter((o) => o !== value)
          : [...newData.options, value];
      } else {
        _.set(newData, path, value);
      }

      if (resetDefaultValuePaths.includes(path)) {
        if (path.includes('type')) {
          newData.options = value === 'select' ? [''] : [];
        }

        newData.defaultValue = '';
      }

      return newData;
    });

    setErrors((prev) => {
      const newErrors = { ...prev };

      if (path === 'type') {
        _.forEach(newErrors, (val, key) => {
          if (!Number.isNaN(+key) || key === 'attachment') {
            delete newErrors[key];
          }
        });
      } else if (path === 'label' || path === 'attachment') {
        delete newErrors[path];
      } else if (path.includes('options')) {
        const [, index] = path.match(/\[(\d+)\]/);

        delete newErrors[index];
      }

      return newErrors;
    });
  }, []);

  const deleteOption = useCallback((index) => {
    setData((prev) => {
      const filteredOptions = prev.options.filter((o, i) => i !== index);
      const foundOption = filteredOptions.includes(prev.defaultValue);

      return {
        ...prev,
        defaultValue: foundOption ? prev.defaultValue : '',
        options: filteredOptions,
      };
    });
  }, []);

  const addOption = useCallback(() => {
    setData((prev) => ({
      ...prev,
      options: [...prev.options, ''],
    }));
  }, []);

  const save = useCallback(async () => {
    let hasError = false;

    if (!data.label.trim()) {
      setErrors((prev) => ({ ...prev, label: true }));
      hasError = true;
    }

    // if (data.type.includes('date')) {
    //   if (!data.options?.[0]) {
    //     setErrors((prev) => ({ ...prev, 0: true }));
    //     hasError = true;
    //   }
    //
    //   if (data.type.includes('dateTime') && !data.options?.[1]) {
    //     setErrors((prev) => ({ ...prev, 1: true }));
    //     hasError = true;
    //   }
    // }

    if (data.type.includes('select')) {
      data.options.forEach((o, index) => {
        if (!o.trim()) {
          setErrors((prev) => ({ ...prev, [index]: true }));
          hasError = true;
        }
      });
    } else if (data.type.includes('attachment') && _.isEmpty(data.options)) {
      setErrors((prev) => ({ ...prev, attachment: true }));

      hasError = true;
    }

    if (hasError) {
      toast.error('Please correct these fields');
    } else {
      saveLoadingToggle(true);
      const sendingData = { ...data };

      delete sendingData.customFieldType;

      try {
        if (fieldType === 'add') {
          await Api.createSettingsList({ [data.customFieldType]: sendingData });
        } else if (data.customFieldType !== defaultData.current.customFieldType) {
          delete sendingData.id;

          await Api.deleteSettingsList({ [defaultData.current.customFieldType]: data.id });
          await Api.createSettingsList({ [data.customFieldType]: sendingData });
        } else {
          await Api.updateSettingsList({ [data.customFieldType]: sendingData });
        }

        toast.success(`Custom field successfully ${fieldType === 'add' ? 'added' : 'updated'}`);

        if (searchParams.get('back')) {
          navigate(searchParams.get('back'));
        } else {
          navigate('/settings/custom-fields');
        }
      } catch (e) {
        setErrors(e.response.data.errors);
      }

      saveLoadingToggle(false);
    }
  }, [data, searchParams]);

  const cancel = useCallback(() => {
    setData(defaultData.current);
  }, []);

  const onDragEnd = (result) => {
    if (result.destination) {
      const options = reorder(
        data.options,
        result.source.index,
        result.destination.index,
      );

      setData({ ...data, options });

      const newErrors = {};
      options.forEach((o, index) => {
        if (!o.trim()) {
          newErrors[index] = true;
        }
      });

      setErrors(newErrors);
    }
  };

  const onDragStart = () => {
    Object.values(inputRefs.current).forEach((input) => input?.blur());
  };

  const {
    defaultValueType, format, symbol, validation, preview,
  } = useMemo(() => dataTypes.find((t) => t.value === data.type), [data.type]);

  const renderDefaultValueFields = (type, isPreview = false) => {
    const label = isPreview ? (data.label || <>&nbsp;</>) : 'Default value';

    return type?.includes('select')
      ? (
        <Select
          label={label}
          options={_.compact(data.options).map((o) => ({ value: o }))}
          value={data.defaultValue}
          onChange={(option) => {
            if (!isPreview) changeData('defaultValue', option);
          }}
          menuPosition="fixed"
          labelPath="value"
          roundBorder
          size="small"
          isDisabled={isPreview}
        />
      )

      : type?.includes('input')
        ? (
          <Input
            label={label}
            value={data.defaultValue}
            onChangeText={(value) => {
              if (!isPreview) changeData('defaultValue', value);
            }}
            size="small"
            roundBorder
            onBeforeInput={(e) => Utils.onBeforeInput(e, validation)}
            symbol={symbol}
            disabled={isPreview}
          />
        )

        : type?.includes('date')
          ? (
            <div className="add_custom_field_fields_date_time">
              <Input
                label={label}
                value="mm.dd.yyyy"
                size="small"
                roundBorder
                disabled={isPreview}
              />

              {type.includes('Time') && (
                <Timepicker disabled />
              )}
            </div>
          )

          : null;
  };

  return (
    <Wrapper
      title={`${fieldType === 'add' ? 'Add' : 'Edit'} custom field`}
      className="add_custom_field_page"
      buttonTitle="Save"
      btnLoading={saveLoading}
      onBtnClick={save}
      onCancelBtnClick={cancel}
    >
      {loading
        ? <Loader />

        : (
          <div className="add_custom_field_wrapper">
            <div className="add_custom_field_fields">
              {fields.map(({
                field, path, label, options,
              }) => (
                <Fragment key={path}>
                  {field === 'select'
                    ? (
                      <Select
                        label={label}
                        options={options}
                        value={_.get(data, path)}
                        onChange={(option) => changeData(path, option)}
                        roundBorder
                        size="small"
                      />
                    )

                    : (
                      <Input
                        label={label}
                        value={_.get(data, path)}
                        onChangeText={(value) => changeData(path, value)}
                        error={errors[path]}
                        size="small"
                        roundBorder
                      />
                    )}
                </Fragment>
              ))}

              {/* Default Value start */}
              {renderDefaultValueFields(defaultValueType)}
              {/* Default Value end */}

              {/* Format start */}
              {format?.includes('dropdown')
                ? (
                  <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {(droppableProvided, droppableSnapshot) => (
                        <div
                          className="add_custom_field_fields_dropdown"
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                        >
                          {data.options.map((o, index) => (
                            <div key={index} className="add_custom_field_fields_dropdown_items_wrapper">
                              <Draggable
                                index={index}
                                draggableId={`id_${index}`}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    className="add_custom_field_fields_dropdown_items"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className={classNames('add_custom_field_fields_dropdown_item', {
                                      error: errors[index],
                                    })}
                                    >
                                      <DraggableIcon />

                                      <Checkbox
                                        checked={!!data.defaultValue.trim() && o === data.defaultValue}
                                      />

                                      <input
                                        type="text"
                                        ref={(ref) => {
                                          inputRefs.current[index] = ref;
                                        }}
                                        value={o}
                                        onChange={({ target: { value } }) => changeData(`options[${index}]`, value)}
                                      />
                                    </div>

                                    {data.options.length > 1 && (
                                      <Button
                                        btnType="transparent"
                                        className="add_custom_field_fields_dropdown_item_icon_wrapper"
                                        onClick={() => deleteOption(index)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    )}

                                    {!droppableSnapshot.isDraggingOver && index === data.options.length - 1 && (
                                      <Button
                                        btnType="transparent"
                                        className="add_custom_field_fields_dropdown_item_icon_wrapper"
                                        onClick={addOption}
                                      >
                                        <AddIcon className="add_icon" />
                                      </Button>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            </div>
                          ))}

                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )

                : format?.includes('attachment')

                  ? (
                    <div className={classNames('add_custom_field_fields_attachment', { error: errors.attachment })}>
                      {attachmentOptions.map((o) => (
                        <Checkbox
                          key={o.value}
                          label={o.label}
                          checked={data.options.includes(o.value)}
                          onChange={() => changeData('attachment', o.value)}
                          labelMarginLeft={10}
                        />
                      ))}
                    </div>
                  )
                  : null}
              {/* Format end */}

              <div className="add_custom_field_fields_radio">
                {_.map(radioFields, ({ title, options }, key) => (
                  <div key={key}>
                    <p>{title}</p>

                    {options.map((o) => (
                      <Checkbox
                        key={o.value}
                        label={o.label}
                        checked={_.get(data, key) === o.value}
                        onChange={() => changeData(key, o.value)}
                        radio
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>

            <div className="add_custom_field_preview">
              <h2>Preview</h2>

              <div>
                {renderDefaultValueFields(preview, true)}
              </div>
            </div>
          </div>
        )}
    </Wrapper>
  );
};

export default CreateOrEditCustomFields;

// format?.includes('date')
//   ? (
//     <div className="add_custom_field_fields_date_time">
//       <Select
//         label="Date"
//         options={dateOptions}
//         value={data.options[0]}
//         onChange={(option) => changeData('options[0]', option)}
//         error={errors[0]}
//         menuPosition="fixed"
//         labelPath="value"
//         roundBorder
//         size="small"
//       />
//
//       {format.includes('Time') && (
//         <Select
//           label="Time"
//           options={timeOptions}
//           value={data.options[1]}
//           onChange={(option) => changeData('options[1]', option)}
//           error={errors[1]}
//           menuPosition="fixed"
//           labelPath="value"
//           roundBorder
//           size="small"
//         />
//       )}
//     </div>
//   )
