import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'currency-formatter';
import moment from 'moment/moment';
import ReturnsList from '../../components/pages/Orders/returns/ReturnsList';
import ReturnPreviewHeader from '../../components/pages/Orders/returns/ReturnPreviewHeader';
import ReceiveForm from '../../components/pages/Orders/returns/ReceiveForm';
import Wrapper from '../../components/Layout/Wrapper';
import noImg from '../../assets/icons/no_img.svg';
import { getProductsRequest } from '../../store/actions/products';
import Utils from '../../helpers/Utils';

const tabs = ['sales_orders', 'receives'];

function ReturnPreview() {
  const [tab, setTab] = useState('sales_orders');
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const { returnId } = useParams();
  const dispatch = useDispatch();

  const singleReturn = useSelector((state) => state.orderReturns.orderReturnsList).find((s) => s.id === returnId);

  useEffect(() => {
    (async () => {
      if (!singleReturn) {
        return;
      }
      const { payload } = await dispatch(getProductsRequest(
        { ids: singleReturn.orderReturnProducts.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [singleReturn]);

  return (
    <Wrapper>
      <div className="preview_wrapper return_preview">
        <Helmet>
          <body className="sales_order_preview" />
        </Helmet>
        <ReturnsList />
        <div className="content">
          <ReturnPreviewHeader />
          <div className="order_preview_content">
            <div className="left">
              <div className="action_tabs">
                <div className="tabs">
                  {tabs.map((t) => (
                    <div
                      className={classNames('tab', { active: tab === t })}
                      onClick={() => setTab(t)}
                      key={t}
                    >
                      {_.startCase(t)}
                    </div>
                  ))}
                </div>
                {tab === 'sales_orders' ? (
                  <div className="items sales_orders">
                    <div className="products_list table_wrapper">
                      <table className="table">
                        <thead className="table_thead">
                          <tr className="table_thead_tr">
                            <th className="table_thead_tr_th">Order#</th>
                            <th className="table_thead_tr_th">Date</th>
                            <th className="table_thead_tr_th">Status</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody className="table_tbody">
                          <tr
                            className="table_tbody_tr"
                          >

                            <td className="table_tbody_tr_td link">
                              <Link
                                to={`/orders/sales-order/preview/${singleReturn?.orderId}`}
                              >
                                {singleReturn?.order.number}
                              </Link>
                            </td>
                            <td className="table_tbody_tr_td">
                              {moment(singleReturn?.order.sourceCreatedAt).format('MM/DD/YYYY')}
                            </td>
                            <td className={`table_tbody_tr_td status ${singleReturn?.order.status}`}>
                              <div>
                                <p className="status_dot" />
                                <span>{Utils.formatStatus(singleReturn?.order.status)}</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
                {tab === 'receives' ? (
                  <div className="items sales_orders">
                    <div className="products_list table_wrapper">
                      <table className="table">
                        <thead className="table_thead">
                          <tr className="table_thead_tr">
                            <th className="table_thead_tr_th">Receive#</th>
                            <th className="table_thead_tr_th">Received On</th>
                          </tr>
                        </thead>
                        <tbody className="table_tbody">
                          {singleReturn?.orderReturnAccepts?.map((p) => (
                            <tr
                              className="table_tbody_tr"
                              key={p.id}
                            >

                              <td className="table_tbody_tr_td">
                                {p.number}
                              </td>
                              <td className="table_tbody_tr_td">
                                <span>{moment(singleReturn.sourceCreatedAt).format('DD MMM YYYY')}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="items">
                <div className="products_list table_wrapper">
                  <table className="table">
                    <thead className="table_thead">
                      <tr className="table_thead_tr">
                        <th className="table_thead_tr_th">Item</th>
                        <th className="table_thead_tr_th">Quantity</th>
                        <th className="table_thead_tr_th">Rate</th>
                        <th className="table_thead_tr_th">Amount</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody className="table_tbody">
                      {singleReturn?.orderReturnProducts?.map((p) => {
                        const product = products?.find((f) => f.id === p.productId) || {};
                        const order = singleReturn?.order?.orderProducts?.find((f) => f.productId === p.productId) || {};
                        return (
                          <tr
                            className="table_tbody_tr"
                            key={p.id}
                          >

                            <td className="table_tbody_tr_td">
                              <div className="info">
                                <img
                                  style={{ height: 30, width: 30 }}
                                  src={product.images?.[0]?.src || product.images?.[0]?.medium || noImg}
                                  alt=""
                                />
                                <Link to={`/products/${product.id}`} target="_blank">{product.title}</Link>
                              </div>
                            </td>
                            <td className="table_tbody_tr_td">
                              {p.qty}
                            </td>
                            <td
                              className="table_tbody_tr_td"
                            >
                              {format(order.price, { code: singleReturn.order.currencyCode })}
                            </td>
                            <td
                              className="table_tbody_tr_td"
                            >
                              {format((order.price * p.qty), { code: singleReturn.order.currencyCode })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="order_information_container">
              <div className="order_information">
                <div className="col">
                  <div className="item">
                    <p
                      className="value"
                    >
                      {`${singleReturn?.customer?.firstName || ''} ${singleReturn?.customer?.lastName || ''}`}
                    </p>
                  </div>
                  <div className="item">
                    <p className="value">{singleReturn?.customer?.phone || ''}</p>
                  </div>
                  <div className="item">
                    <p className="value">{singleReturn?.customer?.email || ''}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {location.state?.receive ? (
          <ReceiveForm />
        ) : null}
      </div>
    </Wrapper>
  );
}

export default ReturnPreview;
