import React from 'react';
import { ReactComponent as NotFoundIcon } from '../assets/icons/404.svg';

const NotFound = () => (
  <div className="not_found_page_wrapper">
    <NotFoundIcon />

    <h1>404</h1>

    <h2>Page Not Found</h2>

    <p>You may have mistyped the address or the page may have been moved.</p>
  </div>
);

export default NotFound;
