import React from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../../Layout/Wrapper';
import CreditNoteForm from '../preview/CreditNoteForm';

function CreditNotesCreate() {
  const navigate = useNavigate();
  return (
    <Wrapper className="credit_notes-create" title="Create Credit Note">
      <CreditNoteForm onCancel={() => navigate('/orders/credit-notes')} />
    </Wrapper>
  );
}

export default CreditNotesCreate;
