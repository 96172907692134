import React from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { ReactComponent as FiltersIcon } from '../../../../assets/icons/filters.svg';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import Input from '../../../_common/Form/Input';
import FilterButton from '../../../_common/Form/FilterButton';

function CreditNotesFilters() {
  const [search, setSearch] = useQueryParam(
    's',
    withDefault(StringParam, ''),
    { updateType: 'replace', removeDefaultsFromUrl: true },
  );

  return (
    <div className="filters">
      <Input search roundBorder placeholder="Search" value={search} onChange={(ev) => setSearch(ev.target.value)} />
    </div>
  );
}

export default CreditNotesFilters;
