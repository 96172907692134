import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';

function Collapsible(props) {
  const {
    minHeight = 60, className, children, title, speed = 1, unmount = false,
  } = props;
  const ref = useRef();
  const maxHeight = useRef(minHeight);
  const timeout = useRef(minHeight);
  const [style, setStyle] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    clearTimeout(timeout.current);
    maxHeight.current = Math.max(ref.current.scrollHeight, maxHeight.current);
    const time = maxHeight.current * speed;
    if (isOpen) {
      setIsVisible(true);
      setStyle({
        height: maxHeight.current,
        transition: `height ${time}ms ease-out`,
        overflow: 'hidden',
      });
      timeout.current = setTimeout(() => {
        setStyle({});
      }, time);
    } else {
      ref.current.style.height = `${maxHeight.current}px`;
      setStyle({
        height: minHeight,
        transition: `height ${time}ms ease-out`,
        overflow: 'hidden',
      });
      if (unmount) {
        timeout.current = setTimeout(() => {
          setIsVisible(false);
        }, time + 200);
      }
    }
  }, [isOpen, minHeight]);

  return (
    <div style={style} ref={ref} className={classNames('collapsible', className, isOpen ? 'open' : 'close')}>
      <p className="title">
        <ArrowIcon className="arrow" onClick={() => setIsOpen(!isOpen)} />
        <span>{title}</span>
      </p>
      {isVisible ? children : null}
    </div>
  );
}

export default Collapsible;
