import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../_common/Form/Button';
import Checkbox from '../../../_common/Form/Checkbox';
import Loader from '../../../_common/Loader/Loader';

const AppInstallInfoWrapper = ({
  children, shopIcon, shopName, description, onModalOpen, installTypes, installType, onChangeInstallType, loading,
  btnLoading,
}) => (
  loading
    ? <Loader size={50} />

    : (
      <div className="app_wrapper">
        <div className="app_top_wrapper">
          <div className="app_top_wrapper_shop">
            <div className="app_top_wrapper_left_block">
              <div className="app_top_wrapper_left_block_shop_info_wrapper">
                <div className="app_top_wrapper_left_block_shop_logo">
                  {shopIcon}
                </div>

                <div className="app_top_wrapper_left_block_shop_title">
                  {`${shopName} - Integration Setup Guide`}
                </div>
              </div>

              <p className="app_top_wrapper_left_block_shop_description">
                {description}
              </p>

              {onChangeInstallType && (
              <div className="app_top_wrapper_left_block_install_type">
                <h2>Install type</h2>

                <div>
                  {installTypes.map(({ label, value }) => (
                    <Checkbox
                      key={value}
                      label={label}
                      onChange={() => onChangeInstallType(value)}
                      checked={value === installType}
                      radio
                    />
                  ))}
                </div>
              </div>
              )}

              <Button
                size="small"
                roundBorder
                onClick={onModalOpen}
                loading={btnLoading}
              >
                Install app
              </Button>
            </div>

            <div className="app_top_wrapper_right_block">
              <h3 className="app_top_wrapper_right_block_title">
                How it works with eSwap
              </h3>

              <div className="app_top_wrapper_right_block_help_info">
                <p>
                  If your credentials are correct your channel will successfully be integrated and all products, orders
                  and
                  customers will be synced to eSwap.
                </p>

                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p>Name your channel to use in eSwap. e.g. 'ebay'. 'amazon-us'.</p>

                <p>Every 30 minutes, new orders will be download from [shop] to eSwap with customers data.</p>

                <p>Twice in day, eSwap checks for new listings in to be downloaded and synced.</p>

                <p>Automatically update order status on sales channel at each step of the fulfilment journey.</p>

                <p>
                  Quickly find any order with intelligent search technology and efficiently prioritise via advanced
                  filters.
                </p>

                <p>
                  Clearly communicate any special requirements or details with your team via internal notes and
                  tags.
                </p>

                <p>Keep inventory up to date across warehouses and bulk edit products in a few clicks.</p>

                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p>Easily create returns, refunds, fulfilment's in eSwap</p>
              </div>
            </div>
          </div>

          <div className="app_top_wrapper_need_help">
            <h3 className="app_top_wrapper_right_block_title">
              Need help?
            </h3>

            <div>
              <p>
                Check
                <span><Link to="#">Integration Guide</Link></span>
              </p>

              <p>
                Email:
                <span><Link to="#">support@eswap.global</Link></span>
              </p>

              <p>
                Website:
                <span><Link to="#">https://eswap.global</Link></span>
              </p>

              <Link to="/">Support articles</Link>
            </div>
          </div>
        </div>

        <div className="app_bottom_wrapper">
          <h2>Installation instruction</h2>

          <div className="app_bottom_wrapper_content">
            {children}
          </div>
        </div>
      </div>
    )
);

AppInstallInfoWrapper.protoType = {
  shopName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  shopIcon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  installType: PropTypes.string,
  installTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onChangeInstallType: PropTypes.func,
  loading: PropTypes.bool,
  btnLoading: PropTypes.bool,
};

AppInstallInfoWrapper.defaultProps = {
  installType: '',
  installTypes: [],
  onChangeInstallType: undefined,
  loading: false,
  btnLoading: false,
};

export default AppInstallInfoWrapper;
