import React, {
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import Wrapper from '../../Layout/Wrapper';
import Loader from '../../_common/Loader/Loader';
import Filters from '../Products/Filters';
import Table from '../../_common/Tables/Table';
import Pagination from '../../_common/Pagination/Pagination';
import Api from '../../../Api';
import { ReactComponent as LogsNotFoundIcon } from '../../../assets/icons/no_match.svg';
import { ReactComponent as ShopIcon } from '../../../assets/icons/filters/shop.svg';
import { ReactComponent as StatusIcon } from '../../../assets/icons/filters/status.svg';
import { ReactComponent as ProcessIcon } from '../../../assets/icons/filters/process.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/filters/user.svg';
import Account from '../../../helpers/Account';
import Utils from '../../../helpers/Utils';

const defaultFiltersParams = withDefault(ArrayParam, []);

const loadOptions = async (query, filter, { search, page }) => {
  if (filter.staticData) {
    return { data: filter.staticData };
  }

  let data;
  let totalPages;

  if (filter.path === 'integrationId') {
    const { data: { integrations } } = await Api.getIntegrations();

    data = [{ id: '0', label: 'eSwap' }, ...integrations.map((s) => ({ id: s.id, label: s.name }))];
  }

  if (filter.path === 'ownerId') {
    const { data: { users, totalPages: totalPageCount } } = await Api.getAdminUsers({
      page: search ? 1 : page,
      s: search,
      includeIds: page === 1 && query[filter.valuePath]?.length ? query[filter.valuePath].join(',') : undefined,
    });

    data = users.map((c) => ({ id: c.id, label: `${c.firstName || '-'} ${c.lastName || '-'}` }));
    totalPages = search ? 1 : totalPageCount;
  }

  return { data, totalPages };
};

const Logs = () => {
  const isAdmin = Account.get()?.isAdmin;
  const hasOwnerId = Account.getOwnerId();

  const [filters, setFilters] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    integrationId: defaultFiltersParams,
    filter: defaultFiltersParams,
    level: defaultFiltersParams,
    ownerId: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const firstLoad = useRef(false);
  const timeout = useRef();
  const appsLists = useRef([]);

  const [logsData, setLogsData] = useState({
    list: [],
    totalPages: 1,
  });

  const { list, totalPages } = logsData;

  const [loading, loadingToggle] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    firstLoad.current = false;

    setSearchParams((prev) => {
      prev.delete('force');

      return prev;
    }, { replace: true });
  }, [searchParams.get('force')]);

  useEffect(() => {
    loadingToggle(true);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      if (!firstLoad.current && !isAdmin) {
        const { data: { integrations } } = await Api.getIntegrations();

        appsLists.current = integrations;
      }

      const sendingFilters = Utils.deleteEmptyKeys(filters);

      sendingFilters.level = sendingFilters.level ? sendingFilters.level.join(',') : 'error,info,warn,debug';

      if (sendingFilters.integrationId) sendingFilters.integrationId = filters.integrationId.join(',');
      if (sendingFilters.filter) sendingFilters.filter = sendingFilters.filter.join(',');
      if (sendingFilters.ownerId) sendingFilters.ownerId = sendingFilters.ownerId.join(',');

      const { data } = await Api.getAllLogs(sendingFilters);

      setLogsData({
        list: data.logs.map((l) => {
          const foundApp = _.upperFirst(appsLists.current.find((app) => app.id === l.integrationId)?.name);

          l.integrationName = foundApp || 'eSwap';
          l.status = l.level;

          return l;
        }),
        totalPages: data.totalPages,
      });

      loadingToggle(false);
      firstLoad.current = true;
    }, 500);
  }, [filters, searchParams.get('force')]);

  const tableHeader = useMemo(() => {
    const header = [
      {
        title: 'Number',
        path: 'index',
      },
      {
        title: 'Status',
        path: 'status',
      },
      {
        title: 'Time',
        path: 'createdAt',
      },
      {
        title: 'Sales channel',
        path: 'integrationName',
      },
      {
        title: 'Message',
        path: 'message',
      },
    ];

    if (isAdmin) {
      header.push({ title: 'Stack', path: 'stack' });
    }

    return header;
  }, []);

  const defaultFilterData = useMemo(() => {
    let filterData = [
      { label: 'Shop', valuePath: 'integrationId' },
      { label: 'Type', valuePath: 'level' },
      { label: 'Process', valuePath: 'filter' },
    ];

    if (isAdmin) {
      filterData = [...filterData.slice(1), { label: 'User', valuePath: 'ownerId' }];
    }

    return filterData;
  });

  const productFilterOptions = useMemo(() => {
    const options = {
      integrationId: {
        label: 'Shop',
        path: 'integrationId',
        valuePath: 'integrationId',
        icon: <ShopIcon />,
        localSearch: true,
      },
      level: {
        label: 'Type',
        path: 'level',
        valuePath: 'level',
        icon: <StatusIcon />,
        staticData: [
          {
            label: 'Success',
            id: 'info',
          },
          {
            label: 'Error',
            id: 'error',
          },
          {
            label: 'Warning',
            id: 'warn',
          },
        ],
      },
      filter: {
        label: 'Process',
        path: 'filter',
        valuePath: 'filter',
        icon: <ProcessIcon />,
        staticData: [
          {
            label: 'Products import',
            id: 'import-products',
          },
          {
            label: 'Orders import',
            id: 'import-orders',
          },
          {
            label: 'User import',
            id: 'import-customers',
          },
          {
            label: 'Others',
            id: 'others',
          },
        ],
      },
    };

    if (isAdmin || hasOwnerId) {
      options.level.staticData = [...options.level.staticData, {
        label: 'Debug',
        id: 'debug',
      }];
    }

    if (isAdmin) {
      options.ownerId = {
        label: 'User',
        path: 'ownerId',
        valuePath: 'ownerId',
        icon: <UserIcon />,
      };
    }

    return options;
  }, []);

  return (
    <Wrapper title="Logs" className="logs_page">
      {!firstLoad.current
        ? <Loader />

        : (
          <>
            <div className="logs_search_field_wrapper">
              <Filters
                queryData={filters}
                setQuery={setFilters}
                defaultFilterData={defaultFilterData}
                filterOptions={productFilterOptions}
                loadOptions={(filter, searchFilters) => loadOptions(filters, filter, searchFilters)}
              />
            </div>

            <div className="logs_table_wrapper">
              <Table
                data={list}
                header={tableHeader}
                loading={loading}
                notFound={(
                  <>
                    <LogsNotFoundIcon />

                    <h1>Logs not found</h1>
                  </>
                )}
              />

              <Pagination
                onChangePage={(p) => setFilters((prev) => ({
                  ...prev,
                  page: p,
                }))}
                currentPage={filters.page}
                totalPages={totalPages}
              />
            </div>
          </>
        )}
    </Wrapper>
  );
};

export default Logs;
