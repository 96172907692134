import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ShopifyIcon } from '../../../../assets/icons/apps/shopify.svg';
import ShopifyImg1 from '../../../../assets/images/apps/shopify/shopify_1.png';
import ShopifyImg2 from '../../../../assets/images/apps/shopify/shopify_2.png';
import ShopifyImg3 from '../../../../assets/images/apps/shopify/shopify_3.png';
import ShopifyImg4 from '../../../../assets/images/apps/shopify/shopify_4.png';
import ShopifyImg5 from '../../../../assets/images/apps/shopify/shopify_5.png';
import ShopifyImg6 from '../../../../assets/images/apps/shopify/shopify_6.png';
import ShopifyImg7 from '../../../../assets/images/apps/shopify/shopify_7.png';
import ShopifyImg8 from '../../../../assets/images/apps/shopify/shopify_8.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import formatter from '../../../../helpers/Formatter';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { appInstallFields } from '../../../../helpers/staticData';

const fields = {
  oAuth: appInstallFields.shopifyOAuth,
  manual: appInstallFields.shopifyManual,
};

const installTypes = [
  {
    label: 'oAuth',
    value: 'oAuth',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
];

function ShopifyInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const [installType, setInstallType] = useState('oAuth');

  const [installModal, setInstallModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const install = useCallback(async (data) => {
    try {
      if (installType === 'oAuth') {
        try {
          const response = await Api.installApp(app, {
            integrationName: data.integrationName,
            url: formatter.url(data.url),
            redirectUrl: `${window.location.origin}/integration-verify/${app}`,
          });

          localStorage.setItem(`${app}IntegrationInfo`, JSON.stringify({
            integrationName: data.integrationName,
          }));

          window.location.href = response.data.params.redirectUrl;
        } catch (err) {
          setInstallModal(false);
          toast.error(err.response.data.message);
        }
      } else {
        const { data: { integration: { id } } } = await Api.createIntegration(
          app,
          {
            integrationName: data.integrationName,
            url: formatter.url(data.url),
            accessToken: data.accessToken,
            clientId: data.clientId,
            clientSecret: data.clientSecret,
            // redirectUrl: `${window.location.origin}/integration-verify/${app}`,
          },
        );

        navigate(`/apps/integration/${id}`);
      }
    } catch (err) {
      if (err.response.data?.errors) {
        throw err;
      } else {
        toast.error(err.response.data.message);
        setInstallModal(false);
      }
    }
  }, [installType]);

  const checkAllowedBeforeInstall = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await Api.getIntegrations({ type: 'shopify' });

      if (data.integrations.length) {
        toast.info('Shopify already installed');
        return;
      }
      setInstallModal(true);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Shopify"
      shopIcon={<ShopifyIcon />}
      installType={installType}
      installTypes={installTypes}
      onChangeInstallType={setInstallType}
      description={'eSwap\'s Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={checkAllowedBeforeInstall}
      btnLoading={loading}
    >
      <p className="title">
        Go to Available Apps -&gt; Sales Channels. Tap on Shopify and click on the
        {' '}
        <strong>Install App</strong>
        {' '}
        button.
      </p>

      <p>
        <img
          src={ShopifyImg1}
          alt="info image"
        />
      </p>

      <p>
        Now you just need to enter some unique shop name(this uses in eSwap like a
        nickname to your shop) and your shop address, which is in Your app URL and
        click to
        {' '}
        <strong>“Install App</strong>
        ” button.
      </p>

      <p>
        <img
          src={ShopifyImg2}
          alt="info image"
        />
      </p>

      <p>
        You’ll be redirected to your Shopify store to approve eSwap -&gt; Shopify
        connection.
      </p>

      <p>
        <img
          src={ShopifyImg3}
          alt="info image"
        />
      </p>

      <p>
        Now you need to proceed with the next few steps to complete the
        installation.
      </p>

      <h2>STEP 1</h2>

      <p>
        Configure Shopify stock locations. Shopify has multiple locations
        functionality,&nbsp; you can map your eSwap and Shopify locations. Here you
        need to choose warehouse in eSwap where your Shopify stock levels will be
        synced. You can also create a new warehouse, for your Shopify store.
      </p>

      <p>
        <img
          src={ShopifyImg4}
          alt="info image"
        />
      </p>

      <h2>STEP 2</h2>

      <p>
        Sync your Shopify prices in eSwap’s pricelists or create a new one for each
        price.
      </p>

      <p>
        <img
          src={ShopifyImg5}
          alt="info image"
        />
      </p>

      <h2>STEP 3</h2>

      <p>
        On this step, you need to choose the default warehouse where all of your
        Shopify orders will be shipped and synced from, and from which price list
        incoming orders will be synced.
      </p>

      <p>
        <img
          src={ShopifyImg6}
          alt="info image"
        />
      </p>

      <h2>STEP 4</h2>

      <p>
        On this step, we ask you to import all your data and automatically sync
        matching products or you can manually import them later from My Apps -&gt;
        Shopify page.
      </p>

      <p>
        <img
          src={ShopifyImg7}
          alt="info image"
        />
      </p>

      <p>
        On the top, you can see a progress bar that will show what we are syncing.
        You can do other processes until your WooCommerce channel will be fully
        integrated. We’ll email you and notify you in the notification section as
        well.
      </p>

      <p>
        <img
          src={ShopifyImg8}
          alt="info image"
        />
      </p>

      <p>
        That’s all now your installation is
        {' '}
        <strong>complete</strong>
        !
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={fields[installType]}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default ShopifyInstall;
