import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { ReactComponent as ShipBobIcon } from '../../../../assets/icons/apps/shipbob.svg';
import Api from '../../../../Api';
import UpdateModal from '../../../_common/Modal/UpdateModal';

const fields = [
  {
    title: 'Your app name',
    placeholder: 'Enter your app name',
    path: 'integrationName',
    required: true,
  },
  {
    title: 'Your app store id',
    placeholder: 'Enter your app store id',
    path: 'storeId',
    required: true,
  },
  {
    title: 'Your shop',
    placeholder: 'Enter your shop',
    path: 'shop',
    required: true,
  },
];

const ShipBobInstall = () => {
  const { app } = useParams();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    const response = await Api.installApp(
      app,
      {
        integrationName: data.integrationName,
        redirectUrl: `${window.location.origin}/integration-verify/${app}`,
      },
    );

    localStorage.setItem(`${app}IntegrationInfo`, JSON.stringify(data));

    window.location.href = response.data.params.redirectUrl;
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="ShipBob"
      shopIcon={<ShipBobIcon />}
      description="ship bob integration helps you to manage stock levels"
      onModalOpen={() => setInstallModal(true)}
    >
      Integration info

      <UpdateModal
        isOpen={installModal}
        fields={fields}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
};

export default ShipBobInstall;
