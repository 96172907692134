import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import Wrapper from '../../../../Layout/Wrapper';
import Api from '../../../../../Api';
import Pagination from '../../../../_common/Pagination/Pagination';
import Table from '../../../../_common/Tables/Table';
import Loader from '../../../../_common/Loader/Loader';
import ScheduleModal from '../ScheduleModal';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Input from '../../../../_common/Form/Input';
import { ReactComponent as ExportIcon } from '../../../../../assets/icons/export.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/remote_printing.svg';
import Button from '../../../../_common/Form/Button';

const tableHeader = [
  {
    title: 'Receive number',
    path: 'receiveNumber',
  },
  {
    title: 'Purchase order number',
    path: 'poNumber',
  },
  {
    title: 'Receive date',
    path: 'receiveDate',
  },
  {
    title: 'Vendor name',
    path: 'vendor',
  },
  {
    title: 'QTY received',
    path: 'qtyReceived',
  },
];

function PurchaseReceiveHistoryReport() {
  const navigate = useNavigate();
  const firstLoad = useRef(true);
  const searchTimeout = useRef(null);

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    // sortBy: withDefault(StringParam, 'createdAt'),
    // sort: withDefault(StringParam, 'desc'),
    startDate: withDefault(StringParam, moment().subtract(1, 'month').format('MM-DD-YYYY')),
    endDate: withDefault(StringParam, moment().format('MM-DD-YYYY')),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [totalPages, setTotalPages] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false);

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    (async () => {
      searchTimeout.current = setTimeout(async () => {
        setTableLoading(true);

        try {
          await getPurchaseReceiveHistory({ allQuery: queryData });
        } catch (e) {
          navigate('/404');
        }

        setTableLoading(false);
        firstLoad.current = false;
      }, 500);
    })();
  }, [queryData]);

  const getPurchaseReceiveHistory = useCallback(async ({ allQuery }) => {
    const { data } = await Api.getPurchaseReceiveHistoryReport(allQuery);

    setTotalPages(data.totalPages);
    setReportData(data.orders);
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setQuery((prev) => ({
      ...prev,
      sort: newSorting.sort,
      sortBy: newSorting.sortBy,
    }));
  }, [queryData.sort, queryData.sortBy]);

  const onSchedule = useCallback(() => {
    setScheduleIsOpen(true);
  }, []);

  const onExport = useCallback(async () => {
    try {
      await Api.exportPurchaseReceiveHistoryReport({
        by: {
          startDate: queryData.startDate,
          endDate: queryData.endDate,
          s: queryData.s,
        },
        fields: ['receiveNumber', 'receiveDate', 'qtyReceived', 'poNumber', 'vendor'],
        fileType: 'csv',
      });

      toast.success('Successfully exported. You will receive an email.');
    } catch (e) {
      toast.error('Something went wrong. Please try later.');
    }
  }, [queryData]);

  const onPrint = () => {
    window.print();
  };

  return (
    <Wrapper
      title="Purchase receive history"
      onBtnClick={onSchedule}
      buttonTitle="Schedule report"
      btnType="light_blue"
    >
      <div className="inventory_report">
        <div className="inventory_report_filters">
          <div className="inventory_report_filter_search">
            <Input
              value={queryData.s}
              placeholder="Search"
              size="small"
              roundBorder
              search
              onChangeText={(val) => setQuery((prev) => ({
                ...prev,
                s: val,
                page: 1,
              }))}
              data-test-id="filter_search"
            />
          </div>

          <div className="inventory_report_filter_right">
            <Button
              onClick={onPrint}
              size="small"
              roundBorder
              iconLeft={<PrintIcon />}
              title="Print"
              btnType="transparent"
              className="print_button"
            />

            {!!reportData.length && (
              <Button
                onClick={onExport}
                size="small"
                roundBorder
                iconLeft={<ExportIcon />}
                title="Export"
                btnType="transparent"
                className="export_button"
              />
            )}

            <RangeDatePicker
              currentDate={{
                startDate: queryData.startDate,
                endDate: queryData.endDate,
              }}
              onChangeDate={(value) => setQuery((prev) => ({
                ...prev,
                startDate: value[0],
                endDate: (value[0] > value[1] || !value[1]) ? value[0] : value[1],
                page: 1,
              }))}
              calendarClassName="inventory_report_filters_menu_calendar"
              popperPlacement="bottom-start"
            />
          </div>
        </div>

        {firstLoad.current ? <Loader /> : (
          <div className="inventory_report_table">
            <Table
              data={reportData}
              header={tableHeader}
              deleteModalText="user invitation"
              sortBy={queryData.sortBy}
              sort={queryData.sort}
              onSortBy={onSortBy}
              loading={tableLoading}
              hideDeleteButtonFields={['active', 'restricted']}
            />
          </div>
        )}

        <div className="inventory_report_pagination">
          <Pagination
            totalPages={totalPages}
            onChangePage={(p) => setQuery((prev) => ({
              ...prev,
              page: p,
            }))}
          />
        </div>

        <ScheduleModal isOpen={scheduleIsOpen} onClose={() => setScheduleIsOpen(false)} />
      </div>
    </Wrapper>
  );
}

export default PurchaseReceiveHistoryReport;
