import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import Button from '../../components/_common/Form/Button';
import CustomersTable from '../../components/pages/Customers/CustomersTable';
import {
  customersTableEditableEvent,
  getCustomersRequest,
  getCustomersTableAttributes,
} from '../../store/actions/customers';
import { ReactComponent as CustomersNotFoundIcon } from '../../assets/icons/notFound/customers_not_found.svg';
import { ReactComponent as TypeIcon } from '../../assets/icons/filters/productType.svg';
import { ReactComponent as ShopIcon } from '../../assets/icons/filters/shop.svg';
import { ReactComponent as CountryIcon } from '../../assets/icons/filters/country.svg';

import Api from '../../Api';
import Wrapper from '../../components/Layout/Wrapper';
import Filters from '../../components/pages/Products/Filters';
import Utils from '../../helpers/Utils';
import TitleBar from '../../components/Layout/TitleBar';

const defaultFilterData = [
  { label: 'Type', valuePath: 'type' },
  { label: 'Shop', valuePath: 'shopId' },
  { label: 'Country', valuePath: 'countryCode' },
];

const filterOptions = {
  type: {
    label: 'Type',
    path: 'type',
    valuePath: 'type',
    icon: <TypeIcon />,
    staticData: [
      {
        label: 'Individual',
        id: 'individual',
      }, {
        label: 'Company',
        id: 'company',
      },
    ],
  },
  shopId: {
    label: 'Shop',
    path: 'shop',
    valuePath: 'shopId',
    icon: <ShopIcon />,
    localSearch: true,
  },
  countryCode: {
    label: 'Countries',
    path: 'countries',
    icon: <CountryIcon />,
    valuePath: 'countryCode',
    localSearch: true,
  },
};

const defaultFiltersParams = withDefault(ArrayParam, []);

const titleFilter = [
  {
    label: 'All Contacts',
    path: '',
  },
  {
    label: 'All Customers',
    path: 'isCustomer',
  },
  {
    label: 'All Vendors',
    path: 'isVendor',
  },
];

function Customers({ archive = false }) {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const selectRef = useRef(null);
  const tableEditable = useSelector((state) => state.customers.tableEditable);
  const columns = useSelector((state) => state.customers.columns);
  const customers = useSelector((state) => state.customers.customers);
  const firstLoadingRef = useRef(true);

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    type: defaultFiltersParams,
    customerType: withDefault(StringParam, ''),
    countryCode: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const { customerType } = queryData;

  const loadOptions = async (filter) => {
    if (filter.staticData) {
      return { data: filter.staticData };
    }

    let data;
    let totalPages;

    if (filter.path === 'countries') {
      const { data: { countries } } = await Api.getCountries();

      data = countries.map((country) => ({ id: country.code, label: country.name }));
    }

    if (filter.path === 'shop') {
      const { data: { integrations, totalPages: p } } = await Api.getIntegrations({ category: 'shop' });

      data = integrations.map((s) => ({ id: s.id, label: s.name }));
      totalPages = p;
    }
    return { data, totalPages };
  };

  useEffect(() => {
    (async () => {
      if (firstLoadingRef.current) {
        await dispatch(getCustomersTableAttributes());
      }
      await handleGetCustomers();
      setLoadingData(false);

      firstLoadingRef.current = false;
    })();

    return () => dispatch(customersTableEditableEvent(false));
  }, [queryData]);

  const handleGetCustomers = async () => {
    const params = Utils.deleteEmptyKeys({
      ...queryData,
      type: queryData.type.join(',') || null,
      shopId: queryData.shopId.join(',') || null,
      countryCode: queryData.countryCode.join(',') || null,
    });

    if (queryData.customerType) {
      delete params.customerType;

      params[queryData.customerType] = true;
    }

    await dispatch(getCustomersRequest(params));
  };

  const saveChangedTableAttribute = async () => {
    try {
      setLoadingSave(true);
      await Api.updateSettings({ customersTableAttributes: columns });

      dispatch(customersTableEditableEvent(false));
      setLoadingSave(false);
    } catch (e) {
      toast.error('Something wrong place try again !');
    }
  };

  useClickAway(selectRef, () => {
    setOpenSelect(false);
  });

  return (
    <Wrapper hideBackBtn className="customers_wrapper crm">
      <TitleBar
        backButton={false}
        actions={titleFilter}
        isActionActive={(d) => d.path === customerType}
        onActionClick={(d) => setQuery((prev) => ({ ...prev, customerType: d.path, page: 1 }))}
      >
        <Button
          title="New contact"
          addBtn
          data-scope="WRITE_CUSTOMERS"
          href="/orders/sales-order/create"
        />
      </TitleBar>

      {!_.isEmpty(customers) || Object.values(queryData).length ? (
        <>
          <div className="customersList">
            <Filters
              queryData={queryData}
              setQuery={setQuery}
              onEditableTableClick={() => dispatch(customersTableEditableEvent(true))}
              tableEditable={tableEditable}
              defaultFilterData={defaultFilterData}
              onImportClick={() => setOpenImportModal(true)}
              onExportClick={() => setOpenExportModal(true)}
              filterOptions={filterOptions}
              loadOptions={loadOptions}
            />

            <CustomersTable
              setOpenExportModal={setOpenExportModal}
              openImportModal={openImportModal}
              openExportModal={openExportModal}
              setOpenImportModal={setOpenImportModal}
              loadingData={loadingData}
              onGetCustomers={handleGetCustomers}
              firstLoadingRef={firstLoadingRef}
            />
          </div>

          {tableEditable
            && (
              <div className="customers_wrapper_table_changed_save">
                <Button
                  className="table_changed_save_button"
                  size="small"
                  title="Cancel"
                  onClick={() => dispatch(customersTableEditableEvent(false))}
                  roundBorder
                  btnType="cancel"
                />

                <Button
                  className="table_changed_save_button"
                  size="small"
                  title="Save changes"
                  onClick={saveChangedTableAttribute}
                  roundBorder
                  color="#1472FF"
                  loading={loadingSave}
                />
              </div>
            )}
        </>
      ) : null}
      {!loadingData && _.isEmpty(customers) && !Object.values(queryData).length ? (
        <div className="customers_not_found">
          <CustomersNotFoundIcon />

          <p>Customers not found</p>

          <div>
            <Button
              title="Create customer"
              href="/crm/customers/create"
              addBtn
              reverseColor
              size="small"
              roundBorder
            />
          </div>
        </div>
      ) : null}
    </Wrapper>
  );
}

export default Customers;
