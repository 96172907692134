import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { ReactComponent as SyncIcon } from '../../assets/icons/sync.svg';
import { ReactComponent as InProgressIcon } from '../../assets/icons/import_in_progress.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/icons/notFound/import_not_found.svg';
import Loader from '../_common/Loader/Loader';
import Utils from '../../helpers/Utils';

const SyncNotifications = () => {
  const apps = useSelector((state) => state.notifications.apps);
  const status = useSelector((state) => state.notifications.status);

  const ref = useRef(null);
  const [isOpen, openingToggle] = useState(false);

  useClickAway(ref, () => {
    openingToggle(false);
  });

  const activeImportCount = apps.filter((app) => app.importStatus === 'importing').length;
  const hasError = apps.some((app) => app.importStatus === 'error');

  return (
    <div ref={ref} className="auth_layout_content_header_right_block_sync">
      <div
        onClick={() => openingToggle((prev) => !prev)}
        className="auth_layout_content_header_right_block_sync_icon"
      >
        <SyncIcon />

        {activeImportCount
          ? <div><InProgressIcon /></div>

          : hasError
            ? <div className="attention">!</div>

            : null}
      </div>

      {isOpen && (
        <div className="auth_layout_content_header_right_block_sync_notifications">
          {status !== 'ok'
            ? <Loader />

            : (
              <>
                <div className="auth_layout_content_header_right_block_sync_notifications_title">
                  <h3>
                    {`${activeImportCount || 'No'} active importing`}
                  </h3>
                </div>

                <div className="auth_layout_content_header_right_block_sync_notifications_list">
                  {apps.length
                    ? apps.map((app, index) => {
                      const Icon = Utils.getAppIcon(app);
                      const isActive = app.importStatus === 'importing';
                      const isDone = !app.importStatus || app.importStatus === 'done';
                      const isError = app.importStatus === 'error';

                      return (
                        <div
                          key={index}
                          className={classNames(
                            'auth_layout_content_header_right_block_sync_notifications_list_notification',
                            {
                              sync: isActive,
                              error: isError,
                            },
                          )}
                        >
                          <div
                            className="auth_layout_content_header_right_block_sync_notifications_list_notification_store_icon"
                          >
                            <Icon />
                          </div>

                          <div
                            className="auth_layout_content_header_right_block_sync_notifications_list_notification_text"
                          >
                            <h3>{app.name}</h3>

                            <p>
                              {isDone
                                ? (app.lastUpdateDate
                                  && `Last updated ${moment(app.lastUpdateDate)
                                    .format('YYYY.MM.DD hh:mm A')}`)

                                : (
                                  <>
                                    <b>Status:</b>
                                    {' '}
                                    {app.message}
                                  </>
                                )}
                            </p>
                          </div>

                          {!isDone && (
                            <div className="auth_layout_content_header_right_block_sync_notifications_icon_wrapper">
                              <SyncIcon />

                              {isError && <div>!</div>}
                            </div>
                          )}
                        </div>
                      );
                    })

                    : (
                      <div className="apps_no_result">
                        <NotFoundIcon />
                      </div>
                    )}
                </div>
              </>
            )}
        </div>
      )}
    </div>
  );
};

SyncNotifications.propTypes = {};

export default SyncNotifications;
