import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SingleBatchesLeft from './components/SingleBatchesLeft';
import FilterButton from '../../../_common/Form/FilterButton';
import Button from '../../../_common/Form/Button';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel.svg';
import useWindowSize from '../../../../helpers/hooks/useWindowSize';
import noImage from '../../../../assets/icons/default.svg';
import Tabs from '../../../_common/Tabs/Tabs';
import Loader from '../../../_common/Loader/Loader';
import SingleBatchesTable from './components/SingleBatchesTable';
import { getBatchesList, singleBatchData } from '../../../../store/actions/batches';
import { getOrdersRequest } from '../../../../store/actions/orders';
import { getStockTransferListRequest } from '../../../../store/actions/stockTransfer';
import { getAllPackagesRequest } from '../../../../store/actions/packages';
import { orderReturnsListRequest } from '../../../../store/actions/orderReturns';
import EditBatchModal from './components/EditBatchModal';

const SingleBatches = () => {
  const { batchesId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize(850);

  const firstLoading = useRef(true);

  const [activeTab, setActiveTab] = useState('Sale orders');
  const singleBatch = useSelector((state) => state.batches.singleBatch);

  const [openEditModal, setOpenEditModal] = useState({});
  const [tabLoading, setTabLoading] = useState(false);
  const [tabFirstLoading, setTabFirstLoading] = useState(false);
  const [tabRequestPath, setTabRequestPath] = useState('sale');

  const [tabPage, setTabPage] = useState(1);

  const tabRequest = useMemo(() => ({
    sale: () => dispatch(getOrdersRequest({
      page: tabPage,
      type: 'sale',
      productId: singleBatch?.product?.id,
    })),
    purchase: () => dispatch(getOrdersRequest({
      page: tabPage,
      type: 'purchase',
      productId: singleBatch?.product?.id,
    })),
    stock: () => dispatch(getStockTransferListRequest({
      page: tabPage,
      productId: singleBatch?.product?.id,
    })),
    shipments: () => dispatch(getAllPackagesRequest({
      page: tabPage,
      type: 'send',
      includes: 'order',
      productId: singleBatch?.product?.id,
    })),
    returns: () => dispatch(orderReturnsListRequest({
      page: tabPage,
      includes: 'order',
      productId: singleBatch?.product?.id,
    })),
  }), [tabPage, singleBatch?.product?.id]);

  const changeTabPage = (page, type) => {
    setTabLoading(true);
    setTabRequestPath(type);
    setTabPage(page);
  };

  const generalTabs = useMemo(() => ([
    {
      name: 'Sale orders',
      component: <SingleBatchesTable
        type="sale"
        page={tabPage}
        onChangePage={(page, type) => changeTabPage(page, type)}
        loading={tabLoading}
        firstLoading={tabFirstLoading}
      />,
    },
    {
      name: 'Purchase orders',
      component: <SingleBatchesTable
        page={tabPage}
        onChangePage={(page, type) => changeTabPage(page, type)}
        loading={tabLoading}
        firstLoading={tabFirstLoading}
        type="purchase"
      />,
    },
    {
      name: 'Stock transfers',
      component: <SingleBatchesTable
        page={tabPage}
        onChangePage={(page, type) => changeTabPage(page, type)}
        loading={tabLoading}
        firstLoading={tabFirstLoading}
        type="stock"
      />,
    },
    {
      name: 'Shipments',
      component: <SingleBatchesTable
        page={tabPage}
        onChangePage={(page, type) => changeTabPage(page, type)}
        loading={tabLoading}
        firstLoading={tabFirstLoading}
        type="shipments"
      />,
    },
    {
      name: 'Returns',
      component: <SingleBatchesTable
        page={tabPage}
        onChangePage={(page, type) => changeTabPage(page, type)}
        loading={tabLoading}
        firstLoading={tabFirstLoading}
        type="returns"
      />,
    },

  ]), [tabPage, tabLoading, tabFirstLoading]);

  useEffect(() => {
    (async () => {
      try {
        const { payload } = await dispatch(getBatchesList(
          {
            page: 1,
            ids: `${batchesId}`,
          },
        ));

        await dispatch(getOrdersRequest({
          page: 1,
          type: 'sale',
          productId: payload.batches[0].product.id,
        }));

        dispatch(singleBatchData(payload.batches[0]));
      } catch (e) {
        console.error(e);
      }

      firstLoading.current = false;
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!firstLoading.current) {
        setTabLoading(true);
        await tabRequest[tabRequestPath]();

        setTabLoading(false);
        setTabFirstLoading(false);
      }
    })();
  }, [tabRequestPath, tabPage, batchesId]);

  const changeTabs = (tabName) => {
    setActiveTab(tabName);
    setTabRequestPath(_.lowerCase(tabName).split(' ')[0]);
    setTabFirstLoading(true);
    setTabPage(1);
  };

  return (
    firstLoading.current ? <Loader />
      : (
        <div className="single_batches_wrapper">
          <SingleBatchesLeft changeTabs={changeTabs} />

          <div className="single_batches_right_wrapper">
            <div className="single_batches_right_top">
              <div className="single_batches_right_info">
                <p>{singleBatch.batchNumber}</p>

                <div>
                  <p>{`Created at: ${moment(singleBatch.createdAt).format('L')}`}</p>

                  <p>{`Expiration date: ${moment(singleBatch.expirationDateTo).format('L')}`}</p>

                  <p>{`Last updated: ${moment(singleBatch.updatedAt).format('L')}`}</p>
                </div>
              </div>

              <div className="single_batches_right_buttons">
                <FilterButton
                  onClick={() => setOpenEditModal(singleBatch)}
                  style={{ marginRight: 10 }}
                >
                  <EditIcon />

                  {!isMobile && 'Edit'}
                </FilterButton>

                <Button
                  roundBorder
                  onClick={() => navigate(`/inventory/batches/edit/${singleBatch.product.id}`)}
                  title="Assign initial stock to batch"
                  className="receive_stock_transfer_left_header_btn"
                />

                <div
                  className="receive_stock_transfer_close"
                  role="button"
                  tabIndex="0"
                  onClick={() => navigate('/inventory/batches')}
                >
                  <CancelIcon />
                </div>
              </div>

            </div>

            <div className="single_batches_right_bottom">
              <div className="single_batches_right_item_wrapper">
                <div className="single_batches_right_item_img">
                  <img
                    src={singleBatch.product.images[0]?.src || singleBatch.product.images[0]?.small || noImage}
                    alt="img"
                  />
                </div>

                <div className="single_batches_right_item_info">
                  <div className="single_batches_right_item_info_title">
                    <h3>{singleBatch.product.title}</h3>

                    <div>
                      <p>SKU:</p>
                      <p>{singleBatch.product.sku || '-'}</p>
                    </div>
                  </div>

                  <div className="single_batches_right_stock_count_wrapper">
                    <div>
                      <p>Stock on hand:</p>
                      <p>{singleBatch.product.totalStock.stock ?? '-'}</p>
                    </div>

                    <div>
                      <p>Committed Stock:</p>
                      <p>{singleBatch.product.totalStock.allocated ?? '-'}</p>
                    </div>

                    <div>
                      <p>Available Stock:</p>
                      <p>{singleBatch.product.totalStock.available ?? '-'}</p>
                    </div>
                  </div>
                </div>
              </div>

              <Tabs
                tabs={generalTabs}
                currentTab={activeTab}
                onChangeTab={changeTabs}
              />
            </div>

          </div>

          <EditBatchModal
            setSingleBatch={setOpenEditModal}
            singleBatch={openEditModal}
          />
        </div>
      )
  );
};

export default SingleBatches;
