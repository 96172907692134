import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../_common/Form/Button';
import Account from '../../../../helpers/Account';

const StepWrapper = ({
  title, subtitle, description, nextStepBtnTitle = 'Next', onNextBtnClick, children, loading,
}) => {
  const token = localStorage.getItem('onboardingToken');

  const navigate = useNavigate();

  const skip = () => {
    Account.setToken(token);

    navigate('/setup', { replace: true });

    localStorage.removeItem('onboardingToken');
  };

  useEffect(() => () => {
    if (token) skip();
  }, []);

  return (
    <div className="onboarding_step_wrapper">
      <div className="onboarding_step_wrapper_titles">
        <h1>{title}</h1>

        <h2>{subtitle}</h2>

        {description && <p>{description}</p>}
      </div>

      <div className="onboarding_step_wrapper_content">
        {children}
      </div>

      <div className="onboarding_step_wrapper_btns">
        <Button
          size="small"
          roundBorder
          onClick={skip}
          title="Skip"
          btnType="transparent"
          color="#1472FF"
        />

        <Button
          className="next_btn"
          size="small"
          title={nextStepBtnTitle}
          onClick={onNextBtnClick}
          roundBorder
          loading={loading}
        />
      </div>
    </div>
  );
};

export default StepWrapper;
