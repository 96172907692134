import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Account from '../../helpers/Account';

const PrivateRoute = ({ showToAdmin = false, children }) => {
  const userIsAdmin = Account.get()?.isAdmin;

  if (userIsAdmin !== showToAdmin) {
    return <Navigate to="/404" replace />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
