import React, { memo, useMemo } from 'react';
import _ from 'lodash';
import Modal from '../../_common/Modal/Modal';
import BulkReorderRules from './BulkActionsModal/BulkReorderRules';
import BulkEdit from './BulkActionsModal/BulkEdit';
import BulkProductLabels from './BulkActionsModal/BulkProductLabels';
import BulkEditPricing from './BulkActionsModal/BulkEditPricing';

const BulkModal = memo(({
  bulkProductsModal, setBulkProductsModal, setWarningsModal, priceListCols,
}) => {
  const bulkModals = useMemo(() => ({
    bulkEdit: <BulkEdit
      productItems={bulkProductsModal.productLists}
      priceListCols={priceListCols}
      setBulkEdit={setBulkProductsModal}
    />,
    editPricing: <BulkEditPricing
      productItems={bulkProductsModal.productLists}
      setBulkEditPricing={setBulkProductsModal}
    />,
    printLabels: <BulkProductLabels
      productItems={bulkProductsModal.productLists}
      onClose={() => setBulkProductsModal({})}
    />,
    reorderRules: <BulkReorderRules
      productItems={bulkProductsModal.productLists}
      setBulkReorderRulesModal={setBulkProductsModal}
      setWarningsModal={setWarningsModal}
    />,
  }), [bulkProductsModal, setBulkProductsModal, setWarningsModal, priceListCols]);

  return (
    <Modal
      className="products_bulk_modal"
      isOpen={!_.isEmpty(bulkProductsModal)}
      onClose={() => setBulkProductsModal({})}
    >
      {bulkModals[bulkProductsModal.path]}
    </Modal>
  );
});

export default BulkModal;
