import React, {
  useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Steps from '../../../_common/Steps/Steps';
import Wrapper from '../../../Layout/Wrapper';
import StepTwo from './StepTwo';
import ImportConfiguration from './ImportConfiguration';
import SuccessfullyIntegrated from './SuccessfullyIntegrated';
import StepOne from './StepOne';
import Api from '../../../../Api';
import StepThree from './StepThree';
import Loader from '../../../_common/Loader/Loader';
import FTPConfiguration from './FTPConfiguration';
import General from './General';
import { getAppsRequest } from '../../../../store/actions/notifications';
import AccountingPaymentMethods from './Accounting/AccountingPaymentMethods';
import AccountingTaxRate from './Accounting/AccountingTaxRate';
import AccountingLedger from './Accounting/AccountingLedger';
import { fileMappingOption } from '../../../../helpers/staticData';
import MailchimpConfiguration from '../../MyApps/Tabs/Mailchimp/MailchimpConfiguration';
import B2BGeneral from '../../MyApps/Tabs/B2B/B2BGeneral';
import B2BOrders from '../../MyApps/Tabs/B2B/B2BOrders';
import B2BOther from '../../MyApps/Tabs/B2B/B2BOther';
import { getMyApp } from '../../../../store/actions/app';

const formattingConfigs = (data = {}) => {
  const configs = {};

  if (data.type === 'ftp') {
    _.forEach(data.configs, (value, key) => {
      if (key) configs[value] = key;
    });

    return configs;
  }

  return data.configs;
};

const IntegrationStepsWrapper = ({ fromMyApp, openExpiredModal }) => {
  const navigate = useNavigate();
  const { integrationId } = useParams();

  const dispatch = useDispatch();
  const myApp = useSelector((state) => state.app.myApp);

  const companyId = useRef();
  const shopWarehouseList = useRef([]);
  const shopPriceList = useRef([]);
  const orderList = useRef([]);

  const accountingList = useRef([]);
  const accountingTaxRateList = useRef([]);
  const accountingPaymentMethodsList = useRef([]);

  const [orderStatusList, setOrderStatusList] = useState([]);

  const [currentStep, setCurrentStep] = useState(1);

  const [integrationData, setIntegrationData] = useState({});
  const integrationTypeName = _.startCase(integrationData.type === 'cartrover'
    ? `${integrationData.type} ${integrationData.settings?.name}`
    : integrationData.type);
  console.log(integrationData);
  const [errors, setErrors] = useState({});

  const [importConfig, setImportConfig] = useState(!fromMyApp);

  const [loading, loadingToggle] = useState(true);
  const [saveLoading, saveLoadingToggle] = useState(false);
  const [isSyncedIntegration, syncedIntegrationToggle] = useState(false);

  useLayoutEffect(() => {
    (async () => {
      try {
        const { data: { integration } } = await Api.getIntegration(integrationId);

        if (integration.type === 'mailchimp') { //  category === "other"
          const { data } = await Api.getOtherIntegrationAudiences(integration.type, integrationId);

          setIntegrationData({ ...integration, ...data });
        } else if (integration.category === 'accounting') {
          const appName = integration.type;
          const appId = integration.id;

          const [
            { data: { accounts } },
            { data: { taxRates } },
            { data: { paymentMethods } }] = await Promise.all([
            Api.getAccounting(appName, appId),
            Api.getAccountingTaxRates(appName, appId),
            Api.getAccountingPaymentMethods(appName, appId),
          ]);

          setIntegrationData({
            ...integration,
            addItemToAccounting: integration.accountingSettings.addItemToAccounting,
          });

          accountingList.current = accounts;
          accountingTaxRateList.current = taxRates;
          accountingPaymentMethodsList.current = paymentMethods;
        } else {
          let requests = [
            Api.getIntegrationStatuses(integrationId),
          ];

          if (integration.type !== 'shipstation') {
            requests = [
              ...requests,
              Api.getCompanies(),
              Api.getIntegrationWarehouses(integrationId),
            ];

            if (integration.type !== 'cartrover') {
              requests = [
                ...requests,
                Api.getIntegrationPriceList(integrationId),
              ];
            }
          }

          if (fromMyApp && integration.category === 'shop') {
            const { data: { integrations } } = await Api.getIntegrations({ category: 'shop' });

            syncedIntegrationToggle(integrations.filter((int) => int.id !== integration.id)
              .some((int) => int.configs.syncProductsStocks === '1'));
          }

          const [
            { data: { orderStatusMapping, orders } },
            companies,
            warehouses,
            priceLists,
          ] = await Promise.all(requests);

          const defaultOrderStatusMapping = { ...orderStatusMapping, ...integration.orderStatusMapping };

          setOrderStatusList(_.map(defaultOrderStatusMapping, (value, name) => {
            const datum = { name, value };

            if (!Object.hasOwn(orderStatusMapping, name)) {
              defaultOrderStatusMapping[name] = datum;

              return { [name]: datum };
            }

            return datum;
          }));

          setIntegrationData({
            ...integration,
            configs: formattingConfigs(integration),
            orderStatusMapping: { ...defaultOrderStatusMapping },
          });

          if (warehouses) shopWarehouseList.current = warehouses.data.warehouses;
          if (priceLists) shopPriceList.current = priceLists.data.priceLists;
          if (companies) companyId.current = companies.data.companies[0].id;

          if (orders) orderList.current = orders;
        }
      } catch (err) {
        navigate('/404', { replace: true });
      }

      loadingToggle(false);
    })();
  }, []);

  const onNextStepClick = async () => {
    if (fromMyApp && myApp.isExpired) {
      openExpiredModal();
    } else if (!fromMyApp && currentStep !== steps.length) {
      setCurrentStep((prev) => prev + 1);
    } else {
      let hasError = false;

      if (fromMyApp || currentStep === _.last(steps)?.step) {
        if (integrationData.type === 'ftp') {
          const { requiredFields, errorMessage } = fileMappingOption[`${integrationData.settings.type}s`];

          requiredFields.forEach((f) => {
            if (!Object.values(integrationData.configs).includes(f)) {
              hasError = true;
            }
          });

          if (hasError) {
            toast.error(errorMessage);
            const ftpSettingStep = steps.find((s) => s.stepType === 'ftpConfig')?.step;

            setCurrentStep(ftpSettingStep);
          }
        } else if (integrationData.type === 'b2b') {
          const appName = integrationData.name.trim();
          const storeName = integrationData.settings.storeName.trim();

          if (!appName || !storeName) {
            setErrors((prev) => ({ ...prev, name: !appName, storeName: !storeName }));
            hasError = true;
          }

          if (hasError) {
            setCurrentStep(1);

            toast.error('Please fill these fields');
          }
        } else {
          orderStatusList.filter((s) => !s.name).forEach((s) => {
            const key = Object.keys(s)[0];

            const name = integrationData.orderStatusMapping?.[key]?.name?.trim();
            const value = integrationData.orderStatusMapping?.[key]?.value?.trim();

            if (!name || !value) {
              setErrors((prev) => ({ ...prev, [key]: { name: !name, value: !value } }));
              hasError = true;
            }
          });

          if (hasError) {
            const orderStatusesStep = steps.find((s) => s.stepType === 'orderStatuses')?.step;
            setCurrentStep(orderStatusesStep);

            toast.error('Please fill these fields');
          }
        }
      }

      if (!hasError) {
        saveLoadingToggle(true);

        try {
          if (integrationData.category === 'accounting') {
            await Api.updateAccounting(integrationId, {
              ...integrationData,
              accountingSettings: {
                ...integrationData.accountingSettings,
                addItemToAccounting: integrationData.addItemToAccounting,
              },
            });
          } else {
            const sendingData = _.cloneDeep(integrationData);

            _.forEach(sendingData.orderStatusMapping, (data, key) => {
              if (typeof data === 'object') {
                delete sendingData.orderStatusMapping[key];
                sendingData.orderStatusMapping[data.name] = data.value;
              }
            });

            if (integrationData.type === 'ftp') {
              sendingData.configs = formattingConfigs(integrationData);
            }

            await Api.updateIntegration(sendingData);

            if (importConfig && integrationData.type !== 'shipstation') {
              await Api.integrationImport({ shop: integrationData.type, type: 'all', integrationId });
            }
          }

          if (!fromMyApp) {
            if (integrationData.category !== 'accounting') {
              await dispatch(getAppsRequest());
            }

            navigate('/my-apps', { replace: true });
          } else if (integrationData.type === 'b2b') {
            await dispatch(getMyApp(integrationId));
          }

          toast.success(fromMyApp ? 'Settings successfully saved' : 'App has been successfully installed');
        } catch (err) {
          console.warn(err);
        }

        saveLoadingToggle(false);
      }
    }
  };

  const selectStep = (step) => {
    window.scrollTo(0, 0);

    setCurrentStep(step);
  };

  const steps = useMemo(() => {
    let defaultSteps = integrationData.category === 'accounting'
      ? [{
        step: 1,
        title: fromMyApp ? 'Ledgers' : 'Step 1',
        mainTitle: `Sync ledgers for ${integrationTypeName}`,
        component: <AccountingLedger
          fromMyApp={fromMyApp}
          data={integrationData.accountingSettings.account}
          changeData={setIntegrationData}
          integrationName={integrationData.name}
          ledgers={accountingList.current}
        />,
      },
        {
          step: 2,
          title: fromMyApp ? 'Tax Rates' : 'Step 2',
          mainTitle: `Sync tax rates for ${integrationTypeName}`,
          component: <AccountingTaxRate
            fromMyApp={fromMyApp}
            data={integrationData.accountingSettings.tax}
            changeData={setIntegrationData}
            integrationName={integrationData.name}
            taxes={accountingTaxRateList.current}
          />,
        },
        {
          step: 3,
          title: fromMyApp ? 'Payment Methods' : 'Step 3',
          mainTitle: `Sync payment methods for ${integrationTypeName}`,
          component: <AccountingPaymentMethods
            fromMyApp={fromMyApp}
            data={integrationData.accountingSettings.payment}
            changeData={setIntegrationData}
            integrationName={integrationData.name}
            paymentMethods={accountingPaymentMethodsList.current}
          />,
        },
        {
          step: 4,
          title: 'Step 4',
          mainTitle: `${integrationTypeName} successfully integrated`,
          component: <SuccessfullyIntegrated />,
        }]

      : [{
        step: 1,
        title: fromMyApp ? 'Locations' : 'Step 1',
        mainTitle: `Configure ${integrationTypeName} stock locations`,
        component: <StepOne
          warehouseData={integrationData.warehouseMapping}
          integrationName={integrationData.name}
          changeData={setIntegrationData}
          shopWarehouseList={shopWarehouseList.current}
          fromMyApp={fromMyApp}
          stockMappingData={integrationData.sendStockMapping}
          integrationType={integrationData.settings?.carrier || integrationData.type}
        />,
      },
        {
          step: 2,
          title: fromMyApp ? 'Price lists' : 'Step 2',
          mainTitle: `Sync price lists for ${integrationTypeName}`,
          component: <StepTwo
            companyId={companyId.current}
            data={integrationData.priceListsMapping}
            integrationName={integrationData.name}
            changeData={setIntegrationData}
            fromMyApp={fromMyApp}
            shopPriceList={shopPriceList.current}
            currencyCode={integrationData.currencyCode}
          />,
        },
        {
          step: 3,
          title: fromMyApp ? 'Order statuses' : 'Step 3',
          mainTitle: `Configure ${integrationTypeName} order statuses`,
          stepType: 'orderStatuses',
          component: <StepThree
            data={integrationData.orderStatusMapping}
            changeData={setIntegrationData}
            orderStatusList={orderStatusList}
            setOrderStatusList={setOrderStatusList}
            fromMyApp={fromMyApp}
            errors={errors}
            setStatusErrors={setErrors}
          />,
        },
        {
          step: 4,
          title: 'Step 4',
          mainTitle: `Configure ${integrationTypeName} stock locations`,
          component: <ImportConfiguration
            importConfig={importConfig}
            changeImportConfig={setImportConfig}
          />,
        },
        {
          step: 5,
          title: 'Step 5',
          mainTitle: `${integrationTypeName} successfully integrated`,
          component: <SuccessfullyIntegrated />,
        },
      ];

    if (integrationData.type === 'ftp') {
      defaultSteps = [
        {
          step: 1,
          title: fromMyApp ? `${_.upperFirst(integrationData.settings.type)} config` : 'Step 1',
          mainTitle: `Configure ${integrationTypeName} ${integrationData.settings.type}`,
          stepType: 'ftpConfig',
          component: <FTPConfiguration
            type={`${integrationData.settings.type}s`}
            data={integrationData.configs}
            changeData={setIntegrationData}
            orderList={orderList.current}
          />,
        },
        ...defaultSteps.slice(2, 3).map((s, index) => ({
          ...s,
          step: index + 2,
          title: fromMyApp ? s.title : `Step ${index + 2}`,
        })),
        ...defaultSteps.slice(fromMyApp ? 3 : 4).map((s, index) => ({
          ...s,
          step: index + 3,
          title: fromMyApp ? s.title : `Step ${index + 3}`,
        })),
      ];
    } else if (integrationData.type === 'wayfair') {
      defaultSteps = [
        ...defaultSteps.slice(0, 2),
        ...defaultSteps.slice(3).map((s, index) => ({ ...s, step: index + 3, title: `Step ${index + 3}` })),
      ];
    } else if (integrationData.type === 'shipstation') {
      defaultSteps = [
        ...defaultSteps.slice(2, 3).map((s, index) => ({
          ...s,
          step: index + 1,
          title: fromMyApp ? 'Order statuses' : `Step ${index + 1}`,
        })),
        ...defaultSteps.slice(4).map((s, index) => ({ ...s, step: index + 2, title: `Step ${index + 2}` })),
      ];
    } else if (integrationData.type === 'cartrover') {
      defaultSteps = [
        ...defaultSteps.slice(0, 1),
        ...defaultSteps.slice(2, 3).map((s, index) => ({
          ...s,
          step: index + 2,
          title: fromMyApp ? 'Order statuses' : `Step ${index + 2}`,
        })),
        ...defaultSteps.slice(4).map((s, index) => ({ ...s, step: index + 3, title: `Step ${index + 3}` })),
      ];
    }

    if (fromMyApp) {
      const filterCount = integrationData.category === 'accounting' ? 1 : 2;
      const generalSettingsPath = integrationData.category === 'accounting' ? 'accounting' : 'general';

      defaultSteps = integrationData.type === 'cartrover'
        ? defaultSteps.slice(0, 2)

        : integrationData.type === 'shipstation'
          ? defaultSteps.slice(0, 1)

          : integrationData.type === 'b2b'
            ? [{
              step: 1,
              title: 'General',
              stepType: 'B2BGeneral',
              component: <B2BGeneral
                data={integrationData}
                changeData={setIntegrationData}
                errors={errors}
                setErrors={setErrors}
              />,
            },
              {
                step: 2,
                title: 'Orders',
                component: <B2BOrders
                  data={integrationData.settings}
                  changeData={setIntegrationData}
                />,
              },
              {
                step: 3,
                title: 'Other',
                component: <B2BOther
                  data={integrationData.settings}
                  changeData={setIntegrationData}
                />,
              }]

            : [{
              step: 1,
              title: 'General',
              component: <General
                data={integrationData}
                changeData={setIntegrationData}
                settingsPath={generalSettingsPath}
                isSyncedIntegration={isSyncedIntegration}
              />,
            },
              // {
              //   step: 2,
              //   title: 'Email settings',
              //   component: <General
              //     data={integrationData}
              //     changeData={setIntegrationData}
              //     settingsPath="email"
              //   />,
              // },
              ...defaultSteps.slice(0, defaultSteps.length - filterCount)
                .map((s, index) => ({ ...s, step: index + 2 })),
            ];

      if (integrationData.type === 'ftp') {
        defaultSteps = defaultSteps.slice(1).map((s, index) => ({ ...s, step: index + 1 }));
      }

      // else if (integrationData.type === 'mailchimp') {
      //   defaultSteps = [{
      //     step: 1,
      //     title: 'Settings',
      //     mainTitle: `Sync audiences ${integrationTypeName}`,
      //     component: <MailchimpConfiguration
      //       data={integrationData}
      //       integrationName={integrationData.name}
      //     />,
      //   }];
      // }
    }

    return defaultSteps;
  }, [integrationData, shopWarehouseList.current, importConfig, orderStatusList, errors, isSyncedIntegration]);

  return (
    <Wrapper
      title={!fromMyApp ? (!loading ? steps.find(({ step }) => step === currentStep)?.mainTitle : '') : ''}
      onBtnClick={onNextStepClick}
      className={classNames('integration_steps_wrapper', {
        lastStep: !fromMyApp && currentStep === steps.length,
        my_app: fromMyApp,
        shipstation: integrationData.type === 'shipstation',
      })}
      buttonDisabled={loading}
      btnLoading={saveLoading}
      buttonTitle={fromMyApp ? 'Save' : currentStep === steps.length ? 'Done installation' : 'Continue'}
      cancelButtonHref={fromMyApp ? '' : '/'}
      hideBackBtn
    >
      {loading
        ? (
          <Loader />
        )

        : (
          <div className="integration_steps_content">
            <div className="steps_wrapper">
              {fromMyApp
                ? (
                  <div className="steps_wrapper_menu">
                    {steps.map((s) => (
                      <div
                        key={s.title}
                        className={classNames('step_wrapper', { active: s.step === currentStep })}
                        onClick={() => selectStep(s.step)}
                      >
                        {s.title}
                      </div>
                    ))}
                  </div>
                )

                : <Steps currentStep={currentStep} steps={steps} setStep={setCurrentStep} />}
            </div>

            <div className={classNames('steps_content', `step${currentStep}`)}>
              <span data-scope="WRITE_INTEGRATIONS">
                {steps.find(({ step }) => step === currentStep)?.component}
              </span>
            </div>
          </div>
        )}
    </Wrapper>
  );
};

export default IntegrationStepsWrapper;
