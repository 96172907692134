import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MacOSIcon } from '../../../../assets/icons/macOS.svg';
import { ReactComponent as WindowsIcon } from '../../../../assets/icons/windowsOS.svg';
import { ReactComponent as LinuxIcon } from '../../../../assets/icons/linuxOS.svg';
import { ReactComponent as DownloadLinkIcon } from '../../../../assets/icons/download_link.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/current_os_arrow.svg';

const osFields = [
  {
    icon: <MacOSIcon />,
    name: 'MacOS',
    downloadLinks: [{ title: 'Install', href: '#' }, { title: 'Install for M2 chips', href: '#' }],
  },
  {
    icon: <WindowsIcon />,
    name: 'Windows',
    downloadLinks: [{ title: 'Install x86', href: '#' }, { title: 'Install x64', href: '#' }],
  },
  {
    icon: <LinuxIcon />,
    name: 'Linux',
    downloadLinks: [{ title: 'Install', href: '#' }, { title: 'Install for M2 chips', href: '#' }],
  },
];

function RemotePrinting() {
  const [os, setOS] = useState('');

  useEffect(() => {
    const { userAgent } = window.navigator;

    if (userAgent.includes('Windows')) {
      setOS('Windows');
    } else if (userAgent.includes('Macintosh')) {
      setOS('MacOS');
    } else if (userAgent.includes('Linux')) {
      setOS('Linux');
    }
  }, []);

  return (
    <div className="remote_printing">
      <h2>Get connected to your devices</h2>

      <p>Install eSwap printer and share your computer devices from within eSwap </p>

      <div className="remote_printing_os_block">
        {osFields.map(({ icon, name, downloadLinks }) => (
          <div className="os_single_wrapper">
            <div className={`os_single ${os === name ? 'active' : ''}`} key={name}>
              <div className="os_single_top">
                {icon}
              </div>

              <div className="os_single_bottom">
                <p className="os_single_install_text">{`Install eSwap app connect for ${name}`}</p>

                <div className="install_links">
                  {downloadLinks.map(({
                    title,
                    href,
                  }) => (
                    <div className="install_link_single" key={href}>
                      <DownloadLinkIcon />

                      <Link to={href} className="install_link">{title}</Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {os === name && (
              <div className="current_os_block">
                <ArrowIcon />

                <span>Select install</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default RemotePrinting;
