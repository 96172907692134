import { createReducer } from '@reduxjs/toolkit';
import { orderReturnsListRequest, singleOrderReturnsRequest } from '../actions/orderReturns';

const initialState = {
  singleOrderReturns: [],
  returnedCount: {},
  orderReturnsList: [],
  totalPages: 0,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(singleOrderReturnsRequest.fulfilled, (state, action) => {
      const { orderReturns } = action.payload.data;
      state.singleOrderReturns = orderReturns;
      const returnedCount = {};
      const returns = orderReturns.map((o) => o.orderReturnProducts).flat(1);
      returns.forEach((p) => {
        if (returnedCount[p.productId]) {
          returnedCount[p.productId] += p.qty;
        } else {
          returnedCount[p.productId] = p.qty;
        }
      });
      console.log(returnedCount, returns)
      state.returnedCount = returnedCount;
    })
    .addCase(orderReturnsListRequest.pending, (state) => {
      state.orderReturnsList = [];
      state.totalPages = 1;
    })
    .addCase(orderReturnsListRequest.fulfilled, (state, action) => {
      const { orderReturns, totalPages } = action.payload.data;
      state.orderReturnsList = orderReturns;
      state.totalPages = totalPages;
    });
});

export default reducer;
