import React, { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Account from '../../helpers/Account';
import defaultAvatar from '../../assets/icons/avatar.svg';
import { ReactComponent as AccountIcon } from '../../assets/icons/account.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { ReactComponent as SwitchIcon } from '../../assets/icons/switch_account.svg';
import { getAccountRequest } from '../../store/actions/users';
import { resetChatBot } from '../../store/actions/chatBot';

const ProfileMenu = () => {
  const navigate = useNavigate();
  const userId = Account.getUserId();
  const { isAdmin = false } = Account.get();

  const dispatch = useDispatch();

  const profile = useSelector((store) => store.users.profile);
  const currentSubscription = useSelector((store) => store.users.currentSubscription);

  const ref = useRef(null);

  const [isOpen, openingToggle] = useState(false);

  useClickAway(ref, () => {
    openingToggle(false);
  });

  const onSwitchAccount = useCallback(async () => {
    openingToggle(false);

    Account.removeUserId();
    navigate('/dashboard');

    dispatch(resetChatBot());
    await dispatch(getAccountRequest());

    window.location.reload();
  }, []);

  return (
    <div ref={ref} className="auth_layout_content_header_right_block_profile">
      <div
        role="button"
        tabIndex="0"
        onClick={() => openingToggle((prev) => !prev)}
        className="auth_layout_content_header_right_block_profile_avatar"
      >
        <img src={profile?.image || defaultAvatar} alt="avatar" />
      </div>

      {isOpen && (
        <div className="auth_layout_content_header_right_block_profile_menu">
          <div className="auth_layout_content_header_right_block_profile_info">
            <div className="auth_layout_content_header_right_block_profile_plan">
              {isAdmin ? 'Super Admin' : _.upperFirst(currentSubscription?.subscription?.planName)}
            </div>

            <div className="auth_layout_content_header_right_block_profile_mail">
              {profile?.email}
            </div>
          </div>

          <div className="auth_layout_content_header_right_block_profile_links">
            <NavLink to="/profile" onClick={() => openingToggle(false)}>
              <AccountIcon />
              My account
            </NavLink>

            {userId && (
              <button type="button" onClick={onSwitchAccount}>
                <SwitchIcon />
                Switch to super admin
              </button>
            )}

            <button type="button" onClick={Account.logout}>
              <LogoutIcon />
              Log out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

ProfileMenu.propTypes = {};

export default ProfileMenu;
