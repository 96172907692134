import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Api from '../../../Api';
import Loader from '../../_common/Loader/Loader';
import Utils from '../../../helpers/Utils';
import Modal from '../../_common/Modal/Modal';
import Input from '../../_common/Form/Input';
import Button from '../../_common/Form/Button';

function ProfileImportSettings() {
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [intervalData, setIntervalData] = useState({
    importOrders: '',
    importProducts: '',
  });
  const [intervalErrors, setIntervalErrors] = useState({});
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await Api.getIntegrations({ userId });

      setIntegrations(data.integrations.map((integration) => {
        if (integration.type === 'ftp') integration.settings.type = `ftp ${integration.settings.type}`;

        return integration;
      }));

      setLoading(false);
    })();
  }, []);

  const onIntervalDataChange = useCallback((path, value) => {
    setIntervalData((prev) => ({
      ...prev,
      [path]: value,
    }));

    setIntervalErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, []);

  const onCloseModal = useCallback(() => {
    setIntervalData({
      importOrders: '',
      importProducts: '',
    });

    setIntervalErrors({});
    setSelectedShop(null);
  }, []);

  const onIntegrationClick = useCallback((shop) => {
    setSelectedShop(shop);
    setIntervalData({
      importOrders: shop.importOrdersInterval,
      importProducts: shop.importProductsInterval,
    });
  }, []);

  const onSaveImportSettings = useCallback(async () => {
    let hasError = false;

    _.forEach(intervalData, (val, key) => {
      if (!val.toString().trim()) {
        setIntervalErrors((prev) => ({ ...prev, [key]: 'Field is required' }));
        hasError = true;
      }

      if (val < 20) {
        setIntervalErrors((prev) => ({ ...prev, [key]: 'Field must be greater than or equal to 20' }));
        hasError = true;
      }
    });

    if (!hasError) {
      setSaveLoading(true);

      try {
        await Promise.all([
          Api.setIntegrationInterval({
            integrationId: selectedShop.id,
            type: 'importOrders',
            interval: intervalData.importOrders,
          }),
          Api.setIntegrationInterval({
            integrationId: selectedShop.id,
            type: 'importProducts',
            interval: intervalData.importProducts,
          }),
        ]);

        setIntegrations((prev) => prev.map((int) => (+selectedShop.id === +int.id ? {
          ...int,
          importOrdersInterval: intervalData.importOrders,
          importProductsInterval: intervalData.importProducts,
        } : int)));

        onCloseModal();
      } catch (e) {
        toast.error(e.response.data.message || 'Something went wrong');
      }

      setSaveLoading(false);
    }
  }, [selectedShop, intervalData]);

  return (
    <div className="profile_channels">
      {loading ? <Loader /> : (
        <div className="user_shops">
          {integrations.map((int) => (
            <div className="integration_single" onClick={() => onIntegrationClick(int)} key={int.id}>
              <div className="integration_single_logo">
                {Utils.getAppIcon(int, true)}
              </div>

              <div className="integration_single_right">
                <h4>{int.name}</h4>

                <div className="integration_single_right_info">
                  {`Import orders every: ${Utils.formattingMinutesToHours(int.importOrdersInterval)}`}
                </div>

                <div className="integration_single_right_info">
                  {`Import products every: ${Utils.formattingMinutesToHours(int.importProductsInterval)}`}
                </div>
              </div>
            </div>
          ))}

          <Modal isOpen={!!selectedShop} onClose={onCloseModal}>
            <div className="import_settings_modal">
              <h3>{`${selectedShop?.name} import settings`}</h3>

              <div className="import_settings_modal_single">
                <p>Import orders every:</p>

                <Input
                  value={intervalData.importOrders}
                  onChangeText={(val) => onIntervalDataChange('importOrders', val)}
                  error={intervalErrors.importOrders}
                  placeholder="Min 20 max 1440 minutes"
                  size="small"
                  roundBorder
                  onBeforeInput={(e) => Utils.typingNumber(e, true, null, 1440)}
                  wrapperClassName="import_settings_modal_single_input"
                />
              </div>

              <div className="import_settings_modal_single">
                <p>Import product every:</p>

                <Input
                  value={intervalData.importProducts}
                  onChangeText={(val) => onIntervalDataChange('importProducts', val)}
                  error={intervalErrors.importProducts}
                  placeholder="Min 20 max 1440 minutes"
                  size
                  roundBorder
                  onBeforeInput={(e) => Utils.typingNumber(e, true, null, 1440)}
                  wrapperClassName="import_settings_modal_single_input"
                />
              </div>

              <div className="import_settings_modal_buttons">
                <Button
                  className="import_settings_modal_button"
                  size="small"
                  title="Cancel"
                  onClick={() => setSelectedShop(null)}
                  roundBorder
                  btnType="cancel"
                />

                <Button
                  className="import_settings_modal_button"
                  size="small"
                  title="Save settings"
                  onClick={onSaveImportSettings}
                  roundBorder
                  color="#1472FF"
                  loading={saveLoading}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default ProfileImportSettings;
