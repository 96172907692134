import React, {
  useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ChatBotSmallIcon } from '../../../assets/icons/chat_bot_small.svg';
import { ReactComponent as ChatArrowIcon } from '../../../assets/icons/chat_arrow.svg';
import Topics from './Topics';
import {
  endThreadRequest,
  getAnswerRequest,
  getThreadRequest,
  getThreadsRequest,
  resetChatBot,
  resetNewMessage,
  updateThreadNameRequest,
} from '../../../store/actions/chatBot';
import Loader from '../Loader/Loader';
import ChatLoader from './ChatLoader';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/chat_bot_send.svg';
import { ReactComponent as ChatBotIcon } from '../../../assets/icons/chat_bot.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/arrow_sort.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close_red.svg';

const fieldStartHeight = 36;
const mainStartHeight = 420;

const ChatBot = () => {
  const dispatch = useDispatch();
  const {
    chat: {
      topic, messages, newMessage, messageLoading, chatLoading, threadId = null, hasMore,
    },
    threads: { threadsLists },
  } = useSelector((store) => store.chatBot);

  const mainRef = useRef(null);
  const mainPrevHeight = useRef(mainStartHeight);
  const fieldRef = useRef(null);
  const fieldCurrentHeight = useRef(fieldStartHeight);

  const [isOpen, openingToggle] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!threadsLists.length) {
      dispatch(getThreadsRequest());
    }
  }, []);

  useEffect(() => {
    if (isOpen && mainRef.current) {
      fieldRef.current.style.height = `${fieldCurrentHeight.current}px`;
      mainRef.current.style.height = `calc(100% - (40px + ${fieldCurrentHeight.current}px)`;

      const { scrollHeight } = mainRef.current;

      mainRef.current?.scrollTo(0, scrollHeight);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && mainRef.current) {
      fieldRef.current.style.height = `${fieldCurrentHeight.current}px`;
      mainRef.current.style.height = `calc(100% - (40px + ${fieldCurrentHeight.current}px)`;

      const { offsetHeight, scrollTop, scrollHeight } = mainRef.current;

      if (Math.floor(offsetHeight + scrollTop + (scrollHeight - mainPrevHeight.current) + 100) >= scrollHeight) {
        mainRef.current?.scrollTo(0, scrollHeight);
      }

      mainPrevHeight.current = scrollHeight;
    }
  }, [messages]);

  const reset = () => {
    dispatch(resetNewMessage());
    setValue('');
    fieldCurrentHeight.current = fieldStartHeight;
    mainPrevHeight.current = mainStartHeight;
  };

  const closeTopic = () => {
    dispatch(resetChatBot(true));
    reset();
  };

  const endTopic = () => {
    dispatch(endThreadRequest(threadId));
    reset();
  };

  const scroll = async (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;

    if (Math.floor(offsetHeight + scrollTop + 100) >= scrollHeight) {
      dispatch(resetNewMessage());
    }

    if (!chatLoading && hasMore && scrollTop <= 10) {
      await dispatch(getThreadRequest({ threadId, after: messages[0].id }));

      const scrollBottom = scrollHeight - scrollTop;

      const { scrollHeight: scrollHeightNew } = mainRef.current;
      mainRef.current.scrollTo(0, scrollHeightNew - scrollBottom - 34);
    }
  };

  const onEnter = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      send();
    }
  };

  const send = () => {
    if (value.trim() && !messageLoading) {
      const foundThread = threadsLists.find((t) => t.threadId === threadId);

      if (foundThread && !foundThread?.threadNameChanged) {
        dispatch(updateThreadNameRequest({ threadId, threadName: value.trim() }));
      }

      dispatch(getAnswerRequest({
        message: value.trim(),
        topic,
        threadId,
      }));

      setValue('');
      fieldRef.current.style.height = `${fieldStartHeight}px`;
      mainRef.current.style.height = 'calc(100% - 76px)';
      fieldCurrentHeight.current = fieldStartHeight;
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);

    fieldRef.current.style.height = `${fieldStartHeight}px`;

    const newHeight = Math.max(e.target.scrollHeight >= 94 ? 94 : e.target.scrollHeight + 2, fieldStartHeight);

    fieldRef.current.style.height = `${newHeight}px`;
    fieldCurrentHeight.current = newHeight;

    const { offsetHeight, scrollTop, scrollHeight } = mainRef.current;

    mainRef.current.style.height = `calc(100% - (40px + ${newHeight}px)`;

    if (Math.floor(offsetHeight + scrollTop + 1) >= scrollHeight) {
      mainRef.current?.scrollTo(0, scrollHeight);
    }
  };

  return createPortal(
    <div className="chat__assistant">
      <div className="chat__assistant__wrapper">
        {isOpen && (
          <div className="chat__assistant__body">
            <ChatArrowIcon className="chat__assistant__body__close_icon" onClick={() => openingToggle(false)} />

            {!(topic || threadId)
              ? <Topics />

              : (
                <>
                  <div className="chat__assistant__header">
                    <div className="chat__assistant__header__back__btn__wrapper">
                      <div
                        className="chat__assistant__header__back__btn"
                        role="button"
                        tabIndex="0"
                        onClick={closeTopic}
                      >
                        <BackIcon />
                      </div>

                      <ChatBotIcon className="chat__bot__icon" />
                    </div>

                    {!!messages.length && (
                      <div
                        className="chat__assistant__header__end__btn"
                        role="button"
                        tabIndex="0"
                        onClick={endTopic}
                      >
                        <CloseIcon />

                        End chat
                      </div>
                    )}
                  </div>

                  <div ref={mainRef} className="chat__assistant__main" onScroll={scroll}>
                    {!messages.length && chatLoading
                      ? <Loader color="white" />

                      : (
                        <>
                          {chatLoading
                            && <Loader className="chat__assistant__main__messages__loading" color="white" size={20} />}

                          {/* <div className="chat__assistant__main__subjects"> */}
                          {/*   <div className="chat__assistant__subjects__title">Help me</div> */}
                          {/*   <div className="chat__assistant__subjects__title">Popular question</div> */}
                          {/*   <div className="chat__assistant__subjects__title">Help me</div> */}
                          {/*   <div className="chat__assistant__subjects__title">Help me</div> */}
                          {/*   <div className="chat__assistant__subjects__title">Popular question</div> */}
                          {/*   <div className="chat__assistant__subjects__title">Help me</div> */}
                          {/* </div> */}

                          {!!messages.length && (
                            <div className="assistant__answer__block">
                              {messages.map(({ id, role, content }) => (
                                <div key={id} className={`${role}__answer`}>{content}</div>
                              ))}

                              {messageLoading && (
                                <div className="assistant__answer assistant_loader_wrapper">
                                  <ChatLoader />
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                  </div>

                  <div className="chat__assistant__footer">
                    {newMessage && (
                      <button
                        className="new_message_indicator"
                        onClick={() => mainRef.current.scrollTo({
                          top: mainRef.current.scrollHeight,
                          behavior: 'smooth',
                        })}
                      >
                        New message

                        <ArrowIcon />
                      </button>
                    )}

                    <div className="chat__assistant__form">
                      <textarea
                        ref={fieldRef}
                        value={value}
                        onChange={onChange}
                        onKeyDown={onEnter}
                        placeholder="Type here..."
                        className={classNames('chat__assistant__input', { has__value: value.trim() && !messageLoading })}
                      />

                      {/* <div */}
                      {/*   ref={fieldRef} */}
                      {/*   onInput={({ target }) => onChange(target)} */}
                      {/*   contentEditable */}
                      {/*   // suppressContentEditableWarning */}
                      {/*   className={classNames('chat__assistant__input', { has__value: hasValue })} */}
                      {/*   // onKeyDown={sendMessage} */}
                      {/* /> */}

                      <button
                        type="button"
                        onClick={send}
                        className="chat__assistant__submit"
                      >
                        <SendIcon />
                      </button>
                    </div>
                  </div>
                </>
              )}
          </div>
        )}

        <div
          className={classNames('chat__assistant__btn', { chat__assistant__btn_open: isOpen })}
          role="button"
          tabIndex="0"
          onClick={() => openingToggle((prev) => !prev)}
        >
          <ChatBotSmallIcon />
          <span>Ai assistant</span>
        </div>
      </div>
    </div>,
    document.getElementById('root'),
  );
};

export default ChatBot;
