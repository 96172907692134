import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import Input from '../../../_common/Form/Input';
import Table from '../../../_common/Tables/Table';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import Button from '../../../_common/Form/Button';
import { ReactComponent as ReasonsNotFoundIcon } from '../../../../assets/icons/no_match.svg';


const modalFields = [
  {
    title: 'Title',
    path: 'title',
    placeholder: 'Enter title',
    required: true,
  },
];

const sortingList = (list, search, { sortBy, sort }) => (
  _.orderBy(
    list.filter((l) => l.title.toLowerCase().includes(search.toLowerCase())),
    (c) => (typeof c[sortBy] === 'string' ? c[sortBy].toLowerCase() : c[sortBy]),
    sort,
  )
);

function AdjustmentReasons() {
  const [search, setSearch] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'id'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const { sort, sortBy } = sorting;

  const firstLoad = useRef(true);
  const timeout = useRef();
  const defaultReasonsLists = useRef([]);

  const [reasons, setReasons] = useState([]);
  const [loading, loadingToggle] = useState(false);
  const [deleteLoading, deleteLoadingToggle] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    (async () => {
      const { data } = await Api.getSettings(['stockReasons']);
      const stockReasons = data.stockReasons.reverse().map((r, index) => ({ id: index + 1, title: r }));

      defaultReasonsLists.current = stockReasons;
      setReasons(sortingList(stockReasons, search, sorting));
      firstLoad.current = false;
    })();
  }, []);

  useEffect(() => {
    if (!firstLoad.current) {
      loadingToggle(true);
      clearTimeout(timeout.current);

      timeout.current = setTimeout(async () => {
        setReasons(sortingList(defaultReasonsLists.current, search, sorting));

        loadingToggle(false);
      }, 500);
    }
  }, [sorting, search]);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, []);

  const updateReasons = async (newReasons) => {
    await Api.updateSettings({ stockReasons: newReasons.map((r) => r.title) });

    setReasons(sortingList(newReasons, search, sorting));
  };

  const editReason = useCallback((id) => {
    setModalData(() => defaultReasonsLists.current.find((l) => l.id === id));
  }, []);

  const deleteReason = useCallback(async (id) => {
    deleteLoadingToggle(true);
    defaultReasonsLists.current = defaultReasonsLists.current.filter((r) => r.id !== id);

    await updateReasons(defaultReasonsLists.current);

    toast.success('Reason successfully deleted');
    deleteLoadingToggle(false);
  }, []);

  const saveReason = useCallback(async (data) => {
    defaultReasonsLists.current = data.id
      ? defaultReasonsLists.current.map((r) => (r.id === data.id ? data : r))
      : [{ id: _.maxBy(defaultReasonsLists.current, 'id').id + 1, ...data }, ...defaultReasonsLists.current];

    await updateReasons(defaultReasonsLists.current);

    setModalData({});

    toast.success(`Reason successfully ${data.id ? 'updated' : 'added'}`);
  }, [search, sorting]);

  const tableHeader = useMemo(() => [
    {
      title: 'Number',
      path: 'index',
    },
    {
      title: 'Title',
      path: 'title',
      navigate: editReason,
      isSelectable: true,
    },
  ], []);

  return (
    <Wrapper
      title="Adjustment reason"
      onBtnClick={() => setModalData({ title: '' })}
      addBtn
      buttonTitle="New reason"
    >
      {firstLoad.current
        ? <Loader />

        : (
          <div className="shipping_types">
            <Input
              value={search}
              onChangeText={setSearch}
              search
              placeholder="Search title"
              roundBorder
              size="small"
            />

            <Table
              sortBy={sortBy}
              onSortBy={onSortBy}
              loading={loading}
              sort={sort}
              data={reasons}
              header={tableHeader}
              deleteModalText="reason"
              deleteLoading={deleteLoading}
              onEditClick={editReason}
              onDeleteClick={deleteReason}
              notFound={(
                <>
                  <ReasonsNotFoundIcon />

                  <h1>Reasons not found</h1>

                  <Button
                    title="Create reason"
                    onClick={() => setModalData({ title: '' })}
                    addBtn
                    reverseColor
                    size="small"
                    roundBorder
                  />
                </>
              )}
            />
          </div>
        )}

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalFields}
        onClose={() => setModalData({})}
        onSave={saveReason}
        singleData={modalData}
        text="adjustment reason"
      />
    </Wrapper>
  );
}

export default AdjustmentReasons;
