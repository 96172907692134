import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Link, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'currency-formatter';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment/moment';
import PreviewWrapper from '../../components/pages/Orders/preview/PreviewWrapper';
import Select from '../../components/_common/Form/Select';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as ValidateIcon } from '../../assets/icons/validate.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import Input from '../../components/_common/Form/Input';
import Datepicker from '../../components/_common/Form/Datepicker';
import noImg from '../../assets/icons/no_img.svg';
import Button from '../../components/_common/Form/Button';
import { verifyAddressRequest } from '../../store/actions/app';
import { getProductsRequest } from '../../store/actions/products';
import RatesModal from '../../components/pages/Orders/preview/RatesModal';
import Collapsible from '../../components/_common/Collapsible';
import ModalAddPayment from '../../components/pages/Settings/PlansBillings/ModalAddPayment';
import EditOrderAddress from '../../components/pages/Orders/preview/EditOrderAddress';
import NoCartAttachedModal from '../../components/pages/Orders/preview/NoCartAttachedModal';
import Utils from '../../helpers/Utils';
import StripeLayout from '../../components/Layout/StripeLayout';
import {
  buyShippingLabelRequest,
  getShippingOptionsRequest,
  getShippingRatesRequest,
  updateOrderPackageRequest,
} from '../../store/actions/packages';
import Checkbox from '../../components/_common/Form/Checkbox';

const weightUnits = [
  { label: 'oz', value: 'oz' },
  { label: 'lb', value: 'lb' },
  { label: 'g', value: 'g' },
  { label: 'kg', value: 'kg' },
];

function BuyShippingLabel() {
  const [validated, setValidated] = useState('notValidated');
  const [products, setProducts] = useState([]);
  const [ratesModal, setRatesModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [formPackage, setFormPackage] = useState({ dimensions: [], extra: {} });
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [nextStepModal, setNextStepModal] = useState(false);
  const [packageErrors, setPackageErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [warehouseAddressValidated, setWarehouseAddressValidated] = useState('notValid');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { packageId, orderId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const packages = useSelector((state) => state.packages.orderPackages);
  const order = useSelector((state) => state.orders.order);
  const shippingRates = useSelector((state) => state.packages.shippingRates);
  const orderPackage = useMemo(() => packages.find((p) => p.id === packageId) || {}, [packageId, packages]);
  const service = useMemo(() => shippingRates.find((r) => r.rateId === searchParams.get('service_id')) || {}, [shippingRates, searchParams]);
  const warehouse = useMemo(() => order.orderProducts?.[0]?.warehouse, [order]);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getProductsRequest(
        { ids: orderPackage.orderPackageProducts?.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [orderPackage]);

  useEffect(() => {
    if (_.isEmpty(orderPackage)) {
      return;
    }
    const d = {
      dimensions: orderPackage.dimensions,
      weightUnit: orderPackage.weightUnit,
      weight: orderPackage.weight,
      orderPackageProducts: orderPackage.orderPackageProducts,
      warehouseId: orderPackage.warehouseId,
      note: orderPackage.note,
      dimensionsUnit: orderPackage.dimensionsUnit,

    };
    setFormPackage((prevState) => ({ ...prevState, ...d }));
  }, [orderPackage]);

  useEffect(() => {
    (async () => {
      const { payload: { data } } = await dispatch(getShippingRatesRequest({ packageId, orderId }));
      if (data.status === 'error') {
        toast.error(data.message);
      }
    })();
  }, [packageId, orderId]);

  useEffect(() => {
    (async () => {
      if (!warehouse) {
        return;
      }
      const d = {
        city: warehouse?.city,
        state: warehouse?.region?.code,
        street: warehouse?.address,
        zip: warehouse?.postalCode,
        country: warehouse?.countryCode || '-',
      };

      const { payload } = await dispatch(verifyAddressRequest(d));
      if (payload?.data?.status !== 'error') {
        setWarehouseAddressValidated('valid');
      } else {
        setWarehouseAddressValidated('notValid');
      }
    })();
  }, [warehouse]);

  const handleChange = useCallback((key, value) => {
    _.set(formPackage, key, value);
    _.unset(packageErrors, key);
    setFormPackage(_.cloneDeep(formPackage));
    setPackageErrors({ ...packageErrors });
  }, [formPackage, packageErrors]);

  const handleUpdatePackage = useCallback(async (p, configure = false) => {
    const d = {
      id: packageId,
      warehouseId: formPackage.warehouseId,
      orderPackageProducts: formPackage.orderPackageProducts.map((o) => ({
        orderProductId: o.orderProductId,
        qty: o.qty,
      })),
      weight: p.weight,
      dimensions: p.dimensions,
      dimensionsUnit: p.dimensionsUnit,
      weightUnit: formPackage.weightUnit,
      note: p.note,
      carrier: p.carrier,
      service: p.service,
      extra: !_.isEmpty(p.extra) ? p.extra : undefined,
    };
    const { payload: { data } } = await dispatch(updateOrderPackageRequest({ id: packageId, ...d }));
    if (configure) {
      setFormPackage({ ...d, rateId: p.rateId });
      searchParams.set('service_id', p.rateId);
      setSearchParams(searchParams);
      const { payload } = await dispatch(getShippingOptionsRequest(p.carrier.toLowerCase()));
      setShippingOptions(payload.data);
    }
    return data;
  }, [formPackage, handleChange]);


  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);

    const data = await handleUpdatePackage(formPackage);
    if (data.status !== 'error') {
      const redirectUrl = `${window.location.origin}/3d`;
      const { payload } = await dispatch(buyShippingLabelRequest({
        shipmentId: service.shipmentId,
        rateId: service.rateId,
        packageId,
        redirectUrl,
      }));
      if (payload.data.message === 'System didnt find customers default payment method. Please attach it and try again.') {
        setNextStepModal(true);
      }
      if (payload.data.carrier) {
        toast.success('Success');
        navigate(`/orders/sales-order/preview/${orderId}`);
      }
      if (payload.data.requiresAction) {
        const url = await Utils.authWindow(payload.data.paymentIntentRedirectUrl, redirectUrl);
        const paymentIntent = new URL(url).searchParams.get('payment_intent');
        const { payload: res } = await dispatch(buyShippingLabelRequest({
          shipmentId: service.shipmentId,
          rateId: service.rateId,
          packageId,
          redirectUrl,
          pId: paymentIntent,
        }));
        if (res.data.carrier) {
          toast.success('Success');
          navigate(`/orders/sales-order/preview/${orderId}`);
        }
      }
    } else if (data.errors) {
      setPackageErrors(data.errors);
    } else {
      toast.error(data.message);
    }
    setLoading(false);
  }, [service, formPackage, orderId, packageId]);

  const handleVerifyAddress = useCallback(async (address) => {
    const d = {
      city: address.city,
      state: address.region,
      street: address.address1,
      zip: address.postalCode,
      country: address.countryCode,
    };

    const { payload } = await dispatch(verifyAddressRequest(d));
    if (payload?.data?.status !== 'error') {
      setValidated('valid');
      await dispatch(getShippingRatesRequest({ packageId, orderId }));
    } else {
      setValidated('notValid');
      toast.error(payload?.data?.message);
    }
  }, [validated, packageId, orderId]);

  const handleGetValidateText = useCallback((key) => {
    if (key === 'notValidated') {
      return <span>Validate Address</span>;
    }
    if (key === 'valid') {
      return (
        <div className={key}>
          <ValidateIcon />
          <span>Address validated</span>
        </div>
      );
    }
    return (
      <div className={key}>
        <WarningIcon />
        <span>Not valid address</span>
      </div>
    );
  }, []);

  return (
    <PreviewWrapper className="buy_shipping_label_wrapper">
      <div className="buy_shipping_label_form">
        <form onSubmit={handleSubmit}>
          <div className="top">
            <p className="page_title">New package</p>
            <div className="actions">
              {/* <Select options={options} placeholder="Shipment actions" roundBorder /> */}
              <CloseIcon className="closeIcon" onClick={() => navigate(-1)} />
            </div>
          </div>
          <div className="form_content">
            <div className="left">
              <div className="package">
                <Input
                  placeholder={orderPackage.number}
                  label="Package#"
                  readOnly
                  roundBorder
                />
              </div>
              <Collapsible
                className="shipment_details"
                title="Shipment details"
                unmount
              >
                <div>
                  <div className="address">
                    <div className="address_title">
                      <p>Shipping address</p>
                      <p className="edit" onClick={() => setAddressModal(true)}>Edit</p>
                    </div>
                    <p>{order?.shippingAddress?.address1}</p>
                    <p>{`${order?.shippingAddress?.firstName || '-'} ${order?.shippingAddress?.lastName || '-'}`}</p>
                    <p>
                      {Utils.formatAddress(
                        [order?.shippingAddress?.address,
                          order?.shippingAddress?.city,
                          order?.shippingAddress?.region,
                          order?.shippingAddress?.postalCode,
                          order?.shippingAddress?.countryCode],
                      )}
                    </p>
                    <p>{order?.shippingAddress?.postalCode}</p>
                    <button
                      className="validate"
                      type="button"
                      onClick={() => handleVerifyAddress(order.shippingAddress)}
                    >
                      {handleGetValidateText(validated)}
                    </button>
                  </div>
                  <div className="details">
                    <div>
                      <p>Ship by</p>
                      <Datepicker
                        format="M.d.yyyy"
                        onChange={(date) => handleChange('shippedAt', moment(date).format('YYYY-MM-DD'))}
                        value={formPackage.shippedAt ? moment(formPackage.shippedAt).format('MM.DD.YYYY') : undefined}
                      />
                    </div>
                    <div>
                      <p>Hold until</p>
                      <Datepicker
                        format="M.d.yyyy"
                        onChange={(date) => handleChange('holdUntil', moment(date).format('YYYY-MM-DD'))}
                        value={formPackage.holdUntil ? moment(formPackage.holdUntil).format('MM.D.YYYY') : undefined}
                      />
                    </div>
                  </div>
                </div>
              </Collapsible>
              <Collapsible
                className="shipment_items"
                title="Shipment items"
                speed={orderPackage.orderPackageProducts?.length > 15 ? 0.5 : 1}
              >
                <div className="products_list table_wrapper">
                  <table className="table">
                    <thead className="table_thead">
                      <tr className="table_thead_tr">
                        <th className="table_thead_tr_th">Product</th>
                        <th className="table_thead_tr_th">Qty</th>
                        <th className="table_thead_tr_th">Unit cost</th>
                        <th className="table_thead_tr_th">Total</th>
                      </tr>
                    </thead>
                    <tbody className="table_tbody">
                      {orderPackage.orderPackageProducts?.map((p) => {
                        const pr = products?.find((f) => f.id === p.productId) || {};
                        return (
                          <tr
                            className="table_tbody_tr"
                            key={p.id}
                          >
                            <td className="table_tbody_tr_td">
                              <div className="info" style={{ alignItems: 'center' }}>
                                {p.type !== 'custom' ? (
                                  <img
                                    style={{ height: 30, width: 30 }}
                                    src={pr?.images?.[0]?.src
                                    || pr?.images?.[0]?.medium
                                    || noImg}
                                    alt=""
                                  />
                                ) : null}
                                {p.type !== 'custom'
                                  ? (
                                    <Link
                                      to={pr.type === 'variant'
                                        ? `/products/${pr.parentId}?productVariantId=${pr.id}`
                                        : `/products/${pr.id}`}
                                      target="_blank"
                                    >
                                      {pr.title}
                                    </Link>
                                  )
                                  : <span>{pr.title}</span>}
                              </div>
                            </td>
                            <td className="table_tbody_tr_td pack">
                              {p.qty}
                            </td>
                            <td
                              className="table_tbody_tr_td"
                            >
                              {format(
                                order?.orderProducts?.find((o) => o.productId === p.productId).price,
                                { code: order.currencyCode },
                              )}
                            </td>
                            <td
                              className="table_tbody_tr_td"
                            >
                              {format(
                                order?.orderProducts?.find((o) => o.productId === p.productId).totalPrice,
                                { code: order.currencyCode },
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="total_container">
                    <div className="total">
                      <div className="item">
                        <p>Product</p>
                        <p>{format(order.subtotalPrice, { code: order.currencyCode })}</p>
                      </div>
                      <div className="item">
                        <p>Shipping</p>
                        <p>{format(order.shippingRate, { code: order.currencyCode })}</p>
                      </div>
                      <div className="item">
                        <p>Tax</p>
                        <p>{format(order.totalTax, { code: order.currencyCode })}</p>
                      </div>
                      <div className="item">
                        <p>Total cost</p>
                        <p>{format(order.totalPrice, { code: order.currencyCode })}</p>
                      </div>
                      <div className="item">
                        <p className="total_value">Total paid</p>
                        <p>{format(0, { code: order.currencyCode })}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapsible>
              <Collapsible
                className="notes"
                title="Notes"
              >
                <div>
                  <Input
                    textarea
                    roundBorder
                    label="Notes"
                    placeholder="Enter any notes to be displayed in your transaction"
                    value={formPackage.note}
                    onChange={(ev) => handleChange('note', ev.target.value)}
                    error={packageErrors.note}
                  />
                </div>
              </Collapsible>
            </div>
            <div className="right">
              <Collapsible
                className="configure_shipment"
                title="Configure shipment"
              >
                <div className="from">
                  <div>
                    <p>Ship from</p>
                    <div className="validate">
                      {' '}
                      {handleGetValidateText(warehouseAddressValidated)}
                    </div>
                    <p className="country">
                      {warehouse
                        ? Utils.formatAddress(
                          [warehouse?.address,
                            warehouse?.city,
                            warehouse?.region?.code,
                            warehouse?.postalCode,
                            warehouse?.country?.code],
                        )
                        : 'US'}
                    </p>
                  </div>
                  <Button btnType="light_blue" roundBorder onClick={() => setRatesModal(true)}>Calculate rates</Button>
                </div>
                <div className="row">
                  <Select
                    roundBorder
                    label="Service"
                    options={shippingRates}
                    getOptionLabel={(o) => `${o.provider} ${o.serviceName}`}
                    getFullOption
                    valuePath="rateId"
                    onChange={async (val) => {
                      handleChange('carrier', val.provider);
                      handleChange('service', val.serviceName);
                      handleChange('rateId', val.rateId);
                      searchParams.set('service_id', val.rateId);
                      setSearchParams(searchParams);
                      const { payload } = await dispatch(getShippingOptionsRequest(val.provider.toLowerCase()));
                      if (payload.data.confirmation) {
                        handleChange('extra.confirmation.signatureConfirmation', 'STANDARD');
                      }
                      if (payload.data.packingSlip) {
                        handleChange('extra.packingSlip', 'PDF_4x6');
                      }
                      setShippingOptions(payload.data);
                    }}
                    value={formPackage.rateId}
                  />
                </div>

                <div className="dimensions">
                  <Input
                    roundBorder
                    label={`Length (${orderPackage.dimensionsUnit})`}
                    value={formPackage.dimensions?.[0]}
                    onChange={(ev) => handleChange('dimensions[0]', ev.target.value)}
                    type="number"
                    error={packageErrors.dimensions?.[0]}

                  />
                  <Input
                    roundBorder
                    label={`Width (${orderPackage.dimensionsUnit})`}
                    value={formPackage.dimensions?.[1]}
                    onChange={(ev) => handleChange('dimensions[1]', ev.target.value)}
                    type="number"
                    error={packageErrors.dimensions?.[1]}

                  />
                  <Input
                    roundBorder
                    label={`Height (${orderPackage.dimensionsUnit})`}
                    value={formPackage.dimensions?.[2]}
                    onChange={(ev) => handleChange('dimensions[2]', ev.target.value)}
                    type="number"
                    error={packageErrors.dimensions?.[2]}

                  />
                </div>
                <div className="weight">
                  <Input
                    roundBorder
                    label="Weight"
                    value={formPackage.weight}
                    onChange={(ev) => handleChange('weight', ev.target.value)}
                    type="number"
                    error={packageErrors.weight}

                  />
                  <Select
                    roundBorder
                    label="Measure units"
                    options={weightUnits}
                    value={formPackage.weightUnit}
                    onChange={(val) => handleChange('weightUnit', val)}
                    error={packageErrors.weightUnit}

                  />
                </div>
                {shippingOptions.insurance ? (
                  <div className="row">
                    <Input
                      roundBorder
                      label="Insure Amount"
                      value={formPackage.extra?.insurance?.amount}
                      symbol="$"
                      onChange={(ev) => {
                        handleChange('extra.insurance.amount', ev.target.value);
                      }}
                    />
                  </div>
                ) : null}
                {shippingOptions.packingSlip ? (
                  <div className="row">

                    <Select
                      roundBorder
                      label="Packing Slip"
                      options={Object.entries(shippingOptions.packingSlip).map((p) => ({ label: p[0], value: p[1] }))}
                      onChange={(val) => {
                        handleChange('extra.packingSlip', val);
                      }}
                      value={formPackage.extra?.packingSlip}
                      error={packageErrors?.extra?.packingSlip}
                    />
                  </div>

                ) : null}
                {shippingOptions.confirmation
                  ? (
                    <div className="row">

                      <Select
                        roundBorder
                        label="Confirmation"
                        options={shippingOptions.confirmation.signatureConfirmation.map((p) => ({
                          label: _.startCase(p),
                          value: p,
                        }))}
                        onChange={(val) => {
                          handleChange('extra.confirmation.signatureConfirmation', val);
                        }}
                        value={formPackage.extra?.confirmation?.signatureConfirmation}
                      />
                    </div>

                  ) : null}
                {shippingOptions.alcohol
                  ? (
                    <div className="row">

                      <Select
                        roundBorder
                        label="Alcohol"
                        options={shippingOptions.alcohol.recipient_type.map((p) => ({
                          label: _.startCase(p),
                          value: p,
                        }))}
                        onChange={(val) => {
                          handleChange('extra.alcohol', val);
                        }}
                        value={formPackage.extra?.alcohol}
                      />
                    </div>

                  ) : null}
              </Collapsible>
              <Collapsible
                title="Other shipping options"
                className="other_shipping_options"
              >
                {shippingOptions.dangerous_goods ? (
                  <Checkbox
                    label="This shipment contains dangerous goods"
                    onChange={(val) => {
                      handleChange('extra.dangerous_goods', val);
                    }}
                    checked={formPackage.extra?.dangerous_goods}
                  />
                ) : null}

              </Collapsible>
              <div className="create_print">
                <div className="rates">
                  <div>
                    <p className="rate">{format(service.amount, { code: service.currency })}</p>
                    <p className="review">Cost review</p>
                  </div>
                  <Button roundBorder type="submit" loading={loading}>Create label</Button>
                </div>
                <div className="arrival">
                  <p>Estimated arrival: </p>
                  {service.estimatedDays ? <p className="bold">{`${service.estimatedDays} business days`}</p> : null}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {ratesModal
        ? (
          <RatesModal
            isOpen={ratesModal}
            onClose={() => setRatesModal(false)}
            formPackage={formPackage}
            orderPackage={orderPackage}
            onUpdatePackage={handleUpdatePackage}
            warehouse={warehouse}
          />
        ) : null}
      <StripeLayout>
        <ModalAddPayment isOpen={paymentMethodModal} onClose={() => setPaymentMethodModal(false)} />
      </StripeLayout>
      <NoCartAttachedModal
        isOpen={nextStepModal}
        onClose={() => setNextStepModal(false)}
        onContinue={() => {
          setPaymentMethodModal(true);
          setNextStepModal(false);
        }}
      />
      {addressModal ? (
        <EditOrderAddress
          isOpen={addressModal}
          onClose={() => setAddressModal(false)}
          onVerify={handleVerifyAddress}
        />
      ) : null}
    </PreviewWrapper>
  );
}

export default BuyShippingLabel;
