import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../_common/Loader/Loader';
import Table from '../../_common/Tables/Table';
import Button from '../../_common/Form/Button';
import Api from '../../../Api';

const tableHeader = [
  {
    title: 'Shop',
    path: 'shop',
  },
  {
    title: 'Type',
    path: 'type',
  },
  {
    title: 'Message',
    path: 'message',
  },
  {
    title: 'Created at',
    path: 'createdAt',
  },
];

function ProfileLogs({ type }) {
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await Api.getAllLogs({ level: type, userId });
      const channels = await Api.getIntegrations({ userId });

      setLogs(data.logs?.map((l) => ({
        ...l,
        type: l.level,
        shop: channels.data.integrations.find((int) => +int.id === +l.integrationId)?.name || '-',
      })) || []);
      setLoading(false);
    })();
  }, [type]);

  return (
    <div className="profile_channels">
      {loading ? <Loader /> : (
        <div>
          <Table
            data={logs}
            header={tableHeader}
          />

          {!!logs.length && (
            <div className="view_all_btn_wrapper">
              <Button
                btnType="transparent"
                color="#1472FF"
                title="View all logs"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

ProfileLogs.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ProfileLogs;
