import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

const Tabs = ({ tabs, currentTab, onChangeTab }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const findTab = tabs.find(({ name, href }) => (name === currentTab || href === pathname));

    if (!findTab && tabs[0].href) {
      navigate(tabs[0].href, { replace: true });
    }
  }, [pathname]);

  return (
    <div>
      <div className="tab_wrapper">
        {tabs.map((t) => {
          const Component = t.href ? NavLink : 'button';
          const activeTab = currentTab === t.name || t.href === pathname;

          return (
            <div key={t.name} data-scope={t.scope} className={classNames('tab', { active: activeTab })}>
              {t.icon && (
                <div className="tab_title_icon">
                  {t.icon}
                </div>
              )}

              <Component
                to={`${t.href}${t.query || ''}` || undefined}
                replace={!!t.href || undefined}
                tabIndex="0"
                id={t.id || undefined}
                className="tab_title"
                onClick={() => onChangeTab(t.name)}
              >
                {t.name}
              </Component>
            </div>
          );
        })}
      </div>

      <div className="tab_content">
        {tabs.find(({ name, href }) => name === currentTab || href === pathname)?.component}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  currentTab: PropTypes.string,
  onChangeTab: PropTypes.func,
};

Tabs.defaultProps = {
  currentTab: '',
  onChangeTab: () => {
  },
};

export default Tabs;
