import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  changeCustomersTableAttribute,
  createCustomerRequest,
  customersTableEditableEvent,
  getCustomersRequest,
  getCustomersTableAttributes,
  getSingleCustomerRequest,
  removeCustomerData,
  removeCustomerFormData,
  setCustomerFormData,
  setCustomerFormErrors,
  unsetCustomerFormData,
  updateCustomerRequest,
} from '../actions/customers';

export const addressIdKey = Symbol('id');
export const contactIdKey = Symbol('id');

const customerFormData = {
  shippingAddresses: [{ [addressIdKey]: _.uniqueId('temp') }],
  billingAddress: {},
  contacts: [{ [contactIdKey]: _.uniqueId('temp'), salutation: 'mr' }],
  type: 'individual',
  isVendor: false,
  isCustomer: true,
};

const initialState = {
  customers: [],
  customer: {},
  totalPages: 1,
  customerFormData,
  errors: {},
  columns: [
    {
      key: 'shopType', isEnabled: true,
    },
    {
      key: 'image', isEnabled: true,
    },
    {
      key: 'firstName', isEnabled: true,
    },
    {
      key: 'lastName', isEnabled: true,
    },
    {
      key: 'phone', isEnabled: true,
    },
    {
      key: 'isCustomer', isEnabled: true,
    },
    {
      key: 'type', isEnabled: true,
    },
    {
      key: 'jobPosition', isEnabled: true,
    },
    {
      key: 'website', isEnabled: true,
    },
    {
      key: 'orders', isEnabled: true,
    },
    {
      key: 'createdAt', isEnabled: true,
    },
  ],
  tableEditable: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCustomersRequest.fulfilled, (state, action) => {
      state.customers = action.payload.data.customers;
      state.totalPages = action.payload.data.totalPages;
    })

    .addCase(createCustomerRequest.rejected, (state, action) => {
      const { errors, message } = action.payload.data;
      state.errors = errors;
      if (message !== 'Unprocessable Entity') {
        toast.error(message);
      }
    })

    .addCase(getCustomersTableAttributes.fulfilled, (state, { payload }) => {
      state.columns = payload.customersTableAttributes?.length ? payload.customersTableAttributes : state.columns;
    })

    .addCase(customersTableEditableEvent, (state, { payload }) => {
      state.tableEditable = payload;
    })

    .addCase(changeCustomersTableAttribute, (state, { payload }) => {
      state.columns = payload;
    })

    .addCase(updateCustomerRequest.rejected, (state, action) => {
      const { errors, message } = action.payload.data;
      state.errors = errors;
      if (message !== 'Unprocessable Entity') {
        toast.error(message);
      }
    })

    .addCase(setCustomerFormData, (state, action) => {
      const { key, value } = action.payload;

      _.set(state.customerFormData, key, value);

      _.unset(state.errors, key);
      state.errors = _.omitBy(state.errors, _.isEmpty);
    })

    .addCase(unsetCustomerFormData, (state, action) => {
      const { key } = action.payload;
      _.unset(state.customerFormData, key);
    })

    .addCase(getSingleCustomerRequest.fulfilled, (state, action) => {
      const { data: { customer, customer: { billingAddress = {}, contacts = [] } } } = action.payload;
      state.customer = customer;

      state.customerFormData = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        isVendor: customer.isVendor,
        isCustomer: customer.isCustomer,
        type: customer.type,
        company: customer.type,
        vatId: customer.type,
        phone: customer.phone,
        workPhone: customer.workPhone,
        defaultCurrencyCode: customer.defaultCurrencyCode,
        defaultPaymentMethodId: customer.defaultPaymentMethodId,
        defaultPaymentTermId: customer.defaultPaymentTermId,
        defaultPriceListId: customer.defaultPriceListId,
        defaultWarehouseId: customer.defaultWarehouseId,
        defaultTaxId: customer.defaultTaxId,
        billingAddress: {
          title: billingAddress?.title || undefined,
          firstName: billingAddress?.firstName,
          lastName: billingAddress?.lastName,
          city: billingAddress?.city,
          region: billingAddress?.region,
          postalCode: billingAddress?.postalCode,
          phone: billingAddress?.phone,
          address1: billingAddress?.address1 || undefined,
          address2: billingAddress?.address2,
          company: billingAddress?.company,
          countryCode: billingAddress?.countryCode,
          [addressIdKey]: billingAddress?.id || _.uniqueId('temp'),
        },
        shippingAddresses: customer.shippingAddresses.length > 0 ? customer.shippingAddresses.map((s) => ({
          title: s.title,
          firstName: s.firstName,
          lastName: s.lastName,
          city: s.city,
          region: s.region,
          postalCode: s.postalCode,
          phone: s?.phone,
          address1: s.address1,
          address2: s.address2,
          company: s.company,
          countryCode: s.countryCode,
          [addressIdKey]: s.id || _.uniqueId('temp'),
        })) : customerFormData.shippingAddresses,
        image: customer.image,
        contacts: contacts.map((c) => ({ ...c, [contactIdKey]: _.uniqueId('temp') })),
        id: customer.id,
      };
    })

    .addCase(removeCustomerFormData, (state) => {
      state.customerFormData = customerFormData;
      state.errors = {};
    })

    .addCase(setCustomerFormErrors, (state, action) => {
      state.errors = { ...state.errors, ...action.payload };
    })

    .addCase(removeCustomerData, (state) => {
      state.customer = {};
    });
});

export default reducer;
