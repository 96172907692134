import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Select from '../../../../_common/Form/Select';
import Api from '../../../../../Api';
import Button from '../../../../_common/Form/Button';

const defaultData = {
  startDate: moment().subtract(1, 'month').format('MM-DD-YYYY'),
  endDate: moment().format('MM-DD-YYYY'),
  warehouseIds: [],
  categoryIds: [],
  brandIds: [],
  productIds: [],
  skus: [],
  productTypes: [],
};

const productTypeOptions = [
  {
    value: 'simple',
    label: 'Simple',
  },
  {
    value: 'bundle',
    label: 'Bundle',
  },
  {
    value: 'variant',
    label: 'Variant',
  },
  {
    value: 'parent',
    label: 'Parent',
  },
];

function InventoryStockMovementExportReport({ closeModal }) {
  const [movementData, setMovementData] = useState(defaultData);
  const [defaultAsyncOptions, setDefaultAsyncOptions] = useState({
    warehouseIds: [],
    categoryIds: [],
    brandIds: [],
    products: [],
  });

  useEffect(() => {
    (async () => {
      const [
        warehouses,
        categories,
        brands,
        products,
        skus,
      ] = await Promise.all([
        Api.getWarehouses(),
        Api.getCategories({ includes: 'parents' }),
        Api.getProductsBrands(),
        Api.getProducts(),
        Api.getProducts({ shopId: '0' }),
      ]);

      setDefaultAsyncOptions({
        location: warehouses.data.warehouses,
        productCategory: categories.data.categories,
        productBrand: brands.data.brands,
        products: products.data.products,
        skus: skus.data.products,
      });
    })();

    return () => setMovementData(defaultData);
  }, []);

  const selectFields = useMemo(() => [
    {
      path: 'warehouseIds',
      label: 'Locations',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: defaultAsyncOptions.location,
      getFullOption: true,
      menuPlacement: 'bottom',
      getOptionLabel: (options) => (options.title),
    },
    {
      path: 'categoryIds',
      label: 'Product categories',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: defaultAsyncOptions.productCategory,
      getFullOption: true,
      menuPlacement: 'bottom',
      getOptionLabel: (options) => (options.name),
    },
    {
      path: 'brandIds',
      label: 'Brands',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: defaultAsyncOptions.productBrand,
      getFullOption: true,
      menuPlacement: 'top',
      getOptionLabel: (options) => (options.name),
    },
    {
      path: 'productIds',
      label: 'Products',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: defaultAsyncOptions.products,
      getFullOption: true,
      menuPlacement: 'top',
      getOptionLabel: (options) => (options.title),
    },
    {
      path: 'skus',
      label: 'SKU',
      valuePath: 'sku',
      isAsync: true,
      defaultOptions: defaultAsyncOptions?.skus?.filter((s) => s.sku),
      getFullOption: true,
      menuPlacement: 'top',
      getOptionLabel: (options) => (options.sku),
    },
  ], [defaultAsyncOptions]);

  const loadMoreData = useCallback(async (path, inputValue, callback) => {
    const paths = {
      warehouseIds: 'warehouses',
      categoryIds: 'categories',
      brandIds: 'brands',
      productIds: 'products',
      skus: 'products',
    };

    const requests = {
      warehouseIds: Api.getWarehouses,
      brandIds: Api.getProductsBrands,
      categoryIds: Api.getCategories,
      productIds: Api.getProducts,
      skus: Api.getProducts,
    };

    const requestData = { s: inputValue };
    if (path === 'skus') requestData.shopId = '0';

    const { data } = await requests[path](requestData);

    const dataForCallback = path !== 'skus' ? data[paths[path]] : data[paths[path]]?.filter((s) => s.sku);

    callback(dataForCallback);
  }, []);

  const onDownload = useCallback(async () => {
    await Api.movementExport({
      by: {
        startDate: movementData.startDate,
        endDate: movementData.endDate,
        skus: movementData.skus.map((s) => s.sku),
        categoryIds: movementData.categoryIds.map((c) => c.id),
        brandIds: movementData.brandIds.map((b) => b.id),
        productTypes: movementData.productTypes,
        productIds: movementData.productIds.map((p) => p.id),
        warehouseIds: movementData.warehouseIds.map((w) => w.id),
      },
      fields: ['title', 'sku', 'date', 'locationFrom', 'locationTo'],
      fileType: 'csv',
    });

    toast.success('Successfully exported');
    closeModal();
  }, [movementData]);

  return (
    <div className="inventory_movement_report">
      <h3>Export stock movement report</h3>

      <div className="inventory_movement_report_select_wrp">
        <RangeDatePicker
          currentDate={{
            startDate: movementData.startDate,
            endDate: movementData.endDate,
          }}
          onChangeDate={(value) => setMovementData((prev) => ({
            ...prev,
            startDate: value[0],
            endDate: (value[0] > value[1] || !value[1]) ? value[0] : value[1],
          }))}
          calendarClassName="inventory_report_filters_menu_calendar"
          popperPlacement="bottom-start"
        />
      </div>

      {selectFields.map(({
        path, valuePath, defaultOptions, getOptionLabel, label, menuPlacement,
      }) => (
        <div className="inventory_movement_report_select_wrp" key={path}>
          <Select
            value={movementData[path]}
            valuePath={valuePath}
            isAsync
            defaultOptions={defaultOptions}
            size="small"
            roundBorder
            loadOptions={(inputValue, callback) => loadMoreData(path, inputValue, callback)}
            onChange={(val) => setMovementData((prev) => ({ ...prev, [path]: val }))}
            getFullOption
            getOptionLabel={getOptionLabel}
              // menuPosition="fixed"
            isMulti
            isClearable
            label={label}
            menuPlacement={menuPlacement}
          />
        </div>
      ))}

      <div className="inventory_movement_report_select_wrp">
        <Select
          value={movementData.productTypes}
          options={productTypeOptions}
          size="small"
          roundBorder
          onChange={(val) => setMovementData((prev) => ({ ...prev, productTypes: val }))}
          isClearable
          label="Product type"
          menuPlacement="top"
          isMulti
        />
      </div>

      <div className="inventory_movement_report_buttons">
        <Button
          className="inventory_movement_report_cancel_btn"
          title="Cancel"
          btnType="cancel"
          size="small"
          roundBorder
          onClick={closeModal}
        />

        <Button
          className="inventory_movement_report_download_btn"
          title="Download"
          size="small"
          roundBorder
          onClick={onDownload}
        />
      </div>
    </div>
  );
}

export default InventoryStockMovementExportReport;
