import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  endThreadRequest,
  getAnswerRequest,
  getThreadRequest,
  getThreadsRequest,
  resetChatBot,
  resetNewMessage,
  socketAIEndThread,
  socketAICreatedThread,
  socketAIMessage, updateThreadNameRequest, createThreadRequest, socketAIUpdateThreadName,
} from '../actions/chatBot';
import Account from '../../helpers/Account';

const initialState = {
  chat: {
    topic: '',
    threadId: '',
    messages: [],
    hasMore: false,
    totalPages: 1,
    newMessage: false,
    messageLoading: false,
    chatLoading: false,
  },
  threads: {
    threadsLists: [],
    page: 1,
    totalPages: 1,
    threadsListsLoading: false,
  },
};

const firstMessage = (firstName, lastName) => ({
  id: Date.now(),
  role: 'assistant',
  content: `Hello, ${firstName} ${lastName}, how can I help you?`,
});

const regex = /^\/[a-z]+(\/[a-z]+)? /i;

const formattingMsg = (message) => message.replace(regex, '');

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(socketAIMessage, (state, { payload }) => {
      const {
        message, threadId, role, id,
      } = payload;

      if (state.chat.threadId === threadId
              && ((role === 'user' && _.last(state.chat.messages).role !== 'user')
                  || (role === 'assistant' && _.last(state.chat.messages).id !== id))) {
        state.chat.messageLoading = role === 'user';
        state.chat.messages = [...state.chat.messages, { ...payload, content: formattingMsg(message) }];
        state.chat.newMessage = role === 'assistant';
      }
    })
    .addCase(resetNewMessage, (state) => {
      state.chat.newMessage = false;
    })
    .addCase(getThreadsRequest.pending, (state) => {
      state.threads.threadsListsLoading = true;
    })
    .addCase(getThreadsRequest.fulfilled, (state, { payload }) => {
      const { threads: threadsLists, page, totalPages } = payload;

      state.threads = {
        threadsLists: [...state.threads.threadsLists, ...threadsLists],
        page,
        totalPages,
        threadsListsLoading: false,
      };
    })
    .addCase(getThreadRequest.pending, (state, { meta: { arg: { threadId } } }) => {
      state.chat.chatLoading = true;
      state.chat.threadId = threadId;
    })
    .addCase(getThreadRequest.fulfilled, (state, { payload }) => {
      const {
        messages, hasMore, thread, firstId,
      } = payload;
      const { firstName, lastName } = Account.get();

      const formattedMessages = messages.reverse().filter((m) => m.content).map((m) => (m.role === 'user'
        ? { ...m, content: formattingMsg(m.content) }
        : m));

      const newMessages = !state.chat.threadId
        ? formattedMessages
        : [...formattedMessages, ...state.chat.messages];

      state.chat.messages = !hasMore
        ? [firstMessage(firstName, lastName), ...newMessages]
        : newMessages;
      // state.chat.threadId = thread.threadId;
      state.chat.topic = `/orders/${thread.threadType}`; // ?
      state.chat.hasMore = hasMore;
      state.chat.messageLoading = firstId === _.last(messages)?.id && _.last(messages)?.role === 'user';
      state.chat.chatLoading = false;
    })
    .addCase(createThreadRequest.pending, (state, { meta: { arg: { orderType } } }) => {
      state.chat.chatLoading = true;
      state.chat.topic = `/orders/${orderType}`;
    })
    .addCase(createThreadRequest.fulfilled, (state, { payload }) => {
      const { firstName, lastName } = Account.get();

      state.chat.messages = [firstMessage(firstName, lastName)];
      state.chat.threadId = payload.thread.threadId;
      state.chat.chatLoading = false;
    })
    .addCase(updateThreadNameRequest.pending, (state, { meta: { arg: { threadId, threadName } } }) => {
      state.threads.threadsLists = state.threads.threadsLists
        .map((t) => (t.threadId === threadId ? { ...t, threadName, threadNameChanged: true } : t));
    })
    .addCase(socketAICreatedThread, (state, { payload }) => {
      if (!state.threads.threadsLists.find((t) => t.threadId === payload.threadId)) {
        state.threads.threadsLists = [payload, ...state.threads.threadsLists];
      }
    })
    .addCase(getAnswerRequest.pending, (state, { meta: { arg: { message } } }) => {
      state.chat.messageLoading = true;
      state.chat.messages = [...state.chat.messages, { role: 'user', content: message, id: Date.now() }];
    })
    .addCase(resetChatBot, (state, leaveThreadsLists) => {
      if (leaveThreadsLists) {
        return { ...initialState, threads: { ...state.threads, threadsLists: state.threads.threadsLists } };
      }

      return initialState;
    })
    .addMatcher(isAnyOf(updateThreadNameRequest.pending, socketAIUpdateThreadName), (state, action) => {
      const { threadId, threadName } = action.payload || action?.meta?.arg;

      state.threads.threadsLists = state.threads.threadsLists
        .map((t) => (t.threadId === threadId ? { ...t, threadName, threadNameChanged: true } : t));
    })
    .addMatcher(isAnyOf(getAnswerRequest.rejected, getThreadRequest.rejected), (state, { meta: { arg: threadId } }) => {
      const newThreadsLists = state.threads.threadsLists.filter((l) => l.threadId !== threadId);

      return { ...initialState, threads: { ...state.threads, threadsLists: newThreadsLists } };
    })
    .addMatcher(isAnyOf(endThreadRequest.pending, socketAIEndThread), (state, action) => {
      const threadId = action.payload || action?.meta?.arg;

      const newThreadsLists = state.threads.threadsLists.filter((l) => l.threadId !== threadId);

      if (threadId === state.chat.threadId) {
        return { ...initialState, threads: { ...state.threads, threadsLists: newThreadsLists } };
      }

      return { ...state, threads: { ...state.threads, threadsLists: newThreadsLists } };
    });
});

export default reducer;
