import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Modal from './Modal';
import Select from '../Form/Select';
import { getTemplatesListRequest } from '../../../store/actions/templates';
import Loader from '../Loader/Loader';
import Button from '../Form/Button';
import { getPrintersListRequest } from '../../../store/actions/app';
import Input from '../Form/Input';

const orientation = [
  { label: 'Portrait', value: 'portrait' },
  { label: 'Landscape', value: 'landscape' },
];

const color = [
  { label: 'Black and white', value: 'blackAndWhite' },
  { label: 'Color', value: 'color' },
];

function PrintDocumentModal(props) {
  const {
    isOpen, onClose, type, onRequest, onRemotePrint,
  } = props;

  const [pdf, setPdf] = useState('');
  const [templateId, setTemplateId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [remotePrintingData, setRemotePrintingData] = useState({
    orientation: 'portrait',
    monochrome: 'blackAndWhite',
    copy: 1,
  });
  const [loadingRemotePrint, setLoadingRemotePrint] = useState(false);

  const dispatch = useDispatch();

  const templates = useSelector((state) => state.templates.templates);
  const printers = useSelector((state) => state.app.printers);

  const devices = useMemo(() => _.uniqBy(printers, 'deviceId').map((p) => {
    const devicePrinters = printers.filter((pr) => pr.deviceId === p.deviceId);
    return {
      label: p.deviceName,
      value: p.deviceName,
      deviceId: p.deviceId,
      paperSizes: p.paperSizes,
      printer: devicePrinters.find((pr) => pr.isDefault)?.printer || devicePrinters[0].printer,
    };
  }), [printers]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (type) {
        await dispatch(getTemplatesListRequest({ type }));
      }
      const data = await onRequest(templateId);
      setPdf(data);
      setLoading(false);
    })();
  }, [templateId, type]);

  useEffect(() => {
    dispatch(getPrintersListRequest());
  }, []);

  useEffect(() => {
    const el = document.getElementById('iFramePdf');
    if (el && pdf) {
      el.src = URL.createObjectURL(pdf);
      el.focus();
    }
  }, [pdf]);

  const handleDownload = useCallback(() => {
    fileDownload(pdf, 'document.pdf');
  }, [pdf]);

  const handleChange = useCallback((key, value) => {
    _.set(remotePrintingData, key, value);
    setRemotePrintingData({ ...remotePrintingData });
  }, [remotePrintingData]);

  const handleRemotePrint = useCallback(async () => {
    setLoadingRemotePrint(true);
    const res = await onRemotePrint({
      to: [remotePrintingData.deviceId, remotePrintingData.printer].join(','),
      paperSize: remotePrintingData.paperSize,
      orientation: remotePrintingData.orientation,
      monochrome: remotePrintingData.monochrome === 'blackAndWhite',
      copy: remotePrintingData.copy,
    });

    if (res.status === 'error') {
      toast.error(res.message);
    } else {
      toast.success('Successfully printed!');
    }
    setLoadingRemotePrint(false);
  }, [remotePrintingData]);

  return (
    <Modal
      isOpen={!!isOpen}
      className="print_order_modal"
      wrapperClassName="print_order_modal_wrapper"
      onClose={onClose}
      overflowY={false}
      zIndex={110}
    >
      <div className="iframe_container">
        {!loading ? <iframe id="iFramePdf" style={{ height: '100%' }} title="template" /> : null}
        {loading ? <Loader /> : null}
      </div>

      <div className="actions">
        <Select
          roundBorder
          label="Template"
          options={templates}
          valuePath="id"
          placeholder="Use stores default template"
          getOptionLabel={(o) => o.title || `${o.template} - ${o.id}`}
          onChange={(id) => setTemplateId(id)}
          value={templateId}
        />
        <p className="title">Print Options</p>
        <p className="subtitle">Browser Print</p>
        <p className="info">
          Hover the cursor over the preview window to access
          browser printing, or download the document using the
          Download button
        </p>
        <Button roundBorder btnType="light_blue" onClick={handleDownload}>
          Download
        </Button>

        {!_.isEmpty(printers) ? (
          <>
            <div className="status">
              <p className="label">Status</p>
              {remotePrintingData.printer ? (
                <p
                  className={`state ${!printers.find((p) => p.deviceId === remotePrintingData.deviceId)?.isOnline ? 'offline' : ''}`}
                >
                  {printers.find((p) => p.deviceId === remotePrintingData.deviceId)?.isOnline ? 'Online' : 'Offline'}
                </p>
              ) : null}
            </div>
            <Select
              options={devices}
              roundBorder
              isSearchable
              label="Device"
              placeholder="Select device"
              valuePath="deviceId"
              getFullOption
              onChange={(val) => {
                handleChange('deviceId', val.deviceId);
                handleChange('printer', val.printer);
                handleChange('paperSize', val.paperSizes[0]);
              }}
              value={remotePrintingData.deviceId}
            />
          </>
        ) : null}
        {remotePrintingData.deviceId ? (
          <>
            <Select
              options={printers.filter((p) => p.deviceId === remotePrintingData.deviceId)}
              roundBorder
              isSearchable
              label="Printer"
              placeholder="Select printer"
              labelPath="description"
              valuePath="printer"
              getFullOption
              onChange={(val) => {
                handleChange('printer', val.printer);
              }}
              value={remotePrintingData.printer}
            />
            <Input
              roundBorder
              value={remotePrintingData.copy}
              onChange={(ev) => handleChange('copy', ev.target.value)}
              type="number"
              className="default_ui"
              label="Copy"
              min={1}
            />
            <Select
              options={orientation}
              roundBorder
              isSearchable
              label="Orientation"
              onChange={(val) => {
                handleChange('orientation', val);
              }}
              value={remotePrintingData.orientation}
            />
            <Select
              options={printers.find((p) => p.deviceId === remotePrintingData.deviceId)?.paperSizes?.map((p) => ({
                label: p,
                value: p,
              }))}
              roundBorder
              isSearchable
              label="Paper Size"
              onChange={(val) => {
                handleChange('paperSize', val);
              }}
              value={remotePrintingData.paperSize}
              menuPlacement="top"
            />
            <Select
              options={color}
              roundBorder
              label="Color"
              onChange={(val) => {
                handleChange('monochrome', val);
              }}
              value={remotePrintingData.monochrome}
              menuPlacement="top"
            />

          </>

        ) : null}
        {!_.isEmpty(printers) ? (
          <Button
            roundBorder
            onClick={handleRemotePrint}
            disabled={!remotePrintingData.printer}
            loading={loadingRemotePrint}
          >
            Remote print
          </Button>
        ) : null}

      </div>
    </Modal>
  );
}

export default PrintDocumentModal;
