import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment/moment';
import ChartWrapper from '../../../../_common/ChartWrapper/ChartWrapper';
import Api from '../../../../../Api';
import Table from '../../../../_common/Tables/Table';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Button from '../../../../_common/Form/Button';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/icons/no_match.svg';

const shippingInfo = [
  {
    title: 'Shipments:',
    path: 'labelCount',
  },
  {
    title: 'Shipping cost:',
    path: 'shippingCost',
    symbol: '$',
  },
  {
    title: 'Average cost:',
    path: 'avgCostPerLabel',
    symbol: '$',
  },
  {
    title: 'Shipping revenue:',
    path: 'shippingRevenue',
    symbol: '$',
  },
  {
    title: 'Net shipping revenue:',
    path: 'netShippingRevenue',
    symbol: '$',
  },
];

const shipmentsByClassTableHeader = [
  {
    title: 'Service',
    path: 'service',
  },
  {
    title: 'Qty',
    path: 'shippedCount',
  },
  {
    title: 'Share',
    path: 'shippedPercent',
  },
];

const shipmentsByCarrierTableHeader = [
  {
    title: 'Carrier',
    path: 'carrier',
  },
  {
    title: 'Qty',
    path: 'shippedCount',
  },
  {
    title: 'Share',
    path: 'shippedPercent',
  },
];

const shipmentsByUserTableHeader = [
  {
    title: 'User name',
    path: 'userName',
  },
  {
    title: 'Qty',
    path: 'shippedLabelCount',
  },
];

const today = moment().format('YYYY-MM-DD');
const lastTwoWeek = moment(new Date()).subtract(14, 'days').format('YYYY-MM-DD');

const Operations = ({ topChartTemplate }) => {
  const [date, setDate] = useQueryParams({
    startDate: withDefault(StringParam, lastTwoWeek),
    endDate: withDefault(StringParam, today, true),
  }, { updateType: 'replaceIn' });

  const { startDate, endDate } = date;

  const [shipmentsChart, setShipmentsChart] = useState(topChartTemplate);
  const [shipmentsInfoByClass, setShipmentInfoByClass] = useState([]);
  const [shipmentsInfoByCarrier, setShipmentInfoByCarrier] = useState([]);
  const [shipmentsInfoByUser, setShipmentInfoByUser] = useState([]);
  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      loadingToggle(true);

      const [
        { data: { shipments, shipmentsInfo: info } },
        { data: { shippingByClass } },
        { data: { shipments: shipmentsByCarrier } },
        { data: { shipments: shipmentsByUser } },
      ] = await Promise.all([
        Api.getReportsShipments({ startDate, endDate: endDate || startDate }),
        Api.getReportsShipmentsByClass({ startDate, endDate: endDate || startDate }),
        Api.getReportsShipmentsByCarrier({ startDate, endDate: endDate || startDate }),
        Api.getReportsShipmentsByUser({ startDate, endDate: endDate || startDate }),
      ]);

      setShipmentInfoByClass(shippingByClass.map((s) => ({ ...s, shippedPercent: `${s.shippedPercent}%` })));
      setShipmentInfoByCarrier(shipmentsByCarrier.map((s) => ({ ...s, shippedPercent: `${s.shippedPercent}%` })));
      setShipmentInfoByUser(shipmentsByUser);

      setShipmentsChart((prev) => ({
        ...prev,
        legend: {
          show: false,
        },
        xaxis: {
          ...prev.xaxis,
          categories: shipments.map((o) => o.date),
        },
        series: [
          {
            name: 'Shipping cost',
            data: shipments.map(({ shippingCost }) => shippingCost),
          },
        ],
        info,
      }));

      loadingToggle(false);
    })();
  }, [date]);

  const changeDate = (d) => {
    setDate({ startDate: d[0], endDate: d[1] || '' });
  };

  return (
    <div className="sales_overview_wrapper operations">
      <div className="sales_overview_date_filter">
        <RangeDatePicker currentDate={date} onChangeDate={changeDate} />
      </div>

      <ChartWrapper height={480} title="Shipments" loading={loading}>
        <div className="chart_info">
          {shippingInfo.map(({ title, path, symbol = '' }) => (
            <div key={title}>{`${title} ${symbol}${shipmentsChart.info[path]}`}</div>
          ))}
        </div>

        <Chart
          height={350}
          width="100%"
          type="bar"
          className="apx-chart"
          series={shipmentsChart.series}
          options={shipmentsChart}
        />
      </ChartWrapper>

      <div className="overview_bottom_block">
        <ChartWrapper height={330} title="Shipment by class" loading={loading}>
          <Table
            data={shipmentsInfoByClass}
            header={shipmentsByClassTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Shipments not found</h1>
              </>
            )}
          />

          {!!shipmentsInfoByClass.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Shipment by carrier" loading={loading}>
          <Table
            data={shipmentsInfoByCarrier}
            header={shipmentsByCarrierTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Shipments not found</h1>
              </>
            )}
          />

          {!!shipmentsInfoByCarrier.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Shipment by user" loading={loading}>
          <Table
            data={shipmentsInfoByUser}
            header={shipmentsByUserTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Shipments not found</h1>
              </>
            )}
          />

          {!!shipmentsInfoByClass.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>
      </div>
    </div>
  );
};

export default Operations;
