import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Input from '../../_common/Form/Input';
import Button from '../../_common/Form/Button';
import { getProductsRequest } from '../../../store/actions/products';
import Checkbox from '../../_common/Form/Checkbox';
import noImg from '../../../assets/icons/no_img.svg';
import Loader from '../../_common/Loader/Loader';
import Modal from '../../_common/Modal/Modal';
import { idKey } from './OrderProductsList';

function OrderProducts(props) {
  const {
    onAddOrderProducts, onAddProducts, priceListId, saveOrder, status, shopId,
  } = props;
  const [products, setProducts] = useState([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [productIds, setProductIds] = useState([]);

  const dispatch = useDispatch();
  const { orderId } = useParams();

  const warehouses = useSelector((state) => state.settings.generalList.warehouses);

  const handleProductsSearch = useCallback(async (ev) => {
    setLoading(true);
    setValue(ev.target.value);
    if (ev.target.value.length === 1) {
      setModal(true);
    }
    const { payload } = await dispatch(getProductsRequest({
      s: ev.target.value,
      type: 'variant,simple,bundle',
      includes: 'stock,prices',
      isArchive: false,
      shopId,
      sortBy: !ev.target.value ? 'totalSold' : undefined,
    }));
    setProducts(payload.data.products);
    setLoading(false);
  }, [value, shopId]);

  const handleCheck = useCallback((id) => {
    if (productIds.includes(id)) {
      setProductIds(productIds.filter((p) => p !== id));
    } else {
      setProductIds([...productIds, id]);
    }
  }, [productIds]);

  const cancel = useCallback(() => {
    setProducts([]);
    setValue('');
    setModal(false);
  }, []);

  const getPrice = useCallback(
    (prices) => prices.find((pr) => pr.priceListId === priceListId)?.price || prices?.[0]?.price || 0,
    [priceListId],
  );

  const handleAddItems = useCallback(() => {
    const items = _.cloneDeep(products).filter((p) => productIds.includes(p.id));

    items.forEach((i) => {
      i.warehouses = warehouses.map((w) => {
        const warehouse = i.totalStock.stocks.find((s) => s.warehouseId === w.id);
        if (!warehouse) {
          return null;
        }
        return {
          ...warehouse,
          ...w,
        };
      }).filter((d) => d);
    });

    const op = items.map((p) => ({
      productId: p.id,
      price: getPrice(p.prices),
      qty: 1,
      warehouseId: p.warehouses.find((w) => w.isDefault)?.id || p.warehouses[0]?.id,
      [idKey]: _.uniqueId('temp_'),
    }));

    onAddOrderProducts(op);
    onAddProducts(items);
    setProducts([]);
    setProductIds([]);
    setValue('');
    setModal(false);
  }, [products, productIds, getPrice, warehouses]);

  const handleAddNew = async () => {
    const res = await saveOrder(orderId ? status : 'draft', '/products/add', { _title: value });
    if (res === 'error') {
      toast.error('Can not navigate until all required fields is filled');
    }
  };

  return (
    <div className="products">
      {loading ? <div className="products_loading"><Loader size={20} /></div> : null}
      <div className="search">
        <Input
          roundBorder
          search
          onChange={handleProductsSearch}
          onFocus={(ev) => {
            handleProductsSearch(ev);
            setModal(true);
          }}
          value={value}
          placeholder="Search product"
          autoComplete="off"
        />
      </div>
      <Modal
        isOpen={modal}
        className="sale_order_products_select_modal"
        onClose={() => {
          setModal(false);
          setValue('');
        }}
      >
        {loading ? <div className="products_loading"><Loader size={20} /></div> : null}
        <p className="modal_title">Select products</p>

        <div className="search_wrapper">
          <Input
            roundBorder
            search
            onChange={(ev) => {
              handleProductsSearch(ev);
            }}
            value={value}
            placeholder="Search product"
            autoFocus
            autoComplete="off"
          />

          <div data-scope="WRITE_PRODUCTS" className="add" onClick={handleAddNew}>
            <span className="plus">+</span>
            <span>Add new</span>
          </div>
        </div>
        {!_.isEmpty(products) ? (
          <div className="dropdown">
            <div className="dropdown_content">
              <ul>
                {products.map((p) => (
                  <li key={p.id}>
                    <Checkbox
                      onChange={() => handleCheck(p.id)}
                      checked={productIds.includes(p.id)}
                      label={(
                        <div className="checkbox_label">
                          <img src={p.images[0]?.src || p.images[0]?.medium || noImg} alt="" />
                          <div>
                            <p className="title">{p.title}</p>
                            <p>{p.id}</p>
                          </div>
                        </div>
                      )}
                    />

                  </li>
                ))}
              </ul>
              <div className="actions">
                <Button roundBorder btnType="cancel" onClick={cancel}>Cancel</Button>
                <Button roundBorder onClick={handleAddItems}>Add items</Button>
              </div>
            </div>
          </div>
        ) : <p style={{ padding: 20 }}>No products</p>}
      </Modal>

    </div>
  );
}

export default OrderProducts;
