import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LayoutMenu from './LayoutMenu';
import Header from './Header';
import Account from '../../helpers/Account';
import { getAccountRequest, getCurrentSubscription, getPermissions } from '../../store/actions/users';
import SearchField from './SearchField';
import useWindowSize from '../../helpers/hooks/useWindowSize';
import ChatBot from '../_common/ChatBot/ChatBot';

const Layout = ({ prevPath }) => {
  const isAuthorized = Account.getToken() && !_.isEmpty(Account.get());
  const currentSubscription = useSelector((state) => state.users.currentSubscription);
  const { pathname } = useLocation();
  const { isMobile } = useWindowSize(767);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  useEffect(() => {
    if (isAuthorized) {
      if (prevPath !== '/login') dispatch(getAccountRequest());
    }
    if (_.isEmpty(currentSubscription)) {
      dispatch(getCurrentSubscription());
    }
  }, [pathname]);

  if (!isAuthorized) {
    Account.remove();
    Account.setLoginRedirect(window.location.pathname + window.location.search);
    return <Navigate to="/login" />;
  }

  return (
    <div className="auth_layout">
      <LayoutMenu />

      <div className="auth_layout_content">
        <Header />

        <main className="auth_layout_content_main">
          {isMobile && <SearchField />}

          <Outlet />
        </main>
      </div>

      <ChatBot />
    </div>
  );
};

Layout.propTypes = {
  prevPath: PropTypes.string.isRequired,
};

export default Layout;
