/* global google */
import React, {
  memo, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import Input from './Input';
import Utils from '../../../helpers/Utils';

const { REACT_APP_GOOGLE_AUTOCOMPLETE_KEY } = process.env;

const AUTOCOMPLETE_CONFIGS = {
  libraries: 'places',
  callback: 'initMap',
  key: REACT_APP_GOOGLE_AUTOCOMPLETE_KEY,
};

const AddScript = memo(() => (
  <div>
    <Helmet>
      <script
        src={
          `https://maps.googleapis.com/maps/api/js${Utils.objectToQueryString(AUTOCOMPLETE_CONFIGS)}`
        }
        async
        defer
      />
    </Helmet>
  </div>
));

const AddressAutocomplete = ({
  addressClick, onChangeText, value, type,
}) => {
  const googleAutocomplete = useRef();
  const timeout = useRef();
  const [options, setOptions] = useState([]);

  const onChange = (val) => {
    onChangeText(type ? 'address' : 'company.address', val);
    clearTimeout(timeout.current);

    if (val.trim()) {
      timeout.current = setTimeout(() => {
        googleAutocomplete.current.getPlacePredictions(
          { input: `${val}` },
          (results, status) => {
            if (status === 'OK') {
              setOptions(results);
            } else {
              setOptions([]);
            }
          },
        );
      }, 300);
    } else {
      setOptions([]);
    }
  };

  const getFullAddress = (placeId, address) => {
    onChangeText(type ? 'address' : 'company.address', address);

    setOptions([]);

    const placesService = new google.maps.places.PlacesService(document.createElement('div'));

    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        addressClick(place);
      } else {
        console.error('Failed to get place details:', status);
      }
    });
  };
  const initialize = () => {
    googleAutocomplete.current = new google.maps.places.AutocompleteService();
  };

  useLayoutEffect(() => {
    if (!googleAutocomplete.current) {
      window.initMap = initialize;
    }
  }, []);

  const closeHandler = (e) => {
    if (!e.target.closest('[id="autocomplete_close_id"]')) {
      setOptions([]);
    }
  };

  useEffect(() => {
    window.addEventListener('click', closeHandler, true);

    return () => {
      window.removeEventListener('click', closeHandler, true);
    };
  }, []);

  return (
    <div className="autocomplete_wrapper" id="autocomplete_close_id">
      <AddScript />

      <Input
        label="Address"
        value={value}
        onChangeText={(val) => onChange(val)}
        size="small"
        roundBorder
      />

      {!!options.length && (
      <div className="autocomplete_options_wrapper">
        {options.map((o) => (
          <div
            className="item"
            key={o.place_id}
            role="button"
            tabIndex="0"
            onClick={() => getFullAddress(o.place_id, o.description)}
          >
            {o.description}
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

export default AddressAutocomplete;
