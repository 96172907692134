import React, {
  useCallback, useRef, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import SaleOrdersFilters from '../../components/pages/Orders/saleOrders/SaleOrdersFilters';
import SaleOrdersTable from '../../components/pages/Orders/saleOrders/SaleOrdersTable';
import Wrapper from '../../components/Layout/Wrapper';
import { getOrdersRequest } from '../../store/actions/orders';
import Loader from '../../components/_common/Loader/Loader';


function ArchivedOrders() {
  const [loading, setLoading] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);

  const dispatch = useDispatch();
  const firstLoaded = useRef(true);
  const [searchParams] = useSearchParams({});

  useDeepCompareEffect(() => {
    handleGetOrders();
  }, [searchParams]);

  const handleGetOrders = useCallback(async () => {
    setLoading(true);
    await dispatch(getOrdersRequest({
      status: 'pending,shipped,partial_shipped,delivered,partial_delivered,draft',
      ...Object.fromEntries(searchParams),
      includes: 'customer',
      isArchive: true,
      type: 'sale',
    }));
    firstLoaded.current = false;
    setLoading(false);
  }, [searchParams]);

  return (
    <Wrapper
      title="Archived orders"
      className="customers_wrapper sales_orders_wrapper"
    >
      {!firstLoaded.current ? (
        <div className="customersList">
          <SaleOrdersFilters reorderCols={false} />
          <SaleOrdersTable
            checkedOrders={checkedOrders}
            setCheckedOrders={setCheckedOrders}
            loading={loading}
            onGetOrders={handleGetOrders}
            preview
            path="/orders/sales-order"
          />
        </div>
      ) : <Loader />}
    </Wrapper>
  );
}

export default ArchivedOrders;
