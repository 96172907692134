import { createReducer } from '@reduxjs/toolkit';
import { getTemplatesListRequest } from '../actions/templates';

const initialState = {
  templates: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTemplatesListRequest.fulfilled, (state, action) => {
      state.templates = action.payload.data.templates;
    });
});

export default reducer;
