import { createReducer } from '@reduxjs/toolkit';
import {
  createExportTemplate,
  getExportPriceList, getExportTemplateCustomFieldList, getExportWarehouseList,
  templateFieldsAction, updateExportTemplate,
} from '../actions/fileExport';

const initialState = {
  templateFields: [],
  priceLists: [],
  warehouses: [],
  templateList: [],
  customFields: [],
  templateListLoading: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(templateFieldsAction, (state, { payload }) => {
      state.templateFields = payload;
    })
    .addCase(getExportPriceList.fulfilled, (state, { payload }) => {
      state.priceLists = payload.priceLists;

      state.templateFields = state.templateFields.map((t) => (t.path === 'priceListIds'
        ? { ...t, childFields: payload.priceLists.map((p) => ({ label: p.name, path: `priceListIds.${p.id}` })) }
        : t));
    })

    .addCase(getExportWarehouseList.fulfilled, (state, { payload }) => {
      state.warehouses = payload.warehouses;

      state.templateFields = state.templateFields.map((t) => (t.path === 'warehouseIds'
        ? { ...t, childFields: payload.warehouses.map((p) => ({ label: p.title, path: `warehouseIds.${p.id}` })) }
        : t));
    })

    .addCase(getExportTemplateCustomFieldList.pending, (state, action) => {
      state.templateListLoading = !action.meta.arg.name.includes('CustomField');
    })

    .addCase(getExportTemplateCustomFieldList.fulfilled, (state, action) => {
      if (action.meta.arg.name.includes('CustomField')) {
        state.customFields = action.payload?.[action.meta.arg.name] || [];

        if (action.payload?.[action.meta.arg.name]?.length) {
          state.templateFields = [...state.templateFields, {
            label: 'Custom field',
            path: 'customField',
            childFields: action.payload?.[action.meta.arg.name]?.map((p) => (
              { label: p.label, path: `customField.${p.label}` })),
          }];
        }
      } else {
        state.templateList = action.payload?.[action.meta.arg.name] || [];

        state.templateListLoading = false;
      }
    })

    .addCase(getExportTemplateCustomFieldList.rejected, (state) => {
      state.templateListLoading = false;
    })

    .addCase(createExportTemplate.fulfilled, (state, action) => {
      state.templateList = action.payload?.[Object.keys(action.meta.arg)[0]];
    })

    .addCase(updateExportTemplate.fulfilled, (state, action) => {
      state.templateList = action.payload?.[Object.keys(action.meta.arg)[0]];
    });
});

export default reducer;
