import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import Wrapper from '../../../Layout/Wrapper';
import Input from '../../../_common/Form/Input';
import Table from '../../../_common/Tables/Table';
import UpdateModal from '../../../_common/Modal/UpdateModal';

const tableHeader = [
  {
    title: 'Number',
    path: 'number',
    isSelectable: true,
  },
  {
    title: 'Name',
    path: 'name',
    isSelectable: true,
  },
  {
    title: 'Slug',
    path: 'slug',
    isSelectable: true,
  },
];

const demoData = [
  {
    id: 1,
    number: '1',
    name: 'Shipping type',
    slug: 'Slug',
  },
  {
    id: 2,
    number: '1',
    name: 'Shipping type',
    slug: 'Slug',
  },
];

const modalFields = [
  {
    title: 'Title',
    path: 'title',
    placeholder: 'Enter title',
    required: true,
  },
  {
    title: 'Slug',
    path: 'slug',
    placeholder: 'Enter slug',
    required: true,
  },
];

function ShippingTypes() {
  const [checkedItems, checkItems] = useState([]);
  const [modalData, setModalData] = useState({});

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const { sort, sortBy } = sorting;

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, []);

  const saveShippingTypes = useCallback(() => {

  }, []);

  const check = (ids) => {
    console.log(ids, 111);
    checkItems(ids);
  };

  return (
    <Wrapper
      title="Shipping types"
      onBtnClick={() => setModalData({ title: '', slug: '' })}
      addBtn
      buttonTitle="New shipping type"
    >
      <div className="shipping_types">
        <Input
          onChangeText={() => {}}
          search
          placeholder="Search name"
          roundBorder
          size="small"
        />

        <Table
          sortBy={sortBy}
          onSortBy={onSortBy}
          sort={sort}
          checkedItems={checkedItems}
          onCheck={check}
          data={demoData}
          header={tableHeader}
          onEditClick={() => {
          }}
          onDeleteClick={() => {
          }}
        />
      </div>

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalFields}
        onClose={() => setModalData({})}
        onSave={saveShippingTypes}
        singleData={modalData}
        text="shipping type"
      />
    </Wrapper>
  );
}

export default ShippingTypes;
