import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ReactComponent as DHLIcon } from '../../../../assets/icons/apps/dhl.svg';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { appInstallFields } from '../../../../helpers/staticData';

function DHLInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const profile = useSelector((state) => state.users.profile);

  const [installModal, setInstallModal] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});

  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      loadingToggle(true);
      const { data: { companies } } = await Api.getCompanies();

      const company = companies.find(({ isDefault }) => isDefault);

      setCompanyInfo(company);
      loadingToggle(false);
    })();
  }, []);

  const install = useCallback(async (data) => {
    try {
      await Api.createShippingIntegration(app, data);

      toast.success('App has been successfully installed');
      navigate('/my-apps');
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="DHL Express"
      shopIcon={<DHLIcon />}
      description={'eSwap\'s Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
      loading={loading}
    >
      Integration info

      <UpdateModal
        isOpen={installModal}
        className="shipping_integration_modal"
        fields={appInstallFields['dhl-express']}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle="Install DHL Express"
        btnText="Install app"
        singleData={{
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          company: companyInfo.name,
          country: companyInfo.country?.code || '',
        }}
      />
    </AppInstallInfoWrapper>
  );
}

export default DHLInstall;
