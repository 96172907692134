import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import {
  createOrderCommentsAndHistoryRequest, deleteOrderCommentsAndHistoryRequest,
  getOrderCommentsAndHistoryRequest,
} from '../../../../store/actions/orders';
import Loader from '../../../_common/Loader/Loader';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check_blue.svg';

function CommentsAndHistory() {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(null);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const commentsAndHistory = useSelector((state) => state.orders.commentsAndHistory);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoadingSubmit(true);
    const { payload: { data } } = await dispatch(createOrderCommentsAndHistoryRequest({
      orderId,
      message,
    }));
    if (data.orderHistory) {
      await dispatch(getOrderCommentsAndHistoryRequest(orderId));
      setMessage('');
    } else {
      toast.error(data.message);
    }
    setLoadingSubmit(false);
  }, [message, orderId]);

  const handleDelete = useCallback(async (id) => {
    setLoadingDelete(id);
    await dispatch(deleteOrderCommentsAndHistoryRequest(id));
    await dispatch(getOrderCommentsAndHistoryRequest(orderId));
    setLoadingDelete(null);
    toast.success('Successfully deleted.');
  }, [orderId]);

  return (
    <div className="comments_and_history">
      <p className="title">Comments and history</p>
      <form data-scope="WRITE_ORDERS" onSubmit={handleSubmit}>
        <Input
          textarea
          placeholder="Your comment here..."
          onChange={(ev) => setMessage(ev.target.value)}
          value={message}
        />
        <div className="button_container">
          <Button reverseColor roundBorder type="submit" loading={loadingSubmit}>Add comment</Button>
        </div>
      </form>
      <div className="list">
        <div className={commentsAndHistory.length > 1 ? 'line' : null}>
          {_.isEmpty(commentsAndHistory) ? <div className="no_history">No history yet</div> : null}
          {!_.isEmpty(commentsAndHistory) ? (
            commentsAndHistory.map((c) => {
              if (c.type === 'manual') {
                return (
                  <div className="manual" key={c.id}>
                    <div className="check"><CheckIcon /></div>
                    <div className="content">
                      <div className="info">
                        <p className="user">{`${c.user.firstName || ''} ${c.user.lastName || ''}`}</p>
                        <p className="date">{moment(c.createdAt).format('DD/MM/YYYY hh:mm:ss')}</p>
                      </div>
                      <div className="actions">
                        <p className="comment">{c.message}</p>
                        <div className="delete" onClick={() => handleDelete(c.id)}>
                          {loadingDelete !== c.id ? <DeleteIcon />
                            : <div className="delete_loading"><Loader size={10} borderWidth={1} /></div>}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="auto" key={c.id}>
                  <div className="check"><CheckIcon /></div>
                  <div>
                    <p className="comment">{c.message}</p>
                    <p className="company">eSwap</p>
                    <p className="date">{moment(c.createdAt).format('DD/MM/YYYY hh:mm:ss')}</p>
                  </div>
                </div>
              );
            })
          ) : null}
        </div>
      </div>

    </div>
  );
}

export default CommentsAndHistory;
