import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import Loader from '../../../_common/Loader/Loader';
import Pagination from '../../../_common/Pagination/Pagination';
import noImage from '../../../../assets/icons/default.svg';
import { shopIcons } from '../../../../assets/shops';
import Api from '../../../../Api';
import Button from '../../../_common/Form/Button';
import { ReactComponent as ImportIcon } from '../../../../assets/icons/import.svg';

const columns = [
  {
    key: 'item', isEnabled: true,
  },
  {
    key: 'sku', isEnabled: true,
  },
  {
    key: 'shopType', isEnabled: true,
  },
  {
    key: 'category', isEnabled: true,
  },
  {
    key: 'totalStock', isEnabled: true,
  },
  {
    key: 'available', isEnabled: true,
  },
  {
    key: 'costPrice', isEnabled: true,
  },
  {
    key: 'createdAt', isEnabled: true,
  },
];

const defaultAttributes = {
  item: 'Item',
  sku: 'SKU',
  shopType: 'Shop',
  category: 'Category',
  totalStock: 'Stock',
  available: 'Available',
  costPrice: 'Cost price',
  createdAt: 'Import date',
};

const Products = ({ openExpiredModal }) => {
  const { integrationId } = useParams();
  const navigate = useNavigate();

  const myApp = useSelector((state) => state.app.myApp);

  const firstLoad = useRef(true);

  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({ totalPage: 1, currentPage: 1 });
  const { totalPage, currentPage } = pagination;

  const [importLoading, setImportLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await Api.getProducts({
        limit: 20,
        page: currentPage,
        includes: 'stock,warehouse,prices',
        shopId: integrationId,
      });

      setProducts(data.products);
      setPagination((prev) => ({ ...prev, totalPage: data.totalPages }));
      firstLoad.current = false;
      setLoading(false);
    })();
  }, [currentPage]);

  const importProducts = async () => {
    setImportLoading(true);

    if (myApp.isExpired) {
      openExpiredModal();
    } else {
      try {
        const { data: { message } } = await Api.integrationImport({
          shop: myApp.type,
          type: 'products',
          integrationId,
        });

        toast.success(message);
      } catch (e) {
        console.warn(e);
        toast.error(e.response.data?.message || 'Something went wrong');
      }
    }

    setImportLoading(false);
  };

  return (
    <div className="my_app_logs">
      {myApp.type !== 'b2b' && (
        <Button
          className="my_app_import_btn"
          size="small"
          btnType="cancel"
          roundBorder
          reverseColor
          iconLeft={<ImportIcon />}
          loading={importLoading}
          onClick={importProducts}
        >
          Import products
        </Button>
      )}

      {firstLoad.current
        ? <Loader />

        : (
          <div className="my_app_logs_table_wrapper">
            <DynamicTable
              loading={loading}
              data={products}
              keyExtractor={(item) => item.id}
              columns={columns}
              renderColumnHeader={(col) => (defaultAttributes[col.key] || col.key)}
              renderColumns={{
                item: ({ item }) => (
                  <div
                    className="product_item_wrapper"
                    role="button"
                    tabIndex="0"
                    onClick={() => navigate(item.type === 'variant'
                      ? `/products/${item.parentId}?productVariantId=${item.id}`
                      : `/products/${item.id}`)}
                  >
                    <div className="product_item_img">
                      <img
                        src={item.images[0]?.src || item.images[0]?.medium || noImage}
                        alt="img"
                      />
                    </div>

                    <div className="product_item_title">
                      {item.type === 'bundle'
                        && (
                          <div className="product_item_type">
                            {`Bundles ${item?.bundle?.length || 0}`}
                          </div>
                        )}

                      <p>{item.title}</p>
                    </div>
                  </div>
                ),

                shopType: ({ item }) => {
                  const Icon = shopIcons[item.shopType] || shopIcons.eswap;

                  return (
                    <div className="products_shop_link_icons_wrapper">
                      <div className="products_shop_icon_wrapper">
                        <Icon />
                      </div>
                    </div>

                  );
                },
                sku: ({ item }) => item.sku || '-',
                category: ({ item }) => item.category || '-',
                totalStock: ({ item }) => item.totalStock?.stock || '0',
                available: ({ item }) => item.totalStock?.available || '0',
                costPrice: ({ item }) => `$ ${item.costPrice || 0}`,
                createdAt: ({ item }) => moment(item.createdAt).format('MM/DD/YY'),
              }}
            />

            {+totalPage > 1 && (
              <div className="my_app_logs_pagination">
                <Pagination
                  onChangePage={(page) => setPagination((prev) => ({ ...prev, currentPage: page }))}
                  page={currentPage}
                  totalPages={+totalPage}
                />
              </div>
            )}
          </div>

        )}
    </div>
  );
};

export default Products;
