import React, { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../../Api';
import Input from '../../_common/Form/Input';
import Button from '../../_common/Form/Button';
import LogoutLayout from '../../Layout/LogoutLayout';

function InvitationVerify() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState({
    password: '',
    confirmPassword: '',
  });

  const onChange = useCallback((path, value) => {
    setPassword((prev) => ({
      ...prev,
      [path]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, []);

  const onSetPassword = async () => {
    setLoading(true);

    let hasError = false;

    if (!password.password.trim()) {
      setErrors((prev) => ({ ...prev, password: 'Field is required' }));
      hasError = true;
    }
    if (!password.confirmPassword.trim()) {
      setErrors((prev) => ({ ...prev, confirmPassword: 'Field is required' }));
      hasError = true;
    }
    if (password.password !== password.confirmPassword) {
      setErrors((prev) => ({ ...prev, password: 'Passwords are not the same' }));
      hasError = true;
    }

    if (!hasError) {
      const token = searchParams.get('token');

      try {
        await Api.crmActivateUser({
          password: password.password,
          token,
        });

        toast.success('Successfully created');
        navigate('/login');
      } catch (e) {
        toast.error(e.response.data.message);
      }
    }

    setLoading(false);
  };

  return (
    <LogoutLayout title="Confirm password">
      <div className="crm_invitation_verify_wrapper">
        <div className="crm_invitation_verify_inputs_block">
          <div className="crm_invitation_verify_input">
            <Input
              onChangeText={(value) => onChange('password', value)}
              value={password.password}
              size="small"
              roundBorder
              password
              label="Password"
              error={errors.password}
            />
          </div>

          <div className="crm_invitation_verify_input">
            <Input
              onChangeText={(value) => onChange('confirmPassword', value)}
              value={password.confirmPassword}
              size="small"
              roundBorder
              password
              label="Confirm password"
              error={errors.confirmPassword}
            />
          </div>

          <Button
            onClick={onSetPassword}
            size="small"
            roundBorder
            loading={loading}
          >
            Confirm password
          </Button>
        </div>
      </div>
    </LogoutLayout>
  );
}

export default InvitationVerify;
