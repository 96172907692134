import moment from 'moment';
import CurrencyFormatter from 'currency-formatter';

class Formatter {
  static price(value, code = 'USD') {
    if (!value) {
      return value;
    }
    return CurrencyFormatter.format(value, { code });
  }

  static date(date) {
    if (!date) {
      return null;
    }
    return moment(date).format('YYYY-DD-MM hh:mm:ss A');
  }

  static customerName() { // todo
    return '';
  }

  static phoneNumber(phone) {
    if (!phone) {
      return '';
    }

    return phone.startsWith('+') ? phone : `+${phone}`;
  }

  static url(url) {
    if (!url) {
      return '';
    }

    return (url.startsWith('http') ? url : `https://${url}`).toLowerCase();
  }
}

export default Formatter;
