import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { format } from 'currency-formatter';
import { orderReturnsListRequest } from '../../../../store/actions/orderReturns';
import Utils from '../../../../helpers/Utils';

function ReturnsList() {
  const dispatch = useDispatch();
  const { returnId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams({});
  const ref = useRef();

  const orderReturnsList = useSelector((state) => state.orderReturns.orderReturnsList);

  useEffect(() => {
    dispatch(orderReturnsListRequest({ ...Object.fromEntries(searchParams), includes: 'customer,order' }));
  }, [searchParams]);

  return (
    <div className="orders_list">
      <div className="top">
        <p className="title">Returns</p>
      </div>
      <div className="list_container">
        <table className="list">
          <tbody>
            {orderReturnsList.map((o) => (
              <tr
                key={o.id}
                className={returnId === o.id ? 'active' : ''}
                data-id={o.id}
                ref={ref}
              >
                <td
                  onClick={() => navigate(`/orders/returns/preview/${o.id}${location.search}`, { replace: true })}
                >
                  <div>
                    <p className="name">{`${o.customer.firstName || '-'} ${o.customer.lastName || '-'}`}</p>
                    <p className="number">{o.number}</p>
                  </div>
                </td>
                <td
                  className="info"
                  onClick={() => navigate(`/orders/returns/preview/${o.id}${location.search}`, { replace: true })}
                >
                  <div>
                    <p className="price">{format(o.totalPrice, { code: o.currencyCode })}</p>
                    <p className={`status ${o.status}`}>
                      <span className="status_dot" />
                      {Utils.formatStatus(o.status)}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReturnsList;
