import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Utils from '../../../../helpers/Utils';
import Select from '../../../_common/Form/Select';
import { getUsersListRequest } from '../../../../store/actions/users';
import { assignOrderToUserRequest } from '../../../../store/actions/orders';

function OrderInformation() {
  const [assignedId, setAssignedId] = useState(null);
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const users = useSelector((state) => state.users.usersList);
  const order = useSelector((state) => state.orders.order);

  useEffect(() => {
    if (!assignedId) {
      setAssignedId(order.assignedUserId);
    }
  }, [order, assignedId]);

  const handleGetUsers = useCallback(async (s) => {
    const { payload } = await dispatch(getUsersListRequest({ s }));
    return payload.users;
  }, []);

  const handleUserSelect = useCallback(async (val) => {
    setAssignedId(val);
    const { payload } = await dispatch(assignOrderToUserRequest({ assignedUserId: val, ids: [orderId] }));
    if (payload.data.status === 'error') {
      toast.error(payload.data.message);
    } else {
      toast.success('Successfully assigned.');
    }
  }, [orderId]);

  return (
    <div className="order_information_container">
      <Select
        label="Assign to"
        roundBorder
        placeholder="Select"
        isAsync
        defaultOptions
        loadOptions={handleGetUsers}
        valuePath="id"
        getOptionLabel={(o) => `${o.firstName || ''} ${o.lastName || ''}`}
        onChange={handleUserSelect}
        value={users.find((u) => u.id === assignedId)}
      />
      <div className="order_information">
        <div className="col">
          <p className="title">Contact information</p>
          <div className="item">
            <p className="value">{`${order.customer?.firstName || ''} ${order.customer?.lastName || ''}`}</p>
          </div>
          <div className="item">
            <p className="value">{order.customer?.phone || ''}</p>
          </div>
          <div className="item">
            <p className="value">{order.customer?.email || ''}</p>
          </div>
        </div>
        <div className="col">
          <p className="title">Shipping address</p>
          <div className="item">
            <p className="address">
              {Utils.formatAddress(
                [order.shippingAddress?.address1,
                  order.shippingAddress?.city,
                  order.shippingAddress?.region,
                  order.shippingAddress?.postalCode,
                  order.shippingAddress?.countryCode],
              )}
            </p>
          </div>
          <div className="item">
            <p
              className="value"
            >
              {`${order.shippingAddress?.firstName || ''} ${order.shippingAddress?.lastName || ''}`}
            </p>
          </div>
        </div>

        <div className="col">
          <p className="title">Billing address</p>
          <div className="item">
            <p className="address">
              {Utils.formatAddress(
                [order.billingAddress?.address1,
                  order.billingAddress?.city,
                  order.billingAddress?.region,
                  order.billingAddress?.postalCode,
                  order.billingAddress?.countryCode],
              )}
            </p>
          </div>
          <div className="item">
            <p
              className="value"
            >
              {`${order.billingAddress?.firstName || ''} ${order.billingAddress?.lastName || ''}`}
            </p>
          </div>
        </div>

      </div>
      {!_.isEmpty(order.tags) ? (
        <div className="order_information">
          <div className="col">
            <p className="title">Tags</p>
            <div className="tags">
              {order.tags.map((t) => (
                <div className="tag" key={t.id}>
                  <span style={{ background: t.color }} />
                  <p>{t.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
      {!_.isEmpty(order.notes) ? (
        <div className="order_information">
          <div className="col">
            <p className="title">Notes</p>
            {order?.notes.map((n) => (
              <p key={n.id}>{n.value}</p>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default OrderInformation;
