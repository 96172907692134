import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppInstallInfoWrapper from '../AppInstallInfoWrapper';
import UpdateModal from '../../../../_common/Modal/UpdateModal';
import { appInstallFields } from '../../../../../helpers/staticData';
import { ReactComponent as PrestaShopIcon } from '../../../../../assets/icons/apps/prestashop.svg';
import Api from '../../../../../Api';

function PrestaShopInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const { data: { integration: { id } } } = await Api.createIntegration(
        'cartrover',
        {
          integrationName: data.integrationName,
          type: 'PrestaShop',
          apiUser: data.apiUser,
          apiKey: data.apiKey,
          server_id: data.server_id,
          password: data.password,
        },
      );

      navigate(`/apps/integration/${id}`);
    } catch (err) {
      if (err.response.data?.errors) {
        throw err;
      } else {
        toast.error(err.response.data.message);
        setInstallModal(false);
      }
    }
  }, []);
  return (
    <AppInstallInfoWrapper
      shopName="Presta Shop"
      shopIcon={<PrestaShopIcon />}
      description="Presta Shop"
      onModalOpen={() => setInstallModal(true)}
    >
      Integration info

      <UpdateModal
        isOpen={installModal}
        fields={appInstallFields['presta-shop']}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default PrestaShopInstall;
