import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment/moment';
import _ from 'lodash';
import ChartWrapper from '../../../../_common/ChartWrapper/ChartWrapper';
import Api from '../../../../../Api';
import Table from '../../../../_common/Tables/Table';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Button from '../../../../_common/Form/Button';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/icons/no_match.svg';

const salesInfo = [
  {
    title: 'Sales:',
    path: 'sales',
    symbol: '$',
  },
  {
    title: 'Sales vs Time last year:',
    path: 'lastSales',
    symbol: '$',
  },
  {
    title: 'Average order value:',
    path: 'averageOrderValue',
    symbol: '$',
  },
  {
    title: 'Orders:',
    path: 'orders',
  },
  {
    title: 'New customers:',
    path: 'newCustomerCount',
  },
  {
    title: 'Returning customers:',
    path: 'returningCustomerCount',
  },
];

const topProductsByRevenueTableHeader = [
  {
    title: 'Product',
    path: 'productName',
  },
  {
    title: 'Revenue',
    path: 'revenue',
  },
];

const salesByCategoryTableHeader = [
  {
    title: 'Name',
    path: 'categoryName',
  },
  {
    title: 'Qty',
    path: 'qty',
  },
  {
    title: 'Share',
    path: 'orderPercent',
  },
];

const salesByStoreTableHeader = [
  {
    title: 'Store',
    path: 'shopType',
  },
  {
    title: 'Revenue',
    path: 'revenue',
  },
];

const today = moment().format('YYYY-MM-DD');
const lastTwoWeek = moment(new Date()).subtract(14, 'days').format('YYYY-MM-DD');

const SalesTrends = ({ topChartTemplate }) => {
  const [date, setDate] = useQueryParams({
    startDate: withDefault(StringParam, lastTwoWeek),
    endDate: withDefault(StringParam, today, true),
  }, { updateType: 'replaceIn' });

  const { startDate, endDate } = date;

  const [salesChart, setSalesChart] = useState(topChartTemplate);
  const [topProductsByRevenue, setTopProductsByRevenue] = useState([]);
  const [salesByCategory, setSalesByCategory] = useState([]);
  const [salesByStore, setSalesByStore] = useState([]);
  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      loadingToggle(true);

      const [
        { data: { sales, salesInfo: info } },
        { data: { products } },
        { data: { orders: salesByCategoryData } },
        { data: { stores: salesByStoreData } },
      ] = await Promise.all([
        Api.getReportsSalesTrends({ startDate, endDate: endDate || startDate }),
        Api.getReportsTopProductsByRevenue({ startDate, endDate: endDate || startDate }),
        Api.getReportsSalesByCategory({ startDate, endDate: endDate || startDate }),
        Api.getReportsSalesStore({ startDate, endDate: endDate || startDate }),
      ]);

      setTopProductsByRevenue(products.map((c) => ({
        ...c,
        revenue: `$${c.revenue}`,
      })));

      setSalesByCategory(salesByCategoryData.map((s) => ({ ...s, orderPercent: `${s.orderPercent}%` })));
      setSalesByStore(salesByStoreData.map((c) => ({
        ...c,
        shopType: _.upperFirst(c.shopType),
        revenue: `$${c.totalPrice}`,
      })));

      setSalesChart((prev) => ({
        ...prev,
        // legend: {
        //   show: false,
        // },
        xaxis: {
          ...prev.xaxis,
          categories: sales.map((o) => o.date),
        },
        series: [
          {
            name: 'Item sold',
            data: sales.map(({ soldItems }) => soldItems),
          },
          {
            name: 'Sales',
            data: sales.map(({ revenue }) => revenue),
          },
        ],
        info,
      }));

      loadingToggle(false);
    })();
  }, [date]);

  const changeDate = (d) => {
    setDate({ startDate: d[0], endDate: d[1] || '' });
  };

  return (
    <div className="sales_overview_wrapper operations">
      <div className="sales_overview_date_filter">
        <RangeDatePicker currentDate={date} onChangeDate={changeDate} />
      </div>

      <ChartWrapper height={480} title="Sales trends" loading={loading}>
        <div className="chart_info">
          {salesInfo.map(({ title, path, symbol = '' }) => (
            <div key={title}>{`${title} ${symbol}${salesChart.info[path]}`}</div>
          ))}
        </div>

        <Chart
          height={350}
          width="100%"
          type="bar"
          className="apx-chart"
          series={salesChart.series}
          options={salesChart}
        />
      </ChartWrapper>

      <div className="overview_bottom_block">
        <ChartWrapper height={330} title="Top products by revenue" loading={loading}>
          <Table
            data={topProductsByRevenue}
            header={topProductsByRevenueTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Products not found</h1>
              </>
            )}
          />

          {!!topProductsByRevenue.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Sales by category" loading={loading}>
          <Table
            data={salesByCategory}
            header={salesByCategoryTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Sales not found</h1>
              </>
            )}
          />

          {!!salesByCategory.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Sales by store" loading={loading}>
          <Table
            data={salesByStore}
            header={salesByStoreTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Sales not found</h1>
              </>
            )}
          />

          {!!salesByStore.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>
      </div>
    </div>
  );
};

export default SalesTrends;
