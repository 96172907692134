import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '../../../assets/icons/cancel.svg';

const Modal = memo(({
  id, isOpen, onClose, children, className, zIndex, backdropBG, wrapperClassName, overflowY,
}) => {
  const [showModal, modalShowToggle] = useState(false);

  const handleEsc = useCallback((event) => {
    if (event.keyCode === 27) onClose();
  }, []);

  const closeModal = () => {
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('width');
    document.body.ontouchmove = () => true;
    window.removeEventListener('keydown', handleEsc);
  };

  useEffect(() => {
    setTimeout(() => modalShowToggle(isOpen));

    if (isOpen) {
      document.body.style.width = `${document.body.getBoundingClientRect().width}px`;
      document.body.style.overflowY = 'hidden';
      document.body.ontouchmove = () => false;
      window.addEventListener('keydown', handleEsc);
    } else {
      closeModal();
    }
  }, [isOpen]);

  useEffect(() => () => {
    closeModal();
  }, []);

  return isOpen && createPortal(
    <div
      id={id}
      className={classNames('modal_container', wrapperClassName)}
      style={{ zIndex }}
    >
      <div
        role="button"
        tabIndex="0"
        style={{ background: backdropBG }}
        className="backdrop"
        onClick={onClose}
      />

      <div className={classNames('modal_wrapper', className, { show_modal: showModal })}>
        {onClose && (
          <CloseIcon
            className="close"
            onClick={onClose}
          />
        )}

        <div className={classNames('modal_content', { overflowY })}>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('root'),
  );
});

Modal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  backdropBG: PropTypes.string,
  zIndex: PropTypes.number,
  overflowY: PropTypes.bool,
};

Modal.defaultProps = {
  id: 'modal',
  wrapperClassName: '',
  className: '',
  backdropBG: 'rgba(0, 0, 0, 0.5)',
  zIndex: 9999,
  onClose: null,
  overflowY: true,
};

export default Modal;
