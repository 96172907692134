import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Table from '../../../_common/Tables/Table';
import { fileMappingOption } from '../../../../helpers/staticData';

const FTPConfiguration = ({
  type, data, changeData, orderList,
}) => {
  const [tableHeader, setTableHeader] = useState(
    Object.keys(orderList[0].row).map((key) => {
      const value = Object.entries(data).find((v) => v[0] === key)?.[1] || '';

      const options = fileMappingOption[type].options.map((o) => (_.map(data, (v) => v).includes(o.value)
        ? { ...o, isDisabled: true }
        : o));

      return {
        path: key, title: key, headerType: 'select', isClearable: true, isSearchable: true, value, options,
      };
    }),
  );

  const selectOrderType = useCallback((path, value) => {
    const newOptionsData = {
      ...data,
      [path]: value,
    };

    changeData((prev) => ({
      ...prev,
      configs: newOptionsData,
    }));

    setTableHeader((prevState) => {
      const options = fileMappingOption[type].options.map((o) => (_.map(newOptionsData, (v) => v).includes(o.value)
        ? { ...o, isDisabled: true }
        : o));

      return prevState.map((p) => (p.path === path ? { ...p, value, options } : { ...p, options }));
    });
  }, [data]);

  return (
    <div className="integration_ftp_configuration_wrapper">
      <Table
        data={orderList.map((l) => l.row)}
        header={tableHeader}
        onHeaderChange={selectOrderType}
      />
    </div>
  );
};

FTPConfiguration.propTypes = {

};

export default FTPConfiguration;
