import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../_common/Loader/Loader';
import { ReactComponent as DragIcon } from '../../../../assets/icons/dashboard_drag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/dashboard_close.svg';
import { getDashboardPurchaseOrdersRequest } from '../../../../store/actions/dashboard';

const ellipseColors = ['#F67932', '#00A84D', '#003AD2', '#E80000', '#8676FF'];

function DashboardPurchaseOrders({
  data, disable, innerRef, onDelete, startDate, endDate,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const purchaseOrders = useSelector((state) => state.dashboard.purchaseOrders);
  const receivedOrders = useSelector((state) => state.dashboard.purchaseReceivedOrders);
  const totalCost = useSelector((state) => state.dashboard.puchaseTotalCost);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await dispatch(getDashboardPurchaseOrdersRequest({ startDate, endDate }));

      setLoading(false);
    })();
  }, [startDate, endDate]);

  // const receivedOrders = purchaseOrders?.[0]?.count || 0;
  // const totalCost = purchaseOrders?.[0]?.amount || 0;

  return (
    <div
      ref={(ref) => {
        innerRef[data?.key] = ref || innerRef[data?.key];
      }}
      className="grid-stack-item"
      gs-max-w="12"
      gs-min-w="3"
      gs-max-h="3"
      gs-min-h="2"
      gs-id={data?.key}
      gs-w={data?.value?.position?.w}
      gs-h={data?.value?.position?.h}
      gs-x={data?.value?.position?.x}
      gs-y={data?.value?.position?.y}
      style={{ display: data?.show === false ? 'none' : 'block' }}
    >
      <div className="grid-stack-item-content">
        <div className="chart_wrapper" style={{ cursor: disable ? 'grab' : 'default' }}>
          {loading ? <Loader /> : (
            <div className="dashboard_purchase_orders">
              <h3 className="dashboard_purchase_orders_title">Purchase orders</h3>

              {disable && (
                <div className="drag_icons">
                  <DragIcon />

                  <CloseIcon onClick={onDelete} />
                </div>
              )}

              {!purchaseOrders.length
                ? <div className="dashboard_purchase_orders_no_data">No Data Found</div>
                : (
                  <>
                    <div className="dashboard_purchase_orders_top">
                      <div className="dashboard_purchase_orders_top_single">
                        <p className="dashboard_purchase_orders_single_left">Received orders</p>
                        <p className="dashboard_purchase_orders_single_right">{receivedOrders}</p>
                      </div>

                      <div className="dashboard_purchase_orders_top_single">
                        <p className="dashboard_purchase_orders_single_left">Total cost (USD)</p>
                        <p className="dashboard_purchase_orders_single_right">{`$${totalCost}`}</p>
                      </div>
                    </div>

                    <div className="dashboard_purchase_orders_bottom">
                      <h3 className="dashboard_purchase_orders_title">Incoming purchase orders</h3>

                      <div className="dashboard_purchase_orders_bottom_single">
                        {purchaseOrders.map((order) => (
                          <div className="dashboard_purchase_orders_bottom_single_order" key={order.id}>
                            <div className="dashboard_purchase_orders_bottom_single_left">
                              <div
                                className="dashboard_purchase_orders_bottom_single_left_color"
                                style={{ borderColor: ellipseColors[0] }}
                              />

                              <div className="dashboard_purchase_orders_bottom_single_left_info">
                                <p
                                  className="dashboard_purchase_orders_bottom_single_left_number"
                                  onClick={() => { navigate(`/stocks/purchase-orders/preview/${order.id}`); }}
                                >
                                  {order.orderId}
                                </p>

                                <p>{order.customer || 'Ernest'}</p>
                              </div>
                            </div>

                            <p className="dashboard_purchase_orders_bottom_single_price">{`$${order.totalPrice}`}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardPurchaseOrders;
