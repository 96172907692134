import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Wrapper from '../../Layout/Wrapper';
import Api from '../../../Api';
import Loader from '../../_common/Loader/Loader';
import Table from '../../_common/Tables/Table';
import Button from '../../_common/Form/Button';
import { ReactComponent as RolesNotFoundIcon } from '../../../assets/icons/notFound/roles_not_found.svg';

const CrmRoles = ({ admin }) => {
  const navigate = useNavigate();
  const profile = useSelector((store) => store.users.profile);

  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = profile.isAdmin
        ? admin
          ? await Api.getAdminRolesList()
          : await Api.getDefaultRoles()
        : await Api.getRolesList();

      setRoles(profile.isAdmin
        ? admin
          ? data.roles
          : data.defaultRoles
        : data.roles);
      setLoading(false);
    })();
  }, [admin]);

  const deleteRole = useCallback(async (id) => {
    setDeleteLoading(true);
    try {
      if (profile.isAdmin) {
        if (admin) {
          await Api.deleteAdminRole(id);
        } else {
          await Api.deleteDefaultRole(id);
        }
      } else {
        await Api.deleteRole(id);
      }

      setRoles((prev) => prev.filter((r) => r.id !== id));
      toast.success('Successfully deleted');
    } catch (e) {
      toast.error(e.response.data.message);
    }

    setDeleteLoading(false);
  }, [admin]);

  const tableHeader = useMemo(() => (
    [
      {
        title: 'Role name',
        path: 'name',
        navigate: (id) => navigate(`/crm/${admin ? 'admin-roles' : 'roles'}/${id}`),
      },
      {
        title: 'Description',
        path: 'description',
      },
    ]
  ), []);

  return (
    <Wrapper
      title="CRM Roles"
      onBtnClick={roles.length ? () => { navigate(`/crm/${admin ? 'admin-roles' : 'roles'}/add`); } : null}
      addBtn
      buttonTitle="New role"
    >
      <div className="roles_wrapper">
        {loading
          ? <Loader />

          : (
            <div className="roles_table_wrapper">
              <Table
                data={roles}
                header={tableHeader}
                deleteModalText="role"
                onDeleteClick={deleteRole}
                deleteLoading={deleteLoading}
                onEditClick={(id) => {
                  navigate(`/crm/${admin ? 'admin-roles' : 'roles'}/${id}`);
                }}
                notFound={(
                  <>
                    <RolesNotFoundIcon />

                    <h1>Roles not found</h1>

                    <Button
                      title="Create role"
                      href={`/crm/${admin ? 'admin-roles' : 'roles'}/add`}
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />
            </div>
          )}
      </div>
    </Wrapper>
  );
};

export default CrmRoles;
