import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';

const Dashboard = () => {
  const { integrationId } = useParams();

  const [dashboardStatistics, setDashboardStatistics] = useState({});
  const { productCount, orderCount, customerCount } = dashboardStatistics;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [product, order, customer] = await Promise.all([
        Api.getProducts({ page: 1, limit: 1, shopId: integrationId }),
        Api.getOrders({ page: 1, limit: 1, shopId: integrationId }),
        Api.getCustomers({ page: 1, limit: 1, shopId: integrationId }),
      ]);

      setDashboardStatistics({
        productCount: product.data.total,
        orderCount: order.data.total,
        customerCount: customer.data.total,
      });
      setLoading(false);
    })();
  }, []);

  return (
    loading
      ? <Loader />

      : (
        <div className="my_app_dashboard">
          <div className="my_app_dashboard_item">
            <div>
              <p style={{
                color: '#FFBD00',
              }}
              >
                {productCount}
              </p>

              <p>products imported to eSwap platform</p>
            </div>
          </div>

          <div className="my_app_dashboard_item">
            <div>
              <p
                style={{
                  color: '#1472FF',
                }}
              >
                {orderCount}
              </p>

              <p>orders imported successfully</p>
            </div>
          </div>

          <div className="my_app_dashboard_item">
            <div>
              <p style={{
                color: '#109B48',
              }}
              >
                {customerCount}
              </p>

              <p>customers imported successfully</p>
            </div>
          </div>
        </div>
      )
  );
};

export default Dashboard;
