import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Table from '../../_common/Tables/Table';
import Button from '../../_common/Form/Button';
import { cancelImportFile } from '../../../store/actions/fileImport';
import Api from '../../../Api';
import { getSetupRequest } from '../../../store/actions/notifications';
import { fileMappingOption } from '../../../helpers/staticData';

const ImportCsvFile = () => {
  const navigate = useNavigate();
  const { type } = useParams();

  const dispatch = useDispatch();
  const file = useSelector((store) => store.fileImport.file);
  const fileTableData = useSelector((store) => store.fileImport.fileTableData);
  const fields = useSelector((store) => store.fileImport.fields);
  const existProductType = useSelector((store) => store.fileImport.existProductType);
  const { hasProducts } = useSelector((state) => state.notifications.setup);

  const [ordersMapping, setOrdersMapping] = useState({});
  const [mappingOptions, setMappingOptions] = useState(fileMappingOption[type].options);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!file || !fileTableData) {
      navigate(-1);
    }
  }, [file, fileTableData]);

  useEffect(() => () => {
    dispatch(cancelImportFile());
  }, []);

  const selectOrderType = useCallback((path, value) => {
    setOrdersMapping((prev) => ({ ...prev, [path]: value }));

    const options = fileMappingOption[type].options.map((o) => (_.map(
      { ...ordersMapping, [path]: value },
      (v) => v,
    ).includes(o.value)
      ? { ...o, isDisabled: true }
      : o));

    setMappingOptions(options);
  }, [ordersMapping]);

  const onCancelClick = () => {
    dispatch(cancelImportFile());
  };

  const onUploadFile = async () => {
    let hasError;

    fileMappingOption[type].requiredFields.forEach((f) => {
      if (!Object.values(ordersMapping).includes(f)) {
        hasError = true;
      }
    });

    if (hasError) {
      toast.error(fileMappingOption[type].errorMessage);
      return;
    }
    setLoading(true);
    const newOrdersMapping = {};
    _.forEach(ordersMapping, (value, key) => {
      newOrdersMapping[value] = key;
    });
    const requestData = {
      mapping: newOrdersMapping,
      file,
      action: type === 'products' ? existProductType : undefined,
    };

    try {
      await Api.importCSVFile(type, requestData);

      if (!hasProducts && type === 'products') {
        await dispatch(getSetupRequest());
      }

      onCancelClick();
      toast.success('Import successfully started import.');
    } catch (e) {
      toast.error('Something went wrong. Please try again later.');
    }

    setLoading(false);
  };

  return ((!file || !fileTableData) ? <div />
    : (
      <div className="import-csv_file_wrapper">
        <div className="import_modal_buttons_wrapper">
          <Button
            size="small"
            roundBorder
            btnType="cancel"
            reverseColor
            onClick={onCancelClick}
            title="Cancel"
            className="import_modal_button"
          />

          <Button
            size="small"
            roundBorder
            loading={loading}
            title="Upload"
            onClick={onUploadFile}
            className="import_modal_button"
          />
        </div>

        <div className="import-csv_file_table_wrapper">
          <Table
            data={fileTableData}
            header={fields.map((f) => ({
              path: f,
              title: f,
              headerType: 'select',
              isClearable: true,
              isSearchable: true,
              value: ordersMapping[f],
              options: mappingOptions,
            }))}
            onHeaderChange={selectOrderType}
          />
        </div>

      </div>
    )
  );
};

export default ImportCsvFile;
