import { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../../../Api';

const BonanzaIntegration = () => {
  const { app } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const {
          integrationId, integrationName, authenticationURL, hardExpirationTime, authToken,
        } = JSON.parse(localStorage.getItem(`${app}IntegrationInfo`)) || {};

        try {
          const sendingData = {
            storeId: `${integrationName}_${+new Date()}`,
            integrationName,
            authenticationURL,
            hardExpirationTime,
            token: authToken,
          };

          if (integrationId) sendingData.integrationId = integrationId;

          const { data: { integration: { id } } } = await Api.createIntegration(app, sendingData);

          navigate(integrationId ? `/my-apps/${id}/dashboard` : `/apps/integration/${id}`, {replace: true});
        } catch (err) {
          toast.error(err.response.data.message);
          navigate('/apps/list/all', { replace: true });
        }
      } catch (e) {
        navigate('/404', { replace: true });
      }

      localStorage.removeItem(`${app}IntegrationInfo`);
    })();
  }, []);

  return null;
};

export default BonanzaIntegration;
