import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';
import Input from '../components/_common/Form/Input';
import Button from '../components/_common/Form/Button';
import LogoutLayout from '../components/Layout/LogoutLayout';
import GoogleButton from '../components/_common/Form/GoogleButton';
import Checkbox from '../components/_common/Form/Checkbox';
import Utils from '../helpers/Utils';
import { loginRequest } from '../store/actions/users';
import Account from '../helpers/Account';

const fields = [
  {
    label: 'Email',
    path: 'email',
    placeholder: 'Enter your email',
    type: 'input',
  },
  {
    label: 'Password',
    path: 'password',
    placeholder: 'Enter your password',
    type: 'password',
  },
];

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [queryParams] = useQueryParams({
    integrationHash: '',
    email: '',
  });

  const [data, setData] = useState({
    email: queryParams.email || '',
    password: '',
    remember: false,
  });

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const changeData = useCallback((path, value) => {
    setData((prev) => ({ ...prev, [path]: value }));

    setErrors((prev) => {
      if (typeof prev.email === 'boolean') {
        return {};
      }

      return ({ ...prev, [path]: '' });
    });
  }, []);

  const login = useCallback(async (e) => {
    e.preventDefault();

    let hasError = false;
    setLoading(true);

    fields.forEach(({ path }) => {
      if (!data[path].trim()) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));
        hasError = true;
      } else if (path === 'email' && !Utils.isEmail(data[path])) {
        setErrors((prev) => ({ ...prev, [path]: 'Enter valid email' }));
        hasError = true;
      }
    });

    if (hasError) {
      toast.error('Please correct these fields');
    } else {
      data.integrationHash = queryParams.integrationHash;
      const { payload } = await dispatch(loginRequest(data));

      if (payload.status === 'error') {
        toast.error(payload.message);

        setErrors({ email: true, password: true });
      } else {
        navigate(Account.getLoginRedirect());
        Account.removeLoginRedirect();
        Account.removeAuthData();
      }
    }

    setLoading(false);
  }, [data, queryParams]);

  return (
    <LogoutLayout title="Log in">
      <div className="login_page">
        <form onSubmit={login}>
          {fields.map(({
            type, label, path, placeholder,
          }) => (
            <div key={label}>
              <Input
                label={label}
                placeholder={placeholder}
                password={type === 'password'}
                value={data[path]}
                error={errors[path]}
                onChangeText={(value) => changeData(path, value)}
                size="small"
                roundBorder
              />
            </div>
          ))}

          <Checkbox
            label="Remember me"
            checked={data.remember}
            onChange={(check) => changeData('remember', check)}
          />

          {!queryParams.integrationHash ? (
            <div className="login_page_forgot_wrapper">
              <Button
                roundBorder
                color="#1472FF"
                href="/reset-password"
                btnType="transparent"
                size="small"
              >
                Forgot password?
              </Button>
            </div>
          ) : null}

          <Button
            roundBorder
            size="small"
            type="submit"
            loading={loading}
          >
            Log in
          </Button>
        </form>

        <div className="login_page_or"><p>or</p></div>

        {!queryParams.integrationHash ? (
          <GoogleButton />
        ) : null}

        <div className="login_page_login_btn_wrapper">
          Don’t have an account?

          <Button
            roundBorder
            color="#1472FF"
            href="/signup"
            btnType="transparent"
            size="small"
          >
            Sign up
          </Button>
        </div>

      </div>
    </LogoutLayout>
  );
};

export default Login;
