import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import Wrapper from '../../components/Layout/Wrapper';
import Loader from '../../components/_common/Loader/Loader';
import { getAllPackagesRequest } from '../../store/actions/packages';
import Utils from '../../helpers/Utils';
import ReceivesFilters from '../../components/pages/Orders/receives/ReceivesFilters';
import ReceivesTable from '../../components/pages/Orders/receives/ReceivesTable';
import { useDeepCompareEffect } from "react-use";

const defaultFiltersParams = withDefault(ArrayParam, []);

function Receives() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const firstLoaded = useRef(true);

  const [queryData] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    orderId: defaultFiltersParams,
    status: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  useDeepCompareEffect(() => {
    handleGetInvoices();
  }, [queryData]);

  const handleGetInvoices = useCallback(async () => {
    setLoading(true);
    await dispatch(getAllPackagesRequest({
      ...Utils.arrayToString(queryData),
      includes: 'order',
      type: 'receive',
    }));
    firstLoaded.current = false;
    setLoading(false);
  }, [queryData]);

  return (
    <Wrapper
      title="Receives"
      className="customers_wrapper sales_orders_wrapper"
    >
      {!firstLoaded.current ? (
        <div className="customersList">
          <ReceivesFilters />
          <ReceivesTable loading={loading} />
        </div>
      ) : <Loader />}
    </Wrapper>
  );
}

export default Receives;
