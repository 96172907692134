import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Table from '../../../../_common/Tables/Table';
import {
  createPaymentMethodRequest,
  deletePaymentMethodRequest, getPaymentMethodsRequest,
  updatePaymentMethodRequest,
} from '../../../../../store/actions/settings';
import UpdateModal from '../../../../_common/Modal/UpdateModal';
import Button from '../../../../_common/Form/Button';
import Loader from '../../../../_common/Loader/Loader';

const methodDefaultData = {
  title: '',
  isDefault: false,
};

function PaymentMethods() {
  const dispatch = useDispatch();

  const [methods, setMethods] = useState([1]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [sorting, setSorting] = useState({
    sortBy: 'title',
    sort: 'asc',
  });

  const { sortBy, sort } = sorting;

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getPaymentMethodsRequest());

      setMethods(payload.paymentMethods);
      setLoading(false);
    })();
  }, []);

  const openModal = useCallback((id = null) => {
    setModalData(id ? methods.find((t) => +t.id === +id) : methodDefaultData);
  }, [methods]);

  const onSave = useCallback(async (newData) => {
    try {
      const { payload } = newData.id
        ? await dispatch(updatePaymentMethodRequest(newData))

        : await dispatch(createPaymentMethodRequest(newData));

      setMethods(payload.paymentMethods);
      closeModal();
    } catch (err) {
      throw err;
    }
  }, []);

  const onDelete = useCallback(async (id) => {
    setModalLoading(true);

    await dispatch(deletePaymentMethodRequest(id));
    setMethods((prev) => prev.filter((t) => +t.id !== +id));

    setModalLoading(false);
  }, [modalData]);

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  useEffect(() => {
    setMethods((prev) => _.orderBy(prev, sorting.sortBy, sorting.sort));
  }, [sorting, methods.length]);

  const closeModal = useCallback(() => {
    setModalData({});
  }, []);

  const tableHeader = useMemo(() => (
    [
      {
        title: 'Title',
        path: 'title',
        isSelectable: true,
        required: true,
        navigate: (id) => openModal(id),
      },
    ]
  ), [openModal]);

  return (
    loading ? <Loader /> : (
      <div className="general_settings">
        <div className="general_settings_content">
          <Button
            onClick={() => openModal()}
            className="new_payment_button"
            size="small"
            roundBorder
            addBtn
          >
            New payment method
          </Button>

          <div className="paymentMethods_tableBlock">
            <Table
              data={methods}
              header={tableHeader}
              onEditClick={openModal}
              onDeleteClick={onDelete}
              onSortBy={onSort}
              sortBy={sortBy}
              sort={sort}
              deleteModalText="payment method"
              deleteLoading={modalLoading}
            />
          </div>

          <UpdateModal
            isOpen={!_.isEmpty(modalData)}
            fields={tableHeader}
            onClose={closeModal}
            onSave={onSave}
            singleData={modalData}
            text="payment method"
            withDefaultSwitcher
          />
        </div>
      </div>
    )
  );
}

export default PaymentMethods;
