import React from 'react';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import Filters from '../../Products/Filters';
import Api from '../../../../Api';
import Utils from '../../../../helpers/Utils';

const DelimitedArrayParamComma = Utils.customDelimitedArrayParam('~');

const defaultFilterData = [
  { label: 'Status', valuePath: 'status' },
  { label: 'Receive status', valuePath: 'receiveStatus' },
  { label: 'Customer', valuePath: 'customerId' },
];

const filterOptions = {
  status: {
    label: 'Status',
    path: 'status',
    valuePath: 'status',
    staticData: [
      {
        label: 'Approved',
        id: 'approved',
      },
      {
        label: 'Declined',
        id: 'declined',
      },
    ],
  },
  receiveStatus: {
    label: 'Receive Status',
    path: 'receiveStatus',
    valuePath: 'receiveStatus',
    staticData: [
      {
        label: 'Received',
        id: 'received',
      },
      {
        label: 'Partially received',
        id: 'partial_received',
      }, {
        label: 'Not received',
        id: 'pending',
      },
    ],
  },
  customerId: {
    label: 'Customers',
    path: 'customers',
    valuePath: 'customerId',
  },
};

const defaultFiltersParams = withDefault(DelimitedArrayParamComma, []);

function ReturnsFilters() {
  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    status: defaultFiltersParams,
    receiveStatus: defaultFiltersParams,
    customerId: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const loadOptions = async (filter) => {
    if (filter.staticData) {
      return { data: filter.staticData };
    }

    let data;
    let totalPages;

    if (filter.path === 'customers') {
      const { data: { customers: list, totalPages: p } } = await Api.getCustomers({ isCustomer: true });

      data = list.map((c) => ({ id: c.id, label: `${c.firstName || '-'} ${c.lastName || '-'}` }));
      totalPages = p;
    }

    return { data, totalPages };
  };

  return (
    <Filters
      queryData={queryData}
      setQuery={setQuery}
      defaultFilterData={defaultFilterData}
      filterOptions={filterOptions}
      loadOptions={loadOptions}
    />
  );
}

export default ReturnsFilters;
