import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'currency-formatter';
import _ from 'lodash';
import {
  useLocation, useNavigate, useParams,
} from 'react-router-dom';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import Button from '../../../_common/Form/Button';
import { getOrdersRequest } from '../../../../store/actions/orders';
import { getCountriesRequest } from '../../../../store/actions/app';
import Utils from '../../../../helpers/Utils';


const DelimitedArrayParamComma = Utils.customDelimitedArrayParam('~');
const defaultFiltersParams = withDefault(DelimitedArrayParamComma, []);

function OrdersList() {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();

  const orders = useSelector((state) => state.orders.orders);

  const type = useMemo(() => (location.pathname.includes('/purchase') ? 'purchase' : 'sale'), [location]);

  const [queryData] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    status: defaultFiltersParams,
    warehouseId: defaultFiltersParams,
    ids: defaultFiltersParams,
    assignedUserId: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  useEffect(() => {
    // eslint-disable-next-line camelcase
    const { service_id, ...rest } = Utils.arrayToString(queryData);
    dispatch(getOrdersRequest({
      status: 'draft,pending,shipped,partial_shipped,delivered,partial_delivered',
      ...rest,
      includes: 'customer',
      isArchive: false,
      type,
    }));
  }, [queryData, type, location.state]);

  useEffect(() => {
    dispatch(getCountriesRequest());
  }, []);

  useEffect(() => {
    const el = ref.current;
  }, [orderId, ref.current]);
  const isPurchase = type === 'purchase';
  return (
    <div className="orders_list">
      <div className="top">
        <p className="title">{`${_.startCase(type)}s orders`}</p>
        <Button
          roundBorder
          addBtn
          data-scope={isPurchase ? 'WRITE_PURCHASE_ORDERS' : 'WRITE_ORDERS'}
          onClick={() => navigate(isPurchase
            ? '/stocks/purchase-orders/create' : '/orders/sales-order/create', { replace: true })}
        >
          Add new
        </Button>
      </div>
      <div className="list_container">
        <table className="list">
          <tbody>
          {orders.map((o) => (
            <tr
              key={o.id}
              className={orderId === o.id ? 'active' : ''}
              data-id={o.id}
              ref={ref}
              onClick={() => navigate(
                type === 'purchase'
                  ? `/stocks/purchase-orders/preview/${o.id}${location.search}`
                  : `/orders/sales-order/preview/${o.id}${location.search}`,
                { replace: true },
              )}
            >
              <td>
                <div>
                  <p className="name">{`${o.customer?.firstName || '-'} ${o.customer?.lastName || '-'}`}</p>
                  <p className="number">{o.number}</p>
                </div>
              </td>
              <td className="info">
                <div>
                  <p className="price">{format(o.totalPrice, { code: o.currencyCode })}</p>
                  <div className={`status ${o.status}`}>
                    <span className="status_dot" />
                    {type === 'purchase'
                      ? Utils.formatPurchaseStatus(o.status)
                      : Utils.formatStatus(o.status)}
                  </div>
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrdersList;
