import React from 'react';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/integration_check.svg';

function SuccessfullyIntegrated() {
  return (
    <div className="successfully_integrated_wrp">
      <div className="successfully_integrated_info">
        <div className="successfully_integrated_single">
          <CheckIcon />

          <p>eSwap is currently importing your products and orders. We will send you an email upon completion.</p>
        </div>

        <div className="successfully_integrated_single">
          <CheckIcon />

          <p>Once products has been successfully imported, your products will be visible in Products List.</p>
        </div>

        <div className="successfully_integrated_single">
          <CheckIcon />

          <p>Once customers has been successfully imported, they will be visible in Customers part</p>
        </div>

        <div className="successfully_integrated_single">
          <CheckIcon />

          <p>Once orders has been successfully Imported, they will be visible in Sales Orders part.</p>
        </div>

        <div className="successfully_integrated_single">
          <CheckIcon />

          <p>
            Most channel integrations do not support Cost Prices. Setting your Initial Cost in eSwap help you to get
            correct workflow about your business in eSwap.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SuccessfullyIntegrated;
