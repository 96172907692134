import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as B2BIcon } from '../../../../assets/icons/apps/b2b.svg';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { appInstallFields } from '../../../../helpers/staticData';
import formatter from '../../../../helpers/Formatter';

const modalTabs = [
  {
    title: 'Store domain',
    value: 'storeUrl',
  },
  {
    title: 'Custom Store URL',
    value: 'customStoreUrl',
  },
];

const baseURL = '.eswap.ch';

function B2BInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const [installModal, setInstallModal] = useState(false);

  const [installType, setInstallType] = useState('storeUrl');

  const fields = useMemo(() => {
    const currentFields = appInstallFields['b-2-b'];

    currentFields[1].title = installType === 'storeUrl' ? 'Your store domain' : 'Your custom store URL';
    currentFields[1].placeholder = installType === 'storeUrl' ? 'Enter your store domain' : 'Enter your store URL';
    currentFields[1].urlPreview = installType === 'storeUrl' ? baseURL : '';
    currentFields[1].regex = installType === 'storeUrl' ? /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/ : null;
    currentFields[1].regexError = installType === 'storeUrl'
      ? 'Enter valid domain: letter, number, hyphen(not at start/end)'
      : '';

    return currentFields;
  }, [installType]);

  const install = useCallback(async (data) => {
    try {
      const { data: { integration: { id } } } = await Api.createIntegration(app, {
        ...data,
        storeUrl: formatter.url(installType === 'storeUrl' ? data.storeUrl + baseURL : data.storeUrl),
      });

      navigate(`/my-apps/${id}/settings`);
      toast.success('App has been successfully installed');
      setInstallModal(false);
    } catch (err) {
      console.warn(err);

      throw {
        response: {
          data: {
            errors: {
              storeUrl: err.response.data.message === 'Integration already installed'
                ? `Integration with this ${installType === 'storeUrl' ? 'domain' : 'store URL'} already installed`
                : err.response.data.message,
            },
            message: err.response.data.message,
          },
        },
      };
    }
  }, [installType]);

  return (
    <AppInstallInfoWrapper
      shopName="B2B"
      shopIcon={<B2BIcon />}
      description={'eSwap\'s Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      Integration info

      <UpdateModal
        isOpen={installModal}
        className="b2b_modal"
        fields={fields}
        tabs={modalTabs}
        activeTab={installType}
        onChangeTab={setInstallType}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle="Install B2B"
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default B2BInstall;
