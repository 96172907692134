import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getWarehouseList = createAsyncThunk(
  'get/warehouse/list/request',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getWarehouses();

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getStockTransferListRequest = createAsyncThunk('get/stock/transfer/list', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getStockTransferList(payload);

    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const deleteStockTransferRequest = createAsyncThunk('delete/stock/transfer', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.deleteStockTransfer(payload);

    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const receiveStockTransferRequest = createAsyncThunk(
  'receive/stock/transfer/request',
  async (payload, thunkAPI) => {
    try {
      const data = await Api.receiveStockTransfer(payload.id, { products: payload.products });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
