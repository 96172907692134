import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import moment from 'moment/moment';
import Button from '../../../_common/Form/Button';
import Utils from '../../../../helpers/Utils';
import { singleOrderReturnsRequest } from '../../../../store/actions/orderReturns';

function Returns() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { orderId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const singleOrderReturns = useSelector((state) => state.orderReturns.singleOrderReturns);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(singleOrderReturnsRequest(orderId));
      setLoading(false);
    })();
  }, [orderId]);

  const handleGetReceiveStatus = useCallback((orderReturnProducts) => {
    const receivedQty = _.sumBy(orderReturnProducts, 'receivedQty');
    const qty = _.sumBy(orderReturnProducts, 'qty');
    if (qty - receivedQty === 0) {
      return 'received';
    }
    if (receivedQty) {
      return 'partially_received';
    }
    return 'not_received';
  }, []);

  return (
    <div className="packages">
      {!loading && _.isEmpty(singleOrderReturns) ? (
        <div className="no_packages">
          No Returns created so far
          <Button
            btnType="transparent"
            onClick={() => navigate(`${location.pathname}${location.search}`, {
              state: { return: true },
              replace: true,
            })}
            data-scope="WRITE_ORDER_RETURNS"
          >
            Create Return
          </Button>
        </div>
      ) : null}
      {!_.isEmpty(singleOrderReturns) && !loading ? (
        <div className="table_wrapper">
          <table className="table">
            <thead className="table_thead">
            <tr className="table_thead_tr">
              <th className="table_thead_tr_th">Return#</th>
              <th className="table_thead_tr_th ordered">Date</th>
              <th className="table_thead_tr_th packed">Status</th>
              <th className="table_thead_tr_th">Receive status</th>
              <th className="table_thead_tr_th">Returned</th>
            </tr>
            </thead>
            <tbody className="table_tbody">
            {singleOrderReturns.map((p) => (
              <tr
                className="table_tbody_tr"
                key={p.id}
              >
                <td className="table_tbody_tr_td number">
                  <Link to={`/orders/returns/preview/${p.id}`}>{p.number}</Link>
                </td>
                <td className="table_tbody_tr_td">
                  {moment(p.sourceCreatedAt).format('DD MMM YYYY')}
                </td>
                <td className="table_tbody_tr_td">
                  <div className={`status ${p.status}`}>
                    <p className="status_dot" />
                    <span>
                        {Utils.formatStatus(p.status)}
                      </span>
                  </div>
                </td>
                <td className="table_tbody_tr_td  ">
                  <div className={`status ${handleGetReceiveStatus(p.orderReturnProducts)}`}>
                    <p className="status_dot" />
                    <span>
                        {Utils.formatStatus(handleGetReceiveStatus(p.orderReturnProducts))}
                      </span>
                  </div>
                </td>
                <td className="table_tbody_tr_td">
                  {_.sumBy(p.orderReturnProducts, 'qty')}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      ) : null}

    </div>
  );
}

export default Returns;
