import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import _ from 'lodash';
import moment from 'moment';
import Wrapper from '../../../Layout/Wrapper';
import SubscriptionCard from './SubscriptionCard';
import Table from '../../../_common/Tables/Table';
import Button from '../../../_common/Form/Button';
import ModalAddPayment from './ModalAddPayment';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import { cardTypes } from '../../../../helpers/staticData';
import WarningModal from '../../../_common/Modal/WarningModal';
import useEventCallback from '../../../../helpers/hooks/useEventCallback';

const tableHeader = [
  {
    title: 'Invoice number',
    path: 'number',
  },
  {
    title: 'Plan',
    path: 'planName',
  },
  {
    title: 'Amount Paid',
    path: 'amountPaid',
  },
  {
    title: 'Created at',
    path: 'createdAt',
  },
  {
    title: 'Coupon',
    path: 'discount',
  },
  {
    title: 'Tax',
    path: 'tax',
  },
  {
    title: 'Status',
    path: 'status',
  },
];

function Subscription() {
  const navigate = useNavigate();
  const stripe = useStripe();

  const [subscription, setSubscription] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [hasMoreInvoices, setHasMoreInvoices] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);

  useEffect(() => {
    if (stripe) {
      (async () => {
        const [sub, inv] = await Promise.all(
          [Api.getCurrentSubscription(), Api.getCurrentSubscriptionInvoices()],
        );

        const paymentIntent = sub.data.data.subscriptionStatus.latestInvoice?.paymentIntent;

        if (paymentIntent?.nextAction?.type === 'use_stripe_sdk') {
          stripe
            .confirmCardPayment(paymentIntent.clientSecret)
            .then((result) => {
              console.log(result, 'rrrrrrrrrrrrrrrr');
            });
        } else if (inv.data.invoices?.[0]?.status !== 'paid' && inv.data.invoices?.[0]?.status) {
          setWarningModal(true);
        }

        setSubscription(sub.data.data);
        setInvoices(inv.data.invoices);
        setHasMoreInvoices(inv.data.hasMore);
        setLoading(false);
      })();
    }
  }, [stripe]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = useEventCallback(async () => {
    const scrollThreshold = document.body.offsetHeight - 20;

    if ((window.innerHeight + window.scrollY >= scrollThreshold) && !scrollLoading && hasMoreInvoices) {
      setScrollLoading(true);

      try {
        const { data } = await Api.getCurrentSubscriptionInvoices({ id: invoices[invoices.length - 1].id });

        setInvoices((prev) => [...prev, ...data.invoices]);
        setHasMoreInvoices(data.hasMore);
      } catch (e) {
        console.log(e);
        // toast.error(e.response.data.message);
      }

      setScrollLoading(false);
    }
  });

  const onPlanUpgrade = () => {
    navigate('/settings/plans-billings/pricing-plans');
  };

  const onDownload = (id) => {
    window.open(invoices.find((i) => i.id === id).invoicePdf);
  };

  const requestCurrentSubscription = useCallback(async () => {
    if (!subscription?.card?.last4) {
      setLoading(true);
      const { data } = await Api.getCurrentSubscription();

      setSubscription(data.data);
      setLoading(false);
    }
  }, []);

  const closeModal = useCallback(() => {
    setAddPaymentModal(false);
  }, [addPaymentModal]);

  return (
    <Wrapper title="Subscription">
      {loading ? <Loader /> : (
        <div className="subscription">
          {subscription?.subscription?.id && (
          <div className="subscription_top">
            <div className="subscription_card_wrapper">
              <SubscriptionCard
                title="Current plan summary"
                onClick={onPlanUpgrade}
                btnTitle="Change plan"
              >
                <div className="subscription_card_single">
                  <p className="subscription_card_title">Plan name</p>

                  <p className="subscription_card_data">{_.upperFirst(subscription.subscription.planName)}</p>
                </div>

                <div className="subscription_card_single">
                  <p className="subscription_card_title">Billing cycle</p>

                  <p className="subscription_card_data">{_.upperFirst(subscription.subscription.interval)}</p>
                </div>

                <div className="subscription_card_single">
                  <p className="subscription_card_title">Plan cost</p>

                  <p className="subscription_card_data">{`${_.upperFirst(subscription.subscription.price)}$`}</p>
                </div>

                <div className="subscription_card_single">
                  <p className="subscription_card_title">Next payment</p>

                  <p className="subscription_card_data">{moment(subscription.reNewDate).format('MM/DD/YY')}</p>
                </div>
              </SubscriptionCard>
            </div>

            {subscription.card.last4 && (
            <div className="subscription_card_wrapper">
              <SubscriptionCard title="Billing details">
                <div className="subscription_card_single">
                  <p className="subscription_card_title">Full name</p>

                  <p className="subscription_card_data_light">{subscription.details.name}</p>
                </div>

                <div className="subscription_card_single_long">
                  <p className="subscription_card_title">Address</p>

                  <p className="subscription_card_data_light">
                    {`
                     ${subscription.details.address.country},
                     ${subscription.details.address.state},
                     ${subscription.details.address.city},
                     ${subscription.details.address.line1},
                     ${subscription.details.address.postalCode}
                    `}
                  </p>
                </div>
              </SubscriptionCard>
            </div>
            )}

            {subscription.card.last4 && (
            <div className="subscription_card_wrapper">
              <SubscriptionCard
                title="Payment method"
                onClick={() => setAddPaymentModal(true)}
                btnType="transparent"
                btnTitle="Add payment method"
              >
                <div style={{ display: 'flex' }}>
                  <div className="subscription_card_wrapper_icon">
                    {cardTypes.find((c) => c.type.toLowerCase() === subscription.card.brand.toLowerCase()).icon}
                  </div>

                  <div className="subscription_card_single">
                    <p className="subscription_card_title">{_.upperFirst(subscription.card.brand)}</p>

                    <p className="subscription_card_data">{`**** ${subscription.card.last4}`}</p>

                    <p className="subscription_card_title">
                      {`
                        Expiry on 
                        ${subscription.card.expMonth < 10
                        ? `0${subscription.card.expMonth}` : subscription.card.expMonth}
                        /
                        ${subscription.card.expYear.toString().slice(-2)}
                      `}
                    </p>
                  </div>
                </div>

                <Button
                  reverseColor
                  roundBorder
                  size="small"
                  className="subscription_card_single_button"
                  href="/settings/plans-billings/change-payment-method"
                >
                  Manage
                </Button>
              </SubscriptionCard>
            </div>
            )}

            {!subscription.card.last4 && (
              <Button
                onClick={() => setAddPaymentModal(true)}
                title="Add payment method"
                size="small"
                btnType="transparent"
                color="#1472FF"
                className="subscription_add_payment_button"
              />
            )}
          </div>
          )}

          <div className="subscription_bottom">
            <h3 className="subscription_bottom_title">Invoice</h3>

            <p className="subscription_bottom_text">Effortlessly handle your billing and invoices right here.</p>

            <div className="subscription_bottom_table_wrp">
              {invoices.length && (
              <Table
                data={invoices}
                header={tableHeader}
                onDownloadClick={onDownload}
              />
              )}
            </div>

            {scrollLoading
              && (
                <div className="subscription_bottom_scroll_loading">
                  <Loader size={28} />
                </div>
              )}
          </div>

          <ModalAddPayment
            isOpen={addPaymentModal}
            onClose={closeModal}
            onSave={requestCurrentSubscription}
            zIndex={105}
          />

          <WarningModal
            isOpen={warningModal}
            onClose={() => setWarningModal(false)}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default Subscription;
