import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Select from '../../_common/Form/Select';
import {
  getCustomersRequest,
  getSingleCustomerRequest,
  removeCustomerData,
  removeCustomerFormData,
} from '../../../store/actions/customers';
import EditAddressModal from './EditAddressModal';
import Utils from '../../../helpers/Utils';

function OrderCustomer(props) {
  const {
    onChange, shippingAddressId, customerId, errors, onSave, status, label, type,
  } = props;

  const [editingAddress, setEditingAddress] = useState('');
  const [loading, setLoading] = useState('');

  const dispatch = useDispatch();
  const { orderId } = useParams();

  const customer = useSelector((state) => state.customers.customer);
  const customers = useSelector((state) => state.customers.customers);

  const shippingAddress = useMemo(() => {
    if (customer.shippingAddresses) {
      return customer.shippingAddresses.find((s) => s.id === shippingAddressId) || customer.shippingAddresses[0];
    }
    return '';
  }, [customer.shippingAddresses, shippingAddressId]);

  useEffect(() => () => {
    dispatch(removeCustomerData());
    dispatch(removeCustomerFormData());
  }, []);

  const handleGetCustomers = useCallback(async (s) => {
    const { payload } = await dispatch(getCustomersRequest({
      s,
      includeIds: customerId,
      isVendor: type === 'vendor' ? true : undefined,
      isCustomer: type === 'customer' ? true : undefined,
    }));
    return payload.data.customers;
  }, [customerId, type]);

  const handleGetLabel = useCallback((o) => {
    if (o.firstName) {
      return `${o.firstName} ${o.lastName}`;
    }
    if (o.email) {
      return o.email;
    }
    if (o.phone) {
      return o.phone;
    }
    return o.id;
  }, []);

  const handleChangeCustomer = useCallback(async (val) => {
    setLoading(true);
    const data = {
      shippingAddressId: null,
      customerId: val.id,
    };
    if (val.defaultCurrencyCode) data.currencyCode = val.defaultCurrencyCode;
    if (val.defaultPaymentMethodId) data.paymentMethodId = val.defaultPaymentMethodId;
    if (val.defaultPaymentTermId) data.paymentTerm = { id: val.defaultPaymentTermId };
    if (val.defaultPriceListId) data.priceListId = val.defaultPriceListId;
    if (val.defaultWarehouseId) data.warehouseId = val.defaultWarehouseId;
    const { payload } = await dispatch(getSingleCustomerRequest(val.id));
    if (payload.data) {
      data.shippingAddressId = _.get(payload, 'data.customer.shippingAddresses[0].id', null);
    }
    onChange(null, data);
    setLoading(false);
  }, [onChange]);

  const handleAddNewCustomer = useCallback(() => {
    const res = onSave(orderId ? status : 'draft', '/crm/customers/create');
    if (res === 'error') {
      toast.error('Can not navigate until all required fields is filled');
    }
  }, [onSave, orderId]);

  return (
    <>
      <div className="customers">
        <Select
          label={label}
          placeholder={`Select ${label}`}
          roundBorder
          isAsync
          loadOptions={handleGetCustomers}
          defaultOptions
          getOptionLabel={handleGetLabel}
          valuePath="id"
          getFullOption
          loading={loading}
          onChange={handleChangeCustomer}
          value={customers.find((c) => c.id === customerId)}
          error={errors.customerId}
          addNew="WRITE_CUSTOMERS"
          onAddNewClick={handleAddNewCustomer}
        />
        {!_.isEmpty(customer) ? (
          <div>
            <div className="info">
              <p className="title">Contact information</p>
              <p className="item">{`${customer.firstName || ''} ${customer.lastName || ''}`}</p>
              <p className="item">{customer.phone}</p>
              <p className="item">{customer.email}</p>
            </div>
            <div className="info">
              <p className="title">
                <span>Shipping address</span>
                <span className="edit" onClick={() => setEditingAddress('shippingAddresses')}>Edit</span>
              </p>
              <div className="address">
                <Select
                  options={customer.shippingAddresses}
                  valuePath="id"
                  getOptionLabel={(o) => Utils.formatAddress(
                    [o.address1,
                      o.city,
                      o.region,
                      o.postalCode,
                      o.countryCode],
                  ) || 'Shipping address'}
                  value={shippingAddressId}
                  onChange={(val) => {
                    onChange('shippingAddressId', val);
                  }}
                  error={errors.shippingAddressId ? 'Field is required' : null}
                />
              </div>
              <p className="address" />
              <p className="item">
                {`${shippingAddress?.firstName || ''} ${shippingAddress?.lastName || ''}`}
              </p>
              <p className="item">{shippingAddress?.phone}</p>
              <p className="item">{shippingAddress?.email}</p>
            </div>
            <div className="info">
              <p className="title">
                <span>Billing address</span>
                <span className="edit" onClick={() => setEditingAddress('billingAddress')}>Edit</span>
              </p>
              {customer.billingAddress ? (
                <p className="address">
                  <span>
                    {Utils.formatAddress(
                      [customer.billingAddress?.address1,
                        customer.shippingAddress?.city,
                        customer.billingAddress?.region,
                        customer.billingAddress?.postalCode,
                        customer.billingAddress?.countryCode],
                    )}
                  </span>
                </p>
              ) : null}
              <p
                className="item"
              >
                {`${customer.billingAddress?.firstName || ''} ${customer.billingAddress?.lastName || ''}`}
              </p>
              <p className="item">{customer.billingAddress?.phone}</p>
              <p className="item">{customer.billingAddress?.email}</p>
            </div>
          </div>
        ) : null}
      </div>
      {editingAddress ? (
        <EditAddressModal
          address={editingAddress}
          isOpen={!!editingAddress}
          onClose={() => setEditingAddress('')}
          onChange={onChange}
        />
      ) : null}
    </>
  );
}

export default OrderCustomer;
