import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as BigcommerceIcon } from '../../../../assets/icons/apps/bigcommerce.svg';
import BigcommerceImg1 from '../../../../assets/images/apps/bigcommerce/bigcommerce_1.png';
import BigcommerceImg2 from '../../../../assets/images/apps/bigcommerce/bigcommerce_2.png';
import BigcommerceImg3 from '../../../../assets/images/apps/bigcommerce/bigcommerce_3.png';
import BigcommerceImg4 from '../../../../assets/images/apps/bigcommerce/bigcommerce_4.png';
import BigcommerceImg5 from '../../../../assets/images/apps/bigcommerce/bigcommerce_5.png';
import BigcommerceImg6 from '../../../../assets/images/apps/bigcommerce/bigcommerce_6.png';
import BigcommerceImg7 from '../../../../assets/images/apps/bigcommerce/bigcommerce_7.png';
import BigcommerceImg8 from '../../../../assets/images/apps/bigcommerce/bigcommerce_8.png';
import BigcommerceImg9 from '../../../../assets/images/apps/bigcommerce/bigcommerce_9.jpg';
import BigcommerceImg10 from '../../../../assets/images/apps/bigcommerce/bigcommerce_10.jpg';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { appInstallFields } from '../../../../helpers/staticData';

const fields = {
  oAuth: [
    {
      title: 'Your app name',
      placeholder: 'Enter your app name',
      path: 'integrationName',
      required: true,
    },
    {
      title: 'Your app hash',
      placeholder: 'Enter your app hash, e.g. 2e67tawf7d',
      path: 'storeHash',
      required: true,
    },
  ],
  manual: appInstallFields.bigcommerce,
};

const installTypes = [
  {
    label: 'oAuth',
    value: 'oAuth',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
];

function BigCommerceInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const [installType, setInstallType] = useState('oAuth');

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      if (installType === 'oAuth') {
        const response = await Api.installApp(
          app,
          {
            integrationName: data.integrationName,
            storeHash: data.storeHash,
            redirectUrl: `${window.location.origin}/integration-verify/${app}`,
          },
        );

        localStorage.setItem(`${app}IntegrationInfo`, JSON.stringify({
          integrationName: data.integrationName,
        }));

        window.location.href = response.data.params.redirectUrl;
      } else {
        const { data: { integration: { id } } } = await Api.createIntegration(
          app,
          {
            integrationName: data.integrationName,
            accessToken: data.accessToken,
            storeHash: data.storeHash,
            clientId: data.clientId,
            redirectUrl: `${window.location.origin}/integration-verify/${app}`,
          },
        );

        navigate(`/apps/integration/${id}`);
      }
    } catch (err) {
      if (err.response.data?.errors) {
        throw err;
      } else {
        toast.error(err.response.data.message);
        setInstallModal(false);
      }
    }
  }, [installType]);

  return (
    <AppInstallInfoWrapper
      shopName="Bigcommerce"
      shopIcon={<BigcommerceIcon />}
      installType={installType}
      installTypes={installTypes}
      onChangeInstallType={setInstallType}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and"
        + ' process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p className="title">
        Installation Instructions for Connecting BigCommerce to eSwap:
      </p>
      <ul>
        <li>
          Log in to your BigCommerce account.
        </li>

        <li>
          From the left pane, select &quot;Advanced Settings&quot; &gt; &quot;API Accounts.&quot; This will take you to
          the Store API Accounts page.
        </li>

        <li>
          Click on &quot;Create API Account&quot; to proceed.
        </li>

        <li>
          Enter the API account name in the Name textbox and select the required Oauth Scopes.
        </li>
      </ul>

      <p>
        <img
          src={BigcommerceImg9}
          alt="info image"
        />
      </p>
      <ul>
        <li>
          Note: The Store HASH information can be found from the API Path textbox. It&#39;s the value
          between &quot;/stores/&quot; and &quot;/v3/&quot;.
        </li>

        <li>
          Click &quot;Save.&quot; This will display the BigCommerce API Credentials page containing the
          Access Token and Client ID.
        </li>

        <li>
          Copy these credentials securely as they will be needed for the integration.
        </li>
      </ul>

      <p>
        Note: Ensure to save these credentials securely as they may not be accessible again
        once you close the window.
      </p>

      <p>
        <img
          src={BigcommerceImg10}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Navigate to the Apps section in eSwap, particularly under &quot;Ecommerce,&quot; and select
          &quot;BigCommerce.&quot;
        </li>
        <li>Click on &quot;Install app.&quot;</li>
      </ul>

      <p>
        <img
          src={BigcommerceImg1}
          alt="info image"
        />
      </p>

      <p>Click on &quot;Install app.&quot;</p>

      <p>
        <img
          src={BigcommerceImg2}
          alt="info image"
        />
      </p>

      <ul>
        <li>Enter a desired name for your BigCommerce shop.</li>
        <li>Enroll your shop access token, hash, and client ID obtained from the previous step.</li>
        <li>Click &quot;Install app&quot; to proceed.</li>
      </ul>

      <p>
        <img
          src={BigcommerceImg3}
          alt="info image"
        />
      </p>

      <p className="title">Upon installation, follow these configuration steps:</p>

      <ul>
        <li>
          Choose a warehouse location from existing options or create a new one if
          necessary.
        </li>
      </ul>

      <p>
        <img
          src={BigcommerceImg4}
          alt="info image"
        />
      </p>

      <ul>
        <li>Sync your price list with BigCommerce or create a new one as required.</li>
      </ul>

      <p>
        <img
          src={BigcommerceImg5}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Configure BigCommerce order statuses to align with existing lines or create new
          ones if necessary.
        </li>
      </ul>

      <p>
        <img
          src={BigcommerceImg6}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Decide whether to import data immediately or at a later time.
        </li>
        <li>The import process will begin based on your selections.</li>
      </ul>

      <p>
        <img
          src={BigcommerceImg7}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Once the configuration steps are completed, click &quot;Done Installation&quot; to finalize the
          setup.
        </li>
        <li>The BigCommerce app will now be accessible from your apps page within eSwap.</li>
      </ul>

      <p>
        <img
          src={BigcommerceImg8}
          alt="info image"
        />
      </p>

      <p>
        By following these steps, you will successfully connect your BigCommerce account to eSwap, enabling
        seamless data synchronization and management between the two platforms.
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={fields[installType]}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default BigCommerceInstall;
