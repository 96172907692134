import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as EcwidIcon } from '../../../../assets/icons/apps/ecwid.svg';
import EcwidImg1 from '../../../../assets/images/apps/ecwid/ecwid_1.png';
import EcwidImg2 from '../../../../assets/images/apps/ecwid/ecwid_2.png';
import EcwidImg3 from '../../../../assets/images/apps/ecwid/ecwid_3.png';
import EcwidImg4 from '../../../../assets/images/apps/ecwid/ecwid_4.png';
import EcwidImg5 from '../../../../assets/images/apps/ecwid/ecwid_5.png';
import EcwidImg6 from '../../../../assets/images/apps/ecwid/ecwid_6.png';
import EcwidImg7 from '../../../../assets/images/apps/ecwid/ecwid_7.png';
import EcwidImg8 from '../../../../assets/images/apps/ecwid/ecwid_8.png';
import EcwidImg9 from '../../../../assets/images/apps/ecwid/ecwid_9.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';

const fields = [
  {
    title: 'Your app name',
    placeholder: 'Enter your app name',
    path: 'integrationName',
    required: true,
  },
];

function EcwidInstall() {
  const { app } = useParams();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const response = await Api.installApp(
        app,
        {
          integrationName: data.integrationName,
          redirectUrl: `${window.location.origin}/integration-verify/${app}`,
          // redirectUrl: 'https://app.eswap.ch/redirect',
        },
      );

      localStorage.setItem(`${app}IntegrationInfo`, JSON.stringify({
        integrationName: data.integrationName,
      }));

      window.location.href = response.data.params.redirectUrl;
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Ecwid"
      shopIcon={<EcwidIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and "
        + 'process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p className="title">
        Installation Instructions for Connecting Ecwid to eSwap:
      </p>

      <ul>
        <li>
          Navigate to the Apps section in eSwap, specifically under &quot;Ecommerce,&quot; and select
          &quot;Ecwid.&quot;
        </li>
        <li>Click on &quot;Install app.&quot;</li>
      </ul>

      <p>
        <img
          src={EcwidImg1}
          alt="info image"
        />
      </p>

      <p>
        Click on &quot;Install app.&quot;
      </p>

      <p>
        <img
          src={EcwidImg2}
          alt="info image"
        />
      </p>

      <ul>
        <li>Enter a desired name for your Ecwid shop.</li>
        <li>Click &quot;Install app&quot; to proceed.</li>
      </ul>

      <p>
        <img
          src={EcwidImg3}
          alt="info image"
        />
      </p>

      <p>
        Ecwid will prompt you to agree to start the integration process. Follow the on-screen
        instructions to proceed.
      </p>

      <p>
        <img
          src={EcwidImg4}
          alt="info image"
        />
      </p>

      <ul>
        <li>Upon installation, follow these configuration steps:</li>
        <li>Sync your price list with Ecwid or create a new one as required.</li>
      </ul>

      <p>
        <img
          src={EcwidImg5}
          alt="info image"
        />
      </p>

      <p>
        Configure Ecwid order statuses to align with existing lines or create new ones if
        necessary.
      </p>

      <p>
        <img
          src={EcwidImg6}
          alt="info image"
        />
      </p>

      <p>
        Decide whether to import data immediately or at a later time.
      </p>

      <p>
        <img
          src={EcwidImg7}
          alt="info image"
        />
      </p>

      <p>
        The import process will initiate based on your selections.
      </p>

      <p>
        <img
          src={EcwidImg8}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Once the configuration steps are completed, click &quot;Done Installation&quot; to finalize the
          setup.
        </li>
        <li>The Ecwid app will now be accessible from your apps page within eSwap.</li>
      </ul>

      <p>
        <img
          src={EcwidImg9}
          alt="info image"
        />
      </p>

      <p>
        By following these steps, you will successfully connect your Ecwid account to eSwap, facilitating
        seamless data synchronization and management between the two platforms.
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={fields}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default EcwidInstall;
