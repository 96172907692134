import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';

const ChartWrapper = ({
  children, title, loading, height, className,
}) => (
  <div className={`chart_wrapper ${className}`} style={{ height }}>
    {loading
      ? <Loader />

      : (
        <>
          <div className="chart_wrapper_title">
            <h2>{title}</h2>
          </div>

          {children}
        </>
      )}
  </div>
);

ChartWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

ChartWrapper.defaultProps = {
  title: '',
  className: '',
  loading: false,
};

export default ChartWrapper;
