import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useClickAway } from 'react-use';
import { useNavigate } from 'react-router-dom';
import HeaderSelect from './HeaderSelect';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import Api from '../../Api';
import Loader from '../_common/Loader/Loader';
import Button from '../_common/Form/Button';

const options = [
  {
    label: 'Sales orders',
    value: 'orders',
    text: 'number',
    url: '/orders/sales-order',
    singleURL: '/orders/sales-order/edit',
  },
  {
    label: 'Products',
    value: 'products',
    text: 'title',
    url: '/products',
    singleURL: '',
    img: true,
  },
  {
    label: 'Price list',
    value: 'priceList',
    text: 'name',
    singleURL: '/inventory/price-lists',
    url: '/inventory/price-lists',
  },
  {
    label: 'Customers',
    value: 'customers',
    text: 'firstName,lastName',
    url: '/crm/customers',
    singleURL: '/crm/customers/edit',
    img: true,
  },
  // {
  //   label: 'Invoices',
  //   value: 'invoices',
  // },
  // {
  //   label: 'Vendors',
  //   value: 'vendors',
  // },
  // {
  //   label: 'Purchase orders',
  //   value: 'purchaseOrders',
  // },
  // {
  //   label: 'Shipment',
  //   value: 'shipment',
  // },
  // {
  //   label: 'Returns',
  //   value: 'returns',
  // },
  // {
  //   label: 'All items',
  //   value: '',
  // },
];

const searchRequestsMethods = {
  orders: 'getOrders',
  products: 'getProducts',
  customers: 'getCustomers',
  priceList: 'getPriceList',
  invoices: 'getInvoices',
};

const SearchField = () => {
  const navigate = useNavigate();
  const searchTimeout = useRef();
  const ref = useRef(null);

  const [result, setResult] = useState([]);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [search, setSearch] = useState({
    type: 'orders',
    value: '',
  });

  const { type, value } = search;

  useEffect(() => {
    if (value) {
      searchTimeout.current = setTimeout(async () => {
        const { data } = await Api[searchRequestsMethods[type]]({ s: value, limit: 5 });

        setResult(data[type === 'priceList' ? 'priceLists' : type]);

        setSearchLoading(false);
      }, 500);
    } else {
      setSearchLoading(false);
    }
  }, [value, type]);

  const changeSearchValue = (path, val) => {
    if (path === 'type' && !value) {
      setSearchOpen(false);
      setSearch((prev) => ({ ...prev, [path]: val }));
      return;
    }

    setSearchLoading(true);

    if (val) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
      setResult([]);
    }

    clearTimeout(searchTimeout.current);
    setSearch((prev) => ({ ...prev, [path]: val }));
  };

  useClickAway(ref, () => {
    setSearchOpen(false);
  });

  const visibleSearchResult = (res) => {
    let resultText;
    let src;

    const { img, text } = options.find((o) => o.value === type);

    if (text.includes(',')) {
      resultText = res.firstName ? `${res.firstName} ${res.lastName}`
        : res.email ? res.email : 'UNKNOWN UNKNOWN';
    }

    if (type === 'products') {
      src = res?.images?.[0]?.src;
    } else {
      src = res?.image;
    }

    return (
      <div className="search_result_single_returned">
        {img && src && <img src={src} alt="img" className="search_result_single_img" />}

        <p>{res?.[text] || resultText}</p>
      </div>
    );
  };

  const resetSearch = useCallback(() => {
    setSearchOpen(false);
    setResult([]);
    setSearch((prev) => ({
      ...prev,
      value: '',
    }));
  }, [search, result, isSearchOpen]);

  const onSearchOrViewClick = (e, path, res) => {
    e.preventDefault();

    resetSearch();

    const { url, singleURL } = options.find((o) => o.value === type);

    if (path === 'single') {
      navigate(type === 'priceList' ? `${singleURL}/${res.id}/edit` : `${singleURL}/${res.id}`);
    } else {
      navigate(`${url}?s=${value}`);
    }
  };

  return (
    <div className="global_search_field_wrapper" ref={ref}>
      <HeaderSelect
        options={options}
        onChange={(option) => changeSearchValue('type', option)}
        value={type}
      />

      <form onSubmit={(e) => onSearchOrViewClick(e, 'all')}>
        <label className="global_search_field_input">
          <input
            type="text"
            placeholder="Search"
            value={value}
            onChange={({ target }) => changeSearchValue('value', target.value)}
          />

          {!searchLoading ? <SearchIcon /> : <Loader size={18} className="search_loader" />}
        </label>
        {isSearchOpen && (
        <div className="global_search_results_wrp">
          {searchLoading
            ? <p style={{ textAlign: 'center' }}>Searching...</p>
            : result.length ? result.map((r) => (
              <div className="search_result_single" onClick={(e) => onSearchOrViewClick(e, 'single', r)}>
                <SearchIcon />

                {visibleSearchResult(r)}
              </div>
            ))
              : <p style={{ textAlign: 'center' }}>No result</p>}

          {result.length ? (
            <div style={{ textAlign: 'center', margin: '15px 0 5px' }}>
              <Button
                style={{ color: '#1472FF' }}
                btnType="transparent"
                type="submit"
              >
                View all
              </Button>
            </div>

          ) : ''}
        </div>
        )}
      </form>
    </div>
  );
};

SearchField.propTypes = {

};

export default SearchField;
