import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import _ from 'lodash';
import { toast } from 'react-toastify';
import MultiSelect from '../../../_common/Form/MultiSelect';
import { getOrdersRequest, getOrderTagsRequest } from '../../../../store/actions/orders';
import TagFormModal from '../../Settings/Tags/TagFormModal';
import Loader from '../../../_common/Loader/Loader';
import Button from '../../../_common/Form/Button';
import Api from '../../../../Api';
import Utils from '../../../../helpers/Utils';

const DelimitedArrayParamComma = Utils.customDelimitedArrayParam('~');
const defaultFiltersParams = withDefault(DelimitedArrayParamComma, []);

const AddTags = ({ closeModal, orders }) => {
  const dispatch = useDispatch();
  const orderTags = useSelector((state) => state.orders.orderTags);

  const [dateOpenPortal, setDateOpenPortal] = useState();
  const [tags, setTage] = useState([]);
  const [createTagModalOpen, setCreateTagModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [query] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    status: defaultFiltersParams,
    warehouseId: defaultFiltersParams,
    ids: defaultFiltersParams,
    orderStatus: withDefault(StringParam, ''),
    _status: withDefault(StringParam, ''),
    assignedUserId: defaultFiltersParams,
    tagId: defaultFiltersParams,
    customerId: defaultFiltersParams,
    isArchive: withDefault(StringParam, ''),
  });

  useMemo(() => {
    setTage(_.uniqBy(orders.map((o) => o.tags).flat(1), 'id'));
  }, [orders]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getOrderTagsRequest());
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setDateOpenPortal(document.querySelector('#modal'));
  }, []);

  const saveTag = async (d) => {
    await dispatch(getOrderTagsRequest());

    setTage((prev) => [...prev, d]);
  };
  const saveClick = async () => {
    if (!tags.length) {
      toast.error('Please select a.svg tag !');
      return;
    }

    setSaveLoading(true);
    const tagIds = tags.map((t) => t.id);
    const { orderStatus, _status, ...q } = Utils.arrayToString(query);

    await Promise.all(orders.map((o) => Api.changeOrdersTags({ orderId: o.id, tagIds })));

    await dispatch(getOrdersRequest({
      status: orderStatus || _status || undefined,
      ...q,
      includes: 'customer,tags,orderProducts',
      isArchive: false,
      type: 'sale',
    }));

    setSaveLoading(false);
    closeModal();
  };

  return (
    <div className="bulk_add_tags_wrapper">
      <h1>Add tags</h1>

      <div className="bulk_add_body_wrapper">
        {loading
          ? <Loader />
          : (
            <MultiSelect
              placeholder="Select tags"
              options={orderTags}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.id}
              value={orderTags.filter((o) => tags.some((t) => t.id === o.id))}
              onChange={setTage}
              menuPosition="fixed"
              menuPortalTarget={dateOpenPortal}
              addNew="WRITE_TAGS"
              onAddNewClick={() => setCreateTagModalOpen(true)}
            />
          )}
      </div>

      <div className="bulk_order_buttons_wrapper">
        <Button
          className="bulk_order_button"
          size="small"
          title="Cancel"
          onClick={closeModal}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="bulk_order_button"
          size="small"
          title="Save"
          onClick={saveClick}
          roundBorder
          color="#1472FF"
          loading={saveLoading}
        />
      </div>

      {createTagModalOpen && (
        <TagFormModal
          isOpen={createTagModalOpen}
          onClose={() => setCreateTagModalOpen(false)}
          onSave={saveTag}
        />
      )}
    </div>
  );
};

export default AddTags;
