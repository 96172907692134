import React, { useState } from 'react';
import Select from '../../../_common/Form/Select';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import noImage from '../../../../assets/icons/default.svg';
import Button from '../../../_common/Form/Button';

function ShippingLabels({ openPresets }) {
  const [openItem, setOpenItem] = useState(false);
  const [openRevalidate, setOpenRevalidate] = useState(false);
  const [openPreset, setOpenPreset] = useState(false);
  const [bulkUpdate, setBulkUpdate] = useState(false);

  return (
    <div>
      <h3 className="shipping_label_title">Bulk shipping label</h3>
      <div className="rows_selected">
        <h4 className="selected_count">2 rows selected</h4>

        <div className="rows_selected_block">
          <Select
            // options={currencies}
            roundBorder
            lightBlue
            // valuePath="code"
            // labelPath="code"
            // value={generalList.currencyCode}
            // onChange={(code) => changeGeneralSettings('currencyCode', code)}
            size="small"
          />
          <Select
            lightBlue
            roundBorder
            // options={currencies}
            // valuePath="code"
            // labelPath="code"
            // value={generalList.currencyCode}
            // onChange={(code) => changeGeneralSettings('currencyCode', code)}
            size="small"
          />
        </div>

      </div>

      <div className="shipping_label_item">
        <h4 className="selected_count items_title" onClick={() => setOpenItem(!openItem)}>
          <ArrowIcon className={openItem && 'open'} />
          Items
        </h4>

        {openItem && (
          <div className="selected_item">
            <div className="selected_item_wrap">
              <p className="items_order">Items from order #SO-00001</p>

              <div className="item">
                <div className="item_img_blog">
                  <figure>
                    <img src={noImage} alt="" />
                  </figure>
                  <p>McKesson Hand Sanitizer with Aloe, Spring Water Scent</p>
                </div>

                <div className="order_qty">
                  <div className="qty">Qty:</div>
                  <div className="qty_count">2</div>
                </div>
              </div>
            </div>

            <div className="selected_item_wrap">
              <p className="items_order">Items from order #SO-00001</p>

              <div className="item">
                <div className="item_img_blog">
                  <figure>
                    <img src={noImage} alt="" />
                  </figure>
                  <p>McKesson Hand Sanitizer with Aloe, Spring Water Scent</p>
                </div>

                <div className="order_qty">
                  <div className="qty">Qty:</div>
                  <div className="qty_count">2</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="shipping_label_item">
        <div className="multiple_addresses">
          <h4 className="selected_count items_title" onClick={() => setOpenRevalidate(!openRevalidate)}>
            <ArrowIcon className={openRevalidate && 'open'} />
            Revalidate addresses
          </h4>

          <div className="address">Multiple Addresses</div>
        </div>

        {openRevalidate && (
          <div className="selected_item">
            <div className="selected_item_wrap">

              <div className="errors">
                <div className="errors_count">2 errors</div>
                <p className="errors_text">Click the button below to re-validate the addresses of the selected rows</p>
              </div>

              <div className="revalidate_but">Revalidate addresses</div>

            </div>
          </div>
        )}
      </div>

      <div className="shipping_label_item">
        <div className="multiple_addresses">
          <h4 className="selected_count items_title" onClick={() => setOpenPreset(!openPreset)}>
            <ArrowIcon className={openPreset && 'open'} />
            Preset
          </h4>
        </div>

        {openPreset && (
          <div className="selected_item">
            <div className="selected_item_wrap preset">
              <Select
                label="Preset"
                // options={currencies}
                roundBorder
                // valuePath="code"
                // labelPath="code"
                // value={generalList.currencyCode}
                // onChange={(code) => changeGeneralSettings('currencyCode', code)}
                size="small"
              />

              <Button
                title="Apply"
                size="small"
                btnType="light_blue"
                // loading={loading}
                roundBorder
                onClick={openPresets}
              />
            </div>
          </div>
        )}
      </div>

      <div className="shipping_label_item">
        <div className="multiple_addresses">
          <h4 className="selected_count items_title" onClick={() => setBulkUpdate(!bulkUpdate)}>
            <ArrowIcon className={bulkUpdate && 'open'} />
            Bulk update
          </h4>
        </div>

        {bulkUpdate && (
          <div className="selected_item">
            <div className="selected_item_wrap bulk_update">
              <div>Ship from</div>
              <div>Service</div>
              <div>Package</div>
              <div>Confirmation</div>
              <div>Weight</div>
            </div>
          </div>
        )}
      </div>

      <div className="shipping_price">
        <div className="price_block">
          <div className="price">$4.14</div>
          <div className="cost_review">Cost review</div>
        </div>
        <Button
          title="Create label"
          size="small"
          // loading={loading}
          roundBorder
          // onClick={onDelete}
        />
      </div>
    </div>
  );
}

export default ShippingLabels;
