import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const returnOrderRequest = createAsyncThunk(
  'orderReturns/returnOrderRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.returnOrder(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const singleOrderReturnsRequest = createAsyncThunk(
  'orderReturns/singleOrderReturnsRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.singleOrderReturns(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const orderReturnsListRequest = createAsyncThunk(
  'orderReturns/orderReturnsListRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.orderReturnsList(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const acceptOrderReturnRequest = createAsyncThunk(
  'orderReturns/acceptOrderReturnRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, ...d } = payload;
      const { data } = await Api.acceptOrderReturn(id, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const deleteReturnRequest = createAsyncThunk(
  'orderReturns/deleteReturnRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteReturn(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const declineReturnRequest = createAsyncThunk(
  'orderReturns/declineReturnRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.declineReturn(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const generateReturnNumberRequest = createAsyncThunk(
  'orderReturns/generateReturnNumberRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.generateReturnNumber(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
