import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../../../Api';

const WooCommerceIntegration = () => {
  const { app } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const { integrationId } = JSON.parse(localStorage.getItem(`${app}IntegrationInfo`)) || {};

        if (integrationId) {
          navigate(`/my-apps/${integrationId}/dashboard`, { replace: true });

          toast.success('App has been successfully reinstalled');
        } else {
          const { data: { integrations } } = await Api.getIntegrations();

          navigate(`/apps/integration/${integrations[0]?.id}`, { replace: true });
        }
      } catch (e) {
        navigate('/404', { replace: true });
      }

      localStorage.removeItem(`${app}IntegrationInfo`);
    })();
  }, []);

  return null;
};

export default WooCommerceIntegration;
