import { createReducer } from '@reduxjs/toolkit';
import {
  getDashboardBySalesChannelsRequest,
  getDashboardLatestOrdersRequest,
  getDashboardLayoutRequest,
  getDashboardLowQuantityRequest,
  getDashboardOrderSummaryRequest,
  getDashboardOutStockRequest,
  getDashboardProductsCountRequest,
  getDashboardPurchaseOrdersRequest,
  getDashboardShopsRequest,
  getDashboardStatusCountRequest,
  getDashboardTopProductsRequest, updateDashboardLayoutRequest,
} from '../actions/dashboard';

const initialState = {
  dashboardLayout: [],
  statusCount: {},
  products: [],
  bySalesChannels: [],
  purchaseOrders: [],
  topSellingProducts: [],
  itemsAtLowQuantity: [],
  itemsOutOfStock: [],
  salesOrdersSummary: [],
  salesOrdersSummaryStatus: '',
  shops: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getDashboardLayoutRequest.fulfilled, (state, { payload }) => {
      const settingType = window.innerWidth < 768 ? 'mobileDashboardSettings'
        : window.innerWidth < 1170 ? 'tabletDashboardSettings' : 'dashboardSettings';

      state.dashboardLayout = payload.data[settingType];
    })
    .addCase(updateDashboardLayoutRequest.fulfilled, (state, { payload }) => {
      const settingType = window.innerWidth < 768 ? 'mobileDashboardSettings'
        : window.innerWidth < 1170 ? 'tabletDashboardSettings' : 'dashboardSettings';

      state.dashboardLayout = payload.data[settingType];
    })
    .addCase(getDashboardStatusCountRequest.fulfilled, (state, { payload }) => {
      state.statusCount = payload.data.data;
    })
    .addCase(getDashboardProductsCountRequest.fulfilled, (state, { payload }) => {
      state.products = payload.data.data;
    })
    .addCase(getDashboardBySalesChannelsRequest.fulfilled, (state, { payload }) => {
      state.bySalesChannels = payload.data.data;
    })
    .addCase(getDashboardPurchaseOrdersRequest.fulfilled, (state, { payload }) => {
      state.purchaseOrders = payload.data.data;
      state.purchaseReceivedOrders = payload.data.receivedOrders;
      state.puchaseTotalCost = payload.data.totalCost;
    })
    .addCase(getDashboardTopProductsRequest.fulfilled, (state, { payload }) => {
      state.topSellingProducts = payload.data.data;
    })
    .addCase(getDashboardOrderSummaryRequest.pending, (state) => {
      state.salesOrdersSummaryStatus = 'request';
    })
    .addCase(getDashboardOrderSummaryRequest.fulfilled, (state, { payload }) => {
      state.salesOrdersSummary = payload.data.data;
      state.salesOrdersSummaryStatus = 'ok';
    })
    .addCase(getDashboardLowQuantityRequest.fulfilled, (state, { payload }) => {
      state.itemsAtLowQuantity = payload.data.data;
    })
    .addCase(getDashboardOutStockRequest.fulfilled, (state, { payload }) => {
      state.itemsOutOfStock = payload.data.data;
    })
    .addCase(getDashboardShopsRequest.fulfilled, (state, { payload }) => {
      state.shops = [{ id: '0', name: 'eSwap' }, ...payload.data.integrations];
    });
});

export default reducer;
