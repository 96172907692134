import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import Input from '../../../_common/Form/Input';
import Select from '../../../_common/Form/Select';
import Utils from '../../../../helpers/Utils';
import ProductVariants from './ProductVariants';

const fields = [
  {
    label: 'Length',
    index: 0,
  },
  {
    label: 'Width',
    index: 1,
  },
  {
    label: 'Height',
    index: 2,
  },
];

const dimensionsUnits = [
  { label: 'inch', value: 'in' },
  { label: 's', value: 's' },
  { label: 'cm', value: 'cm' },
  { label: 'mm', value: 'mm' },
];

const weightUnits = [
  { label: 'oz', value: 'oz' },
  { label: 'lb', value: 'lb' },
  { label: 'g', value: 'g' },
  { label: 'kg', value: 'kg' },
];

function ProductDimensions({
  dimensions, dimensionsUnit, weight, weightUnit, onDataChange, variantId, errors, variants,
}) {
  const [show, setShow] = useState(true);

  const foundErrors = useMemo(() => {
    if (variantId) {
      const foundIndex = variants.findIndex((v) => v.id === variantId);

      return errors?.variants?.[foundIndex];
    }
  }, [errors]);

  return (
    <div className="create_product_dimensions">
      <div className={`create_product_title_block ${!show ? 'not_show' : ''}`}>
        {!variantId && <ArrowIcon onClick={() => setShow((prev) => !prev)} />}

        <h2 className="create_product_title_block_title">Dimensions & weight</h2>
      </div>

      {show && (
        <div className="create_product_dimensions_block">
          <div className="create_product_dimensions_block_top">
            {fields.map(({ label, index }) => (
              <div className="create_product_dimensions_input" key={index}>
                <Input
                  onChangeText={(value) => onDataChange(index, value)}
                  value={dimensions[index]}
                  label={label}
                  size="small"
                  roundBorder
                  onBeforeInput={(e) => Utils.typingFloatNumber(e, null, 2)}
                  error={variantId ? foundErrors?.dimensions?.[index] : errors?.dimensions?.[index]}
                />
              </div>
            ))}

            <div className="create_product_dimensions_input">
              <Select
                options={dimensionsUnits}
                value={dimensionsUnit}
                onChange={(value) => onDataChange('dimensionsUnit', value)}
                size="small"
                roundBorder
                label="Measure units"
              />
            </div>
          </div>

          <div className="create_product_dimensions_block_top">
            <div className="create_product_dimensions_input">
              <Input
                onChangeText={(value) => onDataChange('weight', value)}
                value={weight}
                label="Weight"
                size="small"
                roundBorder
                onBeforeInput={(e) => Utils.typingFloatNumber(e, null, 2)}
              />
            </div>

            <div className="create_product_dimensions_input">
              <Select
                options={weightUnits}
                value={weightUnit}
                onChange={(value) => onDataChange('weightUnit', value)}
                size="small"
                roundBorder
                label="Measure units"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ProductVariants.propTypes = {
  dimensions: PropTypes.array,
  dimensionsUnit: PropTypes.string,
  weight: PropTypes.string,
  weightUnit: PropTypes.string,
  onDataChange: PropTypes.func,
  variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
  variants: PropTypes.array,
};

ProductVariants.defaultProps = {
  variantId: '',
  dimensions: [],
  dimensionsUnit: 'cm',
  weight: '',
  weightUnit: 'oz',
  onDataChange: () => {},
  errors: {},
  variants: [],
};

export default ProductDimensions;
