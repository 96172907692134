import React, { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Wrapper from '../../../Layout/Wrapper';
import AmazonInstall from '../AppInstall/AmazonInstall';
import WooCommerceInstall from '../AppInstall/WooCommerceInstall';
import ShopifyInstall from '../AppInstall/ShopifyInstall';
import EcwidInstall from '../AppInstall/EcwidInstall';
import OdooInstall from '../AppInstall/OdooInstall';
import BigCommerceInstall from '../AppInstall/BigCommerceInstall';
import EbayInstall from '../AppInstall/EbayInstall';
import BonanzaInstall from '../AppInstall/BonanzaInstall';
import VendInstall from '../AppInstall/VendInstall';
import FTPInstall from '../AppInstall/FTPInstall';
import WayfairInstall from '../AppInstall/WayfairInstall';
import FedexInstall from '../AppInstall/FedexInstall';
import UPSInstall from '../AppInstall/UPSInstall';
import USPSInstall from '../AppInstall/USPSInstall';
import CanadaPostInstall from '../AppInstall/CanadaPostInstall';
import DHLInstall from '../AppInstall/DHLInstall';
import DPDInstall from '../AppInstall/DPDInstall';
import PurolatorInstall from '../AppInstall/PurolatorInstall';
import QuickbooksInstall from '../AppInstall/QuickbooksInstall';
import GmailInstall from '../AppInstall/GmailInstall';
import SmtpInstall from '../AppInstall/SmtpInstall';
import XeroInstall from '../AppInstall/XeroInstall';
import MailchimpInstall from '../AppInstall/MailchimpInstall';
import ShipStationInstall from '../AppInstall/ShipStationInstall';
import StripeInstall from '../AppInstall/StripeInstall';
import ShipBobInstall from '../AppInstall/ShipBobInstall';
import CRWishInstall from '../AppInstall/CartRover/CRWishInstall';
import CREtsyInstall from '../AppInstall/CartRover/CREtsyInstall';
import CRWalmartInstall from '../AppInstall/CartRover/CRWalmartInstall';
import CRWayfairInstall from '../AppInstall/CartRover/CRWayfairInstall';
import CRMagento2Install from '../AppInstall/CartRover/CRMagento2Install';
import CRHouzzInstall from '../AppInstall/CartRover/CRHouzzInstall';
import B2BInstall from '../AppInstall/B2BInstall';
import ExtensivWarehouseManagerInstall from '../AppInstall/CartRover/ExtensivWarehouseManagerInstall';
import ExtensivOrderInstall from '../AppInstall/CartRover/ExtensivOrderInstall';
import GoogleShoppingInstall from '../AppInstall/CartRover/GoogleShoppingInstall';
import OpenCartInstall from '../AppInstall/CartRover/OpenCartInstall';
import PrestaShopInstall from "../AppInstall/CartRover/PrestaShopInstall";

const integrations = {
  our: {
    ebay: <EbayInstall />,
    amazon: <AmazonInstall />,
    woocommerce: <WooCommerceInstall />,
    shopify: <ShopifyInstall />,
    ecwid: <EcwidInstall />,
    odoo: <OdooInstall />,
    bigcommerce: <BigCommerceInstall />,
    bonanza: <BonanzaInstall />,
    vend: <VendInstall />,
    'ftp-order': <FTPInstall />,
    'ftp-product': <FTPInstall />,
    'ftp-customer': <FTPInstall />,
    wayfair: <WayfairInstall />,
    fedex: <FedexInstall />,
    shipstation: <ShipStationInstall />,
    ups: <UPSInstall />,
    usps: <USPSInstall />,
    'dhl-express': <DHLInstall />,
    'canada-post': <CanadaPostInstall />,
    'dpd-uk': <DPDInstall />,
    purolator: <PurolatorInstall />,
    quickbooks: <QuickbooksInstall />,
    xero: <XeroInstall />,
    mailchimp: <MailchimpInstall />,
    shipbob: <ShipBobInstall />,
    gmail: <GmailInstall />,
    smtp: <SmtpInstall />,
    stripe: <StripeInstall />,
    b2b: <B2BInstall />,
  },
  cartrover: {
    etsy: <CREtsyInstall />,
    extensiv: <ExtensivWarehouseManagerInstall />,
    'extensiv-order': <ExtensivOrderInstall />,
    'google-shopping': <GoogleShoppingInstall />,
    'open-cart': <OpenCartInstall />,
    'presta-shop': <PrestaShopInstall />,
    houzz: <CRHouzzInstall />,
    'magento-2': <CRMagento2Install />,
    wish: <CRWishInstall />,
    walmart: <CRWalmartInstall />,
    wayfair: <CRWayfairInstall />,
  },
};

function AppInstallWrapper({ type = 'our' }) {
  const { app } = useParams();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);

    if (!integrations[type][app]) {
      navigate('/404', { replace: true });
    }
  }, []);

  return (
    <Wrapper className="app_page_wrapper">
      {integrations[type][app]}
    </Wrapper>
  );
}

AppInstallWrapper.propTypes = {
  type: PropTypes.oneOf(['our', 'cartrover']),
};

export default AppInstallWrapper;
