import React, { useCallback } from 'react';
import _ from 'lodash';
import Select from '../../../../_common/Form/Select';
import Switcher from '../../../../_common/Form/Switcher';
import Input from '../../../../_common/Form/Input';
import { orderStatuses } from '../../../../../helpers/staticData';
import Utils from '../../../../../helpers/Utils';

const fields = [
  {
    title: 'Tax',
    path: 'tax',
    required: true,
    validation: 'currency',
  },
  {
    title: 'Price list fallback',
    path: 'priceListFallback',
    type: 'select',
    options: [],
    // valuePath: 'id',
    // labelPath: 'name',
    // menuPortalTarget: true,
  },
  {
    title: 'Order status',
    path: 'orderStatus',
    type: 'select',
    options: orderStatuses,
  },
  {
    title: 'Min order value',
    path: 'minOrderValue',
    validation: 'currency',
  },
  {
    title: 'Display recommended retail prices (RRP)',
    path: 'rrp',
    type: 'toggle',
  },
];

const B2BGeneral = ({ data, changeData }) => {
  const onChange = useCallback((path, value) => {
    changeData((prev) => {
      const newData = { ...prev };
      _.set(newData, path, value);

      return newData;
    });
  }, []);

  return (
    <div className="integration_step_two_wrapper my_app b2b_orders">
      <div>
        <h2>Orders configs</h2>

        {fields.map(({
          path, title, type, options, validation,
        }) => (
          <div
            key={path}
            className="integration_step_two_fields_wrapper"
          >
            <div className="integration_step_two_fields_left_block">
              <p className="integration_step_two_fields_left_block_title">{title}</p>
            </div>

            <div className="integration_step_two_fields_right_block">
              <div className="integration_step_two_fields_right_block_select">
                {type === 'select'
                  ? (
                    <Select
                      value={data[path]}
                      onChange={(value) => onChange(`settings.${path}`, value)}
                      options={options}
                    // labelPath="name"
                    // valuePath="id"
                      roundBorder
                      size="small"
                    />
                  )

                  : type === 'toggle'
                    ? (
                      <Switcher
                        checked={!!+data[path]}
                        onChange={(check) => onChange(`settings.${path}`, check ? '1' : '0')}
                      />
                    )

                    : (
                      <Input
                        size="small"
                        roundBorder
                        value={data[path]}
                        onBeforeInput={(e) => Utils.onBeforeInput(e, validation)}
                        onChangeText={(val) => onChange(`settings.${path}`, val)}
                      />
                    )}
              </div>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

B2BGeneral.propTypes = {};

export default B2BGeneral;
