import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { ReactComponent as ShipStationIcon } from '../../../../assets/icons/apps/ship_station.svg';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { appInstallFields } from '../../../../helpers/staticData';

function ShipStationInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const { data: { integration: { id } } } = await Api.createIntegration(app, data);

      navigate(`/apps/integration/${id}`);
      toast.success('App has been successfully installed');
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="ShipStation"
      shopIcon={<ShipStationIcon />}
      description={'eSwap\'s Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      Integration info

      <UpdateModal
        isOpen={installModal}
        fields={appInstallFields.shipstation}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${_.upperFirst(app)}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default ShipStationInstall;
