import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import Account from '../../helpers/Account';

const { REACT_APP_GOOGLE_AUTH_CLIENT_ID } = process.env;

const LogoutLayout = ({ children, title }) => {
  const isAuthorized = Account.getToken();

  if (isAuthorized) return <Navigate to="/" />;

  return (
    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
      <div className="logout_layout">
        <div className="logout_layout_logo_wrapper">
          <LogoIcon />
        </div>

        <div className="logout_layout_content">
          <h1>{title}</h1>

          <div className="logout_layout_content_form_wrapper">
            {children}
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

LogoutLayout.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
};

export default LogoutLayout;
