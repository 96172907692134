import { useEffect, useState } from 'react';

const useWindowSize = (mobileMaxWidth = 767) => {
  const [isMobile, isMobileToggle] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const handleScreen = () => {
      isMobileToggle(window.innerWidth <= mobileMaxWidth);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleScreen);

    handleScreen();

    return () => window.removeEventListener('resize', handleScreen);
  }, []);

  return { isMobile, windowWidth };
};

export default useWindowSize;
