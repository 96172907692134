import React from 'react';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import noImage from '../../../../assets/icons/default.svg';
import Button from '../../../_common/Form/Button';

const columns = [
  {
    key: 'item', isEnabled: true,
  },
  {
    key: 'sku', isEnabled: true,
  },
  {
    key: 'dimensions', isEnabled: true,
  },
  {
    key: 'weight', isEnabled: true,
  },
];

const defaultAttributes = {
  item: 'Item',
  sku: 'SKU',
  dimensions: 'Dimensions',
  weight: 'Weight',
};

const BulkProductLabels = ({ productItems, onClose }) => (
  <div className="bulk_edit_modal_wrapper bulk_label">
    <h2>Bulk product labels</h2>

    {/* <div className="bulk_edit_modal_table_edit"> */}
    {/* /!*  <FilterButton*!/ */}
    {/* /!*    className="filter_button dots"*!/ */}
    {/* /!*    onClick={() => {*!/ */}
    {/* /!*    }}*!/ */}
    {/* /!*  >*!/ */}
    {/* /!*    <TableEditIcon />*!/ */}
    {/* /!*  </FilterButton>*!/ */}
    {/* /!*</div>*!/ */}

    <div className="bulk_edit_modal_table">
      <DynamicTable
          // onChange={(a.svg)=>{
          //   console.log(a.svg)}}
        hideOrShow={false}
        loading={false}
        data={productItems}
        keyExtractor={(item) => item.id}
        columns={columns}
        renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
        renderColumns={{
          item: ({ item }) => (
            <div className="bulk_edit_modal_item_wrapper">
              <div className="bulk_edit_modal_item_img">
                <img
                  src={item.images[0]?.src || item.images[0]?.medium || noImage}
                  alt="img"
                />
              </div>

              <p>{item.title}</p>
            </div>
          ),

          sku: ({ item }) => (item.sku || '-'),

          dimensions: ({ item }) => (item.dimensions.length
            ? `${item.dimensions.join('x')} ${item.dimensionsUnit}`
            : '-'),

          weight: ({ item }) => (`${item.weight || 0} ${item.weightUnit || 'kg'}`),
        }}
      />

    </div>

    <div className="bulk_edit_modal_buttons_wrapper">
      <Button
        className="bulk_edit_modal_button"
        size="small"
        title="Cancel"
        onClick={onClose}
        roundBorder
        btnType="cancel"
      />

      <Button
        className="bulk_edit_modal_button"
        size="small"
        title="Export labels"
        onClick={() => {
        }}
        roundBorder
        color="#1472FF"
        loading={false}
      />
    </div>

  </div>
);

export default BulkProductLabels;
