import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment/moment';
import { format } from 'currency-formatter';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import Button from '../../../_common/Form/Button';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel_red.svg';
import { ReactComponent as QBooksIcon } from '../../../../assets/integrations/quickBooks.svg';
import { ReactComponent as XeroIcon } from '../../../../assets/integrations/xero.svg';
import { ReactComponent as StripeIcon } from '../../../../assets/integrations/stripe.svg';
import Api from '../../../../Api';
import {
  cancelInvoiceRequest,
  deleteInvoiceRequest,
  getOrderInvoicesRequest, sendInvoiceToAccountingRequest,
} from '../../../../store/actions/invoices';
import Utils from '../../../../helpers/Utils';
import { getSingleOrderRequest } from '../../../../store/actions/orders';
import { getIntegrationsRequest } from '../../../../store/actions/integrations';
import TableDropdown from './TableDropdown';

function Invoices() {
  const [loading, setLoading] = useState();
  const [dropdown, setDropdown] = useState(null);
  const [deletingData, setDeletingData] = useState({});
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [integrations, setIntegrations] = useState([]);

  const dispatch = useDispatch();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const invoices = useSelector((state) => state.invoices.orderInvoices);
  const order = useSelector((state) => state.orders.order);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getIntegrationsRequest({ category: 'accounting' }));
      setIntegrations(payload?.data?.integrations || []);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getOrderInvoicesRequest(orderId));
      setLoading(false);
    })();
  }, [orderId]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    let datum = {};
    if (deletingData.status === 'canceled') {
      const { payload } = await dispatch(deleteInvoiceRequest(deletingData.id));
      datum = payload.data;
    } else {
      const { payload } = await dispatch(cancelInvoiceRequest(deletingData.id));
      datum = payload.data;
    }
    if (datum.status === 'error') {
      toast.error(datum.message);
    } else {
      await dispatch(getOrderInvoicesRequest(orderId));
      await dispatch(getSingleOrderRequest(orderId));
    }
    setLoadingDelete(false);
    setDeletingData({});
  }, [deletingData, orderId]);

  const handleSendToAccounting = useCallback(async (invoiceId, integrationId) => {
    const { payload } = await dispatch(sendInvoiceToAccountingRequest({ orderId, invoiceId, integrationId }));
    await dispatch(getOrderInvoicesRequest(orderId));
    toast.warning(payload.message);
  }, [orderId]);

  return (
    <div className="invoices packages">
      {!loading && _.isEmpty(invoices) ? (
        <div className="no_packages">
          No Invoices created so far
          <Button
            btnType="transparent"
            onClick={() => navigate(`${location.pathname}${location.search}`, {
              state: { invoice: true },
              replace: true,
            })}
          >
            {`Create ${order.type === 'purchase' ? 'bill' : 'invoice'}`}
          </Button>
        </div>
      ) : null}
      {!_.isEmpty(invoices) && !loading ? (
        <div className="table_wrapper">
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">{order.type === 'purchase' ? 'Bill#' : 'Invoice#'}</th>
                <th className="table_thead_tr_th">Date</th>
                <th className="table_thead_tr_th">Status</th>
                <th className="table_thead_tr_th">Due date</th>
                <th className="table_thead_tr_th">Amount</th>
                <th className="table_thead_tr_th">Balance Due</th>
                <th className="table_thead_tr_th">Available on</th>
                <th />
              </tr>
            </thead>
            <tbody className="table_tbody">
              {invoices.map((p, index) => {
                const quickbooks = p.accountingInvoice.find((a) => a.accounting === 'quickbooks');
                const xero = p.accountingInvoice.find((a) => a.accounting === 'xero');
                const stripe = p.accountingInvoice.find((a) => a.accounting === 'stripe');
                const payments = p.payments?.filter((p) => p.status !== 'canceled');
                return (
                  <tr
                    className="table_tbody_tr"
                    key={p.id}
                  >
                    <td className="table_tbody_tr_td number">
                      <Link
                        data-scope="READ_ORDER_INVOICES"
                        to={order.type === 'purchase'
                          ? `/stocks/bills/preview/${p.id}` : `/orders/invoices/preview/${p.id}`}
                      >
                        {p.number}
                      </Link>
                    </td>
                    <td className="table_tbody_tr_td">
                      {p.sourceCreatedAt ? moment(p.sourceCreatedAt).format('DD MMM YYYY') : null}
                    </td>
                    <td className="table_tbody_tr_td">
                      <div className={`status ${p.status}`}>
                        <p className="status_dot" />
                        <span>{Utils.formatStatus(p.status)}</span>
                      </div>
                    </td>
                    <td className="table_tbody_tr_td">
                      {p.dueDate ? moment(p.dueDate).format('DD MMM YYYY') : null}
                    </td>
                    <td className="table_tbody_tr_td">
                      {format(p.totalPrice, { code: order.currencyCode })}
                    </td>
                    <td className="table_tbody_tr_td">
                      {format(
                        p.totalPrice - _.sumBy(payments, 'amount') > 0
                          ? p.totalPrice - _.sumBy(payments, 'amount')
                          : 0,
                        { code: order.currencyCode },
                      )}
                    </td>
                    <td className="table_tbody_tr_td available_on">
                      {quickbooks
                        ? (
                          <a href={quickbooks.url} target="_blank" rel="noreferrer">
                            <QBooksIcon />
                          </a>
                        ) : null}
                      {xero
                        ? (
                          <a href={xero.url} target="_blank" rel="noreferrer">
                            <XeroIcon />
                          </a>
                        ) : null}
                      {stripe
                        ? (
                          <a href={stripe.url} target="_blank" rel="noreferrer">
                            <StripeIcon />
                          </a>
                        ) : null}
                    </td>
                    <td className="table_tbody_tr_td">
                      <div className="dot_icon" onClick={() => setDropdown(p.id)}>
                        <DotsIcon />
                      </div>
                      {dropdown === p.id ? (
                        <TableDropdown onClose={() => setDropdown(null)} top={50 + ((index + 1) * 45)}>
                          {p.status !== 'canceled' && !quickbooks && integrations.some((i) => i.type === 'quickbooks')
                            ? (
                              <li
                                data-scope="WRITE_ORDER_INVOICES"
                                onClick={() => handleSendToAccounting(
                                  p.id,
                                  integrations.find((i) => i.type === 'quickbooks').id,
                                )}
                              >
                                Send to QuickBook
                              </li>
                            ) : null}
                          {p.status !== 'canceled' && !xero && integrations.some((i) => i.type === 'xero')
                            ? (
                              <li
                                data-scope="WRITE_ORDER_INVOICES"
                                onClick={() => handleSendToAccounting(
                                  p.id,
                                  integrations.find((i) => i.type === 'xero').id,
                                )}
                              >
                                Send to Xero
                              </li>
                            ) : null}
                          {p.status !== 'canceled' && !stripe && integrations.some((i) => i.type === 'stripe')
                            ? (
                              <li
                                data-scope="WRITE_ORDER_INVOICES"
                                onClick={() => handleSendToAccounting(
                                  p.id,
                                  integrations.find((i) => i.type === 'stripe').id,
                                )}
                              >
                                Send to Stripe
                              </li>
                            ) : null}
                          {['unpaid', 'partial_paid'].includes(p.status)
                            ? (
                              <li
                                data-scope="WRITE_ORDER_INVOICES"
                                onClick={() => navigate(
                                  `${location.pathname}${location.search}`,
                                  { state: { payment: p.id }, replace: true },
                                )}
                              >
                                Register payment
                              </li>
                            ) : null}
                          <li onClick={async () => {
                            setLoadingDownload(true);
                            const { data } = await Api.downloadInvoice(p.id);
                            setLoadingDownload(false);
                            fileDownload(data, 'document.pdf');
                          }}
                          >
                            {`Download ${order.type === 'purchase' ? 'bill' : 'invoice'}`}
                            {loadingDownload ? (
                              <div className="loader_wrapper" style={{ right: 7, top: 9 }}>
                                <span style={{ width: 15, height: 15 }} />
                              </div>
                            ) : null}
                          </li>
                          {order.type === 'sale' ? (
                            <li
                              onClick={() => setDeletingData({ id: p.id, status: p.status })}
                              className="delete"
                              data-scope="DELETE_ORDER_INVOICES"
                            >
                              {p.status === 'canceled' ? 'Delete invoice' : 'Cancel invoice'}
                            </li>
                          ) : (
                            <li
                              onClick={() => setDeletingData({ id: p.id, status: p.status })}
                              className="delete"
                              data-scope="DELETE_ORDER_INVOICES"
                            >
                              {p.status === 'canceled' ? 'Delete bill' : 'Cancel bill'}
                            </li>
                          )}
                        </TableDropdown>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
      <DeleteModal
        isOpen={!!deletingData.id}
        onDelete={handleDelete}
        onClose={() => setDeletingData({})}
        loading={loadingDelete}
        text={order.type === 'purchase' ? 'bill' : 'invoice'}
        deleteType={deletingData.status !== 'canceled' ? 'cancel' : 'delete'}
        icon={deletingData.status !== 'canceled' ? <CancelIcon /> : undefined}
        btnTitle={deletingData.status !== 'canceled' ? 'confirm' : 'delete'}
      />

    </div>
  );
}

export default Invoices;
