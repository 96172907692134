import React, { useCallback, useId } from 'react';
import grapesjs from 'grapesjs';
import GjsEditor from '@grapesjs/react';
import webpagePlugin from 'grapesjs-preset-webpage';
import basicPlugin from 'grapesjs-blocks-basic';
import imageEditorPlugin from 'grapesjs-tui-image-editor';
import { blocks, styleManager, templateStyles } from './GJSData';
import './_grapes_editor.scss';
import 'grapesjs/dist/css/grapes.min.css';

const GrapesEditor = ({ template }) => {
  const id = useId();

  const onLoadGjs = useCallback((editor) => {
    const command = editor.Commands;
    const panel = editor.Panels;

    // Change dragMode
    command.add('change_mode', {
      run() {
        // Drag Mode On
        editor.getModel().set('dmode', 'absolute');
      },
      stop() {
        // Drag Mode Off
        editor.getModel().set('dmode', '');
      },
    });

    // Add dragMode shortcut icon
    panel.addButton('options', [{
      className: 'fa fa-hand-rock-o',
      command: 'change_mode',
      attributes: {
        title: 'Drag Mode',
      },
    }]);

    // Add drop functionality after changing dragMode
    editor.on('component:drag:end', (event) => {
      const dragMode = editor.getModel().getDragMode();

      if (!dragMode) {
        const component = event.target;

        const style = component.getStyle();
        const resetStyleTypes = ['left', 'right', 'top', 'bottom', 'height', 'width', 'min-width', 'max-width',
          'min-height', 'max-height'];

        resetStyleTypes.forEach((type) => delete style[type]);

        component.setStyle({ ...style, position: 'static' });
      }

      // component.set('style', { position: 'static' });
      editor.refresh();
    });

    // Add shortcode functionality
    // editor.RichTextEditor.add('shortcodes', {
    //   name: 'Shortcodes',
    //   icon: `
    //         <div class="dropdown-wrapper">
    //            <div class="icon-wrapper">
    //              <svg onclick="toggle()"
    //                   width="15px" height="15px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    //                <path d="M6 14H4V6h2V4H2v12h4M7.1 17h2.1l3.7-14h-2.1M14 4v2h2v8h-2v2h4V4" fill="#FFFFFF"/>
    //              </svg>
    //
    //             <div  tabindex="0" class="dropdown_content">
    //             <input type="text" id="shortcode_search" oninput="filterShortcodes(this)">
    //
    //               <ul id="shortcode_content">
    //                 <li class="dropdown_item" onclick="selectShortcode('[email=Email]')">
    //                   Email
    //                 </li>
    //
    //                 <li class="dropdown_item" onclick="selectShortcode('[date=Date]')">
    //                   Date
    //                 </li>
    //               </ul>
    //             </div>
    //            </div>
    //        </div>`,
    //   event: 'change',
    //   result: () => {
    //   },
    // });

    // const setShortcodeDefaultState = () => {
    //   document.querySelector('.dropdown-wrapper').classList.remove('active');
    //
    //   const input = document.getElementById('shortcode_search');
    //   input.value = '';
    //
    //   window.filterShortcodes(input);
    // };
    //
    // editor.on('rte:enable', () => {
    //   setShortcodeDefaultState();
    // });
    //
    // window.toggle = function toggle() {
    //   document.querySelector('.dropdown-wrapper').classList.toggle('active');
    // };
    //
    // window.selectShortcode = function selectShortcode(value) {
    //   const canvasDoc = editor.Canvas.getBody().ownerDocument;
    //   canvasDoc.execCommand('insertText', false, value);
    //
    //   setShortcodeDefaultState();
    // };
    //
    // window.filterShortcodes = function filterShortcodes(input) {
    //   const inputValue = input.value.toUpperCase();
    //   const content = document.getElementById('shortcode_content');
    //   const items = content.getElementsByClassName('dropdown_item');
    //
    //   [...items].forEach((item) => {
    //     const title = item.innerText.toUpperCase();
    //
    //     if (title.includes(inputValue)) {
    //       item.style.display = '';
    //     } else {
    //       item.style.display = 'none';
    //     }
    //   });
    // };
  }, []);

  return (
    <div id="gjs_editor">
      <GjsEditor
        grapesjs={grapesjs}
        onReady={onLoadGjs}
        key={id}
        options={{
          showDevices: false,
          height: '100%',
          storageManager: false,
          plugins: [basicPlugin, imageEditorPlugin, webpagePlugin],
          styleManager,
          components: template.html,
          style: templateStyles,
          resizable: true,
          blockManager: {
            custom: true,
            blocks,
          },
        }}
      />
    </div>
  );
};

export default GrapesEditor;
