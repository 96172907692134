import React, { useMemo } from 'react';
import SelectedCard from './SelectedCard';
import Input from '../../../_common/Form/Input';

const StepTwo = ({ changeData, data }) => {
  const changeSaleData = (type) => {
    changeData((prev) => {
      const foundData = prev.sale.includes(type);

      if (foundData) {
        return { ...prev, sale: prev.sale.filter((s) => s !== type) };
      }

      return { ...prev, sale: [...prev.sale, type] };
    });
  };

  const changeOtherValue = (value) => {
    changeData((prev) => ({ ...prev, saleOtherValue: value }));

    changeData((prev) => {
      if (!value.trim()) {
        return { ...prev, sale: prev.sale.filter((s) => s !== 'other') };
      }

      if (!prev.sale.includes('other')) {
        return { ...prev, sale: [...prev.sale, 'other'] };
      }

      return prev;
    });
  };

  const info = useMemo(() => [
    {
      type: 'sale',
      content: (
        <div>
          <p className="selected_card_top_description">I sell my products online</p>
          <p className="selected_card_bottom_description">via Shopify, eBay, Amazon, WooCommerce or others</p>
        </div>
      ),
    },
    {
      type: 'retail',
      content: (
        <div>
          <p className="selected_card_top_description">I sell my products wholesale</p>
          <p className="selected_card_bottom_description">to retailers, wholesalers, or other parties for resale</p>
        </div>
      ),
    },
    {
      type: 'other',
      content: (
        <div>
          <p className="selected_card_top_description">I sell using other ways</p>

          <Input
            value={data.saleOtherValue}
            onChangeText={(value) => changeOtherValue(value)}
            size="small"
            roundBorder
          />
        </div>
      ),
    },
  ], [data]);

  return (
    <div className="onboarding_page_steps_content">
      {info.map(({ type, content }) => (
        <SelectedCard
          key={type}
          onChange={() => type !== 'other' && changeSaleData(type)}
          checked={data.sale.includes(type)}
          hideCheckbox={type === 'other'}
        >
          {content}
        </SelectedCard>
      ))}
    </div>
  );
};

export default StepTwo;
