import React, {
  forwardRef, useCallback, useId, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeCloseIcon } from '../../../assets/icons/eye-fill.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';

const Input = forwardRef(({
  label, wrapperClassName, value, placeholder, onChange, onChangeText, roundBorder, textarea, password, error, type,
  fieldset, size, search, symbol, disabled, tooltip, tooltipPlace, ...p
}, ref) => {
  const id = useId();

  const [currentType, setCurrentType] = useState(password ? 'password' : type);

  const changeType = useCallback(() => {
    setCurrentType((prev) => (prev === 'password' ? 'text' : 'password'));
  }, []);

  const handleChange = useCallback((ev) => {
    if (onChange) {
      onChange(ev);
    }

    if (onChangeText) {
      onChangeText(ev.target.value);
    }
  }, [onChange, onChangeText]);

  const Component = textarea ? 'textarea' : 'input';

  return (
    <div
      className={classNames('input_wrapper', wrapperClassName, {
        error,
        round_border: roundBorder,
        search,
        password,
        textarea,
        fieldset,
        symbol,
        [`size_${size}`]: !!size,
      })}
      data-tooltip-id={tooltip ? 'default-tooltip' : undefined}
      data-tooltip-content={tooltip}
      data-tooltip-place={tooltip ? tooltipPlace : undefined}
    >
      {label && <label htmlFor={id} className="label">{label}</label>}

      <div className="input_field_wrapper">
        {search && <label htmlFor={id} className="search_icon"><SearchIcon /></label>}

        {symbol && <label htmlFor={id} className="symbol_wrapper">{symbol}</label>}

        <Component
          id={id}
          ref={ref}
          placeholder={placeholder}
          value={value}
          autoComplete={password ? 'new-password' : undefined}
          onChange={handleChange}
          type={currentType}
          className={classNames({ overflowY: textarea })}
          disabled={disabled}
          {...p}
        />

        {password && (
          <button
            className="eye_icon"
            onClick={changeType}
            type="button"
          >
            {currentType === 'password' ? <EyeIcon /> : <EyeCloseIcon />}
          </button>
        )}

        {!!error && typeof error === 'string' && <p className="error_text">{error}</p>}
      </div>
    </div>
  );
});

Input.propTypes = {
  wrapperClassName: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placeholder: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textarea: PropTypes.bool,
  password: PropTypes.bool,
  roundBorder: PropTypes.bool,
  fieldset: PropTypes.bool,
  search: PropTypes.bool,
  symbol: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPlace: PropTypes.oneOf(['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom',
    'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end']),
};

Input.defaultProps = {
  wrapperClassName: 'text',
  type: 'text',
  label: '',
  value: '',
  placeholder: '',
  size: 'default',
  textarea: false,
  password: false,
  error: false,
  roundBorder: false,
  fieldset: false,
  search: false,
  symbol: '',
  onChange: undefined,
  onChangeText: undefined,
  disabled: false,
  tooltip: undefined,
  tooltipPlace: 'top',
};

export default Input;
