import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Invoices from './Invoices';
import Packages from './Packages';
import Payments from './Payments';
import Returns from './Returns';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/warning_tansparent.svg';
import BackorderFormModal from './BackorderFormModal';
import Backorders from './Backorders';
import CreditNotes from './CreditNotes';

const tabs = [
  {
    key: 'invoices', salesLabel: 'Invoices', purchaseLabel: 'Bills', scope: 'READ_ORDER_INVOICES',
  },
  {
    key: 'packages', salesLabel: 'Packages', purchaseLabel: 'Receives', scope: 'READ_ORDER_PACKAGES',
  },
  {
    key: 'payments', salesLabel: 'Payments', purchaseLabel: 'Payments', scope: 'READ_ORDER_INVOICES',
  },
  {
    key: 'returns', salesLabel: 'Returns', purchaseLabel: '', scope: 'READ_ORDER_RETURNS',
  },
  {
    key: 'purchase_orders',
    salesLabel: 'Purchase orders',
    purchaseLabel: 'Associated sales orders',
    scope: 'READ_PURCHASE_ORDERS',
  },
  {
    key: 'credit_notes', salesLabel: 'Credit notes', purchaseLabel: '', scope: 'READ_CREDIT_NOTES',
  },
];

function ActionTabs() {
  const [tab, setTab] = useState('invoices');
  const [backorderModal, setBackorderModal] = useState(false);
  const [searchParams] = useSearchParams({});

  const order = useSelector((state) => state.orders.order);
  const withNegativeStock = useSelector((state) => state.products.withNegativeStock);

  useEffect(() => {
    if (searchParams.get('tab')) {
      setTab(searchParams.get('tab'));
    }
  }, [searchParams.get('tab')]);

  const filteredTabs = useMemo(() => {
    if (order.type === 'purchase') {
      return tabs.filter((t) => t.purchaseLabel);
    }
    return tabs;
  }, [order.type, tabs]);

  return (
    <div>
      {order.type === 'sale' && order.status !== 'draft' && !_.isEmpty(withNegativeStock) && !order.backorder ? (
        <div className="warning_notice">
          <InfoIcon />
          <div>
            <p>Purchase order required</p>
            <span>One or more of your items cannot be fulfilled with the available stock.</span>
            <span className="link" onClick={() => setBackorderModal(true)}>Create purchase order</span>
          </div>
        </div>
      ) : null}
      <div className="action_tabs">
        <div className="tabs">
          {filteredTabs.map((t) => (
            <div
              key={t.key}
              data-scope={t.scope}
              className={classNames('tab', { active: tab === t.key })}
              onClick={() => setTab(t.key)}
            >
              {order.type === 'purchase' ? t.purchaseLabel : t.salesLabel}
            </div>
          ))}
        </div>
        {tab === 'invoices' ? <Invoices /> : null}
        {tab === 'packages' ? <Packages /> : null}
        {tab === 'payments' ? <Payments /> : null}
        {tab === 'returns' ? <Returns /> : null}
        {tab === 'purchase_orders' ? <Backorders /> : null}
        {tab === 'credit_notes' ? <CreditNotes /> : null}
      </div>
      {backorderModal ? (
        <BackorderFormModal
          isOpen={backorderModal}
          onClose={() => setBackorderModal(false)}
          products={withNegativeStock}
        />
      ) : null}
    </div>
  );
}

export default ActionTabs;
