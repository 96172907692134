import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '../Form/Checkbox';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeCloseIcon } from '../../../assets/icons/eye-fill.svg';
import { ReactComponent as DraggableIcon } from '../../../assets/icons/dragble.svg';
import { ReactComponent as NoMatchIcon } from '../../../assets/icons/no_match.svg';
import Loader from '../Loader/Loader';

const DynamicTable = ({
  columns, renderColumns, renderColumnHeader, data, callDragDisabled,
  keyExtractor, editable, onChange, onSelect, loading, checkbox, checkedItems,
  renderChildColumns, openChildData, childData, childLoading, hideOrShow, errorIndex,
}) => {
  const [checked, setChecked] = useState(new Set());

  useMemo(() => {
    if (checkedItems?.length === 0) {
      checked.clear();
    }
  }, [checkedItems?.length]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    onChange(reorder(
      columns,
      result.source.index,
      result.destination.index,
    ));
  };

  const handleCheck = (key) => {
    if (key) {
      if (checked.has(key)) {
        checked.delete(key);
      } else {
        checked.add(key);
      }
    } else if (checked.size === data.length) {
      checked.clear();
    } else {
      data.forEach((d) => checked.add(keyExtractor(d)));
    }
    setChecked(new Set(checked));
    if (onSelect) {
      onSelect(Array.from(checked));
    }
  };

  const handleVisibleChange = (item) => () => {
    onChange(columns.map((d) => {
      if (d.key === item.key) {
        return {
          ...d,
          isEnabled: !d.isEnabled,
        };
      }
      return d;
    }));
  };

  return (
    <div className={classNames('dynamic_table', 'table', { editable })}>
      {loading && <Loader />}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(droppableProvided) => (
            <div
              className={classNames('', { loading })}
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {checkbox && (
                <div className="column column_checkbox">
                  <div className="row header">
                    <Checkbox
                      checked={(checked.size === data.length) && data.length !== 0}
                      indeterminate={Array.from(checked).length}
                      onChange={() => handleCheck()}
                      disabled={data.length === 0}
                    />
                  </div>

                  {data.map((datum, i) => {
                    const key = keyExtractor(datum, i);

                    if (openChildData.includes(datum.id)) {
                      return (
                        <div key={key}>
                          <div className={`row row_${i + 1}`}>
                            <Checkbox
                              name="selection[]"
                              value={key}
                              checked={checked.has(key)}
                              onChange={() => handleCheck(key)}
                            />
                          </div>

                          {childLoading === datum.id && (
                          <div
                            className="row-child first firstChild"
                          />
                          )}

                          {childData?.[openChildData.find((p) => p === datum.id)]?.map((childDatum, index) => (
                            <div
                              className={classNames('row-child first', {
                                firstChild: index === 0,
                              })}
                              key={keyExtractor(childDatum)}
                            />
                          ))}
                        </div>
                      );
                    }

                    return (
                      <div key={key} className={`row row_${i + 1}`}>
                        <Checkbox
                          name="selection[]"
                          value={key}
                          checked={checked.has(key)}
                          onChange={() => handleCheck(key)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              {columns.filter((item) => item.isEnabled || editable || hideOrShow)
                .map((item, index) => {
                  const isDragDisabled = !editable || item.isDragDisabled || callDragDisabled(item);

                  return (
                    <Draggable
                      key={item.key}
                      index={index}
                      isDragDisabled={isDragDisabled}
                      draggableId={item.key}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className={classNames('column', `column_${item.key}`, {
                            disabled: !item.isEnabled,
                            dragging: snapshot.isDragging,
                          })}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="row header">
                            {!isDragDisabled && <DraggableIcon />}

                            {renderColumnHeader(item)}

                            {(!isDragDisabled || hideOrShow) && (
                              <div
                                tabIndex={0}
                                role="button"
                                onClick={handleVisibleChange(item)}
                                className="toggle-table-view"
                              >
                                {item.isEnabled ? <EyeIcon /> : <EyeCloseIcon />}

                              </div>
                            )}
                          </div>

                          {data.map((datum, i) => (
                            <div key={keyExtractor(datum, i)}>
                              <div className={classNames(`row row_${i + 1}`, {
                                error: errorIndex.includes(i) && renderColumns.error,
                              })}
                              >
                                {renderColumns[item.key] ? (
                                  <div className="table_error">
                                    {renderColumns[item.key]({
                                      item: datum,
                                      index: i,
                                      columnIndex: index,
                                      column: item,
                                    })}

                                    {errorIndex.includes(i) && index === 0 && renderColumns.error && (
                                      renderColumns.error({
                                        item: datum,
                                        index: i,
                                        columnIndex: index,
                                        column: item,
                                      }))}
                                  </div>

                                ) : (
                                  '--EMPTY--'
                                )}
                              </div>

                              {openChildData.includes(datum.id)
                                && (childLoading === datum.id
                                  ? (
                                    <div
                                      className="row-child"
                                      style={{ position: index === 0 ? 'relative' : 'static' }}
                                    >
                                      { index === 0 && <Loader size={25} />}
                                    </div>
                                  )
                                  : childData?.[openChildData.find((p) => p === datum.id)]?.map((childDatum) => (
                                    <div className="row-child " key={keyExtractor(childDatum)}>
                                      {renderChildColumns[item.key]({
                                        item: childDatum,
                                        columnIndex: index,
                                        column: item,
                                      })}
                                    </div>
                                  )))}
                            </div>

                          ))}
                        </div>
                      )}
                    </Draggable>
                  );
                })}

              {renderColumns.actions && (
                <Draggable
                  key="actions"
                  index={columns.length}
                  isDragDisabled
                  draggableId="actions"
                  isEnabled={false}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classNames('column', 'column_actions', {
                        disabled: false,
                        dragging: snapshot.isDragging,
                      })}
                    >
                      <div className="row header" />

                      {data.map((item, i) => (
                        <div key={keyExtractor(item, i)}>
                          <div className={`row row_${i + 1}`}>
                            {renderColumns.actions({
                              item,
                              index: i,
                              column: 'actions',
                            })}
                          </div>

                          {childLoading === item.id && openChildData.includes(item.id) && (
                            <div
                              className="row-child"
                            />
                          )}

                          {openChildData.includes(item.id) && !childLoading
                            && (
                              childData?.[openChildData.find((p) => p === item.id)]?.map((childDatum) => (
                                <div className="row-child" key={keyExtractor(childDatum)}>
                                  {renderChildColumns.actions({
                                    item: childDatum,
                                    index: i,
                                    column: 'actions',
                                  })}
                                </div>
                              )))}
                        </div>

                      ))}
                    </div>
                  )}
                </Draggable>
              )}

              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!data.length && (
        !loading ? (
          <div className="no_match">
            <NoMatchIcon />
            <p>No data matched your search</p>
          </div>
        ) : <div className="no_match loading" />
      )}
    </div>
  );
};

DynamicTable.propTypes = {
  onChange: PropTypes.func,
  keyExtractor: PropTypes.func,
  onSelect: PropTypes.func,
  callDragDisabled: PropTypes.func,
  editable: PropTypes.bool,
  childLoading: PropTypes.string,
  renderColumns: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  checkedItems: PropTypes.array,
  loading: PropTypes.bool,
  checkbox: PropTypes.bool,
  hideOrShow: PropTypes.bool,
  renderChildColumns: PropTypes.object,
  openChildData: PropTypes.array,
  childData: PropTypes.object,
  errorIndex: PropTypes.array,
};

DynamicTable.defaultProps = {
  childData: {},
  openChildData: [],
  renderChildColumns: {},
  editable: false,
  childLoading: '',
  checkbox: false,
  hideOrShow: false,
  loading: false,
  onSelect: null,
  checkedItems: null,
  errorIndex: [],
  onChange: () => {
  },
  keyExtractor: (d) => d.id || d.key,
  callDragDisabled: () => false,
};

export default DynamicTable;
