import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import Modal from '../../../_common/Modal/Modal';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Select from '../../../_common/Form/Select';
import Switcher from '../../../_common/Form/Switcher';
import Api from '../../../../Api';
import Utils from '../../../../helpers/Utils';
import { cardTypes } from '../../../../helpers/staticData';
import Loader from '../../../_common/Loader/Loader';
import AddressAutocomplete from '../../../_common/Form/AddressAutocomplete';

const fields = [
  {
    path: 'email',
    label: 'Email',
  },
  {
    path: 'country',
    label: 'Country',
    type: 'select',
    optionPath: 'code',
    optionLabel: 'name',
  },
  {
    path: 'state',
    label: 'State',
    type: 'select',
    optionPath: 'name',
    optionLabel: 'name',
  },
  {
    path: 'city',
    label: 'City',
  },
  {
    path: 'zip',
    label: 'Zip',
  },
];

const defaultCardData = {
  cardName: '',
  // email: '',
  // country: '',
  // state: '',
  // city: '',
  // address: '',
  // zip: '',
};

const ModalAddPayment = memo(({
  isOpen, onClose, zIndex, className, onSave,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  // const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  // const [statesLoading, statesLoadingToggle] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [cardType, setCardType] = useState('');

  const [cardData, setCardData] = useState(defaultCardData);

  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const [animOn, animToggle] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     const payload = await Api.getCountries();
  //
  //     setCountries(payload.data.countries);
  //
  //     statesLoadingToggle(false);
  //   })();
  // }, []);

  // useEffect(() => {
  //   if (cardData.country) {
  //     (async () => {
  //       statesLoadingToggle(true);
  //
  //       const payload = await Api.getRegions(cardData.country);
  //
  //       setStates(payload.data.regions);
  //       statesLoadingToggle(false);
  //     })();
  //   }
  // }, [cardData.country]);

  useEffect(() => {
    if (!isOpen) {
      setCardData(defaultCardData);
      setCardType('');
      setErrors({});
      animToggle(false);
    } else {
      setTimeout(() => animToggle(true), 350);
    }
  }, [isOpen]);

  const onDataChange = useCallback((path, value) => {
    console.log(path, 7777, value);
    setCardData((prev) => ({
      ...prev,
      [path]: value,
    }));

    setCardType(value?.brand || '');

    setErrors((prev) => ({
      ...prev,
      [path]: value?.error?.message || '',
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);

    if (!elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      toast.error(submitError.message);
      return;
    }

    // let hasError = false;

    // fields.forEach(({ path, label }) => {
    //   if (!cardData[path].trim()) {
    //     setErrors((prev) => ({ ...prev, [path]: `${label} is required` }));
    //     hasError = true;
    //   } else if (path === 'email' && !Utils.isEmail(cardData[path])) {
    //     setErrors((prev) => ({ ...prev, [path]: 'Enter valid email' }));
    //     hasError = true;
    //   }
    // });

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: cardData.cardName,
        // email: cardData.email,
        // address: {
        //   country: cardData.country,
        //   state: cardData.state,
        //   city: cardData.city,
        //   postal_code: cardData.zip,
        //   line1: cardData.address,
        // },
      },
      // elements,
      // clientSecret,
      // confirmParams: {
      //   return_url: 'https://example.com/order/123/complete',
      // },
    });

    if (error) {
      console.log(error, 'ERROR');

      const path = error.param === 'number' ? 'cardNumber'
        : error.param === 'cvc' ? 'cardCvc' : 'cardExpiry';

      setErrors((prev) => ({
        ...prev,
        [path]: error.message,
      }));

      setBtnLoading(false);
    } else {
      // if (!hasError) {
      try {
        await Api.attachPaymentMethod(paymentMethod.id, isDefault);

        if (onSave) onSave();

        toast.success('Successfully added');
        onClose();
      } catch (err) {
        toast.error(err.response.data.message);
      }

      setBtnLoading(false);
      // } else {
      //   toast.error('Please correct these fields');
      // }
    }
  };

  return (
    <Modal
      wrapperClassName={classNames('add_payment_modal_wrapper', { anim: animOn })}
      className={classNames('add_payment_modal_content', className)}
      isOpen={isOpen}
      onClose={() => !btnLoading && onClose()}
      zIndex={zIndex}
    >
      {!animOn && <Loader className="add_payment_modal_loader" />}

      <h2>Add payment method</h2>

      <form onSubmit={handleSubmit}>

        <div className="add_payment_card_block">
          <h4 className="add_payment_card_subtitle">Card details</h4>

          <div className="add_payment_card_block_fields">
            <Input
              onChangeText={(value) => onDataChange('cardName', value)}
              value={cardData.cardName}
              size="small"
              roundBorder
              wrapperClassName="add_payment_input"
              label="Name on card"
              error={errors?.cardName}
            />

            <div className="field_single_wrp">
              <p className="field_single_wrp_label">Card Number</p>

              <CardNumberElement
                className={`card_number ${errors?.cardNumber ? 'error' : ''}`}
                onChange={(e) => onDataChange('cardNumber', e)}
              />

              <p className="field_single_wrp_error">{errors?.cardNumber}</p>

              {cardType && (
                <div className="field_single_wrp_card_icon">
                  {cardTypes.find((c) => c.type.toLowerCase() === cardType.toLowerCase())?.icon}
                </div>
              )}
            </div>

            <div className="field_single_wrp">
              <p className="field_single_wrp_label">Expiry date</p>

              <CardExpiryElement
                className={`card_number ${errors?.cardExpiry ? 'error' : ''}`}
                onChange={(e) => onDataChange('cardExpiry', e)}
                options={{ placeholder: 'MM / YY' }}
              />

              <p className="field_single_wrp_error">{errors?.cardExpiry}</p>
            </div>

            <div className="field_single_wrp">
              <p className="field_single_wrp_label">CVV</p>

              <CardCvcElement
                className={`card_number ${errors?.cardCvc ? 'error' : ''}`}
                onChange={(e) => onDataChange('cardCvc', e)}
                options={{ placeholder: 'CVC' }}
              />

              <p className="field_single_wrp_error">{errors?.cardCvc}</p>
            </div>
          </div>
        </div>

        <div className="add_payment_card_block">
          {/* <h4 className="add_payment_card_subtitle">Personal details</h4> */}

          {/* <div className="add_payment_card_block_fields"> */}
          {/*   <AddressAutocomplete */}
          {/*     value="" */}
          {/*     onChangeText={() => {}} */}
          {/*     type="edit" */}
          {/*     addressClick={onAddressClick} */}
          {/*   /> */}

          {/*   {fields.map(({ */}
          {/*     type, path, label, optionLabel, optionPath, */}
          {/*   }) => { */}
          {/*     if (type === 'select') { */}
          {/*       return ( */}
          {/*         <Select */}
          {/*           onChange={(value) => onDataChange(path, value)} */}
          {/*           options={path === 'country' ? countries : states} */}
          {/*           label={label} */}
          {/*           roundBorder */}
          {/*           size="small" */}
          {/*           wrapperClassName="add_payment_input" */}
          {/*           error={errors[path]} */}
          {/*           placeholder={label} */}
          {/*           value={cardData[path]} */}
          {/*           key={path} */}
          {/*           loading={path === 'state' && statesLoading} */}
          {/*           isDisabled={path === 'state' && !cardData.country} */}
          {/*           valuePath={optionPath} */}
          {/*           labelPath={optionLabel} */}
          {/*           menuPlacement="top" */}
          {/*         /> */}
          {/*       ); */}
          {/*     } */}

          {/*     return ( */}
          {/*       <Input */}
          {/*         onChangeText={(value) => onDataChange(path, value)} */}
          {/*         value={cardData[path]} */}
          {/*         label={label} */}
          {/*         roundBorder */}
          {/*         size="small" */}
          {/*         wrapperClassName="add_payment_input" */}
          {/*         error={errors[path]} */}
          {/*         placeholder={label} */}
          {/*         key={path} */}
          {/*       /> */}
          {/*     ); */}
          {/*   })} */}
          {/* </div> */}

          <Switcher
            onChange={(checked) => setIsDefault(checked)}
            label="Mark as default payment method"
            className="add_payment_card_switcher"
            checked={isDefault}
          />
        </div>

        <div className="add_payment_modal_btns_wrapper">
          <Button
            size="small"
            roundBorder
            btnType="cancel"
            reverseColor
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            size="small"
            roundBorder
            loading={btnLoading}
            type="submit"
            className="add_payment_modal_btns_submit"
          >
            Add payment method
          </Button>
        </div>
      </form>
    </Modal>
  );
});

ModalAddPayment.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  className: PropTypes.string,
  zIndex: PropTypes.number,
};

ModalAddPayment.defaultProps = {
  className: '',
  zIndex: 102,
  onClose: null,
  onSave: null,
};
export default ModalAddPayment;
