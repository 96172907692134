import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Wrapper from '../../Layout/Wrapper';
import Filters from '../Products/Filters';
import Loader from '../../_common/Loader/Loader';
import DynamicTable from '../../_common/DynamicTable/DynamicTable';
import Pagination from '../../_common/Pagination/Pagination';
import Utils from '../../../helpers/Utils';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_with_circle.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_with_circle.svg';
import StatusField from '../../_common/StatusField/StatusField';
import {
  deleteStockTransferRequest,
  getStockTransferListRequest,
  getWarehouseList,
} from '../../../store/actions/stockTransfer';
import { getProductsRequest } from '../../../store/actions/products';
import DeleteModal from '../../_common/Modal/DeleteModal';

const defaultFilterData = [
  { label: 'Status', valuePath: 'status' },
];

const productFilterOptions = {
  status: {
    label: 'Status',
    path: 'status',
    valuePath: 'status',
    staticData: [
      {
        label: 'Active',
        id: 'active',
      },
      {
        label: 'Received',
        id: 'received',
      },
      {
        label: 'Partially received',
        id: 'partiallyReceived',
      },
    ],
  },
};

const columns = [
  {
    key: 'number', isEnabled: true,
  },
  {
    key: 'sourceWarehouse', isEnabled: true,
  },
  {
    key: 'destinationWarehouse', isEnabled: true,
  },
  {
    key: 'totalUnits', isEnabled: true,
  },
  {
    key: 'receivedAt', isEnabled: true,
  },
  {
    key: 'updatedAt', isEnabled: true,
  },
  {
    key: 'status', isEnabled: true,
  },
];

const defaultAttributes = {
  number: 'Transfer number',
  sourceWarehouse: 'Location from',
  destinationWarehouse: 'Location to',
  totalUnits: 'Total units',
  receivedAt: 'Receive date',
  updatedAt: 'Last update',
  status: 'Status',
};

const defaultFiltersParams = withDefault(ArrayParam, []);

const StockTransfer = () => {
  const navigate = useNavigate();

  const firstLoad = useRef(true);
  const timeOutRef = useRef(null);

  const dispatch = useDispatch();
  const stockTransferList = useSelector((store) => store.stockTransfer.stockTransferList);
  const stockTransferTotalPage = useSelector((store) => store.stockTransfer.stockTransferTotalPage);
  const warehouseList = useSelector((store) => store.stockTransfer.warehouseList);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState('');

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    status: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  useEffect(() => {
    (async () => {
      if (!warehouseList.length && firstLoad.current) {
        const { payload } = await dispatch(getWarehouseList());

        dispatch(getProductsRequest({
          includes: 'stock,warehouse',
          warehouseId: payload.warehouses[0]?.id,
          totalStock: `1,,${payload.warehouses[0]?.id},`,
        }));
      }
      setLoading(true);

      clearTimeout(timeOutRef.current);

      timeOutRef.current = setTimeout(async () => {
        await dispatch(getStockTransferListRequest(Utils.arrayToString(queryData)));

        firstLoad.current = false;
        setLoading(false);
      }, 400);
    })();
  }, [queryData.status, queryData.s]);

  const changePage = useCallback(async (currentPage) => {
    clearTimeout(timeOutRef.current);
    setLoading(true);

    await dispatch(getStockTransferListRequest(Utils.arrayToString({ ...queryData, page: currentPage })));

    setQuery((prev) => ({ ...prev, page: currentPage }));
    setLoading(false);
  }, []);

  const stockTransferDelete = async () => {
    setDeleteLoading(true);
    await dispatch(deleteStockTransferRequest(openDeleteModal));

    if (stockTransferList.length === 1 && +queryData.page > 1) {
      await changePage(+queryData.page - 1);
    }

    toast.success('Stock transfer successfully deleted');

    setDeleteLoading(false);

    setOpenDeleteModal('');
  };

  return (
    <Wrapper
      onBtnClick={() => navigate('/stocks/stock-transfer/create')}
      addBtn
      title="Stock transfer"
      buttonTitle="New stock transfer"
      className="stock_transfer"
      hideBackBtn
    >
      {firstLoad.current
        ? <Loader />
        : (
          <div className="stock_transfer_wrapper">
            <Filters
              queryData={queryData}
              setQuery={setQuery}
              defaultFilterData={defaultFilterData}
              onImportClick={() => {}}
              filterOptions={productFilterOptions}
              loadOptions={(filter) => ({ data: filter.staticData })}
            />

            <div className="stock_transfer_table_wrapper">
              <DynamicTable
                loading={loading}
                data={stockTransferList}
                keyExtractor={(item) => item.id}
                columns={columns}
                renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
                renderColumns={{
                  number: ({ item }) => (
                    <div
                      className="stock_transfer_table_number"
                      role="button"
                      tabIndex="0"
                      onClick={() => navigate(`/stocks/stock-transfer/receive/${item.id}`)}
                    >
                      {item.number}
                    </div>
                  ),

                  sourceWarehouse: ({ item }) => item.sourceWarehouse.title,
                  destinationWarehouse: ({ item }) => item.destinationWarehouse.title,
                  totalUnits: ({ item }) => item.totalUnits,
                  receivedAt: ({ item }) => (item.receivedAt ? moment(item.receivedAt).format('MM/DD/YY') : '-'),
                  updatedAt: ({ item }) => moment(item.updatedAt).format('MM/DD/YY'),
                  status: ({ item }) => <StatusField status={item.status} />,

                  actions: ({ item }) => (
                    <div className="products_actions_wrapper">
                      <div
                        className={classNames('', { disable: item.status === 'received' })}
                        role="button"
                        tabIndex="0"
                        onClick={item.status !== 'received'
                          ? () => navigate(`/stocks/stock-transfer/edit/${item.id}`)
                          : null}
                      >
                        <EditIcon />
                      </div>

                      <div
                        role="button"
                        tabIndex="0"
                        onClick={() => setOpenDeleteModal(item.id)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  ),
                }}
              />

              {+stockTransferTotalPage > 1 && (
              <div className="stock_transfer_pagination">
                <Pagination
                  page={queryData.page}
                  totalPages={+stockTransferTotalPage}
                  onChangePage={changePage}
                />
              </div>
              )}
            </div>

          </div>
        )}

      <DeleteModal
        isOpen={!!openDeleteModal}
        text="stock transfer"
        onDelete={stockTransferDelete}
        loading={deleteLoading}
        onClose={() => setOpenDeleteModal('')}
      />
    </Wrapper>
  );
};

export default StockTransfer;
