import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import _ from 'lodash';
import classNames from 'classnames';
import { useClickAway, useDeepCompareEffect } from 'react-use';
import { toast } from 'react-toastify';
import * as PDFLib from 'pdf-lib';
import SaleOrdersFilters from '../../components/pages/Orders/saleOrders/SaleOrdersFilters';
import SaleOrdersTable from '../../components/pages/Orders/saleOrders/SaleOrdersTable';
import Wrapper from '../../components/Layout/Wrapper';
import { archiveOrderRequest, getOrdersRequest } from '../../store/actions/orders';
import Utils from '../../helpers/Utils';
import Button from '../../components/_common/Form/Button';
import { ReactComponent as NotDataIcon } from '../../assets/icons/sale_order_no_data.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import BulkEdit from '../../components/pages/Orders/BulkModal';
import ArchiveModal from '../../components/_common/Modal/ArchiveModal';
import PrintDocumentModal from '../../components/_common/Modal/PrintDocumentModal';
import { getAllPackagesRequest } from '../../store/actions/packages';
import Api from '../../Api';

import TitleBar from '../../components/Layout/TitleBar';

const titleFilter = [
  {
    label: 'All Sales orders',
    path: '',
  },
  {
    label: 'Draft',
    path: 'draft',
  },
  {
    label: 'Approved',
    path: 'pending',
  },
  {
    label: 'Packaged',
    path: 'packaged',
  },
  {
    label: 'Shipped',
    path: 'shipped,partial_shipped',
  },
  {
    label: 'Delivered',
    path: 'delivered,partial_delivered',
  },
  {
    label: 'Canceled',
    path: 'canceled',
  },
];

const DelimitedArrayParamComma = Utils.customDelimitedArrayParam('~');
const defaultFiltersParams = withDefault(DelimitedArrayParamComma, []);

function SalesOrders() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const firstLoaded = useRef(true);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [bulkOrdersModal, setBulkOrdersModal] = useState({});
  const [openPrintModal, setOpenPrintModal] = useState('');

  const orders = useSelector((state) => state.orders.orders);

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    status: defaultFiltersParams,
    warehouseId: defaultFiltersParams,
    ids: defaultFiltersParams,
    orderStatus: withDefault(StringParam, ''),
    _status: withDefault(StringParam, ''),
    assignedUserId: defaultFiltersParams,
    tagId: defaultFiltersParams,
    customerId: defaultFiltersParams,
    isArchive: withDefault(StringParam, ''),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const { orderStatus } = queryData;

  useDeepCompareEffect(() => {
    handleGetOrders();
  }, [queryData]);

  const handleGetOrders = useCallback(async () => {
    // eslint-disable-next-line no-shadow
    const {
      orderStatus, _status, isArchive, ...query
    } = Utils.arrayToString(queryData);

    await dispatch(getOrdersRequest({
      status: orderStatus || _status || undefined,
      ...query,
      includes: 'customer,tags,orderProducts',
      isArchive: isArchive ? undefined : false,
      type: 'sale',
    }));

    firstLoaded.current = false;
    setLoading(false);
  }, [queryData]);

  useEffect(() => {
    setCheckedOrders([]);
  }, [queryData.page]);

  const openBulkActionModals = (path) => {
    if (path === 'archive') {
      setArchiveModal(checkedOrders);
    } else if (path.includes('print')) {
      setOpenPrintModal(path);
    } else {
      setBulkOrdersModal({ path, orders: orders.filter((o) => checkedOrders.includes(o.id)) });
    }
  };

  const [archiveModal, setArchiveModal] = useState([]);
  const [archiveLoading, setArchiveLoading] = useState(false);

  const handleArchive = useCallback(async () => {
    setArchiveLoading(true);

    const { payload } = await dispatch(archiveOrderRequest(archiveModal.join(',')));

    if (payload.data.status !== 'error') {
      toast.success('Orders successfully archived');
      await handleGetOrders();
    } else {
      toast.error(payload.data.message);
    }
    setCheckedOrders([]);
    setArchiveModal([]);
    setArchiveLoading(false);
  }, [archiveModal]);

  const downloadPdfFile = async (templateId) => {
    const { payload: { data } } = await dispatch(getAllPackagesRequest({
      orderId: checkedOrders.join(','), limit: 100,
    }));

    const packages = data.orderPackages || [];

    const response = await Promise.all(packages.map((o) => (
      openPrintModal === 'printPackingList'
        ? Api.downloadPackingListDocument(o.id, { templateId }, 'arraybuffer')
        : openPrintModal === 'printPackingSlips'
          ? Api.downloadPackageDocument(o.id, { templateId }, 'arraybuffer')
          : {}
    )));

    const pdfDocs = await Promise.all(response.map((buffer) => PDFLib.PDFDocument.load(buffer.data)));
    const mergedPdf = await PDFLib.PDFDocument.create();

    for (const pdfDoc of pdfDocs) {
      const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    return blob;
  };
  return (
    <Wrapper
      hideBackBtn
      className="customers_wrapper sales_orders"
    >
      <TitleBar
        backButton={false}
        actions={titleFilter}
        isActionActive={(d) => d.path === orderStatus}
        onActionClick={(d) => setQuery((prev) => ({ ...prev, orderStatus: d.path, page: 1 }))}
      >
        <Button
          title="Add new"
          addBtn
          data-scope="WRITE_ORDERS"
          href="/orders/sales-order/create"
        />
      </TitleBar>
      {!_.isEmpty(orders) || Object.values({ ...Object.fromEntries(searchParams) }).length ? (
        <div className="customersList">
          <SaleOrdersFilters
            checkedOrders={checkedOrders}
            reorderCols
            setOpenExportModal={setOpenExportModal}
            setOpenImportModal={setOpenImportModal}
            type="sale"
            openBulkActionModals={openBulkActionModals}
          />

          <SaleOrdersTable
            setCheckedOrders={setCheckedOrders}
            checkedOrders={checkedOrders}
            loading={loading}
            onGetOrders={handleGetOrders}
            preview
            setOpenExportModal={setOpenExportModal}
            openImportModal={openImportModal}
            openExportModal={openExportModal}
            setOpenImportModal={setOpenImportModal}
            path="/orders/sales-order"
          />
        </div>
      ) : null}
      {!loading && _.isEmpty(orders) && !Object.values({ ...Object.fromEntries(searchParams) }).length ? (
        <div className="customers_not_found">
          <NotDataIcon />

          <p>You don’t have a Sales orders yet</p>

          <div>
            <Button
              title="Creat your first Sales order"
              href="/orders/sales-order/create"
              addBtn
              data-scope="WRITE_ORDERS"
              reverseColor
              size="small"
              roundBorder
            />
          </div>
        </div>
      ) : null}

      <BulkEdit bulkOrderModal={bulkOrdersModal} setBulkOrderModal={setBulkOrdersModal} />

      <ArchiveModal
        isOpen={!!archiveModal.length}
        text="orders"
        onArchive={handleArchive}
        loading={archiveLoading}
        onClose={() => setArchiveModal([])}
      />

      {openPrintModal && (
        <PrintDocumentModal
          isOpen={openPrintModal}
          onClose={() => setOpenPrintModal('')}
          type={openPrintModal === 'printPackingList'
            ? 'orders/packing-list'
            : openPrintModal === 'printPackingSlips'
              ? 'orders/packing-slip'
              : ''}
          onRequest={downloadPdfFile}
          onRemotePrint={() => {
          }}
        />
      )}
    </Wrapper>
  );
}

export default SalesOrders;
