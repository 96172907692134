import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import Loader from '../../../../_common/Loader/Loader';
import Api from '../../../../../Api';
import Select from '../../../../_common/Form/Select';
import Table from '../../../../_common/Tables/Table';
import Button from '../../../../_common/Form/Button';
import UpdateModal from '../../../../_common/Modal/UpdateModal';

const fields = [
  {
    title: 'Title',
    placeholder: 'Enter title',
    path: 'title',
    required: true,
  },
  {
    title: 'Description',
    placeholder: 'Enter description',
    path: 'description',
    required: true,
  },
  {
    title: 'Code',
    placeholder: 'Enter code',
    path: 'code',
    required: true,
  },
];

const AccountingLedger = ({
  data, integrationName, changeData, ledgers, fromMyApp,
}) => {
  const [ledgersList, setLedgersList] = useState([]);
  const [loading, loadingToggle] = useState(true);

  const [isModalOpen, modalToggle] = useState(false);

  const getLedgers = async () => {
    const { data: { ledgers: list } } = await Api.getAccountingLedgers();

    setLedgersList(list.map((l) => (data[l.id] ? { ...l, ledger: data[l.id] } : l)));

    loadingToggle(false);
  };

  useEffect(() => {
    getLedgers();
  }, []);

  const changePaymentData = useCallback((id, path, value) => {
    setLedgersList((prev) => prev.map((p) => (p.id === id ? { ...p, [path]: value } : p)));

    changeData((prev) => ({
      ...prev,
      accountingSettings: {
        ...prev.accountingSettings,
        account: {
          ...prev.accountingSettings.account,
          [id]: value,
        },
      },
    }));
  }, []);

  const addNewLedger = useCallback(async (ledgerData) => {
    try {
      await Api.createAccountingLedgers(ledgerData);
      await getLedgers();

      modalToggle(false);
      toast.success('Ledger successfully added');
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }, []);

  const tableHeader = useMemo(() => [
    {
      title: 'Ledgers',
      path: 'title',
    },
    {
      title: 'Action',
      path: 'description',
    },
    {
      title: `Sync to ${integrationName} ledgers`,
      path: 'ledger',
      fieldType: 'select',
      options: ledgers,
      valuePath: 'id',
      labelPath: 'name',
    },
  ], [ledgers]);

  return (
    <div className="integration_step_one_wrapper ledgers">
      {loading
        ? (
          <Loader />
        )

        : (
          fromMyApp
            ? (
              <div className={classNames('integration_step_two_wrapper', { my_app: fromMyApp })}>
                <div className="accounting_ledger_wrapper">
                  <h2>Configure Ledgers sync between eSwap and your app</h2>

                  <Button
                    className="add_new_ledger"
                    size="small"
                    title="Add new ledger"
                    onClick={() => modalToggle(true)}
                    roundBorder
                  />
                </div>

                {ledgersList.map((l) => (
                  <div
                    key={l.id}
                    className="integration_step_two_fields_wrapper"
                  >
                    <div className="integration_step_two_fields_left_block">
                      <p className="integration_step_two_fields_left_block_title">{l.title}</p>

                      <p
                        className="integration_step_two_fields_left_block_text"
                      >
                        {l.description}
                      </p>
                    </div>

                    <div className="integration_step_two_fields_right_block">
                      <div className="integration_step_two_fields_right_block_select">
                        <Select
                          key={l.id}
                          value={data[l.id]}
                          label={l.name}
                          onChange={(value) => changePaymentData(l.id, 'accounting', value)}
                          options={ledgers}
                          valuePath="id"
                          labelPath="name"
                          roundBorder
                          size="small"
                          wrapperClassName="my_app_step_one_wrapper_location_select"
                          menuPosition="fixed"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )

            : (
              <Table
                data={ledgersList}
                header={tableHeader}
                onChange={changePaymentData}
              />
            )
        )}

      <UpdateModal
        isOpen={isModalOpen}
        fields={fields}
        onSave={addNewLedger}
        onClose={() => modalToggle(false)}
        text="Ledger"
        btnText="Add ledger"
      />
    </div>
  );
};

AccountingLedger.propTypes = {};

export default AccountingLedger;
