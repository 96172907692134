import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Link, useLocation, useNavigate,
} from 'react-router-dom';
import moment from 'moment';
import { format } from 'currency-formatter';
import { toast } from 'react-toastify';
import Tooltip from 'rc-tooltip';
import {
  archiveOrderRequest,
  changeSalesOrdersTableAttributes,
  getSalesOrdersTableAttributes, salesOrdersTableEditableEvent, unArchiveOrderRequest,
} from '../../../../store/actions/orders';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as ArchiveIcon } from '../../../../assets/icons/archive_with_cirle.svg';
import { ReactComponent as UnArchiveIcon } from '../../../../assets/icons/un_archive.svg';
import Pagination from '../../../_common/Pagination/Pagination';
import shopIcons from '../../../../assets/shops';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import Button from '../../../_common/Form/Button';
import Api from '../../../../Api';
import ArchiveModal from '../../../_common/Modal/ArchiveModal';
import ImportModal from '../../ImportCSVFile/ImportModal';
import ExportFileModal from '../../ExportFile/ExportFileModal';
import Utils from '../../../../helpers/Utils';

function SaleOrdersTable(props) {
  const [saveLoading, setSaveLoading] = useState(false);
  const [archiveModal, setArchiveModal] = useState(null);
  const [archiveLoading, setArchiveLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const orders = useSelector((state) => state.orders.orders);
  const totalPages = useSelector((state) => state.orders.totalPages);
  const tableEditable = useSelector((state) => state.orders.tableEditable);
  const tableColumns = useSelector((state) => state.orders.tableColumns);

  const type = useMemo(() => (location.pathname.includes('/stocks') ? 'purchase' : 'sale'), [location]);

  useEffect(() => {
    dispatch(getSalesOrdersTableAttributes());
  }, []);

  const changeTableAttributes = useCallback((attrs) => {
    dispatch(changeSalesOrdersTableAttributes(attrs));
  }, []);

  const saveChangedTableAttribute = useCallback(async () => {
    try {
      setSaveLoading(true);
      await Api.updateSettings({ ordersTableAttributes: tableColumns });
      dispatch(salesOrdersTableEditableEvent(false));
      setSaveLoading(false);
    } catch (e) {
      toast.error('Something wrong place try again !');
    }
  }, [tableColumns]);

  const handleArchive = useCallback(async () => {
    setArchiveLoading(true);
    if (archiveModal.isArchive) {
      const { payload } = await dispatch(unArchiveOrderRequest(archiveModal.id));
      if (payload.data.status !== 'error') {
        toast.success('Order successfully restored');
        await props.onGetOrders();
      } else {
        toast.error(payload.data.message);
      }
    } else {
      const { payload } = await dispatch(archiveOrderRequest(archiveModal.id));
      if (payload.data.status !== 'error') {
        toast.success('Order successfully archived');
        await props.onGetOrders();
      } else {
        toast.error(payload.data.message);
      }
    }

    setArchiveModal(null);
    setArchiveLoading(false);
  }, [archiveModal]);

  return (
    <>
      <div className="table_wrapper sale_orders_table">
        <DynamicTable
          onChange={changeTableAttributes}
          loading={props.loading}
          checkedItems={props.checkedOrders}
          onSelect={props.setCheckedOrders}
          data={orders}
          editable={tableEditable}
          checkbox
          keyExtractor={(item) => item.id}
          columns={tableColumns}
          callDragDisabled={(call) => ['item'].includes(call.key)}
          renderColumnHeader={(col) => (col.key === 'customer' && type === 'purchase' ? 'Vendor' : _.startCase(col.key))}
          renderColumns={{
            orderId: ({ item }) => (
              props.preview ? (
                <div className="link">
                  <Link to={`${props.path}/preview/${item.id}${location.search}`}>{item.number}</Link>
                </div>
              ) : (
                <div>
                  {item.number}
                </div>
              )
            ),
            shop: ({ item }) => (
              <div className="shop" style={{ width: 40 }}>
                <img src={shopIcons[item.shopType || 'eswap']} alt="" style={{ width: '100%', height: '100%' }} />
              </div>
            ),
            customer: ({ item }) => (
              <div>
                {`${item.customer?.firstName || '-'} ${item.customer?.lastName || '-'}`}
              </div>
            ),
            shippingAddress: ({ item }) => (
              <div>
                {Utils.formatAddress(
                  [item.shippingAddress?.address1,
                    item.shippingAddress?.city,
                    item.shippingAddress?.region,
                    item.shippingAddress?.postalCode,
                    item.shippingAddress?.countryCode],
                )}
              </div>
            ),
            billingAddress: ({ item }) => (
              <div>
                {Utils.formatAddress(
                  [item.billingAddress?.address1,
                    item.billingAddress?.city,
                    item.billingAddress?.region,
                    item.billingAddress?.postalCode,
                    item.billingAddress?.countryCode],
                )}
              </div>
            ),
            status: ({ item }) => (
              <div className={`status ${item.status}`}>
                <div>
                  <p className="status_dot" />
                  <span>
                    {item.type === 'purchase'
                      ? Utils.formatPurchaseStatus(item.status)
                      : Utils.formatStatus(item.status)}
                  </span>
                </div>
              </div>
            ),
            paymentStatus: ({ item }) => (
              <div className={`status ${item.paymentStatus}`}>
                <div>
                  <p className="status_dot" />
                  <span>{Utils.formatStatus(item.paymentStatus)}</span>
                </div>
              </div>
            ),
            tags: ({ item }) => (
              <div className="tags">
                {item.tags.map((tag) => (
                  <Tooltip placement="top" overlay={<span>{tag.name}</span>} key={tag.id} destroyTooltipOnHide>
                    <p style={{ background: tag.color }} />
                  </Tooltip>
                ))}
              </div>
            ),
            createdAt: ({ item }) => (
              <div>
                {moment(item.createdAt).format('DD MMM YYYY')}
              </div>
            ),
            total: ({ item }) => (
              <div>
                {format(item.totalPrice || '-', { code: item.currencyCode })}
              </div>
            ),
            actions: ({ item }) => (
              <div className="products_actions_wrapper">
                <div
                  role="button"
                  className="icon"
                  tabIndex="0"
                  data-scope={type === 'purchase' ? 'WRITE_PURCHASE_ORDERS' : 'WRITE_ORDERS'}
                  onClick={() => navigate(`${props.path}/edit/${item.id}`)}
                >
                  <EditIcon />
                </div>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setArchiveModal(item)}
                  className="icon archive_icon"
                  data-scope={type === 'purchase' ? 'DELETE_PURCHASE_ORDERS' : 'DELETE_ORDERS'}
                >
                  {!item.isArchive ? <ArchiveIcon /> : <UnArchiveIcon />}
                </div>

              </div>
            ),
          }}
        />

      </div>
      {tableEditable
        && (
          <div className="products_wrapper_table_changed_save">
            <Button
              className="table_changed_save_button"
              size="small"
              title="Cancel"
              onClick={() => dispatch(salesOrdersTableEditableEvent(false))}
              roundBorder
              btnType="cancel"
            />

            <Button
              className="table_changed_save_button"
              size="small"
              title="Save changes"
              onClick={saveChangedTableAttribute}
              roundBorder
              color="#1472FF"
              loading={saveLoading}
            />
          </div>
        )}

      <Pagination totalPages={totalPages} />

      <ArchiveModal
        isOpen={!!archiveModal}
        text="order"
        restore={location.pathname.includes('archive')}
        onArchive={handleArchive}
        loading={archiveLoading}
        onClose={() => setArchiveModal(null)}
      />

      <ImportModal
        isOpen={props.openImportModal}
        onClose={() => props.setOpenImportModal(false)}
        type="orders"
      />

      <ExportFileModal
        isOpen={props.openExportModal}
        onClose={() => props.setOpenExportModal(false)}
        selectedItemsId={props.checkedOrders}
        name="ordersExport"
      />
    </>
  );
}

export default SaleOrdersTable;
