import React from 'react';
import _ from 'lodash';
import Modal from '../../../_common/Modal/Modal';
import img from '../../../../assets/icons/finances.svg';
import { serviceIcons } from '../../../../assets/icons/shippingServices';
import Button from '../../../_common/Form/Button';

function NoCartAttachedModal(props) {
  const { onClose, isOpen, onContinue } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="no_cart_attached_modal">
      <p className="title">
        You’re about buy your first label
      </p>
      <p className="desc">
        When you buy a label in eSwap, you instantly gain the benefits from our excellent
        partnerships.
      </p>
      <img src={img} alt="img" />
      <p className="subtitle">Save on rates for these carriers too!</p>
      <p className="desc">
        You get up to an 89% savings immediately with the following popular carriers when you ship
        with eSwap
      </p>
      <div className="services">
        {_.map(serviceIcons, (Icon) => <Icon key={Icon} name={Icon} />)}
      </div>
      <div className="actions">
        <Button btnType="cancel" roundBorder onClose={onClose}>Cancel</Button>
        <Button roundBorder onClick={onContinue}>Continue setup</Button>
      </div>
    </Modal>
  );
}

export default NoCartAttachedModal;
