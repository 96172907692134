import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import Table from '../../../_common/Tables/Table';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import Button from '../../../_common/Form/Button';
import { ReactComponent as TagsNotFoundIcon } from '../../../../assets/icons/notFound/tags.svg';

const generateRandomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  return `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
};

const modalFields = [
  {
    title: 'Tag name',
    path: 'name',
    placeholder: 'Enter Tag name',
    required: true,
  },
  // {
  //   title: 'Type',
  //   path: 'type',
  //   placeholder: 'Enter Type',
  //   type: 'select',
  //   options: [
  //     { label: 'Product', value: 'product' },
  //     { label: 'Order', value: 'order' },
  //   ],
  //   required: true,
  // },
  {
    title: 'Tag color',
    path: 'color',
    placeholder: 'Enter Tag',
    type: 'colorPicker',
    required: true,
  },
];

const tagDefaultData = {
  name: '',
  color: '',
};

const requestMethods = {
  orders: {
    get: 'getOrdersTags',
    create: 'createOrderTag',
    update: 'updateOrderTag',
    delete: 'deleteOrderTag',
  },
  products: {
    get: 'getProductsTags',
    create: 'createProductTag',
    update: 'updateProductTag',
    delete: 'deleteProductTag',
  },
};

const Tags = ({ type = 'orders' }) => {
  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const [tags, setTags] = useState([]);

  const firstLoad = useRef(true);

  const [modalData, setModalData] = useState({});
  const [loading, loadingToggle] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    (async () => {
      loadingToggle(true);

      await getTagsRequest();

      loadingToggle(false);
      firstLoad.current = false;
    })();
  }, [sorting]);

  const getTagsRequest = async () => {
    const { data } = await Api[requestMethods[type].get](sorting);

    setTags(data.tags);
  };

  const openModal = useCallback((id) => {
    setModalData(id ? tags.find((t) => +t.id === +id) : tagDefaultData);
  }, [tags]);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, [sorting]);

  const onDelete = useCallback(async (id) => {
    setDeleteLoading(true);

    await Api[requestMethods[type].delete](id);

    setTags((prev) => prev.filter((p) => p.id !== id));

    toast.success('Tag successfully deleted');

    setDeleteLoading(false);
  }, []);

  const saveTags = useCallback(async (newData) => {
    const sendingData = {
      name: newData.name,
      color: newData.color,
    };

    if (!modalData?.id) {
      await Api[requestMethods[type].create](sendingData);
    } else {
      if (newData?.id) {
        sendingData.id = newData.id;
      }

      await Api[requestMethods[type].update](sendingData);
    }

    await getTagsRequest();

    toast.success(`Tag successfully ${modalData.id ? 'updated' : 'saved'}`);
    setModalData({});
  }, [modalData]);

  const tableHeader = useMemo(() => [
    {
      title: 'Number',
      path: 'index',
    },
    {
      title: 'Tag Name',
      path: 'name',
      isSelectable: true,
      navigate: (id) => openModal(id),
    },
    // {
    //   title: 'Type',
    //   path: 'type',
    //   isSelectable: true,
    // },
    {
      title: 'Color',
      path: 'color',
    },
  ], [openModal]);

  return (
    <Wrapper
      title={`${_.upperFirst(type)} tags`}
      onBtnClick={() => setModalData(tagDefaultData)}
      addBtn
      buttonTitle="New tag"
    >
      {firstLoad.current
        ? <Loader />

        : (
          <div className="tags_table_wrapper">
            <Table
              data={tags}
              header={tableHeader}
              onEditClick={openModal}
              onDeleteClick={onDelete}
              onSortBy={onSortBy}
              sortBy={sorting.sortBy}
              sort={sorting.sort}
              deleteModalText="tag"
              deleteLoading={deleteLoading}
              loading={loading}
              notFound={(
                <>
                  <TagsNotFoundIcon />

                  <h1>Tags not found</h1>

                  <Button
                    title="Create tag"
                    onClick={() => setModalData(tagDefaultData)}
                    addBtn
                    reverseColor
                    size="small"
                    roundBorder
                  />
                </>
              )}
            />
          </div>
        )}

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalFields}
        onClose={() => setModalData({})}
        onSave={saveTags}
        singleData={modalData}
        text="tag"
      />
    </Wrapper>
  );
};

export default Tags;
