import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import Input from '../../_common/Form/Input';
import Checkbox from '../../_common/Form/Checkbox';
import Loader from '../../_common/Loader/Loader';
import Utils from '../../../helpers/Utils';

const PolarisFilter = ({
  filterName, loadOptions, cancelFilter, hideSearchInput, values, setFilterValue, lastChild, clearClick, icon,
}) => {
  const filterSelectRef = useRef(null);
  const timeOutRef = useRef(null);

  const [options, setOptions] = useState([]);
  const [page, setPage] = useState({ currenPage: 1, totalPages: 1 });
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [openSelect, setOpenSelect] = useState('');
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    (async () => {
      clearTimeout(timeOutRef.current);
      setLoading(true);

      timeOutRef.current = setTimeout(async () => {
        if (filterName.localSearch && search) {
          setOptions((prev) => {
            const checkOptions = prev.filter((p) => values.includes(p.id));

            return _.uniqBy([...checkOptions, ...Utils.filterData(prev, search, 'label')], 'label');
          });

          setLoading(false);
          return;
        }

        const data = await loadOptions({
          search: search.trim(),
          page: 1,
        });

        if (data.totalPages) {
          setPage({ totalPages: data.totalPages, currenPage: 1 });
        }

        setOptions(data.data);

        setLoading(false);
      }, 500);
    })().catch(console.error);
  }, [search]);

  useEffect(() => {
    if (lastChild && !values.length) {
      setOpenSelect(filterName.path);
    }
  }, []);

  useClickAway(filterSelectRef, () => {
    setOpenSelect('');
  });

  const onScrollEvent = async (ev) => {
    if (ev.target.scrollHeight <= ev.target.scrollTop + ev.target.clientHeight + 30
      && page.totalPages >= page.currenPage + 1
      && !pageLoading) {
      setPage((prev) => ({ ...prev, currenPage: prev.currenPage + 1 }));

      setPageLoading(true);

      const data = await loadOptions({
        search: search.trim(),
        page: page.currenPage + 1,
      });

      setOptions((prev) => _.uniqBy([...prev, ...data.data], 'id'));
      setPageLoading(false);
    }
  };

  return (
    <div
      className="filter-wrapper"
      role="button"
      tabIndex="0"
      ref={filterSelectRef}
      onClick={() => setOpenSelect((prev) => (prev ? '' : filterName.path))}
    >
      {icon}

      <p className="filter_label">{`${filterName.label}:`}</p>

      {options.length
        ? values.map((v) => {
          const fountOption = options?.find((option) => option.id === v);

          return fountOption && (
            <p key={v} className="filter_name">
              {fountOption.label}
            </p>
          );
        })

        : !options.length && loading && <Loader size={10} borderWidth={2} className="filter_loader" />}

      <div
        className="cancel_icon"
        role="button"
        tabIndex="0"
        onClick={(e) => {
          e.stopPropagation();
          cancelFilter(filterName);
        }}
      >
        <CancelIcon />
      </div>

      {filterName.path === openSelect && (
      <div
        role="button"
        tabIndex="0"
        className="custom_checkbox_select_wrapper"
        onClick={(e) => e.stopPropagation()}
      >

        {!hideSearchInput && (
        <Input
          placeholder="Search"
          value={search}
          onChangeText={(val) => setSearch(val)}
          wrapperClassName="custom_checkbox_input"
        />
        )}

        <div
          className="custom_checkbox_select"
          tabIndex="0"
          role="button"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onScroll={onScrollEvent}
        >

          {loading
            ? <div style={{ position: 'relative', height: 50 }}><Loader size={30} /></div>
            : options.map((o) => (
              <div className="custom_checkbox_item" key={o.id}>
                <Checkbox
                  onChange={(checked) => setFilterValue(filterName.valuePath, o.id, checked)}
                  label={o.label}
                  checked={values.includes(o.id)}
                />

              </div>
            ))}

          {pageLoading && <div style={{ position: 'relative', height: 10 }}><Loader size={15} borderWidth={2} /></div>}

          {!loading && !options.length && <p>No data</p>}

        </div>

        {values.length > 0 && (
        <div
          className="custom_checkbox_clear_all_button"
          role="button"
          tabIndex="0"
          onClick={() => clearClick(filterName)}
        >
          Clear all
        </div>
        )}
      </div>
      )}
    </div>
  );
};

PolarisFilter.propTypes = {
  filterName: PropTypes.object.isRequired,
  loadOptions: PropTypes.func.isRequired,
  cancelFilter: PropTypes.func.isRequired,
  hideSearchInput: PropTypes.bool,
  values: PropTypes.array,
  setFilterValue: PropTypes.func.isRequired,
  lastChild: PropTypes.bool,
  icon: PropTypes.element,
  clearClick: PropTypes.func.isRequired,
};

PolarisFilter.defaultProps = {
  hideSearchInput: false,
  lastChild: false,
  values: [],
};

export default PolarisFilter;
