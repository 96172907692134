import React, {
  useCallback, useRef, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import _ from 'lodash';
import { useDeepCompareEffect } from 'react-use';
import SaleOrdersFilters from '../../components/pages/Orders/saleOrders/SaleOrdersFilters';
import SaleOrdersTable from '../../components/pages/Orders/saleOrders/SaleOrdersTable';
import Wrapper from '../../components/Layout/Wrapper';
import { getOrdersRequest } from '../../store/actions/orders';
import Utils from '../../helpers/Utils';
import Button from '../../components/_common/Form/Button';
import { ReactComponent as NotDataIcon } from '../../assets/icons/sale_order_no_data.svg';
import TitleBar from '../../components/Layout/TitleBar';


const DelimitedArrayParamComma = Utils.customDelimitedArrayParam('~');
const defaultFiltersParams = withDefault(DelimitedArrayParamComma, []);

function PurchaseOrders() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstLoaded = useRef(true);
  const [searchParams] = useSearchParams();

  const orders = useSelector((state) => state.orders.orders);
  const [checkedOrders, setCheckedOrders] = useState([]);

  const [queryData] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    status: defaultFiltersParams,
    warehouseId: defaultFiltersParams,
    ids: defaultFiltersParams,
    assignedUserId: defaultFiltersParams,
    tagId: defaultFiltersParams,
    customerId: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  useDeepCompareEffect(() => {
    handleGetOrders();
  }, [queryData]);

  const handleGetOrders = useCallback(async () => {
    setLoading(true);
    await dispatch(getOrdersRequest({
      status: 'pending,shipped,partial_shipped,delivered,partial_delivered',
      ...Utils.arrayToString(queryData),
      includes: 'customer,tags',
      isArchive: false,
      type: 'purchase',
    }));
    firstLoaded.current = false;
    setLoading(false);
  }, [queryData]);

  return (
    <Wrapper hideBackBtn>
      <TitleBar title="All Purchase orders">
        <Button
          title="Add new"
          addBtn
          data-scope="WRITE_PURCHASE_ORDERS"
          href="/stocks/purchase-orders/create"
        />
      </TitleBar>
      {!_.isEmpty(orders) || Object.values({ ...Object.fromEntries(searchParams) }).length ? (
        <div className="customersList">
          <SaleOrdersFilters
            reorderCols
            setOpenExportModal={() => {
            }}
            setOpenImportModal={() => {
            }}
            type="purchase"
          />

          <SaleOrdersTable
            setCheckedOrders={setCheckedOrders}
            checkedOrders={checkedOrders}
            loading={loading}
            onGetOrders={handleGetOrders}
            preview
            setOpenExportModal={() => {
            }}
            openImportModal={false}
            openExportModal={false}
            setOpenImportModal={() => {
            }}
            path="/stocks/purchase-orders"
          />
        </div>
      ) : null}
      {!loading && _.isEmpty(orders) && !Object.values({ ...Object.fromEntries(searchParams) }).length ? (
        <div className="customers_not_found">
          <NotDataIcon />

          <p>You don’t have a Purchase orders yet</p>

          <div>
            <Button
              title="Creat your first Purchase order"
              href="/stocks/purchase-orders/create"
              addBtn
              reverseColor
              size="small"
              roundBorder
            />
          </div>
        </div>
      ) : null}
    </Wrapper>
  );
}

export default PurchaseOrders;
