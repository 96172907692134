import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Wrapper from '../../../Layout/Wrapper';
import Tabs from '../../../_common/Tabs/Tabs';
import AppsTabWrapper from './AppsTabWrapper';
import { shopIcons } from '../../../../assets/shops';

const integrations = {
  marketplaces: {
    our: [
      {
        name: 'Amazon',
        icon: shopIcons.amazon,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/amazon',
      },
      {
        name: 'Ebay',
        icon: shopIcons.ebay,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/ebay',
      },
      {
        name: 'Bonanza',
        icon: shopIcons.bonanza,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/bonanza',
      },
      {
        name: 'Wayfair',
        icon: shopIcons.wayfair,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/wayfair',
      },
    ],
    cartrover: [
      {
        name: 'Etsy',
        icon: shopIcons.etsy,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/etsy',
      },
      {
        name: 'Houzz',
        icon: shopIcons.houzz,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/houzz',
      },
      {
        name: 'Magento 2',
        icon: shopIcons['magento-2'],
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/magento-2',
      },
      {
        name: 'Walmart',
        icon: shopIcons.walmart,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/walmart',
      },
      {
        name: 'Wayfair',
        icon: shopIcons.wayfair,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/wayfair',
      },
      {
        name: 'Wish',
        icon: shopIcons.wish,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/wish',
      },
      {
        name: 'Extensiv 3PL Warehouse Manager',
        icon: shopIcons.extensiv,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/extensiv',
      },
      {
        name: 'Extensiv Order Manager',
        icon: shopIcons.extensiv,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/extensiv-order',
      },
      {
        name: 'Google Shopping',
        icon: shopIcons.google,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/google-shopping',
      },
      {
        name: 'Open Cart',
        icon: shopIcons.cart,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/open-cart',
      },
      {
        name: 'Presta Shop',
        icon: shopIcons.presta,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/cartrover/presta-shop',
      },
    ],
  },
  ecommerce: {
    our: [
      {
        name: 'WooCommerce',
        icon: shopIcons.woocommerce,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/woocommerce',
      },
      {
        name: 'Shopify',
        icon: shopIcons.shopify,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/shopify',
      },
      {
        name: 'Bigcommerce',
        icon: shopIcons.bigcommerce,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/bigcommerce',
      },
      {
        name: 'Ecwid',
        icon: shopIcons.ecwid,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/ecwid',
      },
      {
        name: 'Vend',
        icon: shopIcons.vend,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/vend',
      },
    ],
  },
  erp: {
    our: [
      {
        name: 'Odoo',
        icon: shopIcons.odoo,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/odoo',
      },
    ],
  },
  ftp: {
    our: [
      {
        name: 'FTP Orders',
        icon: shopIcons.ftp,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/ftp-order',
      },
      {
        name: 'FTP Products',
        icon: shopIcons.ftp,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/ftp-product',
      },
      {
        name: 'FTP Customers',
        icon: shopIcons.ftp,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/ftp-customer',
      },
    ],
  },
  shipping: {
    our: [
      {
        name: 'Fedex',
        icon: shopIcons.fedex,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/fedex',
      },
      {
        name: 'UPS',
        icon: shopIcons.ups,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/ups',
      },
      {
        name: 'USPS',
        icon: shopIcons.usps,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/usps',
      },
      {
        name: 'DHL Express',
        icon: shopIcons['dhl-express'],
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/dhl-express',
      },
      {
        name: 'Canada post',
        icon: shopIcons['canada-post'],
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/canada-post',
      },
      {
        name: 'DPD UK',
        icon: shopIcons['dpd-uk'],
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/dpd-uk',
      },
      {
        name: 'Purolator',
        icon: shopIcons.purolator,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/purolator',
      },
      {
        name: 'ShipStation',
        icon: shopIcons.shipstation,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/shipstation',
      },
    ],
  },
  threePL: {
    our: [
      // {
      //   name: 'ShipBob',
      //   icon: shopIcons.shipbob,
      //   text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
      //   link: '/apps/shipbob',
      // },
    ],
  },
  accounting: {
    our: [
      {
        name: 'Quickbooks',
        icon: shopIcons.quickbooks,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/quickbooks',
      },
      {
        name: 'Xero',
        icon: shopIcons.xero,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/xero',
      },
    ],
  },
  marketing: {
    our: [
      {
        name: 'Mailchimp',
        icon: shopIcons.mailchimp,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/mailchimp',
      },
    ],
  },
  email: {
    our: [
      {
        name: 'Gmail',
        icon: shopIcons.gmail,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/gmail',
      },
      {
        name: 'SMTP',
        icon: shopIcons.smtp,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/smtp',
      },
    ],
  },
  payments: {
    our: [
      {
        name: 'Stripe',
        icon: shopIcons.stripe,
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/stripe',
      },
    ],
  },
  b2b: {
    our: [
      {
        name: 'B2B',
        icon: shopIcons['b-2-b'],
        text: 'Checkout Upsell, Post Purchase Upsell, Thank You Page Upsell',
        link: '/apps/b2b',
      },
    ],
  },
};

export const generalTabs = [
  {
    name: 'All',
    href: '/apps/list/all',
    component: <AppsTabWrapper
      integrations={_.sortBy(_.map(integrations, (value) => value.our).flat(1), 'name')}
      cartroverIntegrations={_.sortBy(
        _.compact(_.map(integrations, (value) => value.cartrover)).flat(1),
        'name',
      )}
    />,
  },
  {
    name: 'Marketplaces',
    href: '/apps/list/marketplaces',
    component: <AppsTabWrapper
      integrations={integrations.marketplaces.our}
      cartroverIntegrations={integrations.marketplaces.cartrover}
    />,
  },
  {
    name: 'Ecommerce',
    href: '/apps/list/ecommerce',
    component: <AppsTabWrapper integrations={integrations.ecommerce.our} />,
  },
  {
    name: 'ERP',
    href: '/apps/list/erp',
    component: <AppsTabWrapper integrations={integrations.erp.our} />,
  },
  {
    name: 'FTP',
    href: '/apps/list/ftp',
    component: <AppsTabWrapper integrations={integrations.ftp.our} />,
  },
  {
    name: 'Shipping',
    href: '/apps/list/shipping',
    component: <AppsTabWrapper integrations={integrations.shipping.our} />,
  },
  {
    name: '3PL',
    href: '/apps/list/3pl',
    component: <AppsTabWrapper integrations={integrations.threePL.our} />,
  },
  {
    name: 'Accounting',
    href: '/apps/list/accounting',
    component: <AppsTabWrapper integrations={integrations.accounting.our} />,
  },
  {
    name: 'Marketing',
    href: '/apps/list/marketing',
    component: <AppsTabWrapper integrations={integrations.marketing.our} />,
  },
  {
    name: 'Payments',
    href: '/apps/list/payments',
    component: <AppsTabWrapper integrations={integrations.payments.our} />,
  },
  {
    name: 'EDI',
    href: '/apps/list/edi',
  },
  {
    name: 'B2B',
    href: '/apps/list/b2b',
    component: <AppsTabWrapper integrations={integrations.b2b.our} />,
  },
  {
    name: 'Email',
    href: '/apps/list/email',
    component: <AppsTabWrapper integrations={integrations.email.our} />,
  },
];

function AppsWrapper() {
  const params = useParams();

  useLayoutEffect(() => {

  });

  return (
    <Wrapper title="Apps">
      <Tabs tabs={generalTabs} />
    </Wrapper>
  );
}

export default AppsWrapper;
