import React from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../../Layout/Wrapper';
import CreditNotesFilters from './CreditNotesFilters';
import CreditNotesTable from './CreditNotesTable';

function CreditNotes() {
  const navigate = useNavigate();

  return (
    <Wrapper
      title="Credit Notes"
      className="customers_wrapper sales_orders_return_wrapper"
      addBtn
      buttonTitle="Add new"
      onBtnClick={() => navigate('/orders/credit-notes/create')}
    >
      <div className="sales_order_returns customersList">
        <CreditNotesFilters />
        <CreditNotesTable />
      </div>
    </Wrapper>
  );
}

export default CreditNotes;
