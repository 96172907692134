import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from './Modal';
import Button from '../Form/Button';
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive_with_cirle.svg';
import { ReactComponent as UnArchiveIcon } from '../../../assets/icons/un_archive.svg';

const ArchiveModal = memo(({
  isOpen, onClose, onArchive, className, text, loading, restore,
}) => (
  <Modal
    className={classNames('delete_modal_wrapper archive_modal_wrapper', className)}
    isOpen={isOpen}
    onClose={() => !loading && onClose()}
  >
    <div className="delete_modal_delete_icon_wrapper">
      {restore
        ? <UnArchiveIcon />
        : <ArchiveIcon />}
    </div>

    <h2>
      {`${restore ? 'Restore' : 'Archive'} ${text}`}
    </h2>

    <div className="delete_modal_delete_text">
      <p>{`Are you sure you want to ${restore ? 'restore' : 'archive'} this ${text}?`}</p>
    </div>

    <div className="delete_modal_btns_wrapper">
      <Button
        size="small"
        roundBorder
        btnType="cancel"
        reverseColor
        onClick={onClose}
      >
        Cancel
      </Button>

      <Button
        size="small"
        roundBorder
        loading={loading}
        onClick={onArchive}
        btnType="orange"
      >
        Confirm
      </Button>
    </div>
  </Modal>
));

ArchiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onArchive: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
};

ArchiveModal.defaultProps = {
  className: '',
  text: '',
  onClose: null,
  loading: false,
  onArchive: () => {},
};

export default ArchiveModal;
