import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import Pagination from '../../../_common/Pagination/Pagination';
import Table from '../../../_common/Tables/Table';
import { ReactComponent as NotFoundIcon } from '../../../../assets/icons/notFound/import_not_found.svg';


function PriceToProducts() {
  const { priceId } = useParams();
  const navigate = useNavigate();
  const firstLoad = useRef(false);

  const [tableLoading, setTableLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    (async () => {
      setTableLoading(true);

      const { data } = await Api.getPriceListProducts({ id: priceId, page: currentPage });

      setTotalPages(data.totalPages);
      setProducts(data.products.map((p) => ({
        ...p,
        stock: p?.totalStock?.stock,
        available: p?.totalStock?.available,
        category: p.categories.length ? `${p.categories?.[0]?.name}
          ${p.categories?.[0]?.parents.length
          ? p.categories?.[0]?.parents.map((par) => par?.name).reverse().join(' / ') : ''}`
          : '',
      })));

      setTableLoading(false);
      firstLoad.current = true;
    })();
  }, [currentPage]);

  const onProductClick = useCallback((id) => {
    const foundProduct = products.find((p) => +p.id === +id);

    const parentId = foundProduct?.parentId;

    navigate((+parentId !== 0) ? `/products/${parentId}?productVariantId=${id}` : `/products/${id}`);
  }, [products]);

  const tableHeader = useMemo(() => (
    [
      {
        title: 'Product',
        path: 'productItem',
        navigate: (id) => onProductClick(id),
      },
      {
        title: 'Shop',
        path: 'shopType',
      },
      {
        title: 'SKU',
        path: 'sku',
      },
      {
        title: 'Stock',
        path: 'stock',
      },
      {
        title: 'Available',
        path: 'available',
      },
      {
        title: 'Category',
        path: 'category',
      },
    ]
  ), [products]);

  return (
    <div>
      {!firstLoad.current && tableLoading ? <Loader /> : (
        <div className="inventory_price_to_product">
          <div className="inventory_price_to_product_table">
            <Table
              data={products}
              header={tableHeader}
              loading={tableLoading}
              notFound={(
                <>
                  <NotFoundIcon width={200} height={100} />

                  <h1>Products not found</h1>
                </>
              )}
            />
          </div>

          <Pagination totalPages={totalPages} onChangePage={(p) => setCurrentPage(p)} currentPage={currentPage} />
        </div>
      )}
    </div>
  );
}

export default PriceToProducts;
