import React, { memo, useMemo } from 'react';
import _ from 'lodash';
import Modal from '../../_common/Modal/Modal';
import PayOrder from './BulkAction/PayOrder';
import AddTags from './BulkAction/AddTags';
import RemoveTags from './BulkAction/RemoveTags';
import InvoiceCreate from './BulkAction/InvoiceCreate';

const BulkModal = memo(({
  bulkOrderModal, setBulkOrderModal,
}) => {
  const bulkModals = useMemo(() => ({
    payOrders: <PayOrder closeModal={() => setBulkOrderModal({})} orders={bulkOrderModal.orders} />,
    addTags: <AddTags closeModal={() => setBulkOrderModal({})} orders={bulkOrderModal.orders} />,
    invoice: <InvoiceCreate closeModal={() => setBulkOrderModal({})} orders={bulkOrderModal.orders} />,
    removeTags: <RemoveTags closeModal={() => setBulkOrderModal({})} orders={bulkOrderModal.orders} />,
  }), [bulkOrderModal, setBulkOrderModal]);

  return (
    <Modal
      className="orders_bulk_modal"
      isOpen={!_.isEmpty(bulkOrderModal)}
      onClose={() => setBulkOrderModal({})}
    >
      {bulkModals[bulkOrderModal.path] || ''}
    </Modal>
  );
});

export default BulkModal;
