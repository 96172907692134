import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import { clearProductsList, getProductsRequest } from '../../../../store/actions/products';
import Checkbox from '../../../_common/Form/Checkbox';
import noImg from '../../../../assets/icons/no_img.svg';
import Loader from '../../../_common/Loader/Loader';

const SearchProduct = ({ warehouseId, setRequestData, products }) => {
  const dispatch = useDispatch();
  const productList = useSelector((store) => store.products.productList);
  const cleanSearchInput = useSelector((store) => store.stockTransfer.cleanSearchInput);

  const timeOutRef = useRef();
  const productRef = useRef();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useLayoutEffect(() => {
    if (cleanSearchInput) {
      setValue('');
    }

    setProductIds(products);
  }, [openModal, cleanSearchInput]);

  const handleProductsSearch = async (val) => {
    setValue(val);

    setLoading(true);
    clearTimeout(timeOutRef.current);

    timeOutRef.current = setTimeout(async () => {
      await dispatch(getProductsRequest({
        s: val.trim(),
        includes: 'stock,warehouse',
        warehouseId,
        totalStock: `1,,${warehouseId}`,
      }));

      setOpenModal(true);
      setLoading(false);
    }, 500);
  };

  const handleCheck = (product) => {
    if (productIds.some((p) => p.productId === product.id)) {
      setProductIds((prev) => prev.filter((p) => p.productId !== product.id));
    } else {
      if (productIds.length + 1 > 500) {
        toast.error('You can\'t add more than 500 products');
        return;
      }

      setProductIds((prev) => [...prev, {
        images: product.images, productId: product.id, stocks: product.stocks, quantity: '0', title: product.title,
      }]);
    }
  };

  const cancel = () => {
    dispatch(clearProductsList());
    setValue('');

    dispatch(getProductsRequest({
      includes: 'stock,warehouse',
      warehouseId,
      totalStock: `1,,${warehouseId}`,
    }));

    setOpenModal(false);
  };

  const handleAddItems = () => {
    setRequestData((prev) => ({ ...prev, products: productIds }));

    setOpenModal(false);
    setValue('');
  };

  useClickAway(productRef, () => {
    setOpenModal(false);
  });

  return (
    <div className="products" ref={productRef}>
      <div className="search">
        <Input
          roundBorder
          search
          onChange={(ev) => handleProductsSearch(ev.target.value)}
          value={value}
          placeholder="Search product"
          onFocus={() => setOpenModal(true)}
          autoComplete="off"
        />

        {loading && <Loader size={20} className="products_loading" />}
      </div>

      {openModal && (
      <div className="dropdown">
        <div className="dropdown_content">
          {productList.length ? (
            <ul>
              {productList.map((p) => (
                <li key={p.id}>
                  <Checkbox
                    onChange={() => handleCheck(p)}
                    checked={productIds.some((pr) => pr.productId === p.id)}
                    label={(
                      <div className="checkbox_label">
                        <img src={p.images[0]?.src || p.images[0]?.medium || noImg} alt="img" />

                        <div>
                          <p className="title">{p.title}</p>

                          <p>{p.sku || '-'}</p>
                        </div>
                      </div>
                      )}
                  />

                </li>
              ))}
            </ul>
          )
            : <p style={{ padding: 20 }}>No products</p>}

          <div className="actions">
            <Button roundBorder btnType="cancel" onClick={cancel}>Cancel</Button>

            <Button
              roundBorder
              onClick={handleAddItems}
              disabled={!productIds.length}
              className={classNames('btn_primary', { disable: !productIds.length })}
            >
              Add items
            </Button>
          </div>
        </div>

      </div>
      )}
    </div>
  );
};
SearchProduct.propTypes = {
  warehouseId: PropTypes.string.isRequired,
  setRequestData: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
};
export default SearchProduct;
