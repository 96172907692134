import { createReducer } from '@reduxjs/toolkit';
import {
  cancelImportFile, changeImportExistType, readImportFile, uploadFile,
} from '../actions/fileImport';

const initialState = {
  fields: [],
  fileTableData: [],
  file: null,
  existProductType: 'update',
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(uploadFile, (state, { payload }) => {
      state.file = payload;
    })
    .addCase(changeImportExistType, (state, { payload }) => {
      state.existProductType = payload;
    })

    .addCase(cancelImportFile, (state) => {
      state.file = null;
      state.fileTableData = [];
      state.fields = [];
      state.existProductType = 'update';
    })

    .addCase(readImportFile, (state, { payload }) => {
      state.fileTableData = payload.data;
      state.fields = payload.meta.fields;
    });
});

export default reducer;
