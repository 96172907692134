import React from 'react';

function FilterButton(props) {
  const { children, ...p } = props;
  return (
    <button className="filter_button" type="button" {...p}>
      {children}
    </button>
  );
}

export default FilterButton;
