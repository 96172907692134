import React, { useEffect, useState, useTransition } from 'react';
import PropTypes from 'prop-types';

const CircleProgress = ({
  size, borderWidth, color, title, fontSize, percent,
}) => {
  const [progress, setProgress] = useState(0);
  const [currentPercent, setCurrentPercent] = useState(0);
  const [, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => setProgress(percent));

    const interval = setInterval(() => {
      setCurrentPercent((prev) => {
        if (prev === percent) {
          clearInterval(interval);

          return prev;
        }

        if (prev < percent) {
          return prev + 1;
        }

        return prev - 1;
      });
    }, 2000 / (percent > currentPercent ? percent - currentPercent : currentPercent - percent));
  }, [percent]);

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size + 0.5} ${size}`}
      className="circular-progress"
      data-tooltip-id="default-tooltip"
      data-tooltip-content="Tasks done"
      data-tooltip-place="bottom"
      style={{
        '--size': `${size}px`,
        '--stroke-width': `${borderWidth}px`,
        '--progress': progress,
        '--bar-color': color,
        '--bar-font-size': `${fontSize}px`,
      }}
    >
      <circle className="bg" />
      <circle className="fg" />

      <foreignObject
        x="0"
        y="0"
        width="100%"
        height="100%"
      >
        <div className="title">
          {title && <p>{title}</p>}

          <p>{`${currentPercent}%`}</p>
        </div>
      </foreignObject>

      {/* <text */}
      {/*  x="50%" */}
      {/*  y="50%" */}
      {/*  dominantBaseline="middle" */}
      {/*  textAnchor="middle" */}
      {/* > */}
      {/*  {`${currentPercent}%`} */}
      {/* </text> */}
    </svg>
  );
};

CircleProgress.propTypes = {
  size: PropTypes.number,
  borderWidth: PropTypes.number,
  color: PropTypes.string,
  title: PropTypes.string,
  fontSize: PropTypes.number,
  percent: PropTypes.number.isRequired,
};

CircleProgress.defaultProps = {
  size: 200,
  borderWidth: 20,
  color: '#1472FF',
  title: '',
  fontSize: 33,
};

export default CircleProgress;
