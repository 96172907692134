import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  deleteStockTransferRequest,
  getStockTransferListRequest,
  getWarehouseList,
  receiveStockTransferRequest,
} from '../actions/stockTransfer';
import { getProductsRequest } from '../actions/products';

const initialState = {
  warehouseList: [],
  stockTransferList: [],
  stockTransferTotalPage: 1,
  cleanSearchInput: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProductsRequest.pending, (state, action) => {
      state.cleanSearchInput = !action.meta.arg.s;
    })
    .addCase(getWarehouseList.fulfilled, (state, { payload }) => {
      state.warehouseList = payload.warehouses;
    })
    .addCase(deleteStockTransferRequest.fulfilled, (state, action) => {
      state.stockTransferList = state.stockTransferList.filter((s) => s.id !== action.meta.arg);
    })
    .addCase(receiveStockTransferRequest.fulfilled, (state, action) => {
      state.stockTransferList = state.stockTransferList.map((s) => (s.id === action.meta.arg.id
        ? action.payload.data.stockTransfer
        : s));
    })
    .addCase(getStockTransferListRequest.pending, (state) => {
      state.stockTransferList = [];
      state.stockTransferTotalPage = 1;
    })
    .addCase(getStockTransferListRequest.fulfilled, (state, { payload }) => {
      state.stockTransferList = _.uniqBy(payload.stockTransfers, 'id');
      state.stockTransferTotalPage = payload.totalPages;
    });
});

export default reducer;
