import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { format } from 'currency-formatter';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import shopIcons from '../../../../../assets/shops';
import DynamicTable from '../../../../_common/DynamicTable/DynamicTable';
import Utils from '../../../../../helpers/Utils';
import Loader from '../../../../_common/Loader/Loader';
import Pagination from '../../../../_common/Pagination/Pagination';
import useWindowSize from '../../../../../helpers/hooks/useWindowSize';
import StatusField from '../../../../_common/StatusField/StatusField';
import { handleGetReceiveStatus } from '../../../Orders/returns/ReturnsTable';

const columns = {
  sale: [
    { key: 'orderId', isEnabled: true },
    { key: 'shop', isEnabled: true },
    { key: 'customer', isEnabled: true },
    { key: 'shippingAddress', isEnabled: true },
    { key: 'billingAddress', isEnabled: true },
    { key: 'status', isEnabled: true },
    { key: 'paymentStatus', isEnabled: true },
    { key: 'createdAt', isEnabled: true },
  ],
  purchase: [
    { key: 'orderId', isEnabled: true },
    { key: 'shop', isEnabled: true },
    { key: 'customer', isEnabled: true },
    { key: 'shippingAddress', isEnabled: true },
    { key: 'billingAddress', isEnabled: true },
    { key: 'status', isEnabled: true },
    { key: 'paymentStatus', isEnabled: true },
    { key: 'createdAt', isEnabled: true },
  ],
  stock: [
    { key: 'number', isEnabled: true },
    { key: 'sourceWarehouse', isEnabled: true },
    { key: 'destinationWarehouse', isEnabled: true },
    { key: 'totalUnits', isEnabled: true },
    { key: 'receivedAt', isEnabled: true },
    { key: 'updatedAt', isEnabled: true },
    { key: 'status', isEnabled: true },
  ],
  shipments: [
    { key: 'shipping', isEnabled: true },
    { key: 'salesOrder', isEnabled: true },
    { key: 'customer', isEnabled: true },
    { key: 'date', isEnabled: true },
    { key: 'carrier', isEnabled: true },
    { key: 'tracking', isEnabled: true },
    { key: 'shippingRate', isEnabled: true },
    { key: 'status', isEnabled: true },
  ],
  returns: [
    { key: 'return', isEnabled: true },
    { key: 'salesOrder', isEnabled: true },
    { key: 'customer', isEnabled: true },
    { key: 'date', isEnabled: true },
    { key: 'status', isEnabled: true },
    { key: 'receiveStatus', isEnabled: true },
    { key: 'returned', isEnabled: true },
  ],
};

const defaultAttributes = {
  sale: {
    orderId: 'Order Id',
    shop: 'Shop',
    customer: 'Customer',
    shippingAddress: 'Shipping address',
    billingAddress: 'Billing address',
    status: 'Status',
    paymentStatus: 'Payment status',
    createdAt: 'Created At',
  },
  purchase: {
    orderId: 'Order Id',
    shop: 'Shop',
    customer: 'Customer',
    shippingAddress: 'Shipping address',
    billingAddress: 'Billing address',
    status: 'Status',
    paymentStatus: 'Payment status',
    createdAt: 'Created At',
  },
  stock: {
    number: 'Transfer number',
    sourceWarehouse: 'Location from',
    destinationWarehouse: 'Location to',
    totalUnits: 'Total units',
    receivedAt: 'Receive date',
    updatedAt: 'Last update',
    status: 'Status',
  },
  shipments: {
    shipping: 'Shipping#',
    salesOrder: 'Sales order#',
    customer: 'Customer',
    date: 'Date',
    carrier: 'Carrier',
    tracking: 'Tracking#',
    shippingRate: 'Shipping rate',
    status: 'status',
  },
  returns: {
    return: 'Return#',
    salesOrder: 'Sales order#',
    customer: 'Customer',
    date: 'Date',
    status: 'status',
    receiveStatus: 'Receive status',
    returned: 'Returned',
  },
};

const SingleBatchesTable = ({
  type, firstLoading, loading, page, onChangePage,
}) => {
  const { isMobile } = useWindowSize(870);

  const orders = useSelector((state) => state.orders.orders);
  const ordersTotalPages = useSelector((state) => state.orders.totalPages);

  const stockTransferList = useSelector((store) => store.stockTransfer.stockTransferList);
  const stockTransferTotalPage = useSelector((store) => store.stockTransfer.stockTransferTotalPage);

  const singleReturn = useSelector((state) => state.orderReturns.orderReturnsList);
  const singleReturnTotalPage = useSelector((state) => state.orderReturns.totalPages);

  const shipments = useSelector((state) => state.packages.allPackages);
  const shipmentsTotalPages = useSelector((state) => state.packages.totalPages);

  const tableData = useMemo(() => (
    {
      sale: { data: orders, totalPage: ordersTotalPages },
      purchase: { data: orders, totalPage: ordersTotalPages },
      stock: { data: stockTransferList, totalPage: stockTransferTotalPage },
      shipments: { data: shipments, totalPage: shipmentsTotalPages },
      returns: { data: singleReturn, totalPage: singleReturnTotalPage },
    }), [orders, ordersTotalPages, stockTransferList,
    stockTransferTotalPage, shipments, shipmentsTotalPages, singleReturn, singleReturnTotalPage]);

  useEffect(() => {
    (() => {

    })();
  }, []);
  return (
    <div className="single_batches_table_wrapper">
      {firstLoading
        ? <Loader />
        : (
          <div>
            <DynamicTable
              data={tableData[type].data}
              keyExtractor={(item) => item.productId || item.id}
              columns={columns[type]}
              loading={loading}
              renderColumnHeader={(col) => defaultAttributes[type][col.key] || col.key}
              renderColumns={{
                orderId: ({ item }) => (
                  <Link
                    to={`${type === 'sale' ? '/orders/sales-order' : '/stocks/purchase-orders'}/preview/${item.id}`}
                  >
                    {item.number}

                  </Link>
                ),

                shop: ({ item }) => (
                  <div className="shop" style={{ width: 40 }}>
                    <img
                      src={shopIcons[item.shopType || 'eswap']}
                      alt=""
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                ),

                customer: ({ item }) => (
                  <div>
                    {type === 'shipments'
                      ? `${item.order?.shippingAddress?.firstName || '-'} ${item.order?.shippingAddress?.lastName || ''}`
                      : `${item.customer?.firstName || '-'} ${item.customer?.lastName || '-'}`}
                  </div>
                ),

                shippingAddress: ({ item }) => Utils.formatAddress(
                  [item.shippingAddress?.address1,
                    item.shippingAddress?.city,
                    item.shippingAddress?.region,
                    item.shippingAddress?.postalCode,
                    item.shippingAddress?.countryCode],
                ),

                billingAddress: ({ item }) => Utils.formatAddress(
                  [item.billingAddress?.address1,
                    item.billingAddress?.city,
                    item.billingAddress?.region,
                    item.billingAddress?.postalCode,
                    item.billingAddress?.countryCode],
                ),

                status: ({ item }) => <StatusField status={item.status} a={console.log(item.status)} />,

                paymentStatus: ({ item }) => <StatusField status={item.paymentStatus} />,

                createdAt: ({ item }) => moment(item.createdAt).format('DD MMM YYYY'),

                number: ({ item }) => (
                  <Link to={`/stocks/stock-transfer/receive/${item.id}`}>
                    {item.number}
                  </Link>
                ),

                sourceWarehouse: ({ item }) => item.sourceWarehouse?.title || '-',

                destinationWarehouse: ({ item }) => item.destinationWarehouse?.title || '-',

                totalUnits: ({ item }) => item.totalUnits,

                receivedAt: ({ item }) => (item.receivedAt ? moment(item.receivedAt).format('MM/DD/YY') : '-'),

                updatedAt: ({ item }) => moment(item.updatedAt).format('MM/DD/YY'),

                shipping: ({ item }) => item.number || '-',

                date: ({ item }) => moment(item.sourceCreatedAt || item.createdAt).format('DD MMM YYYY'),

                carrier: ({ item }) => item.carrier || '-',

                tracking: ({ item }) => item.trackingNumber || item.trackNumber || '-',

                shippingRate: ({ item }) => format(item.shippingPrice, { code: item?.order?.currencyCode }),

                return: ({ item }) => <Link to={`/orders/returns/preview/${item.id}`}>{item.number}</Link>,

                salesOrder: ({ item }) => (
                  <Link
                    to={`/orders/sales-order/preview/${item?.order?.id}`}
                  >
                    {item?.order?.number}
                  </Link>
                ),

                receiveStatus: ({ item }) => (
                  <StatusField
                    status={handleGetReceiveStatus(item.orderReturnProducts)}
                  />
                ),

                returned: ({ item }) => _.sumBy(item.orderReturnProducts, 'qty'),
              }}
            />

            {tableData[type].totalPage > 1 && (
              <div className="batches_pagination">
                <Pagination
                  page={page}
                  totalPages={+tableData[type].totalPage}
                  showNumber={isMobile ? 1 : 2}
                  onChangePage={(currentPage) => onChangePage(currentPage, type)}
                />
              </div>
            )}
          </div>

        )}

    </div>
  );
};

SingleBatchesTable.propTypes = {
  type: PropTypes.string,
  firstLoading: PropTypes.bool,
  loading: PropTypes.bool,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangePage: PropTypes.func,
};
SingleBatchesTable.defaultProp = {
  type: 'sale',
  firstLoading: false,
  loading: false,
  page: 1,
  onChangePage: () => {
  },
};

export default SingleBatchesTable;
