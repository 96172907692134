import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/icons/checkbox_success.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_red.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ReactComponent as ViewImageIcon } from '../../../assets/icons/view_img.svg';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/warning_red.svg';
import DefaultImage from '../../../assets/icons/default.svg';
import Button from '../../_common/Form/Button';
import ProductPrices from './CreateComponents/ProductPrices';
import ProductInventory from './CreateComponents/ProductInventory';
import Input from '../../_common/Form/Input';
import Modal from '../../_common/Modal/Modal';
import Utils from '../../../helpers/Utils';
import DeleteModal from '../../_common/Modal/DeleteModal';
import ProductDimensions from './CreateComponents/ProductDimensions';
import Checkbox from '../../_common/Form/Checkbox';
import LinkedProducts from './CreateComponents/LinkedProducts';
import ProductCustomFields from './CreateComponents/ProductCustomFields';

const imageAllowFormats = '.png, .jpg, .jpeg, .webp';

function CreateProductVariant({
  setVariantId, variantId, variants, onDataChange, isVariantDataChanged, parentProductImages,
  onUpdateVariant, setNextVariantIndex, nextVariantIndex, errors, setSaveVariantModal, saveVariantModal, productData,
}) {
  const { productId } = useParams();

  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [imageModal, setImageModal] = useState();

  const selectedVariant = useMemo(() => (
    variants.find((v) => v.id.toString() === variantId.toString())
  ), [variantId, variants]);

  const selectedVariantIndex = useMemo(() => (
    variants.findIndex((v) => v.id.toString() === variantId.toString())
  ), [variantId, variants]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (saveVariantModal && nextVariantIndex === null) {
      setSaveVariantModal(false);
    }
  }, [nextVariantIndex]);

  const onVariantClick = useCallback((id, index) => {
    // if (isVariantDataChanged && productId !== 'add') {
    //   if (id.toString() !== variantId.toString()) {
    //     setSaveVariantModal(true);
    //     setNextVariantIndex(index);
    //   }
    // } else {
    setVariantId(id);
    // }
  }, [isVariantDataChanged]);

  const onUploadFile = useCallback((e) => {
    Utils.onUploadFile(e, (file) => {
      onDataChange('variantImage', file);
      setUploadImageModal(false);
    }, imageAllowFormats);
  }, [onDataChange]);

  const onDeleteIconClick = useCallback(() => {
    onDataChange('variantImage', 'delete');
    setImageModal('');
  }, []);

  const onSaveModalClose = useCallback(() => {
    setNextVariantIndex(null);
    setSaveVariantModal(false);
  }, []);

  return (
    <div className="create_product_variant">
      <div className="product_create_header">
        <div className="product_header_left">
          <ArrowIcon onClick={() => setVariantId('')} className="product_header_left_back" />

          <h2 className="product_create_header_title">Update variant</h2>

          {Utils.getAppIcon(selectedVariant, true)}
        </div>

        <div className="product_create_header_btns">
          <Button
            onClick={() => setVariantId('')}
            btnType="cancel"
            title="Cancel"
            size="small"
            roundBorder
          />

          <Button
            onClick={() => onUpdateVariant()}
            title="Update variant"
            size="small"
            roundBorder
            className="create_product_button"
          />
        </div>
      </div>

      <div className="create_product_variant_block">
        <div className="create_product_variant_block_left">
          <div className="create_product_variant_title_block">
            <div className="create_product_variant_title_block_img_wrp">
              <img
                src={parentProductImages?.[0]?.src?._preview || parentProductImages?.[0]?.src || DefaultImage}
                className="create_product_variant_image"
                alt="Variant image"
              />
            </div>

            <p className="create_product_variant_name">{selectedVariant.title}</p>
            <p className="create_product_variant_count">{`${variants.length} variants`}</p>
          </div>

          <div className="create_product_variant_variants">
            <p className="create_product_variant_title">Variants</p>

            <div className="create_product_variant_all">
              {variants.map((v, i) => (
                <div
                  className="create_product_variant_single"
                  key={v.id}
                  onClick={() => onVariantClick(v.id, i)}
                >
                  <div>
                    <img
                      src={v.images?.[0]?.src?._preview || v.images?.[0]?.src || DefaultImage}
                      className="create_product_variant_single_img"
                      alt="Variant image"
                    />
                  </div>

                  <p className={`create_product_variant_single_name ${variantId === v.id ? 'active' : ''}`}>
                    {v.title}
                  </p>

                  {errors?.variants?.[i] && <ErrorIcon className="create_product_variant_single_error" />}

                  {variantId === v.id && <CheckedIcon className="create_product_variant_check_icon" />}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="create_product_variant_block_right">
          <div className="variant_options">
            <p className="create_product_variant_title">Options</p>

            <div className="variant_options_inputs">
              {selectedVariant.images?.[0]
                ? (
                  <div
                    className="variant_options_inputs_image_wrp"
                    onMouseEnter={() => setIsImageHovered(true)}
                    onMouseLeave={() => setIsImageHovered(false)}
                  >
                    {isImageHovered && (
                      <div className="hovered_image_layer">
                        <div>
                          <ViewImageIcon onClick={() => setImageModal('view')} />
                        </div>

                        <div>
                          <PencilIcon onClick={() => setUploadImageModal(true)} />
                        </div>
                      </div>
                    )}

                    <img
                      src={selectedVariant.images?.[0]?.src?._preview || selectedVariant.images?.[0]?.src}
                      alt="Variant image"
                      className="variant_options_inputs_image"
                    />

                    <DeleteIcon
                      className="variant_options_inputs_image_delete"
                      onClick={() => setImageModal('delete')}
                    />
                  </div>
                )
                : (
                  <div className="variant_options_inputs_upload">
                    <div
                      className="product_create_variant_upload"
                      onClick={() => setUploadImageModal(true)}
                    >
                      <UploadIcon />

                      <p>Upload image</p>
                    </div>
                  </div>
                )}

              <div className="variant_input_name">
                <Input
                  size="small"
                  roundBorder
                  label="Title"
                  value={selectedVariant.title}
                  onChangeText={(value) => onDataChange('variantTitle', value)}
                  error={errors?.variants?.[variants.findIndex(
                    (v) => v.id.toString() === selectedVariant.id.toString(),
                  )]?.title}
                />
              </div>

              <div className="variant_input_sku">
                <Input
                  size="small"
                  roundBorder
                  label="SKU"
                  value={selectedVariant.sku}
                  onChangeText={(value) => onDataChange('variantSku', value)}
                />
              </div>

              {selectedVariant.attributes.map(({ title, value }) => (
                <div className="variant_input" key={title + value}>
                  <Input
                    size="small"
                    roundBorder
                    label={title}
                    value={value}
                    disabled
                  />
                </div>
              ))}
            </div>
          </div>

          <ProductPrices
            onDataChange={onDataChange}
            priceData={selectedVariant.priceData}
            isVariant
          />

          <ProductDimensions
            onDataChange={onDataChange}
            dimensions={selectedVariant.dimensions}
            dimensionsUnit={selectedVariant.dimensionsUnit}
            weightUnit={selectedVariant.weightUnit}
            weight={selectedVariant.weight}
            variantId={selectedVariant.id}
            errors={errors}
            variants={variants}
          />

          <ProductInventory
            stockWarehouses={selectedVariant.variantStockWarehouses}
            onDataChange={onDataChange}
            isVariant
          />

          <ProductCustomFields
            customFields={selectedVariant.customFields}
            onDataChange={onDataChange}
            isVariant
            errors={errors}
            selectedVariantIndex={selectedVariantIndex}
          />

          {/* <div className="variant_sales_channels"> */}
          {/*   <h3 className="create_product_title_block_title">Sales channels</h3> */}

          {/*   <Table */}
          {/*     data={[1, 2]} */}
          {/*     header={salesTableHeader} */}
          {/*   /> */}
          {/* </div> */}

          {productId !== 'add' && (
            <LinkedProducts
              data={selectedVariant}
              parentProductData={productData}
              onDataChange={onDataChange}
              updateProduct={onUpdateVariant}
            />
          )}
        </div>

        <Modal
          isOpen={saveVariantModal}
          onClose={onSaveModalClose}
          className="create_product_variant_save_modal"
        >
          <h3>Save changes to continue?</h3>

          <div className="save_variant_changes_modal_btns">
            <Button
              size="small"
              roundBorder
              btnType="cancel"
              reverseColor
              onClick={() => setSaveVariantModal(false)}
              title="Cancel"
            />

            <Button
              size="small"
              roundBorder
              onClick={onUpdateVariant}
              type="submit"
              className="save_variant_changes_modal_save_btn"
              title="Save"
            />
          </div>
        </Modal>

        <DeleteModal
          isOpen={imageModal === 'delete'}
          onClose={() => setImageModal('')}
          onDelete={onDeleteIconClick}
        />

        <Modal
          isOpen={imageModal === 'view'}
          onClose={() => setImageModal('')}
          className="product_variant_img_view_modal"
          zIndex={103}
        >
          <div>
            <img
              src={selectedVariant.images?.[0]?.src?._preview || selectedVariant.images?.[0]?.src || DefaultImage}
              alt="Variant image"
              className="product_variant_img_view_modal_image"
            />
          </div>
        </Modal>

        <Modal
          isOpen={uploadImageModal}
          onClose={() => setUploadImageModal(false)}
          className="create_product_image_upload_modal"
          zIndex={103}
        >
          <div className="create_product_image_upload_modal_block">
            <div className="create_product_images_input_wrp">
              <Button
                btnType="upload"
                onFileUpload={onUploadFile}
                title="Upload image"
                reverseColor
                className="create_product_images_input"
              />
            </div>

            <div className="create_product_upload_modal_bottom">
              <div className="create_product_upload_image_all">
                {parentProductImages.map((img) => (
                  <div
                    className="create_product_upload_image_single"
                    onClick={() => onDataChange('variantImageUpdate', img)}
                    key={_.uniqueId()}
                  >
                    <img
                      src={img?.src?._preview || img?.src || DefaultImage}
                      alt="Variant image"
                      className="create_product_upload_img"
                    />

                    <Checkbox
                      onChange={() => onDataChange('variantImageUpdate', img)}
                      checked={selectedVariant.images?.[0]?.src?._preview
                        ? (selectedVariant.images?.[0]?.src?._preview === img?.src?._preview)
                        : (selectedVariant.images?.[0]?.src === img?.src)}
                      className="selected_image_checkbox"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

CreateProductVariant.propTypes = {
  setVariantId: PropTypes.func,
  variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variants: PropTypes.array,
  onDataChange: PropTypes.func,
  isVariantDataChanged: PropTypes.bool,
  parentProductImages: PropTypes.array,
  onUpdateVariant: PropTypes.func,
  setNextVariantIndex: PropTypes.func,
  nextVariantIndex: PropTypes.string,
  errors: PropTypes.object,
  setSaveVariantModal: PropTypes.func,
  saveVariantModal: PropTypes.bool,
  productData: PropTypes.object,
};

CreateProductVariant.defaultProps = {
  setVariantId: () => {},
  variantId: '',
  variants: [],
  onDataChange: () => {},
  isVariantDataChanged: false,
  parentProductImages: [],
  onUpdateVariant: () => {},
  setNextVariantIndex: () => {},
  nextVariantIndex: '',
  errors: {},
  setSaveVariantModal: () => {},
  saveVariantModal: false,
  productData: {},
};

export default CreateProductVariant;
