import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'currency-formatter';
import { toast } from 'react-toastify';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import Input from '../../../_common/Form/Input';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';
import Datepicker from '../../../_common/Form/Datepicker';
import { getPaymentMethodsRequest } from '../../../../store/actions/settings';
import Utils from '../../../../helpers/Utils';
import { bulkPayOrderRequest } from '../../../../store/actions/orders';
import { getOrdersInvoicesRequest } from '../../../../store/actions/invoices';

const columns = [
  {
    key: 'orderNumber', isEnabled: true,
  },
  {
    key: 'paidAmount', isEnabled: true,
  },
  {
    key: 'amountReceived', isEnabled: true,
  },
  {
    key: 'paymentType', isEnabled: true,
  },
];

const defaultAttributes = {
  orderNumber: 'Sales order#',
  paidAmount: 'Paid Amount',
  amountReceived: 'Amount to be paid',
  paymentType: 'Payment type',
};

const PayOrder = ({ closeModal, orders = [] }) => {
  const dispatch = useDispatch();
  const [dateOpenPortal, setDateOpenPortal] = useState();
  const [selectLoading, setSelectLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState([]);
  const [date, setDate] = useState(new Date());
  const [requestData, setRequestData] = useState([]);
  const [error, setError] = useState([]);
  const allOrdersInvoices = useSelector((state) => state.invoices.allOrdersInvoices);

  useMemo(() => {
    setRequestData(orders.map((o) => ({
      orderId: o.id, amount: 0, sourceCreatedAt: date, paymentType: paymentType[0],
    })));
  }, [orders, date, paymentType]);

  useEffect(() => {
    (async () => {
      setSelectLoading(true);
      const { payload: { paymentMethods } } = await dispatch(getPaymentMethodsRequest());

      setPaymentType(paymentMethods);
      setSelectLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(orders)) {
      setDateOpenPortal(document.querySelector('#modal'));

      dispatch(getOrdersInvoicesRequest({
        orderId: orders.map((d) => d.id).join(','),
        limit: 100,
        includes: 'payments',
      }));
    }
  }, [orders]);

  const changeData = (path, value) => {
    setError((prev) => {
      _.unset(prev, path);

      return [...prev];
    });

    setRequestData((prev) => _.set(prev, path, value));
  };

  const saveClick = async () => {
    setLoading(true);
    let hasError = false;

    const data = await Promise.all(requestData.map((r) => (+r.amount
      ? dispatch(bulkPayOrderRequest(r))
      : { payload: {} })));

    const errors = data.map((d) => {
      if (d.payload.status >= 500) {
        hasError = true;
      }
      return d.payload.data?.errors;
    });

    if (hasError) {
      toast.error('Something wrong please try again!');
    } else if (errors.filter((e) => e).length) {
      setError(errors);

      errors.forEach((er, index) => {
        if (!er) {
          changeData(`${index}.amount`, 0);
        }
      });

      await dispatch(getOrdersInvoicesRequest({
        orderId: orders.map((d) => d.id).join(','),
        limit: 100,
        includes: 'payments',
      }));

      toast.error('Please correctly set the field !');
    } else {
      closeModal();
    }

    setLoading(false);
  };

  return (
    <div className="bulk_pay_order_wrapper">
      <h1>Pay orders</h1>

      <Datepicker
        label="Date"
        onChange={setDate}
        value={moment(date).format('MM.DD.YYYY')}
        error=""
        wrapperClassName="pay_order_date"
        portalContainer={dateOpenPortal}
      />

      <div className="bulk_pay_order_table_wrapper">
        <DynamicTable
          data={orders}
          keyExtractor={(item) => item.id}
          columns={columns}
          renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
          renderColumns={{
            orderNumber: ({ item }) => (<div className="bulk_pay_order_table_item">{item.number}</div>),

            paidAmount: ({ item }) => {
              const totalPayed = _.sumBy(
                allOrdersInvoices
                  .filter((d) => d.orderId === item.id)
                  .map((d) => d.payments)
                  .flat(1),
                (p) => +p.amount || 0,
              );

              return (
                <div className="bulk_pay_order_table_item">
                  {format(totalPayed, { code: item.currencyCode })}
                </div>
              );
            },

            amountReceived: ({ item, index }) => {
              const { amount } = requestData.find((r) => r.orderId === item.id);

              return (
                <Input
                  wrapperClassName="bulk_pay_order_input"
                  placeholder={!amount ? '0' : ''}
                  value={amount === 0 ? '' : amount}
                  onChange={(e) => changeData(`${index}.amount`, e.target.value.trim() || 0)}
                  onBeforeInput={Utils.typingNumber}
                  error={error[index]?.amount}
                />
              );
            },

            paymentType: ({ item, index }) => (
              <Select
                wrapperClassName="bulk_pay_order_select"
                getOptionLabel={(o) => o.title}
                getOptionValue={(o) => o.id}
                options={paymentType}
                value={requestData.find((r) => r.orderId === item.id).paymentType}
                onChange={(val) => changeData(`${index}.paymentType`, val)}
                valuePath="id"
                menuPosition="fixed"
                getFullOption
                menuPortalTarget={dateOpenPortal}
                loading={selectLoading}
                error={error[index]?.paymentType}
              />
            ),
          }}
        />

      </div>

      <div className="bulk_pay_order_buttons_wrapper">
        <Button
          className="bulk_pay_order_button"
          size="small"
          title="Cancel"
          onClick={closeModal}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="bulk_pay_order_button"
          size="small"
          title="Pay orders"
          onClick={saveClick}
          roundBorder
          color="#1472FF"
          loading={loading}
          disabled={!requestData.filter((r) => +r.amount).length}
        />
      </div>
    </div>
  );
};

export default PayOrder;
