import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'currency-formatter';
import moment from 'moment/moment';
import Wrapper from '../../components/Layout/Wrapper';
import noImg from '../../assets/icons/no_img.svg';
import { getProductsRequest } from '../../store/actions/products';
import Utils from '../../helpers/Utils';
import ReceivesList from '../../components/pages/Orders/receives/ReceivesList';
import ReceivePreviewHeader from '../../components/pages/Orders/receives/ReceivePreviewHeader';

function ReceivePreview() {
  const [products, setProducts] = useState([]);
  const { receiveId } = useParams();
  const dispatch = useDispatch();

  const allPackages = useSelector((state) => state.packages.allPackages);

  const singleReceive = useMemo(() => allPackages.find((o) => o.id === receiveId) || {}, [allPackages, receiveId]);

  useEffect(() => {
    (async () => {
      if (!singleReceive) {
        return;
      }
      const { payload } = await dispatch(getProductsRequest(
        { ids: singleReceive.orderPackageProducts?.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [singleReceive]);

  return (
    <Wrapper>
      <div className="preview_wrapper return_preview">
        <Helmet>
          <body className="sales_order_preview" />
        </Helmet>
        <ReceivesList />
        <div className="content">
          <ReceivePreviewHeader />
          <div className="order_preview_content">
            <div className="left">
              <div className="action_tabs">
                <div className="tabs">
                  <div
                    className="tab active"
                  >
                    Purchase orders
                  </div>
                </div>
                <div className="items sales_orders">
                  <div className="products_list table_wrapper">
                    <table className="table">
                      <thead className="table_thead">
                        <tr className="table_thead_tr">
                          <th className="table_thead_tr_th">Order#</th>
                          <th className="table_thead_tr_th">Date</th>
                          <th className="table_thead_tr_th">Status</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody className="table_tbody">
                        <tr
                          className="table_tbody_tr"
                        >

                          <td className="table_tbody_tr_td link">
                            <Link
                              to={`/stocks/purchase-orders/preview/${singleReceive?.orderId}`}
                            >
                              {singleReceive?.order?.number}
                            </Link>
                          </td>
                          <td className="table_tbody_tr_td">
                            {moment(singleReceive?.order?.sourceCreatedAt).format('MM/DD/YYYY')}
                          </td>
                          <td className={`table_tbody_tr_td status ${singleReceive?.order?.status}`}>
                            <div>
                              <p className="status_dot" />
                              <span>{Utils.formatPurchaseStatus(singleReceive?.order?.status)}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="items">
                <div className="products_list table_wrapper">
                  <table className="table">
                    <thead className="table_thead">
                      <tr className="table_thead_tr">
                        <th className="table_thead_tr_th">Item</th>
                        <th className="table_thead_tr_th">Quantity</th>
                        <th className="table_thead_tr_th">Rate</th>
                        <th className="table_thead_tr_th">Amount</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody className="table_tbody">
                      {singleReceive?.orderPackageProducts?.map((p) => {
                        const product = products?.find((f) => f.id === p.productId) || {};
                        const order = singleReceive?.order?.orderProducts?.find((f) => f.productId === p.productId) || {};
                        return (
                          <tr
                            className="table_tbody_tr"
                            key={p.id}
                          >

                            <td className="table_tbody_tr_td">
                              <div className="info">
                                <img
                                  style={{ height: 30, width: 30 }}
                                  src={product.images?.[0]?.src || product.images?.[0]?.medium || noImg}
                                  alt=""
                                />
                                <Link to={`/products/${product.id}`} target="_blank">{product.title}</Link>
                              </div>
                            </td>
                            <td className="table_tbody_tr_td">
                              {p.qty}
                            </td>
                            <td
                              className="table_tbody_tr_td"
                            >
                              {format(order.price, { code: singleReceive.order.currencyCode })}
                            </td>
                            <td
                              className="table_tbody_tr_td"
                            >
                              {format((order.price * p.qty), { code: singleReceive.order.currencyCode })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="order_information_container">
              <div className="order_information">
                <div className="col">
                  <div className="item">
                    <p
                      className="value"
                    >
                      {`${singleReceive?.order?.shippingAddress?.firstName || ''} ${singleReceive?.order?.shippingAddress?.lastName || ''}`}
                    </p>
                  </div>
                  <div className="item">
                    <p className="value">{singleReceive?.order?.shippingAddress?.phone || ''}</p>
                  </div>
                  <div className="item">
                    <p className="value">{singleReceive?.order?.shippingAddress?.email || ''}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </Wrapper>
  );
}

export default ReceivePreview;
