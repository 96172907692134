import React, { useCallback, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../Api';
import Account from '../helpers/Account';
import Loader from '../components/_common/Loader/Loader';

function ShopifyAuth() {
  const [query] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    request().catch(console.error);
  }, [query]);

  const request = useCallback(async () => {
    try {
      const requestData = Object.fromEntries([...query]);
      const { data } = await Api.shopifyAppAuth(requestData);
      const { authData, ownerId, integrationHash } = data;
      Account.remove();
      sessionStorage.setItem('authData', JSON.stringify(authData));
      const hash = `integrationHash=${encodeURIComponent(integrationHash)}`;
      navigate(ownerId ? `/login?${hash}` : `/signup?${hash}`, {
        replace: true,
      });
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    }
  }, [query]);

  return (
    <div>
      <Loader />
    </div>
  );
}

export default ShopifyAuth;
