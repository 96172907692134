import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HouseIcon } from '../../../../assets/icons/house.svg';
import { ReactComponent as AllProductsIcon } from '../../../../assets/icons/all_product.svg';
import { ReactComponent as ArchivedProductsIcon } from '../../../../assets/icons/archived_products.svg';
import { ReactComponent as QuantityIcon } from '../../../../assets/icons/quantity_on_hand.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import { ReactComponent as DragIcon } from '../../../../assets/icons/dashboard_drag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/dashboard_close.svg';
import Button from '../../../_common/Form/Button';
import Loader from '../../../_common/Loader/Loader';
// import { useSelector } from 'react-redux';

const dashboardProductsTypes = [
  {
    path: 'outOfStockProducts',
    dataPath: 'outOfStock',
    title: 'Out of stock products',
    link: '/products?totalStock=%2C0',
    icon: <HouseIcon />,
    // iconBackground: 'rgba(250, 88, 46, 0.15)',
  },
  {
    path: 'allProducts',
    dataPath: 'allProducts',
    title: 'All products',
    link: '/products',
    icon: <AllProductsIcon />,
    // iconBackground: 'rgba(1, 99, 222, 0.15)',
  },
  {
    path: 'archivedProducts',
    dataPath: 'archived',
    title: 'Archived products',
    link: '/inventory/archive',
    icon: <ArchivedProductsIcon />,
    // iconBackground: 'rgba(22, 195, 91, 0.15)',
  },
  {
    path: 'quantityOnHand',
    dataPath: 'onHand',
    title: 'Quantity On Hand',
    icon: <QuantityIcon />,
    // iconBackground: 'rgba(120, 40, 220, 0.15)',
  },
];

const DashboardProducts = ({
  data, disable, innerRef, onDelete, loading,
}) => {
  const navigate = useNavigate();

  const products = useSelector((store) => store.dashboard.products || []);
  const productTypes = dashboardProductsTypes.find((d) => d.path === data?.key);
  const currentStats = products[productTypes?.dataPath] > 99999 ? '99999+' : products[productTypes?.dataPath];

  return (
    <div
      ref={(ref) => {
        innerRef[data?.key] = ref || innerRef[data?.key];
      }}
      className="grid-stack-item"
      gs-max-w="12"
      gs-min-w="2"
      gs-max-h="1"
      gs-min-h="1"
      gs-id={data?.key}
      gs-w={data?.value?.position?.w}
      gs-h={data?.value?.position?.h}
      gs-x={data?.value?.position?.x}
      gs-y={data?.value?.position?.y}
      style={{ display: data?.show === false ? 'none' : 'block' }}
    >
      <div className="grid-stack-item-content">
        <div className="chart_wrapper" style={{ cursor: disable ? 'grab' : 'default' }}>
          {loading ? <Loader /> : (
            <div className="dashboard_white_card_single">
              <div className="dashboard_product_up">
                <div className="dashboard_white_card_top">
                  {productTypes?.icon}
                </div>

                {disable && (
                <div className="drag_icons">
                  <DragIcon />

                  <CloseIcon onClick={onDelete} />
                </div>
                )}

                <p className="dashboard_white_card_top_title">{productTypes?.title}</p>
              </div>

              <div className="dashboard_white_card_bottom">
                <p className="dashboard_white_card_number">{currentStats || 0}</p>

                {productTypes?.link && !disable && (
                <Button
                  onClick={() => { navigate(productTypes?.link); }}
                  iconRight={<ArrowIcon />}
                  height={43}
                  color="#DFE6F0"
                  className="dashboard_white_card_button"
                  roundBorder
                />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DashboardProducts.propTypes = {
  data: PropTypes.object.isRequired,
  disable: PropTypes.bool.isRequired,
  innerRef: PropTypes.object.isRequired,
};

export default DashboardProducts;
