import React, {
  useCallback, useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useDeepCompareEffect } from 'react-use';
import Loader from '../../components/_common/Loader/Loader';
import Wrapper from '../../components/Layout/Wrapper';
import InvoiceFilters from '../../components/pages/Orders/invoices/InvoicesFilters';
import { getOrdersInvoicesRequest } from '../../store/actions/invoices';
import InvoicesTable from '../../components/pages/Orders/invoices/InvoicesTable';
import Utils from '../../helpers/Utils';

const defaultFiltersParams = withDefault(ArrayParam, []);

function Bills() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const firstLoaded = useRef(true);

  const [queryData] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    orderId: defaultFiltersParams,
    status: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  useDeepCompareEffect(() => {
    handleGetInvoices();
  }, [queryData]);

  const handleGetInvoices = useCallback(async () => {
    setLoading(true);
    await dispatch(getOrdersInvoicesRequest({
      ...Utils.arrayToString(queryData),
      type: 'bill',
    }));
    firstLoaded.current = false;
    setLoading(false);
  }, [queryData]);

  return (
    <Wrapper
      title="Bills"
      className="customers_wrapper sales_orders_wrapper"
    >
      {!firstLoaded.current ? (
        <div className="customersList">
          <InvoiceFilters />
          <InvoicesTable loading={loading} type="purchase" />
        </div>
      ) : <Loader />}
    </Wrapper>
  );
}

export default Bills;
