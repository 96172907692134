import React, { useEffect, useRef, useState } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment/moment';
import Api from '../../../Api';
import AdminAllUsersChart from './AdminCharts/AdminAllUsersChart';
import AdminTrialsChart from './AdminCharts/AdminTrialsChart';
import AdminUserBoughtPackage from './AdminCharts/AdminUserBoughtPackage';
import AdminPaidAmountChart from './AdminCharts/AdminPaidAmountChart';
import Button from '../../_common/Form/Button';
import RangeDatePicker from '../../_common/Form/RangePickerRange';
import Loader from '../../_common/Loader/Loader';

function AdminDashboard() {
  const firstLoad = useRef(true);

  const [date, setDate] = useQueryParams({
    startDate: withDefault(StringParam, moment().subtract(1, 'year').format('YYYY-MM-DD')),
    endDate: withDefault(StringParam, moment().format('YYYY-MM-DD')),
  }, { updateType: 'replaceIn' });

  const { startDate, endDate } = date;

  const [loading, setLoading] = useState(true);
  const [interval, setInterval] = useState('month');
  const [dashboardData, setDashboardData] = useState({
    accountingIntegration: {},
    allUsersCount: {},
    boughtThePackage: [],
    paidAmount: {},
    salesChannels: {},
    trials: {},
  });

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await Api.getAdminDashboard({ startDate, endDate });

      const {
        accountingIntegration, allUsersCount, boughtThePackage, paidAmount, salesChannels, trials,
      } = data;

      setDashboardData({
        accountingIntegration, allUsersCount, boughtThePackage, paidAmount, salesChannels, trials,
      });
      setInterval(allUsersCount.countPerPeriod[0].interval);

      firstLoad.current = false;
      setLoading(false);
    })();
  }, [startDate, endDate]);

  return (
    <div className="admin_dashboard">
      {(loading && firstLoad.current) ? <Loader /> : (
        <>
          <div className="admin_dashboard_datepicker">
            <div className="dashboard_react_calendar">
              <RangeDatePicker
                currentDate={date}
                onChangeDate={(dates) => {
                  setDate({
                    startDate: dates[0],
                    endDate: (dates[0] > dates[1] || !dates[1]) ? dates[0] : dates[1],
                  });
                }}
              />
            </div>
          </div>

          <div className="admin_chart_wrapper">
            <AdminAllUsersChart
              allUsersCount={dashboardData.allUsersCount}
              loading={loading && !firstLoad.current}
              interval={interval}
            />
          </div>

          <div className="admin_chart_wrapper">
            <AdminTrialsChart
              trials={dashboardData.trials}
              loading={loading && !firstLoad.current}
              interval={interval}
            />
          </div>

          <div className="admin_chart_wrapper">
            <AdminUserBoughtPackage
              boughtThePackage={dashboardData.boughtThePackage}
              loading={loading && !firstLoad.current}
              interval={interval}
            />
          </div>

          <div className="admin_chart_wrapper">
            <AdminPaidAmountChart
              paidAmount={dashboardData.paidAmount}
              loading={loading && !firstLoad.current}
              interval={interval}
            />
          </div>

          <div className="admin_chart_wrapper">
            {loading && !firstLoad.current ? <Loader /> : (
              <>
                <div className="admin_chart_header">
                  <div className="admin_chart_header_left">
                    <h3>Users who use more sales channels</h3>

                    <Button href="#" btnType="transparent" color="#1472FF" title="View all" />
                  </div>

                  <div className="admin_chart_header_count">{dashboardData.salesChannels.count}</div>
                </div>

                <div className="admin_chart_table">
                  {dashboardData?.salesChannels?.topUsers?.map((u) => (
                    <div className="admin_chart_table_single" key={u.id}>
                      {u.email}

                      <span>{u.integrationCount}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          <div className="admin_chart_wrapper">
            {loading && !firstLoad.current ? <Loader /> : (
              <>
                <div className="admin_chart_header">
                  <div className="admin_chart_header_left">
                    <h3>Users who use accounting integration</h3>

                    <Button href="#" btnType="transparent" color="#1472FF" title="View all" />
                  </div>

                  <div className="admin_chart_header_count">{dashboardData.accountingIntegration.count}</div>
                </div>

                <div className="admin_chart_table">
                  {[{ email: 'a.svg@mail.ru' }, { email: 'b@mail.ri' }].map((u) => ( // dashboardData.accountingIntegration.topUsers
                    <div className="admin_chart_table_single" key={u.id}>
                      {u.email}

                      <span>7</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default AdminDashboard;
