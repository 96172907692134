import React from 'react';
import { Helmet } from 'react-helmet';
import PreviewWrapper from '../../components/pages/Orders/preview/PreviewWrapper';
import OrderPreview from '../../components/pages/Orders/preview/OrderPreview';
import Wrapper from '../../components/Layout/Wrapper';

function PurchasesOrderPreview() {
  return (
    <Wrapper>
      <Helmet>
        <body className="sales_order_preview" />
      </Helmet>
      <PreviewWrapper>
        <OrderPreview />
      </PreviewWrapper>
    </Wrapper>
  );
}

export default PurchasesOrderPreview;
