import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import FilterButton from '../../../_common/Form/FilterButton';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import Utils from '../../../../helpers/Utils';
import { deleteOrderPackageRequest } from '../../../../store/actions/packages';

function ReceivePreviewHeader() {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { receiveId } = useParams();
  const dispatch = useDispatch();

  const allPackages = useSelector((state) => state.packages.allPackages);

  const singleReceive = useMemo(() => allPackages.find((o) => o.id === receiveId) || {}, [allPackages, receiveId]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload: { data } } = await dispatch(deleteOrderPackageRequest(receiveId));
    if (data.status === 'error') {
      toast.error(data.message);
    } else {
      toast.success('Successfully deleted.');
      navigate(`/stocks/receives${location.search}`);
    }
    setLoadingDelete(false);
  }, [receiveId, location]);

  return (
    <div className="preview_header">
      <div className="info">
        <div className="row">
          <p className="number">{singleReceive.number}</p>
          <div className="statuses">
            <p className={singleReceive.status}>
              <span className="status_dot" />
              {Utils.formatPurchaseStatus(singleReceive.status)}
            </p>
          </div>
        </div>
        <div className="row">
          <p className="created_at">
            <span>{moment(singleReceive.sourceCreatedAt || singleReceive.createdAt).format('MM/DD/YYYY')}</span>
          </p>
        </div>
      </div>
      <div className="actions">
        <FilterButton className="filter_button" onClick={handleDelete}>
          <DeleteIcon />
          <span>Delete</span>
        </FilterButton>
        <RemoveIcon className="remove_icon" onClick={() => navigate(`/stocks/receives${location.search}`)} />
      </div>
    </div>
  );
}

export default ReceivePreviewHeader;
