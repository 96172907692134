import React from 'react';
import Wrapper from '../../components/Layout/Wrapper';
import ReturnsFilters from '../../components/pages/Orders/returns/ReturnsFilters';
import ReturnsTable from '../../components/pages/Orders/returns/ReturnsTable';

function SalesOrderReturns() {
  return (
    <Wrapper title="Returns" className="customers_wrapper sales_orders_return_wrapper">
      <div className="sales_order_returns customersList">
        <ReturnsFilters />
        <ReturnsTable />
      </div>
    </Wrapper>
  );
}

export default SalesOrderReturns;
