import React, {
  Fragment,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as StartIcon } from '../../../../assets/icons/star.svg';
import { ReactComponent as ScheduledIcon } from '../../../../assets/icons/scheduled.svg';
import { ReactComponent as InventoryIcon } from '../../../../assets/icons/inventory.svg';
import { ReactComponent as OrdersIcon } from '../../../../assets/icons/orders_reporting.svg';
import { ReactComponent as PurchasesIcon } from '../../../../assets/icons/purchases_reporting.svg';
import { ReactComponent as ReturnIcon } from '../../../../assets/icons/return_reporting.svg';
import { ReactComponent as LineStarIcon } from '../../../../assets/icons/line_star.svg';
import { ReactComponent as YellowStarIcon } from '../../../../assets/icons/yellow_star.svg';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import InventoryStockMovementExportReport from './ProductReports/InventoryStockMovementExportReport';
import Modal from '../../../_common/Modal/Modal';
import InventoryHistoricReport from './ProductReports/InventoryHistoricReport';

const subMenus = {
  favorites: [],
  scheduled: [],
  inventory: [
    {
      path: '/reports/reporting/stock-on-hand',
      title: 'Inventory stock on hand',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/details',
      title: 'Inventory details',
      isSaved: false,
      text: 'View your stock levels on hand, commited, available and on order.',
    },
    {
      path: '/reports/reporting/inventories',
      title: 'Inventory report',
      isSaved: false,
      text: 'Export your stock on hand and moving average cost at any point in history.',
    },
    {
      path: '/reports/reporting/location',
      title: 'Report by location',
      isSaved: false,
      text: 'Compare your stock levels in multiple locations',
    },
    {
      path: '/reports/reporting/stock-updating',
      title: 'Product stock updating',
      isSaved: false,
      text: 'Product Stock Updating Report',
    },
    {
      path: '/reports/reporting/incoming',
      title: 'Incoming stock',
      isSaved: false,
      text: 'View a.svg list of variants that will come in from the purchase orders',
    },
    {
      path: '/reports/reporting/stock-movement',
      title: 'Stock movement export',
      isSaved: false,
      text: 'Export stock movement',
    },
    {
      path: '/reports/reporting/historic',
      title: 'Inventory historic export',
      isSaved: false,
      text: 'Exporting your Historical Inventory Details to CSV',
    },
  ],
  orders: [
    {
      path: '/reports/reporting/sales-history',
      title: 'Sales history',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/sales-package-history',
      title: 'Sales package history',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/sales-by-time',
      title: 'Sales by time period',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/sales-by-product',
      title: 'Sales by product',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/sales-by-customer',
      title: 'Sales by customer',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/sales-invoice-history',
      title: 'Invoice history',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/sales-shipped-items',
      title: 'Shipped items',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
  ],
  purchases: [
    {
      path: '/reports/reporting/purchase-history',
      title: 'Purchase order history',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/purchase-by-customer',
      title: 'Purchase by vendor',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/purchase-receive-history',
      title: 'Receive history',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/purchase-by-product',
      title: 'Purchases by product',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/purchase-reorder',
      title: 'Reorder report',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
  ],
  return: [
    {
      path: '/reports/reporting/returns-history',
      title: 'Returns history',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/returns-by-product',
      title: 'Returns by product',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/returns-by-customer',
      title: 'Returns by customer',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
    {
      path: '/reports/reporting/returns-by-country',
      title: 'Returns by country',
      isSaved: false,
      text: 'View your current stock levels and stock values on hand',
    },
  ],
};

const tabs = [
  {
    path: 'favorites',
    title: 'Favorites',
    icon: <StartIcon />,
  },
  {
    path: 'scheduled',
    title: 'Scheduled reports',
    icon: <ScheduledIcon />,
  },
  {
    path: 'inventory',
    title: 'Inventory',
    icon: <InventoryIcon />,
  },
  {
    path: 'orders',
    title: 'Orders',
    icon: <OrdersIcon />,
  },
  {
    path: 'purchases',
    title: 'Purchases',
    icon: <PurchasesIcon />,
  },
  {
    path: 'return',
    title: 'Return',
    icon: <ReturnIcon />,
  },
];

function Reporting() {
  const firstLoad = useRef(false);

  const [activeTab, setActiveTab] = useState('favorites');
  const [favorites, setFavorites] = useState([]);
  const [scheduled, setScheduled] = useState([]);
  const [exportsModal, setExportsModal] = useState('');

  useEffect(() => {
    (async () => {
      const { data } = await Api.getSettings(['favoriteReports']);

      setFavorites(data.favoriteReports || []);
      firstLoad.current = true;
    })();
  }, []);

  const addOrRemoveFromFavorites = useCallback(async (path) => {
    const newFavorites = favorites.includes(path) ? favorites.filter((f) => f !== path) : [...favorites, path];

    setFavorites(newFavorites);

    Api.updateSettings({ favoriteReports: newFavorites });
  }, [favorites]);

  const updatedMenus = useMemo(() => {
    const allMenus = [...subMenus.inventory, ...subMenus.orders, ...subMenus.purchases, ...subMenus.return];

    const newFavorites = favorites.map((f) => allMenus.find((m) => m.path === f));

    return (
      {
        favorites: newFavorites,
        scheduled,
        inventory: [
          {
            path: '/reports/reporting/stock-on-hand',
            title: 'Inventory stock on hand',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/details',
            title: 'Inventory details',
            isSaved: false,
            text: 'View your stock levels on hand, commited, available and on order.',
          },
          {
            path: '/reports/reporting/inventories',
            title: 'Inventory report',
            isSaved: false,
            text: 'Export your stock on hand and moving average cost at any point in history.',
          },
          {
            path: '/reports/reporting/location',
            title: 'Report by location',
            isSaved: false,
            text: 'Compare your stock levels in multiple locations',
          },
          {
            path: '/reports/reporting/stock-updating',
            title: 'Product stock updating',
            isSaved: false,
            text: 'Product Stock Updating Report',
          },
          {
            path: '/reports/reporting/incoming',
            title: 'Incoming stock',
            isSaved: false,
            text: 'View a.svg list of variants that will come in from the purchase orders',
          },
          {
            path: '/reports/reporting/stock-movement',
            title: 'Stock movement export',
            isSaved: false,
            text: 'Export stock movement',
          },

          {
            path: '/reports/reporting/historic',
            title: 'Inventory historic export',
            isSaved: false,
            text: 'Exporting your Historical Inventory Details to CSV',
          },
        ],
        orders: [
          {
            path: '/reports/reporting/sales-history',
            title: 'Sales history',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/sales-package-history',
            title: 'Sales package history',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/sales-by-time',
            title: 'Sales by time period',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/sales-by-product',
            title: 'Sales by product',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/sales-by-customer',
            title: 'Sales by customer',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/sales-invoice-history',
            title: 'Invoice history',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/sales-shipped-items',
            title: 'Shipped items',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
        ],
        purchases: [
          {
            path: '/reports/reporting/purchase-history',
            title: 'Purchase order history',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/purchase-by-customer',
            title: 'Purchase by vendor',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/purchase-receive-history',
            title: 'Receive history',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/purchase-by-product',
            title: 'Purchases by product',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/purchase-reorder',
            title: 'Reorder report',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
        ],
        return: [
          {
            path: '/reports/reporting/returns-history',
            title: 'Returns history',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/returns-by-product',
            title: 'Returns by product',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/returns-by-customer',
            title: 'Returns by customer',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
          {
            path: '/reports/reporting/returns-by-country',
            title: 'Returns by country',
            isSaved: false,
            text: 'View your current stock levels and stock values on hand',
          },
        ],
      }
    );
  }, [favorites.length, scheduled]);

  return (
    <div className="reporting">
      <h3>Reports</h3>

      <div className="reporting_block">
        <div className="reporting_menu">
          {tabs.map(({
            title,
            path,
            icon,
          }) => (
            <Fragment key={path}>
              <div
                className={`reporting_menu_single ${activeTab === path ? 'active' : ''}`}
                onClick={() => setActiveTab(path)}
              >
                {icon}

                <p className="reporting_menu_single_name">{title}</p>
              </div>

              {path === 'scheduled' && <div className="reporting_menu_title">REPORT CATEGORY</div>}
            </Fragment>
          ))}
        </div>

        <div className="reporting_right">
          {!firstLoad.current ? <Loader /> : (
            updatedMenus[activeTab].map(({ path, title, text }) => (
              <div className="reporting_right_menu_single" key={path}>
                <div className="reporting_right_menu_single_left">
                  {favorites.includes(path)
                    ? <YellowStarIcon onClick={() => addOrRemoveFromFavorites(path)} />
                    : <LineStarIcon onClick={() => addOrRemoveFromFavorites(path)} />}

                  {(path !== '/reports/reporting/stock-movement' && path !== '/reports/reporting/historic')
                    ? <Link to={path}>{title}</Link>
                    : (
                      <div
                        onClick={() => setExportsModal(path)}
                        className="reporting_right_menu_single_left_movement"
                      >
                        {title}
                      </div>
                    )}
                </div>

                <div className="reporting_right_menu_single_text">
                  {text}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <Modal
        isOpen={!!exportsModal}
        onClose={() => setExportsModal('')}
        className={exportsModal === '/reports/reporting/historic' ? 'historic_modal' : 'stock_movement_modal'}
      >
        {
         exportsModal === '/reports/reporting/historic'
           ? <InventoryHistoricReport closeModal={() => setExportsModal('')} />
           : <InventoryStockMovementExportReport closeModal={() => setExportsModal('')} />
        }
      </Modal>
    </div>
  );
}

export default Reporting;
