import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  getAppsRequest, getNotificationsRequest, getSetupRequest, socketImport,
} from '../actions/notifications';

const initialState = {
  apps: [],
  status: '',
  notifications: {
    type: 'All',
    list: [],
    status: '',
  },
  setup: {
    firstLoad: true,
    percent: 0,
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAppsRequest.pending, (state) => {
      state.status = 'pending';
    })
    .addCase(getAppsRequest.fulfilled, (state, { payload }) => {
      state.apps = payload.integrations.map((integration) => {
        if (integration.type === 'ftp') {
          integration.settings.type = `ftp ${integration.settings.type}`;
          integration.name = integration.settings.type;
        }

        return integration;
      });
      state.status = 'ok';
    })
    .addCase(socketImport, (state, { payload }) => {
      const {
        action, message, integrationId, type,
      } = payload;
      const foundCurrentApp = state.apps.find((app) => +app.id === +integrationId
        && (!app.importType || app.importType === type));

      const foundApp = state.apps.find((app) => +app.id === +integrationId);

      const formattedData = {
        ...foundApp,
        importStatus: action,
        message,
        importType: type,
        lastUpdateDate: action === 'done' ? new Date() : foundApp?.lastUpdateDate,
      };

      if (foundCurrentApp) {
        state.apps = [
          formattedData,
          ...state.apps.filter((app) => !(+app.id === +integrationId
          && (!app.importType || app.importType === type)))];
      } else {
        state.apps = [formattedData, ...state.apps];
      }
    })
    .addCase(getNotificationsRequest.pending, (state, { meta: { arg } }) => {
      state.notifications.status = 'pending';
      state.notifications.type = arg;
    })
    .addCase(getNotificationsRequest.fulfilled, (state, { payload }) => {
      state.notifications.list = payload?.logs || [];
      state.notifications.status = 'ok';
    })
    // .addCase(getSetupRequest.pending, (state) => {
    //   state.setup.status = 'pending';
    // })
    .addCase(getSetupRequest.fulfilled, (state, { payload }) => {
      const setup = _.map(payload, (p) => (typeof p === 'object' ? _.map(p, (val) => val) : p)).flat(1);
      const totalSetupCount = setup.length;
      const completedSetupCount = _.sum(setup, (p) => Number(p));
      const completedSetupPercent = Math.floor((completedSetupCount / totalSetupCount) * 100);

      state.setup = payload;
      state.firstLoad = false;
      state.setup.totalCount = totalSetupCount;
      state.setup.completedCount = completedSetupCount;
      state.setup.percent = completedSetupPercent;
    });
});

export default reducer;
