import React, {
  useCallback,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import FilterButton from '../../../_common/Form/FilterButton';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/remote_printing.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import Button from '../../../_common/Form/Button';
import Api from '../../../../Api';
import PrintDocumentModal from '../../../_common/Modal/PrintDocumentModal';
import Utils from '../../../../helpers/Utils';

function InvoicePreviewHeader() {
  const [loading, setLoading] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceId } = useParams();

  const singleInvoice = useSelector((state) => state.invoices.allOrdersInvoices).find((s) => s.id === invoiceId) || {};

  const handleDownload = useCallback(async () => {
    setLoading(true);
    const { data } = await Api.downloadInvoice(invoiceId);
    setLoading(false);
    fileDownload(data, 'document.pdf');
  }, [invoiceId]);

  const handlePrint = useCallback(async (params) => {
    try {
      const { data } = await Api.viewInvoice(singleInvoice.id, 'remote-print', { ...params }, 'json');
      return data;
    } catch (e) {
      return e.response.data;
    }
  }, [singleInvoice]);

  return (
    <div className="preview_header">
      <div className="info">
        <div className="row">
          <p className="number">{singleInvoice.number}</p>
          <div className="statuses">
            <p className={singleInvoice.status}>
              <span className="status_dot" />
              {Utils.formatStatus(singleInvoice.status)}
            </p>
          </div>
        </div>
      </div>
      <div className="actions">
        <FilterButton
          className="filter_button"
          onClick={() => setShowPrintModal(true)}
        >
          <PrintIcon />
          <span>Print</span>
        </FilterButton>
        <FilterButton
          className="filter_button"
          onClick={handleDownload}
        >
          {!loading ? <DownloadIcon /> : (
            <div className="loader_wrapper" style={{ width: 15, height: 15, position: 'relative' }}>
              <span
                style={{
                  width: 15,
                  height: 15,
                }}
                className="loader"
              />
            </div>
          )}
          <span>Download</span>
        </FilterButton>
        {singleInvoice.status !== 'paid' ? (
          <Button
            roundBorder
            data-scope="WRITE_ORDER_INVOICES"
            onClick={() => navigate(`${location.pathname}${location.search}`, { state: { payment: singleInvoice.id } })}
          >
            Register payment
          </Button>
        ) : null}
        {location.key !== 'default' ? <RemoveIcon className="remove_icon" onClick={() => navigate(-1)} /> : null}
      </div>
      {showPrintModal ? (
        <PrintDocumentModal
          isOpen={showPrintModal}
          onClose={() => setShowPrintModal(false)}
          type="invoices/invoice"
          onRequest={async (templateId) => {
            const { data } = await Api.viewInvoice(singleInvoice.id, 'download', { templateId });
            return data;
          }}
          onRemotePrint={handlePrint}
        />
      ) : null}
    </div>
  );
}

export default InvoicePreviewHeader;
