import React, { useEffect, useRef, useState } from 'react';
import _, { isArray } from 'lodash';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Input from '../../_common/Form/Input';
import FilterButton from '../../_common/Form/FilterButton';
import useWindowSize from '../../../helpers/hooks/useWindowSize';
import PolarisFilter from './PolarisFilter';
import { ReactComponent as PrintIcon } from '../../../assets/icons/remote_printing.svg';
import { ReactComponent as ImportIcon } from '../../../assets/icons/import.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export.svg';
import { ReactComponent as FiltersIcon } from '../../../assets/icons/filters.svg';
import { ReactComponent as TableEditIcon } from '../../../assets/icons/table_edit.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';

const Filters = ({
  queryData, setQuery, showActionButtons, balkActions, balkActionsClick,
  onPrintClick, onImportClick, onExportClick, onEditableTableClick, tableEditable,
  defaultFilterData, filterOptions, loadOptions, withSearch, searchPlaceholder,
}) => {
  const { isMobile } = useWindowSize(1100);
  const filterModal = useRef(null);
  const ActionModal = useRef(null);

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [productFilters, setProductFilters] = useState([]);
  const [filterData, setFilterData] = useState(defaultFilterData);

  useEffect(() => {
    _.forEach(queryData, (value, key) => {
      if (isArray(value) && value.length) {
        setProductFilters((prev) => ([...prev, filterOptions[key]]));
        setFilterData((prev) => prev.filter((p) => p.valuePath !== key));
      }
    });
  }, []);

  useClickAway(filterModal, () => {
    setOpenFilterModal(false);
  });

  useClickAway(ActionModal, () => {
    setOpenActionModal(false);
  });

  const setFiltersClick = (filterName) => {
    setFilterData((prev) => prev.filter((p) => p.valuePath !== filterName.valuePath));

    setProductFilters((prev) => [...prev, filterName]);

    setOpenFilterModal(false);
  };

  const setFilterValueInQuery = (path, id, check) => {
    setQuery((prev) => ({
      ...prev,
      page: 1,
      [path]: !check
        ? prev[path].filter((p) => p !== id)
        : [...prev[path], id],
    }));
  };

  const cancelFilter = (filter) => {
    setFilterData((prev) => ([...prev, { label: filter.label, valuePath: filter.valuePath }]));

    setProductFilters((prev) => prev.filter((p) => p.path !== filter.path));

    setQuery((prev) => ({ ...prev, [filter.valuePath]: [] }));
  };

  const clearClick = (filter) => {
    setQuery((prev) => ({ ...prev, [filter.valuePath]: [] }));
  };

  const clearAllFiltersClick = () => {
    setProductFilters([]);
    setFilterData(defaultFilterData);
    _.forEach(queryData, (value, key) => {
      if (isArray(value) && value.length) {
        setQuery((prev) => ({ ...prev, [key]: [] }));
      }
    });
  };

  const balkItemClick = (value) => {
    balkActionsClick(value);
    setOpenActionModal(false);
  };

  return (
    <div className={classNames('filters_global_wrapper', { disable: tableEditable })}>
      <div className="filters">
        <div>
          {withSearch && (
            <Input
              search
              roundBorder
              placeholder={searchPlaceholder}
              value={queryData.s}
              onChangeText={(val) => setQuery((prev) => ({
                ...prev,
                s: val,
                page: 1,
              }))}
            />
          )}
        </div>
        <div>

          {showActionButtons && (
            <div className="filter_button_wrapper" ref={ActionModal}>
              <FilterButton
                className={classNames('filter_button', { active: openActionModal })}
                onClick={() => setOpenActionModal((prev) => !prev)}
              >
                <p>Actions</p>

                <ArrowIcon />
              </FilterButton>

              {openActionModal && (
                <div className="action_items_wrapper">
                  {balkActions.map((b) => (
                    <div
                      key={b.value}
                      className="action_item"
                      role="button"
                      tabIndex="0"
                      onClick={() => balkItemClick(b.value)}
                    >
                      {b.label}
                    </div>
                  ))}

                </div>
              )}
            </div>
          )}

          {onPrintClick
            && (
            <FilterButton onClick={onPrintClick}>
              <PrintIcon />

              {!isMobile && 'Print'}
            </FilterButton>
            )}

          {onImportClick && (
          <FilterButton className="filter_button" onClick={onImportClick}>
            <ImportIcon />

            {!isMobile && 'Import'}
          </FilterButton>
          )}

          {onExportClick && (
          <FilterButton onClick={onExportClick} className="filter_button">
            <ExportIcon />

            {!isMobile && 'Export'}
          </FilterButton>
          )}

          {!!defaultFilterData.length && (
          <div className={classNames('filter_button_wrapper', { disable: !filterData.length })} ref={filterModal}>
            <FilterButton
              className="filter_button"
              onClick={() => setOpenFilterModal((prev) => !prev)}
            >
              <FiltersIcon />

              {!isMobile && 'Filters'}
            </FilterButton>

            {openFilterModal && (
            <div className="filter_items_wrapper">
              {filterData.map((f) => (
                <div
                  className="filter_item"
                  role="button"
                  tabIndex="0"
                  key={f.valuePath}
                  onClick={() => setFiltersClick(filterOptions[f.valuePath])}
                >
                  {f.label}
                </div>
              ))}
            </div>
            )}
          </div>
          )}

          {onEditableTableClick && (
          <FilterButton
            className="filter_button dots"
            onClick={onEditableTableClick}
          >
            <TableEditIcon />
          </FilterButton>
          )}
        </div>
      </div>

      {!!productFilters.length && (
      <div className="filters_wrapper_with_clear">
        <div className="filters_wrapper">
          {productFilters.map((filter, index) => (
            <PolarisFilter
              key={filter.path}
              filterName={filter}
              icon={filter.icon}
              lastChild={index === productFilters.length - 1}
              values={queryData[filter.valuePath] || []}
              hideSearchInput={!!filter.staticData}
              cancelFilter={cancelFilter}
              loadOptions={(data) => loadOptions(filter, data)}
              clearClick={clearClick}
              setFilterValue={setFilterValueInQuery}
            />
          ))}
        </div>

        <div
          className="clear_button_filters"
          role="button"
          tabIndex="0"
          onClick={clearAllFiltersClick}
        >
          Clear all
        </div>
      </div>
      )}
    </div>
  );
};

Filters.propTypes = {
  queryData: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  showActionButtons: PropTypes.bool,
  balkActions: PropTypes.array,
  balkActionsClick: PropTypes.func,
  onPrintClick: PropTypes.func,
  onImportClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onEditableTableClick: PropTypes.func,
  tableEditable: PropTypes.bool,
  withSearch: PropTypes.bool,
  defaultFilterData: PropTypes.array,
  filterOptions: PropTypes.object,
  loadOptions: PropTypes.func,
  searchPlaceholder: PropTypes.string,
};

Filters.defaultProps = {
  showActionButtons: false,
  tableEditable: false,
  withSearch: true,
  balkActions: [],
  balkActionsClick: undefined,
  onPrintClick: undefined,
  onImportClick: undefined,
  onExportClick: undefined,
  onEditableTableClick: undefined,
  loadOptions: () => {},
  filterOptions: {},
  defaultFilterData: [],
  searchPlaceholder: 'Search',
};

export default Filters;
