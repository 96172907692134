import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as WayfairIcon } from '../../../../assets/icons/apps/wayfair.svg';
import WayfairImg1 from '../../../../assets/images/apps/wayfair/Wayfair_1.png';
import WayfairImg2 from '../../../../assets/images/apps/wayfair/Wayfair_2.png';
import WayfairImg3 from '../../../../assets/images/apps/wayfair/Wayfair_3.png';
import WayfairImg4 from '../../../../assets/images/apps/wayfair/Wayfair_4.png';
import WayfairImg5 from '../../../../assets/images/apps/wayfair/Wayfair_5.png';
import WayfairImg6 from '../../../../assets/images/apps/wayfair/Wayfair_6.png';
import WayfairImg7 from '../../../../assets/images/apps/wayfair/Wayfair_7.png';
import WayfairImg8 from '../../../../assets/images/apps/wayfair/Wayfair_8.png';
import WayfairImg9 from '../../../../assets/images/apps/wayfair/Wayfair_9.png';
import WayfairImg10 from '../../../../assets/images/apps/wayfair/Wayfair_10.png';
import WayfairImg11 from '../../../../assets/images/apps/wayfair/Wayfair_11.png';
import WayfairImg12 from '../../../../assets/images/apps/wayfair/Wayfair_12.png';
import WayfairImg13 from '../../../../assets/images/apps/wayfair/Wayfair_13.png';
import WayfairImg14 from '../../../../assets/images/apps/wayfair/Wayfair_14.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { appInstallFields } from '../../../../helpers/staticData';

function WayfairInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const { data: { integration: { id } } } = await Api.createIntegration(
        app,
        {
          integrationName: data.integrationName,
          clientId: data.clientId,
          clientSecret: data.clientSecret,
          // sandbox: +data.sandbox,
        },
      );

      navigate(`/apps/integration/${id}`);
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Wayfair"
      shopIcon={<WayfairIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and"
        + ' process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p className="title">
        Installation Instructions for Connecting Wayfair to eSwap:
      </p>

      <ul>
        <li>
          Visit the Wayfair Extranet website and log in to your account.
        </li>

        <li>
          Navigate to the Developer section by clicking on &quot;More&quot; in the navigation bar, then
          selecting &quot;Developer,&quot; and finally &quot;Applications.&quot;
        </li>
      </ul>

      <p>
        <img
          src={WayfairImg11}
          alt="info image"
        />
      </p>

      <p>
        Click on the &quot;New Application&quot; button on the Application Management page.
      </p>

      <p>
        <img
          src={WayfairImg12}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          In the Create Application window, enter the following details:
        </li>

        <li>
          Name: Enter &quot;eSwap Prod&quot; as the name.
        </li>

        <li>
          Description: Provide a brief description (optional).
        </li>
      </ul>

      <p>
        <img
          src={WayfairImg13}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          After saving, the Client ID and Client Secret will be displayed. Copy these credentials as
          they will be required for the integration.
        </li>

        <li>
          Note: Ensure to save these credentials securely as they may not be accessible
          again once you close the window.
        </li>
      </ul>

      <p>
        <img
          src={WayfairImg14}
          alt="info image"
        />
      </p>

      <p>
        In eSwap, navigate to the Apps section and choose &quot;Wayfair.&quot;
      </p>

      <p>
        <img
          src={WayfairImg1}
          alt="info image"
        />
      </p>

      <p>
        Click on &quot;Install app.&quot;
      </p>

      <p>
        <img
          src={WayfairImg2}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          A modal will appear. Fill in the following details:
        </li>

        <li>
          Your app name: Enter any desired name.
        </li>

        <li>
          Your app client ID: Paste the copied Client ID from Wayfair.e.
        </li>
        <li>
          Your app client secret: Paste the copied Client Secret from Wayfair.
        </li>
      </ul>

      <p>
        <img
          src={WayfairImg3}
          alt="info image"
        />
      </p>

      <p>Click &quot;Install app&quot; to proceed.</p>

      <p>
        <img
          src={WayfairImg4}
          alt="info image"
        />
      </p>

      <p>Upon installation, follow the configuration steps:</p>

      <p>
        <img
          src={WayfairImg5}
          alt="info image"
        />
      </p>

      <p>Choose a Wirestock location or create a new one.</p>

      <p>
        <img
          src={WayfairImg6}
          alt="info image"
        />
      </p>

      <p>Sync price list or create a new one.</p>

      <p>
        <img
          src={WayfairImg7}
          alt="info image"
        />
      </p>

      <p>Choose whether to import data immediately or later.</p>

      <p>
        <img
          src={WayfairImg8}
          alt="info image"
        />
      </p>

      <p>The import process will begin.</p>

      <p>
        <img
          src={WayfairImg9}
          alt="info image"
        />
      </p>

      <ul>
        <li>After configuring, click &quot;Done Installation&quot; to finalize.</li>
        <li>The Wayfair app will now be accessible from your apps page in eSwap.</li>
      </ul>

      <p>
        <img
          src={WayfairImg10}
          alt="info image"
        />
      </p>

      <p>
        By following these steps, you will successfully connect your Wayfair account to eSwap for seamless data
        synchronization and management.
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={appInstallFields.wayfair}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
        // withDefaultSwitcher
        // switcherPath="sandbox"
        // switcherLabel="Sandbox"
      />
    </AppInstallInfoWrapper>
  );
}

export default WayfairInstall;
