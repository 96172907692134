import io from 'socket.io-client';
import { createAction } from '@reduxjs/toolkit';
import Account from '../../helpers/Account';
import { socketImport } from './notifications';
import {
  socketAICreatedThread,
  socketAIEndThread,
  socketAIMessage,
  socketAIUpdateThreadName,
} from './chatBot';
import Utils from '../../helpers/Utils';
import { getPrintersListRequest } from './app';

const {
  // REACT_APP_API_URL = 'https://settling-bunny-happy.ngrok-free.app', // Argisht
  REACT_APP_API_URL = 'https://bapi.eswap.ch',
} = process.env;

let socket;

export function socketInit() {
  return (dispatch, getState) => {
    if (!socket) {
      const token = Account.getToken();
      const userId = Account.getUserId();
      const ownerId = Account.getOwnerId();

      const extraHeaders = {
        Authorization: `Bearer ${token}`,
        'X-Device-Id': Utils.getDeviceId(),
      };

      if (userId) {
        extraHeaders['X-User-Id'] = userId;
        extraHeaders['X-Owner-Id'] = ownerId;
      }

      socket = io.connect(REACT_APP_API_URL, {
        extraHeaders,
        // transports: ['websocket', 'polling'],
      });

      socket.on('import', (data) => {
        // console.log(data, 222);
        dispatch(socketImport(data));

        if (data.action === 'finish') {
          setTimeout(() => dispatch(socketImport({ ...data, action: 'done' })), 1000);
        }
      });

      socket.on('aiBotMessage', (data) => {
        dispatch(socketAIMessage(data));
      });

      socket.on('aiBotThreadUpdated', (data) => {
        dispatch(socketAIUpdateThreadName(data));
      });

      socket.on('aiBotThreadCreated', (data) => {
        dispatch(socketAICreatedThread(data));
      });

      socket.on('aiBotThreadClose', (data) => {
        dispatch(socketAIEndThread(data.threadId));
      });

      socket.on('printer-connect', async () => {
        dispatch(getPrintersListRequest());
      });
    }
  };
}

export function destroySocket() {
  if (socket) {
    socket.destroy();
    socket = null;
  }

  return (dispatch) => {
    dispatch(socketDestroy());
  };
}

export const socketDestroy = createAction('socket/destroy');
