import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useClickAway } from 'react-use';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { toast } from 'react-toastify';
import Wrapper from '../../../../Layout/Wrapper';
import Api from '../../../../../Api';
import Pagination from '../../../../_common/Pagination/Pagination';
import Table from '../../../../_common/Tables/Table';
import Loader from '../../../../_common/Loader/Loader';
import ScheduleModal from '../ScheduleModal';
import Button from '../../../../_common/Form/Button';
import { ReactComponent as FiltersIcon } from '../../../../../assets/icons/filters.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../../../../assets/icons/export.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/remote_printing.svg';
import Select from '../../../../_common/Form/Select';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Input from '../../../../_common/Form/Input';
import ChartWrapper from '../../../../_common/ChartWrapper/ChartWrapper';
import Tabs from '../../../../_common/Tabs/Tabs';

const topChartTemplate = {
  chart: {
    toolbar: {
      show: false,
      // tools: {
      //   download: true,
      //   selection: false,
      //   zoom: false,
      //   zoomin: false,
      //   zoomout: false,
      //   pan: false,
      //   reset: false,
      // },
    },
  },

  colors: ['#109B48', '#FFBD00', '#1472FF', '#ab14ff', '#14f7ff'],

  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '50%',
    },
  },
  dataLabels: {
    enabled: false,
  },

  // stroke: {
  //   show: true,
  //   width: 2,
  //   colors: ['transparent'],
  // },

  xaxis: {
    categories: [],
    axisBorder: {
      show: false,
      // height: 1.5,
      // width: '100%',
      // offsetX: 0,
      // offsetY: -1,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      style: {
        colors: '#717A8A',
        fontSize: '13px',
        fontFamily: 'Proxima Nova, sans-serif',
        fontWeight: 400,
      },

      // formatter(value, timestamp) {
      //   return new Date(timestamp); // The formatter function overrides format property
      // },
    },
  },

  yaxis: {
    labels: {
      offsetY: 2,
      style: {
        colors: '#717A8A',
        fontSize: '14px',
        fontFamily: 'Proxima Nova, sans-serif',
        fontWeight: 600,
      },
      formatter: (value) => Math.round(value),
    },
  },

  states: {
    normal: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
    hover: {
      filter: {
        type: 'none',
      },
    },
  },

  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'top',
    horizontalAlign: 'right',
    floating: false,
    fontSize: '12px',
    fontFamily: 'DM Sans, sans-serif',
    fontWeight: 400,
    markers: {
      width: 14,
      height: 14,
      radius: 14,
    },
  },

  series: [],
};

const defaultFiltersParams = withDefault(ArrayParam, []);

const tableHeader = [
  {
    title: 'Date',
    path: 'date',
  },
  {
    title: 'Sales qty',
    path: 'salesVolume',
  },
  {
    title: 'Sales value',
    path: 'salesValue',
  },
  {
    title: 'Tax',
    path: 'tax',
  },
  {
    title: 'Profit',
    path: 'profit',
  },
  {
    title: 'Profit margin',
    path: 'profitMargin',
  },
  {
    title: 'No of orders',
    path: 'orderCount',
  },
];

const selectOptions = [
  {
    label: 'Is',
    value: 'is',
  },
  {
    label: 'Not',
    value: 'not',
  },
  {
    label: 'Is Empty',
    value: 'isEmpty',
  },
  {
    label: 'Is Not Empty',
    value: 'isNotEmpty',
  },
  {
    label: 'In',
    value: 'in',
  },
  {
    label: 'Not In',
    value: 'notIn',
  },
];

const filterKeys = [
  { label: 'Order Tags', value: 'orderTags' },
  { label: 'Sales Channels', value: 'salesChannels' },
  { label: 'Status', value: 'status' },
];

const chartsTabs = [
  {
    name: 'Sales value',
  },
  {
    name: 'Sales volume',
  },
];

const statusFields = [
  {
    id: 'shipped',
    name: 'Shipped',
  },
  {
    id: 'pending',
    name: 'Pending',
  },
  {
    id: 'partial_shipped',
    name: 'Partial shipped',
  },
  {
    id: 'delivered',
    name: 'Delivered',
  },
  {
    id: 'partial_delivered',
    name: 'Partial delivered',
  },
  {
    id: 'canceled',
    name: 'Canceled',
  },
];

const intervals = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'year',
    label: 'Year',
  },
];

function SalesByTimePeriodReport() {
  const navigate = useNavigate();
  const firstLoad = useRef(true);
  const filterMenuRef = useRef(null);
  const searchTimeout = useRef(null);

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    // sortBy: withDefault(StringParam, 'createdAt'),
    // sort: withDefault(StringParam, 'desc'),
    dateFormat: withDefault(StringParam, 'month'),
    startDate: withDefault(StringParam, moment().subtract(1, 'month').format('MM-DD-YYYY')),
    endDate: withDefault(StringParam, moment().format('MM-DD-YYYY')),
    advancedFilters: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [totalPages, setTotalPages] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false);
  const [filterMenu, setFilterMenu] = useState(false);
  const [extendedFilters, setExtendedFilters] = useState([]);
  const [extendedErrors, setExtendedErrors] = useState({});
  const [salesValueChart, setSalesValueChart] = useState(topChartTemplate);
  const [salesVolumeChart, setSalesVolumeChart] = useState(topChartTemplate);
  const [salesInfo, setSalesInfo] = useState({});
  const [visibleChart, setVisibleChart] = useState('Sales value');

  const [defaultAsyncOptions, setDefaultAsyncOptions] = useState({
    salesChannels: [],
    orderTags: [],
  });

  const valueSelects = useMemo(() => ({
    salesChannels: {
      path: 'shopId',
      label: 'Channel',
      valuePath: 'id',
      defaultOptions: [{ id: '0', name: 'eSwap' }, ...defaultAsyncOptions.salesChannels],
      getFullOption: true,
      getOptionLabel: (options) => (options.name),
    },
    orderTags: {
      path: 'tagId',
      label: 'Tags',
      valuePath: 'id',
      defaultOptions: defaultAsyncOptions.orderTags,
      getFullOption: true,
      getOptionLabel: (options) => (options.name),
    },
  }), [defaultAsyncOptions]);

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    (async () => {
      searchTimeout.current = setTimeout(async () => {
        setTableLoading(true);

        await settingStateFromQuery();

        if (firstLoad.current) {
          const [
            shops,
            tags,
          ] = await Promise.all([
            Api.getIntegrations({ category: 'shop' }),
            Api.getOrdersTags(),
          ]);

          setDefaultAsyncOptions({
            salesChannels: shops.data.integrations,
            orderTags: tags.data.tags,
          });
        }

        try {
          await getSalesByTimePeriod({
            allQuery: queryData,
          });
        } catch (e) {
          navigate('/404');
        }

        setTableLoading(false);
        firstLoad.current = false;
      }, 500);
    })();
  }, [queryData]);

  const filterCounts = useMemo(() => (extendedFilters.length), [extendedFilters]);

  const settingStateFromQuery = useCallback(async () => {
    let hasError = false;

    let filters = [];

    queryData.advancedFilters.forEach((v) => {
      try {
        filters = [...filters, ...JSON.parse(v)];
      } catch (e) {
        hasError = true;
        filters = [];
      }
    });

    setExtendedFilters(() => {
      if (hasError) {
        setQuery((prev) => ({
          ...prev,
          advancedFilters: [],
        }));

        setExtendedErrors([]);
        return [];
      }

      setExtendedErrors(() => {
        let newErrors = {};

        filters.forEach((f) => {
          newErrors = {
            ...newErrors,
            [f.id]: {
              key: '',
              operator: '',
              value: '',
            },
          };
        });

        return newErrors;
      });

      return filters;
    });
  }, [queryData]);

  useClickAway(filterMenuRef, async () => {
    if (filterMenu) {
      setFilterMenu(false);

      await settingStateFromQuery();
    }
  });

  const getSalesByTimePeriod = useCallback(async ({ allQuery }) => {
    const requestData = { ...allQuery };
    let newAdvancedFilters = [];

    requestData.advancedFilters.forEach((f) => {
      const parsedFilter = JSON.parse(f);

      newAdvancedFilters = parsedFilter.map((filter) => {
        let newFilter = { ...filter };
        delete newFilter.id;

        if (Array.isArray(newFilter.value)) {
          newFilter = {
            ...newFilter,
            value: newFilter.value.map((v) => (v.id ? v.id : v)),
          };
        } else if (typeof newFilter.value === 'object') {
          newFilter = {
            ...newFilter,
            value: newFilter.value.id,
          };
        }

        return newFilter;
      });
    });

    const { data } = await Api.getSalesByTimeReport({
      ...requestData,
      advancedFilters: newAdvancedFilters,
    });

    const chartsData = await Api.getSalesByTimeChartReport({
      ...requestData,
      advancedFilters: newAdvancedFilters,
    });

    let categories = [];
    let salesValue = [];
    let salesVolume = [];
    let profit = [];
    let productPrice = [];

    chartsData.data.sales.forEach((d) => {
      categories = [...categories, d.date];
      salesValue = [...salesValue, (d.salesValue || 0)];
      salesVolume = [...salesVolume, (d.salesVolume || 0)];
      profit = [...profit, (d.profit || 0)];
      productPrice = [...productPrice, (d.productPrice || 0)];
    });

    setSalesValueChart((prev) => ({
      ...prev,
      // legend: {
      //   show: false,
      // },
      xaxis: {
        ...prev.xaxis,
        categories,
      },
      series: [
        {
          name: 'Incoming stock',
          data: salesValue,
        },
        {
          name: 'Profit',
          data: profit,
        },
        {
          name: 'Cost',
          data: productPrice,
        },
      ],
    }));

    setSalesVolumeChart((prev) => ({
      ...prev,
      legend: {
        show: false,
      },
      xaxis: {
        ...prev.xaxis,
        categories,
      },
      series: [
        {
          name: 'Incoming cost',
          data: salesVolume,
        },
      ],
      colors: ['#109B48'],
    }));

    setSalesInfo(data.salesInfo);
    setTotalPages(data.totalPages);
    setReportData(data.orders);
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setQuery((prev) => ({
      ...prev,
      sort: newSorting.sort,
      sortBy: newSorting.sortBy,
    }));
  }, [queryData.sort, queryData.sortBy]);

  const onSchedule = useCallback(() => {
    setScheduleIsOpen(true);
  }, []);

  const onAddMoreFilterClick = useCallback(() => {
    const id = _.uniqueId();

    setExtendedFilters((prev) => [...prev, {
      key: '',
      operator: '',
      id,
    }]);

    setExtendedErrors((prev) => ({
      ...prev,
      [id]: {
        key: '',
        operator: '',
      },
    }));
  }, []);

  const onDeleteFilterLine = useCallback((id) => {
    setExtendedFilters((prev) => prev.filter((filter) => filter.id !== id));
    setExtendedErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[id];

      return newErrors;
    });
  }, []);

  const onAdvancedFiltersChange = useCallback((id, path, value) => {
    setExtendedFilters((prev) => {
      let newValue;

      newValue = path === 'key' ? '' : path === 'value' ? value : prev.value;

      if (path === 'operator') {
        newValue = '';

        return prev.map((fields) => {
          if (fields.id === id) {
            const newFields = {
              ...fields,
              operator: value,
              value: '',
            };

            if (value === 'isEmpty' || value === 'isNotEmpty') {
              delete newFields.value;
            }

            if (value === 'in' || value === 'notIn') {
              newFields.value = [];
            }

            return newFields;
          }

          return fields;
        });
      }

      return prev.map((fields) => {
        const newFields = {
          ...fields,
          [path]: value,
          value: newValue,
        };

        if (path !== 'value') {
          newFields.operator = '';
        }

        if (id === fields.id) {
          if (fields.operator === 'isEmpty' || fields.operator === 'isNotEmpty') {
            delete newFields.value;
          }

          return newFields;
        }

        return fields;
      });
    });

    setExtendedErrors((prev) => {
      let allErrors = { ...extendedErrors };

      _.forEach(prev, (fields, errorId) => {
        if (path !== 'operator') {
          if (id === errorId) {
            const newError = { ...allErrors[errorId], [path]: '' };

            const foundFilter = extendedFilters.find((filter) => filter.id === id);

            if (foundFilter.value !== 'isEmpty' && foundFilter.value !== 'isNotEmpty' && foundFilter.value) {
              newError.value = '';
            }

            allErrors = {
              ...allErrors,
              [errorId]: newError,
            };
          }
        }

        if (id === errorId) {
          const newError = { ...allErrors[errorId], [path]: '' };

          if (value === 'isEmpty' || value === 'isNotEmpty') {
            delete newError.value;
          } else {
            newError.value = '';
          }

          allErrors = {
            ...allErrors,
            [errorId]: newError,
          };
        }
      });

      return allErrors;
    });
  }, [extendedErrors, extendedFilters]);

  const onExport = useCallback(async () => {
    try {
      await Api.exportSalesByTimeReport({
        by: {
          startDate: queryData.startDate,
          endDate: queryData.endDate,
          s: queryData.s,
          dateFormat: queryData.dateFormat,
        },
        fields: ['tax', 'salesVolume', 'salesValue', 'profitMargin', 'profit', 'orderCount', 'date'],
        fileType: 'csv',
      });

      toast.success('Successfully exported. You will receive an email.');
    } catch (e) {
      toast.error('Something went wrong. Please try later.');
    }
  }, [queryData]);

  const onPrint = () => {
    window.print();
  };

  const onRunReport = useCallback(() => {
    let hasError = false;

    extendedFilters.forEach((filter) => {
      _.forEach(filter, (value, key) => {
        if (!value.toString().trim()) {
          hasError = true;

          setExtendedErrors((prev) => {
            const newError = { ...prev[filter.id], [key]: 'Fields is required' };

            return { ...prev, [filter.id]: newError };
          });
        }
      });
    });

    if (!hasError) {
      setQuery((prev) => ({
        ...prev,
        advancedFilters: !extendedFilters.length ? [] : JSON.stringify(extendedFilters),
        page: 1,
      }));

      setFilterMenu(false);
    }
  }, [extendedFilters, extendedErrors]);

  return (
    <Wrapper
      title="Sales by time period report"
      onBtnClick={onSchedule}
      buttonTitle="Schedule report"
      btnType="light_blue"
    >
      <div className="inventory_report">
        <div className="inventory_report_top_info">
          {Object.entries(salesInfo).map((info) => (
            <div className="inventory_report_top_info_single">
              <p>{_.startCase(info[0])}</p>

              <h4>{`$${(+info[1]).toFixed(2)}`}</h4>
            </div>
          ))}
        </div>

        <div className="inventory_report_filters">
          <div className="inventory_report_filter_search" />

          <div className="inventory_report_filter_right">
            <Button
              onClick={onPrint}
              size="small"
              roundBorder
              iconLeft={<PrintIcon />}
              title="Print"
              btnType="transparent"
              className="print_button"
            />

            {!!reportData.length && (
              <Button
                onClick={onExport}
                size="small"
                roundBorder
                iconLeft={<ExportIcon />}
                title="Export"
                btnType="transparent"
                className="export_button"
              />
            )}

            <div className="extended_filter_single_select_mini">
              <Select
                size="small"
                roundBorder
                placeholder="Select a field"
                options={intervals}
                onChange={(val) => setQuery((prev) => ({ ...prev, dateFormat: val }))}
                menuPosition="fixed"
                value={queryData.dateFormat}
              />
            </div>

            <RangeDatePicker
              currentDate={{
                startDate: queryData.startDate,
                endDate: queryData.endDate,
              }}
              onChangeDate={(value) => setQuery((prev) => ({
                ...prev,
                startDate: value[0],
                endDate: (value[0] > value[1] || !value[1]) ? value[0] : value[1],
                page: 1,
              }))}
              calendarClassName="inventory_report_filters_menu_calendar"
              popperPlacement="bottom-start"
            />

            <div className="inventory_report_filter_btn_wrapper" ref={filterMenuRef}>
              <Button
                title="Filters"
                size="small"
                roundBorder
                iconLeft={<FiltersIcon />}
                btnType="transparent"
                className="inventory_report_filter_btn"
                onClick={() => setFilterMenu((prev) => !prev)}
              />

              {filterMenu && (
                <div className="inventory_report_filters_menu">
                  <div className="inventory_report_extended_filters">
                    {extendedFilters.map(({
                      key,
                      operator,
                      value,
                      id,
                    }) => (
                      <div className="extended_filter_single" key={id}>
                        <div className="extended_filter_single_select">
                          <Select
                            size="small"
                            roundBorder
                            placeholder="Select a field"
                            options={filterKeys}
                            onChange={(val) => onAdvancedFiltersChange(id, 'key', val)}
                            menuPosition="fixed"
                            value={key}
                            error={extendedErrors?.[id]?.key}
                          />
                        </div>

                        <div className="extended_filter_single_select">
                          <Select
                            size="small"
                            roundBorder
                            placeholder="Select a comporator"
                            options={selectOptions}
                            value={operator}
                            menuPosition="fixed"
                            onChange={(val) => onAdvancedFiltersChange(id, 'operator', val)}
                            error={extendedErrors?.[id]?.operator}
                          />
                        </div>

                        {operator !== 'isEmpty' && operator !== 'isNotEmpty' && operator && (
                          <div className="extended_filter_single_select_long">
                            <Select
                              value={value}
                              options={key === 'status' ? statusFields : valueSelects[key].defaultOptions}
                              valuePath="id"
                              isSearchable
                              size="small"
                              roundBorder
                              onChange={(val) => onAdvancedFiltersChange(id, 'value', val)}
                              getFullOption={key !== 'status'}
                              labelPath="name"
                              key={key}
                              isDisabled={operator === 'isEmpty' || operator === 'isNotEmpty' || !key}
                              menuPosition="fixed"
                              error={extendedErrors?.[id]?.value}
                              isMulti={operator === 'in' || operator === 'notIn'}
                            />
                          </div>
                        )}

                        <DeleteIcon onClick={() => onDeleteFilterLine(id)} className="delete_svg" />
                      </div>
                    ))}

                    <div className="inventory_report_filters_menu_buttons">
                      <Button
                        btnType="transparent"
                        onClick={() => onAddMoreFilterClick()}
                        className="inventory_report_filters_menu_btn"
                        title="Add filter"
                        addBtn
                        color="#1472FF"
                      />

                      <Button
                        onClick={() => onRunReport()}
                        className="inventory_report_filters_menu_run_btn"
                        title="Run report"
                        size="small"
                        roundBorder
                      />
                    </div>
                  </div>
                </div>
              )}

              {!!filterCounts && !firstLoad.current
                && <div className="inventory_report_has_filter">{filterCounts}</div>}
            </div>
          </div>
        </div>

        {firstLoad.current ? <Loader /> : (
          <div className="inventory_report_with_charts">
            <Tabs
              tabs={chartsTabs}
              currentTab={visibleChart}
              onChangeTab={(name) => setVisibleChart(name)}
            />

            <div className="inventory_report_charts_block">
              {visibleChart === 'Sales value' ? (
                <div className="inventory_report_chart_single">
                  <ChartWrapper
                    height={480}
                    title="Sales value"
                    loading={tableLoading}
                    className="sales_value_chart"
                  >
                    <Chart
                      height={400}
                      width="100%"
                      type="area"
                      className="apx-chart"
                      series={salesValueChart.series}
                      options={salesValueChart}
                    />
                  </ChartWrapper>
                </div>
              ) : (
                <div className="inventory_report_chart_single">
                  <ChartWrapper
                    height={480}
                    title="Sales volume"
                    loading={tableLoading}
                    className="sales_volume_chart"
                  >
                    <Chart
                      height={400}
                      width="100%"
                      type="bar"
                      className="apx-chart"
                      series={salesVolumeChart.series}
                      options={salesVolumeChart}
                    />
                  </ChartWrapper>
                </div>
              )}
            </div>

            <div className="inventory_report_table">
              <Table
                data={reportData}
                header={tableHeader}
                deleteModalText="user invitation"
                sortBy={queryData.sortBy}
                sort={queryData.sort}
                onSortBy={onSortBy}
                loading={tableLoading}
                hideDeleteButtonFields={['active', 'restricted']}
              />
            </div>
          </div>
        )}

        <div className="inventory_report_pagination">
          <Pagination
            totalPages={totalPages}
            onChangePage={(p) => setQuery((prev) => ({
              ...prev,
              page: p,
            }))}
          />
        </div>

        <ScheduleModal isOpen={scheduleIsOpen} onClose={() => setScheduleIsOpen(false)} />
      </div>
    </Wrapper>
  );
}

export default SalesByTimePeriodReport;
