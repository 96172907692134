import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  changeShipmentsTableAttributes, getAllPackagesRequest,
  getOrderPackagesRequest,
  getOrderRatesByDimensionsRequest, getShipmentsTableAttributes,
  getShippingRatesRequest, shipmentsTableEditableEvent,
} from '../actions/packages';

const initialState = {
  orderPackages: [],
  packedCounts: {},
  shippingRates: [],
  shippingRatesByWeight: [],
  tableEditable: false,
  allPackages: [],
  totalPages: 0,
  tableColumns: [
    {
      key: 'shipping#', label: 'Shipping#', isEnabled: true,
    },
    { key: 'sale_order#', label: 'Sales order#', isEnabled: true },
    { key: 'customer', isEnabled: true },
    { key: 'date', isEnabled: true },
    { key: 'carrier', isEnabled: true },
    { key: 'tracking#', label: 'Tracking#', isEnabled: true },
    { key: 'shippingRate', isEnabled: true },
    { key: 'status', isEnabled: true },
  ],
  tableColumnsReceive: [
    {
      key: 'receive#', label: 'Receive#', isEnabled: true,
    },
    { key: 'purchase_order#', label: 'Purchase order#', isEnabled: true },
    { key: 'vendor', isEnabled: true },
    { key: 'date', isEnabled: true },
    { key: 'status', isEnabled: true },
  ],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOrderPackagesRequest.fulfilled, (state, action) => {
      const { orderPackages } = action.payload.data;
      state.orderPackages = orderPackages;
      const packedCounts = {};
      const packages = orderPackages.map((o) => o.orderPackageProducts).flat(1);
      packages.forEach((p) => {
        if (packedCounts[p.orderProductId]) {
          packedCounts[p.orderProductId] += p.qty;
        } else {
          packedCounts[p.orderProductId] = p.qty;
        }
      });
      state.packedCounts = packedCounts;
    })

    .addCase(getShippingRatesRequest.fulfilled, (state, action) => {
      state.shippingRates = action.payload.data.rates;
      state.shippingRatesByWeight = action.payload.data.rates;
    })
    .addCase(getAllPackagesRequest.pending, (state) => {
      state.allPackages = [];
      state.totalPages = 1;
    })
    .addCase(getAllPackagesRequest.fulfilled, (state, action) => {
      state.allPackages = action.payload.data.orderPackages;
      state.totalPages = action.payload.data.totalPages;
    })
    .addCase(getOrderRatesByDimensionsRequest.fulfilled, (state, action) => {
      state.shippingRatesByWeight = action.payload.data.rates;
      state.shippingRates = action.payload.data.rates;
    })
    .addCase(shipmentsTableEditableEvent, (state, { payload }) => {
      state.tableEditable = payload;
    })
    .addCase(changeShipmentsTableAttributes, (state, { payload }) => {
      state.tableColumns = payload;
    })
    .addCase(getShipmentsTableAttributes.fulfilled, (state, { payload }) => {
      state.tableColumns = payload.orderPackagesTableAttributes
        ? _.unionBy([...state.tableColumns, ...payload.orderPackagesTableAttributes], 'key')
        : state.tableColumns;
    });
});

export default reducer;
