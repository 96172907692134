import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as AmazonIcon } from '../../../../assets/icons/apps/amazon.svg';
import Amazon2 from '../../../../assets/images/apps/amazon_app_2.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import { amazonStaticData } from '../../../../helpers/staticData';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';

const fields = [
  {
    title: 'Your app name',
    placeholder: 'Enter your app name',
    path: 'integrationName',
    required: true,
  },
  {
    title: 'Country',
    placeholder: 'Enter country',
    path: 'country',
    type: 'select',
    options: amazonStaticData,
    valuePath: 'countryCode',
    labelPath: 'country',
    menuPortalTarget: true,
    required: true,
  },
];

function AmazonInstall() {
  const { app } = useParams();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    const countryData = amazonStaticData.find((d) => d.countryCode === data.country);

    const response = await Api.installApp(
      app,
      {
        country: countryData.countryCode,
        region: countryData.region,
        integrationName: data.integrationName,
        // redirectUrl: 'https://new-dev.eswap.ch/amazon/get-user-auth-token',
        redirectUrl: `${window.location.origin}/integration-verify/${app}`,
      },
    );

    localStorage.setItem(`${app}IntegrationInfo`, JSON.stringify({
      integrationName: data.integrationName,
    }));

    window.location.href = response.data.params.redirectUrl;
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Amazon - us"
      shopIcon={<AmazonIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS)."}
      onModalOpen={() => setInstallModal(true)}
    >
      <p>
        Learn more about Amazon MWS. Amazon
        {' '}
        <span><Link to="">Click here</Link></span>
        {' '}
        to login to your Amazon Marketplace Web Service (MWS).
        Next, Sign up for MWS (enter your Amazon email and password to sign in)
      </p>

      <img src={Amazon2} alt="img" />

      <div>
        <p>Select the following option: I want to give a developer access to my Amazon seller account with MWS.</p>
        <p>Enter the following values in the corresponding fields:</p>
        <p>AMAZON US, CA, MX</p>

        <p><strong>Developer Name: eSwap</strong></p>
        <p><strong>Developer Account No: 5974-2762-8523</strong></p>

        <p>Click on Next.</p>
        <p>Accept the license agreement and click the Next button.</p>
      </div>

      <p>eSwap will start importing your products from Amazon. </p>
      <p>
        You can check the import progress via the Dashboard in your Amazon Channel integration section in your
        eSwap account.
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={fields}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default AmazonInstall;
