import React, {
  useCallback, useEffect, useId, useLayoutEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu_dots.svg';
import useEventCallback from '../../../helpers/hooks/useEventCallback';
import Loader from '../Loader/Loader';

const Menu = ({
  options, onChange, zIndex = 1, loading = false, loaderColor,
}) => {
  const uniqId = useId();

  const [isMenuOpen, menuOpeningToggle] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState({ });

  const setSelectOptionsPosition = useCallback(() => {
    const menuRef = document.getElementById(`menu_container${uniqId}`);

    const optionsRef = document.getElementById(`options_container${uniqId}`);

    const optionsHeight = optionsRef?.getBoundingClientRect()?.height || 0;
    const optionsWidth = optionsRef?.getBoundingClientRect()?.width || 0;

    const side = menuRef?.getBoundingClientRect() || {};

    const topPosition = side.top + side.height + window.scrollY + 8;

    const bottomPosition = side.top + window.scrollY - (optionsHeight + 8);

    const showInTop = window.innerHeight + window.scrollY > topPosition + optionsHeight;
    // setOptionsPosition({ left: side.left, top: showInTop ? topPosition : bottomPosition }); show right
    setOptionsPosition({ left: side.left - (optionsWidth - side.width), top: showInTop ? topPosition : bottomPosition });
  }, []);

  const closeMenu = useEventCallback((e) => {
    if (isMenuOpen && !e.target.closest(`[id="menu_container${uniqId}"]`)
      && !e.target.closest(`[id="options_container${uniqId}"]`)) {
      menuOpeningToggle(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    window.addEventListener('click', closeMenu, true);
    window.addEventListener('scroll', setSelectOptionsPosition, true);
    window.addEventListener('resize', setSelectOptionsPosition, true);

    return () => {
      window.removeEventListener('click', closeMenu, true);
      window.removeEventListener('scroll', setSelectOptionsPosition, true);
      window.addEventListener('resize', setSelectOptionsPosition, true);
    };
  }, []);

  const selectMenuOption = (e, option) => {
    e.stopPropagation();

    onChange(option);
    menuOpeningToggle(false);
  };

  const menuOpening = useCallback((e) => {
    e.stopPropagation();

    menuOpeningToggle((prev) => !prev);
  }, []);

  useLayoutEffect(() => {
    if (isMenuOpen) {
      setTimeout(() => setSelectOptionsPosition());
    }
  }, [isMenuOpen]);

  return (
    <div
      id={`menu_container${uniqId}`}
      className="menu_wrapper"
    >
      <button
        className={classNames('menu_btn', { active: isMenuOpen })}
        type="button"
        onClick={menuOpening}
      >
        {loading
          ? <Loader size={15} color={loaderColor} />

          : <MenuIcon />}
      </button>

      {isMenuOpen && createPortal(
        <div
          id={`options_container${uniqId}`}
          style={{ ...optionsPosition, zIndex }}
          className="menu_options_container"
        >
          {options.map(({
            label, value, invertLabel, invert,
          }) => (
            <div
              role="button"
              tabIndex="0"
              key={value}
              onClick={(e) => selectMenuOption(e, value)}
              className="menu_option"
            >
              {invert ? invertLabel : label}
            </div>
          ))}
        </div>,
        document.getElementById('root'),
      )}
    </div>
  );
};

Menu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default Menu;
