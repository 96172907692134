import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment/moment';
import ChartWrapper from '../../../../_common/ChartWrapper/ChartWrapper';
import Api from '../../../../../Api';
import Table from '../../../../_common/Tables/Table';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Button from '../../../../_common/Form/Button';
import Select from '../../../../_common/Form/Select';
import useWindowSize from '../../../../../helpers/hooks/useWindowSize';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/icons/no_match.svg';

const ordersInfo = [
  {
    title: 'New orders:',
    path: 'newOrdersCount',
  },
  {
    title: 'Ready to ship:',
    path: 'pendingOrdersCount',
  },
  {
    title: 'Shipped:',
    path: 'shippedOrdersCount',
  },
];

const chartOptionsTemplate = {
  chart: {
    toolbar: {
      show: false,
    },
  },

  plotOptions: {
    bar: {
      columnWidth: '100%',
      barHeight: '20px',
      horizontal: true,
      distributed: true,
      colors: {
        backgroundBarColors: ['#F1F4F9'],
      },
    },
  },

  dataLabels: {
    enabled: false,
  },

  yaxis: {
    labels: {
      offsetY: 2,
      style: {
        colors: '#111C38',
        fontSize: '14px',
        fontFamily: 'Proxima Nova, sans-serif',
        fontWeight: 400,
      },
    },
  },

  xaxis: {
    categories: [],

    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },

    labels: {
      style: {
        colors: '#717A8A',
        fontSize: '13px',
        fontFamily: 'Proxima Nova, sans-serif',
        fontWeight: 400,
      },
    },
  },

  states: {
    normal: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
    hover: {
      filter: {
        type: 'none',
      },
    },
  },

  legend: {
    show: false,
  },

  tooltip: {
    y: {
      title: {
        formatter() {
          return '';
        },
      },
    },
  },

  series: [],
};

const tableHeader = [
  {
    title: 'Carrier',
    path: 'carrier',
  },
  {
    title: 'Qty',
    path: 'shippedCount',
  },
  {
    title: 'Share',
    path: 'shippedPercent',
  },
];

const createChartData = (chartData, categoryPath, seriesPath, baseColor, setMethod, annotation) => {
  const categories = [];
  const seriesData = [];
  const backgroundBarColors = [];
  const maxValue = +_.maxBy(chartData, (d) => +d[seriesPath])?.[seriesPath];

  chartData.forEach((d) => {
    categories.push(_.upperFirst(d[categoryPath]));
    seriesData.push(+d[seriesPath]);
    backgroundBarColors.push(!(+d[seriesPath]) ? '#F1F4F9' : 'transparent');
  });

  setMethod((prev) => {
    const data = {
      ...prev,
      plotOptions: {
        bar: {
          ...prev.plotOptions.bar,
          colors: {
            backgroundBarColors,
          },
        },
      },
      yaxis: {
        ...prev.yaxis,
        max: maxValue,
      },
      xaxis: {
        ...prev.xaxis,
        categories,
      },
      colors: [baseColor],
      series: [{ data: seriesData }],
    };

    if (annotation) {
      data.annotations = {
        yaxis: [{
          y: annotation,
          strokeDashArray: 0,
          borderColor: '#E80000',
          label: {
            offsetX: -5,
            offsetY: -5,
            // borderColor: 'transparent',
            style: {
              color: '#111C38',
              background: '#FFFFFF',
              fontFamily: 'Proxima Nova, sans-serif',
              fontSize: 14,
              fontWeight: 500,
            },
            text: `SLA: ${annotation} hours`,
          },
        }],
      };

      data.xaxis = {
        ...data.xaxis,
        labels: {
          ...data.xaxis.labels,
          formatter(value) {
            return `${value.toFixed(1)}%`;
          },
        },
      };
    }

    return data;
  });
};

const today = moment().format('YYYY-MM-DD');
const lastTwoWeek = moment(new Date()).subtract(14, 'days').format('YYYY-MM-DD');

// if (_.sum(data.orders.map(({ pendingCount }) => pendingCount))) {
//   series.push({
//     name: 'Shipped',
//     data: data.orders.map(({ pendingCount }) => pendingCount),
//   });
// }
//
// if (_.sum(data.orders.map(({ shippedCount }) => shippedCount))) {
//   series.push({
//     name: 'Unshipped',
//     data: data.orders.map(({ shippedCount }) => shippedCount),
//   });
// }

const SalesOverview = ({ topChartTemplate }) => {
  const [date, setDate] = useQueryParams({
    startDate: withDefault(StringParam, lastTwoWeek),
    endDate: withDefault(StringParam, today, true),
  }, { updateType: 'replaceIn' });

  const { startDate, endDate } = date;

  const [ordersChart, setOrdersChart] = useState(topChartTemplate);
  const [salesChart, setSalesChart] = useState(chartOptionsTemplate);
  const [orderAgingChart, setOrderAging] = useState(chartOptionsTemplate);
  const [shipmentInfo, setShipmentInfo] = useState([]);
  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      loadingToggle(true);

      const [
        { data: { stores } },
        { data: { agingOrders } },
        { data: { shipments } },
        { data: { orders, ordersInfo: info } },
      ] = await Promise.all([
        Api.getReportsSalesByStore({ startDate, endDate: endDate || startDate }),
        Api.getReportsOrderAging({ startDate, endDate: endDate || startDate }),
        Api.getReportsShipmentsByCarrier({ startDate, endDate: endDate || startDate }),
        Api.getReportsOrder({ startDate, endDate: endDate || startDate }),
      ]);

      createChartData(stores, 'shopType', 'totalPrice', '#1472FF', setSalesChart);
      createChartData(agingOrders, 'timeRange', 'shippedPercent', '#109B48', setOrderAging, '<1');
      setShipmentInfo(shipments.map((s) => ({ ...s, shippedPercent: `${s.shippedPercent}%` })));

      setOrdersChart((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: orders.map((o) => o.date),
        },
        series: [
          {
            name: 'Shipped',
            data: orders.map(({ pendingCount }) => pendingCount),
          },
          {
            name: 'Unshipped',
            data: orders.map(({ shippedCount }) => shippedCount),
          },
        ],
        info,
      }));

      loadingToggle(false);
    })();
  }, [date]);

  const changeDate = (d) => {
    setDate({ startDate: d[0], endDate: d[1] || '' });
  };

  const changeSla = (sla) => {
    setOrderAging((prev) => ({
      ...prev,
      annotations: {
        yaxis: [{
          ...prev.annotations.yaxis[0],
          y: sla,
          label: {
            ...prev.annotations.yaxis[0].label,
            text: `SLA: ${sla} hours`,
          },
        }],
      },
    }));
  };
  const { isMobile } = useWindowSize(1170);
  return (
    <div className="sales_overview_wrapper">
      <div className="sales_overview_date_filter">
        <RangeDatePicker currentDate={date} onChangeDate={changeDate} />
      </div>

      <ChartWrapper height={isMobile ? 480 : 420} title="Orders" loading={loading}>
        <div className="chart_info">
          {ordersInfo.map(({ title, path }) => (
            <div key={title}>{`${title} ${ordersChart.info[path]}`}</div>
          ))}
        </div>

        <Chart
          height={350}
          width="100%"
          type="bar"
          className="apx-chart"
          series={ordersChart.series}
          options={ordersChart}
        />
      </ChartWrapper>

      <div className="overview_bottom_block">
        <ChartWrapper height={330} title="Sales by store" loading={loading}>
          {salesChart.xaxis.categories.length ? (
            <>
              <Chart
                height={275}
                width="100%"
                type="bar"
                className="report-chart"
                series={salesChart.series}
                options={salesChart}
              />

              <Button
                color="#1472FF"
                btnType="transparent"
                type="submit"
                className="reports_view_all"
              >
                View all
              </Button>
            </>
          )

            : (
              <div className="data_not_found">
                <NotFoundIcon className="not_found_icon" />

                <h1>Sales not found</h1>
              </div>
            )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Shipment by carrier" loading={loading}>
          <Table
            data={shipmentInfo}
            header={tableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Shipments not found</h1>
              </>
            )}
          />

          {!!shipmentInfo.length && (
            <Button
              style={{ color: '#1472FF' }}
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Open order aging" loading={loading}>
          {orderAgingChart.xaxis.categories.length ? (
            <>
              <Select
                wrapperClassName="reports_order_aging_select"
                value={orderAgingChart.annotations.yaxis[0].y}
                onChange={changeSla}
                options={orderAgingChart.xaxis.categories.map((c) => ({ value: c }))}
                labelPath="value"
                roundBorder
                menuPosition="fixed"
                size="small"
              />

              <Chart
                height={275}
                width="100%"
                type="bar"
                className="report-chart"
                series={orderAgingChart.series}
                options={orderAgingChart}
              />

              <Button
                color="#1472FF"
                btnType="transparent"
                type="submit"
                className="reports_view_all"
              >
                View all
              </Button>
            </>
          )

            : (
              <div className="data_not_found">
                <NotFoundIcon className="not_found_icon" />

                <h1>Orders not found</h1>
              </div>
            )}
        </ChartWrapper>
      </div>
    </div>
  );
};

export default SalesOverview;
