import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select from '../../../_common/Form/Select';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Button from '../../../_common/Form/Button';
import Api from '../../../../Api';

const tagDefaultData = {
  name: '',
  color: '',
};

function ProductTags({
  tags, tagValue = [], setProductData, setTags,
}) {
  const [modalData, setModalData] = useState({});

  const newTags = useMemo(() => (
    tagValue.map((t) => (typeof t === 'object' ? t.id : t))
  ), [tagValue]);

  const modalFields = [
    {
      title: 'Tag name',
      path: 'name',
      placeholder: 'Enter Tag name',
      required: true,
    },
    {
      title: 'Tag color',
      path: 'color',
      placeholder: 'Enter Tag',
      type: 'colorPicker',
      required: true,
    },
  ];

  const saveTags = useCallback(async (newData) => {
    const sendingData = {
      name: newData.name,
      color: newData.color,
    };

    const newTag = await Api.createProductTag(sendingData);

    const { data } = await Api.getProductsTags();
    setProductData((prev) => ({ ...prev, tags: [...prev.tags, newTag.data.tag.id] }));

    setTags(data.tags);

    toast.success('Tag has been successfully created');
    setModalData({});
  }, [modalData]);

  return (
    <div className="create_product_tags">
      <div className="create_product_tags_block">
        <div className="create_product_tags_input_wrp">
          <Select
            options={tags}
            value={newTags}
            onChange={(value) => setProductData((prev) => ({ ...prev, tags: value }))}
            label="Tags"
            valuePath="id"
            labelPath="name"
            size="small"
            roundBorder
            isMulti
            isClearable
            isSearchable
          />
        </div>

        <Button
          onClick={() => setModalData(tagDefaultData)}
          size="small"
          roundBorder
          btnType="transparent"
          color="#1472FF"
          addBtn
          title="Add tag"
        />
      </div>

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalFields}
        onClose={() => setModalData({})}
        onSave={saveTags}
        singleData={modalData}
        text="tag"
      />
    </div>
  );
}

ProductTags.propTypes = {
  tags: PropTypes.array,
  tagValue: PropTypes.array,
  setProductData: PropTypes.func,
  setTags: PropTypes.func,
};

ProductTags.defaultProps = {
  tags: [],
  tagValue: [],
  setProductData: () => {},
  setTags: () => {},
};

export default ProductTags;
