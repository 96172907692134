import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import moment from 'moment';
import { toast } from 'react-toastify';
import Wrapper from '../../../../Layout/Wrapper';
import Api from '../../../../../Api';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Select from '../../../../_common/Form/Select';
import Loader from '../../../../_common/Loader/Loader';
import Table from '../../../../_common/Tables/Table';
import Pagination from '../../../../_common/Pagination/Pagination';
import ScheduleModal from '../ScheduleModal';
import { ReactComponent as ExportIcon } from '../../../../../assets/icons/export.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/remote_printing.svg';
import Button from '../../../../_common/Form/Button';

const tableHeader = [
  {
    title: 'Customer name',
    path: 'vendorName',
  },
  {
    title: 'Qty ordered',
    path: 'orderedQty',
  },
  {
    title: 'Qty to be received',
    path: 'receivedQty',
  },
];

function PurchaseByCustomerReport() {
  const firstLoad = useRef(true);
  const searchTimeout = useRef(null);

  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    // sortBy: withDefault(StringParam, 'createdAt'),
    // sort: withDefault(StringParam, 'desc'),
    customerId: withDefault(StringParam, ''),
    startDate: withDefault(StringParam, moment().subtract(1, 'month').format('MM-DD-YYYY')),
    endDate: withDefault(StringParam, moment().format('MM-DD-YYYY')),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [totalPages, setTotalPages] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({ id: '', firstName: 'All customers', lastName: '' });

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    (async () => {
      setTimeout(async () => {
        setTableLoading(true);

        await settingStateFromQuery();

        if (firstLoad.current) {
          const customers = await Api.getCustomers({ isVendor: true });

          setCustomerOptions(customers.data.customers);
        }

        try {
          await getPurchaseByCustomer({ allQuery: queryData });
        } catch (e) {
          // navigate('/404');
        }

        setTableLoading(false);
        firstLoad.current = false;
      }, 500);
    })();
  }, [queryData]);

  const settingStateFromQuery = useCallback(async () => {
    let singleCustomer = { firstName: 'All customers', lastName: '', id: '' };

    if (queryData.customerId) {
      const foundCustomer = customerOptions.find((c) => c.id === queryData.customerId);

      if (foundCustomer) {
        singleCustomer = foundCustomer;
      } else {
        try {
          const customer = await Api.getSingleCustomer(queryData.customerId);

          singleCustomer = customer.data.customer;
        } catch (e) {
          console.log(e);
        }
      }
    }

    setSelectedCustomer(singleCustomer);
  }, [queryData]);

  const getPurchaseByCustomer = useCallback(async ({ allQuery }) => {
    let requestData = { ...allQuery };

    if (!requestData.customerId) {
      delete requestData.customerId;
    } else {
      requestData = {
        ...requestData,
        customerId: +requestData.customerId,
      };
    }

    const { data } = await Api.getPurchaseByCustomerReport(requestData);

    setTotalPages(data.totalPages);
    setReportData(data.orders);
  }, []);

  const loadMoreCustomers = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getCustomers({ s: inputValue, isVendor: true });

    callback(data.customers);
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setQuery((prev) => ({
      ...prev,
      sort: newSorting.sort,
      sortBy: newSorting.sortBy,
    }));
  }, [queryData.sort, queryData.sortBy]);

  const onSchedule = useCallback(() => {
    setScheduleIsOpen(true);
  }, []);

  const onCustomerChange = useCallback((value) => {
    setSelectedCustomer(value);

    setQuery((prev) => ({ ...prev, customerId: value.id, page: 1 }));
  }, []);

  const onExport = useCallback(async () => {
    try {
      await Api.exportPurchaseByCustomerReport({
        by: {
          startDate: queryData.startDate,
          endDate: queryData.endDate,
          s: queryData.s,
        },
        fields: ['amount', 'purchaseQty', 'sku', 'vendorName'],
        fileType: 'csv',
      });

      toast.success('Successfully exported. You will receive an email.');
    } catch (e) {
      toast.error('Something went wrong. Please try later.');
    }
  }, [queryData]);

  const onPrint = () => {
    window.print();
  };

  return (
    <Wrapper
      title="Purchase orders by customer report"
      onBtnClick={onSchedule}
      buttonTitle="Schedule report"
      btnType="light_blue"
    >
      {tableLoading && firstLoad.current ? <Loader /> : (
        <div className="inventory_report">
          <div className="inventory_report_filters">
            <div className="inventory_report_filter_search">
              {/* <Input */}
              {/*   value={queryData.s} */}
              {/*   placeholder="Search" */}
              {/*   size="small" */}
              {/*   roundBorder */}
              {/*   search */}
              {/*   onChangeText={(val) => setQuery((prev) => ({ */}
              {/*     ...prev, */}
              {/*     s: val, */}
              {/*   }))} */}
              {/*   data-test-id="filter_search" */}
              {/* /> */}
            </div>

            <div className="inventory_report_filter_right">
              <Button
                onClick={onPrint}
                size="small"
                roundBorder
                iconLeft={<PrintIcon />}
                title="Print"
                btnType="transparent"
                className="print_button"
              />

              {!!reportData.length && (
                <Button
                  onClick={onExport}
                  size="small"
                  roundBorder
                  iconLeft={<ExportIcon />}
                  title="Export"
                  btnType="transparent"
                  className="export_button"
                />
              )}

              <RangeDatePicker
                currentDate={{
                  startDate: queryData.startDate,
                  endDate: queryData.endDate,
                }}
                onChangeDate={(value) => setQuery((prev) => ({
                  ...prev,
                  startDate: value[0],
                  endDate: (value[0] > value[1] || !value[1]) ? value[0] : value[1],
                  page: 1,
                }))}
                calendarClassName="inventory_report_filters_menu_calendar"
                popperPlacement="bottom-start"
              />

              <div className="inventory_report_filter_select">
                <Select
                  value={selectedCustomer}
                  valuePath="id"
                  isAsync
                // options={customerOptions}
                  defaultOptions={[{
                    id: '',
                    firstName: 'All customers',
                    lastName: '',
                  }, ...customerOptions]}
                  getFullOption
                  getOptionLabel={(options) => (`${options.firstName} ${options.lastName}`)}
                  size="small"
                  roundBorder
                  loadOptions={(inputValue, callback) => loadMoreCustomers(inputValue, callback)}
                  placeholder="Customer"
                  onChange={(value) => onCustomerChange(value)}
                />
              </div>
            </div>
          </div>

          {firstLoad.current ? <Loader /> : (
            <div className="inventory_report_table">
              <Table
                data={reportData}
                header={tableHeader}
                deleteModalText="user invitation"
                sortBy={queryData.sortBy}
                sort={queryData.sort}
                onSortBy={onSortBy}
                loading={tableLoading}
                hideDeleteButtonFields={['active', 'restricted']}
              />
            </div>
          )}

          <div className="inventory_report_pagination">
            <Pagination
              totalPages={totalPages}
              onChangePage={(p) => setQuery((prev) => ({
                ...prev,
                page: p,
              }))}
            />
          </div>

          <ScheduleModal isOpen={scheduleIsOpen} onClose={() => setScheduleIsOpen(false)} />
        </div>
      )}
    </Wrapper>
  );
}

export default PurchaseByCustomerReport;
