import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import Modal from '../../../_common/Modal/Modal';
import Timepicker from '../../../_common/Form/Timepicker';
import Datepicker from '../../../_common/Form/Datepicker';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import { getOrdersRequest, getSingleOrderRequest } from '../../../../store/actions/orders';
import { getOrderPackagesRequest, markPackageAsDeliveredRequest } from '../../../../store/actions/packages';

function MarkAsDeliveredModal(props) {
  const { isOpen, onClose, data } = props;
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    handleChange('note', data.note);
  }, [data.note]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload } = await dispatch(markPackageAsDeliveredRequest({ id: data.id, ...form }));
    if (!payload.data.errors) {
      await dispatch(getOrderPackagesRequest(data.orderId));
      await dispatch(getSingleOrderRequest(data.orderId));
      const s = Object.fromEntries(searchParams);
      delete s.service_id;
      await dispatch(getOrdersRequest(
        {
          ...s,
          status: 'pending,shipped,partial_shipped,delivered,partial_delivered',
          includes: 'customer',
          isArchive: false,
          type: 'sale',
        },
      ));
      onClose();
    } else {
      toast.error(payload.data.message);
    }
    setLoading(false);
  }, [form, data, searchParams]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="mark_as_delivered_modal">
      <p className="title">Mark as delivered</p>
      <form onSubmit={handleSubmit}>
        <div className="dates">

          <Datepicker
            label="Date"
            onChange={(date) => handleChange('deliveredAt', moment(date).toISOString())}
            value={form.deliveredAt ? moment(form.deliveredAt).toDate() : undefined}
            portalContainer={document.querySelector('.modal_wrapper')}
          />
          <Timepicker
            label="Time"
            value={form.deliveredAt ? moment(form.deliveredAt).format('hh:mm') : undefined}
            onChange={(time) => {
              if (time) {
                const [h, m] = time.split(':');
                const date = moment(form.deliveredAt).set({ hours: h, minutes: m });
                handleChange('deliveredAt', moment(date).toISOString());
              }
            }}
          />

        </div>
        <Input
          textarea
          value={form.note}
          label="Note"
          onChange={(ev) => handleChange('note', ev.target.value)}
        />
        <div className="actions">
          <Button roundBorder btnType="cancel" onClick={onClose}>Cancel</Button>
          <Button roundBorder type="submit" loading={loading}>Update status</Button>
        </div>
      </form>
    </Modal>
  );
}

export default MarkAsDeliveredModal;
