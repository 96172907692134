import { createReducer } from '@reduxjs/toolkit';
import { getCreditNoteRequest, getCreditNotesRequest } from '../actions/creditNotes';

const initialState = {
  creditNotes: [],
  totalPages: 1,
  creditNote: {},
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCreditNotesRequest.fulfilled, (state, action) => {
      state.creditNotes = action.payload.data.creditNotes;
      state.totalPages = action.payload.data.totalPages;
    })
    .addCase(getCreditNoteRequest.fulfilled, (state, action) => {
      state.creditNote = action.payload.data.creditNote;
    });
});

export default reducer;
