import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment/moment';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Datepicker from '../../../_common/Form/Datepicker';
import noImg from '../../../../assets/icons/no_img.svg';
import Select from '../../../_common/Form/Select';
import { getPackagesRequest, getSettingsRequest } from '../../../../store/actions/settings';
import { getProductBatchesRequest, getProductsRequest } from '../../../../store/actions/products';
import {
  createOrderPackageRequest,
  generatePackageNumberRequest,
  getOrderPackagesRequest,
} from '../../../../store/actions/packages';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/warning_tansparent.svg';
import { getSingleOrderRequest } from '../../../../store/actions/orders';
import Loader from '../../../_common/Loader/Loader';

const weightUnits = [
  { label: 'oz', value: 'oz' },
  { label: 'lb', value: 'lb' },
  { label: 'g', value: 'g' },
  { label: 'kg', value: 'kg' },
];

const dimensionsUnits = [
  { label: 'in', value: 'in' },
  { label: 's', value: 's' },
  { label: 'cm', value: 'cm' },
  { label: 'mm', value: 'mm' },
];

const dimensionsIdKey = Symbol('id');

function PackageForm() {
  const [form, setForm] = useState({
    weightUnit: 'lb',
    dimensionsUnit: 'in',
    sourceCreatedAt: moment().format('YYYY-MM-DD'),
    dimensions: [],
    orderPackageProducts: [],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [custom, setCustom] = useState(false);
  const [products, setProducts] = useState([]);
  const [batches, setBatches] = useState({});
  const [serialNumbers, setSerialNumbers] = useState({});

  const dispatch = useDispatch();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const order = useSelector((state) => state.orders.order);
  const packedCounts = useSelector((state) => state.packages.packedCounts);
  const packages = useSelector((state) => state.settings.packages);

  useEffect(() => {
    if (!order.orderProducts) {
      return;
    }
    (async () => {
      setLoadingData(true);
      const orderPackageProducts = order.orderProducts?.filter((p) => p.productId);
      const { payload: { data } } = await dispatch(generatePackageNumberRequest());
      const { payload: { packageDimensions } } = await dispatch(getPackagesRequest());
      const { payload: { sizeUnit, weightUnit } } = await dispatch(getSettingsRequest(['weightUnit', 'sizeUnit']));

      let batchesData = await Promise.all(orderPackageProducts.map((p) => dispatch(getProductBatchesRequest({ productId: p.productId }))));
      batchesData = batchesData.map((b) => b.payload.batches).flat(1);
      const bt = batchesData
        .filter((b) => b.batchNumber)
        .map((b) => ({ label: b.batchNumber, value: b.batchNumber, productId: b.productId }));
      const sn = batchesData
        .filter((b) => b.serialNumber)
        .map((b) => ({ label: b.serialNumber, value: b.serialNumber, productId: b.productId }));

      setBatches(_.groupBy(bt, 'productId'));
      setSerialNumbers(_.groupBy(sn, 'productId'));

      const dims = packageDimensions?.find((dim) => dim.isDefault);

      const d = {
        orderPackageProducts: orderPackageProducts.map((p) => ({
          ...p,
          orderProductId: p.id,
          qty: p.qty - packedCounts[p.orderProductId] >= 0 ? p.qty - packedCounts[p.orderProductId] : p.qty,
        })),
        number: data.number,
        dimensionsUnit: sizeUnit,
        weightUnit,
        dimensions: dims?.dimensions || [],
        [dimensionsIdKey]: dims?.id,
      };
      setForm({ ...form, ...d });
      setLoadingData(false);
    })();
  }, [packedCounts, order.orderProducts]);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getProductsRequest(
        { ids: order.orderProducts?.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [order]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(createOrderPackageRequest({
      ...form,
      orderId,
      warehouseId: form.orderPackageProducts[0].warehouseId,
      orderPackageProducts: form.orderPackageProducts.map((o) => ({
        orderProductId: o.orderProductId,
        qty: o.qty,
        trackingInfo: o.trackingInfo,
      })),
    }));
    if (data.status === 'error' && !_.isEmpty(data.errors)) {
      setErrors(data.errors || {});
    } else if (data.status === 'error' && _.isEmpty(data.errors)) {
      toast.error(data.message);
    } else {
      await dispatch(getOrderPackagesRequest(orderId));
      await dispatch(getSingleOrderRequest(orderId));
      toast.success('Package successfully created.');
      navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
    }
    setLoading(false);
  }, [form, orderId]);

  const handleDeleteItems = useCallback((id) => {
    const orderPackageProducts = form.orderPackageProducts.filter((o) => o.warehouseId !== id);
    setForm({ ...form, orderPackageProducts });
  }, [form]);

  const handleGetBatches = useCallback(async (s, productId, type) => {
    const { payload } = await dispatch(getProductBatchesRequest({ s, productId }));

    const data = payload.batches.map((b) => ({
      label: b.batchNumber || b.serialNumber,
      value: b.batchNumber || b.serialNumber,
    }));
    if (type === 'batch') {
      setBatches({ ...batches, [productId]: data });
    } else {
      setSerialNumbers({ ...serialNumbers, [productId]: data });
    }
    return data;
  }, [batches, serialNumbers]);

  return (
    <div className="package_form">
      {!loadingData ? (
        <form onSubmit={handleSubmit}>
          <div className="top">
            <p className="title">New package</p>
            <div className="actions">
              <Button
                btnType="cancel"
                roundBorder
                onClick={() => navigate(`${location.pathname}${location.search}`, { state: {}, replace: true })}
              >
                Cancel
              </Button>
              <Button
                roundBorder
                type="submit"
                loading={loading}
                data-scope="WRITE_ORDER_PACKAGES"
                disabled={form.orderPackageProducts.length < 1
                  || _.uniqBy(form.orderPackageProducts, 'warehouseId').length > 1
                  || order.orderPackageProducts?.some((o) => !o.warehouseId)}
              >
                Create package
              </Button>
            </div>
          </div>
          <div className="row">
            <Input
              label="Package#"
              roundBorder
              onChange={(ev) => handleChange('number', ev.target.value)}
              value={form.number}
            />
            <Datepicker
              label="Date"
              onChange={(date) => handleChange('sourceCreatedAt', moment(date).format('YYYY-MM-DD'))}
              value={form.sourceCreatedAt ? moment(form.sourceCreatedAt).toDate() : undefined}
            />
          </div>
          {_.uniqBy(form.orderPackageProducts, 'warehouseId').length > 1 ? (
            <div className="warning_notice">
              <InfoIcon />
              <div>
                <p>You can't create package yet </p>
                <span>The order products are in different warehouses, please make internal transfer or manually add stock to continue.</span>
              </div>
            </div>
          ) : null}
          {_.map(
            _.groupBy(order.orderProducts
              ?.filter((p) => form.orderPackageProducts
                .some((or) => or.orderProductId === p.id)), 'warehouse.id'),
            (w, o) => (
              <div key={o}>
                <div className="row_title">
                  <p>{w[0].warehouse?.title}</p>
                  {_.uniqBy(form.orderPackageProducts, 'warehouseId').length > 1
                    ? <span onClick={() => handleDeleteItems(o)}>Delete items</span> : null}
                </div>
                <div className="table_wrapper">
                  <table className="table">
                    <thead className="table_thead">
                      <tr className="table_thead_tr">
                        <th className="table_thead_tr_th">Product</th>
                        {!_.isEmpty(serialNumbers)
                          ? <th className="table_thead_tr_th">Serial number</th>
                          : <th />}
                        {!_.isEmpty(batches)
                          ? <th className="table_thead_tr_th">Batch number</th>
                          : <th />}
                        <th className="table_thead_tr_th ordered">Ordered</th>
                        <th className="table_thead_tr_th packed">Packed</th>
                        <th className="table_thead_tr_th">Qty to pack</th>
                      </tr>
                    </thead>
                    <tbody className="table_tbody">
                      {w?.map((p) => {
                        const el = form.orderPackageProducts.findIndex((a) => a.orderProductId === p.id);

                        return (
                          <tr
                            className="table_tbody_tr"
                            key={p.id}
                          >
                            <td className="table_tbody_tr_td product_title">
                              <div className="info">
                                <img
                                  style={{ height: 30, width: 30 }}
                                  src={products?.find((f) => f.id === p.productId)?.images?.[0]?.src
                                  || products?.find((f) => f.id === p.productId)?.images?.[0]?.medium
                                  || noImg}
                                  alt=""
                                />
                                <Link to={`/products/${p.productId}`} target="_blank">{p.title}</Link>
                              </div>
                            </td>

                            {serialNumbers[form.orderPackageProducts[el].productId] ? (
                              <td className="table_tbody_tr_td ordered">
                                <Select
                                  defaultOptions={serialNumbers[form.orderPackageProducts[el].productId]}
                                  menuPortalTarget={document.body}
                                  isAsync
                                  loadOptions={(v) => (
                                    handleGetBatches(v, form.orderPackageProducts[el].productId, 'serialNumber')
                                  )}
                                  onChange={(val) => handleChange(`orderPackageProducts[${el}].trackingInfo`, val)}
                                  value={serialNumbers[form.orderPackageProducts[el].productId]
                                    .find((s) => s.value === form.orderPackageProducts[el]?.trackingInfo)}
                                  error={_.get(errors, `orderPackageProducts[${el}].trackingInfo`)}
                                />
                              </td>
                            ) : <td />}
                            {batches[form.orderPackageProducts[el].productId] ? (
                              <td className="table_tbody_tr_td ordered">
                                <Select
                                  defaultOptions={batches[form.orderPackageProducts[el]?.productId]}
                                  menuPortalTarget={document.body}
                                  isAsync
                                  loadOptions={(v) => (
                                    handleGetBatches(v, form.orderPackageProducts[el].productId, 'batch')
                                  )}
                                  onChange={(val) => handleChange(`orderPackageProducts[${el}].trackingInfo`, val)}
                                  value={batches[form.orderPackageProducts[el].productId]
                                    .find((s) => s.value === form.orderPackageProducts[el]?.trackingInfo)}
                                  error={_.get(errors, `orderPackageProducts[${el}].trackingInfo`)}
                                />
                              </td>
                            ) : <td />}
                            <td className="table_tbody_tr_td ordered">
                              {p.qty}
                            </td>
                            <td className="table_tbody_tr_td packed">
                              {packedCounts[p.productId] || 0}
                            </td>
                            <td className="table_tbody_tr_td qty_input">
                              <Input
                                onChange={(ev) => handleChange(`orderPackageProducts[${el}]`, {
                                  orderProductId: p.id,
                                  qty: ev.target.value,
                                  warehouseId: p.warehouseId,
                                })}
                                value={form.orderPackageProducts[el]?.qty}
                                type="number"
                                readOnly={p.qty - packedCounts[p.productId] <= 0}
                                error={_.get(errors, `orderPackageProducts[${el}].qty`, '')}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {form.orderPackageProducts?.some((op) => !op.warehouseId)
                    ? <span style={{ color: '#EB0000' }}>Warehouse is missing</span> : null}
                </div>
              </div>
            ),
          )}
          <div>
            <p className="title package">Package dimensions</p>
            <div className="row">
              <Select
                options={[...packages, { id: '_custom', title: 'Custom' }]}
                roundBorder
                getOptionLabel={(o) => (o.id !== '_custom' ? `${o.title} (${o.dimensions?.join('x')})` : o.title)}
                valuePath="id"
                getFullOption
                onChange={(val) => {
                  handleChange([dimensionsIdKey], val.id);
                  if (val.id !== '_custom') {
                    handleChange('dimensions', val.dimensions);
                    setCustom(false);
                  } else {
                    setCustom(true);
                    handleChange('dimensions', []);
                  }
                }}
                value={form[dimensionsIdKey]}
                error={!!errors.dimensions}
              />
            </div>
            {custom ? (
              <div className="row small">
                <Input
                  label="Length"
                  roundBorder
                  onChange={(ev) => handleChange('dimensions[0]', ev.target.value)}
                  value={form.dimensions[0]}
                  error={errors.dimensions?.[0]}
                  type="number"
                />
                <Input
                  label="Width"
                  roundBorder
                  onChange={(ev) => handleChange('dimensions[1]', ev.target.value)}
                  value={form.dimensions[1]}
                  error={errors.dimensions?.[1]}
                  type="number"
                />
                <Input
                  label="Height"
                  roundBorder
                  onChange={(ev) => handleChange('dimensions[2]', ev.target.value)}
                  value={form.dimensions[2]}
                  error={errors.dimensions?.[2]}
                  type="number"
                />
                <Select
                  label="Measure units"
                  options={dimensionsUnits}
                  roundBorder
                  onChange={(val) => handleChange('dimensionsUnit', val)}
                  value={form.dimensionsUnit}
                />
              </div>
            ) : null}
            <div className="row small">
              <Input
                label="Weight"
                roundBorder
                onChange={(ev) => handleChange('weight', ev.target.value)}
                value={form.weight}
                type="number"
                error={errors.weight}
              />
              <Select
                label="Measure units"
                options={weightUnits}
                roundBorder
                onChange={(val) => handleChange('weightUnit', val)}
                value={form.weightUnit}
              />
            </div>
          </div>
        </form>
      ) : <Loader />}
    </div>
  );
}

export default PackageForm;
