import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import Tabs from '../../_common/Tabs/Tabs';
import { changeAppStatus, getMyApp } from '../../../store/actions/app';
import Loader from '../../_common/Loader/Loader';
import AppCard from '../Apps/AppList/AppCard';
import DeleteModal from '../../_common/Modal/DeleteModal';
import Api from '../../../Api';
import Dashboard from './Tabs/Dashboard';
import IntegrationStepsWrapper from '../Apps/AppSteps/IntegrationStepsWrapper';
import Logs from './Tabs/Logs';
import Wrapper from '../../Layout/Wrapper';
import Utils from '../../../helpers/Utils';
import Products from './Tabs/Products';
import Orders from './Tabs/Orders';
import Customers from './Tabs/Customers';
import SMTPConfigs from '../Apps/SMTPConfigs/SMTPConfigs';
import Button from '../../_common/Form/Button';
import { ReactComponent as ImportIcon } from '../../../assets/icons/import.svg';
import Switcher from '../../_common/Form/Switcher';
import WarningModal from '../../_common/Modal/WarningModal';
import UpdateModal from '../../_common/Modal/UpdateModal';
import { appInstallFields } from '../../../helpers/staticData';
import formatter from '../../../helpers/Formatter';
import MailchimpConfiguration from './Tabs/Mailchimp/MailchimpConfiguration';
import MailchimpLogs from './Tabs/Mailchimp/MailchimpLogs';


const twoTypesInstall = ['shopify']; //  'bigcommerce'

const modalTabs = [
  {
    title: 'oAuth',
    value: 'OAuth',
  },
  {
    title: 'Manual',
    value: 'Manual',
  },
];

const disallowedReinstallApp = ['messaging', 'b2b'];

const MyApp = () => {
  const { integrationId } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const myApp = useSelector((state) => state.app.myApp);

  const [loading, setLoading] = useState(true);
  const [importLoading, setImportLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState('');
  const [installModal, setInstallModal] = useState(false);

  const [installType, setInstallType] = useState('');

  const { fields, appDefaultData, appName } = useMemo(() => {
    const appType = _.kebabCase(myApp.settings?.carrier || (myApp.type === 'cartrover'
      ? `${myApp.type}${myApp.settings?.name}`
      : myApp.type)) + installType;

    const appFormattedName = Utils.getAppName(myApp);

    const appFields = appInstallFields[appType] || [
      {
        title: 'Your app name',
        placeholder: 'Enter your app name',
        path: 'integrationName',
        required: true,
      },
    ];

    const defaultData = { integrationName: myApp.name };

    return { fields: appFields, appDefaultData: defaultData, appName: appFormattedName };
  }, [myApp, installType]);

  const importType = useMemo(() => {
    let currentType = 'all';

    if (myApp.type === 'ftp') {
      const [, ftpType] = myApp.settings.type.split(' ');

      currentType = `${ftpType}s`;
    }

    return currentType;
  }, [myApp]);

  const generalTabs = useMemo(() => {
    let tabs = [
      {
        name: 'Dashboard',
        href: `/my-apps/${integrationId}/dashboard`,
        component: <Dashboard />,
        scope: 'READ_INTEGRATIONS',
      },
      {
        name: 'Settings',
        href: `/my-apps/${integrationId}/settings`,
        component: <IntegrationStepsWrapper fromMyApp openExpiredModal={() => setOpenWarningModal('expired')} />,
        scope: 'READ_INTEGRATIONS',
      },
      {
        name: 'Products',
        href: `/my-apps/${integrationId}/products`,
        component: <Products openExpiredModal={() => setOpenWarningModal('expired')} />,
        scope: 'READ_PRODUCTS',
      },
      {
        name: 'Orders',
        href: `/my-apps/${integrationId}/order-list`,
        component: <Orders openExpiredModal={() => setOpenWarningModal('expired')} />,
        scope: 'READ_ORDERS',
      },
      {
        name: 'Customers',
        href: `/my-apps/${integrationId}/customers`,
        component: <Customers openExpiredModal={() => setOpenWarningModal('expired')} />,
        scope: 'READ_CUSTOMERS',
      },
      {
        name: 'Logs',
        href: `/my-apps/${integrationId}/logs`,
        component: <Logs />,
      },
    ];

    if (myApp.type?.includes('ftp')) {
      const allowTabs = ['Dashboard', 'Settings', 'Logs'];

      tabs = tabs.filter(({ name }) => allowTabs.includes(name));
    } else if (myApp.type === 'messaging') {
      tabs = [
        {
          name: 'Configs',
          href: `/my-apps/${integrationId}/configs`,
          component: <SMTPConfigs />,
        },
      ];
    } else if (myApp.type === 'mailchimp') {
      tabs = [
        {
          name: 'Configs',
          href: `/my-apps/${integrationId}/configs`,
          component: <MailchimpConfiguration />,
        },
        {
          name: 'Error logs',
          href: `/my-apps/${integrationId}/logs`,
          component: <MailchimpLogs />,
        },
      ];
    } else if (myApp.category === 'accounting' || myApp.type === 'shipstation') {
      const allowTabs = ['Settings', 'Logs'];

      tabs = tabs.filter(({ name }) => allowTabs.includes(name));
    } else if (myApp.category === 'b2b') {
      const allowTabs = ['Dashboard', 'Settings', 'Products', 'Customers'];

      tabs = tabs.filter(({ name }) => allowTabs.includes(name));
    } else if (myApp.category !== 'shop') {
      const allowTabs = ['Logs'];

      tabs = tabs.filter(({ name }) => allowTabs.includes(name));
    }

    return tabs;
  }, [myApp]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const app = await dispatch(getMyApp(integrationId));

      if (app.error) {
        navigate('/404', { replace: true });
      } else {
        if (twoTypesInstall.includes(app.payload.integration.type)) {
          setInstallType('OAuth');
        }

        if (app.payload.integration.settings?.refreshTokenExpireDate) {
          const today = moment();
          const tokenExpireDate = moment(app.payload.integration.settings.refreshTokenExpireDate);
          const daysLeftBeforeExpiration = tokenExpireDate.diff(today, 'days');

          if (daysLeftBeforeExpiration <= 0) {
            setOpenWarningModal('expired');
          } else if (daysLeftBeforeExpiration <= 3) {
            setOpenWarningModal('minDaysToExpired');
          }
        }
      }

      setLoading(false);
    })();
  }, []);

  const importData = async () => {
    setImportLoading(true);

    if (myApp.isExpired) {
      setOpenWarningModal('expired');
    } else {
      try {
        const { data: { message } } = await Api.integrationImport({
          shop: myApp.type,
          type: importType,
          integrationId,
        });

        toast.success(message);
      } catch (e) {
        console.warn(e);
        toast.error(e.response.data?.message || 'Something went wrong');
      }
    }

    setImportLoading(false);
  };

  const changeStatus = async (isEnabled) => {
    if (myApp.isExpired) {
      setOpenWarningModal('expired');
    } else {
      dispatch(changeAppStatus({ integrationId, isEnabled }));
    }
  };

  const deleteIntegration = async () => {
    setDeleteLoading(true);

    const { category, type } = myApp;

    if (category === 'other') {
      await Api.uninstallOtherIntegration(integrationId);
    } else if (category === 'shipping') {
      await Api.uninstallShippingIntegration(integrationId);
    } else if (category === 'accounting') {
      await Api.deleteAccounting(type, integrationId);
    } else {
      await Api.uninstallIntegration(integrationId);
    }

    toast.success('App successfully deleted');
    navigate('/my-apps');
    setOpenDeleteModal(false);
    setDeleteLoading(false);
  };

  const reinstallApp = useCallback(async (data) => {
    const {
      id, category, type, settings,
    } = myApp;

    const integrationInfo = {
      integrationName: data.integrationName,
      integrationId: id,
    };

    try {
      const oAuthInstallTypes = [
        'amazon', 'bonanza', 'ebay', 'ecwid', 'shopifyOAuth', 'vend', 'woocommerce', 'mailchimp', 'quickbooks', 'xero',
      ];

      if (type !== 'cartrover' && oAuthInstallTypes.includes(type + installType)) {
        const sendingData = {
          integrationName: data.integrationName,
          redirectUrl: `${window.location.origin}/integration-verify/${type}`,
        };

        let responseRedirectUrl;

        if (category === 'other') {
          const { data: { params } } = await Api.installOtherApp(type, sendingData);

          responseRedirectUrl = params.redirectUrl;
        } else if (category === 'accounting') {
          const { data: { params } } = await Api.installAccounting(type, sendingData);

          responseRedirectUrl = params.redirectUrl;
        } else if (category === 'shop') {
          if (type === 'amazon') {
            sendingData.country = settings.integrationCountry;
            sendingData.region = settings.integrationRegion;
          } else if (type === 'woocommerce') {
            sendingData.url = settings.url;
          }

          const { data: { params } } = await Api.installApp(type, sendingData);

          responseRedirectUrl = params.redirectUrl;

          if (type === 'bonanza') {
            const { authenticationURL, hardExpirationTime, authToken } = params;

            integrationInfo.authenticationURL = authenticationURL;
            integrationInfo.hardExpirationTime = hardExpirationTime;
            integrationInfo.authToken = authToken;

            responseRedirectUrl = authenticationURL;
          }
        }

        localStorage.setItem(`${type}IntegrationInfo`, JSON.stringify(integrationInfo));

        window.location.href = responseRedirectUrl;
      } else {
        const sendingData = { integrationId: id, ...data };

        if (type === 'odoo') {
          sendingData.url = formatter.url(data.url);
        } else if (type === 'bigcommerce') {
          sendingData.redirectUrl = `${window.location.origin}/integration-verify/${type}`;
        } else if (type === 'ftp') {
          const ftpType = settings.type.split(' ')[1];

          sendingData.type = ftpType;
        } else if (type === 'cartrover') {
          sendingData.type = settings.cart_id;
        }

        try {
          if (category === 'shipping') {
            await Api.createShippingIntegration(_.kebabCase(settings.carrier), sendingData);
          } else {
            await Api.createIntegration(type, sendingData);
          }

          dispatch(getMyApp(integrationId));
          toast.success('App has been successfully reinstalled');

          setInstallModal(false);
        } catch (err) {
          if (err.response.data?.errors) {
            throw err;
          } else {
            toast.error(err.response.data.message);
            setInstallModal(false);
          }
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }, [myApp, installType]);

  const openInstallModal = useCallback(async () => {
    setInstallModal(true);
    setOpenWarningModal('');
  }, []);

  return (
    loading ? (
      <div className="my_app_loader_wrapper">
        <Loader />
      </div>
    )
      : (
        <Wrapper
          title={(
            <AppCard
              name={myApp.name}
              icon={Utils.getAppIcon(myApp)}
              text={Utils.getAppName(myApp)}
              onDelete={() => setOpenDeleteModal(true)}
              installBtnText="Reinstall app"
              onInstall={!disallowedReinstallApp.includes(myApp.type) ? openInstallModal : null}
            />
          )}
          className="my_app_wrapper"
        >
          <div className="my_app_import_all_btn">
            <Switcher
              onChange={changeStatus}
              label="Status"
              checked={myApp.isEnabled}
            />

            {myApp.category === 'shop' && (
              <Button
                size="small"
                btnType="cancel"
                roundBorder
                reverseColor
                iconLeft={<ImportIcon />}
                loading={importLoading}
                onClick={importData}
                className={classNames(importType)}
              >
                {`Import ${importType}`}
              </Button>
            )}

            {myApp.category === 'b2b' && (
              <Button
                size="small"
                roundBorder
                reverseColor
                href={myApp.settings.storeUrl}
              >
                Visit Your Store
              </Button>
            )}
          </div>

          <Tabs tabs={generalTabs} />

          <DeleteModal
            isOpen={openDeleteModal}
            text="Intagration"
            onDelete={deleteIntegration}
            deleteType="uninstall"
            onClose={() => setOpenDeleteModal(false)}
            loading={deleteLoading}
          />

          <WarningModal
            isOpen={!!openWarningModal}
            onClose={() => setOpenWarningModal('')}
            onSaveClick={openInstallModal}
            // loading={saveLoading === 'modal'}
            text={openWarningModal === 'expired'
              ? `Please reinstall your ${myApp.name} channel to get updates.`
              : `Your ${myApp.name} channel requires a reinstall in 3 days.`}
            btnText="Reinstall app"
          />

          <UpdateModal
            isOpen={installModal}
            fields={fields}
            onSave={reinstallApp}
            tabs={installType ? modalTabs : null}
            activeTab={installType}
            onChangeTab={setInstallType}
            singleData={appDefaultData}
            onClose={() => setInstallModal(false)}
            fullTitle={(
              <>
                Reinstall
                {' '}
                <span style={{ textTransform: 'capitalize' }}>{appName}</span>
              </>
            )}
            btnText="Reinstall app"
            className={myApp.type === 'shippo' ? 'shipping_integration_modal' : ''}
          />
        </Wrapper>
      )
  );
};

export default MyApp;
