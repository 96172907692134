import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import Table from '../../../_common/Tables/Table';

const tableHeader = [
  {
    title: 'Location',
    path: 'title',
  },
  {
    title: 'Stock',
    path: 'stock',
  },
  {
    title: 'Allocated',
    path: 'allocated',
  },
  {
    title: 'Available',
    path: 'available',
    fieldType: 'input',
    validation: 'number',
    placeholder: '0',
  },
  {
    title: 'Incoming',
    path: 'incoming',
  },
];

function ProductInventory({
  stockWarehouses, onDataChange, disabled, isVariant,
}) {
  const [show, setShow] = useState(true);

  // const tableData = useMemo(() => warehouses.map((w) => {
  //   const foundInv = inventory.find((inv) => +inv.warehouseId === +w.id);
  //
  //   if (foundInv) {
  //     return {
  //       ...w, ...foundInv, available: foundInv.available, id: w.id,
  //     };
  //   }
  //
  //   return { ...w };
  // }), [warehouses, inventory]);

  // console.log({ tableData, inventory });

  return (
    <div className="create_product_inventory">
      <div className={`create_product_title_block ${!show ? 'not_show' : ''}`}>
        {!isVariant && <ArrowIcon onClick={() => setShow((prev) => !prev)} />}

        <h2 className="create_product_title_block_title">Inventory</h2>
      </div>

      {show && (
        <div className="create_product_inventory_table">
          <Table
            data={stockWarehouses}
            header={tableHeader}
            onChange={(id, path, value) => onDataChange(!isVariant ? 'stocks' : 'variantStocks', value, id)}
            disableWhenStatusFalse={disabled}
          />
        </div>
      )}
    </div>
  );
}

ProductInventory.propTypes = {
  onDataChange: PropTypes.func.isRequired,
  stockWarehouses: PropTypes.array.isRequired,
  isVariant: PropTypes.bool,
  disabled: PropTypes.bool,
};

ProductInventory.defaultProps = {
  isVariant: false,
  disabled: false,
};

export default ProductInventory;
