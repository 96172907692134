import React, { useCallback, useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';

const StepTwo = ({
  companyId, data, integrationName, currencyCode, changeData, shopPriceList, fromMyApp,
}) => {
  const [priceList, setPriceList] = useState([]);
  const [loading, loadingToggle] = useState(true);

  const [priceCreatingIds, setPriceCreatingIds] = useState([]);

  useLayoutEffect(() => {
    (async () => {
      loadingToggle(true);

      const { data: { priceLists } } = await Api.getPriceList();
      setPriceList(_.orderBy(priceLists, 'createdAt', 'desc'));

      loadingToggle(false);
    })();
  }, []);

  const selectPrice = useCallback((id, value) => {
    changeData((prev) => ({
      ...prev,
      priceListsMapping: {
        ...prev.priceListsMapping,
        [id]: value,
      },
    }));
  }, []);

  const createPrice = useCallback(async (id, type, name) => {
    setPriceCreatingIds((prev) => [...prev, id]);

    const { data: { priceList: price } } = await Api.createPrice({
      name: `${integrationName} ${name.toLowerCase()}`, type, currencyCode, companyId, isDefault: false,
    });

    setPriceList((prev) => [price, ...prev]);
    selectPrice(id, price.id);

    setPriceCreatingIds((prev) => prev.filter((p) => p !== id));
  }, []);

  return (
    <div className={classNames('integration_step_two_wrapper', { my_app: fromMyApp })}>
      {loading
        ? (
          <Loader />
        )

        : (
          <div>
            {fromMyApp && <h2>Configure Price Lists sync between eSwap and your app</h2>}

            {shopPriceList.map(({ id, name, type }) => (
              <div
                key={id}
                className="integration_step_two_fields_wrapper"
              >
                <div className="integration_step_two_fields_left_block">
                  <p className="integration_step_two_fields_left_block_title">{name}</p>

                  <p
                    className="integration_step_two_fields_left_block_text"
                  >
                    {`Sync your ${integrationName} ${name} prices to`}
                  </p>
                </div>

                <div className="integration_step_two_fields_right_block">
                  <div className="integration_step_two_fields_right_block_select">
                    <Select
                      value={data[id]}
                      onChange={(value) => selectPrice(id, value)}
                      options={priceList}
                      loading={!!priceCreatingIds.includes(id)}
                      isDisabled={!!priceCreatingIds.includes(id)}
                      labelPath="name"
                      valuePath="id"
                      roundBorder
                      size="small"
                      menuPosition="fixed"
                    />
                  </div>

                  {!fromMyApp && (
                  <Button
                    roundBorder
                    size="small"
                    reverseColor
                    btnType="transparent"
                    color="#1472FF"
                    className="integration_step_two_fields_right_block_btn"
                    disabled={!!priceCreatingIds.includes(id)}
                    onClick={() => createPrice(id, type, name)}
                  >
                    Create new
                  </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default StepTwo;
