import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../../Layout/Wrapper';
import Api from '../../../Api';
import Select from '../../_common/Form/Select';
import Input from '../../_common/Form/Input';
import Switcher from '../../_common/Form/Switcher';
import Utils from '../../../helpers/Utils';
import MultipleTagInput from '../../_common/Form/MultipleTagInput';
import Formatter from '../../../helpers/Formatter';
import Loader from '../../_common/Loader/Loader';
import { saveDraftReorderProduct } from '../../../store/actions/products';


const formattingData = (data) => {
  const currentData = { ...data };
  currentData.countryCode = currentData.countryCode || null;
  currentData.regionId = currentData.regionId || null;
  currentData.phone = Formatter.phoneNumber(currentData.phone);
  currentData.isVirtual = !!+currentData.isVirtual;

  return currentData;
};

const defaultWarehouseData = {
  title: '',
  countryCode: '',
  regionId: '',
  city: '',
  address: '',
  phone: '',
  workersEmail: [],
  isVirtual: '0',
  postalCode: '',
  addressNumber: '',
  space: '',
  code: '',
  details: '',
  isDefault: false,
  isUnsalable: false,
};

const CreateOrEditWarehouse = () => {
  const { warehouseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const draftReorder = useSelector((state) => state.products.draftSavedReorder);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [statesLoading, statesLoadingToggle] = useState(false);

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [saveLoading, saveLoadingToggle] = useState(false);

  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      const payload = await Api.getCountries();

      setCountries(payload.data.countries);

      try {
        if (warehouseId === 'add') {
          setData(defaultWarehouseData);
        } else {
          const { data: { warehouse } } = await Api.getWarehouse(warehouseId);

          setData({ ...warehouse, isVirtual: warehouse.isVirtual ? '1' : '0', isDisabled: warehouse.isDefault });
        }
      } catch (e) {
        navigate('/404');
      }

      loadingToggle(false);
    })();
  }, []);

  useEffect(() => {
    if (data.countryCode) {
      (async () => {
        statesLoadingToggle(true);
        const payload = await Api.getRegions(data.countryCode);

        setStates(payload.data.regions);
        statesLoadingToggle(false);
      })();
    }
  }, [data.countryCode]);

  const changeData = useCallback((path, value) => {
    setData((prev) => ({ ...prev, [path]: value }));
    setErrors((prev) => ({ ...prev, [path]: '' }));
  }, []);

  const onBeforeMultiInput = useCallback((value) => {
    if (!Utils.isEmail(value)) {
      toast.error('Please enter valid email');
      return false;
    }

    return true;
  }, []);

  const topFields = useMemo(() => [
    {
      label: 'Title',
      path: 'title',
      placeholder: 'Enter warehouse title',
      type: 'input',
      required: true,
    },
    {
      label: 'Warehouse type',
      path: 'isVirtual',
      placeholder: 'Enter warehouse type',
      options: [{ label: 'Real', value: '0' }, { label: 'Virtual', value: '1' }],
      optionLabel: 'label',
      optionPath: 'value',
      type: 'select',
    },
  ], []);

  const middleFields = useMemo(() => [
    {
      label: 'Phone',
      path: 'phone',
      placeholder: 'Enter warehouse phone',
      type: 'input',
      validation: 'phone',
    },
    // {
    //   label: 'Email',
    //   path: 'email',
    //   placeholder: 'Enter warehouse email',
    //   type: 'input',
    // },
    {
      label: (
        <span>
          Emails

          <span
            style={{
              fontSize: 12, color: '#717A8A', fontWeight: 400, paddingLeft: 6,
            }}
          >
            (separate emails using a enter)
          </span>
        </span>),
      path: 'workersEmail',
      placeholder: 'Enter warehouse emails',
      type: 'multipleInput',
    },
    {
      label: 'Country',
      path: 'countryCode',
      placeholder: 'Enter warehouse country',
      options: countries,
      optionPath: 'code',
      optionLabel: 'name',
      type: 'select',
    },
    {
      label: 'State',
      path: 'regionId',
      options: states,
      optionPath: 'id',
      optionLabel: 'name',
      placeholder: 'Enter warehouse state',
      type: 'select',
    },
    {
      label: 'City',
      path: 'city',
      placeholder: 'Enter warehouse city',
      type: 'input',
    },
    {
      label: 'Address',
      path: 'address',
      placeholder: 'Enter warehouse address',
      type: 'input',
    },
    {
      label: 'Zip code',
      path: 'postalCode',
      placeholder: 'Enter warehouse zip code',
      type: 'input',
      validation: 'number',
    },
    {
      label: 'Address number (optional)',
      path: 'addressNumber',
      placeholder: 'Enter warehouse address number',
      type: 'input',
      validation: 'number',
    },
    {
      label: 'Space',
      path: 'space',
      placeholder: 'Enter warehouse space',
      type: 'input',
    },
    {
      label: 'Warehouse code',
      path: 'code',
      placeholder: 'Enter warehouse code',
      type: 'input',
    },
  ], [countries, states]);

  const submit = useCallback(async (e) => {
    e?.preventDefault();

    let hasError = false;

    [...topFields, ...middleFields].forEach(({ path, required }) => {
      if (required && (
        typeof data[path] === 'string' ? !data[path].trim() : _.isEmpty(data[path]))) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));

        hasError = true;
      }
    });

    if (hasError) {
      toast.error('Please correct these fields');
    } else {
      saveLoadingToggle(true);

      try {
        const sendingData = formattingData(data);

        let newWarehouse;

        if (data.id) {
          newWarehouse = await Api.updateWarehouse(sendingData);
        } else {
          newWarehouse = await Api.createWarehouse(sendingData);
        }

        const backUrl = searchParams?.get('backUrl');

        if (backUrl) {
          dispatch(saveDraftReorderProduct({ ...draftReorder, warehouseId: newWarehouse.data.warehouse }));

          navigate(backUrl);
        } else {
          navigate('/stocks/warehouses');
        }

        toast.success(`Warehouse successfully ${data.id ? 'updated' : 'created'}`);
      } catch (err) {
        setErrors(err.response.data.errors);
        toast.error('Please correct these fields');
      }

      saveLoadingToggle(false);
    }
  }, [data]);

  return (
    <Wrapper
      title={`Warehouse ${warehouseId === 'add' ? 'add' : 'edit'}`}
      onBtnClick={() => submit()}
      onCancelBtnClick={() => navigate(-1)}
      btnLoading={saveLoading}
      buttonDisabled={loading}
      className="warehouse_create_global_wrapper"
    >
      {loading
        ? <Loader />

        : (
          <form onSubmit={submit} className="warehouse_create_wrapper">
            <div className="warehouse_create_top_fields_wrapper">
              <div className="warehouse_create_top_fields">
                {topFields.map(({
                  type, label, path, placeholder, options, optionLabel, optionPath,
                }) => (
                  <div key={label}>
                    {type === 'select'
                      ? (
                        <Select
                          label={label}
                          placeholder={placeholder}
                          options={options || []}
                          value={data[path]}
                          error={errors[path]}
                          onChange={(value) => changeData(path, value)}
                          labelPath={optionLabel}
                          valuePath={optionPath}
                          roundBorder
                          size="small"
                        />
                      )

                      : (
                        <Input
                          label={label}
                          placeholder={placeholder}
                          value={data[path]}
                          error={errors[path]}
                          onChangeText={(value) => changeData(path, value)}
                          size="small"
                          roundBorder
                        />
                      )}
                  </div>
                ))}
              </div>

              <div className="warehouse_create_switchers">
                <Switcher
                  onChange={(check) => changeData('isDefault', check)}
                  checked={data.isDefault}
                  label="Set as default"
                  disabled={data.isDisabled}
                />

                <Switcher
                  onChange={(check) => changeData('isUnsalable', check)}
                  checked={data.isUnsalable}
                  label="Mark as unsellable"
                />
              </div>
            </div>

            <div className="warehouse_create_middle_fields_wrapper">
              <h2>Warehouse contact info</h2>

              <div className="warehouse_create_middle_fields">
                {middleFields.map(({
                  type, label, path, placeholder, validation, options, optionLabel, optionPath,
                }) => (
                  <div key={label}>
                    {type === 'select'
                      ? (
                        <Select
                          label={label}
                          placeholder={placeholder}
                          options={options || []}
                          value={data[path]}
                          error={errors[path]}
                          onChange={(value) => changeData(path, value)}
                          labelPath={optionLabel}
                          valuePath={optionPath}
                          roundBorder
                          loading={path === 'regionId' && statesLoading}
                          isDisabled={path === 'regionId' && !data.countryCode}
                          isSearchable
                          isClearable
                          size="small"
                        />
                      )

                      : type === 'multipleInput'
                        ? (
                          <MultipleTagInput
                            label={label}
                            placeholder={placeholder}
                            value={data[path]}
                            error={errors[path]}
                            onChange={(value) => changeData(path, value)}
                            onBeforeInput={onBeforeMultiInput}
                            size="small"
                            roundBorder
                          />
                        )

                        : (
                          <Input
                            label={label}
                            placeholder={placeholder}
                            password={type === 'password'}
                            value={data[path]}
                            error={errors[path]}
                            onChangeText={(value) => changeData(path, value)}
                            onBeforeInput={(e) => Utils.onBeforeInput(e, validation)}
                            size="small"
                            roundBorder
                          />
                        )}
                  </div>
                ))}
              </div>
            </div>

            <div className="warehouse_create_bottom_fields_wrapper">
              <h2>Add a details</h2>

              <Input
                placeholder="Type here"
                value={data.details}
                onChangeText={(value) => changeData('details', value)}
                roundBorder
                textarea
              />
            </div>

            <button style={{ display: 'none' }} type="submit" />
          </form>
        )}
    </Wrapper>
  );
};

export default CreateOrEditWarehouse;
