import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../../Layout/Wrapper';
import Loader from '../../../_common/Loader/Loader';
import Input from '../../../_common/Form/Input';
import Select from '../../../_common/Form/Select';
import Table from '../../../_common/Tables/Table';
import Api from '../../../../Api';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import Button from '../../../_common/Form/Button';
import { ReactComponent as FieldsNotFoundIcon } from '../../../../assets/icons/no_match.svg';

const menuOptions = [
  {
    label: 'Mark as active',
    invertLabel: 'Mark as inactive',
    value: 'inactive',
    path: 'displayInPortal',
  },
  {
    label: 'Delete custom field',
    value: 'delete',
  },
  {
    label: 'Edit',
    value: 'edit',
  },
];

const types = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Order',
    value: 'orderCustomFields',
  },
  {
    label: 'Product',
    value: 'productCustomFields',
  },
  {
    label: 'Customer',
    value: 'customerCustomFields',
  },
];

const filteringList = (list, type, search) => list.filter((l) => l.customFieldType.includes(type)
  && l.label?.toLowerCase().includes(search.toLowerCase()));

const sortingList = (list, { sortBy, sort }) => _.orderBy(list, (c) => c[sortBy].toLowerCase(), sort);

const CustomFields = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useQueryParams({
    type: withDefault(StringParam, ''),
    s: withDefault(StringParam, ''),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'id'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const { s: search, type } = filters;
  const { sort, sortBy } = sorting;

  const firstLoad = useRef(false);
  const timeout = useRef();

  const defaultData = useRef([]);
  const [customFields, setCustomFields] = useState([]);

  const [loading, loadingToggle] = useState(false);
  const [menuLoadingIds, setMenuLoadingIds] = useState([]);

  const [deletingId, setDeletingId] = useState('');
  const [deleteLoading, deleteLoadingToggle] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await Api.getSettings(['orderCustomFields', 'productCustomFields', 'customerCustomFields']);

      const formattingData = _.map(data, (value, key) => value.map((v) => ({
        ...v,
        customFieldType: key,
        customFieldTypeFormatted: _.upperFirst(key.match(/\b[a-z]+(?=[A-Z])/)[0]),
        mandatory: v.isMandatory ? 'Yes' : 'No',
        status: v.displayInPortal ? 'active' : 'disabled',
        fieldType: _.upperFirst(v.type),
      }))).flat(1);

      defaultData.current = sortingList(filteringList(formattingData, type, search), sorting);
      setCustomFields(defaultData.current);
      firstLoad.current = true;
    })();
  }, []);

  useEffect(() => {
    if (firstLoad.current) {
      loadingToggle(true);
      clearTimeout(timeout.current);

      timeout.current = setTimeout(async () => {
        setCustomFields(sortingList(filteringList(defaultData.current, type, search), sorting));

        loadingToggle(false);
      }, 500);
    }
  }, [search, type]);

  const changeFilters = useCallback((key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);

    setCustomFields((prev) => sortingList(prev, newSorting));
  }, []);

  const menuSelect = useCallback(async (id, option) => {
    const {
      id: fieldId, customFieldType, label, type: fieldType, defaultValue, displayInPortal, helpText, isMandatory,
      options,
    } = defaultData.current.find((d) => d.id === id);

    if (option === 'inactive') {
      setMenuLoadingIds((prev) => [...prev, id]);

      await Api.updateSettingsList({
        [customFieldType]: {
          id: fieldId,
          label,
          type: fieldType,
          defaultValue,
          displayInPortal: !displayInPortal,
          helpText,
          isMandatory,
          options,
        },
      });

      setCustomFields((prev) => prev.map((p) => (p.id === id
        ? {
          ...p,
          displayInPortal: !p.displayInPortal,
          status: !p.displayInPortal ? 'active' : 'disabled',
        }
        : p)));

      toast.success(`Custom field successfully marked as ${!displayInPortal ? 'active' : 'inactive'}`);
      setMenuLoadingIds((prev) => prev.filter((p) => p !== id));
    } else if (option === 'delete') {
      setDeletingId(id);
    } else {
      navigate(`/settings/custom-fields/${customFieldType}/${id}`);
    }
  }, []);

  const deleteCustomField = async () => {
    deleteLoadingToggle(true);
    const { customFieldType } = defaultData.current.find((d) => d.id === deletingId);

    await Api.deleteSettingsList({ [customFieldType]: deletingId });

    setCustomFields((prev) => prev.filter((l) => l.id !== deletingId));
    setDeletingId('');
    deleteLoadingToggle(false);
    toast.success('Custom field successfully deleted');
  };

  const tableHeader = useMemo(() => [
    {
      title: 'Title',
      path: 'label',
      navigate: (id) => {
        const { customFieldType } = defaultData.current.find((d) => d.id === id);

        navigate(`/settings/custom-fields/${customFieldType}/${id}`);
      },
      isSelectable: true,
    },
    {
      title: 'Type',
      path: 'customFieldTypeFormatted',
      isSelectable: true,
    },
    {
      title: 'Data type',
      path: 'fieldType',
      isSelectable: true,
    },
    {
      title: 'Mandatory',
      path: 'mandatory',
      isSelectable: true,
    },
    {
      title: 'Status',
      path: 'status',
      isSelectable: true,
    },
  ], []);

  return (
    <Wrapper
      title="Custom fields"
      buttonHref="/settings/custom-fields/add"
      addBtn
      buttonTitle="New custom field"
    >
      {!firstLoad.current
        ? <Loader />

        : (
          <>
            <div className="warehouse_search_field_wrapper">
              <Input
                placeholder="Search name"
                roundBorder
                size="small"
                search
                value={search}
                onChangeText={(value) => changeFilters('s', value)}
                wrapperClassName="warehouse_search_field"
              />

              <div className="warehouse_filters_wrapper">
                <Select
                  options={types}
                  value={type}
                  onChange={(option) => changeFilters('type', option)}
                  roundBorder
                  size="small"
                  lightBlue
                />
              </div>
            </div>

            <div className="warehouse_list">
              <Table
                data={customFields}
                header={tableHeader}
                sortBy={sortBy}
                sort={sort}
                onSortBy={onSortBy}
                loading={loading}
                onMenuSelect={menuSelect}
                menuOptions={menuOptions}
                menuLoadingIds={menuLoadingIds}
                notFound={(
                  <>
                    <FieldsNotFoundIcon />

                    <h1>Custom fields not found</h1>

                    <Button
                      title="Create custom field"
                      href="/settings/custom-fields/add"
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />
            </div>
          </>
        )}

      <DeleteModal
        isOpen={!!deletingId}
        onDelete={deleteCustomField}
        onClose={() => setDeletingId('')}
        loading={deleteLoading}
        text="custom field"
      />
    </Wrapper>
  );
};

CustomFields.propTypes = {

};

export default CustomFields;
