import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Wrapper from '../../Layout/Wrapper';
import CheckboxTable from '../../_common/Tables/CheckboxTable';
import Input from '../../_common/Form/Input';
import Loader from '../../_common/Loader/Loader';
import Api from '../../../Api';

const header = [
  {
    title: 'Read',
    path: 'READ',
  },
  {
    title: 'Write',
    path: 'WRITE',
  },
  {
    title: 'Delete',
    path: 'DELETE',
  },
];

const CreateRole = ({ admin }) => {
  const navigate = useNavigate();
  const { roleId } = useParams();

  const profile = useSelector((store) => store.users.profile);

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [tables, setTables] = useState({});
  const [defaultScopes, setDefaultScopes] = useState([]);
  const [creatingScopes, setCreatingScopes] = useState([]);
  const [nameError, setNameError] = useState('');
  const [roleInfo, setRoleInfo] = useState({
    name: '',
    description: '',
  });

  const { name, description } = roleInfo;

  useEffect(() => {
    (async () => {
      try {
        const { data } = admin ? await Api.getRolesAdminScopes() : await Api.getRolesDefaultScopes();

        data.scopes.forEach((d) => setTables((prev) => (
          {
            ...prev,
            [d.group]: prev[d.group] ? [...prev[d.group], d] : [d],
          }
        )));

        setDefaultScopes(data.defaultScopes);
        setCreatingScopes((prev) => [...prev, ...data.defaultScopes]);

        if (roleId !== 'add') {
          const singleData = profile.isAdmin
            ? admin
              ? await Api.getSingleAdminRole(roleId)
              : await Api.getSingleDefaultRole(roleId)
            : Api.getSingleRole(roleId);

          const roleData = profile.isAdmin
            ? admin
              ? singleData.data.role
              : singleData.data.defaultRole
            : singleData.data.role;

          setCreatingScopes((prev) => [...prev, ...roleData.scopes]);
          setRoleInfo({
            name: roleData.name,
            description: roleData.description,
          });
        }
      } catch (e) {
        navigate('/404');
      }

      setLoading(false);
    })();
  }, [admin]);

  const onFieldChange = useCallback((label, path, group, checked, checkedList) => {
    const foundLine = tables[group].find((t) => t.label === label);

    setCreatingScopes((prev) => {
      if (path === 'FULL_ACCESS') {
        const allFieldsAreChecked = foundLine.list.length === checkedList.length;

        if (allFieldsAreChecked) {
          return prev.filter((p) => !checkedList.includes(p));
        }

        return _.uniq([...prev, ...foundLine.list]);
      }

      if (path.includes('READ') && !checked) {
        return prev.filter((p) => (!defaultScopes.includes(path) && !foundLine.list.includes(p)));
      }

      if (!path.includes('READ') && checked) {
        return [...prev, path, foundLine.list.find((r) => (r.includes('READ')))];
      }

      return prev.includes(path) && !defaultScopes.includes(path)
        ? prev.filter((p) => p !== path)
        : [...prev, path];
    });
  }, [tables]);

  const changeRoleInfo = useCallback((path, value) => {
    setRoleInfo((prev) => ({
      ...prev,
      [path]: value,
    }));

    if (path === 'name') {
      setNameError('');
    }
  }, []);

  const saveRole = useCallback(async () => {
    setBtnLoading(true);

    let sendingData = {
      scopes: creatingScopes,
      name,
      description,
    };

    const hasError = !name.trim() || !description.trim();

    if (hasError) {
      toast.error('Role name and description is required');
    } else {
      try {
        if (roleId === 'add') {
          if (profile.isAdmin) {
            if (admin) {
              await Api.createAdminRole(sendingData);
            } else {
              await Api.createDefaultRole(sendingData);
            }
          } else {
            await Api.createRole(sendingData);
          }
        } else {
          sendingData = { ...sendingData, id: roleId };

          if (profile.isAdmin) {
            if (admin) {
              await Api.updateAdminRole(sendingData);
            } else {
              await Api.updateDefaultRole(sendingData);
            }
          } else {
            await Api.updateRole(sendingData);
          }
        }

        navigate(`/crm/${admin ? 'admin-roles' : 'roles'}`);
      } catch (e) {
        toast.error(e.response.data.message);
      }
    }

    setBtnLoading(false);
  }, [roleInfo, creatingScopes, admin]);

  return (
    <Wrapper
      title={roleId === 'add' ? 'New role' : 'Update role'}
      onBtnClick={saveRole}
      buttonTitle="Save changes"
      cancelButtonHref="/crm/roles"
      btnLoading={btnLoading}
      className="new_role"
    >
      {loading ? <Loader /> : (
        <div className="new_role_wrapper">
          <div className="new_role_top_block">
            <Input
              onChangeText={(val) => changeRoleInfo('name', val)}
              value={name}
              error={nameError}
              label="Role name"
              roundBorder
              wrapperClassName="new_role_top_block_input"
              size="small"
              placeholder="Role name"
            />

            <Input
              onChangeText={(val) => changeRoleInfo('description', val)}
              value={description}
              textarea
              label="Description"
              roundBorder
              wrapperClassName="new_role_top_block_input"
              placeholder="Descrition"
            />
          </div>

          <div className="new_role_tables_single">
            {Object.keys(tables).map((t) => (
              <CheckboxTable
                data={tables[t]}
                header={header}
                tableTitle={t}
                onChange={onFieldChange}
                defaultScopes={defaultScopes}
                checkedData={creatingScopes}
                key={t}
              />
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CreateRole;
