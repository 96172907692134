import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function TabButton({
  onClick, activeValue, label, buttonsData,
}) {
  return (
    <div className="tab_button_wrapper">
      {label && <label>{label}</label>}

      <div className="tab_button">
        {buttonsData.map(({ title, value }) => (
          <button
            type="button"
            key={title}
            onClick={() => onClick(value)}
            className={classNames({ active: activeValue === value })}
          >
            {title}
          </button>
        ))}
      </div>
    </div>
  );
}

// {
//   width: `calc(100% / ${buttonsData.length})`,
//     minWidth: width || 'auto',
//   height,
//   fontWeight,
//   pointerEvents: (disableActiveButton && activeValue === value) ? 'none' : 'auto',
// }
TabButton.propTypes = {
  label: PropTypes.string,
  buttonsData: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  activeValue: PropTypes.string,
};

TabButton.defaultProps = {
  label: '',
  activeValue: '',
};

export default TabButton;
