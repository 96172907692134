import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import Button from '../../../_common/Form/Button';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import PackageInfoModal from './PackageInfoModal';
import ShipManually from './ShipManually';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import MarkAsDeliveredModal from './MarkAsDeliveredModal';
import Api from '../../../../Api';
import { deleteOrderPackageRequest, getOrderPackagesRequest } from '../../../../store/actions/packages';
import Utils from '../../../../helpers/Utils';
import { getSingleOrderRequest } from '../../../../store/actions/orders';
import TableDropdown from './TableDropdown';

function Packages() {
  const [packageInfoModal, setPackageInfoModal] = useState(null);
  const [markAsDeliveredModal, setMarkAsDeliveredModal] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [dropdown, setDropdown] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const orderPackages = useSelector((state) => state.packages.orderPackages);
  const order = useSelector((state) => state.orders.order);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getOrderPackagesRequest(orderId));
      setLoading(false);
    })();
  }, [orderId]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload: { data } } = await dispatch(deleteOrderPackageRequest(deletingId));
    if (data.status === 'error') {
      toast.error(data.message);
    } else {
      await dispatch(getOrderPackagesRequest(orderId));
      await dispatch(getSingleOrderRequest(orderId));
    }
    setLoadingDelete(false);
    setDeletingId(null);
  }, [deletingId, orderId]);

  const getDate = useCallback((p) => ({
    packed: p.sourceCreatedAt ? moment(p.sourceCreatedAt).format('DD MMM YYYY') : null,
    shipped: p.shippedAt ? moment(p.shippedAt).format('DD MMM YYYY') : null,
    delivered: p.deliveredAt ? moment(p.deliveredAt).format('DD MMM YYYY') : null,
  })[p.status], []);

  return (
    <>
      <div className="packages">
        {!loading && _.isEmpty(orderPackages) ? (
          <div className="no_packages">
            {`No ${order.type === 'purchase' ? 'Receives' : 'Packages'} created so far`}
            <Button
              btnType="transparent"
              onClick={() => navigate(`${location.pathname}${location.search}`, {
                state: { package: true },
                replace: true,
              })}
            >
              {`Create ${order.type === 'purchase' ? 'receive' : 'package'}`}
            </Button>
          </div>
        ) : null}
        {!_.isEmpty(orderPackages) && !loading ? (
          <div className="table_wrapper">
            <table className="table">
              <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Package#</th>
                <th className="table_thead_tr_th ordered">Date</th>
                <th className="table_thead_tr_th packed">Status</th>
                <th className="table_thead_tr_th">Ordered</th>
                <th className="table_thead_tr_th">Packed</th>
                <th className="table_thead_tr_th">Qty to pack</th>
                <th />
              </tr>
              </thead>
              <tbody className="table_tbody">
              {orderPackages.map((p, index) => (
                <tr
                  className="table_tbody_tr"
                  key={p.id}
                >
                  <td className="table_tbody_tr_td number" onClick={() => setPackageInfoModal(p.id)}>
                    {p.number}
                  </td>
                  <td className="table_tbody_tr_td">
                    {getDate(p)}
                  </td>
                  <td className="table_tbody_tr_td">
                    <div className={`status ${p.status}`}>
                      <p className="status_dot" />
                      <span>
                          {order.type === 'purchase'
                            ? Utils.formatPurchaseStatus(p.status)
                            : Utils.formatStatus(p.status)}
                        </span>
                    </div>
                  </td>
                  <td className="table_tbody_tr_td">
                    {_.sumBy(order.orderProducts, 'qty')}
                  </td>
                  <td className="table_tbody_tr_td">
                    {_.sumBy(p.orderPackageProducts, 'qty')}
                  </td>
                  <td className="table_tbody_tr_td">
                    {_.sumBy(order.orderProducts, 'qty') - _.sumBy(orderPackages
                      .map((o) => o.orderPackageProducts).flat(1), 'qty')}
                  </td>
                  <td className="table_tbody_tr_td">
                    <div className="dot_icon" onClick={() => setDropdown(p.id)}>
                      <DotsIcon />
                    </div>
                    {dropdown === p.id ? (
                      <TableDropdown onClose={() => setDropdown(null)} top={50 + ((index + 1) * 45)}>
                        {p.status === 'packed'
                          ? (
                            <li
                              onClick={() => navigate(`${location.pathname}${location.search}`, {
                                state: { shipManually: p.id },
                                replace: true,
                              })}
                            >
                              Ship manually
                            </li>
                          ) : null}
                        {p.status === 'packed'
                          ? (
                            <li onClick={() => navigate(`/orders/sales-order/preview/buy-label/${orderId}/${p.id}`)}>
                              Ship via
                              carrier
                            </li>
                          ) : null}
                        {p.status === 'shipped'
                          ? <li onClick={() => setMarkAsDeliveredModal(p.id)}>Mark as delivered</li> : null}
                        <li onClick={async () => {
                          setLoadingDownload(true);
                          try {
                            const { data } = await Api.downloadPackageDocument(p.id);
                            fileDownload(data, 'document.pdf');
                          } catch (e) {
                            toast.error('Something went wrong');
                          }
                          setLoadingDownload(false);
                        }}
                        >
                          Download packing slip
                          {loadingDownload ? (
                            <div className="loader_wrapper" style={{ top: 10 }}>
                                <span style={{
                                  width: 15,
                                  height: 15,
                                  marginLeft: 5,
                                }}
                                />
                            </div>
                          ) : null}
                        </li>
                        <li
                          onClick={() => setDeletingId(p.id)}
                          className="delete"
                        >
                          Delete packing slip
                        </li>
                      </TableDropdown>
                    ) : null}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        ) : null}

        {packageInfoModal ? (
          <PackageInfoModal
            onClose={() => setPackageInfoModal(false)}
            isOpen={!!packageInfoModal}
            data={orderPackages.find((p) => p.id === packageInfoModal)}
          />
        ) : null}

        <DeleteModal
          isOpen={!!deletingId}
          onDelete={handleDelete}
          onClose={() => setDeletingId(null)}
          loading={loadingDelete}
          text="package"
        />
        {markAsDeliveredModal ? (
          <MarkAsDeliveredModal
            isOpen={!!markAsDeliveredModal}
            onClose={() => setMarkAsDeliveredModal(null)}
            data={orderPackages.find((p) => p.id === markAsDeliveredModal)}
          />
        ) : null}
      </div>
      {location.state?.shipManually ? (
        <ShipManually
          info={orderPackages.find((p) => p.id === location.state.shipManually)}
          packageId={location.state.shipManually}
          onClose={() => navigate(`${location.pathname}${location.search}`, { state: {}, replace: true })}
        />
      ) : null}
    </>

  );
}

export default Packages;
