import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getOrderPackagesRequest = createAsyncThunk(
  'packages/getOrderPackagesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getOrderPackages(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const shipPackageRequest = createAsyncThunk(
  'packages/shipPackageRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, ...d } = payload;
      const { data } = await Api.shipPackage(id, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const deleteOrderPackageRequest = createAsyncThunk(
  'packages/deleteOrderPackageRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteOrderPackage(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const markPackageAsDeliveredRequest = createAsyncThunk(
  'packages/markPackageAsDeliveredRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, ...d } = payload;
      const { data } = await Api.markPackageAsDelivered(id, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getShippingRatesRequest = createAsyncThunk(
  'packages/getShippingRatesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getShippingRates(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getShippingOptionsRequest = createAsyncThunk(
  'packages/getShippingOptionsRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getShippingOptions(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const buyShippingLabelRequest = createAsyncThunk(
  'packages/buyShippingLabelRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.buyShippingLabel(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const updateOrderPackageRequest = createAsyncThunk(
  'packages/updateOrderPackageRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, ...d } = payload;
      const { data } = await Api.updateOrderPackage(id, d);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getOrderRatesByDimensionsRequest = createAsyncThunk(
  'packages/getOrderRatesByDimensionsRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getOrderRates(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getShipmentsTableAttributes = createAsyncThunk(
  'packages/getShipmentsTableAttributes',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getSettings(['orderPackagesTableAttributes']);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const generatePackageNumberRequest = createAsyncThunk(
  'packages/generatePackageNumberRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.generatePackageNumber(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createOrderPackageRequest = createAsyncThunk(
  'packages/createOrderPackageRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createOrderPackage(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getAllPackagesRequest = createAsyncThunk(
  'packages/getAllPackagesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getAllPackages(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createOrderReceiveRequest = createAsyncThunk(
  'packages/createOrderReceiveRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createOrderReceive(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const shipmentsTableEditableEvent = createAction(
  'packages/shipmentsTableEditableEvent',
);

export const changeShipmentsTableAttributes = createAction(
  'packages/changeShipmentsTableAttributes',
);
