import React, { useCallback, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { findCurrency } from 'currency-formatter';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Datepicker from '../../../_common/Form/Datepicker';
import Select from '../../../_common/Form/Select';
import { getSingleOrderRequest } from '../../../../store/actions/orders';
import Checkbox from '../../../_common/Form/Checkbox';
import { getOrderPackagesRequest, shipPackageRequest } from '../../../../store/actions/packages';

function ShipManually(props) {
  const { info, packageId, onClose } = props;
  const [form, setForm] = useState({ status: 'shipped', shippedAt: moment().format('YYYY-MM-DD') });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const order = useSelector((state) => state.orders.order);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(shipPackageRequest({
      id: packageId,
      ...form,
      carrier: form.carrier?.value,
    }));
    if (data.status === 'error') {
      setErrors({ ...data.errors });
    } else {
      await dispatch(getOrderPackagesRequest(orderId));
      await dispatch(getSingleOrderRequest(orderId));
      toast.success('Shipment successfully created.');
      onClose();
    }
    setLoading(false);
  }, [form, packageId, orderId]);

  return (
    <div className="ship_manually">
      <form onSubmit={handleSubmit}>
        <div className="top">
          <p className="title">New shipment</p>
          <div className="actions">
            <Button btnType="cancel" roundBorder onClick={onClose}>Cancel</Button>
            <Button roundBorder type="submit" loading={loading}>
              Create shipment
            </Button>
          </div>
        </div>
        <div className="row">
          <Input
            label="Package#"
            roundBorder
            value={info.number}
            readOnly
          />
          <Input
            label="Shipment Order#"
            roundBorder
            value={order.number}
            readOnly
          />
          <Datepicker
            label="Date"
            onChange={(date) => handleChange('shippedAt', moment(date).format('YYYY-MM-DD'))}
            value={form.shippedAt ? moment(form.shippedAt).format('MM.DD.YYYY') : undefined}
          />
        </div>
        <div className="row">
          <Select
            label="Carrier"
            options={[]}
            roundBorder
            getFullOption
            onChange={(val) => handleChange('carrier', val)}
            value={form.carrier}
            isCreatable
          />
          <Input
            label="Tracking#"
            roundBorder
            onChange={(ev) => handleChange('trackingNumber', ev.target.value)}
            value={form.trackingNumber}
            placeholder="#"
          />
          <Input
            label="Tracking URL"
            roundBorder
            onChange={(ev) => handleChange('trackingUrl', ev.target.value)}
            value={form.trackingUrl}
            placeholder="URL"
          />
        </div>
        <div className="row">
          <Input
            label="Shipping Charges (if any)"
            roundBorder
            onChange={(ev) => handleChange('shippingPrice', ev.target.value)}
            value={form.shippingPrice}
            type="number"
            symbol={findCurrency(order.currencyCode).symbol}
          />
        </div>
        <Input
          textarea
          label="Notes"
          roundBorder
          onChange={(ev) => handleChange('note', ev.target.value)}
          value={form.note}
          placeholder="Enter any notes to be displayed in your transaction"
        />
        <Checkbox
          label="Shipment already delivered"
          onChange={() => {
            if (form.status === 'shipped') {
              handleChange('status', 'delivered');
            } else {
              handleChange('status', 'shipped');
            }
          }}
          checked={form.status === 'delivered'}
        />
      </form>

    </div>
  );
}

export default ShipManually;
