import { useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../../../Api';

const ShopifyIntegration = () => {
  const { app } = useParams();

  const navigate = useNavigate();

  const [{ code, shop: storeId }] = useQueryParams({
    code: StringParam,
    shop: StringParam,
  });

  useEffect(() => {
    (async () => {
      try {
        const { integrationName, integrationId } = JSON.parse(localStorage.getItem(`${app}IntegrationInfo`)) || {};

        if (!code || !storeId || !integrationName) {
          navigate('/404', { replace: true });
        } else {
          const sendingData = { code, storeId, integrationName };

          if (integrationId) sendingData.integrationId = integrationId;

          try {
            const { data: { integration: { id } } } = await Api.createIntegration(app, sendingData);

            navigate(integrationId ? `/my-apps/${id}/dashboard` : `/apps/integration/${id}`, { replace: true });

            if (integrationId) toast.success('App has been successfully reinstalled');
          } catch (err) {
            toast.error(err.response.data.message);
            navigate('/apps/list/all', { replace: true });
          }
        }
      } catch (e) {
        navigate('/404', { replace: true });
      }

      localStorage.removeItem(`${app}IntegrationInfo`);
    })();
  }, []);

  return null;
};

export default ShopifyIntegration;
