import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import _ from 'lodash';
import Utils from '../../../../helpers/Utils';
import Modal from '../../../_common/Modal/Modal';
import Api from '../../../../Api';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import Switcher from '../../../_common/Form/Switcher';
import { companyIndustries, companySizes } from '../../../../helpers/staticData';
import { ReactComponent as LogoIcon } from '../../../../assets/icons/add_image.svg';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/cancel.svg';
import Formatter from '../../../../helpers/Formatter';
import AddressAutocomplete from '../../../_common/Form/AddressAutocomplete';

const imageAllowFormats = '.png, .jpg, .jpeg, .webp';

const requiredFields = [
  'name', 'email', 'phone', 'fax', 'address', 'size', 'industry', 'registerNumber', 'vatNumber', 'countryCode',
  'regionId', 'city', 'postalCode',
];

const addressFieldsFormat = {
  administrative_area_level_1: {
    path: 'regionId',
    textType: 'short_name',
  },
  country: {
    path: 'countryCode',
    textType: 'short_name',
  },
  locality: {
    path: 'city',
    textType: 'long_name',
  },
  postal_code: {
    path: 'postalCode',
    textType: 'short_name',
  },
};

const CreateCompanyModal = memo(({
  isOpen, data, countries, onClose, onSave,
}) => {
  const [companyData, setCompanyData] = useState({});
  const [errors, setErrors] = useState({});
  const [saveLoading, saveLoadingToggle] = useState(false);

  const [statesLoading, statesLoadingToggle] = useState(false);
  const [states, setStates] = useState([]);

  useEffect(() => {
    if (isOpen) setCompanyData(data);
  }, [isOpen]);

  useEffect(() => {
    if (companyData.countryCode) {
      (async () => {
        statesLoadingToggle(true);
        const payload = await Api.getRegions(companyData.countryCode);

        if (isNaN(companyData.regionId)) {
          const regionId = payload.data.regions.find((p) => p.code === companyData.countryCode.regionId)?.id || '';

          setCompanyData((prev) => ({
            ...prev,
            regionId,
          }));
        }

        setStates(payload.data.regions);
        statesLoadingToggle(false);
      })();
    }
  }, [companyData.countryCode]);

  const submit = useCallback(async (e) => {
    e.preventDefault();
    let hasError = false;

    fields.forEach(({ path }) => {
      if (requiredFields.includes(path) && !companyData[path].trim()) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));

        hasError = true;
      } else if (path === 'email' && !Utils.isEmail(companyData[path])) {
        setErrors((prev) => ({ ...prev, [path]: 'Enter valid email' }));
        hasError = true;
      }
    });

    if (hasError) {
      toast.error('Please correct these fields');
    } else {
      saveLoadingToggle(true);

      try {
        const sendingData = { ...companyData };
        sendingData.phone = Formatter.phoneNumber(sendingData.phone);
        sendingData.fax = Formatter.phoneNumber(sendingData.fax);

        const { data: { company } } = companyData.id
          ? await Api.updateCompany(sendingData)
          : await Api.createCompany(sendingData);

        toast.success(`Company successfully ${companyData.id ? 'updated' : 'created'}`);

        company.country = countries.find((s) => s.code === company.countryCode);
        company.region = states.find((s) => s.id === company.regionId);

        onSave(company);
        onClose();
      } catch (err) {
        setErrors(err.response.data.errors);
        toast.error('Please correct these fields');
      }

      saveLoadingToggle(false);
    }
  }, [companyData, states]);

  const changeCompanyData = useCallback((path, value) => {
    setCompanyData((prev) => {
      const newData = { ...prev, [path]: value };

      if (path === 'countryCode') {
        newData.regionId = '';
      }

      return newData;
    });

    setErrors((prev) => ({ ...prev, [path]: '' }));
  }, []);

  const uploadLogo = useCallback((e) => {
    Utils.onUploadFile(e, (file) => {
      setCompanyData((prev) => ({ ...prev, logo: file }));
    }, imageAllowFormats);
  }, []);

  const addressClick = useCallback((addressInfo) => {
    _.forEach(addressInfo.address_components, (val) => {
      const info = addressFieldsFormat[val.types[0]];

      if (info?.path) {
        setCompanyData((prev) => {
          const newData = { ...prev };

          _.set(newData, info.path, val[info.textType]);

          return newData;
        });

        if (val[info.textType]) {
          setErrors((prev) => {
            const newErrors = { ...prev };
            _.unset(newErrors, info?.path);

            return newErrors;
          });
        }
      }
    });
  }, []);

  const fields = useMemo(() => [
    {
      label: 'Company name',
      path: 'name',
      placeholder: 'Enter company name',
      type: 'input',
    },
    {
      label: 'Email',
      path: 'email',
      placeholder: 'Enter company email',
      type: 'input',
    },
    {
      label: 'Company size',
      path: 'size',
      placeholder: 'Enter company size',
      options: companySizes,
      optionPath: 'value',
      optionLabel: 'value',
      type: 'select',
      dataTestId: 'size_select',
    },
    {
      label: 'Industry',
      path: 'industry',
      placeholder: 'Enter company industry',
      options: companyIndustries,
      optionPath: 'title',
      optionLabel: 'title',
      type: 'select',
      dataTestId: 'industry_select',
    },
    {
      label: 'Phone',
      path: 'phone',
      placeholder: 'Enter phone',
      type: 'input',
      validation: 'phone',
    },
    {
      label: 'VAT number',
      path: 'vatNumber',
      placeholder: 'Enter vat number',
      type: 'input',
    },
    {
      label: 'Address',
      path: 'address',
      placeholder: 'Enter address',
      type: 'autoComplete',
      fullWidth: true,
      dataTestId: 'autocomplete_input',
    },
    {
      label: 'Country',
      path: 'countryCode',
      placeholder: 'Enter country',
      options: countries,
      optionPath: 'code',
      optionLabel: 'name',
      type: 'select',
      dataTestId: 'country_code_select',
    },
    {
      label: 'State',
      path: 'regionId',
      options: states,
      optionPath: 'id',
      optionLabel: 'name',
      placeholder: 'Enter state',
      type: 'select',
      dataTestId: 'state_select',
    },
    {
      label: 'City',
      path: 'city',
      placeholder: 'Enter city',
      type: 'input',
    },
    {
      label: 'Zip code',
      path: 'postalCode',
      placeholder: 'Enter zip code',
      type: 'input',
    },
    {
      label: 'FAX',
      path: 'fax',
      placeholder: 'Enter fax',
      type: 'input',
      validation: 'phone',
    },
    {
      label: 'Register number',
      path: 'registerNumber',
      placeholder: 'Enter register number',
      type: 'input',
    },
  ], [countries, states]);

  return (
    <Modal
      className="create_company_modal_wrapper"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h2>{`${companyData.id ? 'Edit' : 'Create'} company`}</h2>

      <div className="upload_btn_wrapper">
        <div className="upload_btn_logo_wrapper">
          {!companyData.logo
            ? (
              <div className="default_company_logo_wrapper">
                <LogoIcon />
              </div>
            )

            : (
              <div className="company_logo_wrapper">
                <img
                  src={companyData.logo?._preview || companyData.logo}
                  alt="Company logo"
                  className="company_logo"
                />

                <ClearIcon onClick={() => changeCompanyData('logo', '')} />
              </div>
            )}
        </div>

        <Button
          btnType="upload"
          size="small"
          reverseColor
          onFileUpload={uploadLogo}
          fileAccept={imageAllowFormats}
        >
          Upload logo
        </Button>
      </div>

      <form onSubmit={submit}>
        {fields.map(({
          type, label, path, placeholder, validation, dataTestId,
          options, optionLabel, optionPath, fullWidth,
        }) => (
          <div key={label} className={classNames({ reset_password_field: fullWidth })}>
            {type === 'select'
              ? (
                <Select
                  label={label}
                  placeholder={placeholder}
                  options={options || []}
                  value={companyData[path]}
                  error={errors[path]}
                  onChange={(value) => changeCompanyData(path, value)}
                  labelPath={optionLabel}
                  valuePath={optionPath}
                  roundBorder
                  loading={path === 'regionId' && statesLoading}
                  isDisabled={path === 'regionId' && !companyData.countryCode}
                  isSearchable
                  size="small"
                  dataTestId={dataTestId}
                />
              )
              : type === 'autoComplete'
                ? (
                  <AddressAutocomplete
                    value={companyData[path]}
                    onChangeText={changeCompanyData}
                    type="edit"
                    addressClick={addressClick}
                  />
                )
                : (
                  <Input
                    label={label}
                    placeholder={placeholder}
                    password={type === 'password'}
                    value={companyData[path]}
                    error={errors[path]}
                    onChangeText={(value) => changeCompanyData(path, value)}
                    onBeforeInput={(e) => Utils.onBeforeInput(e, validation)}
                    size="small"
                    roundBorder
                  />
                )}
          </div>
        ))}

        <Switcher
          onChange={(check) => changeCompanyData('isDefault', check)}
          checked={companyData.isDefault}
          disabled={data.isDefault}
          className="company_default_switcher"
          label="Set as default"
        />

        <div className="btn_wrapper">
          <Button
            btnType="cancel"
            size="small"
            roundBorder
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            roundBorder
            size="small"
            type="submit"
            loading={saveLoading}
          >
            Save changes
          </Button>
        </div>
      </form>
    </Modal>
  );
});

CreateCompanyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CreateCompanyModal;
