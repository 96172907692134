import React, { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from '../_common/Loader/Loader';

const StripeLoader = lazy(() => import('../_common/StripeLoader'));

function StripeLayout({ stripeOptions }) {
  return (
    <Suspense fallback={<Loader />}>
      <StripeLoader stripeOptions={stripeOptions}>
        <Outlet />
      </StripeLoader>
    </Suspense>
  );
}

export default StripeLayout;
