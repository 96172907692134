import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Link, useLocation, useNavigate,
} from 'react-router-dom';
import moment from 'moment';
import { format } from 'currency-formatter';
import { toast } from 'react-toastify';
import { useClickAway } from 'react-use';
import fileDownload from 'js-file-download';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import Pagination from '../../../_common/Pagination/Pagination';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import Button from '../../../_common/Form/Button';
import Api from '../../../../Api';
import {
  changeShipmentsTableAttributes,
  getShipmentsTableAttributes,
  shipmentsTableEditableEvent,
} from '../../../../store/actions/packages';

function ShipmentsTable(props) {
  const [checkedShipments, setCheckedShipments] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const dropdownRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const allPackages = useSelector((state) => state.packages.allPackages);
  const totalPages = useSelector((state) => state.packages.totalPages);
  const tableEditable = useSelector((state) => state.packages.tableEditable);
  const tableColumns = useSelector((state) => state.packages.tableColumns);

  useClickAway(dropdownRef, () => {
    setInvoiceId(null);
  });

  useEffect(() => {
    dispatch(getShipmentsTableAttributes());
  }, []);

  const changeTableAttributes = useCallback((attrs) => {
    dispatch(changeShipmentsTableAttributes(attrs));
  }, []);

  const saveChangedTableAttribute = useCallback(async () => {
    try {
      setSaveLoading(true);
      await Api.updateSettings({ orderPackagesTableAttributes: tableColumns });
      dispatch(shipmentsTableEditableEvent(false));
      setSaveLoading(false);
    } catch (e) {
      toast.error('Something wrong place try again !');
    }
  }, [tableColumns]);

  return (
    <>
      <div className="table_wrapper sale_orders_table">
        <DynamicTable
          onChange={changeTableAttributes}
          loading={props.loading}
          onSelect={setCheckedShipments}
          data={allPackages}
          editable={tableEditable}
          checkbox
          keyExtractor={(item) => item.id}
          columns={tableColumns}
          callDragDisabled={(call) => ['item'].includes(call.key)}
          renderColumnHeader={(col) => col.label || _.startCase(col.key)}
          renderColumns={{
            'shipping#': ({ item }) => (
              <div className="link">
                {item.number}
              </div>
            ),
            'sale_order#': ({ item }) => (
              <div className="link">
                <Link to={`/orders/sales-order/preview/${item.orderId}${location.search}`}>{item.order.number}</Link>
              </div>
            ),
            customer: ({ item }) => (
              <div>
                {`${item.order.shippingAddress?.firstName || '-'} ${item.order.shippingAddress?.lastName || ''}`}
              </div>
            ),
            date: ({ item }) => (
              <div>
                {moment(item.sourceCreatedAt || item.createdAt).format('DD MMM YYYY')}
              </div>
            ),
            carrier: ({ item }) => (
              <div>
                {item.carrier || '-'}
              </div>
            ),
            'tracking#': ({ item }) => (
              <div>
                {item.trackingNumber || item.trackNumber || '-'}
              </div>
            ),
            shippingRate: ({ item }) => (
              <div>
                {format(item.shippingPrice, { code: item.order.currencyCode })}
              </div>
            ),
            status: ({ item }) => (
              <div className={`status ${item.status}`}>
                <div>
                  <p className="status_dot" />
                  <span>{_.startCase(item.status)}</span>
                </div>
              </div>
            ),
            actions: ({ item }) => (
              <div className="products_actions_wrapper">
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setInvoiceId(item.id)}
                >
                  <DotsIcon />
                </div>
                {item.id === invoiceId ? (
                  <ul className="dropdown invoices" ref={dropdownRef}>
                    <li
                      onClick={() => {
                        navigate(`/orders/sales-order/preview/${item.order.id}?tab=packages`, {
                          state: { shipManually: item.id },
                          replace: true,
                        });
                      }}
                    >
                      Ship manually
                    </li>
                    <li
                      onClick={() => {
                        navigate(`/orders/sales-order/preview/buy-label/${item.order.id}/${item.id}`);
                      }}
                    >
                      Ship via carrier
                    </li>
                    <li onClick={async () => {
                      setLoadingDownload(true);
                      const { data } = await Api.downloadPackageDocument(item.id);
                      setLoadingDownload(false);
                      fileDownload(data, 'document.pdf');
                    }}
                    >
                      Download package slip
                      {loadingDownload ? (
                        <div
                          className="loader_wrapper"
                          style={{
                            right: -6,
                            top: 9,
                          }}
                        >
                          <span style={{
                            width: 15,
                            height: 15,
                          }}
                          />
                        </div>
                      ) : null}
                    </li>

                  </ul>
                ) : null}

              </div>
            ),
          }}
        />

      </div>
      {tableEditable
        && (
          <div className="products_wrapper_table_changed_save">
            <Button
              className="table_changed_save_button"
              size="small"
              title="Cancel"
              onClick={() => dispatch(shipmentsTableEditableEvent(false))}
              roundBorder
              btnType="cancel"
            />

            <Button
              className="table_changed_save_button"
              size="small"
              title="Save changes"
              onClick={saveChangedTableAttribute}
              roundBorder
              color="#1472FF"
              loading={saveLoading}
            />
          </div>
        )}
      <Pagination totalPages={totalPages} />
    </>
  );
}

export default ShipmentsTable;
