import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import Table from '../../../../_common/Tables/Table';
import Loader from '../../../../_common/Loader/Loader';
import UpdateModal from '../../../../_common/Modal/UpdateModal';
import Button from '../../../../_common/Form/Button';
import Api from '../../../../../Api';


const taxDefaultData = {
  title: '',
  code: '',
  line: '',
  rate: '',
  type: 'sale',
  isDefault: false,
};

function TaxTypes() {
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [sorting, setSorting] = useState({
    sortBy: 'title',
    sort: 'asc',
  });

  const { sortBy, sort } = sorting;

  useEffect(() => {
    (async () => {
      const { data } = await Api.getTaxTypes();

      setTaxes(data.taxes);
      setLoading(false);
    })();
  }, []);

  const openModal = useCallback((id = null) => {
    setModalData(id ? taxes.find((t) => +t.id === +id) : taxDefaultData);
  }, [taxes]);

  const onSave = useCallback(async (newData) => {
    try {
      const { data: { tax } } = newData.id
        ? await Api.updateTaxTypes(newData)
        : await Api.createTaxTypes(newData);

      setTaxes((prev) => {
        if (newData.id) {
          return prev.map((t) => (+t.id === +tax.id ? tax : t));
        }

        return [...prev, tax];
      });

      closeModal();
    } catch (err) {
      throw err;
    }
  }, []);

  const onDelete = useCallback(async (id) => {
    setModalLoading(true);

    await Api.deleteTaxTypes(id);
    setTaxes((prev) => prev.filter((t) => +t.id !== +id));

    setModalLoading(false);
  }, []);

  const onSort = useCallback((newSorting) => {
    setSorting(newSorting);
  }, []);

  useEffect(() => {
    setTaxes((prev) => _.orderBy(prev, sorting.sortBy, sorting.sort));
  }, [sorting, taxes.length]);

  const closeModal = useCallback(() => {
    setModalData({});
  }, []);

  const tableHeader = useMemo(() => (
    [
      {
        title: 'Title',
        path: 'title',
        isSelectable: true,
        required: true,
        navigate: (id) => openModal(id),
      },
      {
        title: 'Code',
        path: 'code',
        isSelectable: true,
        required: true,
      },
      {
        title: 'Line',
        path: 'line',
        isSelectable: true,
        required: true,
      },
      {
        title: 'Rate',
        path: 'rate',
        isSelectable: true,
        required: true,
        validation: 'rate',
      },
      {
        title: 'Type',
        path: 'type',
        isSelectable: true,
        type: 'select',
        menuPortalTarget: true,
        options: [
          { label: 'Purchase', value: 'purchase' },
          { label: 'Sale', value: 'sale' },
        ],
      },
    ]
  ), [openModal]);

  return (
    loading ? <Loader /> : (
      <div className="general_settings">
        <div className="general_settings_content">
          <Button
            onClick={() => openModal()}
            className="save_button"
            size="small"
            roundBorder
            addBtn
            data-scope="WRITE_TAXES"
          >
            New tax
          </Button>

          <Table
            data={taxes}
            header={tableHeader}
            onEditClick={openModal}
            onDeleteClick={onDelete}
            onSortBy={onSort}
            sortBy={sortBy}
            sort={sort}
            deleteModalText="tax type"
            deleteLoading={modalLoading}
            editScope="WRITE_TAXES"
            deleteScope="DELETE_TAXES"
          />
        </div>

        <UpdateModal
          isOpen={!_.isEmpty(modalData)}
          fields={tableHeader}
          onClose={closeModal}
          onSave={onSave}
          singleData={modalData}
          text="tax type"
        />
      </div>
    )
  );
}

export default TaxTypes;
