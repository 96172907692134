import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../_common/Modal/Modal';
import Select from '../../_common/Form/Select';
import Input from '../../_common/Form/Input';
import { getCountriesRequest, getRegionsRequest } from '../../../store/actions/app';
import {
  createCustomerAddressRequest,
  getSingleCustomerRequest,
  setCustomerFormData, updateCustomerAddressRequest,
} from '../../../store/actions/customers';
import Button from '../../_common/Form/Button';
import { addressIdKey } from '../../../store/reducers/customers';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/delete.svg';

function EditAddressModal(props) {
  const {
    isOpen, address, onClose, onChange,
  } = props;

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const bottomRef = useRef();

  const countries = useSelector((state) => state.app.countries);
  const regions = useSelector((state) => state.app.regions);
  const customerFormData = useSelector((state) => state.customers.customerFormData);
  const { shippingAddresses, billingAddress, id } = customerFormData;

  useEffect(() => {
    dispatch(getCountriesRequest());
  }, []);

  const handleRemoveAddress = useCallback((id) => {
    dispatch(setCustomerFormData({
      key: 'shippingAddresses',
      value: shippingAddresses.filter((c) => c[addressIdKey] !== id),
    }));
  }, [shippingAddresses]);

  const handleCountryChange = useCallback(async (key, value) => {
    handleChange(`${key}.countryCode`, value);
    handleChange(`${key}.region`, '');
    setLoading(true);
    await dispatch(getRegionsRequest(value));
    setLoading(false);
  }, []);

  const handleChange = useCallback((key, value) => {
    dispatch(setCustomerFormData({ key, value }));
  }, []);

  const handleAddAddress = useCallback(async () => {
    await dispatch(setCustomerFormData({
      key: 'shippingAddresses',
      value: [...shippingAddresses, { [addressIdKey]: _.uniqueId('temp') }],
    }));
    bottomRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, [shippingAddresses, bottomRef.current]);

  const handleSave = useCallback(async (ev) => {
    ev.preventDefault();
    setLoadingSave(true);
    if (address === 'billingAddress') {
      let datum = {};
      if (billingAddress[addressIdKey]?.includes('temp')) {
        const { payload: { data } } = await dispatch(createCustomerAddressRequest({
          ...billingAddress,
          customerId: id,
          type: 'billing',
        }));
        datum = data;
      } else {
        const { payload: { data } } = await dispatch(updateCustomerAddressRequest({
          ...billingAddress,
          id: billingAddress[addressIdKey],
          customerId: id,
        }));
        datum = data;
      }
      const { payload: { data } } = await dispatch(getSingleCustomerRequest(customerFormData.id));

      if (datum.status === 'error') {
        setErrors({ [billingAddress[addressIdKey]]: datum.errors });
      } else {
        onChange('billingAddress', data.customer.billingAddress);
        onClose();
      }
    } else {
      const newAddresses = shippingAddresses.filter((s) => s[addressIdKey]?.includes('temp'));
      const editingAddresses = shippingAddresses.filter((s) => !s[addressIdKey]?.includes('temp'));
      const responseNew = await Promise.all(newAddresses.map((s) => dispatch(createCustomerAddressRequest({
        ...s,
        customerId: id,
        type: 'shipping',
      }))));
      const responseEditing = await Promise.all(editingAddresses.map((s) => dispatch(updateCustomerAddressRequest({
        ...s,
        id: s[addressIdKey],
        customerId: id,
      }))));
      const response = [...responseEditing, ...responseNew];
      const er = {};
      if (response.some((r) => r.payload.data.status === 'error')) {
        response.forEach((r, i) => {
          er[shippingAddresses[i][addressIdKey]] = r.payload.data.errors;
        });
        setErrors(er);
      } else {
        const { payload: { data } } = await dispatch(getSingleCustomerRequest(customerFormData.id));
        onChange('shippingAddress', data.customer.shippingAddresses[0]);
        onChange('shippingAddressId', data.customer.shippingAddresses[0]?.id);
        onClose();
      }
    }

    setLoadingSave(false);
  }, [billingAddress, shippingAddresses, address]);

  return (
    <Modal isOpen={isOpen} className="edit_address_modal" onClose={onClose}>
      <form onSubmit={handleSave}>
        {address === 'billingAddress' ? (
          <div>
            <p className="title">{_.startCase(address)}</p>
            <div className="row">

              <Input
                roundBorder
                label="First Name"
                placeholder="First Name"
                onChange={(ev) => handleChange('billingAddress.firstName', ev.target.value)}
                value={_.get(billingAddress, 'firstName', '')}
                error={_.get(errors[billingAddress[addressIdKey]], 'firstName')}
              />
              <Input
                roundBorder
                label="Last Name"
                placeholder="Last Name"
                onChange={(ev) => handleChange('billingAddress.lastName', ev.target.value)}
                value={_.get(billingAddress, 'lastName', '')}
                error={_.get(errors[billingAddress[addressIdKey]], 'lastName')}
              />

            </div>
            <div className="row">
              <Select
                options={countries}
                roundBorder
                isSearchable
                label="Country/region"
                placeholder="Country"
                onChange={(val) => handleCountryChange('billingAddress', val)}
                value={_.get(billingAddress, 'countryCode', '')}
                valuePath="code"
                labelPath="name"
                error={_.get(errors[billingAddress[addressIdKey]], 'countryCode')}
              />
              <Select
                options={regions[billingAddress.countryCode]}
                roundBorder
                label="State"
                placeholder="State"
                loading={loading}
                onChange={(value) => handleChange('billingAddress.region', value)}
                value={regions[billingAddress.countryCode]?.find((c) => c.value === billingAddress.region)}
                error={_.get(errors[billingAddress[addressIdKey]], 'region')}
                isCreatable
              />
            </div>
            <div className="row">
              <Input
                roundBorder
                label="City"
                placeholder="City"
                onChange={(ev) => handleChange('billingAddress.city', ev.target.value)}
                value={_.get(billingAddress, 'city', '')}
                error={_.get(errors[billingAddress[addressIdKey]], 'city ')}
              />
              <Input
                roundBorder
                label="Title"
                placeholder="Title"
                onChange={(ev) => handleChange('billingAddress.title', ev.target.value)}
                value={_.get(billingAddress, 'title', '')}
                error={_.get(errors[billingAddress[addressIdKey]], 'title')}
              />
            </div>

            <div className="row">

              <Input
                roundBorder
                label="Address 1"
                placeholder="Address 1"
                onChange={(ev) => handleChange('billingAddress.address1', ev.target.value)}
                value={_.get(billingAddress, 'address1', '')}
                error={_.get(errors[billingAddress[addressIdKey]], 'address1')}
              />
              <Input
                roundBorder
                label="Address 2"
                placeholder="Address 2"
                onChange={(ev) => handleChange('billingAddress.address2', ev.target.value)}
                value={_.get(billingAddress, 'address2', '')}
                error={_.get(errors[billingAddress[addressIdKey]], 'address2')}
              />
            </div>
            <div className="row">
              <Input
                roundBorder
                label="Mobile phone"
                onChange={(ev) => handleChange('billingAddress.phone', ev.target.value)}
                value={_.get(billingAddress, 'phone', '')}
                error={_.get(errors[billingAddress[addressIdKey]], 'phone')}
              />
              <Input
                roundBorder
                label="Zip"
                placeholder="Zip"
                onChange={(ev) => handleChange('billingAddress.postalCode', ev.target.value)}
                value={_.get(billingAddress, 'postalCode', '')}
                error={_.get(errors[billingAddress[addressIdKey]], 'postalCode')}
              />
            </div>
          </div>
        ) : null}
        <div>
          {address === 'shippingAddresses' ? shippingAddresses.map((a, i) => (
            <div className={`item ${i > 0 ? 'addressItemShipping' : ''}`} key={a[addressIdKey]}>
              <div className="top">
                <p className="title">
                  Shipping address
                  <span>{shippingAddresses.length > 1 ? ` ${i + 1}` : ''}</span>
                </p>
                {i === 0 ? (
                  <Button btnType="transparent" iconLeft={<AddIcon />} onClick={handleAddAddress}>
                    New shipping
                    address
                  </Button>
                ) : null}
                {i > 0 ? (
                  <p className="removeIcon" onClick={() => handleRemoveAddress(a[addressIdKey])}>
                    <RemoveIcon />
                  </p>
                ) : null}
              </div>
              <div className="shipping__address__modal__input__group">
                <div className="row">
                  <Input
                    roundBorder
                    label="First Name"
                    placeholder="First Name"
                    onChange={(ev) => handleChange(`shippingAddresses[${i}].firstName`, ev.target.value)}
                    value={_.get(shippingAddresses, `[${i}].firstName`, '')}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'firstName')}
                  />
                  <Input
                    roundBorder
                    label="Last Name"
                    placeholder="Last Name"
                    onChange={(ev) => handleChange(`shippingAddresses[${i}].lastName`, ev.target.value)}
                    value={_.get(shippingAddresses, `[${i}].lastName`, '')}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'lastName')}
                  />
                </div>
                <div className="row">
                  <Select
                    options={countries}
                    isSearchable
                    roundBorder
                    label="Country/region"
                    placeholder="Country"
                    onChange={(val) => handleCountryChange(`shippingAddresses[${i}]`, val)}
                    value={_.get(shippingAddresses, `[${i}].countryCode`, '')}
                    valuePath="code"
                    labelPath="name"
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'countryCode')}
                  />
                  <Select
                    options={regions[shippingAddresses[i].countryCode]}
                    isSearchable
                    roundBorder
                    label="State"
                    placeholder="State"
                    onChange={(value) => handleChange(`shippingAddresses[${i}].region`, value)}
                    value={regions[shippingAddresses[i].countryCode]?.find((c) => c.value === shippingAddresses[i].region)}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'region')}
                    loading={loading}
                    isCreatable
                  />
                </div>
                <div className="row">
                  <Input
                    roundBorder
                    label="City"
                    placeholder="City"
                    onChange={(ev) => handleChange(`shippingAddresses[${i}].city`, ev.target.value)}
                    value={_.get(shippingAddresses, `[${i}].city`, '')}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'city')}

                  />
                  <Input
                    roundBorder
                    label="Title"
                    placeholder="Title"
                    onChange={(ev) => handleChange(`shippingAddresses[${i}].title`, ev.target.value)}
                    value={_.get(shippingAddresses, `[${i}].title`, '')}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'title')}
                  />

                </div>

                <div className="row">

                  <Input
                    roundBorder
                    label="Address 1"
                    placeholder="Address 1"
                    onChange={(ev) => handleChange(`shippingAddresses[${i}].address1`, ev.target.value)}
                    value={_.get(shippingAddresses, `[${i}].address1`, '')}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'address1')}
                  />
                  <Input
                    roundBorder
                    label="Address 2"
                    placeholder="Address 2"
                    onChange={(ev) => handleChange(`shippingAddresses[${i}].address2`, ev.target.value)}
                    value={_.get(shippingAddresses, `[${i}].address2`, '')}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'address2')}
                  />
                </div>
                <div className="row">
                  <Input
                    roundBorder
                    label="Mobile phone"
                    onChange={(ev) => handleChange(`shippingAddresses[${i}].phone`, ev.target.value)}
                    value={_.get(shippingAddresses, `[${i}].phone`, '')}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'phone')}
                  />
                  <Input
                    roundBorder
                    label="Zip"
                    placeholder="Zip"
                    onChange={(ev) => handleChange(`shippingAddresses[${i}].postalCode`, ev.target.value)}
                    value={_.get(shippingAddresses, `[${i}].postalCode`, '')}
                    error={_.get(errors[shippingAddresses[i][addressIdKey]], 'postalCode')}
                  />
                </div>
              </div>
            </div>
          )) : null}
          <div ref={bottomRef} />
        </div>
        <div className="actions">
          <Button roundBorder btnType="cancel" onClick={onClose}>Cancel</Button>
          <Button roundBorder type="submit" loading={loadingSave}>Save changes</Button>
        </div>
      </form>
    </Modal>
  );
}

export default EditAddressModal;

EditAddressModal.propTypes = {
  address: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
