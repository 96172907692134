import React, { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '../../../_common/Tabs/Tabs';
import General from './Tabs/General';
import TaxTypes from './Tabs/TaxTypes';
import PaymentMethods from './Tabs/PaymentMethods';
import PaymentTerms from './Tabs/PaymentTerms';
import Wrapper from '../../../Layout/Wrapper';

export const generalTabs = [
  {
    name: 'General',
    href: '/settings/general/general',
    component: <General />,
  },
  {
    name: 'Tax Types',
    href: '/settings/general/tax-types',
    component: <TaxTypes />,
    scope: 'READ_TAXES',
  },
  {
    name: 'Payment Methods',
    href: '/settings/general/payment-methods',
    component: <PaymentMethods />,
  },
  {
    name: 'Payment Terms',
    href: '/settings/general/payment-terms',
    component: <PaymentTerms />,
  },
];

function GeneralSettingsWrapper() {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!generalTabs.find((t) => t.href === pathname)) {
      navigate('/404', { replace: true });
    }
  }, []);

  return (
    <Wrapper title="Settings">
      <Tabs tabs={generalTabs} />
    </Wrapper>
  );
}

export default GeneralSettingsWrapper;
