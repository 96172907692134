import React, { useCallback } from 'react';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import Switcher from '../../../../_common/Form/Switcher';

const B2BOther = ({ data, changeData }) => {
  const onChange = useCallback((path, value) => {
    changeData((prev) => {
      const newData = { ...prev };
      _.set(newData, path, value);

      return newData;
    });
  }, []);

  console.log(data);
  return (
    <div className="b2b_other">
      <h2>
        Other configs
      </h2>

      <div className="b2b_other_content">
        <div className="b2b_other_editor_wrapper">
          <Switcher
            label="Enable maintenance mode"
            checked={!!+data.messageStatus}
            onChange={(check) => onChange('settings.messageStatus', check ? '1' : '0')}
          />

          <p className="b2b_other_editor_title">Message</p>

          <ReactQuill
            theme="snow"
            value={data.message}
            onChange={(value) => onChange('settings.message', value)}
          />
        </div>

        <div className="b2b_other_editor_wrapper">
          <Switcher
            label="Enable Term and Conditions"
            checked={!!+data.termsStatus}
            onChange={(check) => onChange('settings.termsStatus', check ? '1' : '0')}
          />

          <p className="b2b_other_editor_title">Term and Conditions text</p>

          <ReactQuill
            theme="snow"
            value={data.terms}
            onChange={(value) => onChange('settings.terms', value)}
          />
        </div>
      </div>
    </div>
  );
};

B2BOther.propTypes = {};

export default B2BOther;
