import React, { useMemo, useState } from 'react';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useDispatch, useSelector } from 'react-redux';
import { salesOrdersTableEditableEvent } from '../../../../store/actions/orders';
import Filters from '../../Products/Filters';
import { ReactComponent as InvoiceIcon } from '../../../../assets/icons/invoice.svg';
import { ReactComponent as PayOrdersIcon } from '../../../../assets/icons/payOrders.svg';
import { ReactComponent as ArchiveIcon } from '../../../../assets/icons/archive.svg';
import { ReactComponent as AddTagsIcon } from '../../../../assets/icons/addTags.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/remove.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/print_labels.svg';
import { ReactComponent as ShopIcon } from '../../../../assets/icons/filters/shop.svg';
import { ReactComponent as WarehouseIcon } from '../../../../assets/icons/filters/warehouse.svg';
import { ReactComponent as TageIcon } from '../../../../assets/icons/filters/tag.svg';
import { ReactComponent as CustomerIcon } from '../../../../assets/icons/filters/user.svg';
import { ReactComponent as StatusIcon } from '../../../../assets/icons/filters/status.svg';
import { ReactComponent as AssignIcon } from '../../../../assets/icons/filters/assign.svg';
import { ReactComponent as OrderIcon } from '../../../../assets/icons/filters/order.svg';
import RateBrowser from '../BulkAction/RateBrowser';
import Modal from '../../../_common/Modal/Modal';
import NewPreset from '../BulkAction/NewPreset';
import ShippingLabels from '../BulkAction/ShippingLabels';
import Api from '../../../../Api';
import Utils from '../../../../helpers/Utils';

const defaultFilterData = [
  { label: 'Status', valuePath: 'status' },
  { label: 'Shop', valuePath: 'shopId' },
  { label: 'Warehouses', valuePath: 'warehouseId' },
  { label: 'Orders', valuePath: 'ids' },
  { label: 'Assigned To', valuePath: 'assignedUserId' },
  { label: 'Tags', valuePath: 'tagId' },
  { label: 'Customer', valuePath: 'customerId' },
];

const filterOptions = {
  status: {
    label: 'Status',
    path: 'status',
    valuePath: 'status',
    icon: <StatusIcon />,
    staticData: [
      {
        label: 'Shipped',
        id: 'shipped',
      },
      {
        label: 'Pending',
        id: 'pending',
      },
      {
        label: 'Cancelled',
        id: 'canceled',
      },
      {
        label: 'Delivered',
        id: 'delivered',
      },
      {
        label: 'Packaged',
        id: 'packaged',
      },
      {
        label: 'Partially shipped',
        id: 'partial_shipped',
      },
      {
        label: 'Draft',
        id: 'draft',
      },
      {
        label: 'Partially delivered',
        id: 'partial_delivered',
      },
    ],
  },
  shopId: {
    label: 'Shop',
    path: 'shop',
    icon: <ShopIcon />,
    valuePath: 'shopId',
    localSearch: true,
  },
  warehouseId: {
    label: 'Warehouses',
    icon: <WarehouseIcon />,
    path: 'warehouses',
    valuePath: 'warehouseId',
  },
  ids: {
    label: 'Orders',
    path: 'orders',
    icon: <OrderIcon />,
    valuePath: 'ids',
  },
  assignedUserId: {
    label: 'Assigned To',
    icon: <AssignIcon />,
    path: 'users',
    valuePath: 'assignedUserId',
  },
  tagId: {
    label: 'Tags',
    path: 'tags',
    icon: <TageIcon />,
    valuePath: 'tagId',
  },
  customerId: {
    label: 'Customer',
    icon: <CustomerIcon />,
    path: 'customer',
    valuePath: 'customerId',
  },
};

const DelimitedArrayParamComma = Utils.customDelimitedArrayParam('~');
const defaultFiltersParams = withDefault(DelimitedArrayParamComma, []);
const balkActions = [
  // {
  //   label: (
  //     <>
  //       <FlatIcon />
  //
  //       <p>Finalize</p>
  //     </>
  //   ),
  //   value: 'finalize',
  // },
  {
    label: (
      <>
        <InvoiceIcon />

        <p>Invoice</p>
      </>
    ),
    value: 'invoice',
  },
  {
    label: (
      <>
        <PayOrdersIcon />

        <p>Pay orders</p>
      </>
    ),
    value: 'payOrders',
  },
  // {
  //   label: (
  //     <>
  //       <FulfillOrdersIcon />
  //
  //       <p>Fulfill orders</p>
  //     </>
  //   ),
  //   value: 'fulfillOrders',
  // },
  // {
  //   label: (
  //     <>
  //       <ShippingLabelsIcon />
  //
  //       <p>Create shipping labels</p>
  //     </>
  //   ),
  //   value: 'shippingLabels',
  // },
  // {
  //   label: (
  //     <>
  //       <SendIcon />
  //
  //       <p>Send to MCF</p>
  //     </>
  //   ),
  //   value: 'SendToMCF',
  // },
  {
    label: (
      <>
        <ArchiveIcon />

        <p>Archive orders</p>
      </>
    ),
    value: 'archive',
  },
  // {
  //   label: (
  //     <>
  //       <AssignToIcon />
  //
  //       <p>Assign to</p>
  //     </>
  //   ),
  //   value: 'assignTo',
  // },
  {
    label: (
      <>
        <AddTagsIcon />

        <p>Add tags</p>
      </>
    ),
    value: 'addTags',
  },
  {
    label: (
      <>
        <CloseIcon />

        <p>Remove tags</p>
      </>
    ),
    value: 'removeTags',
  },
  {
    label: (
      <>
        <PrintIcon />

        <p>Print packing slips</p>
      </>
    ),
    value: 'printPackingSlips',
  },
  {
    label: (
      <>
        <PrintIcon />

        <p>Print shipping labels</p>
      </>
    ),
    value: 'printShippingLabels',
  }, {
    label: (
      <>
        <PrintIcon />

        <p>Print packing list</p>
      </>
    ),
    value: 'printPackingList',
  },
];

function SaleOrdersFilters(props) {
  const { filterExclude = [] } = props;
  const dispatch = useDispatch();
  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    status: defaultFiltersParams,
    warehouseId: defaultFiltersParams,
    ids: defaultFiltersParams,
    orderStatus: withDefault(StringParam, ''),
    _status: withDefault(StringParam, ''),
    assignedUserId: defaultFiltersParams,
    tagId: defaultFiltersParams,
    customerId: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [openShippingLabels, setOpenShippingLabels] = useState(false);
  const [openPresetsModal, setOpenPresetsModal] = useState(false);
  const [openNewPresetsModal, setOpenNewPresetsModal] = useState(false);
  const [openSavePresetsModal, setOpenSavePresetsModal] = useState(false);

  const tableEditable = useSelector((state) => state.orders.tableEditable);

  const normalizeFilterOptions = useMemo(() => {
    if (props.type === 'purchase') {
      filterOptions.customerId.label = 'Vendor';
    } else {
      filterOptions.customerId.label = 'Customer';
    }
    return filterOptions;
  }, [props.type]);

  const loadOptions = async (filter) => {
    if (filter.staticData) {
      return { data: filter.staticData };
    }

    let data;
    let totalPages;

    if (filter.path === 'warehouses') {
      const { data: { warehouses: list, totalPages: p } } = await Api.getWarehouses();

      data = list.map((warehouse) => ({ id: warehouse.id, label: warehouse.title }));
      totalPages = p;
    }

    if (filter.path === 'shop') {
      const { data: { integrations, totalPages: p } } = await Api.getIntegrations({ category: 'shop' });

      data = integrations.map((s) => ({ id: s.id, label: s.name }));
      data = [{ label: 'eSwap', id: '0' }, ...data];
      totalPages = p;
    }

    if (filter.path === 'orders') {
      const { data: { orders, totalPages: p } } = await Api.getOrders({
        isArchive: false,
        type: props.type,
      });

      data = orders.map((s) => ({ id: s.id, label: s.number }));
      totalPages = p;
    }

    if (filter.path === 'users') {
      const { data: { users, totalPages: p } } = await Api.getUsersList();

      data = users.map((s) => ({ id: s.id, label: `${s.firstName || ''} ${s.lastName || ''}` }));
      totalPages = p;
    }
    if (filter.path === 'tags') {
      const { data: { tags, totalPages: p } } = await Api.getOrdersTags();

      data = tags.map((s) => ({ id: s.id, label: s.name }));
      totalPages = p;
    }
    if (filter.path === 'customer') {
      const { data: { customers, totalPages: p } } = await Api.getCustomers({
        isVendor: props.type === 'purchase' ? true : undefined,
        isCustomer: props.type === 'sale' ? true : undefined,
      });

      const handleGetLabel = (o) => {
        if (o.firstName) {
          return `${o.firstName} ${o.lastName}`;
        }
        if (o.email) {
          return o.email;
        }
        if (o.phone) {
          return o.phone;
        }
        return o.id;
      };

      data = customers.map((s) => ({ id: s.id, label: handleGetLabel(s) }));
      totalPages = p;
    }
    return { data, totalPages };
  };

  const balkActionsClick = (path) => {
    props.openBulkActionModals(path);
    if (path === 'shippingLabels') {
      setOpenShippingLabels(true);
    }
  };

  const openPresets = () => {
    setOpenShippingLabels(false);
    setOpenPresetsModal(true);
  };

  const savePreset = () => {
    setOpenNewPresetsModal(false);
    setOpenSavePresetsModal(true);
  };

  return (
    <>
      <Filters
        showActionButtons={!!props.checkedOrders?.length}
        balkActions={balkActions}
        balkActionsClick={balkActionsClick}
        queryData={queryData}
        setQuery={setQuery}
        tableEditable={tableEditable}
        defaultFilterData={defaultFilterData
          .filter((f) => !filterExclude.includes(f.valuePath))
          .map((d) => {
            if (d.valuePath === 'customerId') {
              if (props.type === 'purchase') {
                d.label = 'Vendor';
              } else {
                d.label = 'Customer';
              }
            }
            return d;
          })}
        onPrintClick={() => {
        }}
        onImportClick={() => props.setOpenImportModal(true)}
        onExportClick={() => props.setOpenExportModal(true)}
        filterOptions={normalizeFilterOptions}
        loadOptions={loadOptions}
        onEditableTableClick={props.reorderCols ? () => dispatch(salesOrdersTableEditableEvent(true)) : undefined}
      />

      <Modal
        className="shipping_labels_modal"
        isOpen={openShippingLabels}
        onClose={() => setOpenShippingLabels(false)}
      >
        <ShippingLabels openPresets={openPresets} />
      </Modal>

      <Modal
        className="presets_modal"
        isOpen={openPresetsModal}
        onClose={() => setOpenPresetsModal(false)}
      >
        <h3 className="presets_modal_title">Presets</h3>

        <div className="presets_modal_wrap">
          <div className="preset_title">Preset title</div>

          <div className="preset_edit_delete">
            <div className="preset_edit_delete_title">My preset 1</div>

            <div className="deleteEditBlock">
              <div>
                <Edit />
              </div>

              <div>
                <Delete />
              </div>

            </div>
          </div>

          <div
            role="button"
            tabIndex="0"
            className="new_preset"
            onClick={() => {
              setOpenPresetsModal(false);
              setOpenNewPresetsModal(true);
            }}
          >
            <PlusIcon />
            New preset
          </div>
        </div>
      </Modal>

      <Modal
        className="new_preset_modal"
        isOpen={openNewPresetsModal}
        onClose={() => setOpenNewPresetsModal(false)}
      >
        <NewPreset savePreset={savePreset} />
      </Modal>

      <Modal
        className="rate_browser"
        isOpen={openSavePresetsModal}
        onClose={() => setOpenSavePresetsModal(false)}
      >
        <RateBrowser />
      </Modal>
    </>

  );
}

export default SaleOrdersFilters;
