import React from 'react';
import _ from 'lodash';
import { format } from 'currency-formatter';
import Button from '../../../_common/Form/Button';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/close_red.svg';

const splitMethods = [
  { label: 'by quantity', value: 'quantity' },
  { label: 'equal', value: 'equal' },
];

const costId = Symbol('id');

function LandedCoast(props) {
  const {
    onChange, landedCosts, currencyCode, total, errors,
  } = props;

  return (
    <div className="landed_coast">
      <p className="title">Landed cost</p>
      {!_.isEmpty(landedCosts)
        ? (
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Title</th>
                <th className="table_thead_tr_th">Cost</th>
                <th className="table_thead_tr_th">Tax</th>
                <th className="table_thead_tr_th">Split method</th>
                <th className="table_thead_tr_th">Total</th>
                <th />
              </tr>
            </thead>
            <tbody className="table_tbody">
              {landedCosts.map((l, i) => (
                <tr
                  className="table_tbody_tr"
                  key={l[costId]}
                >

                  <td className="table_tbody_tr_td title">
                    <Input
                      value={_.get(landedCosts, `[${i}].title`)}
                      onChange={(ev) => {
                        _.set(landedCosts, `[${i}].title`, ev.target.value);
                        onChange('landedCosts', [...landedCosts]);
                      }}
                      error={!!_.get(errors, `landedCosts[${i}].title`)}
                    />

                  </td>
                  <td className="table_tbody_tr_td coast">
                    <Input
                      value={_.get(landedCosts, `[${i}].price`)}
                      type="number"
                      onChange={(ev) => {
                        _.set(landedCosts, `[${i}].price`, ev.target.value);
                        onChange('landedCosts', [...landedCosts]);
                      }}
                      error={!!_.get(errors, `landedCosts[${i}].price`)}
                    />
                  </td>
                  <td className="table_tbody_tr_td discount">
                    <Input
                      min={0}
                      symbol="%"
                      value={_.get(landedCosts, `[${i}].taxPercentage`)}
                      type="number"
                      onChange={(ev) => {
                        _.set(landedCosts, `[${i}].taxPercentage`, ev.target.value);
                        onChange('landedCosts', [...landedCosts]);
                      }}
                      error={!!_.get(errors, `landedCosts[${i}].taxPercentage`)}
                    />
                  </td>

                  <td className="table_tbody_tr_td select">
                    <Select
                      options={splitMethods}
                      menuPortalTarget={document.body}
                      onChange={(val) => {
                        _.set(landedCosts, `[${i}].splitMethod`, val);
                        onChange('landedCosts', [...landedCosts]);
                      }}
                      value={_.get(landedCosts, `[${i}].splitMethod`)}
                    />
                  </td>
                  <td
                    className="table_tbody_tr_td"
                  >
                    {format(_.get(total, `landedCosts[${i}].price`), { code: currencyCode })}
                  </td>
                  <td className="table_tbody_tr_td action">
                    <RemoveIcon onClick={() => {
                      onChange('landedCosts', landedCosts.filter((pr) => pr[costId] !== l[costId]));
                    }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
        : null}
      <Button
        btnType="transparent"
        onClick={() => onChange(`landedCosts[${landedCosts.length}]`, {
          splitMethod: splitMethods[0].value,
          [costId]: _.uniqueId('temp'),
        })}
      >
        {_.isEmpty(landedCosts) ? 'Add landed cost' : 'Add new'}
      </Button>
    </div>
  );
}

export default LandedCoast;
