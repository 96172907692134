import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Api from '../../../../../Api';
import Loader from '../../../../_common/Loader/Loader';
import Select from '../../../../_common/Form/Select';

const AccountingPaymentMethods = ({
  data, integrationName, changeData, paymentMethods, fromMyApp,
}) => {
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      const { data: { paymentMethods: list } } = await Api.getSettings(['paymentMethods']);

      setPaymentMethodsList(list);
      loadingToggle(false);
    })();
  }, []);

  const changePaymentData = (key, value) => {
    changeData((prev) => ({
      ...prev,
      accountingSettings: {
        ...prev.accountingSettings,
        payment: {
          ...prev.accountingSettings.payment,
          [key]: value,
        },
      },
    }));
  };

  return (
    <div className={classNames('integration_step_two_wrapper', { my_app: fromMyApp })}>
      {loading
        ? (
          <Loader />
        )

        : (
          <div>
            {fromMyApp && <h2>Configure Payment Methods sync between eSwap and your app</h2>}

            {paymentMethodsList.map(({ id, title }) => (
              <div
                key={id}
                className="integration_step_two_fields_wrapper"
              >
                <div className="integration_step_two_fields_left_block">
                  <p className="integration_step_two_fields_left_block_title">{title}</p>

                  <p
                    className="integration_step_two_fields_left_block_text"
                  >
                    {`Sync your ${integrationName} ${title} method to`}
                  </p>
                </div>

                <div className="integration_step_two_fields_right_block">
                  <div className="integration_step_two_fields_right_block_select">
                    <Select
                      value={data[id]}
                      onChange={(value) => changePaymentData(id, value)}
                      options={paymentMethods}
                      labelPath="name"
                      valuePath="id"
                      roundBorder
                      size="small"
                      menuPosition="fixed"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default AccountingPaymentMethods;
