import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Modal from '../../_common/Modal/Modal';
import ExportConfig from './ExportConfig';
import ExportTemplate from './ExportTemplate';
import { getExportTemplateCustomFieldList, templateFieldsAction } from '../../../store/actions/fileExport';

const ExportFileModal = memo(({
  isOpen, onClose, className, selectedItemsId, name,
}) => {
  const dispatch = useDispatch();

  const [requestExport, setRequestExport] = useState({
    by: {
      ids: [],
      shopId: [],
      warehouseId: [],
    },
    fileType: 'csv',
  });
  const [exportType, setExportType] = useState('all');
  const [createOrUpdateTemplate, setCreateOrUpdateTemplate] = useState('');
  const [requestSaveTemplate, setRequestSaveTemplate] = useState({ fields: [] });

  useMemo(() => {
    setRequestExport((prev) => ({
      ...prev,
      by: {
        ...prev.by,
        ids: selectedItemsId,
      },
    }));

    setExportType(selectedItemsId.length ? 'selected' : 'all');
  }, [selectedItemsId]);

  const onChangeExportType = (type) => {
    setRequestExport((prev) => ({
      ...prev,
      by: {
        ids: [],
        shopId: [],
        warehouseId: type === 'selected' ? selectedItemsId : [],
      },
    }));

    setExportType(type);
  };

  const onCloseModal = useCallback(() => {
    setCreateOrUpdateTemplate('');
    setExportType('all');
    setRequestExport({
      by: {
        ids: [],
        shopId: [],
        warehouseId: [],
      },
      fileType: 'csv',
    });
    setRequestSaveTemplate({ fields: [] });
    onClose();
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(getExportTemplateCustomFieldList({ name }));
    })();

    return () => dispatch(templateFieldsAction([]));
  }, []);

  return (
    <Modal
      className={classNames('export_modal_wrapper', className)}
      isOpen={isOpen}
      onClose={onCloseModal}
      zIndex={998}
    >
      {createOrUpdateTemplate
        ? (
          <ExportTemplate
            createOrUpdateTemplate={createOrUpdateTemplate}
            setCreateOrUpdateTemplate={setCreateOrUpdateTemplate}
            requestSaveTemplate={requestSaveTemplate}
            setRequestSaveTemplate={setRequestSaveTemplate}
            name={name}
            setRequestExport={setRequestExport}
          />
        )
        : (
          <ExportConfig
            name={name}
            selectedItemsLength={selectedItemsId.length}
            exportType={exportType}
            onChangeExportType={onChangeExportType}
            createOrUpdateTemplate={createOrUpdateTemplate}
            closeModal={onCloseModal}
            setCreateOrUpdateTemplate={setCreateOrUpdateTemplate}
            requestSaveTemplate={requestSaveTemplate}
            setRequestSaveTemplate={setRequestSaveTemplate}
            setRequestExport={setRequestExport}
            requestExport={requestExport}
          />
        )}

    </Modal>
  );
});

ExportFileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  selectedItemsId: PropTypes.array,
};

ExportFileModal.defaultProps = {
  className: '',
  onClose: null,
  selectedItemsId: [],
};

export default ExportFileModal;
