import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const socketAIMessage = createAction('chatBot/socket/get-message');
export const socketAIEndThread = createAction('chatBot/socket/close-thread');
export const socketAICreatedThread = createAction('chatBot/socket/created-thread');
export const socketAIUpdateThreadName = createAction('chatBot/socket/update-thread-name');
export const resetNewMessage = createAction('chatBot/reset-new-message');
export const resetChatBot = createAction('chatBot/reset');

export const getAnswerRequest = createAsyncThunk(
  'chatBot/get-answer',
  async (payload, thunkAPI) => {
    const { message, topic, threadId } = payload;

    try {
      const { data } = await Api.getAnswer({ userMessage: `${topic} ${message}`, threadId });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getThreadsRequest = createAsyncThunk(
  'chatBot/get-threads',
  async (payload, thunkAPI) => {
    const { page = 1 } = payload || {};

    try {
      const { data } = await Api.getThreads(payload);

      return { ...data, page };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getThreadRequest = createAsyncThunk(
  'chatBot/get-thread',
  async ({ threadId, after }, thunkAPI) => {
    try {
      const { data } = await Api.getThread({ threadId, after });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createThreadRequest = createAsyncThunk(
  'chatBot/create-thread',
  async ({ orderType, threadName }, thunkAPI) => {
    try {
      const { data } = await Api.createThread({ orderType, threadName });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const updateThreadNameRequest = createAsyncThunk(
  'chatBot/update-thread',
  async ({ threadId, threadName }, thunkAPI) => {
    try {
      const { data } = await Api.updateThread({ threadId, threadName });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const endThreadRequest = createAsyncThunk(
  'chatBot/end-thread',
  async (threadId, thunkAPI) => {
    try {
      if (threadId) {
        return await Api.closeThread(threadId);
      }

      return null;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
