import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { currencies } from 'currency-formatter';
import { toast } from 'react-toastify';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import Input from '../../../_common/Form/Input';
import Select from '../../../_common/Form/Select';
import Checkbox from '../../../_common/Form/Checkbox';
import Utils from '../../../../helpers/Utils';
import Switcher from '../../../_common/Form/Switcher';
import Button from '../../../_common/Form/Button';

const fields = [
  {
    path: 'name',
    label: 'Name',
    isSelectable: true,
  },
  {
    path: 'type',
    label: 'Type',
    type: 'select',
    options: [{ value: 'sale', label: 'Sale' }, { value: 'buy', label: 'Buy' }],
  },
  {
    label: 'Company',
    path: 'companyId',
    type: 'select',
    options: [],
    labelPath: 'name',
    valuePath: 'id',
  },
  {
    label: 'Currency',
    path: 'currencyCode',
    type: 'select',
    options: currencies,
    valuePath: 'code',
    labelPath: 'code',
  },
];

function EditPrice() {
  const navigate = useNavigate();
  const isDefault = useRef(false);
  const { priceId } = useParams();

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [companies, setCompanies] = useState([]);
  const [priceData, setPriceData] = useState({
    name: '',
    type: '',
    currencyCode: '',
    companyId: '',
    isDefault: false,
    // discount: '',
    // increase: '',
    // roundUp: false,
  });

  useEffect(() => {
    (async () => {
      const [singlePrice, company] = await Promise.all([
        Api.getPriceListSingle(priceId), Api.getCompanies(),
      ]);

      const singleData = { ...singlePrice.data.priceList };

      if (singleData.isDefault) {
        isDefault.current = true;
      }

      setCompanies(company.data.companies);
      setPriceData({
        name: singleData.name,
        type: singleData.type,
        currencyCode: singleData.currencyCode,
        companyId: singleData.companyId,
        isDefault: singleData.isDefault,
      });
      setLoading(false);
    })();
  }, []);

  const onDataChange = useCallback((path, value) => {
    setPriceData((prev) => ({
      ...prev,
      [path]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, []);

  const savePrice = useCallback(async (e) => {
    e.preventDefault();
    let hasError = false;

    fields.forEach(({ path }) => {
      if (!priceData?.[path]?.toString().trim()) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));
        hasError = true;
      }
    });

    if (!hasError) {
      setBtnLoading(true);

      try {
        await Api.updatePrice({ ...priceData, priceId });

        toast.success('Successfully updated');
        navigate('/inventory/price-lists');
      } catch (err) {
        toast.error(err.response.data.message);
      }

      setBtnLoading(false);
    }
  }, [priceData]);

  return (
    <div>
      {loading ? <Loader /> : (
        <div className="inventory_price_list_edit">
          <div className="inventory_price_list_edit_top">
            {fields.map(({
              path, label, type, options, valuePath, labelPath,
            }) => {
              if (type === 'select') {
                return (
                  <div className="inventory_price_list_input">
                    <Select
                      options={path === 'companyId' ? companies : options}
                      onChange={(value) => onDataChange(path, value)}
                      value={priceData[path]}
                      valuePath={valuePath}
                      labelPath={labelPath}
                      label={label}
                      size="small"
                      roundBorder
                      error={errors[path]}
                    />
                  </div>
                );
              }

              return (
                <div className="inventory_price_list_input">
                  <Input
                    onChangeText={(value) => onDataChange(path, value)}
                    value={priceData[path]}
                    label={label}
                    size="small"
                    roundBorder
                    error={errors[path]}
                  />
                </div>
              );
            })}

            {!isDefault.current && (
            <Switcher
              onChange={(checked) => onDataChange('isDefault', checked)}
              checked={priceData.isDefault}
              label="Is default price"
              className="inventory_price_list_switcher"
            />
            )}
          </div>

          <div className="inventory_price_list_items">
            <h3 className="inventory_price_list_items_title">Price list items changes</h3>

            <div className="inventory_price_list_items_block">
              <div className="inventory_price_list_input">
                <Input
                  onChangeText={(value) => onDataChange('discount', value)}
                  onBeforeInput={(e) => Utils.typingNumber(e, true, 2, 99)}
                  value={priceData.discount}
                  label="Discount %"
                  size="small"
                  roundBorder
                  error={errors.discount}
                />
              </div>

              <div className="inventory_price_list_input">
                <Input
                  onChangeText={(value) => onDataChange('increase', value)}
                  onBeforeInput={(e) => Utils.typingNumber(e, true, 2, 99)}
                  value={priceData.increase}
                  label="Increase %"
                  size="small"
                  roundBorder
                  error={errors.increase}
                />
              </div>

              <Checkbox
                label="Round up"
                onChange={(checked) => onDataChange('roundUp', checked)}
                checked={priceData.roundUp}
              />
            </div>
          </div>

          <Button
            onClick={savePrice}
            title="Save"
            className="inventory_price_list_btn"
            size="small"
            roundBorder
            loading={btnLoading}
          />
        </div>
      )}
    </div>
  );
}

export default EditPrice;
