import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { findCurrency } from 'currency-formatter';
import {
  generatePaymentNumberRequest, getSingleOrderRequest,
  recordPaymentRequest,
} from '../../../../store/actions/orders';
import Input from '../../../_common/Form/Input';
import Datepicker from '../../../_common/Form/Datepicker';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';
import { getSettingsRequest } from '../../../../store/actions/settings';
import {
  getOrderInvoicesRequest,
  getOrdersInvoicesRequest,
  getSingleInvoiceRequest,
} from '../../../../store/actions/invoices';

function RecordPaymentForm() {
  const [form, setForm] = useState({
    sourceCreatedAt: moment().format('YYYY-MM-DD'),
    note: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const order = useSelector((state) => state.orders.order);
  const invoices = useSelector((state) => state.invoices.orderInvoices);
  const allInvoices = useSelector((state) => state.invoices.allOrdersInvoices);
  const paymentMethodsArr = useSelector((state) => state.settings.generalList.paymentMethods);

  useEffect(() => {
    (async () => {
      const { payload: { paymentMethods } } = await dispatch(getSettingsRequest(['paymentMethods']));
      handleChange('paymentTypeId', paymentMethods.find((p) => p.isDefault)?.id);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { payload: { data } } = await dispatch(generatePaymentNumberRequest());
      handleChange('number', data.number);
    })();
  }, []);

  useEffect(() => {
    const invoice = [...allInvoices, ...invoices].find((i) => i.id === location.state.payment);
    let amount = 0;
    if (invoice) {
      amount = invoice.totalPrice - _.sumBy(invoice.payments?.filter((p) => p.status !== 'canceled'), 'amount');
    }
    handleChange('amount', amount > 0 ? amount : 0);
  }, [location.state.payment, invoices, allInvoices]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const p = paymentMethodsArr.find((o) => o.id === form.paymentTypeId);
    const d = {
      sourceCreatedAt: form.sourceCreatedAt,
      amount: form.amount,
      paymentType: {
        id: p.id,
        title: p.title,
      },
      id: location.state.payment,
    };
    const { payload: { data } } = await dispatch(recordPaymentRequest(d));
    if (data.status === 'error') {
      setErrors(data.errors || {});
    } else {
      await dispatch(getOrderInvoicesRequest(data.payment.orderId));
      await dispatch(getSingleInvoiceRequest(data.payment.orderId));
      await dispatch(getOrdersInvoicesRequest(
        {
          ...Object.fromEntries(searchParams),
          includes: 'orderInvoiceProducts,payments',
          includeIds: location.state.payment,
        },
      ));
      await dispatch(getSingleOrderRequest(data.payment.orderId));
      toast.success('Payment successfully recorded.');
      navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
    }
    setLoading(false);
  }, [form, location.state, paymentMethodsArr]);

  return (
    <div className="record_payment_form package_form">
      <form onSubmit={handleSubmit}>
        <div className="top">
          <p className="title">New payment</p>
          <div className="actions">
            <Button
              btnType="cancel"
              roundBorder
              onClick={() => navigate(`${location.pathname}${location.search}`, {
                state: {},
                replace: true,
              })}
            >
              Cancel
            </Button>
            <Button
              data-scope="WRITE_ORDER_INVOICES"
              roundBorder
              type="submit"
              loading={loading}
              disabled={!form.paymentTypeId}
            >
              Register payment
            </Button>
          </div>
        </div>
        <div className="row big">
          <Input
            label="Number"
            roundBorder
            value={form.number}
            onChange={(ev) => handleChange('number', ev.target.value)}
          />
          <Datepicker
            label="Date"
            onChange={(date) => handleChange('sourceCreatedAt', moment(date).format('YYYY-MM-DD'))}
            value={form.sourceCreatedAt ? moment(form.sourceCreatedAt).toDate() : undefined}
          />
          <Select
            label="Payment type"
            options={paymentMethodsArr}
            roundBorder
            valuePath="id"
            labelPath="title"
            onChange={(val) => handleChange('paymentTypeId', val)}
            value={form.paymentTypeId}
            error={errors.paymentType}
          />
        </div>
        <div className="row">
          <div className="small">
            <Input
              label={`Amount ${order.type === 'sale' ? 'Received' : 'Paid'}`}
              roundBorder
              value={form.amount}
              onChange={(ev) => handleChange('amount', ev.target.value)}
              symbol={findCurrency(order?.currencyCode)?.symbol}
              error={errors.amount}
              type="number"
            />
          </div>
        </div>

        <div className="note">
          <Input
            textarea
            roundBorder
            label="Notes"
            value={form.note}
            onChange={(ev) => handleChange('note', ev.target.value)}
          />
        </div>
      </form>
    </div>
  );
}

export default RecordPaymentForm;
