import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import Input from '../../../_common/Form/Input';
import Switcher from '../../../_common/Form/Switcher';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';
import Chip from '../../../_common/Chip/Chip';
import Loader from '../../../_common/Loader/Loader';
import Modal from '../../../_common/Modal/Modal';
import Checkbox from '../../../_common/Form/Checkbox';
import Api from '../../../../Api';
import Utils from '../../../../helpers/Utils';
import DeleteModal from '../../../_common/Modal/DeleteModal';

const countOptions = [{ value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' }, { value: '5', label: '5' }, { value: '6', label: '6' }, { value: '7', label: '7' }, { value: '8', label: '8' }, { value: '9', label: '9' }, { value: '10', label: '10' }, { value: '11', label: '11' }, { value: '12', label: '12' }];
const boolOptions = [{ value: '0', label: 'NO' }, { value: '1', label: 'YES' }];
const intervalOptions = [{ value: 'week', label: 'Week' }, { value: 'month', label: 'Month' }, { value: 'year', label: 'Year' }];

const count = [
  {
    path: 'INTEGRATION_COUNT',
    label: 'Integration count',
  },
  {
    path: 'AUTOMATION_COUNT',
    label: 'Automation count',
  },
  {
    path: 'ORDER_COUNT',
    label: 'Order count',
  },
  {
    path: 'SHIPPING_LABELS_COUNT',
    label: 'Shipping labels count',
  },
  {
    path: 'USERS_COUNT',
    label: 'CrmUsers count',
  },
  {
    path: 'WAREHOUSE_COUNT',
    label: 'Warehouse count',
  },
];

const limits = [
  {
    path: 'LIMIT_ON_BUNDLES',
    label: 'Limit on bundles',
    options: boolOptions,
  },
  {
    path: 'LIMIT_ON_FBA',
    label: 'Limit on FBA',
    options: boolOptions,
  },
  {
    path: 'LIMIT_ON_ADVANCED_USER_PERMISSIONS',
    label: 'Limit on advanced user permission',
    options: boolOptions,
  },
  {
    path: 'LIMIT_ON_API_ACCESS',
    label: 'Limit on API access',
    options: boolOptions,
  },
  {
    path: 'ALLOW_B2B_DOMAIN',
    label: 'Allow B2B domain',
    options: boolOptions,
  },
  {
    path: 'B2B',
    label: 'B2B',
    options: boolOptions,
  },
  {
    path: 'INVENTORY_MANAGEMENT',
    label: 'Inventory management',
    options: boolOptions,
  },
];

const defaultPriceData = {
  price: '',
  interval: '',
  intervalCount: '',
  createdId: 'id_0',
  active: true,
};

function CreatePlan() {
  const { planId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [appsModal, setAppsModal] = useState(false);
  const [deletingPriceId, setDeletingPriceId] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [chipsData, setChipsData] = useState([]);
  const [priceData, setPriceData] = useState([defaultPriceData]);
  const [errors, setErrors] = useState({});
  const [planData, setPlanData] = useState({
    name: '',
    publicName: '',
    scopes: {},
    status: 'active',
    assignedUserId: null,
  });

  useEffect(() => {
    (async () => {
      const scopes = await Api.getPlanScopes();
      const allChips = [];

      for (const key in scopes.data.integrationScopes) {
        allChips.push({
          path: key,
          label: _.startCase(key.toLowerCase()),
        });
      }

      setChipsData(allChips);

      if (planId !== 'add') {
        try {
          const { data } = await Api.getSinglePlan(planId);

          const plan = data.plans[0];

          setPlanData({
            name: plan.name,
            publicName: plan.publicName,
            scopes: plan.scopes,
            status: plan.status,
            assignedUserId: plan?.assignedUserId || null,
          });

          setPriceData(plan.prices);

          const chips = [];

          allChips.forEach(({ path }) => {
            if (plan.scopes[path]) {
              chips.push(path);
            }
          });

          setSelectedChips(chips);
        } catch (e) {
          navigate('/404');
        }
      } else {
        setPlanData((prev) => {
          const defaultScopes = {};

          limits.forEach(({ path }) => {
            defaultScopes[path] = 0;
          });

          return {
            ...prev,
            scopes: defaultScopes,
          };
        });
      }

      setLoading(false);
    })();
  }, []);

  const onDataChange = useCallback((path, value) => {
    setPlanData((prev) => {
      if (path.includes('_') || path === 'B2B') {
        return {
          ...prev,
          scopes: {
            ...prev.scopes,
            [path]: value,
          },
        };
      }

      return {
        ...prev,
        [path]: value,
      };
    });

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, [planData, priceData]);

  const onChipsChange = useCallback((path) => {
    setSelectedChips((prev) => (prev.includes(path) ? prev.filter((c) => c !== path) : [...prev, path]));
  }, []);

  const onCountsChange = useCallback((path, value) => {
    setPlanData((prev) => ({
      ...prev,
      scopes: {
        ...prev.scopes,
        [path]: value,
      },
    }));

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, []);

  const onPriceChange = useCallback((path, value, id) => {
    setPriceData((prev) => prev.map((p) => {
      if (p.createdId === id) {
        const newData = { ...p, [path]: value };

        if (path === 'interval' && value === 'year') {
          newData.intervalCount = '';
        }

        return newData;
      }

      return p;
    }));

    setErrors((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [path]: '',
      },
    }));
  }, []);

  const onAddPriceClick = () => {
    setPriceData((prev) => [...prev, { ...defaultPriceData, createdId: _.uniqueId('id_') }]);
  };

  const onDeletePriceClick = useCallback(async (priceId, createdId) => {
    if (priceId) {
      setDeletingPriceId(priceId);
    } else {
      setErrors((prev) => {
        delete prev[createdId];

        return prev;
      });

      setPriceData((prev) => prev.filter((p) => p.createdId !== createdId));
    }
  }, []);

  const onArchivePrice = async () => {
    setDeleteLoading(true);

    await Api.archivePlanPrice(deletingPriceId);
    setPriceData((prev) => prev.filter((p) => p?.id !== deletingPriceId));

    setDeleteLoading(false);
    setDeletingPriceId('');
  };

  const savePlan = useCallback(async () => {
    setBtnLoading(true);
    let hasError = false;

    if (!planData.name.trim()) {
      setErrors((prev) => ({
        ...prev,
        name: 'Field is required',
      }));

      hasError = true;
    }

    if (!planData.publicName.trim()) {
      setErrors((prev) => ({
        ...prev,
        publicName: 'Field is required',
      }));

      hasError = true;
    }

    priceData.forEach((p) => {
      for (const key in p) {
        if (typeof p[key] !== 'boolean' && !p[key].toString().trim()) {
          setErrors((prev) => ({
            ...prev,
            [p.createdId]: {
              ...prev[p.createdId],
              [key]: 'Field is required',
            },
          }));

          hasError = true;
        }
      }
    });

    count.forEach(({ path }) => {
      if (!planData.scopes?.[path]?.toString()?.trim()) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));
        hasError = true;
      }
    });

    if (!selectedChips.length && !hasError) {
      toast.error('Please choose at least one shop from apps');
      hasError = true;
    }

    if (!hasError) {
      const sendingChips = {};

      selectedChips.forEach((c) => {
        sendingChips[c] = 1;
      });

      const sendingData = {
        name: planData.name,
        publicName: planData.publicName,
        status: planData.status,
        scopes: {
          ...planData.scopes,
          ...sendingChips,
        },
      };

      if (planId === 'add') {
        sendingData.assignedUserId = null;
      } else {
        sendingData.productId = planId;
      }

      try {
        if (planId === 'add') {
          const { data } = await Api.createPlan(sendingData);

          const createPrice = [];

          priceData.forEach((p) => {
            createPrice.push(Api.createPlanPrice({
              productId: data.plan.productId,
              amount: p.price,
              currency: 'USD',
              interval: p.interval,
              intervalCount: p.intervalCount,
            }));
          });

          await Promise.all(createPrice);
          toast.success('Successfully created');
          navigate('/settings/plans-billings/plans');
        } else {
          const newPrices = [];

          priceData.forEach((p) => {
            if (!p.id) {
              newPrices.push(Api.createPlanPrice({
                productId: planId,
                amount: p.price,
                currency: 'USD',
                interval: p.interval,
                intervalCount: p.intervalCount,
              }));
            }
          });

          if (newPrices.length) await Promise.all(newPrices);

          await Api.updatePlan(sendingData);
          toast.success('Successfully updated');
          navigate('/settings/plans-billings/plans');
        }
      } catch (e) {
        toast.error(e.response.data.message);
      }
    }

    setBtnLoading(false);
  }, [planData, priceData, chipsData, selectedChips, errors]);

  return (
    <Wrapper
      title={planId === 'add' ? 'Create subscription plan' : 'Edit subscription plan'}
      onBtnClick={savePlan}
      buttonTitle={planId === 'add' ? 'Create plan' : 'Save plan'}
      cancelButtonHref="/settings/plans-billings/plans"
      btnLoading={btnLoading}
      className="new_role"
    >
      {loading ? <Loader /> : (
        <div className="create_plan_wrapper">
          <div className="create_plan_name_block">
            <div className="create_plan_name_input">
              <Input
                value={planData.name}
                onChangeText={(val) => onDataChange('name', val)}
                label="Plan name"
                size="small"
                error={errors.name}
                roundBorder
              />
            </div>

            <div className="create_plan_name_input">
              <Input
                value={planData.publicName}
                onChangeText={(val) => onDataChange('publicName', val)}
                label="Public plan name"
                size="small"
                error={errors.publicName}
                roundBorder
              />
            </div>

            <div className="create_plan_name_switcher">
              <Switcher
                onChange={(checked) => onDataChange('status', checked)}
                checked={planData.status}
                label="Status"
              />
            </div>
          </div>

          <div className="create_plan_price_block_wrapper">
            {priceData.map((p, index) => (
              <div className="create_plan_price_block" key={p.createdId || p.id}>
                <div className="create_plan_price_single">
                  <Input
                    value={p.price}
                    onBeforeInput={Utils.typingFloatNumber}
                    onChangeText={(value) => onPriceChange('price', value, p.createdId)}
                    label="Price"
                    size="small"
                    roundBorder
                    error={errors?.[p.createdId]?.price || errors?.[p.id]?.price}
                    key={`price${index}`}
                    symbol="$"
                    disabled={!!p.id}
                  />
                </div>

                <div className="create_plan_price_single">
                  <Select
                    options={intervalOptions}
                    value={p.interval}
                    onChange={(value) => onPriceChange('interval', value, p.createdId)}
                    label="Interval"
                    size="small"
                    error={errors?.[p.createdId]?.interval || errors?.[p.id]?.interval}
                    roundBorder
                    key={`interval${index}`}
                    isDisabled={!!p.id}
                  />
                </div>

                <div className="create_plan_price_single">
                  <Select
                    options={priceData[index].interval === 'year' ? countOptions.slice(0, 3) : countOptions}
                    value={`${p.intervalCount}`}
                    onChange={(value) => onPriceChange('intervalCount', value, p.createdId)}
                    label="Interval count"
                    size="small"
                    error={errors?.[p.createdId]?.intervalCount || errors?.[p.id]?.intervalCount}
                    roundBorder
                    key={`intervalCount${index}`}
                    isDisabled={!!p.id}
                  />
                </div>

                {index !== 0 && p.active && (
                  <Button
                    onClick={() => onDeletePriceClick(p.id, p.createdId)}
                    className="create_plan_price_delete_btn"
                    btnType="transparent"
                    color="#EB0000"
                    size="small"
                    roundBorder
                    title="Archive price"
                  />
                )}

                {p.active === false && <p className="create_plan_price_archived_text">ARCHIVED</p>}
              </div>
            ))}

            <Button
              className="create_plan_price_add_btn"
              btnType="transparent"
              color="#1472FF"
              size="small"
              roundBorder
              title="Add price"
              onClick={onAddPriceClick}
              addBtn
            />
          </div>

          <div className="create_plan_count_block_wrapper">
            <div className="create_plan_count_block">
              {count.map(({ path, label }) => (
                <div className="create_plan_count_single_block" key={path}>
                  <Input
                    value={planData.scopes[path]}
                    onChangeText={(val) => onCountsChange(path, val)}
                    label={label}
                    size="small"
                    roundBorder
                    error={errors[path]}
                    onBeforeInput={(e) => Utils.typingNumber(e, true)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="create_plan_limit_block_wrapper">
            <div className="create_plan_limit_block">
              {limits.map(({ path, label, options }) => (
                <div className="create_plan_limit_single_block" key={path}>
                  <Select
                    options={options}
                    onChange={(value) => onDataChange(path, value)}
                    value={planData.scopes?.[path]?.toString()}
                    label={label}
                    size="small"
                    roundBorder
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="create_plan_limit_assign_wrapper">
            <div className="create_plan_limit_assign_select">
              <Select
                onChange={() => {
                }}
                label="Assign to (optional)"
                size="small"
                roundBorder
              />
            </div>
          </div>

          <div className="create_plan_apps_wrapper">
            <div className="create_plan_apps_titles_block">
              <p className="create_plan_apps_title">Available apps</p>

              <Button btnType="transparent" color="#1472FF" onClick={() => setAppsModal(true)}>
                See all apps
              </Button>
            </div>

            <div className="create_plan_selected_wrapper">
              <p className="create_plan_selected_text">Selected:</p>

              <div className="create_plan_selected_chips">
                {selectedChips.map((text) => (
                  <Chip
                    onDelete={() => setSelectedChips((prev) => prev.filter((c) => c !== text))}
                    text={_.startCase(text.toLowerCase())}
                    key={text}
                  />
                ))}
              </div>
            </div>
          </div>

          <Modal
            isOpen={appsModal}
            onClose={() => setAppsModal(false)}
            wrapperClassName="create_plan_apps_modal_wrapper"
          >
            <h3>Select Apps</h3>

            <div className="create_plan_apps_modal_checkbox_block">
              {chipsData.map(({ path, label }) => (
                <Checkbox
                  onChange={(checked) => onChipsChange(path, checked)}
                  label={label}
                  className="create_plan_apps_modal_checkbox"
                  checked={selectedChips.includes(path)}
                  key={path}
                />
              ))}
            </div>
          </Modal>

          <DeleteModal
            isOpen={!!deletingPriceId}
            onDelete={onArchivePrice}
            loading={deleteLoading}
            onClose={() => setDeletingPriceId('')}
            text="price"
          />
        </div>
      )}
    </Wrapper>
  );
}

export default CreatePlan;
