import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Wrapper from '../../Layout/Wrapper';
import Api from '../../../Api';
import Loader from '../../_common/Loader/Loader';
import AppCard from '../Apps/AppList/AppCard';
import DeleteModal from '../../_common/Modal/DeleteModal';
import Utils from '../../../helpers/Utils';
import Button from '../../_common/Form/Button';
import { ReactComponent as NotFoundIcon } from '../../../assets/icons/notFound/apps_not_found.svg';


function MyApps() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [modalInfo, setModalInfo] = useState({});

  useEffect(() => {
    (async () => {
      const { data } = await Api.getIntegrations();

      setIntegrations(data.integrations.map((integration) => {
        if (integration.type === 'ftp') integration.settings.type = `ftp ${integration.settings.type}`;

        return integration;
      }));

      setLoading(false);
    })();
  }, []);

  const deleteIntegration = useCallback(async () => {
    setDeleteLoading(true);

    if (modalInfo.category === 'other') {
      await Api.uninstallOtherIntegration(modalInfo.id);
    } else if (modalInfo.category === 'shipping') {
      await Api.uninstallShippingIntegration(modalInfo.id);
    } else if (modalInfo.category === 'accounting') {
      await Api.deleteAccounting(modalInfo.type, modalInfo.id);
    } else {
      await Api.uninstallIntegration(modalInfo.id);
    }

    setIntegrations((prev) => prev.filter((i) => i.id !== modalInfo.id));

    toast.success('App successfully deleted');
    setModalInfo({});
    setDeleteLoading(false);
  }, [modalInfo]);

  const goToApp = (app) => {
    if (app.settings?.type !== 'gmail' && app.type !== 'stripe') {
      navigate(`${app.id}/dashboard`);
    }
  };

  return (
    <Wrapper title="My apps">
      {loading
        ? <Loader />

        : (
          <div className="my_apps">
            {integrations.length
              ? integrations.map((app) => {
                const icon = Utils.getAppIcon(app);

                return (
                  <div
                    role="button"
                    tabIndex="0"
                    className={classNames('my_app_single', {
                      disabled: app.settings?.type === 'gmail' || app.type === 'stripe',
                    })}
                    key={app.id}
                    onClick={() => goToApp(app)}
                  >
                    <AppCard
                      key={app.link}
                      name={app.name}
                      icon={icon}
                      text={Utils.getAppName(app)}
                      link={app.link}
                      type="card"
                      onDelete={(e) => {
                        e.stopPropagation();
                        setModalInfo(app);
                      }}
                    />
                  </div>
                );
              })

              : (
                <div className="data_not_found">
                  <NotFoundIcon />

                  <h1>No installed apps</h1>
                  <Button
                    className="global_btn_save"
                    size="small"
                    title="Go to apps"
                    href="/apps/list/all"
                    roundBorder
                    data-scope="WRITE_INTEGRATIONS"
                  />
                </div>
              )}

            <DeleteModal
              isOpen={!_.isEmpty(modalInfo)}
              text="Intagration"
              onDelete={deleteIntegration}
              deleteType="uninstall"
              onClose={() => setModalInfo({})}
              loading={deleteLoading}
            />
          </div>
        )}
    </Wrapper>
  );
}

export default MyApps;
