import React, { forwardRef, useId } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import Loader from '../Loader/Loader';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import { ReactComponent as ImportIcon } from '../../../assets/icons/import.svg';

const Button = forwardRef(({
  id, className, title, children, iconLeft, iconRight, href, type, disabled, btnType, color, size,
  reverseColor, roundBorder, addBtn, onClick, loading, tooltip, tooltipPlace, fileAccept, onFileUpload,
  multipleUpload, loaderSize, ...p
}, ref) => {
  const uniqId = useId();

  const Component = btnType === 'upload' ? 'label' : href ? NavLink : 'button';

  return (
    <>
      {onFileUpload && (
        <input
          type="file"
          id={id || uniqId}
          className="button_upload_file_input"
          onChange={onFileUpload}
          accept={fileAccept}
          multiple={multipleUpload}
        />
      )}

      <Component
        ref={ref}
        type={href || btnType === 'upload' ? undefined : type}
        to={href || undefined}
        htmlFor={btnType === 'upload' ? (id || uniqId) : undefined}
        data-tooltip-id={tooltip ? 'default-tooltip' : undefined}
        data-tooltip-content={tooltip}
        data-tooltip-place={tooltip ? tooltipPlace : undefined}
        style={{
          '--button-color': color,
        }}
        className={classNames('button', className, {
          reverse_color: reverseColor,
          round_border: roundBorder,
          btn_color: color,
          [`btn_${btnType}`]: !!btnType,
          [`size_${size}`]: !!size,
        })}
        onClick={onClick}
        disabled={disabled || loading}
        {...p}
      >
        {loading
          ? (
            <Loader
              className="btn_loading"
              size={loaderSize}
            />
          )

          : (
            <>
              {(addBtn || btnType === 'upload' || btnType === 'import' || iconLeft)
                && (
                  <span className="icon_left">
                    {addBtn
                      ? <PlusIcon />

                      : btnType === 'upload'
                        ? <UploadIcon />

                        : btnType === 'import'
                          ? <ImportIcon />

                          : iconLeft}
                  </span>
                )}

              {(title || children) && <span className="text">{title || children}</span>}

              {iconRight && <span className="icon_right">{iconRight}</span>}
            </>
          )}
      </Component>
    </>
  );
});

Button.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  reverseColor: PropTypes.bool,
  roundBorder: PropTypes.bool,
  addBtn: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onFileUpload: PropTypes.func,
  loaderSize: PropTypes.number,
  type: PropTypes.oneOf(['button', 'submit']),
  btnType: PropTypes.oneOf(['primary', 'gray', 'red', 'light_blue', 'transparent', 'cancel', 'upload',
    'import', 'orange']),
  size: PropTypes.oneOf(['small', 'default']),
  tooltip: PropTypes.string,
  tooltipPlace: PropTypes.oneOf(['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom',
    'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end']),
};

Button.defaultProps = {
  id: '',
  className: '',
  color: '',
  title: '',
  type: 'button',
  href: '',
  btnType: 'primary',
  size: 'default',
  children: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  reverseColor: false,
  roundBorder: false,
  loading: false,
  disabled: false,
  addBtn: false,
  onClick: () => {
  },
  onFileUpload: undefined,
  tooltip: undefined,
  tooltipPlace: 'top',
  loaderSize: 20,
};

export default Button;
