import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { currencies } from 'currency-formatter';
import {
  NumberParam, StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import Loader from '../../../_common/Loader/Loader';
import Table from '../../../_common/Tables/Table';
import Api from '../../../../Api';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Pagination from '../../../_common/Pagination/Pagination';
import TitleBar from '../../../Layout/TitleBar';
import Button from '../../../_common/Form/Button';

function PriceLists() {
  const navigate = useNavigate();
  const firstLoad = useRef(true);

  const [page, setPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 1),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    (async () => {
      setTableLoading(true);

      if (firstLoad.current) {
        const company = await Api.getCompanies();

        setCompanies(company.data.companies);
      }

      await getPriceLists(page, sorting.sort, sorting.sortBy);

      setTableLoading(false);
      firstLoad.current = false;
    })();
  }, [page, sorting.sort, sorting.sortBy]);

  const getPriceLists = useCallback(async (newPage, sort, sortBy) => {
    const { data } = await Api.getPriceList({
      page: newPage,
      sort,
      sortBy,
      includes: 'company,productsCount',
    });

    setList(data.priceLists.map((p) => ({ ...p, company: p.company?.name })));
    setTotalPages(data.totalPages);
    setDeleteLoading(false);
  }, [page, sorting.sort, sorting.sortBy]);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, [sorting]);

  const onDeletePrice = useCallback(async (id) => {
    setDeleteLoading(true);

    try {
      await Api.deletePrice(id);

      console.log('aaaaaaaaaaa');
      if (totalPages !== 1 && list.length === 1) {
        setPage((prev) => prev - 1);
      } else {
        await getPriceLists(page, sorting.sort, sorting.sortBy);
      }

      toast.success('Price has been successfully deleted');
    } catch (e) {
      toast.error(e.response.data.message);

      setDeleteLoading(false);
    }
  }, [list, page, sorting]);

  const onCreatePrice = useCallback(async (newData) => {
    const creatingData = { ...newData };
    creatingData.companyId = creatingData.company;
    creatingData.isDefault = !!newData?.isDefault;
    delete creatingData.company;

    await Api.createPrice(creatingData);

    const { data } = await Api.getPriceList({
      page: page || 1,
      sort: sorting.sort,
      sortBy: sorting.sortBy,
      includes: 'company,productsCount',
    });

    setList(data.priceLists.map((p) => ({ ...p, company: p.company.name })));
    setTotalPages(data.totalPages);
    setCreateModal(false);
  }, []);

  const tableHeader = [
    {
      title: 'Name',
      path: 'name',
      required: true,
      isSelectable: true,
      scope: 'WRITE_PRICE_LISTS',
      navigate: (id) => navigate(`/inventory/price-lists/${id}/edit`),
    },
    {
      title: 'Type',
      path: 'type',
      type: 'select',
      required: true,
      options: [{ value: 'sale', label: 'Sale' }, { value: 'buy', label: 'Buy' }],
    },
    {
      title: 'Company',
      path: 'company',
      type: 'select',
      options: [],
      labelPath: 'name',
      valuePath: 'id',
      required: true,
      menuPortalTarget: true,
    },
    {
      title: 'Count',
      path: 'productsCount',
    },
    {
      title: 'Currency',
      path: 'currencyCode',
      type: 'select',
      options: currencies,
      required: true,
      valuePath: 'code',
      labelPath: 'code',
      menuPortalTarget: true,
      isSearchable: true,
    },
  ];

  const modalFields = useMemo(
    () => {
      const newHeader = tableHeader.filter((val, index) => index !== 3);

      return newHeader.map((o) => (o.path === 'company' ? { ...o, options: companies } : o));
    },
    [companies],
  );

  return (
    <Wrapper hideBackBtn>
      <TitleBar title="Price lists">
        <Button
          title="Add new"
          addBtn
          data-scope="WRITE_PRICE_LISTS"
          onClick={() => setCreateModal(true)}
        />
      </TitleBar>
      {firstLoad.current
        ? <Loader />

        : (
          <div className="inventory_price_list_wrapper">
            <div className="price_list_table_wrapper">
              <Table
                data={list}
                header={tableHeader}
                deleteModalText="price"
                onDeleteClick={onDeletePrice}
                deleteLoading={deleteLoading}
                onEditClick={(id) => {
                  navigate(`/inventory/price-lists/${id}/edit`);
                }}
                loading={tableLoading}
                onSortBy={onSortBy}
                sortBy={sorting.sortBy}
                sort={sorting.sort}
                editScope="WRITE_PRICE_LISTS"
                deleteScope="DELETE_PRICE_LISTS"
              />
            </div>

            <Pagination onChangePage={(p) => setPage(p)} totalPages={totalPages} />

            <UpdateModal
              fields={modalFields}
              isOpen={createModal}
              onSave={onCreatePrice}
              text="price"
              onClose={() => setCreateModal(false)}
              withDefaultSwitcher
            />
          </div>
        )}
    </Wrapper>
  );
}

export default PriceLists;
