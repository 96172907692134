import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow_sort.svg';
import { ReactComponent as InvoicedIcon } from '../../../../assets/icons/invoiced.svg';
import { ReactComponent as FulfilledIcon } from '../../../../assets/icons/fulfilled.svg';
import { ReactComponent as CompletedIcon } from '../../../../assets/icons/completed.svg';
import { ReactComponent as AssignedToIcon } from '../../../../assets/icons/assigned_to.svg';
import { ReactComponent as DragIcon } from '../../../../assets/icons/dashboard_drag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/dashboard_close.svg';
import Loader from '../../../_common/Loader/Loader';
import Account from '../../../../helpers/Account';

const DashboardStatusCounts = ({
  data, disable, innerRef, onDelete, loading,
}) => {
  const navigate = useNavigate();

  const dashboardOrdersTypes = useMemo(() => [
    {
      path: 'toBeFulfilled',
      title: 'To be Shipped',
      link: '/orders/sales-order?status=packaged',
      icon: <FulfilledIcon />,
      bgColor: 'linear-gradient(90deg, #F39034 2.03%, #FF2727 99.73%)',
      statusField: 'fulfilled',
    },
    {
      path: 'toBeInvoiced',
      title: 'To be Invoiced',
      link: '/orders/sales-order?_status=no_invoice',
      icon: <InvoicedIcon />,
      bgColor: 'linear-gradient(270deg, #003AD2 0%, #0097EC 100%)',
      statusField: 'invoiced',
    },
    {
      path: 'assignedToMe',
      title: 'Assigned to me',
      link: `/orders/sales-order?assignedUserId=${Account.get().id}`,
      icon: <AssignedToIcon />,
      bgColor: 'linear-gradient(270deg, #5900C9 0.02%, #9852F0 99.98%)',
      statusField: 'assigned',
    },
    {
      path: 'completed',
      title: 'Delivered',
      link: '/orders/sales-order?status=delivered&isArchive=true',
      icon: <CompletedIcon />,
      bgColor: 'linear-gradient(270deg, #00A843 0.19%, #1FD071 99.8%)',
      statusField: 'completed',
    },
  ], []);

  const statusCount = useSelector((state) => state.dashboard.statusCount);

  const ordersTypes = dashboardOrdersTypes.find((d) => d.path === data.key);
  const currentStats = statusCount[ordersTypes.statusField];

  return (
    <div
      ref={(ref) => {
        innerRef[data?.key] = ref || innerRef[data?.key];
      }}
      className="grid-stack-item"
      gs-max-w="12"
      gs-min-w="2"
      gs-max-h="1"
      gs-min-h="1"
      gs-id={data?.key}
      gs-w={data?.value?.position?.w}
      gs-h={data?.value?.position?.h}
      gs-x={data?.value?.position?.x}
      gs-y={data?.value?.position?.y}
      style={{ display: data?.show === false ? 'none' : 'block' }}
      id={data?.key}
    >
      <div className="grid-stack-item-content">
        {/* {!isLoaded.current && <Loader />} */}

        <div className="chart_wrapper" style={{ cursor: disable ? 'grab' : 'default' }}>
          {loading ? <Loader /> : (
            <div
              className={`colored_card_wrapper ${data.key}`}
              key={ordersTypes.path}
              style={{ background: ordersTypes.bgColor }}
            >
              <p className="colored_card_title">{ordersTypes.title}</p>

              <div className="colored_card_top_icon">
                {disable ? (
                  <div className="drag_icons">
                    <DragIcon />

                    <CloseIcon onClick={onDelete} />
                  </div>
                ) : ordersTypes.icon}
              </div>

              <div className="colored_card_number">
                <p>{currentStats || 0}</p>
              </div>

              {!disable && (
                <div className="colored_card_arrow_icon">
                  <ArrowIcon onClick={() => { navigate(ordersTypes.link); }} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DashboardStatusCounts.propTypes = {
  data: PropTypes.object.isRequired,
  disable: PropTypes.bool.isRequired,
  innerRef: PropTypes.object.isRequired,
};

export default DashboardStatusCounts;
