import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useClickAway } from 'react-use';
import _ from 'lodash';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import Wrapper from '../../../../Layout/Wrapper';
import Api from '../../../../../Api';
import Input from '../../../../_common/Form/Input';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Select from '../../../../_common/Form/Select';
import Loader from '../../../../_common/Loader/Loader';
import Table from '../../../../_common/Tables/Table';
import Pagination from '../../../../_common/Pagination/Pagination';
import ScheduleModal from '../ScheduleModal';
import Button from '../../../../_common/Form/Button';
import { ReactComponent as FiltersIcon } from '../../../../../assets/icons/filters.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../../../../assets/icons/export.svg';
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/remote_printing.svg';

const defaultFiltersParams = withDefault(ArrayParam, []);
const tableHeader = [
  {
    title: 'Product',
    path: 'productName',
  },
  {
    title: 'SKU',
    path: 'sku',
  },
  {
    title: 'Stock',
    path: 'stock',
  },
  {
    title: 'No of order',
    path: 'orderNumber',
  },
  {
    title: 'Total value',
    path: 'totalValue',
  },
  {
    title: 'Total tax',
    path: 'totalTax',
  },
];

const inputOptions = [
  {
    label: 'Is',
    value: 'is',
  },
  {
    label: 'Not',
    value: 'not',
  },
  {
    label: 'Starts With',
    value: 'startsWith',
  },
  {
    label: 'Ends With',
    value: 'endsWith',
  },
  {
    label: 'Is Empty',
    value: 'isEmpty',
  },
  {
    label: 'Is Not Empty',
    value: 'isNotEmpty',
  },
  // {
  //   label: 'In',
  //   value: 'in',
  // },
  // {
  //   label: 'Not In',
  //   value: 'notIn',
  // },
];

const selectOptions = [
  {
    label: 'Is',
    value: 'is',
  },
  {
    label: 'Not',
    value: 'not',
  },
  {
    label: 'Is Empty',
    value: 'isEmpty',
  },
  {
    label: 'Is Not Empty',
    value: 'isNotEmpty',
  },
  {
    label: 'In',
    value: 'in',
  },
  {
    label: 'Not In',
    value: 'notIn',
  },
];

const filterKeys = [
  { label: 'Product Name', value: 'productName' },
  { label: 'Payment Status', value: 'paymentStatus' },
  { label: 'Fulfilment status', value: 'status' },
];

const paymentStatusOptions = [
  {
    label: 'Paid',
    value: 'paid',
  },
  {
    label: 'Partial paid',
    value: 'partial_paid',
  },
  {
    label: 'Unpaid',
    value: 'unpaid',
  },
  {
    label: 'Refunded',
    value: 'refunded',
  },
  {
    label: 'Partial refunded',
    value: 'partial_refunded',
  },
  {
    label: 'Canceled',
    value: 'canceled',
  },
];

const fulfilmentStatusOptions = [
  {
    label: 'Packed',
    value: 'packed',
  },
  {
    label: 'Shipped',
    value: 'shipped',
  },
  {
    label: 'Delivered',
    value: 'delivered',
  },
  {
    label: 'Canceled',
    value: 'canceled',
  },
];

const operatorOptions = {
  productName: inputOptions.filter((opt) => (opt.value !== 'isEmpty' && opt.value !== 'isNotEmpty')),
  paymentStatus: selectOptions.filter((opt) => (opt.value === 'is' || opt.value === 'not')),
  status: selectOptions.filter((opt) => (opt.value === 'is' || opt.value === 'not')),
};

function SalesByProductReport() {
  const navigate = useNavigate();
  const firstLoad = useRef(true);
  const filterMenuRef = useRef(null);
  const searchTimeout = useRef(null);

  const inputKeys = ['productName'];
  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    // sortBy: withDefault(StringParam, 'createdAt'),
    // sort: withDefault(StringParam, 'desc'),
    warehouseId: withDefault(StringParam, ''),
    startDate: withDefault(StringParam, moment().subtract(1, 'month').format('MM-DD-YYYY')),
    endDate: withDefault(StringParam, moment().format('MM-DD-YYYY')),
    advancedFilters: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [totalPages, setTotalPages] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [scheduleIsOpen, setScheduleIsOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [filterMenu, setFilterMenu] = useState(false);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({ title: 'All warehouses', id: '' });
  const [extendedFilters, setExtendedFilters] = useState([]);
  const [extendedErrors, setExtendedErrors] = useState({});

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    (async () => {
      setTimeout(async () => {
        setTableLoading(true);

        await settingStateFromQuery();

        if (firstLoad.current) {
          const warehouses = await Api.getWarehouses();

          setWarehouseOptions(warehouses.data.warehouses);
        }

        try {
          await getSalesByProductReport({
            allQuery: queryData,
          });
        } catch (e) {
          navigate('/404');
        }

        setTableLoading(false);
        firstLoad.current = false;
      }, 500);
    })();
  }, [queryData]);

  const filterCounts = useMemo(() => (
    !!selectedWarehouse.id + (+extendedFilters.length)
  ), [extendedFilters, selectedWarehouse]);

  const settingStateFromQuery = useCallback(async () => {
    let hasError = false;

    let filters = [];

    queryData.advancedFilters.forEach((v) => {
      try {
        filters = [...filters, ...JSON.parse(v)];
      } catch (e) {
        hasError = true;
        filters = [];
      }
    });

    setExtendedFilters(() => {
      if (hasError) {
        setQuery((prev) => ({
          ...prev,
          advancedFilters: [],
        }));

        setExtendedErrors([]);
        return [];
      }

      setExtendedErrors(() => {
        let newErrors = {};

        filters.forEach((f) => {
          newErrors = {
            ...newErrors,
            [f.id]: {
              key: '',
              operator: '',
              value: '',
            },
          };
        });

        return newErrors;
      });

      return filters;
    });

    let singleWarehouse = { title: 'All warehouses', id: '' };

    if (queryData.warehouseId) {
      const foundWarehouse = warehouseOptions.find((w) => w.id === queryData.warehouseId);

      if (foundWarehouse) {
        singleWarehouse = foundWarehouse;
      } else {
        try {
          const warehouse = await Api.getWarehouse(queryData.warehouseId);

          singleWarehouse = warehouse.data.warehouse;
        } catch (e) {
          console.log(e);
        }
      }
    }

    setSelectedWarehouse(singleWarehouse);
  }, [queryData]);

  useClickAway(filterMenuRef, async () => {
    if (filterMenu) {
      setFilterMenu(false);

      await settingStateFromQuery();
    }
  });

  const getSalesByProductReport = useCallback(async ({ allQuery }) => {
    const requestData = { ...allQuery };
    let newAdvancedFilters = [];

    requestData.advancedFilters.forEach((f) => {
      const parsedFilter = JSON.parse(f);

      newAdvancedFilters = parsedFilter.map((filter) => {
        let newFilter = { ...filter };
        delete newFilter.id;

        if (Array.isArray(newFilter.value)) {
          newFilter = {
            ...newFilter,
            value: newFilter.value.map((v) => (v.id ? v.id : v)),
          };
        } else if (typeof newFilter.value === 'object') {
          newFilter = {
            ...newFilter,
            value: newFilter.value.id,
          };
        }

        return newFilter;
      });
    });

    !requestData.warehouseId ? delete requestData.warehouseId : requestData.warehouseId = [+requestData.warehouseId];

    const { data } = await Api.getSalesByProductReport({
      ...requestData,
      advancedFilters: newAdvancedFilters,
    });

    setTotalPages(data.totalPages);
    setReportData(data.products);
  }, []);

  const loadMoreWarehouses = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getWarehouses({ s: inputValue });

    callback(data.warehouses);
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setQuery((prev) => ({
      ...prev,
      sort: newSorting.sort,
      sortBy: newSorting.sortBy,
    }));
  }, [queryData.sort, queryData.sortBy]);

  const onSchedule = useCallback(() => {
    setScheduleIsOpen(true);
  }, []);

  const onAddMoreFilterClick = useCallback(() => {
    const id = _.uniqueId();

    setExtendedFilters((prev) => [...prev, {
      key: '',
      operator: '',
      id,
    }]);

    setExtendedErrors((prev) => ({
      ...prev,
      [id]: {
        key: '',
        operator: '',
      },
    }));
  }, []);

  const onDeleteFilterLine = useCallback((id) => {
    setExtendedFilters((prev) => prev.filter((filter) => filter.id !== id));
    setExtendedErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[id];

      return newErrors;
    });
  }, []);

  const onAdvancedFiltersChange = useCallback((id, path, value) => {
    setExtendedFilters((prev) => {
      let newValue;

      newValue = path === 'key' ? '' : path === 'value' ? value : prev.value;

      if (path === 'operator') {
        newValue = '';

        return prev.map((fields) => {
          if (fields.id === id) {
            const newFields = {
              ...fields,
              operator: value,
              value: '',
            };

            if (value === 'isEmpty' || value === 'isNotEmpty') {
              delete newFields.value;
            }

            if (value === 'in' || value === 'notIn') {
              newFields.value = [];
            }

            return newFields;
          }

          return fields;
        });
      }

      return prev.map((fields) => {
        const newFields = {
          ...fields,
          [path]: value,
          value: newValue,
        };

        if (path !== 'value') {
          newFields.operator = '';
        }

        if (id === fields.id) {
          if (fields.operator === 'isEmpty' || fields.operator === 'isNotEmpty') {
            delete newFields.value;
          }

          return newFields;
        }

        return fields;
      });
    });

    setExtendedErrors((prev) => {
      let allErrors = { ...extendedErrors };

      _.forEach(prev, (fields, errorId) => {
        if (path !== 'operator') {
          if (id === errorId) {
            const newError = { ...allErrors[errorId], [path]: '' };

            const foundFilter = extendedFilters.find((filter) => filter.id === id);

            if (foundFilter.value !== 'isEmpty' && foundFilter.value !== 'isNotEmpty' && foundFilter.value) {
              newError.value = '';
            }

            allErrors = {
              ...allErrors,
              [errorId]: newError,
            };
          }
        }

        if (id === errorId) {
          const newError = { ...allErrors[errorId], [path]: '' };

          if (value === 'isEmpty' || value === 'isNotEmpty') {
            delete newError.value;
          } else {
            newError.value = '';
          }

          allErrors = {
            ...allErrors,
            [errorId]: newError,
          };
        }
      });

      return allErrors;
    });
  }, [extendedErrors, extendedFilters]);

  const onExport = useCallback(async () => {
    try {
      await Api.exportSalesHistoryReport({
        by: {
          startDate: queryData.startDate,
          endDate: queryData.endDate,
          s: queryData.s,
          warehouseId: selectedWarehouse.id ? [selectedWarehouse.id] : [],
          advancedFilters: queryData.advancedFilters.length ? JSON.parse(queryData.advancedFilters) : [],
        },
        fields: ['customer', 'orderNumber', 'invoiceNumber', 'qty', 'return', 'payment', 'fulfillment', 'shippedDate',
          'status'],
        fileType: 'csv',
      });

      toast.success('Successfully exported. You will receive an email.');
    } catch (e) {
      toast.error('Something went wrong. Please try later.');
    }
  }, [queryData]);

  const onPrint = () => {
    window.print();
  };

  const onRunReport = useCallback(() => {
    let hasError = false;

    extendedFilters.forEach((filter) => {
      _.forEach(filter, (value, key) => {
        if (!value.toString().trim()) {
          hasError = true;

          setExtendedErrors((prev) => {
            const newError = { ...prev[filter.id], [key]: 'Fields is required' };

            return { ...prev, [filter.id]: newError };
          });
        }
      });
    });

    if (!hasError) {
      setQuery((prev) => ({
        ...prev,
        advancedFilters: !extendedFilters.length ? [] : JSON.stringify(extendedFilters),
        warehouseId: selectedWarehouse.id,
        page: 1,
      }));

      setFilterMenu(false);
    }
  }, [extendedFilters, extendedErrors, selectedWarehouse]);

  return (
    <Wrapper
      title="Sales by product"
      onBtnClick={onSchedule}
      buttonTitle="Schedule report"
      btnType="light_blue"
    >
      <div className="inventory_report">
        <div className="inventory_report_filters">
          <div className="inventory_report_filter_search">
            <Input
              value={queryData.s}
              placeholder="Search"
              size="small"
              roundBorder
              search
              onChangeText={(val) => setQuery((prev) => ({
                ...prev,
                s: val,
                page: 1,
              }))}
              data-test-id="filter_search"
            />
          </div>

          <div className="inventory_report_filter_right">
            <Button
              onClick={onPrint}
              size="small"
              roundBorder
              iconLeft={<PrintIcon />}
              title="Print"
              btnType="transparent"
              className="print_button"
            />

            {!!reportData.length && (
              <Button
                onClick={onExport}
                size="small"
                roundBorder
                iconLeft={<ExportIcon />}
                title="Export"
                btnType="transparent"
                className="export_button"
              />
            )}

            <RangeDatePicker
              currentDate={{
                startDate: queryData.startDate,
                endDate: queryData.endDate,
              }}
              onChangeDate={(value) => setQuery((prev) => ({
                ...prev,
                startDate: value[0],
                endDate: (value[0] > value[1] || !value[1]) ? value[0] : value[1],
                page: 1,
              }))}
              calendarClassName="inventory_report_filters_menu_calendar"
              popperPlacement="bottom-start"
            />

            <div className="inventory_report_filter_btn_wrapper" ref={filterMenuRef}>
              <Button
                title="Filters"
                size="small"
                roundBorder
                iconLeft={<FiltersIcon />}
                btnType="transparent"
                className="inventory_report_filter_btn"
                onClick={() => setFilterMenu((prev) => !prev)}
              />

              {filterMenu && (
                <div className="inventory_report_filters_menu">
                  <div className="inventory_report_filters_checkboxes">
                    <div className="inventory_report_filters_select_wrp">
                      <Select
                        defaultOptions={[{ title: 'All warehouses', id: '' }, ...warehouseOptions]}
                        onChange={(value) => setSelectedWarehouse(value)}
                        value={selectedWarehouse}
                        size="small"
                        roundBorder
                        labelPath="title"
                        valuePath="id"
                        getFullOption
                        loadOptions={(inputValue, callback) => loadMoreWarehouses(inputValue, callback)}
                        isAsync
                      />
                    </div>
                  </div>

                  <div className="inventory_report_extended_filters">
                    {extendedFilters.map(({
                      key, operator, value, id,
                    }) => (
                      <div className="extended_filter_single" key={id}>
                        <div className="extended_filter_single_select">
                          <Select
                            size="small"
                            roundBorder
                            placeholder="Select a field"
                            options={filterKeys}
                            onChange={(val) => onAdvancedFiltersChange(id, 'key', val)}
                            menuPosition="fixed"
                            value={key}
                            error={extendedErrors?.[id]?.key}
                          />
                        </div>

                        <div className="extended_filter_single_select">
                          <Select
                            size="small"
                            roundBorder
                            placeholder="Select a comporator"
                            options={operatorOptions[key] ? operatorOptions[key]
                              : inputKeys.includes(key) ? inputOptions : selectOptions}
                            value={operator}
                            menuPosition="fixed"
                            onChange={(val) => onAdvancedFiltersChange(id, 'operator', val)}
                            error={extendedErrors?.[id]?.operator}
                          />
                        </div>

                        {operator !== 'isEmpty' && operator !== 'isNotEmpty' && operator && (
                          inputKeys.includes(key) ? (
                            <div className="extended_filter_single_select_long">
                              <Input
                                size="small"
                                roundBorder
                                placeholder="Variant name"
                                disabled={operator === 'isEmpty' || operator === 'isNotEmpty' || !key}
                                value={value}
                                onChangeText={(val) => onAdvancedFiltersChange(id, 'value', val)}
                                error={extendedErrors?.[id]?.value}
                              />
                            </div>
                          ) : (
                            <div className="extended_filter_single_select_long">
                              <Select
                                value={value}
                                options={key === 'status' ? fulfilmentStatusOptions : paymentStatusOptions}
                                size="small"
                                roundBorder
                                onChange={(val) => onAdvancedFiltersChange(id, 'value', val)}
                                key={key}
                                isDisabled={operator === 'isEmpty' || operator === 'isNotEmpty' || !key}
                                menuPosition="fixed"
                                error={extendedErrors?.[id]?.value}
                                placeholder="Select status"
                                isMulti={operator === 'in' || operator === 'notIn'}
                              />
                            </div>
                          )
                        )}

                        <DeleteIcon onClick={() => onDeleteFilterLine(id)} className="delete_svg" />
                      </div>
                    ))}

                    <div className="inventory_report_filters_menu_buttons">
                      <Button
                        btnType="transparent"
                        onClick={() => onAddMoreFilterClick()}
                        className="inventory_report_filters_menu_btn"
                        title="Add more"
                        addBtn
                        color="#1472FF"
                      />

                      <Button
                        onClick={() => onRunReport()}
                        className="inventory_report_filters_menu_run_btn"
                        title="Run report"
                        size="small"
                        roundBorder
                      />
                    </div>
                  </div>
                </div>
              )}

              {!!filterCounts && !firstLoad.current
                && <div className="inventory_report_has_filter">{filterCounts}</div>}
            </div>
          </div>
        </div>

        {firstLoad.current ? <Loader /> : (
          <div className="inventory_report_table">
            <Table
              data={reportData}
              header={tableHeader}
              deleteModalText="user invitation"
              sortBy={queryData.sortBy}
              sort={queryData.sort}
              onSortBy={onSortBy}
              loading={tableLoading}
              hideDeleteButtonFields={['active', 'restricted']}
            />
          </div>
        )}

        <div className="inventory_report_pagination">
          <Pagination
            totalPages={totalPages}
            onChangePage={(p) => setQuery((prev) => ({ ...prev, page: p }))}
          />
        </div>

        <ScheduleModal isOpen={scheduleIsOpen} onClose={() => setScheduleIsOpen(false)} />
      </div>
    </Wrapper>
  );
}

export default SalesByProductReport;
