import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Button from '../../../_common/Form/Button';
import Modal from '../../../_common/Modal/Modal';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import {
  applyCreditNoteToInvoiceRequest,
  getCreditNoteRequest,
} from '../../../../store/actions/creditNotes';
import { getOrdersInvoicesRequest } from '../../../../store/actions/invoices';

function ApplyToInvoiceModal(props) {
  const {
    isOpen, onClose, id, onGetData, amount,
  } = props;

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const allOrdersInvoices = useSelector((state) => state.invoices.allOrdersInvoices);

  useEffect(() => {
    handleChange('amount', amount);
  }, [amount]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();

    setLoading(true);
    const { payload: { data } } = await dispatch(applyCreditNoteToInvoiceRequest({ id, ...form }));
    if (data.status === 'error' && !_.isEmpty(data.errors)) {
      setErrors({ ...data.errors });
    } else if (data.status === 'error') {
      toast.error(data.message);
    } else {
      toast.success('Credit successfully applied.');
      await dispatch(getCreditNoteRequest(id));
      if (onGetData) {
        await onGetData();
      }
      onClose();
    }
    setLoading(false);
  }, [form]);

  const handleGetInvoices = useCallback(async (s) => {
    const { payload } = await dispatch(getOrdersInvoicesRequest(s));
    return payload.data.orderInvoices;
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="apply_to_invoice_modal"
    >
      <p className="title">
        Apply to invoice
      </p>
      <form onSubmit={handleSubmit}>
        <Select
          roundBorder
          label="Invoice#"
          isAsync
          loadOptions={handleGetInvoices}
          defaultOptions
          valuePath="id"
          labelPath="number"
          onChange={(v) => handleChange('invoiceId', v)}
          value={allOrdersInvoices.find((i) => i.id === form.invoiceId)}
          error={errors.invoiceId}
          placeholder="Select invoice"
          menuPortalTarget={document.body}
        />
        <Input
          roundBorder
          label="Amount"
          onChange={(ev) => handleChange('amount', ev.target.value)}
          type="number"
          error={errors.amount}
          placeholder="Amount"
          value={form.amount}
        />
        <div className="actions">
          <Button btnType="cancel" roundBorder onClick={onClose}>
            Cancel
          </Button>
          <Button roundBorder type="submit" loading={loading}>
            Save changes
          </Button>

        </div>
      </form>

    </Modal>
  );
}

export default ApplyToInvoiceModal;
