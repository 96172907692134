import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Wrapper from '../../Layout/Wrapper';
import SubscriptionCard from '../Settings/PlansBillings/SubscriptionCard';
import Input from '../../_common/Form/Input';
import Select from '../../_common/Form/Select';
import Checkbox from '../../_common/Form/Checkbox';
import Utils from '../../../helpers/Utils';
import Modal from '../../_common/Modal/Modal';
import Datepicker from '../../_common/Form/Datepicker';
import Button from '../../_common/Form/Button';
import Api from '../../../Api';
import Loader from '../../_common/Loader/Loader';

const inputFields = [
  {
    label: 'Refund amount',
    path: 'refundAmount',
    type: 'input',
  },
  {
    label: 'Payment to refund',
    path: 'paymentToRefund',
    type: 'select',
  },
  {
    label: 'Reason',
    path: 'reason',
    type: 'select',
  },
  {
    label: 'Notes',
    path: 'notes',
    type: 'input',
  },
];

function EditUserSubscription() {
  const navigate = useNavigate();
  const { userId } = useParams();

  const profile = useSelector((store) => store.users.profile);

  const [extendModal, setExtendModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newExtendDate, setNewExtendDate] = useState('');
  const [subscription, setSubscription] = useState({});
  const [planData, setPlanData] = useState({
    refundAmount: '',
    paymentToRefund: '',
    reason: '',
    notes: '',
    allowTest: false,
    setUserToTrial: false,
    trialDays: '',
    extendDate: '',
  });

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (userId && profile.isAdmin) {
        const { data } = await Api.getCurrentSubscription(userId);

        setSubscription(data.data);
      } else {
        navigate('/profile', { replace: true });
      }

      setLoading(false);
    })();
  }, []);

  const onCancelSubscription = useCallback(() => {
    setNewExtendDate('');
    setExtendModal(false);
  }, []);

  const onDataChange = useCallback((path, value) => {
    setPlanData((prev) => ({ ...prev, [path]: value }));
  }, []);

  const onExtend = useCallback(() => {
    setPlanData((prev) => ({ ...prev, extendDate: newExtendDate }));
    setNewExtendDate('');
    setExtendModal(false);
  }, [newExtendDate]);

  return (
    <Wrapper
      title="Edit user subscription"
      onBtnClick={() => {}}
      onCancelBtnClick={() => { navigate(`/users/edit/${userId}`); }}
      buttonTitle="Save changes"
    >
      {loading ? <Loader /> : (
        <div className="subscription_block_edit">
          <div className="subscription_info_block_edit">
            <SubscriptionCard
              title="Current plan"
              onClick={onCancelSubscription}
              onSecondButtonClick={() => setExtendModal(true)}
              btnTitle="Cancel subscription"
              reverseButton
              secondBtnTitle="Extend plan"
            >
              <div className="subscription_card_single">
                <p className="subscription_card_title">Plan name</p>

                <p className="subscription_card_data">{_.upperFirst(subscription.subscription.planName)}</p>
              </div>

              <div className="subscription_card_single">
                <p className="subscription_card_title">Billing cycle</p>

                <p className="subscription_card_data">{_.upperFirst(subscription.subscription.interval)}</p>
              </div>

              <div className="subscription_card_single">
                <p className="subscription_card_title">Plan cost</p>

                <p className="subscription_card_data">{_.upperFirst(subscription.subscription.price)}</p>
              </div>

              <div className="subscription_card_single">
                <p className="subscription_card_title">Next payment</p>

                <p className="subscription_card_data">
                  {moment(subscription.reNewDate)
                    .format('MM/DD/YY')}
                </p>
              </div>
            </SubscriptionCard>

            <div className="subscription_extended_text">
              Plan extended:
              <span>21/05/25</span>
            </div>
          </div>

          <h3 className="refund_title">Refund payment</h3>
          <p className="refund_text">Refunds take 5-10 days to appear on a customer's statement.</p>

          <div className="subscription_block_edit_inputs">
            {inputFields.map(({
              type,
              label,
              path,
            }) => (
              <div className="subscription_block_edit_input_single">
                <label htmlFor={path}>{label}</label>

                <div className="subscription_block_edit_input_single_wrp">
                  {type === 'input'
                    ? (
                      <Input
                        id={path}
                        value={planData[path]}
                        onChangeText={(value) => onDataChange(path, value)}
                        textarea={path === 'notes'}
                        roundBorder
                        size="small"
                        placeholder={path === 'notes' ? 'Your note' : ''}
                        onBeforeInput={path === 'refundAmount' ? (e) => Utils.typingNumber(e, true) : () => {
                        }}
                      />
                    )
                    : (
                      <Select
                        id={path}
                        value={planData[path]}
                        onChange={(value) => onDataChange(path, value)}
                        roundBorder
                        size="small"
                      />
                    )}
                </div>
              </div>
            ))}
          </div>

          <Checkbox
            onChange={(checked) => onDataChange('allowTest', checked)}
            checked={planData.allowTest}
            label="Allow trial user to test platform"
          />

          <Checkbox
            onChange={(checked) => onDataChange('setUserToTrial', checked)}
            checked={planData.setUserToTrial}
            label="Set User To Trial"
          />

          <div className="subscription_block_edit_trial_days_input">
            <Input
              onChangeText={(value) => onDataChange('trialDays', value)}
              value={planData.trialDays}
              label="Set trial days"
              roundBorder
              size="small"
              onBeforeInput={(e) => Utils.typingNumber(e, true)}
            />
          </div>

          <Modal
            isOpen={extendModal}
            onClose={() => setExtendModal(false)}
            wrapperClassName="extend_modal_date_picker"
          >
            <div className="extend_modal_block">
              <h3>Extend current plan</h3>

              <Datepicker
                label="Set date"
                portalContainer={document.querySelector('.modal_wrapper')}
                onChange={(val) => setNewExtendDate(val)}
                value={newExtendDate || planData.extendDate}
              />

              <div className="extend_modal_buttons">
                <Button
                  size="small"
                  roundBorder
                  title="Cancel"
                  btnType="cancel"
                  onClick={() => setExtendModal(false)}
                />

                <Button
                  size="small"
                  roundBorder
                  title="Extend"
                  onClick={onExtend}
                  className="extend_modal__save_btn"
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </Wrapper>
  );
}

export default EditUserSubscription;
