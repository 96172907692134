import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getCountriesRequest = createAsyncThunk(
  'app/getCountriesRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getCountries(payload);

      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getRegionsRequest = createAsyncThunk(
  'app/getRegionsRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getRegions(payload.country || payload);

      return { data, countryData: payload };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const verifyAddressRequest = createAsyncThunk(
  'app/verifyAddressRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.verifyAddress(payload);

      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getMyApp = createAsyncThunk(
  'get/single/my/apps',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getIntegration(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const changeAppStatus = createAsyncThunk(
  'update/app-status',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.switchIntegration(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getPrintersListRequest = createAsyncThunk(
  'app/getPrintersListRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getPrintersList(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const changePrinterStatus = createAction('app/changePrinterStatus')
