export default {
  amazon: require('./amazon.png'),
  'amazon-vendor': require('./amazon-vendor.png'),
  bigcommerce: require('./bigcommerce.png'),
  ecwid: require('./ecwid.png'),
  quickbooks: require('./quickbooks.png'),
  woocommerce: require('./woocommerce.png'),
  eswap: require('./eswap.png'),
  shopify: require('./shopify.svg').default,
  ebay: require('./ebay.png'),
  bonanza: require('./bonanza.svg').default,
  wayfair: require('./wayfair.svg').default,
  vend: require('./vend.svg').default,
  gmail: require('./gmail_icon.svg'),
  smtp: require('./smtp.svg'),
};

export const shopIcons = {
  amazon: require('../icons/apps/amazon.svg').ReactComponent,
  bigcommerce: require('../icons/apps/bigcommerce.svg').ReactComponent,
  ecwid: require('../icons/apps/ecwid.svg').ReactComponent,
  woocommerce: require('../icons/apps/woocommerce.svg').ReactComponent,
  shopify: require('../icons/apps/shopify.svg').ReactComponent,
  ebay: require('../icons/apps/ebay.svg').ReactComponent,
  bonanza: require('../icons/apps/bonanza.svg').ReactComponent,
  vend: require('../icons/apps/vend.svg').ReactComponent,
  wayfair: require('../icons/apps/wayfair.svg').ReactComponent,
  odoo: require('../icons/apps/odoo.svg').ReactComponent,
  ftp: require('../icons/apps/ftp.svg').ReactComponent,
  'ftp-order': require('../icons/apps/ftp.svg').ReactComponent,
  'ftp-product': require('../icons/apps/ftp.svg').ReactComponent,
  'ftp-customer': require('../icons/apps/ftp.svg').ReactComponent,
  fedex: require('../icons/apps/fedex.svg').ReactComponent,
  ups: require('../icons/apps/ups.svg').ReactComponent,
  usps: require('../icons/apps/usps.svg').ReactComponent,
  'dhl-express': require('../icons/apps/dhl.svg').ReactComponent,
  'dpd-uk': require('../icons/apps/dpd.svg').ReactComponent,
  purolator: require('../icons/apps/purolator.svg').ReactComponent,
  shipstation: require('../icons/apps/ship_station.svg').ReactComponent,
  'canada-post': require('../icons/apps/canada_post.svg').ReactComponent,
  quickbooks: require('../icons/apps/quickbooks.svg').ReactComponent,
  xero: require('../icons/apps/xero.svg').ReactComponent,
  mailchimp: require('../icons/apps/mailchimp.svg').ReactComponent,
  gmail: require('../icons/apps/gmail_icon.svg').ReactComponent,
  stripe: require('../icons/apps/stripe.svg').ReactComponent,
  eswap: require('../icons/apps/eSwap.svg').ReactComponent,
  smtp: require('../icons/apps/smtp.svg').ReactComponent,
  shipbob: require('../icons/apps/shipbob.svg').ReactComponent,
  wish: require('../icons/apps/wish.svg').ReactComponent,
  'b-2-b': require('../icons/apps/b2b.svg').ReactComponent,
  etsy: require('../icons/apps/etsy.svg').ReactComponent,
  houzz: require('../icons/apps/houzz.svg').ReactComponent,
  walmart: require('../icons/apps/walmart.svg').ReactComponent,
  extensiv: require('../icons/apps/extensiv.svg').ReactComponent,
  'magento-2': require('../icons/apps/magento2.svg').ReactComponent,
  google: require('../icons/apps/google-shopping.svg').ReactComponent,
  cart: require('../icons/apps/opencart.svg').ReactComponent,
  presta: require('../icons/apps/prestashop.svg').ReactComponent,
};
