import React, {
  useCallback, useLayoutEffect, useRef, useState,
} from 'react';
import Chart from 'react-apexcharts';
import GeoChart from 'react-google-charts';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment/moment';
import ChartWrapper from '../../../../_common/ChartWrapper/ChartWrapper';
import Api from '../../../../../Api';
import Table from '../../../../_common/Tables/Table';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Button from '../../../../_common/Form/Button';
import Select from '../../../../_common/Form/Select';
import Loader from '../../../../_common/Loader/Loader';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/icons/no_match.svg';

const shippingInfo = [
  {
    title: 'New Customers:',
    path: 'newCustomerCount',
  },
  {
    title: 'Return Customers:',
    path: 'returningCustomerCount',
  },
  {
    title: 'New Customers GMV:',
    path: 'newCustomerPercentage',
    symbol: '%',
  },
  {
    title: 'Return Customers GMV:',
    path: 'returningCustomerPercentage',
    symbol: '%',
  },
];

const mostValuableCustomersTableHeader = [
  {
    title: 'Name',
    path: 'fullName',
  },
  {
    title: 'Qty',
    path: 'productsCount',
  },
  {
    title: 'Revenue',
    path: 'revenue',
  },
];

const salesByLocationTableHeader = [
  {
    title: 'Region',
    path: 'region',
  },
  {
    title: 'Qty',
    path: 'orderCount',
  },
  {
    title: 'Revenue',
    path: 'revenue',
  },
];

const regionOptions = [
  {
    label: 'World View',
    value: 'world',
  },
  {
    label: 'Europe',
    value: '150',
  },
  {
    label: 'United States',
    value: 'US',
  },
];

const geoChartTemplate = {
  // sizeAxis: {
  //   minValue: 0,
  //   maxValue: 100,
  // },
  displayMode: 'regions',
  colorAxis: { colors: ['#008FFB', '#003AD2'] },
  legend: 'none',
  // tooltip: { textStyle: { color: '#FF0000' }, showColorCode: true },
};

const formattingOrdersData = (currentRegion, orders, USARegions = []) => [
  [currentRegion === 'US' ? 'State' : 'Country', 'Orders'],
  ...orders.map((d) => [currentRegion === 'US'
    ? USARegions.find((r) => r.code === d.region)?.name
    : d.region, +d.orderCount]),
];

const today = moment().format('YYYY-MM-DD');
const lastTwoWeek = moment(new Date()).subtract(14, 'days').format('YYYY-MM-DD');

const CustomerOverview = ({ topChartTemplate }) => {
  const [date, setDate] = useQueryParams({
    startDate: withDefault(StringParam, lastTwoWeek),
    endDate: withDefault(StringParam, today, true),
  }, { updateType: 'replaceIn' });

  const { startDate, endDate } = date;

  const firstLoad = useRef(true);

  const [customersChart, setCustomersChart] = useState(topChartTemplate);
  const [mostValuableCustomers, setMostValuableCustomers] = useState([]);
  const [salesByLocation, setSalesByLocation] = useState([]);
  const [ordersByRegion, setOrdersByRegion] = useState([]);

  const [region, setRegion] = useState('world');
  const [USRegions, setUSRegion] = useState([]);
  const [regionLoading, regionLoadingToggle] = useState(false);

  const [loading, loadingToggle] = useState(true);

  useLayoutEffect(() => {
    (async () => {
      loadingToggle(true);

      if (firstLoad.current) {
        const { data: { regions } } = await Api.getRegions('US');
        setUSRegion(regions);
      }

      firstLoad.current = false;

      const [
        { data: { orders: customers, customersInfo: info } },
        { data: { customers: mostValuableCustomersData } },
        { data: { sales: salesByLocationData } },
        { data: { orders: ordersByRegionData } },
      ] = await Promise.all([
        Api.getReportsCustomers({ startDate, endDate: endDate || startDate }),
        Api.getReportsMostValuableCustomers({ startDate, endDate: endDate || startDate }),
        Api.getReportsSalesByLocation({ startDate, endDate: endDate || startDate }),
        Api.getReportsOrderByRegion({
          startDate,
          endDate: endDate || startDate,
          region: regionOptions.find((o) => o.value === region)?.label,
        }),
      ]);

      setMostValuableCustomers(mostValuableCustomersData.map((c) => ({
        ...c,
        fullName: `${c.firstName} ${c.lastName}`,
        revenue: `$${c.revenue}`,
      })));

      setSalesByLocation(salesByLocationData.map((c) => ({
        ...c,
        revenue: `$${c.revenue}`,
      })));

      setOrdersByRegion(formattingOrdersData(region, ordersByRegionData));

      setCustomersChart((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: customers.map((o) => o.date),
        },
        series: [
          {
            name: 'New Customer Orders',
            data: customers.map(({ newCustomerCount }) => newCustomerCount),
          },
          {
            name: 'Return Customer Orders',
            data: customers.map(({ returningCustomerCount }) => returningCustomerCount),
          },
        ],
        info,
      }));

      loadingToggle(false);
    })();
  }, [date]);

  const changeDate = (d) => {
    setDate({ startDate: d[0], endDate: d[1] || '' });
  };

  const changeRegion = useCallback(async (currentRegion) => {
    setRegion(currentRegion);

    regionLoadingToggle(true);
    const { data: { orders } } = await Api.getReportsOrderByRegion({
      startDate,
      endDate: endDate || startDate,
      region: regionOptions.find((o) => o.value === currentRegion)?.label,
    });

    setOrdersByRegion(formattingOrdersData(currentRegion, orders, USRegions));
    regionLoadingToggle(false);
  }, [USRegions]);

  return (
    <div className="sales_overview_wrapper operations">
      <div className="sales_overview_date_filter">
        <RangeDatePicker currentDate={date} onChangeDate={changeDate} />
      </div>

      <ChartWrapper height={480} title="Customer overview" loading={loading}>
        <div className="chart_info">
          {shippingInfo.map(({ title, path, symbol = '' }) => (
            <div key={title}>{`${title} ${customersChart.info[path]}${symbol}`}</div>
          ))}
        </div>

        <Chart
          height={350}
          width="100%"
          type="bar"
          className="apx-chart"
          series={customersChart.series}
          options={customersChart}
        />
      </ChartWrapper>

      <div className="overview_bottom_block customers">
        <ChartWrapper height={330} title="Most valuable customers" loading={loading}>
          <Table
            data={mostValuableCustomers}
            header={mostValuableCustomersTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Customers not found</h1>
              </>
            )}
          />

          {!!mostValuableCustomers.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Sales by location" loading={loading}>
          <Table
            data={salesByLocation}
            header={salesByLocationTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Sales not found</h1>
              </>
            )}
          />

          {!!salesByLocation.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>
      </div>

      <ChartWrapper className="region_shipment" height={630} title="Shipments by region" loading={loading}>
        <div className="chart_info chart_select_region">
          <Select
            options={regionOptions}
            value={region}
            onChange={changeRegion}
            roundBorder
            size="small"
            lightBlue
          />
        </div>

        {regionLoading
          ? <Loader />

          : (
            <GeoChart
              chartType="GeoChart"
              width="100%"
              height="500px"
              className="geo_chart"
              options={{
                ...geoChartTemplate,
                region,
                resolution: region === 'US' ? 'provinces' : null,
              }}
              data={ordersByRegion}
            />
          )}

      </ChartWrapper>
    </div>
  );
};

export default CustomerOverview;
