import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  NumberParam,
  StringParam, useQueryParam, withDefault,
} from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../_common/Tables/Table';
import Loader from '../../_common/Loader/Loader';
import Input from '../../_common/Form/Input';
import Wrapper from '../../Layout/Wrapper';
import Api from '../../../Api';
import Pagination from '../../_common/Pagination/Pagination';
import { ReactComponent as ProductsNotFoundIcon } from '../../../assets/icons/no_match.svg';
import Button from '../../_common/Form/Button';

const WarehouseProducts = () => {
  const { warehouseId } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 1),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [s, setSearch] = useQueryParam(
    's',
    withDefault(StringParam, ''),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const firstLoad = useRef(false);
  const timeout = useRef(false);

  const [products, setProducts] = useState({
    list: [],
    totalPages: 1,
  });

  const { list, totalPages } = products;

  const [warehouseName, setWarehouseName] = useState('');
  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    loadingToggle(true);
    clearTimeout(timeout.current);

    if (!firstLoad.current) {
      (async () => {
        const { data } = await Api.getWarehouse(warehouseId);

        setWarehouseName(data.warehouse.title);
      })();
    }

    timeout.current = setTimeout(async () => {
      const { data } = await Api.getProducts({ warehouseId, page, s });

      setProducts({
        list: data.products.map((p) => ({
          ...p,
          allocated: p.totalStock.allocated.toString(),
          available: p.totalStock.available.toString(),
          stock: p.totalStock.stock.toString(),
        })),
        totalPages: data.totalPages,
      });

      loadingToggle(false);
      firstLoad.current = true;
    }, 500);
  }, [page, s]);

  const searchProducts = useCallback((value) => {
    setSearch(value);

    setPage(1);
  }, []);

  const changePage = useCallback((currentPage) => {
    setPage(currentPage);
  }, []);

  const tableHeader = [
    {
      title: 'Title',
      path: 'productItem',
      navigate: (id) => navigate(`/products/${id}`),
    },
    {
      title: 'Variant SKU',
      path: 'sku',
    },
    {
      title: 'Stock',
      path: 'stock',
    },
    {
      title: 'Stock available',
      path: 'available',
    },
    {
      title: 'Stock allocated',
      path: 'allocated',
    },
  ];

  return (
    <Wrapper
      title={warehouseName ? `Warehouse ${warehouseName} products` : ''}
    >
      {!firstLoad.current && loading
        ? <Loader />

        : (
          <>
            <Input
              placeholder="Search title"
              roundBorder
              size="small"
              search
              value={s}
              onChangeText={searchProducts}
              wrapperClassName="company_search_field"
            />

            <div className="list_wrapper">
              <Table
                data={list}
                header={tableHeader}
                loading={loading}
                notFound={(
                  <>
                    <ProductsNotFoundIcon />

                    <h1>Products not found</h1>
                  </>
                )}
              />
            </div>

            <Pagination
              page={page}
              totalPages={totalPages}
              onChangePage={changePage}
            />
          </>
        )}
    </Wrapper>
  );
};

export default WarehouseProducts;
