class Storage {
  static set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static get(key, defaultValue = null) {
    const value = localStorage.getItem(key);
    if (value === null) {
      return defaultValue;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      return defaultValue;
    }
  }

  static remove(key) {
    localStorage.removeItem(key);
  }
}

export default Storage;
