import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import { ReactComponent as AddIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/delete.svg';
import { setCustomerFormData } from '../../../../store/actions/customers';
import { contactIdKey } from '../../../../store/reducers/customers';

const salutationOption = [
  {
    path: 'mr',
    value: 'Mr.',
  },
  {
    path: 'mrs',
    value: 'Mrs.',
  },
  {
    path: 'ms',
    value: 'Ms.',
  },
  {
    path: 'miss',
    value: 'Miss',
  },
  {
    path: 'dr',
    value: 'Dr.',
  },
];

const ContactPerson = () => {
  const dispatch = useDispatch();

  const { contacts } = useSelector((state) => state.customers.customerFormData);
  const errors = useSelector((state) => state.customers.errors);

  const handleAddContact = useCallback(() => {
    dispatch(setCustomerFormData(
      {
        key: 'contacts',
        value: [...contacts,
          { [contactIdKey]: _.uniqueId('temp'), salutation: 'mr' }],
      },
    ));
  }, [contacts]);

  const handleDeleteContact = useCallback((id) => {
    dispatch(setCustomerFormData(
      {
        key: 'contacts',
        value: contacts.filter((c) => c[contactIdKey] !== id),
      },
    ));
  }, [contacts]);

  const handleChange = useCallback((key, value) => {
    dispatch(setCustomerFormData({ key, value }));
  }, []);

  return (
    <div className="contactPerson">
      <table>
        <thead>
          <tr>
            <th>Salutation</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Work phone</th>
            <th>Mobile phone</th>
            <th>Email</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {contacts.map((c, i) => (
            <tr key={c[contactIdKey]}>
              <td style={{ width: 200 }}>
                <Select
                  roundBorder
                  options={salutationOption}
                  value={salutationOption.find((s) => s.path === _.get(contacts[i], 'salutation', ''))?.value || ''}
                  onChange={(option) => handleChange(`contacts[${i}].salutation`, option.path)}
                  getOptionLabel={(o) => (o.value)}
                  getFullOption
                />
              </td>
              <td>
                <Input
                  roundBorder
                  onChange={(ev) => handleChange(`contacts[${i}].firstName`, ev.target.value)}
                  value={_.get(contacts[i], 'firstName', '')}
                  error={_.get(errors, `contacts[${i}].firstName`)}
                />
              </td>
              <td>
                <Input
                  roundBorder
                  onChange={(ev) => handleChange(`contacts[${i}].lastName`, ev.target.value)}
                  value={_.get(contacts[i], 'lastName', '')}
                  error={_.get(errors, `contacts[${i}].lastName`)}
                />
              </td>
              <td>
                <Input
                  roundBorder
                  onChange={(ev) => handleChange(`contacts[${i}].workPhone`, ev.target.value)}
                  value={_.get(contacts[i], 'workPhone', '')}
                  error={_.get(errors, `contacts[${i}].workPhone`)}
                />
              </td>
              <td>
                <Input
                  roundBorder
                  onChange={(ev) => handleChange(`contacts[${i}].mobilePhone`, ev.target.value)}
                  value={_.get(contacts[i], 'mobilePhone', '')}
                  error={_.get(errors, `contacts[${i}].mobilePhone`)}
                />
              </td>
              <td>
                <Input
                  roundBorder
                  onChange={(ev) => handleChange(`contacts[${i}].email`, ev.target.value)}
                  value={_.get(contacts[i], 'email', '')}
                  error={_.get(errors, `contacts[${i}].email`)}
                />
              </td>
              <td className="remove">
                {contacts.length > 1 ? (
                  <div>
                    <p onClick={() => handleDeleteContact(c[contactIdKey])}>
                      <RemoveIcon />
                    </p>
                  </div>
                ) : null}
              </td>
            </tr>
          ))}

        </tbody>
      </table>

      <Button btnType="transparent" iconLeft={<AddIcon />} onClick={handleAddContact}>Add contact person</Button>
    </div>
  );
};

export default ContactPerson;
