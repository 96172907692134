import React, {
  useCallback, useEffect, useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { format } from 'currency-formatter';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel_red.svg';
import {
  cancelPaymentsRequest,
  getOrderInvoicesRequest,
} from '../../../../store/actions/invoices';
import Utils from '../../../../helpers/Utils';
import { getSingleOrderRequest } from '../../../../store/actions/orders';
import TableDropdown from './TableDropdown';

function Payments() {
  const [loading, setLoading] = useState();
  const [dropdown, setDropdown] = useState(null);
  const [deletingData, setDeletingData] = useState({});
  const [loadingDelete, setLoadingDelete] = useState(false);

  const dispatch = useDispatch();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const payments = useSelector((state) => state.invoices.orderInvoices).map((i) => i.payments).flat(1);
  const invoices = useSelector((state) => state.invoices.orderInvoices);
  const order = useSelector((state) => state.orders.order);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getOrderInvoicesRequest(orderId));
      setLoading(false);
    })();
  }, [orderId]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const d = { id: deletingData.id, paymentId: deletingData.paymentId };
    const { payload } = await dispatch(cancelPaymentsRequest(d));
    if (payload.data.status === 'error') {
      toast.error(payload.data.message);
    } else {
      await dispatch(getOrderInvoicesRequest(orderId));
      await dispatch(getSingleOrderRequest(orderId));
      toast.success('Successfully canceled.');
    }
    setLoadingDelete(false);
    setDeletingData({});
  }, [deletingData, orderId]);

  return (
    <div className="invoices packages">
      {!loading && _.isEmpty(payments) ? (
        <div className="no_packages">
          No Payments created so far
        </div>
      ) : null}
      {!_.isEmpty(payments) && !loading ? (
        <div className="table_wrapper">
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Payment#</th>
                <th className="table_thead_tr_th">Invoice#</th>
                <th className="table_thead_tr_th">Date</th>
                <th className="table_thead_tr_th">Payment type</th>
                <th className="table_thead_tr_th">Status</th>
                <th className="table_thead_tr_th">Amount</th>
                <th />
              </tr>
            </thead>
            <tbody className="table_tbody">
              {payments.map((p, index) => {
                const singleInvoice = invoices.find((i) => i.id === p.invoiceId);
                return (
                  <tr
                    className="table_tbody_tr"
                    key={p.id}
                  >
                    <td className="table_tbody_tr_td number">
                      {p.number || '-'}
                    </td>
                    <td className="table_tbody_tr_td number">
                      {invoices.find((i) => i.id === p.invoiceId)?.number}
                    </td>
                    <td className="table_tbody_tr_td">
                      {p.sourceCreatedAt ? moment(p.sourceCreatedAt).format('DD MMM YYYY') : null}
                    </td>
                    <td className="table_tbody_tr_td">
                      {p.paymentType.title}
                    </td>
                    <td className="table_tbody_tr_td">
                      <div className={`status ${p.status}`}>
                        <p className="status_dot" />
                        <span>
                          {order.type === 'purchase'
                            ? Utils.formatPurchaseStatus(p.status)
                            : Utils.formatStatus(p.status)}
                        </span>
                      </div>
                    </td>
                    <td className="table_tbody_tr_td">
                      {format(p.amount, { code: order.currencyCode })}
                    </td>
                    <td className="table_tbody_tr_td">
                      {p.status !== 'canceled' ? (
                        <div className="dot_icon" onClick={() => setDropdown(p.id)}>
                          <DotsIcon />
                        </div>
                      ) : null}
                      {dropdown === p.id ? (
                        <TableDropdown onClose={() => setDropdown(null)} top={50 + ((index + 1) * 45)}>

                          {(_.sumBy(singleInvoice.payments, 'amount') - _.sumBy(singleInvoice.creditNotes, 'credit')) > 0
                            ? (
                              <li onClick={() => navigate(
                                location.pathname,
                                { state: { creditNote: p.invoiceId }, replace: true },
                              )}
                              >
                              Create credit note
                              </li>
                            ) : null}
                          {p.status !== 'canceled' ? (
                            <li
                              onClick={() => setDeletingData({ id: p.invoiceId, paymentId: p.id, status: p.status })}
                              className="delete"
                            >
                              Cancel payment
                            </li>
                          ) : null}
                        </TableDropdown>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
      <DeleteModal
        isOpen={!!deletingData.id}
        onDelete={handleDelete}
        onClose={() => setDeletingData({})}
        loading={loadingDelete}
        text="payment"
        deleteType="cancel"
        icon={<CancelIcon />}
        btnTitle="confirm"
      />
    </div>
  );
}

export default Payments;
