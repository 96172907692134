import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../_common/Form/Button';
import Datepicker from '../../../_common/Form/Datepicker';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import Select from '../../../_common/Form/Select';

const defaultCategoryData = {
  name: '',
  parentId: '',
  description: '',
};

const units = [
  {
    value: 'pcs',
    label: 'pcs',
  },
  {
    value: 'ltr',
    label: 'ltr',
  },
  {
    value: 'ml',
    label: 'ml',
  },
  {
    value: 'kg',
    label: 'kg',
  },
  {
    value: 'g',
    label: 'g',
  },
];

const prodTypeOptions = [
  {
    value: 'product',
    label: 'Product',
  },
  {
    value: 'service',
    label: 'Service',
  },
  {
    value: 'virtual',
    label: 'Virtual',
  },
];

function ProductVendor({
  onDataChange, customers, categories, setCategories, setBrands, productData, createProduct, brands,
}) {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState(defaultCategoryData);
  const [modalIsOpen, setModalIsOpen] = useState('');

  const categoryFields = [
    {
      title: 'Category name',
      path: 'name',
      required: true,
    },
    {
      title: 'Parent category',
      path: 'parentId',
      type: 'select',
      menuPortalTarget: true,
      valuePath: 'id',
      labelPath: 'name',
      isAsync: true,
      defaultOptions: categories,
      getFullOption: true,
      getOptionLabel: (option) => {
        if (option.parents.length) {
          const parents = option.parents.map((p) => p.name).reverse().join(' / ');

          return `${option.name} / ${parents}`;
        }

        return option.name;
      },
    },
    {
      title: 'Description',
      path: 'description',
    },
  ];

  const brandFields = [
    {
      title: 'Title',
      path: 'name',
      placeholder: 'Enter name',
      required: true,
    },
  ];

  const closeModal = useCallback(() => {
    setCategoryData(defaultCategoryData);
    setModalIsOpen('');
  }, []);

  const loadCategories = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getCategories({ s: inputValue, includes: 'parents' });

    callback(data.categories);
  }, []);

  const loadCustomers = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getCustomers({ s: inputValue, isVendor: true });

    callback(data.customers);
  }, []);

  const loadBrands = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getBrands({ s: inputValue });

    callback(data.brands);
  }, []);

  const onAddCustomer = useCallback(async () => {
    const savedProduct = await createProduct(true);

    if (savedProduct.status !== 'error') {
      const productUrl = +savedProduct.parentId
        ? `/products/${savedProduct.parentId}?productVariantId=${savedProduct.id}`
        : `/products/${savedProduct.id}`;

      navigate(productUrl, { replace: true });

      navigate(`/crm/customers/create?back=${productUrl}&isVendor=1`);
    }
  }, [createProduct]);

  const onSaveCategory = useCallback(async (newData) => {
    const newCategory = await Api.createCategory({ ...newData, parentId: newData?.parentId?.id || null });

    const { data } = await Api.getCategories({ includes: 'parents' });
    onDataChange('categories', [...productData.categories, newCategory.data.category]);

    toast.success('Category has been successfully created');

    setCategories(data.categories);
    closeModal();
  }, [productData]);

  const onSaveBrand = useCallback(async (newData) => {
    const newBrand = await Api.createProductBrand(newData);

    const { data } = await Api.getBrands();
    onDataChange('brandId', newBrand.data.brand);

    toast.success('Brand has been successfully created');

    setBrands(data.brands);
    closeModal();
  }, [productData]);

  return (
    <div className="create_product_vendor">
      <div className="create_product_vendor_block">
        <Select
          isAsync
          onChange={(value) => onDataChange('vendorId', value)}
          value={productData.vendorId}
          defaultOptions={customers}
          loadOptions={loadCustomers}
          getFullOption
          size="small"
          label="Vendor"
          roundBorder
          valuePath="id"
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          dataTestId="vendor_select"
          isClearable
        />

        <Button
          onClick={() => onAddCustomer()}
          size="small"
          roundBorder
          btnType="transparent"
          color="#1472FF"
          addBtn
          title="Add vendor"
        />

        <Select
          onChange={(value) => onDataChange('productType', value)}
          value={productData.productType}
          options={prodTypeOptions}
          size="small"
          label="Product type"
          roundBorder
          dataTestId="product_type_select"
        />

        <Select
          isAsync
          value={productData.categories}
          onChange={(value) => onDataChange('categories', value)}
          defaultOptions={categories}
          loadOptions={loadCategories}
          getFullOption
          size="small"
          roundBorder
          label="Category"
          valuePath="id"
          labelPath="name"
          isMulti
          getOptionLabel={(option) => {
            if (option.parents.length) {
              const parents = option.parents.map((p) => p.name).reverse().join(' / ');

              return `${option.name} / ${parents}`;
            }

            return option.name;
          }}
          dataTestId="category_select"
        />

        <Button
          onClick={() => setModalIsOpen('category')}
          size="small"
          roundBorder
          btnType="transparent"
          color="#1472FF"
          addBtn
          title="Add category"
        />

        <Select
          isAsync
          value={productData.brandId}
          onChange={(value) => onDataChange('brandId', value)}
          defaultOptions={brands}
          loadOptions={loadBrands}
          getFullOption
          size="small"
          roundBorder
          label="Brand"
          valuePath="id"
          labelPath="name"
          dataTestId="brand_select"
          isClearable
        />

        <Button
          onClick={() => setModalIsOpen('brand')}
          size="small"
          roundBorder
          btnType="transparent"
          color="#1472FF"
          addBtn
          title="Add brand"
        />

        <Select
          onChange={(value) => onDataChange('unitType', value)}
          value={productData.unitType}
          options={units}
          size="small"
          roundBorder
          label="Unite type"
          dataTestId="unit_type_select"
        />

        <div className="create_product_vendor_datepicker" data-test-id="vendor-datapicker">
          <Datepicker
            label="Publish date"
            value={productData.sourceCreatedAt}
            onChange={(value) => onDataChange('sourceCreatedAt', value)}
            minDate={new Date()}
          />
        </div>
      </div>

      <UpdateModal
        isOpen={!!modalIsOpen}
        onClose={closeModal}
        fields={modalIsOpen === 'brand' ? brandFields : categoryFields}
        onSave={modalIsOpen === 'brand' ? onSaveBrand : onSaveCategory}
        singleData={modalIsOpen === 'brand' ? { name: '' } : categoryData}
        loadMoreOptions={loadCategories}
      />
    </div>
  );
}

ProductVendor.propTypes = {
  onDataChange: PropTypes.func,
  customers: PropTypes.array,
  categories: PropTypes.array,
  setCategories: PropTypes.func,
  setBrands: PropTypes.func,
  createProduct: PropTypes.func,
  productData: PropTypes.object,
  brands: PropTypes.array,
};

ProductVendor.defaultProps = {
  onDataChange: () => {},
  customers: [],
  categories: [],
  setCategories: () => {},
  setBrands: () => {},
  createProduct: () => {},
  productData: {},
  brands: [],
};

export default ProductVendor;
