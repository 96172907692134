import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useClickAway } from 'react-use';
import { unformat } from 'currency-formatter';
import FilterButton from '../../../_common/Form/FilterButton';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/remote_printing.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import { ReactComponent as ArchiveIcon } from '../../../../assets/icons/archive_gray.svg';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate.svg';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';
import PrintDocumentModal from '../../../_common/Modal/PrintDocumentModal';
import { generateInvoiceRequest, getOrderInvoicesRequest } from '../../../../store/actions/invoices';
import Api from '../../../../Api';
import Utils from '../../../../helpers/Utils';
import ArchiveModal from '../../../_common/Modal/ArchiveModal';
import {
  archiveOrderRequest, duplicateOrderRequest,
  getSingleOrderRequest,
  sendOrderToRequest,
  updateOrderRequest,
} from '../../../../store/actions/orders';
import { getIntegrationsRequest } from '../../../../store/actions/integrations';
import shipstationImg from '../../../../assets/integrations/shipstation.png';
import { getShippingRatesRequest } from '../../../../store/actions/packages';

const createOptionsSales = [
  { label: 'Create package', value: 'package', scope: 'WRITE_ORDER_PACKAGES' },
  { label: 'Create invoice', value: 'invoice', scope: 'WRITE_ORDER_INVOICES' },
  { label: 'Create return', value: 'return', scope: 'WRITE_ORDER_RETURNS' },
];

const createOptionsPurchase = [
  { label: 'Create receive', value: 'package', scope: 'WRITE_ORDER_PACKAGES' },
  { label: 'Create bill', value: 'invoice', scope: 'WRITE_ORDER_INVOICES' },
];

function PreviewHeader() {
  const [loading, setLoading] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [archiveModal, setArchiveModal] = useState(null);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [approveLoading, setApproveStatus] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);

  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = useParams();
  const dispatch = useDispatch();

  const order = useSelector((state) => state.orders.order);
  const priceLists = useSelector((state) => state.priceLists.priceLists);
  const packedCounts = _.sum(Object.values(useSelector((state) => state.packages.packedCounts)));
  const invoicedCount = _.sum(Object.values(useSelector((state) => state.invoices.invoicedCount)));

  useClickAway(dropdownRef, () => {
    setDropdown(false);
  });

  const inShipStation = useMemo(() => order?.meta?.find((m) => m.name === 'shipstation'), [order.meta]);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getIntegrationsRequest({ type: 'shipstation' }));
      setIntegrations(payload?.data?.integrations || []);
    })();
  }, []);

  const optionsSales = useMemo(() => {
    if (packedCounts >= _.sumBy(order.orderProducts, 'qty')) {
      return createOptionsSales.filter((o) => o.value !== 'package');
    }
    if (invoicedCount >= _.sumBy(order.orderProducts, 'qty')) {
      return createOptionsSales.filter((o) => o.value !== 'invoice');
    }
    if (!['shipped', 'partial_shipped', 'delivered', 'partial_delivered'].includes(order.status)) {
      return createOptionsSales.filter((o) => o.value !== 'return');
    }

    return createOptionsSales;
  }, [packedCounts, invoicedCount, order.status]);

  const optionsPurchase = useMemo(() => {
    if (_.isEmpty(order.orderProducts)) {
      return createOptionsPurchase.filter((o) => o.value !== 'package');
    }

    return createOptionsPurchase;
  }, [createOptionsPurchase, order]);

  const generateInvoice = useCallback(async () => {
    setLoading(true);
    const { payload } = await dispatch(generateInvoiceRequest(orderId));
    if (payload?.data?.status === 'error') {
      toast.error(payload?.data?.message);
    } else {
      await dispatch(getOrderInvoicesRequest(orderId));
      toast.success('Invoice successfully created.');
      navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
    }
    setLoading(false);
  }, [orderId]);

  const handleArchive = useCallback(async () => {
    setArchiveLoading(true);
    const { payload } = await dispatch(archiveOrderRequest(orderId));
    if (payload.data.status !== 'error') {
      await dispatch(getSingleOrderRequest(orderId));
      toast.success('Order successfully archived');
      setArchiveModal(false);
      navigate(`/orders/sales-order${location.search}`);
    } else {
      toast.error(payload.data.message);
    }
    setArchiveLoading(false);
  }, [orderId]);

  const handleApprove = useCallback(async () => {
    setApproveStatus(true);
    const d = {
      id: order.id,
      companyId: order.companyId,
      customerId: order.customerId,
      shippingAddressId: order.shippingAddressId,
      shippingAddress: {
        address1: order.shippingAddress?.address1,
        address2: order.shippingAddress?.address2,
        city: order.shippingAddress?.city,
        countryCode: order.shippingAddress?.countryCode,
        firstName: order.shippingAddress?.firstName,
        lastName: order.shippingAddress?.lastName,
        id: order.shippingAddress?.id,
        phone: order.shippingAddress?.phone,
        postalCode: order.shippingAddress?.postalCode,
        region: order.shippingAddress?.region,
        title: order.shippingAddress?.title,
      },
      billingAddress: {
        address1: order.billingAddress?.address1,
        address2: order.billingAddress?.address2,
        city: order.billingAddress?.city,
        countryCode: order.billingAddress?.countryCode,
        firstName: order.billingAddress?.firstName,
        lastName: order.billingAddress?.lastName,
        id: order.billingAddress?.id,
        phone: order.billingAddress?.phone,
        postalCode: order.billingAddress?.postalCode,
        region: order.billingAddress?.region,
        title: order.billingAddress?.title,
      },
      priceListId: order.priceListId,
      status: 'pending',
      type: order.type,
      taxType: order.taxType,
      sourceCreatedAt: order.sourceCreatedAt,
      paymentTerm: { id: order.paymentTerm.id },
      totalShippingPrice: +unformat(order.totalShippingPrice, { code: order.currencyCode }) || 0,
      number: order.number,
      currencyCode: order.currencyCode,
      notes: order.notes,
      orderProducts: order.orderProducts.map((o) => ({
        productId: o.productId,
        id: o.id,
        price: o.price || 0,
        discountPercentage: o.discountPercentage || 0,
        qty: o.qty || 1,
        taxPercentage: o.taxPercentage || 0,
        warehouseId: o.warehouseId,
      })),
    };
    if (order.type === 'purchase') {
      d.landedCosts = order.landedCosts.map((l) => ({
        title: l.title,
        price: l.price,
        splitMethod: l.splitMethod,
        taxPercentage: l.taxPercentage,
        id: l.id,
      }));
    }
    const { payload: { data } } = await dispatch(updateOrderRequest(d));

    if (data.status === 'error') {
      if (order.type === 'purchase') {
        navigate(`/stocks/purchase-orders/edit/${order.id}`, { state: data, replace: true });
      } else {
        navigate(`/orders/sales-order/edit/${order.id}`, { state: data, replace: true });
      }
    } else {
      await dispatch(getSingleOrderRequest(order.id));
      toast.success('Successfully approved.');
    }
    setArchiveLoading(false);
  }, [order]);

  const handleShipStation = useCallback(async () => {
    if (inShipStation) {
      window.open(
        inShipStation.value.sourceUrl,
        '_blank',
      );
    } else {
      const { payload } = await dispatch(sendOrderToRequest({ integration: 'shipstation', ids: orderId }));
      if (payload.data.status === 'error') {
        toast.error(payload.data.message);
      } else {
        toast.success('Successfully sent.');
        dispatch(getShippingRatesRequest(orderId));
      }
    }
  }, [inShipStation, orderId]);

  const handlePrint = useCallback(async (params) => {
    try {
      const { data } = await Api.viewOrderQuotation(orderId, 'remote-print', { ...params }, 'json');
      return data;
    } catch (e) {
      return e.response.data;
    }
  }, []);

  const handleDuplicateOrder = useCallback(async () => {
    setLoadingDuplicate(true);
    const { payload } = await dispatch(duplicateOrderRequest(orderId));
    if (payload.data.status === 'error') {
      toast.error(payload.data.message);
    } else {
      toast.success('Successfully duplicated!');
      if (payload.data.order.type === 'purchase') {
        navigate(`/stocks/purchase-orders/preview/${payload.data.order.id}`);
      } else {
        navigate(`/orders/sales-order/preview/${payload.data.order.id}`);
      }
    }
    setLoadingDuplicate(false);
  }, [orderId]);

  const isPurchase = order.type === 'purchase';
  return (
    <div className="preview_header">
      <div className="info">
        <div className="row">
          <p className="number">{order.number}</p>
          <div className="statuses">
            <p className={order.status}>
              <span className="status_dot" />
              {order.type === 'purchase'
                ? Utils.formatPurchaseStatus(order.status)
                : Utils.formatStatus(order.status)}
            </p>
            <p className={order.paymentStatus}>
              <span className="status_dot" />
              {Utils.formatStatus(order.paymentStatus)}
            </p>
          </div>
        </div>
        <div className="row">
          <p className="created_at">
            <span>{moment(order.createdAt).format('MM/DD/YYYY')}</span>
            {' '}
            <span>at</span>
            {' '}
            <span>{moment(order.createdAt).format('h:mm A')}</span>
          </p>
          <p className="price_list">
            <span className="label">Pricelist:</span>
            <span className="value">{priceLists.find((p) => p.id === order.priceListId)?.name || '-'}</span>
          </p>
        </div>
      </div>
      <div className="actions">
        <FilterButton
          className="filter_button"
          onClick={() => navigate(isPurchase ? `/stocks/purchase-orders/edit/${order.id}` : `/orders/sales-order/edit/${order.id}`)}
          data-scope={isPurchase ? 'WRITE_PURCHASE_ORDERS' : 'WRITE_ORDERS'}
        >
          <EditIcon />
          <span>Edit</span>
        </FilterButton>
        <FilterButton className="filter_button" onClick={() => setShowPrintModal(true)}>
          <PrintIcon />
          <span>Print</span>
        </FilterButton>
        <FilterButton className="filter_button dots" onClick={() => setDropdown(true)}>
          <DotsIcon />
          {dropdown ? (
            <ul className="dropdown" ref={dropdownRef}>
              {order.type === 'sale' && integrations.length ? (
                <li
                  onClick={handleShipStation}
                >
                  <img src={shipstationImg} className="shipstation_icon" alt="shipstation_icon" />
                  {inShipStation ? 'Show in ShipStation' : 'Send to ShipStation'}
                </li>
              ) : null}
              <li
                data-scope="WRITE_ORDERS"
                onClick={handleDuplicateOrder}
                style={{ position: 'relative' }}
              >
                <DuplicateIcon className="archive_icon" />
                Duplicate order
                {loadingDuplicate ? (
                  <div className="loader_wrapper" style={{ right: 7, top: 12 }}>
                    <span style={{ width: 15, height: 15 }} className="loader" />
                  </div>
                ) : null}
              </li>
              <li
                data-scope="DELETE_ORDERS"
                onClick={() => {
                  setArchiveModal(true);
                  setDropdown(false);
                }}
              >
                <ArchiveIcon className="archive_icon" />
                Archive
              </li>
            </ul>
          ) : null}
        </FilterButton>
        <Select
          placeholder="Create"
          roundBorder
          options={order.type === 'purchase' ? optionsPurchase : optionsSales}
          onChange={(val) => {
            navigate(`${location.pathname}${location.search}`, { state: { [val]: true }, replace: true });
          }}
        />
        {order.status === 'draft' ? (
          <Button
            data-scope="WRITE_ORDERS"
            btnType="transparent"
            onClick={handleApprove}
            className="approve"
            loading={approveLoading}
          >
            Mark as approved
          </Button>
        ) : null}
        {invoicedCount < _.sumBy(order.orderProducts, 'qty')
          ? (
            <Button
              roundBorder
              onClick={generateInvoice}
              loading={loading}
              data-scope="WRITE_ORDER_INVOICES"
            >
              {`Convert to ${order.type === 'purchase' ? 'bill' : 'invoice'}`}
            </Button>
          ) : null}
        {location.key !== 'default' ? (
          <RemoveIcon
            className="remove_icon"
            onClick={() => navigate(-1)}
          />
        ) : null}
      </div>
      {showPrintModal ? (
        <PrintDocumentModal
          isOpen={showPrintModal}
          onClose={() => setShowPrintModal(false)}
          type="orders/sales-quotation"
          onRequest={async (templateId) => {
            const { data } = await Api.viewOrderQuotation(orderId, 'download', { templateId });
            return data;
          }}
          onRemotePrint={handlePrint}
        />
      ) : null}
      <ArchiveModal
        isOpen={!!archiveModal}
        text="order"
        onArchive={handleArchive}
        loading={archiveLoading}
        onClose={() => setArchiveModal(null)}
      />

    </div>
  );
}

export default PreviewHeader;
