import { createReducer } from '@reduxjs/toolkit';
import {
  getSettingsRequest,
  updateSettingsRequest,
  getWarehousesRequest,
  setDefaultWarehouseRequest,
  getCompaniesRequest,
  setDefaultCompanyRequest,
  getTaxTypesRequest,
  updateTaxTypesRequest,
  deleteTaxTypesRequest, getPackagesRequest, getCustomerCustomFieldsRequest,
} from '../actions/settings';
import { getWarehouseList } from "../actions/stockTransfer";

const initialState = {
  generalList: {
    warehouses: [],
    companies: [],
    defaultWarehouse: {},
    defaultCompany: {},
    weightUnit: '',
    sizeUnit: '',
    cubicUnit: '',
    currencyCode: '',
    paymentTerms: [],
  },
  taxTypes: {
    taxes: [],
  },
  paymentMethods: {
    methods: [],
  },
  paymentTerms: {
    terms: [],
  },
  smtpConfigs: {
    configs: [],
  },
  packages: [],
  customFields: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSettingsRequest.fulfilled, (state, { payload }) => {
      state.generalList.weightUnit = payload.weightUnit;
      state.generalList.sizeUnit = payload.sizeUnit;
      state.generalList.cubicUnit = payload.cubicUnit;
      state.generalList.currencyCode = payload.currencyCode;
      state.generalList.paymentMethods = payload.paymentMethods;
      state.generalList.paymentTerms = payload.paymentTerms;
    })
    .addCase(updateSettingsRequest.fulfilled, (state, { payload }) => {
      state.generalList.weightUnit = payload.weightUnit;
      state.generalList.sizeUnit = payload.sizeUnit;
      state.generalList.cubicUnit = payload.cubicUnit;
      state.generalList.currencyCode = payload.currencyCode;
    })
    .addCase(getWarehousesRequest.fulfilled, (state, { payload }) => {
      state.generalList.warehouses = payload.warehouses;
      state.generalList.defaultWarehouse = payload.warehouses.find((w) => w.isDefault);
    })
    .addCase(setDefaultWarehouseRequest.fulfilled, (state, { payload }) => {
      state.generalList.defaultWarehouse = payload.warehouse;
    })
    .addCase(getCompaniesRequest.fulfilled, (state, { payload }) => {
      state.generalList.companies = payload.companies;
      state.generalList.defaultCompany = payload.companies.find((c) => c.isDefault);
    })
    .addCase(setDefaultCompanyRequest.fulfilled, (state, { payload }) => {
      state.generalList.defaultCompany = payload.company;
    })
    .addCase(getTaxTypesRequest.fulfilled, (state, { payload }) => {
      state.taxTypes.taxes = payload.taxes;
    })
    .addCase(updateTaxTypesRequest.fulfilled, (state, { payload }) => {
      console.log(payload, 'taxtypes-update');
    })
    .addCase(deleteTaxTypesRequest.fulfilled, (state, { meta: { arg: { id } } }) => {
      state.taxTypes.taxes = state.taxTypes.taxes.filter((t) => +t.id !== +id);
    })
    .addCase(getPackagesRequest.fulfilled, (state, { payload }) => {
      state.packages = payload.packageDimensions || [];
    })
    .addCase(getWarehouseList.fulfilled, (state, { payload }) => {
      state.generalList.warehouses = payload.warehouses || [];
    })
    .addCase(getCustomerCustomFieldsRequest.fulfilled, (state, { payload }) => {
      state.customFields = payload.customerCustomFields || [];
    });
});

export default reducer;
