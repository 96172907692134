import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Datepicker from '../../../_common/Form/Datepicker';
import noImg from '../../../../assets/icons/no_img.svg';
import {
  createOrderReceiveRequest, generatePackageNumberRequest,
  getOrderPackagesRequest,
} from '../../../../store/actions/packages';
import { getProductsRequest } from '../../../../store/actions/products';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/warning_tansparent.svg';
import { getSingleOrderRequest } from '../../../../store/actions/orders';

function ReceiveForm() {
  const [form, setForm] = useState({
    number: '',
    sourceCreatedAt: moment().format('YYYY-MM-DD'),
    note: '',
    orderPackageProducts: [],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const dispatch = useDispatch();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const order = useSelector((state) => state.orders.order);
  const packedCounts = useSelector((state) => state.packages.packedCounts);

  useEffect(() => {
    if (!order.orderProducts) {
      return;
    }
    (async () => {
      const orderPackageProducts = order.orderProducts.map((p) => ({
        orderProductId: p.id,
        qty: p.qty - packedCounts[p.orderProductId] >= 0 ? p.qty - packedCounts[p.orderProductId] : p.qty,
        warehouseId: p.warehouse.id,
      }));
      const { payload: { data } } = await dispatch(generatePackageNumberRequest({ prefix: 'RE-' }));

      const d = {
        orderPackageProducts,
        number: data.number,
      };
      setForm({ ...form, ...d });
    })();
  }, [packedCounts, order.orderProducts]);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getProductsRequest(
        { ids: order.orderProducts?.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [order]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(createOrderReceiveRequest({
      ...form,
      orderId,
      warehouseId: form.orderPackageProducts[0].warehouseId,
      orderPackageProducts: form.orderPackageProducts.map((o) => ({
        orderProductId: o.orderProductId,
        qty: o.qty,
      })),
    }));
    if (data.status === 'error') {
      setErrors(data.errors || {});
    } else {
      await dispatch(getOrderPackagesRequest(orderId));
      await dispatch(getSingleOrderRequest(orderId));
      toast.success('Receive successfully created.');
      navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
    }
    setLoading(false);
  }, [form, orderId]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleDeleteItems = useCallback((id) => {
    const orderPackageProducts = form.orderPackageProducts.filter((o) => o.warehouseId !== id);
    setForm({ ...form, orderPackageProducts });
  }, [form]);

  return (
    <div className="package_form receive_form">
      <form onSubmit={handleSubmit}>
        <div className="top">
          <p className="title">New purchase receive</p>
          <div className="actions">
            <Button
              btnType="cancel"
              roundBorder
              onClick={() => navigate(`${location.pathname}${location.search}`, {
                state: {},
                replace: true,
              })}
            >
              Cancel
            </Button>
            <Button
              roundBorder
              type="submit"
              loading={loading}
              disabled={form.orderPackageProducts.length < 1 || _.uniqBy(form.orderPackageProducts, 'warehouseId').length > 1}
            >
              Save & receive
            </Button>
          </div>
        </div>
        <div className="row">
          <Input
            label="Purchase Order#"
            roundBorder
            onChange={(ev) => handleChange('number', ev.target.value)}
            value={order.number}
            readOnly
          />
          <Input
            label="Purchase Receive#"
            roundBorder
            onChange={(ev) => handleChange('number', ev.target.value)}
            value={form.number}
          />
          <Datepicker
            label="Received Date"
            onChange={(date) => handleChange('sourceCreatedAt', moment(date).format('YYYY-MM-DD'))}
            value={form.sourceCreatedAt ? moment(form.sourceCreatedAt).toDate() : undefined}
          />
        </div>
        {_.uniqBy(form.orderPackageProducts, 'warehouseId').length > 1 ? (
          <div className="warning">
            <InfoIcon />
            <div>
              <p>You can't create receive yet </p>
              <span>The order products are in different warehouses, please make internal transfer or manually add stock to continue.</span>
            </div>
          </div>
        ) : null}
        {_.map(
          _.groupBy(order.orderProducts
            ?.filter((p) => form.orderPackageProducts
              .some((or) => or.orderProductId === p.id)), 'warehouse.id'),
          (w, o) => (
            <div key={o}>
              <div className="row_title">
                <p>{w[0].warehouse.title}</p>
                {_.uniqBy(form.orderPackageProducts, 'warehouseId').length > 1
                  ? <span onClick={() => handleDeleteItems(o)}>Delete items</span> : null}
              </div>
              <div className="table_wrapper">
                <table className="table">
                  <thead className="table_thead">
                    <tr className="table_thead_tr">
                      <th className="table_thead_tr_th">Product</th>
                      <th className="table_thead_tr_th ordered">Ordered</th>
                      <th className="table_thead_tr_th packed">Received</th>
                      <th className="table_thead_tr_th">Qty to receive</th>
                    </tr>
                  </thead>
                  <tbody className="table_tbody">
                    {w?.map((p) => {
                      const el = form.orderPackageProducts.findIndex((a) => a.orderProductId === p.id);
                      return (
                        <tr
                          className="table_tbody_tr"
                          key={p.id}
                        >
                          <td className="table_tbody_tr_td product_title">
                            <div className="info">
                              <img
                                style={{ height: 30, width: 30 }}
                                src={products?.find((f) => f.id === p.productId)?.images?.[0]?.src
                                || products?.find((f) => f.id === p.productId)?.images?.[0]?.medium
                                || noImg}
                                alt=""
                              />
                              <Link to={`/products/${p.productId}`} target="_blank">{p.title}</Link>
                            </div>
                          </td>

                          <td className="table_tbody_tr_td ordered">
                            {p.qty}
                          </td>
                          <td className="table_tbody_tr_td packed">
                            {packedCounts[p.productId] || 0}
                          </td>
                          <td className="table_tbody_tr_td qty_input">
                            <Input
                              onChange={(ev) => handleChange(`orderPackageProducts[${el}]`, {
                                orderProductId: p.id,
                                qty: ev.target.value,
                                warehouseId: p.warehouseId,
                              })}
                              value={form.orderPackageProducts[el]?.qty}
                              type="number"
                              readOnly={p.qty - packedCounts[p.productId] <= 0}
                              error={_.get(errors, `orderPackageProducts[${el}].qty`, '')}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ),
        )}
        <div>
          <Input
            textarea
            roundBorder
            label="Note"
            placeholder="Enter any notes to be displayed in your transaction"
            value={form.note}
            onChange={(ev) => handleChange('note', ev.target.value)}
            error={errors.note}
          />
        </div>
      </form>
    </div>
  );
}

export default ReceiveForm;
