import React, {
  useCallback, useLayoutEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Api from '../../../../Api';
import Input from '../../../_common/Form/Input';
import Loader from '../../../_common/Loader/Loader';
import Button from '../../../_common/Form/Button';
import Utils from '../../../../helpers/Utils';
import noImage from '../../../../assets/icons/default.svg';
import { ReactComponent as NotFoundIcon } from '../../../../assets/icons/notFound/import_not_found.svg';
import WarningModal from '../../../_common/Modal/WarningModal';

const tableHeader = [
  {
    'title-unlinked': 'Matching products',
    'title-linked': 'Linked products',
    'title-searched': 'Products',
    path: 'title',
  },
  {
    title: 'SKU',
    path: 'sku',
  },
  {
    title: 'Shop',
    path: 'shopType',
  },
];

const productTypes = 'simple,variant,bundle';

const LinkedTable = ({
  data, shopId, productsFilterLoading, updateProduct,
}) => {
  const { type } = useParams();

  const timeout = useRef();

  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState([]);

  const [modalInfo, setModalInfo] = useState({});

  const [loading, loadingToggle] = useState(false);
  const [saveLoading, saveLoadingToggle] = useState(false);

  useLayoutEffect(() => {
    if (!productsFilterLoading) {
      setProducts(data.products || []);
      setSearchValue('');
    }
  }, [data.id, productsFilterLoading]);

  const searchProducts = useCallback((value) => {
    setSearchValue(value);
    loadingToggle(true);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      if (value.trim()) {
        const { data: { products: productsLists } } = await Api.getProducts({
          s: value, type: productTypes, excludeShopId: shopId,
        });

        setProducts(productsLists);
      } else {
        setProducts(data.products);
      }

      loadingToggle(false);
    }, 500);
  }, [data]);

  const openingModal = useCallback((product, unlink) => {
    setModalInfo({ product, unlink });
  }, []);

  const save = useCallback(async () => {
    const { product, unlink } = modalInfo;
    saveLoadingToggle(true);

    await updateProduct(product, unlink);
    saveLoadingToggle(false);
    setModalInfo({});
  }, [modalInfo]);

  return (
    <div className="listings_page_right_block">
      {productsFilterLoading
        ? <Loader />

        : (
          <>
            <h2>{searchValue ? 'Search result' : type === 'linked' ? 'Linked products' : 'Matching products'}</h2>

            <p className="listings_page_right_block_item_count">{`${products.length} Items`}</p>

            {data.id
              ? (
                <>
                  <Input
                    placeholder="Search by item or SKU"
                    roundBorder
                    size="small"
                    search
                    value={searchValue}
                    onChangeText={(value) => searchProducts(value)}
                    wrapperClassName="product_search_field"
                  />

                  <div className="table_wrapper">
                    {products.length
                      ? (
                        <table className="table">
                          {loading && <Loader />}

                          <thead className="table_thead">
                          <tr className="table_thead_tr">
                            {tableHeader.map((h) => (
                              <th key={h.path} className={classNames('table_thead_tr_th', h.path)}>
                                {h.path === 'title' ? h[`title-${searchValue ? 'searched' : type}`] : h.title}
                              </th>
                            ))}

                            <th />
                          </tr>
                          </thead>

                          <tbody className="table_tbody">
                          {products.map((p) => {
                            const isLinked = !!data.products.find((d) => d.id === p.id);

                            return (
                              <tr key={p.id} className="table_tbody_tr">
                                {tableHeader.map((h) => (
                                  <td key={h.path} className={classNames('table_tbody_tr_td', h.path)}>
                                    {h.path === 'title'
                                      ? (
                                        <div className="listings_page_right_block_product_title">
                                          <img src={p.images?.[0]?.src || noImage} alt="img" />

                                          <p className="listings_page_left_block_product_title">{p.title}</p>
                                        </div>
                                      )

                                      : h.path === 'shopType'
                                        ? (
                                          <div className="listings_page_right_block_product_shop_logo">
                                            {Utils.getAppIcon(p, true)}
                                          </div>
                                        )

                                        : p[h.path]}
                                  </td>
                                ))}

                                <td className="table_tbody_tr_td actions">
                                  <Button
                                    color={isLinked ? '#EB0000' : '#1472FF'}
                                    btnType="transparent"
                                    size="small"
                                    data-scope={isLinked ? 'DELETE_PRODUCT_LISTINGS' : 'WRITE_PRODUCT_LISTINGS'}
                                    onClick={() => openingModal(p, isLinked)}
                                  >
                                    {`${isLinked ? 'Unlink' : 'Link'} item`}
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                        </table>
                      )

                      : (
                        <div className="data_not_found">
                          {loading && <Loader />}

                          <NotFoundIcon />

                          <h1>Products not found</h1>
                        </div>
                      )}
                  </div>
                </>
              )

              : (
                <div className="data_not_found">
                  <NotFoundIcon />

                  <h1>Products not found</h1>
                </div>
              )}
          </>
        )}

      <WarningModal
        isOpen={!_.isEmpty(modalInfo)}
        onClose={() => setModalInfo({})}
        onSaveClick={save}
        loading={saveLoading}
        text={`Are you sure you want to ${modalInfo.unlink ? 'unlink' : 'link'} this product?`}
        btnText="Save"
      />
    </div>
  );
};

export default LinkedTable;
