import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import Button from '../../../_common/Form/Button';
import Loader from '../../../_common/Loader/Loader';
import moment from 'moment/moment';

function AdminTrialsChart({ trials, loading, interval }) {
  const chart = useMemo(() => ({
    series: [{
      name: 'series1',
      data: trials?.subscriptions?.map((d) => d.count) || [],
    }],
    options: {
      chart: {
        height: 350,
        type: 'area',

        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        // type: 'datetime',
        categories: trials?.subscriptions?.map((d) => moment(d.time, 'YYYY/MM/DD')
          .format('MMM')) || [], // interval === 'day' ? 'MMM D, YYYY'
      },
      colors: ['#109B48'],
    },
  }), [trials]);

  return (
    <div className="admin_trials_chart">
      {loading ? <Loader className="admin_chart_wrapper_loader" /> : (
        <>
          <div className="admin_chart_header">
            <div className="admin_chart_header_left">
              <h3>How many users use trial</h3>

              <Button href="#" btnType="transparent" color="#1472FF" title="View all" />
            </div>

            <div className="admin_chart_header_count">{trials.total}</div>
          </div>

          <Chart
            options={chart.options}
            series={chart.series}
            // width="94%"
            type="area"
            height={425}
          />
        </>
      )}
    </div>
  );
}

export default AdminTrialsChart;
