import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Datepicker from '../../../_common/Form/Datepicker';
import Select from '../../../_common/Form/Select';
import noImg from '../../../../assets/icons/no_img.svg';
import {
  generateReturnNumberRequest,
  returnOrderRequest,
  singleOrderReturnsRequest,
} from '../../../../store/actions/orderReturns';
import { getProductsRequest } from '../../../../store/actions/products';
import { getOrderPackagesRequest } from '../../../../store/actions/packages';
import { getSingleOrderRequest } from '../../../../store/actions/orders';

function SalesReturnForm() {
  const [form, setForm] = useState({
    orderReturnProducts: [],
    sourceCreatedAt: moment().format('YYYY-MM-DD'),
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const dispatch = useDispatch();
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const order = useSelector((state) => state.orders.order);
  const packages = useSelector((state) => state.packages.orderPackages);
  const warehouses = useSelector((state) => state.settings.generalList.warehouses);
  const returnedCount = useSelector((state) => state.orderReturns.returnedCount);

  useEffect(() => {
    dispatch(singleOrderReturnsRequest(orderId));
    dispatch(getOrderPackagesRequest(orderId));
  }, [orderId]);

  useEffect(() => {
    if (!form.number) {
      (async () => {
        const { payload } = await dispatch(generateReturnNumberRequest());
        setForm((prev) => ({ ...prev, number: payload.data.number }));
      })();
    }
  }, [form]);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getProductsRequest(
        { ids: order.orderProducts?.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [order]);

  useEffect(() => {
    if (!order.orderProducts) {
      return;
    }
    const orderReturnProducts = {};
    const data = packages?.filter((p) => p.status !== 'packed').map((p) => p.orderPackageProducts).flat(1);
    data.forEach((d) => {
      if (orderReturnProducts[d.productId]) {
        orderReturnProducts[d.productId].qty += d.qty;
      } else {
        orderReturnProducts[d.productId] = { ...d };
      }
      orderReturnProducts[d.productId].returnedCount = returnedCount[d.productId];
      const toReturn = +orderReturnProducts[d.productId].qty - +(returnedCount[d.productId] || 0);
      orderReturnProducts[d.productId].toReturn = toReturn >= 0 ? toReturn : 0;
    });

    handleChange('orderReturnProducts', Object.values(orderReturnProducts));
  }, [order.orderProducts, packages, returnedCount]);

  useEffect(() => {
    if (!form.warehouseId) {
      const dif = warehouses.find((w) => w.isDefault);
      handleChange('warehouseId', dif?.id);
    }
  }, [warehouses, form.warehouseId]);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload: { data } } = await dispatch(returnOrderRequest({
      ...form,
      orderReturnProducts: form.orderReturnProducts.map((o) => (
        {
          qty: o.toReturn,
          productId: o.productId,
        }
      )),
      orderId,
    }));
    if (data.status === 'error' && !_.isEmpty(data.errors)) {
      setErrors(data.errors);
    } else if (data.status === 'error' && _.isEmpty(data.errors)) {
      toast.error(data.message);
    } else {
      await dispatch(singleOrderReturnsRequest(orderId));
      await dispatch(getSingleOrderRequest(orderId));
      toast.success('Sale return successfully created.');
      navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
    }
    setLoading(false);
  }, [form, orderId]);


  return (
    <div className="sales_return_form package_form">
      <form onSubmit={handleSubmit}>
        <div className="top">
          <p className="title">New Return</p>
          <div className="actions">
            <Button
              btnType="cancel"
              roundBorder
              onClick={() => navigate(`${location.pathname}${location.search}`, {
                state: {},
                replace: true,
              })}
            >
              Cancel
            </Button>
            <Button roundBorder type="submit" loading={loading} disabled={!form.orderReturnProducts?.length}>
              Return
            </Button>
          </div>
        </div>
        <div className="row">
          <Input
            label="Return#"
            roundBorder
            onChange={(ev) => handleChange('number', ev.target.value)}
            value={form.number}
            error={errors.number}
          />
          <Datepicker
            label="Date"
            onChange={(date) => handleChange('sourceCreatedAt', moment(date).format('YYYY-MM-DD'))}
            value={form.sourceCreatedAt ? moment(form.sourceCreatedAt).toDate() : undefined}
            error={errors.sourceCreatedAt}
          />
          <Select
            label="Warehouse"
            options={warehouses}
            roundBorder
            labelPath="title"
            valuePath="id"
            onChange={(val) => handleChange('warehouseId', val)}
            value={form.warehouseId}
            error={errors.warehouseId}
          />
        </div>
        <div className="row">
          <Input
            label="Reason"
            roundBorder
            onChange={(ev) => handleChange('reason', ev.target.value)}
            value={form.reason}
            error={errors.reason}
          />
        </div>
        <div className="table_wrapper">
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Item</th>
                <th className="table_thead_tr_th ordered">Shipped</th>
                <th className="table_thead_tr_th packed">Returned</th>
                <th className="table_thead_tr_th">Receivable qty</th>
              </tr>
            </thead>
            <tbody className="table_tbody">
              {form.orderReturnProducts
                ?.map((p, i) => {
                  const product = products?.find((f) => f.id === p.productId);
                  return (
                    <tr
                      className="table_tbody_tr"
                      key={p.id}
                      data-attr={p.productId}
                    >
                      <td className="table_tbody_tr_td product_title">
                        <div className="info" style={{ alignItems: 'center' }}>
                          <img
                            style={{ height: 30, width: 30 }}
                            src={product?.images?.[0]?.src
                            || product?.images?.[0]?.medium
                            || noImg}
                            alt=""
                          />
                          <Link to={`/products/${p.productId}`} target="_blank">{product?.title}</Link>

                        </div>
                      </td>
                      <td className="table_tbody_tr_td">
                        {p?.qty || 0}
                      </td>
                      <td className="table_tbody_tr_td">
                        {p?.returnedCount || 0}
                      </td>
                      <td className="table_tbody_tr_td qty_input">
                        <Input
                          onChange={(ev) => handleChange(`orderReturnProducts[${i}].toReturn`, ev.target.value)}
                          value={p?.toReturn}
                          type="number"
                          error={_.get(errors, `orderReturnProducts[${i}].qty`, '')}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default SalesReturnForm;
