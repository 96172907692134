import axios from 'axios';
import _ from 'lodash';
import toFormData from 'object-to-formdata';
import fileDownload from 'js-file-download';
import Account from './helpers/Account';
import Utils from './helpers/Utils';

const {
  // REACT_APP_API_URL = 'https://amused-pet-racer.ngrok-free.app', // Raz
  // REACT_APP_API_URL = 'https://amazing-herring-amusing.ngrok-free.app', // Tiko
  // REACT_APP_API_URL = 'https://settling-bunny-happy.ngrok-free.app', // Argisht
  // REACT_APP_API_URL = 'https://6e2f-45-143-207-243.ngrok-free.app', // Gev
  // REACT_APP_API_URL = 'https://badger-deep-generally.ngrok-free.app', // Karen
  // REACT_APP_API_URL = 'https://96a7-45-143-207-243.ngrok-free.app', // Sergey
  // REACT_APP_API_URL = 'https://c9a4-45-143-207-243.ngrok-free.app', // Tatev
  // REACT_APP_API_URL = 'http://192.168.31.118:4010', // Ofelya
  REACT_APP_API_URL = 'https://bapi.eswap.ch',
} = process.env;

const updateToken = async () => {
  const refreshToken = Account.getToken('refreshToken');

  if (refreshToken) {
    try {
      const { data } = await axios.post(
        `${REACT_APP_API_URL}/api/token/refresh`,
        {
          refreshToken,
        },
        { headers: { Authorization: `Bearer ${refreshToken}` } },
      );

      if (data?.token) {
        Account.setToken(data.token);
        Account.setToken(data.token, 'refreshToken');

        // getAccount
        return data?.token;
      }
    } catch (e) {
      Account.logout();
    }
  } else {
    Account.logout();
  }
};

const api = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    'ngrok-skip-browser-warning': 'skip-browser-warning',
    'X-Device-Id': Utils.getDeviceId(),
  },
});

api.interceptors.request.use(async (config) => {
  const token = Account.getToken();
  const userId = Account.getUserId();
  const ownerId = Account.getOwnerId();

  if (token) {
    const expiryDate = Account.getExpiryDate();

    if (expiryDate <= new Date()) {
      // const newToken =  await updateToken();
      //  token = newToken
    }

    config.headers.Authorization = token;
  }

  if (userId) {
    config.headers['X-User-Id'] = userId;
    config.headers['X-Owner-Id'] = ownerId;
  }

  return config;
}, (e) => Promise.reject(e));

api.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    if (window.location.pathname !== '/login') {
      Account.logout();
    }
  }

  return Promise.reject(error);
});

const cancelTokens = {};

class Api {
  static cancel(tokenName) {
    if (cancelTokens[tokenName]) {
      cancelTokens[tokenName].abort();

      delete cancelTokens[tokenName];
    }
  }

  static cancelToken(tokenName) {
    cancelTokens[tokenName] = new AbortController();
    return cancelTokens[tokenName].signal;
  }

  static login({ email, password, integrationHash }) {
    return api.post('/users/login', { email, password, integrationHash });
  }

  static register(data) {
    return api.post('/users/register', data);
  }

  static getSetupInfo() {
    return api.get('/users/register/complete');
  }

  static deleteDemoProducts() {
    return api.delete('/users/demo');
  }

  static getAccount(userId) {
    return api.get('/users/profile', {
      headers: {
        'X-Owner-Id': userId,
      },
    });
  }

  static setIntegrationInterval(data) {
    return api.post('/integrations/import-interval', data);
  }

  static updateAccount({ firstName, lastName, image }) {
    return api.put(
      '/users/profile',
      { firstName, lastName, image },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  }

  static createAccountTarget(data) {
    return api.post('/users/targets', data);
  }

  static updateAccountPassword({ currentPassword, newPassword } = {}) {
    return api.put('/users/profile/password', { currentPassword, newPassword });
  }

  static getCountries(params) {
    return api('/countries', params);
  }

  static getRegions(countryCode) {
    return api(`/countries/regions/${countryCode}`);
  }

  static getRegionsByName(regionsName) {
    return api('/countries/regions', { params: { s: regionsName } });
  }

  static getSettings(names = []) {
    return api.get('/settings', {
      params: {
        name: names.join(','),
      },
    });
  }

  static updateSettings(data) {
    return api.put('/settings/update', { ...data });
  }

  static getWarehouses({ s, countryCode, id } = {}) {
    return api.get('/warehouses', { params: { s, countryCode, id } });
  }

  static getWarehouse(id) {
    return api.get(`/warehouses/single/${id}`);
  }

  static createWarehouse({
    title,
    countryCode,
    regionId,
    city,
    address,
    phone,
    workersEmail,
    isVirtual,
    postalCode,
    addressNumber,
    space,
    code,
    details,
    isDefault,
    isUnsalable,
  } = {}) {
    return api.post('/warehouses/create', {
      title,
      countryCode,
      regionId,
      city,
      address,
      phone,
      workersEmail,
      isVirtual,
      postalCode,
      addressNumber,
      space,
      code,
      details,
      isDefault,
      isUnsalable,
    });
  }

  static updateWarehouse({
    id,
    title,
    countryCode,
    regionId,
    city,
    address,
    phone,
    workersEmail,
    isVirtual,
    postalCode,
    addressNumber,
    space,
    code,
    details,
    isDefault,
    isUnsalable,
  } = {}) {
    return api.put(`/warehouses/update/${id}`, {
      title,
      countryCode,
      regionId,
      city,
      address,
      phone,
      workersEmail,
      isVirtual,
      postalCode,
      addressNumber,
      space,
      code,
      details,
      isDefault,
      isUnsalable,
    });
  }

  static deleteWarehouse(id) {
    return api.delete(`/warehouses/delete/${id}`);
  }

  static getWarehouseBinLocations({
    warehouseId, limit = 20, page = 1, sort, sortBy, s,
  } = {}) {
    this.cancel('getWarehouseBinLocations');

    return api.get(`/warehouses/${warehouseId}/bin-locations`, {
      params: {
        limit, page, sort, sortBy, s,
      },
      signal: this.cancelToken('getWarehouseBinLocations'),
    });
  }

  static createWarehouseBinLocation({ warehouseId, title }) {
    return api.post(`/warehouses/${warehouseId}/bin-locations/create/`, { title });
  }

  static updateWarehouseBinLocation({ warehouseId, id, title }) {
    return api.put(`/warehouses/${warehouseId}/bin-locations/update/${id}`, { title });
  }

  static deleteWarehouseBinLocation({ warehouseId, id }) {
    return api.delete(`/warehouses/${warehouseId}/bin-locations/delete/${id}`);
  }

  static setDefaultWarehouse(id) {
    return api.put(`/warehouses/set-default/${id}`);
  }

  static getCompanies(data = {}) {
    const { userId, ...params } = data;

    return api.get('/companies', {
      params,
      headers: {
        'X-Owner-Id': userId,
      },
    });
  }

  static getSingleCompany(id) {
    return api.get(`/companies/single/${id}`);
  }

  static setDefaultCompany(id) {
    return api.put(`/companies/set-default/${id}`);
  }

  static deleteCompany(id) {
    return api.delete(`/companies/delete/${id}`);
  }

  static createCompany({
    name,
    logo,
    email,
    phone,
    fax,
    address,
    size,
    industry,
    registerNumber,
    vatNumber,
    countryCode,
    regionId,
    city,
    postalCode,
    isDefault,
  }) {
    return api.post('/companies/create', {
      name,
      logo,
      email,
      phone,
      fax,
      address,
      size,
      industry,
      registerNumber,
      vatNumber,
      countryCode,
      regionId,
      city,
      postalCode,
      isDefault,
    }, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static updateCompany({
    name,
    logo,
    email,
    phone,
    fax,
    address,
    size,
    industry,
    registerNumber,
    vatNumber,
    countryCode,
    regionId,
    city,
    postalCode,
    isDefault,
    id,
  }) {
    return api.put(`/companies/update/${id}`, {
      name,
      logo,
      email,
      phone,
      fax,
      address,
      size,
      industry,
      registerNumber,
      vatNumber,
      countryCode,
      regionId,
      city,
      postalCode,
      isDefault,
    }, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static getTaxTypes() {
    return api.get('/taxes');
  }

  static updateTaxTypes({
    code, line, rate, title, id,
  }) {
    return api.put(`/taxes/update/${id}`, {
      code, line, rate, title,
    });
  }

  static createTaxTypes({
    code, line, rate, title, type,
  }) {
    return api.post('/taxes/create', {
      code, line, rate, title, type,
    });
  }

  static deleteTaxTypes(id) {
    return api.delete(`/taxes/delete/${id}`);
  }

  static getSettingsList(params) {
    return api.get('/settings/list/single', { params });
  }

  static updateSettingsList(data) { // settingsListUpdate
    return api.put('/settings/list/update', data);
  }

  static createSettingsList(data) { // settingsListCreate
    return api.post('/settings/list/create', data);
  }

  static deleteSettingsList(data) { // settingsListDelete
    return api.delete('/settings/list/delete', { params: data });
  }

  static getCustomers(params) {
    return api.get('/customers', { params });
  }

  static createCustomer(data) {
    return api.post('/customers/create', data, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  static updateCustomer(id, data) {
    return api.put(`/customers/update/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  static getSingleCustomer(id) {
    return api.get(`/customers/single/${id}`);
  }

  static deleteCustomer(id) {
    return api.delete(`/customers/delete/${id}`);
  }

  static getOrdersTags(params) {
    return api.get('/orders/tags', { params });
  }

  static bulkPayOrders({
    orderId, amount, paymentType, sourceCreatedAt,
  }) {
    return api.post(`/orders/${orderId}/payment/create`, { amount, paymentType, sourceCreatedAt });
  }

  static changeOrdersTags({ orderId, tagIds }) {
    return api.put(`orders/update/${orderId}/tags`, { tagIds });
  }

  static createOrderTag(data) {
    return api.post('/orders/tags/create', data);
  }

  static updateOrderTag({ id, name, color }) {
    return api.put(`/orders/tags/update/${id}`, { name, color });
  }

  static deleteOrderTag(id) {
    return api.delete(`/orders/tags/delete/${id}`);
  }

  // Subscriptions

  static getCurrentSubscription(userId) {
    return api.get('/subscriptions/current', {
      headers: {
        'X-Owner-Id': userId,
      },
    });
  }

  static getCurrentSubscriptionInvoices(data = {}) {
    const { id = '', userId = '' } = data;

    return api.get('/subscriptions/invoices', {
      params: { startingAfter: id },
      headers: {
        'X-Owner-Id': userId,
      },
    });
  }

  static creteOrUpdateSubscription(data) {
    return api.put('/subscriptions/update', data);
  }

  static cancelSubscription() {
    return api.delete('/subscriptions/cancel');
  }

  static resumeSubscription() {
    return api.get('/subscriptions/resume');
  }

  static updateSubscriptionTrialEndDate(data) {
    return api.put('/subscriptions/update/trial/date', data);
  }

  static getPaymentMethods() {
    return api.get('/subscriptions/payment-methods');
  }

  static updatePaymentMethod(data) {
    return api.get('/subscriptions/payment-methods/update', data);
  }

  static attachPaymentMethod(id, isDefault) {
    return api.post('/subscriptions/payment-methods/attach', { paymentMethod: id, isDefault });
  }

  static deletePaymentMethod(id) {
    return api.delete('/subscriptions/payment-methods/detach', { params: { paymentMethod: id } });
  }

  static getCoupon(promoCode) {
    return api.get(`/subscriptions/payment-methods/coupon/${promoCode}`);
  }

  static createSetupIntent(email) {
    return api.get('/subscriptions/payment-methods/create/setup-intent', { params: { email } });
  }

  static setDefaultPaymentMethod(id) {
    return api.put('/subscriptions/payment-methods/set/default', { paymentMethod: id });
  }

  static getPlansList({ interval = ' year', currency = 'USD' }) {
    return api.get('/subscriptions/plans', { params: { interval, currency } });
  }

  static getRolesList() {
    return api.get('/subscriptions/roles/list');
  }

  static getRolesDefaultScopes() {
    return api.get('/subscriptions/roles/scopes');
  }

  static getRolesAdminScopes() {
    return api.get('/subscriptions/roles/admin/scopes');
  }

  static getSingleRole(id) {
    return api.get('/subscriptions/roles/single', { params: { id } });
  }

  static createRole(data) {
    return api.post('/subscriptions/roles/create', data);
  }

  static updateRole(data) {
    return api.put('/subscriptions/roles/update', data);
  }

  static deleteRole(id) {
    return api.delete('/subscriptions/roles/delete', { params: { id } });
  }

  static getPlanListForAdmin({
    paginated, page = 1, sort, sortBy,
  }) {
    return api.get('/subscriptions/plans/private', {
      params: {
        paginated, page, sort, sortBy,
      },
    });
  }

  static getSinglePlan(id) {
    return api.get('/subscriptions/plans/private', {
      params: {
        paginated: true, page: 1, sort: 'desc', sortBy: 'createdAt', id,
      },
    });
  }

  static getPlanScopes() {
    return api.get('/subscriptions/plans/scopes');
  }

  static createPlan(data) {
    return api.post('/subscriptions/plans/create', data);
  }

  static updatePlan(data) {
    return api.put('/subscriptions/plans/update', data);
  }

  static deletePlan(productId) {
    return api.delete('/subscriptions/plans/delete', { params: { productId } });
  }

  static createPlanPrice(data) {
    return api.post('/subscriptions/plans/prices/create', data);
  }

  static archivePlanPrice(priceId) {
    return api.delete('/subscriptions/plans/prices/archive', { params: { priceId } });
  }

  // Subscription end

  // Price List start

  static getPriceList(params) {
    return api.get('/price-lists', { params });
  }

  static getPriceListSingle(id) {
    return api.get(`/price-lists/single/${id}`);
  }

  static getPriceListProducts({ id, page }) {
    return api.get(`/products?priceListId=${id}`, { params: { page } });
  }

  static createPrice({
    name, type, currencyCode, companyId, isDefault,
  }) {
    return api.post('/price-lists/create', {
      name, type, currencyCode, companyId, isDefault,
    });
  }

  static updatePrice({
    companyId, name, type, currencyCode, isDefault, priceId,
  }) {
    return api.put(`/price-lists/update/${priceId}`, {
      name, type, currencyCode, isDefault, companyId,
    });
  }

  static deletePrice(id) {
    return api.delete(`/price-lists/delete/${id}`);
  }

  // Price List end

  // Integrations start

  static getIntegrations({ userId = '', ...params } = {}) {
    return api.get('/integrations', {
      params,
      headers: {
        'X-Owner-Id': userId,
      },
    });
  }

  static getIntegration(id) {
    return api.get(`/integrations/single/${id}`);
  }

  static uninstallIntegration(id) {
    return api.delete(`/integrations/uninstall/${id}`);
  }

  static getIntegrationWarehouses(id) {
    return api.get(`/integrations/warehouses/${id}`);
  }

  static getIntegrationPriceList(id) {
    return api.get(`/integrations/price-lists/${id}`);
  }

  static getIntegrationStatuses(id) {
    return api.get(`/integrations/status-mapping/${id}`);
  }

  static testSMTPConnection({
    host, port, email, password, from, encryption, type,
  }) {
    return api.post('/messages/test', {
      host, port, email, password, from, encryption, type,
    });
  }

  static createIntegration(appName, {
    state, sellingPartnerId, spapiOauthCode, // for amazon
    url, db, username, password, // for odoo
    domainPrefix, // for vend
    host, port, user, filePath, type, // for ftp
    authenticationURL, hardExpirationTime, token, // for bananza
    accessToken, clientId, storeHash, accountUuid, // for bigcommerce (accountUuid -> for oAuth)
    clientSecret, sandbox, // for wayfair
    email, from, encryption, // for smtp
    code, storeId, integrationName, redirectUrl,
    apiKey, apiSecret, webhookKey, // for shipStation
    integrationId,
    apiUser, user_key, server_id, // for cartrover
    storeUrl, storeName, // for b2b
  }) {
    return api.post(`/integrations/create/${appName}`, {
      integrationId,
      state,
      sellingPartnerId,
      spapiOauthCode,
      code,
      email,
      encryption,
      from,
      storeId,
      integrationName,
      url,
      db,
      username,
      password,
      accessToken,
      storeHash,
      accountUuid,
      clientId,
      domainPrefix,
      redirectUrl,
      authenticationURL,
      hardExpirationTime,
      token,
      host,
      port,
      user,
      filePath,
      clientSecret,
      sandbox,
      type,
      apiKey,
      apiSecret,
      webhookKey,
      apiUser,
      user_key,
      server_id,
      storeUrl,
      storeName,
    });
  }

  static createShippingIntegration(appName, {
    integrationId, integrationName, accountId, firstName, lastName, email, company, address1, city, country, phone,
    state, zip, // for fedex
    accountNumber, agreements, // for ups
    siteId, // for dhl
    username, // for dpd
    contractId, customerNumber, password, paymentMethod, // for canada
  }) {
    return api.post(`/shipping/create/shippo/${_.snakeCase(appName)}`, {
      integrationId,
      integrationName,
      accountId,
      firstName,
      lastName,
      email,
      company,
      address1,
      city,
      country,
      phone,
      state,
      zip,
      accountNumber,
      agreements,
      siteId,
      contractId,
      customerNumber,
      password,
      paymentMethod,
      username,
    });
  }

  static uninstallShippingIntegration(id) {
    return api.delete(`/shipping/uninstall/shippo/${id}`);
  }

  static installApp(appName, data) {
    return api.post(`/integrations/install/${appName}`, data);
  }

  static installAccounting(appName, data) {
    return api.post(`/accounting/install/${appName}`, data);
  }

  static createAccounting(appName, data, params) {
    return api.post(`/accounting/create/${appName}`, data, { params });
  }

  static updateAccounting(id, {
    accountingSettings, name, currencyCode,
  }) {
    return api.put(`/accounting/update/${id}`, {
      accountingSettings, name, currencyCode,
    });
  }

  static getAccounting(appName, id) {
    return api.get(`/accounting/accounts/${appName}/${id}`);
  }

  static deleteAccounting(appName, id) {
    return api.delete(`/accounting/uninstall/${appName}/${id}`);
  }

  static getAccountingTaxRates(appName, id) {
    return api.get(`/accounting/tax-rates/${appName}/${id}`);
  }

  static getAccountingPaymentMethods(appName, id) {
    return api.get(`/accounting/payment-methods/${appName}/${id}`);
  }

  static getAccountingLedgers() {
    return api.get('/accounting/ledgers');
  }

  static createAccountingLedgers(data) {
    return api.post('/accounting/ledgers', data);
  }

  static updateIntegration({
    id,
    currencyCode,
    isEnabled,
    name,
    orderImportStartDate,
    orderStatusMapping,
    priceListsMapping,
    settings,
    warehouseMapping,
    configs,
  }) {
    return api.put(`/integrations/update/${id}`, {
      currencyCode,
      isEnabled,
      name,
      orderImportStartDate,
      orderStatusMapping,
      priceListsMapping,
      settings,
      warehouseMapping,
      configs,
    });
  }

  static integrationImport({ shop, type, integrationId }) {
    return api.get(`/integrations/import/${shop}`, { params: { type, integrationId } });
  }

  static switchIntegration({ integrationId, isEnabled }) {
    return api.put(`/integrations/switch/${integrationId}`, { isEnabled });
  }

  static installOtherApp(appName, data) {
    return api.post(`/other-integrations/install/${appName}`, data);
  }

  static createOtherIntegration(appName, {
    integrationId, integrationName, code, redirectUrl, // for mailchimp
  }) {
    return api.post(`/other-integrations/create/${appName}`, {
      integrationId, integrationName, code, redirectUrl,
    });
  }

  static uninstallOtherIntegration(id) {
    return api.delete(`/other-integrations/uninstall/${id}`);
  }

  static getOtherIntegrationAudiences(appName, id) {
    return api.get(`/other-integrations/${appName}/audiences/${id}`);
  }

  static sendOtherIntegrationCustomers({ appName, id, ...data }) {
    return api.post(`/other-integrations/${appName}/send-customers/${id}`, data);
  }

  // Integrations end

  // Automation start

  static getAutomationList({
    page = 1, limit = 20, sort, sortBy,
  } = {}) {
    return api.get('/automations/list', {
      params: {
        page, limit, sort, sortBy,
      },
    });
  }

  static getAutomation(id) {
    return api.get(`/automations/single/${id}`);
  }

  static createAutomation({
    name, event, query, isActive, type,
  }) {
    return api.post('/automations/create', {
      name, event, query, isActive, type,
    });
  }

  static updateAutomation({
    id, name, event, query, isActive, type,
  }) {
    return api.put(`/automations/update/${id}`, {
      name, event, query, isActive, type,
    });
  }

  static deleteAutomation(id) {
    return api.delete(`/automations/delete/${id}`);
  }

  // Automation end

  static getPriceLists(params = {}) {
    return api.get('/price-lists', { params });
  }

  static generateOrderNumber(params) {
    return api.get('/orders/generate-number', { params });
  }

  static getProducts(params) {
    return api.get('/products', { params });
  }

  static productArchive(data) {
    return api.put('/products/archive', data);
  }

  static getSingleProduct(id) {
    return api.get(`/products/single/${id}`);
  }

  static generateProductBarcode(data) {
    return api.post('/products/generate-barcode', data);
  }

  static getProductReorderRule(id) {
    return api.get(`/products/reorders?productId=${id}`);
  }

  static bulkProductReorderRules(data) {
    return api.post('/products/reorders/create', data);
  }

  static bulkProductUpdate(data) {
    return api.post('/products/bulk', data);
  }

  static createProduct(data) {
    return api.post('/products/create', toFormData.serialize(data, { indices: true }), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static updateProduct(data, id) {
    return api.put(`/products/update/${id}`, toFormData.serialize(data, { indices: true }), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static getReorders(params) {
    return api.get('/products/reorders', { params });
  }

  static createReorder(data) {
    return api.post('/products/reorders/create', data);
  }

  static updateReorder(id, data) {
    return api.put(`/products/reorders/update/${id}`, data);
  }

  static deleteReorder(id) {
    return api.delete(`/products/reorders/delete/${id}`);
  }

  static getSingleReorder(id) {
    return api.get(`/products/reorders/single/${id}`);
  }

  static generateReorderNumber() {
    return api.get('/products/reorders/generate-number');
  }

  static getProductsBrands(params) {
    return api.get('/products/brands', { params });
  }

  static createProductBrand(data) {
    return api.post('/products/brands/create', data);
  }

  static updateProductBrand({ id, name }) {
    return api.put(`/products/brands/update/${id}`, { name });
  }

  static deleteProductBrand(id) {
    return api.delete(`/products/brands/delete/${id}`);
  }

  static getProductsTags(params) {
    return api.get('/products/tags', { params });
  }

  static createProductTag(data) {
    return api.post('/products/tags/create', data);
  }

  static updateProductTag({ id, name, color }) {
    return api.put(`/products/tags/update/${id}`, { name, color });
  }

  static deleteProductTag(id) {
    return api.delete(`/products/tags/delete/${id}`);
  }

  static createOrder(data) {
    return api.post('/orders/create', data);
  }

  static updateOrder(id, data) {
    return api.put(`/orders/update/${id}`, data);
  }

  static getOrders(params) {
    this.cancel('getOrders');
    return api.get('/orders', {
      signal: this.cancelToken('getOrders'),
      params,
    });
  }

  static getSingleOrder(id) {
    return api.get(`/orders/single/${id}`);
  }

  static calculateOrderPrice(data) {
    this.cancel('calculateOrderPrice');
    return api.post('/orders/calculate', data, {
      signal: this.cancelToken('calculateOrderPrice'),
    });
  }

  static updateCustomerAddress(customerId, id, data) {
    return api.put(`/customers/addresses/${customerId}/update/${id}`, data);
  }

  static viewOrderQuotation(id, type, params, responseType = 'blob') {
    return api.get(`/orders/documents/${id}/order/${type}`, {
      params,
      responseType,
    });
  }

  static getTemplatesList(params) {
    return api.get('/templates', { params });
  }

  static createCustomerAddress(customerId, data) {
    return api.post(`/customers/addresses/${customerId}/create`, data);
  }

  // Categories product start

  static getCategories(params) {
    return api.get('/products/categories', { params });
  }

  static createCategory(data) {
    return api.post('/products/categories/create', data);
  }

  static updateCategory(id, data) {
    return api.put(`/products/categories/update/${id}`, data);
  }

  static deleteCategory(id) {
    return api.delete(`/products/categories/delete/${id}`);
  }

  // Categories product end

  // CRM start

  static getCrmInvitationUsers({
    sortBy = 'createdAt', sort = 'desc', page = 1, s,
  } = {}) {
    return api.get('/users/crm/invites', {
      params: {
        sort, sortBy, page, s,
      },
    });
  }

  static getCrmChildUsers(params) {
    return api.get('/users/crm/child', { params });
  }

  static deleteCrmInviteUser(id) {
    return api.delete('/users/crm/invite/delete', { params: { id } });
  }

  static crmInviteUser(data) {
    return api.post('/users/crm/invite/send', { ...data });
  }

  static crmInvitedUserUpdate(data) {
    return api.put('/users/crm/child/user', data);
  }

  static crmActivateUser(data) {
    return api.post('/users/crm/set/password', data);
  }

  // CRM end

  // EmailTemplates start

  static getTemplates(type) {
    return api.get('/templates', { params: { type } });
  }

  static getTemplate(id) {
    return api.get(`/templates/single/${id}`);
  }

  static createTemplate({ type, html, style }) {
    return api.post('/templates/create', { type, html, style });
  }

  static updateTemplate(id, data) {
    return api.put(`/templates/update/${id}`, data);
  }

  static setDefaultTemplate(id) {
    return api.put(`/templates/set-default/${id}`);
  }

  static deleteTemplate(id) {
    return api.delete(`/templates/delete/${id}`);
  }

  static getOrderCommentsAndHistory(id) {
    return api.get(`/orders/${id}/history`);
  }

  static createOrderCommentsAndHistory(data) {
    return api.post('/orders/history/create', data);
  }

  static deleteOrderCommentsAndHistory(id) {
    return api.delete(`/orders/history/delete/${id}`);
  }

  static createOrderPackage(data) {
    return api.post('/orders/packages/create', data);
  }

  static shipPackage(id, data) {
    return api.put(`/orders/packages/status/shipped/${id}`, data);
  }

  static getOrderPackages(id) {
    return api.get(`/orders/${id}/packages`);
  }

  static deleteOrderPackage(id) {
    return api.delete(`/orders/packages/delete/${id}`);
  }

  // EmailTemplates end

  // UserDashboard start

  static getDashboardLayout() {
    return api.get('/settings', {
      params:
        { name: ['dashboardSettings', 'tabletDashboardSettings', 'mobileDashboardSettings'] },
    });
  }

  static updateDashboardLayout(data, name = 'dashboardSettings') {
    return api.put('/settings/update', { [name]: data });
  }

  static getDashboardStatusCount(data) {
    return api.get('dashboard/status/count', { params: { ...data } });
  }

  static getDashboardProductsCount(data) {
    return api.get('dashboard/products/count', { params: { ...data } });
  }

  static getDashboardBySalesChannels(data) {
    return api.get('dashboard/latest/orders', { params: { ...data } });
  }

  static getDashboardPurchaseOrders(data) {
    return api.get('dashboard/purchase/orders', { params: { ...data } });
  }

  static getDashboardTopProducts(data) {
    return api.get('dashboard/top/products', { params: { ...data } });
  }

  static getDashboardOrderSummary(data) {
    return api.get('dashboard/order/summary', { params: { ...data } });
  }

  static getDashboardLowQuantity(data) {
    return api.get('dashboard/low/quantity', { params: { ...data, limit: 5 } });
  }

  static getDashboardOutStock(data) {
    return api.get('dashboard/out/stock', { params: { ...data, limit: 5 } });
  }

  static getDashboardShops(data) {
    return api.get('dashboard/channel/list', { params: { ...data } });
  }

  // UserDashboard end

  // Reports start

  static getReportsOrder({ startDate, endDate }) {
    return api.get('/reports/get/count-of-new-orders-each-day', { params: { startDate, endDate } });
  }

  static getReportsSalesByStore({ startDate, endDate }) {
    return api.get('/reports/get/sales-by-store', { params: { startDate, endDate } });
  }

  static getReportsOrderAging({ sla, startDate, endDate } = {}) {
    return api.get('/reports/get/open-order-aging', { params: { sla, startDate, endDate } });
  }

  static getReportsShipments({ startDate, endDate } = {}) {
    return api.get('/reports/get/shipment-data', { params: { startDate, endDate } });
  }

  static getReportsShipmentsByCarrier({ startDate, endDate }) {
    return api.get('/reports/get/shipments-by-carrier', { params: { startDate, endDate } });
  }

  static getReportsShipmentsByClass({ startDate, endDate } = {}) {
    return api.get('/reports/get/shipment-by-class', { params: { startDate, endDate } });
  }

  static getReportsShipmentsByUser({ startDate, endDate } = {}) {
    return api.get('/reports/get/shipment-by-user', { params: { startDate, endDate } });
  }

  static getReportsCustomers({ startDate, endDate } = {}) {
    return api.get('/reports/get/customer-order-per-day', { params: { startDate, endDate } });
  }

  static getReportsMostValuableCustomers({ startDate, endDate } = {}) {
    return api.get('/reports/get/most-valuable-customers', { params: { startDate, endDate } });
  }

  static getReportsSalesByLocation({ startDate, endDate, region = 'World View' } = {}) {
    return api.get('/reports/get/sales-by-location', { params: { startDate, endDate, region } });
  }

  static getReportsSalesByCategory({ startDate, endDate } = {}) {
    return api.get('/reports/get/sales-by-category', { params: { startDate, endDate } });
  }

  static getReportsSalesTrends({ startDate, endDate } = {}) {
    return api.get('/reports/get/sales-trends', { params: { startDate, endDate } });
  }

  static getReportsSalesStore({ startDate, endDate } = {}) {
    return api.get('/reports/get/store-sales', { params: { startDate, endDate } });
  }

  static getReportsOrderByRegion({ startDate, endDate, region = 'World View' } = {}) {
    return api.get('/reports/get/orders-by-region', { params: { startDate, endDate, region } });
  }

  static getReportsTopProductsByRevenue({ startDate, endDate } = {}) {
    return api.get('/reports/get/top-products-revenue', { params: { startDate, endDate } });
  }

  static getReportsSoldItems({ startDate, endDate } = {}) {
    return api.get('/reports/get/sold-items', { params: { startDate, endDate } });
  }

  static getReportsTopSalesByCategoryQuantity({ startDate, endDate } = {}) {
    return api.get('/reports/get/top-category-qty', { params: { startDate, endDate } });
  }

  static getReportsTopSalesByCategoryQRevenue({ startDate, endDate } = {}) {
    return api.get('/reports/get/top-category-revenue', { params: { startDate, endDate } });
  }

  static getReportsTopProductsByQty({ startDate, endDate } = {}) {
    return api.get('/reports/get/top-product-qty', { params: { startDate, endDate } });
  }

  static getReportsInventorySummary(data) {
    return api.post('/reports/inventory/summary', data);
  }

  static exportReportsInventorySummary(data) {
    return api.post('/products/export/inventorySummaryExport', data);
  }

  static getReportsIncomingStock(data) {
    return api.post('/reports/inventory-incoming', data);
  }

  static exportReportsIncomingStock(data) {
    return api.post('/products/export/inventoryIncomingExport', data);
  }

  static getReportsIncomingStockChart(data) {
    return api.post('/reports/inventory-incoming-charts', data);
  }

  static getReportsInventoryDetails(data) {
    return api.post('/reports/inventory-detailed-report', data);
  }

  static exportReportsInventoryDetails(data) {
    return api.post('/products/export/inventoryDetailedReportExport', data);
  }

  static getReportsInventoryLocation(data) {
    return api.post('/reports/inventory-location', data);
  }

  static exportReportsInventoryLocation(data) {
    return api.post('/products/export/inventoryLocationExport', data);
  }

  static getProductStockUpdatingReport(data) {
    return api.post('/reports/updated-stocks', data);
  }

  static getProductStockOnHandReport(data) {
    return api.post('/reports/stock-on-hand', data);
  }

  static exportProductStockOnHandReport(data) {
    return api.post('/products/export/inventoryStockOnHandExport', data);
  }

  static movementExport(data) {
    return api.post('/products/export/movementExport', data);
  }

  static historicExport(data) {
    return api.post('/products/export/inventoryHistoricalExport', data);
  }

  static getSalesByCustomerReport(data) {
    return api.get('/reports/sales-by-customer', { params: { ...data } });
  }

  static exportSalesByCustomerReport(data) {
    return api.post('/products/export/salesByCustomerExport', data);
  }

  static getSalesByProductReport(data) {
    return api.post('/reports/sales-by-product', data);
  }

  static exportSalesByProductReport(data) {
    return api.post('/products/export/getSalesByProductExport', data);
  }

  static getSalesHistoryReport(data) {
    return api.post('/reports/sales-history', data);
  }

  static exportSalesHistoryReport(data) {
    return api.post('/products/export/salesHistoryExport', data);
  }

  static getSalesPackageHistoryReport(data) {
    return api.post('/reports/package-history', data);
  }

  static exportSalesPackageHistoryReport(data) {
    return api.post('/products/export/packingHistoryExport', data);
  }

  static getSalesByTimeReport(data) {
    return api.post('reports/sales-by-time', data);
  }

  static getSalesByTimeChartReport(data) {
    return api.post('reports/sales-by-time-chart', data);
  }

  static exportSalesByTimeReport(data) {
    return api.post('/products/export/salesByTimeExport', data);
  }

  static getSalesInvoiceHistoryReport(params) {
    return api.get('/reports/invoice-history', { params });
  }

  static exportSalesInvoiceHistoryReport(data) {
    return api.post('/products/export/invoiceHistoryExport', data);
  }

  static getShippedItemsReport(params) {
    return api.get('/reports/shipped-items', { params });
  }

  static exportShippedItemsReport(data) {
    return api.post('/products/export/shippedItemsExport', data);
  }

  static getReturnsHistoryReport(params) {
    return api.get('/reports/return-history', { params });
  }

  static exportReturnsHistoryReport(data) {
    return api.post('/products/export/returnHistoryExport', data);
  }

  static getReturnsByProductReport(params) {
    return api.get('/reports/return-by-product', { params });
  }

  static exportReturnsByProductReport(data) {
    return api.post('/products/export/returnByProductExport', data);
  }

  static getReturnsByCustomerReport(params) {
    return api.get('/reports/return-by-customer', { params });
  }

  static exportReturnsByCustomerReport(data) {
    return api.post('/products/export/returnByCustomerExport', data);
  }

  static getReturnsByCountryReport(params) {
    return api.get('/reports/return-by-country', { params });
  }

  static exportReturnsByCountryReport(data) {
    return api.post('/products/export/returnByCountryExport', data);
  }

  static getPurchaseByProductReport(data) {
    return api.post('/reports/purchase-product', data);
  }

  static exportPurchaseByProductReport(data) {
    return api.post('/products/export/purchaseByProductExport', data);
  }

  static getPurchaseHistoryReport(data) {
    return api.post('/reports/purchase-history', data);
  }

  static exportPurchaseHistoryReport(data) {
    return api.post('/products/export/purchaseHistoryExport', data);
  }

  static getPurchaseByCustomerReport(data) {
    return api.post('/reports/purchase-by-vendor', data);
  }

  static exportPurchaseByCustomerReport(data) {
    return api.post('/products/export/purchaseByVendorExport', data);
  }

  static getPurchaseReorderReport(data) {
    return api.post('/reports/re-orders', data);
  }

  static exportPurchaseReorderReport(data) {
    return api.post('/products/export/reOrderExport', data);
  }

  static getPurchaseReceiveHistoryReport(data) {
    return api.post('/reports/receive-history', data);
  }

  static exportPurchaseReceiveHistoryReport(data) {
    return api.post('/products/export/receiveHistoryExport', data);
  }

  // Reports end

  static markPackageAsDelivered(id, data) {
    return api.put(`/orders/packages/status/delivered/${id}`, data);
  }

  static downloadPackageDocument(id, params, responseType = 'blob') {
    return api.get(`/orders/packages/documents/${id}/packing-slip/download`, { params, responseType });
  }

  static downloadPackingListDocument(id, params, responseType = 'blob') {
    return api.get(`/orders/packages/documents/${id}/packing-list/download`, { params, responseType });
  }

  static getOrderInvoices(id) {
    return api.get(`/orders/${id}/invoices`);
  }

  static generateInvoiceNumber(params) {
    return api.get('/orders/invoices/generate-number', { params });
  }

  static createInvoice(data) {
    return api.post('/orders/invoices/create', data);
  }

  static deleteInvoice(id) {
    return api.delete(`/orders/invoices/delete/${id}`);
  }

  static downloadInvoice(id) {
    return api.get(`/orders/invoices/documents/${id}/download`, { responseType: 'blob' });
  }

  static recordPayment(id, data) {
    return api.post(`/orders/invoices/${id}/payment/create`, data);
  }

  static returnOrder(data) {
    return api.post('/orders/returns/create', data);
  }

  static singleOrderReturns(id) {
    return api.get(`/orders/${id}/returns`);
  }

  static orderReturnsList(params) {
    return api.get('/orders/returns', { params });
  }

  static acceptOrderReturn(id, data) {
    return api.put(`/orders/returns/accept/${id}`, data);
  }

  static signupGoogleOauth(data) {
    return api.post('/users/google-oauth-data', data);
  }

  static loginGoogleOauth(data) {
    return api.post('/users/login/auth-google', data);
  }

  static verifyAddress(data) {
    return api.post('/shipping/address-validate/shippo', data);
  }

  static forgotPassword({ email, callbackUrl }) {
    return api.post('/users/forgot-password', { email, callbackUrl });
  }

  static resetPassword({ token, newPassword }) {
    return api.put('/users/reset-password', { token, newPassword });
  }

  static getShippingRates(params) {
    return api.get('/shipping/shipping-rates/shippo', { params });
  }

  static deleteReturn(id) {
    return api.delete(`/orders/returns/delete/${id}`);
  }

  static declineReturn(id) {
    return api.put(`/orders/returns/decline/${id}`);
  }

  static getShippingOptions(carrier) {
    return api.get(`/shipping/shipping-options/shippo/${carrier}`);
  }

  static buyShippingLabel(data) {
    return api.post('/shipping/shipping-label/shippo', data);
  }

  static updateOrderPackage(id, data) {
    return api.put(`/orders/packages/update/${id}`, data);
  }

  static getOrderRates(data) {
    return api.post('/shipping/rates-by-dimensions/shippo', data);
  }

  static getBrands(params) {
    return api.get('/products/brands', { params });
  }

  // Logs start

  static getAllLogs(params) {
    this.cancel('getAllLogs');

    return api.get('/logger', {
      params,
      // headers: {
      //   'X-Owner-Id': userId,
      // },
      signal: this.cancelToken('getAllLogs'),
    });
  }

  static deleteLog(id, type, userId) {
    return api.delete(`/logger${type ? `/${type}` : ''}/${id}`, {
      headers: {
        'X-Owner-Id': userId,
      },
    });
  }

  static updateLogSeen(id, type, userId) {
    return api.put(`/logger/seen${type ? `/${type}` : ''}/${id}`, {}, {
      headers: {
        'X-Owner-Id': userId,
      },
    });
  }

  // Logs end

  static getStockTransferList(params) {
    return api.get('/stock-movements/list', { params });
  }

  static createStockTransfer(data) {
    return api.post('/stock-movements/create', data);
  }

  static getSingleStockTransfer(id) {
    return api.get(`/stock-movements/single/${id}`);
  }

  static singleStockTransferUpdate(id, data) {
    return api.put(`/stock-movements/update/${id}`, data);
  }

  static receiveStockTransfer(id, data) {
    return api.post(`/stock-movements/receive/${id}`, data);
  }

  static deleteStockTransfer(id) {
    return api.delete(`/stock-movements/${id}`);
  }

  static getReceiveStockTransferList(id) {
    return api.get(`/stock-movements/receives/${id}`);
  }

  static getSingleReceiveStockTransfer({ id, page }) {
    return api.get(`/stock-movements/receives/single/${id}`, {
      params: { page },
    });
  }

  // Admin start

  static getAdminUsers(params) {
    return api.get('/admin/users/list', { params });
  }

  static getAdminDashboard({ startDate, endDate }) {
    return api.get('/admin/dashboard', { params: { startDate, endDate } });
  }

  static getDefaultRoles() {
    return api.get('/subscriptions/roles/defaults');
  }

  static getAdminRolesList() {
    return api.get('/subscriptions/roles/admin/list');
  }

  static getSingleDefaultRole(id) {
    return api.get(`/subscriptions/roles/defaults/single/${id}`);
  }

  static getSingleAdminRole(id) {
    return api.get(`/subscriptions/roles/admin/single?id=${id}`);
  }

  static createDefaultRole(data) {
    return api.post('/subscriptions/roles/defaults/create', data);
  }

  static createAdminRole(data) {
    return api.post('/subscriptions/roles/admin/create', data);
  }

  static updateDefaultRole(data) {
    return api.put('/subscriptions/roles/defaults/update', data);
  }

  static updateAdminRole(data) {
    return api.put('/subscriptions/roles/admin/update', data);
  }

  static deleteDefaultRole(id) {
    return api.delete(`/subscriptions/roles/defaults/delete/${id}`);
  }

  static deleteAdminRole(id) {
    return api.delete(`/subscriptions/roles/admin/delete?id=${id}`);
  }

  // Admin end

  static getCreateStockControl(products) {
    return api.post('/stock-controls/create', { products });
  }

  static createCreditNote(data) {
    return api.post('/credit-notes/create', data);
  }

  static getCreditNotes(params) {
    return api.get('/credit-notes', { params });
  }

  static refundCreditNote(id) {
    return api.put(`/credit-notes/refund/${id}`);
  }

  static deleteCreditNote(id) {
    return api.delete(`/credit-notes/delete/${id}`);
  }

  static getOrdersInvoices(params) {
    return api.get('/orders/invoices', { params });
  }

  static archiveOrder(id) {
    return api.delete(`/orders/archive/${id}`);
  }

  static cancelInvoice(id) {
    return api.delete(`/orders/invoices/cancel/${id}`);
  }

  static cancelPayments({ id, paymentId }) {
    return api.delete(`/orders/invoices/${id}/payment/cancel/${paymentId}`);
  }

  static generatePackageNumber(params) {
    return api.get('/orders/packages/generate-number', { params });
  }

  static generatePaymentNumber() {
    return api.get('/orders/invoices/payment/generate-number');
  }

  static importCSVFile(type, data) {
    return api.post(`/imports/${type}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static viewInvoice(id, type, params, responseType = 'blob') {
    return api.get(`/orders/invoices/documents/${id}/${type}`, { params, responseType });
  }

  static getAllPackages(params) {
    return api.get('/orders/packages', { params });
  }

  static getExportTemplateList({ name }) {
    return api.get(`/settings?name=${name}`);
  }

  static createExportTemplate(data) {
    return api.post('/settings/list/create', data);
  }

  static updateExportTemplate(data) {
    return api.put('/settings/list/update', data);
  }

  static async exportFile(type, requestData) {
    const { data } = await api.post(`/products/export/${type}`, requestData, { responseType: 'blob' });

    if (data.type !== 'application/json') {
      fileDownload(data, `${type}.${requestData.fileType}`);

      return { toast: false };
    }

    return { toast: true };
  }

  static archiveProduct(id) {
    return api.delete(`/products/archive/${id}`);
  }

  static restoreProduct(id) {
    return api.put(`/products/restore/${id}`);
  }

  static updateProductRelations(data) {
    return api.post('/products/relations/update', data);
  }

  static createOrderReceive(data) {
    return api.post('/orders/packages/receive', data);
  }

  static generateCreditNoteNumber() {
    return api.get('/credit-notes/generate-number');
  }

  static generateReturnNumber() {
    return api.get('/orders/returns/generate-number');
  }

  static generateBatchNumber() {
    return api.get('/products/batches/generate');
  }

  static getUsersList(params) {
    return api.get('/users/list', { params });
  }

  static assignOrderToUser(data) {
    return api.put('orders/assign', data);
  }

  static getBatches(params) {
    return api.get('/products/batches', { params });
  }

  static getMissingBatches(params) {
    return api.get('/products/batches/missing', { params });
  }

  static getBatchesByProduct(params) {
    return api.get('/products/batches/by-product', { params });
  }

  static assignBatches(data) {
    return api.post('/products/batches/assign', data);
  }

  static editBatch(id, data) {
    return api.put(`/products/batches/update/${id}`, data);
  }

  static deleteBatches(id) {
    return api.delete(`/products/batches/delete/${id}`);
  }

  static deleteBatchesByProduct(id) {
    return api.delete(`/products/batches/delete/by-product/${id}`);
  }

  static unArchiveOrder(id) {
    return api.put(`/orders/restore/${id}`);
  }

  //   bot start
  static getThread({ threadId, after } = {}) {
    return api.get('/bot/completions/thread/messages', { params: { threadId, after } });
  }

  static getAnswer({ userMessage, threadId }) {
    return api.post('/bot/completions', { userMessage, threadId });
  }

  static createThread({ orderType, threadName }) {
    return api.post('/bot/completions/thread/create', { orderType, threadName });
  }

  static closeThread(threadId) {
    return api.get('/bot/completions/thread/close', { params: { threadId } });
  }

  static getThreads({
    page = 1, limit = 10, sortBy = 'createdAt', sort = 'desc',
  } = {}) {
    return api.get('/bot/completions/threads', {
      params: {
        page, limit, sortBy, sort,
      },
    });
  }

  static updateThread({ threadId, threadName }) {
    return api.put('/bot/completions/thread/update', { threadId, threadName });
  }

  //   bot end

  static generateInvoice(id) {
    return api.post(`orders/${id}/invoices/generate`);
  }

  static getShopifyRedirectUrl(data) {
    return api.post('/users/shopify-oauth-url', data);
  }

  static shopifyAppAuth(data) {
    return api.post('/users/shopify-oauth-data', data);
  }

  static getProductBatches(params) {
    return api.get('/products/batches/by-product', { params });
  }

  static sendInvoiceToAccounting(params) {
    return api.get('/accounting/send-invoice', { params });
  }

  static sendOrderTo(integration, data) {
    return api.post(`/orders/send/${integration}`, data);
  }

  static downloadCreditNoteDocument(id, type = 'download', params, responseType = 'blob') {
    return api.get(`/credit-notes/documents/${id}/${type}`, { params, responseType });
  }

  static getPrintersList() {
    return api.get('/printers');
  }

  static getPermissions() {
    return api.get('/users/profile/role');
  }

  static getCreditNote(id) {
    return api.get(`/credit-notes/single/${id}`);
  }

  static applyCreditNoteToInvoice(id, data) {
    return api.put(`/credit-notes/apply/${id}`, data);
  }

  static getSingleInvoice(id) {
    return api.get(`/orders/invoices/single/${id}`);
  }

  static duplicateOrder(id) {
    return api.post(`/orders/copy/${id}`);
  }
}

export default Api;
