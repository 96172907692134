import React from 'react';
import PreviewHeader from './PreviewHeader';
import OrderInformation from './OrderInformation';
import ProductsList from './ProductsList';
import ActionTabs from './ActionTabs';

function OrderPreview() {
  return (
    <div className="order_preview">
      <PreviewHeader />
      <div className="order_preview_content">
        <div className="left">
          <ActionTabs />
          <ProductsList />
        </div>
        <OrderInformation />
      </div>

    </div>
  );
}

export default OrderPreview;
