import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Link, useLocation, useNavigate,
} from 'react-router-dom';
import moment from 'moment';
import { format } from 'currency-formatter';
import { toast } from 'react-toastify';
import { useClickAway } from 'react-use';
import fileDownload from 'js-file-download';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import Pagination from '../../../_common/Pagination/Pagination';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import Button from '../../../_common/Form/Button';
import Api from '../../../../Api';
import {
  changeInvoicesTableAttributes,
  getInvoicesTableAttributesRequest,
  invoicesTableEditableEvent,
} from '../../../../store/actions/invoices';
import Utils from '../../../../helpers/Utils';
import PrintDocumentModal from '../../../_common/Modal/PrintDocumentModal';

function InvoicesTable(props) {
  const [checkedInvoices, setCheckedInvoices] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const dropdownRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const allOrdersInvoices = useSelector((state) => state.invoices.allOrdersInvoices);
  const totalPages = useSelector((state) => state.invoices.totalPages);
  const tableEditable = useSelector((state) => state.invoices.tableEditable);
  const tableColumns = useSelector((state) => state.invoices.tableColumns);

  const cols = useMemo(() => _.cloneDeep(tableColumns).map((t) => {
    if (t.key === 'order#') {
      t.label = 'Order#';
    }
    if (t.key === 'invoice#' && props.type === 'purchase') {
      t.label = 'Bill#';
    }
    if (t.key === 'invoice#' && props.type === 'sale') {
      t.label = 'Invoice#';
    }
    return t;
  }), [tableColumns, props.type]);

  useClickAway(dropdownRef, () => {
    setInvoiceId(null);
  });

  useEffect(() => {
    dispatch(getInvoicesTableAttributesRequest());
  }, []);

  const changeTableAttributes = useCallback((attrs) => {
    dispatch(changeInvoicesTableAttributes(attrs.map((a) => ({ key: a.key, isEnabled: a.isEnabled }))));
  }, []);

  const saveChangedTableAttribute = useCallback(async () => {
    try {
      setSaveLoading(true);
      await Api.updateSettings({ orderInvoiceTableAttributes: tableColumns });
      dispatch(invoicesTableEditableEvent(false));
      setSaveLoading(false);
    } catch (e) {
      toast.error('Something wrong place try again !');
    }
  }, [tableColumns]);
  return (
    <>
      <div className="table_wrapper sale_orders_table">
        <DynamicTable
          onChange={changeTableAttributes}
          loading={props.loading}
          onSelect={setCheckedInvoices}
          data={allOrdersInvoices}
          editable={tableEditable}
          checkbox
          keyExtractor={(item) => item.id}
          columns={cols}
          callDragDisabled={(call) => ['item'].includes(call.key)}
          renderColumnHeader={(col) => col.label || _.startCase(col.key)}
          renderColumns={{
            'invoice#': ({ item }) => (
              <div className="link">
                <Link to={props.type === 'sale'
                  ? `/orders/invoices/preview/${item.id}${location.search}`
                  : `/stocks/bills/preview/${item.id}${location.search}`}
                >
                  {item.number}
                </Link>
              </div>
            ),
            'order#': ({ item }) => (
              <div className="link">
                <Link to={props.type === 'sale'
                  ? `/orders/sales-order/preview/${item.orderId}${location.search}`
                  : `/stocks/purchase-orders/preview/${item.orderId}${location.search}`}
                >
                  {item.orderNumber}
                </Link>
              </div>
            ),
            customer: ({ item }) => (
              <div>
                {`${item.billingAddress.firstName || '-'} ${item.billingAddress.lastName || ''}`}
              </div>
            ),
            shippingAddress: ({ item }) => (
              <div>
                {Utils.formatAddress([item.billingAddress.address1,
                  item.billingAddress.city,
                  item.billingAddress.region,
                  item.billingAddress.postalCode,
                  item.billingAddress.countryCode])}
              </div>
            ),
            billingAddress: ({ item }) => (
              <div>
                {Utils.formatAddress([item.shippingAddress.address1,
                  item.shippingAddress.city,
                  item.shippingAddress.region,
                  item.shippingAddress.postalCode,
                  item.shippingAddress.countryCode])}
              </div>
            ),
            status: ({ item }) => (
              <div className={`status ${item.status}`}>
                <div>
                  <p className="status_dot" />
                  <span>{Utils.formatStatus(item.status)}</span>
                </div>
              </div>
            ),
            createdAt: ({ item }) => (
              <div>
                {moment(item.createdAt).format('DD MMM YYYY')}
              </div>
            ),
            dueDate: ({ item }) => (
              <div>
                {moment(item.dueDate).format('DD MMM YYYY')}
              </div>
            ),
            amount: ({ item }) => (
              <div>
                {format(item.totalPrice || '-', { code: item.currencyCode })}
              </div>
            ),
            balanceDue: ({ item }) => (
              <div>
                {format(item.totalPrice - _.sumBy(item.payments, 'amount'), { code: item.currencyCode })}
              </div>
            ),
            actions: ({ item }) => (
              <div className="products_actions_wrapper">
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setInvoiceId(item.id)}
                >
                  <DotsIcon />
                </div>
                {item.id === invoiceId ? (
                  <ul className="dropdown invoices" ref={dropdownRef}>
                    <li onClick={() => setShowPrintModal(item.id)}>
                      Print
                    </li>
                    <li onClick={async () => {
                      setLoadingDownload(true);
                      const { data } = await Api.downloadInvoice(item.id);
                      setLoadingDownload(false);
                      fileDownload(data, 'document.pdf');
                    }}
                    >
                      {`Download ${props.type === 'sale' ? 'invoice' : 'bill'}`}
                      {loadingDownload ? (
                        <div className="loader_wrapper" style={{ right: -6, top: 9 }}>
                          <span style={{ width: 15, height: 15 }} />
                        </div>
                      ) : null}
                    </li>
                    <li
                      data-scope="WRITE_ORDER_INVOICES"
                      onClick={() => {
                        navigate(props.type === 'sale'
                          ? `/orders/invoices/preview/${item.id}`
                          : `/stocks/bills/preview/${item.id}`, { state: { payment: item.id }, replace: true });
                      }}
                    >
                      Register payment
                    </li>
                  </ul>
                ) : null}

              </div>
            ),
          }}
        />

      </div>
      {tableEditable
        && (
          <div className="products_wrapper_table_changed_save">
            <Button
              className="table_changed_save_button"
              size="small"
              title="Cancel"
              onClick={() => dispatch(invoicesTableEditableEvent(false))}
              roundBorder
              btnType="cancel"
            />

            <Button
              className="table_changed_save_button"
              size="small"
              title="Save changes"
              onClick={saveChangedTableAttribute}
              roundBorder
              color="#1472FF"
              loading={saveLoading}
            />
          </div>
        )}
      <Pagination totalPages={totalPages} />
      {showPrintModal ? (
        <PrintDocumentModal
          isOpen={!!showPrintModal}
          onClose={() => setShowPrintModal(false)}
          type="invoices/invoice"
          onRequest={async (templateId) => {
            const { data } = await Api.viewInvoice(showPrintModal, 'download', { templateId });
            return data;
          }}
          onRemotePrint={() => {
          }}

        />
      ) : null}
    </>
  );
}

export default InvoicesTable;
