import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Link, useLocation,
} from 'react-router-dom';
import moment from 'moment';
import { useClickAway } from 'react-use';
import fileDownload from 'js-file-download';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import Pagination from '../../../_common/Pagination/Pagination';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import Api from '../../../../Api';
import {
  changeShipmentsTableAttributes,
  getShipmentsTableAttributes,
} from '../../../../store/actions/packages';
import Utils from '../../../../helpers/Utils';

function ReceivesTable(props) {
  const [checkedShipments, setCheckedShipments] = useState([]);
  const [invoiceId, setInvoiceId] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const dropdownRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const allPackages = useSelector((state) => state.packages.allPackages);
  const totalPages = useSelector((state) => state.packages.totalPages);
  const tableColumns = useSelector((state) => state.packages.tableColumnsReceive);

  useClickAway(dropdownRef, () => {
    setInvoiceId(null);
  });

  useEffect(() => {
    dispatch(getShipmentsTableAttributes());
  }, []);

  const changeTableAttributes = useCallback((attrs) => {
    dispatch(changeShipmentsTableAttributes(attrs));
  }, []);

  return (
    <>
      <div className="table_wrapper sale_orders_table">
        <DynamicTable
          onChange={changeTableAttributes}
          loading={props.loading}
          onSelect={setCheckedShipments}
          data={allPackages}
          checkbox
          keyExtractor={(item) => item.id}
          columns={tableColumns}
          callDragDisabled={(call) => ['item'].includes(call.key)}
          renderColumnHeader={(col) => col.label || _.startCase(col.key)}
          renderColumns={{
            'receive#': ({ item }) => (
              <div className="link">
                <Link to={`/stocks/receives/preview/${item.id}${location.search}`}>{item.number}</Link>
              </div>
            ),
            'purchase_order#': ({ item }) => (
              <div className="link">
                <Link to={`/orders/sales-order/preview/${item.orderId}${location.search}`}>{item.order.number}</Link>
              </div>
            ),
            vendor: ({ item }) => (
              <div>
                {`${item.order.shippingAddress?.firstName || '-'} ${item.order.shippingAddress?.lastName || ''}`}
              </div>
            ),
            date: ({ item }) => (
              <div>
                {moment(item.sourceCreatedAt || item.createdAt).format('DD MMM YYYY')}
              </div>
            ),
            status: ({ item }) => (
              <div className={`status ${item.status}`}>
                <div>
                  <p className="status_dot" />
                  <span>
                    {Utils.formatPurchaseStatus(item.status)}
                  </span>
                </div>
              </div>
            ),
            actions: ({ item }) => (
              <div className="products_actions_wrapper">
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => setInvoiceId(item.id)}
                >
                  <DotsIcon />
                </div>
                {item.id === invoiceId ? (
                  <ul className="dropdown invoices" ref={dropdownRef}>
                    <li onClick={async () => {
                      setLoadingDownload(true);
                      const { data } = await Api.downloadPackageDocument(item.id);
                      setLoadingDownload(false);
                      fileDownload(data, 'document.pdf');
                    }}
                    >
                      Download package slip
                      {loadingDownload ? (
                        <div
                          className="loader_wrapper"
                          style={{
                            right: -6,
                            top: 9,
                          }}
                        >
                          <span style={{
                            width: 15,
                            height: 15,
                          }}
                          />
                        </div>
                      ) : null}
                    </li>

                  </ul>
                ) : null}

              </div>
            ),
          }}
        />

      </div>
      <Pagination totalPages={totalPages} />
    </>
  );
}

export default ReceivesTable;
