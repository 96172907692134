import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useClickAway } from 'react-use';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import Filters from '../../Products/Filters';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import noImage from '../../../../assets/icons/default.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_with_circle.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_with_circle.svg';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import Pagination from '../../../_common/Pagination/Pagination';
import Utils from '../../../../helpers/Utils';
import { deleteBatchRequest, getBatchesList, getMissingBatchesList } from '../../../../store/actions/batches';
import Loader from '../../../_common/Loader/Loader';
import EditBatchModal from './components/EditBatchModal';

export const titleFilter = [
  {
    label: 'All batches',
    path: 'all',
  },
  {
    label: 'Missing information',
    path: 'missingInformation',
  },
];

const columns = [
  { key: 'number', isEnabled: true },
  { key: 'item', isEnabled: true },
  { key: 'sku', isEnabled: true },
  { key: 'manufacturedDate', isEnabled: true },
  { key: 'expirationDate', isEnabled: true },
  { key: 'qty', isEnabled: true },
  { key: 'stock', isEnabled: true },
  { key: 'available', isEnabled: true },
];

const defaultAttributes = {
  number: 'Batch#',
  item: 'Item',
  sku: 'SKU',
  manufacturedDate: 'Manufactured date',
  expirationDate: 'Expiration date',
  stock: 'Stock',
  available: 'Available',
  qty: 'Quantity',
};

const Batches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectRef = useRef();
  const firstLoad = useRef(true);
  const timeOutRef = useRef(null);

  const batchLists = useSelector((state) => state.batches.batchLists);
  const batchTotalPage = useSelector((state) => state.batches.batchTotalPage);

  const [openDeleteModal, setOpenDeleteModal] = useState('');
  const [openSelect, setOpenSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState({});

  const [queryData, setQuery] = useQueryParams({
    type: withDefault(StringParam, 'all'),
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const { type } = queryData;

  useEffect(() => {
    if (type !== 'all' && type !== 'missingInformation') {
      setQuery((prev) => ({ ...prev, type: 'all' }));
    }
  }, [type]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      clearTimeout(timeOutRef.current);

      timeOutRef.current = setTimeout(async () => {
        if (type === 'missingInformation') {
          await dispatch(getMissingBatchesList(Utils.arrayToString({ ...queryData, type: '' })));
        } else {
          await dispatch(getBatchesList(Utils.arrayToString({ ...queryData, type: '' })));
        }
        firstLoad.current = false;
        setLoading(false);
      }, 400);
    })();
  }, [queryData.s, type]);

  useClickAway(selectRef, () => {
    setOpenSelect(false);
  });

  const changePage = useCallback(async (currentPage) => {
    clearTimeout(timeOutRef.current);
    setLoading(true);

    await dispatch(getBatchesList(Utils.arrayToString({ ...queryData, page: currentPage })));

    setQuery((prev) => ({ ...prev, page: currentPage }));
    setLoading(false);
  }, []);

  const batchDelete = async () => {
    setDeleteLoading(true);
    await dispatch(deleteBatchRequest(openDeleteModal));

    if (batchLists.length === 1 && +queryData.page > 1) {
      await changePage(+queryData.page - 1);
    }

    toast.success('Batch successfully deleted');

    setDeleteLoading(false);

    setOpenDeleteModal('');
  };

  return (
    <Wrapper className="batches_wrapper" hideBackBtn>
      <div className="batches_header_wrapper">
        <div
          role="button"
          tabIndex="0"
          onClick={() => setOpenSelect((prev) => !prev)}
          className={classNames('batches_header_select_wrapper', { active: openSelect })}
          ref={selectRef}
        >
          <p className="batches_header_title">
            {titleFilter.find((d) => d.path === type)?.label}
          </p>

          <ArrowIcon />

          {openSelect && (
            <div className="batches_header_select_block">
              {titleFilter.map((d) => (
                <div
                  key={d.path}
                  role="button"
                  tabIndex={0}
                  onClick={() => setQuery((prev) => ({ ...prev, type: d.path }))}
                  className={classNames('', { active: d.path === type })}
                >
                  {d.label}
                </div>
              ))}
            </div>
          )}
        </div>

        <Filters
          queryData={queryData}
          setQuery={setQuery}
          onExportClick={() => {
          }}
        />
      </div>

      {firstLoad.current
        ? <Loader />
        : (
          <div className="batches_body">
            <DynamicTable
              data={batchLists}
              keyExtractor={(item) => item.id}
              columns={columns}
              loading={loading}
              renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
              renderColumns={{
                number: ({ item }) => (
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={type !== 'missingInformation'
                      ? () => navigate(`/inventory/batches/single/${item.id}`) : null}
                    className={classNames('batches_table_number', { missing: type === 'missingInformation' })}
                  >
                    {item.batchNumber || item.serialNumber || '-'}
                  </div>
                ),

                item: ({ item }) => (
                  <div className="product_item_wrapper">
                    <div className="product_item_img">
                      <img
                        src={item.product?.images[0]?.src || item.product?.images[0]?.small || noImage}
                        alt="img"
                      />
                    </div>

                    <div className="product_item_title">
                      <p>{item.product?.title}</p>
                    </div>
                  </div>
                ),

                sku: ({ item }) => item.product?.sku || '-',

                manufacturedDate: ({ item }) => moment(item.manufacturedDate).format('MM/DD/YYYY'),

                expirationDate: ({ item }) => moment(item.expirationDate).format('MM/DD/YYYY'),

                qty: ({ item }) => item.qty ?? '-',

                stock: ({ item }) => item.product?.totalStock?.stock ?? '-',

                available: ({ item }) => item.product?.totalStock?.available ?? '-',

                actions: ({ item }) => (
                  type !== 'missingInformation' && (
                  <div className="products_actions_wrapper">
                    <div
                      role="button"
                      tabIndex="0"
                      onClick={() => setOpenEditModal(item)}
                    >
                      <EditIcon />
                    </div>

                    <div
                      role="button"
                      tabIndex="0"
                      onClick={() => setOpenDeleteModal(item.id)}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                  )
                ),

              }}
            />

            {+batchTotalPage > 1 && (
            <div className="batches_pagination">
              <Pagination
                page={queryData.page}
                totalPages={+batchTotalPage}
                onChangePage={changePage}
              />
            </div>
            )}
          </div>
        )}

      <DeleteModal
        isOpen={!!openDeleteModal}
        text="batches"
        onDelete={batchDelete}
        loading={deleteLoading}
        onClose={() => setOpenDeleteModal('')}
      />

      <EditBatchModal
        setSingleBatch={setOpenEditModal}
        singleBatch={openEditModal}
      />
    </Wrapper>
  );
};

export default Batches;
