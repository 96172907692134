import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  NumberParam, StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Wrapper from '../../Layout/Wrapper';
import Table from '../../_common/Tables/Table';
import Loader from '../../_common/Loader/Loader';
import UpdateModal from '../../_common/Modal/UpdateModal';
import Api from '../../../Api';
import Pagination from '../../_common/Pagination/Pagination';
import Button from '../../_common/Form/Button';
import { ReactComponent as UsersNotFoundIcon } from '../../../assets/icons/notFound/customers_not_found.svg';

const defaultUserData = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
};

const options = [
  {
    title: 'First Name',
    path: 'firstName',
    required: true,
  },
  {
    title: 'Last Name',
    path: 'lastName',
    required: true,
  },
  {
    title: 'Email',
    path: 'email',
    required: true,
  },
  {
    title: 'Role',
    path: 'roleId',
    type: 'select',
    required: true,
    menuPortalTarget: true,
    valuePath: 'id',
    labelPath: 'name',
    options: [],
  },
];

function CrmUsers() {
  const firstLoad = useRef(true);

  const [page, setPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 1),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const profile = useSelector((store) => store.users.profile);

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const [tableLoading, setTableLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [updateUserId, setUpdateUserId] = useState('');
  const [users, setUsers] = useState([]);
  const [invitationData, setInvitationData] = useState(defaultUserData);
  const [roles, setRoles] = useState([]);

  const updateFields = useMemo(
    () => options.map((o) => (o.path !== 'roleId' ? o : { ...o, options: roles })),
    [roles.length],
  );

  const modalData = useMemo(
    () => {
      const newData = { ...invitationData };

      if (newData.status) newData.status = newData.status === 'active';

      return newData;
    },
    [invitationData],
  );

  const getInvitations = useCallback(async (newPage, sort, sortBy) => {
    const { data } = await Api.getCrmInvitationUsers({
      page: newPage,
      sort,
      sortBy,
    });

    setTotalPages(data.totalPages);
    setUsers(data.data);
    setDeleteLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      setTableLoading(true);

      if (firstLoad.current) {
        const { data } = profile.isAdmin ? await Api.getAdminRolesList() : await Api.getRolesList();

        setRoles(data.roles);
      }

      await getInvitations(page, sorting.sort, sorting.sortBy);

      setTableLoading(false);
      firstLoad.current = false;
    })();
  }, [page, sorting.sort, sorting.sortBy]);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, [sorting]);

  const updateUser = useCallback((id) => {
    if (id) {
      setUpdateUserId(id);

      setInvitationData(users.find((u) => u.id === id));
    }
  }, [users]);

  const deleteUserInvitation = useCallback(async (id) => {
    setDeleteLoading(true);

    await Api.deleteCrmInviteUser(id);

    if (totalPages !== 1 && users.length === 1) {
      setPage((prev) => prev - 1);
    } else {
      await getInvitations(page, sorting.sort, sorting.sortBy);
    }
  }, [users, page, sorting]);

  const closeModal = useCallback(() => {
    setUpdateUserId('');
    setInvitationData(defaultUserData);
  }, [invitationData, updateUserId]);

  const onSaveUser = useCallback(async (newData) => {
    if (updateUserId === 'add') {
      await Api.crmInviteUser({ ...newData, callbackUrl: `${window.location.origin}/crm/invitation/verify` });

      setUpdateUserId('');
      toast.success('User has been successfully invited and will get email for invitation.');
    } else {
      const sendingData = {
        firstName: newData.firstName,
        lastName: newData.lastName,
        roleId: newData.roleId,
      };

      sendingData.status = newData.status ? 'active' : 'restricted';

      if (newData?.userId) {
        sendingData.userId = newData.userId;
      } else {
        sendingData.invitationId = newData.id;
      }

      await Api.crmInvitedUserUpdate(sendingData);

      setUpdateUserId('');
      toast.success('Invitation has been successfully updated.');
    }

    const { data } = await Api.getCrmInvitationUsers({
      page: page || 1,
      sort: sorting.sort,
      sortBy: sorting.sortBy,
    });

    setTotalPages(data.totalPages);
    setUsers(data.data);
  }, [invitationData, updateUserId]);

  const tableHeader = useMemo(() => (
    [
      {
        title: 'First Name',
        path: 'firstName',
        navigate: (id) => updateUser(id),
      },
      {
        title: 'Last Name',
        path: 'lastName',
      },
      {
        title: 'Email',
        path: 'email',
      },
      {
        title: 'Role',
        path: 'roleName',
      },
      {
        title: 'Created At',
        path: 'createdAt',
        isSelectable: true,
      },
      {
        title: 'Status',
        path: 'status',
      },
    ]
  ), [updateUser]);

  return (
    <Wrapper
      title="CRM Users"
      onBtnClick={() => setUpdateUserId('add')}
      addBtn
      buttonTitle="New contact"
    >
      <div className="crm_users_wrapper">
        {firstLoad.current
          ? <Loader />

          : (
            <div className="crm_users_table_wrapper">
              <Table
                data={users}
                header={tableHeader}
                deleteModalText="user invitation"
                onDeleteClick={(id) => deleteUserInvitation(id)}
                deleteLoading={deleteLoading}
                onEditClick={(id) => updateUser(id)}
                sortBy={sorting.sortBy}
                sort={sorting.sort}
                onSortBy={onSortBy}
                loading={tableLoading}
                hideDeleteButtonFields={['active', 'restricted']}
                notFound={(
                  <>
                    <UsersNotFoundIcon />

                    <h1>Users not found</h1>

                    <Button
                      title="Create contact"
                      onClick={() => setUpdateUserId('add')}
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />
            </div>
          )}

        <Pagination onChangePage={(p) => setPage(p)} totalPages={totalPages} />

        <UpdateModal
          isOpen={!!updateUserId}
          onClose={closeModal}
          fields={updateFields}
          onSave={onSaveUser}
          singleData={modalData}
          disabledPaths={['email']}
          switcherPath="status"
          switcherLabel="Status"
          withDefaultSwitcher={invitationData?.status !== 'pending' && invitationData?.status}
        />
      </div>
    </Wrapper>
  );
}

export default CrmUsers;
