import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getDashboardLayoutRequest = createAsyncThunk(
  'dashboard/getLayout',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardLayout(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const updateDashboardLayoutRequest = createAsyncThunk(
  'dashboard/updateLayout',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.updateDashboardLayout(payload.data, payload.settingType);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardStatusCountRequest = createAsyncThunk(
  'dashboard/getStatusCount',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardStatusCount(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardProductsCountRequest = createAsyncThunk(
  'dashboard/getProductsCount',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardProductsCount(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardBySalesChannelsRequest = createAsyncThunk(
  'dashboard/bySalesChannels',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardBySalesChannels(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardPurchaseOrdersRequest = createAsyncThunk(
  'dashboard/getPurchaseOrders',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardPurchaseOrders(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardTopProductsRequest = createAsyncThunk(
  'dashboard/getTopProducts',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardTopProducts(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardOrderSummaryRequest = createAsyncThunk(
  'dashboard/getOrderSummary',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardOrderSummary(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardLowQuantityRequest = createAsyncThunk(
  'dashboard/getLowQuantity',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardLowQuantity(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardOutStockRequest = createAsyncThunk(
  'dashboard/getOutStock',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getDashboardOutStock(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getDashboardShopsRequest = createAsyncThunk(
  'dashboard/getShops',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getIntegrations(payload); // getIntegrations
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
