import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import {
  NumberParam, StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import Input from '../../../_common/Form/Input';
import Table from '../../../_common/Tables/Table';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import Pagination from '../../../_common/Pagination/Pagination';
import Loader from '../../../_common/Loader/Loader';
import Button from '../../../_common/Form/Button';
import { ReactComponent as BrandsNotFoundIcon } from '../../../../assets/icons/no_match.svg';


const modalFields = [
  {
    title: 'Title',
    path: 'name',
    placeholder: 'Enter name',
    required: true,
  },
];

const brandDefaultData = { name: '' };

const Brands = () => {
  const [page, setPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 1),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [s, setSearch] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const [brandsData, setTagsData] = useState({
    list: [],
    totalPages: 1,
  });
  const { list, totalPages } = brandsData;

  const firstLoad = useRef(true);
  const timeout = useRef(false);

  const [modalData, setModalData] = useState({});
  const [loading, loadingToggle] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    (async () => {
      loadingToggle(true);
      clearTimeout(timeout.current);

      timeout.current = setTimeout(async () => {
        await getBrandsRequest();

        loadingToggle(false);
        firstLoad.current = false;
      }, 500);
    })();
  }, [sorting, s]);

  const getBrandsRequest = async (newFilter = {}) => {
    const { data } = await Api.getProductsBrands({
      page,
      ...sorting,
      s,
      ...newFilter,
      // limit: 20,
    });

    setTagsData({ list: data.brands, totalPages: +data.totalPages });
  };

  const openModal = useCallback((id) => {
    setModalData(id ? list.find((t) => +t.id === +id) : brandDefaultData);
  }, [list]);

  const onChangeSearch = useCallback((value) => {
    setSearch(value);
    setPage(1);
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, []);

  const onChangePage = useCallback(async (currentPage) => {
    clearTimeout(timeout.current);
    loadingToggle(true);

    await getBrandsRequest({ page: currentPage });

    setPage(currentPage);
    loadingToggle(false);
  }, [s, sorting]);

  const onDelete = useCallback(async (id) => {
    setDeleteLoading(true);

    await Api.deleteProductBrand(id);

    if (+totalPages > 1 && list.length === 1) {
      await onChangePage(page - 1);
    } else {
      await getBrandsRequest();
    }

    toast.success('Brand successfully deleted');

    setDeleteLoading(false);
  }, [s, sorting, page, totalPages, list]);

  const saveTags = useCallback(async (newData) => {
    const sendingData = {
      name: newData.name,
      color: newData.color,
    };

    if (!modalData?.id) {
      await Api.createProductBrand(sendingData);
    } else {
      if (newData?.id) {
        sendingData.id = newData.id;
      }

      await Api.updateProductBrand(sendingData);
    }

    await getBrandsRequest();

    toast.success(`Brand successfully ${modalData.id ? 'updated' : 'saved'}`);
    setModalData({});
  }, [modalData]);

  const tableHeader = useMemo(() => [
    {
      title: 'Number',
      path: 'index',
    },
    {
      title: 'Brand Name',
      path: 'name',
      navigate: (id) => openModal(id),
      isSelectable: true,
    },
  ], [openModal]);

  return (
    <Wrapper
      title="Brands"
      onBtnClick={() => setModalData(brandDefaultData)}
      addBtn
      buttonTitle="New brand"
    >
      {firstLoad.current
        ? <Loader />

        : (
          <>
            <Input
              placeholder="Search title"
              roundBorder
              size="small"
              search
              value={s}
              onChangeText={(value) => onChangeSearch(value)}
              wrapperClassName="company_search_field"
            />

            <div className="tags_table_wrapper">
              <Table
                data={list}
                header={tableHeader}
                onEditClick={openModal}
                onDeleteClick={onDelete}
                onSortBy={onSortBy}
                sortBy={sorting.sortBy}
                sort={sorting.sort}
                deleteModalText="brand"
                deleteLoading={deleteLoading}
                loading={loading}
                notFound={(
                  <>
                    <BrandsNotFoundIcon />

                    <h1>Brands not found</h1>

                    <Button
                      title="Create brand"
                      onClick={() => setModalData(brandDefaultData)}
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />

              <Pagination onChangePage={(p) => onChangePage(p)} totalPages={totalPages} />
            </div>
          </>
        )}

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalFields}
        onClose={() => setModalData({})}
        onSave={saveTags}
        singleData={modalData}
        text="brand"
      />
    </Wrapper>
  );
};

export default Brands;
