import React, { useMemo } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Utils from '../../../../helpers/Utils';

function Backorders() {
  const order = useSelector((state) => state.orders.order);

  const data = useMemo(() => (order.type === 'purchase' ? order.association : order.backorder), [order]);
  return (
    <div className="packages">
      {_.isEmpty(data) ? (
        <div className="no_packages">
          No backorders created so far
        </div>
      ) : null}
      {!_.isEmpty(data) ? (
        <div className="table_wrapper">
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">{order.type === 'sale' ? 'Purchase order#' : 'Sales order#'}</th>
                <th className="table_thead_tr_th ordered">Date</th>
                <th className="table_thead_tr_th packed">Status</th>
              </tr>
            </thead>
            <tbody className="table_tbody">
              <tr
                className="table_tbody_tr"
              >
                <td className="table_tbody_tr_td number">
                  <Link to={`/stocks/purchase-orders/preview/${data.id}`}>{data.number}</Link>
                </td>
                <td className="table_tbody_tr_td">
                  {moment(data.sourceCreatedAt).format('DD MMM YYYY')}
                </td>
                <td className="table_tbody_tr_td">
                  <div className={`status ${data.status}`}>
                    <p className="status_dot" />
                    <span>
                      {Utils.formatStatus(data.status)}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}

    </div>

  );
}

export default Backorders;
