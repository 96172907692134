import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import _ from 'lodash';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import noImage from '../../../../assets/icons/default.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_with_circle.svg';
import Datepicker from '../../../_common/Form/Datepicker';
import Utils from '../../../../helpers/Utils';
import Input from '../../../_common/Form/Input';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import Pagination from '../../../_common/Pagination/Pagination';

const columns = [
  { key: 'number', isEnabled: true },
  { key: 'item', isEnabled: true },
  { key: 'sku', isEnabled: true },
  { key: 'warehouse', isEnabled: true },
  { key: 'manufacturedDate', isEnabled: true },
  { key: 'expirationDate', isEnabled: true },
  { key: 'quantity', isEnabled: true },
  { key: 'qty', isEnabled: true },
];

const defaultAttributes = {
  number: 'Batch#',
  item: 'Item',
  sku: 'SKU',
  warehouse: 'Warehouse',
  manufacturedDate: 'Manufactured date',
  expirationDate: 'Expiration date',
  quantity: 'Quantity',
  qty: 'Qty to assign',
};

const EditBatches = () => {
  const { batchesId } = useParams();
  const navigate = useNavigate();
  const firstLoading = useRef(true);

  const [page] = useQueryParam('page', withDefault(NumberParam, 1));

  const [batches, setBatches] = useState([]);
  const [newBatch, setNewBatch] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const [{ data }, { data: { batchNumber } }] = await Promise.all([
          Api.getBatchesByProduct({ productId: batchesId, page }),
          Api.generateBatchNumber(),
        ]);
        setTotalPages(+data.totalPages);

        setNewBatch({
          images: data.batches[0].images,
          title: data.batches[0].title,
          qtyToAssign: data.batches[0].qtyToAssign,
          warehouse: data.batches[0].warehouse,
          sku: data.batches[0].sku,
          qty: 1,
          expirationDateFrom: '',
          expirationDateTo: '',
          batchNumber,
        });

        setBatches(data.batches);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
      firstLoading.current = false;
    })();
  }, [page]);

  const onChangeData = (path, value, batchNumber) => {
    setErrors((prev) => ({ ...prev, [batchNumber]: { ...prev[batchNumber], [path]: '' } }));

    setBatches((prev) => (prev.map((item) => (item.batchNumber === batchNumber
      ? { ...item, [path]: value } : item))));
  };
  const addNewRow = () => {
    if (firstLoading.current) {
      return;
    }

    setBatches((prev) => {
      const lastBatchNumber = _.last(prev)?.batchNumber?.split('-');

      return [...prev, _.isEmpty(lastBatchNumber) ? newBatch : {
        ...newBatch,
        batchNumber: +lastBatchNumber[1] < +newBatch.batchNumber.split('-')[1]
          ? newBatch.batchNumber
          : `${lastBatchNumber[0]}-${String(+lastBatchNumber[1] + 1).padStart(8, 0)}`,
      }];
    });
  };

  const deleteBatch = (batchNumber) => {
    setErrors((prev) => ({ ...prev, [batchNumber]: {} }));

    setBatches((prev) => prev.filter((item) => item.batchNumber !== batchNumber));
  };

  const onSaveClick = async () => {
    setSaveLoading(true);
    try {
      await Api.assignBatches({ productId: batchesId, batches });

      toast.success('Your changes have been successfully saved.');
    } catch (e) {
      const error = e.response.data.errors.batches;

      _.forEach(error, (err, index) => {
        if (err) {
          const getErrorBatch = _.get(batches, index);
          setErrors((prev) => ({ ...prev, [getErrorBatch.batchNumber]: err }));
        }
      });
      // console.log(window.innerHeight, window.outerHeight, window);
      // window.scrollTo(0, window.outerHeight);
      toast.error('Something went wrong!');
    }

    setSaveLoading(false);
  };

  return (
    <Wrapper
      title="Assign initial stock to a batch"
      onBtnClick={onSaveClick}
      buttonDisabled={firstLoading.current}
      btnLoading={saveLoading}
      onCancelBtnClick={() => navigate(-1)}
      buttonTitle="Save changes"
      cancelButtonTitle="Cancel"
      hideBackBtn
      onThirdButtonClick={addNewRow}
      thirdButtonTitle="+ New row"
      className="batches_edit_wrapper"
    >
      { firstLoading.current
        ? <Loader />
        : (
          <div className="batches_body">
            <DynamicTable
              data={batches}
              keyExtractor={(item) => item.batchNumber}
              columns={columns}
              loading={loading}
              renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
              renderColumns={{
                number: ({ item }) => (
                  <div className="batches_table_number">
                    {item.batchNumber}
                  </div>
                ),

                item: ({ item }) => (
                  <div className="product_item_wrapper">
                    <div className="product_item_img">
                      <img
                        src={item.images[0]?.src || item.images[0]?.medium || noImage}
                        alt="img"
                      />
                    </div>

                    <div className="product_item_title">
                      <p>{item.title}</p>
                    </div>
                  </div>
                ),

                sku: ({ item }) => item.sku || '-',

                warehouse: ({ item }) => item.warehouse || '-',

                manufacturedDate: ({ item }) => (
                  <div className="batches_date_wrapper">
                    <Datepicker
                      onChange={(date) => onChangeData('expirationDateFrom', date, item.batchNumber)}
                      value={item.expirationDateFrom ? moment(item.expirationDateFrom).format('MM/DD/YYYY') : ''}
                      error={errors[item.batchNumber]?.expirationDateFrom}
                      wrapperClassName="batches_date"
                    />
                  </div>

                ),

                expirationDate: ({ item }) => (
                  <div className="batches_date_wrapper">
                    <Datepicker
                      onChange={(date) => onChangeData('expirationDateTo', date, item.batchNumber)}
                      value={item.expirationDateTo ? moment(item.expirationDateTo).format('MM/DD/YYYY') : ''}
                      error={errors[item.batchNumber]?.expirationDateTo}
                      wrapperClassName="batches_date"
                    />
                  </div>
                ),

                quantity: ({ item }) => (
                  <Input
                    wrapperClassName="batches_input"
                    value={item.qty || ''}
                    error={errors[item.batchNumber]?.qty}
                    onChange={(e) => (onChangeData('qty', e.target.value, item.batchNumber))}
                    onBeforeInput={Utils.typingNumber}
                  />
                ),

                qty: ({ item }) => item.qtyToAssign || '-',

                actions: ({ item }) => (
                  <div className="products_actions_wrapper">
                    <div
                      role="button"
                      tabIndex="0"
                      onClick={() => deleteBatch(item.batchNumber)}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                ),

              }}
            />

            {totalPages > 1 && (
            <div className="batches_pagination">
              <Pagination
                totalPages={totalPages}
              />
            </div>
            )}
          </div>
        )}
    </Wrapper>
  );
};

export default EditBatches;
