import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Button from '../../_common/Form/Button';
import {
  deleteStockTransferRequest,
  getStockTransferListRequest,
  getWarehouseList, receiveStockTransferRequest,
} from '../../../store/actions/stockTransfer';
import Api from '../../../Api';
import Pagination from '../../_common/Pagination/Pagination';
import StatusField from '../../_common/StatusField/StatusField';
import Loader from '../../_common/Loader/Loader';
import FilterButton from '../../_common/Form/FilterButton';
import { ReactComponent as PrintIcon } from '../../../assets/icons/remote_printing.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import useWindowSize from '../../../helpers/hooks/useWindowSize';
import ProductTable from './StockTransferComponent/ProductTable';
import Input from '../../_common/Form/Input';
import Utils from '../../../helpers/Utils';
import DeleteModal from '../../_common/Modal/DeleteModal';
import Tabs from '../../_common/Tabs/Tabs';

const columns = [
  {
    key: 'item', isEnabled: true,
  },
  {
    key: 'sourceWarehouse', isEnabled: true,
  },
  {
    key: 'destinationWarehouse', isEnabled: true,
  },
  {
    key: 'currentQty', isEnabled: true,
  },
  {
    key: 'quantity', isEnabled: true,
  },
];

const defaultAttributes = {
  item: 'Item',
  sourceWarehouse: 'Source stock',
  destinationWarehouse: 'Destination stock',
  currentQty: 'Transfer qty',
  quantity: 'Receive qty',
};

const receivedColumns = [
  {
    key: 'number', isEnabled: true,
  },
  {
    key: 'receivedQty', isEnabled: true,
  },
  {
    key: 'receiveDate', isEnabled: true,
  },
];

const receivedDefaultAttributes = {
  number: 'Receive#',
  receivedQty: 'Received qty',
  receiveDate: 'Receive date',
};

const ReceiveStockTransfer = () => {
  const navigate = useNavigate();
  const { stockTransferId } = useParams();
  const firstLoading = useRef(true);
  const { isMobile } = useWindowSize(1030);

  const dispatch = useDispatch();
  const stockTransferList = useSelector((store) => store.stockTransfer.stockTransferList);
  const stockTransferTotalPage = useSelector((store) => store.stockTransfer.stockTransferTotalPage);
  const warehouseList = useSelector((store) => store.stockTransfer.warehouseList);

  const [activeTab, setActiveTab] = useState('For receive');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [singleStockTransfer, setSingleStockTransfer] = useState({});
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [singleStockLoading, setSingleStockLoading] = useState(false);
  const [changeTabLoading, setChangeTabLoading] = useState(false);
  const [sourceWarehouse, setStockWarehouse] = useState({});
  const [destinationWarehouse, setDestinationWarehouse] = useState({});
  const [products, setProducts] = useState([]);
  const [receivedTransfer, setReceivedTransfer] = useState([]);
  const [filterReceivedTransfer, setFilterReceivedTransfer] = useState([]);

  const {
    sourceWarehouseId, destinationWarehouseId, note,
  } = singleStockTransfer;

  useEffect(() => {
    (async () => {
      if (!firstLoading.current) {
        setSingleStockLoading(true);
        try {
          const { data: { stockTransfer } } = await Api.getSingleStockTransfer(stockTransferId);
          setSingleStockTransfer(stockTransfer);
          setProducts(stockTransfer.products);
        } catch (e) {
          console.error(e);
        }
        setSingleStockLoading(false);
      }
    })();
  }, [stockTransferId]);

  useEffect(() => {
    (async () => {
      try {
        const [{ data: { stockTransfer } }] = await Promise.all(
          [Api.getSingleStockTransfer(stockTransferId),
            dispatch(getStockTransferListRequest({
              page: 1,
              ids: `${stockTransferId}`,
            })),
            dispatch(getWarehouseList())],
        );
        setProducts(stockTransfer.products);
        setSingleStockTransfer(stockTransfer);
      } catch (e) {
        console.error(e);
      }
      firstLoading.current = false;
    })();
  }, []);

  useEffect(() => {
    if (sourceWarehouseId && destinationWarehouseId) {
      setDestinationWarehouse(warehouseList.find((w) => w.id === destinationWarehouseId));
      setStockWarehouse(warehouseList.find((w) => w.id === sourceWarehouseId));
    }
  }, [sourceWarehouseId, destinationWarehouseId]);

  useEffect(() => {
    (async () => {
      if (activeTab === 'Received') {
        setChangeTabLoading(true);
        try {
          const { data: { receives } } = await Api.getReceiveStockTransferList(stockTransferId);

          setReceivedTransfer(receives);
          setFilterReceivedTransfer(receives);
        } catch (e) {
          console.error(e);
        }

        setChangeTabLoading(false);
      } else {
        setReceivedTransfer([]);
      }
    })();
  }, [activeTab]);

  const changePage = useCallback(async (currentPage, isNavigate) => {
    setLoading(true);
    setPage(currentPage);

    const { payload } = await dispatch(getStockTransferListRequest({
      page: currentPage,
    }));

    if (isNavigate) {
      navigate(`/stocks/stock-transfer/receive/${payload.stockTransfers[0].id}`);
    }

    if (page === 1 && !payload.stockTransfers.length) {
      navigate('/stocks/stock-transfer');
    }
    setLoading(false);
  }, []);

  const stockTransferDelete = async () => {
    setDeleteLoading(true);
    await dispatch(deleteStockTransferRequest(openDeleteModal));

    setPage(1);

    const { payload: { stockTransfers } } = await dispatch(getStockTransferListRequest({
      page: 1,
    }));

    navigate(`/stocks/stock-transfer/receive/${stockTransfers[0].id}`);

    if (stockTransferList.length === 1 && page > 1) {
      await changePage(page - 1, true);
    }

    toast.success('Stock transfer successfully deleted');

    setDeleteLoading(false);

    setOpenDeleteModal('');
  };

  const receiveStock = async () => {
    setBtnLoading(true);
    const { payload } = await dispatch(receiveStockTransferRequest({
      id: stockTransferId,
      products: products.map((p) => ({ productId: p.productId, quantity: p.currentQtyCover || 0 })),
    }));

    if (payload.status === 200) {
      setProducts(payload.data.stockTransfer.products);
      setSingleStockTransfer(payload.data.stockTransfer);
      toast.success('Stock transfer successfully receive.');
    } else {
      toast.error(payload.data?.message || 'Something went wrong.');
    }

    setBtnLoading(false);
  };

  const productFilter = (value) => {
    setSearchValue(value);

    if (activeTab === 'Received') {
      setFilterReceivedTransfer(Utils.filterData(receivedTransfer, value, 'number', 'id'));
    } else {
      setProducts(Utils.filterData(singleStockTransfer.products, value, 'title', 'productId'));
    }
  };

  const generalTabs = useMemo(() => (_.isEmpty(singleStockTransfer)
    ? []
    : [
      {
        name: 'For receive',
        component: (
          <div className="receive_stock_transfer_right_bottom_table_wrapper">
            <ProductTable
              products={products}
              destinationWarehouseId={destinationWarehouseId}
              sourceWarehouseId={sourceWarehouseId}
              setRequestData={setProducts}
              disable={singleStockTransfer.status === 'received'}
              columns={columns}
              defaultAttributes={defaultAttributes}
            />
          </div>
        ),
      },
      {
        name: 'Received',
        component: (
          <div className="receive_stock_transfer_right_bottom_table_wrapper">
            {changeTabLoading
              ? <div className="receive_stock_transfer_right_bottom_table_wrapper_loader"><Loader /></div>
              : (
                <ProductTable
                  products={filterReceivedTransfer}
                  destinationWarehouseId={destinationWarehouseId}
                  sourceWarehouseId={sourceWarehouseId}
                  columns={receivedColumns}
                  defaultAttributes={receivedDefaultAttributes}
                />
              )}
          </div>
        ),
      },

    ]), [activeTab, products, singleStockTransfer, changeTabLoading, filterReceivedTransfer]);

  const changeStock = (id) => {
    setActiveTab('For receive');

    navigate(`/stocks/stock-transfer/receive/${id}`);
  };

  return (
    firstLoading.current
      ? <div className="receive_stock_transfer_loader"><Loader /></div>
      : (
        <div className="receive_stock_transfer_wrapper">
          <div className="receive_stock_transfer_left">
            <div className="receive_stock_transfer_left_header">
              <h1>Stock transfer</h1>

              <Button
                roundBorder
                onClick={() => navigate('/stocks/stock-transfer/create')}
                addBtn
                title={isMobile ? '' : 'Add new'}
                className="receive_stock_transfer_left_header_btn"
              />
            </div>

            <div className="receive_stock_transfer_left_items_wrapper">
              <div className={classNames('receive_stock_transfer_left_items', {
                loading,
              })}
              >
                {stockTransferList.map((item) => (
                  <div
                    role="button"
                    tabIndex="0"
                    key={item.id}
                    className={classNames('receive_stock_transfer_left_item', {
                      active: item.id === stockTransferId,
                    })}
                    onClick={() => changeStock(item.id)}
                  >
                    <div className="receive_stock_transfer_right_block">
                      <p className="receive_stock_transfer_right_number">{item.number}</p>

                      <p>{moment(item.updatedAt).format('MM/DD/YYYY')}</p>
                    </div>

                    <div className="receive_stock_transfer_right_block">
                      <p>{`Qty: ${item.totalUnits}`}</p>

                      <StatusField status={item.status} />
                    </div>
                  </div>
                ))}
              </div>
              {loading && <Loader />}

              <div className="stock_transfer_pagination">
                  <Pagination
                    showNumber={1}
                    currentPage={page}
                    totalPages={+stockTransferTotalPage}
                    onChangePage={changePage}
                  />
              </div>
            </div>

          </div>

          {singleStockLoading
            ? <div className="receive_stock_transfer_right_loader"><Loader /></div>
            : (
              <div className="receive_stock_transfer_right">
                <div className="receive_stock_transfer_right_top">
                  <div>
                    <div className="receive_stock_transfer_right_top_title">
                      <p>{singleStockTransfer.number}</p>
                      <StatusField status={singleStockTransfer.status} />
                    </div>

                    <p>{moment(singleStockTransfer.updatedAt).format('MM/DD/YYYY [at] h:mm A')}</p>
                  </div>

                  <div className="receive_stock_transfer_right_top_buttons_wrapper">
                    <FilterButton
                      onClick={() => setOpenDeleteModal(stockTransferId)}
                      style={{ marginRight: 10 }}
                    >
                      <DeleteIcon />

                      {!isMobile && 'Delete'}
                    </FilterButton>

                    <FilterButton
                      onClick={() => {
                      }}
                      style={{ marginRight: 10 }}
                    >
                      <PrintIcon />

                      {!isMobile && 'Print'}
                    </FilterButton>

                    <Button
                      roundBorder
                      onClick={receiveStock}
                      title="Receive transfer"
                      loading={btnLoading}
                      className={classNames(
                        'receive_stock_transfer_left_header_btn',
                        { disable: singleStockTransfer.status === 'received' },
                      )}
                      disabled={singleStockTransfer.status === 'received'}
                    />

                    <div
                      className="receive_stock_transfer_close"
                      role="button"
                      tabIndex="0"
                      onClick={() => navigate('/stocks/stock-transfer')}
                    >
                      <CancelIcon />
                    </div>
                  </div>

                </div>

                <div className="receive_stock_transfer_right_bottom">
                  <div className="receive_stock_transfer_right_bottom_item">
                    <div className="receive_stock_transfer_right_bottom_title">
                      <h2>Item details</h2>

                      <Input
                        roundBorder
                        value={searchValue}
                        onChange={(ev) => productFilter(ev.target.value)}
                        search
                        placeholder="Search"
                        wrapperClassName="receive_stock_transfer_top_item"
                      />
                    </div>

                    <Tabs
                      tabs={generalTabs}
                      currentTab={activeTab}
                      onChangeTab={setActiveTab}
                    />

                  </div>

                  <div className="receive_stock_transfer_right_bottom_warehouse_wrapper">
                    <div className="receive_stock_transfer_right_bottom_warehouse">
                      <div>
                        <p>Source warehouse</p>

                        <p>{sourceWarehouse.title || '-'}</p>
                        <p>{sourceWarehouse.country?.name || '-'}</p>
                        <p>{sourceWarehouse.address || '-'}</p>
                        <p>{sourceWarehouse.city || '-'}</p>
                        <p>{sourceWarehouse.number || '-'}</p>
                      </div>

                      <div>
                        <p>Destination warehouse</p>

                        <p>{destinationWarehouse.title || '-'}</p>
                        <p>{sourceWarehouse.country?.name || '-'}</p>
                        <p>{destinationWarehouse.address || '-'}</p>
                        <p>{destinationWarehouse.city || '-'}</p>
                        <p>{destinationWarehouse.number || '-'}</p>
                      </div>
                    </div>

                    {note && (
                    <div className="receive_stock_transfer_right_bottom_warehouse">
                      <div className="note">
                        <p>NOTES</p>

                        <p>{note}</p>
                      </div>

                    </div>
                    )}
                  </div>
                </div>
              </div>
            )}

          <DeleteModal
            isOpen={!!openDeleteModal}
            text="stock transfer"
            onDelete={stockTransferDelete}
            loading={deleteLoading}
            onClose={() => setOpenDeleteModal('')}
          />
        </div>
      )
  );
};

export default ReceiveStockTransfer;
