import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../_common/Form/Button';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/checkbox_success.svg';

function PricingCard({
  title, price, text, btnText, reverseBtnColor, onButtonClick, advantages, interval,
}) {
  return (
    <div className="pricing_card_wrp">
      <h3 className="pricing_card_wrp_title">{title}</h3>

      <p className="pricing_card_wrp_text">{text}</p>

      <div className="pricing_card_wrp_price_block">
        <div className="pricing_card_wrp_price">
          {`${price || price === 0 ? '$' : ''}${price}`}
          {!!price && <p className="pricing_card_wrp_price_text">{`per ${interval}`}</p>}
        </div>
      </div>

      <Button
        reverseColor={reverseBtnColor}
        onClick={onButtonClick}
        roundBorder
        size="small"
        className="pricing_card_wrp_button"
      >
        {btnText}
      </Button>

      <div className="pricing_card_wrp_advantages">
        {advantages.map((t) => (
          <div className="pricing_card_wrp_advantage_single">
            <CheckIcon />

            <p className="pricing_card_wrp_advantage_single_text">{t}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

PricingCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  text: PropTypes.string,
  btnText: PropTypes.string,
  advantages: PropTypes.array,
  onButtonClick: PropTypes.func,
  reverseBtnColor: PropTypes.bool,
};

PricingCard.defaultProps = {
  title: '',
  price: '',
  text: '',
  btnText: '',
  advantages: [],
  onButtonClick: () => {},
  reverseBtnColor: false,
};

export default PricingCard;
