import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../_common/Modal/Modal';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning_triangle.svg';
import Button from '../../../_common/Form/Button';

const WarningMessageModal = memo(({
  warningProducts, onClose, onClick, loading,
}) => (
  <Modal
    className="warning_title_modal"
    isOpen={!!warningProducts.length}
    onClose={onClose}
  >
    <div className="warning_title_modal_header">
      <WarningIcon />

      <h2>{'After clicking okay we\'ll update reorder rules with current settings'}</h2>
    </div>

    <div className="warning_title_modal_message_wrapper">
      {warningProducts.map((p, i) => (
        <div className="warning_title_modal_message" key={`${p.title}_${i}`}>
          <p>{p.title}</p>

          <p>{p.message}</p>
        </div>
      ))}
    </div>

    <div className="warning_title_modal_button_wrapper">
      <Button
        className="warning_title_modal_button"
        size="small"
        title="Okay"
        onClick={onClick || onClose}
        roundBorder
        loading={loading}
        color="#1472FF"
      />
    </div>
  </Modal>
));

WarningMessageModal.porpTypes = {
  warningProducts: PropTypes.array,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
WarningMessageModal.defaultProps = {
  warningProducts: [],
  onClose: () => {},
  onClick: null,
  loading: false,
};

export default WarningMessageModal;
