import React, { useCallback, useEffect, useState } from 'react';
import { currencies } from 'currency-formatter';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../../_common/Form/Select';
import Button from '../../../../_common/Form/Button';
import Loader from '../../../../_common/Loader/Loader';
import Api from '../../../../../Api';
import { getSetupRequest } from '../../../../../store/actions/notifications';

const size = [{ value: 'in', label: 'in' }, { value: 's', label: 's' }, { value: 'cm', label: 'cm' }, { value: 'mm', label: 'mm' }];
const weight = [{ value: 'oz', label: 'oz' }, { value: 'lb', label: 'lb' }, { value: 'g', label: 'g' }, { value: 'kg', label: 'kg' }];
const cubic = [{ value: 'feet', label: 'feet' }, { value: 'meter', label: 'meter' }, { value: 'inch', label: 'inch' }];

function General() {
  const dispatch = useDispatch();

  const { hasCurrency } = useSelector((state) => state.notifications.setup);

  const [options, setOptions] = useState({
    warehouses: [],
    companies: [],
    defaultWarehouse: {},
    defaultCompany: {},
  });

  const {
    warehouses, companies, defaultWarehouse, defaultCompany,
  } = options;

  const [generalList, setGeneralList] = useState({
    currencyCode: '',
    sizeUnit: '',
    weightUnit: '',
    cubicUnit: '',
    companyId: '',
    warehouseId: '',
  });
  const [loadingSettings, setLoadingSettings] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const [settings, warehouse, company] = await Promise.all(
        [
          Api.getSettings(['currencyCode', 'cubicUnit', 'sizeUnit', 'weightUnit']),
          Api.getWarehouses({}),
          Api.getCompanies(),
        ],
      );

      setOptions((prev) => (
        {
          ...prev,
          warehouses: warehouse.data.warehouses,
          companies: company.data.companies,
          defaultWarehouse: warehouse.data.warehouses.find((w) => w.isDefault),
          defaultCompany: company.data.companies.find((c) => c.isDefault),
        }
      ));

      setGeneralList((prev) => (
        {
          ...prev,
          currencyCode: settings.data.currencyCode,
          sizeUnit: settings.data.sizeUnit,
          weightUnit: settings.data.weightUnit,
          cubicUnit: settings.data.cubicUnit,
          warehouseId: defaultWarehouse?.id || warehouse.data.warehouses.find((w) => w.isDefault)?.id,
          companyId: defaultCompany?.id || company.data.companies.find((c) => c.isDefault)?.id,
        }
      ));

      setLoadingSettings(false);
    })();
  }, []);

  const changeGeneralSettings = useCallback((path, value) => {
    setGeneralList((prev) => ({
      ...prev,
      [path]: value,
    }));
  }, [generalList]);

  const saveGeneralSettings = useCallback(async () => {
    setBtnLoading(true);

    const requests = [
      Api.updateSettings({
        currencyCode: generalList.currencyCode,
        sizeUnit: generalList.sizeUnit,
        weightUnit: generalList.weightUnit,
        cubicUnit: generalList.cubicUnit,
      }),
      Api.setDefaultWarehouse(generalList.warehouseId),
      Api.setDefaultCompany(generalList.companyId),
    ];

    try {
      if (!hasCurrency) {
        requests.push(dispatch(getSetupRequest()));
      }

      await Promise.all(requests);

      toast.success('Successfully updated');
      setBtnLoading(false);
    } catch (e) {
      toast.error('Something went wrong');
      setBtnLoading(false);
    }
  }, [generalList]);

  return (
    loadingSettings ? <Loader /> : (
      <div className="general_settings">
        <div className="general_settings_content">
          <Button
            onClick={saveGeneralSettings}
            loading={btnLoading}
            className="save_button"
            size="small"
            roundBorder
          >
            Save
          </Button>

          <div className="general_settings_single_line">
            <p className="general_settings_single_label">Set default Currency</p>

            <Select
              options={currencies}
              wrapperClassName="general_settings_select_wrp"
              valuePath="code"
              labelPath="code"
              value={generalList.currencyCode}
              onChange={(code) => changeGeneralSettings('currencyCode', code)}
              roundBorder
              size="small"
              isSearchable
            />
          </div>

          <div className="general_settings_single_line">
            <p className="general_settings_single_label">Set default Warehouse</p>

            <Select
              options={warehouses}
              wrapperClassName="general_settings_select_wrp"
              valuePath="id"
              labelPath="title"
              onChange={(id) => changeGeneralSettings('warehouseId', id)}
              value={generalList.warehouseId}
              roundBorder
              size="small"
            />
          </div>

          <div className="general_settings_single_line">
            <p className="general_settings_single_label">Set default Company</p>

            <Select
              options={companies}
              wrapperClassName="general_settings_select_wrp"
              valuePath="id"
              labelPath="name"
              onChange={(id) => changeGeneralSettings('companyId', id)}
              value={generalList.companyId}
              roundBorder
              size="small"
            />
          </div>

          <div className="general_settings_single_line">
            <p className="general_settings_single_label">Default weight unit</p>

            <Select
              options={weight}
              value={generalList.weightUnit}
              // wrapperClassName="general_settings_select_wrp_mini"
              onChange={(value) => changeGeneralSettings('weightUnit', value)}
              menuPosition="fixed"
              roundBorder
              size="small"
              lightBlue
            />
          </div>

          <div className="general_settings_single_line">
            <p className="general_settings_single_label">Default size unit</p>

            <Select
              options={size}
              value={generalList.sizeUnit}
              wrapperClassName="general_settings_select_wrp_mini"
              onChange={(value) => changeGeneralSettings('sizeUnit', value)}
              menuPosition="fixed"
              roundBorder
              size="small"
            />
          </div>

          <div className="general_settings_single_line">
            <p className="general_settings_single_label">Cubic calculation</p>

            <Select
              options={cubic}
              value={generalList.cubicUnit}
              wrapperClassName="general_settings_select_wrp_mini"
              onChange={(value) => changeGeneralSettings('cubicUnit', value)}
              menuPosition="fixed"
              roundBorder
              size="small"
            />
          </div>
        </div>
      </div>
    )
  );
}

export default General;
