import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '../../../_common/Modal/Modal';
import Input from '../../../_common/Form/Input';
import { createPackagesRequest, getPackagesRequest, updatePackagesRequest } from '../../../../store/actions/settings';
import Button from '../../../_common/Form/Button';
import Switcher from '../../../_common/Form/Switcher';
import Utils from '../../../../helpers/Utils';

const defaultFormData = {
  title: '',
  isDefault: false,
  dimensions: ['', '', ''],
};

function PackageDimensionsFormModal(props) {
  const { isOpen, id, onClose } = props;

  const [form, setForm] = useState(defaultFormData);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const packages = useSelector((state) => state.settings.packages);

  useEffect(() => {
    if (_.isString(id)) {
      const pack = packages.find((p) => p.id === id);
      setForm({ ...pack });
    }
  }, [packages, id]);

  const handleChange = useCallback((key, value) => {
    setForm((prev) => {
      const newData = _.cloneDeep(prev);
      _.set(newData, key, value);

      return newData;
    });

    setErrors((prev) => {
      const newErrors = _.cloneDeep(prev);
      _.unset(newErrors, key);

      return newErrors;
    });
  }, []);

  const closeModal = useCallback(() => {
    onClose();
    setForm(defaultFormData);
    setErrors({});
  }, []);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);

    let hasError = false;

    if (!form.title.trim()) {
      setErrors((prev) => ({ ...prev, title: 'Field is required' }));

      hasError = true;
    }

    form.dimensions.forEach((d, index) => {
      if (!d.toString()) {
        setErrors((prev) => {
          const newErrors = _.cloneDeep(prev);
          _.set(newErrors, `dimensions[${index}]`, 'Field is required');

          return newErrors;
        });

        hasError = true;
      }
    });

    if (!hasError) {
      const { payload } = form.id
        ? await dispatch(updatePackagesRequest(form))
        : await dispatch(createPackagesRequest(form));

      if (payload.status === 'error') {
        setErrors(payload.errors.packageDimensions);
      } else {
        await dispatch(getPackagesRequest());

        toast.success(`Package successfully ${form.id ? 'updated' : 'added'}`);
        closeModal();
      }
    }

    setLoading(false);
  }, [form]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} className="package_dimensions_form_modal">
      <p className="title">New package</p>
      <form onSubmit={handleSubmit}>
        <Input
          label="Name"
          placeholder="Enter name"
          roundBorder
          onChange={(ev) => handleChange('title', ev.target.value)}
          value={form.title}
          error={errors.title}
        />
        <Input
          label="Package Length"
          placeholder="Enter Package Length"
          roundBorder
          onChange={(ev) => handleChange('dimensions[0]', ev.target.value)}
          value={_.get(form, 'dimensions[0]')}
          error={_.get(errors, 'dimensions[0]')}
          onBeforeInput={(e) => Utils.typingFloatNumber(e, null, 2)}
        />
        <Input
          label="Package Height"
          placeholder="Enter Package Height"
          roundBorder
          onChange={(ev) => handleChange('dimensions[1]', ev.target.value)}
          value={_.get(form, 'dimensions[1]')}
          error={_.get(errors, 'dimensions[1]')}
          onBeforeInput={(e) => Utils.typingFloatNumber(e, null, 2)}
        />
        <Input
          label="Package Width"
          placeholder="Enter Package Width"
          roundBorder
          onChange={(ev) => handleChange('dimensions[2]', ev.target.value)}
          value={_.get(form, 'dimensions[2]')}
          error={_.get(errors, 'dimensions[2]')}
          onBeforeInput={(e) => Utils.typingFloatNumber(e, null, 2)}
        />
        <Switcher
          onChange={(checked) => handleChange('isDefault', checked)}
          label="Make as default"
          checked={form.isDefault}
        />
        <div className="actions">
          <Button roundBorder btnType="cancel" onClick={closeModal}>
            Cancel
          </Button>
          <Button roundBorder type="submit" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default PackageDimensionsFormModal;
