import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import Modal from '../../../_common/Modal/Modal';
import ColorPicker from '../../../_common/ColorPicker/ColorPicker';
import { createOrderTagRequest } from '../../../../store/actions/orders';

function TagFormModal(props) {
  const { isOpen, onClose, onSave } = props;

  const [form, setForm] = useState({
    color: '#e3c956',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setErrors({ ...errors });
    setForm({ ...form });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload } = await dispatch(createOrderTagRequest(form));
    if (payload.data.status === 'error') {
      setErrors({ ...payload.data.errors });
    } else {
      onSave(payload.data.tag);
      onClose();
    }
    setLoading(false);
  }, [form]);

  return (
    <Modal
      wrapperClassName="update_modal_amazon_app"
      className="general_settings_update_modal_wrapper"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} className="fields_block">
        <h3 className="fields_block_title">New price list</h3>
        <div className="update_modal_single_input">

          <Input
            value={form.name}
            onChange={(ev) => handleChange('name', ev.target.value)}
            label="Tag name"
            placeholder="Enter tag name"
            error={errors?.name}
            wrapperClassName="update_modal_input_wrp"
            roundBorder
            size="small"
          />
        </div>
        <div className="update_modal_single_input">
          <ColorPicker
            label="Tag color"
            wrapperClassName="update_modal_color_picker"
            value={form.color}
            onChange={(value) => handleChange('color', value)}
            error={errors?.color}
          />

        </div>

        <div className="update_modal_buttons_wrp">
          <Button
            className="update_modal_cancel_button"
            roundBorder
            size="small"
            btnType="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            className="update_modal_save_button"
            roundBorder
            size="small"
            type="submit"
            loading={loading}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default TagFormModal;
