import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const createCreditNoteRequest = createAsyncThunk(
  'creditNotes/createCreditNoteRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createCreditNote(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getCreditNotesRequest = createAsyncThunk(
  'creditNotes/createCreditNoteRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getCreditNotes(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const refundCreditNoteRequest = createAsyncThunk(
  'creditNotes/refundCreditNoteRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.refundCreditNote(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const deleteCreditNoteRequest = createAsyncThunk(
  'creditNotes/deleteCreditNoteRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.deleteCreditNote(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const generateCreditNoteNumberRequest = createAsyncThunk(
  'creditNotes/generateCreditNoteNumberRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.generateCreditNoteNumber(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getCreditNoteRequest = createAsyncThunk(
  'creditNotes/getCreditNoteRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getCreditNote(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const applyCreditNoteToInvoiceRequest = createAsyncThunk(
  'creditNotes/applyCreditNoteToInvoiceRequest',
  async (payload, thunkAPI) => {
    try {
      const { id, ...p } = payload;
      const { data } = await Api.applyCreditNoteToInvoice(id, p);
      return { data };
      } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
