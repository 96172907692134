import React, {
  useCallback, useLayoutEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import Table from '../../../_common/Tables/Table';
import Api from '../../../../Api';
import Select from '../../../_common/Form/Select';
import Loader from '../../../_common/Loader/Loader';

// const stockMappingShops = ['ebay', 'amazon', 'woocommerce'];

const StepOne = ({
  warehouseData, changeData, shopWarehouseList, integrationName, fromMyApp, stockMappingData, integrationType,
}) => {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, loadingToggle] = useState(true);

  const [shopWarehouses, setShopWarehouses] = useState(shopWarehouseList);

  const getWarehouses = useCallback(async () => {
    const { data: { warehouses: list } } = await Api.getWarehouses();

    setWarehouses(_.orderBy(list, 'createdAt', 'desc'));
  }, []);

  useLayoutEffect(() => {
    (async () => {
      loadingToggle(true);

      await getWarehouses();
      setShopWarehouses((prev) => prev.map((l) => (l.id in warehouseData
        ? { ...l, warehouseMapping: warehouseData[l.id] }

        : l)));
      loadingToggle(false);
    })();
  }, []);

  const selectWarehouse = useCallback((id, _, value) => {
    const foundWarehouseId = shopWarehouses.find((w) => w.id === id)?.id;

    changeData((prev) => ({
      ...prev,
      warehouseMapping: {
        ...prev.warehouseMapping,
        [foundWarehouseId]: value,
      },
    }));

    setShopWarehouses((prev) => prev.map((p) => (p.id === id ? { ...p, warehouseMapping: value } : p)));
  }, [warehouses]);

  const createWarehouse = useCallback(async (id) => {
    setShopWarehouses((prev) => prev.map((p) => (p.id === id ? { ...p, loading: true, disabled: true } : p)));

    const { address = '', city = '' } = shopWarehouses.find((w) => w.id === id);

    const { data: { warehouse } } = await Api.createWarehouse({ title: `${integrationName} warehouse`, city, address });

    setWarehouses((prev) => [warehouse, ...prev]);
    selectWarehouse(id, null, warehouse.id);
    setShopWarehouses((prev) => prev.map((p) => (p.id === id ? { ...p, loading: false, disabled: false } : p)));
  }, []);

  // const selectStockMapping = useCallback((value) => {
  //   changeData((prev) => ({
  //     ...prev,
  //     sendStockMapping: value,
  //   }));
  // }, []);

  const tableHeader = useMemo(() => [
    {
      title: 'Warehouse',
      path: 'name',
    },
    {
      title: 'Address',
      path: 'address',
    },
    {
      title: 'Warehouse in eSwap',
      path: 'warehouseMapping',
      fieldType: 'select',
      options: warehouses,
      valuePath: 'id',
      labelPath: 'title',
    },
    {
      title: '',
      path: '',
      fieldType: 'button',
      fieldTitle: 'Create new warehouse',
    },
  ], [warehouses]);

  return (
    <div className={classNames('integration_step_one_wrapper', { my_app: fromMyApp })}>
      {loading
        ? (
          <Loader />
        )

        : (
          fromMyApp
            ? (
              <div className="my_app_step_one_wrapper">
                {/* {stockMappingShops.includes(integrationType) && ( */}
                {/*   <div className="my_app_step_one_wrapper_location"> */}
                {/*     <div className="my_app_step_one_wrapper_location_title"> */}
                {/*       <h2>Select where eSwap should pull inventory stock levels from</h2> */}

                {/*       <div> */}
                {/*         <h3>Orders will be assigned to</h3> */}
                {/*       </div> */}
                {/*     </div> */}

                {/*     <Select */}
                {/*       value={stockMappingData} */}
                {/*       onChange={(value) => selectStockMapping(value)} */}
                {/*       placeholder="Select warehouses" */}
                {/*       options={warehouses} */}
                {/*       valuePath="id" */}
                {/*       labelPath="title" */}
                {/*       isMulti */}
                {/*       roundBorder */}
                {/*       size="small" */}
                {/*       wrapperClassName="my_app_step_one_wrapper_location_select" */}
                {/*     /> */}
                {/*   </div> */}
                {/* )} */}

                <div className="my_app_step_one_wrapper_sync">
                  <div className="my_app_step_one_wrapper_location_title">
                    <h2>Synchronization settings</h2>

                    <div>
                      <h3>Sync stock levels from</h3>
                    </div>
                  </div>

                  <div className="my_app_step_one_wrapper_warehouses">
                    <h4>
                      Choose the warehouses where all of your eSwap stock levels will be synced
                    </h4>

                    <div className="my_app_step_one_wrapper_warehouses_list">
                      {shopWarehouses.map((w) => (
                        <Select
                          key={w.id}
                          value={w.warehouseMapping}
                          label={w.name}
                          onChange={(value) => selectWarehouse(w.id, 'warehouseMapping', value)}
                          options={warehouses}
                          valuePath="id"
                          labelPath="title"
                          roundBorder
                          size="small"
                          wrapperClassName="my_app_step_one_wrapper_location_select"
                          menuPosition="fixed"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )

            : (
              <Table
                data={shopWarehouses}
                header={tableHeader}
                onClick={createWarehouse}
                onChange={selectWarehouse}
              />
            )
        )}
    </div>
  );
};

StepOne.propTypes = {};

export default StepOne;
