import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import Wrapper from '../../../Layout/Wrapper';
import Input from '../../../_common/Form/Input';
import Table from '../../../_common/Tables/Table';
import Loader from '../../../_common/Loader/Loader';
import Api from '../../../../Api';
import CreateCompanyModal from './CreateCompanyModal';
import Button from '../../../_common/Form/Button';
import { ReactComponent as CompaniesNotFoundIcon } from '../../../../assets/icons/no_match.svg';
import TitleBar from '../../../Layout/TitleBar';

const companyDefaultData = {
  name: '',
  logo: '',
  email: '',
  phone: '',
  fax: '',
  address: '',
  size: '',
  industry: '',
  registerNumber: '',
  vatNumber: '',
  countryCode: '',
  regionId: '',
  city: '',
  postalCode: '',
  isDefault: false,
};

const formattingList = (list) => list.map((l) => {
  const country = l.country.name;
  const region = l.region.name;

  return { ...l, fullAddress: _.compact([l.address, l.city, region, l.postalCode, country]).join(', ') };
});

const sortingList = (list, { sortBy, sort }) => _.orderBy(list, (c) => c[sortBy].toLowerCase(), sort);

const searchingList = (list, searchValue) => {
  if (searchValue.trim()) {
    return list.filter((l) => l.name.toLowerCase().includes(searchValue.trim().toLowerCase())
      || l.fullAddress.toLowerCase().includes(searchValue.trim().toLowerCase()));
  }

  return list;
};

const saveData = (data, newDatum, sorting) => {
  let newCompanyList;

  if (data.find((p) => p.id === newDatum.id)) {
    newCompanyList = data.map((p) => (p.id === newDatum.id ? newDatum : p));
  } else {
    newCompanyList = [newDatum, ...data];
  }

  if (newDatum.isDefault) {
    newCompanyList = newCompanyList.map((p) => (p.id !== newDatum.id ? { ...p, isDefault: false } : p));
  }

  return sortingList(formattingList(newCompanyList), sorting);
};

const Companies = () => {
  const [search, setSearch] = useQueryParam(
    's',
    withDefault(StringParam, ''),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const { sort, sortBy } = sorting;
  const list = useRef([]);
  const [companies, setCompanies] = useState([]);

  const [loading, loadingToggle] = useState(true);
  const [deleteLoading, deleteLoadingToggle] = useState(false);

  const [companyData, setCompanyData] = useState({});

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    (async () => {
      const [countryList, companyList] = await Promise.all([Api.getCountries(), Api.getCompanies()]);

      setCountries(countryList.data.countries);

      list.current = sortingList(formattingList(companyList.data.companies), sorting);

      setCompanies(searchingList(list.current, search));

      loadingToggle(false);
    })();
  }, []);

  const createOrEditCompany = useCallback((id) => {
    setCompanyData(() => {
      if (id) {
        return list.current.find((l) => l.id === id);
      }

      return companyDefaultData;
    });
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);

    setCompanies((prev) => sortingList(prev, newSorting));
    list.current = sortingList(list.current, newSorting);
  }, []);

  const searchCompany = useCallback((value) => {
    setSearch(value);

    setCompanies(searchingList(list.current, value));
  }, []);

  const deleteCompany = useCallback(async (id) => {
    deleteLoadingToggle(true);
    await Api.deleteCompany(id);

    setCompanies((prev) => prev.filter((l) => l.id !== id));
    list.current = list.current.filter((l) => l.id !== id);

    toast.success('Company successfully deleted');
    deleteLoadingToggle(false);
  }, []);

  const saveCompany = useCallback((company) => {
    list.current = saveData(list.current, company, sorting);

    setCompanies(searchingList(list.current, search));
  }, [sorting, search]);

  const closeModal = useCallback(() => {
    setCompanyData({});
  }, []);

  const tableHeader = useMemo(() => [
    {
      title: 'Number',
      path: 'index',
    },
    {
      title: 'Logo',
      path: 'logo',
    },
    {
      title: 'Name',
      path: 'name',
      navigate: (id) => createOrEditCompany(id),
      isSelectable: true,
    },
    {
      title: 'Email',
      path: 'email',
      isSelectable: true,
    },
    {
      title: 'Phone',
      path: 'phone',
    },
    {
      title: 'Address',
      path: 'fullAddress',
    },
    {
      title: 'Created at',
      path: 'createdAt',
      isSelectable: true,
    },
  ], [createOrEditCompany]);

  return (
    <Wrapper hideBackBtn>
      <TitleBar title="Companies">
        <Button
          title="Create company"
          addBtn
          data-scope="WRITE_COMPANIES"
          onClick={() => createOrEditCompany()}
        />
      </TitleBar>
      {loading
        ? <Loader />

        : (
          <>
            <Input
              placeholder="Search name or address"
              roundBorder
              size="small"
              search
              value={search}
              onChangeText={searchCompany}
              wrapperClassName="company_search_field"
            />

            <div className="company_list">
              <Table
                data={companies}
                header={tableHeader}
                sortBy={sortBy}
                sort={sort}
                onSortBy={onSortBy}
                defaultLogoKey="company"
                deleteModalText="company"
                deleteLoading={deleteLoading}
                onEditClick={createOrEditCompany}
                onDeleteClick={deleteCompany}
                deleteScope="DELETE_COMPANIES"
                editScope="WRITE_COMPANIES"
                notFound={(
                  <>
                    <CompaniesNotFoundIcon />

                    <h1>Company not found</h1>

                    <Button
                      title="Create company"
                      onClick={() => createOrEditCompany()}
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />
            </div>
          </>
        )}

      <CreateCompanyModal
        isOpen={!_.isEmpty(companyData)}
        data={companyData}
        countries={countries}
        onClose={closeModal}
        onSave={saveCompany}
      />
    </Wrapper>
  );
};

export default Companies;
