import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import ProductCustomField from '../../../_common/ProductCustomField';

function ProductCustomFields({
  isVariant, customFields, errors, onDataChange, selectedVariantIndex,
}) {
  const [show, setShow] = useState(true);

  return (
    <div className="create_product_custom_fields">
      <div className={`create_product_title_block ${!show ? 'not_show' : ''}`}>
        {!isVariant && <ArrowIcon onClick={() => setShow((prev) => !prev)} />}

        <h2 className="create_product_title_block_title">Custom fields</h2>
      </div>

      {show && (
        <div className="create_product_custom_fields_block">
          {customFields.map((c) => {
            if (c.displayInPortal) {
              return (
                <div className="custom_fields_single" key={c.label} data-test-id={c.label}>
                  <ProductCustomField
                    key={c.id}
                    type={c.type}
                    label={c.label}
                    onChange={(val) => onDataChange(isVariant ? 'variantCustomField' : 'customField', val, c.label)}
                    value={c.value || c.defaultValue}
                    error={isVariant
                      ? errors?.variants?.[selectedVariantIndex]?.customFields?.[_.camelCase(c?.label)]
                      : errors?.customFields?.[_.camelCase(c?.label)]}
                    options={c.options || []}
                  />
                </div>
              );
            }

            return null;
          })}
        </div>
      )}
    </div>
  );
}

ProductCustomFields.propTypes = {
  isVariant: PropTypes.bool,
  customFields: PropTypes.array,
  errors: PropTypes.object,
  onDataChange: PropTypes.func,
  selectedVariantIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProductCustomFields.defaultProps = {
  isVariant: false,
  customFields: [],
  errors: {},
  onDataChange: () => {},
  selectedVariantIndex: undefined,
};

export default ProductCustomFields;
