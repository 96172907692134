import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getBatchesList = createAsyncThunk(
  'get/batches/list',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getBatches(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const getMissingBatchesList = createAsyncThunk(
  'get/batches/missing/list',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getMissingBatches(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const deleteBatchRequest = createAsyncThunk('delete/batch/request', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.deleteBatches(payload);

    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const updateBatchRequest = createAsyncThunk('update/batch/request', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.editBatch(payload.id, { batch: payload });

    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const singleBatchData = createAction('single/batch/data');
