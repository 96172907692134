import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import Wrapper from '../../../Layout/Wrapper';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Input from '../../../_common/Form/Input';
import Table from '../../../_common/Tables/Table';

const tableHeader = [
  {
    title: 'Number',
    path: 'number',
    isSelectable: true,
  }, {
    title: 'Name',
    path: 'name',
    isSelectable: true,
  }, {
    title: 'Full name',
    path: 'fullName',
    isSelectable: true,
  }, {
    title: 'Symbol',
    path: 'symbol',
    isSelectable: true,
  }, {
    title: 'Status',
    path: 'status',
    isSelectable: true,
  },
];

const demoData = [
  {
    id: 1,
    number: '1',
    name: 'USD',
    fullName: 'United States dollar',
    symbol: '$',
    status: 'Active',
  },
  {
    id: 2,
    number: '2',
    name: 'EUR',
    fullName: 'Euro',
    symbol: '€',
    status: 'Disabled',
  },
];

const modalFields = [
  {
    title: 'Name',
    path: 'name',
    placeholder: 'Enter Name',
    required: true,
  },
  {
    title: 'Full name',
    path: 'fullName',
    placeholder: 'Enter Full name',
    required: true,
  },
  {
    title: 'Symbol',
    path: 'symbol',
    placeholder: 'Enter Symbol',
    required: true,
  },
];
function Currency() {
  const [modalData, setModalData] = useState({});
  const [checkedItems, checkItems] = useState([]);

  const [sorting, setSorting] = useQueryParams({
    sort: withDefault(StringParam, 'desc'),
    sortBy: withDefault(StringParam, 'createdAt'),
  }, { updateType: 'replaceIn' });

  const { sort, sortBy } = sorting;

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, []);

  const saveCurrency = useCallback(() => {

  }, []);

  const check = (ids) => {
    console.log(ids, 111);
    checkItems(ids);
  };

  return (
    <Wrapper
      title="Currency"
      onBtnClick={() => setModalData({ title: '', slug: '' })}
      addBtn
      buttonTitle="New currency"
    >
      <div className="shipping_types">
        <Input
          onChangeText={() => {
          }}
          search
          placeholder="Search name or full name"
          roundBorder
          size="small"
        />

        <Table
          sortBy={sortBy}
          onSortBy={onSortBy}
          sort={sort}
          checkedItems={checkedItems}
          onCheck={check}
          data={demoData}
          header={tableHeader}
          onEditClick={() => {
          }}
          onDeleteClick={() => {
          }}
        />
      </div>

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalFields}
        onClose={() => setModalData({})}
        onSave={saveCurrency}
        singleData={modalData}
        fullTitle="Add currency"
        withDefaultSwitcher
        switcherLabel="Status"
      />
    </Wrapper>
  );
}

export default Currency;
