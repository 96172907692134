import { createReducer } from '@reduxjs/toolkit';
import {
  changeSalesOrdersTableAttributes,
  generateOrderNumberRequest,
  getOrderCommentsAndHistoryRequest,
  getOrdersRequest, getOrderTagsRequest,
  getSalesOrdersTableAttributes,
  getSingleOrderRequest,
  salesOrdersTableEditableEvent,
} from '../actions/orders';

const initialState = {
  orderNumber: '',
  orders: [],
  totalPages: 1,
  order: {},
  commentsAndHistory: [],
  tableEditable: false,
  tableColumns: [
    { key: 'orderId', isEnabled: true },
    { key: 'shop', isEnabled: true },
    { key: 'customer', isEnabled: true },
    { key: 'shippingAddress', isEnabled: true },
    { key: 'billingAddress', isEnabled: true },
    { key: 'status', isEnabled: true },
    { key: 'paymentStatus', isEnabled: true },
    { key: 'tags', isEnabled: true },
    { key: 'createdAt', isEnabled: true },
    { key: 'total', isEnabled: true },
  ],
  orderTags: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(generateOrderNumberRequest.fulfilled, (state, action) => {
      state.orderNumber = action.payload.data.number;
    })
    .addCase(getSingleOrderRequest.fulfilled, (state, action) => {
      state.order = action.payload.data.order;
    })
    .addCase(getOrdersRequest.pending, (state) => {
      state.totalPages = 1;
    })
    .addCase(getOrdersRequest.fulfilled, (state, action) => {
      state.orders = action.payload.data.orders;
      state.totalPages = action.payload.data.totalPages;
    })
    .addCase(getOrderCommentsAndHistoryRequest.fulfilled, (state, action) => {
      state.commentsAndHistory = action.payload.data.orderHistoryList;
    })
    .addCase(salesOrdersTableEditableEvent, (state, { payload }) => {
      state.tableEditable = payload;
    })
    .addCase(changeSalesOrdersTableAttributes, (state, { payload }) => {
      state.tableColumns = payload;
    })
    .addCase(getOrderTagsRequest.fulfilled, (state, { payload }) => {
      state.orderTags = payload.data.tags;
    })
    .addCase(getSalesOrdersTableAttributes.fulfilled, (state, { payload }) => {
      state.tableColumns = payload.ordersTableAttributes ? payload.ordersTableAttributes : state.tableColumns;
    });
});

export default reducer;
