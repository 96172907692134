import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SketchPicker } from 'react-color';

class Input extends Component {
  static propTypes = {
    wrapperClassName: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    compact: PropTypes.bool,
    editable: PropTypes.bool,
  };

  static defaultProps = {
    wrapperClassName: '',
    placeholder: ' ',
    compact: false,
    editable: false,
    onChange: () => {
    },
  };

  static id = 0;

  constructor(props) {
    super(props);
    this.constructor.id += 1;
    this.id = `gh-color-${this.constructor.id}`;
    this.state = {
      isOpen: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleMouseDown);
  }

  handleChange = (ev) => {
    if (this.props.onChange) {
      this.props.onChange(ev.target.value);
    }
  };

  handleColorChange = (color) => {
    if (this.props.onChange) {
      this.props.onChange(color.hex);
    }
  };

  openModal = () => {
    this.setState({ isOpen: true });
    window.addEventListener('mousedown', this.handleMouseDown);
  };

  closeModal = () => {
    this.setState({ isOpen: false });
    window.removeEventListener('mousedown', this.handleMouseDown);
  };

  handleMouseDown = (ev) => {
    if (!ev.target.closest(`#${this.id}_wrapper`)) {
      this.closeModal();
    }
  };

  render() {
    const { isOpen } = this.state;
    const {
      children, iconRight, iconLeft, onChangeText, compact,
      wrapperClassName, color, error, label, id, editable, ...props
    } = this.props;

    return (
      <div
        id={`${id || this.id}_wrapper`}
        className={classNames('color_picker', wrapperClassName, {
          'has-icon-left': iconLeft,
          error,
          open: isOpen,
          'has-value': !!props.value,
        })}
      >
        {iconLeft}

        <div className="color_picker_input_wrapper">
          {props.value && (
            <label
              htmlFor={id || this.id}
              className="picker-color"
              style={{ backgroundColor: props.value }}
            />
          )}

          <input
            type="text"
            id={id || this.id}
            {...props}
            onChange={this.handleChange}
            onFocus={this.openModal}
            onClick={this.openModal}
            readOnly={!editable}
          />
        </div>

        {isOpen ? (
          <SketchPicker disableAlpha color={props.value} onChange={this.handleColorChange} />
        ) : null}

        {label ? (
          <label className="label" htmlFor={id || this.id}>
            {props.required ? <span className="required">*</span> : null}
            {label}
          </label>
        ) : null}

        <span className="line" />
        {iconRight}
        {error ? <span className="error_text" title={error}>{error}</span> : null}
        {children}
      </div>
    );
  }
}

export default Input;
