import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  deleteBatchRequest, getBatchesList, getMissingBatchesList, singleBatchData, updateBatchRequest,
} from '../actions/batches';

const initialState = {
  batchLists: [],
  batchTotalPage: 1,
  singleBatch: {},
};

const reducer = createReducer(initialState, (builder) => {
  builder
  // .addCase(getBatchesList.pending, (state) => {
  //   // state.batchLists = [];
  //   // state.batchTotalPage = 1;
  // })

    .addCase(getBatchesList.fulfilled, (state, { payload }) => {
      state.batchLists = _.uniqBy(payload.batches, 'id');
      state.batchTotalPage = payload.totalPages;
    })

    .addCase(getMissingBatchesList.fulfilled, (state, { payload }) => {
      state.batchLists = _.uniqBy(payload.batches, 'id');
      state.batchTotalPage = payload.totalPages;
    })

    .addCase(singleBatchData, (state, { payload }) => {
      state.singleBatch = payload;
    })

    .addCase(updateBatchRequest.fulfilled, (state, action) => {
      const updateData = action.meta.arg;
      state.batchLists = state.batchLists.map((b) => (b.id === updateData.id
        ? {
          ...b,
          qty: updateData.qty,
          expirationDateFrom: updateData.expirationDateFrom,
          expirationDateTo: updateData.expirationDateTo,
        }
        : b));
    })

    .addCase(deleteBatchRequest.fulfilled, (state, action) => {
      state.batchLists = state.batchLists.filter((b) => b.id !== action.meta.arg);
    });
});

export default reducer;
