import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Loader from '../../../_common/Loader/Loader';
import Button from '../../../_common/Form/Button';
import { ReactComponent as DragIcon } from '../../../../assets/icons/dashboard_drag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/dashboard_close.svg';
import Modal from '../../../_common/Modal/Modal';
import Checkbox from '../../../_common/Form/Checkbox';
import useEventCallback from '../../../../helpers/hooks/useEventCallback';
import { getDashboardOrderSummaryRequest } from '../../../../store/actions/dashboard';
import Api from '../../../../Api';

const colors = ['#FF9900', '#003AD2', '#16C35B', '#fff700', '#739BFFFF', '#CA00FFFF', '#FFF700FF',
  '#e60049', '#0bb4ff', '#50e991', '#e6d800', '#9b19f5', '#ffa300', '#dc0ab4', '#b3d4ff', '#00bfa0',
  '#fd7f6f', '#7eb0d5', '#b2e061', '#bd7ebe', '#ffb55a', '#ffee65', '#beb9db', '#fdcce5', '#8bd3c7'];

const chartTemplate = {
  options: {
    chart: {
      height: 470,
      type: 'line',

      toolbar: {
        show: false,
        // tools: {
        //   download: true,
        //   selection: false,
        //   zoom: false,
        //   zoomin: false,
        //   zoomout: false,
        //   pan: false,
        //   reset: false,
        // },
      },
    },

    dataLabels: {
      enabled: false,
    },

    fill: {
      opacity: 0.3,
      type: 'solid',
    },

    stroke: {
      curve: 'straight',
    },

    xaxis: {
      categories: [],
      labels: {
        formatter(val) {
          return moment(val)
            .format('MM/DD');
        },
      },
    },

    yaxis: {
      tickAmount: 6,
      labels: {
        formatter(val) {
          const formatter = Intl.NumberFormat('en', { notation: 'compact' });

          return `$${formatter.format(val)}`;
        },
      },
    },

    // tooltip: {
    //   style: {
    //     fontSize: '12px',
    //     fontFamily: 'Inter, sans-serif',
    //   },
    //   x: {
    //     formatter(val, { dataPointIndex }) {
    //       return chartTemplate.options.xaxis.categories.find((c, index) => index === dataPointIndex)?.date;
    //     },
    //   },
    //
    //   y: {
    //     formatter(val) {
    //       const formatter = Intl.NumberFormat('en');
    //       return `$ ${formatter.format(val)}`;
    //     },
    //   },
    // },

    colors,

    markers: {
      seriesIndex: 0,
      dataPointIndex: 5,
      colors: ['#ffffff'],
      strokeColors: colors,
      showNullDataPoints: true,
      size: 5,
      strokeWidth: 3,
      shape: 'circle',
      hover: {
        size: 5,
      },
    },

    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'Inter, sans-serif',
      showForSingleSeries: true,
      floating: false,
      offsetY: 20,
      offsetX: 15,
      height: 50,
      markers: {
        width: 18,
        height: 18,
        offsetY: 3,
      },
    },
  },

  series: [{
    name: '',
    data: [],
  }],
};

function DashboardSalesOrdersSummary({
  data,
  innerRef,
  disable,
  onDelete,
  startDate,
  endDate,
}) {
  const dispatch = useDispatch();
  const firstLoad = useRef(true);
  const userActiveShops = useRef(null);

  const salesOrdersSummary = useSelector((state) => state.dashboard.salesOrdersSummary);
  const salesOrdersSummaryStatus = useSelector((state) => state.dashboard.salesOrdersSummaryStatus);
  const allShops = useSelector((state) => state.dashboard.shops);

  const closeRef = useRef();
  const [chart, setChart] = useState(chartTemplate);
  const [gridKey] = useState(1);
  const [chartInterval, setChartInterval] = useState('day');
  const [shopModal, setShopModal] = useState(false);
  const [savedShops, setSavedShops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (allShops.length) {
        setLoading(true);
        let shops = savedShops;

        const requestData = {
          startDate,
          endDate,
          shop: shops.map((sh) => sh.id),
        };

        if (!shops.length && firstLoad.current) {
          shops = await Api.getSettings(['dashboardSalesOrders']);

          const foundSavedShops = allShops.filter((sh) => shops.data?.dashboardSalesOrders?.includes(sh.id));

          if (shops?.data?.dashboardSalesOrders?.length) {
            requestData.shop = foundSavedShops.map((sh) => sh.id);

            setSavedShops(foundSavedShops.length ? foundSavedShops : allShops);
          } else {
            setSavedShops(allShops);
          }
        }

        const { payload } = await dispatch(getDashboardOrderSummaryRequest({
          ...requestData,
          shop: requestData.shop.length ? requestData.shop : [0],
        }));

        await setChartInterval(payload.data.interval);

        firstLoad.current = false;
      }
    })();
  }, [startDate, endDate, allShops]);

  useEffect(() => {
    if (salesOrdersSummaryStatus === 'ok') {
      if (savedShops?.length) {
        const chartData = {};

        const categories = salesOrdersSummary.map((s) => {
          const returnedDate = new Date(Object.keys(s).toString());

          return chartInterval === 'month' ? moment(returnedDate).format('MMM') : returnedDate;
        });

        savedShops.forEach((sh) => {
          chartData[sh.id] = [];
        });

        salesOrdersSummary.forEach((s) => {
          Object.values(s)[0].forEach((shop) => {
            chartData[shop?.id] = [...chartData[shop?.id], shop.amount];
          });
        });

        setChart((prev) => (
          {
            ...prev,
            series: Object.entries(chartData)
              .map((obj) => (
                {
                  name: allShops.find((sh) => sh.id === obj[0]).name,
                  id: allShops.find((sh) => sh.id === obj[0]).id,
                  data: obj[1],
                }
              )),
            options: {
              ...prev.options,
              xaxis: {
                ...prev.options.xaxis,
                categories,
                // labels: {
                //   formatter(val) {`
                //     console.log(val, 'vvvaaaallll', chartInterval);
                //     return chartInterval === 'month' ? moment(val).format('MMM') : moment(val).format('MM/DD');
                //   },
                // },
              },
              legend: {
                ...prev.options.legend,
                formatter(seriesName, opts) {
                  return (
                    `${seriesName === 'eSwap' ? 'eSwap' : _.upperFirst(seriesName)}
                     <button data-id="${opts.w.config.series[opts.seriesIndex].id}" class="close_button">✕</button>`
                  );
                },
              },
            },
          }
        ));
      }

      setLoading(false);
    }
  }, [salesOrdersSummary]);

  useEffect(() => {
    closeRef.current?.addEventListener('click', handleRemoveClick);

    return () => {
      closeRef.current?.removeEventListener('click', handleRemoveClick);
    };
  }, [closeRef]);

  const handleRemoveClick = useEventCallback(async (ev) => {
    if (ev.target.classList.contains('close_button') || ev.target.closest('.close_button')) {
      if (savedShops.length !== 1) {
        setLoading(true);

        const id = ev.target.getAttribute('data-id');

        const filteredShops = savedShops.filter((sh) => sh.id !== id);

        setSavedShops(filteredShops);

        await dispatch(getDashboardOrderSummaryRequest({ startDate, endDate, shop: filteredShops.map((sh) => sh.id) }));
        await Api.updateDashboardLayout(filteredShops.map((sh) => sh.id), 'dashboardSalesOrders');

        setLoading(false);
      }
    }
  }, []);

  const onShopChange = useCallback((shop) => {
    setSavedShops((prev) => {
      const foundShop = prev.find((sh) => sh.id === shop.id);

      if (foundShop) {
        return savedShops.length === 1 ? prev : prev.filter((sh) => sh.id !== shop.id);
      }

      return [...prev, shop];
    });
  }, [savedShops]);

  const handleModalOpen = () => {
    setShopModal(true);
    userActiveShops.current = savedShops;
  };

  const onCancelAddingShop = () => {
    setSavedShops(userActiveShops.current);
    setShopModal(false);
  };

  const onSaveAddingShops = async () => {
    setSaveLoading(true);

    await dispatch(getDashboardOrderSummaryRequest({ startDate, endDate, shop: savedShops.map((sh) => sh.id) }));
    await Api.updateDashboardLayout(savedShops.map((sh) => sh.id), 'dashboardSalesOrders');
    setShopModal(false);

    setSaveLoading(false);
  };

  return (
    <div
      ref={(ref) => {
        innerRef[data.key] = ref;
      }}
      className="grid-stack-item"
      gs-max-w="12"
      gs-min-w="6"
      gs-max-h="3"
      gs-min-h="3"
      gs-id={data.key}
      gs-w={data.value.position.w}
      gs-h={data.value.position.h}
      gs-x={data.value.position.x}
      gs-y={data.value.position.y}
      style={{ display: data?.show === false ? 'none' : 'block' }}
    >
      <div className="grid-stack-item-content" ref={closeRef}>
        <div className="chart_wrapper" style={{ cursor: disable ? 'grab' : 'default' }}>
          <div className="dashboard_sales_orders_summary">
            <div className="dashboard_sales_orders_summary_top">
              <div className="dashboard_sales_orders_summary_left">
                <h3>Sales order summary</h3>

                {/* {!disable && ( */}
                {/*   <div className="left_shops_block"> */}
                {/*     {allShops.map((s) => ( */}
                {/*       <div className="summary_shop_chip" key={s} /> */}
                {/*     ))} */}
                {/*   </div> */}
                {/* )} */}
              </div>

              <div className="dashboard_sales_orders_summary_right">
                {disable ? (
                  <div className="drag_icons">
                    <DragIcon />

                    <CloseIcon onClick={onDelete} />
                  </div>
                ) : (!!savedShops.length && salesOrdersSummaryStatus === 'ok'
                  && (
                    <Button
                      size="small"
                      addBtn
                      roundBorder
                      title="Add chart"
                      btnType="light_blue"
                      onClick={handleModalOpen}
                    />
                  )
                )}
              </div>
            </div>

            {loading || saveLoading ? <Loader /> : (
              !savedShops.length && salesOrdersSummaryStatus === 'ok'
                ? <div className="summary_chart_no_data">No Data Found</div>
                : (
                  <Chart
                    key={gridKey}
                    options={chart.options}
                    series={chart.series}
                    // width="94%"
                    type="line"
                    height={425}
                  />
                ))}

            <Modal isOpen={shopModal} onClose={() => setShopModal(false)} className="shop_modal">
              <div className="dashboard_sales_orders_summary_shop_modal">
                <h3>All shops</h3>

                {allShops?.map((sh) => (
                  <div className="dashboard_sales_orders_summary_shop_single" key={sh}>
                    <Checkbox
                      onChange={() => onShopChange(sh)}
                      label={_.upperFirst(sh.name)}
                      checked={savedShops.find((s) => s.id === sh.id)}
                    />
                  </div>
                ))}

                <div className="dashboard_sales_orders_summary_shop_modal_btns">
                  <Button
                    size="small"
                    roundBorder
                    title="Cancel"
                    btnType="cancel"
                    onClick={onCancelAddingShop}
                  />

                  <Button
                    size="small"
                    roundBorder
                    title="Save changes"
                    onClick={onSaveAddingShops}
                    loading={saveLoading}
                    className="dashboard_sales_orders_summary_save_btn"
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardSalesOrdersSummary;
