import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useClickAway } from 'react-use';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import Table from '../../../_common/Tables/Table';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import Loader from '../../../_common/Loader/Loader';
import Api from '../../../../Api';
import Checkbox from '../../../_common/Form/Checkbox';
import DefaultImage from '../../../../assets/icons/default.svg';

function ProductBundles({
  onDataChange, bundles = [], warehouses, setProductData, productHasBundle, onVariantOrBundleCheck,
  onCreateProduct, productData,
}) {
  const navigate = useNavigate();

  const [result, setResult] = useState([]);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [value, setValue] = useState('');

  const onBundleProductClick = useCallback(async (id) => {
    const foundBundle = bundles.find((b) => b.id.toString() === id.toString());

    const parentId = (foundBundle.bundleParentId && foundBundle.bundleParentId !== '0')
      ? foundBundle.bundleParentId : foundBundle.parentId;

    const productId = parentId ? foundBundle.bundleProductId
      ? foundBundle.bundleProductId : foundBundle.id
      : foundBundle.bundleProductId || foundBundle.id;

    try {
      await onCreateProduct(true);

      navigate(parentId ? `/products/${parentId}?productVariantId=${productId}` : `/products/${productId}`);
    } catch (e) {
      console.log(e);
    }
  }, [bundles, productData]);

  const tableHeader = [
    {
      title: 'Product',
      path: 'productItem',
      navigate: (id) => onBundleProductClick(id),
    },
    // {
    //   title: 'MAC',
    //   path: 'mac',
    // },
    {
      title: 'Stock',
      path: 'bundleAvailable',
    },
    {
      title: 'Quantity',
      path: 'bundleQuantity',
      fieldType: 'input',
      validation: 'number',
      placeholder: '1',
    },
    // {
    //   title: 'Warehouse',
    //   path: 'bundleWarehouseId',
    //   fieldType: 'select',
    //   options: warehouses,
    //   labelPath: 'title',
    //   valuePath: 'id',
    // },
  ];

  const searchTimeout = useRef();
  const ref = useRef(null);

  useEffect(() => {
    if (value) {
      searchTimeout.current = setTimeout(async () => {
        const { data } = await Api.getProducts({ s: value, type: 'variant,simple', includes: 'stock' });

        setResult(data.products);

        setSearchLoading(false);
      }, 500);
    } else {
      setSearchLoading(false);
    }
  }, [value]);

  useEffect(() => {
    if (productHasBundle && bundles.length) {
      const newBundles = [...bundles];

      const bundleCount = newBundles.length;
      const groupedBundles = _.groupBy(newBundles.map((b) => b.bundleProductStocks
        .map((s) => ({ ...s, bundleQuantity: b.bundleQuantity }))).flat(1), 'warehouseId');

      const filteredBundles = {};

      _.forEach(groupedBundles, (val, key) => {
        if (val.length === bundleCount) {
          filteredBundles[key] = Math.floor(_.minBy(val.map((v) => ({
            ...v, possibleAvailable: v.available / (+v.bundleQuantity || 1),
          })), 'possibleAvailable').possibleAvailable);
        }
      });

      const newStockWarehouses = productData.stockWarehouses.map((w) => {
        if (filteredBundles[w.id] !== undefined) {
          return {
            ...w,
            available: filteredBundles[w.id],
          };
        }

        return { ...w, available: 0 };
      });

      setProductData((prev) => ({
        ...prev,
        stockWarehouses: newStockWarehouses,
      }));
    }
  }, [bundles]);

  const changeSearchValue = (val) => {
    setSearchLoading(true);

    if (val) {
      setSearchOpen(true);
    } else {
      setSearchOpen(false);
      setResult([]);
    }

    clearTimeout(searchTimeout.current);
    setValue(val);
  };

  useClickAway(ref, () => {
    setSearchOpen(false);
  });

  const visibleSearchResult = useCallback((res) => {
    const src = res.images?.[0]?.src ? res.images?.[0]?.src : res.images?.[0];

    return (
      <div className="search_result_single_returned">
        <img src={src || DefaultImage} alt="img" className="search_result_single_img" />

        <p>{res?.title || 'a'}</p>
      </div>
    );
  }, []);

  const resetSearch = useCallback(() => {
    setSearchOpen(false);
    setResult([]);
    setValue('');
  }, [value, result, isSearchOpen]);

  const onDeleteBundle = useCallback((id) => {
    setProductData((prev) => ({
      ...prev,
      bundles: prev.bundles.filter((b) => b.id.toString() !== id.toString()),
    }));
  }, []);

  const onSearchOrViewClick = useCallback((product) => {
    const newBundles = [...bundles, {
      title: product.title,
      // mac: '',
      bundleQuantity: 1,
      // bundleWarehouseId: defaultWarehouse.id,
      bundle: {
        productId: product.id,
        available: product.totalStock.available || 0,
        quantity: 1,
        allocated: 0,
      },
      id: _.uniqueId('bundle_id'),
      // bundleProductId: product.id,
      bundleProductStocks: product.stocks,
      bundleAvailable: product.totalStock.available || 0,
      type: product.type,
      bundleParentId: product.parentId,
    }];

    setProductData((prev) => ({
      ...prev,
      bundles: newBundles,
    }));

    resetSearch();
  }, [warehouses, bundles]);

  return (
    <div className="create_product_bundles">
      <div className={`create_product_title_block ${!show ? 'not_show' : ''}`}>
        <ArrowIcon onClick={() => setShow((prev) => !prev)} />

        <h2 className="create_product_title_block_title">Bundles</h2>
      </div>

      {show && (
        <>
          <div className="create_product_bundle_checkbox">
            <Checkbox
              checked={productHasBundle}
              onChange={(checked) => onVariantOrBundleCheck('bundles', checked)}
              label="This product has bundles"
              className="create_product_options_checkbox"
            />
          </div>

          {productHasBundle && (
          <div className="create_product_bundles_block">
            <div className="create_product_bundles_search" ref={ref}>
              <div className="create_product_bundles_search_top">
                <label className="global_search_field_input">
                  <input
                    type="text"
                    placeholder="Search"
                    value={value}
                    onChange={({ target }) => changeSearchValue(target.value)}
                  />

                  {!searchLoading ? <SearchIcon /> : <Loader size={18} className="search_loader" />}
                </label>

                {isSearchOpen && (
                <div className="global_search_results_wrp">
                  {searchLoading
                    ? <p style={{ textAlign: 'center' }}>Searching...</p>
                    : result.length ? result.map((r) => (
                      <div
                        className={`search_result_single 
                          ${bundles.find((b) => b.bundle.productId === r.id) ? 'disable' : ''}`}
                        onClick={() => !bundles.find((b) => b.bundle.productId === r.id) && onSearchOrViewClick(r)}
                        key={r.id}
                      >
                        <SearchIcon />

                        {visibleSearchResult(r)}
                      </div>
                    ))
                      : <p style={{ textAlign: 'center' }}>No result</p>}
                </div>
                )}
              </div>
            </div>

            <div className="create_product_bundles_table">
              <Table
                data={bundles}
                header={tableHeader}
                onChange={(id, path, val) => onDataChange('bundles', val, id, path)}
                onDeleteClick={(id) => onDeleteBundle(id)}
              />
            </div>
          </div>
          )}
        </>
      )}
    </div>
  );
}

ProductBundles.propTypes = {
  bundles: PropTypes.array,
  warehouses: PropTypes.array,
  setProductData: PropTypes.func,
  onDataChange: PropTypes.func,
  productHasBundle: PropTypes.bool,
  onVariantOrBundleCheck: PropTypes.func,
  onCreateProduct: PropTypes.func,
  productData: PropTypes.object,
};

ProductBundles.defaultProps = {
  bundles: [],
  warehouses: [],
  setProductData: () => {},
  onDataChange: () => {},
  onVariantOrBundleCheck: () => {},
  onCreateProduct: () => {},
  productHasBundle: false,
  productData: {},
};

export default ProductBundles;
