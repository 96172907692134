import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import classNames from 'classnames';
import Wrapper from '../../../Layout/Wrapper';
import Api from '../../../../Api';
import HtmlIframe from './HtmlInIframe';
import Loader from '../../../_common/Loader/Loader';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as DefaultIcon } from '../../../../assets/icons/checkbox_success_blue.svg';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import Menu from '../../../_common/Menu/Menu';

const menu = [
  {
    title: 'Sales quotation',
    type: 'orders/sales-quotation',
  },
  {
    title: 'Packing slip',
    type: 'orders/packing-slip',
  },
  {
    title: 'Invoice',
    type: 'invoices/invoice',
  },
  {
    title: 'Order',
    type: 'orders/order',
  },
];

const menuOptions = [
  {
    label: (
      <div className="template_menu_option">
        <DefaultIcon className="default" />

        <p>Set as default</p>
      </div>
    ),
    value: 'default',
  },
  {
    label: (
      <div className="template_menu_option">
        <DeleteIcon className="delete" />

        <p>Delete template</p>
      </div>
    ),
    value: 'delete',
  },
];

const EmailTemplates = () => {
  const [type, setType] = useQueryParam('page', withDefault(StringParam, 'orders/sales-quotation'));

  const [templates, setTemplates] = useState([]);
  const [loading, loadingToggle] = useState(false);
  const [deletingModalId, setDeletingModalId] = useState('');
  const [deleteLoading, deleteLoadingToggle] = useState(false);
  const [defaultId, setDefaultId] = useState('');

  useEffect(() => {
    (async () => {
      loadingToggle(true);
      const { data } = await Api.getTemplates(type);

      setTemplates(data.templates);

      loadingToggle(false);
    })();
  }, [type]);

  const deleteTemplate = async () => {
    deleteLoadingToggle(true);

    await Api.deleteTemplate(deletingModalId);
    setTemplates((prev) => prev.filter((p) => p.id !== deletingModalId));

    setDeletingModalId('');
    deleteLoadingToggle(false);
  };

  const onMenuSelect = async (id, value) => {
    if (value === 'default') {
      setDefaultId(id);
      await Api.setDefaultTemplate(id);
      setTemplates((prev) => prev.map((p) => ({ ...p, isDefault: p.id === id })));

      setDefaultId('');
    } else {
      setDeletingModalId(id);
    }
  };

  return (
    <Wrapper
      title="Email templates"
    >
      <div className="templates_wrapper">
        <div className="templates_menu">
          {menu.map((m) => (
            <div
              key={m.title}
              className={classNames('menu_item', { active: m.type === type })}
              onClick={() => setType(m.type)}
            >
              {m.title}
            </div>
          ))}
        </div>

        <div className="templates_examples">
          { loading
            ? <Loader />

            : templates.map(({
              id, ownerId, html, isDefault,
            }) => {
              const templatesMenu = !ownerId ? menuOptions.filter(({ value }) => value !== 'delete') : menuOptions;

              return (
                <div key={id} className="template">
                  <div className="template_actions_wrapper">
                    {isDefault
                      ? (
                        <div
                          className={classNames('default_template_icon_wrapper')}
                          data-tooltip-id="default-tooltip"
                          data-tooltip-content="Default template"
                        >
                          <DefaultIcon />
                        </div>
                      )

                      : (
                        <Menu
                          options={templatesMenu}
                          onChange={(menuOption) => onMenuSelect(id, menuOption)}
                          loading={id === defaultId}
                        />
                      )}

                    {/* {ownerId && !isDefault && ( */}
                    {/*   <Button */}
                    {/*     onClick={() => setDeletingModalId(id)} */}
                    {/*     className="delete_template_icon_wrapper" */}
                    {/*     btnType="transparent" */}
                    {/*     tooltip="Delete template" */}
                    {/*   > */}
                    {/*     <DeleteIcon /> */}
                    {/*   </Button> */}
                    {/* )} */}

                    {/* <Button */}
                    {/*   className={classNames('default_template_icon_wrapper', { inactive: !isDefault })} */}
                    {/*   tooltip={!isDefault ? 'Set as default' : 'Default template'} */}
                    {/*   btnType="transparent" */}
                    {/*   onClick={() => !defaultId && setDefaultTemplate(id)} */}
                    {/*   loading={id === defaultId} */}
                    {/*   loaderSize={16} */}
                    {/* > */}
                    {/*   <DefaultIcon /> */}
                    {/* </Button> */}
                  </div>

                  <NavLink to={`/settings/email-templates/${id}`}>
                    {/* <div dangerouslySetInnerHTML={{ __html: html }}/> */}

                    <HtmlIframe html={html} />
                  </NavLink>
                </div>
              );
            })}
        </div>
      </div>

      <DeleteModal
        isOpen={!!deletingModalId}
        onDelete={deleteTemplate}
        onClose={() => setDeletingModalId('')}
        loading={deleteLoading}
        text="template"
      />
    </Wrapper>
  );
};

EmailTemplates.propTypes = {

};

export default EmailTemplates;
