import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Input from '../components/_common/Form/Input';
import Button from '../components/_common/Form/Button';
import LogoutLayout from '../components/Layout/LogoutLayout';
import Utils from '../helpers/Utils';
import Api from '../Api';

const ResetPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const changeEmail = useCallback((value) => {
    setEmail(value);

    setError('');
  }, []);

  const reset = useCallback(async (e) => {
    e.preventDefault();

    let hasError = !!error;
    setLoading(true);

    if (!email.trim()) {
      setError('Field is required');
      hasError = true;
    } else if (!Utils.isEmail(email)) {
      setError('Enter valid email');
      hasError = true;
    }

    if (hasError) {
      toast.error('Please correct the field');
    } else {
      try {
        await Api.forgotPassword({ email, callbackUrl: `${window.location.origin}/create-password` });

        navigate('/login');
      } catch (err) {
        toast.error('Please correct the field');

        setError(err.response.data.message);
      }
    }

    setLoading(false);
  }, [email]);

  return (
    <LogoutLayout title="Reset password">
      <div className="login_page">
        <form onSubmit={reset}>
          <Input
            wrapperClassName="reset_password_field"
            label="Email"
            placeholder="Enter your email"
            value={email}
            onChangeText={changeEmail}
            error={error}
            size="small"
            roundBorder
          />

          <Button
            roundBorder
            size="small"
            type="submit"
            loading={loading}
          >
            Reset password
          </Button>
        </form>

        <div className="login_page_login_btn_wrapper">
          Found your password?

          <Button
            roundBorder
            color="#1472FF"
            href="/login"
            btnType="transparent"
            size="small"
          >
            Log in
          </Button>
        </div>
      </div>
    </LogoutLayout>
  );
};

export default ResetPassword;
