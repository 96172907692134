import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  NumberParam, StringParam, useQueryParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useParams } from 'react-router-dom';
import Table from '../../_common/Tables/Table';
import Loader from '../../_common/Loader/Loader';
import Input from '../../_common/Form/Input';
import Wrapper from '../../Layout/Wrapper';
import Api from '../../../Api';
import UpdateModal from '../../_common/Modal/UpdateModal';
import Pagination from '../../_common/Pagination/Pagination';
import { ReactComponent as BinLocationsNotFoundIcon } from '../../../assets/icons/no_match.svg';
import Button from '../../_common/Form/Button';

import TitleBar from '../../Layout/TitleBar';

const modalFields = [
  {
    title: 'Title',
    path: 'title',
    required: true,
  },
];

const binLocationDefaultData = {
  title: '',
};

const WarehouseBinLocations = () => {
  const { warehouseId } = useParams();

  const [page, setPage] = useQueryParam(
    'page',
    withDefault(NumberParam, 1),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [s, setSearch] = useQueryParam(
    's',
    withDefault(StringParam, ''),
    { updateType: 'replaceIn', removeDefaultsFromUrl: true },
  );

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const { sort, sortBy } = sorting;

  const firstLoad = useRef(false);
  const timeout = useRef(false);

  const [list, setBinLocations] = useState({
    binLocations: [],
    totalPages: 1,
  });

  const { binLocations, totalPages } = list;

  const [loading, loadingToggle] = useState(true);
  const [deleteLoading, deleteLoadingToggle] = useState(false);

  const [modalData, setModalData] = useState({});

  const getBinLocationsRequest = useCallback(async (params) => {
    const { data } = await Api.getWarehouseBinLocations(params);

    setBinLocations(data);
  }, []);

  const getBinLocations = useCallback(() => {
    loadingToggle(true);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      await getBinLocationsRequest({
        warehouseId, page, ...sorting, s,
      });

      loadingToggle(false);
      firstLoad.current = true;
    }, 500);
  }, [page, sorting, s]);

  useEffect(() => {
    getBinLocations();
  }, [sorting, s]);

  const createOrEditBinLocation = useCallback((id = null) => {
    setModalData(() => {
      if (id) {
        return binLocations.find((l) => l.id === id);
      }

      return binLocationDefaultData;
    });
  }, [binLocations]);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);
  }, []);

  const searchBinLocation = useCallback((value) => {
    setSearch(value);

    setPage(1);
  }, []);

  const deleteBinLocation = useCallback(async (id) => {
    deleteLoadingToggle(true);
    await Api.deleteWarehouseBinLocation({ warehouseId, id });

    if (+totalPages > 1 && binLocations.length === 1) {
      await changePage(page - 1);
    } else {
      await getBinLocationsRequest({
        warehouseId, page, ...sorting, s,
      });
    }

    deleteLoadingToggle(false);
    toast.success('Bin location successfully deleted');
  }, [page, totalPages, sorting, s, binLocations]);

  const saveBinLocation = useCallback(async (binLocation) => {
    try {
      if (binLocation.id) {
        await Api.updateWarehouseBinLocation({ ...binLocation });
      } else {
        await Api.createWarehouseBinLocation({ warehouseId, ...binLocation });
      }

      await getBinLocationsRequest({
        warehouseId, page, ...sorting, s,
      });

      toast.success(`Warehouse successfully ${binLocation.id ? 'updated' : 'created'}`);
      closeModal();
    } catch (err) {
      throw err;
    }
  }, [page, sorting, s]);

  const changePage = useCallback(async (currentPage) => {
    clearTimeout(timeout.current);
    loadingToggle(true);

    await getBinLocationsRequest({
      warehouseId, page: currentPage, ...sorting, s,
    });

    setPage(currentPage);
    loadingToggle(false);
  }, [sorting, s]);

  const closeModal = useCallback(() => {
    setModalData({});
  }, []);

  const tableHeader = useMemo(() => [
    {
      title: 'Title',
      path: 'title',
      navigate: (id) => createOrEditBinLocation(id),
      isSelectable: true,
    },
    {
      title: 'Created at',
      path: 'createdAt',
      isSelectable: true,
    },
  ], [createOrEditBinLocation]);

  return (
    <Wrapper hideBackBtn>
      <TitleBar title="Bin locations">
        <Button
          data-scope="WRITE_WAREHOUSES_BIN_LOCATIONS"
          title="New Location"
          addBtn
          onClick={() => createOrEditBinLocation()}
        />
      </TitleBar>
      {!firstLoad.current && loading
        ? <Loader />

        : (
          <>
            <Input
              placeholder="Search title"
              roundBorder
              size="small"
              search
              value={s}
              onChangeText={searchBinLocation}
              wrapperClassName="company_search_field"
            />

            <div className="list_wrapper">
              <Table
                data={binLocations}
                header={tableHeader}
                sortBy={sortBy}
                sort={sort}
                onSortBy={onSortBy}
                loading={loading}
                deleteLoading={deleteLoading}
                onEditClick={createOrEditBinLocation}
                deleteModalText="bin location"
                onDeleteClick={deleteBinLocation}
                editScope="WRITE_WAREHOUSES_BIN_LOCATIONS"
                deleteScope="DELETE_WAREHOUSES_BIN_LOCATIONS"
                notFound={(
                  <>
                    <BinLocationsNotFoundIcon />

                    <h1>Bin locations not found</h1>
                    <Button
                      title="Create location"
                      onClick={() => createOrEditBinLocation()}
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                      data-scope="WRITE_WAREHOUSES_BIN_LOCATIONS"
                    />
                  </>
                )}
              />
            </div>

            <Pagination
              page={page}
              totalPages={totalPages}
              onChangePage={changePage}
            />
          </>
        )}

      <UpdateModal
        isOpen={!_.isEmpty(modalData)}
        fields={modalFields}
        onClose={closeModal}
        onSave={saveBinLocation}
        singleData={modalData}
        text="bin location"
      />
    </Wrapper>
  );
};

export default WarehouseBinLocations;
