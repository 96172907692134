import React, { useCallback, useEffect } from 'react';
import { currencies } from 'currency-formatter';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerFormData } from '../../../../store/actions/customers';
import Select from '../../../_common/Form/Select';

function Details() {
  const dispatch = useDispatch();

  const customerFormData = useSelector((state) => state.customers.customerFormData);
  const {
    defaultPriceListId, defaultPaymentMethodId, defaultWarehouseId, defaultPaymentTermId,
    defaultTaxId, defaultCurrencyCode,
  } = customerFormData;

  const {
    paymentMethods, warehouses, paymentTerms, currencyCode,
  } = useSelector((state) => state.settings.generalList);
  const taxTypes = useSelector((state) => state.settings.taxTypes.taxes);
  const priceLists = useSelector((state) => state.priceLists.priceLists);
  const errors = useSelector((state) => state.customers.errors);

  useEffect(() => {
    if (!defaultPriceListId) {
      const def = priceLists.find((p) => p.isDefault);
      handleChange('defaultPriceListId', def?.id);
    }
  }, [priceLists, defaultPriceListId]);

  useEffect(() => {
    if (customerFormData.isCustomer) {
      handleChange('defaultPriceListId', priceLists.filter((p) => p.type === 'sale').find((p) => p.isDefault)?.id);
    }
    if (customerFormData.isVendor) {
      handleChange('defaultPriceListId', priceLists.filter((p) => p.type === 'buy').find((p) => p.isDefault)?.id);
    }
  }, [priceLists, customerFormData.isCustomer, customerFormData.isVendor]);

  useEffect(() => {
    if (!defaultPaymentMethodId) {
      const def = paymentMethods?.find((p) => p.isDefault);
      handleChange('defaultPaymentMethodId', def?.id);
    }
  }, [paymentMethods, defaultPaymentMethodId]);

  useEffect(() => {
    if (!defaultWarehouseId) {
      const def = warehouses.find((p) => p.isDefault);
      handleChange('defaultWarehouseId', def?.id);
    }
  }, [warehouses, defaultWarehouseId]);

  useEffect(() => {
    if (!defaultPaymentTermId) {
      const def = paymentTerms.find((p) => p.isDefault);
      handleChange('defaultPaymentTermId', def?.id);
    }
  }, [paymentTerms, defaultPaymentTermId]);

  useEffect(() => {
    if (!defaultTaxId) {
      const def = taxTypes.find((p) => p.isDefault);
      handleChange('defaultTaxId', def?.id);
    }
  }, [taxTypes, defaultTaxId]);

  useEffect(() => {
    if (!defaultCurrencyCode) {
      handleChange('defaultCurrencyCode', currencyCode);
    }
  }, [currencyCode, defaultCurrencyCode]);

  const handleChange = useCallback((key, value) => {
    dispatch(setCustomerFormData({ key, value }));
  }, []);

  return (
    <div className="details">
      <div className="row">
        <Select
          options={currencies}
          roundBorder
          label="Currency"
          placeholder="Select"
          valuePath="code"
          labelPath="code"
          onChange={(val) => handleChange('defaultCurrencyCode', val)}
          value={_.get(customerFormData, 'defaultCurrencyCode', '')}
          isSearchable
          error={errors.defaultCurrencyCode}
        />
        <Select
          roundBorder
          label="Payment method"
          placeholder="Select"
          options={paymentMethods}
          valuePath="id"
          labelPath="title"
          onChange={(val) => handleChange('defaultPaymentMethodId', val)}
          value={_.get(customerFormData, 'defaultPaymentMethodId', '')}
          error={errors.defaultPaymentMethodId}
        />
        <Select
          roundBorder
          label="Payment terms"
          placeholder="Select"
          options={paymentTerms}
          valuePath="id"
          labelPath="title"
          onChange={(val) => handleChange('defaultPaymentTermId', val)}
          value={_.get(customerFormData, 'defaultPaymentTermId', '')}
          error={errors.defaultPaymentTermId}
        />
      </div>
      <div className="row">
        <Select
          roundBorder
          label="Price list"
          placeholder="Select"
          options={priceLists}
          valuePath="id"
          labelPath="name"
          onChange={(val) => handleChange('defaultPriceListId', val)}
          value={_.get(customerFormData, 'defaultPriceListId', '')}
          error={errors.defaultPriceListId}
        />
        <Select
          roundBorder
          label="Warehouse"
          placeholder="Select"
          options={warehouses}
          valuePath="id"
          labelPath="title"
          onChange={(val) => handleChange('defaultWarehouseId', val)}
          value={_.get(customerFormData, 'defaultWarehouseId', '')}
          error={errors.defaultWarehouseId}
        />
        <Select
          roundBorder
          label="Tax types"
          placeholder="Select"
          options={taxTypes}
          valuePath="id"
          labelPath="title"
          onChange={(val) => handleChange('defaultTaxId', val)}
          value={_.get(customerFormData, 'defaultTaxId', '')}
          error={errors.defaultTaxId}
        />
      </div>
    </div>
  );
}

export default Details;
