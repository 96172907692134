import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useDispatch } from 'react-redux';
import { ReactComponent as CancelIcon } from '../../../../assets/icons/cancel.svg';
import Button from '../../../_common/Form/Button';
import Api from '../../../../Api';
import Utils from '../../../../helpers/Utils';
import { getOrdersRequest } from '../../../../store/actions/orders';

const DelimitedArrayParamComma = Utils.customDelimitedArrayParam('~');
const defaultFiltersParams = withDefault(DelimitedArrayParamComma, []);

const RemoveTags = ({ closeModal, orders }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const [query] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    shopId: defaultFiltersParams,
    status: defaultFiltersParams,
    warehouseId: defaultFiltersParams,
    ids: defaultFiltersParams,
    orderStatus: withDefault(StringParam, ''),
    _status: withDefault(StringParam, ''),
    assignedUserId: defaultFiltersParams,
    tagId: defaultFiltersParams,
    customerId: defaultFiltersParams,
    isArchive: withDefault(StringParam, ''),
  });

  useMemo(() => {
    setTags(orders.map((o) => ({ orderId: o.id, tags: o.tags })));
  }, [orders]);

  const removeTags = (tag) => {
    setTags((prev) => prev.map((p) => ({
      ...p,
      tags: p.tags.filter((t) => t.id !== tag.id),
    })));
  };

  const saveClick = async () => {
    setLoading(true);

    const { orderStatus, _status, ...q } = Utils.arrayToString(query);

    await Promise.all(
      tags.map((o) => Api.changeOrdersTags({
        orderId: o.orderId,
        tagIds: o.tags.map((t) => t.id),
      })),
    );

    await dispatch(getOrdersRequest({
      status: orderStatus || _status || undefined,
      ...q,
      includes: 'customer,tags,orderProducts',
      isArchive: false,
      type: 'sale',
    }));

    setLoading(false);
    closeModal();
  };

  return (
    <div className="bulk_add_tags_wrapper">
      <h1>Remove tags</h1>

      <div className="bulk_remove_tags_wrapper">
        {_.uniqBy(tags.map((t) => t.tags).flat(1), 'id').map((o, index) => (
          <div className="bulk_remove_tag_wrapper" key={o.id}>
            <span style={{ background: o.color }} />

            <p>{o.name}</p>

            <div
              role="button"
              tabIndex="0"
              onClick={() => removeTags(o, index)}
            >
              <CancelIcon />
            </div>
          </div>
        ))}
      </div>

      <div className="bulk_order_buttons_wrapper">
        <Button
          className="bulk_order_button"
          size="small"
          title="Cancel"
          onClick={closeModal}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="bulk_order_button"
          size="small"
          title="Save"
          onClick={saveClick}
          roundBorder
          color="#1472FF"
          loading={loading}
        />
      </div>

    </div>
  );
};

export default RemoveTags;
