import React, {
  useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
  StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/_common/Loader/Loader';
import Wrapper from '../components/Layout/Wrapper';
import Api from '../Api';
import Input from '../components/_common/Form/Input';
import Table from '../components/_common/Tables/Table';
import Button from '../components/_common/Form/Button';
import Select from '../components/_common/Form/Select';
import { ReactComponent as ProductsIcon } from '../assets/icons/products.svg';
import { ReactComponent as BinLocationIcon } from '../assets/icons/bin_location.svg';
import { ReactComponent as MarkDefaultIcon } from '../assets/icons/success_round.svg';
import { ReactComponent as WarehousesNotFoundIcon } from '../assets/icons/no_match.svg';
import TitleBar from '../components/Layout/TitleBar';


const options = [
  {
    label: (
      <div data-scope="READ_PRODUCTS" className="warehouse_menu_option">
        <ProductsIcon />
        Warehouse products
      </div>
    ),
    value: 'products',
  },
  {
    label: (
      <div data-scope="READ_WAREHOUSES_BIN_LOCATIONS" className="warehouse_menu_option">
        <BinLocationIcon />
        Bin locations
      </div>
    ),
    value: 'bin-locations',
  },
  {
    label: (
      <div data-scope="WRITE_WAREHOUSES" className="warehouse_menu_option">
        <MarkDefaultIcon />
        Mark as default
      </div>
    ),
    value: 'markAsDefault',
  },
];

const formattingList = (list) => list.map((l) => {
  const country = l.country?.name;
  const region = l.region?.name;

  return { ...l, fullAddress: _.compact([l.address, l.city, region, l.postalCode, country]).join(', ') };
});

const sortingList = (list, { sortBy, sort }) => _.orderBy(list, (c) => c[sortBy].toLowerCase(), sort);

const Warehouses = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useQueryParams({
    countryCode: withDefault(StringParam, ''),
    s: withDefault(StringParam, ''),
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const [sorting, setSorting] = useQueryParams({
    sortBy: withDefault(StringParam, 'createdAt'),
    sort: withDefault(StringParam, 'desc'),
  }, { updateType: 'replaceIn' });

  const { s: search, countryCode } = filters;
  const { sort, sortBy } = sorting;

  const firstLoad = useRef(false);
  const timeout = useRef();

  const [warehouses, setWarehouses] = useState([]);

  const [filterOptions, setFilterOptions] = useState([]);

  const [loading, loadingToggle] = useState(true);
  const [deleteLoading, deleteLoadingToggle] = useState(false);

  const getFilterWarehouses = useCallback(async () => {
    const { data } = await Api.getWarehouses({});

    setFilterOptions([
      { label: 'All warehouses', value: '' },
      ..._.uniqBy(_.sortBy(data.warehouses.map((w) => ({ label: w.country?.name, value: w.countryCode })), 'label')
        .filter((w) => w.label), 'label')]);
  }, []);

  useLayoutEffect(() => {
    getFilterWarehouses();
  }, []);

  useEffect(() => {
    loadingToggle(true);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      const { data } = await Api.getWarehouses(filters);

      setWarehouses(sortingList(formattingList(data.warehouses), sorting));

      loadingToggle(false);
      firstLoad.current = true;
    }, 500);
  }, [filters]);

  const changeFilters = useCallback((key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  }, []);

  const onSortBy = useCallback((newSorting) => {
    setSorting(newSorting);

    setWarehouses((prev) => sortingList(prev, newSorting));
  }, []);

  const deleteWarehouse = useCallback(async (id) => {
    deleteLoadingToggle(true);

    try {
      await Api.deleteWarehouse(id);
      await getFilterWarehouses();

      setWarehouses((prev) => prev.filter((l) => l.id !== id));

      toast.success('Companies successfully deleted');
    } catch (e) {
      toast.error(e.response.data?.message || 'Something went wrong');
    }

    deleteLoadingToggle(false);
  }, []);

  const menuSelect = useCallback(async (id, option) => {
    if (option === 'markAsDefault') {
      setWarehouses((prev) => prev.map((p) => ({ ...p, isDefault: p.id === id })));
      toast.success('Warehouse successfully marked as default');
      Api.setDefaultWarehouse(id);
    } else {
      navigate(`/stocks/warehouses/${id}/${option}`);
    }
  }, []);

  const tableHeader = useMemo(() => [
    {
      title: 'Title',
      path: 'title',
      navigate: (id) => navigate(`/stocks/warehouses/${id}`),
      isSelectable: true,
    },
    {
      title: 'Address',
      path: 'fullAddress',
    },
    {
      title: 'Products',
      path: 'products',
    },
    {
      title: 'Available summary',
      path: 'availableSummary',
    },
    {
      title: 'Created at',
      path: 'createdAt',
      isSelectable: true,
    },
  ], []);

  return (
    <Wrapper hideBackBtn>
      <TitleBar title="Warehouses">
        <Button
          data-scope="WRITE_WAREHOUSES"
          title="New warehouse"
          addBtn
          href="/stocks/warehouses/add"
        />
      </TitleBar>
      {!firstLoad.current && loading
        ? <Loader />

        : (
          <>
            <div className="warehouse_search_field_wrapper">
              <Input
                placeholder="Search name or address"
                roundBorder
                size="small"
                search
                value={search || ''}
                onChangeText={(value) => changeFilters('s', value)}
                wrapperClassName="warehouse_search_field"
              />

              <div className="warehouse_filters_wrapper">
                <Select
                  options={filterOptions}
                  value={countryCode}
                  onChange={(option) => changeFilters('countryCode', option)}
                  roundBorder
                  size="small"
                  lightBlue
                />

                {/* <Button */}
                {/*   btnType="import" */}
                {/*   size="small" */}
                {/*   roundBorder */}
                {/*   reverseColor */}
                {/* > */}
                {/*   Import */}
                {/* </Button> */}
              </div>
            </div>

            <div className="warehouse_list">
              <Table
                data={warehouses}
                header={tableHeader}
                sortBy={sortBy}
                // onCheck={() => {}}
                sort={sort}
                onSortBy={onSortBy}
                loading={loading}
                deleteModalText="warehouse"
                deleteLoading={deleteLoading}
                onEditClick={(id) => navigate(`/stocks/warehouses/${id}`)}
                onDeleteClick={deleteWarehouse}
                onMenuSelect={menuSelect}
                menuOptions={options}
                editScope="WRITE_WAREHOUSES"
                deleteScope="DELETE_WAREHOUSES"
                notFound={(
                  <>
                    <WarehousesNotFoundIcon />

                    <h1>Warehouses not found</h1>

                    <Button
                      title="Create warehouse"
                      href="/stocks/warehouses/add"
                      addBtn
                      reverseColor
                      size="small"
                      roundBorder
                    />
                  </>
                )}
              />
            </div>
          </>
        )}
    </Wrapper>
  );
};

Warehouses.propTypes = {};

export default Warehouses;
