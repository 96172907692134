import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../../Layout/Wrapper';
import Button from '../../../_common/Form/Button';
import PlasticCard from './PlasticCard';
import ModalAddPayment from './ModalAddPayment';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import DeleteModal from '../../../_common/Modal/DeleteModal';

function ChangePaymentMethod() {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [deletingId, setDeletingId] = useState('');
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const onDeleteCard = async () => {
    setDeleteLoading(true);

    try {
      await Api.deletePaymentMethod(deletingId);
      const card = await Api.getPaymentMethods();

      setCards(card.data.paymentMethods);
      setDeletingId('');
    } catch (e) {
      toast.error(e.response.data.message);
    }

    setDeleteLoading(false);
  };

  const getPaymentMethods = async () => {
    setLoading(true);
    const { data } = await Api.getPaymentMethods();

    setCards(data.paymentMethods);
    setLoading(false);
  };

  const setDefaultPayment = async () => {
    setConfirmLoading(true);

    const { id } = cards.find((c) => c.isDefault);

    await Api.setDefaultPaymentMethod(id);

    toast.success('Successfully changed');
    setConfirmLoading(false);
  };

  const closeModal = useCallback(() => {
    setAddPaymentModal(false);
  }, [addPaymentModal]);

  return (
    <Wrapper
      title="Payment methods"
      buttonTitle="Confirm changes"
      onBtnClick={setDefaultPayment}
      className="change_payment_method_wrapper"
      onCancelBtnClick={() => navigate(-1)}
      btnLoading={confirmLoading}
    >
      {loading ? <Loader /> : (
        <>
          <div className="payment_methods_change_wrapper">
            <Button
              reverseColor
              addBtn
              size="small"
              roundBorder
              color="#1472FF"
              btnType="transparent"
              className="payment_methods_card_add_btn"
              onClick={() => setAddPaymentModal(true)}
            >
              Add new card
            </Button>

            <div className="payment_methods_card_block">
              {cards.map((c) => <PlasticCard data={c} setDeletingId={setDeletingId} key={c.id} setCards={setCards} />)}
            </div>
          </div>

          <ModalAddPayment
            isOpen={addPaymentModal}
            onClose={closeModal}
            onSave={getPaymentMethods}
          />

          <DeleteModal
            isOpen={!!deletingId}
            onDelete={onDeleteCard}
            onClose={() => setDeletingId('')}
            loading={deleteLoading}
            text="payment method"
          />
        </>
      )}
    </Wrapper>
  );
}

export default ChangePaymentMethod;
