import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Select from '../../../_common/Form/Select';
import Api from '../../../../Api';

const SMTPOptions = [
  { label: 'TLS', value: 'TLS' },
  { label: 'STARTTLS', value: 'STARTTLS' },
];

function SMTPConfigs() {
  const [SMTPData, setSMTPData] = useState({ settings: {} });

  const { integrationId } = useParams();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    (async () => {
      const { data: { integrations } } = await Api.getIntegrations({ type: 'messaging' });
      const smtpData = integrations.find((i) => i.settings.type === 'smtp');
      setSMTPData({
        settings: {
          host: smtpData.settings.host,
          email: smtpData.settings.email,
          port: smtpData.settings.port,
          type: smtpData.settings.type,
          password: smtpData.settings.password,
          from: smtpData.settings.from,
          encryption: smtpData.settings.encryption,
        },
        warehouseMapping: smtpData.warehouseMapping,
        orderStatusMapping: smtpData.orderStatusMapping,
        priceListsMapping: smtpData.priceListsMapping,
        name: smtpData.name,
        isEnabled: smtpData.isEnabled,
        currencyCode: smtpData.currencyCode,
        orderImportStartDate: smtpData.orderImportStartDate,
      });
    })();
  }, []);

  const changeSMTP = useCallback((path, value) => {
    setSMTPData((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        [path]: value,
      },
    }));

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, [SMTPData]);

  const testConnection = useCallback(async () => {
    try {
      const { status } = await Api.testSMTPConnection(SMTPData.settings);
      if (status === 200) {
        toast.success('Test connection successfully');
      }
    } catch (err) {
      if (err.response.data?.errors) {
        setErrors(err?.response?.data?.errors);
        toast.error(err.response.data.message);
      }
    }
  }, [SMTPData]);

  const saveSmtp = useCallback(async () => {
    try {
      const data = await Api.updateIntegration({ id: integrationId, ...SMTPData });
      if (data.status === 200) {
        toast.success('Configs  successfully updated');
      }
    } catch (err) {
      if (err.response.data?.errors) {
        setErrors(err?.response?.data?.errors.settings);
        toast.error(err.response.data.message);
      }
    }
  }, [SMTPData]);

  return (
    <div className="smtp__configs">
      <div className="smtp__configs__container">
        <div className="smtp__configs__top">

          <div className="smtp__configs__buttons">
            <Button
              title="Save"
              size="small"
              roundBorder
              onClick={saveSmtp}
            />
          </div>
        </div>

        <div className="smtp__configs_form">
          <div className="smtp__configs_form_item">
            <p>Host</p>

            <Input
              placeholder="Host"
              value={SMTPData.settings.host}
              onChangeText={(value) => changeSMTP('host', value)}
              error={errors.host}
              size="small"
              roundBorder
            />
          </div>

          <div className="smtp__configs_form_item">
            <p>Email</p>

            <Input
              placeholder="Email"
              error={errors.email}
              value={SMTPData.settings.email}
              onChangeText={(value) => changeSMTP('email', value)}
              size="small"
              roundBorder
            />
          </div>

          <div className="smtp__configs_form_item">
            <p>Password</p>

            <Input
              placeholder="Password"
              value={SMTPData.settings.password}
              onChangeText={(value) => changeSMTP('password', value)}
              error={errors.password}
              size="small"
              roundBorder
            />
          </div>

          <div className="smtp__configs_form_item">
            <p>Port</p>

            <Input
              value={SMTPData.settings.port}
              onChangeText={(value) => changeSMTP('port', value)}
              placeholder="Port"
              error={errors.port}
              size="small"
              roundBorder
            />
          </div>

          <div className="smtp__configs_form_item">
            <p>From</p>

            <Input
              value={SMTPData.settings.from}
              onChangeText={(value) => changeSMTP('from', value)}
              placeholder="From"
              error={errors.from}
              size="small"
              roundBorder
            />
          </div>

          <div className="smtp__configs_form_item">
            <p>Encryption</p>

            <Select
              placeholder="Encryption"
              value={SMTPData.settings.encryption}
              onChange={(value) => changeSMTP('encryption', value)}
              options={SMTPOptions}
              valuePath="value"
              labelPath="label"
              roundBorder
              size="small"
            />
          </div>

          <div className="smtp__configs_form_item">
            <p />

            <div className="input_wrapper">
              <Button
                className="test__connection"
                reverseColor
                title="Test connection"
                size="small"
                roundBorder
                onClick={testConnection}
              />
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default SMTPConfigs;
