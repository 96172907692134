import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment/moment';
import {
  getCountriesRequest, getRegionsRequest, getMyApp, changeAppStatus, getPrintersListRequest, changePrinterStatus,
} from '../actions/app';

const initialState = {
  countries: [],
  regions: {},
  myApp: {},
  printers: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCountriesRequest.fulfilled, (state, action) => {
      state.countries = action.payload.data.countries;
    })
    .addCase(getMyApp.fulfilled, (state, { payload }) => {
      if (payload.integration.type === 'ftp') {
        payload.integration.settings.type = `ftp ${payload.integration.settings.type}`;
      }

      if (payload.integration.settings?.refreshTokenExpireDate) {
        const today = moment();
        const tokenExpireDate = moment(payload.integration.settings.refreshTokenExpireDate);

        payload.integration.isExpired = tokenExpireDate.diff(today, 'days') <= 0;
        payload.integration.isEnabled = payload.integration.isExpired ? false : payload.integration.isEnabled;
      }

      state.myApp = payload.integration;
    })
    .addCase(changeAppStatus.pending, (state, { meta: { arg } }) => {
      const { isEnabled } = arg;

      state.myApp = { ...state.myApp, isEnabled };
    })
    .addCase(getRegionsRequest.fulfilled, (state, action) => {
      const { countryData: { country, region }, countryData, data } = action.payload;
      let regData = data.regions.map((r) => ({
        ...r,
        label: r.name,
        value: r.name,
      }));
      if (region && !regData.some((r) => r.value === country)) {
        regData = [...regData, { label: region, value: region }];
      }
      const d = {};
      if (country) {
        d[country] = regData;
      } else {
        d[countryData] = regData;
      }

      state.regions = {
        ...state.regions,
        ...d,
      };
    })
    .addCase(getPrintersListRequest.fulfilled, (state, action) => {
      state.printers = action.payload.printers;
    })
    .addCase(changePrinterStatus, (state, action) => {
      state.printers = state.printers.map((p) => {
        if (p.deviceId === action.payload.deviceId) {
          p.isOnline = action.payload.isOnline;
        }
        return p;
      });
    });
});

export default reducer;
