import React from 'react';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import Filters from '../../Products/Filters';
import Api from '../../../../Api';

const defaultFilterData = [
  { label: 'Order', valuePath: 'orderId' },
];

const filterOptions = {
  orderId: {
    label: 'Orders',
    path: 'orders',
    valuePath: 'orderId',
  },
};

const defaultFiltersParams = withDefault(ArrayParam, []);

function ReceivesFilters() {
  const [queryData, setQuery] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    orderId: defaultFiltersParams,
    status: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const loadOptions = async (filter) => {
    if (filter.staticData) {
      return { data: filter.staticData };
    }

    let data;
    let totalPages;

    if (filter.path === 'orders') {
      const { data: { orders: list, totalPages: p } } = await Api.getOrders({ type: 'purchase' });
      totalPages = p;

      data = list.map((order) => ({ id: order.id, label: order.number }));
    }
    return { data, totalPages };
  };

  return (
    <Filters
      queryData={queryData}
      setQuery={setQuery}
      tableEditable={false}
      defaultFilterData={defaultFilterData}
      onPrintClick={() => {
      }}
      filterOptions={filterOptions}
      loadOptions={loadOptions}
      onEditableTableClick={undefined}
    />
  );
}

export default ReceivesFilters;
