import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Switcher from '../../../_common/Form/Switcher';
import Button from '../../../_common/Form/Button';
import Modal from '../../../_common/Modal/Modal';
import { createPaymentTermRequest } from '../../../../store/actions/settings';

const from = [{ value: 'invoice_date', label: 'Invoice date' }, { value: 'end_of_month', label: 'End of month' }];

function PaymentsTermForm(props) {
  const { isOpen, onClose, onSave } = props;
  const [form, setForm] = useState({
    isDefault: false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setErrors({ ...errors });
    setForm({ ...form });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    const { payload } = await dispatch(createPaymentTermRequest(form));
    if (payload.status === 'error') {
      setErrors({ ...payload.errors.paymentMethods });
    } else {
      onSave(payload.paymentTerms);
      onClose();
    }
    setLoading(false);
  }, [form]);
  return (
    <Modal
      wrapperClassName="update_modal_amazon_app"
      className="general_settings_update_modal_wrapper"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} className="fields_block">
        <h3 className="fields_block_title">New payment term</h3>
        <div className="update_modal_single_input">

          <Input
            value={form.title}
            onChange={(ev) => handleChange('title', ev.target.value)}
            label="Title"
            placeholder="Title"
            error={errors?.title}
            wrapperClassName="update_modal_input_wrp"
            roundBorder
            size="small"
          />
        </div>
        <div className="update_modal_single_input">

          <Select
            onChange={(d) => handleChange('startFrom', d)}
            value={form.startFrom}
            label="From"
            placeholder="From"
            options={from}
            error={errors?.startFrom}
            wrapperClassName="update_modal_input_wrp"
            roundBorder
            size="small"
          />
        </div>
        <div className="update_modal_single_input">

          <Input
            value={form.dueInDays}
            onChange={(ev) => handleChange('dueInDays', ev.target.value)}
            label="Due in days"
            placeholder="Due in days"
            error={errors?.dueInDays}
            wrapperClassName="update_modal_input_wrp"
            roundBorder
            size="small"
            type="number"
          />
        </div>
        <Switcher
          onChange={(checked) => handleChange('isDefault', checked)}
          label="Set as default"
          className="update_modal_swithcher"
          checked={form.isDefault}
        />

        <div className="update_modal_buttons_wrp">
          <Button
            className="update_modal_cancel_button"
            roundBorder
            size="small"
            btnType="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            className="update_modal_save_button"
            roundBorder
            size="small"
            type="submit"
            loading={loading}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default PaymentsTermForm;
