import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  bulkEditProductRequest, bulkGenerateBarcodeRequest,
  changeProductTableAttribute, changeProductTableAttributeRequest, clearProductsList,
  getPriseListAndAddTableAttribute,
  getProductsRequest,
  getProductsTableAttributes, productTableEditableEvent,
  saveDraftReorderProduct,
} from '../actions/products';

const initialState = {
  productList: [],
  productTotalPage: 1,
  columns: [
    {
      key: 'item', isEnabled: true,
    },
    {
      key: 'sku', isEnabled: true,
    },
    {
      key: 'shopType', isEnabled: true,
    },
    {
      key: 'category', isEnabled: true,
    },
    {
      key: 'totalStock', isEnabled: true,
    },
    {
      key: 'available', isEnabled: true,
    },
    {
      key: 'createdAt', isEnabled: true,
    },
  ],
  defaultAttributes: {
    item: 'Item',
    sku: 'SKU',
    shopType: 'Shop',
    category: 'Category',
    totalStock: 'Stock',
    available: 'Available',
    createdAt: 'Import date',
  },
  priseList: [],
  tableEditable: false,
  priceListsEnabledColumns: [],
  priceListsEnabledDefaultAttributes: {},
  bulkPricingDefaultAttributes: {
    item: 'Item',
    sku: 'SKU',
  },
  bulkPricingColumns: [{
    key: 'item', isEnabled: true,
  },
  {
    key: 'sku', isEnabled: true,
  }],
  withNegativeStock: [],
  draftSavedReorder: {},
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProductsRequest.fulfilled, (state, { payload }) => {
      const { data, calcStock } = payload;

      state.productList = data.products;
      state.productTotalPage = data.totalPages;
      if (calcStock) {
        state.withNegativeStock = data.products.filter((p) => p.totalStock?.available + p.totalStock?.incoming < 0);
      }
    })
    .addCase(getProductsRequest.pending, (state) => {
      state.withNegativeStock = [];
    })
    .addCase(clearProductsList, (state) => {
      state.productList = [];

      state.productTotalPage = 1;
    })
    .addCase(getProductsTableAttributes.fulfilled, (state, { payload }) => {
      state.columns = payload.productsTableAttributes?.length ? payload.productsTableAttributes : state.columns;

      state.priceListsEnabledColumns = (payload.productsTableAttributes || [])
        .filter((p) => p.isEnabled && p.key.includes('priceList_'));
    })

    .addCase(changeProductTableAttributeRequest.fulfilled, (state, { payload }) => {
      state.priceListsEnabledColumns = (payload.productsTableAttributes || [])
        .filter((p) => p.isEnabled && p.key.includes('priceList_'));
    })

    .addCase(getPriseListAndAddTableAttribute.fulfilled, (state, { payload }) => {
      const newColumns = payload.map((p) => ({
        key: `priceList_${p.id}`,
        isEnabled: false,
      }));

      state.priseList = payload;

      state.columns = _.uniqBy([..._.differenceBy(state.columns, newColumns, 'key')
        .filter((d) => !d.key.includes('priceList_')),
      ...newColumns], 'key');

      state.bulkPricingColumns = [...state.bulkPricingColumns,
        ...newColumns.map((n) => ({ ...n, isEnabled: true }))];

      state.bulkPricingDefaultAttributes = _.defaults(
        state.bulkPricingDefaultAttributes,
        ...payload.map((p) => ({ [`priceList_${p.id}`]: p.name })),
      );

      state.defaultAttributes = _.defaults(
        state.defaultAttributes,
        ...payload.map((p) => ({ [`priceList_${p.id}`]: p.name })),
      );
    })

    .addCase(productTableEditableEvent, (state, { payload }) => {
      state.tableEditable = payload;
    })

    .addCase(bulkEditProductRequest.fulfilled, (state, action) => {
      state.productList = state.productList.map((p) => {
        const updateProduct = action.meta.arg.find((a) => a.id === p.id);

        if (updateProduct) {
          return { ...p, ...updateProduct };
        }
        return p;
      });
    })

    .addCase(bulkGenerateBarcodeRequest.fulfilled, (state, { payload }) => {
      state.productList = state.productList.map((p) => {
        const updateProduct = payload.products.find((a) => a.id === p.id);

        if (updateProduct) {
          return { ...p, ...updateProduct };
        }
        return p;
      });
    })

    .addCase(changeProductTableAttribute, (state, { payload }) => {
      state.columns = payload;
    })

    .addCase(saveDraftReorderProduct, (state, { payload }) => {
      console.log(payload, 'PAYLOAD');
      state.draftSavedReorder = payload;
    });
});

export default reducer;
