import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const loginRequest = createAsyncThunk(
  'users/login',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.login(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getAccountRequest = createAsyncThunk(
  'users/profile/get',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getAccount(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getCurrentSubscription = createAsyncThunk(
  'users/current-subscription/get',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getCurrentSubscription(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const loginGoogleOauth = createAsyncThunk(
  'users/login/google/oauth',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.loginGoogleOauth(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getUsersListRequest = createAsyncThunk(
  'users/getUsersListRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getUsersList(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getRolesListRequest = createAsyncThunk(
  'users/getRolesListRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getRolesList(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const createUserRequest = createAsyncThunk(
  'users/createUserRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.crmInviteUser(payload);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const getPermissions = createAsyncThunk(
  'users/getPermissions',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getPermissions();
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
