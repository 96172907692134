import React, { useState } from 'react';
import Tabs from '../../../_common/Tabs/Tabs';
import SalesOverview from './Tabs/SalesOverview';
import Operations from './Tabs/Operations';
import CustomerOverview from './Tabs/CustomerOverview';
import SalesTrends from './Tabs/SalesTrends';
import ProductHighlights from './Tabs/ProductHighlights';

const topChartTemplate = {
  chart: {
    toolbar: {
      show: false,
      // tools: {
      //   download: true,
      //   selection: false,
      //   zoom: false,
      //   zoomin: false,
      //   zoomout: false,
      //   pan: false,
      //   reset: false,
      // },
    },
  },

  colors: ['#109B48', '#FFBD00', '#1472FF', '#ab14ff', '#14f7ff'],

  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '50%',
    },
  },
  dataLabels: {
    enabled: false,
  },

  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },

  xaxis: {
    type: 'datetime',
    categories: [],
    axisBorder: {
      show: false,
      // height: 1.5,
      // width: '100%',
      // offsetX: 0,
      // offsetY: -1,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      style: {
        colors: '#717A8A',
        fontSize: '13px',
        fontFamily: 'Proxima Nova, sans-serif',
        fontWeight: 400,
      },

      // formatter(value, timestamp) {
      //   return new Date(timestamp); // The formatter function overrides format property
      // },
    },
  },

  yaxis: {
    labels: {
      offsetY: 2,
      style: {
        colors: '#717A8A',
        fontSize: '14px',
        fontFamily: 'Proxima Nova, sans-serif',
        fontWeight: 600,
      },
    },
  },

  states: {
    normal: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
    hover: {
      filter: {
        type: 'none',
      },
    },
  },

  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'top',
    horizontalAlign: 'right',
    floating: false,
    fontSize: '12px',
    fontFamily: 'DM Sans, sans-serif',
    fontWeight: 400,
    markers: {
      width: 14,
      height: 14,
      radius: 14,
    },
  },

  info: {},
  series: [],
};

const overviewTabs = [
  {
    name: 'Sales overview',
    component: <SalesOverview topChartTemplate={topChartTemplate} />,
  },
  {
    name: 'Operations',
    component: <Operations topChartTemplate={topChartTemplate} />,
  },
  {
    name: 'Sales trends',
    component: <SalesTrends topChartTemplate={topChartTemplate} />,
  },
  {
    name: 'Customer overview',
    component: <CustomerOverview topChartTemplate={topChartTemplate} />,
  },
  {
    name: 'Product highlights',
    component: <ProductHighlights topChartTemplate={topChartTemplate} />,
  },
];

const Overview = () => {
  const [activeTab, setActiveTab] = useState('Sales overview');

  return (
    <div className="transparent_wrapper overview_page">
      <div className="transparent_wrapper_title">
        <h1>Reports</h1>
      </div>

      <Tabs
        tabs={overviewTabs}
        currentTab={activeTab}
        onChangeTab={setActiveTab}
      />
    </div>
  );
};

export default Overview;
