import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import moment from 'moment/moment';
import _ from 'lodash';
import ChartWrapper from '../../../../_common/ChartWrapper/ChartWrapper';
import Api from '../../../../../Api';
import Table from '../../../../_common/Tables/Table';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Button from '../../../../_common/Form/Button';
import { ReactComponent as NotFoundIcon } from '../../../../../assets/icons/no_match.svg';

const salesInfo = [
  {
    title: 'Total products ordered:',
    path: 'totalProductsOrdered',
  },
  {
    title: 'Unique products ordered:',
    path: 'uniqueProductOrdered',
  },
  {
    title: 'Products per order (Avg):',
    path: 'productPerOrder',
  },
];

const salesByCategoryQuantityTableHeader = [
  {
    title: 'Name',
    path: 'categoryName',
  },
  {
    title: 'Qty',
    path: 'qty',
  },
  {
    title: 'Revenue',
    path: 'revenue',
  },
];

const salesByCategoryRevenueTableHeader = [
  {
    title: 'Name',
    path: 'categoryName',
  },
  {
    title: 'Qty',
    path: 'qty',
  },
  {
    title: 'Revenue',
    path: 'revenue',
  },
];

const topProductsByQuantityTableHeader = [
  {
    title: 'Product',
    path: 'productName',
  },
  {
    title: 'Qty',
    path: 'qty',
  },
];

const generateChartData = (items, categoryName) => {
  let chartData = [];

  items.forEach((item) => {
    if (!chartData.length || chartData.at(-1).date !== item.date) {
      chartData = [...chartData, {
        ...item,
        itemCount: item.categoryName === categoryName ? item.itemCount : '0',
      }];
    } else if (chartData.at(-1).date === item.date || item.categoryName === categoryName) {
      chartData = [...chartData.slice(0, chartData.length - 1), item];
    }
  });

  return chartData.map((d) => +d.itemCount);
};

const today = moment().format('YYYY-MM-DD');
const lastTwoWeek = moment(new Date()).subtract(14, 'days').format('YYYY-MM-DD');

const ProductHighlights = ({ topChartTemplate }) => {
  const [date, setDate] = useQueryParams({
    startDate: withDefault(StringParam, lastTwoWeek),
    endDate: withDefault(StringParam, today, true),
  }, { updateType: 'replaceIn' });

  const { startDate, endDate } = date;

  const [salesChart, setSalesChart] = useState(topChartTemplate);
  const [salesByCategoryQuantity, setSalesByCategoryQuantity] = useState([]);
  const [salesByCategoryRevenue, setSalesByCategoryRevenue] = useState([]);
  const [topProductsByQuantity, setTopProductsByQuantity] = useState([]);
  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      loadingToggle(true);

      const [
        { data: { items, productsInfo: info } },
        { data: { orders: salesByCategoryQuantityData } },
        { data: { orders: salesByCategoryRevenueData } },
        { data: { products: topProductsByQuantityData } },
      ] = await Promise.all([
        Api.getReportsSoldItems({ startDate, endDate: endDate || startDate }),
        Api.getReportsTopSalesByCategoryQuantity({ startDate, endDate: endDate || startDate }),
        Api.getReportsTopSalesByCategoryQRevenue({ startDate, endDate: endDate || startDate }),
        Api.getReportsTopProductsByQty({ startDate, endDate: endDate || startDate }),
      ]);

      setSalesByCategoryQuantity(salesByCategoryQuantityData.map((c) => ({
        ...c,
        revenue: `$${c.revenue}`,
      })));

      setSalesByCategoryRevenue(salesByCategoryRevenueData.map((c) => ({
        ...c,
        revenue: `$${c.revenue}`,
      })));

      setTopProductsByQuantity(topProductsByQuantityData);

      const categories = _.uniq(_.compact(items.map((o) => o.categoryName)));
      const series = categories.map((category) => ({
        name: _.upperFirst(category),
        data: generateChartData(items, category),
      }));

      setSalesChart((prev) => ({
        ...prev,
        // legend: {
        //   show: false,
        // },
        xaxis: {
          ...prev.xaxis,
          categories: items.map((o) => o.date),
        },
        series,
        info,
      }));

      loadingToggle(false);
    })();
  }, [date]);

  const changeDate = (d) => {
    setDate({ startDate: d[0], endDate: d[1] || '' });
  };

  return (
    <div className="sales_overview_wrapper operations">
      <div className="sales_overview_date_filter">
        <RangeDatePicker currentDate={date} onChangeDate={changeDate} />
      </div>

      <ChartWrapper height={480} title="Sales trends" loading={loading}>
        <div className="chart_info">
          {salesInfo.map(({ title, path }) => (
            <div key={title}>{`${title} ${salesChart.info[path]}`}</div>
          ))}
        </div>

        <Chart
          height={350}
          width="100%"
          type="bar"
          className="apx-chart"
          series={salesChart.series}
          options={salesChart}
        />
      </ChartWrapper>

      <div className="overview_bottom_block">
        <ChartWrapper height={330} title="Sales by category quantity" loading={loading}>
          <Table
            data={salesByCategoryQuantity}
            header={salesByCategoryQuantityTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Sales not found</h1>
              </>
            )}
          />

          {!!salesByCategoryQuantity.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Sales by category revenue" loading={loading}>
          <Table
            data={salesByCategoryRevenue}
            header={salesByCategoryRevenueTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Sales not found</h1>
              </>
            )}
          />

          {!!salesByCategoryRevenue.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>

        <ChartWrapper height={330} title="Top products by quantity" loading={loading}>
          <Table
            data={topProductsByQuantity}
            header={topProductsByQuantityTableHeader}
            notFound={(
              <>
                <NotFoundIcon className="not_found_icon" />

                <h1>Products not found</h1>
              </>
            )}
          />

          {!!topProductsByQuantity.length && (
            <Button
              color="#1472FF"
              btnType="transparent"
              type="submit"
              className="reports_view_all"
            >
              View all
            </Button>
          )}
        </ChartWrapper>
      </div>
    </div>
  );
};

export default ProductHighlights;
