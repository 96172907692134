import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Modal from '../../../_common/Modal/Modal';
import { format } from "currency-formatter";

const tabs = ['package_details', 'shipment_details'];

function PackageInfoModal(props) {
  const { isOpen, onClose, data } = props;
  const [tab, setTab] = useState('package_details');

  const order = useSelector((state) => state.orders.order);
  console.log(data);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="package_info_modal">
      <p className="title">Package</p>
      <p className="number">{data.number}</p>
      <div className="action_tabs">
        <div className="tabs">
          {tabs.map((t) => (
            <div
              className={classNames('tab', { active: tab === t })}
              onClick={() => setTab(t)}
              key={t}
            >
              {_.startCase(t)}
            </div>
          ))}
        </div>
        {tab === 'package_details' ? (
          <div>
            <div className="package_details">
              <p className="title">Package details</p>
              <div className="item">
                <p className="label">Sales Order#</p>
                <p className="value">{order.number}</p>
              </div>
              <div className="item">
                <p className="label">Order Date</p>
                <p className="value">{moment(order.createdAt).format('DD MMM YYYY')}</p>
              </div>
              <div className="item">
                <p className="label">Package Date</p>
                <p className="value">{moment(data.sourceCreatedAt).format('DD MMM YYYY')}</p>
              </div>
              <div className="item">
                <p className="label">Status</p>
                <p className="value">{_.startCase(data.status)}</p>
              </div>
            </div>
            <div className="item_details">
              <p className="title">Item details</p>
              {data.orderPackageProducts.map((d) => (
                <div className="item">
                  <p className="label">{order.orderProducts.find((p) => p.productId === d.productId)?.title}</p>
                  <p className="value">{`Qty: ${d.qty}`}</p>
                </div>
              ))}
              <div className="item">
                <p className="label">Total Quantity</p>
                <p className="value">{_.sumBy(data.orderPackageProducts, 'qty')}</p>
              </div>
            </div>
          </div>
        ) : null}
        {tab === 'shipment_details' ? (
          <div className="shipment_details">
            {data.status === 'packed' ? <div className="no_info">No shipment information </div> : (
              <div className="package_details">
                <p className="title">Package details</p>
                <div className="item">
                  <p className="label">Shipment Order#</p>
                  <p className="value">{order.number}</p>
                </div>
                <div className="item">
                  <p className="label">Date of Shipment</p>
                  <p className="value">{data.shippedAt ? moment(data.shippedAt).format('DD MMM YYYY') : '-'}</p>
                </div>
                <div className="item">
                  <p className="label">Carrier</p>
                  <p className="value">{data.carrier || '-'}</p>
                </div>
                <div className="item">
                  <p className="label">Tracking#</p>
                  <p className="value">{data.trackingNumber || '-'}</p>
                </div>
                <div className="item">
                  <p className="label">Shipment Rate</p>
                  <p className="value">{format(data.shippingPrice, { code: order.currencyCode })}</p>
                </div>
              </div>
            )}

          </div>
        ) : null}
      </div>
    </Modal>
  );
}

export default PackageInfoModal;
