import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useClickAway } from 'react-use';

function TableDropdown(props) {
  const { target = '.packages' } = props;
  const dropdownRef = useRef();
  useClickAway(dropdownRef, () => {
    props.onClose();
  });

  return (
    ReactDOM.createPortal(<ul className="dropdown" ref={dropdownRef} style={{ top: props.top }}>
      {props.children}
    </ul>, document.querySelector(target))
  );
}

export default TableDropdown;
