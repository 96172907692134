import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../../Layout/Wrapper';
import TabButton from '../../../_common/Tabs/TabButton';
import { ReactComponent as RoundArrowIcon } from '../../../../assets/icons/arrow_round.svg';
import PricingCard from './PricingCard';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';

const tabData = [
  {
    title: 'Monthly',
    value: 'month',
    width: '',
  },
  {
    title: 'Annually',
    value: 'year',
    width: '',
  },
];

const advantages = [
  'Ecommerce Integrations', 'Free Shopify Integration', 'Inventory history', 'Listing tool', 'Marketplace Integration',
  'Multichannel Inventory Control', 'Order Live update', 'Order routing',
];

function PricingPlans() {
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState({});
  const [activeTab, setActiveTab] = useState('month');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [plan, current] = await Promise.all([
        Api.getPlansList({ interval: activeTab }),
        Api.getCurrentSubscription(),
      ]);

      setPlans(plan.data.plans);
      setCurrentPlan(current.data.data);
      setLoading(false);
    })();
  }, [activeTab]);

  const onUpgradeClick = (id) => {
    navigate(`/settings/plans-billings/update-plan/${id}?interval=${activeTab}`);
  };

  return (
    <Wrapper title="Pricing plans">
      <div className="pricing_plans">
        <div className="pricing_plans_top">
          <div className="pricing_plans_tabs">
            <TabButton
              onClick={!loading ? setActiveTab : () => {}}
              buttonsData={tabData}
              activeValue={activeTab}
            />
          </div>

          <div className="pricing_plans_percent">
            <div className="pricing_plans_percent_text">
              Save 25%

              <RoundArrowIcon className="pricing_plans_percent_arrow_icon" />
            </div>
          </div>

          <div className="pricing_plans_balance">
            <span>Your balance:</span>

            {` $${-1 * (currentPlan.balance / 100) || 0}`}
          </div>
        </div>

        {loading ? <Loader className="pricing_plans_loading" /> : (
          <div className="pricing_plans_cards_block">
            {plans.map((p) => (
              <div className="pricing_plans_card_single" key={p.id}>
                <PricingCard
                  title={p.publicName}
                  price={p.prices?.[0]?.price}
                  text="The quickest and easiest way to try eSwap"
                  btnText={currentPlan.subscription.priceId === p.prices?.[0]?.id ? 'Your current plan'
                    : 'Change plan'} // currentPlan.subscription.price < p.prices?.[0]?.price ? 'Upgrade' : 'Downgrade'
                  onButtonClick={() => onUpgradeClick(p.id)}
                  advantages={advantages}
                  interval={activeTab}
                  reverseBtnColor={currentPlan.subscription.priceId === p.prices?.[0]?.id}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default PricingPlans;
