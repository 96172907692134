import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getPriceListsRequest = createAsyncThunk(
  'priceLists/getPriceListsRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getPriceLists(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createPriceListRequest = createAsyncThunk(
  'priceLists/createPriceListRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.createPrice(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
