import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import { shopIcons } from '../../../../assets/shops';
import StatusField from '../../../_common/StatusField/StatusField';
import Formatter from '../../../../helpers/Formatter';
import Pagination from '../../../_common/Pagination/Pagination';
import noImage from '../../../../assets/icons/default.svg';
import { ReactComponent as ImportIcon } from '../../../../assets/icons/import.svg';
import Button from '../../../_common/Form/Button';

const columns = [
  {
    key: 'firstName', isEnabled: true,
  },
  {
    key: 'lastName', isEnabled: true,
  },
  {
    key: 'shopType', isEnabled: true,
  },
  {
    key: 'image', isEnabled: true,
  },
  {
    key: 'phone', isEnabled: true,
  },
  {
    key: 'type', isEnabled: true,
  },
  {
    key: 'ordersCount', isEnabled: true,
  },
  {
    key: 'createdAt', isEnabled: true,
  },
];

const defaultAttributes = {
  shopType: 'Shop',
  image: 'Image',
  firstName: 'First name',
  lastName: 'Last name',
  phone: 'Phone',
  type: 'Type',
  ordersCount: 'Orders',
  createdAt: 'Created at',
};

const Customers = ({ openExpiredModal }) => {
  const { integrationId } = useParams();
  const navigate = useNavigate();

  const myApp = useSelector((state) => state.app.myApp);

  const firstLoad = useRef(true);

  const [loading, setLoading] = React.useState(false);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState({ totalPage: 1, currentPage: 1 });
  const { totalPage, currentPage } = pagination;

  const [importLoading, setImportLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await Api.getCustomers({
        limit: 20,
        page: currentPage,
        shopId: integrationId,
      });

      setCustomers(data.customers);
      setPagination((prev) => ({ ...prev, totalPage: data.totalPages }));
      firstLoad.current = false;
      setLoading(false);
    })();
  }, [currentPage]);

  const importCustomers = async () => {
    setImportLoading(true);

    if (myApp.isExpired) {
      openExpiredModal();
    } else {
      try {
        const { data: { message } } = await Api.integrationImport({
          shop: myApp.type,
          type: 'customers',
          integrationId,
        });

        toast.success(message);
      } catch (e) {
        console.warn(e);
        toast.error(e.response.data?.message || 'Something went wrong');
      }
    }

    setImportLoading(false);
  };

  return (
    <div className="my_app_logs">
      {myApp.type !== 'b2b' && (
        <Button
          className="my_app_import_btn"
          size="small"
          btnType="cancel"
          roundBorder
          reverseColor
          iconLeft={<ImportIcon />}
          loading={importLoading}
          onClick={importCustomers}
        >
          Import customers
        </Button>
      )}

      {firstLoad.current
        ? (
          <Loader />
        )
        : (
          <div className="my_app_logs_table_wrapper">
            <DynamicTable
              loading={loading}
              data={customers}
              keyExtractor={(item) => item.id}
              columns={columns}
              renderColumnHeader={(col) => (defaultAttributes[col.key] || col.key)}
              renderColumns={{

                image: ({ item }) => (
                  <div className="my_app_logs_table_customer_image_wrapper">
                    <img
                      src={item.image || noImage}
                      alt="img"
                    />
                  </div>
                ),
                shopType: ({ item }) => {
                  const Icon = shopIcons[item.shopType] || shopIcons.eswap;

                  return (
                    Icon ? (
                      <div className="orders_shop_icon_wrapper">
                        <Icon />
                      </div>
                    )
                      : '-'
                  );
                },

                firstName: ({ item }) => (
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => navigate(`/crm/customers/edit/${item.id}`)}
                    className="my_app_logs_table_order_number"
                  >
                    {item.firstName || '-'}
                  </div>
                ),
                lastName: ({ item }) => item.lastName || '-',
                phone: ({ item }) => item.phone || '-',
                type: ({ item }) => item.type || '-',
                ordersCount: ({ item }) => item.ordersCount || '-',

                createdAt: ({ item }) => moment(item.createdAt).format('YYYY/MM/DD'),
              }}
            />

            {+totalPage > 1 && (
              <div className="my_app_logs_pagination">
                <Pagination
                  onChangePage={(page) => setPagination((prev) => ({ ...prev, currentPage: page }))}
                  page={currentPage}
                  totalPages={+totalPage}
                />
              </div>
            )}
          </div>

        )}
    </div>
  );
};

export default Customers;
