import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as MailchimpIcon } from '../../../../assets/icons/apps/mailchimp.svg';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';

const fields = [
  {
    title: 'Your app name',
    placeholder: 'Enter your app name',
    path: 'integrationName',
    required: true,
  },
];

function MailchimpInstall() {
  const { app } = useParams();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const response = await Api.installOtherApp(
        app,
        {
          integrationName: data.integrationName,
          redirectUrl: `${window.location.origin}/integration-verify/${app}`,
          // redirectUrl: 'http://127.0.0.1:3000/integration-verify/mailchimp',
        },
      );

      localStorage.setItem(`${app}IntegrationInfo`, JSON.stringify({
        integrationName: data.integrationName,
      }));

      window.location.href = response.data.params.redirectUrl;
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Mailchimp"
      shopIcon={<MailchimpIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and"
        + ' process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      Integration info

      <UpdateModal
        isOpen={installModal}
        fields={fields}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default MailchimpInstall;
