import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as OdooIcon } from '../../../../assets/icons/apps/odoo.svg';
import OdooImg1 from '../../../../assets/images/apps/odoo/odoo_1.png';
import OdooImg2 from '../../../../assets/images/apps/odoo/odoo_2.png';
import OdooImg3 from '../../../../assets/images/apps/odoo/odoo_3.png';
import OdooImg4 from '../../../../assets/images/apps/odoo/odoo_4.png';
import OdooImg5 from '../../../../assets/images/apps/odoo/odoo_5.png';
import OdooImg6 from '../../../../assets/images/apps/odoo/odoo_6.png';
import OdooImg7 from '../../../../assets/images/apps/odoo/odoo_7.png';
import OdooImg8 from '../../../../assets/images/apps/odoo/odoo_8.png';
import OdooImg9 from '../../../../assets/images/apps/odoo/odoo_9.png';
import OdooImg10 from '../../../../assets/images/apps/odoo/odoo_10.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import formatter from '../../../../helpers/Formatter';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { appInstallFields } from '../../../../helpers/staticData';

function OdooInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const { data: { integration: { id } } } = await Api.createIntegration(
        app,
        {
          integrationName: data.integrationName,
          url: formatter.url(data.url),
          db: data.db,
          username: data.username,
          password: data.password,
        },
      );

      navigate(`/apps/integration/${id}`);
    } catch (err) {
      if (err.response.data?.errors) {
        throw err;
      } else {
        toast.error(err.response.data.message);
        setInstallModal(false);
      }
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Odoo"
      shopIcon={<OdooIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and "
        + 'process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p className="title">
        Installation Instructions for Connecting Odoo to eSwap:
      </p>

      <ul>
        <li>Log in to your Odoo account.</li>
        <li>Copy the URL of your Odoo shop; this will be needed for integration.</li>
        <li>From the right menu, select &quot;My Database.&quot; This will take you to the Databases page.</li>
      </ul>

      <p>
        <img
          src={OdooImg9}
          alt="info image"
        />
      </p>

      <p>
        Copy your database name.
      </p>

      <p>
        <img
          src={OdooImg10}
          alt="info image"
        />
      </p>

      <p>
        Navigate to the Apps section in eSwap, particularly under &quot;ERP,&quot; and select &quot;Odoo.&quot;
      </p>

      <p>
        <img
          src={OdooImg1}
          alt="info image"
        />
      </p>

      <p>
        Click on &quot;Install app.&quot;
      </p>

      <p>
        <img
          src={OdooImg2}
          alt="info image"
        />
      </p>

      <ul>
        <li>Enter a desired name for your Odoo shop.</li>
        <li>Enter your Odoo shop URL.</li>
        <li>Enter your Odoo database name.</li>
        <li>Enter your username (the same username or email used for logging into Odoo).</li>
        <li>Enter your password (your Odoo password).</li>
        <li>Click &quot;Install app&quot; to proceed.</li>
      </ul>

      <p>
        <img
          src={OdooImg3}
          alt="info image"
        />
      </p>

      <ul>
        <li>Upon installation, follow these configuration steps:</li>
        <li>
          Choose a warehouse location from existing options or create a new one if
          necessary.
        </li>
      </ul>

      <p>
        <img
          src={OdooImg4}
          alt="info image"
        />
      </p>

      <p>Sync your price list with Odoo or create a new one as required.</p>

      <p>
        <img
          src={OdooImg5}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Configure Odoo order statuses to align with existing lines or create new ones if
          necessary.
        </li>
        <li>Decide whether to import data immediately or at a later time.</li>
      </ul>

      <p>
        <img
          src={OdooImg6}
          alt="info image"
        />
      </p>

      <p>The import process will begin based on your selections.</p>

      <p>
        <img
          src={OdooImg7}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Once the configuration steps are completed, click &quot;Done Installation&quot; to finalize the
          setup.
        </li>
        <li>The Odoo app will now be accessible from your apps page within eSwap.</li>
      </ul>

      <p>
        <img
          src={OdooImg8}
          alt="info image"
        />
      </p>

      <p>
        By following these steps, you will successfully connect your Odoo account to eSwap, enabling seamless
        data synchronization and management between the two platforms.
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={appInstallFields.odoo}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default OdooInstall;
