import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { currencies } from 'currency-formatter';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Api from '../../../../Api';
import { defaultReorders } from '../CreateProduct';
import Input from '../../../_common/Form/Input';
import Utils from '../../../../helpers/Utils';
import Select from '../../../_common/Form/Select';
import Button from '../../../_common/Form/Button';
import Checkbox from '../../../_common/Form/Checkbox';
import WarningMessageModal from './WarningMessageModal';

const BulkReorderRules = ({
  productItems, setBulkReorderRulesModal,
}) => {
  const [productSelectOptions, setProductSelectOptions] = useState({});
  const [errors, setErrors] = useState({});
  const [reorderData, setReorderData] = useState(defaultReorders);
  const [selectMenuWrapper, setSelectMenuWrapper] = useState();
  const [loading, setLoading] = useState(false);
  const [warningsModal, setWarningsModal] = useState([]);

  const {
    priceLists, customers, companies, warehouses,
  } = productSelectOptions;

  useEffect(() => {
    if (productItems.length) {
      setSelectMenuWrapper(document.querySelector('#modal'));
    }
  }, [productItems]);

  useEffect(() => {
    (async () => {
      const [
        prices,
        warehouse,
        customer,
        company,
      ] = await Promise.all([
        Api.getPriceLists(),
        Api.getWarehouses(),
        Api.getCustomers({ isVendor: true }),
        Api.getCompanies(),
      ]);

      setProductSelectOptions({
        priceLists: prices.data.priceLists,
        warehouses: warehouse.data.warehouses,
        customers: customer.data.customers,
        companies: company.data.companies,
      });

      setReorderData((prev) => ({
        ...prev,
        companyId: company.data.companies[0] || '',
        currencyCode: currencies[0].code,
        priceListId: prices.data.priceLists[0] || '',
        warehouseId: warehouse.data.warehouses[0] || '',
        vendorId: customer.data.customers[0] || '',
      }));
    })();
  }, []);

  const onDataChange = (path, value) => {
    setErrors((prev) => ({ ...prev, [path]: '' }));

    setReorderData((prev) => ({
      ...prev,
      [path]: path === 'action'
        ? value ? 'purchase' : 'draft'
        : value,
    }));
  };

  const onClose = () => {
    setBulkReorderRulesModal([]);
    setReorderData(defaultReorders);
  };

  const saveClick = async () => {
    setLoading(true);
    let hasError = false;
    _.forEach(reorderData, (val, key) => {
      if (key !== 'price' && !val) {
        setErrors((prev) => ({ ...prev, [key]: 'Field is has required' }));
        hasError = true;
      }
    });
    if (hasError) {
      toast.error('error');
    } else {
      const requestData = {
        reorderProducts: productItems.map((item) => ({
          productId: item.id,
          minStock: reorderData.minStock,
          reorderQty: reorderData.reorderQty,
          price: reorderData.price,
        })),
        action: reorderData.action,
        title: reorderData.title,
        warehouseId: reorderData.warehouseId.id || '',
        vendorId: reorderData.vendorId.id || '',
        companyId: reorderData.companyId.id || '',
        priceListId: reorderData.priceListId.id || '',
        currencyCode: reorderData.currencyCode,
        overwrite: !!warningsModal.length,
      };

      try {
        await Api.bulkProductReorderRules(requestData);

        setWarningsModal([]);
        onClose();
      } catch (e) {
        const error = e.response.data.errors.reorderProducts;

        setWarningsModal(error.map((p, index) => {
          if (p) {
            return { title: productItems[index].title, message: p?.productId };
          }

          return null;
        }));
      }
    }

    setLoading(false);
  };

  const selectFields = [
    {
      path: 'warehouseId',
      label: 'Warehouse',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: warehouses,
      getFullOption: true,
      getOptionLabel: (options) => (options.title),
    },
    {
      path: 'vendorId',
      label: 'Vendor',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: customers,
      getFullOption: true,
      getOptionLabel: (options) => (`${options.firstName} ${options.lastName}`),
    },
    {
      path: 'companyId',
      label: 'Company',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: companies,
      getFullOption: true,
      getOptionLabel: (options) => (options.name),
      createFields: [
        {
          title: 'Name',
          path: 'name',
          required: true,
          isSelectable: true,
        },
        {
          title: 'Type',
          path: 'type',
          type: 'select',
          required: true,
          options: [{
            value: 'sale',
            label: 'Sale',
          }, {
            value: 'buy',
            label: 'Buy',
          }],
        },
        {
          title: 'Company',
          path: 'company',
          type: 'select',
          options: companies,
          labelPath: 'name',
          valuePath: 'id',
          required: true,
          menuPortalTarget: true,
        },
        {
          title: 'Count',
          path: 'productsCount',
        },
        {
          title: 'Currency',
          path: 'currencyCode',
          type: 'select',
          options: currencies,
          required: true,
          valuePath: 'code',
          labelPath: 'code',
          menuPortalTarget: true,
          isSearchable: true,
        },
      ],
      loadMoreOptions: (inputValue, callback) => loadMoreData('companies', inputValue, callback),
    },
    {
      path: 'currencyCode',
      label: 'Currency',
      options: currencies,
      valuePath: 'code',
      isAsync: false,
      getOptionLabel: (options) => (options.code),
    },
    {
      path: 'priceListId',
      label: 'Price list to get cost price for product',
      valuePath: 'id',
      isAsync: true,
      defaultOptions: priceLists,
      getFullOption: true,
      getOptionLabel: (options) => (options.name),
      createFields: [
        {
          title: 'Name',
          path: 'name',
          required: true,
          isSelectable: true,
        },
        {
          title: 'Type',
          path: 'type',
          type: 'select',
          required: true,
          options: [{
            value: 'sale',
            label: 'Sale',
          }, {
            value: 'buy',
            label: 'Buy',
          }],
        },
        {
          title: 'Company',
          path: 'company',
          type: 'select',
          options: companies,
          labelPath: 'name',
          valuePath: 'id',
          required: true,
          menuPortalTarget: true,
        },
        {
          title: 'Count',
          path: 'productsCount',
        },
        {
          title: 'Currency',
          path: 'currencyCode',
          type: 'select',
          options: currencies,
          required: true,
          valuePath: 'code',
          labelPath: 'code',
          menuPortalTarget: true,
          isSearchable: true,
        },
      ],
      loadMoreOptions: (inputValue, callback) => loadMoreData('priceLists', inputValue, callback),
    },
  ];

  const loadMoreData = useCallback(async (path, inputValue, callback) => {
    const requestPath = path === 'warehouseId' ? 'warehouses'
      : path === 'vendorId' ? 'customers' : path === 'companyId' ? 'companies' : 'priceLists';

    const requests = {
      warehouses: Api.getWarehouses,
      customers: Api.getCustomers,
      companies: Api.getCompanies,
      priceLists: Api.getPriceLists,
    };

    const requestData = { s: inputValue };
    if (requestPath === 'customers') requestData.isVendor = true;

    const { data } = await requests[requestPath](requestData);

    callback(data[requestPath]);
  }, []);

  return (
    <div className="bulk_reorder_rules_modal_wrapper">
      <h2>Reorder rules</h2>

      <div className="bulk_reorder_rules_modal_container">
        <div className="bulk_reorder_rules_modal_input_container">
          <Input
            value={reorderData.title}
            label="Title"
            onChangeText={(value) => onDataChange('title', value)}
            size="small"
            roundBorder
            wrapperClassName="bulk_reorder_rules_modal_input"
            error={errors?.title}
          />

          <div className="bulk_reorder_rules_modal_input_mini_wrapper">
            <Input
              value={reorderData.minStock}
              label="Minimum stock"
              onChangeText={(value) => onDataChange('minStock', value)}
              size="small"
              roundBorder
              wrapperClassName="bulk_reorder_rules_modal_input_mini"
              onBeforeInput={(e) => Utils.typingNumber(e, true)}
              error={errors?.minStock}
            />

            <Input
              value={reorderData.reorderQty}
              label="Reorder qty"
              onChangeText={(value) => onDataChange('reorderQty', value)}
              size="small"
              roundBorder
              wrapperClassName="bulk_reorder_rules_modal_input_mini"
              onBeforeInput={(e) => Utils.typingNumber(e, true)}
              error={errors?.reorderQty}
            />
          </div>
        </div>

        <div className="bulk_reorder_rules_modal_block_selects">
          <h3>Locations</h3>

          {selectFields.map(({
            path, label, valuePath, isAsync, defaultOptions,
            options, getFullOption, getOptionLabel,
          }) => (
            <div className="bulk_reorder_rules_modal_block_select_wrp" key={path}>
              <Select
                value={reorderData[path]}
                options={options}
                label={label}
                valuePath={valuePath}
                isAsync={isAsync}
                defaultOptions={defaultOptions}
                size="small"
                roundBorder
                loadOptions={(inputValue, callback) => loadMoreData(path, inputValue, callback)}
                onChange={(value) => onDataChange(path, value)}
                getFullOption={getFullOption}
                getOptionLabel={getOptionLabel}
                error={errors?.[path]}
                isSearchable={!isAsync}
                menuPosition="fixed"
                menuPortalTarget={selectMenuWrapper}
              />
            </div>
          ))}

          <Input
            value={reorderData.price}
            label="Cost price"
            onChangeText={(value) => onDataChange('price', value)}
            size="small"
            roundBorder
            placeholder="$"
            onBeforeInput={(e) => Utils.typingFloatNumber(e, 1000000000, 2)}
            // error={errors?.price}
          />

          <Checkbox
            onChange={(checked) => onDataChange('action', checked)}
            label="Automatically create a purchase order without confirmation"
            checked={reorderData.action === 'purchase'}
            className="bulk_reorder_rules_modal_checkbox"
          />
        </div>
      </div>

      <div className="bulk_edit_modal_buttons_wrapper">
        <Button
          className="bulk_edit_modal_button"
          size="small"
          title="Cancel"
          onClick={onClose}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="bulk_edit_modal_button"
          size="small"
          title="Save"
          onClick={saveClick}
          roundBorder
          color="#1472FF"
          loading={loading && !warningsModal.length}
        />
      </div>

      <WarningMessageModal
        warningProducts={warningsModal.filter((w) => w)}
        onClose={() => setWarningsModal([])}
        onClick={saveClick}
        loading={loading}
      />
    </div>
  );
};

BulkReorderRules.propTypes = {
  productItems: PropTypes.array,
  setBulkReorderRulesModal: PropTypes.func,
};

BulkReorderRules.defaultProps = {
  productItems: [],
  setBulkReorderRulesModal: () => {
  },
};

export default BulkReorderRules;
