import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { format } from 'currency-formatter';
import { getOrdersInvoicesRequest } from '../../../../store/actions/invoices';
import Loader from '../../../_common/Loader/Loader';
import Utils from '../../../../helpers/Utils';

function InvoicesList() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams({});
  const ref = useRef();

  const allOrdersInvoices = useSelector((state) => state.invoices.allOrdersInvoices);
  const orderType = useMemo(() => (location.pathname.includes('/stocks') ? 'purchase' : 'sale'), [location]);

  useEffect(() => {
    (async () => {
      await dispatch(getOrdersInvoicesRequest(
        {
          ...Object.fromEntries(searchParams),
          includes: 'orderInvoiceProducts,payments',
          type: orderType === 'sale' ? 'invoice' : 'bill',
          includeIds: invoiceId,
        },
      ));
    })();
  }, [searchParams, orderType, invoiceId]);

  return (
    <div className="orders_list">
      <div className="top">
        <p className="title">{orderType === 'sale' ? 'Invoices' : 'Bills'}</p>
      </div>
      <div className="list_container">
        {!loading ? (
          <table className="list">
            <tbody>
              {allOrdersInvoices.map((o) => (
                <tr
                  key={o.id}
                  className={invoiceId === o.id ? 'active' : ''}
                  data-id={o.id}
                  ref={ref}
                >
                  <td
                    onClick={() => navigate(
                      orderType === 'sale'
                        ? `/orders/invoices/preview/${o.id}${location.search}`
                        : `/stocks/bills/preview/${o.id}${location.search}`,
                      { replace: true },
                    )}
                  >
                    <div>
                      <p className="name">
                        {`${o.billingAddress?.firstName || '-'} ${o.billingAddress?.lastName || '-'}`}
                      </p>
                      <p className="number">{o.number}</p>
                    </div>
                  </td>
                  <td
                    className="info"
                    onClick={() => navigate(
                      orderType === 'sale'
                        ? `/orders/invoices/preview/${o.id}${location.search}`
                        : `/stocks/bills/preview/${o.id}${location.search}`,
                      { replace: true },
                    )}
                  >
                    <div>
                      <p className="price">{format(o.totalPrice, { code: o.currencyCode })}</p>
                      <p className={`status ${o.status}`}>
                        <span className="status_dot" />
                        {Utils.formatStatus(o.status)}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : <Loader />}
      </div>
    </div>
  );
}

export default InvoicesList;
