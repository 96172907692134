import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from '../_common/Form/Button';
import { ReactComponent as GoBackIcon } from '../../assets/icons/go_back.svg';

const Wrapper = memo(({
  title, children, onBtnClick, buttonTitle, addBtn, buttonDisabled, buttonHref, btnLoading, hideBackBtn,
  cancelButtonTitle, onCancelBtnClick, cancelButtonHref, className, onThirdButtonClick, thirdButtonTitle,
  btnType,
}) => {
  const navigate = useNavigate();

  return (
    <div className={classNames('global_wrapper', className)}>
      <div className="global_title_wrapper">
        <div className="global_title">
          {!hideBackBtn && <GoBackIcon className="global_back" onClick={() => navigate(-1)} />}

          {title && <h1>{title}</h1>}
        </div>

        <div className="global_btns_wrapper">
          {onThirdButtonClick && (
            <Button
              size="small"
              btnType="transparent"
              color="#1472FF"
              roundBorder
              title={thirdButtonTitle}
              onClick={onThirdButtonClick}
            />
          )}

          {(onCancelBtnClick || cancelButtonHref) && (
            <Button
              size="small"
              btnType="cancel"
              roundBorder
              title={cancelButtonTitle}
              href={cancelButtonHref}
              onClick={onCancelBtnClick}
              disabled={buttonDisabled}
            />
          )}

          {(onBtnClick || buttonHref) && (
            <Button
              className="global_btn_save"
              size="small"
              title={buttonTitle}
              href={buttonHref}
              addBtn={addBtn}
              onClick={onBtnClick}
              roundBorder
              disabled={buttonDisabled}
              loading={btnLoading}
              btnType={btnType}
            />
          )}
        </div>
      </div>

      <div className="global_content">
        {children}
      </div>
    </div>
  );
});

Wrapper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  buttonHref: PropTypes.string,
  children: PropTypes.any.isRequired,
  buttonTitle: PropTypes.string,
  onBtnClick: PropTypes.func,
  addBtn: PropTypes.bool,
  hideBackBtn: PropTypes.bool,
  btnLoading: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  cancelButtonTitle: PropTypes.string,
  cancelButtonHref: PropTypes.string,
  thirdButtonTitle: PropTypes.string,
  btnType: PropTypes.string,
  onCancelBtnClick: PropTypes.func,
  onThirdButtonClick: PropTypes.func,
};

Wrapper.defaultProps = {
  title: '',
  buttonTitle: 'Save',
  buttonHref: '',
  onBtnClick: undefined,
  addBtn: false,
  hideBackBtn: false,
  buttonDisabled: false,
  btnLoading: false,
  cancelButtonTitle: 'Cancel',
  cancelButtonHref: '',
  onCancelBtnClick: undefined,
  className: '',
  thirdButtonTitle: '',
  btnType: 'primary',
  onThirdButtonClick: undefined,
};

export default Wrapper;
