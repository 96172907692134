import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function StepsLine({ steps, setStep, currentStep }) {
  return (
    <div className="stepper_wrapper_line">
      {steps.map(({ step }) => (
        <div
          key={step}
          style={{ cursor: step > currentStep ? 'pointer' : 'default' }}
          className={classNames('step', { active: step <= currentStep })}
          onClick={() => setStep(step)}
        />
      ))}
    </div>
  );
}

StepsLine.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    step: PropTypes.number.isRequired,
    // title: PropTypes.string.isRequired,
  })),
  setStep: PropTypes.func,
};

StepsLine.defaultProps = {
  setStep: () => {},
};

export default StepsLine;
