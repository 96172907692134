import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useNavigate, useParams,
} from 'react-router-dom';
import OrdersList from './OrdersList';
import { getSingleOrderRequest } from '../../../../store/actions/orders';
import Loader from '../../../_common/Loader/Loader';
import { getPriceListsRequest } from '../../../../store/actions/priceLists';
import PackageForm from './PackageForm';
import { getWarehousesRequest } from '../../../../store/actions/settings';
import InvoiceForm from './InvoiceForm';
import RecordPaymentForm from './RecordPaymentForm';
import SalesReturnForm from './SalesReturnForm';
import CreditNoteForm from './CreditNoteForm';
import { getOrderInvoicesRequest } from '../../../../store/actions/invoices';
import { getOrderPackagesRequest } from '../../../../store/actions/packages';
import ReceiveForm from './ReceiveForm';
import useWindowSize from '../../../../helpers/hooks/useWindowSize';
import { ReactComponent as GoBackIcon } from '../../../../assets/icons/go_back.svg';
import Button from '../../../_common/Form/Button';

function PreviewWrapper(props) {
  const { isMobile } = useWindowSize(1170);

  const { children, className = '' } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { orderId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const order = useSelector((state) => state.orders.order);

  const type = useMemo(() => (location.pathname.includes('/purchase') ? 'purchase' : 'sale'), [location]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all(
        [dispatch(getSingleOrderRequest(orderId)),
          dispatch(getOrderPackagesRequest(orderId)),
          dispatch(getPriceListsRequest()),
          dispatch(getWarehousesRequest()),
          dispatch(getOrderInvoicesRequest(orderId))],
      );
      setLoading(false);
    })();
  }, [orderId]);

  return (
    <div className="preview_wrapper">
      {!isMobile && <OrdersList />}

      {loading ? <Loader /> : null}

      {location.state?.package && order.type === 'sale' ? (
        <PackageForm />
      ) : null}
      {location.state?.package && order.type === 'purchase' ? (
        <ReceiveForm />
      ) : null}
      {location.state?.invoice ? (
        <InvoiceForm />
      ) : null}
      {location.state?.payment ? (
        <RecordPaymentForm />
      ) : null}
      {location.state?.return ? (
        <SalesReturnForm />
      ) : null}
      {location.state?.creditNote ? (
        <CreditNoteForm
          onCancel={() => navigate(`${location.pathname}${location.search}`, { state: {}, replace: true })}
        />
      ) : null}

      {!loading ? (
        <div className={`content ${className}`}>

          {isMobile
            && (
              <div className="mobile__back__and__new">
                <GoBackIcon className="global_back" onClick={() => navigate(-1)} />
                <Button
                  roundBorder
                  addBtn
                  onClick={() => navigate(type === 'purchase'
                    ? '/stocks/purchase-orders/create' : '/orders/sales-order/create')}
                  data-scope="WRITE_ORDERS"
                >
                  Add new
                </Button>
              </div>
            )}

          {children}
        </div>
      ) : null}

    </div>
  );
}

export default PreviewWrapper;
