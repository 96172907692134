import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import Checkbox from '../../../_common/Form/Checkbox';
import SubscriptionCard from './SubscriptionCard';
import ModalAddPayment from './ModalAddPayment';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import { cardTypes } from '../../../../helpers/staticData';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import WarningModal from '../../../_common/Modal/WarningModal';

const upgradeText = `After downgrading you will have "$X" on your balance, which you can use for next subscription.
Please Note: You can't withdraw this balance`;
const downgradeText = `After upgrading, if you haven’t used all the days in your current subscription, we will 
calculate and charge you the difference between your new and previous subscriptions.`;

function UpdatePlan() {
  const { planId } = useParams();
  const navigate = useNavigate();

  const [interval, setInterval] = useQueryParam(
    'interval',
    withDefault(StringParam, 'year'),
    { updateType: 'replace' },
  );

  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [couponData, setCouponData] = useState({});
  const [updatedPlan, setUpdatedPlan] = useState({});
  const [data, setData] = useState({
    plans: [],
    cards: [],
    currentPlan: {},
  });

  const { plans, cards, currentPlan } = data;

  useEffect(() => {
    (async () => {
      const [currentSubscription, plansList, payments] = await Promise.all([
        Api.getCurrentSubscription(),
        Api.getPlansList({ interval: '' }),
        Api.getPaymentMethods(),
      ]);

      setData({
        currentPlan: {
          ...currentSubscription.data.data.subscription,
          reNewDate: currentSubscription.data.data.reNewDate,
          balance: currentSubscription.data.data.balance,
        },
        cards: payments.data.paymentMethods,
        plans: plansList.data.plans,
      });

      setUpdatedPlan(plansList.data.plans.find((p) => p.id === planId));
      setSelectedCard(payments.data.paymentMethods.find((c) => c.isDefault));

      setLoading(false);
    })();
  }, []);

  const applyCoupon = async () => {
    setCouponLoading(true);

    try {
      const coupon = await Api.getCoupon(promoCode);
      setCouponData(coupon.data.coupon);
    } catch (e) {
      toast.error(e.response.data.message);
    }

    setCouponLoading(false);
  };

  const onAddPaymentMethod = async () => {
    setLoading(true);
    const payment = await Api.getPaymentMethods();

    setSelectedCard(payment.data.paymentMethods.find((c) => c.isDefault));

    setData((prev) => ({ ...prev, cards: payment.data.paymentMethods }));
    setLoading(false);
  };

  const onConfirmUpdatedPlan = async () => {
    setConfirmLoading(true);

    if (!selectedCard?.id) {
      toast.error('Please add or choose payment method');
      setConfirmLoading(false);
      closeWarningModal();

      return;
    }

    try {
      await Api.creteOrUpdateSubscription({
        paymentMethod: selectedCard.id,
        priceId: updatedPlan.prices.find((p) => p.interval === interval).id,
        coupon: couponData?.id || null,
        trial: false,
      });

      toast.success('Successfully updated');
      navigate('/settings/plans-billings/subscription');
    } catch (e) {
      toast.error(e.response.data.message);
    }

    setConfirmLoading(false);
  };

  const closeModal = useCallback(() => {
    setAddPaymentModal(false);
  }, [addPaymentModal]);

  const closeWarningModal = useCallback(() => {
    setWarningModal(false);
  }, [warningModal]);

  const selectedPrice = updatedPlan?.prices?.find((p) => p.interval === interval).price;

  return (
    <Wrapper title="Update your plan">
      {loading ? <Loader /> : (
        <div className="update_plan_wrapper">
          <div className="update_plan_block">
            <div className="update_plan_info_block">
              <div className="update_plan_info_block_left">
                Your current plan:
                <span>{_.upperFirst(currentPlan.planName)}</span>
              </div>

              <div className="update_plan_info_block_right">
                Next payment:
                <span>{moment(currentPlan.reNewDate).format('MM/DD/YYYY')}</span>
              </div>
            </div>

            <div className="update_plan_current_balance">
              <span>Your balance:</span>

              {`$${-1 * (currentPlan.balance / 100)}`}
            </div>

            <div className="update_plan_update_block">
              <SubscriptionCard
                title="Your updated plan"
                planTitle={_.upperFirst(updatedPlan.publicName)}
              >
                <div className="update_plan_interval_block">
                  {updatedPlan?.prices?.map((p) => (
                    <div className="update_plan_interval_checkbox_wrp">
                      <Checkbox
                        radio
                        onChange={() => setInterval(p.interval)}
                        className="update_plan_interval_block_radio"
                        checked={interval === p.interval}
                        label={`${_.upperFirst(p.interval)}ly`}
                        disabled={p.id === currentPlan?.priceId}
                      />

                      <p className="update_plan_interval_block_price">{`($${p.price} ${p.currency})`}</p>

                      {p.id === currentPlan?.priceId && <p>(Your plan)</p>}
                    </div>
                  ))}
                </div>
              </SubscriptionCard>

              <SubscriptionCard
                title="Payment method"
                onClick={() => setAddPaymentModal(true)}
                btnType="transparent"
                btnTitle="Add payment method"
                className="update_plan_cards_block_wrp"
              >
                <div className="update_plan_cards_block">
                  {!cards.length ? <p className="update_plan_no_card">No payment method</p>
                    : cards.map((c) => (
                      <div className="update_plan_single_card">
                        <div className="update_plan_single_card_left">
                          <Checkbox
                            onChange={() => setSelectedCard(c)}
                            radio
                            checked={selectedCard?.id === c.id}
                            className="update_plan_single_card_radio"
                          />

                          <div className="update_plan_single_card_info">
                            <p className="update_plan_single_card_info_name">{_.upperFirst(c.card.brand)}</p>

                            <p className="update_plan_single_card_info_last4">{`**** ${_.upperFirst(c.card.last4)}`}</p>

                            <p className="update_plan_single_card_info_name">
                              {`
                              Expiry on 
                              ${c.card.expMonth < 10
                                ? `0${c.card.expMonth}` : c.card.expMonth}
                              /
                              ${c.card.expYear.toString().slice(-2)}
                            `}
                            </p>

                            {c.isDefault && (
                            <div className="card_right_block_default">
                              Default
                            </div>
                            )}
                          </div>
                        </div>

                        <div className="update_plan_single_card_icon">
                          {cardTypes.find((t) => t.type.toLowerCase() === c.card.brand.toLowerCase()).icon}
                        </div>
                      </div>
                    ))}
                </div>
              </SubscriptionCard>

              <div className="update_plan_coupon_block">
                <div className="update_plan_coupon_left">
                  <Input
                    onChangeText={(value) => setPromoCode(value)}
                    value={promoCode}
                    size="small"
                    placeholder="Promo code"
                    roundBorder
                    wrapperClassName="update_plan_coupon_left_input"
                    disabled={updatedPlan.prices.find((p) => p.interval === interval).price === 0}
                  />

                  <div className="update_plan_coupon_left_btn_wrp">
                    {couponLoading ? <Loader size={20} />
                      : updatedPlan.prices.find((p) => p.interval === interval).price !== 0 && (
                      <Button
                        onClick={applyCoupon}
                        reverseColor
                        btnType="transparent"
                        color="#1472FF"
                      >
                        Apply
                      </Button>
                      )}
                  </div>
                </div>

                <div className="update_plan_coupon_right">
                  <div className="update_plan_coupon_right_single">
                    <p className="update_plan_coupon_right_single_title">Subtotal</p>

                    <p className="update_plan_coupon_right_single_price">
                      {`${selectedPrice} $`}
                    </p>
                  </div>

                  <div className="update_plan_coupon_right_single">
                    <p className="update_plan_coupon_right_single_title">Promocode</p>

                    <p
                      className="update_plan_coupon_right_single_price"
                      style={{ color: '#109B48' }}
                    >
                      {_.isEmpty(couponData) ? '-'
                        : !couponData.amountOff
                          ? `- ${((selectedPrice * couponData.percentOff) / 100)} $`
                          : `- ${couponData.amountOff / 100} $`}
                    </p>
                  </div>

                  <div className="update_plan_coupon_right_single">
                    <p
                      className="update_plan_coupon_right_single_title"
                      style={{ color: '#111C38', fontSize: '16px' }}
                    >
                      Total due today
                    </p>

                    <p
                      className="update_plan_coupon_right_single_price"
                      style={{ fontSize: '20px' }}
                    >
                      {_.isEmpty(couponData) ? selectedPrice
                        : !couponData.amountOff
                          ? `${selectedPrice - ((selectedPrice * couponData.percentOff) / 100)} $`
                          : `${(selectedPrice - (couponData.amountOff / 100)) < 0 ? 0
                            : selectedPrice - (couponData.amountOff / 100)} $`}
                    </p>
                  </div>
                </div>
              </div>

              <div className="update_plan_buttons_block">
                <Button
                  btnType="cancel"
                  roundBorder
                  size="small"
                  onClick={() => { navigate(-1); }}
                >
                  Cancel
                </Button>

                <Button
                  roundBorder
                  size="small"
                  className="update_plan_buttons_confirm"
                  onClick={() => setWarningModal(true)}
                >
                  Confirm plan changes
                </Button>
              </div>
            </div>
          </div>

          <ModalAddPayment
            isOpen={addPaymentModal}
            onClose={closeModal}
            onSave={onAddPaymentMethod}
          />

          <WarningModal
            isOpen={warningModal}
            onClose={closeWarningModal}
            loading={confirmLoading}
            onSaveClick={onConfirmUpdatedPlan}
            btnText="Update plan"
            text={currentPlan?.price < updatedPlan?.prices?.find((p) => p.interval === interval)?.price
              ? downgradeText : upgradeText}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default UpdatePlan;
