import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { ReactComponent as StripeIcon } from '../../../../assets/icons/apps/stripe.svg';
import Api from '../../../../Api';

function StripeInstall() {
  const { app } = useParams();

  const [loading, loadingToggle] = useState(false);

  const install = useCallback(async () => {
    loadingToggle(true);

    try {
      const response = await Api.installAccounting(
        app,
        {
          integrationName: 'Stripe',
          redirectUrl: `${window.location.origin}/integration-verify/${app}`,
        },
      );

      window.location.href = response.data.params.redirectUrl;
    } catch (err) {
      toast.error(err.response.data.message);
    }

    loadingToggle(false);
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Stripe"
      shopIcon={<StripeIcon />}
      description={'eSwap\'s Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and'
        + ' process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={install}
      btnLoading={loading}
    >
      <p className="title">
        Installation Instructions for Connecting Gmail to eSwap:
      </p>

      <br />

      <p>Follow these steps to connect your Gmail account to eSwap for enhanced email management.</p>

      <ul>
        <li>Navigate to the Apps section within eSwap.</li>
        <li>Go to the Email category and select Gmail.</li>
        <li>Click on Install App button. This will redirect you to Google's authorization page.</li>
        <li>
          Sign in to the Google account you want to connect. If you are already signed in, select your account
          from the list.
        </li>
        <li>Click Continue to authorize eSwap to access your Google account.</li>
        <li>
          Review the permissions eSwap is requesting. Select the features that eSwap can access and click Continue.
        </li>
      </ul>

      <br />
      <p>Once installation is complete, the Gmail app will be added to your apps page within eSwap.</p>
    </AppInstallInfoWrapper>
  );
}

export default StripeInstall;
