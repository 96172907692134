import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from '../../../_common/Tables/Table';
import Input from '../../../_common/Form/Input';
import Loader from '../../../_common/Loader/Loader';
import { getDashboardOutStockRequest } from '../../../../store/actions/dashboard';
import { ReactComponent as DragIcon } from '../../../../assets/icons/dashboard_drag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/dashboard_close.svg';

function DashboardItemsOutOfStock({
  data, setSearchValues, searchValue, dateLoading, disable, innerRef, onDelete, startDate, endDate,
}) {
  const navigate = useNavigate();
  const timeout = useRef();
  const firstLoad = useRef(true);
  const dispatch = useDispatch();

  const itemsOutOfStock = useSelector((store) => store.dashboard.itemsOutOfStock);

  const [searchLoading, setSearchLoading] = useState(false);

  const header = useMemo(() => [
    {
      title: 'Inventory',
      path: 'inventory',
      navigate: (item) => {
        const foundProd = itemsOutOfStock.find((prod) => +prod.productId === +item.productId);

        navigate(`${+foundProd.parentId
          ? `/products/${foundProd.parentId}?productVariantId=${foundProd.productId}`
          : `/products/${foundProd.productId}`}`);
      },
    },
    {
      title: 'SKU',
      path: 'sku',
    },
    {
      title: 'Incoming PO',
      path: 'incomingPo',
    },
  ], [itemsOutOfStock]);

  useEffect(() => {
    (async () => {
      setSearchLoading(true);

      await dispatch(getDashboardOutStockRequest({ startDate, endDate }));

      setSearchLoading(false);
      firstLoad.current = false;
    })();
  }, []);

  const onChange = useCallback(async (value) => {
    clearTimeout(timeout.current);
    setSearchValues((prev) => ({ ...prev, stockSearch: value }));

    timeout.current = setTimeout(async () => {
      setSearchLoading(true);

      await dispatch(getDashboardOutStockRequest({ stockSearch: value, startDate, endDate }));

      setSearchLoading(false);
    }, 500);
  }, []);

  return (
    <div
      ref={(ref) => {
        innerRef[data?.key] = ref || innerRef[data?.key];
      }}
      className="grid-stack-item"
      gs-max-w="12"
      gs-min-w="3"
      gs-max-h="3"
      gs-min-h="2"
      gs-id={data?.key}
      gs-w={data?.value?.position?.w}
      gs-h={data?.value?.position?.h}
      gs-x={data?.value?.position?.x}
      gs-y={data?.value?.position?.y}
      style={{ display: data?.show === false ? 'none' : 'block' }}
    >
      <div className="grid-stack-item-content">
        <div className="chart_wrapper" style={{ cursor: disable ? 'grab' : 'default' }}>
          {firstLoad.current && searchLoading ? <Loader /> : (
            <div className="items_out_of_stock">
              <div className="items_out_of_stock_top">
                <h3>Items out of stock</h3>

                {disable ? (
                  <div className="drag_icons">
                    <DragIcon />

                    <CloseIcon onClick={onDelete} />
                  </div>
                ) : (
                  <div className="search_block">
                    <Input
                      search
                      size="small"
                      roundBorder
                      placeholder="Search"
                      onChangeText={(value) => onChange(value)}
                      value={searchValue}
                    />

                    {/* {searchLoading && <Loader size={18} className="search_loader" />} */}
                  </div>
                )}
              </div>

              <Table data={itemsOutOfStock} header={header} loading={searchLoading || dateLoading} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardItemsOutOfStock;
