import React from 'react';
import AppCard from './AppCard';

function AppsTabWrapper({ integrations, cartroverIntegrations = [] }) {
  return (
    <div className="apps_wrapper">

      <div className="apps_lists">
        {integrations.map(({
          name, icon, text, link,
        }) => (
          <AppCard
            key={link}
            name={name}
            icon={icon}
            text={text}
            link={link}
          />
        ))}
      </div>

      {!!cartroverIntegrations.length && (
        <>
          <div className="apps_third_party_integrations">
            Third party integrations

            <div>Developed by CartRover</div>
          </div>

          <div className="apps_lists">
            {cartroverIntegrations.map(({
              name, icon, text, link,
            }) => (
              <AppCard
                key={link}
                name={name}
                icon={icon}
                text={text}
                link={link}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AppsTabWrapper;
