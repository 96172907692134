import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const statusList = [
  {
    label: 'Active',
    status: 'active',
    className: 'active',
  },
  {
    label: 'Delivered',
    status: 'delivered',
    className: 'active',
  },
  {
    label: 'Success',
    status: 'info',
    className: 'active',
  },
  {
    label: 'Error',
    status: 'error',
    className: 'canceled',
  },
  {
    label: 'Warning',
    status: 'warn',
    className: 'canceled',
  },
  {
    label: 'Debug',
    status: 'debug',
    className: 'pending',
  },
  {
    label: 'Inactive',
    status: 'inactive',
    className: 'disabled',
  },
  {
    label: 'Completed',
    status: 'completed',
    className: 'active',
  },
  {
    label: 'Disabled',
    status: 'disabled',
    className: 'disabled',
  },
  {
    label: 'Draft',
    status: 'draft',
    className: 'disabled',
  },
  {
    label: 'Refunded',
    status: 'refunded',
    className: 'disabled',
  },
  {
    label: 'Paid',
    status: 'paid',
    className: 'paid',
  },
  {
    label: 'Shipped',
    status: 'shipped',
    className: 'pending',
  },
  {
    label: 'Fulfilled',
    status: 'fulfilled',
    className: 'fulfilled',
  },
  {
    label: 'Pending',
    status: 'pending',
    className: 'pending',
  },
  {
    label: 'Unfulfilled',
    status: 'finalized',
    className: 'pending',
  },
  {
    label: 'Cancelled',
    status: 'canceled',
    className: 'canceled',
  },
  {
    label: 'Failed',
    status: 'failed',
    className: 'canceled',
  },
  {
    label: 'Partially Paid',
    status: 'partially_paid',
    className: 'partially_paid',
  },
  {
    label: 'Partially Paid',
    status: 'partially_paid',
    className: 'partially_paid',
  },
  {
    label: 'Partially Paid',
    status: 'partially_paid',
    className: 'partially_paid',
  },
  {
    label: 'Not Paid',
    status: 'open',
    className: 'open',
  },
  {
    label: 'Uncollectible',
    status: 'uncollectible',
    className: 'uncollectible',
  },
  {
    label: 'Void',
    status: 'void',
    className: 'void',
  },
  {
    label: 'Incomplete',
    status: 'incomplete',
    className: 'incomplete',
  },
  {
    label: 'Incomplete Expired',
    status: 'incomplete_expired',
    className: 'incomplete_expired',
  },
  {
    label: 'Trialing',
    status: 'trialing',
    className: 'trialing',
  },
  {
    label: 'Past Due',
    status: 'past_due',
    className: 'past_due',
  },
  {
    label: 'Canceled',
    status: 'canceled',
    className: 'canceled',
  },
  {
    label: 'Unpaid',
    status: 'unpaid',
    className: 'unpaid',
  },
  {
    label: 'Restricted',
    status: 'restricted',
    className: 'restricted',
  },
  {
    label: 'Received',
    status: 'received',
    className: 'received',
  },
  {
    label: 'Partially received',
    status: 'partially_received',
    className: 'partially_received',
  },
  {
    label: 'Partially delivered',
    status: 'partial_delivered',
    className: 'partially_received',
  },
  {
    label: 'Partially Invoiced',
    status: 'partially_invoiced',
    className: 'partially_invoiced',
  },
  {
    label: 'Invoiced',
    status: 'invoiced',
    className: 'invoiced',
  },
  {
    label: 'packed',
    status: 'packed',
    className: 'packed',
  },
  {
    label: 'not_received',
    status: 'not_received',
    className: 'not_received',
  },
];

const StatusField = ({ status }) => {
  const foundStatus = useMemo(() => statusList.find((l) => l.status === _.snakeCase(status)), [status]);

  return (
    foundStatus
      ? (
        <div className={`status_field ${foundStatus?.className || 'default'}`}>
          <p>{foundStatus?.label}</p>
        </div>
      )
      : '-'
  );
};

StatusField.propTypes = {
  status: PropTypes.string,
};

export default StatusField;
