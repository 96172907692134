import React, {
  useCallback, useRef, useState,
} from 'react';
import _ from 'lodash';
import {
  Link, useLocation,
} from 'react-router-dom';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import { toast } from 'react-toastify';
import { useClickAway, useDeepCompareEffect } from 'react-use';
import Loader from '../../../_common/Loader/Loader';
import Pagination from '../../../_common/Pagination/Pagination';
import Checkbox from '../../../_common/Form/Checkbox';
import {
  declineReturnRequest,
  deleteReturnRequest,
  orderReturnsListRequest,
} from '../../../../store/actions/orderReturns';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import Utils from '../../../../helpers/Utils';

const DelimitedArrayParamComma = Utils.customDelimitedArrayParam('~');
const defaultFiltersParams = withDefault(DelimitedArrayParamComma, []);

export const handleGetReceiveStatus = (orderReturnProducts) => {
  const receivedQty = _.sumBy(orderReturnProducts, 'receivedQty');
  const qty = _.sumBy(orderReturnProducts, 'qty');
  if (qty - receivedQty === 0) {
    return 'received';
  }
  if (receivedQty) {
    return 'partially_received';
  }
  return 'not_received';
};

function ReturnsTable() {
  const [checkedReturns, setCheckedReturns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const orderReturnsList = useSelector((state) => state.orderReturns.orderReturnsList);
  const totalPages = useSelector((state) => state.orderReturns.totalPages);

  const [queryData] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    status: defaultFiltersParams,
    receiveStatus: defaultFiltersParams,
    customerId: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  const dropdownRef = useRef();

  useClickAway(dropdownRef, () => {
    setDropdown(false);
  });

  useDeepCompareEffect(() => {
    handleGetOrders();
  }, [queryData]);

  const handleGetOrders = useCallback(async () => {
    setLoading(true);
    await dispatch(orderReturnsListRequest({ ...Utils.arrayToString(queryData), includes: 'customer,order' }));
    setLoading(false);
  }, [queryData]);

  const handleCheckReturns = useCallback((id) => {
    let checkedData = [];
    if (id === 'all' && checkedReturns.length !== orderReturnsList.length) {
      checkedData = orderReturnsList.map((o) => o.id);
    } else if (id !== 'all') {
      if (checkedReturns.includes(id)) {
        checkedData = checkedReturns.filter((o) => o !== id);
      } else {
        checkedData = [...checkedReturns, id];
      }
    }
    setCheckedReturns(checkedData);
  }, [checkedReturns, orderReturnsList]);

  const handleDecline = useCallback(async () => {
    const { payload: { data } } = await dispatch(declineReturnRequest(dropdown));
    if (data.status === 'error') {
      toast.error(data.message);
    } else {
      toast.success('Successfully declined.');
      await handleGetOrders();
    }
    setDropdown(null);
  }, [dropdown, queryData]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload: { data } } = await dispatch(deleteReturnRequest(dropdown));
    if (data.status === 'error') {
      toast.error(data.message);
    } else {
      toast.success('Successfully deleted.');
      await handleGetOrders();
    }
    setLoadingDelete(false);
    setDropdown(null);
  }, [dropdown, location]);

  return (
    <>
      <div className="table_wrapper sale_orders_table">
        {!(loading && _.isEmpty(orderReturnsList)) ? (
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th checkbox">
                  <Checkbox
                    onChange={() => handleCheckReturns('all')}
                    checked={checkedReturns.length === orderReturnsList.length}
                  />
                </th>
                <th className="table_thead_tr_th">Return#</th>
                <th className="table_thead_tr_th">Sales order#</th>
                <th className="table_thead_tr_th">Customer</th>
                <th className="table_thead_tr_th">Date</th>
                <th className="table_thead_tr_th">Status</th>
                <th className="table_thead_tr_th">Receive status</th>
                <th className="table_thead_tr_th">Returned</th>
                <th />
              </tr>
            </thead>

            <tbody className="table_tbody">
              {orderReturnsList.map((o) => (
                <tr
                  className="table_tbody_tr"
                  key={o.id}
                >
                  <th className="table_tbody_tr_td checkbox">
                    <Checkbox
                      onChange={() => handleCheckReturns(o.id)}
                      checked={checkedReturns.includes(o.id)}
                    />
                  </th>
                  <td className="table_tbody_tr_td link">
                    <Link to={`/orders/returns/preview/${o.id}${location.search}`}>{o.number}</Link>
                  </td>
                  <td className="table_tbody_tr_td">
                    {o.order.number}
                  </td>
                  <td className="table_tbody_tr_td">{`${o.customer.firstName || '-'} ${o.customer.lastName || '-'}`}</td>
                  <td
                    className="table_tbody_tr_td"
                  >
                    {o.sourceCreatedAt ? moment(o.sourceCreatedAt).format('YYYY.MM.DD hh:mm:ss') : '-'}
                  </td>
                  <td className={`table_tbody_tr_td status ${o.status}`}>
                    <div>
                      <p className="status_dot" />
                      <span>{_.startCase(o.status)}</span>
                    </div>
                  </td>
                  <td className={`table_tbody_tr_td status ${handleGetReceiveStatus(o.orderReturnProducts)}`}>
                    <div>
                      <p className="status_dot" />
                      <span>{_.startCase(handleGetReceiveStatus(o.orderReturnProducts))}</span>
                    </div>
                  </td>
                  <td className="table_tbody_tr_td">{_.sumBy(o.orderReturnProducts, 'qty')}</td>
                  <td className="table_tbody_tr_td">
                    <div className="actions">
                      <p onClick={() => setDropdown(o.id)}>
                        <DotsIcon />
                      </p>
                      {dropdown === o.id ? (
                        <ul className="dropdown" ref={dropdownRef} style={{ width: 150 }}>
                          <li onClick={handleDecline}>Mark as Declined</li>
                          <li className="delete" onClick={handleDelete}>
                            Delete
                            {loadingDelete ? <Loader size={10} style={{ top: 45 }} /> : null}
                          </li>
                        </ul>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {loading && _.isEmpty(orderReturnsList) ? <Loader size={30} /> : null}
        {!loading && _.isEmpty(orderReturnsList) ? <div className="no_data">No returns yet</div> : null}
      </div>
      <Pagination totalPages={totalPages} />
    </>
  );
}

export default ReturnsTable;
