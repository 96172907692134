import React from 'react';
import Button from '../../_common/Form/Button';

const TermsAndPrivacyText = () => (
  <p>
    By clicking "Sign up" button you are accepting our

    <Button
      roundBorder
      color="#1472FF"
      href="https://eswap.global/terms-and-conditions/"
      btnType="transparent"
      size="small"
    >
      Terms of usage
    </Button>

    and

    <Button
      roundBorder
      color="#1472FF"
      href="https://eswap.global/privacy-policy/"
      btnType="transparent"
      size="small"
    >
      Privacy policy
    </Button>
  </p>
);

export default TermsAndPrivacyText;
