import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../../_common/Form/Button';

function AppCard({
  icon: Icon, name, text, link, onDelete, onInstall, installBtnText, type,
}) {
  return (
    <div className={classNames('app_single_card_wrapper', type)}>
      <div className="app_single_card_icon">
        <Icon />
      </div>

      <div className="app_single_card_right">
        <p className="app_single_card_right_title">{name}</p>

        <p className="app_single_card_right_text">
          {type === 'card' && <span>Type: </span>}

          {text}
        </p>

        <div className="app_single_card_right_btn__wrapper">
          {onDelete && (
            <Button
              btnType="transparent"
              reverseColor
              color="#EB0000"
              roundBorder
              href={link}
              data-scope="DELETE_INTEGRATIONS"
              className="app_single_card_right_button"
              onClick={onDelete}
            >
              Uninstall app
            </Button>
          )}

          {(link || onInstall) && (
            <Button
              btnType="transparent"
              reverseColor
              color="#1472FF"
              href={link}
              data-scope="WRITE_INTEGRATIONS"
              className="app_single_card_right_button"
              onClick={onInstall}
            >
              {installBtnText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

AppCard.propTypes = {
  icon: PropTypes.elementType,
  name: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  installBtnText: PropTypes.string,
  onDelete: PropTypes.func,
  onInstall: PropTypes.func,
  type: PropTypes.oneOf(['default', 'card']),
};

AppCard.defaultProps = {
  icon: '',
  name: '',
  text: '',
  link: '',
  type: 'default',
  installBtnText: 'Install app',
  onDelete: undefined,
  onInstall: undefined,
};
export default AppCard;
