import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as VendIcon } from '../../../../assets/icons/apps/vend.svg';
import VendImg1 from '../../../../assets/images/apps/vend/vend_1.png';
import VendImg2 from '../../../../assets/images/apps/vend/vend_2.png';
import VendImg3 from '../../../../assets/images/apps/vend/vend_3.png';
import VendImg4 from '../../../../assets/images/apps/vend/vend_4.png';
import VendImg5 from '../../../../assets/images/apps/vend/vend_5.png';
import VendImg6 from '../../../../assets/images/apps/vend/vend_6.png';
import VendImg7 from '../../../../assets/images/apps/vend/vend_7.png';
import VendImg8 from '../../../../assets/images/apps/vend/vend_8.png';
import VendImg9 from '../../../../assets/images/apps/vend/vend_9.png';
import VendImg10 from '../../../../assets/images/apps/vend/vend_10.png';
import VendImg11 from '../../../../assets/images/apps/vend/vend_11.png';
import UpdateModal from '../../../_common/Modal/UpdateModal';
import Api from '../../../../Api';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';

const fields = [
  {
    title: 'Your app name',
    placeholder: 'Enter your app name',
    path: 'integrationName',
    required: true,
  },
];

function VendInstall() {
  const { app } = useParams();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const response = await Api.installApp(
        app,
        {
          integrationName: data.integrationName,
          redirectUrl: `${window.location.origin}/integration-verify/${app}`,
          // redirectUrl: 'https://app.eswap.ch/redirect',
        },
      );

      localStorage.setItem(`${app}IntegrationInfo`, JSON.stringify({
        integrationName: data.integrationName,
      }));

      window.location.href = response.data.params.redirectUrl;
    } catch (err) {
      setInstallModal(false);
      toast.error(err.response.data.message);
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Vend"
      shopIcon={<VendIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and"
        + ' process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p className="title">
        Installation Instructions for Connecting Vend to eSwap:
      </p>

      <ul>
        <li>
          Navigate to the Apps section in eSwap, particularly under &quot;Ecommerce,&quot; and select
          &quot;Vend.&quot;
        </li>
      </ul>

      <p>
        <img
          src={VendImg1}
          alt="info image"
        />
      </p>

      <p>Click on &quot;Install app.&quot;</p>

      <p>
        <img
          src={VendImg2}
          alt="info image"
        />
      </p>

      <ul>
        <li>Enter a desired name for your Vend shop.</li>
        <li>Click &quot;Install app&quot; to proceed</li>
      </ul>

      <p>
        <img
          src={VendImg3}
          alt="info image"
        />
      </p>

      <ul>
        <li>You will be redirected to the Vend (X-series store) website.</li>
        <li>Input your store URL as prompted.</li>
      </ul>

      <p>
        <img
          src={VendImg4}
          alt="info image"
        />
      </p>

      <p>Fill in your username and password to log in to your Vend account.</p>

      <p>
        <img
          src={VendImg5}
          alt="info image"
        />
      </p>

      <ul>
        <li>Vend will prompt you to agree to start the integration process.</li>
        <li>Click &quot;Connect&quot; to initiate the integration.</li>
      </ul>

      <p>
        <img
          src={VendImg6}
          alt="info image"
        />
      </p>

      <ul>
        <li className="title">Upon installation, follow these configuration steps:</li>
        <li>
          Choose a warehouse location from existing options or create a new one if
          necessary.
        </li>
      </ul>

      <p>
        <img
          src={VendImg7}
          alt="info image"
        />
      </p>

      <p>Sync your price list with Vend or create a new one as required.</p>

      <p>
        <img
          src={VendImg8}
          alt="info image"
        />
      </p>

      <p>
        Configure Vend order statuses to align with existing lines or create new ones if
        necessary.
      </p>

      <p>
        <img
          src={VendImg9}
          alt="info image"
        />
      </p>

      <ul>
        <li>Decide whether to import data immediately or at a later time.</li>
        <li>The import process will begin based on your selections.</li>
      </ul>

      <p>
        <img
          src={VendImg10}
          alt="info image"
        />
      </p>

      <ul>
        <li>
          Once the configuration steps are completed, click &quot;Done Installation&quot; to finalize the
          setup.
        </li>

        <li>
          The Vend app will now be accessible from your apps page within eSwap..
        </li>
      </ul>

      <p>
        <img
          src={VendImg11}
          alt="info image"
        />
      </p>

      <p>
        By following these steps, you will successfully connect your Vend account to eSwap, enabling seamless
        data synchronization and management between the two platforms.
      </p>

      <UpdateModal
        isOpen={installModal}
        fields={fields}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default VendInstall;
