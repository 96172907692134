import React from 'react';
import Checkbox from '../../../_common/Form/Checkbox';

function ImportConfiguration({ importConfig, changeImportConfig }) {
  return (
    <div className="import_configuration_wrapper">
      <div className="import_configuration_wrapper_block">
        <div
          onClick={() => changeImportConfig(true)}
          className="import_configuration_wrapper_block_single"
          role="button"
          tabIndex={0}
        >
          <Checkbox
            onChange={() => changeImportConfig(true)}
            radio
            checked={importConfig}
          />

          <div className="import_configuration_wrapper_block_info">
            <h3>Import and Match Products, Customers, Orders and Categories</h3>

            <p>Now eSwap will import your shop products and match them by SKU, also customers and orders.</p>
          </div>
        </div>

        <div
          onClick={() => changeImportConfig(false)}
          className="import_configuration_wrapper_block_single"
          role="button"
          tabIndex={0}
        >
          <Checkbox
            onChange={() => changeImportConfig(false)}
            radio
            checked={!importConfig}
          />

          <div className="import_configuration_wrapper_block_info">
            <h3>You can import your shop products, customers and orders later from shop settings page.</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportConfiguration;
