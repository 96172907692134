import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'currency-formatter';
import moment from 'moment/moment';
import _ from 'lodash';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Layout/Wrapper';
import noImg from '../../assets/icons/no_img.svg';
import { getProductsRequest } from '../../store/actions/products';
import InvoicePreviewHeader from '../../components/pages/Orders/invoices/InvoicePreviewHeader';
import InvoicesList from '../../components/pages/Orders/invoices/InvoicesList';
import RecordPaymentForm from '../../components/pages/Orders/preview/RecordPaymentForm';
import Utils from '../../helpers/Utils';
import TableDropdown from '../../components/pages/Orders/preview/TableDropdown';
import { ReactComponent as DotsIcon } from '../../assets/icons/menu_dots.svg';
import DeleteModal from '../../components/_common/Modal/DeleteModal';
import { cancelPaymentsRequest, getOrdersInvoicesRequest, getSingleInvoiceRequest } from '../../store/actions/invoices';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel_red.svg';
import CreditNoteForm from '../../components/pages/Orders/preview/CreditNoteForm';
import Loader from '../../components/_common/Loader/Loader';

const tabs = ['invoices', 'payments'];


function InvoicePreview() {
  const [products, setProducts] = useState([]);
  const [tab, setTab] = useState('invoices');
  const [dropdown, setDropdown] = useState(null);
  const [deletingData, setDeletingData] = useState({});
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const singleInvoice = useSelector((state) => state.invoices.singleInvoice);

  useEffect(() => {
    if (invoiceId) {
      (async () => {
        setLoading(true);
        await dispatch(getSingleInvoiceRequest(invoiceId));
        setLoading(false);
      })();
    }
  }, [invoiceId]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(singleInvoice)) {
        return;
      }
      const { payload } = await dispatch(getProductsRequest(
        { ids: singleInvoice?.orderInvoiceProducts?.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [singleInvoice]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const d = { id: deletingData.id, paymentId: deletingData.paymentId };
    const { payload } = await dispatch(cancelPaymentsRequest(d));
    if (payload.data.status === 'error') {
      toast.error(payload.data.message);
    } else {
      await dispatch(getOrdersInvoicesRequest({ includes: 'payments' }));
      toast.success('Successfully canceled.');
    }
    setLoadingDelete(false);
    setDeletingData({});
  }, [deletingData, singleInvoice]);

  return (
    <Wrapper>
      <div className="preview_wrapper return_preview invoice_preview">
        <Helmet>
          <body className="sales_order_preview" />
        </Helmet>
        <InvoicesList />
        {!loading ? (
          <div className="content">
            <InvoicePreviewHeader />
            <div className="order_preview_content">
              <div className="left">
                <div className="action_tabs">
                  <div className="tabs">
                    {tabs.map((t) => (
                      <div
                        className={classNames('tab', { active: tab === t })}
                        onClick={() => setTab(t)}
                        key={t}
                      >
                        {_.startCase(t)}
                      </div>
                    ))}
                  </div>
                  {tab === 'invoices' ? (
                    <div className="items sales_orders">
                      <div className="products_list table_wrapper">
                        <table className="table">
                          <thead className="table_thead">
                            <tr className="table_thead_tr">
                              <th className="table_thead_tr_th">Date</th>
                              <th className="table_thead_tr_th">Due Date</th>
                              <th className="table_thead_tr_th">Amount</th>
                              {singleInvoice.type === 'bill' ? <th className="table_thead_tr_th">Landed cost</th> : null}
                              <th className="table_thead_tr_th">Balance Due</th>
                            </tr>
                          </thead>
                          <tbody className="table_tbody">
                            <tr
                              className="table_tbody_tr"
                            >

                              <td className="table_tbody_tr_td">
                                {moment(singleInvoice.createdAt).format('DD MMM YYYY')}
                              </td>
                              <td className="table_tbody_tr_td">
                                {moment(singleInvoice.dueDate).format('DD MMM YYYY')}
                              </td>
                              <td className="table_tbody_tr_td">
                                {format(singleInvoice.totalPrice || '', { code: singleInvoice.currencyCode })}
                              </td>
                              {singleInvoice.type === 'bill' ? (
                                <td className="table_tbody_tr_td">
                                  {format(singleInvoice.landedCost || '', { code: singleInvoice.currencyCode })}
                                </td>
                              ) : null}
                              <td className="table_tbody_tr_td">
                                {format(
                                  singleInvoice.totalPrice
                              - _.sumBy(singleInvoice.payments?.filter((p) => p.status !== 'canceled'), 'amount'),
                                  { code: singleInvoice.currencyCode },
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                  {tab === 'payments' ? (
                    <div className="items sales_orders payments">
                      <div className="products_list table_wrapper">
                        <table className="table">
                          <thead className="table_thead">
                            <tr className="table_thead_tr">
                              <th className="table_thead_tr_th">Payment#</th>
                              <th className="table_thead_tr_th">Date</th>
                              <th className="table_thead_tr_th">Payment type</th>
                              <th className="table_thead_tr_th">Status</th>
                              <th className="table_thead_tr_th">Amount</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody className="table_tbody">
                            {singleInvoice.payments.map((p, index) => (
                              <tr
                                className="table_tbody_tr"
                                key={p.id}
                              >
                                <td className="table_tbody_tr_td number">
                                  {p.number || '-'}
                                </td>
                                <td className="table_tbody_tr_td">
                                  {p.sourceCreatedAt ? moment(p.sourceCreatedAt).format('DD MMM YYYY') : null}
                                </td>
                                <td className="table_tbody_tr_td">
                                  {p.paymentType.title}
                                </td>
                                <td className="table_tbody_tr_td">
                                  <div className={`status ${p.status}`}>
                                    <p className="status_dot" />
                                    <span>
                                      {singleInvoice?.order?.type === 'purchase'
                                        ? Utils.formatPurchaseStatus(p.status)
                                        : Utils.formatStatus(p.status)}
                                    </span>
                                  </div>
                                </td>
                                <td className="table_tbody_tr_td">
                                  {format(p.amount, { code: singleInvoice?.currencyCode })}
                                </td>
                                <td className="table_tbody_tr_td">
                                  {p.status !== 'canceled' ? (
                                    <div className="dot_icon" onClick={() => setDropdown(p.id)}>
                                      <DotsIcon />
                                    </div>
                                  ) : null}
                                  {dropdown === p.id ? (
                                    <TableDropdown
                                      target=".payments"
                                      onClose={() => setDropdown(null)}
                                      top={60 + ((index + 1) * 45)}
                                    >

                                      {(_.sumBy(singleInvoice.payments, 'amount') - _.sumBy(singleInvoice.creditNotes, 'credit')) > 0
                                        ? (
                                          <li onClick={() => navigate(
                                            location.pathname,
                                            { state: { creditNote: p.invoiceId }, replace: true },
                                          )}
                                          >
                                            Create credit note
                                          </li>
                                        ) : null}
                                      {p.status !== 'canceled' ? (
                                        <li
                                          onClick={() => setDeletingData({
                                            id: p.invoiceId,
                                            paymentId: p.id,
                                            status: p.status,
                                          })}
                                          className="delete"
                                        >
                                          Cancel payment
                                        </li>
                                      ) : null}
                                    </TableDropdown>
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="preview_products_list">
                  <p className="title">{`Items(${singleInvoice?.orderInvoiceProducts?.length || 0})`}</p>
                  <div className="products_list table_wrapper">
                    <table className="table">
                      <thead className="table_thead">
                        <tr className="table_thead_tr">
                          <th className="table_thead_tr_th">Product</th>
                          <th className="table_thead_tr_th">Quantity</th>
                          <th className="table_thead_tr_th">Price</th>
                          <th className="table_thead_tr_th">Discount</th>
                          <th className="table_thead_tr_th">Tax</th>
                          <th className="table_thead_tr_th">Amount</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody className="table_tbody">
                        {singleInvoice?.orderInvoiceProducts?.map((p) => {
                          const product = products?.find((f) => f.id === p.productId) || {};
                          return (
                            <tr
                              className="table_tbody_tr"
                              key={p.id}
                            >

                              <td className="table_tbody_tr_td">
                                <div className="info" style={{ alignItems: 'center' }}>
                                  {p.type !== 'custom' ? (
                                    <img
                                      style={{ height: 30, width: 30 }}
                                      src={product.images?.[0]?.src
                                    || product.images?.[0]?.medium
                                    || noImg}
                                      alt=""
                                    />
                                  ) : null}
                                  {p.type !== 'custom'
                                    ? (
                                      <Link
                                        to={product.type === 'variant'
                                          ? `/products/${product.parentId}?productVariantId=${product.id}`
                                          : `/products/${product.id}`}
                                        target="_blank"
                                      >
                                        {p.title}
                                      </Link>
                                    )
                                    : <span>{p.title}</span>}
                                </div>
                              </td>
                              <td className="table_tbody_tr_td">
                                {p.qty}
                              </td>
                              <td className="table_tbody_tr_td">
                                {format(
                                  p.subtotalPrice / p.qty,
                                  { code: singleInvoice.currencyCode },
                                )}
                              </td>
                              <td
                                className="table_tbody_tr_td "
                              >
                                {`${p.discountPercentage || 0}%`}
                              </td>

                              <td className="table_tbody_tr_td ">
                                {`${p.taxPercentage || 0}%`}
                              </td>
                              <td
                                className="table_tbody_tr_td"
                              >
                                {format(p.totalPrice, { code: singleInvoice.currencyCode })}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="total_container">
                    <div className="total">
                      <div className="item">
                        <p>Total Items</p>
                        <p>{singleInvoice?.orderInvoiceProducts?.length || 0}</p>
                      </div>
                      <div className="item">
                        <p>Total Units</p>
                        <p>{_.sumBy(singleInvoice?.orderInvoiceProducts, 'qty') || 0}</p>
                      </div>
                      <div className="item">
                        <p>Tax</p>
                        <p>{format(singleInvoice.totalTax, { code: singleInvoice.currencyCode })}</p>
                      </div>
                      <div className="item">
                        <p>Shipping charges</p>
                        <p>{format(singleInvoice.shippingPrice, { code: singleInvoice.currencyCode })}</p>
                      </div>
                      <div className="item">
                        <p className="subtotal_value">Subtotal</p>
                        <p>{format(singleInvoice.subtotalPrice, { code: singleInvoice.currencyCode })}</p>
                      </div>
                      <div className="item">
                        <p className="total_value">Total</p>
                        <p>{format(singleInvoice.totalPrice, { code: singleInvoice.currencyCode })}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="order_information_container">

                <div className="order_information">
                  <div className="col">
                    <p className="title">Contact information</p>
                    <div className="item">
                      <p
                        className="value"
                      >
                        {`${singleInvoice?.billingAddress?.firstName || ''} ${singleInvoice?.billingAddress?.lastName || ''}`}
                      </p>
                    </div>
                    <div className="item">
                      <p className="value">
                        {
                        Utils.formatAddress(
                          [singleInvoice?.billingAddress?.address,
                            singleInvoice?.billingAddress?.city,
                            singleInvoice?.billingAddress?.region,
                            singleInvoice?.billingAddress?.postalCode,
                            singleInvoice?.billingAddress?.code],
                        )
                      }
                      </p>
                    </div>
                    <div className="item">
                      <p className="value">{singleInvoice?.billingAddress?.phone || ''}</p>
                    </div>
                    <div className="item">
                      <p className="value">{singleInvoice?.billingAddress?.email || ''}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {location.state?.creditNote ? (
              <CreditNoteForm
                onCancel={() => navigate(`${location.pathname}${location.search}`, { state: {}, replace: true })}
              />
            ) : null}
          </div>
        ) : <Loader />}

        {location.state?.payment ? (
          <RecordPaymentForm />
        ) : null}
        <DeleteModal
          isOpen={!!deletingData.id}
          onDelete={handleDelete}
          onClose={() => setDeletingData({})}
          loading={loadingDelete}
          text="payment"
          deleteType="cancel"
          icon={<CancelIcon />}
          btnTitle="confirm"
        />
      </div>

    </Wrapper>
  );
}

export default InvoicePreview;
