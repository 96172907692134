import React, {
  useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import Select from '../../_common/Form/Select';
import Input from '../../_common/Form/Input';
import Button from '../../_common/Form/Button';
import Utils from '../../../helpers/Utils';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import TermsAndPrivacyText from './TermsAndPrivacyText';
import ReCaptcha2 from '../../_common/ReCaptcha/ReCaptcha2';
import { companyIndustries, companySizes } from '../../../helpers/staticData';
import AddressAutocomplete from '../../_common/Form/AddressAutocomplete';

const StepTwo = ({
  data, onChange, changeStep, errors, setErrors, countries, registration, states, statesLoading, onReCaptchaChange,
  onReCaptchaExpire, addressClick, fromGoogleOauth,
}) => {
  const [saveLoading, saveLoadingToggle] = useState(false);

  const submit = useCallback(async (e) => {
    e.preventDefault();
    let hasError = fields.some(({ path }) => errors.company?.[path]);

    fields.forEach(({ path }) => {
      const key = `company.${path}`;

      if (path !== 'address' && !_.get(data, key).trim()) {
        setErrors((prev) => {
          const newErrors = { ...prev };
          _.set(newErrors, key, 'Field is required');

          return newErrors;
        });

        hasError = true;
      }
    });

    if (hasError) {
      toast.error('Please correct these fields');
    } else {
      saveLoadingToggle(true);

      await registration();

      saveLoadingToggle(false);
    }
  }, [data, errors]);

  const fields = useMemo(() => [
    {
      label: 'Company name',
      path: 'name',
      placeholder: 'Enter company name',
      type: 'input',
    },
    {
      label: 'Company size',
      path: 'size',
      placeholder: 'Enter company size',
      options: companySizes,
      optionPath: 'value',
      optionLabel: 'value',
      type: 'select',
    },
    {
      label: 'Industry',
      path: 'industry',
      placeholder: 'Enter company industry',
      options: companyIndustries,
      optionPath: 'title',
      optionLabel: 'title',
      type: 'select',
    },
    {
      label: 'Phone',
      path: 'phone',
      placeholder: 'Enter Phone',
      type: 'input',
      validation: 'phone',
    },
    {
      label: 'Address',
      path: 'address',
      placeholder: 'Enter Address',
      type: 'autoComplete',
      fullWidth: true,
    },
    {
      label: 'Country',
      path: 'countryCode',
      placeholder: 'Enter country',
      options: countries,
      optionPath: 'code',
      optionLabel: 'name',
      type: 'select',
    },
    {
      label: 'State',
      path: 'regionId',
      options: states,
      optionPath: 'id',
      optionLabel: 'name',
      placeholder: 'Enter state',
      type: 'select',
    },
    {
      label: 'City',
      path: 'city',
      placeholder: 'Enter city',
      type: 'input',
    },
    {
      label: 'Zip code',
      path: 'postalCode',
      placeholder: 'Enter zip code',
      type: 'input',
    },
  ], [states]);

  return (
    <form onSubmit={submit}>
      {fields.map(({
        type, label, path, placeholder, validation, options, optionLabel, optionPath,
        fullWidth,
      }) => {
        const key = `company.${path}`;

        return (
          <div key={label} className={classNames({ full_width: fullWidth })}>
            {type === 'select'
              ? (
                <Select
                  label={label}
                  placeholder={placeholder}
                  options={options || []}
                  value={_.get(data, key)}
                  error={_.get(errors, key)}
                  onChange={(value) => onChange(key, value)}
                  labelPath={optionLabel}
                  valuePath={optionPath}
                  roundBorder
                  loading={path === 'regionId' && statesLoading}
                  isDisabled={path === 'regionId' && (!_.get(data, 'company.countryCode') || statesLoading)}
                  isSearchable
                  size="small"
                />
              )

              : type === 'autoComplete'
                ? (
                  <AddressAutocomplete
                    value={_.get(data, key)}
                    onChangeText={onChange}
                    addressClick={addressClick}
                  />
                )
                : (
                  <Input
                    label={label}
                    placeholder={placeholder}
                    password={type === 'password'}
                    value={_.get(data, key)}
                    error={_.get(errors, key)}
                    onChangeText={(value) => onChange(key, value)}
                    onBeforeInput={(e) => Utils.onBeforeInput(e, validation)}
                    size="small"
                    roundBorder
                  />
                )}
          </div>
        );
      })}

      {data && (
      <ReCaptcha2
        onChange={onReCaptchaChange}
        onExpired={onReCaptchaExpire}
        error={_.get(errors, 'captcha')}
      />
      )}

      <TermsAndPrivacyText />

      <Button
        roundBorder
        size="small"
        type="submit"
        loading={saveLoading}
      >
        Sign up
      </Button>

      {!fromGoogleOauth && (
      <Button
        btnType="transparent"
        className="back_btn"
        color="#1472FF"
        iconLeft={<ArrowIcon />}
        onClick={() => changeStep(1)}
      >
        Back
      </Button>
      )}
    </form>
  );
};

StepTwo.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  registration: PropTypes.func.isRequired,
  addressClick: PropTypes.func.isRequired,
  states: PropTypes.array.isRequired,
  statesLoading: PropTypes.bool.isRequired,
  fromGoogleOauth: PropTypes.bool.isRequired,
};

export default StepTwo;
