import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

function Chip({ text, onDelete }) {
  return (
    <div className="chip">
      <span>
        {text}
      </span>

      <CloseIcon
        onClick={onDelete}
      />
    </div>
  );
}

Chip.propTypes = {
  text: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Chip;
