import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Modal from './Modal';
import Input from '../Form/Input';
import Select from '../Form/Select';
import Switcher from '../Form/Switcher';
import Button from '../Form/Button';
import Utils from '../../../helpers/Utils';
import ColorPicker from '../ColorPicker/ColorPicker';
import Api from '../../../Api';
import TabButton from '../Tabs/TabButton';

const UpdateModal = memo(({
  id, fields, singleData, onSave, isOpen, onClose, zIndex, className, text, withDefaultSwitcher, fullTitle, btnText,
  disabledPaths, switcherPath, switcherLabel, colorPath, countryPath, statePath, loadMoreOptions, agreementsText,
  dynamicPlaceholder, tabs, activeTab, onChangeTab,
}) => {
  const [currentFields, setCurrentFields] = useState([]);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [saveLoading, saveLoadingToggle] = useState(false);

  useEffect(() => {
    if (fields.length) setCurrentFields(fields);
  }, [fields]);

  const setOptions = (findPath, stateOptions, loading, disabled) => {
    setCurrentFields((prev) => prev.map((p) => (p.path === findPath ? {
      ...p, options: stateOptions, loading, disabled,
    } : p)));
  };

  useEffect(() => {
    const foundCountryField = fields.find((field) => field.path === countryPath);

    if (!foundCountryField?.options?.length) {
      (async () => {
        setOptions(countryPath, [], true, true);

        const payload = await Api.getCountries();

        setOptions(countryPath, payload.data.countries, false, false);
      })();
    }
  }, []);

  useEffect(() => {
    const hasStateField = fields.some((field) => field.path === statePath);

    if (hasStateField && data[countryPath]) {
      (async () => {
        setOptions(statePath, [], true, true);

        const payload = await Api.getRegions(data[countryPath]);

        setOptions(statePath, payload.data.regions, false, false);
      })();
    }
  }, [data[countryPath]]);

  useEffect(() => {
    if (isOpen) {
      setErrors({});
      setData(singleData);
    }
  }, [isOpen]);

  const onChange = useCallback((path, value) => {
    setData((prev) => {
      const newData = {
        ...prev,
        [path]: value,
      };

      if (path === countryPath && fields.find((f) => f.path === statePath)) {
        newData[statePath] = '';
      }

      return newData;
    });

    setErrors((prev) => ({
      ...prev,
      [path]: '',
    }));
  }, [data, fields]);

  const saveData = useCallback(async (e) => {
    e.preventDefault();
    let hasError = false;

    fields.forEach(({
      path, required, regex, regexError,
    }) => {
      if (required && !data?.[path]?.toString()?.trim()) {
        setErrors((prev) => ({ ...prev, [path]: 'Field is required' }));
        hasError = true;
      } else if (regex) {
        setErrors((prev) => ({ ...prev, [path]: !regex.test(data[path].toString().trim()) ? regexError : '' }));
        hasError = hasError || !regex.test(data[path].toString().trim());
      } else if (path.toLowerCase().includes('email') && !Utils.isEmail(data[path])) {
        setErrors((prev) => ({ ...prev, [path]: 'Enter valid email' }));
        hasError = true;
      } else if (path.toLowerCase().includes('url') && data[path] && !Utils.isUrl(data[path])) {
        setErrors((prev) => ({ ...prev, [path]: 'Enter valid url' }));
        hasError = true;
      } else {
        setErrors((prev) => ({ ...prev, [path]: '' }));
      }
    });

    if (!hasError) {
      saveLoadingToggle(true);

      try {
        await onSave(data);
      } catch (err) {
        setErrors(err?.response?.data?.errors || err?.errors);

        toast.error(err?.response?.data?.message || 'Please correct these fields');
      }

      saveLoadingToggle(false);
    } else {
      toast.error('Please correct these fields');
    }
  }, [data]);

  const getDynamicPlaceholder = (dynamicPath, placeholder) => dynamicPlaceholder?.[data[dynamicPath]] || placeholder;

  return (
    <Modal
      wrapperClassName="update_modal_amazon_app"
      className={classNames('general_settings_update_modal_wrapper', className)}
      isOpen={isOpen}
      onClose={() => !saveLoading && onClose()}
      zIndex={zIndex}
      id={id}
    >
      <form onSubmit={saveData} className="fields_block">
        <h3 className="fields_block_title">{fullTitle || `${!singleData?.id ? 'New' : 'Edit'} ${text}`}</h3>

        {tabs && <TabButton buttonsData={tabs} activeValue={activeTab} onClick={onChangeTab} />}

        {currentFields.map(({
          placeholder, title, path, type, options, validation, valuePath, labelPath, menuPortalTarget, getFullOption,
          disabled, loading, isAsync, defaultOptions, required, dynamicPlaceholderKey, urlPreview, ...rest
        }) => (
          <div className="update_modal_single_input" key={path}>
            {
              type === 'select'
                ? (
                  <Select
                    onChange={(d) => onChange(path, d)}
                    value={data[path]}
                    label={title}
                    placeholder={placeholder || title}
                    options={options}
                    valuePath={valuePath}
                    labelPath={labelPath}
                    error={errors?.[path]}
                    loading={loading}
                    wrapperClassName="update_modal_input_wrp"
                    roundBorder
                    getFullOption={getFullOption}
                    size="small"
                    menuPosition={menuPortalTarget ? 'fixed' : null}
                    menuPortalTarget={menuPortalTarget ? document.querySelector(`#${id || 'modal'}`) : null}
                    isDisabled={(disabledPaths.includes(path) && singleData?.id) || disabled}
                    isAsync={isAsync}
                    loadOptions={loadMoreOptions}
                    defaultOptions={defaultOptions}
                    {...rest}
                  />
                )
                : type === 'colorPicker'
                  ? (
                    <ColorPicker
                      label={title}
                      placeholder={placeholder || title}
                      wrapperClassName="update_modal_color_picker"
                      value={data[colorPath] || ''}
                      onChange={(value) => onChange(colorPath, value)}
                      error={errors?.[path]}
                    />
                  )
                  : (
                    <Input
                      value={data[path]}
                      onChangeText={(value) => onChange(path, value)}
                      label={`${title}${urlPreview && data[path] ? `: ${data[path].toLowerCase()}${urlPreview}` : ''}`}
                      password={type === 'password'}
                      placeholder={getDynamicPlaceholder(dynamicPlaceholderKey, placeholder || title)}
                      error={errors?.[path]}
                      wrapperClassName="update_modal_input_wrp"
                      roundBorder
                      size="small"
                      symbol={path === 'rate' ? '%' : ''}
                      onBeforeInput={(e) => Utils.onBeforeInput(e, validation)}
                      disabled={disabledPaths.includes(path) && singleData?.id}
                    />
                  )
            }

            {type === 'password'
              && <input type="text" autoComplete="new-password" style={{ display: 'none' }} />}
          </div>
        ))}

        {agreementsText && (
          <div className="agreements_text_wrapper">
            {agreementsText}
          </div>
        )}

        {withDefaultSwitcher && (
          <Switcher
            onChange={(checked) => onChange((switcherPath), checked)}
            label={switcherLabel}
            className="update_modal_swithcher"
            // disabled={singleData.isDefault}
            checked={(typeof data[switcherPath] === 'string' ? data[switcherPath] === 'active' : data[switcherPath])}
          />
        )}

        <div className="update_modal_buttons_wrp">
          <Button
            className="update_modal_cancel_button"
            roundBorder
            size="small"
            btnType="cancel"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            className={classNames('update_modal_save_button', { disabled: agreementsText && !data[switcherPath] })}
            roundBorder
            size="small"
            type="submit"
            loading={saveLoading}
          >
            {btnText || (!singleData?.id ? 'Create' : 'Save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
});

UpdateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  loadMoreOptions: PropTypes.func,
  className: PropTypes.string,
  fullTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.string,
  zIndex: PropTypes.number,
  singleData: PropTypes.object,
  btnText: PropTypes.string,
  disabledPaths: PropTypes.array,
  switcherLabel: PropTypes.string,
  colorPath: PropTypes.string,
  switcherPath: PropTypes.string,
  countryPath: PropTypes.string,
  statePath: PropTypes.string,
  dynamicPlaceholder: PropTypes.object,
  agreementsText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  })),
  activeTab: PropTypes.string,
  onChangeTab: PropTypes.func,
};

UpdateModal.defaultProps = {
  className: '',
  text: '',
  fullTitle: '',
  btnText: '',
  zIndex: 150,
  onClose: null,
  onSave: () => {},
  loadMoreOptions: () => {},
  singleData: {},
  disabledPaths: [],
  switcherLabel: 'Set as default',
  colorPath: 'color',
  switcherPath: 'isDefault',
  countryPath: 'country',
  statePath: 'state',
  agreementsText: '',
  dynamicPlaceholder: null,
  tabs: undefined,
  activeTab: '',
  onChangeTab: null,
};
export default UpdateModal;
