import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { format } from 'currency-formatter';
import {
  ArrayParam, NumberParam, StringParam, useQueryParams, withDefault,
} from 'use-query-params';
import Utils from '../../../../helpers/Utils';
import { getAllPackagesRequest } from '../../../../store/actions/packages';

const defaultFiltersParams = withDefault(ArrayParam, []);

function ReceivesList() {
  const dispatch = useDispatch();
  const { receiveId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams({});
  const ref = useRef();

  const allPackages = useSelector((state) => state.packages.allPackages);

  const [queryData] = useQueryParams({
    s: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    orderId: defaultFiltersParams,
    status: defaultFiltersParams,
  }, { updateType: 'replaceIn', removeDefaultsFromUrl: true });

  useEffect(() => {
    dispatch(getAllPackagesRequest({
      ...Utils.arrayToString(queryData),
      includes: 'order',
      type: 'receive',
    }));
  }, [searchParams]);

  return (
    <div className="orders_list">
      <div className="top">
        <p className="title">Receives</p>
      </div>
      <div className="list_container">
        <table className="list">
          <tbody>
            {allPackages.map((o) => (
              <tr
                key={o.id}
                className={receiveId === o.id ? 'active' : ''}
                data-id={o.id}
                ref={ref}
              >
                <td
                  onClick={() => navigate(`/stocks/receives/preview/${o.id}${location.search}`, { replace: true })}
                >
                  <div>
                    <p
                      className="name"
                    >
                      {`${o.order.shippingAddress?.firstName || '-'} ${o.order.shippingAddress?.lastName || '-'}`}
                    </p>
                    <p className="number">
                      <span>{o.number}</span>
                      <span className="order_number">{` | ${o.order.number}`}</span>
                    </p>
                  </div>
                </td>
                <td
                  className="info"
                  onClick={() => navigate(`/stocks/receives/preview/${o.id}${location.search}`, { replace: true })}
                >
                  <div>
                    <p className="price">{format(o.totalPrice, { code: o.currencyCode })}</p>
                    <p className={`status ${o.status}`}>
                      <span className="status_dot" />
                      {Utils.formatPurchaseStatus(o.status)}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReceivesList;
