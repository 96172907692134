import React, {
  useCallback, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { useClickAway } from 'react-use';
import { toast } from 'react-toastify';
import _ from 'lodash';
import fileDownload from 'js-file-download';
import FilterButton from '../../../_common/Form/FilterButton';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/remote_printing.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download.svg';
import { ReactComponent as ApplyIcon } from '../../../../assets/icons/apply.svg';
import Loader from '../../../_common/Loader/Loader';
import Api from '../../../../Api';
import {
  deleteCreditNoteRequest,
  getCreditNoteRequest, getCreditNotesRequest,
  refundCreditNoteRequest,
} from '../../../../store/actions/creditNotes';
import PrintDocumentModal from '../../../_common/Modal/PrintDocumentModal';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import ApplyToInvoiceModal from './ApplyToInvoiceModal';
import Utils from '../../../../helpers/Utils';

function CreditNotePreviewHeader() {
  const [dropdown, setDropdown] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [applyModal, setApplyModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { noteId } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams({});

  const dropdownRef = useRef();

  useClickAway(dropdownRef, () => {
    setDropdown(false);
  });

  const creditNote = useSelector((state) => state.creditNotes.creditNote);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload: { data } } = await dispatch(deleteCreditNoteRequest(noteId));
    if (data.status === 'error') {
      toast.error(data.message);
    } else {
      toast.success('Successfully deleted.');
      navigate(`/orders/credit-notes${location.search}`);
    }
    setLoadingDelete(false);
  }, [noteId, location]);

  const downloadDocument = useCallback(async () => {
    setLoadingDownload(true);
    try {
      const { data } = await Api.downloadCreditNoteDocument(noteId);
      fileDownload(data, 'document.pdf');
      setLoadingDownload(false);
    } catch (e) {
      console.warn(e.response);
    }
  }, [noteId]);

  const handleRefund = useCallback(async () => {
    setLoadingRefund(true);
    const { payload: { data } } = await dispatch(refundCreditNoteRequest(noteId));
    if (data.creditNote) {
      toast.success('Credit note successfully refunded.');
      await dispatch(getCreditNoteRequest(noteId));
      await dispatch(getCreditNotesRequest({ ...Object.fromEntries(searchParams), includes: 'customer' }));
      setDropdown(null);
    } else {
      toast.error(data.message);
    }
    setLoadingRefund(false);
  }, [noteId, searchParams]);

  const handlePrint = useCallback(async (params) => {
    try {
      const { data } = await Api.downloadCreditNoteDocument(creditNote.id, 'remote-print', { ...params }, 'json');
      return data;
    } catch (e) {
      return e.response.data;
    }
  }, [creditNote]);

  return (
    <div className="preview_header">
      <div className="info">
        <div className="row">
          <p className="number">{creditNote.number}</p>
          <div className="statuses">
            <p className={Utils.getCreditNoteStatus(
              creditNote?.credit,
              creditNote?.refunded,
              creditNote?.applied,
            )}
            >
              <span className="status_dot" />
              {_.startCase(Utils.getCreditNoteStatus(
                creditNote?.credit,
                creditNote?.refunded,
                creditNote?.applied,
              ))}
            </p>
          </div>
        </div>
      </div>
      <div className="actions">
        <FilterButton
          className="filter_button"
          onClick={() => setShowPrintModal(true)}

        >
          <PrintIcon />
          <span>Print</span>
        </FilterButton>
        <FilterButton
          className="filter_button"
          onClick={downloadDocument}
        >
          {!loadingDownload ? (
            <>
              <DownloadIcon />
              <span>Download</span>
            </>
          ) : (
            <div className="loader_wrapper" style={{ width: 15, height: 15, position: 'relative' }}>
              <span
                style={{
                  width: 15,
                  height: 15,
                }}
                className="loader"
              />
            </div>
          )}
        </FilterButton>
        {+creditNote.refunded < +creditNote.credit ? (
          <FilterButton
            className="filter_button"
            onClick={() => setApplyModal(true)}
          >
            <ApplyIcon />
            <span>Apply to invoice</span>
          </FilterButton>
        ) : null}
        {Utils.canRefundCreditNote(creditNote) ? (
          <FilterButton
            className="filter_button blue"
            onClick={handleRefund}
          >
            {!loadingRefund ? <span>Refund</span>
              : (
                <div className="loader_wrapper" style={{ width: 15, height: 15, position: 'relative' }}>
                  <span
                    style={{
                      width: 15,
                      height: 15,
                      borderColor: '#ffffff',
                    }}
                    className="loader"
                  />
                </div>
              )}
          </FilterButton>
        ) : null}

        <FilterButton className="filter_button dots" onClick={() => setDropdown(true)}>
          <DotsIcon />
          {dropdown ? (
            <ul className="dropdown" ref={dropdownRef}>
              <li className="delete" onClick={() => setDeletingId(creditNote.id)}>
                Delete
                {loadingDelete ? <Loader size={10} /> : null}
              </li>
            </ul>
          ) : null}
        </FilterButton>

        <RemoveIcon className="remove_icon" onClick={() => navigate(-1)} />
      </div>

      {showPrintModal ? (
        <PrintDocumentModal
          isOpen={showPrintModal}
          onClose={() => setShowPrintModal(false)}
          onRequest={async (templateId) => {
            const { data } = await Api.downloadCreditNoteDocument(creditNote.id, 'download', { templateId });
            return data;
          }}
          onRemotePrint={handlePrint}
        />
      ) : null}

      <DeleteModal
        isOpen={!!deletingId}
        onDelete={handleDelete}
        onClose={() => setDeletingId('')}
        loading={loadingDelete}
        text="credit note"
      />
      {applyModal ? (
        <ApplyToInvoiceModal
          isOpen={applyModal}
          id={noteId}
          onClose={() => setApplyModal(false)}
          amount={+creditNote.credit - (+creditNote.refunded + +creditNote.applied)}
        />
      ) : null}
    </div>
  );
}

export default CreditNotePreviewHeader;
