import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../_common/Form/Button';
import Select from '../../../../_common/Form/Select';
import Api from '../../../../../Api';
import Loader from '../../../../_common/Loader/Loader';

const MailchimpConfiguration = ({}) => {
  const { integrationId } = useParams();
  const navigate = useNavigate();

  const [integrationData, setIntegrationData] = useState({});

  const [loading, loadingToggle] = useState(true);
  const [error, setError] = useState('');
  const [saveLoading, saveLoadingToggle] = useState(false);

  useLayoutEffect(() => {
    (async () => {
      try {
        const { data: { integration } } = await Api.getIntegration(integrationId);

        const [{ data }, { data: { total } }] = await Promise.all([
          Api.getOtherIntegrationAudiences(integration.type, integrationId),
          Api.getCustomers({ meta: 'mailchimp' }),
        ]);

        setIntegrationData({ ...integration, ...data, totalExportCount: total });
      } catch (err) {
        navigate('/404', { replace: true });
      }

      loadingToggle(false);
    })();
  }, []);

  const changeData = useCallback((value) => {
    setIntegrationData((prev) => ({ ...prev, settings: { ...prev.settings, listId: value } }));
    setError('');
  }, [integrationData]);

  const save = useCallback(async () => {
    saveLoadingToggle(true);

    if (!integrationData.settings?.listId) {
      setError('Field is required');

      toast.error('Please correct the field');
    } else {
      const sendingData = { ...integrationData };
      sendingData.orderImportStartDate = sendingData.orderImportStartDate || new Date();

      await Api.updateIntegration(sendingData);

      toast.success('Configs successfully saved');
    }

    saveLoadingToggle(false);
  }, [integrationData]);

  return (
    <div className="smtp__configs">
      {loading
        ? <Loader />

        : (
          <div className="smtp__configs__container">
            <div className="smtp__configs__top">

              <div className="smtp__configs__buttons">
                <Button
                  title="Save"
                  size="small"
                  roundBorder
                  onClick={save}
                  loading={saveLoading}
                />
              </div>
            </div>

            <div className="smtp__configs_form">
              <div className="smtp__configs_form_item">
                <h2>{`Customers exported to MailChimp: ${integrationData.totalExportCount}`}</h2>
              </div>

              <div className="smtp__configs_form_item">
                <p>Audiences</p>

                <Select
                  placeholder="Select audience"
                  value={integrationData.settings?.listId}
                  onChange={changeData}
                  options={integrationData.audiences}
                  valuePath="id"
                  labelPath="name"
                  roundBorder
                  size="small"
                  error={error}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

MailchimpConfiguration.propTypes = {

};

export default MailchimpConfiguration;
