import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import _ from 'lodash';
import Input from '../components/_common/Form/Input';
import Button from '../components/_common/Form/Button';
import LogoutLayout from '../components/Layout/LogoutLayout';
import Api from '../Api';

const CreatePassword = () => {
  const navigate = useNavigate();
  const [{ token }] = useQueryParams({ token: StringParam });

  if (!token) {
    return <Navigate to="/login" />;
  }

  const [createPasswordFields, setCreatePasswordFields] = useState({ password: '', confirmPassword: '' });
  const [error, setError] = useState({});

  const { password, confirmPassword } = createPasswordFields;

  const [loading, setLoading] = useState(false);

  const changePassword = (path, value) => {
    setCreatePasswordFields((prev) => ({ ...prev, [path]: value }));

    setError((prev) => ({ ...prev, [path]: '', global: '' }));
  };

  const reset = async (e) => {
    e.preventDefault();

    let hasError = false;

    setLoading(true);

    _.forEach(createPasswordFields, (val, key) => {
      if (!val.trim()) {
        hasError = true;
        setError((prev) => ({ ...prev, [key]: 'Field is required' }));
      }
    });

    if (!hasError && password !== confirmPassword) {
      setError({ global: 'Passwords do not match.' });
      hasError = true;
    }

    if (hasError) {
      toast.error('Please correct the field');
    } else {
      try {
        await Api.resetPassword({ token, newPassword: password });

        navigate('/login');
      } catch (err) {
        toast.error('Please correct the field');

        setError({ global: err.response.data.message });
      }
    }

    setLoading(false);
  };

  return (
    <LogoutLayout title="Change password">
      <div className="login_page">
        <form onSubmit={reset}>
          <Input
            label="New password"
            placeholder="Enter your password"
            value={password}
            onChangeText={(val) => changePassword('password', val)}
            error={error.password}
            size="small"
            roundBorder
            password
          />

          <Input
            label="Confirm new password"
            placeholder="Enter your password"
            value={confirmPassword}
            onChangeText={(val) => changePassword('confirmPassword', val)}
            error={error.confirmPassword}
            password
            size="small"
            roundBorder
          />

          {error.global && <p className="error_message">{error.global}</p>}

          <Button
            roundBorder
            size="small"
            type="submit"
            loading={loading}
          >
            Change password
          </Button>
        </form>

        <div className="login_page_login_btn_wrapper">
          Found your password?

          <Button
            roundBorder
            color="#1472FF"
            href="/login"
            btnType="transparent"
            size="small"
          >
            Log in
          </Button>
        </div>
      </div>
    </LogoutLayout>
  );
};

export default CreatePassword;
