import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { currencies } from 'currency-formatter';
import _ from 'lodash';
import Button from '../components/_common/Form/Button';
import { ReactComponent as Video } from '../assets/icons/video.svg';
import { ReactComponent as Question } from '../assets/icons/question.svg';
import { ReactComponent as Add } from '../assets/icons/add_circle.svg';
import Wrapper from '../components/Layout/Wrapper';
import Loader from '../components/_common/Loader/Loader';
import UpdateModal from '../components/_common/Modal/UpdateModal';
import Api from '../Api';
import { getSetupRequest } from '../store/actions/notifications';

const fields = [
  {
    title: 'Select currency',
    path: 'currencyCode',
    type: 'select',
    options: currencies,
    labelPath: 'code',
    valuePath: 'code',
    menuPortalTarget: true,
    isSearchable: true,
    required: true,
  },
];

const Setup = () => {
  const dispatch = useDispatch();
  const profile = useSelector((store) => store.users.profile);
  const {
    firstLoad, totalCount, completedCount, percent, hasCurrency, isDeletedDemo, hasProducts, isEmailConfirmed,
    extraTasks,
  } = useSelector((state) => state.notifications.setup);

  const [progress, setProgress] = useState(0);
  const [isCurrencyModalOpen, currencyModalToggle] = useState(false);
  const [currencyModalInfo, setCurrencyModalInfo] = useState({});

  const [deleteLoading, deleteLoadingToggle] = useState(false);

  useEffect(() => {
    if (!hasCurrency) {
      (async () => {
        const { data: { currencyCode } } = await Api.getSettings(['currencyCode']);

        setCurrencyModalInfo({ currencyCode });
      })();
    }
  }, [hasCurrency]);

  const saveBaseCurrency = useCallback(async (data) => {
    await Api.updateSettings(data);
    await dispatch(getSetupRequest());

    currencyModalToggle(false);
  }, []);

  const deleteDemoProducts = useCallback(async () => {
    deleteLoadingToggle(true);
    await Api.deleteDemoProducts();
    await dispatch(getSetupRequest());
    deleteLoadingToggle(false);
  }, []);

  useEffect(() => {
    setTimeout(() => setProgress(percent), 300);
  }, [percent]);

  const setups = useMemo(() => {
    let tasks = [
      {
        title: 'Confirm Base Currency',
        description: 'Currency',
        buttonTitle: 'Confirm',
        onClick: () => currencyModalToggle(true),
        isDone: hasCurrency,
      },
      {
        title: 'Create Your First Product or Import via CSV',
        description: 'Using eSwap you will be able to create products one by one, or import multiple products '
          + 'using CSV sample!',
        buttonTitle: 'Create a.svg product',
        href: '/products/add',
        isDone: hasProducts,
      },
      {
        title: 'Configure Your Emails',
        description: 'Configure your custom SMTP service and all emails in eSwap to your customers will be sent from your'
          + ' configured email address!',
        href: '/apps/list/email',
        buttonTitle: 'Configure email',
        isDone: isEmailConfirmed,
      },
    ];

    if (!_.isEmpty(extraTasks)) {
      tasks = [
        ...tasks,
        ..._.map(extraTasks, (value, key) => (
          {
            title: `Install ${_.upperFirst(key)}`,
            description: 'Install app',
            buttonTitle: 'Install',
            href: `/apps/${key}`,
            isDone: value,
          }
        )),
      ];
    }

    tasks = [
      ...tasks,
      {
        title: 'Delete Demo Data',
        description: 'Delete Demo Data',
        onClick: deleteDemoProducts,
        buttonTitle: 'Clear demo data',
        isDone: isDeletedDemo,
        loading: deleteLoading,
      },
    ];

    return tasks;
  }, [extraTasks]);

  return (
    <Wrapper
      title={`Hi ${profile.firstName} ${profile.lastName}, Welcome to eSwap!`}
    >
      <div className="setup">
        {firstLoad && <Loader />}

        {!firstLoad && (
          <div className="setup_wrap">
            <div className="setup_left">
              <div className="setup_left_title">
                <p>Completing these tasks will have you up and running in no time!</p>
              </div>

              <div className="setup_content_title">
                <p>{`You have completed ${completedCount} out of ${totalCount} tasks`}</p>

                <div className="setup_progress_container">
                  <div style={{ '--percent': `${progress}%` }} className="set_up_progress" />
                  <div className="set_up_percentage">{`${percent}%`}</div>
                </div>
              </div>

              <div className="set_up_left_content">

                <div className="set_up_content_block ">
                  {setups.map(({
                    title, description, href, onClick, isDone, buttonTitle, loading,
                  }) => (
                    <div key={title} className={classNames('set_up_content', { active: isDone })}>
                      <div>
                        <h6>{title}</h6>
                        <p>{description}</p>
                      </div>

                      {!isDone && (
                        <div className="configure_btn_wrapper">
                          <Button
                            title={buttonTitle}
                            size="small"
                            href={href}
                            onClick={onClick}
                            roundBorder
                            className="configure_btn"
                            loading={loading}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="setup_right">
              <div className="setup_right_wrap">
                <h6>Meet your eSwap colleague</h6>

                <div className="book_atime_block">
                  <h6>Book a time with your eSwap colleague!</h6>

                  <p>
                    Book a time with one of our colleague if you need any help getting started or have any questions.
                  </p>

                  <Button
                    roundBorder
                    // onClick={() => setOpenNewTemplateModal(true)}
                    size="small"
                    title="Book a time"
                    className="book_atime"
                  />
                </div>

                <h6 className="learn_more">Learn more about eSwap</h6>

                <div className="about_eSwap">
                  <div className="about_eSwap_tab" style={{ background: 'rgba(0, 58, 210, 0.05)' }}>
                    <Video />
                    <div>
                      <h6>See what you can do with eSwap</h6>
                      <p>A quick 4 min intro</p>
                    </div>
                  </div>

                  <div className="about_eSwap_tab" style={{ background: 'rgba(31,65,117,0.05)' }}>
                    <Question />
                    <div>
                      <h6>Help center</h6>
                      <p>Advice and answers from the eSwap team</p>
                    </div>

                  </div>

                  <div className="about_eSwap_tab" style={{ background: 'rgba(22,195,91,0.05)' }}>
                    <Add />
                    <div>
                      <h6>Add your ideas to eSwap</h6>
                      <p>View our upcoming roadmap and add your ideas</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <UpdateModal
        isOpen={isCurrencyModalOpen}
        fields={fields}
        onSave={saveBaseCurrency}
        onClose={() => currencyModalToggle(false)}
        singleData={currencyModalInfo}
        fullTitle="Select base currency"
        btnText="Save"
      />
    </Wrapper>
  );
};

export default Setup;
