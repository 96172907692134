import moment from 'moment';
import Cookies from 'js-cookie';
import Storage from './Storage';

class Account {
  static setToken(token, tokenName = 'token', remember = true) {
    Cookies.set(tokenName, token, { expires: remember ? 365 : null });
  }

  static getToken = (token = 'token') => Cookies.get(token);

  static setExpiryDate = (expiryDate) => localStorage.setItem('expiryDate', expiryDate);

  static getExpiryDate = () => moment(new Date() || localStorage.getItem('expiryDate'))
    .subtract(10, 'minutes').toDate();

  static set = (account) => Storage.set('profile', account);

  static get = () => Storage.get('profile', {});

  static getUserId = () => Cookies.get('userId');

  static setUserId = (id) => Cookies.set('userId', id);

  static getOwnerId = () => Cookies.get('ownerId');

  static setOwnerId = (id) => Cookies.set('ownerId', id);

  static removeUserId = () => Cookies.remove('userId');

  static setLoginRedirect = (redirect) => sessionStorage.setItem('login.redirect', redirect);

  static getLoginRedirect = () => sessionStorage.getItem('login.redirect') || '/';

  static removeLoginRedirect = () => sessionStorage.removeItem('login.redirect');

  static getAuthData = () => {
    let authData = {
      integrationHash: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      company: {
        name: '',
        size: '',
        industry: '',
        phone: '',
        countryCode: '',
        regionId: '',
        postalCode: '',
        city: '',
        address: '',
      },
    };
    try {
      const data = JSON.parse(sessionStorage.getItem('authData'));
      if (data) {
        authData = {
          ...authData,
          integrationHash: data.integrationHash || '',
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          password: data.password || '',
          currencyCode: data.currencyCode || '',
          company: {
            ...authData.company,
            name: data.companyName || '',
            phone: data.phone || '',
            city: data.city || '',
            address: data.address || '',
            regionId: data.regionId || '',
            countryCode: data.countryCode || '',
            postalCode: data.postalCode || '',
          },
        };
      }
      console.log(authData);
    } catch (e) {
      //
    }
    return authData;
  };

  static removeAuthData = () => {
    sessionStorage.removeItem('authData');
  };

  static remove = () => {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    localStorage.removeItem('profile');
    localStorage.removeItem('expiryDate');
    Cookies.remove('userId');
    Cookies.remove('ownerId');
    Storage.remove('account');
  };

  static logout = () => {
    this.remove();
    window.location.href = '/login';
  };
}

export default Account;
