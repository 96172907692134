import _ from 'lodash';
import { toast } from 'react-toastify';
import { decodeDelimitedArray, encodeDelimitedArray } from 'use-query-params';
import { v7 as uuidV7 } from 'uuid';
import { shopIcons } from '../assets/shops';

class Utils {
  static isUrl = (value) => /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/img.test(value);

  static isEmail = (value) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/img.test(value);

  static deleteEmptyKeys = (object) => {
    const obj = _.cloneDeep(object);

    for (const propName in obj) {
      if ((typeof obj[propName] !== 'boolean' && typeof obj[propName] !== 'number')
        && (typeof obj[propName] === 'object' ? _.isEmpty(obj[propName]) : !obj[propName].trim())) {
        delete obj[propName];
      }
    }

    return obj;
  };

  static onUploadFile = (e, callback, validationTypes, maxFileSize) => {
    const { files } = e.target;

    if (_.isEmpty(files)) {
      return false;
    }

    const validTypes = validationTypes.replaceAll('.', '')
      .split(', ');

    _.forEach(files, (file) => {
      if (!validTypes || validTypes.find((t) => file?.type.includes(t))) {
        if (maxFileSize && Math.ceil(file.size / 1024 / 1024) > maxFileSize) {
          return toast.error(`File max size must be ${maxFileSize}mb`);
        }

        file._preview = URL.createObjectURL(file);

        callback(file);
      } else {
        toast.error(`Not valid file type, please upload ${validationTypes} files`);
      }
    });

    e.target.value = '';
  };

  static typingNumber = (e, positiveNumber, maxLength, maxValue, notAllowIfFirstNumZero) => {
    const notAllowSymbols = ['.', 'e', '+', '-'];
    const {
      data: currentSymbol,
      target: { value, selectionStart: startPosition, selectionEnd: endPosition },
      nativeEvent: { keyCode },
    } = e;

    const currentValue = value.substring(0, startPosition) + currentSymbol + value.substring(endPosition, value.length);

    const valueToNumber = +currentValue;
    const firstNumber = currentValue.slice(0, 1);

    const isSpaceKey = keyCode === 32;
    const isInvalidNumber = Number.isNaN(valueToNumber);
    const isInvalidPositiveNumber = positiveNumber && +firstNumber === 0;
    const isInvalidLength = maxLength && currentValue.length > maxLength;
    const isInvalidMaxValue = maxValue && valueToNumber > maxValue;
    const isNotAllowedAfterZero = notAllowIfFirstNumZero && currentValue.length > 1 && +firstNumber === 0;

    if (isSpaceKey || isInvalidNumber || notAllowSymbols.includes(currentSymbol) || isInvalidLength
      || isInvalidMaxValue || isInvalidPositiveNumber || isNotAllowedAfterZero) {
      e.preventDefault();
    }
  };

  static typingFloatNumber = (e, maxValue, countOfDigitsAfterPoint) => {
    const notAllowSymbols = ['e', '+', '-'];
    const {
      data: currentSymbol,
      target: { value, selectionStart: startPosition, selectionEnd: endPosition },
      nativeEvent: { keyCode },
    } = e;

    const currentValue = value.substring(0, startPosition) + currentSymbol + value.substring(endPosition);

    const valueToNumber = +currentValue;
    const firstSymbol = currentValue.slice(0, 1);
    const secondSymbol = currentValue.slice(1, 2);
    const lengthAfterPoint = currentValue.split('.')?.[1]?.length;

    const isSpaceKey = keyCode === 32;
    const isInvalidNumber = Number.isNaN(valueToNumber);
    const isInvalidMaxValue = maxValue && valueToNumber > maxValue;
    const isInvalidZeroNumberFormat = currentValue.length > 1 && +firstSymbol === 0 && secondSymbol !== '.';
    const isInvalidCountOfDigitsAfterDot = countOfDigitsAfterPoint && lengthAfterPoint > countOfDigitsAfterPoint;

    if (isSpaceKey || isInvalidNumber || isInvalidMaxValue || isInvalidZeroNumberFormat
      || isInvalidCountOfDigitsAfterDot || notAllowSymbols.includes(currentSymbol)) {
      e.preventDefault();
    }
  };

  static typingPhoneNumber = (e) => {
    const {
      data: currentSymbol,
      target: { value, selectionStart: startPosition, selectionEnd: endPosition },
    } = e;

    const currentValue = value.substring(0, startPosition) + currentSymbol + value.substring(endPosition, value.length);

    if (!/^\+?\d{0,15}$/.test(currentValue)) {
      e.preventDefault();
    }
  };

  static onBeforeInput = (e, validation, maxLength) => {
    if (validation === 'phone') {
      this.typingPhoneNumber(e);
    } else if (validation === 'number' || validation === 'positiveNumber') {
      this.typingNumber(e, validation === 'positiveNumber', maxLength);
    } else if (validation === 'currency') {
      this.typingFloatNumber(e, null, 2);
    } else if (validation === 'rate') {
      this.typingNumber(e, true, 3, 100);
    }
  };

  static objectToQueryString = (object) => {
    const arrayWithQuery = Object.entries(object)
      .filter((param) => !!param[1])
      .map((param) => param.join('='));

    return `?${arrayWithQuery.join('&')}`;
  };

  static authWindow = (url, redirectUrl) => new Promise((resolve) => {
    const height = 570;
    const width = 520;
    const configs = {
      location: 'yes',
      scrollbars: 'yes',
      status: 'yes',
      height,
      width,
      top: (window.screen.height / 2) - (height / 2),
      left: (window.screen.width / 2) - (width / 2),
    };
    const configStr = _.map(configs, (value, key) => `${key}=${value}`).join(',');

    const popup = window.open(url, '_blank', configStr);

    const handleWindowClose = () => {
      resolveData(null);
    };

    const resolveData = (data) => {
      clearInterval(interval);
      popup.removeEventListener('close', handleWindowClose);
      window.removeEventListener('beforeunload', handleWindowClose);
      popup?.close();
      resolve(data);
    };

    const interval = setInterval(() => {
      try {
        const { href } = popup.location;
        if (href.startsWith(redirectUrl)) {
          resolveData(href);
        }
      } catch {
        //
      }
    }, 500);

    popup.addEventListener('close', handleWindowClose);
    window.addEventListener('beforeunload', handleWindowClose);
  });

  static getAppIcon = (app, returnSVG) => {
    const Icon = shopIcons[_.kebabCase(app.shopType || app.settings?.type || app.settings?.carrier
        || (app.type === 'cartrover' ? app.settings?.name : app.type))]
      || shopIcons.eswap;

    if (returnSVG) {
      return <Icon />;
    }

    return Icon;
  };

  static getAppName = (app) => {
    const appName = app.settings?.carrier?.replace('_', ' ')
      || (app.settings?.type === 'b2b'
        ? 'B2B'
        : app.settings?.type)

      || (app.type === 'cartrover'
        ? `${app.type} ${app.settings?.name}`
        : app.type);

    return appName;
  };

  static formattingMinutesToHours = (minutes) => `${Math.floor(minutes / 60)}h ${minutes % 60}min`;

  static arrayToString = (object) => {
    const obj = _.cloneDeep(object);

    for (const propName in obj) {
      if ((typeof obj[propName] !== 'boolean' && typeof obj[propName] !== 'number')
        && (typeof obj[propName] === 'object' ? _.isEmpty(obj[propName]) : !obj[propName]?.trim())) {
        delete obj[propName];
      }

      if (_.isArray(obj[propName])) {
        obj[propName] = obj[propName].join(',');
      }
    }

    return obj;
  };

  static filterData(data, search, searchKey, uniqBy = 'id') {
    if (!data) {
      return [];
    }
    return _.uniqBy(data.filter((i) => (i?.[searchKey]?.trim()
      .toLowerCase()
      .indexOf(search?.toLowerCase()) > -1)), uniqBy);
  }

  static formatAddress(address) {
    if (!address) {
      return null;
    }
    return address.filter((d) => d).join(', ');
  }

  static formatStatus(status) {
    return {
      partial_delivered: 'Partially delivered',
      partial_shipped: 'Partially shipped',
      partial_paid: 'Partially paid',
      partial_refunded: 'Partially refunded',
    }[status] || _.upperFirst(_.startCase(status));
  }

  static formatPurchaseStatus(status) {
    return this.formatStatus(status).replace('delivered', 'received').replace('Delivered', 'Received');
  }

  static customDelimitedArrayParam(entrySeparator = ',') {
    return {
      encode: (d) => encodeDelimitedArray(d, entrySeparator),
      decode: (d) => decodeDelimitedArray(d, entrySeparator),
    };
  }

  static getDeviceId() {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = uuidV7();
      localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
  }

  static getCreditNoteStatus(credit, refunded, applied) {
    if ((refunded + applied) >= credit) {
      return 'refunded';
    }
    if ((refunded && refunded < credit) || (applied && applied < credit)) {
      return 'partially_refunded';
    }
    return 'pending';
  }

  static canRefundCreditNote(creditNote) {
    return (+creditNote.refunded < +creditNote.credit && !creditNote.applied)
      || ((+creditNote.applied + +creditNote.refunded) < +creditNote.credit);
  }
}

export default Utils;
