import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { v7 as uuidV7 } from 'uuid';
import Button from './Button';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/google.svg';
import Api from '../../../Api';
import { loginGoogleOauth } from '../../../store/actions/users';

const scopes = ['openid',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/user.phonenumbers.read'];

const GoogleButton = ({
  title, signup, setData, setStep, setFromGoogleOauth,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const login = useGoogleLogin({
    flow: 'implicit',
    scope: scopes.join(' '),
    ux_mode: 'popup',
    include_granted_scopes: true,
    onSuccess: async (resp) => {
      const respScopes = resp.scope.split(' ');

      if (scopes.every((s) => respScopes.includes(s))) {
        if (signup) {
          setLoading(true);

          try {
            const { data: { authData } } = await Api.signupGoogleOauth({ accessToken: resp.access_token });

            setData((prev) => ({
              ...prev,
              firstName: authData.firstName,
              lastName: authData.lastName,
              email: authData.email,
              image: authData.image,
              password: uuidV7(),
              company: {
                ...prev.company,
                phone: authData.phone,
                city: authData.city,
                countryCode: authData.countryCode,
              },
            }));

            setStep(2);
            setFromGoogleOauth(true);
          } catch (e) {
            toast.error('Please try again later.');
          }

          setLoading(false);
        } else {
          const { payload } = await dispatch(loginGoogleOauth({ accessToken: resp.access_token }));

          if (payload.status === 'error') {
            toast.error('Account does not exist!');
          } else {
            navigate('/');
          }
        }
      } else {
        toast.error('Please select all accesses');
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });
  return (
    <div>
      <Button color="#DFE6F0" className="google_btn" roundBorder size="small" onClick={login} loading={loading}>
        <GoogleIcon className="google_icon" />

        <p className="google_btn_text">
          {title}
        </p>
      </Button>
    </div>
  );
};

GoogleButton.propTypes = {
  title: PropTypes.string,
  signup: PropTypes.bool,
  setData: PropTypes.func,
  setStep: PropTypes.func,
  setFromGoogleOauth: PropTypes.func,
};

GoogleButton.defaultProps = {
  title: 'Log in with Google',
  signup: false,
  setData: () => {},
  setStep: () => {},
  setFromGoogleOauth: () => {},
};

export default GoogleButton;
