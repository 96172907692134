import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import _ from 'lodash';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/notifications.svg';
import { ReactComponent as NotFoundIcon } from '../../assets/icons/notFound/notification_not_found.svg';
import { getNotificationsRequest } from '../../store/actions/notifications';
import Loader from '../_common/Loader/Loader';
import Button from '../_common/Form/Button';

const tabs = [{
  title: 'All',
},
{
  title: 'Errors',
}];

const Notifications = () => {
  const dispatch = useDispatch();

  const { type, list, status } = useSelector((state) => state.notifications.notifications);

  const ref = useRef(null);
  const [isOpen, openingToggle] = useState(false);

  useClickAway(ref, () => {
    openingToggle(false);
  });

  useEffect(() => {
    if (isOpen) {
      dispatch(getNotificationsRequest(type));
    }
  }, [isOpen]);

  const setTab = (currentType) => {
    dispatch(getNotificationsRequest(currentType));
  };

  return (
    <div ref={ref} className="auth_layout_content_header_right_block_notifications">
      <div
        className="auth_layout_content_header_right_block_notifications_icon"
        onClick={() => openingToggle((prev) => !prev)}
      >
        <NotificationsIcon />

        {/* <div /> */}
      </div>

      {isOpen && (
        <div className="auth_layout_content_header_right_block_notifications_tabs_wrapper">
          <h2>
            Notifications
          </h2>

          <div className="auth_layout_content_header_right_block_notifications_tabs">
            {tabs.map(({ title }) => (
              <div
                key={title}
                className={classNames(
                  'auth_layout_content_header_right_block_notifications_tabs_tab',
                  { active: type === title },
                )}
                onClick={() => setTab(title)}
              >

                <div>
                  {title}
                </div>
              </div>
            ))}
          </div>

          {status !== 'ok' && <Loader />}

          <div className="auth_layout_content_header_right_block_notifications_list">
            {list.length
              ? (
                <>
                  { list.map((l) => (
                    <div key={l._id} className="auth_layout_content_header_right_block_notifications_list_notification">
                      <h3>
                        {moment(l.createdAt)
                          .format('MMM DD YYYY, hh:mmA')}
                      </h3>

                      <p>
                        {_.upperFirst(l.message)}
                      </p>
                    </div>
                  ))}

                  <Button
                    href={`/logs?force=1${type === 'Errors' ? '&level=error' : ''}`}
                    color="#1472FF"
                    btnType="transparent"
                    type="submit"
                    className="notifications_view_all"
                    onClick={() => openingToggle(false)}
                  >
                    View all
                  </Button>
                </>
              )

              : (
                <div className="notifications_no_result">
                  <NotFoundIcon />
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

Notifications.propTypes = {};

export default Notifications;
