import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import Button from '../Form/Button';

const WarningModal = memo(({
  isOpen, onClose, zIndex, className, text, loading, onSaveClick, btnText,
}) => (
  <Modal
    className={classNames('warning_modal_wrapper', className)}
    isOpen={isOpen}
    onClose={() => !loading && onClose()}
    zIndex={zIndex}
  >
    <div className="warning_modal_block">
      <div className="warning_modal_delete_icon_wrapper">
        <WarningIcon />
      </div>

      <h2>
        Warning alert
      </h2>

      <div className="warning_modal_text">
        <p>{text}</p>
      </div>

      {onSaveClick && (
      <div className="warning_modal_buttons_block">
        <Button
          onClick={onClose}
          size="small"
          roundBorder
          btnType="cancel"
          title="Cancel"
        />

        <Button
          onClick={onSaveClick}
          loading={loading}
          size="small"
          roundBorder
          title={btnText}
        />
      </div>
      )}
    </div>
  </Modal>
));

WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  text: PropTypes.string,
  btnText: PropTypes.string,
  zIndex: PropTypes.number,
  loading: PropTypes.bool,
  onSaveClick: PropTypes.func,
};

WarningModal.defaultProps = {
  className: '',
  text: '',
  btnText: '',
  zIndex: 150,
  onClose: null,
  onSaveClick: null,
  loading: false,
};

export default WarningModal;
