import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from '../Loader/Loader';
import Checkbox from '../Form/Checkbox';

function CheckboxTable({
  header, data, loading, tableTitle, onChange, defaultScopes, checkedData,
}) {
  return (
    <div className="table_wrapper">
      {loading && <Loader />}

      {data.length
        ? (
          <>
            <h3 className="table_title">{tableTitle}</h3>

            <table className="table">
              <thead className="table_thead">
                <tr className="checkbox_table_thead_tr">
                  <th className="checkbox_table_thead_tr_empty" />
                  <th className="checkbox_table_thead_tr_th">
                    <span>
                      Full access
                    </span>
                  </th>

                  {header.map((h) => (
                    <th
                      key={h.title}
                      className={classNames(
                        'checkbox_table_thead_tr_th',
                        {
                          index: h.path === 'index',
                          status: h.path.toLowerCase().includes('status'),
                          selectable: h.isSelectable,
                        },
                      )}
                    >
                      <span>
                        {h.title}
                      </span>
                    </th>
                  ))}

                  <th />
                </tr>
              </thead>

              <tbody className="table_tbody">
                {data.map(({ label, list, group }) => {
                  const checkedListFields = list.filter((f) => checkedData.includes(f));
                  const isFullAccessChecked = checkedListFields.length === list.length;

                  return (
                    <tr
                      className="table_tbody_tr"
                      key={label}
                    >
                      <td className="checkbox_table_tbody_tr_field_name">
                        {label}
                      </td>

                      <td>
                        <span className="checkbox_table_tbody_tr_td">
                          <Checkbox
                            checked={isFullAccessChecked}
                            onChange={(checked) => onChange(label, 'FULL_ACCESS', group, checked, checkedListFields)}
                          />
                        </span>
                      </td>

                      {header.map(({ path }) => {
                        const foundPath = list.find((l) => l.includes(path));

                        const isChecked = defaultScopes.includes(foundPath) || checkedData.includes(foundPath);

                        return (
                          <td
                            key={path}
                          >
                            <span className="checkbox_table_tbody_tr_td">
                              <Checkbox
                                checked={isChecked}
                                onChange={(checked) => !defaultScopes.includes(foundPath)
                                  && onChange(label, foundPath, group, checked)}
                                disabled={(!list.includes(foundPath) && path !== 'FULL_ACCESS')}
                                plus={defaultScopes.includes(foundPath)}
                              />
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )

        : <div className="table_no_result">No Data Found</div>}
    </div>
  );
}

CheckboxTable.propTypes = {
  header: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  tableTitle: PropTypes.string,
  defaultScopes: PropTypes.array,
  checkedData: PropTypes.array,
};

CheckboxTable.defaultProps = {
  loading: false,
  defaultScopes: [],
  checkedData: [],
  tableTitle: '',
};

export default CheckboxTable;
