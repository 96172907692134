import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Wrapper from '../components/Layout/Wrapper';
import Loader from '../components/_common/Loader/Loader';
import { ReactComponent as AvatarIcon } from '../assets/icons/avatar.svg';
import Api from '../Api';
import ProfileChannels from '../components/pages/ProfileTables/ProfileChannels';
import ProfileLogs from '../components/pages/ProfileTables/ProfileLogs';
import ProfileInvoices from '../components/pages/ProfileTables/ProfileInvoices';
import Tabs from '../components/_common/Tabs/Tabs';
import SubscriptionCard from '../components/pages/Settings/PlansBillings/SubscriptionCard';
import Account from '../helpers/Account';
import ProfileImportSettings from '../components/pages/ProfileTables/ProfileImportSettings';

const profileTabs = [
  {
    name: 'Channels',
    component: <ProfileChannels />,
  },
  {
    name: 'Error logs',
    component: <ProfileLogs type="error,warn" />,
  },
  {
    name: 'Success logs',
    component: <ProfileLogs type="info" />,
  },
  {
    name: 'Invoices',
    component: <ProfileInvoices />,
  },
];

function Profile() {
  const { userId } = useParams();

  //   const { userId } = Account.getUserId();

  const navigate = useNavigate();
  const firstLoading = useRef(true);

  const profile = useSelector((state) => state.users.profile);
  const status = useSelector((state) => state.users.status);

  const [company, setCompany] = useState({});
  const [visitedUser, setVisitedUser] = useState({});
  const [userSubscription, setUserSubscription] = useState({});
  const [activeTable, setActiveTable] = useState('Channels');

  const { isAdmin } = profile;

  useEffect(() => {
    (async () => {
      if (userId && isAdmin) {
        const [user, subscription] = await Promise.all([
          Api.getAccount(userId), Api.getCurrentSubscription(userId),
        ]);

        setUserSubscription(subscription.data.data);
        setVisitedUser(user.data.user);
      } else {
        const { data } = await Api.getAccount();
        setVisitedUser(data.user);
        navigate('/profile', { replace: true });
      }

      const { data } = await Api.getCompanies(userId ? { userId } : {});

      setCompany(data.companies?.[0] || {});

      firstLoading.current = false;
    })();
  }, []);

  const allTabs = useMemo(() => {
    if (isAdmin) {
      return [
        ...profileTabs,
        {
          name: 'Debug logs',
          component: <ProfileLogs type="debug" />,
        },
        {
          name: 'Import settings',
          component: <ProfileImportSettings />,
        },
      ];
    }

    return profileTabs;
  }, [userId]);

  const onTabChange = useCallback((name) => {
    setActiveTable(name);
  }, [activeTable]);

  const onEditSubscription = useCallback(() => {
    navigate(`/users/edit/${userId}/subscription`);
  }, []);

  return (
    <Wrapper
      title="Profile"
      onBtnClick={() => { navigate('/profile/update'); }}
      buttonTitle="Edit profile"
      className="profile_wrapper"
    >
      {status === 'pending' || firstLoading.current ? <Loader /> : (
        <div className="profile_block">
          <div className="profile_top">
            <div className="profile_user_img_wrp">
              {visitedUser.image
                ? <img src={visitedUser.image} className="profile_user_img" alt="avatar" /> : <AvatarIcon />}
            </div>

            <div className="profile_name_block">
              <p className="profile_user_name">{`${visitedUser.firstName} ${visitedUser.lastName}`}</p>

              <p>{visitedUser.email}</p>

              <p className="profile_created_at">
                <span>Joined at:</span>
                {moment(visitedUser.createdAt).format('Do MMMM YYYY')}
              </p>

              <p className="profile_created_at">
                {visitedUser.isOnline ? <p>Online</p> : (
                  !!userId && (
                    <>
                      <span>Last login:</span>
                      {moment(visitedUser.lastLoginAt)
                        .format('Do MMMM YYYY')}
                    </>
                  ))}
              </p>
            </div>
          </div>

          <div className="company_info">
            <div className="company_info_left">
              <div className="company_single_info">
                <p>Country/Region</p>

                {!_.isEmpty(company) ? (
                  <div>
                    <span>
                      <img src={`/flags/${company.country.code.toLowerCase()}.svg`} alt="flag" />
                    </span>

                    {company.country.name}
                  </div>
                ) : '-'}
              </div>

              <div className="company_single_info">
                <p>Address</p>

                {!_.isEmpty(company) ? <div>{`${company.city}, ${company.address} ${company.postalCode}`}</div> : '-'}
              </div>

              <div className="company_single_info">
                <p>Phone</p>

                {!_.isEmpty(company) ? <div>{company.phone}</div> : '-'}
              </div>
            </div>

            {!!userId && isAdmin && (
            <div className="subscription_info_block">
              <SubscriptionCard
                title="Current plan"
                onClick={onEditSubscription}
                btnTitle="Edit subscription"
                reverseButton
              >
                <div className="subscription_card_single">
                  <p className="subscription_card_title">Plan name</p>

                  <p className="subscription_card_data">{_.upperFirst(userSubscription.subscription.planName)}</p>
                </div>

                <div className="subscription_card_single">
                  <p className="subscription_card_title">Billing cycle</p>

                  <p className="subscription_card_data">{_.upperFirst(userSubscription.subscription.interval)}</p>
                </div>

                <div className="subscription_card_single">
                  <p className="subscription_card_title">Plan cost</p>

                  <p className="subscription_card_data">{`${_.upperFirst(userSubscription.subscription.price)}$`}</p>
                </div>

                <div className="subscription_card_single">
                  <p className="subscription_card_title">Next payment</p>

                  <p className="subscription_card_data">{moment(userSubscription.reNewDate).format('MM/DD/YY')}</p>
                </div>
              </SubscriptionCard>
            </div>
            )}
          </div>

          <div className="company_tables">
            <Tabs tabs={allTabs} currentTab={activeTable} onChangeTab={onTabChange} />
          </div>
        </div>
      )}
    </Wrapper>
  );
}

export default Profile;
