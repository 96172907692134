import { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../../../Api';

const ShipBobIntegration = () => {
  const { app } = useParams();

  const navigate = useNavigate();

  const [authorizationCode] = useQueryParam('authorizationCode', StringParam);

  useEffect(() => {
    (async () => {
      try {
        const {
          integrationId, integrationName, shop, storeId,
        } = JSON.parse(localStorage.getItem(`${app}IntegrationInfo`)) || {};

        try {
          const sendingData = {
            integrationName,
            shop,
            storeId,
            authorizationCode,
          };

          if (integrationId) sendingData.integrationId = integrationId;

          const { data: { integration: { id } } } = await Api.createIntegration(app, sendingData);

          navigate(integrationId ? `/my-apps/${id}/dashboard` : `/apps/integration/${id}`, { replace: true });
        } catch (err) {
          toast.error(err.response.data.message);
          navigate('/apps/list/all', { replace: true });
        }
      } catch (e) {
        navigate('/404', { replace: true });
      }

      localStorage.removeItem(`${app}IntegrationInfo`);
    })();
  }, []);

  return null;
};

export default ShipBobIntegration;
