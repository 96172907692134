import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { toast } from 'react-toastify';
import { components } from 'react-select';
import StatusField from '../StatusField/StatusField';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow_sort.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/import.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning_red.svg';
import CompanyLogo from '../../../assets/icons/company_logo.svg';
import Checkbox from '../Form/Checkbox';
import Button from '../Form/Button';
import Loader from '../Loader/Loader';
import DeleteModal from '../Modal/DeleteModal';
import Menu from '../Menu/Menu';
import Utils from '../../../helpers/Utils';
import Input from '../Form/Input';
import Select from '../Form/Select';
import noImage from '../../../assets/icons/default.svg';

const logoFields = ['logo'];
const titleFields = ['title', 'name'];
const blueFields = [...titleFields, 'number', 'item', 'firstName', 'label'];
const dateFields = ['createdAt', 'updatedAt', 'lastLoginAt', 'invoiceDate', 'date', 'returnDate',
  'updatedDate', 'issueDate', 'shippedDate', 'receiveDate'];
const dividedFields = ['amountPaid', 'subtotal', 'totalPrice', 'cost'];
const productTypeFields = ['bundle', 'variant'];

const defaultLogo = {
  user: CompanyLogo,
  company: CompanyLogo,
  shop: CompanyLogo,
};

// const errors = [];
//
// const onChange = (id, path, value) => {
//   const foundError = errors.find((err) => err.id === id);
//
//   if (foundError) {
//     foundError[path] = true;
//   } else {
//     errors.push({ id, [path]: true });
//   }
// };

// const errors = {};
// const onChange = (id, path, value) => {
//   errors[id] = { ...(errors[id] || {}), [path]: true };
// };

const Table = memo(({
  data, header, onEditClick, onDeleteClick, onDownloadClick, onCheck, checkedItems, sortBy, sort, onSortBy, loading,
  defaultLogoKey, deleteLoading, deleteModalText, menuOptions, onMenuSelect, onChange, onClick, errors,
  disableWhenStatusFalse, hideDeleteButtonFields, onHeaderChange, productType, notFound, notFoundClassName,
  lineErrorData, menuLoadingIds, editScope, deleteScope
}) => {
  const [deletingId, setDeletingId] = useState('');

  useEffect(() => {
    if (typeof deleteLoading !== 'undefined' && !deleteLoading) setDeletingId('');
  }, [deleteLoading]);

  const onDeleteItem = useCallback(() => {
    onDeleteClick(deletingId);

    if (typeof deleteLoading === 'undefined') setDeletingId('');
  }, [deletingId]);

  const onSort = useCallback((currentSortBy) => {
    const newSortType = { sortBy: currentSortBy, sort: 'asc' };

    if (sortBy === currentSortBy) {
      newSortType.sort = sort === 'desc' ? 'asc' : 'desc';
    }

    onSortBy(newSortType);
  }, [sort, sortBy]);

  const onCheckItem = (id) => {
    let newCheckedItems = [...checkedItems, id];

    if (id === 'all') {
      if (data.length === checkedItems.length) {
        newCheckedItems = [];
      } else {
        newCheckedItems = data.map((d) => d.id);
      }
    } else if (checkedItems.includes(id)) {
      newCheckedItems = checkedItems.filter((p) => p !== id);
    }

    onCheck(newCheckedItems);
  };

  const MenuList = useCallback((props, addButtonText, onAddBtnClick) => (
    <components.MenuList {...props}>
      {props.children}

      {addButtonText && (
        <Button
          btnType="transparent"
          size="small"
          color="#1472FF"
          roundBorder
          addBtn
          className="add_new_reason"
          onClick={onAddBtnClick}
        >
          {addButtonText}
        </Button>
      )}
    </components.MenuList>
  ), []);

  return (
    <div className="table_wrapper">
      {data?.length
        ? (
          <table className="table">
            {loading && <Loader />}

            <thead className="table_thead">
            <tr className="table_thead_tr">
              {onCheck && (
                <th className="table_thead_tr_th checkbox">
                  <Checkbox
                    checked={checkedItems.length === data.length}
                    indeterminate={checkedItems.length}
                    onChange={() => onCheckItem('all')}
                  />
                </th>
              )}

              {header.map((h) => (
                <th
                  key={h.title}
                  className={classNames(
                    'table_thead_tr_th',
                    {
                      index: h.path === 'index',
                      status: h.path.toLowerCase().includes('status'),
                      selectable: h.isSelectable,
                      logo: logoFields.includes(h.path),
                      field: h.headerType,
                    },
                  )}
                >
                  {h.headerType === 'select'
                    ? (
                      <Select
                        value={h.value}
                        label={h.title}
                        onChange={(value) => onHeaderChange(h.path, value)}
                        placeholder={h.placeholder}
                        options={h.options}
                        valuePath={h.valuePath}
                        labelPath={h.labelPath}
                        isClearable={h.isClearable}
                        isSearchable={h.isSearchable}
                        styles={{
                          option: (base, { data: { value } }) => ({
                            ...base,
                            color: h.options.find((o) => o?.required && o[h.valuePath || 'value'] === value)
                              ? '#EB0000' : 'inherit',
                          }),
                        }}
                        fieldset
                        menuPosition="fixed"
                        getFullOption={h.getFullOption}
                        size="small"
                      />
                    )

                    : (
                      <span
                        role="button"
                        tabIndex="0"
                        onClick={() => h.isSelectable && onSort(h.path)}
                      >
                          {h.title}

                        <span className="table_thead_tr_th_arrow">
                            {h.isSelectable && h.path === sortBy && (
                              <ArrowIcon className={classNames({ desc: sort === 'desc' })} />
                            )}
                          </span>
                        </span>
                    )}
                </th>
              ))}

              {(onEditClick || onDeleteClick || onDownloadClick || onMenuSelect) && !!header.length && <th />}
            </tr>
            </thead>

            <tbody className="table_tbody">
            {data.map((d, index) => (
              <tr
                className={`table_tbody_tr ${disableWhenStatusFalse && !d.status ? 'disable_row' : ''}`}
                key={d.id || index}
              >
                {onCheck && (
                  <th className="table_tbody_tr_td checkbox">
                    <Checkbox
                      checked={checkedItems.includes(d.id)}
                      onChange={() => onCheckItem(d.id)}
                    />
                  </th>
                )}

                {header.map((h, i) => (
                  <td
                    className={classNames(
                      'table_tbody_tr_td',
                      {
                        index: h.path === 'index',
                        blue: blueFields.includes(h.path),
                        date: dateFields.includes(h.path),
                        status: h.path.toLowerCase().includes('status'),
                        logo: logoFields.includes(h.path),
                        clickable: h.navigate,
                      },
                    )}
                    key={h.title}
                    data-scope={h.scope}
                    onClick={() => h.navigate?.(d.id || d)}
                  >
                    {(lineErrorData?.[index] && i === 0)
                      && <WarningIcon style={{ position: 'absolute', right: '10px', top: '18px' }} />}

                    {h.fieldType === 'input'
                      ? (
                        <Input
                          value={d[h.path]}
                          onChangeText={(value) => onChange(d.id, h.path, value)}
                          placeholder={h.placeholder}
                          error={errors?.[d.id]?.[h.path]}
                          size="small"
                          symbol={h.type === 'rate' ? '%' : ''}
                          onBeforeInput={(e) => Utils.onBeforeInput(e, h.validation, h.maxLength)}
                          disabled={disableWhenStatusFalse || d?.disabledKeys?.includes(h.path) || d.disabled}
                          tooltip={d?.tooltip?.[h.path]}
                          autoComplete="off"
                        />
                      )

                      : h.fieldType === 'select'
                        ? (
                          <Select
                            value={d[h.path]}
                            onChange={(value) => onChange(d.id, h.path, value)}
                            placeholder={h.placeholder}
                            options={h.options}
                            loading={d.loading}
                            isDisabled={d.disabled}
                            valuePath={h.valuePath}
                            labelPath={h.labelPath}
                            menuPosition="fixed"
                            error={errors?.[d.id]?.[h.path]}
                            getFullOption={h.getFullOption}
                            isOptionDisabled={(option) => option.disabled}
                            components={{
                              MenuList: (props) => MenuList(props, h.addButtonText, () => onChange(d.id, h.path, { addNew: true })),
                            }}
                            size="small"
                          />
                        )

                        : h.fieldType === 'button'
                          ? (
                            <Button
                              btnType="transparent"
                              onClick={() => onClick(d.id, h.path)}
                              color="#1472FF"
                              disabled={d.disabled}
                            >
                              {h.fieldTitle}
                            </Button>
                          )

                          : logoFields.includes(h.path)
                            ? (
                              <div className="table_tbody_tr_td_logo">
                                <img src={d[h.path] || defaultLogo[defaultLogoKey]} alt={h.title} />
                              </div>
                            )

                            : titleFields.includes(h.path)
                              ? (
                                <span>
                                  {d[h.path]}
                                  {d.isDefault && <span className="is_default">Default</span>}
                                  </span>
                              )

                              : h.path === 'shopType'
                                ? (
                                  <div className="table_tbody_tr_td_logo_shop_icon">
                                    {Utils.getAppIcon(d, true)}
                                  </div>
                                )

                                : h.path === 'productItem'
                                  ? (
                                    <div className="product_item_wrapper">
                                      <div className="product_item_img">
                                        <img
                                          src={d.images?.[0]?.src?._preview || d.images?.[0]?.src
                                            || d.images?.[0]?.small || d.images?.[0]?.medium || noImage}
                                          alt="img"
                                        />
                                      </div>

                                      <div className="product_item_title">
                                        <p>{d.title}</p>
                                      </div>
                                    </div>
                                  )

                                  : h.path.toLowerCase().includes('status')
                                    ? <StatusField status={d[h.path]} />

                                    : dateFields.includes(h.path)
                                      ? (d[h.path] ? moment(d[h.path]).format('MM/DD/YY, hh:mmA') : '-')

                                      : h.path === 'rate' ? `${d[h.path]}%`

                                        : h.path === 'index' ? index + 1

                                          : h.path === 'parentId' ? (d[h.path] === '0' ? 'Parent' : 'Child')

                                            : h.path === 'reachedTheLimits' ? (d[h.path] ? 'Yes' : 'No')

                                              : h.path === 'parents'
                                                ? (d[h.path]?.length ? d[h.path].map((p) => p.name).reverse()
                                                  .join(' / ') : '-')

                                                : h.path === 'discount' ? d.discountPrice

                                                  : h.path === 'color' ? (
                                                      <div
                                                        className="round_color_wrap"
                                                      >
                                                        <span
                                                          className="round_color"
                                                          style={{ backgroundColor: d.color }}
                                                        />
                                                        <span>{d.color}</span>
                                                      </div>
                                                    )

                                                    : dividedFields.includes(h.path) ? `$${(d[h.path] / 100).toFixed(2)}`

                                                      // : h.path === 'coupon' ? discountedPrice

                                                      : (d[h.path] || '-')}
                  </td>
                ))}

                {((onEditClick || onDeleteClick || onDownloadClick || onMenuSelect) && !!header.length && (
                  <td className="table_tbody_tr_td actions" key={d.id}>
                    <div>
                      {onDownloadClick && (!disableWhenStatusFalse || d.status) && (
                        <Button
                          tooltip="Download"
                          tooltipPlace="top-end"
                          btnType="transparent"
                          onClick={() => onDownloadClick(d.id)}
                        >
                          <DownloadIcon />
                        </Button>
                      )}

                      {onEditClick && (!disableWhenStatusFalse || d.status) && (
                        <Button data-scope={editScope} btnType="transparent" onClick={() => onEditClick(d.id)}>
                          <EditIcon />
                        </Button>
                      )}

                      {onDeleteClick && (!disableWhenStatusFalse || d.status)
                        && !hideDeleteButtonFields.includes(d.status) && (
                          <Button
                            btnType="transparent"
                            data-scope={deleteScope}
                            onClick={() => {
                              if (d.isDefault) {
                                toast.error(`You can't delete the default ${deleteModalText}`);
                              } else {
                                setDeletingId(d.id);
                              }
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        )}

                      {onMenuSelect && (!disableWhenStatusFalse || d.status) && (
                        <Menu
                          options={menuOptions.map((o) => ({ ...o, invert: d[o.path] }))}
                          onChange={(menuOption) => onMenuSelect(d.id, menuOption)}
                          loading={menuLoadingIds.includes(d.id)}
                        />
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
            </tbody>
          </table>
        )

        : (
          <div className={classNames('data_not_found', notFoundClassName)}>
            {loading && <Loader />}

            {notFound}
          </div>
        )}

      <DeleteModal
        isOpen={!!deletingId}
        onDelete={onDeleteItem}
        onClose={() => setDeletingId('')}
        loading={deleteLoading}
        text={deleteModalText}
      />
    </div>
  );
});

Table.propTypes = {
  header: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  checkedItems: PropTypes.array,
  sortBy: PropTypes.string,
  sort: PropTypes.oneOf(['asc', 'desc']),
  onCheck: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  onSortBy: PropTypes.func,
  defaultLogoKey: PropTypes.oneOf(['user', 'company', 'shop']),
  loading: PropTypes.bool,
  deleteModalText: PropTypes.string,
  notFoundClassName: PropTypes.string,
  deleteLoading: PropTypes.bool,
  lineErrorData: PropTypes.array,
  menuLoadingIds: PropTypes.array,
  disableWhenStatusFalse: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    value: PropTypes.string.isRequired,
  })),
  errors: PropTypes.shape({
    id: PropTypes.object,
  }),
  onMenuSelect: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onHeaderChange: PropTypes.func,
  hideDeleteButtonFields: PropTypes.array,
  notFound: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Table.defaultProps = {
  checkedItems: [],
  sortBy: '',
  sort: 'asc',
  onCheck: undefined,
  onEditClick: undefined,
  onDeleteClick: undefined,
  onDownloadClick: undefined,
  onMenuSelect: undefined,
  onSortBy: undefined,
  defaultLogoKey: 'user',
  notFound: <h1>No Data Found</h1>,
  loading: false,
  disableWhenStatusFalse: false,
  lineErrorData: [],
  menuLoadingIds: [],
  deleteModalText: '',
  notFoundClassName: '',
  deleteLoading: undefined,
  menuOptions: [],
  hideDeleteButtonFields: [],
  onChange: () => {
  },
  onClick: () => {
  },
  onHeaderChange: () => {
  },
  errors: {},
};

export default Table;
