import React from 'react';
import Input from '../../../_common/Form/Input';
import Select from '../../../_common/Form/Select';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/close.svg';
import Button from '../../../_common/Form/Button';

function NewPreset({ savePreset }) {
  return (
    <div>
      <h3 className="new_preset_title">New preset</h3>

      <Input
        label="Preset title"
        // value={value}
        // onChangeText={(val) => onChange(val)}
        size="small"
        roundBorder
      />

      <div className="new_preset_options">
        <h3>Add shipping options</h3>

        <div className="shipping_options">
          <div>Ship from</div>
          <div>Service</div>
          <div>Confirmation</div>
          <div>Weight</div>
        </div>
      </div>

      <div className="package_select">
        <Select
          label="Package"
          // options={currencies}
          roundBorder
          // valuePath="code"
          // labelPath="code"
          // value={generalList.currencyCode}
          // onChange={(code) => changeGeneralSettings('currencyCode', code)}
          size="small"
        />

        <DeleteIcon />
      </div>

      <div className="inputs_block">
        <Input
          label="Length (in)"
          // value={value}
          // onChangeText={(val) => onChange(val)}
          size="small"
          roundBorder
        />

        <Input
          label="Width (in)"
          // value={value}
          // onChangeText={(val) => onChange(val)}
          size="small"
          roundBorder
        />

        <Input
          label="Height (in)"
          // value={value}
          // onChangeText={(val) => onChange(val)}
          size="small"
          roundBorder
        />

      </div>

      <div className="cancel_save_but">
        <Button
          title="Cancel"
          size="small"
          roundBorder
          btnType="cancel"
          reverseColor
          // onClick={onClose}
        />

        <Button
          title="Save preset"
          size="small"
          roundBorder
          onClick={savePreset}
        />
      </div>

    </div>
  );
}

export default NewPreset;
