import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import RangeDatePicker from '../../../../_common/Form/RangePickerRange';
import Select from '../../../../_common/Form/Select';
import Button from '../../../../_common/Form/Button';
import Api from '../../../../../Api';
import Checkbox from '../../../../_common/Form/Checkbox';

const defaultData = {
  startDate: moment().subtract(1, 'month').format('MM-DD-YYYY'),
  endDate: moment().format('MM-DD-YYYY'),
  warehouseIds: [],
  disabled: false,
};

function InventoryHistoricReport({ closeModal }) {
  const [historicData, setHistoricData] = useState(defaultData);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await Api.getWarehouses();

      setWarehouseOptions(data.warehouses);
    })();

    return () => setHistoricData(defaultData);
  }, []);

  const loadMoreWarehouses = useCallback(async (inputValue, callback) => {
    const { data } = await Api.getWarehouses({ s: inputValue });

    callback(data.warehouses);
  }, []);

  const onExport = useCallback(async () => {
    await Api.historicExport({
      by: {
        disabled: false,
        startDate: historicData.startDate,
        endDate: historicData.endDate,
        warehouseIds: historicData.warehouseIds.map((w) => w.id),
      },
      fields: ['brand', 'costTotal', 'costPrice', 'inStock', 'productType', 'sku', 'productName'],
      fileType: 'xlsx',
    });

    toast.success('Successfully exported');
    closeModal();
  }, [historicData]);

  return (
    <div className="inventory_movement_report">
      <h3>Export stock movement report</h3>

      <div className="inventory_movement_report_select_wrp">
        <RangeDatePicker
          currentDate={{
            startDate: historicData.startDate || defaultData.startDate,
            endDate: historicData.endDate || defaultData.endDate,
          }}
          onChangeDate={(value) => setHistoricData((prev) => ({
            ...prev,
            startDate: value[0],
            endDate: (value[0] > value[1] || !value[1]) ? value[0] : value[1],
          }))}
          calendarClassName="inventory_report_filters_menu_calendar"
          popperPlacement="bottom-start"
          portalId="root"
          menuWrapperClassName="inventory_report_historic_calendar"
        />
      </div>

      <div className="historic_report_select_wrp">
        <Select
          value={historicData.warehouseIds}
          valuePath="id"
          isAsync
          defaultOptions={warehouseOptions}
          size="small"
          roundBorder
          loadOptions={(inputValue, callback) => loadMoreWarehouses(inputValue, callback)}
          onChange={(val) => setHistoricData((prev) => ({ ...prev, warehouseIds: val }))}
          getFullOption
          labelPath="title"
          isMulti
          isClearable
          label="Location"
          menuPlacement="top"
        />
      </div>

      <Checkbox
        label="Include disabled products"
        checked={historicData.disabled}
        onChange={(checked) => setHistoricData((prev) => ({ ...prev, disabled: checked }))}
      />

      <div className="inventory_movement_report_buttons">
        <Button
          className="inventory_movement_report_cancel_btn"
          title="Cancel"
          btnType="cancel"
          size="small"
          roundBorder
          onClick={closeModal}
        />

        <Button
          className="inventory_movement_report_download_btn"
          title="Export"
          size="small"
          roundBorder
          onClick={onExport}
        />
      </div>
    </div>
  );
}

export default InventoryHistoricReport;
