import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Select from '../../../_common/Form/Select';
import Input from '../../../_common/Form/Input';
import Table from '../../../_common/Tables/Table';
import { getDashboardBySalesChannelsRequest } from '../../../../store/actions/dashboard';
import Loader from '../../../_common/Loader/Loader';
import { ReactComponent as DragIcon } from '../../../../assets/icons/dashboard_drag.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/dashboard_close.svg';

const filterButtons = [
  {
    label: 'All',
    key: '',
    value: '',
    width: 50,
  },
  {
    label: 'Shipped',
    key: 'shipped',
    value: 'shipped',
    width: 100,
  },
  {
    label: 'Pending',
    key: 'pending',
    value: 'pending',
    width: 100,
  },
  {
    label: 'Partial shipped',
    key: 'partial_shipped',
    value: 'partial_shipped',
    width: 100,
  },
  {
    label: 'Delivered',
    key: 'delivered',
    value: 'delivered',
    width: 100,
  },
  {
    label: 'Partial delivered',
    key: 'partial_delivered',
    value: 'partial_delivered',
    width: 100,
  },
  {
    label: 'Canceled',
    key: 'canceled',
    value: 'canceled',
    width: 100,
  },
];

function DashboardBySalesChannels({
  data, searchData, setSearchData, disable, onDelete, innerRef, latestSalesData, startDate, endDate,
}) {
  const navigate = useNavigate();
  const timeout = useRef();
  const firstLoad = useRef(true);
  const dispatch = useDispatch();

  const bySalesChannels = useSelector((store) => store.dashboard.bySalesChannels);
  const shops = useSelector((store) => store.dashboard.shops || []);

  const channelOption = useMemo(() => [{ id: '', name: 'All shops' }, ...shops], [shops]);

  const [searchLoading, setSearchLoading] = useState(true);

  const header = [
    {
      title: 'Order Id',
      path: 'orderId',
      navigate: (id) => navigate(`/orders/sales-order/preview/${id}`),
    },
    {
      title: 'Customer',
      path: 'customer',
    },
    {
      title: 'Shipment status',
      path: 'status',
    },
    {
      title: 'Payment status',
      path: 'paymentStatus',
    },
    {
      title: 'Total',
      path: 'totalPrice',
    },
  ];

  useEffect(() => {
    (async () => {
      setSearchLoading(true);

      await dispatch(getDashboardBySalesChannelsRequest({ startDate, endDate, ...latestSalesData }));

      firstLoad.current = false;
      setSearchLoading(false);
    })();
  }, [startDate, endDate]);

  const onChange = useCallback(async (path, value) => {
    setSearchData((prev) => ({
      ...prev,
      [path]: value,
    }));

    if (path === 'byChannelSearch') {
      clearTimeout(timeout.current);

      timeout.current = setTimeout(async () => {
        setSearchLoading(true);

        await dispatch(getDashboardBySalesChannelsRequest({
          ...searchData, [path]: value, startDate, endDate,
        }));

        setSearchLoading(false);
      }, 500);
    } else {
      setSearchLoading(true);

      await dispatch(getDashboardBySalesChannelsRequest({
        ...searchData, [path]: value, startDate, endDate,
      }));
      setSearchLoading(false);
    }
  }, [startDate, endDate]);

  return (
    <div
      ref={(ref) => {
        innerRef[data?.key] = ref || innerRef[data?.key];
      }}
      className="grid-stack-item"
      gs-max-w="12"
      gs-min-w="4"
      gs-max-h="4"
      gs-min-h="2"
      gs-id={data?.key}
      gs-w={data?.value?.position?.w}
      gs-h={data?.value?.position?.h}
      gs-x={data?.value?.position?.x}
      gs-y={data?.value?.position?.y}
      style={{ display: data?.show === false ? 'none' : 'block' }}
    >
      <div className="grid-stack-item-content">
        <div className="chart_wrapper" style={{ cursor: disable ? 'grab' : 'default' }}>
          {firstLoad.current && searchLoading ? <Loader /> : (
            <div className="charts_latest_orders">
              <div className="charts_latest_orders_top">
                <h3 className="charts_latest_orders_title">Latest sales orders - By sales channels</h3>

                {!!shops.length && !disable && (
                  <div className="charts_latest_orders_top_select_block">
                    <div className="charts_latest_orders_top_select">
                      <Select
                        options={filterButtons}
                        onChange={(val) => onChange('status', val)}
                        size="small"
                        roundBorder
                        value={searchData.status}
                      />
                    </div>

                    <div className="charts_latest_orders_top_select">
                      <Select
                        options={channelOption}
                        onChange={(val) => onChange('channel', val)}
                        value={searchData.channel}
                        valuePath="id"
                        labelPath="name"
                        size="small"
                        roundBorder
                        placeholder="Channels"
                      />
                    </div>
                  </div>
                )}

                {disable && (
                  <div className="drag_icons">
                    <DragIcon />

                    <CloseIcon onClick={onDelete} />
                  </div>
                )}
              </div>

              {!disable && (
                <div className="charts_latest_orders_filters">
                  <div className="charts_latest_orders_search">
                    <Input
                      size="small"
                      roundBorder
                      search
                      placeholder="Search..."
                      onChangeText={(val) => onChange('byChannelSearch', val)}
                      value={searchData.byChannelSearch}
                    />

                    {/* {searchLoading && <Loader size={18} className="search_loader" />} */}
                  </div>
                </div>
              )}

              <div className="charts_latest_orders_table">
                <Table
                  data={bySalesChannels}
                  header={header}
                  loading={searchLoading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardBySalesChannels;
