import React, { useEffect, useState } from 'react';
import { format } from 'currency-formatter';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import Button from '../../../_common/Form/Button';
import StatusField from '../../../_common/StatusField/StatusField';
import { generateInvoiceRequest, getOrdersInvoicesRequest } from '../../../../store/actions/invoices';
import Loader from '../../../_common/Loader/Loader';

const InvoiceCreate = ({ closeModal, orders }) => {
  const dispatch = useDispatch();
  const allOrdersInvoices = useSelector((state) => state.invoices.allOrdersInvoices);

  const [loading, setLoading] = useState(false);
  const [convertBtnLoading, setConvertBtnLoading] = useState(false);
  const [error, setError] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (!_.isEmpty(orders)) {
        await dispatch(getOrdersInvoicesRequest({
          orderId: orders.map((d) => d.id).join(','),
          limit: 100,
          includes: 'payments',
        }));
      }
      setLoading(false);
    })();
  }, [orders]);

  const saveClick = async () => {
    setConvertBtnLoading(true);
    let hasError = false;

    const data = await Promise.all(orders.map((o) => (!allOrdersInvoices.find((i) => i.orderId === o.id)
      ? dispatch(generateInvoiceRequest(o.id)) : { payload: {} })));

    const errors = data.map((d) => {
      if (d.payload.status >= 500) {
        hasError = true;
      }
      return d.payload.data?.message;
    });

    if (hasError) {
      toast.error('Something wrong please try again!');
    } else if (errors.filter((e) => e).length) {
      setError(errors);

      toast.error('Can\'t create invoice !');
    } else {
      closeModal();
    }
    setConvertBtnLoading(false);
  };

  return (
    <div className="bulk_invoice_wrapper">
      <h2> Bulk invoice create</h2>

      <div className="bulk_invoice_body_wrapper">
        {loading
          ? <Loader />
          : orders.map((o, index) => {
            const orderInvoices = allOrdersInvoices.filter((i) => i.orderId === o.id);

            return (
              <div className={classNames('bulk_invoice_single_item_wrapper', { error: error[index] })} key={o.id}>
                <div className="bulk_invoice_single_item_left_block">
                  <p>{`#${o.number}`}</p>

                  <div>
                    <p>{`${o.orderProducts.length} items`}</p>

                    <span />

                    <p>{format(o.totalPrice || '-', { code: o.currencyCode })}</p>
                  </div>
                </div>

                <div>
                  {!!orderInvoices.length && (
                  <StatusField
                    status={o.totalPrice <= _.sumBy(orderInvoices, (i) => (+i.totalPrice))
                      ? 'invoiced'
                      : 'partially_invoiced'}
                  />
                  )}

                  {error[index] && <p className="error_message">{error[index]}</p>}
                </div>
              </div>
            );
          })}
      </div>

      <div className="bulk_order_buttons_wrapper">
        <Button
          className="bulk_order_button"
          size="small"
          title="Cancel"
          onClick={closeModal}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="bulk_order_button"
          size="small"
          title="Convert to invoice"
          onClick={saveClick}
          roundBorder
          color="#1472FF"
          loading={convertBtnLoading}
        />
      </div>
    </div>
  );
};

export default InvoiceCreate;
