import React from 'react';
import TimePicker from 'react-time-picker';

function Timepicker(props) {
  const {
    onChange, value, label, ...p
  } = props;
  return (
    <div className="datepicker_wrapper timepicker_wrapper">
      {label ? <span className="label">{label}</span> : null}
      <TimePicker disableClock clearIcon={null} onChange={onChange} value={value} {...p} />
    </div>
  );
}

export default Timepicker;
