import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { format } from 'currency-formatter';
import TableDropdown from './TableDropdown';
import Api from '../../../../Api';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/menu_dots.svg';
import { deleteCreditNoteRequest, refundCreditNoteRequest } from '../../../../store/actions/creditNotes';
import { getOrderInvoicesRequest } from '../../../../store/actions/invoices';
import DeleteModal from '../../../_common/Modal/DeleteModal';
import Utils from '../../../../helpers/Utils';
import ApplyToInvoiceModal from '../creditNotes/ApplyToInvoiceModal';

function CreditNotes() {
  const [deletingId, setDeletingId] = useState(null);
  const [dropdown, setDropdown] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [applyModal, setApplyModal] = useState(null);

  const dispatch = useDispatch();
  const { orderId } = useParams();

  const creditNotes = useSelector((state) => state.invoices.orderInvoices).map((i) => i.creditNotes).flat(1);

  const handleRefund = useCallback(async (id) => {
    setLoadingRefund(true);
    const { payload: { data } } = await dispatch(refundCreditNoteRequest(id));
    if (data.creditNote) {
      toast.success('Credit note successfully refunded.');
      await dispatch(getOrderInvoicesRequest(orderId));
      setDropdown(null);
    } else {
      toast.error(data.message);
    }
    setLoadingRefund(false);
  }, [orderId]);

  const handleDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { payload: { data } } = await dispatch(deleteCreditNoteRequest(deletingId));
    if (data.status !== 'error') {
      toast.success('Successfully deleted.');
      await dispatch(getOrderInvoicesRequest(orderId));
      setDeletingId(null);
    } else {
      toast.error(data.message);
    }
    setLoadingDelete(false);
  }, [deletingId]);

  const handleGetAmount = useCallback(() => {
    const note = creditNotes.find((c) => c.id === applyModal);
    if (note) {
      return +note.credit - (+note.refunded + +note.applied);
    }
    return null;
  }, [creditNotes, applyModal]);

  return (
    <div className="packages">
      {_.isEmpty(creditNotes) ? (
        <div className="no_packages">
          No credit notes created so far
        </div>
      ) : null}
      {!_.isEmpty(creditNotes) ? (
        <div className="table_wrapper">
          <table className="table">
            <thead className="table_thead">
              <tr className="table_thead_tr">
                <th className="table_thead_tr_th">Credit note#</th>
                <th className="table_thead_tr_th ordered">Date</th>
                <th className="table_thead_tr_th packed">Amount</th>
                <th className="table_thead_tr_th packed">Status</th>
                <th />
              </tr>
            </thead>
            <tbody className="table_tbody">
              {creditNotes.map((c, index) => (
                <tr
                  className="table_tbody_tr"
                  key={c.id}
                >
                  <td className="table_tbody_tr_td number">
                    <Link to={`/orders/credit-notes/preview/${c.id}`}>
                      {c.number}
                    </Link>
                  </td>
                  <td className="table_tbody_tr_td">
                    {moment(c.sourceCreatedAt).format('DD MMM YYYY')}
                  </td>
                  <td className="table_tbody_tr_td">
                    {format(c.credit, { code: c.currencyCode })}
                  </td>
                  <td className="table_tbody_tr_td">
                    <div className={`status ${Utils.getCreditNoteStatus(
                      c?.credit,
                      c?.refunded,
                      c?.applied,
                    )}`}
                    >
                      <p className="status_dot" />
                      <span>
                        <span>
                          {_.startCase(Utils.getCreditNoteStatus(
                            c?.credit,
                            c?.refunded,
                            c?.applied,
                          ))}
                        </span>
                      </span>
                    </div>
                  </td>
                  <td className="table_tbody_tr_td">
                    <div className="dot_icon" onClick={() => setDropdown(c.id)}>
                      <DotsIcon />
                    </div>
                    {dropdown === c.id ? (
                      <TableDropdown onClose={() => setDropdown(null)} top={50 + ((index + 1) * 45)}>
                        <li onClick={async () => {
                          setLoadingDownload(true);
                          try {
                            const { data } = await Api.downloadCreditNoteDocument(c.id);
                            fileDownload(data, 'document.pdf');
                            setLoadingDownload(false);
                          } catch (e) {
                            console.warn(e.response);
                          }
                        }}
                        >
                          Download document
                          {loadingDownload ? (
                            <div className="loader_wrapper" style={{ top: 8 }}>
                              <span style={{
                                width: 15,
                                height: 15,
                              }}
                              />
                            </div>
                          ) : null}
                        </li>
                        {+c.refunded < +c.credit ? (
                          <li onClick={() => setApplyModal(c.id)}>
                            Apply to invoice
                          </li>
                        ) : null}
                        {Utils.canRefundCreditNote(c) ? (
                          <li onClick={() => handleRefund(c.id)}>
                            Refund
                            {loadingRefund ? (
                              <div className="loader_wrapper" style={{ top: 8 }}>
                                <span style={{
                                  width: 15,
                                  height: 15,
                                }}
                                />
                              </div>
                            ) : null}
                          </li>
                        ) : null}
                        <li className="delete" onClick={() => setDeletingId(c.id)}>
                          Delete
                        </li>
                      </TableDropdown>
                    ) : null}
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      ) : null}
      <DeleteModal
        isOpen={!!deletingId}
        onDelete={handleDelete}
        onClose={() => setDeletingId(null)}
        loading={loadingDelete}
        text="credit note"
      />
      {applyModal ? (
        <ApplyToInvoiceModal
          id={applyModal}
          isOpen={!!applyModal}
          onClose={() => setApplyModal(false)}
          onGetData={() => dispatch(getOrderInvoicesRequest(orderId))}
          amount={handleGetAmount()}
        />
      ) : null}

    </div>

  );
}

export default CreditNotes;
