import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkbox_success.svg';

function Steps({ steps, setStep, currentStep }) {
  const changeStep = (step) => {
    if (step < currentStep) {
      setStep(step);
    }
  };

  return (
    <div className="stepper_wrapper">
      {steps.map(({ step, title }) => (
        <div
          key={title}
          style={{ cursor: step < currentStep ? 'pointer' : 'default' }}
          className={classNames('step_wrapper', { active: step <= currentStep })}
        >
          <div
            className="step"
            onClick={() => changeStep(step)}
          >
            {step < currentStep
              ? <CheckIcon className="btn_svg" />
              : step}
          </div>

          <h2 className="title">
            {title}
          </h2>
        </div>
      ))}
    </div>
  );
}

Steps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    step: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  })),
  setStep: PropTypes.func,
};
Steps.defaultProps = {
  setStep: () => {},
};

export default Steps;
