import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import ReactDatepicker from 'react-date-picker';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

const Datepicker = (props) => {
  const {
    label, error, portalContainer, wrapperClassName,
    portalPosition, onFocus, onBlur, format, ...p
  } = props;

  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (portalContainer && isOpen) {
      const { top, left } = ref.current.getBoundingClientRect();
      setPosition({
        top: top + window.scrollY + 70,
        left,
      });
    }
  }, [ref, portalContainer, isOpen]);

  const handleFocus = useCallback((ev) => {
    setOpen(true);
    if (onFocus) onFocus(ev);
  }, [onFocus]);

  const handleBlur = useCallback((ev) => {
    setOpen(false);
    if (onBlur) onBlur(ev);
  }, [onBlur]);

  return (
    <div
      role="button"
      tabIndex="0"
      ref={ref}
      onClick={() => {
        // if (!isOpen) {
        //   ref.current.querySelector('.react-date-picker__inputGroup__year').focus();
        // }
      }}
      className={classNames('datepicker_wrapper', { error, [wrapperClassName]: wrapperClassName })}
    >
      {label ? <span className="label">{label}</span> : null}
      {portalContainer && isOpen ? (
        <Helmet>
          <style>
            {`:root {
            --react-date-picker-top: ${position.top}px;
            --react-date-picker-left: ${position.left}px;
            --react-date-picker-transform: ${portalPosition === 'top' ? 'translateY(calc(-100% - 80px))' : 'none'};
            }`}
          </style>
        </Helmet>
      ) : null}

      <ReactDatepicker
        locale="en"
        dayPlaceholder="dd"
        yearPlaceholder="yyyy"
        monthPlaceholder="mm"
        format={format}
        portalContainer={portalContainer}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onDragStart={(ev) => {
          ev.preventDefault();
        }}
        portalId="root-portal"
        {...p}
      />
      {error ? <span className="error_text">{error}</span> : null}
    </div>
  );
};

export default Datepicker;

Datepicker.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  format: PropTypes.string,
  wrapperClassName: PropTypes.string,
  portalPosition: PropTypes.oneOf(['top', 'bottom']),
};

Datepicker.defaultProps = {
  error: '',
  label: '',
  format: 'MM.dd.yyyy',
  wrapperClassName: '',
  portalPosition: 'bottom',
};
