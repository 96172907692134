import React, { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Wrapper from '../../../Layout/Wrapper';
import GrapesEditor from '../../../_common/GrapesEditor/GrapesEditor';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';

const EmailTemplate = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();

  const [template, setTemplate] = useState({});

  const [saveLoading, saveLoadingToggle] = useState(false);
  const [loading, loadingToggle] = useState(true);

  useEffect(() => {
    (async () => {
      loadingToggle(true);
      const { data } = await Api.getTemplate(templateId);

      setTemplate(data.template);
      loadingToggle(false);
    })();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const saveTemplate = async () => {
    saveLoadingToggle(true);
    const data = {
      html: grapesjs.editors[0].getHtml(),
      style: grapesjs.editors[0].getCss(),
    };

    if (template.ownerId) {
      await Api.updateTemplate(templateId, data);
      toast.success('EmailTemplate successfully updated ');
    } else {
      data.template = template.template;
      data.type = template.type;
      await Api.createTemplate(data);
      toast.success('EmailTemplate successfully created ');
      goBack();
    }

    saveLoadingToggle(false);
  };

  return (
    <Wrapper
      title="Email template"
      onBtnClick={!loading && saveTemplate}
      btnLoading={saveLoading}
      onCancelBtnClick={!loading && goBack}
      // cancelButtonHref={!loading ? '/settings/email-templates' : ''}
      cancelButtonTitle="Cancel"
      buttonTitle={template.ownerId ? 'Save' : 'Create'}
      className="email_template_wrapper"
    >
      {loading
        ? <Loader />

        : <GrapesEditor template={template} />}
    </Wrapper>
  );
};

export default EmailTemplate;
