import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as EtsyIcon } from '../../../../../assets/icons/apps/etsy.svg';
import UpdateModal from '../../../../_common/Modal/UpdateModal';
import Api from '../../../../../Api';
import AppInstallInfoWrapper from '../AppInstallInfoWrapper';
import { appInstallFields } from '../../../../../helpers/staticData';

function CREtsyInstall() {
  const { app } = useParams();
  const navigate = useNavigate();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      const { data: { integration: { id } } } = await Api.createIntegration(
        'cartrover',
        {
          integrationName: data.integrationName,
          type: 'Etsy',
          apiUser: data.apiUser,
          apiKey: data.apiKey,
        },
      );

      navigate(`/apps/integration/${id}`);
    } catch (err) {
      if (err.response.data?.errors) {
        throw err;
      } else {
        toast.error(err.response.data.message);
        setInstallModal(false);
      }
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="Etsy"
      shopIcon={<EtsyIcon />}
      description="Etsy"
      onModalOpen={() => setInstallModal(true)}
    >
      <h3>Installation Instructions for Connecting Etsy to eSwap through CartRover</h3>

      <ol>
        <li>Sign in to your CartRover account.</li>
        <li>On the left sidebar, click on the "Merchants" tab.</li>
        <li>Create a new merchant.</li>
        <li>
          Generate API keys for the newly created merchant by clicking on the "Merchant API Keys" button, then
          selecting "New Setup". Give your key setup a name and press "OK".
        </li>
        <li>
          After saving your API keys in the CartRover merchant account, copy them and fill in the API User and API
          Key fields, respectively.
        </li>
        <li>Add a name for your app, then press the "Install App" button.</li>

        <p>
          If the keys are valid and the installation succeeds, you will be prompted to go through the settings steps.
          At this point, CartRover's statuses will be mapped to eSwap.
        </p>

        <li>Press the "Done Installation" button.</li>
        <p>After this, the newly created Etsy app should appear on your apps list.</p>
      </ol>

      <UpdateModal
        isOpen={installModal}
        fields={appInstallFields['cartrover-etsy']}
        onSave={install}
        onClose={() => setInstallModal(false)}
        fullTitle={`Install ${app}`}
        btnText="Install app"
      />
    </AppInstallInfoWrapper>
  );
}

export default CREtsyInstall;
