import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  changeInvoicesTableAttributes, getInvoicesTableAttributesRequest,
  getOrderInvoicesRequest,
  getOrdersInvoicesRequest, getSingleInvoiceRequest, invoicesTableEditableEvent, removeSingleInvoiceData,
} from '../actions/invoices';

const initialState = {
  orderInvoices: [],
  invoicedCount: {},
  tableEditable: false,
  allOrdersInvoices: [],
  availableCreditNotes: 0,
  singleInvoice:{},
  tableColumns: [
    {
      key: 'invoice#', label: 'Invoice#', isEnabled: true,
    },
    { key: 'order#', label: 'Order#', isEnabled: true },
    { key: 'customer', isEnabled: true },
    { key: 'status', isEnabled: true },
    { key: 'createdAt', isEnabled: true },
    { key: 'dueDate', isEnabled: true },
    { key: 'shippingAddress', isEnabled: true },
    { key: 'billingAddress', isEnabled: true },
    { key: 'amount', isEnabled: true },
    { key: 'balanceDue', isEnabled: true },
  ],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(invoicesTableEditableEvent, (state, { payload }) => {
      state.tableEditable = payload;
    })
    .addCase(changeInvoicesTableAttributes, (state, { payload }) => {
      state.tableColumns = payload;
    })
    .addCase(getInvoicesTableAttributesRequest.fulfilled, (state, { payload }) => {
      state.tableColumns = payload.orderInvoiceTableAttributes
        ? _.uniqBy([...state.tableColumns, ...payload.orderInvoiceTableAttributes], 'key')
        : state.tableColumns;
    })
    .addCase(getOrdersInvoicesRequest.fulfilled, (state, { payload }) => {
      state.allOrdersInvoices = payload.data.orderInvoices;
      state.totalPages = payload.data.totalPages;
    })
    .addCase(getSingleInvoiceRequest.fulfilled, (state, { payload }) => {
      state.singleInvoice = payload.orderInvoice;
    })
    .addCase(removeSingleInvoiceData, (state, { payload }) => {
      state.singleInvoice = {};
    })
    .addCase(getOrderInvoicesRequest.fulfilled, (state, action) => {
      const { orderInvoices, availableCreditNotes } = action.payload.data;
      state.orderInvoices = orderInvoices;
      state.availableCreditNotes = availableCreditNotes;

      const invoicedCount = {};
      const invoices = orderInvoices.filter((i) => i.status !== 'canceled').map((o) => o.orderInvoiceProducts).flat(1);

      invoices.forEach((p) => {
        if (invoicedCount[p.orderProductId]) {
          invoicedCount[p.orderProductId] += p.qty;
        } else {
          invoicedCount[p.orderProductId] = p.qty;
        }
      });
      state.invoicedCount = invoicedCount;
    });
});

export default reducer;
