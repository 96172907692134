import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import noImage from '../../../../assets/icons/default.svg';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import { bulkEditProductRequest } from '../../../../store/actions/products';
import Utils from '../../../../helpers/Utils';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning_tansparent.svg';

const BulkEditPricing = ({ productItems, setBulkEditPricing }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const priseList = useSelector((state) => state.products.priseList);
  const bulkPricingDefaultAttributes = useSelector((state) => state.products.bulkPricingDefaultAttributes);
  const bulkPricingColumns = useSelector((state) => state.products.bulkPricingColumns);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorIndex, setErrorIndex] = useState([]);

  const changeProductPrices = (price, id, priceListId, productPrice) => {
    setBulkEditPricing((prev) => ({
      ...prev,
      productLists: prev.productLists.map((p) => (p.id === id
        ? {
          ...p,
          prices: productPrice
            ? p.prices.map((pr) => (pr.priceListId === priceListId ? { ...pr, price } : pr))
            : [...p.prices, { discountPrice: 0, price, priceListId }],
        }
        : p)),
    }));
  };

  const priceListCols = useMemo(() => {
    const data = {};

    priseList.forEach((p) => {
      data[`priceList_${p.id}`] = ({ item }) => {
        const itemPrice = item.prices.find((d) => p.id === d.priceListId);

        return (
          <Input
            key={p.id}
            wrapperClassName="bulk_edit_modal_input_select"
            value={itemPrice?.price}
            onChange={(e) => changeProductPrices(e.target.value, item.id, p.id, itemPrice)}
            onBeforeInput={Utils.typingNumber}
            error={errors?.[item.id]?.[p.id]?.price}
          />
        );
      };
    });
    return data;
  }, [priseList, productItems, errors]);

  const closeModal = () => {
    setBulkEditPricing({});
  };

  const saveClick = async () => {
    setLoading(true);

    const requestData = productItems.map((b) => ({
      id: b.id,
      title: b.title,
      sku: b.sku,
      brandId: b.brandId,
      categories: b.categories.map((c) => ({ id: c.id, name: c.name })),
      prices: b.prices.map((c) => ({ priceListId: c.priceListId, price: c.price, discountPrice: c.discountPrice })),
      barCode: b.barCode,
      barCodeType: b.barCodeType,
    }));

    const { payload } = await dispatch(bulkEditProductRequest(requestData));

    if (payload.status === 200) {
      closeModal();
    } else {
      _.forEach(payload.data.errors.update, (error, index) => {
        if (error) {
          const getErrorProduct = _.get(productItems, index);

          _.forEach(error.prices, (e, i) => {
            if (e) {
              setErrors((prev) => ({
                ...prev,
                [getErrorProduct.id]: {
                  ...prev[getErrorProduct.id],
                  [_.get(getErrorProduct.prices, i).priceListId]: e,
                },
              }
              ));
            }
          });

          if (error.barCode) {
            setErrorIndex((prev) => [...prev, index]);
          }
        }
      });

      toast.error('One or more products can`t be edited, please check the product list');
    }

    setLoading(false);
  };

  return (
    <div
      className="bulk_edit_modal_wrapper"
    >
      <h2>Bulk Edit pricing</h2>

      <div className="bulk_edit_modal_table bulk_price_edit">
        <DynamicTable
          hideOrShow={false}
          loading={false}
          data={productItems}
          keyExtractor={(item) => item.id}
          columns={bulkPricingColumns}
          renderColumnHeader={(col) => bulkPricingDefaultAttributes[col.key] || col.key}
          errorIndex={errorIndex}
          renderColumns={{
            item: ({ item }) => (
              <div className="bulk_edit_modal_item_wrapper">
                <div className="bulk_edit_modal_item_img">
                  <img
                    src={item.images[0]?.src || item.images[0]?.medium || noImage}
                    alt="img"
                  />
                </div>

                <p>{item.title}</p>
              </div>
            ),

            sku: ({ item }) => (item.sku || '-'),

            ...priceListCols,

            error: ({ item }) => (
              <div className="item_warning_block">
                <WarningIcon />

                <p>This product is damaged, please follow the link to edit it</p>

                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => navigate(item.type === 'variant'
                    ? `/products/${item.parentId}?productVariantId=${item.id}`
                    : `/products/${item.id}`)}
                >
                  Edit item
                </div>
              </div>
            ),
          }}
        />

      </div>

      <div className="bulk_edit_modal_buttons_wrapper">
        <Button
          className="bulk_edit_modal_button"
          size="small"
          title="Cancel"
          onClick={() => setBulkEditPricing({})}
          roundBorder
          btnType="cancel"
        />

        <Button
          className="bulk_edit_modal_button"
          size="small"
          title="Save"
          onClick={saveClick}
          roundBorder
          color="#1472FF"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default BulkEditPricing;
