import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getExportWarehouseList = createAsyncThunk('get/export/warehouse', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getWarehouses();

    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const getExportPriceList = createAsyncThunk('get/export/price/list', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.getPriceList({ limit: 100 });

    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});

export const templateFieldsAction = createAction('template/fields');

export const getExportTemplateCustomFieldList = createAsyncThunk(
  'get/export/template/list',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getExportTemplateList(payload);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);

export const createExportTemplate = createAsyncThunk('create/export/template', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.createExportTemplate(payload);
    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
export const updateExportTemplate = createAsyncThunk('update/export/template', async (payload, thunkAPI) => {
  try {
    const { data } = await Api.updateExportTemplate(payload);
    return data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response);
  }
});
