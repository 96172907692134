import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete_with_circle.svg';
import DynamicTable from '../../../_common/DynamicTable/DynamicTable';
import Input from '../../../_common/Form/Input';
import Button from '../../../_common/Form/Button';
import Datepicker from '../../../_common/Form/Datepicker';
import Loader from '../../../_common/Loader/Loader';
import Utils from '../../../../helpers/Utils';
import Select from '../../../_common/Form/Select';

const column = [
  { key: 'expirationDateFrom', isEnabled: true },
  { key: 'expirationDateTo', isEnabled: true },
];

const defaultAttributes = {
  variant: 'Variant',
  batch: 'Batch#',
  serialNumber: 'Serial number#',
  qty: 'Quantity',
  expirationDateFrom: 'Manufactured date',
  expirationDateTo: 'Expiration date',
};

const ProductBatchTracking = ({
  settings, setProductData, batches, addBatch, changeSettings,
  loading, errors, setErrors, variants,
}) => {
  const [show, setShow] = useState(true);

  const columns = useMemo(() => {
    let newColumn = [];
    if (settings === 'serialNumber') {
      newColumn = [
        { key: 'serialNumber', isEnabled: true }, ...column];
    } else {
      newColumn = [
        { key: 'batch', isEnabled: true },
        { key: 'qty', isEnabled: true }, ...column,
      ];
    }

    if (variants.length > 0) {
      newColumn = [{ key: 'variant', isEnabled: true }, ...newColumn];
    }

    return newColumn;
  }, [settings, variants]);

  useEffect(() => {
    if (errors?.length) {
      setErrors((prev) => ({ ...prev, batches: [] }));
    }
  }, [settings]);

  const onDataChange = (path, value, index) => {
    setErrors((err) => ({
      ...err,
      batches: err.batches?.map((b, i) => (i === index ? { ...b, [path]: '' } : b)),
    }));

    setProductData((prev) => ({
      ...prev,
      batches: prev.batches.map((b, i) => {
        if (i === index) {
          if (path === 'variant') {
            return {
              ...b,
              [path]: value.id,
            };
          }

          return {
            ...b,
            [path]: value,
          };
        }

        return b;
      }),
    }));
  };

  useEffect(() => {
    if (variants.length) {
      batches.forEach((b, index) => {
        if (!b.variant) onDataChange('variant', variants[0], index);
      });
    }
  }, [variants]);

  const deleteBatch = (index) => {
    setErrors((err) => {
      _.remove(err?.batches, (e, i) => i === index);

      return _.cloneDeep(err);
    });

    if (!(batches.length - 1)) {
      changeSettings('');
    }

    setProductData((prev) => ({
      ...prev,
      batches: prev.batches.filter((b, i) => i !== index),
    }));
  };

  return (
    <div className={classNames('create_product_batch_wrapper', { hide: !show })}>
      <div
        className="create_product_batch_header_wrapper"
        role="button"
        tabIndex="0"
        onClick={() => setShow((prev) => !prev)}
      >
        <ArrowIcon />

        <h2 className="create_product_batch_title">
          {settings === 'serialNumber'
            ? 'Serial numbers'
            : 'Batch tracked'}
        </h2>
      </div>

      {show && (
        loading ? <div className="create_product_batch_loader"><Loader size={30} /></div>
          : (
            <div className="create_product_batch_table_wrapper">
              <DynamicTable
                hideOrShow={false}
                loading={false}
                data={batches}
                keyExtractor={(item, index) => index}
                columns={columns}
                renderColumnHeader={(col) => defaultAttributes[col.key] || col.key}
                renderColumns={{
                  variant: ({ item, index }) => (
                    <div className="create_product_batch_input_wrapper">
                      <Select
                        options={variants}
                        wrapperClassName="create_product_batch_select"
                        value={item.variant || ''}
                        getFullOption
                        getOptionValue={(s) => (s.title)}
                        getOptionLabel={(s) => (s.title)}
                        valuePath="id"
                        onChange={(options) => onDataChange('variant', options, index)}
                        size="small"
                        error={errors?.[index]?.variant}
                        menuPosition="fixed"
                      />
                    </div>
                  ),

                  batch: ({ item }) => (<div className="create_product_batch_id">{item.batchNumber || '-'}</div>),

                  serialNumber: ({ item, index }) => (
                    <div className="create_product_batch_input_wrapper">
                      <Input
                        wrapperClassName="create_product_batch_input"
                        value={item.serialNumber}
                        onChange={(e) => onDataChange('serialNumber', e.target.value, index)}
                        size={36}
                        error={errors?.[index]?.serialNumber}
                      />
                    </div>

                  ),

                  qty: ({ item, index }) => (
                    <div className="create_product_batch_input_wrapper">
                      <Input
                        wrapperClassName="create_product_batch_input"
                        value={item.qty}
                        error={errors?.[index]?.qty}
                        size={36}
                        onChange={(e) => onDataChange('qty', e.target.value, index)}
                        onBeforeInput={Utils.typingNumber}
                      />
                    </div>

                  ),

                  expirationDateFrom: ({ item, index }) => (
                    <div className="create_product_batch_input_wrapper">
                      <Datepicker
                        onChange={(date) => onDataChange('expirationDateFrom', date, index)}
                        value={item.expirationDateFrom ? moment(item.expirationDateFrom).format('MM.DD.YYYY') : ''}
                        error={errors?.[index]?.expirationDateFrom}
                        wrapperClassName="create_product_batch_date"
                        portalPosition="top"
                        portalContainer={document.getElementById('root')}
                      />
                    </div>

                  ),

                  expirationDateTo: ({ item, index }) => (
                    <div className="create_product_batch_input_wrapper">
                      <Datepicker
                        onChange={(date) => onDataChange('expirationDateTo', date, index)}
                        value={item.expirationDateTo ? moment(item.expirationDateTo).format('MM.DD.YYYY') : ''}
                        error={errors?.[index]?.expirationDateTo}
                        wrapperClassName="create_product_batch_date"
                        portalPosition="top"
                        portalContainer={document.getElementById('root')}
                      />
                    </div>

                  ),

                  actions: ({ item, index }) => (
                    <div className="create_product_batch_actions">
                      <DeleteIcon onClick={() => deleteBatch(index)} />
                    </div>
                  ),

                }}
              />

              <Button
                onClick={() => addBatch(settings, false, _.last(batches).batchNumber)}
                size="small"
                roundBorder
                btnType="transparent"
                color="#1472FF"
                addBtn
                className="create_product_batch_add_new"
                title="Add new"
                loaderSize={20}
              />
            </div>
          )
      )}
    </div>
  );
};

ProductBatchTracking.propTypes = {
  errors: PropTypes.array,
  settings: PropTypes.string.isRequired,
  setProductData: PropTypes.func.isRequired,
  batches: PropTypes.array.isRequired,
  addBatch: PropTypes.func.isRequired,
  changeSettings: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setErrors: PropTypes.func.isRequired,
  variants: PropTypes.array.isRequired,
};

ProductBatchTracking.defaultProps = {
  errors: [],
};

export default ProductBatchTracking;
