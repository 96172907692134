import _ from 'lodash';
import store from '../store';

class UserPermissions {
  static unsubscribe() {
    document.body.removeEventListener('click', this.#handleEvent, { capture: true });
    document.body.removeEventListener('keydown', this.#handleEvent, { capture: true });
    document.body.removeEventListener('focus', this.#handleEvent, { capture: true });
    this.storeUnsubscribe();
    this.observer.disconnect();
  }

  static subscribe() {
    document.body.addEventListener('click', this.#handleEvent, { capture: true });
    document.body.addEventListener('keydown', this.#handleEvent, { capture: true });
    document.body.addEventListener('focus', this.#handleEvent, { capture: true });

    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.#checkPermissionNested(mutation.addedNodes);
        } else if (mutation.type === 'attributes' && mutation.attributeName === 'data-scope') {
          this.#checkPermission(mutation.target);
        }
      }
    });

    this.observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['data-scope'],
      subtree: true,
      childList: true,
    });

    let prevPermissions;
    this.storeUnsubscribe = store.subscribe(() => {
      const { permissions } = store.getState().users;
      if (!_.isEqual(prevPermissions, permissions) && permissions.length) {
        this.#checkPermissionNested([document.body]);
      }
      prevPermissions = permissions;
    });
  }

  static hasAccess = (scope) => {
    if (_.isEmpty(scope) || (!_.isArray(scope) && !_.isString(scope))) {
      return true;
    }
    const { permissions, profile: { isAdmin } } = store.getState().users;

    const scopeArr = _.isArray(scope) ? scope : scope.split(',');

    return isAdmin || !permissions.length || _.intersection(scopeArr, permissions).length === scopeArr.length;
  };

  static #handleEvent = (ev) => {
    if (ev.target && ev.target.closest('.no_access')) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.target.blur();
    }
  };

  static #checkPermission = (target) => {
    const scope = target.getAttribute('data-scope');
    const hasAccess = this.hasAccess(scope);
    if (hasAccess) {
      target.removeAttribute('title');
      target.classList.remove('no_access');
    } else {
      target.title = 'Access Denied';
      target.classList.add('no_access');
      const redirect = target.getAttribute('data-redirect');
      if (redirect) {
        window.history.back();
      }
    }
  };

  static #checkPermissionNested = (nodes) => {
    nodes.forEach((node) => {
      if (node.nodeType === 1) { // Ensure it's an element node
        if (node.hasAttribute('data-scope')) {
          this.#checkPermission(node);
        }
        node.querySelectorAll('[data-scope]').forEach((child) => {
          this.#checkPermission(child);
        });
      }
    });
  };
}

export default UserPermissions;
