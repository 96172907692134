import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../_common/Form/Button';
import noImage from '../../../../assets/icons/default.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import Utils from '../../../../helpers/Utils';
import Modal from '../../../_common/Modal/Modal';
import Input from '../../../_common/Form/Input';
import Checkbox from '../../../_common/Form/Checkbox';
import Api from '../../../../Api';
import Loader from '../../../_common/Loader/Loader';
import { ReactComponent as NotFoundIcon } from '../../../../assets/icons/notFound/import_not_found.svg';
import DeleteModal from '../../../_common/Modal/DeleteModal';

const productTypes = 'simple,variant,bundle';

const LinkedProducts = ({
  data, parentProductData, onDataChange, updateProduct,
}) => {
  const navigate = useNavigate();

  const timeout = useRef();
  const defaultProducts = useRef([]);
  const currentProducts = useRef([]);

  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState([]);

  const [selectedProducts, selectProducts] = useState([]);
  const [deletingProductId, setDeletingProductId] = useState('');

  const [modalType, setModalType] = useState('');
  const [loading, loadingToggle] = useState(false);
  const [saveLoading, saveLoadingToggle] = useState(false);

  useEffect(() => {
    (async () => {
      loadingToggle(true);

      const { data: { products: productsLists } } = await Api.getProducts({
        type: productTypes, excludeShopId: data.shopId,
      });

      defaultProducts.current = productsLists;

      loadingToggle(false);
    })();
  }, []);

  const searchProducts = useCallback((value) => {
    setSearchValue(value);
    loadingToggle(true);
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      if (modalType === 'add') {
        if (value.trim()) {
          const { data: { products: productsLists } } = await Api.getProducts({
            s: value, type: productTypes, excludeShopId: data.shopId,
          });

          setProducts(productsLists);
        } else {
          setProducts(defaultProducts.current);
        }
      } else {
        setProducts(currentProducts.current.filter((p) => p.title.toLowerCase().includes(value.toLowerCase().trim())
         || p.sku?.toLowerCase()?.includes(value.toLowerCase().trim())));
      }

      loadingToggle(false);
    }, 500);
  }, [modalType]);

  const openingModal = useCallback((type) => {
    setSearchValue('');
    setModalType(type);
    loadingToggle(false);

    clearTimeout(timeout.current);

    if (type) {
      selectProducts(data.relations);

      setProducts(type === 'linked' ? data.relations : defaultProducts.current);
      currentProducts.current = type === 'linked' ? data.relations : defaultProducts.current;
    }
  }, [data.relations]);

  const productSelect = useCallback((product) => {
    selectProducts((prev) => {
      if (prev.find((p) => p.id === product.id)) {
        return prev.filter((p) => p.id !== product.id);
      }

      return [...prev, product];
    });
  }, []);

  const save = useCallback(async () => {
    saveLoadingToggle(true);

    let savingData = { ...data, relations: selectedProducts };

    if (parentProductData?.variants) {
      savingData = {
        ...parentProductData,
        variants: parentProductData.variants.map((v) => (v.id === data.id ? { ...v, relations: selectedProducts } : v)),
      };
    }

    const { errors } = await updateProduct(true, savingData);

    if (!errors) {
      onDataChange('relations', selectedProducts);
    }

    openingModal('');
    saveLoadingToggle(false);
  }, [selectedProducts, data, parentProductData]);

  const deleteProduct = useCallback(async () => {
    saveLoadingToggle(true);

    const relations = data.relations.filter((d) => d.id !== deletingProductId);

    let savingData = { ...data, relations: selectedProducts };

    if (parentProductData) {
      savingData = {
        ...parentProductData,
        variants: parentProductData.variants.map((v) => (v.id === data.id ? { ...v, relations } : v)),
      };
    }

    const { errors } = await updateProduct(true, savingData);

    if (!errors) {
      onDataChange('relations', relations);
    }

    setDeletingProductId('');
    saveLoadingToggle(false);
  }, [data, deletingProductId, parentProductData]);

  const navigateToProduct = async (p) => {
    try {
      const response = await updateProduct(true);

      if (response?.status !== 'error') {
        navigate(+p.parentId
          ? `/products/${p.parentId}?productVariantId=${p.id}`
          : `/products/${p.id}`);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div className="create_product_barcode linked_products_wrapper">
      <div className="create_product_barcode_top">
        <h3 className="create_product_barcode_top_title">Linked Products</h3>

        <Button
          onClick={() => openingModal('add')}
          size="small"
          roundBorder
          title="Add new"
          btnType="transparent"
          color="#1472FF"
        />
      </div>

      <div className="linked_products_wrapper_lists">
        {data?.relations?.length
          ? data.relations.slice(0, 3).map((p) => (
            <div key={p.id} className="linked_products_wrapper_lists_product_wrapper">
              <img src={p.images?.[0]?.src || noImage} alt="img" />

              <div
                className="linked_products_wrapper_lists_product_info"
                onClick={() => navigateToProduct(p)}
              >
                <p className="linked_products_wrapper_lists_product_title">{p.title}</p>

                <p className="linked_products_wrapper_lists_product_sku">{`SKU: ${p.sku || '-'}`}</p>

                <div className="linked_products_wrapper_lists_product_shop_logo">
                  {Utils.getAppIcon(p, true)}
                </div>
              </div>

              <Button
                btnType="transparent"
                onClick={() => setDeletingProductId(p.id)}
              >
                <LinkIcon />
              </Button>
            </div>
          ))

          : (
            <div className="data_not_found">
              <NotFoundIcon />

              <h1>Products not found</h1>
            </div>
          )}

        {data?.relations?.length > 3 && (
          <div className="linked_products_wrapper_show_all_wrapper">
            <Button
              onClick={() => openingModal('linked')}
              size="small"
              roundBorder
              title="Show all"
              btnType="transparent"
              color="#717A8A"
            />
          </div>
        )}
      </div>

      <Modal
        isOpen={!!modalType}
        onClose={() => openingModal('')}
        wrapperClassName="linked_products_modal"
      >
        <h2>{`${modalType === 'linked' ? 'Linked' : 'Link'} products`}</h2>

        <div className="linked_products_modal_content">
          <Input
            placeholder="Search products"
            roundBorder
            size="small"
            search
            value={searchValue}
            onChangeText={searchProducts}
            wrapperClassName="linked_products_search_field"
          />

          {loading && <Loader />}

          <div className="linked_products_wrapper_lists">
            {products.length
              ? products.map((p) => (
                <div key={p.id} className="linked_products_wrapper_lists_product_wrapper modal">
                  <Checkbox
                    checked={!!selectedProducts.find((s) => s.id === p.id)}
                    onChange={() => productSelect(p)}
                  />

                  <img src={p.images?.[0]?.src || noImage} alt="img" />

                  <div className="linked_products_wrapper_lists_product_info">
                    <p className="linked_products_wrapper_lists_product_title">{p.title}</p>

                    <p className="linked_products_wrapper_lists_product_sku">{`SKU: ${p.sku || '-'}`}</p>

                    <div className="linked_products_wrapper_lists_product_shop_logo">
                      {Utils.getAppIcon(p, true)}
                    </div>
                  </div>
                </div>
              ))
              : (
                <div className="data_not_found">
                  <NotFoundIcon />

                  <h1>Products not found</h1>
                </div>
              )}
          </div>

          <div className="linked_products_modal_content_buttons_wrp">
            <Button
              className="linked_products_modal_content_cancel_button"
              roundBorder
              size="small"
              btnType="cancel"
              onClick={() => openingModal('')}
            >
              Cancel
            </Button>

            <Button
              className="linked_products_modal_content_save_button"
              roundBorder
              size="small"
              onClick={save}
              loading={saveLoading}
            >
              {modalType === 'add' ? 'Add' : 'Save'}
            </Button>
          </div>
        </div>
      </Modal>

      <DeleteModal
        isOpen={!!deletingProductId}
        onDelete={deleteProduct}
        onClose={() => setDeletingProductId('')}
        loading={saveLoading}
        deleteType="uninstall"
        text="product"
      />
    </div>
  );
};

LinkedProducts.propTypes = {
  data: PropTypes.object.isRequired,
  parentProductData: PropTypes.object,
  onDataChange: PropTypes.func,
  updateProduct: PropTypes.func,
};

LinkedProducts.defaultProps = {
  parentProductData: {},
  onDataChange: () => {},
  updateProduct: () => {},
};

export default LinkedProducts;
