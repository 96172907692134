import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from '../../_common/Modal/Modal';
import Button from '../../_common/Form/Button';
import { ReactComponent as ImportIcon } from '../../../assets/icons/import_file.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download_example_import.svg';
import productFile from '../../../assets/exampleImportFiles/products.csv';
import orderFile from '../../../assets/exampleImportFiles/orders.csv';
import customerFile from '../../../assets/exampleImportFiles/customers.csv';
import Checkbox from '../../_common/Form/Checkbox';
import {
  cancelImportFile, changeImportExistType, readImportFile, uploadFile,
} from '../../../store/actions/fileImport';

const exampleCSVFiles = {
  products: productFile,
  orders: orderFile,
  customers: customerFile,
};

const ImportModal = memo(({
  isOpen, onClose, className, type,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const file = useSelector((store) => store.fileImport.file);
  const existProductType = useSelector((store) => store.fileImport.existProductType);

  const changeImportFile = (csvFile) => {
    if ((csvFile?.type === 'text/csv') || (csvFile === null)) {
      if (csvFile !== null && (csvFile.size / 1048576) >= 100) {
        toast.error('Max onUploading size is 100MB !');
      } else {
        dispatch(uploadFile(csvFile));
      }
    } else {
      toast.error('Please select csv file!');
    }
  };

  const readFile = () => {
    if (!file) {
      toast.error('Please select csv file!');
      return;
    }

    Papa.parse(file, {
      header: true,
      preview: 10,
      complete: (result) => {
        dispatch(readImportFile(result));
      },
    });

    navigate(`/import-csv-file/${type}`);
  };

  const closeModal = () => {
    dispatch(cancelImportFile());
    onClose();
  };

  const changeRadioButton = (existType) => {
    dispatch(changeImportExistType(existType));
  };

  return (
    <Modal
      className={classNames('import_modal_wrapper', className)}
      isOpen={isOpen}
      zIndex={998}
      onClose={closeModal}
    >
      <h1>Import via CSV</h1>

      <input
        type="file"
        id="CSV_file"
        accept=".csv"
        onChange={(e) => {
          changeImportFile(e.target.files[0]);

          e.target.value = '';
        }}
      />

      <label htmlFor="CSV_file" className="import_modal_label">
        {file ? (
          <div className="import_modal_label_wrapper_with_file">
            <div>
              <FileIcon />

              <p>{file.name}</p>
            </div>

            <CancelIcon
              className="import_modal_cancel_icon"
              onClick={(e) => {
                e.preventDefault();

                changeImportFile(null);
              }}
            />
          </div>
        )
          : (
            <div className="import_modal_label_wrapper_without_file">
              <ImportIcon />

              <h2>Select CSV file</h2>

              <p>Max onUploading size is 100MB</p>
            </div>
          )}

      </label>

      <a
        href={exampleCSVFiles[type]}
        target="_blank"
        download={`${type.replace('s', '')}_sample.csv`}
        className="import_modal_template_example"
        rel="noreferrer"
      >
        <DownloadIcon />

        <p>Download template</p>
      </a>

      {type === 'products' && (
      <div className="import_modal_radio_wrapper">
        <Checkbox
          onChange={() => changeRadioButton('update')}
          label="Increase existing products stock"
          radio
          checked={existProductType === 'update'}
          className="import_modal_radio"
        />

        <Checkbox
          onChange={() => changeRadioButton('create')}
          label="Overwrite existing products stock"
          className="import_modal_radio"
          radio
          checked={existProductType === 'create'}
        />
      </div>
      )}

      <div className="import_modal_buttons_wrapper">
        <Button
          size="small"
          roundBorder
          btnType="cancel"
          reverseColor
          onClick={closeModal}
          title="Cancel"
          className="import_modal_button"
        />

        <Button
          size="small"
          roundBorder
          title="Upload and continue"
          onClick={readFile}
          className="import_modal_button"
        />
      </div>
    </Modal>
  );
});

ImportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

ImportModal.defaultProps = {
  className: '',
  onClose: null,
};

export default ImportModal;
