import { createReducer } from '@reduxjs/toolkit';
import { getPriceListsRequest } from '../actions/priceLists';

const initialState = {
  priceLists: [],
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPriceListsRequest.fulfilled, (state, action) => {
      state.priceLists = action.payload.data.priceLists;
    });
});

export default reducer;
