import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as PlansBillingsIcon } from '../assets/icons/plans_billings.svg';
import { ReactComponent as ShippingIcon } from '../assets/icons/shipping.svg';
import { ReactComponent as PackagesIcon } from '../assets/icons/packages.svg';
import { ReactComponent as ShippingTypeIcon } from '../assets/icons/shipping_type.svg';
import { ReactComponent as CompanyIcon } from '../assets/icons/company.svg';
import { ReactComponent as CurrencyIcon } from '../assets/icons/currncy.svg';
import { ReactComponent as EmailTemplateIcon } from '../assets/icons/email_templates.svg';
import { ReactComponent as TagsIcon } from '../assets/icons/tags.svg';
import { ReactComponent as BrandsIcon } from '../assets/icons/brands.svg';
import { ReactComponent as AdjustmentReasonIcon } from '../assets/icons/adjustment_reason.svg';
import { ReactComponent as CustomFieldsIcon } from '../assets/icons/custom_fields.svg';
import { ReactComponent as RemotePrintingIcon } from '../assets/icons/remote_printing.svg';
// import { ReactComponent as ProductsIcon } from '../assets/icons/products.svg';
// import { ReactComponent as ApiIcon } from '../assets/icons/api.svg';

export const settingsTabs = [
  {
    title: 'General',
    icon: <SettingsIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'general/general',
  },
  {
    title: 'Plans & billing',
    icon: <PlansBillingsIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'plans-billings/subscription',
  },
  {
    title: 'Packages',
    icon: <PackagesIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'packages',
  },
  {
    title: 'Shipping types',
    icon: <ShippingTypeIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'shipping-types',
    // component: <General />,
  },
  {
    title: 'Company',
    icon: <CompanyIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'companies',
  },
  {
    title: 'Currency',
    icon: <CurrencyIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'currency',
  },
  {
    title: 'Email templates',
    icon: <EmailTemplateIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'email-templates',
  },
  {
    title: 'Products Tags',
    icon: <TagsIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'products-tags',
  },
  {
    title: 'Orders Tags',
    icon: <TagsIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'orders-tags',
  },
  {
    title: 'Brands',
    icon: <BrandsIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'brands',
  },
  {
    title: 'Adjustment reason',
    icon: <AdjustmentReasonIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'adjustment-reasons',
  },
  {
    title: 'Remote printing',
    icon: <RemotePrintingIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'remote-printing',
  },
  {
    title: 'Custom fields',
    icon: <CustomFieldsIcon />,
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    path: 'custom-fields',
  },
  // {
  //   title: 'API access',
  //   icon: <ApiIcon />,
  //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  //   path: '',
  // },
  // {
  //   title: 'Product types',
  //   icon: <ProductsIcon className="settings_icon" />,
  //   text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  //   path: 'product-types',
  // },
];

function Settings() {
  return (
    <div className="settings_page">
      <div className="settings_page_tabs">
        {settingsTabs.map((t) => (
          <Link className="settings_page_tabs_single" to={`/settings/${t.path}`} key={t.title}>
            {t.icon}

            <h3>{t.title}</h3>

            <p>{t.text}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Settings;
