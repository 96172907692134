import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryParamProvider } from 'use-query-params';
import { Tooltip } from 'react-tooltip';
import Navigation from './Navigation';

const App = () => (
  <>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Navigation />
      </QueryParamProvider>
    </BrowserRouter>

    <Tooltip id="default-tooltip" />
    <ToastContainer style={{ zIndex: 99999 }} />
  </>
);

export default App;
