import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import _ from 'lodash';
import Button from '../../../_common/Form/Button';
import Loader from '../../../_common/Loader/Loader';
import moment from 'moment/moment';

function AdminUserBoughtPackage({ boughtThePackage, loading, interval }) {
  const chart = useMemo(() => ({
    series: [{
      name: 'series1',
      data: boughtThePackage?.map((d) => d.count) || [],
    }],
    options: {
      chart: {
        height: 350,
        type: 'area',

        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        // type: 'datetime',
        categories: boughtThePackage?.map((d) => moment(d.time, 'YYYY/MM/DD')
          .format('MMM')) || [], // interval === 'day' ? 'MMM D, YYYY',
      },
      colors: ['#8571F4'],
      fill: {
        opacity: 0,
        type: 'solid',
      },
    },
  }), [boughtThePackage]);

  const counts = useMemo(() => {
    const count = {
      total: [],
      active: [],
      canceled: [],
    };

    boughtThePackage.forEach((d) => {
      count.total = [...count.total, +d.count];
      count.active = [...count.active, +d.active];
      count.canceled = [...count.canceled, +d.canceled];
    });

    count.total = _.sum(count.total);
    count.active = _.sum(count.active);
    count.canceled = _.sum(count.canceled);

    return count;
  }, [boughtThePackage]);

  return (
    <div className="admin_package_chart">
      {loading ? <Loader className="admin_chart_wrapper_loader" /> : (
        <>
          <div className="admin_chart_header">
            <div className="admin_chart_header_left">
              <h3>Users have bought the package</h3>

              <Button href="#" btnType="transparent" color="#1472FF" title="View all" />
            </div>

            <div className="admin_chart_header_count_package">
              <p>{`Total ${counts.total}`}</p>
              <p>{`Active ${counts.active}`}</p>
              <p>{`Canceled ${counts.canceled}`}</p>
            </div>
          </div>

          <Chart
            options={chart.options}
            series={chart.series}
            // width="94%"
            type="area"
            height={425}
          />
        </>
      )}
    </div>
  );
}

export default AdminUserBoughtPackage;
