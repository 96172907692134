import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'currency-formatter';
import moment from 'moment/moment';
import _ from 'lodash';
import Tooltip from 'rc-tooltip';
import ReceiveForm from '../../components/pages/Orders/returns/ReceiveForm';
import Wrapper from '../../components/Layout/Wrapper';
import noImg from '../../assets/icons/no_img.svg';
import { getProductsRequest } from '../../store/actions/products';
import CreditNotesList from '../../components/pages/Orders/creditNotes/CreditNotesList';
import CreditNotePreviewHeader from '../../components/pages/Orders/creditNotes/CreditNotePreviewHeader';
import { getCreditNoteRequest } from '../../store/actions/creditNotes';
import Loader from '../../components/_common/Loader/Loader';
import Utils from '../../helpers/Utils';
import { ReactComponent as InfoIcon } from '../../assets/icons/warning.svg';

function CreditNotePreview() {
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const { noteId } = useParams();
  const dispatch = useDispatch();

  const creditNote = useSelector((state) => state.creditNotes.creditNote);

  useEffect(() => {
    if (noteId) {
      dispatch(getCreditNoteRequest(noteId));
    }
  }, [noteId]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(creditNote.invoice)) {
        return;
      }
      const { payload } = await dispatch(getProductsRequest(
        { ids: creditNote.invoice.orderInvoiceProducts.map((p) => p.productId) },
      ));
      setProducts(payload.data.products);
    })();
  }, [creditNote]);

  if (_.isEmpty(creditNote)) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <div className="preview_wrapper return_preview">
        <Helmet>
          <body className="sales_order_preview" />
        </Helmet>
        <CreditNotesList />
        <div className="content">
          <CreditNotePreviewHeader />
          <div className="order_preview_content">
            <div className="left">
              <div className="action_tabs">
                <div className="items sales_orders">
                  <div className="products_list table_wrapper">
                    <table className="table">
                      <thead className="table_thead">
                        <tr className="table_thead_tr">
                          <th className="table_thead_tr_th">Invoice#</th>
                          <th className="table_thead_tr_th">Date</th>
                          <th className="table_thead_tr_th">Credits applied</th>
                          <th className="table_thead_tr_th">Credits Remaining</th>
                          <th className="table_thead_tr_th">Status</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody className="table_tbody">
                        <tr
                          className="table_tbody_tr"
                        >

                          <td className="table_tbody_tr_td link">
                            {creditNote?.invoiceId ? (
                              <Link
                                to={`/orders/invoices/preview/${creditNote?.invoiceId}`}
                              >
                                {creditNote?.invoice?.number}
                              </Link>
                            ) : <span>No invoice applied</span>}
                          </td>
                          <td className="table_tbody_tr_td">
                            {moment(creditNote?.sourceCreatedAt).format('MM/DD/YYYY')}
                          </td>
                          <td className="table_tbody_tr_td with_warning">
                            <div>
                              {format(
                                (creditNote?.refunded || 0) + (creditNote?.applied || 0),
                                { code: creditNote?.currencyCode },
                              )}
                              {!_.isEmpty(creditNote?.appliedInvoices) ? (
                                <Tooltip
                                  placement="top"
                                  overlay={(
                                    <div className="notes">
                                      {creditNote?.appliedInvoices.map((i) => (
                                        <Link to={`/orders/invoices/preview/${i.id}`} key={i.id} target="_blank">
                                          {`Applied to invoice - ${i.number}`}
                                        </Link>
                                      ))}
                                    </div>
                                )}
                                  destroyTooltipOnHide
                                >
                                  <InfoIcon />
                                </Tooltip>
                              ) : null}
                            </div>
                          </td>
                          <td className="table_tbody_tr_td">
                            {format(
                              (creditNote?.credit || 0)
                            - ((creditNote?.refunded || 0) + (creditNote?.applied || 0)),
                              { code: creditNote?.currencyCode },
                            )}
                          </td>
                          <td className={`table_tbody_tr_td status ${Utils.getCreditNoteStatus(
                            creditNote?.credit,
                            creditNote?.refunded,
                            creditNote?.applied,
                          )}`}
                          >
                            <div>
                              <p className="status_dot" />
                              <span>
                                {_.startCase(Utils.getCreditNoteStatus(
                                  creditNote?.credit,
                                  creditNote?.refunded,
                                  creditNote?.applied,
                                ))}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="items">
                <div className="products_list table_wrapper">
                  <table className="table">
                    <thead className="table_thead">
                      <tr className="table_thead_tr">
                        <th className="table_thead_tr_th">Item</th>
                        <th className="table_thead_tr_th">Quantity</th>
                        <th className="table_thead_tr_th">Rate</th>
                        <th className="table_thead_tr_th">Amount</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody className="table_tbody">
                      {creditNote.invoice ? creditNote?.invoice?.orderInvoiceProducts?.map((p) => {
                        const product = products?.find((f) => f.id === p.productId) || {};
                        return (
                          <tr
                            className="table_tbody_tr"
                            key={p.id}
                          >

                            <td className="table_tbody_tr_td">
                              <div className="info">
                                <img
                                  style={{ height: 30, width: 30 }}
                                  src={product.images?.[0]?.src || product.images?.[0]?.medium || noImg}
                                  alt=""
                                />
                                <Link to={`/products/${product.id}`} target="_blank">{product.title}</Link>
                              </div>
                            </td>
                            <td className="table_tbody_tr_td">
                              {p.qty}
                            </td>
                            <td
                              className="table_tbody_tr_td"
                            >
                              {format(p.subtotalPrice / p.qty, { code: creditNote.currencyCode })}
                            </td>
                            <td
                              className="table_tbody_tr_td"
                            >
                              {format((p.totalPrice), { code: creditNote.currencyCode })}
                            </td>
                          </tr>
                        );
                      }) : null}
                    </tbody>
                  </table>
                </div>
                <div className="total_container">
                  <div className="total">
                    <div className="item">
                      <p>Total Items</p>
                      <p>{creditNote?.invoice?.orderInvoiceProducts?.length || 0}</p>
                    </div>
                    <div className="item">
                      <p>Total Units</p>
                      <p>{_.sumBy(creditNote?.invoice?.orderInvoiceProducts, 'qty') || 0}</p>
                    </div>
                    <div className="item">
                      <p>Tax</p>
                      <p>{format(creditNote?.invoice?.totalTax, { code: creditNote?.invoice?.currencyCode })}</p>
                    </div>
                    <div className="item">
                      <p>Shipping charges</p>
                      <p>{format(creditNote?.invoice?.totalShippingPrice, { code: creditNote?.invoice?.currencyCode })}</p>
                    </div>
                    {creditNote?.invoice?.type === 'bill' ? (
                      <div className="item">
                        <p>Landed costs</p>
                        <p>{format(creditNote?.invoice?.totalLandedCosts, { code: creditNote?.invoice?.currencyCode })}</p>
                      </div>
                    ) : null}
                    <div className="item">
                      <p className="subtotal_value">Subtotal</p>
                      <p>{format(creditNote?.invoice?.subtotalPrice, { code: creditNote?.invoice?.currencyCode })}</p>
                    </div>
                    <div className="item">
                      <p className="total_value">Total</p>
                      <p>{format(creditNote?.invoice?.totalPrice, { code: creditNote?.invoice?.currencyCode })}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="order_information_container">
              <div className="order_information">
                <div className="col">
                  <p className="title">Contact information</p>
                  <div className="item">
                    <p
                      className="value"
                    >
                      {`${creditNote?.customer?.firstName || ''} ${creditNote?.customer?.lastName || ''}`}
                    </p>
                  </div>
                  <div className="item">
                    <p className="value">{creditNote?.customer?.phone || ''}</p>
                  </div>
                  <div className="item">
                    <p className="value">{creditNote?.customer?.email || ''}</p>
                  </div>
                </div>
                <div className="col">
                  <p className="title">Billing address</p>
                  <div className="item">
                    <p className="address">
                      {Utils.formatAddress(
                        [creditNote?.order?.billingAddress?.address1,
                          creditNote?.order?.billingAddress?.city,
                          creditNote?.order?.billingAddress?.region,
                          creditNote?.order?.billingAddress?.postalCode,
                          creditNote?.order?.billingAddress?.countryCode],
                      )
                        || Utils.formatAddress(
                          [creditNote?.customer?.billingAddress?.address1,
                            creditNote?.customer?.billingAddress?.city,
                            creditNote?.customer?.billingAddress?.region,
                            creditNote?.customer?.billingAddress?.postalCode,
                            creditNote?.customer?.billingAddress?.countryCode],
                        )}
                    </p>
                  </div>
                  <div className="item">
                    <p
                      className="value"
                    >
                      {`${creditNote?.order?.billingAddress?.firstName || ''} ${creditNote?.order?.billingAddress?.lastName || ''}`}
                    </p>
                  </div>
                </div>
              </div>
              {!_.isEmpty(creditNote?.order?.notes) ? (
                <div className="order_information">
                  <div className="col">
                    <p className="title">Notes</p>
                    {creditNote.order?.notes.map((n) => (
                      <p key={n.value}>{n.value}</p>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>

          </div>

        </div>
        {location.state?.receive ? (
          <ReceiveForm />
        ) : null}
      </div>
    </Wrapper>
  );
}

export default CreditNotePreview;
