import React, {
  useCallback, useEffect, useState,
} from 'react';
import moment from 'moment/moment';
import { findCurrency, format, currencies } from 'currency-formatter';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import Button from '../../../_common/Form/Button';
import Input from '../../../_common/Form/Input';
import Datepicker from '../../../_common/Form/Datepicker';
import noImg from '../../../../assets/icons/no_img.svg';
import { createCreditNoteRequest, generateCreditNoteNumberRequest } from '../../../../store/actions/creditNotes';
import Select from '../../../_common/Form/Select';
import { getCustomersRequest } from '../../../../store/actions/customers';
import { getSettingsRequest } from '../../../../store/actions/settings';
import {
  getOrderInvoicesRequest,
  getOrdersInvoicesRequest,
  getSingleInvoiceRequest, removeSingleInvoiceData,
} from '../../../../store/actions/invoices';
import { getProductsRequest } from '../../../../store/actions/products';
import { getSingleOrderRequest } from '../../../../store/actions/orders';
import Loader from '../../../_common/Loader/Loader';
import Api from "../../../../Api";

function CreditNoteForm(props) {
  const { onCancel } = props;

  const [form, setForm] = useState({
    sourceCreatedAt: moment().format('YYYY-MM-DD'),
    credit: undefined,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [products, setProducts] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();
  const { orderId, invoiceId } = useParams();

  const order = useSelector((state) => state.orders.order);
  const allOrdersInvoices = useSelector((state) => state.invoices.allOrdersInvoices);
  const singleInvoice = useSelector((state) => state.invoices.singleInvoice);
  const customers = useSelector((state) => state.customers.customers);

  useEffect(() => {
    (async () => {
      setLoadingData(true);
      if (location.state?.creditNote) {
        await dispatch(getSingleInvoiceRequest(location.state.creditNote));
      } else {
        dispatch(removeSingleInvoiceData());
      }
      setLoadingData(false);
    })();
  }, [location.state]);

  useEffect(() => {
    (async () => {
      if (singleInvoice) {
        const { payload } = await dispatch(getProductsRequest(
          { ids: singleInvoice.orderInvoiceProducts?.map((p) => p.productId) },
        ));
        setProducts(payload.data.products);
      }
    })();
  }, [singleInvoice]);

  useEffect(() => {
    if (!form.number) {
      (async () => {
        const { payload } = await dispatch(generateCreditNoteNumberRequest());
        handleChange('number', payload.data.number);
      })();
    }
  }, [form.number]);

  useEffect(() => {
    if (singleInvoice) {
      const amount = _.sumBy(singleInvoice.payments?.filter((p) => p.status !== 'canceled'), 'amount')
        - _.sumBy(singleInvoice?.creditNotes, 'credit');
      handleChange('credit', amount);
      handleChange('customerId', singleInvoice.billingAddress?.customerId);
    }
  }, [singleInvoice]);

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getSettingsRequest(['currencyCode']));
      handleChange('currencyCode', payload.currencyCode);
    })();
  }, []);

  const handleChange = useCallback((key, value) => {
    _.set(form, key, value);
    _.unset(errors, key);
    setForm({ ...form });
    setErrors({ ...errors });
  }, [form, errors]);

  const handleSubmit = useCallback(async (ev) => {
    ev.preventDefault();
    setLoading(true);
    let datum = { ...form };
    if (orderId) {
      datum = {
        ...datum, orderId, customerId: order.customerId, invoiceId: location.state.creditNote,
      };
    }
    if (invoiceId) {
      datum = {
        ...datum,
        orderId: singleInvoice.orderId,
        customerId: singleInvoice.billingAddress.customerId,
        invoiceId: location.state.creditNote,
      };
    }
    const { payload: { data } } = await dispatch(createCreditNoteRequest(datum));
    if (data.status === 'error' && _.isEmpty(data.errors)) {
      toast.error(data.message);
    } else if (data.status === 'error' && !_.isEmpty(data.errors)) {
      setErrors({ ...data.errors });
    } else {
      await dispatch(getSingleOrderRequest(orderId));
      await dispatch(getOrderInvoicesRequest(orderId));
      toast.success('Credit note successfully created.');
      onCancel();
    }
    setLoading(false);
  }, [form, orderId, order, location, invoiceId, singleInvoice]);

  const handleGetCustomers = useCallback(async (s) => {
    const { payload: { data } } = await dispatch(getCustomersRequest({
      s,
      includeIds: !_.isEmpty(singleInvoice) ? singleInvoice.billingAddress.customerId : undefined,
    }));

    return data.customers;
  }, [singleInvoice]);

  const handleGetInvoices = useCallback(async (s) => {
    const { payload: { data } } = await dispatch(getOrdersInvoicesRequest({ s, customerId: form.customerId }));

    return data.orderInvoices;
  }, [form.customerId]);

  const handleGetLabel = useCallback((o) => {
    if (o.firstName) {
      return `${o.firstName} ${o.lastName}`;
    }
    if (o.email) {
      return o.email;
    }
    if (o.phone) {
      return o.phone;
    }
    return o.id;
  }, []);

  return (
    <div className="invoice_form package_form">
      <form onSubmit={handleSubmit}>
        <div className="top">
          <p className="title">New credit note</p>
          <div className="actions">
            <Button btnType="cancel" roundBorder onClick={onCancel}>Cancel</Button>
            <Button roundBorder type="submit" loading={loading} disabled={form.credit <= 0}>
              Create credit note
            </Button>
          </div>
        </div>
        {!loadingData ? (
          <>
            <div className="form">
              <div className="row">
                <Input
                  label="Credit Note#"
                  roundBorder
                  value={form.number}
                  onChange={(ev) => handleChange('number', ev.target.value)}
                />
                <Datepicker
                  label="Date"
                  onChange={(date) => handleChange('sourceCreatedAt', moment(date).format('YYYY-MM-DD'))}
                  value={form.sourceCreatedAt ? moment(form.sourceCreatedAt).toDate() : undefined}
                />
                <Select
                  label="Customer"
                  placeholder="Select customer"
                  roundBorder
                  isAsync
                  getFullOption
                  loadOptions={handleGetCustomers}
                  defaultOptions
                  getOptionLabel={(o) => handleGetLabel(o)}
                  getOptionValue={(o) => o.id}
                  error={errors.customerId}
                  onChange={(val) => handleChange('customerId', val.id)}
                  value={customers.find((c) => c.id === form.customerId)}
                  isDisabled={!location.pathname.includes('create')}
                />

              </div>
              <div className="row">
                {location.pathname.includes('create') ? (
                  <Select
                    label="Invoice"
                    placeholder="Select invoice"
                    roundBorder
                    isAsync
                    getFullOption
                    key={form.customerId}
                    loadOptions={handleGetInvoices}
                    defaultOptions
                    getOptionLabel={(o) => o.number}
                    getOptionValue={(o) => o.id}
                    error={errors.invoiceId}
                    isClearable
                    onChange={(val) => {
                      handleChange('invoiceId', val?.id || null);
                      handleChange('currencyCode', val?.currencyCode || null);
                    }}
                    value={allOrdersInvoices.find((c) => c.id === form.invoiceId)}
                  />
                ) : null}
                <Input
                  label="Amount"
                  roundBorder
                  value={form.credit}
                  error={errors.credit}
                  onChange={(ev) => handleChange('credit', ev.target.value)}
                  symbol={findCurrency(order?.currencyCode)?.symbol}
                  type="number"
                />
                {location.pathname.includes('create') ? (
                  <Select
                    options={currencies}
                    roundBorder
                    label="Currency"
                    placeholder="Select"
                    valuePath="code"
                    labelPath="code"
                    onChange={(val) => handleChange('currencyCode', val)}
                    value={form.currencyCode}
                    isDisabled={form.invoiceId}
                  />
                ) : null}
              </div>
            </div>

            <div className="preview_products_list">
              {!location.pathname.includes('create') ? (
                <div className="products_list table_wrapper">
                  <table className="table">
                    <thead className="table_thead">
                      <tr className="table_thead_tr">
                        <th className="table_thead_tr_th">Product</th>
                        <th className="table_thead_tr_th">Qty</th>
                        <th className="table_thead_tr_th">Amount</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody className="table_tbody">
                      {singleInvoice?.orderInvoiceProducts?.map((p) => {
                        const product = products?.find((f) => f.id === p.productId) || {};
                        return (
                          <tr
                            className="table_tbody_tr"
                            key={p.id}
                          >
                            <td className="table_tbody_tr_td">
                              <div className="info" style={{ alignItems: 'center' }}>
                                {p.type !== 'custom' ? (
                                  <img
                                    style={{ height: 30, width: 30 }}
                                    src={product.images?.[0]?.src
                                    || product.images?.[0]?.medium
                                    || noImg}
                                    alt=""
                                  />
                                ) : null}
                                {p.type !== 'custom'
                                  ? (
                                    <Link
                                      to={product.type === 'variant'
                                        ? `/products/${product.parentId}?productVariantId=${product.id}`
                                        : `/products/${product.id}`}
                                      target="_blank"
                                    >
                                      {p.title}
                                    </Link>
                                  )
                                  : <span>{p.title}</span>}
                              </div>
                            </td>
                            <td className="table_tbody_tr_td ">
                              {p.qty}
                            </td>
                            <td className="table_tbody_tr_td">{format(p.totalPrice, { code: order.currencyCode })}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
              <div className="total_container">
                <div>
                  <Input
                    textarea
                    label="Notes"
                    placeholder="Enter any notes to be displayed in your transaction"
                    onChange={(ev) => handleChange('note', ev.target.value)}
                    value={form.note}
                  />
                </div>
                {!location.pathname.includes('create') ? (
                  <div className="total">
                    <div className="item">
                      <p>Total Items</p>
                      <p>{singleInvoice?.orderProducts?.length || 0}</p>
                    </div>
                    <div className="item">
                      <p>Tax</p>
                      <p>{format(singleInvoice.totalTax, { code: singleInvoice.currencyCode })}</p>
                    </div>
                    <div className="item">
                      <p>Shipping charges</p>
                      <p>{format(singleInvoice.shippingRate, { code: singleInvoice.currencyCode })}</p>
                    </div>
                    <div className="item">
                      <p className="subtotal_value">Subtotal</p>
                      <p>{format(singleInvoice.subtotalPrice, { code: singleInvoice.currencyCode })}</p>
                    </div>
                    <div className="item">
                      <p className="total_value">Total</p>
                      <p>{format(singleInvoice.totalPrice, { code: singleInvoice.currencyCode })}</p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        ) : <Loader />}

      </form>
    </div>
  );
}

export default CreditNoteForm;
