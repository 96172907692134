import React, { useEffect, useId, useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactComponent as CalendarSvg } from '../../../assets/icons/calendar.svg';

const calendarData = {
  days: ['s', 'm', 't', 'w', 't', 'f', 's'],
  months: ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'],
};

const locale = {
  localize: {
    day: (n) => calendarData.days[n],
    month: (n) => calendarData.months[n],
  },
  formatLong: {
    date: () => 'yyyy-mm-dd',
  },
};

const today = moment().format('MM/DD/YYYY');
const yesterday = moment(new Date()).subtract(1, 'd').format('MM/DD/YYYY');
const weekStartDay = moment().subtract(7, 'day').format('MM/DD/YYYY');
const thisMonthStartDay = moment().startOf('month').format('MM/DD/YYYY');
const thisMonthEndDate = today;
const lastMonthStartDay = moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY');
const lastMonthEndDate = moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY');

const tabRange = [
  {
    tabName: 'today',
    start: new Date(),
    end: null,
    dateArray: [today, null],
  },
  {
    tabName: 'yesterday',
    start: new Date(yesterday),
    end: null,
    dateArray: [yesterday, null],
  },
  {
    tabName: 'week',
    start: new Date(weekStartDay),
    end: new Date(),
    dateArray: [weekStartDay, today],
  },
  {
    tabName: 'thisMonth',
    start: new Date(thisMonthStartDay),
    end: new Date(),
    dateArray: [thisMonthStartDay, today],
  },
  {
    tabName: 'lastMonth',
    start: new Date(lastMonthStartDay),
    end: new Date(lastMonthEndDate),
    dateArray: [lastMonthStartDay, lastMonthEndDate],
  },
];

let date = {};

const RangeDatePicker = ({
  currentDate, onChangeDate, show, withoutApplyBtn, todayLabel, showMonthsCount, selectsRange,
  calendarClassName, portalId, menuWrapperClassName,
}) => {
  const uniqId = useId();
  const [startDate, setStartDate] = useState(currentDate.startDate ? new Date(currentDate.startDate) : null);
  const [endDate, setEndDate] = useState(currentDate.endDate ? new Date(currentDate.endDate) : null);

  const [isCalendarOpen, calendarToggle] = useState(false);

  const findTab = tabRange.reverse().find((t) => (
    (currentDate.startDate ? moment(currentDate.startDate).format('YYYY-MM-DD') : null)
    === moment(t.dateArray[0]).format('YYYY-MM-DD')

    && (currentDate.endDate ? moment(currentDate.endDate).format('YYYY-MM-DD') : null)
    === (t.dateArray[1] ? moment(t.dateArray[1]).format('YYYY-MM-DD') : null)));

  const [currentTab, setCurrentTab] = useState(findTab ? findTab.tabName : '');

  useEffect(() => {
    if (!isCalendarOpen) {
      date = currentDate;

      setStartDate(currentDate.startDate ? new Date(moment(currentDate.startDate).format('MM/DD/YYYY')) : null);
      setEndDate(currentDate.endDate ? new Date(moment(currentDate.endDate).format('MM/DD/YYYY')) : null);

      if (findTab) setCurrentTab(findTab.tabName);
    }
  }, [currentDate, isCalendarOpen]);

  const closeCalendar = (clear) => {
    calendarToggle(false);

    if (!date.start) {
      setStartDate(null);
      setEndDate(null);
    } else {
      setStartDate(new Date(date.start));
      setEndDate(date.end ? new Date(date.end) : null);
    }

    if (clear) {
      setStartDate(new Date(thisMonthStartDay));
      setEndDate(new Date(thisMonthEndDate));
      setCurrentTab('thisMonth');
      onChangeDate([
        moment(thisMonthStartDay).format('YYYY-MM-DD'),
        moment(thisMonthEndDate).format('YYYY-MM-DD')]);
    }
  };

  const calendarOpen = (toggle) => {
    if (!toggle) closeCalendar();

    else calendarToggle(toggle);
  };

  const onClickTabs = (tab) => {
    const clickedTab = tabRange.find((t) => t.tabName === tab);

    setStartDate(clickedTab.start);
    setEndDate(clickedTab.end);

    onChangeDate([
      moment(clickedTab.dateArray[0]).format('YYYY-MM-DD'),
      clickedTab.dateArray[1] ? moment(clickedTab.dateArray[1]).format('YYYY-MM-DD') : null]);

    setCurrentTab(tab);
    calendarToggle(false);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setCurrentTab('');

    if (withoutApplyBtn) {
      onChangeDate([moment(start).format('YYYY-MM-DD'),
        end ? moment(end).format('YYYY-MM-DD') : null]);
    }
  };

  const onApplyClick = () => {
    if (startDate) {
      onChangeDate([moment(startDate).format('YYYY-MM-DD'),
        endDate ? moment(endDate).format('YYYY-MM-DD') : null]);
    }

    calendarToggle(false);
  };

  const onCancelClick = () => {
    closeCalendar(true);
  };

  const closeHandler = (e) => {
    if (!e.target.closest(`[data-id="calendar_${uniqId}"]`)
      && !e.target.closest('.react-datepicker__tab-loop') && !show) {
      closeCalendar();
    }
  };

  useEffect(() => {
    window.addEventListener('click', closeHandler, true);

    return () => {
      window.removeEventListener('click', closeHandler, true);
    };
  }, []);

  const calendarContainer = ({ className, children }) => (
    <div className={`date_piker_wrapper ${menuWrapperClassName}`}>
      <CalendarContainer className={className}>
        <div style={{ position: 'relative' }}>{children}</div>

        {showMonthsCount !== 1 && (
          <div className="bottom_buttons">

            <div className="date_buttons">
              <button
                type="button"
                className={currentTab === 'today' ? 'active' : ''}
                onClick={() => onClickTabs('today')}
              >
                Today
              </button>

              <button
                type="button"
                className={currentTab === 'yesterday' ? 'active' : ''}
                onClick={() => onClickTabs('yesterday')}
              >
                Yesterday
              </button>

              <button
                type="button"
                className={currentTab === 'week' ? 'active' : ''}
                onClick={() => onClickTabs('week')}
              >
                Last 7 days
              </button>

              <button
                type="button"
                className={currentTab === 'thisMonth' ? 'active' : ''}
                onClick={() => onClickTabs('thisMonth')}
              >
                This month
              </button>

              <button
                type="button"
                className={currentTab === 'lastMonth' ? 'active' : ''}
                onClick={() => onClickTabs('lastMonth')}
              >
                Last month
              </button>
            </div>

            <div className="apply_buttons">
              <button
                type="button"
                onClick={onCancelClick}
              >
                Clear
              </button>

              {!withoutApplyBtn && (
                <button
                  type="button"
                  className="apply_btn"
                  onClick={onApplyClick}
                >
                  Apply
                </button>
              )}
            </div>
          </div>
        )}
      </CalendarContainer>
    </div>
  );

  return (
    <div data-id={`calendar_${uniqId}`} className={`calendar_wrapper ${show ? 'inline' : ''}`}>
      <DatePicker
        customInput={(
          <button
            className={`calendar_custom_input ${isCalendarOpen ? 'active' : ''}`}
            onClickCapture={() => calendarOpen(!isCalendarOpen)}
            type="button"
          >
            <CalendarSvg className="calendar_icon" />

            <div>
              {currentDate.startDate
                ? (
                  <span>
                    {currentDate.endDate
                      ? `${moment(currentDate.startDate).format('MM.DD.YYYY')} 
                      - ${moment(currentDate.endDate).format('MM.DD.YYYY')}`
                      // : todayLabel && currentTab === 'today'
                      //   ? 'Today'
                      : moment(currentDate.startDate).format('MM.DD.YYYY')}
                  </span>
                )

                : <span style={{ color: '#949292' }}>mm.dd.yyyy - mm.dd.yyyy</span>}
            </div>

            <div
              className={`calendar_custom_input_arrow ${isCalendarOpen ? 'open' : ''}`}
            />
          </button>
        )}
        selected={startDate ? new Date(moment(startDate).format('MM/DD/YYYY')) : null}
        startDate={startDate ? new Date(moment(startDate).format('MM/DD/YYYY')) : null}
        endDate={endDate ? new Date(moment(endDate).format('MM/DD/YYYY')) : null}
        selectsRange
        onChange={onChange}
        yearDropdownItemNumber={100}
        showYearDropdown={showMonthsCount !== 1}
        monthsShown={showMonthsCount}
        locale={locale}
        inline={show}
        scrollableYearDropdown
        open={isCalendarOpen}
        calendarContainer={calendarContainer}
        calendarClassName={calendarClassName}
        portalId={portalId}
      />
    </div>
  );
};

export default RangeDatePicker;

RangeDatePicker.propTypes = {
  currentDate: PropTypes.object.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  show: PropTypes.bool,
  todayLabel: PropTypes.bool,
  withoutApplyBtn: PropTypes.bool,
  selectsRange: PropTypes.bool,
  showMonthsCount: PropTypes.number,
  calendarClassName: PropTypes.string,
  menuWrapperClassName: PropTypes.string,
};

RangeDatePicker.defaultProps = {
  show: false,
  withoutApplyBtn: false,
  selectsRange: false,
  todayLabel: false,
  showMonthsCount: 2,
  calendarClassName: '',
  menuWrapperClassName: '',
};
