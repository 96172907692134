import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../Api';

export const getTemplatesListRequest = createAsyncThunk(
  'customers/getTemplatesListRequest',
  async (payload, thunkAPI) => {
    try {
      const { data } = await Api.getTemplatesList(payload);
      return { data };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  },
);
