import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import AppInstallInfoWrapper from './AppInstallInfoWrapper';
import { ReactComponent as SmtpIcon } from '../../../../assets/icons/apps/smtp.svg';
import Api from '../../../../Api';
import SmtpCreateModal from './SmtpModal/SmtpCreateModal';
import { getSetupRequest } from '../../../../store/actions/notifications';

const SMTPOptions = [
  { label: 'TLS', value: 'TLS' },
  { label: 'STARTTLS', value: 'STARTTLS' },
];

const fields = [
  {
    title: 'Host',
    placeholder: 'Host',
    path: 'host',
    required: true,
  }, {
    title: 'Email',
    placeholder: 'Email',
    path: 'email',
    required: true,
  }, {
    title: 'Password',
    placeholder: 'Password',
    path: 'password',
    type: 'password',
    required: true,
  }, {
    title: 'Port',
    placeholder: 'Port',
    path: 'port',
    required: true,
  }, {
    title: 'From',
    placeholder: 'Name <demo@gmail.com>',
    path: 'from',
  },
  {
    title: 'Encryption',
    placeholder: 'Encryption',
    path: 'encryption',
    type: 'select',
    options: SMTPOptions,
    valuePath: 'value',
    labelPath: 'label',
    menuPortalTarget: true,
  },
];

function SmtpInstall() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [installModal, setInstallModal] = useState(false);

  const install = useCallback(async (data) => {
    try {
      await Api.createIntegration(
        'messaging',
        {
          integrationName: 'SMTP',
          type: 'smtp',
          host: data.host,
          email: data.email,
          password: data.password,
          from: data.from,
          encryption: data.encryption,
          port: data.port,
          redirectUrl: `${window.location.origin}/integration-verify/messaging`,
        },
      );

      await dispatch(getSetupRequest());

      navigate('/my-apps');
    } catch (err) {
      if (err.response.data?.errors) {
        throw err;
      } else {
        toast.error(err.response.data.message);
        setInstallModal(false);
      }
    }
  }, []);

  const testConnection = useCallback(async (data) => {
    try {
      const { status } = await Api.testSMTPConnection(
        {
          host: data.host,
          email: data.email,
          password: data.password,
          from: data.from,
          port: data.port,
          encryption: data.encryption,
        },
      );
      if (status === 200) {
        toast.success('Test connection successfully');
      }
    } catch (err) {
      if (err.response.data?.errors) {
        throw err;
      } else {
        toast.error(err.response.data.message);
      }
    }
  }, []);

  return (
    <AppInstallInfoWrapper
      shopName="SMTP - us"
      shopIcon={<SmtpIcon />}
      description={"eSwap's Amazon integration helps you to manage stock levels on Amazon, retrieve sales orders and"
        + ' process them. To integrate eSwap with Amazon, go to the Amazon Marketplace Web Service (Amazon MWS).'}
      onModalOpen={() => setInstallModal(true)}
    >
      <p>
        Learn more about Amazon MWS. Amazon
        {' '}
        <span><Link to="">Click here</Link></span>
        {' '}
        to login to your Amazon Marketplace Web Service (MWS).
        Next, Sign up for MWS (enter your Amazon email and password to sign in)
      </p>

      <div>
        <p>Select the following option: I want to give a developer access to my Amazon seller account with MWS.</p>
        <p>Enter the following values in the corresponding fields:</p>
        <p>AMAZON US, CA, MX</p>

        <p><strong>Developer Name: eSwap</strong></p>
        <p><strong>Developer Account No: 5974-2762-8523</strong></p>

        <p>Click on Next.</p>
        <p>Accept the license agreement and click the Next button.</p>
      </div>

      <p>eSwap will start importing your products from Amazon. </p>
      <p>
        You can check the import progress via the Dashboard in your Amazon Channel integration section in your
        eSwap account.
      </p>

      <SmtpCreateModal
        isOpen={installModal}
        fields={fields}
        onSave={install}
        onClose={() => setInstallModal(false)}
        onClick={testConnection}
      />
    </AppInstallInfoWrapper>
  );
}

export default SmtpInstall;
